<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="this.title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出力範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container fluid>
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="6">
                      <b-form-group
                        label="営業所"
                        label-for="salesOffice"
                      >
                        <b-form-select
                          id="salesOffice"
                          v-model="salesOffice"
                          :options="salesOfficeList"
                          value-field="id"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 発注先コード -->
                    <b-col lg="6">
                      <b-form-group
                        label="発注先コード"
                        description="発注先の範囲を入力してください。空白の場合は全範囲が設定されます。" >
                        <b-container fluid>
                          <b-row>
                            <b-col class="px-0">
                              <validation-provider name="supplierCodeStart" :rules="{numeric:true,consistency:supplierCodeEnd}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      id="supplierCodeStart"
                                      name="supplierCodeStart"
                                      v-model.number="supplierCodeStart"
                                      maxlength="6"/>
                                    <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                            <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                            <b-col class="px-0">
                              <validation-provider name="supplierCodeEnd" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      id="supplierCodeEnd"
                                      name="supplierCodeEnd"
                                      v-model.number="supplierCodeEnd"
                                      maxlength="6"/>
                                    <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 発行区分コードプルダウン -->
                    <b-col lg="6">
                      <b-form-group
                        label="発行区分"
                        label-for="orderSlipClass"
                      >
                        <b-form-select
                          id="orderSlipClass"
                          v-model="orderSlipClass"
                          :options="orderSlipClassList"
                          value-field="id"
                          @change="updateOrderNumber()"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 発注書回数プルダウン -->
                    <b-col lg="6" v-if="Number(this.$route.query.menuId) == 1">
                      <b-form-group
                        label="発注書回数"
                        label-for="orderSlipOutoutNum"
                        description="発注書（通常）出力の場合のみ有効です。発注書（直送）出力の場合は午前午後に関係なく出力されます。"
                      >
                        <b-form-select
                          id="orderSlipOutoutNum"
                          v-model="orderSlipOutoutNum"
                          :options="orderSlipOutoutNumList"
                          value-field="id"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 受注データ入力ユーザー -->
                    <b-col lg="6" v-if="Number(this.$route.query.menuId) == 3">
                      <b-form-group
                        label="受注データ入力ユーザー"
                        description="検索アイコンからユーザーを選択してください。"
                      >
                        <b-input-group>
                          <b-form-select
                            id="orderInsertUser"
                            v-model="orderInsertUser"
                            :options="staffList"
                            value-field="id"
                            disabled
                          />
                          <b-input-group-text @click="showStaffSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '3']" @shortkey="showStaffSearchModal" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 再発行時に指定 -->
                    <b-col lg="6">
                      <div class="d-flex justify-content-between">
                        <h6 class="text-secondary"><span class="oi oi-document"></span><strong> 再発行時に指定</strong></h6>
                      </div>
                      <b-form-group label="注文番号" description="注文番号の範囲を入力してください。">
                        <b-container fluid>
                          <b-row>
                            <b-col class="px-0">
                              <validation-provider name="orderNumberStart" :rules="{numeric:true,consistency:orderNumberEnd,required_if:(orderSlipClass === 2 && !orderNumberEnd)}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      id="orderNumberStart"
                                      name="orderNumberStart"
                                      v-model.number="orderNumberStart"
                                      :disabled="orderSlipClass !== 2" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                            <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                            <b-col class="px-0">
                              <validation-provider name="orderNumberEnd" :rules="{numeric:true, required_if:(orderSlipClass === 2 && !orderNumberStart)}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      id="orderNumberEnd"
                                      name="orderNumberEnd"
                                      v-model.number="orderNumberEnd"
                                      :disabled="orderSlipClass !== 2" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- 発注書出力ボタン -->
          <b-card-footer>
            <b-container>
              <b-row v-if="menuId === 1" class="justify-content-md-center">
                <b-col cols="12" sm="6" md="5" lg="4" class="pb-1">
                  <b-button variant="success" class="btn-block rounded-pill" @click="clearAlert(); clickOutputPurchaseOrder(false);"><span class="oi oi-document"></span> 発注書（通常）出力</b-button>
                </b-col>
                <b-col cols="12" sm="6" md="5" lg="4" class="pb-1">
                  <b-button variant="success" class="btn-block rounded-pill" @click="clearAlert(); clickOutputPurchaseOrder(true);" :disabled="orderSlipClass === 3"><span class="oi oi-document"></span> 発注書（直送）出力</b-button>
                </b-col>
              </b-row>
              <b-row v-if="menuId !== 1" class="justify-content-md-center">
                <b-col cols="12" md="6" lg="5" xl="4" class="pb-1">
                  <b-button variant="success" class="btn-block rounded-pill" @click="clearAlert(); clickOutputPurchaseOrder(false);"><span class="oi oi-document"></span> 発注書出力</b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●担当者検索モーダル●●● -->
  <STAFFSEARCH @from-child="closeStaffSearchModal"/>
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.supplier"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { init, addOperationLogs, isSystemEditable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices, /*list_t_orders*/ } from '@/graphql/queries';

// モジュール名
const MODULE_NAME = 'order-slip-print';
export default {
  name: 'ORDER-SLIP-PRINT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    STAFFSEARCH,
    CLIENTSEARCH,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: null,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],

      // 発注書発行画面の識別ID
      menuId: null,

      // 抽出条件
      // 営業所
      salesOffice: null,
      // 発行区分
      orderSlipClass: null,
      // 発注先コード（開始）
      supplierCodeStart: null,
      // 発注先コード（終了）
      supplierCodeEnd: null,
      // 発注書回数
      orderSlipOutoutNum: null,
      // 受注データ入力ユーザー
      orderInsertUser: null,
      // 再発行フラグ
      isReprint: false,
      // 注文番号（開始）
      orderNumberStart: null,
      // 注文番号（終了）
      orderNumberEnd: null,

      // 営業所プルダウン用リスト
      salesOfficeList: [],
      // 受注データ入力ユーザープルダウン用リスト
      staffList: [],
      // // 受注データ入力ユーザー
      // staff: {
      //   id: '',
      //   text: '',      
      // },
      originalStaffList: [],
      // 発行区分プルダウン用リスト
      orderSlipClassList: [
        { id: 1, text: '1：新規発行'},
        { id: 2, text: '2：再発行'},
        { id: 3, text: '3：強制発行'},
      ],
      // 発注書回数プルダウン用リスト
      orderSlipOutoutNumList: [
        { id: 1, text: '午前中のみ発注'},
        { id: 2, text: '午後のみ発注'},
      ],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 確認ダイアログ用
      confirmMessage: [],
      // 直送かどうか
      isDirectDelivery: false,
    }
  },
  /**
   * createdライフサイクルフック
   */
  created() {
    // URLパラメータから画面IDを取得、画面タイトルにセット
    this.title = '発注書発行'
    this.menuId = Number(this.$route.query.menuId);
    switch(this.menuId) {
    case 1: // 発注書発行
      this.title = '発注書発行';
      break;
    case 2: // 発注書発行（入力分）
      this.title = '発注書発行（入力分）';
      break;
    case 3: // 発注書発行（特別売上分）
      this.title = '発注書発行（特別売上分）';
      break;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0;
    },
  },
  methods: {
    /**
     * 画面の初期化処理
     */
    async fetchData() {

      // ローディングの開始
      this.$store.commit('setLoading', true);

      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;

      /* データ取得 */
      // 担当者データ
      let staffListData = await this.getStaffInfo();
      if (!staffListData) {
        // ローディングの終了
        this.$store.commit('setLoading', false);
        return;
      }
      // 営業所データ
      let officeListData = await this.getOfficesData();
      if (!officeListData) {
        // ローディングの終了
        this.$store.commit('setLoading', false);
        return;
      }

      /* プルダウンのリスト生成・変更 */
      // 営業所
      for (const officeItem of officeListData) {
        this.salesOfficeList.push({
          id: officeItem.office_id,
          text: officeItem.office_id + '：' + officeItem.office_name_kanji,
          name: officeItem.office_name_kanji
        });
      }
      // 受注データ入力ユーザー
      for (const staff of staffListData) {
        this.originalStaffList.push({
          id: staff.staff_id,
          text: staff.staff_id + '：' + staff.staff_name_kanji,
          name: staff.staff_name_kanji,
          office_id: staff.office_id,
          login_id: staff.login_id,
        });
      }
      // 発行区分、発注書様式
      switch(this.menuId) {
      case 1: // 発注書発行
        break;
      case 2: // 発注書発行（入力分）
      case 3: // 発注書発行（特別売上分）
        // 発行区分「3:強制発行」を間引く
        this.orderSlipClassList = this.orderSlipClassList.filter(function( item ) {
          return item.id !== 3;
        });
        break;
      default:
        break;
      }

      /* 初期値セット */
      // 営業所：ログインユーザーの所属営業所
      let loginStaff = this.originalStaffList.find((input) => input.login_id == this.loginStaffInfo);
      let salesOffice = this.salesOfficeList.find((input) => input.id == loginStaff.office_id);
      this.salesOffice = salesOffice ? salesOffice.id : null;
      // 発行区分：「1: 新規発行」
      this.orderSlipClass = 1;
      // 発注書回数：「0：午前午後どちらも発注」
      this.orderSlipOutoutNum = 1;
      // 受注データ入力ユーザーリスト：ログインユーザーの所属営業所のユーザーリスト
      this.staffList = this.originalStaffList;
      // 受注データ入力ユーザー：ログインユーザー
      this.orderInsertUser = loginStaff.id;

      // ローディングの終了
      this.$store.commit('setLoading', false);
      return true;
    },
    /**
     * 担当者リストの取得
     */
    async getStaffInfo() {
      const functionName = 'getStaffInfo';
      // 検索条件を設定
      let where_clause = `AND login_id = '${this.loginId}'`;
      let condition = {where_clause: where_clause}; 
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(list_m_staffs), condition);
        if (result.data.list_m_staffs) {
          // データを取得できた場合、取得データを返却
          return result.data.list_m_staffs;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return null;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
    },
    /**
     * 営業所情報の取得
     */
    async getOfficesData() {
      const functionName = 'getOfficesData';
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(list_m_offices));
        if (result.data.list_m_offices) {
          // データを取得できた場合、取得データを返却
          return result.data.list_m_offices;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result.data is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
    },
    /**
     * 「発注書出力」ボタン押下処理
     * @param {Boolan} isDirectDelivery - 直送かどうか
     */
    async clickOutputPurchaseOrder(isDirectDelivery){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションチェック
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        this.isDirectDelivery = isDirectDelivery;
        await this.saveConfirm();
      }
    },
    /**
     * 保存時の確認ダイアログ表示
     */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で発注書を発行します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /**
     * 確認モーダルを閉じた時
     */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 確認後保存処理
     */
    async confirmSave() {
      const functionName = 'confirmSave';

      var newWin = window.open('', '_blank');
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          newWin.close()
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        newWin.close()
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      let moveTo = 'PURCHASE-ORDER';

      switch (this.menuId) {
      case 1:
        moveTo = this.isDirectDelivery
          ? 'PURCHASE-ORDER-DIRECT-DELIVERY' : this.orderSlipClass == 2
            ? 'PURCHASE-ORDER-NORMAL-REISSUE' : 'PURCHASE-ORDER-NORMAL';
        break;
      case 2:
        moveTo = 'PURCHASE-ORDER';
        break;
      case 3:
        moveTo = 'PURCHASE-ORDER-SALES';
        break;
      default:
        // 画面遷移しない
        newWin.close()
        return;
      }

      // 帳票印刷プレビュー画面へ遷移
      let route = this.$router.resolve({
        name: moveTo,
        query: {
          // メニューID
          menuId: this.menuId,
          // 営業所
          salesOffice: this.salesOffice,
          // 発行区分
          orderSlipClass: this.orderSlipClass,
          // 発注先コード（開始）
          supplierCodeStart: this.supplierCodeStart,
          // 発注先コード（終了）
          supplierCodeEnd: this.supplierCodeEnd,
          // 発注書回数
          orderSlipOutoutNum: this.orderSlipOutoutNum,
          // 受注データ入力ユーザー
          orderInsertUser: this.orderInsertUser,
          // 注文番号（開始）
          orderNumberStart: this.orderNumberStart,
          // 注文番号（終了）
          orderNumberEnd: this.orderNumberEnd,
        }
      });
      newWin.location = route.href
    },
    /**
     * 「取引先検索」アイコン押下処理
     */
    showClientSearchModal: function(index) {
      // const functionName = 'showClientSearchModal';
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 「取引先検索」モーダルのクローズ押下処理
     */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.supplierCodeStart = clientItems.detail.client_id;
        } else {
          this.supplierCodeEnd = clientItems.detail.client_id;
        }
      }
    },
    /**
     * 「担当者検索」アイコン押下処理
     */
    showStaffSearchModal: function() {
      this.dataIndex = -1;
      this.$bvModal.show('staffSearchModal');
    },
    /**
     * 「担当者検索」モーダルのクローズ押下処理
     */
    closeStaffSearchModal: function(staffItems){
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if(typeof staffItems != 'undefined'){
        this.orderInsertUser = staffItems.detail.staff_id;
      }
    },
    /**
     * 画面のアラートをクリア
     */
    clearAlert: function() {
      // const functionName = 'clearAlert'
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /**
     * 発行区分更新時の注文番号の初期化処理
     */
    async updateOrderNumber() {
      this.orderNumberStart = null;
      this.orderNumberEnd = null;
      // バリデーションを解除
      this.$refs.observer.reset();
    }
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>