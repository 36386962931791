<template>
  <!-- 取引先コード切替画面 -->
  <div>
    <Header :type="menu_type" :title="title"/>
    <!-- 致命的なエラーが発生した場合 -->
    <b-container v-if="criticalMessages.length > 0" fluid class="p-4 min-vh-85">
      <b-alert show variant="danger">
        <ul v-for="(message, index) in criticalMessages" :key="index" style="list-style: none;">
          <li>{{ message }}</li>
        </ul>
      </b-alert>
    </b-container>
    <!-- 致命的なエラーが発生していない場合 -->
    <b-container v-if="criticalMessages.length === 0" fluid class="px-4 pt-4 min-vh-85">
      <b-row class="d-flex justify-content-center my-2">
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{title}}</strong></h5>
                <router-link to="/client-code-switch-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </b-media-body>
          </b-media>
          <!-- 取引先コード切替が実行中ではない場合 -->
          <b-card v-if="!isRunning" class="main-card mb-3" no-body>
            <b-card-header>
              <b-alert v-if="errorMessages.length > 0" class="mb-0" show variant="danger">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p class="pb-3">新取引先コードを入力して[切替]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form>
                  <b-row class="mt-2">
                    <b-col>
                      <b-form-group
                        label="取引先区分"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="clientClass"
                      >
                        <b-form-input id="clientClass" readonly v-model="clientClass"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="取引先名"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="clientName"
                      >
                        <b-form-input id="clientName" readonly v-model="clientName"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="旧取引先コード"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="clientId"
                      >
                        <b-form-input id="clientId" readonly v-model="oldClientId"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="新取引先コード"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="newClientId"
                        description="使用されていない取引先コードを指定してください。"
                      >
                        <validation-provider
                          :rules="{required:true,numeric:true,max:6,between:{min:0,max:999999},not_same_number:{other:client?client.client_id:'',title:'旧取引先コード'}}"
                          v-slot="{ classes, errors }"
                        >
                          <div :class="classes">
                            <b-form-input id="newClientId" maxlength="6" v-model="newClientId"></b-form-input>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-center mt-2">
                    <b-col sm="5" md="4" lg="3">
                      <b-button block :disabled="isUpdateButtonDisabled" pill variant="primary" @click="onUpdateButtonClick"><span class="oi oi-circle-check"></span> 切替</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- 取引先コード切替が実行中の場合 -->
          <b-card v-if="isRunning" class="main-card mb-3" no-body>
            <b-card-body class="text-center">
              <b-row>
                <b-col>
                  <h5 class="my-3">取引先コード切替を実行中です。しばらくお待ちください。</h5>
                  <div class="my-3"><b>本画面は自動的に更新されません。</b></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-3 text-center">
                  <b-button pill variant="success" @click="checkRunningState">状況を確認する</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, init, isSystemEditable, executeSelectSql } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_control, list_m_clients, getM_clients } from '@/graphql/queries';
import { execute_client_code_switch } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const';

// モジュール名
const MODULE_NAME = 'client-code-switch-edit';

export default {
  name: 'CLIENT-CODE-SWITCH-EDIT',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      menu_type: 'user',
      title: '取引先コード切替',
      clientCodeSwitchState: 0,
      client: null,
      newClientId: '',
      isStartMsgBoxConfirmShow: false,
      errorMessages: [],
      criticalMessages: []
    };
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /**
     * 取引先区分を返します。
     */
    clientClass() {
      return (this.client === null) ? '' : this.client.client_class;
    },
    /**
     * 取引先名を返します。
     */
    clientName() {
      return (this.client === null) ? '' : (this.client.client_name_kanji.trim() === '') ? this.client.client_name_kana : this.client.client_name_kanji;
    },
    /**
     * 現在の取引先コードを返します。
     */
    oldClientId() {
      return (this.client === null) ? '' : this.client.client_id;
    },
    /**
     * 更新ボタンの有効・無効を返します。
     */
    isUpdateButtonDisabled() {
      return this.isStartMsgBoxConfirmShow;
    },
    /**
     * 取引先コード切替が実行中かどうかを返します。
     */
    isRunning() {
      return this.clientCodeSwitchState === Const.ClientCodeSwitchStateClass.RUNNING;
    }
  },
  methods: {
    /**
     * パラメータに指定されたIDに対応する取引先情報を取得して画面に表示します。
     */
    async fetchData() {
      const functionName = 'fetchData';

      // 取引先コード切替が実行中かどうかを確認します。
      let listMControlResult = null;
      try {
        listMControlResult = await API.graphql(graphqlOperation(list_m_control));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: listMControlResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.data.list_m_control.length === 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'コントロールマスタが空です。');
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      this.clientCodeSwitchState = listMControlResult.data.list_m_control[0].switch_client_id_state;
      if (this.isRunning) {
        return;
      }

      // 切り替えを行う対象の取引先情報を取得します。
      let getMClientsResult = null;
      try {
        getMClientsResult = await API.graphql(graphqlOperation(getM_clients, { id: this.$route.query.id }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_clients',
          id: this.$route.query.id
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (getMClientsResult.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_clients',
          id: this.$route.query.id,
          result: getMClientsResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      this.client = getMClientsResult.data.getM_clients;
    },
    /**
     * 更新ボタンクリックイベント処理
     */
    async onUpdateButtonClick() {
      const functionName = 'onUpdateButtonClick';

      this.errorMessages = [];
      this.isStartMsgBoxConfirmShow = true;

      // 入力エラーがあった場合はエラーの項目へスクロール
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      // 整合性をチェックします。
      if (!await this.validate()) {
        scrollTo(0,0);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      if (!await this.$bvModal.msgBoxConfirm('取引先コード切替を行います。よろしいですか？', {
        title: '取引先コード切替の開始',
        okTitle: 'はい',
        cancelTitle: 'いいえ'
      })) {
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      // 取引先コード切替開始
      const executeClientCodeSwitchInput = {
        id: this.client.id,
        client_class: this.client.client_class,
        old_client_id: this.client.client_id,
        new_client_id: this.newClientId,
        new_client_id_first_digit: String(this.newClientId).padStart(6, '0').substring(0, 1)
      };
      await addOperationLogs('Info', MODULE_NAME, functionName, `取引先コード切替API呼び出しを開始します。取引先ID：${executeClientCodeSwitchInput.id}、取引先区分：${executeClientCodeSwitchInput.client_class}、取引先コード：${executeClientCodeSwitchInput.old_client_id}、新取引先コード：${executeClientCodeSwitchInput.new_client_id}、取引先分類コード：${executeClientCodeSwitchInput.new_client_id_first_digit}`);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          await addOperationLogs('Info', MODULE_NAME, functionName, `月次更新・取引先コード切替・製品コード切替のいずれかが実行中のため、取引先コード切替API呼び出しを中止しました。取引先ID：${executeClientCodeSwitchInput.id}、取引先区分：${executeClientCodeSwitchInput.client_class}、取引先コード：${executeClientCodeSwitchInput.old_client_id}、新取引先コード：${executeClientCodeSwitchInput.new_client_id}、取引先分類コード：${executeClientCodeSwitchInput.new_client_id_first_digit}`);
          this.errorMessages.push(msg);
          this.isStartMsgBoxConfirmShow = false;
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      // 取引先コード切替Step Functionsを呼び出します。
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(execute_client_code_switch, {
          input: executeClientCodeSwitchInput
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_client_code_switch',
          input: executeClientCodeSwitchInput
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }
      if (result.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_client_code_switch',
          input: executeClientCodeSwitchInput,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      // 呼び出しに成功したので実行中画面に切り替えます。
      await addOperationLogs('Info', MODULE_NAME, functionName, `取引先コード切替API呼び出しが成功しました。実行ID：${result.data.execute_client_code_switch.execution_id}`);
      await this.$bvModal.msgBoxOk('取引先コード切替を開始しました。', {
        title: '取引先コード切替の開始',
        okTitle: 'はい'
      });
      this.isStartMsgBoxConfirmShow = false;
      this.clientCodeSwitchState = Const.ClientCodeSwitchStateClass.RUNNING
    },
    /**
     * 整合性チェックを行います。
     * @returns {Boolean} 入力に問題が無い場合はtrue、問題がある場合はfalse
     */
    async validate() {
      const functionName = 'validate';

      // 新しい取引先コードが存在するかどうかチェックします。
      const listMClientsWhereClause = `AND client_class = ${this.client.client_class} AND client_id = ${this.newClientId}`;
      let listMClientsResult = null;
      try {
        listMClientsResult = await API.graphql(graphqlOperation(list_m_clients, { where_clause: listMClientsWhereClause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: listMClientsWhereClause
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMClientsResult.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: listMClientsWhereClause,
          result: listMClientsResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMClientsResult.data.list_m_clients.length > 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2039'].replace('%arg1%', '新取引先コード').replace('%arg2%', '取引先コード'));
        return false;
      }

      if (this.client.client_class == Const.ClientClass.customer) {
        // 取引先区分が「1：得意先」の場合、現場マスタ、請求マスタ、請求マスタ２も確認
        // SELECT句
        let selectSql = 'SELECT';
        selectSql += ' (SELECT COUNT(*) FROM m_clients_sites WHERE client_id = ' + this.newClientId + ' LIMIT 1) AS clients_sites_flg';
        selectSql += ',(SELECT COUNT(*) FROM m_billings WHERE client_id IN (' + this.client.client_id + ',' + this.newClientId + ') LIMIT 1) AS billings_flg';
        selectSql += ',(SELECT COUNT(*) FROM m_billings2 WHERE client_id IN (' + this.client.client_id + ',' + this.newClientId + ') LIMIT 1) AS billings2_flg';
        // FROM句
        selectSql += ' FROM ';
        selectSql += 'DUAL ';
        //console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        if (resultData[0].clients_sites_flg > 0) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2039'].replace('%arg1%', '新取引先コード').replace('%arg2%', '取引先コード'));
          return false;
        } else if (resultData[0].billings_flg > 0) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2077'].replace('%arg1%', '旧取引先コード、または、新取引先コード').replace('%arg2%', '請求前処理').replace('%arg3%', '請求前処理の範囲変更、または、請求マスタ状況チェックで削除'));
          return false;
        } else if (resultData[0].billings2_flg > 0) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2077'].replace('%arg1%', '旧取引先コード、または、新取引先コード').replace('%arg2%', '請求前処理２').replace('%arg3%', '請求前処理２の範囲変更、または、請求マスタ状況チェック２で削除'));
          return false;
        }
      } else {
        // 取引先区分が「2：仕入先」の場合、支払マスタも確認
        // SELECT句
        let selectSql = 'SELECT';
        selectSql += ' (SELECT COUNT(*) FROM m_payments WHERE client_id IN (' + this.client.client_id + ',' + this.newClientId + ') LIMIT 1) AS payments_flg';
        // FROM句
        selectSql += ' FROM ';
        selectSql += 'DUAL ';
        //console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        if (resultData[0].payments_flg > 0) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2077'].replace('%arg1%', '旧取引先コード、または、新取引先コード').replace('%arg2%', '支払前処理').replace('%arg3%', '支払前処理の範囲変更、または、支払マスタ状況チェックで削除'));
          return false;
        }
      }

      return true;
    },
    /**
     * 取引先コード切替が実行中かどうかを確認します。
     * 終了している場合は取引先コード切替保守画面へ遷移します。
     */
    async checkRunningState() {
      const functionName = 'checkRunningState';

      // 取引先コード切替が実行中かどうかを確認します。
      let listMControlResult = null;
      try {
        listMControlResult = await API.graphql(graphqlOperation(list_m_control));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: listMControlResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.data.list_m_control.length === 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'コントロールマスタが空です。');
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      const m_control = listMControlResult.data.list_m_control[0];
      let message = '';
      switch (m_control.switch_client_id_state) {
      case Const.ClientCodeSwitchStateClass.NOT_RUNNING:
        message = '正常に終了しました。';
        break;
      case Const.ClientCodeSwitchStateClass.RUNNING:
        message = '実行中です。';
        break;
      case Const.ClientCodeSwitchStateClass.ERROR_STOP:
        message = 'エラー終了しました。';
        break;
      }
      await this.$bvModal.msgBoxOk(message, {
        title: '取引先コード切替の状況確認',
        okTitle: 'はい'
      });
      if (m_control.switch_client_id_state !== Const.ClientCodeSwitchStateClass.RUNNING) {
        this.$router.push({ name: 'CLIENT-CODE-SWITCH-MAINTENANCE' });
      }
    }
  }
}
</script>