<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 現場保守編集</strong></h5>
                <router-link to="/site-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                 <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                   <li>{{ message }}</li>
                 </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <div class="row mt-2">
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                      <validation-provider rules="required|numeric|max:6|between:1,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientId" class="form-control" v-model="clientId" readonly>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 form-group">
                      <label for="clientName" class="form-label"><strong>取引先名</strong></label>
                      <input type="text" id="clientName" class="form-control" v-model="clientName" readonly>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場コード -->
                    <div class="col form-group">
                      <label for="siteId" class="form-label"><strong>現場コード</strong></label>
                      <validation-provider rules="required|numeric|max:6|between:1,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="siteId" name="siteId" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="siteId" readonly>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 配送コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      <label for="shippingCode" class="form-label"><strong>配送コード</strong></label>
                      <validation-provider rules="max:5" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="shippingCode" class="form-control" v-model="shippingCode" readonly>
                            <!-- 現場保守検索ボタン -->
                            <b-input-group-text @click="showRouteSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                <span class="oi oi-magnifying-glass"></span>
                                <button type="button" v-shortkey="['alt', '1']" @shortkey="showRouteSearchModal()" class="d-none"></button>
                              </b-button>
                            </b-input-group-text>
                          </div>
                          <small class="form-text text-muted">任意項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 配送名 -->
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 form-group">
                      <label for="shippingName" class="form-label"><strong>配送名</strong></label>
                      <input type="text" id="shippingName" class="form-control" v-model="shippingName" readonly>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場名（漢字） -->
                    <div class="col form-group">
                      <label for="clientSiteNameKanji" class="form-label"><strong>現場名（漢字）</strong></label>
                      <validation-provider rules="max:50" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteNameKanji" class="form-control" v-model="clientSiteNameKanji" maxlength="50">
                          <small class="form-text text-muted">任意項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場名（カナ） -->
                    <div class="col form-group">
                      <label for="clientSiteNameKana" class="form-label"><strong>現場名（カナ）</strong></label>
                      <validation-provider rules="required|max:50" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteNameKana" class="form-control" v-model="clientSiteNameKana" maxlength="50">
                          <small class="form-text text-muted">必須項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場郵便番号 -->
                    <div class="col form-group">
                      <label for="clientSiteZipCode" class="form-label"><strong>現場郵便番号</strong></label>
                      <validation-provider rules="zip_code" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteZipCode" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSiteZipCode" maxlength="8">
                          <small class="form-text text-muted">任意項目です。「nnn-nnnn」形式で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場住所１ -->
                    <div class="col form-group">
                      <label for="clientSiteAddress1" class="form-label"><strong>現場住所１</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteAddress1" class="form-control" v-model="clientSiteAddress1" maxlength="34">
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場住所２ -->
                    <div class="col form-group">
                      <label for="clientSiteAddress2" class="form-label"><strong>現場住所２</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteAddress2" class="form-control" v-model="clientSiteAddress2" maxlength="34">
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 納品場所 -->
                    <div class="col form-group">
                      <label for="deliveryLocation" class="form-label"><strong>納品場所</strong></label>
                      <validation-provider rules="max:54" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="deliveryLocation" class="form-control" v-model="deliveryLocation" maxlength="54">
                          <small class="form-text text-muted">任意項目です。54文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- TEL -->
                    <div class="col form-group">
                      <label for="clientSitePhoneNumber" class="form-label"><strong>TEL</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSitePhoneNumber" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSitePhoneNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 発注書印字区分 -->
                    <div class="col form-group col-12 col-lg-3">
                      <label for="orderPrintClass" class="form-label"><strong>発注書印字区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select class="form-control" id="orderPrintClass" v-model="orderPrintClass" :options="orderPrintClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 発注書用取引先名 -->
                    <div class="col form-group col-12 col-lg-9">
                      <label for="orderPrintClientName" class="form-label"><strong>発注書用取引先名（※仮伝宛名）</strong></label>
                      <validation-provider :rules="{max: 32}" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="orderPrintClientName" class="form-control" v-model="orderPrintClientName" maxlength="32">
                          <small class="form-text text-muted">任意項目です。32文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 請求書印字区分 -->
                    <div class="col form-group col-12 col-lg-3">
                      <label for="billingPrintClass" class="form-label"><strong>請求書印字区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select class="form-control" id="billingPrintClass" v-model="billingPrintClass" :options="billingPrintClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 請求書用取引先名 -->
                    <div class="col form-group col-12 col-lg-9">
                      <label for="billingPrintClientName" class="form-label"><strong>請求書用取引先名</strong></label>
                      <validation-provider :rules="{max: 50}" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="billingPrintClientName" class="form-control" v-model="billingPrintClientName" maxlength="50">
                          <small class="form-text text-muted">任意項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- アストコード -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="astId" class="form-label"><strong>アストコード</strong></label>
                      <validation-provider rules="alpha_num|max:5" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="astId" class="form-control" v-model="astId" maxlength="5">
                          <small class="form-text text-muted">任意項目です。英数字5文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 相手先現場コード -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="clientControlSiteId" class="form-label"><strong>相手先現場コード</strong></label>
                      <validation-provider rules="alpha_num_spaces|max:10" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientControlSiteId" class="form-control" v-model="clientControlSiteId" maxlength="10">
                          <small class="form-text text-muted">任意項目です。英数字、スペース10文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 所管部支店 -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="departmentBranch" class="form-label"><strong>所管部支店</strong></label>
                      <validation-provider rules="max:6" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="departmentBranch" class="form-control" v-model="departmentBranch" maxlength="6">
                          <small class="form-text text-muted">任意項目です。6文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●ルートマスタ検索モーダル●●● -->
    <RouteSearch @from-child="closeRouteSearchModal"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { addOperationLogs, escapeQuote, init, getNullStr, CreateColRow, CreateUpdateSql, isSystemEditable } from '@/assets/js/common.js';
import { executeTransactSql } from '@/graphql/mutations';
import Const from '@/assets/js/const.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_clients_sites, list_m_clients, list_m_routes } from '@/graphql/queries';
import RouteSearch from '@/components/modal/route-search.vue';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'site-edit';

export default {
  name: 'SITE-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    RouteSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場保守修正',
      orderPrintClassList: Const.OrderPrintClassList,
      billingPrintClassList: Const.BillingPrintClassList,
      // 取引先コード
      clientId: '',
      // 取引先名
      clientName: '',
      // 配送コード
      shippingCode: '',
      // 配送名
      shippingName: '',
      // 現場コード
      siteId: '',
      // 現場名（漢字）
      clientSiteNameKanji: '',
      // 現場名（カナ）
      clientSiteNameKana: '',
      // 現場郵便番号
      clientSiteZipCode: '',
      // 現場住所１
      clientSiteAddress1: '',
      // 現場住所２
      clientSiteAddress2: '',
      // 納品場所
      deliveryLocation: '',
      // TEL
      clientSitePhoneNumber: '',
      // 発注書印字区分
      orderPrintClass: '',
      // 発注書用取引先名
      orderPrintClientName: '',
      // 請求書印字区分
      billingPrintClass: '',
      // 請求書用取引先名
      billingPrintClientName: '',
      // アストコード
      astId: '',
      // 相手先現場コード
      clientControlSiteId: '',
      // 所管部支店
      departmentBranch: '',
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 処理結果成功メッセージ
      successMessages: [],
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    // 新規画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query.clientId, this.$route.query.siteId);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} clientId - 取引先コード
     * @param {String} siteId - 現場コード
     */
    async fetchData(clientId, siteId) {
      this.errorMessages = [];

      // 現場マスタ検索
      const site = await this.searchClientsSites(clientId, siteId);
      if (site === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }

      // 取引先マスタ検索
      const client = await this.searchClients(clientId);

      // ルートマスタ検索
      let route = null;
      if (site.shipping_code !== '') {
        route = await this.searchRoutes(site.shipping_code);
      }

      // 取引先ID
      this.clientId = clientId;
      // 取引先名
      this.clientName = (client === null) ? '取引先マスタに該当なし' : client.client_name_kanji;
      // 配送コード
      this.shippingCode = site.shipping_code;
      // 配送名（移行前データは、' 'の場合あり）
      if (getNullStr(this.shippingCode).trim() !== '') {
        this.shippingName = (route === null) ? 'ルートマスタに該当なし' : route.shipping_name;
      }
      // 現場コード
      this.siteId = siteId;
      // 現場名（漢字）
      this.clientSiteNameKanji = site.client_site_name_kanji;
      // 現場名（カナ）
      this.clientSiteNameKana = site.client_site_name_kana;
      // 現場郵便番号
      this.clientSiteZipCode = site.client_site_zip_code;
      // 現場住所１
      this.clientSiteAddress1 = site.client_site_address_1;
      // 現場住所２
      this.clientSiteAddress2 = site.client_site_address_2;
      // 納品場所
      this.deliveryLocation = site.delivery_location;
      // TEL
      this.clientSitePhoneNumber = site.client_site_phone_number;
      // 発注書印字区分
      this.orderPrintClass = site.order_print_class;
      // 発注書用取引先名
      this.orderPrintClientName = site.order_print_client_name;
      // 請求書印字区分
      this.billingPrintClass = site.billings_print_class;
      // 請求書用取引先名
      this.billingPrintClientName = site.billings_print_client_name;
      // アストコード
      this.astId = site.ast_id;
      // 相手先現場コード
      this.clientControlSiteId = site.client_control_site_id;
      // 所管部支店
      this.departmentBranch = site.department_branch;
    },
    /**
     * 現場マスタを検索します。
     * @param {String} clientId - 取引先コード
     * @param {String} siteId - 現場コード
     * @returns {Object} 現場マスタデータ
     */
    async searchClientsSites(clientId, siteId) {
      const functionName = 'searchClientsSites';

      let result = null;
      const whereClause = `AND client_id = ${clientId} AND site_id = ${siteId}`;
      try {
        result = await API.graphql(graphqlOperation(list_m_clients_sites, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_clients_sites === 0) ? null : result.data.list_m_clients_sites[0];
    },
    /**
     * 取引先マスタを検索します。
     * @param {String} clientId - 取引先コード
     * @returns {Object} 取引先マスタデータ
     */
    async searchClients(clientId) {
      const functionName = 'searchClients';

      let result = null;
      const whereClause = `AND client_id = ${clientId} AND client_class = 1 ORDER BY client_id ASC`;
      try {
        result = await API.graphql(graphqlOperation(list_m_clients, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_clients.length === 0) ? null : result.data.list_m_clients[0];
    },
    /**
     * ルートマスタを検索します。
     * @param {String} shipping_code - 配送コード
     * @returns {Object} ルートマスタデータ
     */
    async searchRoutes(shipping_code) {
      const functionName = 'searchRoutes';

      const whereClause = `AND shipping_code = '${await escapeQuote(shipping_code)}' ORDER BY updated DESC`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_routes, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_routes',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_routes',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_routes.length === 0) ? null : result.data.list_m_routes[0];
    },
    /**
     * 配送コード検索モーダル表示
     */
    showRouteSearchModal() {
      this.$bvModal.show('routeSearchModal');
    },
    /**
     * 配送コード検索モーダルコールバック
     * @param {Object} choiceData - 選択された項目
     */
    closeRouteSearchModal(choiceData) {
      this.shippingCode = choiceData.shippingCode;
      this.shippingName = choiceData.shippingName;
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({
              name: 'SITE-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      const colList = [];
      // 配送コード
      colList.push(CreateColRow('shipping_code', await escapeQuote(this.shippingCode), 'VARCHAR'));
      // 現場名（漢字）
      if (getNullStr(this.clientSiteNameKanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('client_site_name_kanji', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('client_site_name_kanji', await escapeQuote(this.clientSiteNameKanji), 'VARCHAR'));
      }
      // 現場名（カナ）
      colList.push(CreateColRow('client_site_name_kana', await escapeQuote(this.clientSiteNameKana), 'VARCHAR'));
      // 現場郵便番号
      colList.push(CreateColRow('client_site_zip_code', this.clientSiteZipCode, 'VARCHAR'));
      // 現場住所１
      colList.push(CreateColRow('client_site_address_1', await escapeQuote(this.clientSiteAddress1), 'VARCHAR'));
      // 現場住所２
      colList.push(CreateColRow('client_site_address_2', await escapeQuote(this.clientSiteAddress2), 'VARCHAR'));
      // 納品場所
      colList.push(CreateColRow('delivery_location', await escapeQuote(this.deliveryLocation), 'VARCHAR'));
      // TEL
      colList.push(CreateColRow('client_site_phone_number', this.clientSitePhoneNumber, 'VARCHAR'));
      // 発注書印字区分
      colList.push(CreateColRow('order_print_class', this.orderPrintClass, 'NUMBER'));
      // 発注書用取引先名
      colList.push(CreateColRow('order_print_client_name', await escapeQuote(this.orderPrintClientName), 'VARCHAR'));
      // 請求書印字区分
      colList.push(CreateColRow('billings_print_class', this.billingPrintClass, 'NUMBER'));  
      // 請求書用取引先名
      colList.push(CreateColRow('billings_print_client_name', await escapeQuote(this.billingPrintClientName), 'VARCHAR'));
      // アストコード
      colList.push(CreateColRow('ast_id', await escapeQuote(this.astId), 'VARCHAR'));
      // 相手先現場コード
      colList.push(CreateColRow('client_control_site_id', await escapeQuote(this.clientControlSiteId), 'VARCHAR'));
      // 所管部支店
      colList.push(CreateColRow('department_branch', await escapeQuote(this.departmentBranch), 'VARCHAR'));
      // 更新ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'))

      const sql = `${CreateUpdateSql(colList, 'm_clients_sites')} WHERE client_id = ${this.clientId} AND site_id = ${this.siteId}`;
      const sqls = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    }
  }
}
</script>