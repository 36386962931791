<template>
  <!-- 担当者実績保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 担当者実績保守編集</strong></h5>
                <router-link to="/staffs-results-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 担当者コード -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="staffId" class="form-label"><strong>担当者コード: {{staffResultsObj.staff_id}}</strong></label>
                    <!-- <input type="text" id="staffId" class="form-control" v-model="staffResultsObj.staff_id" readonly> -->
                  </div>
                  <!-- 担当者名 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="staff_name_kanji" class="form-label"><strong>担当者名: {{staffResultsObj.staff_name_kanji}}</strong></label>
                    <!-- <input type="text" id="staff_name_kanji" class="form-control" v-model="staffResultsObj.staff_name_kanji" readonly> -->
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 売上額 -->
                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="sales" class="form-label"><strong>売上額</strong></label>
                        <input type="text" id="sales" class="form-control" v-model="staffResultsObj.sales" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上返品額 -->
                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="returned" class="form-label"><strong>売上返品額</strong></label>
                        <input type="text" id="returned" class="form-control" v-model="staffResultsObj.returned" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上値引額 -->
                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="discount" class="form-label"><strong>売上値引額</strong></label>
                        <input type="text" id="discount" class="form-control" v-model="staffResultsObj.discount" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 純売上額 -->
                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="net_sales" class="form-label"><strong>純売上額</strong></label>
                        <input type="text" id="net_sales" class="form-control" v-model="staffResultsObj.net_sales" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 粗利額 -->
                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="gross_profit" class="form-label"><strong>粗利額</strong></label>
                        <input type="text" id="gross_profit" class="form-control" v-model="staffResultsObj.gross_profit" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <!-- 翌月・当期テーブル -->
                <div class="row my-4">
                  <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-6 form-group">
                    <b-table
                      show-empty
                      :small="true"
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="staffResultsObj.nextCurrentList"
                      :fields="fields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 翌月 -->
                      <template #cell(next_month)="data">
                        <input type="text" id="next_month" class="form-control" v-model="data.item.next_month" readonly>
                      </template>
                      <!-- 当期 -->
                      <template #cell(current_term)="data">
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" id="current_term" class="form-control" v-model="data.item.current_term" maxlength="12">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 純売上高1-12 -->
                  <div v-for="(item, index) in staffResultsObj.net_sales_list" :key="index" class="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label :for="index" class="form-label"><strong>純売上高{{index+1}}({{item.title}})</strong></label>
                        <input type="text" :id="index" class="form-control" v-model="staffResultsObj.net_sales_list[index].value" maxlength="12">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
              <!-- 保存ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                   <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CONFIRM from '@/components/modal/confirm.vue';
import { addOperationLogs, init, CreateColRow, CreateUpdateSql, getUserCol, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import moment from 'moment';

// ログ出力用モジュール名
const MODULE_NAME = 'staffs-results-edit';

export default {
  name: 'STAFFS-RESULTS-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '担当者実績保守編集',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 担当者実績対象
      staffResultsObj: {},
      // 担当者実績テーブル
      fields: DataTblDef.staffs_results_edit_list_fields,
      // 当月月初
      billingDateStart: moment().startOf('month').format('YYYY-MM-DD'),
      // 当月月末
      billingDateEnd: moment().endOf('month').format('YYYY-MM-DD'),
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} staff_id - 担当者コード
     */
    async fetchData({staff_id}) {
      this.busy = true;
      // 検索SQL
      staff_id = Number(staff_id)
      // 担当者実績のデータを請求
      var [staffResults, nextMonthInfo ] = await Promise.all([
        this.searchStaffResults(staff_id),
        this.searchNextMonthInfo(staff_id)
      ])
      // 翌月oo最新データをセット
      staffResults.next_month_sales = Number(nextMonthInfo.nextMonthSales).toLocaleString()
      staffResults.next_month_returned = Number(nextMonthInfo.nextMonthReturned).toLocaleString()
      staffResults.next_month_discount = Number(nextMonthInfo.nextMonthDiscount).toLocaleString()
      staffResults.next_month_net_sales = Number(nextMonthInfo.nextMonthNetSales).toLocaleString()
      staffResults.next_month_gross_profit = Number(nextMonthInfo.nextMonthGrossProfit).toLocaleString()
      // 翌月と当期データを整形
      staffResults.nextCurrentList = [
        { 
          key: 'sales',
          item: '売上額',
          next_month: staffResults.next_month_sales,
          current_term: staffResults.current_term_sales,
        },
        { 
          key: 'returned',
          item: '売上返品額',
          next_month: staffResults.next_month_returned,
          current_term: staffResults.current_term_returned,
        },
        { 
          key: 'discount',
          item: '値引額',
          next_month: staffResults.next_month_discount,
          current_term: staffResults.current_term_discount,
        },
        { 
          key: 'net_sales',
          item: '純売上額',
          next_month: staffResults.next_month_net_sales,
          current_term: staffResults.current_term_net_sales,
        },
        { 
          key: 'gross_profit',
          item: '粗利額',
          next_month: staffResults.next_month_gross_profit,
          current_term: staffResults.current_term_gross_profit,
        },
      ]
      // 純売上高1-12リスト
      staffResults.net_sales_list = [
        {
          title: '5月',
          value: staffResults.net_sales_1,
        },
        {
          title: '6月',
          value: staffResults.net_sales_2,
        },
        {
          title: '7月',
          value: staffResults.net_sales_3,
        },
        {
          title: '8月',
          value: staffResults.net_sales_4,
        },
        {
          title: '9月',
          value: staffResults.net_sales_5,
        },
        {
          title: '10月',
          value: staffResults.net_sales_6,
        },
        {
          title: '11月',
          value: staffResults.net_sales_7,
        },
        {
          title: '12月',
          value: staffResults.net_sales_8,
        },
        {
          title: '1月',
          value: staffResults.net_sales_9,
        },
        {
          title: '2月',
          value: staffResults.net_sales_10,
        },
        {
          title: '3月',
          value: staffResults.net_sales_11,
        },
        {
          title: '4月',
          value: staffResults.net_sales_12,
        },
      ]
     
      // データをセット
      this.staffResultsObj = {...staffResults}
      this.busy = false;
    },
    /**
     * 担当者実績マスタ検索
     * @param {Int} staff_id - 担当者コード
     * @returns {<Object>} 検索結果
     */
    async searchStaffResults(staff_id) {
      const functionName = 'searchStaffResults';
      let result = null;
      // 担当者実績と担当者の結合
      let SQLs = `SELECT  * FROM t_staffs_results TSR LEFT JOIN m_staffs MS USING(staff_id) 
      where staff_id = ${staff_id} and MS.is_deleted = 0`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      // if (body.data[0][0] === undefined) {
      //   this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      // } else {
      return body.data[0][0]
      // }
    },
    /**
     * 累積トランザクション検索
     * @param {Int} staff_id - 担当者コード
     * @returns {<Object>} 検索結果
     */
    async searchNextMonthInfo(staff_id) {
      var [nextMonthSales, nextMonthReturned, nextMonthDiscount, nextMonthGrossProfit] = await Promise.all([
        this.searchNextMonthSales(staff_id),
        this.nextMonthReturned(staff_id),
        this.nextMonthDiscount(staff_id),
        this.nextMonthGrossProfit(staff_id),
      ])
      // 翌月純売上額
      const nextMonthNetSales = Number(nextMonthSales) - Number(nextMonthDiscount)

      return {
        // 翌月売上額
        nextMonthSales,
        // 翌月売上返品額
        nextMonthReturned,
        // 翌月値引額
        nextMonthDiscount,
        // 翌月純売上額
        nextMonthNetSales,
        // 翌月粗利額
        nextMonthGrossProfit,
      }
    },
    /**
     * 累積トランザクション検索
     * @param {Int} staff_id - 担当者コード
     * @returns {Int} 翌月売上額
     */
    async searchNextMonthSales(staff_id) {
      const functionName = 'searchNextMonthSales';
      let result = null;

      let SQLs = `SELECT SUM(product_sales_unit_amount) SUM FROM t_cumulative_transaction 
      WHERE staff_id = ${staff_id} 
      AND transaction_id = 'UR' 
      AND billing_date BETWEEN '${this.billingDateStart}' AND '${this.billingDateEnd}'
      AND (bill_class = 11 OR bill_class = 14)`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0].SUM || 0
    },
    /**
     * 累積トランザクション検索
     * @param {Int} staff_id - 担当者コード
     * @returns {Int} 翌月売上返品額
     */
    async nextMonthReturned(staff_id) {
      const functionName = 'nextMonthReturned';
      let result = null;

      let SQLs = `SELECT SUM(product_sales_unit_amount) SUM FROM t_cumulative_transaction 
      WHERE staff_id = ${staff_id} 
      AND transaction_id = 'UR' 
      AND billing_date BETWEEN '${this.billingDateStart}' AND '${this.billingDateEnd}'
      AND bill_class = 12`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0].SUM || 0
    },
    /**
     * 累積トランザクション検索
     * @param {Int} staff_id - 担当者コード
     * @returns {Int} 翌月値引額
     */
    async nextMonthDiscount(staff_id) {
      const functionName = 'nextMonthDiscount';
      let result = null;

      let SQLs = `SELECT SUM(amount) SUM FROM t_cumulative_transaction 
      WHERE staff_id = ${staff_id} 
      AND transaction_id = 'NK' 
      AND billing_date BETWEEN '${this.billingDateStart}' AND '${this.billingDateEnd}'
      AND kind = 'A'`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0].SUM || 0
    },
    /**
     * 累積トランザクション検索
     * @param {Int} staff_id - 担当者コード
     * @returns {Int} 翌月粗利額
     */
    async nextMonthGrossProfit(staff_id) {
      const functionName = 'nextMonthGrossProfit';
      let result = null;

      let SQLs = `SELECT SUM(product_gross_profit) SUM FROM t_cumulative_transaction 
      WHERE staff_id = ${staff_id} 
      AND transaction_id = 'UR' 
      AND billing_date BETWEEN '${this.billingDateStart}' AND '${this.billingDateEnd}'`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0].SUM || 0
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'STAFFS-RESULTS-INQUIRY', 
              query: { staff_id: this.staffResultsObj.staff_id },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      // 構成マスタを更新か新規
      await this.execUpdateStaffResults()
    },
    /**
     * 更新処理
     */
    async execUpdateStaffResults() {
      const functionName = 'execUpdateAndInsertStaffResults';
      const colList = [];
      // 売上額
      colList.push(CreateColRow('sales', Number(this.staffResultsObj.sales), 'INT'));
      // 売上返品額
      colList.push(CreateColRow('returned', Number(this.staffResultsObj.returned), 'INT'));
      // 売上値引額
      colList.push(CreateColRow('discount', Number(this.staffResultsObj.discount), 'INT'));
      // 純売上額
      colList.push(CreateColRow('net_sales', Number(this.staffResultsObj.net_sales), 'INT'));
      // 粗利額
      colList.push(CreateColRow('gross_profit', Number(this.staffResultsObj.gross_profit), 'INT'));
      // 翌月・当期テーブル各項目
      this.staffResultsObj.nextCurrentList.forEach(element => {
        const keyCurrentTerm = `current_term_${element.key}`
        colList.push(CreateColRow(keyCurrentTerm, Number(element.current_term), 'INT'));
      });
      for (let index = 0; index < 12; index++) {
        //  純売上高
        const value = Number(this.staffResultsObj.net_sales_list[index].value)
        colList.push(CreateColRow(`net_sales_${index+1}`, value, 'INT'));
      }
      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 't_staffs_results')} WHERE staff_id = ${this.staffResultsObj.staff_id}`;
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>