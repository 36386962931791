<template>
  <!-- 請求残高保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 請求残高保守照会</strong></h5>
                <router-link to="/billings-balances-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <!-- 請求年月 -->
              <div class="row col-12">
                <h5>
                  <strong>
                  請求年月: &nbsp;
                    {{billingsBalancesObj.billing_month_year | month_year}}
                  </strong>
                </h5>
              </div>
              <!-- 得意先コード -->
              <div class="row col-12">
                <h5>
                  <strong>
                  得意先コード: &nbsp;
                    {{billingsBalancesObj.client_id}}&nbsp;&nbsp;&nbsp;&nbsp; 
                    {{billingsBalancesObj.client_name_kanji}}
                  </strong>
                </h5>
              </div>
              <!-- 現場コード -->
              <div class="row col-12 mb-2">
                <h5>
                  <strong>
                  現場コード: &nbsp;{{billingsBalancesObj.site_id}}
                  </strong>
                </h5>
              </div>
              <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-8">
                <b-tbody>
                  <b-tr>
                    <b-th>前回請求税抜残高</b-th>
                    <b-td>
                      {{billingsBalancesObj.pre_billing_no_tax_balance}}
                    </b-td>
                    <b-th>2回前請求税抜額</b-th>
                    <b-td>
                      {{billingsBalancesObj.two_pre_billing_money_no_tax}}
                    </b-td>
                    <b-th>3回前請求税抜額</b-th>
                    <b-td>
                      {{billingsBalancesObj.three_pre_billing_money_no_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>前回請求消費税残高</b-th>
                    <b-td>
                      {{billingsBalancesObj.pre_billing_tax_balance}}
                    </b-td>
                    <b-th>2回前請求消費税額</b-th>
                    <b-td>
                      {{billingsBalancesObj.two_pre_billing_money_tax}}
                    </b-td>
                    <b-th>3回前請求消費税額</b-th>
                    <b-td>
                      {{billingsBalancesObj.three_pre_billing_money_tax}}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
                <b-tbody>
                  <b-tr>
                    <b-th>締日売掛売上</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_receivable_sales}}
                    </b-td>
                    <b-th>請求締切日</b-th>
                    <b-td>
                        {{billingsBalancesObj.billing_end_date}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日伝票枚数</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_sales_billing_cnt}}
                    </b-td>
                    <b-th>入金予定日</b-th>
                    <b-td>
                       {{billingsBalancesObj.payment_scheduled}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日入金額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_payment}}
                    </b-td>
                    <b-th>締日消費税入金</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_tax_payment}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日一括消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_bulk_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日伝票消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_billing_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日通常消費税課税小計</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_normal_tax_subtotal}}
                    </b-td>
                    <b-th>締日通常消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_normal_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日軽減消費税課税小計</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_light_tax_subtotal}}
                    </b-td>
                    <b-th>締日軽減消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_light_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日売掛売上_先行除外</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_receivable_sales_pre_exclude}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日通常消費税課税小計_先行除外</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_normal_tax_subtotal_pre_exclude}}
                    </b-td>
                    <b-th>締日通常消費税額_先行除外</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_normal_tax_pre_exclude}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日軽減消費税課税小計_先行除外</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_light_tax_subtotal_pre_exclude}}
                    </b-td>
                    <b-th>締日軽減消費税額_先行除外</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_light_tax_pre_exclude}}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
                <b-tbody>
                  <b-tr>
                    <b-th>締日繰越税抜額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_forward_no_tax}}
                    </b-td>
                    <b-th>締日繰越消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_forward_tax}}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>締日請求税抜額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_billing_money_no_tax}}
                    </b-td>
                    <b-th>締日請求消費税額</b-th>
                    <b-td>
                        {{billingsBalancesObj.closing_date_billing_money_tax}}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn(billingsBalancesObj)"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'billings-balances-inquiry';

export default {
  name: 'BILLINGS-BALANCES-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '請求残高保守照会',
      // 請求残高対象
      billingsBalancesObj: {},
      // ユーザ名
      username: this.$store.getters.user.username,
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 請求年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} billing_month_year - 請求年月
     * @param {String} client_id - 得意先コード
     * @param {String} site_id - 現場コード
     */
    async fetchData({billing_month_year, client_id, site_id}) {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        billing_month_year = Number(billing_month_year)
        client_id = Number(client_id)
        site_id = Number(site_id)
        // 請求残高マスタのデータを請求
        let billingsBalances = await this.searchBillingsBalances(billing_month_year, client_id, site_id)

        // 値を「,」で分ける
        Object.keys(billingsBalances).forEach(key => {
          if (key != 'client_id' && key != 'client_name_kanji' && key != 'billing_month_year' && key != 'site_id' && key != 'billing_end_date' && key != 'payment_scheduled') {
            billingsBalances[key] = billingsBalances[key].toLocaleString();
          }
        });

        // 請求残高データをセット
        this.billingsBalancesObj = {...billingsBalances}
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.successMessages.length > 0 || this.errorMessages.length > 0) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /**
     * 請求残高マスタを検索します。
     * @param {Int} month_year - 処理年月
     * @param {Int} client_id -  得意先コード
     * @param {Int} site_id -  現場コード
     * @returns {Object} 請求残高マスタデータ
     */
    async searchBillingsBalances(month_year, client_id, site_id ) {
      const functionName = 'searchBillingsBalances';

      let result = null;
      let sqls = `SELECT 
                  MC.client_id,
                  MC.client_name_kanji,
                  TBB.billing_month_year,
                  TBB.site_id,
                  TBB.billing_end_date,
                  TBB.pre_billing_no_tax_balance,
                  TBB.pre_billing_tax_balance,
                  TBB.closing_date_receivable_sales,
                  TBB.closing_date_sales_billing_cnt,
                  TBB.closing_date_bulk_tax,
                  TBB.closing_date_billing_tax,
                  TBB.closing_date_tax,
                  TBB.closing_date_payment,
                  TBB.closing_date_tax_payment,
                  TBB.closing_date_forward_no_tax,
                  TBB.closing_date_forward_tax,
                  TBB.closing_date_billing_money_no_tax,
                  TBB.closing_date_billing_money_tax,
                  TBB.two_pre_billing_money_no_tax,
                  TBB.two_pre_billing_money_tax,
                  TBB.three_pre_billing_money_no_tax,
                  TBB.three_pre_billing_money_tax,
                  TBB.payment_scheduled,
                  TBB.closing_date_normal_tax_subtotal,
                  TBB.closing_date_normal_tax,
                  TBB.closing_date_light_tax_subtotal,
                  TBB.closing_date_light_tax,
                  TBB.closing_date_receivable_sales_pre_exclude,
                  TBB.closing_date_normal_tax_subtotal_pre_exclude,
                  TBB.closing_date_normal_tax_pre_exclude,
                  TBB.closing_date_light_tax_subtotal_pre_exclude,
                  TBB.closing_date_light_tax_pre_exclude
                  FROM t_billings_balances TBB LEFT JOIN m_clients MC USING(client_id) 
                  WHERE billing_month_year = ${month_year} AND client_id = ${client_id} AND site_id = ${site_id} AND MC.client_class = 1
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sqls] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },
    /**
     * 編集ボタン押下時
     * @param {Int} billing_month_year - 請求年月
     * @param {Int} client_id - 得意先コード
     * @param {Int} site_id - 現場コード
     */
    clickEditBtn({billing_month_year, client_id, site_id}) {
      this.$router.push({ name: 'BILLINGS-BALANCES-EDIT',
        query: { 
          billing_month_year: billing_month_year, 
          client_id: client_id,
          site_id: site_id,
        }});
    },
  }
}
</script>
