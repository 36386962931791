<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-2 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col>
        <b-media>
          <b-media-body class="pb-2">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 見積照会</strong></h5>
              <b-button-toolbar>
                <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'見積入力に遷移する'" @click="clickInputBtn" class="m-0">
                  <span class="oi oi-plus"></span> 新規登録(F10)
                  <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                </b-button>&nbsp;&nbsp;
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'見積・発注一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </b-button-toolbar>
            </div>
          </b-media-body>
        </b-media>
        <div class="main-card card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <b-form id="InputForm" class="form-horizontal">
              <b-row>
                <!-- 見積番号 -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="estimateId">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>見積番号 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="estimateId" type="text" v-model="estimateId" readonly />
                  </b-input-group>
                </div>
                <!-- 見積日付 -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="dateEstimateDate">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>見積日付 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="dateEstimateDate" type="text" v-model="dateEstimateDate" readonly />
                  </b-input-group>
                </div>
                <!-- 取引先コード -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="suppliersCode">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>取引先コード </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input type="text" id="suppliersCode" v-model="suppliers.code" readonly />
                    <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが0以外の場合、利用可能です。）" :disabled="suppliers.code == 0" @click="clientInfoModal(suppliers.class, suppliers.code)">
                      <span class="oi oi-eye"></span> 情報
                    </b-button>
                  </b-input-group>
                </div>
                <!-- 営業所 -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="selectSalesOffice">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>営業所 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row class="mt-2">
                <!-- 取引先名 -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <label for="suppliersName"><strong>取引先名</strong><b-form-text class="text-muted d-inline-flex">(見積登録時点の取引先名です)</b-form-text></label>
                  <b-form-input type="text" id="suppliersName" v-model="suppliers.name" readonly />
                </div>
                <!-- 取引先印刷用宛名 -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <label for="clientPrintName"><strong>取引先印刷用宛名</strong><b-form-text class="text-muted d-inline-flex">(帳票の「宛先」の部分に出力されます)</b-form-text></label>
                  <b-form-input type="text" id="clientPrintName" v-model="suppliers.printName" readonly />
                </div>
              </b-row>
              <b-row class="mt-2">
                <!-- 見積種類 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="checkEstimateKind" v-b-tooltip.hover.noninteractive title="帳票の「単価御見積」の部分に出力される内容です(1:売上合計+消費税、2:文言「単価御見積」)">
                    <strong>見積種類</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢単価御見積｣...)</b-form-text>
                  </label>
                  <b-form-input id="checkEstimateKind" type="text" v-model="textEstimateKind" readonly />
                </div>
                <!-- 消費税区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="checkTaxType" v-b-tooltip.hover.noninteractive title="消費税を金額とは別に設定するかを設定します（1:売上合計に応じた消費税を算出、2:消費税は0）">
                    <strong>消費税区分</strong>
                    <b-form-text class="text-muted d-inline-flex">(消費税を金額...)</b-form-text>
                  </label>
                  <b-form-input id="checkTaxType" type="text" v-model="textTaxType" readonly />
                </div>
                <!-- 取引先担当者コード -->
                <!-- 取引先担当者名 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="clientStaffName" v-b-tooltip.hover.noninteractive title="帳票の「担当」の部分に出力されます">
                    <strong>取引先担当者</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢担当｣の部...)</b-form-text>
                  </label>
                  <b-form-input id="clientStaffName" type="text" :value="clientStaff.id + ': ' + clientStaff.name" readonly />
                </div>
                <!-- 作成担当者コード -->
                <!-- 作成担当者名 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="createdStaffName" v-b-tooltip.hover.noninteractive title="取引先担当者と異なる場合、帳票の「担当」の右側に括弧括りで出力されます">
                    <strong>作成先担当者</strong>
                    <b-form-text class="text-muted d-inline-flex">(取引先担当者と...)</b-form-text>
                  </label>
                  <b-form-input id="createdStaffName" type="text" :value="createdStaff.id + ': ' + createdStaff.name" readonly />
                </div>
              </b-row>
              <b-row class="mt-2">
                <!-- 単価登録区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="checkUnitPriceRegisterClass" v-b-tooltip.hover.noninteractive title="見積作成時に選択された取引先に単価登録を行うかの設定です（3:親取引先で設定した親子取引先にも単価登録を行う）">
                    <strong>単価登録区分</strong>
                    <b-form-text class="text-muted d-inline-flex">(見積作成時に...)</b-form-text>
                  </label>
                  <b-form-input id="checkUnitPriceRegisterClass" type="text" v-model="textUnitPriceRegisterClass" readonly />
                </div>
                <!-- 単価 適用日／備考 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <label for="dateUnitPriceEffectiveDate" v-b-tooltip.hover.noninteractive title="登録された単価の適用開始日です">
                    <strong>適用日</strong>
                    <b-form-text class="text-muted d-inline-flex">(登録された単価...)</b-form-text>
                  </label>
                  <b-form-input id="dateUnitPriceEffectiveDate" type="text" v-model="dateUnitPriceEffectiveDate" readonly />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <label for="textUnitPriceNote" v-b-tooltip.hover.noninteractive title="単価登録に関する備考です、帳票には出力されません">
                    <strong>備考</strong>
                    <b-form-text class="text-muted d-inline-flex">(単価登録に関する備考です、帳票には出力されません)</b-form-text>
                  </label>
                  <b-form-input id="textUnitPriceNote" type="text" v-model="textUnitPriceNote" readonly />
                </div>
              </b-row>
              <b-row v-if="(checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll)">
                <!-- 登録区分 -->
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-2">
                  <label for="checkRegisterClass" v-b-tooltip.hover.noninteractive title="製品単価情報の登録に同製品グループも含めるかどうかを設定します（0：同製品グループも含め登録、1：自身のみ登録）">
                    <strong class='text-body'>登録区分</strong>
                    <b-form-text class="text-muted d-inline-flex">(製品単価情報...)</b-form-text>
                  </label>
                  <b-form-input id="checkRegisterClass" type="text" v-model="textRegisterClass" readonly />
                </div>
              </b-row>
              <b-row class="mt-2">
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <!-- 現場コード -->
                  <label for="onSiteCode">
                    <strong>現場コード</strong>
                  </label>
                  <b-form-input id="onSiteCode" type="text" v-model="onSite.code" readonly />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                  <!-- 納期 -->
                  <label for="dateDeliveryDate" v-b-tooltip.hover.noninteractive title="帳票の「受渡期日」の部分に出力されます">
                    <strong>納期</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢受渡期日｣...)</b-form-text>
                  </label>
                  <b-form-input id="dateDeliveryDate" type="text" v-model="dateDeliveryDate" readonly />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <!-- 内容 -->
                  <label for="textContents">
                    <strong>内容</strong>
                    <b-form-text class="text-muted d-inline-flex">(作成した見積の内容です、帳票には出力されません)</b-form-text>
                  </label>
                  <b-form-input id="textContents" type="text" v-model="textContents" readonly />
                </div>
              </b-row>
              <b-row class="mt-2">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <!-- 受渡場所 -->
                  <label for="clientSiteName">
                    <strong>受渡場所</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢受渡場所｣の部分に出力されます)</b-form-text>
                  </label>
                  <b-form-input id="clientSiteName" type="text" v-model="onSite.name" readonly />
                </div>
              </b-row>
              <b-row class="mt-2">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <!-- 支払方法 -->
                  <label for="textPaymentWay">
                    <strong>支払方法</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢支払方法｣の部分の部分に出力されます)</b-form-text>
                  </label>
                  <b-form-input id="textPaymentWay" type="text" v-model="textPaymentWay" readonly />
                  <!-- 見積期限／見積期限（文章） -->
                  <label for="dateEstimatePeriod">
                    <strong>見積期限</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢有効期限｣の部分に｢日付＋文章｣の形式で出力されます)</b-form-text>
                  </label>
                  <b-row>
                    <b-col lg="6">
                      <b-form-input id="dateEstimatePeriod" type="text" v-model="dateEstimatePeriod" readonly />
                    </b-col>
                    <b-col lg="6">
                      <b-form-input name="textEstimatePeriodSentence" type="text" v-model="textEstimatePeriodSentence" readonly />
                    </b-col>
                  </b-row>
                </div>
                <!-- 摘要 -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                  <label for="textSummary">
                    <strong>摘要</strong>
                    <b-form-text class="text-muted d-inline-flex">(帳票の｢摘要｣の部分に出力されます)</b-form-text>
                  </label>
                  <b-form-textarea id="textSummary" rows="3" v-model="textSummary" readonly />
                </div>
              </b-row>
              <b-row>
                <!-- 製品 -->
                <b-col lg="12">
                  <b-row>
                    <b-col lg="12">
                      <b-table
                        :small="true"
                        :items="productList"
                        :fields="fields"
                      >
                        <!-- <template #table-colgroup>
                          <col style="width:110px;"/>
                          <col style="width:250px;"/>
                          <col style="width:200px;"/>
                          <col style="width:100px;"/>
                          <col style="width:50px;"/>
                          <col style="width:130px;"/>
                          <col style="width:130px;"/>
                          <col style="width:100px;"/>
                          <col style="width:40px;"/>
                        </template> -->
                        <template #cell(ProductCode)="data">
                          <b-input-group style="width: 80px;">
                            <b-form-input size="sm" class="px-1" type="text" v-model="data.item.ProductCode" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(ProductName)="data">
                          <b-input-group style="min-width: 340px;">
                            <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                            <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" readonly v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                          </b-input-group>
                        </template>
                        <template #cell(Note)="data">
                          <b-input-group style="min-width: 260px;">
                            <b-form-input size="sm" class="px-1" type="text" name="productNote" v-model="data.item.Note" readonly v-b-tooltip.hover.noninteractive.right="data.item.Note" />
                          </b-input-group>
                        </template>
                        <template #cell(Quantity)="data">
                          <b-input-group style="width: 60px;">
                            <b-form-input size="sm" class="px-1" type="text" v-model="data.item.Quantity" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(Unit)="data">
                          <b-input-group style="width: 50px;">
                            <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(SellingPrice)="data">
                          <b-input-group style="width: 110px;">
                            <b-form-input size="sm" class="px-1" type="text" name="productSellingPrice" v-model="data.item.SellingPrice" readonly />
                            <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                          </b-input-group>
                        </template>
                        <template #cell(SalesUnitPrice)="data">
                            {{ data.item.SalesUnitPrice.toLocaleString() }}
                        </template>
                        <template #cell(PurchasePrime)="data">
                          <b-input-group style="width: 90px;">
                            <b-form-input size="sm" class="px-1" type="text" name="productPurchasePrime" v-model="data.item.PurchasePrime" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(Money)="data">
                          {{ data.item.Money.toLocaleString() }}
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form>
                        <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-btn-toolbar>
                        <!-- 取引先製品単価履歴照会ボタン -->
                        <b-button class="mr-2" pill size="sm" variant="success" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                          <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                        </b-button>
                      </b-btn-toolbar>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="justify-content-md-center">
              <b-col lg="12">
                <b-btn-toolbar class="mt-2">
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の見積のPDF出力を行います。" @click="clickOutputEstimatePdf">
                    <span class="oi oi-document"></span> 見積書PDF出力
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の見積内容の編集を行います。" @click="clickEditBtn">
                    <span class="oi oi-pencil"></span> 編集
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の見積内容の削除を行います。" @click="clearAlert(); clickDeleteBtn();">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の見積をコピーして見積を新規登録します。" @click="clickEstimateCopyBtn">
                    <span class="oi oi-plus"></span> コピー見積
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の見積をコピーして受注を新規登録します。" @click="clearAlert(); clickReceivedOrderCopyBtn();">
                    <span class="oi oi-plus"></span> コピー受注
                  </b-button>
                </b-btn-toolbar>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
  <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['照会中の見積データを削除します。','よろしいですか？']" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, formatDate, getControlMaster, executeTransactSqlList, isSystemEditable, getNullStr, refreshClientAmountClassProductList, checkReceivedOrderCopy, getListValue, getEstimateDeleteUnitPriceSqlList } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_estimate, list_m_products } from '@/graphql/queries';

const MODULE_NAME = 'estimate-inquiry';

export default {
  name: 'ESTIMATE-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTINQUIRY,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '見積照会',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOfficeText: '',
      // 見積日付
      dateEstimateDate: '',
      // 取引先系
      suppliers:{
        class: 0,
        code: 0,
        name: '',
        printName: '',
      },
      // 取引先担当者
      clientStaff:{
        id: 0,
        name: ' ',
      },
      // 作成担当者
      createdStaff:{
        id: 0,
        name: ' ',
      },
      // 見積種類ラジオボタン
      checkEstimateKind: null,
      textEstimateKind: '',
      // 消費税区分ラジオボタン
      checkTaxType: null,
      textTaxType: '',
      // 単価登録区分ラジオボタン
      checkUnitPriceRegisterClass: null,
      textUnitPriceRegisterClass: '',
      // 登録区分ラジオボタン
      checkRegisterClass: null,
      textRegisterClass: '',
      // 単価適用日
      dateUnitPriceEffectiveDate: '',
      // 単価備考
      textUnitPriceNote: '',
      // 内容
      textContents: '',
      // 納期
      dateDeliveryDate: '',
      // 現場系
      onSite:{
        deliveryCode: 0,
        code: 0,
        name: '',
      },
      // 支払方法
      textPaymentWay: '',
      // 見積期限
      dateEstimatePeriod: '',
      // 見積期限（文章）
      textEstimatePeriodSentence: '',
      // 摘要
      textSummary: '',
      // テーブル定義
      productList:[],
      fields:[
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Note',
          label: '備考',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'SalesUnitPrice',
          label: 'BM価格',
          tdClass: 'text-right',
        },
        {
          key: 'SellingPrice',
          label: '売価',
        },
        {
          key: 'PurchasePrime',
          label: '仕入単価',
        },
        {
          key: 'Money',
          label: '金額',
          tdClass: 'text-right',
        },
      ],
      dataIndex: 0,
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      tax: 0,
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 単価登録区分
        UnitPriceRegisterClass: {
          // 登録しない
          insertNo: Const.UnitPriceRegisterClass.insertNo,
          // 自身のみ登録
          insertMine: Const.UnitPriceRegisterClass.insertMine,
          // 親子全て登録
          insertAll: Const.UnitPriceRegisterClass.insertAll,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // コントロールマスタ
      controlMasterData: {
        lightTaxMark: '',
      },
      // パラメータ
      estimateId: 0,
      parentKbn: 0,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.estimateId = this.$route.query.estimateId;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    textTotalMoneyText: function() {
      let retTotalMoneyText = '消費税額：' + this.tax.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  methods:{
    async fetchData() {
      this.$store.commit('setLoading', true);
      const functionName = 'fetchData';
      try {
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // コントロールマスタ
        let controlData = await getControlMaster();
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 指定された見積データを設定
        await this.setEstimate();
        // パラメータによってメッセージを表示
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 見積データ設定 */
    async setEstimate(){
      const functionName = 'setEstimate';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let resultEstimate = null;
      try {
        resultEstimate = await API.graphql(graphqlOperation(list_t_estimate,{where_clause: where_clause}));
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_estimate',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        return;
      }
      let dataEstimate = resultEstimate.data.list_t_estimate;
      //console.log(dataEstimate);
      if(dataEstimate != null){
        await this.setResult(dataEstimate);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake() {
      let where_clause = '';
      // 引数の見積番号で絞り込む
      where_clause += 'AND estimate_id = ' + this.estimateId + ' ';
      // 製品一覧の行番号でソート
      where_clause += 'ORDER BY estimate_row';

      return where_clause;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0){
        // 営業所
        this.selectSalesOfficeText = result[0].office_id + '：' + result[0].office_name_kanji;
        // 見積日付
        this.dateEstimateDate = formatDate(result[0].estimate_date);
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_print_name;
        // 取引先担当者
        this.clientStaff = {id:result[0].client_staff_id,name:result[0].client_staff_name_kanji};
        // 作成担当者
        this.createdStaff = {id:result[0].created_staff_id,name:result[0].created_staff_name_kanji};
        // 見積種類
        this.checkEstimateKind = result[0].estimate_kind;
        for(let i = 0; Const.EstimateKindList.length; i++){
          if(Const.EstimateKindList[i].id == result[0].estimate_kind){
            this.textEstimateKind = Const.EstimateKindList[i].id + '：' + Const.EstimateKindList[i].name;
            break;
          }
        }
        // 消費税区分
        this.checkTaxType = result[0].tax_type;
        for(let i = 0; Const.TaxTypeList.length; i++){
          if(Const.TaxTypeList[i].id == result[0].tax_type){
            this.textTaxType = Const.TaxTypeList[i].id + '：' + Const.TaxTypeList[i].name;
            break;
          }
        }
        // 単価登録区分
        this.checkUnitPriceRegisterClass = result[0].unit_price_register_class;
        for(let i = 0; Const.UnitPriceRegisterClassList.length; i++){
          if(Const.UnitPriceRegisterClassList[i].id == result[0].unit_price_register_class){
            this.textUnitPriceRegisterClass = Const.UnitPriceRegisterClassList[i].id + '：' + Const.UnitPriceRegisterClassList[i].name;
            break;
          }
        }
        // 登録区分ラジオボタン
        this.checkRegisterClass = result[0].group_register_class;
        this.textRegisterClass = getListValue(Const.ClientsProductsMasterRegisterClassList, result[0].group_register_class, 'id', 'text');
        // 単価適用日
        this.dateUnitPriceEffectiveDate = formatDate(result[0].unit_price_effective_date);
        // 単価備考
        this.textUnitPriceNote = result[0].unit_price_note;
        // 内容
        this.textContents = result[0].contents;
        // 納期
        this.dateDeliveryDate = formatDate(result[0].delivery_date);
        // 現場系
        this.onSite.deliveryCode = result[0].client_id;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        // 支払方法
        this.textPaymentWay = result[0].payment_way;
        // 見積期限
        this.dateEstimatePeriod = formatDate(result[0].estimate_period);
        // 見積期限（文章）
        this.textEstimatePeriodSentence = result[0].estimate_period_sentence;
        // 摘要
        this.textSummary = result[0].summary;
        // 全件参照して製品一覧を作成
        let productIdCsv = '';
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].Note = result[i].product_note;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].product_unit;
          this.productList[i].SellingPrice = result[i].product_sales_unit_price;
          this.productList[i].PurchasePrime = result[i].product_purchase_price;
          this.productList[i].totalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
            if (productIdCsv != '') {
              productIdCsv += ',';
            }
            // 製品コード
            productIdCsv += this.productList[i].ProductCode;
          }
        }
        // 売上単価を製品マスターから取得
        if (productIdCsv != '') {
          let where_clause = 'AND product_id IN (' + productIdCsv + ') ';
          let condition = {where_clause: where_clause};
          let productsResult = await API.graphql(graphqlOperation(list_m_products,condition));
          let productsResultData = productsResult.data.list_m_products;
          //console.log(productsResultData);
          if (productsResultData != null) {
            for (let i = 0; i < this.productList.length; i++) {
              let data = productsResultData.find(el => el.product_id == this.productList[i].ProductCode);
              if (data != undefined) {
                this.productList[i].SalesUnitPrice = data.sales_unit_price;
                this.productList[i].ProductTaxRateClass = data.product_tax_rate_class_sales;
              }
            }
          }
        }
        this.calcTotal();
        // 取引先製品単価区分を設定
        await refreshClientAmountClassProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateEstimateDate);
        // 消費税
        this.tax = result[0].tax;
      }
    },
    // 取引先照会
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      //console.log('取引先製品単価履歴照会モーダル');

      let productIdCsv = '';
      for(let i = 0; i < this.productList.length; i++){
        if(productIdCsv != ''){
          productIdCsv += ',';
        }
        // 製品コード
        productIdCsv += this.productList[i].ProductCode;
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.suppliers.code;
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 合計金額、合計仕入単価、粗利計算
    calcTotal: function() {
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      for(let i = 0; i < this.productList.length;i++){
        this.totalFee += this.productList[i].Money;
        this.totalPurchasePrime += this.productList[i].totalPurchasePrime;
      }
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        ProductCode: 0,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        Note:'',
        Quantity: 0,
        Unit: '',
        SellingPrice: 0,
        ClientAmountClass: '',
        SalesUnitPrice: 0,
        PurchasePrime: 0,
        totalPurchasePrime: 0,
        Money: 0,
      };
      this.productList.push(newProduct);
    },
    /* 見積書PDF出力ボタンの押下 */
    async clickOutputEstimatePdf() {
      // 見積書PDF出力処理
      //console.log('見積書PDF出力処理');
      let route = this.$router.resolve({ name: 'ESTIMATE', query: { estimateId: this.estimateId }});
      window.open(route.href, '_blank');
    },
    /* 編集ボタン押下時 */
    async clickEditBtn() {
      //console.log('編集');
      // 見積修正画面へ遷移
      this.$router.push({ name: 'ESTIMATE-EDIT', query: { estimateId: this.estimateId } });
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn() {
      //console.log('削除');
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 取引先親子マスタの削除SQL
      let deleteSql = 'DELETE FROM t_estimate';
      deleteSql += ' WHERE ';
      // 見積番号
      deleteSql += 'estimate_id = ' + this.estimateId + ' ';

      //console.log(deleteSql);

      let sqlList = [];

      // 見積データの削除前に単価登録情報を削除
      let deleteUnitPriceSql = await getEstimateDeleteUnitPriceSqlList(this.estimateId);
      if (getNullStr(deleteUnitPriceSql) != '') {
        sqlList.push(deleteUnitPriceSql);
      }

      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        // 見積一覧画面へ遷移
        this.$router.push({ name: 'ESTIMATE-LIST', params: { parentKbn: 1 } });
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* コピー見積ボタン押下時 */
    async clickEstimateCopyBtn(){
      //console.log('コピー見積');
      // 見積入力画面へ遷移
      this.$router.push({ name: 'ESTIMATE-INPUT', query: { propEstimateId: this.estimateId, propReceivedOrderId: '0' } });
    },
    /* コピー受注ボタン押下時 */
    async clickReceivedOrderCopyBtn(){
      const functionName = 'clickReceivedOrderCopyBtn';
      //console.log('コピー受注');
      this.$store.commit('setLoading', true);
      try {
        let retCheck = await checkReceivedOrderCopy(this.productList);
        if (retCheck != null) {
          if (retCheck.msgId == '2057') {
            this.alertWarning.push(DISP_MESSAGES.WARNING[retCheck.msgId]);
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING[retCheck.msgId].replace('%arg1%',retCheck.productId));
          }
        } else {
          // 受注入力画面へ遷移
          this.$router.push({ name: 'RECEIVED-ORDER-INPUT', query: { estimateId: this.estimateId } });
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2057']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack(){
      // 見積・発注一覧画面へ遷移
      this.$router.push({ name: 'ESTIMATE-LIST' });
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'ESTIMATE-INPUT', query: { propEstimateId: 0, propReceivedOrderId: 0 }});
    },
  }
}
</script>
<style scoped>
</style>