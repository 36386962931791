<template>
  <div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <b-card-header v-if="getMessageFlg==true">
                        <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                          <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                            <li>{{error}}</li>
                          </ul>
                        </b-alert>
                        <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                          <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                            <li>{{error}}</li>
                          </ul>
                        </b-alert>
                      </b-card-header>
                      <!-- ●●●検索条件●●● -->
                      <b-container fluid>
                        <b-row>
                          <b-col lg="6">
                            <b-container fluid>
                              <b-row>
                                <b-col class="px-0">
                                  <b-form-group
                                    label="取引先"
                                    description="完全一致または範囲検索です。"
                                  >
                                    <b-input-group>
                                      <validation-provider name="selectClientIdStart" :rules="{numeric:true, consistency:searchConditions.selectClientIdEnd}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <b-input-group class="selectClientIdStart" id="selectClientIdStart">
                                            <b-form-input
                                              name="selectClientIdStart"
                                              class="form-control"
                                              size="sm"
                                              maxlength="6"
                                              v-model="searchConditions.selectClientIdStart"
                                            ></b-form-input>
                                            <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                                <span class="oi oi-magnifying-glass"></span>
                                                <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                              </b-button>
                                            </b-input-group-text>
                                          </b-input-group>
                                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </div>
                                      </validation-provider>
                                      <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                                      <validation-provider name="selectClientIdEnd" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <b-input-group class="selectClientIdEnd" id="selectClientIdEnd">
                                            <b-form-input
                                              name="selectClientIdEnd"
                                              class="form-control"
                                              size="sm"
                                              maxlength="6"
                                              v-model="searchConditions.selectClientIdEnd"
                                            ></b-form-input>
                                            <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                                <span class="oi oi-magnifying-glass"></span>
                                                <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                              </b-button>
                                            </b-input-group-text>
                                          </b-input-group>
                                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </div>
                                      </validation-provider>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                          <!-- 売上伝票年月入力欄 -->
                          <b-col lg="6">
                            <b-container fluid>
                              <b-row>
                                <b-col class="px-0">
                                  <b-form-group
                                    label="売上伝票年月"
                                    label-for="datepicker"
                                  >
                                    <b-input-group>
                                      <validation-provider name="selectBillingDateStart" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/, consistency:searchConditions.selectBillingDateEnd}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <b-form-input id="datepickerStart" name="selectBillingDateStart" type="month" v-model="searchConditions.selectBillingDateStart" />
                                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </div>
                                      </validation-provider>
                                      <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                                      <validation-provider name="selectBillingDateEnd" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <div>
                                            <b-form-input id="datepickerEnd" name="selectBillingDateEnd" type="month" v-model="searchConditions.selectBillingDateEnd" />
                                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                          </div>
                                        </div>  
                                      </validation-provider>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                      </b-container>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); onClickSearch();" :disabled="busy">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-table-simple
            id="resultTable"
            v-if="itemList.length !== 0"
            :bordered='false'
            :borderless='true'
            :outlined='false'
            small
            caption-top
          >
            <b-tbody class="d-flex justify-content-end">
              <b-tr class="row">
                <div class="col-lg-6">
                  <b-th>売上総計</b-th>
                  <b-td>{{totalAmount.toLocaleString()}}</b-td>
                </div>
                <div class="col-lg-6">
                  <b-th>粗利総計</b-th>
                  <b-td>{{totalGrossProfit.toLocaleString()}}</b-td>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
            <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
  <Footer />
  <!-- ●●●得意先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class='ClientClass'/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const, { QUERY_REQUEST_TYPE } from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import { onUpdateQueryStatus } from '@/graphql/subscriptions';
import { init, addOperationLogs, formatDate, formatCurDate } from '@/assets/js/common.js'
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { requestQueryAsync, onQueryStatusUpdate } from '@/assets/js/executeSqlAsync.js'
const MODULE_NAME = 'query-client-sales-by-month';
const MONTH_FORMAT = 'YYYY-MM';
const MONTH_DATE_FORMAT = 'YYYY-MM-DD';
export default {
  name: 'QUERY-CLIENT-SALES-BY-MONTH',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '得意先別売上額（年月指定）照会',
      // アラート
      alertWarning:[],
      alertDanger : [],
      alertSuccess : [],
      // 検索条件
      searchConditions:{
        selectTransactionId: Const.TransactionId.sales,
        selectClientIdStart: '',
        selectClientIdEnd: '',
        selectBillingDateStart: '',
        selectBillingDateEnd: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 得意先区分
      ClientClass :Const.ClientClass.customer,
      // 売上総計
      totalAmount: 0,
      // 粗利総計
      totalGrossProfit: 0,
    }
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'billingDate',
          label: '売上伝票年月',
          sortable: true,
        },
        {
          key: 'clientId',
          label: '取引先',
          sortable: true,
        },
        {
          key: 'salesAmount',
          label: '売上合計',
          sortable: true,
        },
        {
          key: 'grossProfit',
          label: '粗利額',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コンボボックスの作成
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    /* フェッチ */
    async fetchData(){
      // ローディングの開始
      this.$store.commit('setLoading', true);
      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;
      // 売上伝票年月に今月を設定
      this.searchConditions.selectBillingDateStart = formatCurDate(MONTH_FORMAT);
      this.searchConditions.selectBillingDateEnd = formatCurDate(MONTH_FORMAT);
      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async onClickSearch(){
      // 明細テーブルのローディングを開始
      this.busy = true;

      // ログ出力用メソッド名
      const functionName = 'onClickSearch';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
          // 明細テーブルのローディングを終了
          this.busy = false;
        } else {
          // 検索開始
          this.search();
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
      }
    },
    /* 検索処理 */
    async search(){
      // ログ出力用メソッド名
      const functionName = 'search';
      this.itemList = [];
      this.initPaging();
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      // console.log('selectSql', selectSql);
      //console.log('selectSql '+JSON.stringify(selectSql))

      // 非同期SELECTリクエスト
      let processId;
      try {
        processId = await requestQueryAsync({
          type: QUERY_REQUEST_TYPE.SIMPLE_QUERY,
          sqls: [selectSql],
        });
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          message: DISP_MESSAGES.WARNING['2063']
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }

      // DataCreationStatusテーブルを監視する。
      let subscription;
      try {
        subscription = API.graphql(
          graphqlOperation(onUpdateQueryStatus, { ProcessID: processId })
        ).subscribe({
          next: async ({ value }) => {
            // ステータスの変更を検知した際の処理
            const result = await onQueryStatusUpdate(value?.data?.onUpdateQueryStatus ?? null, subscription);

            if(result === undefined) return;
            if(!result) {
              this.alertDanger.push(DISP_MESSAGES.WARNING['2001']);
              // 総件数をdataTableの総件数にセット
              this.totalRows = 0;
              // 明細テーブルのローディングを終了
              this.busy = false;
              return;
            }
            if(result[0].length > 0) {
              // 結果データをセット
              await this.setResult(result[0]);
              // 結果テーブルへスクロール
              document.getElementById('resultTable').scrollIntoView();
            } else {
              // 総件数をdataTableの総件数にセット
              this.totalRows = 0;
            }
            // 明細テーブルのローディングを解除
            this.busy = false;
          }
        });      
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'onQueryStatusUpdate',
          message: DISP_MESSAGES.WARNING['2064'],
          SQLs: selectSql,
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(const data of result){
        this.itemList.push({
          billingDate : !data.billing_date ? null :formatDate(data.billing_date, 'YYYYMM'),
          clientId : (!data.client_name_kanji) ? data.client_id : data.client_id + '：' + data.client_name_kanji,
          salesAmount : Number(data.product_sales_unit_amount).toLocaleString(),
          grossProfit : Number(data.product_gross_profit).toLocaleString(),
          totalAmount : Number(data.product_sales_unit_amount),
          totalGrossProfit : Number(data.product_gross_profit),
        });
      }
      this.totalAmount = this.itemList.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
      this.totalGrossProfit = this.itemList.map(item => item.totalGrossProfit).reduce((prev, curr) => prev + curr, 0);
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += 'cumulative_transaction.client_id'; // 取引先コード
      selectSql += ',clients.client_name_kanji'; // 取引先名
      selectSql += `,DATE_FORMAT(cumulative_transaction.billing_date, '${'%Y%m'}') AS billing_date`; // 伝票年月
      selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount'; // 売上金額
      selectSql += ',SUM(cumulative_transaction.product_gross_profit) AS product_gross_profit'; // 粗利額
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN (' + await this.makeSelectSqlQuery() + ') AS cumulative_transaction_QUERY ';
      selectSql += 'ON cumulative_transaction.transaction_id = cumulative_transaction_QUERY.transaction_id ';
      selectSql += 'AND cumulative_transaction.billing_no = cumulative_transaction_QUERY.billing_no ';
      selectSql += 'AND cumulative_transaction.billing_row = cumulative_transaction_QUERY.billing_row ';
      selectSql += 'AND cumulative_transaction.monthly_month_year = cumulative_transaction_QUERY.monthly_month_year ';
      // 取引先マスタ（m_clients）と結合
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'AND cumulative_transaction.client_class = clients.client_class ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.client_id';
      selectSql += `,DATE_FORMAT(cumulative_transaction.billing_date, '${'%Y%m'}')`;
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.billing_date';
      return selectSql;
    },
    // SELECTQUERY文字列作成
    async makeSelectSqlQuery(){
      // 検索条件
      let whereClauses = [];
      // トランザクションID区分
      whereClauses.push(`cumulative_transaction.transaction_id = '${this.searchConditions.selectTransactionId}'`);
      // 取引先開始&取引先終了
      if (this.searchConditions.selectClientIdStart && this.searchConditions.selectClientIdEnd) {
        whereClauses.push(`cumulative_transaction.client_class = '${Const.ClientClass.customer}'`);
        // 取引先開始
        whereClauses.push(`cumulative_transaction.client_id BETWEEN '${this.searchConditions.selectClientIdStart}'`);
        // 取引先終了
        whereClauses.push(`'${this.searchConditions.selectClientIdEnd}'`);
      } else if (this.searchConditions.selectClientIdStart) {
        whereClauses.push(`cumulative_transaction.client_class = '${Const.ClientClass.customer}'`);
        whereClauses.push(`cumulative_transaction.client_id = '${this.searchConditions.selectClientIdStart}'`);
      } else if (this.searchConditions.selectClientIdEnd) {
        whereClauses.push(`cumulative_transaction.client_class = '${Const.ClientClass.customer}'`);
        whereClauses.push(`cumulative_transaction.client_id = '${this.searchConditions.selectClientIdEnd}'`);
      }
      // 売上伝票年月開始
      whereClauses.push(`cumulative_transaction.billing_date BETWEEN '${moment(this.searchConditions.selectBillingDateStart).startOf('month').format(MONTH_DATE_FORMAT)}'`);
      // 売上伝票年月終了
      whereClauses.push(`'${moment(this.searchConditions.selectBillingDateEnd).endOf('month').format(MONTH_DATE_FORMAT)}'`);

      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.transaction_id';
      selectSql += ',cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_row';
      selectSql += ',cumulative_transaction.monthly_month_year';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      /* WHERE句 */
      // 検索条件が指定された場合
      if(whereClauses.length !== 0){
        selectSql += `WHERE ${whereClauses.join(' AND ')} ` 
      }
      return selectSql;
    },
    /**
     * 「取引先検索」アイコン押下処理
     */
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 「取引先検索」モーダルのクローズ押下処理
     */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.selectClientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.selectClientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  }
}
</script>
<style scoped>
.input-daterange{
  min-width: 18.5vw;
}
</style>