<template>
  <b-modal
    id="siteSearchModal"
    size="xl"
    centered
    @show="searchButton('init')"
    title="現場検索"
  >
    <b-container class="px-0">
      <b-row>
        <b-col>
          <!-- ●●●検索条件●●● -->
          <div class="main-card card px-3 pt-2">
            <div class="mb-0" id="heading1">
              <h5 class="mb-0">
                <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                  <span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
            </div>
            <b-collapse id="collapse-1" visible>
              <b-card-body class="p-1">
                <b-form @submit.prevent="searchButton('click');" id="siteSearchForm" class="form-horizontal">
                  <b-row>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientSiteCode">
                            <b-input-group-text  class="px-1">
                              取引先
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="clientSiteCode" name="clientSiteCode" v-model="client" readonly></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientSiteName">
                            <b-input-group-text  class="px-1">
                              現場名
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="search" id="clientSiteName" name="clientSiteName" v-model.trim="searchConditions.clientSiteName" autofocus></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientSiteCode">
                            <b-input-group-text  class="px-1">
                              現場コード
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="search" id="clientSiteCode" name="clientSiteCode" v-model.trim="searchConditions.clientSiteCode"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientSiteCode">
                            <b-input-group-text  class="px-1">
                              表示範囲
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-select
                          id="selectTradition"
                          v-model="searchLimit"
                          :options="searchLimitList"
                        >
                        </b-form-select>
                      </b-input-group>
                    </div>
                  </b-row>
                  <!-- 検索ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="3">
                      <b-button block pill variant="success" size="sm" type="submit" form="siteSearchForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col class="mt-0">
          <b-row>
            <b-col lg="6">
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            show-empty
            :small="true"
            :items="siteList"
            :fields="fields"
            :busy="busy"
            :filter="filter"
            :current-page="currentPage"
            :sticky-header= true
            >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
            <template #cell(detail)="detail">
              <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'対象のデータで入力します。'" @click="choice(detail.item)" class="mr-1">
                <span class="oi oi-check"></span> 選択
              </b-button>
            </template>
            <template #cell(clientSiteNameKanji)="data">
              <div style="max-width:200px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.clientSiteNameKanji}}
                  </span>
                </div>
              </div>
            </template>  
            <template #cell(clientSiteNameKana)="data">
              <div style="max-width:200px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.clientSiteNameKana}}
                  </span>
                </div>
              </div>
            </template>  
            <template #cell(address1)="data">
              <div style="max-width:200px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.address1}}
                  </span>
                </div>
              </div>
            </template>  
            <template #cell(address2)="data">
              <div style="max-width:200px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.address2}}
                  </span>
                </div>
              </div>
            </template>  
          </b-table>
        </b-col>
      </b-card>
    </b-container>
    <template #modal-footer>
      <b-button size="sm" @click="$bvModal.hide('siteSearchModal')" class="ml-1 float-start">キャンセル</b-button>
    </template>
  </b-modal>
</template>
<script>
import { init, convertSqlLikeSpecialChar } from '@/assets/js/common.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import {API, graphqlOperation} from 'aws-amplify';
import {list_m_clients_sites} from '@/graphql/queries';
export default {
  name: 'SITE-SEARCH',
  props:['clientInfo'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場検索',
      client: '',
      // 検索条件
      searchConditions:{
        clientSiteCode: '',
        clientSiteName: '',
      },
      // 検索結果
      siteList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // 検索上限値
      searchLimitList: [],
      searchLimit: 0,
      limitCnt: 1000,
      limitMax: 10000,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      resultError: []
    }
  },
  computed:{
    fields(){
      return [
        {
          key: 'detail',
          label: ''
        },
        {
          key: 'clientSiteId',
          label: '現場コード',
          sortable: true
        },
        {
          key: 'clientSiteNameKanji',
          label: '現場名漢字',
          sortable: true
        },
        {
          key: 'clientSiteNameKana',
          label: '現場名カナ',
        },
        {
          key: 'clientSiteZipCode',
          label: '郵便番号',
        },
        {
          key: 'address1',
          label: '住所1',
          sortable: true
        },
        {
          key: 'address2',
          label: '住所2',
          sortable: true
        },
      ]
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.searchLimitList = this.createSearchLimitList();
  },
  /* 関数群 */
  methods:{
    choice:function(choiceData){
      this.$emit('from-child', choiceData);
      this.$bvModal.hide('siteSearchModal');
    },
    /* 検索ボタン押下時 */
    async searchButton(key){
      this.client = this.clientInfo.client_id + '：' + this.clientInfo.client_name;
      if(key == 'init'){
        this.searchConditions.clientSiteCode = '';
        this.searchConditions.clientSiteName = '';
        this.searchLimit = 0;
        this.siteList = [];
        this.resultError = [];
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
        this.busy = false;
      } else {
        await this.search();
      }
    },
    /* 検索処理 */
    async search(){
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.siteList = [];
      this.resultError = [];
      // CRUD処理
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      // 検索条件がある場合、条件取得
      this.busy = true;
      try {
        //console.log('条件取得');
        let condition = {where_clause: where_clause};
        let result = await API.graphql(graphqlOperation(list_m_clients_sites,condition));
        let resultData = result.data.list_m_clients_sites;
        if(resultData != null){
          await this.setResult(resultData);
          if(resultData.length >= this.limitCnt){
            this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%',this.limitCnt).replace('%arg2%','検索結果'));
          }
          this.busy = false;
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
          this.busy = false;
        }
      } catch (error) {
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.busy = false;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          clientSiteId: result[i].site_id,
          clientSiteNameKanji: result[i].client_site_name_kanji,
          clientSiteNameKana: result[i].client_site_name_kana,
          clientSiteZipCode: result[i].client_site_zip_code,
          address1: result[i].client_site_address_1,
          address2: result[i].client_site_address_2,
          Status: '',
          detail: result[i],
        };
        this.siteList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake(){
      let where_clause = '';
      const { clientSiteCode, clientSiteName } = this.searchConditions
      // 現場コード
      if(clientSiteCode != ''){
        where_clause += 'AND site_id = ' + clientSiteCode + ' ';
      }
      // 現場名
      if(clientSiteName != ''){
        where_clause += `AND (REPLACE(REPLACE(CONVERT(client_site_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(clientSiteName)}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(client_site_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(clientSiteName)}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 親画面から渡された取引先コード
      where_clause += 'AND client_id = ' + this.clientInfo.client_id + ' ';
      // ORDER BY
      where_clause += 'ORDER BY client_id,site_id ';
      // LIMIT
      where_clause += 'LIMIT ' + this.limitCnt + ' OFFSET ' + (this.searchLimit * this.limitCnt).toString() + ' ';
      return where_clause;
    },
    // 検索条件の表示開始～上限リスト作成
    createSearchLimitList: function() {
      let retList = [];
      for (let i = 0; i < 20 && i * this.limitCnt < this.limitMax; i++) {
        let rowLimit = {
          value: i,
          text: (i * this.limitCnt + 1).toString() + '～' + ((i + 1) * this.limitCnt).toString(),
        };
        retList.push(rowLimit);
      }
      return retList;
    },
  },
}
</script>
<style scoped>
</style>