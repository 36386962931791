<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
          <!-- ●●●コンテンツ●●● -->
          <div class="mt-0">
              <!-- ●●●メインコンテンツ●●● -->
              <div id="content-menu" class="my-2 mx-0" role="main">
                  <!-- ●●●1件目●●● -->
                  <section class="" id="menu-1">
                      <!-- ●●●メニュータイトル●●● -->
                      <div class="user-header alt bg-none">
                          <div class="media">
                              <div class="media-body pb-3">
                                  <h5 class="text-secondary m-0"><span class="oi oi-clipboard"></span>日次処理メニュー</h5>
                              </div>
                          </div>
                      </div>
                      <!-- ●●●メニュー●●● -->
                      <div id="collapse-menu0" class="row collapse show">
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['1']" @shortkey="executeShortcut('purchase-list')">
                              <router-link to="/purchase-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="1" id="menu1-1">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">1</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">仕入（在庫なし） 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>仕入伝票の検索、登録、修正、削除を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['2']" @shortkey="executeShortcut('deposit-list')">
                              <router-link to="/deposit-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="2" id="menu1-2">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">2</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">入金 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>入金伝票の検索、登録、修正、削除を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['3']" @shortkey="executeShortcut('payment-list')">
                              <router-link to="/payment-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="3" id="menu1-3">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">3</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">支払 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>支払伝票の検索、登録、修正、削除を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['4']" @shortkey="executeShortcut('loading-unloading-list')">
                              <router-link to="/loading-unloading-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="4" id="menu1-4">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">4</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">入出庫 一覧/入力/照会</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>入出庫伝票の検索、登録、照会を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['5']" @shortkey="executeShortcut('sales-list')">
                              <router-link to="/sales-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="5" id="menu1-5">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">5</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">売上 一覧/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>売上伝票（通常・返品）の検索、修正、削除、伝票発行を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['6']" @shortkey="executeShortcut('tentative-sales-list')">
                              <router-link to="/tentative-sales-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="6" id="menu1-6">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">6</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">売上一覧(仮伝票)・入力変更・削除（仮伝票発行あり）</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>売上仮伝票検索、修正、削除、仮伝票発行を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['7']" @shortkey="executeShortcut('sales-direct-list')">
                              <router-link to="/sales-direct-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="7" id="menu1-7">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">7</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">直送売上 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>直送売上伝票（受注なし）の登録と直送売上伝票（受注ありなし両方）の検索、修正、削除、伝票発行を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['8']" @shortkey="executeShortcut('pca-account-list')">
                              <router-link to="/pca-account-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="8" id="menu1-8">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">8</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">PCA会計用データ一覧</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>PCA会計テーブルに登録されたデータの一覧表示・CSV出力・データクリア処理を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['9']" @shortkey="executeShortcut('save-documents-list')">
                              <router-link to="/save-documents-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="9" id="menu1-9">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">9</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">保存電子書類一覧/入力/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>電子帳簿保存法のための電子書類の検索、登録、削除を行います。</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
          <Footer />
      </div>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-SALES-DAILY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '日次処理メニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>