<template>
  <!-- 製品単位変換保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品単位変換保守編集</strong></h5>
                <router-link to="/products-units-conversions-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- ケース製品コード -->
                  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 form-group">
                    <label for="case_product_id" class="form-label"><strong>ケース製品コード(単位:{{productUnitsConversionsObj.case_unit}})</strong></label>
                    <input type="text" id="case_product_id" class="form-control" v-model="productUnitsConversionsObj.case_product_id" readonly>
                  </div>
                  <!-- ケース製品名 -->
                  <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                    <label for="case_product_name_kanji" class="form-label"><strong>ケース製品名(単位:{{productUnitsConversionsObj.case_unit}})</strong></label>
                    <input type="text" id="case_product_name_kanji" class="form-control" v-model="productUnitsConversionsObj.case_product_name_kanji" readonly v-b-tooltip.hover.noninteractive :title="productUnitsConversionsObj.case_product_name_kanji">
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- バラ製品コード -->
                  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|max:8|min:8" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="loose_product_id" class="form-label"><strong>バラ製品コード</strong></label>
                        <div class="input-group">
                          <input type="text" id="loose_product_id" class="form-control" v-model="productUnitsConversionsObj.loose_product_id" @input="productIdConfirm(productUnitsConversionsObj.loose_product_id)" maxlength="8">
                          <!-- 製品コード検索ボタン -->
                          <b-input-group-text @click="showProductSearchModal" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '1']" @shortkey="showProductSearchModal" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- バラ製品名 -->
                  <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                    <label for="case_product_name_kanji" class="form-label"><strong>バラ製品名</strong></label>
                    <input type="text" id="case_product_name_kanji" class="form-control" v-model="productUnitsConversionsObj.loose_product_name_kanji" readonly v-b-tooltip.hover.noninteractive :title="productUnitsConversionsObj.loose_product_name_kanji">
                  </div>
                </div> 
                <div class="row mt-2">
                  <!-- ケース入数 -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-1 form-group">
                    <validation-provider rules="required|numeric|between:1,999" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="case_product_id" class="form-label"><strong>ケース入数(単位:{{productUnitsConversionsObj.loose_unit}})</strong></label>
                        <input type="text" id="quantity" class="form-control" v-model="productUnitsConversionsObj.quantity" maxlength="3">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div> 
              </validation-observer>
              <!-- 保存ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                   <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import Const from '@/assets/js/const.js';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import { list_m_products, list_m_products_units_conversions } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, CreateColRow, CreateUpdateSql, getUserCol, checkProductId, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products_units_conversions-edit';

export default {
  name: 'PRODUCTS_UNITS_CONVERSIONS-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品単位変換保守編集',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品単位変換対象
      productUnitsConversionsObj: {},
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  computed:{
    /* 製品モーダルのパラメータ用 */
    productSearchProp() {
      return {
        office_id: null,
        office_name: null,
        client_class: null,
        client_id: null,
        client_name: null,
      };
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} case_product_id - ケース製品コード
     * @param {String} loose_product_id - バラ製品コード
     */
    async fetchData({case_product_id, loose_product_id}) {
      this.busy = true;
      // ケース製品コード
      case_product_id = Number(case_product_id)
      loose_product_id = Number(loose_product_id)
      // 製品のデータを請求
      // 製品単位変換マスタのデータを請求
      const productUnitsConversions = await this.searchUnitsConversions(case_product_id, loose_product_id)
      if (productUnitsConversions === undefined) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }

      // 製品単位変換データをセット
      this.productUnitsConversionsObj = {...productUnitsConversions}
      this.busy = false;
    },
    // 製品検索
    showProductSearchModal() {
      this.errorMessages = []
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal(productItems){
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        // 「諸口区分」が諸口の場合
        if (productItems.detail.sundries_class == Const.SundriesClass.normal) {
          if (this.productUnitsConversionsObj.case_product_id === productItems.productId) {
            this.errorMessages.push(DISP_MESSAGES.WARNING['2015'])
            scrollTo(0,0);
            return
          } else {
            // バラ製品に入れる
            this.productUnitsConversionsObj.loose_product_id = productItems.productId;
            this.productUnitsConversionsObj.loose_product_name_kanji = productItems.productNameKanji;
            this.productUnitsConversionsObj.loose_unit = productItems.detail.unit;
          }
        } else {
          // 「諸口区分」が諸口の場合
          this.errorMessages.push(DISP_MESSAGES.WARNING['2044'])
          // バラ製品に入れる
          this.productUnitsConversionsObj.loose_product_id = ''
          this.productUnitsConversionsObj.loose_product_name_kanji = ''
          this.productUnitsConversionsObj.loose_unit = ''
          scrollTo(0,0);
        }
      }
    },
    /**
     * バラ製品コード確認処理
     * @param {Int} loose_product_id - バラ製品コード
     * @param {Object} data - データ行内容
     * @returns {Array<Object>} 検索結果
     */
    async productIdConfirm(loose_product_id) {
      const functionName = 'productIdConfirm';
      this.errorMessages = []
      // 数字8桁のみ
      if (checkProductId(loose_product_id)) {
        loose_product_id = Number(loose_product_id)
        // コード重複場合
        if (this.productUnitsConversionsObj.case_product_id == loose_product_id) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2015'])
          scrollTo(0,0);
          return
        }
        const whereClause = `AND product_id = ${loose_product_id}`
        let result = null;
        try {
          result = await API.graphql(graphqlOperation(list_m_products, {
            where_clause: whereClause
          }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            where_clause: whereClause
          }, error);
          return null;
        }
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            where_clause: whereClause,
            result: result
          });
          return null;
        }
        if (result.data.list_m_products.length === 1) {
          // 「諸口区分」が諸口の場合
          if (result.data.list_m_products[0].sundries_class == Const.SundriesClass.normal) {
            // バラ製品コードと製品名をセット
            this.productUnitsConversionsObj.loose_product_name_kanji = result.data.list_m_products[0].product_name_kanji
            this.productUnitsConversionsObj.loose_product_id = loose_product_id
            this.productUnitsConversionsObj.quantity = 1
            this.productUnitsConversionsObj.loose_unit = result.data.list_m_products[0].unit
          } else {
            // 「諸口区分」が諸口の場合
            this.errorMessages.push(DISP_MESSAGES.WARNING['2044'])
            this.productUnitsConversionsObj.loose_product_name_kanji = ''
            this.productUnitsConversionsObj.loose_product_id = ''
            this.productUnitsConversionsObj.loose_unit = ''
            scrollTo(0,0);
          }
        } else {
          // 部材のコードと製品名をセット
          this.productUnitsConversionsObj.loose_product_name_kanji = null
          this.productUnitsConversionsObj.loose_product_id = loose_product_id
          this.productUnitsConversionsObj.quantity = null
          this.productUnitsConversionsObj.loose_unit = ''
          this.errorMessages.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','製品コード'));
          scrollTo(0,0);
        }
      }
    },
    /**
     *  製品単位変換マスタを検索します。
     * @param {Int} case_product_id - 製品コード
     * @param {Int} loose_product_id - 製品コード
     * @returns {Array<Object>} 製品単位変換マスタと製品マスタ結合データ
     */
    async searchUnitsConversions(case_product_id, loose_product_id) {
      const functionName = 'searchUnitsConversions';

      let result = null;
      // 製品単位変換マスタと製品マスタ結合
      const SQLs = `SELECT  
                    MPUC.case_product_id case_product_id,
                    MP.product_name_kanji case_product_name_kanji,
                    MP.product_name_kana case_product_name_kana,
                    MP.unit case_unit,
                    MPUC.quantity,
                    MPUC.loose_product_id loose_product_id,
                    MP2.product_name_kanji loose_product_name_kanji,
                    MP2.product_name_kana loose_product_name_kana,
                    MP2.unit loose_unit
                    FROM 
                    m_products_units_conversions MPUC 
                    LEFT JOIN m_products MP ON MPUC.case_product_id = MP.product_id 
                    LEFT JOIN m_products MP2 ON  MPUC.loose_product_id = MP2.product_id
                    WHERE MPUC.case_product_id=${case_product_id} AND MPUC.loose_product_id=${loose_product_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0]
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('入力された製品単位変換データを保存します。よろしいですか？', {
          title: '製品単位変換の保存',
        })) {
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-UNITS-CONVERSIONS-INQUIRY', 
              query: { 
                case_product_id: this.productUnitsConversionsObj.case_product_id,
                loose_product_id: this.productUnitsConversionsObj.loose_product_id
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          scrollTo(0,0);
        }
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      // コード重複場合
      if (this.productUnitsConversionsObj.case_product_id == this.productUnitsConversionsObj.loose_product_id) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2015'])
        scrollTo(0,0);
        return
      }
      // バラ製品、且つ、ケース製品の登録不可
      const objExist = await this.unitsConversionsIdsExist()
      if (objExist.case.length != 0) {
        this.errorMessages.push('このケース製品はすでにバラ製品として使われています')
        return
      }
      if (objExist.loose.length != 0) {
        this.errorMessages.push('このバラ製品はすでにケース製品として使われています')
        return
      }

      // 製品単位変換マスタを更新か新規
      await this.execUpdateUnitsConversions()
    },
    /**
     * 更新処理
     */
    async execUpdateUnitsConversions() {
      const functionName = 'execUpdateUnitsConversions';

      const colList = [];
      // バラ製品コード
      colList.push(CreateColRow('loose_product_id', Number(this.productUnitsConversionsObj.loose_product_id), 'INT'));
      // 入数
      colList.push(CreateColRow('quantity', Number(this.productUnitsConversionsObj.quantity), 'INT'));
      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_products_units_conversions')} 
                    WHERE 
                    case_product_id = ${Number(this.productUnitsConversionsObj.case_product_id)} 
                    and loose_product_id = ${Number(this.$route.query.loose_product_id)}`;
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    /**
     * バラ製品、且つ、ケース製品の登録不可
     * @return {case: Array, loose:Array}  すでにケースかバラかになった製品コード
     */
    async unitsConversionsIdsExist() {
      const functionName = 'unitsConversionsIdsExist';

      // ケース製品コード
      const case_product_id = Number(this.productUnitsConversionsObj.case_product_id)
      // バラ製品コード
      const loose_product_id = Number(this.productUnitsConversionsObj.loose_product_id)

      const where_clause = `AND (case_product_id = ${loose_product_id} or loose_product_id = ${case_product_id})`
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_products_units_conversions, { where_clause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_units_conversions',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_units_conversions',
          where_clause: where_clause,
          result: result
        });
        return null;
      }
      if (result.data.list_m_products_units_conversions.length != 0 ) {
        console.log(result.data.list_m_products_units_conversions);
        // 一時保存のSet
        let tempCase = new Set()
        let tempLoose = new Set()
        result.data.list_m_products_units_conversions.forEach(element => {
          if (element.loose_product_id == this.productUnitsConversionsObj.case_product_id) tempCase.add(element.case_product_id)
          if (element.case_product_id == this.productUnitsConversionsObj.loose_product_id) tempLoose.add(element.loose_product_id)
        });
        return {
          case: Array.from(tempCase),
          loose: Array.from(tempLoose),
        }
      }
      return {
        case: [], 
        loose: [],
      }
    },
  }
}
</script>