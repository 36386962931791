<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive.right="'クリックで検索条件を表示/非表示できます。'">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive.bottom="'電子書類の登録'" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-form @submit.prevent="clearAlert(); searchButton();" id="orderListForm" class="form-horizontal">
                      <b-container fluid>
                        <!-- ●●●検索条件●●● -->
                        <b-row class="mt-1">
                          <!-- 電子書類区分入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="electronicDocumentsClass" rules="numeric" v-slot="{ classes,errors }">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <label for="electronicDocumentsClass" v-b-tooltip.hover.noninteractive title="完全一致検索">電子書類区分 <span class="px-1 oi oi-flag"/></label>
                                <b-form-input type="text" id="electronicDocumentsClass" name="electronicDocumentsClass" v-model="searchConditions.electronicDocumentsClass" maxlength="4"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 電子書類名入力欄 -->
                          <b-col lg="9">
                            <label for="electronicDocumentsName" v-b-tooltip.hover.noninteractive title="部分一致検索">電子書類名 <span class="px-1 oi oi-flag"/></label>
                            <b-form-input type="text" id="electronicDocumentsName" v-model="searchConditions.electronicDocumentsName" maxlength="40"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row class="mt-2">
                          <!-- 削除チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isDeleted" v-b-tooltip.hover.noninteractive title="チェックONの場合、削除された電子書類データが表示されます">削除 <span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isDeleted"
                              name="isDeleted"
                              v-model="searchConditions.isDeleted"
                            ></b-form-checkbox>
                          </b-col>
                        </b-row>
                        <!-- 検索ボタン -->
                        <b-row class="justify-content-md-center">
                          <b-col lg="3">
                            <b-button block pill variant="success" type="submit" form="orderListForm">
                              <span class="oi oi-magnifying-glass"></span> 検 索 
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="data">
                <div v-show="!printStatus">
                  <!-- 編集ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'電子書類の編集を行います。'" @click="clickEditBtn(data.item.ElectronicDocumentsClass)" class="mr-1" v-if="searchConditions.isDeletedSearch==false">
                    <span class="oi oi-pencil"></span> 編集
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'電子書類の削除を行います。'" @click="clearAlert(); clickDeleteBtn(data.item.ElectronicDocumentsClass, true)" v-if="searchConditions.isDeletedSearch==false">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                  <!-- 削除取消ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'電子書類の削除を取り消します。'" @click="clearAlert(); clickDeleteBtn(data.item.ElectronicDocumentsClass, false)" v-if="searchConditions.isDeletedSearch==true">
                    <span class="oi oi-delete"></span> 削除取消
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mt-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, executeSelectSql, init, isSystemEditable, CreateColRow, CreateUpdateSql, executeTransactSqlList } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'save-documents-maintenance-list';

export default {
  name: 'SAVE-DOCUMENTS-MAINTENANCE-LIST',
  // コンポーネント
  components: {
    Header,
    Footer
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '電子書類保守一覧',
      // ユーザ名
      loginId: this.$store.getters.user.username,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions: {
        electronicDocumentsClass: '',
        electronicDocumentsName: '',
        isDeleted: false,
        isDeletedSearch: false,
      },
      //  結果リスト
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // 表示データの総件数
      totalRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant: DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
      // パラメータ
      parentKbn: 0,
    };
  },
  computed: {
    // フィールド
    fields: function() {
      return [
        {
          key: 'ElectronicDocumentsClass',
          label: '電子書類区分',
          sortable: true,
        },
        {
          key: 'ElectronicDocumentsName',
          label: '電子書類名',
          sortable: true,
        },
        {
          key: 'Sort',
          label: '表示順',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  // mountedライフサイクルフック
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  // 関数群
  methods: {
    clickInputBtn() {
      this.$router.push({ name: 'SAVE-DOCUMENTS-MAINTENANCE-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    // フィルター時のイベント
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期検索
        await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      this.itemList = [];
      this.searchConditions.isDeletedSearch = this.searchConditions.isDeleted;
      // ページング機能の初期化
      this.initPaging();
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if(resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // SELECT文字列作成
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' electronic_documents_class';
      selectSql += ',electronic_documents_name';
      selectSql += ',sort';
      selectSql += ',is_deleted';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_electronic_documents ';
      // WHERE句
      selectSql += ' WHERE ';
      // 削除チェック
      if (this.searchConditions.isDeletedSearch == false) {
        // 削除チェック無し
        selectSql += 'is_deleted = 0 ';
      } else {
        // 削除チェックあり
        selectSql += 'is_deleted = 1 ';
      }
      // 電子書類区分
      if (this.searchConditions.electronicDocumentsClass != '') {
        selectSql += 'AND electronic_documents_class = ' + this.searchConditions.electronicDocumentsClass + ' ';
      }
      // 電子書類名(あいまい検索対象)
      if (this.searchConditions.electronicDocumentsName != '') {
        const electronicDocumentsName = convertSqlLikeSpecialChar(this.searchConditions.electronicDocumentsName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(electronic_documents_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${electronicDocumentsName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' electronic_documents_class';
      // LIMIT
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    // 取得結果セット
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          ElectronicDocumentsClass: result[i].electronic_documents_class,
          ElectronicDocumentsName: result[i].electronic_documents_name,
          Sort: result[i].sort,
        };
        this.itemList.push(searchResult);
      }
    },
    // 編集ボタン押下時
    async clickEditBtn(electronicDocumentsClass) {
      // 電子書類保守修正画面へ遷移
      let query = {
        electronicDocumentsClass: electronicDocumentsClass
      };
      let route = this.$router.resolve({ name: 'SAVE-DOCUMENTS-MAINTENANCE-EDIT', query: query });
      window.open(route.href, '_blank');
    },
    // 削除（取消）ボタン押下時
    async clickDeleteBtn(electronicDocumentsClass, isDelete) {
      const functionName = 'clickDeleteBtn';
      try {
        // 作業メッセージ
        let methodTitle = '';
        let methodMessage = '';
        if (isDelete == true) {
          methodTitle = '削除確認';
          methodMessage = '選択された電子書類を削除します。';
        } else {
          methodTitle = '削除取消確認';
          methodMessage = '選択された電子書類の削除を取り消します。';
        }
        // 保存確認ダイアログを表示
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div', methodMessage));
        confirmMessage.push(this.$createElement('div', 'よろしいですか？'));
        let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: methodTitle});
        if (retConfirm == true) {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn(electronicDocumentsClass, isDelete);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 削除（取消）処理
    async execDeleteBtn(electronicDocumentsClass, isDelete) {
      const functionName = 'execDeleteBtn';
      //console.log('電子書類マスタ削除（取消）処理');
      let colList = [];
      // 削除フラグ
      colList.push(CreateColRow('is_deleted', isDelete == true ? 1 : 0, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_electronic_documents');
      updateSql += ' WHERE ';
      updateSql += 'electronic_documents_class = ' + electronicDocumentsClass + ' ';
      updateSql += 'AND is_deleted = ' + (isDelete == true ? '0' : '1') + ' ';

      let sqlList = [];
      sqlList.push(updateSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  }
};
</script>