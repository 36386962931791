<template>
  <b-modal centered hide-footer hide-header-close :id="id" no-close-on-esc no-close-on-backdrop scrollable size="xl" :title="title" @show="onShow">
    <b-container fluid>
      <b-row>
        <b-col class="p-0">
          <b-card no-body>
            <b-card-body class="p-2">
              <h5><a v-b-toggle.collapse-1 title="クリックすると出力範囲指定を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 検索条件</a></h5>
              <b-collapse id="collapse-1" :visible="true" class="px-3">
                <b-form @submit.prevent="onSearchButtonClick();" id="inventoryProductSearchForm" class="form-horizontal">
                  <validation-observer ref="observer">
                    <b-row>
                      <b-col md="12" lg="6">
                        <b-form-group description="製品名を入力してください。" label="製品名" label-cols-sm="3" label-cols-lx="2" label-size="sm" size="sm">
                          <b-form-input maxlength="40" size="sm" type="search" v-model="searchCondition.productName"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="6">
                        <b-form-group description="製品コードを入力してください。" label="製品コード" label-cols-sm="3" label-cols-lx="2" label-size="sm" size="sm">
                          <validation-provider rules="numeric|max:8" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <b-form-input maxlength="8" size="sm" type="search" v-model="searchCondition.productId"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" lg="6">
                        <b-form-group description="ハイフン区切りで入力してください。ブランクにすると全置き場指定となります。" label="置き場所" label-cols-sm="3" label-cols-lx="2" label-size="sm" size="sm">
                          <validation-provider rules="max:11|place" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <b-form-input maxlength="11" size="sm" type="search" v-model="searchCondition.place"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="6">
                        <b-form-group description="9A製品を区別するか指定してください。" label="9A製品" label-cols-sm="3" label-cols-lx="2" label-size="sm" size="sm">
                          <b-form-select :options="ignore9AOptions" size="sm" v-model="searchCondition.ignore9A"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-sm-center">
                      <b-col class="text-center" sm="5" lg="4" xl="3">
                        <b-button block pill size="sm" variant="success" type="submit" form="inventoryProductSearchForm">
                          <span class="oi oi-magnifying-glass"></span> 検索
                        </b-button>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-form>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="px-0">
          <div class="border px-4 py-3 mb-2 bg-white">
            <b-row v-if="alertDangerMessages.length > 0">
              <b-col>
                <b-alert show variant="danger" class="mt-2" v-if="alertDangerMessages.length > 0">
                  <ul v-for="(message,index) in alertDangerMessages" :key="index" style="list-style: none;">
                    <li>{{message}}</li>
                  </ul>
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  :bordered="true"
                  :busy="isBusy"
                  :current-page="currentPage"
                  :fields="fields"
                  :filter="filter"
                  :items="items"
                  :per-page="perPage"
                  :responsive="true"
                  show-empty
                  small
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- 選択ボタン -->
                  <template #cell(choice)="data">
                    <b-button size="sm" @click="onChoiceButtonClick(data)">選択</b-button>
                  </template>
                  <!-- 置き場所 -->
                  <template #cell(place)="data">
                    <div class="text-center">{{ `${data.item.place_1 === null ? '' : data.item.place_1}-${data.item.place_2 === null ? '' : data.item.place_2}-${data.item.place_3 === null ? '' : data.item.place_3}-${data.item.place_4 === null ? '' : data.item.place_4}` }}</div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col cols="6">
                <small>{{ getPagingMessage }}</small>
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-row>
              <b-col class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filter != null ? filterRows : items.length"
                  :per-page="perPage == -1 ? items.length : perPage"
                  align="center"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="$bvModal.hide(id)">キャンセル</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, executeSelectSql } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'inventory-product-search';
// 一覧最大件数
const SEARCH_RESULT_MAX = 1000;

export default {
  name: 'INVENTORY-PRODUCT-SEARCH',
  props: {
    id: {
      type: String,
    },
    officeId: {
      type: Number,
    }
  },
  computed: {
    /**
     * ページの表示件数
     */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  data() {
    return {
      menu_type: 'user',
      title: '製品選択',
      searchCondition: {
        productId: '',
        productName: '',
        place: '',
        ignore9A: 0
      },
      ignore9AOptions: [
        { value: 0, text: '区別しない' },
        { value: 1, text: '9A製品以外' },
        { value: 2, text: '9A製品のみ' },
      ],
      // 製品情報リスト
      items: [],
      // Dangerアラートメッセージ
      alertDangerMessages: [],
      // 
      // テーブルビジーフラグ
      isBusy: false,
      // テーブルフィールド
      fields: DataTblDef.inventory_product_search_table_fields,
      // テーブルフィルターキーワード
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  methods: {
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索ボタンクリック処理
     */
    async onSearchButtonClick() {
      const functionName = 'onSearchButtonClick';

      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
        return;
      }

      if (this.isBusy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.isBusy = true;

      this.alertDangerMessages = [];

      let sql = 'SELECT' +
          ' subquery.product_id' +
          ',subquery.product_name_kanji' +
          ',subquery.place_1' +
          ',subquery.place_2' +
          ',subquery.place_3' +
          ',subquery.place_4 ' +
        'FROM ' +
          '(' +
            'SELECT' +
              ' mpd.product_id' +
              ',mp.product_name_kanji' +
              ',IF(mpd.place_1 IS NULL, \'\', TRIM(mpd.place_1)) AS place_1' +
              ',IF(mpd.place_2 IS NULL, \'\', TRIM(mpd.place_2)) AS place_2' +
              ',IF(mpd.place_3 IS NULL, \'\', TRIM(mpd.place_3)) AS place_3' +
              ',IF(mpd.place_4 IS NULL, \'\', TRIM(mpd.place_4)) AS place_4 ' +
            'FROM ' +
              'm_products_details mpd ' +
              'INNER JOIN m_products mp ' +
                'ON mp.product_id = mpd.product_id ' +
            'WHERE ' +
              `mpd.office_id = ${this.officeId} `;
      // 検索条件：製品コード
      if (this.searchCondition.productId !== '') {
        sql += `AND mpd.product_id like '${this.searchCondition.productId}%' `;
      }
      // 検索条件：製品名
      if (this.searchCondition.productName !== '') {
        sql += `AND (mp.product_name_kanji LIKE '%${this.searchCondition.productName}%' OR mp.product_name_kana LIKE '%${this.searchCondition.productName}%') `;
      }
      // 検索条件：置き場所
      if (this.searchCondition.place !== '') {
        const words = this.searchCondition.place.split('-');
        sql += (words[0] === '') ? 'AND (mpd.place_1 IS NULL OR TRIM(mpd.place_1) = \'\') ' : `AND mpd.place_1 = '${words[0]}' `;
        sql += (words[1] === '') ? 'AND (mpd.place_2 IS NULL OR TRIM(mpd.place_2) = \'\') ' : `AND mpd.place_2 = '${words[1]}' `;
        sql += (words[2] === '') ? 'AND (mpd.place_3 IS NULL OR TRIM(mpd.place_3) = \'\') ' : `AND mpd.place_3 = '${words[2]}' `;
        sql += (words[3] === '') ? 'AND (mpd.place_4 IS NULL OR TRIM(mpd.place_4) = \'\') ' : `AND mpd.place_4 = '${words[3]}' `;
      }
      // 検索条件：9A製品区別
      switch (this.searchCondition.ignore9A) {
      case 1: // 9A製品以外
        sql += 'AND mp.is_9A = 0 ';
        break;
      case 2: // 9A製品のみ
        sql += 'AND mp.is_9A = 1 ';
        break;
      default: // 区別しない
        break;
      }
      sql += ') subquery ' +
        'ORDER BY' +
          ' subquery.place_1' +
          ',subquery.place_2' +
          ',subquery.place_3' +
          ',subquery.place_4' +
          ',subquery.product_id ' +
        `LIMIT ${SEARCH_RESULT_MAX};`;

      // SQLの実行
      try {
        this.items = await executeSelectSql(sql);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.items = [];
        this.isBusy = false;
        return;
      }

      if (this.items.length >= SEARCH_RESULT_MAX) {
        this.alertDangerMessages.push(DISP_MESSAGES.WARNING['2002']);
      }
      this.isBusy = false;
    },
    /**
     * モーダルを閉じます。
     * @param {Object} choiceData - 選択されたデータ
     */
    onChoiceButtonClick(choiceData) {
      this.$emit('from-child', choiceData);
      this.$bvModal.hide(this.id);
    },
    /**
     * モーダル表示時に検索条件を初期化します。
     */
    onShow() {
      this.searchCondition.productId = '';
      this.searchCondition.productName = '';
      this.searchCondition.place = '';
      this.searchCondition.ignore9A = 0;
      this.items = [];
    }
  }
}
</script>