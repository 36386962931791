<template>
  <!-- 仕入先現場コード保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 仕入先現場コード保守照会</strong></h5>
                <router-link to="/suppliers-sites-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 仕入先コード  -->
                <div class="col-12 form-group">
                  <label for="supplier_id" class="form-label">仕入先コード： {{obj.supplier_id}} &nbsp;&nbsp;&nbsp; {{obj.supplierName}}</label>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 得意先コード -->
                <div class="col-12 form-group">
                  <label for="client_id" class="form-label">得意先コード： {{obj.client_id}} &nbsp;&nbsp;&nbsp; {{obj.clientName}}</label>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場コード -->
                <div class="col-12 form-group">
                  <label for="site_id" class="form-label">現場コード： {{obj.site_id}} &nbsp;&nbsp;&nbsp; {{obj.client_site_name_kanji}}</label>
                </div>
              </div>
              <div class="row">
                <!-- 相手先現場コード -->
                <div class="col-12 form-group">
                  <label for="supplier_control_site_id" class="form-label">相手先現場コード： {{obj.supplier_control_site_id}}</label>
                </div>
              </div>
            </div>
            <!-- 削除・編集ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-cancel btn-block" @click="clickDeleteBtn"><span class="oi oi-circle-x"></span> 削除</button>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn"><span class="oi oi-circle-check"></span> 編集</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

// ログ出力用モジュール名
const MODULE_NAME = 'suppliers-sites-inquiry';

export default {
  name: 'SUPPLIERS-SITES-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入先現場コード保守照会',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 仕入先現場コード対象
      obj: {},
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0 || this.successMessages.length > 0
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData({supplier_id, client_id, site_id}) {
      const functionName = 'fetchData'
      this.busy = true;
      try {
        //  仕入先現場コードマスタのデータを請求
        const objResult = await this.searchSuppliersSites(supplier_id, client_id, site_id)
  
        // データセット 
        this.obj = {...objResult}
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']); 
      }
      this.busy = false;
    },
    /**
     * 仕入先現場コードマスタを検索します。
     * @param {Int} supplier_id
     * @param {Int} client_id
     * @param {Int} site_id
     * @returns {Object} 仕入先現場コードマスタデータ
     */
    async searchSuppliersSites(supplier_id, client_id, site_id) {
      const functionName = 'searchSuppliersSites';

      let result = null;
      // 仕入先現場コードと取引先マスタと現場マスタの結合
      const SQLs = `SELECT  
                    MSS.supplier_id,
                    MSS.client_id,
                    MSS.site_id,
                    MSS.supplier_control_site_id,
                    MC.client_name_kanji AS supplierName,
                    MC2.client_name_kanji AS clientName,
                    MCS.client_site_name_kanji
                    FROM 
                    m_suppliers_sites MSS 
                    LEFT JOIN m_clients MC ON MSS.supplier_id = MC.client_id AND MC.client_class = ${Const.ClientClass.supplier}
                    LEFT JOIN m_clients MC2 ON MSS.client_id = MC2.client_id AND MC2.client_class = ${Const.ClientClass.customer}
                    LEFT JOIN m_clients_sites MCS ON MSS.client_id = MCS.client_id AND MSS.site_id = MCS.site_id 
                    WHERE 
                    MSS.supplier_id = ${supplier_id}  
                    AND MSS.client_id = ${client_id}  
                    AND MSS.site_id = ${site_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      if (body.data[0].length != 0){
        return body.data[0][0]
      } else {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
    },
    /**
     * 編集ボタンの押下
     */
    async clickEditBtn() {
      this.$router.push({ 
        name: 'SUPPLIERS-SITES-EDIT', 
        query: { 
          supplier_id: this.obj.supplier_id,
          client_id: this.obj.client_id,
          site_id: this.obj.site_id,
        },
      });
    },
    /**
     * 削除ボタン押下時
     */
    clickDeleteBtn() {
      this.$bvModal.msgBoxConfirm('仕入先現場コードデータを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDel()
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'SUPPLIERS-SITES-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1002']] }  
            });
          }
          this.$store.commit('setLoading', false);
        }
      });
    },
    /**
     * 削除処理
     */
    async execDel() {
      const functionName = 'execDel';

      // 仕入先現場コードマスタ
      const SQLs = `DELETE FROM m_suppliers_sites 
                    WHERE 
                    supplier_id = ${this.obj.supplier_id}
                    AND client_id = ${this.obj.client_id}
                    AND site_id = ${this.obj.site_id}`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
