<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col class="md-12">
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 受注在庫引当変更</strong></h5>
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'受注在庫引当一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
                <b-row>
                  <b-col>
                    <b-table-simple
                      :bordered="bordered"
                      :borderless="borderless"
                      :outlined="outlined"
                      small
                      caption-top
                    >
                      <caption>引当元</caption>
                      <b-tbody>
                        <b-tr>
                          <b-td>受注番号</b-td>
                          <b-td>{{hikiateMoto.receivedOrderId}}</b-td>
                          <b-td>入力日</b-td>
                          <b-td>{{hikiateMoto.inputDate}}</b-td>
                          <b-td>納品指定</b-td>
                          <b-td>{{hikiateMoto.deliveryDesignDate}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>行番号</b-td>
                          <b-td>{{hikiateMoto.receivedOrderRow}}</b-td>
                          <b-td>得意先</b-td>
                          <b-td colspan="3">{{hikiateMoto.clientId + ' ' + hikiateMoto.clientName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td></b-td>
                          <b-td></b-td>
                          <b-td>現場</b-td>
                          <b-td colspan="3">{{hikiateMoto.siteId + ' ' + hikiateMoto.shippingCode + ' ' + hikiateMoto.siteName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>製品コード</b-td>
                          <b-td>{{hikiateMoto.productId}}</b-td>
                          <b-td>製品名</b-td>
                          <b-td colspan="3">{{hikiateMoto.productName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td></b-td>
                          <b-td colspan="5">
                            <b-table
                              :items="hikiateMotoItem"
                              :fields="hikiateMotoFields"
                            >
                            </b-table>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-table-simple
                      :bordered="bordered"
                      :borderless="borderless"
                      :outlined="outlined"
                      small
                      caption-top
                    >
                      <caption>引当先</caption>
                      <b-tbody>
                        <b-tr>
                          <b-td>受注番号</b-td>
                          <validation-provider name="receivedOrderId" :rules="{required:true,numeric:true,is_not:hikiateMoto.receivedOrderId ? hikiateMoto.receivedOrderId.toString(): ''}" v-slot="{ classes,errors }">
                            <b-td :class="classes" >
                              <b-input-group>
                                <b-form-input type="text" name="receivedOrderId" v-model="hikiateSaki.receivedOrderId" style="width: 120px;"></b-form-input>
                                <b-button pill variant="success" v-b-tooltip.hover.noninteractive title="入力された受注で対象の製品のある行番号を検索します。受注番号の入力後に押下して下さい。" @click="clearAlert(); searchRowNoButton();">
                                  <span class="oi oi-magnifying-glass"></span> 行番号検索 
                                </b-button>
                              </b-input-group>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-td>
                          </validation-provider>
                          <b-td>入力日</b-td>
                          <b-td>{{hikiateSaki.inputDate}}</b-td>
                          <b-td>納品指定</b-td>
                          <b-td>{{hikiateSaki.deliveryDesignDate}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>行番号</b-td>
                          <validation-provider name="receivedOrderRow" :rules="{required:true,numeric:true}" v-slot="{ classes,errors }">
                            <b-td :class="classes" >
                              <b-input-group>
                                <b-form-select
                                  id="selectDeliveryDesignation"
                                  v-model="hikiateSaki.receivedOrderRow"
                                  :options="hikiateSaki.receivedOrderRowList"
                                />
                                <input type="hidden" name="receivedOrderRow" v-model="hikiateSaki.receivedOrderRow" />
                              </b-input-group>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-td>
                          </validation-provider>
                          <b-td>得意先</b-td>
                          <b-td colspan="3">{{hikiateSaki.clientId + ' ' + hikiateSaki.clientName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td colspan="2">
                            <b-button pill variant="success" @click="clearAlert(); searchButton();" :disabled="hikiateSaki.receivedOrderRow==null">
                              <span class="oi oi-magnifying-glass"></span> 引当先検索 
                            </b-button>
                          </b-td>
                          <b-td>現場</b-td>
                          <b-td colspan="3">{{hikiateSaki.siteId + ' ' + hikiateSaki.shippingCode + ' ' + hikiateSaki.siteName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>製品コード</b-td>
                          <b-td>{{hikiateMoto.productId}}</b-td>
                          <b-td>製品名</b-td>
                          <b-td colspan="3">{{hikiateSaki.productName}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td></b-td>
                          <b-td colspan="5">
                            <b-table
                              :items="hikiateSaki.hikiateSakiItem"
                              :fields="hikiateSakiFields"
                            >
                              <template #cell(ReserveQuantityChange)="data">
                                <validation-provider name="ReserveQuantityChange" :rules="{required: true, between: {min: 1, max: hikiateMoto.reserveQuantity < hikiateSaki.hikiateSakiItem[0].RemainingQuantity - hikiateSaki.hikiateSakiItem[0].ReserveQuantity ? hikiateMoto.reserveQuantity : hikiateSaki.hikiateSakiItem[0].RemainingQuantity - hikiateSaki.hikiateSakiItem[0].ReserveQuantity }}" v-slot="{ classes,errors }">
                                  <b-input-group style="width: 80px;">
                                    <b-row>
                                      <b-col lg="12" :class="classes">
                                        <b-form-input type="number" name="ReserveQuantityChange" v-model.number="data.item.ReserveQuantityChange" :readonly="hikiateSaki.dataFlg==false" @change="calcTotalReserveQuantity" />
                                      </b-col>
                                      <b-col lg="12" :class="classes">
                                        <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                      </b-col>
                                    </b-row>
                                  </b-input-group>
                                </validation-provider>
                              </template>
                            </b-table>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card-body>
            <b-card-footer>
              <b-row class="justify-content-md-center pb-4">
                <b-col lg="12">
                  <b-btn-toolbar class="mt-2">
                    <b-button class="mr-2" pill variant="success" v-b-tooltip.hover.noninteractive title="受注照会画面を表示します。" @click="clickReceivedOrderInquiry">
                      <span class="oi oi-eye"></span> 受注照会
                    </b-button>
                    <b-button class="mr-2" pill variant="success" v-b-tooltip.hover.noninteractive title="入力されたデータを保存します。" @click="clearAlert(); saveData();" :disabled="hikiateSaki.dataFlg==false">
                      <span class="oi oi-circle-check"></span> 保存
                    </b-button>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, executeSelectSql, formatDate, getListValue, addOperationLogs, getControlMaster, CreateColRow, CreateUpdateSql, executeTransactSqlList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_service_classes } from '@/graphql/queries';

const MODULE_NAME = 'received-order-stock-reserve-change';

export default {
  name: 'RECEIVED-ORDER-STOCK-RESERVE-CHANGE',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '受注在庫引当変更',
      username: '',
      listUpdatedSelect: [],
      updatedInitCnt: 0,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 引当元
      hikiateMoto: {
        // 営業所コード
        officeId: null,
        // 受注番号
        receivedOrderId: null,
        // 受注行番号
        receivedOrderRow: null,
        // 入力日
        inputDate: null,
        // 納品指定日
        deliveryDesignDate: '',
        // 取引先区分
        clientClass: '',
        // 取引先コード
        clientId: '',
        // 取引先名
        clientName: '',
        // 現場コード
        siteId: '',
        // 配送コード
        shippingCode: '',
        // 現場名
        siteName: '',
        // 製品コード
        productId: null,
        // 製品名
        productName: '',
        // サービス区分
        serviceClass: '',
        // 単位
        unit: '',
        // 受注数
        quantity: 0,
        // 受注残
        remainingQuantity: 0,
        // 引当数
        reserveQuantity: 0,
      },
      // 引当先
      hikiateSaki: {
        // 受注番号
        receivedOrderId: null,
        // 受注行番号
        receivedOrderRow: null,
        receivedOrderRowList: [{value: null, text: '行番号検索して下さい'}],
        // 入力日
        inputDate: null,
        // 納品指定日
        deliveryDesignDate: '',
        // 取引先区分
        clientClass: '',
        // 取引先コード
        clientId: '',
        // 取引先名
        clientName: '',
        // 現場コード
        siteId: '',
        // 配送コード
        shippingCode: '',
        // 現場名
        siteName: '',
        // 製品名
        productName: '',
        // 引当先（テーブル用）
        hikiateSakiItem:[{
          // 受注番号
          ReceivedOrderId: null,
          // 受注行番号
          ReceivedOrderRow: null,
          // サービス区分
          ServiceClass: '',
          // 単位
          Unit: '',
          // 受注数
          Quantity: 0,
          // 受注残
          RemainingQuantity: 0,
          // 引当済数
          ReserveQuantity: 0,
          // 引当数（変更）
          ReserveQuantityChange: 0,
          // 引当総数
          TotalReserveQuantity: 0,
        }],
        // 受注データの有無
        dataFlg: false,
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // テーブル情報
      bordered: false,
      borderless: true,
      outlined: false,
      // サービス区分
      serviceList: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // パラメータ
      receivedOrderId: 0,
      receivedOrderRow: 0,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    this.receivedOrderRow = this.$route.query.receivedOrderRow;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 引当元一覧 */
    hikiateMotoItem: function() {
      return [{
        ServiceClass: this.hikiateMoto.serviceClass,
        Unit: this.hikiateMoto.unit,
        Quantity: this.hikiateMoto.quantity,
        RemainingQuantity: this.hikiateMoto.remainingQuantity,
        ReserveQuantity: this.hikiateMoto.reserveQuantity,
      }];
    },
    /* 引当元フィールド */
    hikiateMotoFields: function() {
      return [
        {
          key: 'ServiceClass',
          label: 'サービス',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'Quantity',
          label: '受注数',
          tdClass: 'text-right',
        },
        {
          key: 'RemainingQuantity',
          label: '受注残',
          tdClass: 'text-right',
        },
        {
          key: 'ReserveQuantity',
          label: '引当数',
          tdClass: 'text-right',
        },
      ];
    },
    /* 引当元フィールド */
    hikiateSakiFields: function() {
      return [
        {
          key: 'ServiceClass',
          label: 'サービス',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'Quantity',
          label: '受注数',
          tdClass: 'text-right',
        },
        {
          key: 'RemainingQuantity',
          label: '受注残',
          tdClass: 'text-right',
        },
        {
          key: 'ReserveQuantity',
          label: '引当済数',
          tdClass: 'text-right',
        },
        {
          key: 'ReserveQuantityChange',
          label: '引当数',
        },
        {
          key: 'TotalReserveQuantity',
          label: '引当総数',
          tdClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData'
      this.$store.commit('setLoading', true);
      try {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // ログインユーザーの情報
        let user = this.$store.getters.user;
        this.username = user.username;
        //console.log('ログイン情報');
        //console.log(this.username);
        // 引数取得
        this.hikiateMoto.receivedOrderId = this.receivedOrderId;
        this.hikiateMoto.receivedOrderRow = this.receivedOrderRow;      
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        let serviceListResult = await API.graphql(graphqlOperation(list_m_service_classes));
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for (let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月取得
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 初期データ設定
        await this.setInitData();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      const functionName = 'setInitData';
      // 検索条件作成
      let selectSql = '';
      selectSql = await this.makeSelectSqlInit();
      //console.log(selectSql);
      try {
        //console.log('条件取得');
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if(dataResult != null){
          await this.setResultInit(dataResult);
        }else{
          this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 初期データ取得SELECT文作成 */
    async makeSelectSqlInit() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.office_id';
      selectSql += ',orders_receives.input_date';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.client_class';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name';
      selectSql += ',orders_receives.service_class';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.remaining_quantity - IfNull(ships.shipping_quantity,0) AS remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity - IfNull(ships.shipping_quantity,0) AS reserve_quantity';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'LEFT JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id = ' + this.hikiateMoto.receivedOrderId + ' ';
      // 受注行番号
      selectSql += 'AND orders_receives.order_receive_row = ' + this.hikiateMoto.receivedOrderRow + ' ';

      return selectSql;
    },
    /* 取得結果セット（初期値） */
    async setResultInit(result) {
      if (result.length > 0) {
        // 営業所コード
        this.hikiateMoto.officeId = result[0].office_id;
        // 入力日
        this.hikiateMoto.inputDate = formatDate(result[0].input_date);
        // 納品指定日
        this.hikiateMoto.deliveryDesignDate = result[0].delivery_design_date == null ? '0000/00/00' : formatDate(result[0].delivery_design_date);
        // 取引先区分
        this.hikiateMoto.clientClass = result[0].client_class;
        // 取引先コード
        this.hikiateMoto.clientId = result[0].client_id;
        // 取引先名
        this.hikiateMoto.clientName = result[0].client_name;
        // 現場コード
        this.hikiateMoto.siteId = result[0].site_id;
        // 配送コード
        this.hikiateMoto.shippingCode = result[0].shipping_code;
        // 現場名
        this.hikiateMoto.siteName = result[0].client_site_name;
        // 製品コード
        this.hikiateMoto.productId = result[0].product_id;
        // 製品名
        this.hikiateMoto.productName = result[0].product_name;
        // サービス区分
        this.hikiateMoto.serviceClass = getListValue(this.serviceList, result[0].service_class);
        // 単位
        this.hikiateMoto.unit = result[0].unit;
        // 受注数
        this.hikiateMoto.quantity = result[0].order_receive_quantity;
        // 受注残
        this.hikiateMoto.remainingQuantity = result[0].remaining_quantity;
        // 引当数
        this.hikiateMoto.reserveQuantity = result[0].reserve_quantity;
      }
    },
    // 行番号検索ボタン
    async searchRowNoButton() {
      const functionName = 'searchRowNoButton';
      try {
        await this.$refs.observer.validate();
        const errors = this.$refs.observer.errors;
        if (errors.receivedOrderId.length > 0) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          this.$store.commit('setLoading', true);
          await this.searchRowNo();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 行番号検索処理
    async searchRowNo() {
      let selectSql = '';
      selectSql += 'SELECT orders_receives.order_receive_row';
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id = ' + this.hikiateSaki.receivedOrderId + ' ';
      // 製品コード
      selectSql += 'AND orders_receives.product_id = ' + this.hikiateMoto.productId + ' ';
      // 営業所コード
      selectSql += 'AND orders_receives.office_id = ' + this.hikiateMoto.officeId + ' ';
      // 受注伝票種別
      selectSql += 'AND orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.normal + ' ';
      // 配送種別区分
      selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      // セット品区分
      selectSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      // 削除済みフラグ
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null && resultData.length > 0) {
        this.hikiateSaki.receivedOrderRowList = [];
        for (let i = 0; i < resultData.length; i++) {
          if (i == 0) {
            this.hikiateSaki.receivedOrderRow = resultData[i].order_receive_row;
          }
          this.hikiateSaki.receivedOrderRowList.push({value: resultData[i].order_receive_row, text: resultData[i].order_receive_row});
        }
      } else {
        this.hikiateSaki.receivedOrderRow = null;
        this.hikiateSaki.receivedOrderRowList = [{value: null, text: '製品行無し'}];
      }
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        await this.$refs.observer.validate();
        const errors = this.$refs.observer.errors;
        //console.log(errors);
        if (errors.receivedOrderId.length > 0 || errors.receivedOrderRow.length > 0) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          this.$store.commit('setLoading', true);
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.setSakiInit();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSqlSaki();
      //console.log(selectSql);
      try {
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          // データ設定
          await this.setResultSaki(resultData);
          // 検索時の排他制御用の変数作成
          await this.createUpdatedSelectSearch();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 引当先受注データ取得SELECT文作成 */
    async makeSelectSqlSaki() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.office_id';
      selectSql += ',orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      selectSql += ',orders_receives.input_date';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.client_class';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name';
      selectSql += ',orders_receives.product_name';
      selectSql += ',orders_receives.service_class';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.remaining_quantity - IfNull(ships.shipping_quantity,0) AS remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity - IfNull(ships.shipping_quantity,0) AS reserve_quantity';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'LEFT JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id = ' + this.hikiateSaki.receivedOrderId + ' ';
      // 受注行番号
      selectSql += 'AND orders_receives.order_receive_row = ' + this.hikiateSaki.receivedOrderRow + ' ';
      // 製品コード
      selectSql += 'AND orders_receives.product_id = ' + this.hikiateMoto.productId + ' ';
      // 営業所コード
      selectSql += 'AND orders_receives.office_id = ' + this.hikiateMoto.officeId + ' ';
      // 受注伝票種別
      selectSql += 'AND orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.normal + ' ';
      // 配送種別区分
      selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      // セット品区分
      selectSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      // 削除済みフラグ
      selectSql += 'AND orders_receives.is_deleted = 0 ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResultSaki(result) {
      if (result.length > 0) {
        // 入力日
        this.hikiateSaki.inputDate = formatDate(result[0].input_date);
        // 納品指定日
        this.hikiateSaki.deliveryDesignDate = result[0].delivery_design_date == null ? '0000/00/00' : formatDate(result[0].delivery_design_date);
        // 取引先区分
        this.hikiateSaki.clientClass = result[0].client_class;
        // 取引先コード
        this.hikiateSaki.clientId = result[0].client_id;
        // 取引先名
        this.hikiateSaki.clientName = result[0].client_name;
        // 現場コード
        this.hikiateSaki.siteId = result[0].site_id;
        // 配送コード
        this.hikiateSaki.shippingCode = result[0].shipping_code;
        // 現場名
        this.hikiateSaki.siteName = result[0].client_site_name;
        // 製品名
        this.hikiateSaki.productName = result[0].product_name;
        // 引当先（テーブル用）
        let hikiateSakiItem = {
          // 受注番号
          ReceivedOrderId: result[0].order_receive_id,
          // 受注行番号
          ReceivedOrderRow: result[0].order_receive_row,
          // サービス区分
          ServiceClass: getListValue(this.serviceList, result[0].service_class),
          // 単位
          Unit: result[0].unit,
          // 受注数
          Quantity: result[0].order_receive_quantity,
          // 受注残
          RemainingQuantity: result[0].remaining_quantity,
          // 引当済数
          ReserveQuantity: result[0].reserve_quantity,
          // 引当数（変更）
          ReserveQuantityChange: 0,
          // 引当総数
          TotalReserveQuantity: result[0].reserve_quantity,
        };
        this.hikiateSaki.hikiateSakiItem = [];
        this.hikiateSaki.hikiateSakiItem.push(hikiateSakiItem);
        // データの有無
        this.hikiateSaki.dataFlg = true;
      }
    },
    /* 引当先情報初期化 */
    setSakiInit: function() {
      // 入力日
      this.hikiateSaki.inputDate = '';
      // 納品指定日
      this.hikiateSaki.deliveryDesignDate = '';
      // 取引先区分
      this.hikiateSaki.clientClass = '';
      // 取引先コード
      this.hikiateSaki.clientId = '';
      // 取引先名
      this.hikiateSaki.clientName = '';
      // 現場コード
      this.hikiateSaki.siteId = '';
      // 配送コード
      this.hikiateSaki.shippingCode = '';
      // 現場名
      this.hikiateSaki.siteName = '';
      // 製品名
      this.hikiateSaki.productName = '';
      // 引当先（テーブル用）
      let hikiateSakiItem = {
        // 受注番号
        ReceivedOrderId: null,
        // 受注行番号
        ReceivedOrderRow: null,
        // サービス区分
        ServiceClass: '',
        // 単位
        Unit: '',
        // 受注数
        Quantity: 0,
        // 受注残
        RemainingQuantity: 0,
        // 引当済数
        ReserveQuantity: 0,
        // 引当数（変更）
        ReserveQuantityChange: 0,
        // 引当総数
        TotalReserveQuantity: 0,
      };
      this.hikiateSaki.hikiateSakiItem = [];
      this.hikiateSaki.hikiateSakiItem.push(hikiateSakiItem);
      // データの有無
      this.hikiateSaki.dataFlg = false;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 引当総数の計算 */
    calcTotalReserveQuantity: function() {
      if (this.hikiateSaki.hikiateSakiItem.length > 0) {
        this.hikiateSaki.hikiateSakiItem[0].TotalReserveQuantity = Number(this.hikiateSaki.hikiateSakiItem[0].ReserveQuantity) + Number(this.hikiateSaki.hikiateSakiItem[0].ReserveQuantityChange);
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      await this.$refs.observer.validate();
      const errors = this.$refs.observer.errors;

      // 引当数のバリデーションが通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (errors.ReserveQuantityChange.length > 0) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 保存処理
      if (await this.execSave() == true) {
        // 受注在庫引当一覧へ遷移
        this.$router.push({ 
          name: 'RECEIVED-ORDER-STOCK-RESERVE-LIST', 
          params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] },
        });
      }
      //console.log('保存処理終了');
    },
    /* 保存処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 受注データ更新処理
        await this.updateOrdersReceives();
        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 作成した登録用SQLを全実行
        if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return retResult;
        }
        retResult = true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
      return retResult;
    },
    /* 受注データ更新SQL作成 */
    async updateOrdersReceives() {
      //console.log('受注データ更新SQL作成');
      /* 受注データ更新 */
      // 引当先の引当に入力値分増加
      let updateSql = '';
      let colList = [];
      // 未引当数
      colList.push(CreateColRow('reserve_quantity_incomplete', 'reserve_quantity_incomplete - ' + this.hikiateSaki.hikiateSakiItem[0].ReserveQuantityChange, 'NUMBER'));
      // 引当済数
      colList.push(CreateColRow('reserve_quantity', 'reserve_quantity + ' + this.hikiateSaki.hikiateSakiItem[0].ReserveQuantityChange, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.username, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'order_receive_id = ' + this.hikiateSaki.hikiateSakiItem[0].ReceivedOrderId + ' ';
      updateSql += 'AND order_receive_row = ' + this.hikiateSaki.hikiateSakiItem[0].ReceivedOrderRow + ' ';
      //console.log(updateSql);
      this.transactSqlList.push(updateSql);
      // 引当元の引当から入力値分減少
      updateSql = '';
      colList = [];
      // 未引当数
      colList.push(CreateColRow('reserve_quantity_incomplete', 'reserve_quantity_incomplete + ' + this.hikiateSaki.hikiateSakiItem[0].ReserveQuantityChange, 'NUMBER'));
      // 引当済数
      colList.push(CreateColRow('reserve_quantity', 'reserve_quantity - ' + this.hikiateSaki.hikiateSakiItem[0].ReserveQuantityChange, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.username, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'order_receive_id = ' + this.hikiateMoto.receivedOrderId + ' ';
      updateSql += 'AND order_receive_row = ' + this.hikiateMoto.receivedOrderRow + ' ';
      //console.log(updateSql);
      this.transactSqlList.push(updateSql);
    },
    /* 受注照会ボタン押下時 */
    async clickReceivedOrderInquiry() {
      // 受注照会画面を別タブで表示
      //console.log('受注照会');
      let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INQUIRY' });
      window.open(route.href, '_blank');
    },
    /* キャンセルボタン押下時 */
    async clickBack() {
      // 受注在庫引当一覧画面へ遷移
      this.$router.push({ name: 'RECEIVED-ORDER-STOCK-RESERVE-LIST' });
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(ships.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' ships.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
      this.updatedInitCnt = this.listUpdatedSelect.length;
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelectSearch() {
      // 最初に初期時点のリスト状態に戻す
      if (this.updatedInitCnt < this.listUpdatedSelect.length) {
        this.listUpdatedSelect.splice(this.updatedInitCnt + 1, this.listUpdatedSelect.length - this.updatedInitCnt)
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.hikiateSaki.hikiateSakiItem[0].ReceivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(ships.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' ships.order_receive_id = ' + this.hikiateSaki.hikiateSakiItem[0].ReceivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = this.updatedInitCnt; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
  }
}
</script>
<style scoped>
</style>