<template>
  <!-- 製品実績保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品実績保守照会</strong></h5>
                <router-link to="/products-results-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- 処理年月 -->
                <div class="col-12 form-group">
                  処理年月: {{productResultsObj.month_year | month_year}}
                </div>
              </div>
              <div class="row mt-2">
                <!-- 営業所 -->
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 form-group">
                  営業所: {{productResultsObj.officeName}}
                </div>
                <!-- 製品分類コード -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form-group">
                  製品分類コード: {{productResultsObj.product_id | product_class_id}}
                </div>
              </div>
              <div class="row mt-2">
                <!-- 製品コード -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                  製品コード: &nbsp;{{productResultsObj.product_id}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{productResultsObj.product_name_kanji}}
                </div>
              </div>
              <!-- テーブル -->
              <div class="row my-2">
                <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6 form-group">
                  <b-table
                    show-empty
                    :small="true"
                    :head-variant="headVariant"
                    :responsive="true"
                    :items="productResultsObj.tableItems"
                    :fields="fields"
                    :busy="busy"
                    :bordered="true"
                    :empty-text="emptyText"
                  >
                    <!-- テーブル読み込み時表示html -->
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>読み込んでいます...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <!-- 粗利額 -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="gross_profit">粗利額</label>
                  <input readonly type="text" id="gross_profit" class="form-control" v-model="productResultsObj.gross_profit" maxlength="12">
                </div>
              </div>
              <!-- 編集ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn()"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products-results-inquiry';

export default {
  name: 'PRODUCTS-RESULTS-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品実績保守照会',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // 製品実績対象
      productResultsObj: {},
      // 製品実績テーブル
      fields: DataTblDef.products_results_edit_list_fields,
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 請求年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    },
    product_class_id: function (product_id) {
      // 製品分類コードの表示方法
      return String(product_id).slice(0,4)
    },
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    // 編集画面からメッセージ
    this.successMessages = this.$route.params.successMessages || []
    this.errorMessages = this.$route.params.errorMessages || []
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} month_year - 処理年月
     * @param {String} office_class_id - 営業所分類コード
     * @param {String} product_id - 製品コード
     */
    async fetchData({month_year, office_class_id, product_id}) {
      this.busy = true;
      // 検索SQL
      month_year = Number(month_year)
      office_class_id = Number(office_class_id)
      product_id = Number(product_id)
      // 製品実績のデータを請求
      const productResults = await this.searchProductResults(month_year, office_class_id, product_id)
      productResults.officeName = `${productResults.office_id}:${productResults.office_name_kanji}`
      productResults.tableItems = [
        {
          item: '数量',
          purchase_stock: Number(productResults.purchase_stock_count).toLocaleString(),
          sales: Number(productResults.sales_count).toLocaleString(),
        },
        {
          item: '返品数量',
          purchase_stock: Number(productResults.purchase_stock_return_count).toLocaleString(),
          sales: Number(productResults.sales_return_count).toLocaleString(),
        },
        {
          item: '総数量',
          purchase_stock: Number(productResults.purchase_stock_total_count).toLocaleString(),
          sales: Number(productResults.sales_total_count).toLocaleString(),
        },
        {
          item: '額',
          purchase_stock: Number(productResults.purchase_stock_amount).toLocaleString(),
          sales: Number(productResults.sales_amount).toLocaleString(),
        },
        {
          item: '返品額',
          purchase_stock: Number(productResults.purchase_stock_return_amount).toLocaleString(),
          sales: Number(productResults.sales_return_amount).toLocaleString(),
        },
        {
          item: '総額',
          purchase_stock: Number(productResults.purchase_stock_total_amount).toLocaleString(),
          sales: Number(productResults.sales_total_amount).toLocaleString(),
        },
      ]
      productResults.gross_profit = Number(productResults.gross_profit).toLocaleString()
     
      // データをセット
      this.productResultsObj = {...productResults}
      this.busy = false;
    },
    /**
     * 製品実績マスタ検索
     * @param {Int} month_year - 処理年月
     * @param {Int} office_class_id - 営業所分類コード
     * @param {Int} product_id - 製品コード
     * @returns {<Object>} 検索結果
     */
    async searchProductResults(month_year, office_class_id, product_id) {
      const functionName = 'searchProductResults';
      let result = null;
      // 製品実績と営業所と製品の結合
      let SQLs = `SELECT  * 
      FROM t_products_results TPR 
      LEFT JOIN m_offices MO ON TPR.office_class_id = MO.office_id
      LEFT JOIN m_products MP USING(product_id)
      WHERE 
      TPR.month_year = ${month_year} 
      AND TPR.office_class_id = ${office_class_id}
      AND TPR.product_id = ${product_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0]
    },
    /**
     * 編集ボタンの押下
     */
    async clickEditBtn() {
      this.$router.push({ 
        name: 'PRODUCTS-RESULTS-EDIT', 
        query: { 
          month_year: this.productResultsObj.month_year,
          office_class_id: this.productResultsObj.office_class_id,
          product_id: this.productResultsObj.product_id,
        },
        params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
      });
    },
  }
}
</script>