<template>
  <!-- 製品実績保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品実績保守編集</strong></h5>
                <router-link to="/products-results-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <hr>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 処理年月 -->
                  <div class="col-12 form-group">
                   処理年月: {{productResultsObj.month_year | month_year}}
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 営業所 -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 form-group">
                    営業所: {{productResultsObj.officeName}}
                  </div>
                  <!-- 製品分類コード -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form-group">
                    製品分類コード: {{productResultsObj.product_id | product_class_id}}
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 製品コード -->
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                    製品コード: &nbsp;{{productResultsObj.product_id}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{productResultsObj.product_name_kanji}}
                  </div>
                </div>
                <!-- テーブル -->
                <div class="row my-2">
                  <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6 form-group">
                    <b-table
                      show-empty
                      :small="true"
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="productResultsObj.tableItems"
                      :fields="fields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 仕入 -->
                      <template #cell(purchase_stock)="data">
                        <validation-provider v-if="data.item.ruleMomey" rules="required|integer|max:8" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" id="next_month" class="form-control" v-model="data.item.purchase_stock" maxlength="8">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                        <validation-provider v-else rules="required|integer|max:5" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" id="next_month" class="form-control" v-model="data.item.purchase_stock" maxlength="5">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 売上 -->
                      <template #cell(sales)="data">
                        <validation-provider v-if="data.item.ruleMomey" rules="required|integer|max:8" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" id="sales" class="form-control" v-model="data.item.sales" maxlength="8">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                        <validation-provider v-else rules="required|integer|max:5" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" id="sales" class="form-control" v-model="data.item.sales" maxlength="5">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="row">
                  <!-- 粗利額 -->
                  <div class="col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|integer|max:8" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="gross_profit">粗利額</label>
                        <input type="text" id="gross_profit" class="form-control" v-model="productResultsObj.gross_profit" maxlength="8">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
              <!-- 保存ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                   <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, CreateColRow, CreateUpdateSql, getUserCol, getControlMaster, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products-results-edit';

export default {
  name: 'PRODUCTS-RESULTS-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品実績保守編集',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品実績対象
      productResultsObj: {},
      // 製品実績テーブル
      fields: DataTblDef.products_results_edit_list_fields,
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 請求年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    },
    product_class_id: function (product_id) {
      // 製品分類コードの表示方法
      return String(product_id).slice(0,4)
    },
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    this.$store.commit('setLoading', true);
    let controlData = await getControlMaster();
    // 現在処理年月の物と違う場合、照会画面に戻る
    if (Number(controlData.process_month_year) > Number(this.$route.query.month_year)) {
      this.$router.push({ 
        name: 'PRODUCTS-RESULTS-INQUIRY', 
        query: { 
          month_year: this.$route.query.month_year,
          office_class_id: this.$route.query.office_class_id,
          product_id: this.$route.query.product_id,
        },
        params: { errorMessages: ['現在処理年月より古いため編集できません。'] }  
      });
    }
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} month_year - 処理年月
     * @param {String} office_class_id - 営業所分類コード
     * @param {String} product_id - 製品コード
     */
    async fetchData({month_year, office_class_id, product_id}) {
      this.busy = true;
      // 検索SQL
      month_year = Number(month_year)
      office_class_id = Number(office_class_id)
      product_id = Number(product_id)
      // 製品実績のデータを請求
      const productResults = await this.searchProductResults(month_year, office_class_id, product_id)
      productResults.officeName = `${productResults.office_id}:${productResults.office_name_kanji}`
      productResults.tableItems = [
        {
          key: 'count',
          // 入力制限ルール、数量：false 金額：true
          ruleMomey: false,
          item: '数量',
          purchase_stock: productResults.purchase_stock_count,
          sales: productResults.sales_count,
        },
        {
          key: 'return_count',
          ruleMomey: false,
          item: '返品数量',
          purchase_stock: productResults.purchase_stock_return_count,
          sales: productResults.sales_return_count,
        },
        {
          key: 'total_count',
          ruleMomey: false,
          item: '総数量',
          purchase_stock: productResults.purchase_stock_total_count,
          sales: productResults.sales_total_count,
        },
        {
          key: 'amount',
          ruleMomey: true,
          item: '額',
          purchase_stock: productResults.purchase_stock_amount,
          sales: productResults.sales_amount,
        },
        {
          key: 'return_amount',
          ruleMomey: true,
          item: '返品額',
          purchase_stock: productResults.purchase_stock_return_amount,
          sales: productResults.sales_return_amount,
        },
        {
          key: 'total_amount',
          ruleMomey: true,
          item: '総額',
          purchase_stock: productResults.purchase_stock_total_amount,
          sales: productResults.sales_total_amount,
        },
      ]
     
      // データをセット
      this.productResultsObj = {...productResults}
      this.busy = false;
    },
    /**
     * 製品実績マスタ検索
     * @param {Int} month_year - 処理年月
     * @param {Int} office_class_id - 営業所分類コード
     * @param {Int} product_id - 製品コード
     * @returns {<Object>} 検索結果
     */
    async searchProductResults(month_year, office_class_id, product_id) {
      const functionName = 'searchProductResults';
      let result = null;
      // 製品実績と営業所と製品の結合
      let SQLs = `SELECT  * 
      FROM t_products_results TPR 
      LEFT JOIN m_offices MO ON TPR.office_class_id = MO.office_id
      LEFT JOIN m_products MP USING(product_id)
      WHERE 
      TPR.month_year = ${month_year} 
      AND TPR.office_class_id = ${office_class_id}
      AND TPR.product_id = ${product_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0]
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('入力された製品実績データを保存します。よろしいですか？', {
          title: '製品実績の保存',
        })) {
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-RESULTS-INQUIRY', 
              query: { 
                month_year: this.productResultsObj.month_year,
                office_class_id: this.productResultsObj.office_class_id,
                product_id: this.productResultsObj.product_id,
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          scrollTo(0,0);
        }
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      // 製品実績マスタを更新
      await this.execUpdateProductResults()
    },
    /**
     * 更新処理
     */
    async execUpdateProductResults() {
      const functionName = 'execUpdateProductResults';
      const colList = [];
      // テーブル各項目
      this.productResultsObj.tableItems.forEach(element => {
        const keyPurchaseStock = `purchase_stock_${element.key}`
        const keySales = `sales_${element.key}`
        colList.push(CreateColRow(keyPurchaseStock, Number(element.purchase_stock), 'INT'));
        colList.push(CreateColRow(keySales, Number(element.sales), 'INT'));
      });
      // 粗利額
      colList.push(CreateColRow('gross_profit', Number(this.productResultsObj.gross_profit), 'INT'));
  
      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      let sql = `${CreateUpdateSql(colList.concat(colUser), 't_products_results')} 
      WHERE month_year = ${this.productResultsObj.month_year} 
      AND office_class_id = ${this.productResultsObj.office_class_id} 
      AND product_id = ${this.productResultsObj.product_id}`;
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>