<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 取引先製品単価一括更新</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <!-- ●●●検索条件●●● -->
          <b-card>
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body class="p-2">
              <validation-observer ref="observer">
                <b-container fluid>
                  <!-- ●●●検索条件●●● -->
                  <b-row>
                    <!-- インポートファイル設定 -->
                    <b-col lg="6">
                      <validation-provider name="fileClientsSalesPrice" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="読み込みファイル(.csv)"
                          label-for="fileClientsSalesPrice"
                        >
                          <b-form-file
                            id="fileClientsSalesPrice"
                            v-model="fileClientsSalesPrice"
                            :class="{'border-danger': errors[0]}"
                          >
                          </b-form-file>
                          <b-col :class="classes" lg="12">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- ファイルインポートボタン -->
                  <b-row>
                    <b-col>
                      <b-form style="text-align: left;">
                        <b-button pill variant="success" @click="clearAlert(); clickFileImport();">
                          <span class="oi oi-data-transfer-upload"></span> ファイルインポート
                        </b-button>
                        <b-form-text class="text-muted">1回のインポート可能件数は1000件です。<br>一度に取り込める取引先は10件までです。<br>取引先毎に製品は100件毎の登録となります。</b-form-text>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea" v-if="fields!=null">
        <b-col lg="12">
          <b-row v-if="dispButtonList.length > 0">
            <b-col lg="3" class="px-0">
              <label for="dateUnitPriceEffectiveDate">
                <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='見積コピー時の適用日の初期値です。'>
                  <strong class='text-body'>適用年月日 <span class="oi oi-flag"/></strong>
                </b-input-group-text>
              </label>
              <b-form-datepicker
                id="dateUnitPriceEffectiveDate"
                name="dateUnitPriceEffectiveDate"
                v-model="dateUnitPriceEffectiveDate"
                calendar-width="50%"
                :reset-button="false"
              ></b-form-datepicker>
            </b-col>
            <b-col lg="9">
              <!-- 見積コピーボタン -->
              <label for="dateUnitPriceEffectiveDate">
                <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='この画面では単価登録は完了しません。ボタンを押下しますと見積入力画面が作成されます。'>
                  <strong class='text-body'>見積入力画面へ <span class="oi oi-flag"/></strong>
                </b-input-group-text>
              </label>
              <b-btn-toolbar>
                <b-button
                  v-for="(dispButton, index) of dispButtonList"
                  :key="index"
                  class="mr-2"
                  pill
                  variant="success"
                  v-b-tooltip.hover.noninteractive
                  @click="clearAlert(); clickEstimateCopyBtn(dispButton);"
                >
                  <span class="oi oi-pencil"></span> {{ '取引先' + dispButton.ClientId + 'の' + dispButton.ProductIdList.length + '製品' + ( dispButton.ClientIndex > 0 ? '(' + (dispButton.ClientIndex * clientProductMax + 1).toString() + '～)' : '' ) }}
                </b-button>
              </b-btn-toolbar>
            </b-col>
          </b-row>
          <b-row>
            <!-- 表示データ選択 -->
            <b-col lg="5" class="my-1 px-0">
              <b-form-radio-group
                id="checkOkNg"
                v-model="checkOkNg"
                :options="dispOkNgList"
                @change="setOkNgList"
              ></b-form-radio-group>
            </b-col>
            <b-col>
              <label style="color: red;">「BM価格」「（最新）」は参考情報です。「売上単価」が更新用の数値となります。</label>
            </b-col>
          </b-row>
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :small="true"
              :filter="filter"
              @filtered="onFiltered"
            >
              <template #head(ClientId)>
                <div>取引先</div>
                <div>コード</div>
              </template>
              <template #head(EffectiveSalesUnitPrice)>
                <div>売上単価</div>
                <div>（最新）</div>
              </template>
              <template #head(UnitPriceEffectiveDate)>
                <div>適用年月日</div>
                <div>（最新）</div>
              </template>
              <template #cell(SalesUnitPrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.SalesUnitPrice.toLocaleString() : data.item.SalesUnitPrice }}
              </template>
              <template #cell(EffectiveSalesUnitPrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.EffectiveSalesUnitPrice.toLocaleString() : data.item.EffectiveSalesUnitPrice }}
              </template>
              <template #cell(SalesUnitPriceMaster)="data">
                {{ checkOkNgDisp == 1 ? data.item.SalesUnitPriceMaster.toLocaleString() : data.item.SalesUnitPriceMaster }}
              </template>
              <template #cell(ErrNaiyo)="data">
                <div style="max-width:100px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;" v-b-tooltip.hover.noninteractive.right="data.item.ErrNaiyo">
                    {{ data.item.ErrNaiyo }}
                  </div>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, formatDate, formatCurDate, formatDateCalc, getNullStr, getFormCounter, addOperationLogs, executeSelectSql, CreateColRow, CreateInsertSql, executeTransactSqlList } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
//import { API, graphqlOperation } from 'aws-amplify';
//import { requestTransactSqlsAsync, onTransactSqlStatusUpdate } from '@/assets/js/executeSqlAsync.js'
//import { onUpdateTransactSqlStatus } from '@/graphql/subscriptions';
import { parse } from 'csv-parse';

const MODULE_NAME = 'csv-import-clients-sales-price';

export default {
  name: 'CSV-IMPORT-CLIENTS-SALES-PRICE',
  // コンポーネント
  components: {
    Header,
    Footer,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先製品単価一括更新',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // インポートファイル
      fileClientsSalesPrice: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群（本項目は件数が大きくなりやすいため、共通値は使用しない）
      pageOptions: [
        {value: 100, text: '100'},
        {value: 500, text: '500'},
        {value: 1000, text: '1000'},
      ],
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      filter: null,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // csvインデックス
      csvColIndex: {
        // 取引先コード
        clientId: -1,
        // 製品コード
        productId: -1,
        // 売上単価
        salesUnitPrice: -1,
      },
      // csvカラム名
      csvColName: {
        // 取引先コード
        clientId: '取引先コード',
        // 製品コード
        productId: '製品コード',
        // 売上単価
        salesUnitPrice: '売上単価',
      },
      // 上限値
      lineMax: 1000,
      clientMax: 10,
      clientProductMax: 100,
      // テーブル構成
      fields: null,
      fieldsOk: [],
      fieldsNg: [],
      // ワークテーブル用ID
      workId: null,
      // ボタン構成
      dispButtonList: [],
      // 適用年月日
      dateUnitPriceEffectiveDate: null,
      // インポート結果
      itemList: [],
      checkOkList: [],
      checkNgList: [],
      checkOkNg: 1,
      checkOkNgDisp: 1,
      dispOkNgList: [
        {value: 1, text: 'OKデータ一覧表示'},
        {value: 2, text: 'NGデータ一覧表示'}
      ],
      isWarning: false,
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    // ファイルにイベント追加
    document.getElementById('fileClientsSalesPrice').addEventListener('click', () => {
      this.fileClientsSalesPrice = null;
    });
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  // 関数群
  methods: {
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)を取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // フィールド作成
        this.createFields();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // フィールド作成
    createFields: function() {
      // 更新対象のフィールド
      this.fieldsOk.push({key: 'No', label: 'No'});
      this.fieldsOk.push({key: 'ClientId', label: '取引先コード'});
      this.fieldsOk.push({key: 'ClientName', label: '取引先名'});
      this.fieldsOk.push({key: 'ProductId', label: '製品コード'});
      this.fieldsOk.push({key: 'ProductName', label: '製品名'});
      this.fieldsOk.push({key: 'SalesUnitPrice', label: '売上単価', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'UpRate', label: 'UP率(％)', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'EffectiveSalesUnitPrice', label: '売上単価（最新）', tdClass: 'text-right', thClass: 'bg-warning'});
      this.fieldsOk.push({key: 'SalesUnitPriceMaster', label: 'BM価格', tdClass: 'text-right', thClass: 'bg-warning'});
      this.fieldsOk.push({key: 'UnitPriceEffectiveDate', label: '適用年月日（最新）', thClass: 'bg-warning'});
      // エラー行のフィールド
      this.fieldsNg.push({key: 'No', label: 'No'});
      this.fieldsNg.push({key: 'ErrNaiyo', label: 'エラー内容', sortable: true});
      this.fieldsNg.push({key: 'ClientId', label: '取引先コード'});
      this.fieldsNg.push({key: 'ClientName', label: '取引先名'});
      this.fieldsNg.push({key: 'ProductId', label: '製品コード'});
      this.fieldsNg.push({key: 'ProductName', label: '製品名'});
      this.fieldsNg.push({key: 'SalesUnitPrice', label: '売上単価', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'UpRate', label: 'UP率(％)', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'EffectiveSalesUnitPrice', label: '売上単価（最新）', tdClass: 'text-right', thClass: 'bg-warning'});
      this.fieldsNg.push({key: 'SalesUnitPriceMaster', label: 'BM価格', tdClass: 'text-right', thClass: 'bg-warning'});
      this.fieldsNg.push({key: 'UnitPriceEffectiveDate', label: '適用年月日（最新）', thClass: 'bg-warning'});
    },
    // ファイルインポートボタン押下時
    async clickFileImport() {
      const functionName = 'clickFileImport';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          let confirmMessage = '指定したファイルを取り込みます。よろしいですか？';
          if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: 'ファイルインポート'}) == true) {
            await this.fileImport();
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // ファイルインポート
    async fileImport() {
      this.$store.commit('setLoading', true);
      // 表示中の情報がある場合、初期化
      this.fileDataInit();
      let reader = new FileReader();
      reader.onload = ('load', () => {
        this.readFile(reader.result);
      });
      reader.onerror = ('error', (error) => {
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2062']);
        this.$store.commit('setLoading', false);
      });
      await reader.readAsText(this.fileClientsSalesPrice);
    },
    // ファイルの内容を読み込む
    async readFile(fileText) {
      const functionName = 'readFile';
      try {
        // テキストをCSV形式で読み込み配列を作成
        let csvRecords = this.createCsvArray(fileText);
        //console.log(csvRecords);
        // 行数チェック
        if (csvRecords.length < 2) {
          // データ無し
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（データ無し）'));
          this.$store.commit('setLoading', false);
          return;
        } else if (csvRecords.length > this.lineMax + 1) {
          // 上限行を超過
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.lineMax + '行を超過）'));
          this.$store.commit('setLoading', false);
          return;
        }
        // ヘッダーチェック
        if (this.checkHeaderRow(csvRecords[0]) == false) {
          this.$store.commit('setLoading', false);
          return;
        }
        // 表示用の一覧を作成
        let csvDataRecords = [];
        // ヘッダーを除き、不要な列は除外された一覧を作成
        // ・ヘッダー除外のため1始まり
        for (let i = 1; i < csvRecords.length; i++) {
          let newRow = {
            No: i,
            _cellVariants: {No: 'info'},
            ClientId: getNullStr(csvRecords[i][this.csvColIndex.clientId]).trim(),
            ProductId: getNullStr(csvRecords[i][this.csvColIndex.productId]).trim(),
            SalesUnitPrice: getNullStr(csvRecords[i][this.csvColIndex.salesUnitPrice]).trim(),
            SalesUnitPriceMaster: null,
            UnitPriceEffectiveDate: null,
            EffectiveSalesUnitPrice: null,
          };
          csvDataRecords.push(newRow);
        }
        // CSVファイルのデータをチェックし、更新可能リストと更新NGリストに分ける
        await this.createDispList(csvDataRecords);
        // 初期状態では更新リストを表示
        this.setOkNgList();
        // IDを発行
        await this.getWorkId();
        // NGリストがある場合、警告を表示
        if (this.checkNgList.length > 0) {
          let message = DISP_MESSAGES.WARNING['2070'].replace('%arg1%',csvDataRecords.length).replace('%arg2%',this.checkNgList.length);
          let options = {
            title: '警告',
            bodyBgVariant: 'warning',
            headerBgVariant: 'warning',
            footerBgVariant: 'danger',
          };
          await this.$bvModal.msgBoxOk(message, options);
        } else {
          let message = DISP_MESSAGES.INFO['0003'].replace('%arg1%',csvDataRecords.length);
          let options = {
            title: '情報',
          };
          await this.$bvModal.msgBoxOk(message, options);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // ファイル情報初期化
    fileDataInit: function() {
      // ページングの初期化
      this.initPaging();
      // フィールド情報
      this.fields = null;
      // 表示情報
      this.itemList = [];
      this.checkOkList = [];
      this.checkNgList = [];
      this.checkOkNg = 1;
      this.checkOkNgDisp = 1;
      this.isWarning = false;
      this.dispButtonList = [];
      // csvインデックス
      this.csvColIndex.clientId = -1;
      this.csvColIndex.productId = -1;
      this.csvColIndex.salesUnitPrice = -1;
      // 適用年月日
      this.dateUnitPriceEffectiveDate = formatCurDate('YYYY-MM-DD');
    },
    // CSVファイルの配列を作成
    createCsvArray: function(fileText) {
      const records = [];
      let parser = parse();
      parser.on('readable', function() {
        let record;
        while ((record = parser.read()) !== null) {
          records.push(record);
        }
      });
      parser.write(fileText);
      parser.end();

      return records;
    },
    // ヘッダーチェック
    checkHeaderRow: function(row) {
      for (let i = 0; i < row.length; i++) {
        switch(row[i].trim()) {
        case this.csvColName.clientId:
          if (this.csvColIndex.clientId == -1) {
            this.csvColIndex.clientId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.clientId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.productId:
          if (this.csvColIndex.productId == -1) {
            this.csvColIndex.productId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.productId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.salesUnitPrice:
          if (this.csvColIndex.salesUnitPrice == -1) {
            this.csvColIndex.salesUnitPrice = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.salesUnitPrice + 'が2列以上）'));
            return false;
          }
          break;
        default:
          break;
        }
      }
      if (this.csvColIndex.clientId != -1 &&
      this.csvColIndex.productId != -1 &&
      this.csvColIndex.salesUnitPrice != -1
      ) {
        // ヘッダー行に全てのカラムがある場合はtrue
        return true;
      } else {
        let noColName = '';
        if (this.csvColIndex.clientId == -1) {
          noColName += this.csvColName.clientId;
        }
        if (this.csvColIndex.productId == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.productId;
        }
        if (this.csvColIndex.salesUnitPrice == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.salesUnitPrice;
        }
        // ヘッダー行にカラムがない場合はfalse
        this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + noColName + 'の列無し）'));
        return false;
      }
    },
    // 表示用の一覧作成
    async createDispList(csvDataRecords) {
      let clientProductList = [];
      let productIdList = [];
      // CSVファイルのデータから取引先製品一覧と製品一覧を作成（数値チェック、マスター未チェック）
      this.createClientProductList(csvDataRecords, clientProductList, productIdList);
      //console.log(clientProductList);
      //console.log(productIdList);
      let isNoMaster = false;
      if (clientProductList.length > 0) {
        let csvClientId = '';
        for (let i = 0; i < clientProductList.length; i++) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += clientProductList[i].ClientId;
        }
        let csvProductId = productIdList.join(',');
        let selectSqlClient = 'SELECT client_id,client_name_kanji FROM m_clients';
        selectSqlClient += ' WHERE client_class = ' + Const.ClientClass.customer + ' AND client_id IN (' + csvClientId + ')';
        let selectSqlProduct = 'SELECT product_id,product_name_kanji,sales_unit_price FROM m_products';
        selectSqlProduct += ' WHERE product_id IN (' + csvProductId + ')';
        // 非同期でまとめて取得
        let clientResult = null;
        let productResult = null;
        [clientResult, productResult] = await Promise.all([
          executeSelectSql(selectSqlClient),
          executeSelectSql(selectSqlProduct),
        ]);
        //console.log(clientResult);
        //console.log(productResult);
        if (clientResult != null && clientResult.length > 0 && productResult != null && productResult.length > 0) {
          // 取引先と製品のマスタがある場合
          // マスタ情報から取引先製品一覧を設定（マスター確認、取引先製品情報取得）
          await this.setClientProductList(clientProductList, clientResult, productResult);
          if (clientProductList.length > 0) {
            // 表示用の一覧を判定
            this.checkDispList(csvDataRecords, clientProductList);
            // ボタン一覧情報作成
            this.createDispButtonList(clientProductList);
          } else {
            // 取引先マスタ、または、製品マスタ両方にある行がない場合
            isNoMaster = true;
          }
        } else {
          // 取引先コード、製品コードがマスタにない場合（テーブル確認）
          isNoMaster = true;
        }
      } else {
        // 取引先コード、製品コードがマスタにない場合（テーブル未確認（数値でないためテーブル確認不要））
        isNoMaster = true;
      }
      if (isNoMaster == true) {
        // 情報を取得できなかった場合、全データをマスタエラーとする
        for (let i = 0; i < csvDataRecords.length; i++) {
          csvDataRecords[i].ErrNaiyo = 'マスタエラー';
          csvDataRecords[i]._cellVariants.ClientId = 'danger';
          csvDataRecords[i]._cellVariants.ProductId = 'danger';
          this.checkNgList.push(csvDataRecords[i]);
        }
      }
    },
    // CSVファイルのデータから取引先製品一覧と製品一覧を作成（数値チェック、マスター未チェック）
    createClientProductList: function(csvDataRecords, clientProductList, productIdList) {
      // 製品マスタ、製品詳細マスタから取得するための取引先コードと製品コードを取得
      for (let i = 0; i < csvDataRecords.length; i++) {
        if (isNaN(Number(csvDataRecords[i].ClientId)) == false && isNaN(Number(csvDataRecords[i].ProductId)) == false) {
          let clientProduct = clientProductList.find(el => el.ClientId == Number(csvDataRecords[i].ClientId));
          if (clientProduct == undefined) {
            clientProductList.push({ClientId: Number(csvDataRecords[i].ClientId), ProductIdList: [{ProductId: Number(csvDataRecords[i].ProductId)}]});
          } else {
            let productIdIndex= clientProduct.ProductIdList.findIndex(el => el.ProductId == Number(csvDataRecords[i].ProductId));
            if (productIdIndex == -1) {
              clientProduct.ProductIdList.push({ProductId: Number(csvDataRecords[i].ProductId)});
            }
          }
          let productIdIndex = productIdList.findIndex(el => el.ProductId == Number(csvDataRecords[i].ProductId));
          if (productIdIndex == -1) {
            productIdList.push(Number(csvDataRecords[i].ProductId));
          }
        }
      }
    },
    // マスタ情報から取引先製品一覧を設定（マスター確認、取引先製品情報取得）
    async setClientProductList(clientProductList, clientResult, productResult) {
      // 取引先コード、製品コードがマスタにある場合
      for (let i = 0; i < clientProductList.length; i++) {
        let client = clientResult.find(el => el.client_id == clientProductList[i].ClientId);
        if (client != undefined) {
          // 取引先コードがマスタにある場合
          // 取引名を取得
          clientProductList[i].ClientName = client.client_name_kanji;
          clientProductList[i].IsUpdateRow = false;
          // 製品コードがマスタにあるか確認
          for (let j = 0; j < clientProductList[i].ProductIdList.length; j++) {
            let productIndex = productResult.findIndex(el => el.product_id == clientProductList[i].ProductIdList[j].ProductId);
            if (productIndex != -1) {
              // 製品コードがマスタにある場合
              // 製品名、単価を取得
              clientProductList[i].ProductIdList[j].ProductName = productResult[productIndex].product_name_kanji;
              clientProductList[i].ProductIdList[j].SalesUnitPrice = productResult[productIndex].sales_unit_price;
              clientProductList[i].ProductIdList[j].UnitPriceEffectiveDate = '';
              clientProductList[i].ProductIdList[j].EffectiveSalesUnitPrice = '';
              clientProductList[i].ProductIdList[j].IsUpdateRow = false;
            } else {
              // 製品コードがマスタにない場合
              // その製品コードの行を削除
              clientProductList[i].ProductIdList.splice(j, 1);
              j--;
              continue;
            }
          }
          if (clientProductList[i].ProductIdList.length == 0) {
            // 製品コードがマスタにない場合
            // その取引先コードの行を削除
            clientProductList.splice(i, 1);
            i--;
            continue;
          }
        } else {
          // 取引先コードがマスタにない場合
          // その取引先コードの行を削除
          clientProductList.splice(i, 1);
          i--;
          continue;
        }
      }
      if (clientProductList.length > 0) {
        let clientId = '';
        let csvProductId = '';
        let promiseArray = [];
        for (let i = 0; i < clientProductList.length && i < this.clientMax; i++) {
          clientProductList[i].IsUpdateRow = true;
          clientId = clientProductList[i].ClientId;
          csvProductId = '';
          for (let j = 0; j < clientProductList[i].ProductIdList.length; j++) {
            if (csvProductId != '') {
              csvProductId += ',';
            }
            csvProductId += clientProductList[i].ProductIdList[j].ProductId;
          }
          let selectSql = '';
          selectSql += 'SELECT ';
          selectSql += ' clients_products.client_id';
          selectSql += ',clients_products.product_id';
          selectSql += ',clients_products.unit_price_effective_date';
          selectSql += ',clients_products.sales_unit_price';
          selectSql += ' FROM ';
          selectSql += 'm_clients_products AS clients_products ';
          selectSql += 'INNER JOIN (';
          selectSql += 'SELECT ';
          selectSql += ' client_class';
          selectSql += ',client_id';
          selectSql += ',product_id';
          selectSql += ',MAX(unit_price_effective_date) AS unit_price_effective_date';
          selectSql += ' FROM ';
          selectSql += 'm_clients_products ';
          selectSql += ' WHERE ';
          selectSql += 'client_class = ' + Const.ClientClass.customer + ' ';
          selectSql += 'AND client_id = ' + clientId + ' ';
          selectSql += 'AND product_id IN (' + csvProductId + ') ';
          selectSql += 'GROUP BY client_class, client_id, product_id ';
          selectSql += ') AS clients_products_QUERY ';
          selectSql += 'ON clients_products.client_class = clients_products_QUERY.client_class ';
          selectSql += 'AND clients_products.client_id = clients_products_QUERY.client_id ';
          selectSql += 'AND clients_products.product_id = clients_products_QUERY.product_id ';
          selectSql += 'AND clients_products.unit_price_effective_date = clients_products_QUERY.unit_price_effective_date ';
          selectSql += 'ORDER BY clients_products.client_id, clients_products.product_id ';
          promiseArray.push(executeSelectSql(selectSql));
        }
        let aryClientProductResult = await Promise.all(promiseArray);
        for (let i = 0; i < aryClientProductResult.length; i++) {
          if (aryClientProductResult[i] != null && aryClientProductResult[i].length > 0) {
            let clientProduct = clientProductList.find(el => el.ClientId == aryClientProductResult[i][0].client_id);
            if (clientProduct != undefined) {
              for (let j = 0; j < aryClientProductResult[i].length; j++) {
                let product = clientProduct.ProductIdList.find(el => el.ProductId == aryClientProductResult[i][j].product_id);
                if (product != undefined) {
                  product.UnitPriceEffectiveDate = formatDate(aryClientProductResult[i][j].unit_price_effective_date);
                  product.EffectiveSalesUnitPrice = aryClientProductResult[i][j].sales_unit_price;
                }
              }
            }
          }
        }
      }
    },
    // 表示用の一覧を判定
    checkDispList: function(csvDataRecords, clientProductList) {
      // 両方がマスタに存在する取引先製品の組合せがある場合
      for (let i = 0; i < csvDataRecords.length; i++) {
        let isErr = false;
        let clientProduct = clientProductList.find(el => el.ClientId == Number(csvDataRecords[i].ClientId));
        if (clientProduct == undefined) {
          // 取引先コードがマスタにない場合
          csvDataRecords[i].ErrNaiyo = 'マスタエラー';
          csvDataRecords[i]._cellVariants.ClientId = 'danger';
          csvDataRecords[i]._cellVariants.ProductId = 'danger';
          this.checkNgList.push(csvDataRecords[i]);
        } else {
          let productIdIndex = clientProduct.ProductIdList.findIndex(el => el.ProductId == Number(csvDataRecords[i].ProductId));
          if (productIdIndex == -1) {
            // 製品コードがマスタにない場合
            csvDataRecords[i].ErrNaiyo = 'マスタエラー';
            csvDataRecords[i]._cellVariants.ClientId = 'danger';
            csvDataRecords[i]._cellVariants.ProductId = 'danger';
            this.checkNgList.push(csvDataRecords[i]);
          } else {
            // 取引先コード、製品コードがマスタにある場合
            // 製品名、単価を取得
            csvDataRecords[i].ClientName = clientProduct.ClientName;
            csvDataRecords[i].ProductName = clientProduct.ProductIdList[productIdIndex].ProductName;
            csvDataRecords[i].SalesUnitPriceMaster = clientProduct.ProductIdList[productIdIndex].SalesUnitPrice;
            csvDataRecords[i].UnitPriceEffectiveDate = clientProduct.ProductIdList[productIdIndex].UnitPriceEffectiveDate;
            csvDataRecords[i].EffectiveSalesUnitPrice = clientProduct.ProductIdList[productIdIndex].EffectiveSalesUnitPrice;
            // 売上単価
            if (getNullStr(csvDataRecords[i].SalesUnitPrice) != '' && isNaN(Number(csvDataRecords[i].SalesUnitPrice)) == false &&
              0 <= Number(csvDataRecords[i].SalesUnitPrice) && Number(csvDataRecords[i].SalesUnitPrice) <= 99999999 && Number.isInteger(Number(csvDataRecords[i].SalesUnitPrice)) == true) {
              csvDataRecords[i].SalesUnitPrice = Number(csvDataRecords[i].SalesUnitPrice);
            } else {
              csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? '売上単価が不正' : csvDataRecords[i].ErrNaiyo += '、売上単価が不正';
              csvDataRecords[i]._cellVariants.SalesUnitPrice = 'danger';
              isErr = true;
            }
            if (isErr == true) {
              this.checkNgList.push(csvDataRecords[i]);
            } else if (clientProduct.IsUpdateRow == false) {
              // マスタはあるが更新対象でない場合
              csvDataRecords[i].ErrNaiyo = '取引先数上限超過';
              csvDataRecords[i]._cellVariants.ClientId = 'danger';
              this.checkNgList.push(csvDataRecords[i]);
            } else if (this.checkOkList.findIndex(el => el.ClientId == csvDataRecords[i].ClientId && el.ProductId == csvDataRecords[i].ProductId) != -1) {
              // 既に同じ取引先製品情報がある場合
              csvDataRecords[i].ErrNaiyo = '重複エラー';
              csvDataRecords[i]._cellVariants.ClientId = 'danger';
              csvDataRecords[i]._cellVariants.ProductId = 'danger';
              this.checkNgList.push(csvDataRecords[i]);
            } else {
              // エラーがなく、重複していない場合は更新一覧に追加
              this.checkOkList.push(csvDataRecords[i]);
              // 製品単価を設定
              clientProduct.ProductIdList[productIdIndex].SalesUnitPriceInput = csvDataRecords[i].SalesUnitPrice;
              // 更新対象の取引先製品情報に設定
              clientProduct.ProductIdList[productIdIndex].IsUpdateRow = true;
              // UP率を設定
              let upRate = null;
              if (clientProduct.ProductIdList[productIdIndex].SalesUnitPriceInput == 0 ||
                clientProduct.ProductIdList[productIdIndex].EffectiveSalesUnitPrice == 0 ||
                clientProduct.ProductIdList[productIdIndex].EffectiveSalesUnitPrice == '') {
                upRate = null;
              } else {
                upRate = Math.round((clientProduct.ProductIdList[productIdIndex].SalesUnitPriceInput - clientProduct.ProductIdList[productIdIndex].EffectiveSalesUnitPrice) * 1000 / clientProduct.ProductIdList[productIdIndex].SalesUnitPriceInput) / 10;
              }
              clientProduct.ProductIdList[productIdIndex].UpRate = upRate;
              csvDataRecords[i].UpRate = upRate;
            }
          }
        }
      }
    },
    // ボタン一覧情報作成
    createDispButtonList: function(clientProductList) {
      for (let i = 0; i < clientProductList.length; i++) {
        let clientProduct = clientProductList[i];
        if (clientProduct.IsUpdateRow == true) {
          let productIdList = [];
          let clientIndex = 0;
          for (let j = 0; j < clientProduct.ProductIdList.length; j++) {
            if (clientProduct.ProductIdList[j].IsUpdateRow == true) {
              productIdList.push(clientProduct.ProductIdList[j]);
              if (productIdList.length >= this.clientProductMax) {
                let dispButton = {
                  ClientId: clientProduct.ClientId,
                  ProductIdList: productIdList,
                  ClientIndex: clientIndex,
                  IsInsert: false,
                };
                this.dispButtonList.push(dispButton);
                productIdList = [];
                clientIndex++;
              }
            }
          }
          if (productIdList.length > 0) {
            let dispButton = {
              ClientId: clientProduct.ClientId,
              ProductIdList: productIdList,
              ClientIndex: clientIndex,
              IsInsert: false,
            };
            this.dispButtonList.push(dispButton);
          }
        }
      }
    },
    // OKNGリスト切り替え
    setOkNgList: function() {
      this.$store.commit('setLoading', true);
      this.initPaging();
      if (this.checkOkNg == 1) {
        this.fields = this.fieldsOk;
        // OKリスト選択
        this.itemList = this.checkOkList;
      } else {
        this.fields = this.fieldsNg;
        // NGリスト選択
        this.itemList = this.checkNgList;
      }
      this.checkOkNgDisp = this.checkOkNg;
      this.totalRows = this.itemList.length;
      this.$store.commit('setLoading', false);
    },
    /* コピー見積ボタン押下時 */
    async clickEstimateCopyBtn(dispButton) {
      let newWin = window.open('', '_blank');
      if (dispButton.IsInsert == false) {
        if (await this.insertClientsProductsCsv(dispButton) == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          scrollTo(0,0);
          newWin.close();
          return;
        } else {
          dispButton.IsInsert = true;
        }
      }
      // 見積入力画面へ遷移
      let query = {
        propWorkId: this.workId,
        propClientId: dispButton.ClientId,
        propClientIndex: dispButton.ClientIndex,
        propDate: this.dateUnitPriceEffectiveDate,
        propClientProductMax: this.clientProductMax,
      };
      let route = this.$router.resolve({ name: 'ESTIMATE-INPUT', query: query});
      newWin.location = route.href;
    },
    // 取引先製品CSVワーク登録
    async insertClientsProductsCsv(dispButton) {
      const functionName = 'insertClientsProductsCsv';
      let sqlList = [];
      let bulkInsertSql = '';
      for (let i = 0; i < dispButton.ProductIdList.length; i++) {
        let colList = [];
        // ID
        colList.push(CreateColRow('id', this.workId, 'NUMBER'));
        // CSV行
        colList.push(CreateColRow('csv_row', dispButton.ClientIndex * this.clientProductMax + i + 1, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', dispButton.ClientId, 'NUMBER'));
        // 製品コード
        colList.push(CreateColRow('product_id', dispButton.ProductIdList[i].ProductId, 'NUMBER'));
        // 売上単価
        colList.push(CreateColRow('sales_unit_price', dispButton.ProductIdList[i].SalesUnitPriceInput, 'NUMBER'));
        // 旧売上単価
        colList.push(CreateColRow('old_sales_unit_price', dispButton.ProductIdList[i].EffectiveSalesUnitPrice, 'NUMBER'));
        // 作成年月日
        colList.push(CreateColRow('created_date', 'CURDATE()', 'DATE'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO w_clients_products_csv (' + CreateInsertSql(colList, 'col', 'w_clients_products_csv') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 'w_clients_products_csv') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          sqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        sqlList.push(bulkInsertSql);
      }
      return await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // ワークテーブル用ID取得
    async getWorkId() {
      const functionName = 'getWorkId';
      // 期限切れの取引先製品CSVワークを削除（二日前以前のデータ）
      let deleteSql = 'DELETE FROM w_clients_products_csv';
      deleteSql += ' WHERE ';
      deleteSql += 'created_date <= \'' + formatDateCalc(formatCurDate(), 0, 0, -2) + '\' ';
      //console.log(deleteSql);
      let sqlList = [];
      sqlList.push(deleteSql);
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == false) {
        throw '取引先製品CSVワークの削除に失敗しました。';
      }
      this.workId = await getFormCounter(Const.CounterClass.clientProductCsvId, 0, 0, this.loginId, MODULE_NAME, functionName);
      if (this.workId == null) {
        throw 'IDの取得に失敗しました。';
      }
    },
  },
}
</script>