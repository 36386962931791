/**
 * sendMessage関数はAmplify Authの関数でないため、別途amplifyが自動生成するauthロールにポリシーをアタッチする必要あり
 * {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": [
                "sqs:SendMessage",
                "sqs:SetQueueAttributes"
            ],
            "Resource": "arn:aws:sqs:*:{AWSアカウントID}:*"
        }
    ]
}
 */
import AWS from 'aws-sdk';
import { getEssentialCredentials } from '@/assets/aws/cognito';
import setting from '@/assets/setting/setting';
import { v4 as uuidv4 } from 'uuid';
import { addOperationLogs } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { createQueryStatus } from '@/graphql/mutations';
const MODULE_NAME = 'sqs';

/**
 * SELECTのSQLの非同期実行をリクエストするSQSメッセージを送信
 */
export async function sendQueryRequestMessage({processId, type, SQLs, level, identityId, csvOutputConfig=null}) {
  try {
    AWS.config.update({
      credentials: await getEssentialCredentials(),
      region: setting.region
    });
    const sqs = new AWS.SQS({apiVersion: '2012-11-05'});

    const messageObject = {
      processID: processId,
      typeClass: type,
      SQLs: SQLs,
      level: level,
      identityId: identityId
    }

    // CSV出力データをjsonファイルでS3に保管する場合はCSV出力情報を添付
    if(csvOutputConfig) Object.assign(messageObject, { csvOutputConfig: csvOutputConfig });

    const params = {
      MessageBody: JSON.stringify(messageObject),
      MessageDeduplicationId: uuidv4(),
      MessageGroupId: processId,
      QueueUrl: setting.queryRequestQueueURL,
    };
    await API.graphql(
      graphqlOperation(createQueryStatus, {
        input : {
          ProcessID: processId,
          StatusClass: 1,
          TypeClass: type
        }
      })
    );
    return sqs.sendMessage(params).promise();
  } catch (error) {
    console.log('sendMessage');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'sendMessage', JSON.stringify(SQLs), error);
    throw error;
  }
}

/**
 * INSERT/UPDATEのSQLの非同期実行をリクエストするSQSメッセージを送信
 */
export async function sendTransactSqlsRequestMessage({processId, type, s3Key=null, SQLs=null}) {
  try {
    AWS.config.update({
      credentials: await getEssentialCredentials(),
      region: setting.region
    });
    const sqs = new AWS.SQS({apiVersion: '2012-11-05'});

    const messageObject = {
      processID: processId,
      typeClass: type
    }

    // SQLをjsonファイルでS3に保管しているする場合はS3のキーを添付
    if(s3Key) Object.assign(messageObject, { s3Key: s3Key });
    // SQLを直接メッセージに含める場合はSQLのリストを添付
    if(SQLs) Object.assign(messageObject, { SQLs: SQLs });

    const params = {
      MessageBody: JSON.stringify(messageObject),
      MessageDeduplicationId: uuidv4(),
      MessageGroupId: processId,
      QueueUrl: setting.transactSqlRequestQueueURL,
    };
    await API.graphql(
      graphqlOperation(createQueryStatus, {
        input : {
          ProcessID: processId,
          StatusClass: 1,
          TypeClass: type
        }
      })
    );
    return sqs.sendMessage(params).promise();
  } catch (error) {
    addOperationLogs('ERROR', MODULE_NAME, 'sendTransactSqlsRequestMessage', {}, error);
    throw error;
  }
}