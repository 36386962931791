export default{
  // データテーブル定義
  perPage: 100,
  pageOptions: [
    {value: 5, text: '5'},
    {value: 10, text: '10'},
    {value: 15, text: '15'},
    {value: 100, text: '100'},
    {value: -1, text: 'ALL'},
  ],
  currentPage: 1,
  emptyText: 'データが登録されていません。', // TODO kudo 不要なので消す（main.jsでb-tableに初期値設定するようにしたため）
  headerVariant: 'light', // TODO kudo 不要なので消す（ヘッダ行の色は「bootstrap-vue-wrapper.css」で指定しているため）

  // 受注入力画面データテーブル表示定義
  received_order_input_fields:[
    {
      key: 'ProductCode',
      label: '製品コード',
      thStyle: 'width:160px;',
    },
    {
      key: 'ProductCode',
      label: '製品コード',
    },
    {
      key: 'ProductName',
      label: '製品名',
    },
    {
      key: 'SupplierCode',
      label: '発注先コード',
      thStyle: 'width:160px;',
    },
    {
      key: 'Supplier',
      label: '発注先',
    },
    {
      key: 'Quantity',
      label: '数量',
      thStyle: 'width:130px;',
    },
    {
      key: 'SellingPrice',
      label: '売価',
    },
    {
      key: 'PurchasePrime',
      label: '仕入単価',
    },
    {
      key: 'Money',
      label: '金額',
    },
    {
      key: 'delete',
      label: ' ',
      thStyle: 'width:40px;',
    },
  ],
  // 取引先検索画面データテーブル表示定義
  client_search_modal_fileds:[
    {
      key: 'detail',
      label: ''
    },
    {
      key: 'clientId',
      label: 'コード',
      sortable: true
    },
    {
      key: 'clientNameKanji',
      label: '取引先名漢字',
      sortable: true
    },
    {
      key: 'clientNameKana',
      label: '取引先名カナ',
    },
    {
      key: 'address1',
      label: '住所1',
      sortable: true
    },
    {
      key: 'address2',
      label: '住所2',
      sortable: true
    },
  ],
  // 現場検索画面データテーブル表示定義
  site_search_modal_fileds:[
    {
      key: 'detail',
      label: ''
    },
    {
      key: 'clientSiteId',
      label: '現場コード',
      sortable: true
    },
    {
      key: 'clientSiteNameKanji',
      label: '現場名漢字',
      sortable: true
    },
    {
      key: 'clientSiteNameKana',
      label: '現場名カナ',
    },
    {
      key: 'address1',
      label: '住所1',
      sortable: true
    },
    {
      key: 'address2',
      label: '住所2',
      sortable: true
    },
  ],
  // 在庫照会モーダル
  stock_inquiry_fields:[
    {
      key: 'productId',
      label: '製品コード',
    },
    {
      key: 'productName',
      label: '製品名',
    },
    {
      key: 'orderManageClass',
      label: '区分名',
    },
    {
      key: 'stockQuantity',
      label: '現在庫数',
    },
    {
      key: 'invReserveQuantity',
      label: '引当済数',
    },
    {
      key: 'availableQuantity',
      label: '使用可能在庫数',
    },
    {
      key: 'totalStockQuantity',
      label: '合計入荷予定数',
    },
  ],
  // 自動出荷確定画面データテーブル表示定義
  forwarding_auto_fileds:[
    {
      key: 'salesOffice',
      label: '営業所',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'shippingType',
      label: '配送種別',
      sortable: true,
      thStyle: 'width:120px;'
    },
    {
      key: 'shippingCode',
      label: '配送コード',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:160px;'
    },
  ],
  // 出荷確定画面データテーブル表示定義
  forwarding_confirm_list_fileds:[
    {
      key: 'receivedOrderId',
      label: '受注番号',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'salesRecordDate',
      label: '売上計上日',
      sortable: true,
      thStyle: 'width:120px;'
    },
    {
      key: 'closingDate',
      label: '締日',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'clientId',
      label: '取引先コード',
      sortable: true,
      thStyle: 'width:120px;'
    },
    {
      key: 'client',
      label: '取引先名',
      sortable: true
    },
    {
      key: 'Address',
      label: '住所',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:240px;'
    },
  ],
  // 出荷確定ピッキング表画面データテーブル表示定義
  forwarding_pickingform_fileds:[
    {
      key: 'salesOffice',
      label: '営業所',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'shipConfirmDate',
      label: '出荷確定日',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'shippingType',
      label: '配送種別',
      sortable: true,
      thStyle: 'width:90px;'
    },
    {
      key: 'shippingCode',
      label: '配送コード',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'client',
      label: '得意先',
      sortable: true
    },
    {
      key: 'clientSite',
      label: '現場',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:160px;'
    },
  ],
  // 自動売上伝票発行画面データテーブル表示定義
  auto_ales_slip_fileds:[
    {
      key: 'salesOffice',
      label: '営業所',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'billClass',
      label: '伝票種類',
      sortable: true
    },
    {
      key: 'client',
      label: '得意先',
      sortable: true
    },
    {
      key: 'shippingType',
      label: '配送種別',
      sortable: true,
      thStyle: 'width:120px;'
    },
    {
      key: 'shippingCode',
      label: '配送コード',
      sortable: true
    },
    {
      key: 'ReceivedOrderNum',
      label: '受注番号',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:160px;'
    },
  ],
  // 売上伝票発行画面データテーブル表示定義
  sales_slip_fileds:[
    {
      key: 'salesOffice',
      label: '営業所',
      sortable: true,
      thStyle: 'width:100px;'
    },
    {
      key: 'clientId',
      label: '得意先',
      sortable: true,
      thStyle: 'width:120px;'
    },
    {
      key: 'clientSiteNameKanji',
      label: '得意先名',
      sortable: true
    },
    {
      key: 'shippingCode',
      label: '配送コード',
      sortable: true
    },
    {
      key: 'billClass',
      label: '伝票種類',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:160px;'
    },
  ],

  // 入金一覧画面データテーブル表示定義
  deposit_list_fields:[
    {
      key: 'billingNo',
      label: '伝票番号',
      sortable: true,
    },
    {
      key: 'billingDate',
      label: '伝票日付',
      sortable: true,
    },
    {
      key: 'clientId',
      label: '取引先コード',
      sortable: true
    },
    {
      key: 'clientNameKanji',
      label: '取引先名漢字',
      sortable: true
    },
    {
      key: 'clientNameKana',
      label: '取引先名カナ',
    },
    {
      key: 'Operation',
      label: '操作',
      thStyle: 'width:520px;'
    }
  ],
  // 入金入力画面データテーブル表示定義
  deposit_input_fields:[
    {
      key: 'denominations',
      label: '金種',
      thStyle: 'width:60px;',
    },
    {
      key: 'amount',
      label: '金額',
      thStyle: 'width:60px;',
    },
    {
      key: 'summary',
      label: '摘要',
      thStyle: 'width:80px;',
    },
    {
      key: 'accountSupport',
      label: '勘定ー補助',
      thStyle: 'width:80px;',
    },
    {
      key: 'settlementScheduled',
      label: '決済予定',
      thStyle: 'width:80px;',
    },
    {
      key: 'delete',
      label: ' ',
      thStyle: 'width:10px;',
    },
  ],
  
  // 入金照会画面データテーブル表示定義
  deposit_inquiry_fields:[
    {
      key: 'denominations',
      label: '金種',
      thStyle: 'width:60px;',
    },
    {
      key: 'amount',
      label: '金額',
      thStyle: 'width:60px;',
    },
    {
      key: 'summary',
      label: '摘要',
      thStyle: 'width:80px;',
    },
    {
      key: 'accountSupport',
      label: '勘定ー補助',
      thStyle: 'width:80px;',
    },
    {
      key: 'settlementScheduled',
      label: '決済予定',
      thStyle: 'width:80px;',
    },
  ],
  // 支払残高保守一覧画面データテーブル表示定義
  payments_balances_list_fields:[
    {
      key: 'client_id',
      label: '仕入先コード',
      sortable: true
    },
    {
      key: 'month_year',
      label: '処理年月',
      sortable: true
    },
    {
      key: 'client_name_kanji',
      label: '仕入先名（漢字）',
      sortable: true
    },
    {
      key: 'client_name_kana',
      label: '仕入先名（カナ）',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 請求残高保守一覧画面データテーブル表示定義
  billings_balances_list_fields:[
    {
      key: 'month_year',
      label: '請求年月',
      sortable: true
    },
    {
      key: 'client_id',
      label: '得意先コード',
      sortable: true
    },
    {
      key: 'site_id',
      label: '現場コード',
      sortable: true
    },
    {
      key: 'client_name_kanji',
      label: '得意先名（漢字）',
      sortable: true
    },
    {
      key: 'client_name_kana',
      label: '得意先名（カナ）',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 営業所保守一覧画面データテーブル表示定義
  office_list_fields:[
    {
      key: 'officeId',
      label: '営業所コード',
      thStyle: 'width:55px;',
      sortable: true
    },
    {
      key: 'officeName',
      label: '営業所名',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:50px;'
    },
  ],

  // 営業所保守入力画面データテーブル表示定義
  office_input_fields:[
    {
      key: 'officeId',
      label: '営業所コード',
    },
    {
      key: 'officeNameKanji',
      label: '営業所名（漢字）',
    },
    {
      key: 'officeNameKana',
      label: '営業所名（カナ）',
    },
  ],

  // 担当者保守一覧画面データテーブル表示定義
  staff_list_fields:[
    {
      key: 'staffId',
      label: '担当者コード',
      thStyle: 'width:55px;',
      sortable: true
    },
    {
      key: 'staffNameKanji',
      label: '担当者名（漢字）',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'staffNameKana',
      label: '担当者名（カナ）',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'officeName',
      label: '営業所',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'loginId',
      label: 'ログインID',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      thStyle: 'width:60px;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width:50px;'
    },
  ],
  // 担当者実績保守一覧画面データテーブル表示定義
  staffs_results_list_fields:[
    {
      key: 'staff_id',
      label: '担当者コード',
      thStyle: 'width: 15%;',
      sortable: true
    },
    {
      key: 'staff_name_kanji',
      label: '担当者名（漢字）',
      thStyle: 'width: 25%;',
      sortable: true
    },
    {
      key: 'staff_name_kana',
      label: '担当者名(カナ)',
      thStyle: 'width: 25%;',
      sortable: true
    },
    {
      key: 'office_name_kanji',
      label: '営業所',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 15%;'
    }
  ],
  // 担当者実績保守一覧画面編集データテーブル表示定義
  staffs_results_edit_list_fields:[
    {
      key: 'item',
      label: '項目',
      thStyle: 'width: 20%;',
    },
    {
      key: 'next_month',
      label: '翌月',
      thStyle: 'width: 40%;',
    },
    {
      key: 'current_term',
      label: '当期',
      thStyle: 'width: 40%;',
    },
   
  ],
  // 仕入先現場コード保守一覧画面データテーブル表示定義
  suppliers_sites_list_fields:[
    {
      key: 'supplier_id',
      label: '仕入先コード',
      sortable: true
    },
    {
      key: 'supplier_name',
      label: '仕入先名',
      sortable: true
    },
    {
      key: 'client_id',
      label: '得意先コード',
      sortable: true
    },
    {
      key: 'client_name',
      label: '得意先名',
      sortable: true
    },
    {
      key: 'site_id',
      label: '現場コード',
      sortable: true
    },
    {
      key: 'client_site_name_kanji',
      label: '現場名',
      sortable: true
    },
    {
      key: 'supplier_control_site_id',
      label: '相手先現場コード',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 現場保守一覧画面データテーブル表示定義
  clients_sites_list_fields:[
    {
      key: 'clientId',
      label: '取引先コード',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'clientName',
      label: '取引先名',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'siteId',
      label: '現場コード',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'clientSiteNameKanji',
      label: '現場名（漢字）',
      thStyle: 'width: 38%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 22%;'
    }
  ],
  // 製品保守一覧画面データテーブル表示定義
  products_list_fields:[
    {
      key: 'product_id',
      label: '製品コード',
      sortable: true
    },
    {
      key: 'product_name_kanji',
      label: '製品名',
      sortable: true
    },
    {
      key: 'product_name_kana',
      label: '製品名(カナ)',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 製品詳細照会画面データテーブル表示定義
  products_office_list_fields:[
    {
      key: 'office_name_kanji',
      label: '営業所',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'place',
      label: '置き場所',
      thStyle: 'width: 10%;',
    },
    {
      key: 'purchase_price',
      label: '仕入単価',
      thStyle: 'width: 10%;',
    },
    {
      key: 'cost_price',
      label: '原価単価',
      thStyle: 'width: 10%;',
    },
    {
      key: 'summary',
      label: '仕入単価修正摘要',
      thStyle: 'width: 10%;',
    },
    {
      key: 'supplier_product_id',
      label: '仕入先製品コード',
      thStyle: 'width: 10%;',
    },
    {
      key: 'picking_print_class',
      label: 'ピッキング表印刷区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'client_id',
      label: '発注先コード',
      thStyle: 'width: 10%;',
    },
    {
      key: 'client_name_kanji',
      label: '発注先名',
      thStyle: 'width: 10%;',
    },
    {
      key: 'service_whether_class',
      label: 'サービス区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'service_quantity',
      label: '無償サービス個数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'case_conversion_class',
      label: 'ケース換算区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'appropriate_stock',
      label: '適正在庫数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'minimum_quantity',
      label: '最低発注数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'lots_quantity',
      label: '発注ロット数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'lead_time',
      label: '入庫リードタイム',
      thStyle: 'width: 10%;',
    }
  ],
  // 製品詳細編集画面データテーブル表示定義
  productInfosTableFields:[
    {
      key: 'office_name_kanji',
      label: '営業所',
      // stickyColumn: true,
      sortable: true
    },
    {
      key: 'place',
      label: '置き場所(1A-2B-3C-4D)',
    },
    {
      key: 'purchase_price',
      label: '仕入単価',
    },
    {
      key: 'cost_price',
      label: '原価単価',
    },
    {
      key: 'summary',
      label: '仕入単価修正摘要',
    },
    {
      key: 'supplier_product_id',
      label: '仕入先製品コード',
    },
    {
      key: 'picking_print_class',
      label: 'ピッキング表印刷区分',
    },
    {
      key: 'client_id',
      label: '発注先コード',
    },
    {
      key: 'client_name_kanji',
      label: '発注先名',
    },
    {
      key: 'service_whether_class',
      label: 'サービス区分',
    },
    {
      key: 'service_quantity',
      label: '無償サービス個数',
    },
    {
      key: 'case_conversion_class',
      label: 'ケース換算区分',
    },
    {
      key: 'appropriate_stock',
      label: '適正在庫数',
    },
    {
      key: 'minimum_quantity',
      label: '最低発注数',
    },
    {
      key: 'lots_quantity',
      label: '発注ロット数',
    },
    {
      key: 'lead_time',
      label: '入庫リードタイム',
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // サービス区分名保守一覧画面データテーブル表示定義
  service_classes_list_fields:[
    {
      key: 'service_class',
      label: 'サービス区分',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'service_class_name',
      label: 'サービス区分名',
      thStyle: 'width: 40%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 20%;'
    }
  ],
  // ルート保守一覧画面データテーブル表示定義
  routes_list_fields:[
    {
      key: 'shipping_code',
      label: '配送コード',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'shipping_name',
      label: '配送名',
      thStyle: 'width: 40%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 20%;'
    }
  ],
  // 納品指定区分保守一覧画面データテーブル表示定義
  deliverys_designs_classe_list_fields:[
    {
      key: 'delivery_design_class',
      label: '納品指定区分',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'delivery_design_name',
      label: '納品指定区分名',
      thStyle: 'width: 40%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 20%;'
    }
  ],
  // 9Aコード保守一覧画面データテーブル表示定義
  products9A_class_list_fields:[
    {
      key: 'product_class_id',
      label: '製品分類コード',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'product_class_name',
      label: '製品分類名',
      thStyle: 'width: 40%;',
      sortable: true
    },
    {
      key: 'sequence',
      label: '現在No.',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'min_max',
      label: '枝番：最小～最大',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 20%;'
    }
  ],
  // 9A製品詳細照会画面データテーブル表示定義
  products_office_list_fields_9A:[
    {
      key: 'office_name_kanji',
      label: '営業所',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'place',
      label: '置き場所',
      thStyle: 'width: 10%;',
    },
    {
      key: 'purchase_price',
      label: '仕入単価',
      thStyle: 'width: 10%;',
    },
    {
      key: 'summary',
      label: '仕入単価修正摘要',
      thStyle: 'width: 10%;',
    },
    {
      key: 'supplier_product_id',
      label: '仕入先製品コード',
      thStyle: 'width: 10%;',
    },
    {
      key: 'picking_print_class',
      label: 'ピッキング表印刷区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'client_id',
      label: '発注先コード',
      thStyle: 'width: 10%;',
    },
    {
      key: 'client_name_kanji',
      label: '発注先名',
      thStyle: 'width: 10%;',
    },
    {
      key: 'service_whether_class',
      label: 'サービス区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'service_quantity',
      label: '無償サービス個数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'case_conversion_class',
      label: 'ケース換算区分',
      thStyle: 'width: 10%;',
    },
    {
      key: 'appropriate_stock',
      label: '適正在庫数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'minimum_quantity',
      label: '最低発注数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'lots_quantity',
      label: '発注ロット数',
      thStyle: 'width: 10%;',
    },
    {
      key: 'lead_time',
      label: '入庫リードタイム',
      thStyle: 'width: 10%;',
    }
  ],
  // 9A製品詳細編集画面データテーブル表示定義
  productInfosTableFields_9A:[
    {
      key: 'office_name_kanji',
      label: '営業所',
      // stickyColumn: true,
      sortable: true
    },
    {
      key: 'place',
      label: '置き場所(1A-2B-3C-4D)',
    },
    {
      key: 'purchase_price',
      label: '仕入単価',
    },
    {
      key: 'summary',
      label: '仕入単価修正摘要',
    },
    {
      key: 'supplier_product_id',
      label: '仕入先製品コード',
    },
    {
      key: 'picking_print_class',
      label: 'ピッキング表印刷区分',
    },
    {
      key: 'client_id',
      label: '発注先コード',
    },
    {
      key: 'client_name_kanji',
      label: '発注先名',
    },
    {
      key: 'service_whether_class',
      label: 'サービス区分',
    },
    {
      key: 'service_quantity',
      label: '無償サービス個数',
    },
    {
      key: 'case_conversion_class',
      label: 'ケース換算区分',
    },
    {
      key: 'appropriate_stock',
      label: '適正在庫数',
    },
    {
      key: 'minimum_quantity',
      label: '最低発注数',
    },
    {
      key: 'lots_quantity',
      label: '発注ロット数',
    },
    {
      key: 'lead_time',
      label: '入庫リードタイム',
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 在庫保守一覧画面データテーブル表示定義
  stocks_list_fields:[
    {
      key: 'product_id',
      label: '製品コード',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'month_year',
      label: '処理年月',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'office_name_kanji',
      label: '営業所',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'product_name_kanji',
      label: '製品名',
      sortable: true
    },
    {
      key: 'product_name_kana',
      label: '製品名(カナ)',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 製品構成保守一覧画面データテーブル表示定義
  products_compositions_list_fields:[
    {
      key: 'product_id',
      label: '製品コード',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'product_name_kanji',
      label: '製品名',
      sortable: true
    },
    {
      key: 'product_name_kana',
      label: '製品名(カナ)',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 製品構成部材データ照会テーブル表示定義
  produc_compositions_inquiry_table_fields:[
    {
      key: 'product_id',
      label: '部材製品コード',
      thStyle: 'width: 20%;',
      sortable: true
    },
    {
      key: 'product_name_kanji',
      label: '部材名',
      thStyle: 'width: 60%;',
      sortable: true
    },
    {
      key: 'quantity',
      label: '数量',
      thStyle: 'width: 20%;',
      sortable: true
    },
  ],
  // 製品構成部材データテーブル表示定義
  product_compositions_table_fields:[
    {
      key: 'product_id',
      label: '部材製品コード',
      thStyle: 'width: 20%;',
    },
    {
      key: 'product_name_kanji',
      label: '部材名',
      thStyle: 'width: 60%;',
    },
    {
      key: 'quantity',
      label: '数量',
      thStyle: 'width: 10%;',
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 10%;'
    },
  ],
  // 製品実績保守一覧画面データテーブル表示定義
  products_results_list_fields:[
    {
      key: 'month_year',
      label: '処理年月',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'product_id',
      label: '製品コード',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'office_name',
      label: '営業所',
      sortable: true
    },
    {
      key: 'product_class_id',
      label: '製品分類コード',
      thStyle: 'width: 10%;',
      sortable: true
    },
    {
      key: 'product_name_kanji',
      label: '製品名',
      sortable: true
    },
    {
      key: 'product_name_kana',
      label: '製品名(カナ)',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 製品実績保守一覧画面編集データテーブル表示定義
  products_results_edit_list_fields:[
    {
      key: 'item',
      label: '項目',
      thStyle: 'width: 20%;',
    },
    {
      key: 'purchase_stock',
      label: '仕入',
      thStyle: 'width: 40%;',
    },
    {
      key: 'sales',
      label: '売上',
      thStyle: 'width: 40%;',
    },
   
  ],
  // 製品単位変換保守一覧画面データテーブル表示定義
  products_units_conversions_list_fields:[
    {
      key: 'case_product_id',
      label: 'ケース製品',
      sortable: true
    },
    {
      key: 'conversions',
      label: 'ケース入数',
    },
    {
      key: 'loose_product_id',
      label: 'バラ製品',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 製品単価グループ保守一覧画面データテーブル表示定義
  products_prices_groups_list_fields:[
    {
      key: 'group_product_id',
      label: '代表製品',
      sortable: true
    },
    {
      key: 'member_product_id',
      label: 'グループ製品',
    },
    {
      key: 'operation',
      label: '操作',
    }
  ],
  // 取引先保守一覧画面データテーブル表示定義
  clients_list_fields:[
    {
      key: 'client_class',
      label: '取引先区分',
      thStyle: 'width: 120px;',
      tdClass: 'text-center',
      sortable: true
    },
    {
      key: 'client_id',
      label: '取引先コード',
      thStyle: 'width: 120px;',
      tdClass: 'text-center',
      sortable: true
    },
    {
      key: 'client_name_kanji',
      label: '取引先名',
      sortable: true
    },
    {
      key: 'staff_id',
      label: '担当者コード',
      thStyle: 'width: 120px;',
      tdClass: 'text-center',
      sortable: true
    },
    {
      key: 'staff_name_kanji',
      label: '担当者名',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 20%;'
    }
  ],
  // 取引先保守入力／編集／照会画面の発注条件条件
  clients_order_condition_fields:[
    {
      key: 'orderConditionQuantity',
      label: '数量',
      thStyle: 'width: 33%'
    },
    {
      key: 'orderConditionAmount',
      label: '金額',
      thStyle: 'width: 34%'
    },
  ],
  // 取引先保守入力／編集／照会画面の取引先サンワ顧客コード
  clients_suppliers_offices_fields: [
    {
      key: 'officeId',
      label: '営業所コード',
      sortable: true,
      tdClass: 'align-middle',
    },
    {
      key: 'officeNameKanji',
      label: '営業所名',
      sortable: true,
      tdClass: 'align-middle'
    },
    {
      key: 'supplierControlOfficeId',
      label: '相手先営業所コード'
    }
  ],
  // 照会レポート定義一覧画面関連
  inquiry_report_list_fields: [
    {
      key: 'index',
      label: 'No.',
      thStyle: 'width: 55px;',
      tdClass: 'align-middle text-center'
    },
    {
      key: 'inquiry_name',
      label: '定義名',
      thStyle: 'width: 250px;',
      tdClass: 'align-middle text-wrap',
      sortable: true
    },
    {
      key: 'description',
      label: '説明',
      tdClass: 'align-middle text-wrap',
      sortable: true
    },
    {
      key: 'created_user',
      label: '作成者',
      thStyle: 'width: 140px;',
      tdClass: 'align-middle text-center text-wrap',
      sortable: true
    },
    {
      key: 'updated_user',
      label: '更新者',
      thStyle: 'width: 140px;',
      tdClass: 'align-middle text-center text-wrap',
      sortable: true
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 159px;',
      tdClass: 'align-middle'
    }
  ],
  // 得意先別売掛実績表
  accounts_receivable_supply_list_fields: [
    {
      key: 'client_id',
      thClass: 'align-top text-left',
      tdClass: 'align-top text-left'
    },
    {
      key: 'last_month_receivable_no_tax_balance_result',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_deposit',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_bill_deposit',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_tax_deposit',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_tax',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'operation',
      thClass: 'align-middle',
      tdClass: 'align-top text-center'
    }
  ],
  // 仕入先別買掛実績表
  accounts_payable_supply_list_fields: [
    {
      key: 'supplier_id',
      thClass: 'align-top text-left',
      tdClass: 'align-top text-left'
    },
    {
      key: 'last_month_payable_no_tax_balance_result',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_payment',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_bill_payment',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_tax_payment',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'monthly_tax',
      thClass: 'align-top text-right',
      tdClass: 'align-top text-right'
    },
    {
      key: 'operation',
      thClass: 'align-middle',
      tdClass: 'align-top text-center'
    }
  ],
  // 取引先コード切替
  client_code_switch_table_fields: [
    {
      key: 'clientClass',
      label: '取引先区分',
      sortable: true,
      thStyle: 'width: 100px',
      tdClass: 'text-center'
    },
    {
      key: 'clientId',
      label: '取引先コード',
      sortable: true,
      thStyle: 'width: 120px;'
    },
    {
      key: 'clientName',
      label: '取引先名',
      sortable: true,
      tdClass: 'align-middle text-wrap'
    },
    {
      key: 'operation',
      label: '',
      thStyle: 'width: 172px;'
    },
  ],
  // 製品コード切替
  product_code_switch_table_fields: [
    {
      key: 'productId',
      label: '製品コード',
      sortable: true,
      thStyle: 'width: 120px;',
      tdClass: 'text-center'
    },
    {
      key: 'productName',
      label: '製品名',
      sortable: true,
      tdClass: 'align-middle text-wrap'
    },
    {
      key: 'operation',
      label: '',
      thStyle: 'width: 158px;'
    }
  ],
  // 棚卸前処理
  inventory_preprocess_table_fields: [
    {
      key: 'office',
      label: '営業所',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
    },
    {
      key: 'place',
      label: '置き場所',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
    },
    {
      key: 'inventoryPreprocessDatetime',
      label: '棚卸前処理',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 200px;'
    },
    {
      key: 'inventoryTablePrintDatetime',
      label: '棚卸表印刷',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 200px;'
    },
    {
      key: 'inventoryInputDatetime',
      label: '棚卸入力',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 200px;'
    },
    {
      key: 'inventoryDifferenceTableDatetime',
      label: '棚卸差異表',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 200px;'
    },
    {
      key: 'inventoryNo',
      label: '棚卸No.',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 120px;'
    }
  ],
  // 棚卸入力
  inventory_input_table_fields: [
    {
      key: 'finished',
      label: '済',
      thStyle: 'width: 50px;',
      tdClass: 'align-middle text-center',
    },
    {
      key: 'place',
      label: '置き場所',
      thStyle: 'width: 120px;',
      tdClass: 'align-middle'
    },
    {
      key: 'product_id',
      label: '製品コード',
      thStyle: 'width: 105px;',
      tdClass: 'align-middle text-center'
    },
    {
      key: 'product_name',
      label: '製品名',
      tdClass: 'align-middle'
    },
    {
      key: 'shelves_count',
      label: '実棚数',
      thStyle: 'min-width: 100px; width: 90px;',
      tdClass: 'align-middle'
    },
    {
      key: 'inventory_count',
      label: '現在庫数',
      thStyle: 'width: 100px;',
      tdClass: 'align-middle text-right'
    },
    {
      key: 'operation',
      label: '操作',
      thStyle: 'width: 100px;',
      tdClass: 'align-middle text-right text-center'
    }
  ],
  // 棚卸差異表
  stocks_amount_table_fields: [
    {
      key: 'office',
      label: '営業所',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center'
    },
    {
      key: 'inventory_no',
      label: '棚卸No.',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center'
    },
    {
      key: 'inventoryDate',
      label: '棚卸日時',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center'
    },
    {
      key: 'stocks_amount',
      label: '棚卸金額合計',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-right'
    },
    {
      key: 'book_stocks_amount',
      label: '帳簿在庫金額合計',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-right'
    }
  ],
  // 棚卸製品検索
  inventory_product_search_table_fields: [
    {
      key: 'choice',
      label: '選択',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 60px;'
    },
    {
      key: 'product_id',
      label: '製品コード',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 100px;'
    },
    {
      key: 'product_name_kanji',
      label: '製品名',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle'
    },
    {
      key: 'place',
      label: '置き場所',
      thClass: 'text-wrap align-middle',
      tdClass: 'text-wrap align-middle text-center',
      thStyle: 'width: 100px;'
    }
  ]
}