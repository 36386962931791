<template>
  <div class="text-center">
    <div v-if="btnFlg == false">
      <div v-for="(page, index) of pages" :key="index">
        <TemplateInventoryStockVariance :id="`page-${index}`"/>
      </div>
    </div>
    <div v-if="btnFlg == true">
      <b-alert show variant="info">
        <ul style="list-style: none;">
          <li>{{message1}}</li>
          <li>{{message2}}</li>
        </ul>
      </b-alert>
      <b-btn-toolbar class="mt-2">
        <b-button v-for="(btnPrint, index) of btnPrintList" :key="index" class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover @click="onPrintButtonClick(officeId, btnPrint.startIndex)">
          <span class="oi oi-document"></span> {{ btnPrint.name }}
        </b-button>
      </b-btn-toolbar>
    </div>
  </div>
</template>

<script>
import TemplateInventoryStockVariance from '@/assets/svg/inventory_Stock_Variance.svg';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, executeSelectSql, executeTransactSqlList, setPaperA4Landscape } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import moment from 'moment';
import store from '@/store';

// モジュール名
const MODULE_NAME = 'inventory-stock-variance-print';
// 1ページあたりの件数
const PAGE_RECORDS_MAX = 50;
const DATA_TYPE_INVENTORY = 0;
const DATA_TYPE_PLACE_TOTAL = 1;
const DATA_TYPE_TOTAL = 2;
// アライン
const ITEM_ALIGN_LEFT = 0;
const ITEM_ALIGN_CENTER = 1;
const ITEM_ALIGN_RIGHT = 2;
// 最大ページ数
const MAX_PAGE_CNT = 40;

export default {
  menu_type: 'user',
  name: 'INVENTORY-STOCK-VARIANCE-PRINT',
  components: {
    TemplateInventoryStockVariance
  },
  data() {
    return {
      title: '棚卸差異表',
      pages: [],
      btnFlg: false,
      btnPrintList: [],
      startIndex: 0,
      officeId: null,
      message1: DISP_MESSAGES.INFO['0001'].replace('%arg1%', MAX_PAGE_CNT),
      message2: DISP_MESSAGES.INFO['0002'],
    }
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    // 印刷レイアウトを設定します。
    setPaperA4Landscape();
    // 帳票を作成します。
    const query = this.$route.query;
    this.officeId = query.officeId;
    this.createForm(query.officeId);
  },
  /**
   * 画面閉じる時のイベント
   */
  onunload() {
    // メモリ解放
    this.pages = null;
  },
  methods: {
    /**
     * 帳票を作成します。
     * @param {Number} officeid - 営業所コード
     */
    createForm(officeId) {
      const functionName = 'createForm';

      // 棚卸履歴から営業所コードに対応する棚卸No.を取得
      this.listTInventoriesHistories(officeId).then(tInventoriesHistoriesList => {
        if (tInventoriesHistoriesList.length === 0) {
          // 棚卸履歴にデータが無かった場合
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2050'], {
            title: this.title
          }).then(() => {
            window.close();
          });
          return;
        }
        const tInventoriesHistories = tInventoriesHistoriesList[0];

        // 棚卸商品から棚卸No.に対応する棚卸商品情報を取得
        this.listTInventories(tInventoriesHistories.inventory_no).then(tInventoriesList => {
          // 置き場所ごとの最終更新者情報を取得
          this.listInventoryEntryUsers(tInventoriesHistories.inventory_no).then(inventoryEntryUsers => {
            if (inventoryEntryUsers === null) {
              this.$store.commit('setLoading', false);
              this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
                title: this.title
              }).then(() => {
                window.close();
              });
              return;
            }
            // 棚卸履歴の棚卸差異表出力日時を更新
            this.updateInventoriesHistories(tInventoriesHistories.inventory_no).then(updateInventoriesHistoriesResult => {
              if (updateInventoriesHistoriesResult) {
                // 更新に成功した場合は帳票作成
                let pages = this.createPages(tInventoriesHistories, tInventoriesList);
                if (this.$route.query.startIndex == -1) {
                  if (pages.length > MAX_PAGE_CNT) {
                    // ページ数が最大数を超過していた場合、ページ毎の印刷ボタン画面を表示
                    this.setPagePrintBtnWindow(pages.length);
                    pages = null;
                    this.$store.commit('setLoading', false);
                    return;
                  }
                } else {
                  this.startIndex = Number(this.$route.query.startIndex);
                  // スタートページよりも前の要素を除外
                  if (this.startIndex > 0) {
                    // 最初のページから印刷以外
                    pages.splice(0, this.startIndex - 1);
                  } 
                  // 最大ページ数よりも後ろの要素を除外
                  if (pages.length > MAX_PAGE_CNT) {
                    // 最大ページ数よりも後ろの要素がある場合
                    pages.splice(MAX_PAGE_CNT, pages.length);
                  }
                }
                this.pages = pages;
                const outputDatetime = moment().format('YYYY/MM/DD  HH:mm');
                const inventoryDatetime = moment(tInventoriesHistories.preprocess_datetime).format('YYYY/MM/DD');
                this.replacePageData(this.pages, 0, outputDatetime, inventoryDatetime, inventoryEntryUsers);
              } else {
                // 更新に失敗した場合はエラー処理
                addOperationLogs('Error', MODULE_NAME, functionName, `棚卸履歴の棚卸差異表日時更新でエラーが発生しました：棚卸No.=${tInventoriesHistories.inventory_no}`);
                this.$store.commit('setLoading', false);
                this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
                  title: this.title
                }).then(() => {
                  window.close();
                });
              }
            });
          });
        }).catch(async error => {
          await addOperationLogs('Error', MODULE_NAME, functionName, `棚卸データの取得でエラーが発生しました：棚卸No.=${tInventoriesHistories.inventory_no}`, error);
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
            title: this.title
          }).then(() => {
            window.close();
          });
        });
      }).catch(async error => {
        await addOperationLogs('Error', MODULE_NAME, functionName, `棚卸履歴からの棚卸No.の取得でエラーが発生しました：営業所コード=${officeId}`, error);
        this.$store.commit('setLoading', false);
        this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
          title: this.title
        }).then(() => {
          window.close();
        });
      });
    },
    /**
     * 指定された営業所コードに対応する最新の履歴情報を取得します。
     * @param {Number} offinceId - 営業所コード
     * @returns {Object} 履歴情報
     */
    async listTInventoriesHistories(officeId) {
      return await executeSelectSql(
        'SELECT' +
          ' MAX(inventory_no) AS inventory_no' +
          ',preprocess_datetime ' +
        'FROM ' +
          't_inventories_histories ' +
        'WHERE ' +
          `office_id = ${officeId} ` +
        'GROUP BY' +
          ' office_id;'
      );
    },
    /**
     * 指定された棚卸Noに対応する棚卸情報を取得します。
     * @param {Number} inventoryNo - 棚卸No
     * @returns {Array} 棚卸情報リスト
     */
    async listTInventories(inventoryNo) {
      return await executeSelectSql(
        'SELECT' +
          ' ti.inventory_no' + // 棚卸No.
          ',ti.office_id' + // 営業所コード
          ',ti.product_id' + // 製品コード
          ',ti.product_name' + // 製品名
          ',ti.inventory_count' + // 総棚卸数
          ',ti.shelves_count' + // 総実棚数
          ',ti.inventory_entry_user_id' + // 棚卸入力者
          ',mo.office_name_kanji' + // 営業所名
          ',mpd.cost_price' + // 原価単価
          ',IF(mpd.place_1 IS NULL, \'\', TRIM(mpd.place_1)) AS place_1' + // 置き場所1
          ',IF(mpd.place_2 IS NULL, \'\', TRIM(mpd.place_2)) AS place_2' + // 置き場所2
          ',IF(mpd.place_3 IS NULL, \'\', TRIM(mpd.place_3)) AS place_3' + // 置き場所3
          ',IF(mpd.place_4 IS NULL, \'\', TRIM(mpd.place_4)) AS place_4 ' + // 置き場所4
        'FROM ' +
          't_inventories ti ' +
          'INNER JOIN m_offices mo ' +
            'ON mo.office_id = ti.office_id ' +
          'INNER JOIN m_products_details mpd ' +
            'ON mpd.product_id = ti.product_id ' +
            'AND mpd.office_id = ti.office_id ' +
        'WHERE ' +
          `ti.inventory_no = ${inventoryNo} ` +
        'ORDER BY' +
          ' mpd.place_1' +
          ',mpd.place_2' +
          ',mpd.place_3' +
          ',mpd.place_4' +
          ',ti.product_id;'
      );
    },
    /**
     * 帳票に出力する棚卸差異表データを作成します。
     * @param {Object} tInventoriesHistories - 棚卸履歴
     * @param {Array} listTInventories - 棚卸リスト
     * @returns {Array} 棚卸差異表データ
     */
    createPages(tInventoriesHistories, listTInventories) {
      // 棚卸差異表データ
      const newPages = [];
      // 在庫金額
      let inventoryAmount_place = 0;
      // 帳簿在庫金額
      let bookInventoryAmount_place = 0;
      // 在庫金額（合計）
      let inventoryAmount_total = 0;
      // 帳簿在庫金額（合計）
      let bookInventoryAmount_total = 0;
      // ページデータ
      let pageData = null;

      for (const tInventories of listTInventories) {
        // ページデータにデータを追加する前に行う処理
        if (pageData === null) {
          // ページ最初のデータの場合
          pageData = this.createPageData(tInventoriesHistories, tInventories);
        } else if (pageData.dataList.length === PAGE_RECORDS_MAX) {
          // ページデータに最大ページ行数の件数までデータを詰め込んでいた場合
          newPages.push(pageData);
          pageData = this.createPageData(tInventoriesHistories, tInventories);
        } else if (
          pageData.officeId != tInventories.office_id ||
          pageData.place1 != tInventories.place_1 ||
          pageData.place2 != tInventories.place_2 ||
          pageData.place3 != tInventories.place_3 ||
          pageData.place4 != tInventories.place_4
        ) {
          // 営業所か場所が変わった場合
          if (pageData.dataList.length >= (PAGE_RECORDS_MAX - 1)) {
            newPages.push(pageData);
            pageData = this.copyPageDataFrame(pageData);
          }
          pageData.dataList.push({
            type: DATA_TYPE_PLACE_TOTAL,
            inventoryAmount: inventoryAmount_place,
            bookInventoryAmount: bookInventoryAmount_place
          });
          inventoryAmount_place = 0;
          bookInventoryAmount_place = 0;
          newPages.push(pageData);
          pageData = this.createPageData(tInventoriesHistories, tInventories);
        }

        // ページデータにデータを追加
        pageData.dataList.push({
          type: DATA_TYPE_INVENTORY,
          data: tInventories
        });

        // 場所計と合計の棚卸金額と帳簿金額に加算
        const inventoryAmount = tInventories.cost_price * tInventories.shelves_count; // 棚卸金額
        const bookAmount = tInventories.cost_price * tInventories.inventory_count; // 帳簿在庫金額
        inventoryAmount_place += inventoryAmount; // 場所計の棚卸金額
        bookInventoryAmount_place += bookAmount; // 場所計の帳簿在庫金額
        inventoryAmount_total += inventoryAmount; // 合計の棚卸金額
        bookInventoryAmount_total += bookAmount; // 合計の帳簿在庫金額
      }

      if (pageData != null) {
        if (pageData.dataList.length >= (PAGE_RECORDS_MAX - 1)) {
          newPages.push(pageData);
          pageData = this.copyPageDataFrame(pageData);
        }
        pageData.dataList.push({
          type: DATA_TYPE_PLACE_TOTAL,
          inventoryAmount: inventoryAmount_place,
          bookInventoryAmount: bookInventoryAmount_place
        });

        if (pageData.dataList.length >= (PAGE_RECORDS_MAX - 1)) {
          newPages.push(pageData);
          pageData = this.copyPageDataFrame(pageData);
        }
        pageData.dataList.push({
          type: DATA_TYPE_TOTAL,
          inventoryAmount: inventoryAmount_total,
          bookInventoryAmount: bookInventoryAmount_total
        });

        newPages.push(pageData);
      }

      return newPages;
    },
    /**
     * 棚卸差異表のページのデータを作成します。
     * @param {Object} tInventoriesHistories - 棚卸履歴
     * @param {Object} tInventories - ページの先頭行の棚卸データ
     * @returns {Object} 棚卸差異表のページデータ
     */
    createPageData(tInventoriesHistories, tInventories) {
      return {
        officeId: tInventories.office_id,
        officeName: tInventories.office_name_kanji,
        place1: tInventories.place_1,
        place2: tInventories.place_2, 
        place3: tInventories.place_3,
        place4: tInventories.place_4,
        inventoryEntryUserId: tInventories.inventory_entry_user_id,
        inventoryEntryUserName: tInventories.staff_name_kanji,
        lastUpdateDatetime: tInventoriesHistories.updated,
        dataList: []
      };
    },
    /**
     * 指定された棚卸差異表のページデータを棚卸データを除いてコピーします。
     * @param {Object} pageData - ベースとなる棚卸差異表のページデータ
     * @returns {Object} 棚卸差異表のページデータ
     */
    copyPageDataFrame(pageData) {
      return {
        officeId: pageData.officeId,
        officeName: pageData.officeName,
        place1: pageData.place1,
        place2: pageData.place2, 
        place3: pageData.place3,
        place4: pageData.place4,
        inventoryEntryUserId: pageData.inventoryEntryUserId,
        inventoryEntryUserName: pageData.inventoryEntryUserName,
        lastUpdateDatetime: pageData.lastUpdateDatetime,
        dataList: []
      };
    },
    /**
     * 指定されたページインデックス番号のSVGの文字列置換を行います。
     * @param {Array} pages - SVGのDOMオブジェクトリスト
     * @param {Number} pageIndex - ページ番号
     * @param {String} outputDatetime - 出力日時
     * @param {String} inventoryDate - 棚卸日
     * @param {Array} inventoryEntryUsers - 棚卸入力者情報リスト
     */
    async replacePageData(pages, pageIndex, outputDatetime, inventoryDate, inventoryEntryUsers) {
      setTimeout(() => {
        const svg = $(`svg#page-${pageIndex}`);
        this.resizePage(svg);

        // 各列のタイトル幅とX座標の値を取得しておく
        const tspanList = svg.find('tspan');
        const productIdElement = tspanList.filter((index, ele) => ele.textContent === '製品コード');
        const productIdElementX = Number(productIdElement.attr('x'));
        const productIdElementWidth = productIdElement[0].getBBox().width;
        const productNameElement = tspanList.filter((index, ele) => ele.textContent === '製 品 名');
        const productNameElementX = Number(productNameElement.attr('x'));
        const productNameElementWidth = productNameElement[0].getBBox().width;
        const costPriceElement = tspanList.filter((index, ele) => ele.textContent === '原価');
        const costPriceElementX = Number(costPriceElement.attr('x'));
        const costPriceElementWidth = costPriceElement[0].getBBox().width;
        const inventoryCountElement = tspanList.filter((index, ele) => ele.textContent === '棚卸数');
        const inventoryCountElementX = Number(inventoryCountElement.attr('x'));
        const inventoryCountElementWidth = inventoryCountElement[0].getBBox().width;
        const bookCountElement = tspanList.filter((index, ele) => ele.textContent === '帳簿在庫（現在庫数）');
        const bookCountElementX = Number(bookCountElement.attr('x'));
        const bookCountElementWidth = bookCountElement[0].getBBox().width;
        const differenceCountElement = tspanList.filter((index, ele) => ele.textContent === '差異数');
        const differenceCountElementX = Number(differenceCountElement.attr('x'));
        const differenceCountElementWidth = differenceCountElement[0].getBBox().width;
        const inventoryAmountElement = tspanList.filter((index, ele) => ele.textContent === '棚卸金額');
        const inventoryAmountElementX = Number(inventoryAmountElement.attr('x'));
        const inventoryAmountElementWidth = inventoryAmountElement[0].getBBox().width;
        const bookAmountElement = tspanList.filter((index, ele) => ele.textContent === '帳簿在庫金額');
        const bookAmountElementX = Number(bookAmountElement.attr('x'));
        const bookAmountElementWidth = bookAmountElement[0].getBBox().width;

        let index = 0;
        const page = pages[pageIndex];
        for (const data of page.dataList) {
          let productId = ''; // 製品コード
          let productName = ''; // 製品名
          let costPrice = ''; // 原価
          let inventoryCount = ''; // 棚卸数
          let bookCount = ''; // 帳簿在庫（現在庫数）
          let differenceCount = ''; // 差異数
          let inventoryAmount = ''; // 棚卸金額
          let bookAmount = ''; // 帳簿在庫金額

          // ページの最初のデータの場合はヘッダーの情報を設定する
          if (index === 0) {
            const inventoryEntryUser = inventoryEntryUsers.find(element => page.place1 === element.place_1 && page.place2 === element.place_2 && page.place3 === element.place_3 && page.place4 === element.place_4);
            this.replace(svg, '%OUTPUT_DATETIME%', outputDatetime, );
            this.replace(svg, '%PAGE%', (pageIndex + this.startIndex + 1).toString());
            this.replace(svg, '%OFFICE%', ` ${String(page.officeId).padStart(2, '0')}    ${page.officeName}`);
            this.replace(svg, '%INVENTORY_DATE%', inventoryDate);
            const inputter = inventoryEntryUser.inventory_entry_user_id === null ? '' : `${String(inventoryEntryUser.inventory_entry_user_id).padStart(4, '0')}    ${inventoryEntryUser.staff_name_kanji}`;
            this.replace(svg, '%INPUTTER%', inputter);
            const place1 = page.place1 === null ? '  ' : page.place1.padEnd(2, ' ')
            const place2 = page.place2 === null ? '  ' : page.place2.padEnd(2, ' ')
            const place3 = page.place3 === null ? '  ' : page.place3.padEnd(2, ' ')
            const place4 = page.place4 === null ? '  ' : page.place4.padEnd(2, ' ')
            this.replace(svg, '%PLACE%', ` ${place1} - ${place2} - ${place3} - ${place4}`);
            const lastUpdateDatetime = inventoryEntryUser.updated === null ? '' : moment(inventoryEntryUser.updated).format('YYYY/MM/DD');
            this.replace(svg, '%UPDATE_DATE%', lastUpdateDatetime);
          }
          if (data.type === DATA_TYPE_INVENTORY) {
            const dataData = data.data;
            productId = dataData.product_id; // 製品コード
            productName = dataData.product_name; // 製品名
            inventoryCount = dataData.inventory_count.toLocaleString(); // 現在庫数
            costPrice = dataData.cost_price.toLocaleString(); // 原価
            inventoryCount = dataData.shelves_count.toLocaleString(); // 棚卸数
            bookCount = dataData.inventory_count.toLocaleString(); // 帳簿在庫
            differenceCount = (dataData.shelves_count - dataData.inventory_count).toLocaleString(); // 差異数
            inventoryAmount = (dataData.cost_price * dataData.shelves_count).toLocaleString(); // 棚卸金額
            inventoryAmount = (inventoryAmount === '-0') ? '0' : inventoryAmount; // 0と負の整数の乗算は-0になるので0に差し替える
            bookAmount = (dataData.cost_price * dataData.inventory_count).toLocaleString(); // 帳簿在庫金額
            bookAmount = (bookAmount === '-0') ? '0' : bookAmount; // 0と負の整数の乗算は-0になるので0に差し替える
          } else if (data.type === DATA_TYPE_PLACE_TOTAL) {
            this.replaceAlgin(svg, `%0_${index}%`, productId, productIdElementX, productIdElementWidth, ITEM_ALIGN_CENTER); // 製品コード
            this.replaceAlgin(svg, `%1_${index}%`, productName, productNameElementX, productNameElementWidth, ITEM_ALIGN_LEFT); // 製品名
            this.replaceAlgin(svg, `%2_${index}%`, costPrice, costPriceElementX, costPriceElementWidth, ITEM_ALIGN_RIGHT); // 原価
            this.replaceAlgin(svg, `%3_${index}%`, inventoryCount, inventoryCountElementX, inventoryCountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸数
            this.replaceAlgin(svg, `%4_${index}%`, bookCount, bookCountElementX, bookCountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫（現在庫数）
            this.replaceAlgin(svg, `%5_${index}%`, differenceCount, differenceCountElementX, differenceCountElementWidth, ITEM_ALIGN_RIGHT); // 差異数
            this.replaceAlgin(svg, `%6_${index}%`, inventoryAmount, inventoryAmountElementX, inventoryAmountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸金額
            this.replaceAlgin(svg, `%7_${index}%`, bookAmount, bookAmountElementX, bookAmountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫金額

            index++;
            differenceCount = '置場所計'; // 棚卸数
            inventoryAmount = data.inventoryAmount.toLocaleString(); // 棚卸金額
            bookAmount = data.bookInventoryAmount.toLocaleString(); // 帳簿在庫金額
          } else if (data.type === DATA_TYPE_TOTAL) {
            this.replaceAlgin(svg, `%0_${index}%`, productId, productIdElementX, productIdElementWidth, ITEM_ALIGN_CENTER); // 製品コード
            this.replaceAlgin(svg, `%1_${index}%`, productName, productNameElementX, productNameElementWidth, ITEM_ALIGN_LEFT); // 製品名
            this.replaceAlgin(svg, `%2_${index}%`, costPrice, costPriceElementX, costPriceElementWidth, ITEM_ALIGN_RIGHT); // 原価
            this.replaceAlgin(svg, `%3_${index}%`, inventoryCount, inventoryCountElementX, inventoryCountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸数
            this.replaceAlgin(svg, `%4_${index}%`, bookCount, bookCountElementX, bookCountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫（現在庫数）
            this.replaceAlgin(svg, `%5_${index}%`, differenceCount, differenceCountElementX, differenceCountElementWidth, ITEM_ALIGN_RIGHT); // 差異数
            this.replaceAlgin(svg, `%6_${index}%`, inventoryAmount, inventoryAmountElementX, inventoryAmountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸金額
            this.replaceAlgin(svg, `%7_${index}%`, bookAmount, bookAmountElementX, bookAmountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫金額

            index++;
            differenceCount = '合　　計'; // 棚卸数
            inventoryAmount = data.inventoryAmount.toLocaleString(); // 在庫金額
            bookAmount = data.bookInventoryAmount.toLocaleString(); // 帳簿在庫金額
          }

          this.replaceAlgin(svg, `%0_${index}%`, productId, productIdElementX, productIdElementWidth, ITEM_ALIGN_CENTER); // 製品コード
          this.replaceAlgin(svg, `%1_${index}%`, productName, productNameElementX, productNameElementWidth, ITEM_ALIGN_LEFT); // 製品名
          this.replaceAlgin(svg, `%2_${index}%`, costPrice, costPriceElementX, costPriceElementWidth, ITEM_ALIGN_RIGHT); // 原価
          this.replaceAlgin(svg, `%3_${index}%`, inventoryCount, inventoryCountElementX, inventoryCountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸数
          this.replaceAlgin(svg, `%4_${index}%`, bookCount, bookCountElementX, bookCountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫（現在庫数）
          this.replaceAlgin(svg, `%5_${index}%`, differenceCount, differenceCountElementX, differenceCountElementWidth, ITEM_ALIGN_RIGHT); // 差異数
          this.replaceAlgin(svg, `%6_${index}%`, inventoryAmount, inventoryAmountElementX, inventoryAmountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸金額
          this.replaceAlgin(svg, `%7_${index}%`, bookAmount, bookAmountElementX, bookAmountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫金額
          index++;
        }
        for (; index < PAGE_RECORDS_MAX; index++) {
          this.replace(svg, `%0_${index}%`, '', productIdElementX, productIdElementWidth, ITEM_ALIGN_CENTER); // 製品コード
          this.replace(svg, `%1_${index}%`, '', productNameElementX, productNameElementWidth, ITEM_ALIGN_LEFT); // 製品名
          this.replace(svg, `%2_${index}%`, '', costPriceElementX, costPriceElementWidth, ITEM_ALIGN_RIGHT); // 原価
          this.replace(svg, `%3_${index}%`, '', inventoryCountElementX, inventoryCountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸数
          this.replace(svg, `%4_${index}%`, '', bookCountElementX, bookCountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫（現在庫数）
          this.replace(svg, `%5_${index}%`, '', differenceCountElementX, differenceCountElementWidth, ITEM_ALIGN_RIGHT); // 差異数
          this.replace(svg, `%6_${index}%`, '', inventoryAmountElementX, inventoryAmountElementWidth, ITEM_ALIGN_RIGHT); // 棚卸金額
          this.replace(svg, `%7_${index}%`, '', bookAmountElementX, bookAmountElementWidth, ITEM_ALIGN_RIGHT); // 帳簿在庫金額
        }
        pageIndex++;
        if (pageIndex < pages.length) {
          this.replacePageData(pages, pageIndex, outputDatetime, inventoryDate, inventoryEntryUsers);
        } else {
          this.$store.commit('setLoading', false);
        }
      }, 0);
    },
    /**
     * ページサイズを調整します。
     * @param {Object} page - ページ
     */
    resizePage(page) {
      page.attr('viewBox', `0 0 ${page.innerWidth()} ${page.innerHeight()}`);
      page.attr('width', '297mm');
      page.attr('height', '209mm');
    },
    /**
     * 指定されたページのキーワード置換を行います。
     * @param {Object} page - SVG
     * @param {String} keyword - キーワード
     * @param {String} value - 置換文字列
     */
    replace(page, keyword, value) {
      const element = page.find(`tspan:contains("${keyword}")`);
      element.text(value);
      return element;
    },
    /**
     * 指定されたページのキーワード置換を行います。
     * @param {Object} page - SVG
     * @param {String} keyword - キーワード
     * @param {String} value - 置換文字列
     * @param {Number} titleX - タイトルのX座標
     * @param {Number} titleWidth - タイトルの幅
     * @param {Number} pos - 配置位置
     */
    replaceAlgin(page, keyword, value, titleX, titleWidth, pos) {
      const element = this.replace(page, keyword, value);
      if (pos === ITEM_ALIGN_LEFT) {
        element.attr('x', titleX);
      } else if (pos === ITEM_ALIGN_CENTER) {
        const elementWidth = element[0].getBBox().width;
        const x = (titleWidth > elementWidth) ? Number(titleX) + ((titleWidth - elementWidth) / 2) : Number(element.attr('x')) + ((elementWidth - titleWidth) / 2);
        element.attr('x', x);
      } else if (pos === ITEM_ALIGN_RIGHT) {
        element.attr('x', Number(titleX) + titleWidth - element[0].getBBox().width);
      }
    },
    /**
     * 棚卸履歴の棚卸表出力日時を更新します。
     * @param {Number} inventoryNo - 棚卸No.
     * @returns {Boolean} 成功した場合はtrue、失敗した場合はfalse
     */
    async updateInventoriesHistories(inventoryNo) {
      const functionName = 'updateInventoriesHistories';
      const sql = 'UPDATE ' +
          't_inventories_histories ' +
        'SET' +
          ' print_stock_variance_datetime = CURRENT_TIMESTAMP()' +
          ',updated = CURRENT_TIMESTAMP()' +
          `,updated_user = '${store.getters.user.username}' ` +
        'WHERE ' +
          `inventory_no = ${inventoryNo};`;
      return executeTransactSqlList([ sql ], MODULE_NAME, functionName);
    },
    /**
     * 置き場所ごとの最終更新者情報を取得します。
     * @param {Number} inventoryNo - 棚卸No.
     * @returns {Array} 置き場所ごとの最終更新者情報
     */
    async listInventoryEntryUsers(inventoryNo) {
      const functionName = 'listInventoryEntryUsers';

      // 置き場所ごとの最終更新日時を取得
      const sql1 = 'SELECT' +
          ' subquery.place_1' +
          ',subquery.place_2' +
          ',subquery.place_3' +
          ',subquery.place_4' +
          ',MAX(subquery.updated) updated ' +
        'FROM ' +
          '(' +
            'SELECT' +
              ' IF(mpd.place_1 IS NULL, \'\', TRIM(mpd.place_1)) place_1' +
              ',IF(mpd.place_2 IS NULL, \'\', TRIM(mpd.place_2)) place_2' +
              ',IF(mpd.place_3 IS NULL, \'\', TRIM(mpd.place_3)) place_3' +
              ',IF(mpd.place_4 IS NULL, \'\', TRIM(mpd.place_4)) place_4' +
              ',ti.updated ' +
            'FROM ' +
              't_inventories ti ' +
              'INNER JOIN m_products_details mpd ON mpd.office_id = ti.office_id AND mpd.product_id = ti.product_id ' +
            'WHERE ' +
              `ti.inventory_no = ${inventoryNo}` +
          ') subquery ' +
        'GROUP BY' +
          ' subquery.place_1' +
          ',subquery.place_2' +
          ',subquery.place_3' +
          ',subquery.place_4';
      let results1 = null;
      try {
        results1 = await executeSelectSql(sql1);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, `棚卸商品からの場所ごとの最終更新日時取得でエラーが発生しました：棚卸No.=${inventoryNo}`, error);
        return null;
      }

      // 置き場所ごとに最終更新日時だった棚卸入力者を取得します。
      // 同じ日時の人がいた場合は最も若い担当者コードの棚卸入力者を採用します。
      // 置き場所10カ所ごとにまとめてSQLを発行します。
      const results = [];
      let sqlList2 = [];
      for (const result1 of results1) {
        const sql2 = 'SELECT' +
            ' IFNULL(mpd.place_1, \'\') place_1' +
            ',IFNULL(mpd.place_2, \'\') place_2' +
            ',IFNULL(mpd.place_3, \'\') place_3' +
            ',IFNULL(mpd.place_4, \'\') place_4' +
            ',ti.inventory_entry_user_id' +
            ',IF(ti.updated = ti.created, NULL, ti.updated) updated' +
            ',ms.staff_name_kanji ' +
          'FROM ' +
            't_inventories ti ' +
            'INNER JOIN m_products_details mpd ON mpd.office_id = ti.office_id AND mpd.product_id = ti.product_id ' +
            'LEFT JOIN m_staffs ms ON ms.staff_id = ti.inventory_entry_user_id ' +
          'WHERE ' +
            `ti.inventory_no = ${inventoryNo} ` +
            (result1.place_1 === '' ? 'AND (mpd.place_1 IS NULL OR TRIM(mpd.place_1) = \'\') ' : `AND mpd.place_1 = '${result1.place_1}' `) +
            (result1.place_2 === '' ? 'AND (mpd.place_2 IS NULL OR TRIM(mpd.place_2) = \'\') ' : `AND mpd.place_2 = '${result1.place_2}' `) +
            (result1.place_3 === '' ? 'AND (mpd.place_3 IS NULL OR TRIM(mpd.place_3) = \'\') ' : `AND mpd.place_3 = '${result1.place_3}' `) +
            (result1.place_4 === '' ? 'AND (mpd.place_4 IS NULL OR TRIM(mpd.place_4) = \'\') ' : `AND mpd.place_4 = '${result1.place_4}' `) +
            `AND ti.updated = '${result1.updated}' ` +
          'ORDER BY' +
            ' ti.inventory_entry_user_id ' +
          'LIMIT 1;'
        sqlList2.push(sql2);

        if (sqlList2.length === 10) {
          // 10カ所分のSQLが溜まったら実行
          let results2 = null;
          try {
            results2 = await API.graphql(graphqlOperation(executeTransactSql, {
              SQLs: sqlList2
            }));
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqlList2
            }, error);
            return null;
          }
          if (results2.errors) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqlList2,
              result: results2
            });
            return null;
          }
          const responseBody = JSON.parse(results2.data.executeTransactSql.body);
          for (const data of responseBody.data) {
            results.push(data[0]);
          }
          sqlList2 = [];
        }
      }

      // 未実行SQLが残っていた場合はここで実行
      if (sqlList2.length > 0) {
        let results3 = null;
        try {
          results3 = await API.graphql(graphqlOperation(executeTransactSql, {
            SQLs: sqlList2
          }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList2
          }, error);
          return null;
        }
        if (results3.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList2,
            result: results3
          });
          return null;
        }
        const responseBody = JSON.parse(results3.data.executeTransactSql.body);
        for (const data of responseBody.data) {
          results.push(data[0]);
        }
      }

      return results;
    },
    /**
     * ページ毎印刷ボタン画面を設定します。
     * @param {Number} pageCnt - ページ数
     */
    setPagePrintBtnWindow: function(pageCnt) {
      let cntNokori = pageCnt;
      this.btnPrintList = [];
      let btnCnt = 0;
      while (cntNokori > 0) {
        // 全ページの印刷ボタンを用意するまでループ
        cntNokori -= MAX_PAGE_CNT;
        this.btnPrintList.push({name: (btnCnt * MAX_PAGE_CNT + 1).toString() + 'ページ目から印刷', startIndex: btnCnt * MAX_PAGE_CNT });
        btnCnt++;
      }
      this.btnFlg = true;
    },
    /**
     * PDF印刷ボタンクリックイベント処理
     */
    onPrintButtonClick(officeId, startIndex) {
      // 棚卸差異表印刷プレビュー画面へ遷移します。
      const route = this.$router.resolve({
        name: 'INVENTORY-STOCK-VARIANCE-PRINT',
        query: {
          officeId: officeId,
          startIndex: startIndex,
        }
      });
      window.open('', '_blank').location = route.href
    },
  }
}
</script>

