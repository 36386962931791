<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                        <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 営業所入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="営業所" label-for="salesOfficeOption">
                            <b-form-select
                              id="salesOfficeOption"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOfficeOption"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 伝票種別区分プルダウン -->
                        <b-col lg="6">
                          <b-form-group label="伝票種別" label-for="selectBillClass">
                            <b-input-group>
                              <b-form-select
                                id="selectBillClass"
                                v-model="searchConditions.selectBillClass"
                                :options="searchConditions.purchaseBillClassList"
                                value-field="id"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="伝票番号">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="orderDateStart" :rules="{dateConsistency:searchConditions.orderDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group label="伝票日付" label-for="datepicker">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="orderDateStart" class="form-control" v-model="searchConditions.orderDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="orderDateEnd" class="form-control" v-model="searchConditions.orderDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                            <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、伝票番号か取引先コードを指定してください。</b-form-text>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="取引先コード" label-for="customerCode">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="取引先名" label-for="customerName">
                            <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 製品コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="製品コード" label-for="productCode">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">前方一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="製品名" label-for="productName">
                            <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="clientList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(operation)="operation">
                <div v-show="!printStatus">
                  <!-- 修正ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left title="仕入単価の修正を行います。" @click="clickEditBtn(operation.item)" class="mr-1">
                    <span class="oi oi-pencil"></span> 修正
                  </b-button>&nbsp;
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, formatDate, formatCurDate, formatDateCalc, convertSqlLikeSpecialChar, getControlMaster, dateConsistency, getListValue, executeSelectSql, setOfficeListOption } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'purchase-unit-price-list';

export default {
  name: 'PURCHASE-UNIT-PRICE-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入単価修正一覧',
      // アラート
      alertSuccess: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOfficeOption: [],
        selectBillClass: 0,
        purchaseBillClassList: [],
        traditionList: [],
        billingNo: '',
        personInCharge: '',
        orderDateStart: '',
        orderDateEnd: '',
        clientCode: '',
        clientName: '',
        siteName: '',
        productCode: '',
        productName: '',
      },
      // 検索結果
      clientList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchPurchaseListData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'billingDate',
          label: '伝票日付',
          sortable: true,
          thStyle: 'width: 15%;',
        },
        {
          key: 'billingNo',
          label: '伝票番号',
          sortable: true,
          thStyle: 'width: 10%;',
        },
        {
          key: 'billClass',
          label: '伝票種別',
          sortable: true,
          thStyle: 'width: 15%;',
        },
        {
          key: 'salesOffice',
          label: '営業所名',
          sortable: true,
          thStyle: 'width: 10%;',
        },
        {
          key: 'customerCode',
          label: '取引先コード',
          sortable: true,
          thStyle: 'width: 10%;',
        },
        {
          key: 'customerName',
          label: '取引先名',
          thStyle: 'width: 35%;',
          sortable: true,
        },
        {
          key: 'operation',
          label: '操作',
          thStyle: 'width: 5%;',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0
    },
  },
  /* 関数群 */
  methods:{
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchPurchaseListData(){
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報を取得
        let user = store.getters.user;

        // 営業所データ取得
        await setOfficeListOption()
        // 営業所プルダウン作成
        this.searchConditions.salesOfficeOption = this.$store.getters.officeOption
        this.searchConditions.selectSalesOffice = user.office_id
       
        // 伝票種別
        this.searchConditions.purchaseBillClassList.push({id: 0, text: '全て'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「11:通常売上」「12:返品伝票」「14:直送伝票」
          if (Const.TraditionList[i].id == Const.Tradition.salesNormal ||
          Const.TraditionList[i].id == Const.Tradition.salesReturn ||
          Const.TraditionList[i].id == Const.Tradition.salesDirect) {
            this.searchConditions.purchaseBillClassList.push(Const.TraditionList[i]);
          }
        }
        //console.log(this.searchConditions.purchaseBillClassList);
        // 伝票日付に１か月前～を設定
        this.searchConditions.orderDateStart = formatDateCalc(formatCurDate(), 0, -1, 0, false, 'YYYY-MM-DD');
        // 初期検索
        //await this.search();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.clientList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let resultData = null;
      try {
        resultData = await executeSelectSql(selectSql);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      //console.log(resultData);
      if(resultData != null){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      }else{
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // コントロールマスタの現在処理年月の初日を作成
      let controlData = await getControlMaster();
      let processDate = String(controlData.process_month_year) + '01';
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          billingDate: formatDate(result[i].billing_date),
          billingNo: result[i].billing_no,
          billClass: getListValue(Const.TraditionList, result[i].bill_class, 'id', 'text'),
          salesOffice: result[i].office_name_kanji,
          customerCode: result[i].client_id,
          customerName: result[i].client_name_kanji,
          monthlyMonthYear: result[i].monthly_month_year,
          operation: result[i],
        };
        // 日付は現在処理年月の初日と同じ、または、未来の場合、編集可
        searchResult.EditFlg = dateConsistency(processDate, searchResult.billingDate);
        this.clientList.push(searchResult);
      }
    },

    /* SELECT文字列作成 */
    async makeSelectSql(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',cumulative_transaction.monthly_month_year';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON cumulative_transaction.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON cumulative_transaction.order_receive_id = orders_receives.order_receive_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID = 'UR'
      selectSql += `cumulative_transaction.transaction_id = '${Const.TransactionId.sales}' `;
      // 仕入単価0
      selectSql += 'AND cumulative_transaction.product_purchase_price = 0 ';
      // 伝票種別
      if (this.searchConditions.selectBillClass != 0) {
        selectSql += 'AND cumulative_transaction.bill_class =' + this.searchConditions.selectBillClass + ' ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND cumulative_transaction.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 伝票番号
      if(this.searchConditions.billingNo != ''){
        selectSql += 'AND cumulative_transaction.billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 消費税行を除外
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      // 伝票日付
      if(this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == ''){
        selectSql += 'AND cumulative_transaction.billing_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      }else if(this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != ''){
        selectSql += 'AND cumulative_transaction.billing_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }else if(this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != ''){
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 取引先コード
      if(this.searchConditions.clientCode != ''){
        selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
        selectSql += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if(this.searchConditions.clientName != ''){
        // 特殊文字変換
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 担当者コード
      if(!(this.searchConditions.personInCharge === '')){
        selectSql += 'AND cumulative_transaction.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 製品コード
      if(this.searchConditions.productCode != ''){
        selectSql += 'AND cumulative_transaction.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if(this.searchConditions.productName != ''){
        // 特殊文字変換
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.billing_no';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' billing_date DESC';
      selectSql += ',billing_no DESC';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 仕入単価修正ボタン押下時 */
    async clickEditBtn({billingNo, monthlyMonthYear}){
      //console.log('修正', billingNo);
      let route = this.$router.resolve({ name: 'PURCHASE-UNIT-PRICE-EDIT', query: { billingNo, monthlyMonthYear } });
      window.open(route.href, '_blank');
    },

    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>