<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="売上入力(仮伝票)の新規登録" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="売上入力(仮伝票)の請求書出力" @click="clickSeikyuBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-print"></span> 仮請求書(F9)
                    <button v-shortkey="['f9']" @shortkey="clickSeikyuBtn" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 営業所入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="営業所" label-for="salesOfficeOption">
                            <b-form-select id="salesOfficeOption" v-model="searchConditions.selectSalesOffice" :options="searchConditions.salesOfficeOption">
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="伝票番号" label-for="billingNo">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="billingNo" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingDateStart" :rules="{dateConsistency:searchConditions.billingDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group label="伝票日付" label-for="datepicker1">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="billingDateStart" class="form-control" v-model="searchConditions.billingDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="billingDateEnd" class="form-control" v-model="searchConditions.billingDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票種別入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="伝票種別" label-for="selectTradition">
                            <b-form-select
                              id="selectTradition"
                              v-model="searchConditions.selectTradition"
                              :options="searchConditions.traditionList"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="取引先コード" label-for="customerCode">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="取引先名" label-for="customerName">
                            <b-form-input type="text" id="customerName" name="customerName" v-model.trim="searchConditions.clientName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。仮伝票に登録済みの取引先名と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 担当者コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="personInCharge" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="担当者コード">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" name="personInCharge" v-model="searchConditions.personInCharge"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 現場名入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="現場名" label-for="siteName">
                            <b-form-input type="text" id="siteName" name="siteName" v-model.trim="searchConditions.siteName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの現場名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 製品コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group label="製品コード" label-for="productCode">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">前方一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="6">
                          <b-form-group label="製品名" label-for="productName">
                            <b-form-input type="text" id="productName" name="productName" v-model.trim="searchConditions.productName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- printイベント用のレイアウト -->
              <template v-if="printStatus" #cell(customerName)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.customerName }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(siteName)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.siteName }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(operation)="operation">
                <div v-show="!printStatus">
                  <!-- 仮伝票発行ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の仮伝票発行を行います。'" @click="clickPrintBtn(operation.item)" class="mr-1">
                    <span class="oi oi-print"></span> 仮伝票発行
                  </b-button>
                  <!-- 修正ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の修正を行います。'" @click="clickEditBtn(operation.item)" class="mr-1">
                    <span class="oi oi-pencil"></span> 修正
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の削除を行います。'" @click="clearAlert(); clickDeleteBtn(operation.item)" class="mr-1">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group :label="getPagingMessage" class="mt-0 mb-0"/>
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, formatDate, convertSqlLikeSpecialChar, addOperationLogs, getListValue, getControlMaster, executeSelectSql, executeTransactSqlList, setOfficeListOption, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'tentative-sales-list';

export default {
  name: 'TENTATIVE-SALES-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売上一覧(仮伝票)',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOfficeOption: [],
        billingNo: '',
        billingDateStart: '',
        billingDateEnd: '',
        selectTradition: 0,
        traditionList: [],
        personInCharge: '',
        clientCode: '',
        clientName: '',
        siteName: '',
        productCode: '',
        productName: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 削除確認ダイアログ用
      confirmMessage: [],
      delData: {},
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'billingDate',
          label: '伝票日付',
          sortable: true,
        },
        {
          key: 'billingNo',
          label: '伝票番号',
          sortable: true,
        },
        {
          key: 'billClass',
          label: '伝票種別',
          sortable: true,
        },
        {
          key: 'salesOffice',
          label: '営業所名',
          sortable: true,
        },
        {
          key: 'staffId',
          label: '担当者コード',
          sortable: true,
        },
        {
          key: 'personInCharge',
          label: '担当者名',
          sortable: true,
        },
        {
          key: 'customerCode',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'customerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'siteName',
          label: '現場名',
          sortable: true,
        },
        {
          key: 'operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertDanger.length > 0
    },
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'TENTATIVE-SALES-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報を取得
        let user = store.getters.user;

        // 営業所データ取得
        await setOfficeListOption()
        // 営業所プルダウン作成
        this.searchConditions.salesOfficeOption = this.$store.getters.officeOption
        // 営業所データ初期値セット
        this.searchConditions.selectSalesOffice = user.office_id
        // 伝票種別
        this.searchConditions.traditionList.push({id: 0, text: '全て'});
        Const.TraditionList.forEach(Tradition => {
          // 「11:通常売上」「12:返品伝票」「14：直送伝票」
          if (Tradition.id == Const.Tradition.salesNormal || Tradition.id == Const.Tradition.salesReturn || Tradition.id == Const.Tradition.salesDirect) {
            this.searchConditions.traditionList.push(Tradition);
          }
        });
        // 現在処理年月、消費税率取得
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      this.itemList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log('条件取得', selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null) {
        await this.setResult(resultData);
        if(resultData.length >= 1000) {
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.client_name AS client_name_kanji';
      selectSql += ',cumulative_transaction.client_site_name';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON cumulative_transaction.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      // 伝票種別
      if (this.searchConditions.selectTradition != 0) {
        // 伝票種別を指定した場合
        selectSql += 'AND cumulative_transaction.bill_class = ' + this.searchConditions.selectTradition + ' ';
      } else {
        // 伝票種別が全て（11:通常伝票、12:返品伝票、14：直送伝票）
        selectSql += 'AND cumulative_transaction.bill_class IN (' + Const.Tradition.salesNormal + ',' + Const.Tradition.salesReturn + ',' + Const.Tradition.salesDirect + ') ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND cumulative_transaction.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 伝票番号
      if (this.searchConditions.billingNo != '') {
        selectSql += 'AND cumulative_transaction.billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 伝票日付
      if (this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd == '') {
        selectSql += 'AND cumulative_transaction.billing_date >= ' + '\'' + this.searchConditions.billingDateStart + '\' ';
      } else if(this.searchConditions.billingDateStart == '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date <= ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      } else if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN ' + '\'' + this.searchConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName)
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.client_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 担当者コード
      if (!(this.searchConditions.personInCharge === '')) {
        selectSql += 'AND cumulative_transaction.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 現場名(あいまい検索対象)
      if (this.searchConditions.siteName != '') {
        const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName)
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.client_site_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 製品コード
      if (this.searchConditions.productCode != '') {
        selectSql += 'AND cumulative_transaction.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if (this.searchConditions.productName != '') {
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName)
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.billing_no';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' billing_date';
      selectSql += ',billing_no';
      selectSql += ',billing_row';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          billingDate: formatDate(result[i].billing_date),
          billingNo: result[i].billing_no,
          billClass: getListValue(Const.TraditionList, result[i].bill_class, 'id', 'text'),
          salesOffice: result[i].office_name_kanji,
          staffId: result[i].staff_id,
          personInCharge: result[i].staff_name_kanji,
          customerCode: result[i].client_id,
          customerName: result[i].client_name_kanji,
          siteName: result[i].client_site_name,
          operation: result[i],
        };
        this.itemList.push(searchResult);
      }
    },
    /* 仮伝票発行ボタン押下時 */
    async clickPrintBtn({billingNo }) {
      // 得意先元帳PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        billingNo,
      };
      let route = this.$router.resolve({ name: 'SALES-TENTATIVE-NOUHIN', query});
      window.open(route.href, '_blank');
    },
    /* 修正ボタン押下時 */
    async clickEditBtn({billingNo}) {
      let route = this.$router.resolve({ name: 'TENTATIVE-SALES-EDIT', query: { billingNo: billingNo, parentKbn: 0 } });
      window.open(route.href, '_blank');
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn({billingNo}) {
      //console.log('削除');
      this.delData = { billingNo };
      this.confirmMessage = [];
      this.confirmMessage.push('選択されたデータを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 累積トランザクション削除SQL
      const delSql = `
        DELETE FROM t_cumulative_transaction_tentative 
        WHERE 
        transaction_id = '${Const.TransactionId.sales}' 
        AND billing_no = ${this.delData.billingNo} 
      `

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      try {
        await executeTransactSqlList(delSql, MODULE_NAME, functionName)
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },

    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      this.$router.push({ name: 'TENTATIVE-SALES-INPUT' });
    },
    /* 仮請求書ボタン押下時 */
    async clickSeikyuBtn() {
      let route = this.$router.resolve({ name: 'TENTATIVE-INVOICE-LIST'});
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
</style>