<template>
  <b-modal
    id="clientProductInquiryModal"
    size="xl"
    centered
    @show="searchButton('init')"
    title="取引先製品単価履歴照会"
  >
    <b-container>
      <b-row>
        <b-col>
          <!-- ●●●検索条件●●● -->
          <div>
            <b-card>
              <div class="mb-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-1">
                  <b-form @submit.prevent="searchButton('click');" id="clientProductInquiryForm" class="form-horizontal">
                    <validation-observer ref="observer">
                      <b-row>
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 form-group  px-1">
                          <b-input-group>
                            <template #prepend>
                              <label for="clientId">
                                <b-input-group-text  class="px-1">
                                  取引先コード
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="text" id="clientId" name="clientId" v-model="clientProductProp.clientId" readonly></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 form-group  px-1">
                          <b-input-group>
                            <template #prepend>
                              <label for="clientNameKanji">
                                <b-input-group-text  class="px-1">
                                  取引先名
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="text" id="clientNameKanji" name="clientNameKanji" v-model="clientProductProp.clientNameKanji" readonly></b-form-input>
                          </b-input-group>
                        </div>
                      </b-row>
                      <b-row>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group  px-1">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-input-group>
                              <template #prepend>
                                <label for="productCode">
                                  <b-input-group-text  class="px-1">
                                    製品コード
                                  </b-input-group-text>
                                </label>
                              </template>
                              <b-form-input type="search" id="productCode" name="productCode" v-model="searchConditions.productCode" />
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>    
                            </b-input-group>
                          </validation-provider>
                        </div>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-1">
                        <b-col lg="3">
                          <b-button block pill variant="success" type="submit" form="clientProductInquiryForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                        </b-col>
                      </b-row>
                    </validation-observer>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="1ページの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="5" class="pl-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            show-empty
            :small="true"
            :items="clientProductList"
            :fields="fields"
            :busy="busy"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :sticky-header= true
            @filtered="onFiltered"
            >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <template #modal-footer>
      <b-button size="sm" @click="$bvModal.hide('clientProductInquiryModal')" class="mr-1">閉じる</b-button>
    </template>
  </b-modal>
<!-- ●●●取引先検索モーダル●●● -->
</template>
<script>
import { init, formatDate, addOperationLogs } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_v_clients_products_01 } from '@/graphql/queries';

const MODULE_NAME = 'client-product-inquiry';

export default {
  name: 'CLIENT-PRODUCT-INQUIRY',
  props:['clientProductProp'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先製品単価履歴照会',
      // 検索条件
      searchConditions: {
        productCode: '',
      },
      // 検索結果
      clientProductList: [],
      fields:[
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'UnitPriceEffectiveDate',
          label: '適用年月日',
        },
        {
          key: 'SalesUnitPrice',
          label: '適用単価',
          tdClass: 'text-right',
        },
        {
          key: 'Reason',
          label: '理由',
        },
      ],
      // 検索結果
      resultError: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  /* 関数群 */
  methods:{
    /* 検索ボタン押下時 */
    async searchButton(key) {
      const functionName = 'searchButton';
      try {
        if (key == 'init') {
          // 初期表示時
          this.busy = true;
          this.searchConditions.productCode = '';
          await this.search();
        } else {
          const observer = this.$refs.observer;
          const success = await observer.validate();
          if (!success) {
            const el = document.querySelector('#error:first-of-type');
            el.scrollIntoView({ block: 'center', inline: 'nearest' });
          }else{
            if (this.busy == true) {
              // 連続して押下された場合は処理を行わない
              return;
            }
            this.busy = true;
            await this.search();
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 検索処理 */
    async search(){
      this.resultError = [];
      this.clientProductList = [];
      // ページング機能の初期化
      this.initPaging();
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let resultClientsProducts = await API.graphql(graphqlOperation(list_v_clients_products_01,{where_clause: where_clause}));
      let resultData = resultClientsProducts.data.list_v_clients_products_01;
      //console.log(resultData);
      if(resultData != null){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      }else{
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          ProductCode: result[i].product_id,
          ProductName: result[i].product_name_kanji,
          UnitPriceEffectiveDate: formatDate(result[i].unit_price_effective_date),
          SalesUnitPrice: result[i].sales_unit_price.toLocaleString(),
          Reason: result[i].reason,
        };
        this.clientProductList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake(){
      let where_clause = '';
      // 取引先区分
      where_clause += 'AND client_class = ' + this.clientProductProp.clientClass + ' ';
      // 取引先コード
      where_clause += 'AND client_id = ' + this.clientProductProp.clientId + ' ';
      // 製品コード
      if (this.searchConditions.productCode == '') {
        where_clause += 'AND product_id IN (' + this.clientProductProp.productIdCsv + ') ';
      } else {
        where_clause += 'AND product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }

      // ソート
      where_clause += 'LIMIT 1000 ';

      return where_clause;
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
</style>