<template>
<div>
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <b-card-title>
              <h5 v-if="monthYear!='' && updateFlg==false && missMonthYearFlg==false">
                他のジョブが終了しているのを確認してください。<br/>
                {{monthYear}} 分の支払締更新を行います。<br/>
                以下の条件でよろしいですか？
              </h5>
              <h5 v-if="updateFlg==false && missMonthYearFlg==true">
                以下の条件は現在処理年月と異なるため締更新は不可能です。<br/>
                支払前処理で条件を変更してください。
              </h5>
              <h5 v-if="updateFlg==true">
                以下の条件の支払締更新は実施済みです。<br/>
                支払前処理で条件を変更してください。
              </h5>
            </b-card-title>
            <b-container>
              <b-row>
                <b-col lg="6">
                  <b-table
                    show-empty
                    empty-text="データが登録されていません。支払前処理画面で登録できます。"
                    :fields="fields"
                    :items="results"
                  >
                  </b-table>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
          <!-- 更新ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button pill block variant="success" v-b-tooltip.hover title="表示中の締日、締切日の支払書の締更新を行います。" @click="clearAlert(); clickUpdateBtn();" :disabled="results.length==0||updateFlg==true||missMonthYearFlg==true">
                  <span class="oi oi-circle-check"></span> 更新
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['表示中の条件で支払締更新を行います。','よろしいですか？']" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatDateCalc, executeSelectSql, addOperationLogs, CreateInsertSql, CreateUpdateSql, CreateColRow, executeTransactSqlList, isSystemEditable, getControlMaster, getClosingDate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_closing_update_completion, list_t_closing_update_range } from '@/graphql/queries';

const MODULE_NAME = 'payment-update';

export default ({
  name: 'PAYMENT-UPDATE',
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '支払締更新処理',
      // 同時更新取引先数
      maxSameTimeClientsCount: 7000,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索結果
      results: [],
      monthYear: '',
      paymentMonthYear: '',
      updateFlg: false,
      missMonthYearFlg: false,
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 画面起動時の請求前設定範囲
      closingUpdateRange: {
        process_month_year: null,
        closing_date: null,
      },
      // 支払前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
        csvClientId: [],
      },
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /** フィールド */
    fields() {
      return [
        {
          key    : 'invoiceCount',
          label  : '件数',
        },
        {
          key     : 'closingDate',
          label   : '締日',
        },
        {
          key     : 'deadlineDate',
          label   : '締切日',
        },
      ];
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)を保持
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 最初に初期化
      this.monthYear = '';
      this.paymentMonthYear = '';
      this.updateFlg = false;
      this.results = [];
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = null;
      let controlData = null;
      [dataResult, controlData] = await Promise.all([
        executeSelectSql(selectSql),
        getControlMaster(),
      ]);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult, controlData);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' (SELECT COUNT(*) FROM m_payments) AS payments_cnt';
      selectSql += ',closing_update_range.process_month_year';
      selectSql += ',closing_update_range.closing_date';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_closing_update_range AS closing_update_range ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'closing_update_range.billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result, controlData) {
      if (result.length > 0) {
        let deadlineDate = null;
        if (result[0].closing_date == 99) {
          deadlineDate = formatDateCalc(('000000' + result[0].process_month_year).slice(-6) + '01', 0, 0, 0, true);
        } else {
          deadlineDate = formatDate(('000000' + result[0].process_month_year).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        let searchResult = {
          invoiceCount: result[0].payments_cnt.toLocaleString(),
          closingDate: result[0].closing_date,
          deadlineDate: deadlineDate,
        };
        this.results.push(searchResult);
        // 対象の年月を設定
        this.monthYear = formatDate(deadlineDate, 'YYYY/MM');
        this.paymentMonthYear = formatDate(deadlineDate, 'YYYY-MM');
        // 画面起動時の支払前処理の設定範囲を保持
        this.closingUpdateRange.process_month_year = result[0].process_month_year;
        this.closingUpdateRange.closing_date = result[0].closing_date;
        // 更新済みかどうかの値を設定
        this.updateFlg = await this.checkUpdateCompletion(result[0].process_month_year, result[0].closing_date);
        // 現在処理年月と等しいかどうかの値を設定
        this.missMonthYearFlg = result[0].process_month_year != controlData.process_month_year;
      }
    },
    // 更新完了チェック
    async checkUpdateCompletion(processMonthYear, closingDate) {
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';
      where_clause += 'AND process_month_year = ' + processMonthYear + ' ';
      where_clause += 'AND closing_date = ' + closingDate + ' ';
      let condition = {where_clause: where_clause};
      let result = await API.graphql(graphqlOperation(list_t_closing_update_completion, condition));
      let resultData = result.data.list_t_closing_update_completion;
      //console.log(resultData);
      if (resultData == null || resultData.length == 0) {
        // 指定した年月と締日でまだ締更新を行っていない
        return false;
      } else {
        // 指定した年月と締日で締更新実行済み
        return true;
      }
    },
    /* 更新ボタン押下時 */
    async clickUpdateBtn() {
      //console.log('更新');
      this.$bvModal.show('confirmModal');
    },
    // 更新確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          // 保存処理
          this.$store.commit('setLoading', true);
          if (await this.checkClosingUpdateRange() == true) {
            // 対象データ取得
            await this.getBillingDateInfo();
            //console.log('更新処理開始');
            if (await this.execUpdate() == true) {
              this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1003']);
              await this.setInitData();
            }
            //console.log('更新処理終了');
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 締更新範囲確認
    async checkClosingUpdateRange() {
      // 検索条件作成
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';
      //console.log(where_clause);
      let result = await API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause}));
      let dataResult = result.data.list_t_closing_update_range;
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        if (this.closingUpdateRange.process_month_year == dataResult[0].process_month_year &&
            this.closingUpdateRange.closing_date == dataResult[0].closing_date) {
          if (await this.checkUpdateCompletion(dataResult[0].process_month_year, dataResult[0].closing_date) == false) {
            return true;
          } else {
            return false;
          }
        } else {
          // 画面起動時と支払前の範囲が異なる場合はNG
          return false;
        }
      } else {
        return false;
      }
    },
    /* 更新処理 */
    async execUpdate() {
      const functionName = 'execUpdate';
      let retResult = false;
      this.transactSqlList = [];
      if (this.preBilling.csvClientId.length > 0) {
        // 支払残高UPDATE/INSERT SQL作成処理
        this.createUpdateTPaymentsBalancesSQL();
        // 累積トランザクションUPDATESQL作成処理
        await this.createCumulativeTransactionUpdateSql();
      }
      // 締更新完了登録SQL作成
      await this.insertClosingUpdateCompletion();
      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return retResult;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return retResult;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return retResult;
      }
      retResult = true;

      return retResult;
    },
    /**
     * 支払残高を締次するSQLを作成します。
     */
    createUpdateTPaymentsBalancesSQL() {
      for (let i = 0; i < this.preBilling.csvClientId.length; i++) {
        let csvClientId = this.preBilling.csvClientId[i];
        // 累積トランザクション集計用サブクエリ
        const subquery = 'SELECT' +
            ' ' + this.preBilling.processMonthYear + ' AS payment_month_year' + // 支払年月
            ',tct.client_id' + // 仕入先コード
            ',\'' + this.preBilling.billingStartDate + '\' AS payment_start_date' + // 支払開始日
            ',\'' + this.preBilling.billingEndDate + '\' AS payment_end_date' + // 支払締切日
            `,SUM(IF(tct.transaction_id = '${Const.TransactionId.stocking}', tct.product_purchase_amount, 0)) AS closing_date_payable` + // 締日買掛仕入
            `,SUM(IF(tct.transaction_id = '${Const.TransactionId.payment}' AND tct.kind IN ('C','D','E'), 0, tct.amount)) AS closing_date_payments` + // 締日支払
            `,SUM(IF(tct.transaction_id = '${Const.TransactionId.payment}' AND tct.kind IN ('C','D','E'), tct.amount, 0)) AS closing_date_tax_payments` + // 締日消費税支払
            ',SUM(' +
            '   CASE WHEN (tct.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')' +
            '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN tct.product_purchase_amount ELSE 0 END) ' +
            '   ELSE 0 END)' +
            ' AS closing_date_normal_tax_subtotal' + // 締日通常消費税課税小計
            ',TRUNCATE((SUM(' +
            '   CASE WHEN (tct.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')' +
            '     THEN tct.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= tct.billing_date THEN ' +
            '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)' +
            '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)' +
            '   ELSE 0 END) / 100),0)' +
            ' AS closing_date_normal_tax' + // 締日通常消費税額
            ',SUM(' +
            '   CASE WHEN (tct.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')' +
            '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN tct.product_purchase_amount ELSE 0 END) ' +
            '   ELSE 0 END)' +
            ' AS closing_date_light_tax_subtotal' + // 締日軽減消費税課税小計
            ',TRUNCATE((SUM(' +
            '   CASE WHEN (tct.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')' +
            '     THEN tct.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= tct.billing_date THEN ' +
            '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)' +
            '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)' +
            '   ELSE 0 END) / 100),0)' +
            ' AS closing_date_light_tax' + // 締日軽減消費税額
          ' FROM ' +
            't_cumulative_transaction tct ' + // 累積トランザクション
            'LEFT JOIN m_products AS products ' +
            'ON tct.product_id = products.product_id ' +
          ' WHERE ' +
              `tct.transaction_id IN ('${Const.TransactionId.stocking}', '${Const.TransactionId.payment}') ` +
              `AND tct.client_class = ${Const.ClientClass.supplier} ` +
              'AND tct.client_id IN (' + csvClientId + ') ' +
              'AND tct.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ' +
              'AND tct.is_update_closing_date = 0 ' +
          'GROUP BY ' +
            'tct.client_id';

        // 今月分支払残高更新用SQL
        const updateThisMonthSql = 'UPDATE ' +
            't_payments_balances AS tpb ' + // 支払残高
            `LEFT JOIN (${subquery}) AS result ` + // 集計結果
              'ON result.payment_month_year = tpb.payment_month_year ' +
              'AND result.client_id = tpb.client_id ' +
          'SET' +
            ' tpb.payment_start_date = result.payment_start_date' + // 支払開始日
            ',tpb.payment_end_date = result.payment_end_date' + // 支払締切日
            ',tpb.closing_date_payable = IFNULL(result.closing_date_payable, 0)' + // 締日買掛仕入
            ',tpb.closing_date_bulk_tax = IFNULL(result.closing_date_normal_tax, 0) + IFNULL(result.closing_date_light_tax, 0)' + // 締日一括消費税額
            ',tpb.closing_date_bill_tax = 0' + // 締日伝票消費税額
            ',tpb.closing_date_tax = IFNULL(result.closing_date_normal_tax, 0) + IFNULL(result.closing_date_light_tax, 0)' + // 締日消費税額
            ',tpb.closing_date_payments = IFNULL(result.closing_date_payments, 0)' + // 締日支払
            ',tpb.closing_date_tax_payments = IFNULL(result.closing_date_tax_payments, 0)' + // 締日消費税支払
            ',tpb.closing_date_forward_no_tax_payments = tpb.pre_no_tax_payment_balances - IFNULL(result.closing_date_payments, 0)' + // 締日繰越税抜額
            ',tpb.closing_date_forward_tax_payments = tpb.pre_tax_payment_balances - IFNULL(result.closing_date_tax_payments, 0)' + // 締日繰越消費税額
            ',tpb.closing_date_normal_tax_subtotal = IFNULL(result.closing_date_normal_tax_subtotal, 0)' + // 締日通常消費税課税小計
            ',tpb.closing_date_normal_tax = IFNULL(result.closing_date_normal_tax, 0)' + // 締日通常消費税額
            ',tpb.closing_date_light_tax_subtotal = IFNULL(result.closing_date_light_tax_subtotal, 0)' + // 締日軽減消費税課税小計
            ',tpb.closing_date_light_tax = IFNULL(result.closing_date_light_tax, 0)' + // 締日軽減消費税額
            ',tpb.updated = CURRENT_TIMESTAMP()' + // 更新日
            `,tpb.updated_user = '${this.loginId}' ` + // 更新ユーザー 
          'WHERE ' +
            'tpb.payment_month_year = result.payment_month_year ' +
            'AND result.client_id = tpb.client_id';
        //console.log(updateThisMonthSql);
        this.transactSqlList.push(updateThisMonthSql);

        // 支払マスタに存在するが支払残高に存在しない支払先の情報を支払残高に登録するSQL
        const insertThisMonthSql = 'INSERT INTO t_payments_balances (payment_month_year,client_id,office_id,payment_start_date,payment_end_date,pre_no_tax_payment_balances,pre_tax_payment_balances,closing_date_payable,closing_date_bulk_tax,closing_date_bill_tax,closing_date_tax,closing_date_payments,closing_date_tax_payments,closing_date_forward_no_tax_payments,closing_date_forward_tax_payments,closing_date_normal_tax_subtotal,closing_date_normal_tax,closing_date_light_tax_subtotal,closing_date_light_tax,created_user,updated_user) ' +
          'SELECT' +
            ' result.payment_month_year' + // 支払年月
            ',result.client_id' + // 仕入先コード
            ',0 AS office_id' + // 営業所コード（支払先ごとに集計するため0固定）
            ',result.payment_start_date' + // 支払開始日
            ',result.payment_end_date' + // 支払締切日
            ',0 AS pre_no_tax_payment_balances' + // 前回支払税抜残高
            ',0 AS pre_tax_payment_balances' + // 前回支払消費税残高
            ',IFNULL(result.closing_date_payable, 0)' + // 締日買掛仕入
            ',IFNULL(result.closing_date_normal_tax, 0) + IFNULL(result.closing_date_light_tax, 0)' + // 締日一括消費税額
            ',0 AS closing_date_bill_tax' + // 締日伝票消費税額
            ',IFNULL(result.closing_date_normal_tax, 0) + IFNULL(result.closing_date_light_tax, 0)' + // 締日消費税額
            ',IFNULL(result.closing_date_payments, 0)' + // 締日支払
            ',IFNULL(result.closing_date_tax_payments, 0)' + // 締日消費税支払
            ',-1 * IFNULL(result.closing_date_payments, 0)' + // 締日繰越税抜額
            ',-1 * IFNULL(result.closing_date_tax_payments, 0)' + // 締日繰越消費税額
            ',IFNULL(result.closing_date_normal_tax_subtotal, 0)' + // 締日通常消費税課税小計
            ',IFNULL(result.closing_date_normal_tax, 0)' + // 締日通常消費税額
            ',IFNULL(result.closing_date_light_tax_subtotal, 0)' + // 締日軽減消費税課税小計
            ',IFNULL(result.closing_date_light_tax, 0)' + // 締日軽減消費税額
            `,'${this.loginId}'` + // 作成ユーザー
            `,'${this.loginId}' ` + // 更新ユーザー
          'FROM ' +
            '(' + subquery + ') AS result ' +
            'LEFT JOIN t_payments_balances AS tpb ' +
              'ON tpb.payment_month_year = ' + this.preBilling.processMonthYear + ' ' +
              'AND tpb.client_id = result.client_id ' +
          'WHERE ' +
            'tpb.client_id IS NULL';
        //console.log(insertThisMonthSql);
        this.transactSqlList.push(insertThisMonthSql);
      }

      // 先月からの支払残高があるが伝票がない支払残高を更新するSQL
      const updateThisMonthSqlNoPayments = 'UPDATE ' +
          't_payments_balances AS tpb ' + // 支払残高
          'INNER JOIN m_payments AS mp ' + // 支払マスタ
            'ON tpb.payment_month_year = ' + this.preBilling.processMonthYear + ' ' +
            'AND mp.client_id = tpb.client_id ' +
            'AND tpb.payment_start_date IS NULL ' +
        'SET' +
          ' tpb.payment_start_date = mp.payment_start_date' + // 支払開始日
          ',tpb.payment_end_date = mp.payment_end_date' + // 支払締切日
          ',tpb.closing_date_forward_no_tax_payments = tpb.pre_no_tax_payment_balances' + // 締日繰越税抜額
          ',tpb.closing_date_forward_tax_payments = tpb.pre_tax_payment_balances' + // 締日繰越消費税額
          ',tpb.updated = CURRENT_TIMESTAMP()' + // 更新日
          `,tpb.updated_user = '${this.loginId}' ` + // 更新ユーザー 
        'WHERE ' +
          'tpb.payment_month_year = ' + this.preBilling.processMonthYear + ' ' +
          'AND mp.client_id = tpb.client_id ' +
          'AND tpb.payment_start_date IS NULL ';
      //console.log(updateThisMonthSqlNoPayments);
      this.transactSqlList.push(updateThisMonthSqlNoPayments);

      // 来月分支払残高登録用SQL
      const insertNextMonthSql = 'INSERT INTO t_payments_balances (payment_month_year,client_id,office_id,payment_start_date,payment_end_date,pre_no_tax_payment_balances,pre_tax_payment_balances,closing_date_payable,closing_date_bulk_tax,closing_date_bill_tax,closing_date_tax,closing_date_payments,closing_date_tax_payments,closing_date_forward_no_tax_payments,closing_date_forward_tax_payments,closing_date_normal_tax_subtotal,closing_date_normal_tax,closing_date_light_tax_subtotal,closing_date_light_tax,created_user,updated_user) ' +
        'SELECT' +
          ' DATE_FORMAT(DATE_ADD(mp.payment_end_date, INTERVAL 1 MONTH), \'%Y%m\') AS payment_month_year' + // 支払年月
          ',mp.client_id' + // 仕入先コード
          ',mp.office_id' + // 営業所コード
          ',NULL' + // 支払開始日
          ',NULL' + // 支払締切日
          ',tpb.pre_no_tax_payment_balances + tpb.closing_date_payable - tpb.closing_date_payments' + // 前回支払税抜残高
          ',tpb.pre_tax_payment_balances + tpb.closing_date_tax - tpb.closing_date_tax_payments' + // 前回支払消費税残高
          ',0' + // 締日買掛仕入
          ',0' + // 締日一括消費税額
          ',0' + // 締日伝票消費税額
          ',0' + // 締日消費税額
          ',0' + // 締日支払
          ',0' + // 締日消費税支払
          ',0' + // 締日繰越税抜額
          ',0' + // 締日繰越消費税額
          ',0' + // 締日通常消費税課税小計
          ',0' + // 締日通常消費税額
          ',0' + // 締日軽減消費税課税小計
          ',0' + // 締日軽減消費税額
          `,'${this.loginId}'` + // 作成ユーザー
          `,'${this.loginId}' ` + // 更新ユーザー
        'FROM ' +
          'm_payments AS mp ' +
          'INNER JOIN t_payments_balances AS tpb ' +
            'ON tpb.client_id = mp.client_id ' +
            'AND tpb.payment_month_year = ' + this.preBilling.processMonthYear + ' ';
      //console.log(insertNextMonthSql);
      this.transactSqlList.push(insertNextMonthSql);
    },
    /* 累積トランザクションUPDATESQL作成処理 */
    async createCumulativeTransactionUpdateSql(){
      //console.log('累積トランザクションUPDATESQL作成処理');
      for (let i = 0; i < this.preBilling.csvClientId.length; i++) {
        let csvClientId = this.preBilling.csvClientId[i];
        // 更新SQLを作成
        let updateSql = '';
        let colList = [];
        // 締日更新フラグ
        colList.push(CreateColRow('cumulative_transaction.is_update_closing_date', 1, 'NUMBER'));
        // 締次年月
        colList.push(CreateColRow('cumulative_transaction.closing_month_year', this.preBilling.processMonthYear, 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('cumulative_transaction.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('cumulative_transaction.updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 't_cumulative_transaction AS cumulative_transaction');
        updateSql += ' WHERE ';
        updateSql += 'cumulative_transaction.is_update_closing_date = 0 ';
        updateSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
        updateSql += 'AND cumulative_transaction.client_id IN (' + csvClientId + ') ';
        updateSql += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
        updateSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
        //console.log(updateSql)
        this.transactSqlList.push(updateSql);
      }
    },
    // 締更新完了登録SQL作成
    async insertClosingUpdateCompletion() {
      //console.log('締更新完了登録SQL作成');
      // 登録SQLを作成
      let colList = [];
      // 請求支払区分
      colList.push(CreateColRow('billing_payment_class', null, 'NUMBER'));
      // 処理年月
      colList.push(CreateColRow('process_month_year', null, 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', null, 'NUMBER'));
      // 取引先コード開始
      colList.push(CreateColRow('client_id_start', null, 'NUMBER'));
      // 取引先コード終了
      colList.push(CreateColRow('client_id_end', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSql = 'INSERT INTO t_closing_update_completion (' + CreateInsertSql(colList, 'col', 't_closing_update_completion') + ') ';
      // 登録情報SELECT文
      let selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' billing_payment_class';
      selectSql += ',process_month_year';
      selectSql += ',closing_date';
      selectSql += ',client_id_start';
      selectSql += ',client_id_end';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ' FROM t_closing_update_range ';
      selectSql += ' WHERE ';
      selectSql += 'billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';

      insertSql += selectSql;

      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBilling = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';
      let selectSql = 'SELECT client_id FROM m_payments ORDER BY client_id';
      let dataResult = null;
      [preBilling, controlData, dataResult] = await Promise.all([
        API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause})),
        getControlMaster(),
        executeSelectSql(selectSql),
      ]);
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      let preBillingResult = preBilling.data.list_t_closing_update_range;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
      this.preBilling.csvClientId = [];
      let csvClientId = '';
      if (dataResult != null && dataResult.length > 0) {
        for (let i = 0; i < dataResult.length; i++) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += dataResult[i].client_id;
          if (csvClientId.length > this.maxSameTimeClientsCount) {
            this.preBilling.csvClientId.push(csvClientId);
            csvClientId = '';
          }
        }
        if (csvClientId != '') {
          this.preBilling.csvClientId.push(csvClientId);
        }
      }
    },
  },
});
</script>
<style scoped>
</style>