<template>
  <div class="text-center">
    <div v-for="(page, index) of pages" :key="index">
      <TemplateMonthlyAccountReceivableSupplyList :id="`page-${index}`"/>
    </div>
  </div>
</template>
<script>
import TemplateMonthlyAccountReceivableSupplyList from '@/assets/svg/monthly_AccountReceivableSupplyList.svg';
import { addOperationLogs, formatCurDate, setPaperA4Landscape } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'accounts-receivable-supply-list-print';
// テーブル項目テンプレート
const TBALE_ITEMS_TEMPLATE = {
  last_month_receivable_no_tax_balance_result: 0, // 前月末売掛税抜残高
  last_month_receivable_tax_balance_result: 0, // 前月末売掛消費税残高
  monthly_sales: 0, // 月次売上額
  monthly_deposit: 0, // 月次現金入金額
  monthly_check_deposit: 0, // 月次小切手入金額
  monthly_transfer_deposit: 0, // 月次振込入金額
  monthly_bill_deposit: 0, // 月次手形入金額
  monthly_offset_deposit: 0, // 月次相殺入金額
  monthly_other_deposit: 0, // 月次その他入金額
  monthly_tax_deposit: 0, // 月次消費税入金額
  monthly_bulk_tax: 0, // 月次一括消費税額
  monthly_billing_tax: 0, // 月次伝票消費税額
  monthly_tax: 0, // 月次消費税額
  monthly_gross_profit: 0, // 月次粗利額
  billing_amount: 0 // 今回請求額
};
// ページあたりの出力件数
const PER_PAGE = 10;
// 合計用の得意先コード
const CLIENT_ID_TOTAL = 'total';
// 各種金額列の左右パディング
const PADDING = 8;

export default {
  menu_type: 'user',
  name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST-PRINT',
  components: {
    TemplateMonthlyAccountReceivableSupplyList
  },
  data() {
    return {
      title: '得意先別売掛実績表',
      pages: []
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  beforeMount() {
    this.$store.commit('setLoading', true);
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 印刷レイアウトを設定します。
    setPaperA4Landscape();
    // 帳票を作成します。
    const query = this.$route.query;
    this.createForm(query.monthYear, query.clientIdFrom, query.clientIdTo);
  },
  methods: {
    /**
     * 帳票を作成します。
     * @param {String} monthYear - 処理年月
     * @param {String} clientIdFrom - 開始得意先コード
     * @param {String} clientIdTo - 終了得意先コード
     */
    createForm(monthYear, clientIdFrom, clientIdTo) {
      const functionName = 'createForm';

      // 売掛実績・残高マスタからデータを取得します。
      this.fetchData(monthYear, clientIdFrom, clientIdTo).then(response => {
        if (response === null) {
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2001'], {
            title: this.title
          }).then(() => {
            window.close();
          });
          return;
        }

        // 帳票タイトル文字列を作成します。
        const year = Math.floor(monthYear / 100 % 100);
        const month = String(monthYear % 100).padStart(2, '0');
        const title = '得　意　先　別　売　掛　実　績　表　' + `${year}/${month}`;

        // 帳票データを作成します。this.pages にデータが代入された時点で、
        // 作成するページ分の帳票テンプレートがレンダリングされます。
        this.pages = this.createFormData(response);

        // 出力日時文字列を作成します。
        const outputDateTime = formatCurDate('YY/MM/DD HH:mm');

        // 帳票テンプレートに対して値の置換と表示位置調整を行います。
        this.setPageData(0, title, outputDateTime);
      }).catch(async error => {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.$store.commit('setLoading', false);
        this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2001'], {
          title: this.title
        }).then(() => {
          window.close();
        });
      });
    },
    /**
     * 売掛実績・残高マスタデータを取得します。
     * @param {String} monthYear - 処理年月
     * @param {String} clientIdFrom - 開始得意先コード
     * @param {String} clientIdTo - 終了得意先コード
     * @returns {Array} 売掛実績・残高マスタデータ
     */
    async fetchData(monthYear, clientIdFrom, clientIdTo) {
      const functionName = 'fetchData';

      // 売掛実績・残高マスタデータ取得用SQL文を作成します。
      const sql = this.createSQL(monthYear, clientIdFrom, clientIdTo);
      const sqls = [ sql ];

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        return null;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        return null;
      }
      return body.data[0];
    },
    /**
     * 売掛実績・残高マスタ取得用SQLを作成します。
     * @param {String} monthYear - 処理年月
     * @param {String} clientIdFrom - 開始得意先コード
     * @param {String} clientIdTo - 終了得意先コード
     * @returns {String} 成功した場合はSQL文、失敗した場合はnull
     */
    createSQL(monthYear, clientIdFrom, clientIdTo) {
      const whereClauses = [];
      whereClauses.push(`trbr.month_year = ${monthYear}`);
      if (clientIdFrom !== '' && clientIdTo !== '') {
        whereClauses.push(`trbr.client_id BETWEEN ${clientIdFrom} AND ${clientIdTo}`);
      } else if (clientIdFrom !== '') {
        whereClauses.push(`trbr.client_id >= ${clientIdFrom}`);
      } else if (clientIdTo !== '') {
        whereClauses.push(`trbr.client_id <= ${clientIdTo}`);
      }

      return 'SELECT' +
        ' trbr.month_year' +
        ',trbr.client_id' +
        ',trbr.staff_id' +
        ',trbr.last_month_receivable_no_tax_balance_result' +
        ',trbr.last_month_receivable_tax_balance_result' +
        ',trbr.monthly_sales' +
        ',trbr.monthly_deposit' +
        ',trbr.monthly_check_deposit' +
        ',trbr.monthly_transfer_deposit' +
        ',trbr.monthly_bill_deposit' +
        ',trbr.monthly_offset_deposit' +
        ',trbr.monthly_other_deposit' +
        ',trbr.monthly_tax_deposit' +
        ',trbr.monthly_bulk_tax' +
        ',trbr.monthly_billing_tax' +
        ',trbr.monthly_tax' +
        ',trbr.monthly_gross_profit' +
        ',trbr.billing_amount' +
        ',mc.client_name_kanji' +
        ',ms.staff_name_kanji' +
        ',ms.staff_name_kana ' +
        'FROM t_receivables_balances_results AS trbr' +
        ' JOIN m_clients mc ON mc.client_class = 1 AND trbr.client_id = mc.client_id' +
        ' JOIN m_staffs ms ON trbr.staff_id = ms.staff_id ' +
        `WHERE ${whereClauses.join(' AND ')} ` +
        'ORDER BY trbr.client_id ASC, trbr.staff_id ASC';
    },
    /**
     * 帳票データを作成します。
     * @param {Array} records - 集計元データ
     * @returns {Array} 作成した帳票データ
     */
    createFormData(records) {
      const total = { ...TBALE_ITEMS_TEMPLATE };
      total.client_id = CLIENT_ID_TOTAL;

      let page = [];
      const pages = [ page ];
      for (const record of records) {
        // 前月末売掛税抜残高
        total.last_month_receivable_no_tax_balance_result += record.last_month_receivable_no_tax_balance_result;
        // 前月末売掛消費税残高
        total.last_month_receivable_tax_balance_result += record.last_month_receivable_tax_balance_result;
        // 月次売上額
        total.monthly_sales += record.monthly_sales;
        // 月次現金入金額
        total.monthly_deposit += record.monthly_deposit;
        // 月次小切手入金額
        total.monthly_check_deposit += record.monthly_check_deposit;
        // 月次振込入金額
        total.monthly_transfer_deposit += record.monthly_transfer_deposit;
        // 月次手形入金額
        total.monthly_bill_deposit += record.monthly_bill_deposit;
        // 月次相殺入金額
        total.monthly_offset_deposit += record.monthly_offset_deposit;
        // 月次その他入金額
        total.monthly_other_deposit += record.monthly_other_deposit;
        // 月次消費税入金額
        total.monthly_tax_deposit += record.monthly_tax_deposit;
        // 月次一括消費税額
        total.monthly_bulk_tax += record.monthly_bulk_tax;
        // 月次伝票消費税額
        total.monthly_billing_tax += record.monthly_billing_tax;
        // 月次消費税額
        total.monthly_tax += record.monthly_tax;
        // 月次粗利額
        total.monthly_gross_profit += record.monthly_gross_profit;
        // 今回請求額
        total.billing_amount += record.billing_amount;

        page.push(record);
        if (page.length === PER_PAGE) {
          page = [];
          pages.push(page);
        }
      }
      page.push(total);

      for (let i = page.length; i < PER_PAGE; i++) {
        page.push({ client_id: null });
      }

      return pages;
    },
    /**
     * 指定されたページのSVGに帳票データを設定します。
     * @param {Number} pageIndex - ページ番号（0始まり）
     * @param {String} title - 帳票タイトル置換文字列
     * @param {String} outDateTime - 帳票出力日時置換文字列
     * @returns {Promise} Promiseオブジェクト
     */
    setPageData(pageIndex, title, outputDateTime) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          try {
            const pageData = this.pages[pageIndex];
            const page = $(`svg#page-${pageIndex}`);
            this.resizePage(page);

            // ヘッダー幅
            let lastMonthReceivableNoTaxBalanceResultWidth = 0; // 売掛税抜残高・売掛消費税残高・売上額の列の幅
            let monthlyDepositWidth = 0; // 現金入金額・小切手入金額・振込入金額の列の幅
            let monthlyBillDepositWidth = 0; // 手形入金額・相殺入金額・その他入金額の列の幅
            let monthlyTaxDepositWidth = 0; // 消費税入金額・一括消費税額・伝票消費税額の列の幅
            let monthlyTaxWidth = 0; // 消費税額・粗利額・今回支給額の列の幅

            for (let rowIndex = 0; rowIndex < PER_PAGE; rowIndex++) {
              const rowData = pageData[rowIndex];
              const rowNo = rowIndex + 1;

              let element = null;
              if (rowIndex === 0) {
                // タイトル
                element = page.find('tspan:contains("%TITLE%")');
                element.text(element.text().replace('%TITLE%', title));
                element.attr('x', (element.parent().parent()[0].getBBox().width - element[0].getBBox().width) / 2);

                // 日付／ページ番号
                element = page.find('tspan:contains("%DATE_TIME%")');
                element.text(element.text()
                  .replace('%DATE_TIME%', outputDateTime)
                  .replace('%PAGE%', String(pageIndex + 1).padStart(3, ' '))
                );

                // 売掛税抜残高
                lastMonthReceivableNoTaxBalanceResultWidth = this.replaceHeaderValue(page, '%0_2_1%', '売掛税抜残高');
                // 売掛消費税残高
                this.replaceValueAlignRight(page, '%0_2_2%', '売掛消費税残高', lastMonthReceivableNoTaxBalanceResultWidth);
                // 売上額
                this.replaceValueAlignRight(page, '%0_2_3%', '売上額', lastMonthReceivableNoTaxBalanceResultWidth);
                // 現金入金額
                monthlyDepositWidth = this.replaceHeaderValue(page, '%0_3_1%', '現金入金額');
                // 小切手入金額
                this.replaceValueAlignRight(page, '%0_3_2%', '小切手入金額', monthlyDepositWidth);
                // 振込入金額
                this.replaceValueAlignRight(page, '%0_3_3%', '振込入金額', monthlyDepositWidth);
                // 手形入金額
                monthlyBillDepositWidth = this.replaceHeaderValue(page, '%0_4_1%', '手形入金額');
                // 相殺入金額
                this.replaceValueAlignRight(page, '%0_4_2%', '相殺入金額', monthlyBillDepositWidth);
                // その他入金額
                this.replaceValueAlignRight(page, '%0_4_3%', 'その他入金額', monthlyBillDepositWidth);
                // 消費税入金額
                monthlyTaxDepositWidth = this.replaceHeaderValue(page, '%0_5_1%', '消費税入金額');
                // 一括消費税額
                this.replaceValueAlignRight(page, '%0_5_2%', '一括消費税額', monthlyTaxDepositWidth);
                // 伝票消費税額
                this.replaceValueAlignRight(page, '%0_5_3%', '伝票消費税額', monthlyTaxDepositWidth);
                // 消費税額
                monthlyTaxWidth = this.replaceHeaderValue(page, '%0_6_1%', '消費税額');
                // 粗利額
                this.replaceValueAlignRight(page, '%0_6_2%', '粗利額', monthlyTaxWidth);
                // 今回請求額
                this.replaceValueAlignRight(page, '%0_6_3%', '今回請求額', monthlyTaxWidth);
              }

              let client_id = ''; // 得意先コード
              let client_name_kanji = ''; // 得意先名
              let staff_id = ''; // 担当者コード
              let staff_name_kanji = ''; // 担当者名
              let last_month_receivable_no_tax_balance_result = ''; // 売掛税抜残高
              let last_month_receivable_tax_balance_result = ''; // 売掛消費税残高
              let monthly_sales = ''; // 売上額
              let monthly_deposit = ''; // 現金入金額
              let monthly_check_deposit = ''; // 小切手入金額
              let monthly_transfer_deposit = ''; // 振込入金額
              let monthly_bill_deposit = ''; // 手形入金額
              let monthly_offset_deposit = ''; // 相殺入金額
              let monthly_other_deposit = ''; // その他入金額
              let monthly_tax_deposit = ''; // 消費税入金額
              let monthly_bulk_tax = ''; // 一括消費税額
              let monthly_billing_tax = ''; // 伝票消費税額
              let monthly_tax = ''; // 消費税額
              let monthly_gross_profit = ''; // 粗利額
              let billing_amount = ''; // 今回請求額

              if (rowData.client_id !== null) {
                if (rowData.client_id === CLIENT_ID_TOTAL) {
                  // ＜  総合計  ＞
                  client_name_kanji = '＜ 合　計 ＞'; 
                } else {
                  // 得意先コード
                  client_id = String(rowData.client_id).padStart(6, '0');
                  // 得意先名
                  client_name_kanji = rowData.client_name_kanji;
                  // 担当者コード
                  staff_id = String(rowData.staff_id).padStart(4, '0');
                  // 担当者名
                  staff_name_kanji = (rowData.staff_name_kanji.trim() === '') ? rowData.staff_name_kana : rowData.staff_name_kanji;
                }

                // 売掛税抜残高
                last_month_receivable_no_tax_balance_result = rowData.last_month_receivable_no_tax_balance_result.toLocaleString();
                // 売掛消費税残高
                last_month_receivable_tax_balance_result = rowData.last_month_receivable_tax_balance_result.toLocaleString();
                // 売上額
                monthly_sales = rowData.monthly_sales.toLocaleString();

                // 現金入金額
                monthly_deposit = rowData.monthly_deposit.toLocaleString();
                // 小切手入金額
                monthly_check_deposit = rowData.monthly_check_deposit.toLocaleString();
                // 振込入金額
                monthly_transfer_deposit = rowData.monthly_transfer_deposit.toLocaleString();

                // 手形入金額
                monthly_bill_deposit = rowData.monthly_bill_deposit.toLocaleString();
                // 相殺入金額
                monthly_offset_deposit = rowData.monthly_offset_deposit.toLocaleString();
                // その他入金額
                monthly_other_deposit = rowData.monthly_other_deposit.toLocaleString();

                // 消費税入金額
                monthly_tax_deposit = rowData.monthly_tax_deposit.toLocaleString();
                // 一括消費税額
                monthly_bulk_tax = rowData.monthly_bulk_tax.toLocaleString();
                // 伝票消費税額
                monthly_billing_tax = rowData.monthly_billing_tax.toLocaleString();

                // 消費税額
                monthly_tax = rowData.monthly_tax.toLocaleString();
                // 粗利額
                monthly_gross_profit = rowData.monthly_gross_profit.toLocaleString();
                // 今回請求額
                billing_amount = rowData.billing_amount.toLocaleString();
              }

              // 得意先コード
              this.replaceValue(page, `%${rowNo}_1_1%`, client_id);
              // 得意先名
              this.replaceValue(page, `%${rowNo}_1_2%`, client_name_kanji);
              // 担当者コード
              this.replaceValue(page, `%${rowNo}_1_3%`, staff_id);
              // 担当者名
              this.replaceValue(page, `%${rowNo}_1_4%`, staff_name_kanji);

              // 売掛税抜残高
              this.replaceValueAlignRight(page, `%${rowNo}_2_1%`, last_month_receivable_no_tax_balance_result, lastMonthReceivableNoTaxBalanceResultWidth);
              // 売掛消費税残高
              this.replaceValueAlignRight(page, `%${rowNo}_2_2%`, last_month_receivable_tax_balance_result, lastMonthReceivableNoTaxBalanceResultWidth);
              // 売上額
              this.replaceValueAlignRight(page, `%${rowNo}_2_3%`, monthly_sales, lastMonthReceivableNoTaxBalanceResultWidth);

              // 現金入金額
              this.replaceValueAlignRight(page, `%${rowNo}_3_1%`, monthly_deposit, monthlyDepositWidth);
              // 小切手入金額
              this.replaceValueAlignRight(page, `%${rowNo}_3_2%`, monthly_check_deposit, monthlyDepositWidth);
              // 振込入金額
              this.replaceValueAlignRight(page, `%${rowNo}_3_3%`, monthly_transfer_deposit, monthlyDepositWidth);

              // 手形入金額
              this.replaceValueAlignRight(page, `%${rowNo}_4_1%`, monthly_bill_deposit, monthlyBillDepositWidth);
              // 相殺入金額
              this.replaceValueAlignRight(page, `%${rowNo}_4_2%`, monthly_offset_deposit, monthlyBillDepositWidth);
              // その他入金額
              this.replaceValueAlignRight(page, `%${rowNo}_4_3%`, monthly_other_deposit, monthlyBillDepositWidth);

              // 消費税入金額
              this.replaceValueAlignRight(page, `%${rowNo}_5_1%`, monthly_tax_deposit, monthlyTaxDepositWidth);
              // 一括消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_5_2%`, monthly_bulk_tax, monthlyTaxDepositWidth);
              // 伝票消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_5_3%`, monthly_billing_tax, monthlyTaxDepositWidth);

              // 消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_6_1%`, monthly_tax, monthlyTaxWidth);
              // 粗利額
              this.replaceValueAlignRight(page, `%${rowNo}_6_2%`, monthly_gross_profit, monthlyTaxWidth);
              // 今回請求額
              this.replaceValueAlignRight(page, `%${rowNo}_6_3%`, billing_amount, monthlyTaxWidth);
            }

            const nextPageIndex = pageIndex + 1;
            if (nextPageIndex < this.pages.length) {
              this.setPageData(nextPageIndex, title, outputDateTime);
            } else {
              this.$store.commit('setLoading', false);
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 0);
      });
    },
    /**
     * ページサイズを調整します。
     * @param {Object} page - ページ
     */
    resizePage(page) {
      page.attr('viewBox', `0 0 ${page.innerWidth()} ${page.innerHeight()}`);
      page.attr('width', '297mm');
      page.attr('height', '209mm');
    },
    /**
     * 指定されたページからキーワードを含む要素を探し出して文字列を置換し、右寄せにします。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @returns {Number} 見つけた要素の親フレームの幅
     */
    replaceHeaderValue(page, keyword, value) {
      const element = this.replaceValue(page, keyword, value);
      const fieldWidth = element.parent().prev()[0].getBBox().width;
      this.setAlignRight(element, fieldWidth, 4);
      return fieldWidth;
    },
    /**
     * 指定されたページからキーワードを含む要素を探し出して文字列を置換し、右寄せします。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @param {Number} fieldWidth - フィールド幅
     */
    replaceValueAlignRight(page, keyword, value, fieldWidth) {
      const element = this.replaceValue(page, keyword, value);
      this.setAlignRight(element, fieldWidth);
    },
    /**
     * 指定されたページのキーワードを指定された値に置換します。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @returns {Object} 文字列置換を行った要素
     */
    replaceValue(page, keyword, value) {
      const element = page.find(`tspan:contains("${keyword}")`);
      element.text(element.text().trimEnd().replace(keyword, value));
      return element;
    },
    /**
     * 指定されたJQuery要素を親要素の親要素の幅に合わせて右寄せします。
     * @param {Object} element - 位置を調整するtspan要素
     * @param {Number} fieldWidth - フィールド幅
     */
    setAlignRight(element, fieldWidth) {
      const x = Number(element.attr('x'));
      const width = element[0].getBBox().width;
      element.attr('x', x - PADDING + fieldWidth - width);
    }
  }
}
</script>
