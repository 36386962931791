<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <!-- TODO 現在は印刷時にヘッダーは除外しているが、1ページ目のみに表示されるようにする（タイトルを表示するため） -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 得意先コード -->
                          <b-col lg="6">
                            <b-form-group label="得意先コード" label-for="clientId">
                              <validation-provider name="clientId" rules="required|numeric|min:6" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group>
                                      <b-form-input
                                        id="clientId"
                                        name="clientId"
                                        type="text" 
                                        v-model.number="searchConditions.clientId" 
                                        @change="searchClient(searchConditions.clientId)" 
                                        maxlength="6"
                                      />
                                      <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                          <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                        </b-button>
                                      </b-input-group-text>
                                    </b-input-group>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                              <b-form-text class="text-muted">検索ボタンから選択してください。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 現場コード -->
                          <b-col lg="12">
                            <b-form-group
                              label="現場コード"
                              label-for="siteIdStart"
                            >
                              <b-container fluid>
                                <b-row>
                                  <b-col class="px-0">
                                    <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                      <div :class="classes">
                                        <b-input-group>
                                          <b-form-input 
                                            type="text" 
                                            id="siteIdStart" 
                                            name="siteIdStart" 
                                            v-model.number="searchConditions.siteIdStart" 
                                            :readonly="searchConditions.clientId == ''"
                                            maxlength="4"
                                            @blur="searchConditions.siteIdEnd = searchConditions.siteIdStart"
                                          />
                                          <b-input-group-text @click="showSiteSearchModal(1);" v-if="searchConditions.clientId !== ''" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                              <span class="oi oi-magnifying-glass"></span>
                                              <button type="button" v-shortkey="['alt', '2']" @shortkey="showSiteSearchModal(1);" class="d-none"></button>
                                            </b-button>
                                          </b-input-group-text>
                                          <!-- 現場クリアボタン -->
                                          <b-button size="sm" pill variant="success" title="現場をクリアします。" @click="siteClear(1)" class="mr-1">
                                            <span class="oi oi-circle-x"></span> クリア
                                          </b-button>
                                        </b-input-group>
                                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                      </div>
                                    </validation-provider>
                                  </b-col>
                                  <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                                  <b-col class="px-0">
                                    <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                      <div :class="classes">
                                        <b-input-group>
                                          <b-input 
                                            type="text" 
                                            v-model.number="searchConditions.siteIdEnd" 
                                            :readonly="searchConditions.clientId == ''"
                                            maxlength="4"
                                          />
                                          <b-input-group-text @click="showSiteSearchModal(2);" v-if="searchConditions.clientId !== ''" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                              <span class="oi oi-magnifying-glass"></span>
                                              <button type="button" v-shortkey="['alt', '3']" @shortkey="showSiteSearchModal(2);" class="d-none"></button>
                                            </b-button>
                                          </b-input-group-text>
                                          <!-- 現場クリアボタン -->
                                          <b-button size="sm" pill variant="success" title="現場をクリアします。" @click="siteClear(2)" class="mr-1">
                                            <span class="oi oi-circle-x"></span> クリア
                                          </b-button>
                                        </b-input-group>
                                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                      </div>
                                    </validation-provider>
                                  </b-col>
                                </b-row>
                              </b-container>
                              <b-form-text class="text-muted">現場の範囲を入力してください。空白の場合は全範囲が設定されます。得意先選択後に入力可能です。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 製品コード -->
                          <b-col lg="6">
                            <validation-provider name="productId" rules="numeric" v-slot="{ classes,errors }">
                              <b-row>
                                <b-col lg="12" :class="classes">
                                  <b-form-group label="製品コード">
                                    <b-input-group>
                                      <b-form-input
                                        id="productId"
                                        name="productId"
                                        type="text" 
                                        v-model="searchConditions.productId" 
                                        @change="searchProduct(searchConditions.productId)"
                                        maxlength="8"
                                      />
                                      <b-input-group-text @click="showProductSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+4」ボタンで呼出可能">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                          <button type="button" v-shortkey="['alt', '4']" @shortkey="showProductSearchModal()" class="d-none"></button>
                                        </b-button>
                                      </b-input-group-text>
                                      <!-- 製品クリアボタン -->
                                      <b-button size="sm" pill variant="success" title="製品をクリアします。" @click="productClear()" class="mr-1">
                                        <span class="oi oi-circle-x"></span> クリア
                                      </b-button>
                                    </b-input-group>
                                    <b-form-text class="text-muted">検索ボタンから選択してください。0の場合、全製品を検索します。</b-form-text>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="12" :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-row>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 伝票日付 -->
                          <b-col lg="12">
                            <validation-provider name="billingDateStart" :rules="{dateConsistency:searchConditions.billingDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group label="伝票日付" label-for="billingDateStart">
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker
                                      id="billingDateStart"
                                      name="billingDateStart"
                                      v-model="searchConditions.billingDateStart"
                                      calendar-width="50%"
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <span class="input-group-text">～</span>
                                    <b-form-datepicker
                                      v-model="searchConditions.billingDateEnd"
                                      calendar-width="50%"
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                              <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付は基本的に指定してください。</b-form-text>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-table-simple
            v-if="searchResultDispConditions.clientId!=''"
            :bordered="bordered"
            :borderless="borderless"
            :outlined="outlined"
            small
            caption-top
          >
            <caption>検索条件</caption>
            <b-tbody>
              <b-tr>
                <b-td>得意先</b-td>
                <b-td>{{searchResultDispConditions.clientId + ' ' + searchResultDispConditions.clientName}}</b-td>
                <b-td>締日</b-td>
                <b-td>{{searchResultDispConditions.closingDate}}</b-td>
                <b-td>入金予定</b-td>
                <b-td>{{searchResultDispConditions.paymentScheduled}}</b-td>
              </b-tr>
              <b-tr>
                <b-td>現場</b-td>
                <b-td>{{searchResultDispConditions.siteIdStart + ' ～ ' + searchResultDispConditions.siteIdEnd}}</b-td>
                <b-td>金種</b-td>
                <b-td>{{searchResultDispConditions.businessClass}}</b-td>
                <b-td>担当</b-td>
                <b-td>{{searchResultDispConditions.staff}}</b-td>
              </b-tr>
              <b-tr>
                <b-td>製品</b-td>
                <b-td colspan="5">{{searchResultDispConditions.productId + ' ' + searchResultDispConditions.productName}}</b-td>
              </b-tr>
              <b-tr>
                <b-td>伝票日付</b-td>
                <b-td colspan="5">{{searchResultDispConditions.billingDateStart + ' ～ ' + searchResultDispConditions.billingDateEnd}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-row class="justify-content-md-center">
            <b-col sm="4" md="4" lg="3" xl="2">
              <b-button pill size="sm" variant="success" v-b-tooltip.hover.noninteractive :title="'一覧のチェックONの伝票を全て発行します。（最大' + maxPrintCnt + '件として下さい。）'" @click="clearAlert(); clickBulkPrintBtn();" :disabled="checkedIndexs.length==0">
                <span class="oi oi-document"></span> 伝票一括発行
              </b-button>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input style="max-width: 70px;" size="sm" type="number" v-model.number="inputRowCheckStart"/>
                <b-input-group-text>～</b-input-group-text>
                <b-form-input style="max-width: 70px;" size="sm" type="number" v-model.number="inputRowCheckEnd"/>
                <b-button pill size="sm" variant="success" v-b-tooltip.hover.noninteractive :title="'入力された数値の範囲の行を全てチェックONにします。'" @click="clearAlert(); clickBulkCheckBtn();" :disabled="htmlGetNullStr(inputRowCheckStart)==''||htmlGetNullStr(inputRowCheckEnd)==''||itemList.length==0">
                  <span class="oi oi-document"></span> 入力行チェック
                </b-button>
              </b-input-group>
            </b-col>
          </b-row>
          <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col class="mt-2">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
                <template #head(check)>
                  印<br>刷<br>
                  <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="totalCheckCnt==0" />
                </template>
                <template #head(billingDate)>伝票日付<br>&nbsp;<br>&nbsp;</template>
                <template #head(free_col_1)>伝票番号<br>現場コード<br>&nbsp;</template>
                <template #head(free_col_2)>納品指定日<br>配送コード<br>製品コード</template>
                <template #head(free_col_3)>納品指定<br>現場名<br>製品名</template>
                <template #head(free_col_4)>納品指定区分<br><br>数量</template>
                <template #head(free_col_5)><br><br>単価</template>
                <template #head(free_col_6)><br><br>金額</template>
                <template #cell(check)="data">
                  <b-check v-if="data.item.transactionId==htmlConst.TransactionId.sales" v-model="checkedIndexs" :value="data.item.checkedIndex" @change="changeCheck"/>
                </template>
                <template #cell(free_col_1)="data">
                  {{ data.item.billingNo }}<br/>
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.sales">{{ data.item.siteId }}<br/></span>
                  <b-button v-if="data.item.transactionId==htmlConst.TransactionId.sales && !printStatus" size="sm" v-b-tooltip.hover.noninteractive title="売上伝票照会画面に遷移します。" @click="clickSalesSlipBtn(data.item.billingNo)" class="mr-2">
                    <span class="oi oi-list"> 売上伝票照会</span>
                  </b-button>
                </template>
                <template #cell(free_col_2)="data">
                  {{ data.item.deliveryDesignDate }}<br/>
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.sales">{{ data.item.shippingCode }}<br/></span>
                  <ul v-for="(productId,index) in data.item.productId" :key="index" style="list-style: none;">
                    <li>{{productId}}<br/></li>
                  </ul>
                </template>
                <template #cell(free_col_3)="data">
                  <div style="max-width:300px;">
                    <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                      <span v-b-tooltip.hover.noninteractive :title="data.item.deliveryDesign">
                        {{ data.item.deliveryDesign }}<br/>
                      </span>
                    </div>
                    <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                      <span v-if="data.item.transactionId==htmlConst.TransactionId.sales" v-b-tooltip.hover.noninteractive :title="data.item.clientSiteName">
                        {{ data.item.clientSiteName }}<br/>
                      </span>
                    </div>
                    <div v-for="(productName,index) in data.item.productName" :key="index" style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                      <span v-b-tooltip.hover.noninteractive :title="productName">{{ productName }}</span>
                    </div>
                  </div>
                </template>
                <template #cell(free_col_4)="data">
                  {{ data.item.deliveryDesignClass }}<br/>
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.sales"><br/></span>
                  <ul v-for="(productQuantity,index) in data.item.productQuantity" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productQuantity}}<br/></li>
                  </ul>
                </template>
                <template #cell(free_col_5)="data">
                  <br/>
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.sales"><br/></span>
                  <ul v-for="(productSalesUnitPrice,index) in data.item.productSalesUnitPrice" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productSalesUnitPrice}}<br/></li>
                  </ul>
                </template>
                <template #cell(free_col_6)="data">
                  <br/>
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.sales"><br/></span>
                  <ul v-for="(productSalesUnitAmount,index) in data.item.productSalesUnitAmount" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productSalesUnitAmount}}<br/></li>
                  </ul>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
    <!-- ●●●現場検索モーダル●●● -->
    <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { searchClientModal, searchProductsModal } from '@/assets/js/searchModal.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, getControlMaster, dateConsistency, executeSelectSql, formatDate, formatCurDate, formatDateCalc, getListValue, getNullStr } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import { list_m_service_classes } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'invoice-inquiry';

export default {
  name: 'INVOICE-INQUIRY',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    PRODUCTSEARCH,
    SITESEARCH,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '得意先元帳照会',
      // アラート
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索項目
      searchConditions: {
        // 取引先区分
        clientClass: '',
        // 取引先コード
        clientId: '',
        // 取引先名
        clientName: '',
        // 締日
        closingDate: '',
        // 入金予定
        paymentScheduled: '',
        // 金種（取引区分)
        businessClass: '',
        // 担当
        staff: '',
        // 現場コード（開始）
        siteIdStart: '',
        // 現場コード（終了）
        siteIdEnd: '',
        // 製品コード
        productId: '0',
        // 製品名
        productName: '',
        // 伝票日付（開始）
        billingDateStart: '',
        // 伝票日付（終了）
        billingDateEnd: '',
      },
      // 検索項目
      searchResultDispConditions: {
        // 取引先区分
        clientClass: '',
        // 取引先コード
        clientId: '',
        // 取引先名
        clientName: '',
        // 締日
        closingDate: '',
        // 入金予定
        paymentScheduled: '',
        // 金種（取引区分)
        businessClass: '',
        // 担当
        staff: '',
        // 現場コード（開始）
        siteIdStart: '',
        // 現場コード（終了）
        siteIdEnd: '',
        // 製品コード
        productId: '0',
        // 製品名
        productName: '',
        // 伝票日付（開始）
        billingDateStart: '',
        // 伝票日付（終了）
        billingDateEnd: '',
      },
      // 取引先モーダル用の引数
      propClientClass: Const.ClientClass.customer,
      // 検索結果に表示する検索条件
      bordered: false,
      borderless: true,
      outlined: false,
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // 印刷チェックボックス関連
      totalCheckCnt: 0,
      isAllChecked: false,
      checkedIndexs: [],
      maxPrintCnt: 40,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // トランザクションID
        TransactionId: {
          // 売上
          sales: 'UR',
          // 仕入
          stocking: 'SI',
          // 入金
          deposit: 'NK',
          // 支払
          payment: 'SK',
          // 入出庫
          storage: 'NS',
        },
      },
      // プリントアウト状態
      printStatus: false,
      // サービス区分
      serviceList: [],
      // 入力行チェック
      inputRowCheckStart: null,
      inputRowCheckEnd: null,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
        this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: null,
        office_name: null,
        client_class: this.searchConditions.clientClass,
        client_id: this.searchConditions.clientId,
        client_name: this.searchConditions.clientName,
      };
    },
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'check',
        },
        {
          key: 'billingDate',
        },
        {
          key: 'free_col_1',
        },
        {
          key: 'free_col_2',
        },
        {
          key: 'free_col_3',
          thStyle: 'width:245px;',
        },
        {
          // 製品数量
          key: 'free_col_4',
        },
        {
          // 製品単価
          key: 'free_col_5',
        },
        {
          // 製品金額
          key: 'free_col_6',
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 非同期でDBから情報を取得
        let serviceListResult = null;
        let controlData = null;
        [serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
        ]);
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 伝票日付に１か月前～を設定
        this.searchConditions.billingDateStart = formatDateCalc(formatCurDate(), 0, -1, 0, false, 'YYYY-MM-DD');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      this.propClientClass = Const.ClientClass.customer;
      const result = await searchClientModal(client_id, this.propClientClass, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (String(client_id).length == 6) {
          // 取引先クリア
          await this.closeClientSearchModal({detail:{}})
        }
      }
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        //console.log(clientItems);
        this.searchConditions.clientClass = clientItems.detail.client_class;
        this.searchConditions.clientId = clientItems.detail.client_id;
        this.searchConditions.clientName = clientItems.detail.client_name_kanji;
        this.searchConditions.closingDate = clientItems.detail.closing_date;
        // 入金予定
        if (clientItems.detail.payment_scheduled == 99) {
          this.searchConditions.paymentScheduled = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, clientItems.detail.payment_class, 0, true);
        } else {
          let calcDay = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, clientItems.detail.payment_class, clientItems.detail.payment_scheduled - 1);
          let monthLastDay = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, clientItems.detail.payment_class, 0, true);
          if (dateConsistency(calcDay, monthLastDay) == true) {
            this.searchConditions.paymentScheduled = calcDay;
          } else {
            this.searchConditions.paymentScheduled = monthLastDay;
          }
        }
        this.searchConditions.businessClass = getListValue(Const.businessClassList, clientItems.detail.business_class);
        this.searchConditions.staff = clientItems.detail.staff_id + ' ' + clientItems.detail.staff_name_kanji;
      }
    },
    // 製品検索
    showProductSearchModal: function() {
      this.$bvModal.show('productSearchModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id) {
      if (product_id == 0) {
        this.productClear()
        return
      }
      var productSearchProp = {
        office_id: this.$store.getters.user.office_id,
        office_name: null,
        client_class: this.searchConditions.clientClass,
        client_id: this.searchConditions.clientId,
        client_name: this.searchConditions.clientName,
      }
      const result = await searchProductsModal(product_id, productSearchProp, 'searchProduct', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result)
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productClear()
        }
      }
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        this.searchConditions.productId = productItems.detail.product_id;
        this.searchConditions.productName = productItems.detail.product_name_kanji;
      }
    },
    // 製品クリア
    productClear: function() {
      this.searchConditions.productId = '0';
      this.searchConditions.productName = '';
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          this.$store.commit('setLoading', true);
          await this.search();
          this.$store.commit('setLoading', false);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // 印刷チェックを初期化
      this.checkedIndexs = [];
      this.isAllChecked = false;
      this.totalCheckCnt = 0;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      this.busy = true;
      try {
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null) {
          this.saveSearchConditions();
          await this.setResult(resultData);
          if (resultData.length >= 5000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%','5000').replace('%arg2%','製品'));
          }
          //console.log(resultData);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 検索条件を保存 */
    saveSearchConditions: function() {
      this.searchResultDispConditions.clientClass = this.searchConditions.clientClass;
      this.searchResultDispConditions.clientId = this.searchConditions.clientId;
      this.searchResultDispConditions.clientName = this.searchConditions.clientName;
      this.searchResultDispConditions.closingDate = this.searchConditions.closingDate;
      this.searchResultDispConditions.paymentScheduled = this.searchConditions.paymentScheduled;
      this.searchResultDispConditions.businessClass = this.searchConditions.businessClass;
      this.searchResultDispConditions.staff = this.searchConditions.staff;
      this.searchResultDispConditions.siteIdStart = this.searchConditions.siteIdStart;
      this.searchResultDispConditions.siteIdEnd = this.searchConditions.siteIdEnd;
      this.searchResultDispConditions.productId = this.searchConditions.productId;
      this.searchResultDispConditions.productName = this.searchConditions.productName;
      this.searchResultDispConditions.billingDateStart = formatDate(this.searchConditions.billingDateStart);
      this.searchResultDispConditions.billingDateEnd = formatDate(this.searchConditions.billingDateEnd);
    },
    /* 取得結果セット */
    async setResult(result){
      let searchResult = null;
      for (let i = 0; i < result.length; i++) {
        // 伝票番号毎に１件として検索結果に表示するリストを作成
        if (searchResult == null || searchResult.transactionId != result[i].transaction_id || searchResult.billingNo != result[i].billing_no) {
          if (searchResult != null) {
            if (searchResult.transactionId == Const.TransactionId.sales) {
              this.totalCheckCnt++;
            }
            this.itemList.push(searchResult);
          }
          searchResult = {
            transactionId: result[i].transaction_id,
            billingDate: formatDate(result[i].billing_date),
            billingNo: result[i].billing_no,
            deliveryDesignDate: result[i].transaction_id == Const.TransactionId.sales ? (result[i].delivery_design_date == null ? '0000/00/00' : formatDate(result[i].delivery_design_date)): '',
            deliveryDesign: result[i].delivery_design,
            deliveryDesignClass: result[i].delivery_design_class,
            siteId: result[i].site_id,
            shippingCode: result[i].shipping_code,
            clientSiteName: result[i].client_site_name,
            monthlyMonthYear: result[i].monthly_month_year,
            checkedIndex: i,
            productId: [],
            productName: [],
            productQuantity: [],
            productSalesUnitPrice: [],
            productSalesUnitAmount: [],
          };
        }
        // 製品、消費税、入金の３パターン
        if (result[i].transaction_id == Const.TransactionId.deposit) {
          // トランザクションIDがNKの場合は入金
          searchResult['productId'].push('');
          searchResult['productName'].push(result[i].kind + '　（　' + (getListValue(Const.denominationList, result[i].kind) + '　　　').substr(0, 3) + '　）　' + result[i].account_support);
          searchResult['productQuantity'].push('');
          searchResult['productSalesUnitPrice'].push('');
          searchResult['productSalesUnitAmount'].push(result[i].amount.toLocaleString());
        } else if (result[i].product_id == 0) {
          // 製品コードが0の場合は消費税
          searchResult['productId'].push('');
          searchResult['productName'].push('消費税');
          searchResult['productQuantity'].push('');
          searchResult['productSalesUnitPrice'].push('');
          searchResult['productSalesUnitAmount'].push(result[i].product_tax.toLocaleString());
        } else {
          // 上記以外の場合（製品コードが0以外、且つ、トランザクションIDがUR）の場合は製品
          searchResult['productId'].push(result[i].product_id);
          searchResult['productName'].push(result[i].product_name);
          searchResult['productQuantity'].push(result[i].product_quantity.toLocaleString() + (result[i].product_unit == null ? '' : ' ' + result[i].product_unit));
          if (getNullStr(result[i].service_class) != '') {
            searchResult['productSalesUnitPrice'].push(getListValue(this.serviceList, result[i].service_class, 'id', 'text'));
          } else {
            searchResult['productSalesUnitPrice'].push(result[i].product_sales_unit_price.toLocaleString());
          }
          searchResult['productSalesUnitAmount'].push(result[i].product_sales_unit_amount.toLocaleString());
        }
      }
      if (searchResult != null) {
        if (searchResult.transactionId == Const.TransactionId.sales) {
          this.totalCheckCnt++;
        }
        this.itemList.push(searchResult);
      }
      // 伝票の総件数をdataTableの総件数にセット
      this.totalRows = this.itemList.length;
      // 検索結果の初期表示時、全結果が表示されているようにする
      //this.perPage = -1;
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' transaction_id';
      selectSql += ',billing_date';
      selectSql += ',billing_no';
      selectSql += ',delivery_design_date';
      selectSql += ',delivery_design';
      selectSql += ',delivery_design_class';
      selectSql += ',site_id';
      selectSql += ',shipping_code';
      selectSql += ',client_site_name';
      selectSql += ',product_id';
      selectSql += ',product_name';
      selectSql += ',product_quantity';
      selectSql += ',product_unit';
      selectSql += ',product_sales_unit_price';
      selectSql += ',product_sales_unit_amount';
      selectSql += ',product_tax';
      selectSql += ',kind';
      selectSql += ',account_support';
      selectSql += ',amount';
      selectSql += ',service_class';
      selectSql += ',monthly_month_year';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += ' t_cumulative_transaction';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID（UR:売上、NK:入金）
      selectSql += 'transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      // 得意先コード
      selectSql += 'AND client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND client_id = ' + this.searchConditions.clientId + ' ';
      // 現場コード
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        selectSql += 'AND site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        selectSql += 'AND site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        selectSql += 'AND site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      if (this.searchConditions.productId != 0) {
        // 製品コードが指定された場合は指定された製品コードのレコードのみ
        selectSql += 'AND product_id = ' + this.searchConditions.productId + ' ';
      }
      // 伝票日付
      if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd == ''){
        selectSql += 'AND billing_date >= ' + '\'' + this.searchConditions.billingDateStart + '\' ';
      }else if(this.searchConditions.billingDateStart == '' && this.searchConditions.billingDateEnd != ''){
        selectSql += 'AND billing_date <= ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }else if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd != ''){
        selectSql += 'AND billing_date BETWEEN ' + '\'' + this.searchConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }
      // 金額が0のデータは非表示
      selectSql += 'AND (transaction_id = \'' + Const.TransactionId.sales + '\' OR amount <> 0) ';
      /* ORDER BY */
      selectSql += ' ORDER BY ';
      selectSql += 'billing_date';
      selectSql += ',transaction_id DESC';
      selectSql += ',billing_no';
      selectSql += ',billing_row';
      selectSql += ',site_id';
      /* LIMIT */
      selectSql += ' LIMIT 5000';
      return selectSql;
    },
    /* 売上伝票照会ボタン押下時 */
    async clickSalesSlipBtn(billingNo) {
      let route = this.$router.resolve({ name: 'SALES-SLIP-INQUIRY', query: { propsBillingNo: billingNo } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function(index) {
      //console.log('現場検索モーダル');
      this.siteSearchInfo.client_id = this.searchConditions.clientId;
      this.siteSearchInfo.client_name = this.searchConditions.clientName;
      this.openModalIndex = index;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場検索モーダルを閉じた時
    async closeSiteSearchModal(siteItems) {
      // モーダルから渡された値の有無チェック
      if(typeof siteItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.siteIdStart = siteItems.detail.site_id;
        } else {
          this.searchConditions.siteIdEnd = siteItems.detail.site_id;
        }
      }
    },
    siteClear: function(index) {
      //console.log('現場クリア');
      if (index == 1) {
        this.searchConditions.siteIdStart = '';
      } else {
        this.searchConditions.siteIdEnd = '';
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 全チェック選択
    clickCheckAll: function() {
      // 印刷チェックを初期化
      this.checkedIndexs = [];
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 印刷チェックを全てON
        for (let i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].transactionId == Const.TransactionId.sales) {
            this.checkedIndexs.push(this.itemList[i].checkedIndex);
          }
        }
      }
    },
    // チェックボックス個別選択（全チェックのチェックボックスを合わせる）
    changeCheck: function() {
      this.isAllChecked = this.totalCheckCnt <= this.checkedIndexs.length;
    },
    // 入力行チェックボタンの押下
    clickBulkCheckBtn: function() {
      let regexp = new RegExp(/^[1-9]$|(^[1-9][0-9]*)/);
      if (regexp.test(getNullStr(this.inputRowCheckStart)) == false ||
      regexp.test(getNullStr(this.inputRowCheckEnd)) == false ) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2082'].replace('%arg1%', '1以上の数値'));
        scrollTo(0,0);
        return;
      }
      if (this.inputRowCheckStart <= this.inputRowCheckEnd) {
        for (let i = this.inputRowCheckStart - 1; i < this.itemList.length && i < this.inputRowCheckEnd; i++) {
          if (this.itemList[i].transactionId == Const.TransactionId.sales) {
            if (this.checkedIndexs.findIndex(el => el == this.itemList[i].checkedIndex) < 0) {
              // 既にチェックONの場合は追加しない
              this.checkedIndexs.push(this.itemList[i].checkedIndex);
            }
          }
        }
        this.changeCheck();
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2082'].replace('%arg1%', '正しい大小関係'));
        scrollTo(0,0);
        return;
      }
    },
    // 伝票一括発行ボタンの押下
    async clickBulkPrintBtn() {
      if (this.checkedIndexs.length <= this.maxPrintCnt) {
        let billingNoList = [];
        let monthlyMonthYearList = [];
        for (let i = 0; i < this.checkedIndexs.length; i++) {
          let item = this.itemList.find(el => el.checkedIndex == this.checkedIndexs[i]);
          let index = billingNoList.findIndex(el => el == item.billingNo);
          if (index == -1) {
            billingNoList.push(item.billingNo);
            monthlyMonthYearList.push(item.monthlyMonthYear);
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2076'].replace('%arg1%', '伝票番号' + item.billingNo));
            scrollTo(0,0);
            return;
          }
        }
        // 請求書PDF出力処理画面へ遷移
        let query = {
          receivedOrderId: 0,
          billingNo: billingNoList.join(','),
          monthlyMonthYear: monthlyMonthYearList.join(','),
          isBulkPrintFlg: '1',
        };
        let route = this.$router.resolve({ name: 'SALES-DIRECT-NOUHIN', query: query });
        window.open(route.href, '_blank');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2075'].replace('%arg1%', '伝票').replace('%arg2%', this.maxPrintCnt));
        scrollTo(0,0);
        return;
      }
    },
    // HTMLでgetNullStrを呼び出し用
    htmlGetNullStr: function(val) {
      return getNullStr(val);
    },
  }
}
</script>