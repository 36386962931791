<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出力範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); clickOutputPdf();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="6">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-select
                          id="selectSalesOffice"
                          v-model="searchConditions.selectSalesOffice"
                          :options="searchConditions.salesOfficeOption"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票種類 -->
                    <b-col lg="6">
                      <b-form-group>
                        <label class="form-label" :title="htmlConst.BillingTypeClassTitle">伝票種類 <span class="oi oi-flag"/></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-radio-group
                              id="checkBillingTypeClass"
                              v-model="searchConditions.checkBillingTypeClass"
                              :options="searchConditions.billingTypeClassList"
                            ></b-form-radio-group>
                          </b-col>
                          <validation-provider name="checkBillingTypeClass" rules="required" v-slot="{ classes,errors }">
                            <b-col lg="12">
                              <input name="checkBillingTypeClass" type="hidden" v-model="searchConditions.checkBillingTypeClass" />
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 得意先コード -->
                    <b-col lg="12">
                      <b-form-group
                        label="得意先コード"
                        label-for="clientIdStart"
                      >
                        <b-input-group>
                          <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-form-input type="text" id="clientIdStart" name="clientIdStart" v-model.number="searchConditions.clientIdStart" maxlength="6" @blur="searchConditions.clientIdEnd = searchConditions.clientIdStart"/>
                                  <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                          <b-input-group-text>～</b-input-group-text>
                          <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-input type="text" v-model.number="searchConditions.clientIdEnd" maxlength="6"/>
                                  <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                        </b-input-group>
                        <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 配送種別 -->
                    <b-col lg="6">
                      <b-form-group
                        label="配送種別"
                        label-for="shippingTypeClassStart"
                      >
                        <b-input-group>
                          <validation-provider name="shippingTypeClassStart" :rules="{required: (searchConditions.shippingCodeStart==''||searchConditions.shippingCodeEnd==''), consistency:searchConditions.shippingTypeClassEnd}" v-slot="{ classes,errors }">
                            <b-input-group :class="classes">
                              <b-form-input type="text" id="shippingTypeClassStart" name="shippingTypeClassStart" v-model.number="searchConditions.shippingTypeClassStart" maxlength="1" @blur="searchConditions.shippingTypeClassEnd = searchConditions.shippingTypeClassStart"/>
                            </b-input-group>
                            <b-input-group :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-input-group>
                          </validation-provider>
                          <b-input-group-text>～</b-input-group-text>
                          <validation-provider name="shippingTypeClassEnd" :rules="{required: (searchConditions.shippingCodeStart==''||searchConditions.shippingCodeEnd=='')}" v-slot="{ classes,errors }">
                            <b-input-group :class="classes">
                              <b-form-input type="text" name="shippingTypeClassEnd" v-model.number="searchConditions.shippingTypeClassEnd" maxlength="1"/>
                            </b-input-group>
                            <b-input-group :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-input-group>
                          </validation-provider>
                        </b-input-group>
                        <b-form-text class="text-muted">配送種別の範囲を入力してください。空白の場合は全範囲が設定されます。<br>配送種別、ルートのいずれかは必須項目です。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- ルート -->
                    <b-col lg="6">
                      <b-form-group
                        label="ルート"
                        label-for="shippingCodeStart"
                      >
                        <validation-provider name="shippingCodeStart" :rules="{consistency:searchConditions.shippingCodeEnd}" v-slot="{ classes,errors }">
                          <b-input-group :class="classes">
                            <b-form-input type="text" id="shippingCodeStart" name="shippingCodeStart" v-model="searchConditions.shippingCodeStart" @blur="searchConditions.shippingCodeEnd = searchConditions.shippingCodeStart" />
                            <b-input-group-text>～</b-input-group-text>
                            <b-form-input type="text" name="shippingCodeEnd" v-model="searchConditions.shippingCodeEnd" />
                          </b-input-group>
                          <b-input-group :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-input-group>
                        </validation-provider>
                        <b-form-text class="text-muted">配送コードの範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- PDF出力ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center my-2">
              <b-col lg="4">
                <b-button class="mr-2" pill variant="success" v-b-tooltip.hover.noninteractive title="指定された範囲の納品書のPDF出力を行います。" type="submit" form="InputForm">
                  <span class="oi oi-document"></span> 納品書PDF出力
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatCurDate, setOfficeListOption, addOperationLogs, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'sales-slip-print-auto';

export default {
  name: 'SALES-SLIP-PRINT-AUTO',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '自動売上伝票発行',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOfficeOption: [],
        // 伝票種類
        checkBillingTypeClass: 0,
        billingTypeClassList: Const.BillingTypeClassList,
        // 取引先コード（開始）
        clientIdStart: '',
        // 取引先コード（終了）
        clientIdEnd: '',
        // 配送コード（開始）
        shippingCodeStart: '',
        // 配送コード（終了）
        shippingCodeEnd: '',
        // 配送種別（開始）
        shippingTypeClassStart: '',
        // 配送種別（終了）
        shippingTypeClassEnd: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 確認ダイアログ用
      confirmMessage: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 伝票種類吹き出し
        BillingTypeClassTitle: Const.BillingTypeClassTitle,
      },
      // 別タブを開く
      newWin: null,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData'
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
  
        // 営業所データ取得
        await setOfficeListOption()
        // 営業所プルダウン作成
        this.searchConditions.salesOfficeOption = this.$store.getters.officeOptionEscapeAll
        // 営業所データ初期値セット
        this.searchConditions.selectSalesOffice = user.office_id
        
        // 確定入力日初期値セット
        this.searchConditions.dateConfirmDate = formatCurDate('YYYY-MM-DD');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      this.$store.commit('setLoading', false);
    },
    /* PDF出力ボタンの押下 */
    async clickOutputPdf() {
      //console.log(this.searchConditions);
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で実行します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 別タブ事前に開く
          this.newWin = window.open('', '_blank');
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg) {
        // タブを閉じる
        this.newWin.close()
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認modal */
    async confirmSave() {
      const functionName = 'confirmSave';

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      // PDF出力処理
      await this.clickOutputSalesSlipPdf();
    },
    /* 納品書PDF出力ボタンの押下 */
    async clickOutputSalesSlipPdf() {
      // 得意先元帳PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        selectSalesOffice: this.searchConditions.selectSalesOffice,
        checkBillingTypeClass: this.searchConditions.checkBillingTypeClass,
        clientIdStart: this.searchConditions.clientIdStart == '' ? '000000' : this.searchConditions.clientIdStart,
        clientIdEnd: this.searchConditions.clientIdEnd == '' ? '999999' : this.searchConditions.clientIdEnd,
        shippingCodeStart: this.searchConditions.shippingCodeStart == '' ? '*' : this.searchConditions.shippingCodeStart,
        shippingCodeEnd: this.searchConditions.shippingCodeEnd == '' ? '99999' : this.searchConditions.shippingCodeEnd,
        shippingTypeClassStart: this.searchConditions.shippingTypeClassStart == '' ? '*' : this.searchConditions.shippingTypeClassStart,
        shippingTypeClassEnd: this.searchConditions.shippingTypeClassEnd == '' ? '9' : this.searchConditions.shippingTypeClassEnd,
      };
      let route = this.$router.resolve({ name: 'SALES-SLIP-NOUHIN-AUTO', query: query});
      this.newWin.location = route.href
    },
    /* 取引先検索 */
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /* 取引先検索モーダルを閉じた時 */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>