<template>
  <!-- 取引先保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a v-b-toggle.collapse-1 title="クリックすると検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <router-link to="/client-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="取引先の新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <b-collapse id="collapse-1" :visible="true">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 取引先区分 -->
                        <div class="col-md-4 form-group">
                          <label for="clientClass" class="form-label">取引先区分</label>
                          <div>
                            <b-select id="clientClass" class="form-control" v-model="searchConditions.clientClass" :options="clientClassList">
                              <template #first>
                                <b-form-select-option :value="''">未選択</b-form-select-option>
                              </template>
                            </b-select>
                          </div>
                          <small class="form-text text-muted">任意項目です。</small>
                        </div>
                        <!-- 担当者コード入力欄 -->
                        <div class="col-md-8 form-group">
                          <label for="staffName" class="form-label">担当者コード名</label>
                          <div class="input-group">
                            <input type="text" id="staffName" class="form-control" v-model="staffName" readonly>
                            <!-- 担当者検索ボタン -->
                            <b-input-group-text @click="showStaffSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                <span class="oi oi-magnifying-glass"></span>
                                <button type="button" v-shortkey="['alt', '1']" @shortkey="showStaffSearchModal" class="d-none"></button>
                              </b-button>
                            </b-input-group-text>
                            <!-- 担当者コードクリアボタン -->
                            <b-button pill variant="success" title="担当者コードをクリアします。" @click="clearStaffId" class="mr-1">
                              <span class="oi oi-circle-x"></span> クリア
                            </b-button>
                          </div>
                          <small class="form-text text-muted">完全一致検索です。登録済みの担当者コードと一致するデータを検索します。</small>
                          <validation-provider rules="numeric|max:4|between:0,9999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="hidden" id="staffId" v-model="searchConditions.staffId">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <!-- 取引先コード入力欄 -->
                        <div class="col-md-4 form-group">
                          <label for="clientId" class="form-label">取引先コード</label>
                          <validation-provider name="clientId" rules="numeric|max:6|between:1,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientId" class="form-control" v-model="searchConditions.clientId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。登録済みの取引先コードと一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 取引先名入力欄 -->
                        <div class="col-md-8 form-group">
                          <label for="clientName" class="form-label">取引先名</label>
                          <validation-provider rules="max:64" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientName" class="form-control" v-model="searchConditions.clientName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <b-button class="btn-block" pill variant="success" @click="searchButton"><span class="oi oi-magnifying-glass"></span> 検索</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="clientsList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- 取引先区分 -->
                  <template #cell(client_class)="data">
                    {{getClientClassName(data.item.client_class)}}
                  </template>
                  <!-- 取引先コード --->
                  <template #cell(client_id)="data">
                    {{String(data.item.client_id).padStart(6, '0')}}
                  </template>
                  <!-- 担当者コード -->
                  <template #cell(staff_id)="data">
                    {{String(data.item.staff_id).padStart(4, '0')}}
                  </template>
                  <!-- 担当者名 -->
                  <template #cell(staff_name_kanji)="data">
                    {{getStaffName(data.item)}}
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="data">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="取引先保守内容の照会を行います。" @click="clickDetailBtn(operation.item.clientClass, operation.item.clientId)" class="mr-1"> -->
                      <b-button size="sm" @click="clickDetailBtn(data.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="取引先保守内容の編集を行います。" @click="clickEditBtn(operation.item.clientClass, operation.item.clientId)" class="mr-1"> -->
                      <b-button size="sm" @click="clickEditBtn(data.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-row>
              <b-col class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filter != null ? filterRows : clientsList.length"
                  :per-page="perPage == -1 ? clientsList.length : perPage"
                  align="center"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●担当者マスタ検索モーダル●●● -->
    <StaffSearch @from-child="closeStaffSearchModal"/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import StaffSearch from '@/components/modal/staff-search.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init/*, isSystemEditable*/ } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'client-maintenance';

export default {
  name: 'CLIENT-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    StaffSearch
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先保守',
      clientClassList: Const.ClientClassList,
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        clientClass: '',
        clientId: '',
        clientName: '',
        staffId: ''
      },
      staffName: '',
      // 検索結果
      resultError: [],
      clientsList: [],
      fields: DataTblDef.clients_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant: DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.clientsList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
    this.successMessages = this.$route.params.successMessages || []
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'CLIENT-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * 担当者検索モーダルを表示します。 
     */
    showStaffSearchModal() {
      this.$bvModal.show('staffSearchModal');
    },
    /**
     * 担当者検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeStaffSearchModal(choiceData) {
      this.searchConditions.staffId = choiceData.detail.staff_id;
      this.staffName = `${choiceData.detail.staff_id}：${choiceData.detail.staff_name_kanji}`;
    },
    /**
     * 担当者コードをクリアします。
     */
    clearStaffId() {
      this.searchConditions.staffId = '';
      this.staffName = '';
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* 取引先マスタ検索 */
    async searchClients() {
      const functionName = 'searchClients';

      const whereClause = [];
      // 取引先区分
      if (this.searchConditions.clientClass !== '') {
        whereClause.push(`client_class = ${this.searchConditions.clientClass}`);
      }
      // 担当者コード
      if (this.searchConditions.staffId != '') {
        whereClause.push(`ms.staff_id = ${this.searchConditions.staffId}`);
      }
      // 取引先コード
      if (this.searchConditions.clientId !== '') {
        whereClause.push(`client_id = ${this.searchConditions.clientId}`);
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName !== '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        let nameConditions = `(REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `
        nameConditions += `OR REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', ''))`
        whereClause.push(nameConditions);
      }

      const sql = 'SELECT' +
        ' mc.id' +
        ',mc.client_id client_id' +
        ',mc.client_class' +
        ',mc.client_name_kanji' +
        ',mc.staff_id' +
        ',ms.staff_name_kanji' +
        ',ms.staff_name_kana ' +
        'FROM' +
        ' m_clients mc' +
        ' JOIN m_staffs ms ON ms.staff_id = mc.staff_id ' +
        ((whereClause.length === 0) ? '' : `WHERE ${whereClause.join(' AND ')} `) +
        'ORDER BY mc.client_id ASC LIMIT 1000';
      const sqls = [ sql ];

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, {
          SQLs: sqls
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      this.clientsList = body.data[0];
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.clientsList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    getClientClassName(clientClass) {
      const clientClassString = String(clientClass);
      return Const.ClientClassList.find(item => item.value === clientClassString).text;
    },
    getStaffName(item) {
      return (item.staff_name_kanji === '' || item.staff_name_kanji === ' ') ? item.staff_name_kana : item.staff_name_kanji;
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        await this.searchClients();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /* 照会ボタン押下時 */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'CLIENT-INQUIRY', query: { clientClass: item.client_class, clientId: item.client_id }});
      window.open(route.href, '_blank');
    },
    /* 編集ボタン押下時 */
    clickEditBtn(item) {
      let route = this.$router.resolve({ name: 'CLIENT-EDIT', query: { clientClass: item.client_class, clientId: item.client_id } });
      window.open(route.href, '_blank');
    },
    /* 削除ボタン押下時 */
    /*
    clickDeleteBtn(item) {
      const functionName = 'clickDeleteBtn';

      this.$bvModal.msgBoxConfirm('選択された取引先データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);

          this.successMessages = [];
          this.errorMessages = [];

          // 旧相殺相手先側の相殺相手先を削除する
          const offsetClientIdRelationSqlDel = `UPDATE m_clients SET offset_client_id = 0  
                                                WHERE 
                                                offset_client_id = ${Number(item.client_id)} 
                                                AND client_class = ${Number(item.client_class) == 1 ? 2 : 1}` 

          const sqls = [
            `DELETE FROM m_clients WHERE id = ${item.id} AND client_class = ${item.client_class} AND client_id = ${item.client_id}`
          ];
          if (Number(item.client_class) == Const.ClientClass.supplier) {
            // 仕入先の場合、仕入先営業所コードマスタを削除
            sqls.push(`DELETE FROM m_suppliers_offices WHERE supplier_id = ${item.client_id}`);
          }
          sqls.push(offsetClientIdRelationSqlDel)

          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.errorMessages.push(msg);
              scrollTo(0,0);
              return;
            }
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
            this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
            scrollTo(0,0);
            this.$store.commit('setLoading', false);
            return;
          }

          let result = null;
          try {
            result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqls
            }, error);
            this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
            scrollTo(0,0);
            this.$store.commit('setLoading', false);
            return;
          }
          let logLevel = 'Info';
          if (result.errors) {
            logLevel = 'Error';
            this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
          } else {
            this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
          }
          await addOperationLogs(logLevel, MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqls,
            result: result
          });
          await this.searchButton();
          scrollTo(0,0);
          this.$store.commit('setLoading', false);
        }
      });
    }
    */
  }
};
</script>