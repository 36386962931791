<template>
  <!-- 取引先保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 取引先保守編集</strong></h5>
                <router-link to="/client-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <div class="row mt-2">
                    <!-- 取引先区分 -->
                    <div class="col-md-6 form-group">
                      <label for="clientClassName" class="form-label"><strong>取引先区分</strong></label>
                      <input type="text" id="clientClassName" class="form-control" v-model="clientClassName" readonly>
                      <validation-provider rules="required|oneOf:1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="clientClass" v-model="clientClass">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 取引先コード -->
                    <div class="col-md-6 form-group">
                      <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                      <validation-provider rules="required|numeric|max:6|between:1,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientId" class="form-control" v-model="clientId" readonly>
                          </div>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 取引先名（漢字） -->
                    <div class="col-md-6 form-group">
                      <label for="clientNameKanji" class="form-label"><strong>取引先名（漢字）</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientNameKanji" class="form-control" v-model="clientNameKanji" maxlength="34">
                          </div>
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 取引先名（カナ） -->
                    <div class="col-md-6 form-group">
                      <label for="clientNameKana" class="form-label"><strong>取引先名（カナ）</strong></label>
                      <validation-provider rules="required|max:40" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientNameKana" class="form-control" v-model="clientNameKana" maxlength="40">
                          </div>
                          <small class="form-text text-muted">必須項目です。40文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 部署１ -->
                    <div class="col-md-6 form-group">
                      <label for="department1" class="form-label"><strong>部署１</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="department1" class="form-control" v-model="department1" maxlength="22">
                          </div>
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 部署２ -->
                    <div class="col-md-6 form-group">
                      <label for="department2" class="form-label"><strong>部署２</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="department2" class="form-control" v-model="department2" maxlength="22">
                          </div>
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 郵便番号 -->
                    <div class="col-md-6 form-group">
                      <label for="zipCode" class="form-label"><strong>郵便番号</strong></label>
                      <validation-provider rules="zip_code" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="zipCode" class="form-control" v-model="zipCode" maxlength="8">
                          <small class="form-text text-muted">任意項目です。「nnn-nnnn」形式で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 住所１ -->
                    <div class="col-md-6 form-group">
                      <label for="address1" class="form-label"><strong>住所１</strong></label>
                      <validation-provider rules="max:36" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="address1" class="form-control" v-model="address1" maxlength="36">
                          <small class="form-text text-muted">任意項目です。36文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- TEL -->
                    <div class="col-md-6 form-group">
                      <label for="phoneNumber" class="form-label"><strong>TEL</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="phoneNumber" class="form-control" v-model="phoneNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 住所２ -->
                    <div class="col-md-6 form-group">
                      <label for="address2" class="form-label"><strong>住所２</strong></label>
                      <validation-provider rules="max:36" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="address2" class="form-control" v-model="address2" maxlength="36">
                          <small class="form-text text-muted">任意項目です。36文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- FAX -->
                    <div class="col-md-6 form-group">
                      <label for="faxNumber" class="form-label"><strong>FAX</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="faxNumber" class="form-control" v-model="faxNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-md-6 form-group" v-if="clientClass === clientClassClient">
                      <label for="staffName" class="form-label"><strong>担当者</strong></label>
                      <div class="input-group">
                        <input type="text" id="staffName" class="form-control" v-model="staffName" readonly>
                        <!-- 担当者検索ボタン -->
                        <b-input-group-text @click="showStaffSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showStaffSearchModal" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </div>
                      <small class="form-text text-muted">必須項目です。</small>
                      <validation-provider rules="required|numeric|max:4|between:0,9999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="staffId" v-model="staffId">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- チェック内容 -->
                    <div class="col-md-6 form-group">
                      <label for="checkContents" class="form-label"><strong>チェック内容</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="checkContents" class="form-control" v-model="checkContents" maxlength="22">
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 最新現場コード -->
                    <div class="col-md-6 form-group">
                      <label for="latestSiteName" class="form-label"><strong>最新現場コード</strong></label>
                      <div class="input-group">
                        <input type="text" id="latestSiteName" class="form-control" v-model="latestSiteName" readonly>
                        <!-- 現場検索ボタン -->
                        <b-input-group-text @click="showSiteSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '2']" @shortkey="showSiteSearchModal" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </div>
                      <small class="form-text text-muted">必須項目です。</small>
                      <validation-provider rules="required|numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="latestSiteId" class="form-control" v-model="latestSiteId">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 組合コード -->
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="unionId" class="form-label"><strong>組合コード</strong></label>
                      <validation-provider rules="required|numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="unionId" class="form-control" v-model="unionId" maxlength="6">
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 伝票種類 -->
                    <div class="col-md-6 form-group">
                      <label for="billingTypeClass" class="form-label" :title="htmlConst.BillingTypeClassTitle"><strong>伝票種類 <span class="oi oi-flag"/></strong></label>
                      <validation-provider rules="required|oneOf:0,1,2,3,4" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingTypeClass" class="form-control" v-model="billingTypeClass" :options="billingTypeClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 発注書回数 -->
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="orderCountClass" class="form-label"><strong>発注書回数</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="orderCountClass" class="form-control" v-model="orderCountClass" :options="orderCountClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 値引率 -->
                    <div class="col-md-6 form-group">
                      <label for="discountRate" class="form-label"><strong>値引率</strong></label>
                      <validation-provider rules="required|numeric|max:2|between:0,99" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <b-form-input type="number" id="discountRate" v-model.number="discountRate" maxlength="2"/><span class="ml-3 d-flex align-items-center">%</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- サービス区分 -->
                    <div class="col-md-6 form-group">
                      <label for="serviceOrderClass" class="form-label"><strong>サービス区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="serviceOrderClass" class="form-control" v-model="serviceOrderClass" :options="serviceOrderClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- 発注条件 -->
                    <div class="col form-group">
                      <label for="orderConditionQuantity" class="form-label"><strong>発注条件</strong></label>
                      <b-table hover
                        table-class="datatable"
                        :head-variant="headVariant"
                        :responsive="true"
                        :items="orderCondition"
                        :fields="orderConditionTableFields"
                        :bordered="true"
                      >
                        <!-- 発注条件 数量 -->
                        <template #cell(orderConditionQuantity)="data">
                          <validation-provider rules="required|numeric|max:8|between:0,99999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input :readonly="sundriesClass == 1" type="text" id="orderConditionQuantity" class="form-control" v-model="data.item.orderConditionQuantity" maxlength="8">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </template>
                        <!-- 発注条件 金額 -->
                        <template #cell(orderConditionAmount)="data">
                          <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input :readonly="sundriesClass == 1" type="text" id="orderConditionAmount" class="form-control" v-model="data.item.orderConditionAmount" maxlength="10">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 締日 -->
                    <div class="col-md-6 form-group">
                      <label for="closingDate" class="form-label"><strong>締日</strong></label>
                      <validation-provider rules="required|numeric|oneOf:5,10,15,20,25,99" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="closingDate" class="form-control" v-model="closingDate" :options="closingDateList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 請求書出力単位区分 -->
                    <div class="col-md-6 form-group">
                      <label for="billingOutputClass" class="form-label"><strong>請求書出力単位区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingOutputClass" class="form-control" v-model="billingOutputClass" :options="billingOutputClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 請求書区分 -->
                    <div class="col-md-6 form-group">
                      <label for="billingClass" class="form-label"><strong>請求書区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingClass" class="form-control" v-model="billingClass" :options="billingClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 前回請求締切日 -->
                    <div class="col-md-6 form-group">
                      <label for="previousBillingClosingDate" class="form-label"><strong>前回請求締切日</strong></label>
                      <validation-provider rules="regex:[0-9]{4}-[0-1][0-9]-[0-3][0-9]" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-input-group>
                            <b-form-datepicker id="previousBillingClosingDate" class="form-control" v-model="previousBillingClosingDate"
                              locale="ja"
                              :hide-header = true
                              calendar-width="50%"
                              today-button
                              reset-button
                              close-button
                            ></b-form-datepicker>
                            <b-input-group-append>
                              <b-button size="sm" variant="outline-secondary" @click="previousBillingClosingDate=''">
                                <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="form-text text-muted">任意項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 取引区分 -->
                    <div class="col-md-6 form-group">
                      <label for="businessClass" class="form-label"><strong>取引区分</strong></label>
                      <validation-provider rules="required|oneOf:1,2,3,4" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="businessClass" class="form-control" v-model="businessClass" :options="businessClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 相殺相手先 -->
                    <div class="col-md-6 form-group">
                      <label for="offsetClientName" class="form-label"><strong>相殺相手先</strong></label>
                      <div class="input-group">
                        <input type="text" id="offsetClientName" class="form-control" v-model="offsetClientName" readonly>
                        <!-- 取引先検索ボタン -->
                        <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '3']" @shortkey="showClientSearchModal" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </div>
                      <small class="form-text text-muted">必須項目です。</small>
                      <validation-provider rules="required|numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="offsetClientId" v-model="offsetClientId">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 入金（支払）区分 -->
                    <div class="col-md-6 form-group">
                      <label for="paymentClass" class="form-label"><strong>入金（支払）区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2,3" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="paymentClass" class="form-control" v-model="paymentClass" :options="paymentClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 入金（支払）予定日 -->
                    <div class="col-md-6 form-group">
                      <label for="paymentScheduled" class="form-label"><strong>入金（支払）予定日</strong></label>
                      <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="paymentScheduled" class="form-control" v-model="paymentScheduled" :options="paymentDateList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 売上課税区分 -->
                    <div class="col-md-6 form-group">
                      <label for="salesTaxClass" class="form-label"><strong>売上課税区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="salesTaxClass" class="form-control" v-model="salesTaxClass" :options="salesTaxClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 税額計算区分 -->
                    <div class="col-md-6 form-group">
                      <label for="taxCalculationClass" class="form-label"><strong>税額計算区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="taxCalculationClass" class="form-control" v-model="taxCalculationClass" :options="taxCalculationClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- 振り込み手数料 -->
                    <div class="col-md-6 form-group">
                      <label for="transferFee" class="form-label"><strong>振り込み手数料</strong></label>
                      <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee" class="form-control" v-model="transferFee" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[0].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 振り込み手数料２ -->
                    <div class="col-md-6 form-group">
                      <label for="transferFee2" class="form-label"><strong>振り込み手数料２</strong></label>
                      <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee2" class="form-control" v-model="transferFee2" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[1].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="transferFee3" class="form-label"><strong>振り込み手数料３</strong></label>
                      <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee3" class="form-control" v-model="transferFee3" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[2].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- WEB発行区分 -->
                    <div class="col-md-6 form-group" v-if="clientClass == clientClassClient">
                      <label for="webIssueClass" class="form-label"><strong>WEB発行区分</strong></label>
                      <b-select
                        id="webIssueClass"
                        class="form-control"
                        v-model="webIssueClass"
                        :options="webIssueClassList"
                        value-field="id"
                      >
                      </b-select>
                    </div>
                  </div>
                  <div v-if="clientClass === clientClassSupplier" class="row mt-2">
                    <!-- 取引先サンワ顧客コード -->
                    <div class="col-12 form-group">
                      <label class="form-label"><strong>取引先サンワ顧客コード</strong></label>
                      <b-row>
                        <!-- 1ページあたりの表示選択 -->
                        <b-col lg="6" class="my-1">
                          <b-form-group
                            label="1ページあたりの表示件数"
                            label-for="per-page-select"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1"
                          >
                            <b-form-select id="per-page-select" v-model="suppliersOfficesTablePerPage" :options="suppliersOfficesTablePageOptions" size="sm"></b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 検索結果検索 -->
                        <b-col lg="6" class="my-1">
                          <b-form-group
                            label="Filter"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1"
                          >
                            <b-input-group size="sm">
                              <b-form-input id="filter-input" v-model="suppliersOfficesTableFilter" type="search"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <b-table
                            id="suppliersOffices"
                            show-empty
                            :head-variant="headVariant"
                            :responsive="true"
                            :items="suppliersOffices"
                            :fields="suppliersOfficesTableFields"
                            :busy="suppliersOfficesTableBusy"
                            :filter="suppliersOfficesTableFilter"
                            :per-page="suppliersOfficesTablePerPage"
                            :current-page="suppliersOfficesTableCurrentPage"
                            :bordered="true"
                            :empty-text="emptyText"
                            :empty-filtered-text="emptyFilterdText"
                            @filtered="onSuppliersOfficesTableFiltered"
                          >
                            <template #cell(officeId)="data">
                              {{String(data.item.officeId).padStart(2, '0')}}
                            </template>
                            <template #cell(supplierControlOfficeId)="data">
                              <validation-provider rules="numeric|max:10|min_value:0|max_value:2147483647" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input v-model="data.item.supplierControlOfficeId" size="sm" length="10"></b-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group
                            :label="getSuppliersOfficesTablePagingMessage"
                            class="mt-0 mb-0"
                          />
                        </b-col>
                      </b-row>
                      <!-- テーブルページネーション -->
                      <b-row>
                        <b-col class="my-1">
                          <b-pagination
                            v-model="suppliersOfficesTableCurrentPage"
                            :total-rows="suppliersOfficesTableFilter != null ? suppliersOfficesTableFilterRows : suppliersOffices.length"
                            :per-page="suppliersOfficesTablePerPage == -1 ? suppliersOffices.length : suppliersOfficesTablePerPage"
                            align="center"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <b-button class="btn-block" pill variant="success" @click="onSaveButtonClick"><span class="oi oi-circle-check"></span> 保存</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●現場マスタ検索モーダル●●● -->
    <SiteSearch @from-child="closeSiteSearchModal" :clientInfo="{ client_id: clientId, client_name: clientNameKanji }"/>
    <!-- ●●●担当者マスタ検索モーダル●●● -->
    <StaffSearch @from-child="closeStaffSearchModal"/>
    <!-- ●●●担当者マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="offsetClientClass"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateUpdateSql, CreateInsertSql, isSystemEditable, offsetClientIdRelation, checkOffsetClientIdFaild, getUserCol, getNullStr } from '@/assets/js/common.js';
import SiteSearch from '@/components/modal/site-search.vue';
import StaffSearch from '@/components/modal/staff-search.vue';
import ClientSearch from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_clients, list_m_staffs, list_m_clients_sites, list_m_offices, list_m_suppliers_offices } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'client-edit';

export default {
  name: 'CLIENT-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    SiteSearch,
    StaffSearch,
    ClientSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先保守編集',
      clientClassClient: Const.ClientClassList[0].value,
      clientClassSupplier: Const.ClientClassList[1].value,
      // 伝票種類区分リスト
      billingTypeClassList: Const.BillingTypeClassList,
      // 発注書回数区分リスト
      orderCountClassList: Const.OrderCountClassList,
      // サービス発注区分リスト
      serviceOrderClassList: Const.ServiceOrderClassList,
      // 締日リスト
      closingDateList: Const.closingDateList.filter(item => item.value !== ''),
      // 請求書出力単位区分リスト
      billingOutputClassList: Const.BillingOutputClassList,
      // 請求書区分リスト
      billingClassList: Const.BillingClassList,
      // 取引区分リスト
      businessClassList: Const.BusinessClassList,
      // 入金区分リスト
      paymentClassList: Const.PaymentClassList,
      // 入金予定日リスト
      paymentDateList: Const.PaymentDateList,
      // 売上課税区分リスト
      salesTaxClassList: Const.SalesTaxClassList,
      // 税額計算区分リスト
      taxCalculationClassList: Const.TaxCalculationClassList,
      headVariant: DataTblDef.headerVariant,
      orderConditionTableFields: DataTblDef.clients_order_condition_fields,
      suppliersOfficesTableFields: DataTblDef.clients_suppliers_offices_fields,
      suppliersOfficesTableBusy: false,
      suppliersOfficesTableFilter: null,
      // フィルタリングデータの総件数
      suppliersOfficesTableFilterRows: 0,
      // 表示件数のdefault値
      suppliersOfficesTablePerPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      suppliersOfficesTablePageOptions: DataTblDef.pageOptions,
      // ページネーションの初期表示位置
      suppliersOfficesTableCurrentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // ID
      id: '',
      // 取引先区分
      clientClass: '',
      // 取引先区分表示名
      clientClassName: '',
      // 取引先コード
      clientId: '',
      // 取引先名（漢字）
      clientNameKanji: '',
      // 取引先名（カナ）
      clientNameKana: '',
      // 部署１
      department1: '',
      // 部署２
      department2: '',
      // 郵便番号
      zipCode: '',
      // 住所１
      address1: '',
      // 住所２
      address2: '',
      // TEL
      phoneNumber: '',
      // FAX
      faxNumber: '',
      // 担当者コード
      staffId: '',
      // 担当者名
      staffName: '',
      // 最新現場コード
      latestSiteId: '0',
      // 最新現場表示名
      latestSiteName: '',
      // チェック内容
      checkContents: '',
      // 諸口区分
      sundriesClass: '',
      // 締日
      closingDate: '',
      // 請求書区分
      billingClass: '',
      // 前回請求締切日
      previousBillingClosingDate: '',
      // 取引区分
      businessClass: '',
      // 相殺相手先コード
      offsetClientId: '',
      // 相殺相手先名
      offsetClientName: '',
      // 請求書出力単位区分
      billingOutputClass: '',
      // 入金（支払）区分
      paymentClass: '',
      // 入金（支払）予定日
      paymentScheduled: '',
      // 売上課税区分
      salesTaxClass: '',
      // 税額計算区分
      taxCalculationClass: '',
      // 税額端数処理区分
      taxFractionationClass: Const.TaxFractionationClass.truncate,
      // 伝票種類区分
      billingTypeClass: '',
      // 組合コード
      unionId: '',
      // サービス発注区分
      serviceOrderClass: '',
      orderCondition: [
        {
          // 発注条件（数量）
          orderConditionQuantity: '',
          // 発注条件（金額）
          orderConditionAmount: '',
        }
      ],
      // 値引率
      discountRate: 0,
      // 発注書回数区分
      orderCountClass: '',
      // 振り込み手数料
      transferFee: '',
      transferFee2: '',
      transferFee3: '',
      transferFeeList: Const.transferFee,
      // WEB発行区分
      webIssueClass: Const.WebIssueClass.noIssue,
      webIssueClassList: Const.WebIssueClassList,
      // 取引先サンワ顧客コード
      suppliersOffices: [],
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 処理結果成功メッセージ
      successMessages: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種類吹き出し
        BillingTypeClassTitle: Const.BillingTypeClassTitle,
      },
    }
  },
  computed: {
    /**
     * ページの表示件数
     */
    getSuppliersOfficesTablePagingMessage() {
      const tableLength = (this.suppliersOfficesTableFilter != null) ? this.suppliersOfficesTableFilterRows : this.suppliersOffices.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.suppliersOfficesTablePerPage !== -1) {
        end = this.suppliersOfficesTableCurrentPage * this.suppliersOfficesTablePerPage;
        start = end - this.suppliersOfficesTablePerPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    },
    // 取引先区分に対して相殺相手先区分
    offsetClientClass() {
      // 取引先区分と相殺相手区分は逆
      switch (Number(this.clientClass)) {
      case Const.ClientClass.customer:
        return Const.ClientClass.supplier
      case Const.ClientClass.supplier:
        return Const.ClientClass.customer
      default:
        return ''
      }
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    // 新規画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query.clientClass, this.$route.query.clientId);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 現場検索モーダルを表示します。 
     */
    showSiteSearchModal() {
      if (this.clientClass === Number(this.clientClassClient)) {
        this.$bvModal.msgBoxOk('取引先区分が得意先の場合のみ選択できます。');
      } else {
        this.$bvModal.show('siteSearchModal');
      }
    },
    /**
     * 現場検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeSiteSearchModal(choiceData) {
      this.latestSiteId = choiceData.clientSiteId;
      this.latestSiteName = `${choiceData.clientSiteId}：${choiceData.clientSiteNameKanji}`;
    },
    /**
     * 画面に表示するデータを取得します。
     * @param {Number} clientClass - 取引先区分
     * @param {Number} clientId - 取引先コード
     */
    async fetchData(clientClass, clientId) {
      this.$store.commit('setLoading', true);
      // 取引先マスタ検索
      const client = await this.searchMClients(clientClass, clientId);
      if (client === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }

      // 担当者マスタ検索
      const staff = await this.searchMClientsSites(client.staff_id);

      // 現場マスタ検索（最新現場コード）
      const latest_site = await this.seachMClientsSites(client.client_id, client.latest_site_id);

      // 取引先マスタ検索（相殺相手先）
      const offsetClient = await this.searchMClients(client.client_class == 1 ? 2: 1, client.offset_client_id);

      // 営業所マスタ全データ取得
      let offices = null;
      // 仕入先営業所コードマスタ検索（取引先サンワ顧客コード）
      let supplierOffices = null;
      if (Number(clientClass) === Number(this.clientClassSupplier)) {
        offices = await this.listMOffices();
        supplierOffices = await this.searchMSuppliersOffices(client.client_id);
      }

      // ID
      this.id = client.id;
      // 取引先区分
      this.clientClass = String(clientClass);
      // 取引先区分表示名
      this.clientClassName = Const.ClientClassList.find(item => item.value === String(clientClass)).text;
      // 取引先コード
      this.clientId = String(clientId);
      // 取引先名（漢字）
      this.clientNameKanji = client.client_name_kanji;
      // 取引先名（カナ）
      this.clientNameKana = client.client_name_kana;
      // 部署１
      this.department1 = client.department_1;
      // 部署２
      this.department2 = client.department_2;
      // 郵便番号
      this.zipCode = client.zip_code;
      // 住所１
      this.address1 = client.address_1;
      // 住所２
      this.address2 = client.address_2;
      // TEL
      this.phoneNumber = client.phone_number;
      // FAX
      this.faxNumber = client.fax_number;
      // 担当者コード
      this.staffId = String(client.staff_id);
      // 担当者名
      if (staff === null) {
        this.staffName = `${client.staff_id}：担当者マスタに該当なし`;
      } else {
        if (staff.staff_name_kanji.trim() === '') {
          this.staffName = `${client.staff_id}：${staff.staff_name_kana}`;
        } else {
          this.staffName = `${client.staff_id}：${staff.staff_name_kanji}`;
        }
      }
      // 最新現場コード
      this.latestSiteId = client.latest_site_id;
      // 最新現場名
      if (latest_site === null) {
        this.latestSiteName = `${client.latest_site_id}：現場マスタに該当なし`;
      } else {
        this.latestSiteName = `${client.latest_site_id}：${latest_site.client_site_name_kanji}`;
      }
      // チェック内容
      this.checkContents = client.check_contents;
      // 諸口区分
      this.sundriesClass = String(client.sundries_class);
      // 締日
      this.closingDate = client.closing_date;
      // 請求書区分
      this.billingClass = String(client.billing_class);
      // 前回請求締切日
      this.previousBillingClosingDate = client.previous_billing_closing_date;
      // 取引区分
      this.businessClass = String(client.business_class);
      // 相殺相手先コード
      this.offsetClientId = String(client.offset_client_id);
      // 相殺相手先名
      if (offsetClient === null) {
        this.offsetClientName = `${client.offset_client_id}：取引先マスタに該当なし`;
      } else {
        this.offsetClientName = `${client.offset_client_id}：${offsetClient.client_name_kanji}`;
      }
      // 請求書出力単位区分
      this.billingOutputClass = String(client.billing_output_class);
      // 入金（支払）区分
      this.paymentClass = String(client.payment_class);
      // 入金（支払）予定日
      this.paymentScheduled = String(client.payment_scheduled);
      // 売上課税区分
      this.salesTaxClass = String(client.sales_tax_class);
      // 税額計算区分
      this.taxCalculationClass = String(client.tax_calculation_class);
      // 税額端数処理区分
      this.taxFractionationClass = String(client.tax_fractionation_class);
      // 伝票種類区分
      this.billingTypeClass = String(client.billing_type_class);
      // 組合コード
      this.unionId = String(client.union_id);
      // サービス発注区分
      this.serviceOrderClass = String(client.service_order_class);
      this.orderCondition = [
        {
          // 発注条件（数量）
          orderConditionQuantity: String(client.order_condition_quantity),
          // 発注条件（金額）
          orderConditionAmount: String(client.order_condition_amount),
        }
      ],
      // 値引率
      this.discountRate = Number(client.discount_rate);
      // 発注書回数区分
      this.orderCountClass = String(client.order_count_class);
      // 振り込み手数料
      this.transferFee = String(client.transfer_fee);
      // 振り込み手数料２
      this.transferFee2 = String(client.transfer_fee_2);
      // 振り込み手数料３
      this.transferFee3 = String(client.transfer_fee_3);
      // WEB発行区分
      this.webIssueClass = client.web_issue_class;
      // 取引先サンワ顧客コード
      if (Number(clientClass) === Number(this.clientClassSupplier)) {
        const suppliersOffices = [];
        for (const office of offices) {
          const supplierOffice = supplierOffices.find(item => item.office_id === office.office_id);
          suppliersOffices.push({
            officeId: office.office_id,
            officeNameKanji: office.office_name_kanji,
            supplierControlOfficeId: supplierOffice ? supplierOffice.supplier_control_office_id : ''
          });
        }
        this.suppliersOffices = suppliersOffices;
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 取引先マスタを検索します。
     * @param {Number} clientClass - 取引先区分
     * @param {Number} clientId - 取引先コード
     * @returns {Array<Object>} 取引先データ
     */
    async searchMClients(clientClass, clientId) {
      const functionName = 'searchMClients';

      const whereClause = `AND client_class = ${clientClass} AND client_id = ${clientId}`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_clients, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_clients.length === 0) ? null : result.data.list_m_clients[0];
    },
    /**
     * 担当者マスタを検索します。
     * @param {Number} staffId - 担当者コード
     * @returns {Object} 担当者データ
     */
    async searchMClientsSites(staffId) {
      const functionName = 'searchMClientsSites';

      const whereClause = `AND staff_id = ${staffId}`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_staffs, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_staffs.length === 0) ? null : result.data.list_m_staffs[0];
    },
    /**
     * 現場マスタを検索します。
     * @param {Number} clientId - 取引先コード
     * @param {Number} siteId - 現場コード
     * @returns {Object} 現場データ
     */
    async seachMClientsSites(clientId, siteId) {
      const functionName = 'seachMClientsSites';

      const whereClause = `AND client_id = ${clientId} AND site_id = ${siteId}`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_clients_sites, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return (result.data.list_m_clients_sites.length === 0) ? null : result.data.list_m_clients_sites[0];
    },
    /**
     * 営業所マスタから全データを取得します。
     */
    async listMOffices() {
      const functionName = 'listMOffices';

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_offices));
      } catch (error) {
        console.error(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices'
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          result: result
        });
        return null;
      }
      return result.data.list_m_offices;
    },
    /**
     * 仕入先営業所コードマスタを検索します。
     * @param {Number} clientId - 取引先コード
     */
    async searchMSuppliersOffices(clientId) {
      const functionName = 'searchMSuppliersOffices';

      const whereClause = `AND supplier_id = ${clientId}`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_suppliers_offices, {
          where_clause: whereClause
        }));
      } catch (error) {
        console.error(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_suppliers_offices',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_suppliers_offices',
          where_clause: whereClause,
          result: result
        });
        return null;
      }
      return result.data.list_m_suppliers_offices;
    },
    /**
     * 取引先区分が変更された場合、相殺相手先コードをクリアします。
     */
    changeClientClass() {
      this.offsetClientId = '';
    },
    /**
     * 担当者検索モーダルを表示します。 
     */
    showStaffSearchModal() {
      this.$bvModal.show('staffSearchModal');
    },
    /**
     * 担当者検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeStaffSearchModal(choiceData) {
      this.staffId = choiceData.detail.staff_id;
      this.staffName = `${choiceData.detail.staff_id}：${choiceData.detail.staff_name_kanji}`;
    },
    /**
     * 取引先検索モーダルを表示します。
     */
    showClientSearchModal() {
      if (this.clientClass === '') {
        this.$bvModal.msgBoxOk('取引先区分を選択してください。');
      } else {
        this.$bvModal.show('clientSearchModal');
      }
    },
    /**
     * 取引先検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択された項目
     */
    closeClientSearchModal(choiceData) {
      this.offsetClientId = choiceData.detail.client_id;
      this.offsetClientName = `${choiceData.detail.client_id}：${choiceData.detail.client_name_kanji}`;
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onSuppliersOfficesTableFiltered(filteredItems) {
      this.suppliersOfficesTableFilterRows= filteredItems.length;
      this.suppliersOfficesTableCurrentPage= DataTblDef.currentPage;
    },
    /* 保存ボタンの押下 */
    async onSaveButtonClick() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        // 入力チェックが完了した場合、保存確認ダイアログを表示
        await this.saveConfirm();
      } else {
        // 入力エラーがあった場合はエラーの項目へスクロール
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length > 0) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      await this.execUpdate();
      if (this.errorMessages.length > 0) {
        // UPDATE中にエラーが発生した場合は画面の先頭へスクロール
        scrollTo(0,0);
      } else {
        // UPDATEに成功した場合は一覧画面へ遷移
        this.$router.push({
          name: 'CLIENT-MAINTENANCE',
          params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
        });
      }
      //console.log('保存処理終了');
    },
    /* 登録処理 */
    async execUpdate() {
      const functionName = 'execUpdate';
      this.errorMessages = [];

      const sqls = [];
      // 相殺相手先変更する場合
      const curOffsetClientId = await this.curOffsetClientId();
      if (curOffsetClientId != this.offsetClientId) {
        // 相殺相手先すでに登録、判断
        if (await checkOffsetClientIdFaild(this.clientClass, this.offsetClientId)) {      
          this.errorMessages.push(DISP_MESSAGES.DANGER['3008']);
          return
        }
        // 旧相殺相手先側の相殺相手先を削除する
        const offsetClientIdRelationSqlDel = await offsetClientIdRelation(this.clientClass, this.clientId, curOffsetClientId || 0, this.$store.getters.user.username, 'delete')
        // 新相殺相手先側の相殺相手先を更新する
        const offsetClientIdRelationSql = await offsetClientIdRelation(this.clientClass, this.clientId, this.offsetClientId || 0, this.$store.getters.user.username, 'insert')
        sqls.push(offsetClientIdRelationSqlDel)
        sqls.push(offsetClientIdRelationSql)
      }

      sqls.push(await this.createUpdateMClientsSQL());
      const deleteTargets = [];
      if (this.clientClass === this.clientClassSupplier) {
        for (const supplierOffice of this.suppliersOffices) {
          if (supplierOffice.supplierControlOfficeId === '') {
            deleteTargets.push(supplierOffice.officeId);
          } else {
            sqls.push(await this.createUpdateMSuppliersOfficesSQL(supplierOffice));
          }
        }
      }
      if (deleteTargets.length > 0) {
        sqls.push(`DELETE FROM m_suppliers_offices WHERE supplier_id = ${this.clientId} AND office_id in (${deleteTargets.join(',')})`);
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      // 月次更新の状態を確認後、速やかにデータの更新を行います。
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    /**
     * 取引先マスタ更新用SQL文を作成します。
     * @returns {String} SQL文
     */
    async createUpdateMClientsSQL() {
      const colList = [];
      // 取引先分類コード
      colList.push(CreateColRow('client_id_first_digit', this.clientId.length === 6 ? this.clientId.substring(0 ,1) : '0', 'NUMBER'));
      // 取引先名（漢字）
      if (getNullStr(this.clientNameKanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('client_name_kanji', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.clientNameKanji), 'VARCHAR'));
      }
      // 取引先名（カナ）
      colList.push(CreateColRow('client_name_kana', await escapeQuote(this.clientNameKana), 'VARCHAR'));
      // 部署１
      colList.push(CreateColRow('department_1', await escapeQuote(this.department1), 'VARCHAR'));
      // 部署２
      colList.push(CreateColRow('department_2', await escapeQuote(this.department2), 'VARCHAR'));
      // 郵便番号
      colList.push(CreateColRow('zip_code', this.zipCode, 'VARCHAR'));
      // 住所１
      colList.push(CreateColRow('address_1', await escapeQuote(this.address1), 'VARCHAR'));
      // 住所２
      colList.push(CreateColRow('address_2', await escapeQuote(this.address2), 'VARCHAR'));
      // TEL
      colList.push(CreateColRow('phone_number', this.phoneNumber, 'VARCHAR'));
      // FAX
      colList.push(CreateColRow('fax_number', this.faxNumber, 'VARCHAR'));
      // 担当者コード
      colList.push(CreateColRow('staff_id', this.staffId, 'NUMBER'));
      // 最新現場コード
      colList.push(CreateColRow('latest_site_id', this.latestSiteId, 'NUMBER'));
      // チェック内容
      colList.push(CreateColRow('check_contents', await escapeQuote(this.checkContents), 'VARCHAR'));
      // 締日
      colList.push(CreateColRow('closing_date', this.closingDate, 'NUMBER'));
      // 請求書区分
      colList.push(CreateColRow('billing_class', this.billingClass, 'NUMBER'));
      // 前回請求締切日
      if (this.previousBillingClosingDate === null || this.previousBillingClosingDate === '') {
        colList.push(CreateColRow('previous_billing_closing_date', null, 'DATE'));
      } else {
        colList.push(CreateColRow('previous_billing_closing_date', this.previousBillingClosingDate, 'DATE'));
      }
      // 取引区分
      colList.push(CreateColRow('business_class', this.businessClass, 'NUMBER'));
      // 相殺相手先コード
      colList.push(CreateColRow('offset_client_id', this.offsetClientId, 'NUMBER'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', this.billingOutputClass, 'NUMBER'));
      // 入金（支払）区分
      colList.push(CreateColRow('payment_class', this.paymentClass, 'NUMBER'));
      // 入金（支払）予定日
      colList.push(CreateColRow('payment_scheduled', this.paymentScheduled, 'NUMBER'));
      // 売上課税区分
      colList.push(CreateColRow('sales_tax_class', this.salesTaxClass, 'NUMBER'));
      // 税額計算区分
      colList.push(CreateColRow('tax_calculation_class', this.taxCalculationClass, 'NUMBER'));
      // 税額端数処理区分
      colList.push(CreateColRow('tax_fractionation_class', this.taxFractionationClass, 'NUMBER'));
      // 伝票種類区分
      colList.push(CreateColRow('billing_type_class', this.billingTypeClass, 'NUMBER'));
      if (this.clientClass === this.clientClassSupplier) {
        // 仕入先の場合にのみ表示される項目
        // 組合コード
        colList.push(CreateColRow('union_id', this.unionId, 'NUMBER'));
        // サービス発注区分
        colList.push(CreateColRow('service_order_class', this.serviceOrderClass, 'NUMBER'));
        // 発注条件 数量
        colList.push(CreateColRow('order_condition_quantity', this.orderCondition[0].orderConditionQuantity, 'NUMBER'));
        // 発注条件 金額
        colList.push(CreateColRow('order_condition_amount', this.orderCondition[0].orderConditionAmount, 'NUMBER'));
      }
      // 値引率
      colList.push(CreateColRow('discount_rate', this.discountRate, 'NUMBER'));
      if (this.clientClass === this.clientClassSupplier) {
        // 仕入先の場合にのみ表示される項目
        // 発注書回数区分
        colList.push(CreateColRow('order_count_class', this.orderCountClass, 'NUMBER'));
        // 振り込み手数料
        colList.push(CreateColRow('transfer_fee', this.transferFee, 'NUMBER'));
        // 振り込み手数料２
        colList.push(CreateColRow('transfer_fee_2', this.transferFee2, 'NUMBER'));
        // 振り込み手数料３
        colList.push(CreateColRow('transfer_fee_3', this.transferFee3, 'NUMBER'));
      }
      // WEB発行区分
      colList.push(CreateColRow('web_issue_class', this.webIssueClass, 'NUMBER'));
      // 更新ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
      // 更新日
      colList.push(CreateColRow('updated', 'NOW()', 'DATETIME'))

      // SQL文を作成
      return CreateUpdateSql(colList, 'm_clients') + ` WHERE id = ${this.id} AND client_class = ${this.clientClass} AND client_id = ${this.clientId}`;
    },
    /**
     * 仕入先営業所コードマスタ更新用SQL文を作成します。
     * @param {Object} supplierOffice - 取引先サンワ顧客コード設定欄のレコード情報
     * @returns {String} SQL文
     */
    async createUpdateMSuppliersOfficesSQL(supplierOffice) {
      let userName = await escapeQuote(this.$store.getters.user.username)
      const colList = [];
      let sqlInsertCol = ''
      let sqlInsertVal = []
    
      // 仕入先コード
      colList.push(CreateColRow('supplier_id', this.clientId, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', supplierOffice.officeId, 'NUMBER'));
      // 相手先営業所コード
      colList.push(CreateColRow('supplier_control_office_id', supplierOffice.supplierControlOfficeId, 'NUMBER'));
      // 新規と更新ユーザー
      const colBothUser = await getUserCol(userName, 'both')
      // sql insert カラム部分
      sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_suppliers_offices').split(',');
      // sql insert values部分
      sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_suppliers_offices').split(','));
      // sql update values部分
      let sqlUpdateVal = `supplier_control_office_id = ${Number(supplierOffice.supplierControlOfficeId)}, updated_user = '${userName}', updated = CURRENT_TIMESTAMP()`
      
      // SQL文を作成
      return `INSERT INTO m_suppliers_offices (${sqlInsertCol}) VALUES (${sqlInsertVal.join()}) ON DUPLICATE KEY UPDATE ${sqlUpdateVal}`;
    },
    /**
     * 取引先の最新相殺相手先コード
     * @returns {Int} 
     */
    async curOffsetClientId() {
      const functionName = 'curOffsetClientId';
      this.clientClass
      let result = null;
      // 取引先の相殺相手先コード
      let SQLs = `SELECT offset_client_id 
      FROM m_clients 
      WHERE 
      client_id = ${this.clientId} 
      AND client_class = ${this.clientClass}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0].offset_client_id
    },
  }
}
</script>
