import { render, staticRenderFns } from "./staff-edit.vue?vue&type=template&id=c0f910ec&scoped=true&"
import script from "./staff-edit.vue?vue&type=script&lang=js&"
export * from "./staff-edit.vue?vue&type=script&lang=js&"
import style0 from "./staff-edit.vue?vue&type=style&index=0&id=c0f910ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0f910ec",
  null
  
)

export default component.exports