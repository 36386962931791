<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                <strong> 受注入力照会{{getBillingClassText}}
                  <span class="oi oi-flag" v-b-tooltip.hover.noninteractive title="編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。"></span>
                </strong></h5>
                <b-button-toolbar>
                  <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'受注入力に遷移する'" @click="clickInputBtn" class="m-0">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                  </b-button>&nbsp;&nbsp;
                  <b-button variant="danger" pill v-b-tooltip.hover.noninteractive.bottom="'受注一覧に戻る'" @click="cancelReceivedOrderInput" class="m-0">
                    <span class="oi oi-circle-x"></span> キャンセル
                  </b-button>
                </b-button-toolbar>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card mb-3 card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <div class="card-body">
              <validation-observer ref="observer">
                <b-form id="receivedOrderInputForm" class="form-horizontal">
                  <b-row>
                    <!-- ステータス -->
                    <b-col lg="12">
                      <label><strong class='text-body'>ステータス</strong></label>
                      <b-btn-toolbar class="d-inline-flex">
                        <!-- 受注残有無 -->
                        <div v-b-tooltip.hover.noninteractive title="対応が終わっていない製品の有無を表示。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.zanFlg == true ? 'danger' : 'info'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.zanFlg == true ? '受注残あり' : '受注残なし' }}
                          </b-button>
                        </div>
                        <!-- 引当有無 -->
                        <div v-if="selectDelivery!=htmlConst.ShippingTypeClass.direct" v-b-tooltip.hover.noninteractive title="出荷確定可能な引当済みの製品の有無を表示します。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.hikiateFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.hikiateFlg == true ? '引当製品あり' : '引当製品なし' }}
                          </b-button>
                        </div>
                        <!-- 発注済みかどうか -->
                        <div v-if="selectDelivery==htmlConst.ShippingTypeClass.direct||selectBillClass==htmlConst.OrderReceiveBillClass.special" v-b-tooltip.hover.noninteractive title="発注済みの製品の有無を表示します。発注済みの製品がある場合、製品の追加は不可となります。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.orderFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.orderFlg == true ? '発注済製品あり' : '発注済製品なし' }}
                          </b-button>
                        </div>
                        <!-- 入荷済みかどうか -->
                        <div v-if="selectBillClass==htmlConst.OrderReceiveBillClass.special" v-b-tooltip.hover.noninteractive title="入荷済みの製品の有無を表示します。入荷済みの製品がある場合、製品の変更は不可となります。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.nyukaFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.nyukaFlg == true ? '入荷済製品あり' : '入荷済製品なし' }}
                          </b-button>
                        </div>
                        <!-- 出荷済みかどうか -->
                        <div v-if="selectDelivery!=htmlConst.ShippingTypeClass.direct" v-b-tooltip.hover.noninteractive title="出荷確定済みの製品の有無を表示します。出荷確定済みの製品がある場合、製品以外の変更は不可となります。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.shukkaFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.shukkaFlg == true ? '出荷確定製品あり' : '出荷確定製品なし' }}
                          </b-button>
                        </div>
                        <!-- 売上伝票発行有無 -->
                        <div v-b-tooltip.hover.noninteractive title="売上伝票発行済みの製品の有無を表示します。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.denpyoFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.denpyoFlg == true ? '伝票発行製品あり' : '伝票発行製品なし' }}
                          </b-button>
                        </div>
                        <!-- 返品在庫計上有無 -->
                        <div v-if="selectBillClass==htmlConst.OrderReceiveBillClass.return" v-b-tooltip.hover.noninteractive title="返品在庫計上の有無を表示します。">
                          <b-button style="pointer-events: none;" pill :variant="statusFlg.returnFlg == true ? 'info' : 'danger'"  size="sm" class="mr-2">
                            <span class="oi oi-chat"></span> {{ statusFlg.returnFlg == true ? '返品在庫計上済み' : '返品在庫計上未' }}
                          </b-button>
                        </div>
                      </b-btn-toolbar>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 受注番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-input-group>
                        <template #prepend>
                          <label for="receivedOrderId">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>受注番号</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="receivedOrderId" type="text" v-model="receivedOrderId" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectSalesOffice">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>営業所</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 配送種別区分プルダウン -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectDelivery">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>配送</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="selectDelivery" v-model="selectDeliveryText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 伝票種別区分プルダウン -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectBillClass">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>伝種</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="selectBillClass" v-model="selectBillClassText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 受注日付 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="dateOrderReceiveDate">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>受注日付</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="dateOrderReceiveDate" type="text" v-model="dateOrderReceiveDate" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='情報ボタンは入力されたコードに紐づく詳細情報を表示します'>
                              <strong class='text-body'>取引先コード <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="tel" id="suppliersCode" name="suppliersCode" v-model="suppliers.code" readonly></b-form-input>
                        <b-button class="mr-1" size="sm" pill :variant="isClientNoteInput == true ? 'danger' : 'success'" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。コメントがある場合、赤色で表示します。）" :disabled="suppliers.code == ''" @click="clientInfoInquiryModal(suppliers.class, suppliers.code)">
                          <span class="oi oi-eye"></span> 情報
                        </b-button>
                      </b-input-group>
                    </div>
                    <!-- 受注処理担当者 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="orderReceiveStaff">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>受注処理担当者 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="orderReceiveStaff" v-model="orderReceiveStaff.text" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group px-2">
                      <label class="form-label"><strong>取引先名: </strong>{{suppliers.name}}</label>
                    </div>
                     <!-- 締日 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <label class="form-label"><strong>締日: </strong>{{suppliers.closingDate}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 郵便番号 + 取引先住所１ + 取引先住所２ -->
                    <div class="col-12 form-group px-2">
                      <label class="form-label"><strong>取引先住所: </strong>{{suppliers.zipCode}} {{suppliers.address1}} {{suppliers.address2}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先部署１ + 取引先部署２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                      <label class="form-label"><strong>部署: </strong>{{suppliers.department1}} {{suppliers.department2}}</label>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1\2">
                      <label class="form-label"><strong>TEL: </strong>{{suppliers.tel}}</label>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <label class="form-label"><strong>担当者: </strong>{{suppliers.personInCharge}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 単価登録区分 -->
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 form-group px-2">
                      <b-input-group>
                        <template #prepend>
                          <label for="checkUnitPriceRegisterClass">
                            <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='受注作成時に選択された取引先に単価登録を行うかの設定です(3：親取引先で設定した親子取引先にも単価登録を行う)'>
                              <strong class='text-body'>単価登録区分 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="checkUnitPriceRegisterClass" v-model="checkUnitPriceRegisterClassText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 単価 適用日／備考 -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="dateUnitPriceEffectiveDate">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='登録された単価の適用開始日です。'>
                              <strong class='text-body'>適用日 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="dateUnitPriceEffectiveDate" v-model="dateUnitPriceEffectiveDate" readonly />
                      </b-input-group>                        
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="textUnitPriceNote">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='単価登録に関する備考です。帳票には出力されません'>
                              <strong class='text-body'>備考 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="textUnitPriceNote" v-model="textUnitPriceNote" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row v-if="(checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll)">
                    <!-- 登録区分 -->
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group pl-2 pr-0">
                      <b-input-group>
                        <template #prepend>
                          <label for="checkRegisterClass">
                            <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='製品単価情報の登録に同製品グループも含めるかどうかを設定します（0：同製品グループも含め登録、1：自身のみ登録）'>
                              <strong class='text-body'>登録区分 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="checkRegisterClass" v-model="checkRegisterClassText" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 納品指定日 -->
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="orderDate">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品指定日 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="orderDate" v-model="orderDate" readonly />
                      </b-input-group>
                    </div>
                    <!-- 納品指定区分プルダウン -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectDeliveryDesignation">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品指定区分 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="selectDeliveryDesignation" v-model="selectDeliveryDesignationText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 納品指定 -->
                    <div class="col-sm-12 col-md-4 col-lg-5 col-xl-5 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="deliveryNote">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品指定 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="deliveryNote" v-model="deliveryNote" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 現場コード -->
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='情報ボタンは入力されたコードに紐づく詳細情報を表示します'>
                              <strong class='text-body'>現場コード <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="onSiteCode" type="text" v-model="onSite.code" readonly />
                        <b-button class="mr-1" pill size="sm" :variant="isSiteNoteInput == true ? 'danger' : 'success'" title="現場コードに入力された現場の情報を照会します。（現場コードが空欄以外の場合、利用可能です。コメントがある場合、赤色で表示します。）" :disabled="onSite.code == ''" @click="siteInfoInquiryModal(suppliers.code, onSite.code)">
                          <span class="oi oi-eye"></span> 情報
                        </b-button>
                      </b-input-group>
                    </div>
                    <!-- 現場名（漢字） -->
                    <div class="col-sm-12 col-md-4 col-lg-5 col-xl-5 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteName">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>現場名</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteName" readonly v-model="onSite.name" style="color: red;"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 現場郵便番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteZipCode">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>郵便番号</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteZipCode" readonly v-model="onSite.zipCode"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 配送コード -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteDeliveryCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると検索入力可能になります。配送、または、現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                              <strong class='text-body'>配送コード </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteDeliveryCode" readonly v-model="onSite.deliveryCode"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 印刷用宛名 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientPrintName">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>印刷用宛名 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="clientPrintName" readonly v-model="onSite.clientPrintNameText"></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                      <!-- チェック -->
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteCheck">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>チェック内容 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteCheck" readonly v-model="suppliers.check"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 現場住所１ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteAddress1">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>住所１</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteAddress1" readonly v-model="onSite.address1"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 現場住所２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteAddress2">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>住所２</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteAddress2" readonly v-model="onSite.address2"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 納品場所 -->
                    <div class="col-sm-12 col-md-6 col-lg-7 col-xl-7 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteDeliveryPlace">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品場所</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteDeliveryPlace" readonly v-model="onSite.deliveryPlace"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 現場TEL -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteTel">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場の電話番号'>
                              <strong class='text-body'>TEL <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteTel" readonly v-model="onSite.tel"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="productList"
                            :fields="fields"
                            :small="true"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 在庫照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="在庫照会モーダルを表示します。" @click="openStockModal()">
                                  <span class="oi oi-eye"></span> 在庫照会
                                </b-button>&nbsp;
                                <!-- 取引先製品単価履歴照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                                  <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(ProductCode)="data">
                              <b-input-group style="width: 80px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.ProductCode" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(ProductName)="data">
                              <b-input-group style="width: 340px;">
                                <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                <b-form-input style="width: 320px;" size="sm" class="px-1" type="text" v-model="data.item.ProductName" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(ServiceClass)="data">
                              <b-input-group style="width: 120px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.ServiceClassText" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <b-input-group style="width: 60px;">
                                <b-form-input size="sm" class="px-1" type="number" v-model="data.item.Quantity" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(Unit)="data">
                              <b-input-group style="width: 40px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.Unit" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(SalesUnitPrice)="data">
                              {{ data.item.SalesUnitPrice.toLocaleString() }}
                            </template>
                            <template #cell(SellingPrice)="data">
                              <b-input-group style="width: 110px;" class="d-flex align-items-center">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.SellingPrice" readonly />
                                &nbsp;
                                <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                              </b-input-group>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <b-input-group style="width: 90px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.PurchasePrime" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(Money)="data">
                              {{ data.item.Money.toLocaleString() }}
                            </template>
                            <template #cell(SupplierCode)="data">
                              <b-input-group style="width: 140px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.SupplierCode" readonly style="width: 60px;" />
                                <b-button class="mr-1" size="sm" pill variant="success" title="発注先コードに入力された仕入先の情報を照会します。" @click="clientInfoModal(htmlConst.ClientClass.supplier, data.item.SupplierCode)" v-if="data.item.SupplierCode != null && data.item.SupplierCode != 0">
                                  <span class="oi oi-eye"></span> 情報
                                </b-button>
                              </b-input-group>
                            </template>
                            <template #cell(ReserveQuantity)="data">
                              {{ data.item.ReserveQuantity }}
                            </template>
                            <template #cell(SupplierName)="data">
                              <div style="min-width:180px;">
                                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                                  <span>
                                    {{data.item.SupplierName}}
                                  </span>
                                </div>
                              </div>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
                <b-row class="justify-content-md-center pb-4">
                  <b-col lg="12">
                    <b-btn-toolbar class="mt-2">
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注をコピーして見積を新規登録します。" @click="clickEstimateCopy" :disabled="statusFlg.allDeletedFlg==true">
                        <span class="oi oi-plus"></span> コピー見積
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の編集を行います。" @click="clickReceivedOrderEdit" :disabled="statusFlg.allDeletedFlg==true||(selectDelivery==htmlConst.ShippingTypeClass.direct&&statusFlg.denpyoFlg==true)">
                        <span class="oi oi-pencil"></span> 受注編集
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の削除を行います。" @click="clearAlert(); clickReceivedOrderDelete();" :disabled="statusFlg.deleteFlg==false||statusFlg.allDeletedFlg==true">
                        <span class="oi oi-delete"></span> 受注削除
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の出荷確定入力を行います。" @click="clickShipInput" v-if="selectDelivery!=htmlConst.ShippingTypeClass.direct&&statusFlg.shukkaFlg==false" :disabled="statusFlg.allDeletedFlg==true||statusFlg.hikiateFlg==false">
                        <span class="oi oi-pencil"></span> 出荷確定入力
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の出荷確定入力の編集を行います。" @click="clickShipEdit" v-if="selectDelivery!=htmlConst.ShippingTypeClass.direct&&statusFlg.shukkaFlg==true" :disabled="statusFlg.allDeletedFlg==true">
                        <span class="oi oi-pencil"></span> 出荷確定修正
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の出荷確定入力の削除を行います。" @click="clearAlert(); clickShipDelete();" v-if="selectDelivery!=htmlConst.ShippingTypeClass.direct&&statusFlg.shukkaFlg==true" :disabled="statusFlg.allDeletedFlg==true||statusFlg.denpyoFlg==true">
                        <span class="oi oi-delete"></span> 出荷確定削除
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の返品在庫計上入力を行います。" @click="clearAlert(); clickBillReturnInput();" v-if="selectBillClass==htmlConst.OrderReceiveBillClass.return" :disabled="statusFlg.allDeletedFlg==true||this.statusFlg.returnKanoFlg==false">
                        <span class="oi oi-delete"></span> 返品在庫計上入力
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の直送売上入力を行います。" @click="clickDirectInput" v-if="selectDelivery==htmlConst.ShippingTypeClass.direct&&statusFlg.denpyoFlg==false" :disabled="statusFlg.allDeletedFlg==true||statusFlg.allOrderFlg==false">
                        <span class="oi oi-pencil"></span> 直送売上入力
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の直送売上入力の編集を行います。" @click="clickDirectEdit" v-if="selectDelivery==htmlConst.ShippingTypeClass.direct&&statusFlg.denpyoFlg==true" :disabled="billingNo==null">
                        <span class="oi oi-pencil"></span> 直送売上修正
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の受注の仮納品書の印刷を行います。" @click="clickTempDeliverySlip" :disabled="statusFlg.denpyoFlg==false">
                        <span class="oi oi-document"></span> 仮納品書入力
                      </b-button>
                    </b-btn-toolbar>
                  </b-col>
                </b-row>
              </validation-observer>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●取引先情報照会モーダル●●● -->
    <CLIENTINFORMATIONINQUIRY @from-child="closeClientInfoInquiryModal" :clientProp="propClientInquiry"/>
    <!-- ●●●現場情報照会モーダル●●● -->
    <SITEINFORMATIONINQUIRY @from-child="closeSiteInfoInquiryModal" :siteProp="propSiteInquiry"/>
    <!-- 在庫照会モーダル -->
    <STOCKINQUIRY :stock-info="stockInfo" />
    <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
    <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import SITEINFORMATIONINQUIRY from '@/components/modal/site-information-inquiry.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CLIENTINFORMATIONINQUIRY from '@/components/modal/client-information-inquiry.vue';
import STOCKINQUIRY from '@/components/modal/stock-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, getControlMaster, addOperationLogs, formatDate, getListValue, getNullStr, getOrderReceiveClientNote, getOrderReceiveSiteNote, executeSelectSql, CreateColRow, CreateUpdateSql, executeTransactSqlList, modStockProductIdListCase, reserveStockProductIdList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, getBillingMonthYear, getClosingDate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_offices, list_m_deliverys_designs_classes, list_m_service_classes } from '@/graphql/queries';

const MODULE_NAME = 'received-order-input-inquiry';

export default {
  name: 'RECEIVED-ORDER-INPUT-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTINQUIRY,
    SITEINFORMATIONINQUIRY,
    CLIENTINFORMATIONINQUIRY,
    STOCKINQUIRY,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '受注入力照会',
      loginId: '',
      listUpdatedSelect: [],
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 受注日付
      dateOrderReceiveDate: '',
      // 受注処理担当者
      orderReceiveStaff: {
        id: '',
        text: '',      
      },
      // 配送プルダウン
      selectDelivery: '',
      selectDeliveryText: '',
      // 伝種プルダウン
      selectBillClass: null,
      selectBillClassText: '',
      // 取引先系
      suppliers: {
        closingDate: '',
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        firstDigit: '',
        taxClass: '',
        taxCalcClass: '',
        taxFracClass: '',
        check: '',
      },
      // 単価登録区分ラジオボタン
      checkUnitPriceRegisterClass: null,
      checkUnitPriceRegisterClassText: '',
      // 単価適用日
      dateUnitPriceEffectiveDate: '',
      // 単価備考
      textUnitPriceNote: '',
      // 登録区分ラジオボタン
      checkRegisterClass: null,
      checkRegisterClassText: '',
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: '',
      selectDeliveryDesignationText: '',
      deliveryDesignation: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList: [],
      // 削除確認ダイアログ用
      dataIndex: 0,
      // 合計情報
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
      },
      // 発注先検索
      orderSearchInfo: {
        office_id: '',
        product_id: '',
      },
      // 在庫照会情報
      stockInfo: {
        office_id: '',
        product_id: [],
      },
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先の受注ノートの有無
      isClientNoteInput: false,
      // 現場情報照会に渡すパラメータ
      propSiteInquiry: {
        clientId: null,
        siteId: null,
      },
      // 現場の受注ノートの有無
      isSiteNoteInput: false,
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 配送種別区分
        ShippingTypeClass: {
          // 通常配送
          normal: Const.ShippingTypeClass.normal,
          // 宅配
          delivery: Const.ShippingTypeClass.delivery,
          // 営業持参
          bring: Const.ShippingTypeClass.bring,
          // 直送
          direct: Const.ShippingTypeClass.direct,
        },
        // 伝種（受注伝票種別区分）プルダウン
        OrderReceiveBillClass: {
          normal: Const.OrderReceiveBillClass.normal,
          return: Const.OrderReceiveBillClass.return,
          special: Const.OrderReceiveBillClass.special,
        },
        // 諸口区分
        SundriesClass: {
          normal: 0,
          shokuchi: 1,
        },
        // 単価登録区分
        UnitPriceRegisterClass: {
          // 登録しない
          insertNo: Const.UnitPriceRegisterClass.insertNo,
          // 自身のみ登録
          insertMine: Const.UnitPriceRegisterClass.insertMine,
          // 親子全て登録
          insertAll: Const.UnitPriceRegisterClass.insertAll,
        },
        // 受注データの発注状況
        ReceivedOrderOrderJokyo: {
          // 在庫不要
          noStock: Const.ReceivedOrderOrderJokyo.noStock,
          // 在庫管理
          stock: Const.ReceivedOrderOrderJokyo.stock,
          // 発注未
          orderMi: Const.ReceivedOrderOrderJokyo.orderMi,
          // 発注済
          orderZumi: Const.ReceivedOrderOrderJokyo.orderZumi,
          // 入荷済
          nyukaZumi: Const.ReceivedOrderOrderJokyo.nyukaZumi,
        },
        // 出荷確定区分
        ShipsClass: {
          // 未
          mi: Const.ShipsClass.mi,
          // 済
          sumi: Const.ShipsClass.sumi,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 入力日
      inputDate: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // ステータスフラグ
      statusFlg: {
        zanFlg: false,
        hikiateFlg: false,
        orderFlg: false,
        allOrderFlg: true,
        nyukaFlg: false,
        shukkaFlg: false,
        denpyoFlg: false,
        returnKanoFlg: false,
        returnFlg: false,
        deleteFlg: true,
        allDeletedFlg: true,
      },
      // テーブル構成
      fields: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 在庫増加製品
      stockProductId: [],
      // 直送売上修正画面遷移用のパラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
      // パラメータ
      receivedOrderId: 0,
      parentKbn: 0,
      reserveErrFlg: 0,
      errCsvProductId: '',
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 配送種別と伝種毎の伝票の意味のテキスト */
    getBillingClassText: function() {
      if ((this.selectDelivery == Const.ShippingTypeClass.direct && (this.selectBillClass == Const.OrderReceiveBillClass.return || this.selectBillClass == Const.OrderReceiveBillClass.special)) ||
      this.selectDelivery == '' || this.selectBillClass == null) {
        return '';
      } else if (this.selectDelivery == Const.ShippingTypeClass.delivery && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[宅配]';
      } else if (this.selectDelivery == Const.ShippingTypeClass.bring && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[営業持参]';
      } else if (this.selectDelivery == Const.ShippingTypeClass.direct && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[直送]';
      } else if (this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[通常配送]';
      } else if (this.selectBillClass == Const.OrderReceiveBillClass.return) {
        return '[返品]';
      } else {
        return '[特別発注(受注)]';
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    this.parentKbn = this.$route.query.parentKbn;
    this.reserveErrFlg = this.$route.query.reserveErrFlg;
    this.errCsvProductId = this.$route.query.errCsvProductId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('time1');
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let officeListResult = null;
        let deliveryListResult = null;
        let serviceListResult = null;
        let controlData = null;
        [officeListResult, deliveryListResult, serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_offices)),
          API.graphql(graphqlOperation(list_m_deliverys_designs_classes)),
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
        ]);
        //console.timeEnd('time1');
        //console.time('time3');
        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 納品指定区分を取得
        let deliveryListData = deliveryListResult.data.list_m_deliverys_designs_classes;
        //console.log(deliveryListData);
        this.deliveryDesignation.push({id: '', name: '', text: ''});
        for(let i = 0; i < deliveryListData.length; i++){
          let deliveryDesignation = {
            id: deliveryListData[i].delivery_design_class,
            name: deliveryListData[i].delivery_design_name,
            text: deliveryListData[i].delivery_design_class + '：' + deliveryListData[i].delivery_design_name,
          };
          this.deliveryDesignation.push(deliveryDesignation);
        }
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        //console.timeEnd('time3');
        //console.time('time2');
        // 既存データ設定
        await this.setReceivedOrder();
        //console.timeEnd('time2');
        // パラメータによってメッセージを表示
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        if (this.reserveErrFlg == 1) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2021'].replace('%arg1%', this.errCsvProductId));
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 受注データ設定 */
    async setReceivedOrder() {
      // 最初に初期化
      this.productList = [];
      this.statusFlg.zanFlg = false;
      this.statusFlg.hikiateFlg = false;
      this.statusFlg.orderFlg = false;
      this.statusFlg.allOrderFlg = true;
      this.statusFlg.nyukaFlg = false;
      this.statusFlg.shukkaFlg = false;
      this.statusFlg.denpyoFlg = false;
      this.statusFlg.returnKanoFlg = false;
      this.statusFlg.returnFlg = false;
      this.statusFlg.deleteFlg = true;
      this.statusFlg.allDeletedFlg = true;
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
        if (this.statusFlg.allDeletedFlg == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成 */
    makeSelectSql: function() {
      let selectSql = '';
      // 2つのSQLのUNION結果を取得する
      // ・1：配送種別区分が「4：直送」以外（出荷データや入荷データと外部結合して進捗状況を取得）
      // ・2：配送種別区分が「4：直送」（累積トランザクションと外部結合して進捗状況を取得）
      for (let i = 0; i < 2; i++) {
        if (i != 0) {
          selectSql += ' UNION ALL ';
        }
        /* SELECT句 */
        selectSql += 'SELECT ';
        selectSql += ' orders_receives.order_receive_id';
        selectSql += ',orders_receives.order_receive_row';
        selectSql += ',orders_receives.office_id';
        selectSql += ',orders_receives.order_receive_date';
        selectSql += ',orders_receives.order_receive_staff_id';
        selectSql += ',order_receive_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
        selectSql += ',orders_receives.shipping_type_class';
        selectSql += ',orders_receives.order_receive_bill_class';
        selectSql += ',orders_receives.unit_price_register_class';
        selectSql += ',orders_receives.unit_price_effective_date';
        selectSql += ',orders_receives.unit_price_note';
        selectSql += ',orders_receives.client_class';
        selectSql += ',orders_receives.client_id';
        selectSql += ',orders_receives.client_name AS client_name_kanji';
        selectSql += ',clients.closing_date';
        selectSql += ',orders_receives.zip_code';
        selectSql += ',orders_receives.sundries_class';
        selectSql += ',orders_receives.client_id_first_digit';
        selectSql += ',orders_receives.sales_tax_class';
        selectSql += ',orders_receives.tax_calculation_class';
        selectSql += ',orders_receives.tax_fractionation_class';
        selectSql += ',orders_receives.address_1';
        selectSql += ',orders_receives.address_2';
        selectSql += ',orders_receives.department_1';
        selectSql += ',orders_receives.department_2';
        selectSql += ',orders_receives.phone_number';
        selectSql += ',orders_receives.staff_id';
        selectSql += ',staffs.staff_name_kanji';
        selectSql += ',clients.check_contents';
        selectSql += ',orders_receives.delivery_design_date';
        selectSql += ',orders_receives.delivery_design_class';
        selectSql += ',orders_receives.delivery_design';
        selectSql += ',orders_receives.site_id';
        selectSql += ',orders_receives.shipping_code';
        selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
        selectSql += ',orders_receives.client_site_zip_code';
        selectSql += ',orders_receives.client_site_address_1';
        selectSql += ',orders_receives.client_site_address_2';
        selectSql += ',orders_receives.delivery_location';
        selectSql += ',orders_receives.client_site_phone_number';
        selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
        selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
        selectSql += ',orders_receives.product_id';
        selectSql += ',orders_receives.product_name AS product_name_kanji';
        selectSql += ',orders_receives.service_class';
        selectSql += ',orders_receives.product_class_id';
        selectSql += ',orders_receives.order_receive_quantity';
        selectSql += ',orders_receives.unit';
        selectSql += ',products.sales_unit_price';
        selectSql += ',orders_receives.order_receive_unit_amount';
        selectSql += ',orders_receives.client_amount_class';
        selectSql += ',orders_receives.order_unit_amount';
        selectSql += ',products.product_check_class';
        selectSql += ',orders_receives.product_manual_input_class';
        selectSql += ',orders_receives.inventory_control_class';
        selectSql += ',orders_receives.place_1';
        selectSql += ',orders_receives.place_2';
        selectSql += ',orders_receives.place_3';
        selectSql += ',orders_receives.place_4';
        selectSql += ',orders_receives.case_conversion_class';
        selectSql += ',orders_receives.set_class AS set_flg';
        selectSql += ',orders_receives.remaining_quantity';
        selectSql += ',orders_receives.reserve_quantity_incomplete';
        selectSql += ',orders_receives.reserve_quantity';
        selectSql += ',orders_receives.stock_scheduled_reserve_receive';
        if (i == 0) {
          selectSql += ',SUM(IfNull(ships.shipping_quantity,0)) AS shipping_quantity';
          selectSql += ',orders_receives.shipping_quantity AS shipping_quantity_receives';
        } else {
          selectSql += ',orders_receives.shipping_quantity';
          selectSql += ',orders_receives.shipping_quantity AS shipping_quantity_receives';
        }
        selectSql += ',orders_receives.order_id';
        selectSql += ',orders_receives.order_row';
        selectSql += ',orders_receives.order_product_id';
        selectSql += ',orders_receives.is_deleted';
        selectSql += ',products_orders.client_id AS supplier_id';
        selectSql += ',suppliers.client_name_kanji AS supplier_name_kanji';
        selectSql += ',orders_receives.change_order_client_id';
        selectSql += ',change_order_client.client_name_kanji AS change_order_client_name_kanji';
        selectSql += ',IfNull(orders.client_id,0) AS order_supplier_id';
        selectSql += ',order_suppliers.client_name_kanji AS order_supplier_name_kanji';
        selectSql += ',IfNull(orders.order_quantity,0) AS order_quantity';
        selectSql += ',orders.stock_scheduled';
        selectSql += ',CASE WHEN orders.stock_scheduled_confirm_class = 1 THEN \'*\' ELSE \'\' END AS stock_scheduled_confirm_class';
        selectSql += ',orders.stock_scheduled_staff_id';
        selectSql += ',order_staffs.staff_name_kanji AS stock_scheduled_staff_name';
        selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
        if (i == 0) {
          selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_class';
          selectSql += ',CASE WHEN ships.order_receive_id IS NULL THEN 0 ELSE 1 END AS ships_class';
          selectSql += ',CASE WHEN MAX(ships.sales_issue_class) > 0 THEN 1 ELSE 0 END AS sales_issue_class';
          selectSql += ',NULL AS billing_no';
          selectSql += ',NULL AS monthly_month_year';
        } else {
          selectSql += ',orders_receives.is_bill_return_print AS stocks_class';
          selectSql += ',orders_receives.is_bill_return_print AS ships_class';
          selectSql += ',orders_receives.is_bill_return_print AS sales_issue_class';
          selectSql += ',transaction.billing_no';
          selectSql += ',transaction.monthly_month_year';
        }
        selectSql += ',orders_receives.input_date';
        selectSql += ',orders_receives.input_staff_id';
        selectSql += ',orders_receives.group_register_class';
        selectSql += ',products_orders.minimum_quantity';
        selectSql += ',products.product_tax_rate_class_sales';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'INNER JOIN m_clients AS clients USING(client_class,client_id)';
        selectSql += 'LEFT JOIN m_staffs AS order_receive_staffs ';
        selectSql += 'ON orders_receives.order_receive_staff_id = order_receive_staffs.staff_id ';
        selectSql += 'LEFT JOIN m_staffs AS staffs ';
        selectSql += 'ON orders_receives.staff_id = staffs.staff_id ';
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON clients_sites.client_id = orders_receives.client_id ';
        selectSql += 'AND clients_sites.site_id = orders_receives.site_id ';
        selectSql += 'INNER JOIN m_products AS products ';
        selectSql += 'ON orders_receives.product_id = products.product_id ';
        selectSql += 'LEFT JOIN m_products_orders AS products_orders ';
        selectSql += 'ON orders_receives.product_id = products_orders.product_id ';
        selectSql += 'AND orders_receives.office_id = products_orders.office_id ';
        selectSql += 'LEFT JOIN m_clients AS suppliers ';
        selectSql += 'ON suppliers.client_class = 2 ';
        selectSql += 'AND products_orders.client_id = suppliers.client_id ';
        selectSql += 'LEFT JOIN m_clients AS change_order_client ';
        selectSql += 'ON change_order_client.client_class = 2 ';
        selectSql += 'AND orders_receives.change_order_client_id = change_order_client.client_id ';
        selectSql += 'LEFT JOIN t_orders AS orders ';
        selectSql += 'ON orders_receives.order_id = orders.order_id ';
        selectSql += 'AND orders_receives.order_row = orders.order_row ';
        selectSql += 'LEFT JOIN m_clients AS order_suppliers ';
        selectSql += 'ON orders.client_class = order_suppliers.client_class ';
        selectSql += 'AND orders.client_id = order_suppliers.client_id ';
        selectSql += 'LEFT JOIN m_staffs AS order_staffs ';
        selectSql += 'ON orders.stock_scheduled_staff_id = order_staffs.staff_id ';
        if (i == 0) {
          selectSql += 'LEFT JOIN t_ships AS ships ';
          selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
          selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
          selectSql += 'LEFT JOIN (SELECT order_id,order_row FROM t_stocks GROUP BY order_id,order_row) AS stocks ';
          selectSql += 'ON orders_receives.order_id = stocks.order_id ';
          selectSql += 'AND orders_receives.order_row = stocks.order_row ';
        } else {
          selectSql += 'LEFT JOIN t_cumulative_transaction AS transaction ';
          selectSql += 'ON orders_receives.order_receive_id = transaction.order_receive_id ';
          selectSql += 'AND transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
        }
        /* WHERE句 */
        selectSql += ' WHERE ';
        // 引数の受注番号で絞り込む
        selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        if (i == 0) {
          // 配送種別（直送は除外）
          selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
          /* GROUP BY句 */
          selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
        } else {
          // 配送種別（直送）
          selectSql += 'AND orders_receives.shipping_type_class = \'' + Const.ShippingTypeClass.direct + '\' ';
          /* GROUP BY句 */
          selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
        }
      }
      /* ORDER BY句 */
      selectSql += 'ORDER BY order_receive_id,order_receive_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 入力日
        this.inputDate = result[0].input_date;
        // 受注日付
        this.dateOrderReceiveDate = formatDate(result[0].order_receive_date);
        // 受注処理担当者
        this.orderReceiveStaff.id = result[0].order_receive_staff_id;
        this.orderReceiveStaff.text = result[0].order_receive_staff_id + '：' + result[0].order_receive_staff_name_kanji;
        // 配送種別
        this.selectDelivery = result[0].shipping_type_class;
        this.selectDeliveryText = getListValue(Const.DeliveryList, result[0].shipping_type_class, 'id', 'text');
        this.fields = this.getFields();
        // 伝種
        this.selectBillClass = result[0].order_receive_bill_class;
        this.selectBillClassText = getListValue(Const.OrderReceiveBillClassList, result[0].order_receive_bill_class, 'id', 'text');
        // 単価登録区分ラジオボタン
        this.checkUnitPriceRegisterClass = result[0].unit_price_register_class;
        this.checkUnitPriceRegisterClassText = getListValue(Const.UnitPriceRegisterClassList, result[0].unit_price_register_class, 'id', 'text');
        // 単価適用日
        if(this.dateUnitPriceEffectiveDate == null) {
          this.dateUnitPriceEffectiveDate = '';
        } else {
          this.dateUnitPriceEffectiveDate = formatDate(result[0].unit_price_effective_date);
        }
        // 単価備考
        this.textUnitPriceNote = result[0].unit_price_note;
        // 登録区分ラジオボタン
        this.checkRegisterClass = result[0].group_register_class;
        this.checkRegisterClassText = getListValue(Const.ClientsProductsMasterRegisterClassList, result[0].group_register_class, 'id', 'text');
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.setClosingYmd(result[0].closing_date);
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.firstDigit = result[0].client_id_first_digit;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalcClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        this.suppliers.check = result[0].check_contents;
        // 現場系
        this.onSite.deliveryCode = result[0].shipping_code;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        this.onSite.zipCode = result[0].client_site_zip_code;
        this.onSite.address1 = result[0].client_site_address_1;
        this.onSite.address2 = result[0].client_site_address_2;
        this.onSite.deliveryPlace = result[0].delivery_location;
        this.onSite.tel = result[0].client_site_phone_number;
        this.onSite.clientPrintClass = Number(result[0].order_print_class);
        this.onSite.clientPrintName = result[0].order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : this.onSite.clientPrintName;

        // DBからまとめて取得
        let clientNote = null;
        let siteNote = null;

        [clientNote, siteNote] = await Promise.all([
          getOrderReceiveClientNote(this.suppliers.code),
          getOrderReceiveSiteNote(this.suppliers.code, this.onSite.code),
        ]);

        if (getNullStr(clientNote) != '' ) {
          this.isClientNoteInput = true;
        } else {
          this.isClientNoteInput = false;
        }
        if (getNullStr(siteNote) != '' ) {
          this.isSiteNoteInput = true;
        } else {
          this.isSiteNoteInput = false;
        }

        // 納品指定日
        this.orderDate = result[0].delivery_design_date==null ? '' : formatDate(result[0].delivery_design_date);
        // 納品指定区分
        this.selectDeliveryDesignation = result[0].delivery_design_class;
        this.selectDeliveryDesignationText = getListValue(this.deliveryDesignation, this.selectDeliveryDesignation, 'id', 'text');
        // 納品メモ
        this.deliveryNote = result[0].delivery_design;
        // 直送売上修正画面遷移用のパラメータ
        this.billingNo = result[0].billing_no;
        this.monthlyMonthYear = result[0].monthly_month_year;
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          // 削除された製品は飛ばす、表示しない
          if (result[i].is_deleted == 1) continue

          this.addTableRow();

          let productIndex = this.productList.length - 1
          this.productList[productIndex].ProductCode = result[i].product_id;
          this.productList[productIndex].ProductClass = result[i].product_class_id;
          this.productList[productIndex].ProductName = result[i].product_name_kanji;
          this.productList[productIndex]._cellVariants = { ProductName: getListValue(Const.ProductCheckClass, result[i].product_check_class, 'value', 'color') };
          this.productList[productIndex].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[productIndex].ServiceClass = result[i].service_class;
          this.productList[productIndex].ServiceClassText = getListValue(this.serviceList, result[i].service_class, 'id', 'text');
          this.productList[productIndex].Quantity = result[i].order_receive_quantity;
          this.productList[productIndex].Unit = result[i].unit;
          this.productList[productIndex].SalesUnitPrice = result[i].sales_unit_price;
          this.productList[productIndex].SellingPrice = result[i].order_receive_unit_amount;
          this.productList[productIndex].ClientAmountClass = result[i].client_amount_class;
          this.productList[productIndex].PurchasePrime = result[i].order_unit_amount;
          this.productList[productIndex].InventoryClass = result[i].inventory_control_class;
          this.productList[productIndex].Place1 = result[i].place_1;
          this.productList[productIndex].Place2 = result[i].place_2;
          this.productList[productIndex].Place3 = result[i].place_3;
          this.productList[productIndex].Place4 = result[i].place_4;
          this.productList[productIndex].CaseConversion = result[i].case_conversion_class;
          this.productList[productIndex].SetFlg = result[i].set_flg;
          this.productList[productIndex].RemainingQuantity = result[i].remaining_quantity;
          if (this.selectDelivery == Const.ShippingTypeClass.direct || this.selectBillClass == Const.OrderReceiveBillClass.special) {
            // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
            // 入荷予定引当数受注を引当数として見る
            this.productList[productIndex].ReserveQuantity = result[i].stock_scheduled_reserve_receive - result[i].shipping_quantity_receives;
          } else {
            // 上記以外の場合
            // 引当済数を引当数として見る
            this.productList[productIndex].ReserveQuantity = result[i].reserve_quantity;
          }
          this.productList[productIndex].ShippingQuantity = result[i].shipping_quantity;
          if (result[productIndex].order_supplier_id != 0) {
            this.productList[productIndex].SupplierCode = result[i].order_supplier_id;
            this.productList[productIndex].SupplierName = result[i].order_supplier_name_kanji;
            this.productList[productIndex].SupplierCodeRight = result[i].supplier_id;
          } else if (result[productIndex].change_order_client_id != null) {
            this.productList[productIndex].SupplierCode = result[i].change_order_client_id;
            this.productList[productIndex].SupplierName = result[i].change_order_client_name_kanji;
            this.productList[productIndex].SupplierCodeRight = result[i].supplier_id;
          } else if (result[productIndex].supplier_id != null) {
            this.productList[productIndex].SupplierCode = result[i].supplier_id;
            this.productList[productIndex].SupplierName = result[i].supplier_name_kanji;
            this.productList[productIndex].SupplierCodeRight = result[i].supplier_id;
          } else {
            this.productList[productIndex].SupplierCode = null;
            this.productList[productIndex].SupplierName = '製品発注マスタ未登録';
            this.productList[productIndex].SupplierCodeRight = null;
          }
          this.productList[productIndex].OrderQuantity = result[i].order_quantity;
          let productStockScheduled = result[i].stock_scheduled == null ? '0000/00/00' : formatDate(result[i].stock_scheduled);
          if (result[i].stock_scheduled_confirm_class != '') {
            productStockScheduled += ' ' + result[i].stock_scheduled_confirm_class;
          }
          this.productList[productIndex].StockScheduled = productStockScheduled;
          let productStockScheduledStaff = '';
          if (result[i].stock_scheduled_staff_id != null && result[i].stock_scheduled_staff_id != '') {
            productStockScheduledStaff = result[i].stock_scheduled_staff_id;
            if (result[i].stock_scheduled_staff_name != null && result[i].stock_scheduled_staff_name != '') {
              productStockScheduledStaff += ' ' + result[i].stock_scheduled_staff_name;
            }
          }
          this.productList[productIndex].StockScheduledStaff = productStockScheduledStaff;
          this.productList[productIndex].IsDeleted = result[i].is_deleted;
          if (this.productList[productIndex].IsDeleted == 0) {
            this.statusFlg.allDeletedFlg = false;
          }
          this.productList[productIndex].OrderId = result[i].order_id;
          this.productList[productIndex].OrderRow = result[i].order_row;
          this.productList[productIndex].OrderJokyo = this.getOrderJokyo(this.selectDelivery, this.selectBillClass, this.productList[productIndex].InventoryClass, result[i].order_issue_class, result[i].stocks_class);
          this.productList[productIndex].OrderJokyoText = getListValue(Const.ReceivedOrderOrderJokyoList, this.productList[productIndex].OrderJokyo);
          this.productList[productIndex].ShipsClass = result[i].ships_class;
          this.productList[productIndex].ShipsClassText = getListValue(Const.ShipsClassList, result[i].ships_class);
          if (result[i].sales_issue_class == Const.SalesIssueClass.sumi) {
            if ((result[i].order_receive_quantity >= 0 &&
              result[i].order_receive_quantity > Number(result[i].shipping_quantity_receives)) ||
              (result[i].order_receive_quantity < 0 &&
              result[i].order_receive_quantity < Number(result[i].shipping_quantity_receives))) {
              // 伝票発行済み、且つ、伝票発行数が受注数に達していない場合（一部済）
              this.productList[productIndex].SalesIssueClass = Const.SalesIssueClass.middle;
              this.productList[productIndex].SalesIssueClassText = getListValue(Const.SalesIssueClassList, Const.SalesIssueClass.middle);              
            } else {
              // 伝票発行済み、且つ、伝票発行数が受注数に達している場合（済）
              this.productList[productIndex].SalesIssueClass = result[i].sales_issue_class;
              this.productList[productIndex].SalesIssueClassText = getListValue(Const.SalesIssueClassList, result[i].sales_issue_class);
            }
          } else {
            // 伝票発行「未」
            this.productList[productIndex].SalesIssueClass = result[i].sales_issue_class;
            this.productList[productIndex].SalesIssueClassText = getListValue(Const.SalesIssueClassList, result[i].sales_issue_class);
          }
          this.productList[productIndex].TotalPurchasePrime = this.productList[productIndex].Quantity * this.productList[productIndex].PurchasePrime;
          this.productList[productIndex].Money = this.productList[productIndex].Quantity * this.productList[productIndex].SellingPrice;
          // 取得した製品数によってステータス判定
          // 受注残のある製品の有無
          if (result[i].remaining_quantity != 0) {
            this.statusFlg.zanFlg = true;
          }
          // 入荷のある製品の有無
          if (result[i].stocks_class == Const.StocksClass.sumi) {
            this.statusFlg.nyukaFlg = true;
          }
          // 引当のある製品の有無
          if (this.productList[productIndex].ReserveQuantity != 0) {
            this.statusFlg.hikiateFlg = true;
          }
          // 出荷のある製品の有無
          if (result[i].ships_class == Const.ShipsClass.sumi) {
            this.statusFlg.shukkaFlg = true;
          }
          // 伝票発行済みの製品の有無
          if (result[i].sales_issue_class == Const.SalesIssueClass.sumi) {
            this.statusFlg.denpyoFlg = true;
            if (this.selectBillClass == Const.OrderReceiveBillClass.return) {
              // 直送、または、返品で伝票発行済みの場合は削除不可とする。
              this.statusFlg.deleteFlg = false;
            }
          }
          // ケース換算製品の設定
          if (this.productList[productIndex].CaseConversion == Const.CaseConversionClassDef.conversion &&
          this.productList[productIndex].SetFlg == Const.SetClass.set) {
            // ケース換算区分「0:する」、且つ、セット品区分「1:セット品」
            let selectSql = '';
            selectSql = this.makeSelectSqlSet((i + 1).toString());
            //console.log(selectSql);
            //console.log('条件取得');
            let setResult = await executeSelectSql(selectSql);
            //console.log(setResult);
            if (setResult != null) {
              let setOrderJokyo = 0;
              let setOrderJokyoMax = 0;
              for (let j = 0; j < setResult.length; j++) {
                // 直送、または、特別受注の場合のみ
                if (this.selectDelivery == Const.ShippingTypeClass.direct ||
                this.selectBillClass == Const.OrderReceiveBillClass.special) {
                  setOrderJokyo = this.getOrderJokyo(this.selectDelivery, this.selectBillClass, setResult[j].inventory_control_class, setResult[j].order_issue_class, setResult[j].stocks_class);
                  if (setOrderJokyo > setOrderJokyoMax) {
                    setOrderJokyoMax = setOrderJokyo;
                  }
                }
              }
              if (setOrderJokyoMax > this.productList[productIndex].OrderJokyo) {
                this.productList[productIndex].OrderJokyo = setOrderJokyoMax;
                this.productList[productIndex].OrderJokyoText = getListValue(Const.ReceivedOrderOrderJokyoList, this.productList[productIndex].OrderJokyo);
              }
            }
          }
          if (this.productList[productIndex].OrderJokyo >= Const.ReceivedOrderOrderJokyo.orderZumi) {
            this.statusFlg.orderFlg = true;
          } else {
            if (this.productList[productIndex].OrderJokyo != Const.ReceivedOrderOrderJokyo.noStock) {
              // 在庫管理する製品で発注書発行されていない製品が１でもある場合
              this.statusFlg.allOrderFlg = false;
            }
          }
          // 粗利額を確認して、警告の場合、背景色変更
          this.checkGrossProfit(this.productList[productIndex], productIndex);
        }
        this.calcTotal();
        if (this.selectBillClass == Const.OrderReceiveBillClass.return) {
          // 返品の場合のみ
          // 返品在庫計上フラグ
          let selectSql = 'SELECT';
          selectSql += ' SUM(remaining_quantity) AS remaining_quantity';
          selectSql += ',MAX(is_bill_return_count) AS is_bill_return_count';
          selectSql += ' FROM ';
          selectSql += 't_orders_receives';
          selectSql += ' WHERE ';
          selectSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
          let returnResult = await executeSelectSql(selectSql);
          //console.log(returnResult);	

          if (returnResult != null && returnResult.length > 0) {
            if (returnResult[0].is_bill_return_count == 1) {
              this.statusFlg.returnFlg = true;
            }
            if (returnResult[0].remaining_quantity == 0 &&
              this.statusFlg.returnFlg == false) {
              this.statusFlg.returnKanoFlg = true;
            }
          }
        }
      }
    },
    /* 検索SQL作成（セット品） */
    makeSelectSqlSet: function(orderReceiveRow) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_received_set.product_id';
      selectSql += ',orders_received_set.inventory_control_class';
      selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_received_set AS orders_received_set ';
      selectSql += 'LEFT JOIN t_orders AS orders ';
      selectSql += 'ON orders_received_set.order_id = orders.order_id ';
      selectSql += 'AND orders_received_set.order_row = orders.order_row ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders_received_set.order_id = stocks.order_id ';
      selectSql += 'AND orders_received_set.order_row = stocks.order_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_received_set.order_receive_id = ' + this.receivedOrderId + ' ';
      selectSql += 'AND orders_received_set.order_receive_row = ' + orderReceiveRow + ' ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_received_set.order_receive_row_branch ';

      return selectSql;
    },
    // キャンセルボタン
    cancelReceivedOrderInput:function() {
      this.$router.push({ name: 'RECEIVED-ORDER-LIST'});
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 取引先情報照会 */
    clientInfoInquiryModal: function(clientClass, clientId) {
      //console.log('取引先情報照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInformationInquiryModal');
    },
    // 取引先情報照会モーダルを閉じた時
    closeClientInfoInquiryModal: function(item) {
      // モーダルから渡された値の有無チェック
      if (typeof item != 'undefined') {
        this.isClientNoteInput = item.isNoteInput;
      }
    },
    /* 現場情報照会 */
    siteInfoInquiryModal: function(clientId, siteId) {
      //console.log('現場情報照会');
      this.propSiteInquiry.clientId = clientId;
      this.propSiteInquiry.siteId = siteId;
      this.$bvModal.show('siteInformationInquiryModal');
    },
    // 現場情報照会モーダルを閉じた時
    closeSiteInfoInquiryModal: function(item) {
      // モーダルから渡された値の有無チェック
      if (typeof item != 'undefined') {
        this.isSiteNoteInput = item.isNoteInput;
      }
    },
    // 在庫照会モーダルを開く
    openStockModal: function() {
      //console.log('在庫照会モーダル');
      //console.log(this.$bvModal);
      this.stockInfo.office_id = this.selectSalesOffice.toString();
      this.stockInfo.product_id = [];
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].ProductCode != null) {
          this.stockInfo.product_id.push(this.productList[i].ProductCode.toString());
        }
      }
      this.$bvModal.show('stockModal');
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      //console.log('取引先製品単価履歴照会モーダル');

      let productIdCsv = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (productIdCsv != '') {
          productIdCsv += ',';
        }
        // 製品コード
        productIdCsv += this.productList[i].ProductCode;
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.suppliers.code;
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 合計金額、合計仕入単価、粗利
    calcTotal: function() {
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      for (let i = 0; i < this.productList.length;i++) {
        this.totalFee += this.productList[i].Money;
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
      }
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        SupplierCode: null,
        SupplierCodeRight: null,
        SupplierName: '',
        OrderQuantity: 0,
        StockScheduled: '0000/00/00',
        StockScheduledStaff: '',
        ServiceClass: '',
        ServiceClassText: '',
        Quantity: 0,
        Unit: '',
        SalesUnitPrice: 0,
        SellingPrice: 0,
        ClientAmountClass: '',
        PurchasePrime: 0,
        InventoryClass: null,
        Place1: '',
        Place2: '',
        Place3: '',
        Place4: '',
        CaseConversion: '',
        TotalPurchasePrime: 0,
        Money: 0,
        SetFlg: 0,
        ReserveQuantity: 0,
        RemainingQuantity: 0,
        IsDeleted: 0,
        OrderId: 0,
        OrderRow: 0,
        OrderJokyo: Const.ReceivedOrderOrderJokyo.noStock,
        OrderJokyoText: '',
        ShipsClass: Const.ShipsClass.mi,
        ShipsClassText: '',
        SalesIssueClass: Const.SalesIssueClass.mi,
        SalesIssueClassText: '',
      };
      this.productList.push(newProduct);
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum, 1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* コピー見積新規登録ボタン押下時 */
    async clickEstimateCopy() {
      //console.log('コピー見積');
      // 見積入力画面へ遷移
      this.$router.push({ name: 'ESTIMATE-INPUT', query: { propEstimateId: '0', propReceivedOrderId: this.receivedOrderId } });
    },
    /* 受注編集ボタン押下時 */
    async clickReceivedOrderEdit() {
      //console.log('受注編集');
      // 受注修正画面へ遷移
      this.$router.push({ name: 'RECEIVED-ORDER-EDIT', query: { receivedOrderId: this.receivedOrderId } });
    },
    /* 受注削除ボタン押下時 */
    async clickReceivedOrderDelete() {
      //console.log('受注削除');
      this.dataIndex = 0;
      this.confirmMessage = [];
      this.confirmMessage.push('照会中の受注データを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 受注削除処理 */
    async execReceivedOrderDelete() {
      const functionName = 'execReceivedOrderDelete';
      // 実行するSQL保持
      let sqlList = [];
      let updateSql = '';
      let colList = [];
      /* 在庫マスタの更新 */
      // 受注に引き当てた数を在庫に戻す
      this.updateStocksMaster(sqlList);
      // 直送、または、特別受注の場合のみ
      if (this.selectDelivery == Const.ShippingTypeClass.direct ||
      this.selectBillClass == Const.OrderReceiveBillClass.special) {
        if (this.selectDelivery != Const.ShippingTypeClass.direct) {
          // 直送以外
          /* 入荷データ削除 */
          let deleteSql = 'DELETE FROM t_stocks';
          deleteSql += ' WHERE ';
          deleteSql += 'stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
          deleteSql += 'AND order_id IN (SELECT orders.order_id FROM t_orders AS orders WHERE orders.order_receive_id = ' + this.receivedOrderId + ') ';
          //console.log(deleteSql);
          sqlList.push(deleteSql);
        }
        /* 発注データ更新 */
        // 削除フラグを立てる
        updateSql = '';
        colList = [];
        // 削除済フラグ
        colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 't_orders');
        updateSql += ' WHERE ';
        updateSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
        updateSql += 'AND is_deleted = 0 ';
        //console.log(updateSql);
        sqlList.push(updateSql);
      }
      if (this.selectDelivery != Const.ShippingTypeClass.direct) {
        // 直送以外
        /* 出荷データ削除 */
        this.deleteShips(sqlList);
      }
      /* 受注データ更新 */
      // 削除フラグを立てる
      updateSql = '';
      colList = [];
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
      updateSql += 'AND is_deleted = 0 ';
      //console.log(updateSql);
      sqlList.push(updateSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        // 受注一覧画面へ遷移
        this.$router.push({ name: 'RECEIVED-ORDER-LIST', params: { parentKbn: 1 } });
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function(sqlList) {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      if (this.selectBillClass == Const.OrderReceiveBillClass.special) {
        // 発注書発行済みの「特別受注」の場合
        updateSql = '';
        colList = [];
        // 紐づく発注データ削除による在庫マスタ更新（入荷前情報を反映）
        // 入荷予定数
        colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock - orders_QUERY.remaining_quantity', 'NUMBER'));
        // 入荷予定引当数
        colList.push(CreateColRow('stocks.quantity_stock_reserve', 'stocks.quantity_stock_reserve - orders_QUERY.remaining_quantity', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' orders.office_id';
        updateQuery += ',orders.product_id';
        updateQuery += ',SUM(orders.remaining_quantity) AS remaining_quantity';
        updateQuery += ' FROM ';
        updateQuery += 't_orders AS orders ';
        updateQuery += ' WHERE ';
        updateQuery += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
        updateQuery += 'AND orders.order_issue_class > 0 ';
        updateQuery += 'AND orders.is_deleted = 0 ';
        updateQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
        updateQuery += 'GROUP BY orders.office_id,orders.product_id ';
        updateQuery += ') AS orders_QUERY';
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
        updateSql += 'AND stocks.office_id = orders_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = orders_QUERY.product_id ';
        //console.log(updateSql);
        sqlList.push(updateSql);
        // 受注データの削除による在庫マスタ更新（入荷後、且つ、売上伝票発行前情報を反映）
        updateSql = '';
        colList = [];
        // 在庫引当数
        colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - orders_receives_QUERY.balance', 'NUMBER'));
        // 残高数
        colList.push(CreateColRow('stocks.balance', 'stocks.balance + orders_receives_QUERY.balance', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' orders_receives.office_id';
        updateQuery += ',orders_receives.product_id';
        updateQuery += ',SUM(orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity) AS balance';
        updateQuery += ' FROM ';
        updateQuery += 't_orders_receives AS orders_receives ';
        updateQuery += ' WHERE ';
        updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        updateQuery += 'AND orders_receives.is_deleted = 0 ';
        updateQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
        updateQuery += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
        updateQuery += 'GROUP BY orders_receives.office_id,orders_receives.product_id ';
        updateQuery += ') AS orders_receives_QUERY';
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
        updateSql += 'AND stocks.office_id = orders_receives_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = orders_receives_QUERY.product_id ';
        //console.log(updateSql);
        sqlList.push(updateSql);
        // 受注セット品データの削除による在庫マスタ更新（入荷後、且つ、売上伝票発行前情報を反映）
        updateSql = '';
        colList = [];
        // 在庫引当数
        colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - orders_receives_QUERY.balance', 'NUMBER'));
        // 残高数
        colList.push(CreateColRow('stocks.balance', 'stocks.balance + orders_receives_QUERY.balance', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' orders_received_set.product_id';
        updateQuery += ',SUM(orders_received_set.stock_scheduled_reserve_receive - orders_received_set.shipping_quantity) AS balance';
        updateQuery += ' FROM ';
        updateQuery += 't_orders_receives AS orders_receives ';
        updateQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
        updateQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
        updateQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
        updateQuery += ' WHERE ';
        updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        updateQuery += 'AND orders_receives.is_deleted = 0 ';
        updateQuery += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
        updateQuery += 'AND orders_receives.set_class = ' + Const.SetClass.set + ' ';
        updateQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
        updateQuery += 'GROUP BY orders_received_set.product_id ';
        updateQuery += ') AS orders_receives_QUERY';
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
        updateSql += 'AND stocks.office_id = ' + this.selectSalesOffice + ' ';
        updateSql += 'AND stocks.product_id = orders_receives_QUERY.product_id ';
        //console.log(updateSql);
        sqlList.push(updateSql);
      } else if (this.selectDelivery != Const.ShippingTypeClass.direct &&
        this.selectBillClass != Const.OrderReceiveBillClass.return) {
        // 「直送」「返品」以外（通常の在庫管理を行う）
        updateSql = '';
        colList = [];
        // 更新用の副問い合わせテーブル作成
        let updateSubQuery = '';
        for(let i = 0; i < 2; i++) {
          if (updateSubQuery != '') {
            updateSubQuery += ' UNION ALL ';
          }
          if (i == 0) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders_received_set.product_id';
            updateSubQuery += ',orders_received_set.reserve_quantity';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders_receives AS orders_receives ';
            updateSubQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
            updateSubQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
            updateSubQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders_receives.is_deleted = 0 ';
            updateSubQuery += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
            updateSubQuery += 'AND orders_receives.set_class = ' + Const.SetClass.set + ' ';
            updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          } else {
            // 上記以外
            // ・ケース換算しない
            // ・バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders_receives.product_id';
            updateSubQuery += ',orders_receives.reserve_quantity';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders_receives AS orders_receives ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders_receives.is_deleted = 0 ';
            updateSubQuery += 'AND (orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.noConversion + ' ';
            updateSubQuery += 'OR (orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
            updateSubQuery += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ')) ';
            updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          }
        }
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
        updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
        updateQuery += ',SUB_QUERY.product_id';
        updateQuery += ',SUM(SUB_QUERY.reserve_quantity) AS reserve_quantity';
        updateQuery += ' FROM ';
        updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
        updateQuery += 'GROUP BY product_id ';
        updateQuery += ') AS PRODUCT_QUERY';
        // 在庫引当数
        colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - PRODUCT_QUERY.reserve_quantity', 'NUMBER'));
        // 残高数
        colList.push(CreateColRow('stocks.balance', 'stocks.balance + PRODUCT_QUERY.reserve_quantity', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
        updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
        //console.log(updateSql);
        sqlList.push(updateSql);
      } else {
        // 上記以外（在庫マスタ更新しない）
        return;
      }
    },
    /* 出荷確定入力ボタン押下時 */
    async clickShipInput() {
      //console.log('出荷確定入力');
      // 出荷確定入力画面へ遷移
      this.$router.push({ name: 'SHIPS-CONFIRM-INPUT', query: { receivedOrderId: this.receivedOrderId } });
    },
    /* 出荷確定修正ボタン押下時 */
    async clickShipEdit() {
      //console.log('出荷確定修正');
      // 出荷確定修正へ遷移
      this.$router.push({ name: 'SHIPS-CONFIRM-EDIT', query: { receivedOrderId: this.receivedOrderId } });
    },
    /* 出荷確定削除ボタン押下時 */
    async clickShipDelete() {
      //console.log('出荷確定削除');
      this.dataIndex = 1;
      this.confirmMessage = [];
      this.confirmMessage.push('照会中の受注の出荷確定入力を削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 出荷確定削除処理 */
    async execShipDelete() {
      const functionName = 'execShipDelete';
      // 実行するSQL保持
      let sqlList = [];
      /* 出荷データ削除 */
      this.deleteShips(sqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 最新の受注情報を再読み込み
        await this.setReceivedOrder();
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 出荷データ削除SQL作成 */
    deleteShips: function(sqlList) {
      let deleteSql = 'DELETE FROM t_ships';
      deleteSql += ' WHERE ';
      // 受注番号
      deleteSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
      deleteSql += 'AND sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      //console.log(deleteSql);
      sqlList.push(deleteSql);
    },
    /* 返品在庫計上入力ボタン押下時 */
    async clickBillReturnInput() {
      //console.log('返品在庫計上入力');
      this.dataIndex = 2;
      this.confirmMessage = [];
      this.confirmMessage.push('照会中の受注の返品在庫計上入力を行います。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 返品在庫計上入力処理 */
    async execBillReturnInput() {
      const functionName = 'execBillReturnInput';
      // 実行するSQL保持
      let sqlList = [];
      /* 在庫マスタの更新 */
      // 受注に引き当てた数を在庫に戻す
      sqlList.push(this.updateStocksMasterReturn());
      /* 受注データ更新 */
      // 在庫に返品した受注のフラグを立てる
      let updateSql = '';
      let colList = [];
      // 返品在庫計上フラグ
      colList.push(CreateColRow('is_bill_return_count', '1', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
      updateSql += 'AND is_bill_return_count = 0 ';
      //console.log(updateSql);
      sqlList.push(updateSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        // 最初に初期化
        this.stockProductId = [];
        // 製品を未引当の受注に引当てる
        for(let i = 0; i < this.productList.length; i++) {
          if (this.productList[i].IsDeleted == 0 && this.productList[i].InventoryClass == Const.InventoryControlClassDef.inventory) {
            // 在庫増加製品コードリスト追加
            this.addStockProductIdList(this.productList[i].ProductCode);
          }
        }
        // ケース換算製品追加
        let csvStockProductIdList = await modStockProductIdListCase(this.stockProductId);
        // 在庫が増加した製品の引当
        let errCsvProductId = '';
        if (csvStockProductIdList.length > 0) {
          errCsvProductId = await reserveStockProductIdList(this.selectSalesOffice, csvStockProductIdList, this.controlMasterData.processMonthYear, this.loginId, MODULE_NAME);
          if (errCsvProductId != '') {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2021'].replace('%arg1%', errCsvProductId));
          }
        }
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 最新の受注情報を再読み込み
        await this.setReceivedOrder();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    /* 在庫マスタ更新SQL作成（返品） */
    updateStocksMasterReturn: function() {
      //console.log('在庫マスタ更新SQL作成（返品）');
      let updateSql = '';
      let colList = [];
      // 更新用の副問い合わせテーブル作成
      let updateSubQuery = '';
      for (let i = 0; i < 2; i++) {
        if (updateSubQuery != '') {
          updateSubQuery += ' UNION ALL ';
        }
        if (i== 0) {
          // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
          updateSubQuery += 'SELECT';
          updateSubQuery += ' orders_received_set.product_id';
          updateSubQuery += ',orders_received_set.shipping_quantity';
          updateSubQuery += ' FROM ';
          updateSubQuery += 't_orders_receives AS orders_receives ';
          updateSubQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
          updateSubQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
          updateSubQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
          updateSubQuery += ' WHERE ';
          updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
          updateSubQuery += 'AND orders_receives.is_deleted = 0 ';
          updateSubQuery += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
          updateSubQuery += 'AND orders_receives.set_class = ' + Const.SetClass.set + ' ';
          updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          updateSubQuery += 'AND orders_receives.is_bill_return_count = 0 ';
        } else {
          // 上記以外
          // ・ケース換算しない
          // ・バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
          updateSubQuery += 'SELECT';
          updateSubQuery += ' orders_receives.product_id';
          updateSubQuery += ',orders_receives.shipping_quantity';
          updateSubQuery += ' FROM ';
          updateSubQuery += 't_orders_receives AS orders_receives ';
          updateSubQuery += ' WHERE ';
          updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
          updateSubQuery += 'AND orders_receives.is_deleted = 0 ';
          updateSubQuery += 'AND (orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.noConversion + ' ';
          updateSubQuery += 'OR (orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
          updateSubQuery += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ')) ';
          updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          updateSubQuery += 'AND orders_receives.is_bill_return_count = 0 ';
        }
      }
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
      updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
      updateQuery += ',SUB_QUERY.product_id';
      updateQuery += ',SUM(SUB_QUERY.shipping_quantity) AS shipping_quantity';
      updateQuery += ' FROM ';
      updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
      updateQuery += 'GROUP BY product_id ';
      updateQuery += ') AS PRODUCT_QUERY';
      // 残高数（返品は数量マイナスのため、引くことで在庫に戻る）
      colList.push(CreateColRow('stocks.balance', 'stocks.balance - PRODUCT_QUERY.shipping_quantity', 'NUMBER'));
      // 月次売上出庫数
      colList.push(CreateColRow('monthly_salse_ship', 'monthly_salse_ship + PRODUCT_QUERY.shipping_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
      updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
      updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
      //console.log(updateSql);
      return updateSql;
    },
    /* 直送売上入力ボタン押下時 */
    async clickDirectInput() {
      //console.log('直送売上入力');
      // 直送売上入力画面へ遷移
      this.$router.push({ name: 'SALES-DIRECT-INPUT', query: { receivedOrderId: this.receivedOrderId } });
    },
    /* 直送売上修正ボタン押下時 */
    async clickDirectEdit() {
      //console.log('直送売上修正');
      // 直送売上修正へ遷移
      let route = this.$router.resolve({ name: 'SALES-DIRECT-EDIT-NO-ORDER', query: { billingNo: this.billingNo, monthlyMonthYear: this.monthlyMonthYear, parentKbn: 0 } });
      window.open(route.href, '_blank');
    },
    /* 仮納品書入力ボタン押下時 */
    async clickTempDeliverySlip() {
      //console.log('仮納品書入力');
      // 仮伝票、売上入力画面へ遷移
      let route = this.$router.resolve({ name: 'TENTATIVE-SALES-INPUT', query: { receivedOrderId: this.receivedOrderId }});
      window.open(route.href, '_blank');
    },
    // 確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 排他制御チェック
          if (await isOtherSessionUpdated(this.listUpdatedSelect) == false) {
            if (this.dataIndex == 0) {
              // 受注削除
              await this.execReceivedOrderDelete();
            } else if (this.dataIndex == 1) {
              // 出荷確定削除
              await this.execShipDelete(true);
            } else if (this.dataIndex == 2) {
              // 返品在庫計上入力
              await this.execBillReturnInput();
            }
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 発注状況を取得（const.jsの「ReceivedOrderOrderJokyoList」のidを返却） */
    getOrderJokyo: function(shippingTypeClass, orderReceiveBillClass, inventoryClass, orderIssueClass, stocksClass) {
      if (shippingTypeClass == Const.ShippingTypeClass.direct || orderReceiveBillClass == Const.OrderReceiveBillClass.special) {
        if (inventoryClass == Const.InventoryControlClassDef.inventory) {
          // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
          if (orderIssueClass == 0) {
            // 発注未
            return Const.ReceivedOrderOrderJokyo.orderMi;
          } else if (stocksClass == Const.StocksClass.mi) {
            // 発注済み、入荷未
            return Const.ReceivedOrderOrderJokyo.orderZumi;
          } else {
            // 発注済み、入荷済み
            return Const.ReceivedOrderOrderJokyo.nyukaZumi;
          }
        } else {
          // 発注しない、在庫管理から引当もしない
          return Const.ReceivedOrderOrderJokyo.noStock;
        }
      } else {
        // 上記以外の場合、発注データとの紐づけ不要（在庫管理か在庫管理不要かを返却）
        if (inventoryClass == Const.InventoryControlClassDef.inventory) {
          // 在庫管理から引当
          return Const.ReceivedOrderOrderJokyo.stock;
        } else {
          // 発注しない、在庫管理から引当もしない
          return Const.ReceivedOrderOrderJokyo.noStock;
        }
      }
    },
    /* フィールド取得 */
    getFields: function() {
      if (this.selectDelivery != Const.ShippingTypeClass.direct) {
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'SalesUnitPrice',
            label: 'BM価格',
            tdClass: 'text-right',
          },
          {
            key: 'SellingPrice',
            label: '売価',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'Money',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'SupplierCode',
            label: '発注先コード',
          },
          {
            key: 'SupplierName',
            label: '発注先',
          },
          {
            key: 'OrderQuantity',
            label: '発注数',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaff',
            label: '入荷予定処理担当者',
          },
          {
            key: 'RemainingQuantity',
            label: '受注残',
            tdClass: 'text-right',
          },
          {
            key: 'ReserveQuantity',
            label: '引当数',
            tdClass: 'text-right',
          },
          {
            key: 'ShippingQuantity',
            label: '出荷数',
            tdClass: 'text-right',
          },
          {
            key: 'OrderJokyoText',
            label: '発注状況',
          },
          {
            key: 'ShipsClassText',
            label: '出荷確定',
          },
          {
            key: 'SalesIssueClassText',
            label: '伝票発行',
          },
        ];
      } else {
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'SalesUnitPrice',
            label: 'BM価格',
            tdClass: 'text-right',
          },
          {
            key: 'SellingPrice',
            label: '売価',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'Money',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'SupplierCode',
            label: '発注先コード',
          },
          {
            key: 'SupplierName',
            label: '発注先',
          },
          {
            key: 'OrderQuantity',
            label: '発注数',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaff',
            label: '入荷予定処理担当者',
          },
          {
            key: 'RemainingQuantity',
            label: '受注残',
            tdClass: 'text-right',
          },
          {
            key: 'OrderJokyoText',
            label: '発注状況',
          },
          {
            key: 'ShipsClassText',
            label: '直送売上入力',
          },
        ];
      }
    },
    /* 在庫増加製品コードリスト追加 */
    addStockProductIdList: function(productId) {
      if (this.stockProductId.length == 0) {
        this.stockProductId.push(productId);
      } else {
        // 重複しないように製品コードをリストに追加
        for (let j = 0; j < this.stockProductId.length; j++) {
          if (this.stockProductId[j] == productId) {
            break;
          }
          if (j == this.stockProductId.length - 1) {
            // 在庫マスタの増加があった製品はリストに保持（後で未引当の受注への引当に使用）
            this.stockProductId.push(productId);
          }
        }
      }
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      // 初期化
      this.listUpdatedSelect = [];

      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql, checkDelete: false});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(ships.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' ships.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql, checkDelete: false});
      let dataResult = [null, null];
      [dataResult[0], dataResult[1]] = await Promise.all([
        executeSelectSql(this.listUpdatedSelect[0].select),
        executeSelectSql(this.listUpdatedSelect[1].select),
      ]);
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        if (dataResult[i] != null && dataResult[i].length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[i][0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
      //console.log('最終更新日時');
      //console.log(this.listUpdatedSelect);
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT', query: { estimateId: 0 } });
    },
    // 日付と締日から締切日（YYYY/MM/DD）を取得
    setClosingYmd: function(closingDay) {
      let monthYear = getBillingMonthYear(this.inputDate, closingDay);
      this.suppliers.closingDate = getClosingDate(monthYear, closingDay, false);
    },
    // 粗利（売価-仕入単価）を確認して、マイナスの場合は背景色を赤色に変更
    checkGrossProfit: function(item, index) {
      let rowVariant = '';
      if (item.PurchasePrime > item.SellingPrice) {
        // 仕入単価が売価よりも大きい場合
        rowVariant = 'danger';
      }
      if (item._rowVariant != rowVariant) {
        // 背景色を変更する場合
        item._rowVariant = rowVariant;
        this.productList.splice(index, 1, item);
      }
    },
  }
}
</script>
<style scoped>
</style>