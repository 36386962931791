import { Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { sendQueryRequestMessage, sendTransactSqlsRequestMessage } from '@/assets/aws/sqs.js'
import { createQueryStatus, createTransactSqlStatus } from '@/graphql/mutations';
import { addOperationLogs } from '@/assets/js/common.js';

const MODULE_NAME = 'executeSqlAsync.js'

/**
 * 非同期SELECT実行リクエスト
 * @param {*} type 
 * @param {*} sqlList 
 * @returns 
 */
export async function requestQueryAsync({type, sqls, csvOutputConfig=null}) {
  const functionName = 'requestQueryAsync';
  
  // 処理IDを決定
  const processId = uuidv4();

  // 認証情報を取得（amplify storageにアクセスする際にidentityIdが必要なため）
  let credentials = null;
  try {
    credentials = await Auth.currentCredentials();
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: '認証情報の取得に失敗しました。'
    }, error);
    throw error;
  }

  // DynamoDBにリクエストレコードを作成
  try {
    await API.graphql(
      graphqlOperation(createQueryStatus, {
        input : {
          ProcessID: processId,
          StatusClass: 1,
          TypeClass: type
        }
      })
    );
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'DynamoDBへのレコード作成に失敗しました。'
    }, error);
    throw error;
  }

  // SQSキューにメッセージを送信
  try {
    const params = {
      processId: processId,
      type: type,
      SQLs: sqls,
      level: 'private',
      identityId: credentials.identityId
    }
    // CSV出力データをjsonファイルでS3に保管する場合はCSV出力情報を添付
    if(csvOutputConfig) Object.assign(params, { csvOutputConfig: csvOutputConfig });
    
    await sendQueryRequestMessage(params);
  } catch(error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'SQSキューへのメッセージ送信に失敗しました。'
    }, error);
    throw error;
  }

  return processId;
}

/**
 * 非同期SELECT実行のステータス変更検知時処理
 * @param {*} statusInfo
 * @param {*} subscription
 */
export async function onQueryStatusUpdate(statusInfo, subscription) {
  const functionName = 'onQueryStatusUpdate';

  try {
    //console.log('onQueryStatusUpdate statusInfo:', statusInfo)
    const statusClass = statusInfo?.StatusClass;

    // ステータスが正しく渡されていない
    if(statusClass === null) throw new Error('ステータスが取得できません');
    // 正常終了、またはエラーでなければreturn
    if(statusClass !== 0 && statusClass !== 8 && statusClass !== 9) return;

    // サブスクリプション終了
    subscription.unsubscribe();

    if(statusClass == 8 || statusClass == 9) {
      addOperationLogs('Error', MODULE_NAME, functionName, {
        message: '非同期SELECT関数の実行中にエラーが発生しました。' + statusInfo?.Error ?? ''
      }, statusInfo?.Error);
      return false;
    }

    // 関数成功後処理
    if (statusClass == 0) {
      // ファイルパス
      const s3Keys = JSON.parse(statusInfo.S3Keys);
      // console.log({statusInfo});

      // 結果データをS3から取得してパース
      const resultDataObjectList = await getSelectResultsFromS3(s3Keys);

      return resultDataObjectList;
    }
  } catch (error) {
    addOperationLogs('Error', MODULE_NAME, functionName, {
      message: '非同期SELECT関数の実行ステータスチェック中に予期せぬエラーが発生しました。' + error?.message ?? ''
    }, error);
    return false;
  }
}

/**
 * S3のキーリストを用いてS3からJSONファイルを取得し、オブジェクトのリストを返却します
 * @param {*} s3Keys 
 * @returns 
 */
async function getSelectResultsFromS3(s3Keys) {
  const functionName = 'getSelectResultsFromS3';

  let resultList = []
  try {
    for (const key of s3Keys) {
      const jsonFileData = await Storage.get(key, {
        download: true,
        level: 'private'
      });
      const dataObject = await parseJsonFileData(jsonFileData);
      // console.log({dataObject});
      resultList.push(dataObject);
    }
    return resultList;
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'S3からSELECTデータを取得する際にエラーが発生しました。',
      s3Keys: s3Keys
    }, error);
    return null;
  } finally {
    // S3のCSVファイルを削除します。
    await removeS3(s3Keys);
  }
}

/**
 * JSONファイルのデータを受け取り、パースしてオブジェクトを返却します
 * @param {*} jsonFileData 
 * @returns 
 */
async function parseJsonFileData(jsonFileData) {
  const functionName = 'parseJsonFileData';
  try {
    const jsonData = await jsonFileData.Body.text();
    return JSON.parse(jsonData);
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: `S3から取得したJSONデータをパースする際にエラーが発生しました: ${error}`
    }, error);
  }
}

/**
 * 引数keys内のcsvファイルをS3から全て削除します。
 * @param {[String]} keys - 削除対象のs3ファイル名
 */
async function removeS3(keys) {
  const functionName = 'removeS3';
  // S3のCSVファイルを削除します。
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    try {
      await Storage.remove(key, { level: 'private' });
    } catch (error) {
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        message: 'S3からSELECTデータファイルの削除に失敗しました。',
        key: key
      }, error);
    }
  }
  keys = [];
}

/**
 * 非同期INSERT・UPDATE実行リクエスト
 * @param {*} type 
 * @param {*} sqlList 
 * @returns 
 */
export async function requestTransactSqlsAsync(type, sqlList) {
  const functionName = 'executeTransactSqlsAsync';
  
  // S3キーを決定
  const processId = uuidv4();
  const key = `executeTransactSqlWithStatus/${processId}.json`;

  // S3にアップロード
  try {
    await Storage.put(key, JSON.stringify({ SQLs: sqlList }));
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'S3へのアップロードに失敗しました。'
    }, error);
    throw error;
  }

  // DynamoDBにリクエストレコードを作成
  try {
    await API.graphql(
      graphqlOperation(createTransactSqlStatus, {
        input : {
          ProcessID: processId,
          StatusClass: 1,
          TypeClass: type
        }
      })
    );
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'DynamoDBへのレコード作成に失敗しました。'
    }, error);
    throw error;
  }

  // SQSキューにメッセージを送信
  try {
    await sendTransactSqlsRequestMessage({
      processId: processId,
      type: type,
      s3Key: key
    });
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'SQSキューへのメッセージ送信に失敗しました。'
    }, error);
    throw error;
  }

  return processId;
}

/**
 * 非同期INSERT・UPDATE実行のステータス変更検知時処理
 * @param {*} statusInfo
 * @param {*} subscription
 */
export async function onTransactSqlStatusUpdate(statusInfo, subscription) {
  const functionName = 'onTransactSqlExecutionStatusUpdate';

  try {
    // console.log('onTransactSqlStatusUpdate statusInfo:', statusInfo);
    const statusClass = statusInfo?.StatusClass;

    // ステータスが正しく渡されていない
    if(statusClass === null) throw new Error('ステータスが取得できません');
    // 正常終了、またはエラーでなければreturn
    if(statusClass !== 0 && statusClass !== 9) return;

    // サブスクリプション終了
    subscription.unsubscribe();

    // エラーが発生した場合
    if(statusClass == 9) {
      addOperationLogs('Error', MODULE_NAME, functionName, {
        message: '非同期INSERT・UPDATE関数の実行中にエラーが発生しました。' + statusInfo?.Error ?? ''
      }, statusInfo?.Error);
      return false;
    }

    // 処理成功
    if (statusClass == 0) return true;
  } catch (error) {
    addOperationLogs('Error', MODULE_NAME, functionName, {
      message: '非同期INSERT・UPDATE関数の実行ステータスチェック中に予期せぬエラーが発生しました。' + error?.message ?? ''
    }, error);
    return false;
  }
}

// async function getCredentials() {
//   try {
//     return await Auth.currentCredentials();
//   } catch (error) {
//     throw error;
//   }
// }
