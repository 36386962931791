<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
              <strong> 発注照会</strong></h5>
              <b-button-toolbar>
                <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'発注入力に遷移する'" @click="clickInputBtn" class="m-0">
                  <span class="oi oi-plus"></span> 新規登録(F10)
                  <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                </b-button>&nbsp;&nbsp;
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'見積・発注一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </b-button-toolbar>
            </div>
          </b-media-body>
        </b-media>
        <div class="main-card mb-3 card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <b-form id="InputForm" class="form-horizontal">
              <b-row>
                <!-- 発注番号 -->
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="orderId">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>発注番号 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="orderId" type="text" v-model="orderId" readonly />
                  </b-input-group>
                </div>
                <!-- 営業所コードプルダウン -->
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="selectSalesOffice">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>営業所 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                  </b-input-group>
                </div>
                <!-- 発注日付 -->
                <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="dateOrderDate">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>発注日付 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="dateOrderDate" type="text" v-model="dateOrderDate" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 取引先コード -->
                <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="suppliersCode">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='情報ボタンは入力されたコードに紐づく詳細情報を表示します。'>
                          <strong class='text-body'>取引先コード <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input type="text" v-model="suppliers.code" readonly />
                    <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが0以外の場合、利用可能です。）" :disabled="suppliers.code == 0" @click="clientInfoModal(suppliers.class, suppliers.code)">
                      <span class="oi oi-eye"></span> 情報
                    </b-button>
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 取引先名 -->
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-6 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="suppliersName">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='発注登録時点の取引先名です。帳票に出力する取引先は「取引先印刷用宛名」において設定してください'>
                          <strong class='text-body'>取引先名 <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="suppliersName" type="text" v-model="suppliers.name" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 取引先印刷用宛名 -->
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-6 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="clientPrintName">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「宛先」の部分に出力されます。'>
                          <strong class='text-body'>取引先印刷用宛名 <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="clientPrintName" type="text" v-model="suppliers.printName" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 取引先担当者コード -->
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="clientStaffId">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「担当」の部分に出力されます'>
                          <strong class='text-body'>取引先担当者 <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="clientStaffId" type="text" v-model="clientStaffId" readonly />
                  </b-input-group>
                </div>
                <!-- 作成担当者コード -->
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="createdStaffId">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先担当者と異なる場合、帳票の「担当」の右側に括弧括りで出力されます'>
                          <strong class='text-body'>作成担当者 <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="createdStaffId" type="text" v-model="createdStaffId" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 内容 -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="textContents">
                        <b-input-group-text  class="px-1">
                          <strong class='text-body'>内容 </strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-input id="textContents" type="text" v-model="textContents" readonly />
                    <b-form-text class="text-muted">作成した発注の内容です<br>帳票には出力されません</b-form-text>
                  </b-input-group>
                </div>
                <!-- 摘要 -->
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                  <b-input-group>
                    <template #prepend>
                      <label for="textSummary">
                        <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「摘要」の部分に出力されます'>
                          <strong class='text-body'>摘要 <span class="oi oi-flag"/></strong>
                        </b-input-group-text>
                      </label>
                    </template>
                    <b-form-textarea id="textSummary" rows="3" v-model="textSummary" readonly />
                  </b-input-group>
                </div>
              </b-row>
              <b-row>
                <!-- 製品 -->
                <b-col lg="12">
                  <b-row>
                    <b-col lg="12">
                      <b-table
                        :items="productList"
                        :fields="fields"
                        :small="true"
                      >
                        <template #table-colgroup>
                          <col style="width:110px;"/>
                          <col style="width:250px;"/>
                          <col style="width:200px;"/>
                          <col style="width:100px;"/>
                          <col style="width:50px;"/>
                          <col style="width:40px;"/>
                        </template>
                        <template #cell(ProductCode)="data">
                          <b-input-group>
                            <b-form-input size="sm" class="px-1" type="text" v-model="data.item.ProductCode" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(ProductName)="data">
                          <b-input-group>
                            <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                            <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" readonly v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                          </b-input-group>
                        </template>
                        <template #cell(Note)="data">
                          <b-input-group>
                            <b-form-input size="sm" class="px-1" type="text" name="productNote" v-model="data.item.Note" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(Quantity)="data">
                          <b-input-group>
                            <b-form-input size="sm" class="px-1" type="number" v-model="data.item.Quantity" readonly />
                          </b-input-group>
                        </template>
                        <template #cell(Unit)="data">
                          <b-input-group>
                            <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" readonly />
                          </b-input-group>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="12">
                <b-btn-toolbar class="mt-2">
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注のPDF出力を行います。" @click="cleckOutputOrderPdf">
                    <span class="oi oi-document"></span> 発注書PDF出力
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注内容の編集を行います。" @click="clickEditBtn">
                    <span class="oi oi-pencil"></span> 編集
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注内容の削除を行います。" @click="clearAlert(); clickDeleteBtn();">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>&nbsp;
                  <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注をコピーして発注を新規登録します。" @click="clickOrderCopyBtn">
                    <span class="oi oi-plus"></span> コピー発注
                  </b-button>
                </b-btn-toolbar>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['照会中の発注データを削除します。','よろしいですか？']" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, getControlMaster, formatDate, addOperationLogs, executeTransactSqlList, isSystemEditable, getNullStr } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_estimate_orders, list_m_products } from '@/graphql/queries';

const MODULE_NAME = 'estimate-orders-inquiry';

export default {
  name: 'ESTIMATE-ORDERS-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注照会',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOfficeText: '',
      // 発注日付
      dateOrderDate: '',
      // 取引先系
      suppliers:{
        class: 0,
        code: 0,
        name: '',
        printName: '',
      },
      // 取引先担当者
      clientStaff:{
        id: 0,
        name: ' ',
      },
      // 作成担当者
      createdStaff:{
        id: 0,
        name: ' ',
      },
      // 内容
      textContents: '',
      // 摘要
      textSummary: '',
      // テーブル定義
      productList:[],
      fields:[
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Note',
          label: '備考',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
      ],
      // コントロールマスタ
      controlMasterData: {
        lightTaxMark: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // パラメータ
      orderId: 0,
      parentKbn: 0,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.orderId = this.$route.query.orderId;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 取引先担当者コード */
    clientStaffId: function() {
      return `${this.clientStaff.id}:${this.clientStaff.name}`
    },
    /* 作成担当者コード */
    createdStaffId: function() {
      return `${this.createdStaff.id}:${this.createdStaff.name}`
    },
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // 現在処理年月取得
        let controlData = await getControlMaster();
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 指定された発注データを設定
        await this.setOrder();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
          scrollTo(0,0);
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 発注データ設定 */
    async setOrder() {
      const functionName = 'setOrder';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let resultOrder = null;
      try {
        resultOrder = await API.graphql(graphqlOperation(list_t_estimate_orders,{where_clause: where_clause}));
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_estimate_orders',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
        return;
      }
      let dataOrder = resultOrder.data.list_t_estimate_orders;
      //console.log(dataOrder);
      if(dataOrder != null){
        await this.setResult(dataOrder);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake() {
      let where_clause = '';
      // 引数の発注番号で絞り込む
      where_clause += 'AND order_id = ' + this.orderId + ' ';
      // 製品一覧の行番号でソート
      where_clause += 'ORDER BY order_row';

      return where_clause;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0){
        // 営業所
        this.selectSalesOfficeText = result[0].office_id + '：' + result[0].office_name_kanji;
        // 発注日付
        this.dateOrderDate = formatDate(result[0].order_date);
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_print_name;
        // 取引先担当者
        this.clientStaff = {id:result[0].client_staff_id,name:result[0].client_staff_name_kanji};
        // 作成担当者
        this.createdStaff = {id:result[0].created_staff_id,name:result[0].created_staff_name_kanji};
        // 内容
        this.textContents = result[0].contents;
        // 摘要
        this.textSummary = result[0].summary;
        // 全件参照して製品一覧を作成
        let productIdCsv = '';
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].Note = result[i].product_note;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].product_unit;
          if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
            if (productIdCsv != '') {
              productIdCsv += ',';
            }
            // 製品コード
            productIdCsv += this.productList[i].ProductCode;
          }
        }
        // 製品マスターから取得
        if (productIdCsv != '') {
          let where_clause = 'AND product_id IN (' + productIdCsv + ') ';
          let condition = {where_clause: where_clause};
          let productsResult = await API.graphql(graphqlOperation(list_m_products,condition));
          let productsResultData = productsResult.data.list_m_products;
          //console.log(productsResultData);
          if (productsResultData != null) {
            for (let i = 0; i < this.productList.length; i++) {
              let data = productsResultData.find(el => el.product_id == this.productList[i].ProductCode);
              if (data != undefined) {
                this.productList[i].ProductTaxRateClass = data.product_tax_rate_class_purchase;
              }
            }
          }
        }
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: 0,
        ProductName: '',
        Note:'',
        Quantity: 0,
        Unit: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
      };
      return newProduct;
    },
    /* 発注書PDF出力ボタンの押下 */
    async cleckOutputOrderPdf() {
      // 発注書PDF出力処理
      //console.log('発注書PDF出力処理');
      let route = this.$router.resolve({ name: 'ORDER', query: { orderId: this.orderId }});
      window.open(route.href, '_blank');
    },
    /* 編集ボタン押下時 */
    async clickEditBtn(){
      //console.log('編集');
      // 発注修正画面へ遷移
      this.$router.push({ name: 'ESTIMATE-ORDERS-EDIT', query: { orderId: this.orderId } });
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn() {
      //console.log('削除');
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 取引先親子マスタの削除SQL
      let deleteSql = 'DELETE FROM t_estimate_orders';
      deleteSql += ' WHERE ';
      // 発注番号
      deleteSql += 'order_id = ' + this.orderId + ' ';

      //console.log(deleteSql);

      let sqlList = [];
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        // 見積・発注一覧画面へ遷移
        this.$router.push({ name: 'ESTIMATE-LIST', params: { parentKbn: 1 } });
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* コピー発注ボタン押下時 */
    async clickOrderCopyBtn() {
      //console.log('コピー発注');
      // 発注入力画面へ遷移
      this.$router.push({ name: 'ESTIMATE-ORDERS-INPUT', query: { propOrderId: this.orderId } });
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack(){
      // 見積・発注一覧画面へ遷移
      this.$router.push({ name: 'ESTIMATE-LIST' });
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'ESTIMATE-ORDERS-INPUT', query: { propOrderId: 0 }});
    },
  }
}
</script>
<style scoped>
</style>