<template>
  <!--  サービス区分名保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong>  サービス区分名保守編集</strong></h5>
                <router-link to="/service-classes-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <hr>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- サービス区分 -->
                  <div class="col-12 form-group">
                    <label for="service_class" class="form-label">サービス区分： {{obj.service_class}}</label>
                  </div>
                </div>
                <div class="row">
                  <!-- サービス区分名 -->
                  <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="max:10" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="service_class_name" class="form-label">サービス区分名</label>
                        <input type="text" id="service_class_name" class="form-control" v-model="obj.service_class_name" maxlength="10">
                        <!-- <small class="form-text text-muted">必須項目です。</small> -->
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
            </div>
             <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_service_classes } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, CreateColRow, CreateUpdateSql, escapeQuote, getUserCol, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'service-classes-edit';

export default {
  name: 'SERVICE-CLASSES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'サービス区分名保守編集',
      // ユーザ名
      username: this.$store.getters.user.username,
      // サービス区分名対象
      obj: {},
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData({service_class}) {
      this.busy = true;
      // サービス区分マスタのデータを請求
      const objResult = await this.searchServiceClasses(service_class)

      // データセット 
      this.obj = {...objResult}
      this.busy = false;
    },
    /**
     * 納品指定区分マスタを検索します。
     * @param {String} service_class
     * @returns {Object} 納品指定区分マスタデータ
     */
    async searchServiceClasses(service_class) {
      const functionName = 'searchServiceClasses';

      let result = null;
      // todo service_class チェック 横展開
      let where_clause = `AND service_class = '${service_class}'`
      
      try {
        result = await API.graphql(graphqlOperation(list_m_service_classes, {where_clause}));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_service_classes',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_service_classes',
          where_clause: where_clause,
          result: result
        });
        return null;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      return result.data.list_m_service_classes[0];
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];
      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'SERVICE-CLASSES-INQUIRY', 
              query: { service_class: this.obj.service_class },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      // サービス区分マスタ更新
      await this.execUpdateServiceClasses()
    },
    /**
     * 納品指定区分マスタ更新
     */
    async execUpdateServiceClasses(){
      const functionName = 'execUpdateServiceClasses';

      const colList = [];
      // サービス区分名
      colList.push(CreateColRow('service_class_name', await escapeQuote(this.obj.service_class_name), 'VARCHAR'));

      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_service_classes')} WHERE service_class = '${this.obj.service_class}'`;
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
