<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive.right="'クリックで検索条件を表示/非表示できます。'">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.bottom="'印刷'" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive.bottom="'電子書類の登録'" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="clearAlert(); searchButton();" id="saveDocumentsListForm" class="form-horizontal">
                    <validation-observer ref="observer">
                      <b-container fluid>
                        <!-- ●●●検索条件●●● -->
                        <b-row>
                          <!-- 電子書類種類選択欄 -->
                          <b-col sm="5" md="4" lg="3">
                            <b-form-group
                              label="電子書類種類"
                            >
                              <b-form-radio-group
                                size="sm"
                                v-model="searchConditions.checkDocumentsKind"
                                :options="searchConditions.documentsKindList"
                                value-field="id"
                              ></b-form-radio-group>
                            </b-form-group>
                          </b-col>
                          <!-- 営業所入力欄 -->
                          <b-col sm="4" md="3" lg="2">
                            <b-form-group
                              label="営業所"
                              label-for="selectSalesOffice"
                            >
                              <b-form-select
                                id="selectSalesOffice"
                                size="sm"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 電子書類区分 -->
                          <b-col sm="12" md="6" v-if="searchConditions.checkDocumentsKind==htmlConst.DocumentsKind.electronicDocuments">
                            <b-form-group
                              label="電子書類区分"
                              label-for="electronicDocumentsClassList"
                            >
                              <b-form-select
                                id="electronicDocumentsClassList"
                                size="sm"
                                v-model="searchConditions.selectElectronicDocumentsClass"
                                :options="searchConditions.electronicDocumentsClassList"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 請求年月 -->
                          <b-col sm="4" md="4" lg="3" xl="2" v-if="searchConditions.checkDocumentsKind==htmlConst.DocumentsKind.seikyu">
                            <b-form-group
                              label="請求年月"
                              label-for="billingMonthYear"
                            >
                              <b-input-group>
                                <b-form-input size="sm" id="billingMonthYear" type="month" v-model="searchConditions.billingMonthYear" />
                                <b-input-group-append>
                                  <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingMonthYear=null">
                                    <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 取引先区分入力欄 -->
                          <b-col sm="3" lg="2" v-if="searchConditions.checkDocumentsKind==htmlConst.DocumentsKind.electronicDocuments">
                            <b-form-group
                              label="取引先区分"
                              label-for="clientClassList"
                            >
                              <b-form-select
                                id="clientClassList"
                                v-model="searchConditions.selectClientClass"
                                :options="searchConditions.clientClassList"
                                size="sm"
                              >
                            </b-form-select>
                          </b-form-group>
                          </b-col>
                          <!-- 取引先コード入力欄 -->
                          <b-col sm="6" lg="3">
                            <validation-provider name="clientId" rules="numeric" v-slot="{ classes,errors }">
                              <label for="clientId">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group>
                                  <b-form-input size="sm" type="text" id="clientId" name="clientId" maxlength="6" v-model="searchConditions.clientId"></b-form-input>
                                  <b-input-group-text @click="showClientSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能（取引先区分が「全て」の場合は呼出し不可）">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 取引先名入力欄 -->
                          <b-col sm="12" lg="7">
                            <label for="clientName">取引先名<b-form-text class="text-muted d-inline-flex" v-b-tooltip.hover.noninteractive title="部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します">(部分一致検索です。...)</b-form-text></label>
                            <b-form-input size="sm" type="text" id="clientName" name="clientName" v-model="searchConditions.clientName"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 取引日付入力欄 -->
                          <b-col lg="9">
                            <validation-provider name="transactionDateStart" :rules="{dateConsistency:searchConditions.transactionDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                :label="getSearchDateName"
                                label-for="datepicker1"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker size="sm" id="datepicker1" name="transactionDateStart" class="form-control" v-model="searchConditions.transactionDateStart"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.transactionDateStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <b-input-group-text>～</b-input-group-text>
                                    <b-form-datepicker size="sm" id="datepicker2" name="transactionDateEnd" class="form-control" v-model="searchConditions.transactionDateEnd"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.transactionDateEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 取引金額 -->
                          <b-col lg="12">
                            <b-form-group
                              :label="getSearchMoneyName"
                              label-for="moneyStart"
                            >
                              <b-input-group>
                                <validation-provider name="moneyStart" :rules="{between:{min:-9999999999,max:9999999999},numConsistency:searchConditions.moneyEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input size="sm" id="moneyStart" name="moneyStart" type="number" v-model.number="searchConditions.moneyStart" />
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="moneyEnd" :rules="{between:{min:-9999999999,max:9999999999}}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input size="sm" name="moneyEnd" type="number" v-model.number="searchConditions.moneyEnd" />
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 登録日付入力欄 -->
                          <b-col lg="8">
                            <validation-provider name="inputDateStart" :rules="{dateConsistency:searchConditions.inputDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="登録日付"
                                label-for="inputDateStart"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker size="sm" id="inputDateStart" name="inputDateStart" class="form-control" v-model="searchConditions.inputDateStart"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.inputDateStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <b-input-group-text>～</b-input-group-text>
                                    <b-form-datepicker size="sm" id="inputDateEnd" name="inputDateEnd" class="form-control" v-model="searchConditions.inputDateEnd"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.inputDateEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- 登録担当者コード入力欄 -->
                          <b-col lg="4">
                            <validation-provider name="inputStaffId" rules="numeric" v-slot="{ classes,errors }">
                              <label for="inputStaffId">登録担当者コード<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group>
                                  <b-form-input size="sm" type="text" id="inputStaffId" name="inputStaffId" maxlength="4" v-model="searchConditions.inputStaffId"></b-form-input>
                                  <b-input-group-text @click="showStaffSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showStaffSearchModal" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <!-- 検索ボタン -->
                        <b-row class="justify-content-md-center mt-2">
                          <b-col lg="3">
                            <b-button block pill variant="success" type="submit" form="saveDocumentsListForm">
                              <span class="oi oi-magnifying-glass"></span> 検 索 
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(BillingIssueInputDate)>請求書<br>発行登録日</template>
              <template #head(NormalSeparateClassText)>通常分<br>割区分</template>
              <template #head(BillingSeparateClassText)>請求書<br>分割区分</template>
              <template #head(SeparateInvoiceNoText)>分割請求<br>書番号</template>
              <template #head(BillingDuplicateClassText)>重<br>複</template>
              <template #cell(BillingDuplicateClassText)="data">
                <span :style="data.item.IsLatest ? 'color: red': ''">{{ data.item.BillingDuplicateClassText }}</span>
              </template>
              <template #cell(Client)="data">
                <div style="max-width:250px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive.right="data.item.Client">
                      {{ data.item.Client }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(Site)="data">
                <div style="max-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive.right="data.item.Site">
                      {{ data.item.Site }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(InputStaff)="data">
                <div style="max-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive.left="data.item.InputStaff">
                      {{ data.item.InputStaff }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(Money)="data">
                {{ data.item.Money.toLocaleString() }}
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="data">
                <div v-show="!printStatus">
                  <!-- ダウンロードボタン -->
                  <b-button size="sm" class="mr-1" @click="clearAlert(); clickDownloadBtn(data.item)">
                    <span class="oi oi-data-transfer-download"></span>
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" @click="clearAlert(); clickDeleteBtn(data.item)">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="modalClientClass"/>
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import { init, windowPrint, formatDate, formatCurDate, formatDateCalc, getListValue, getNullStr, addOperationLogs, convertSqlLikeSpecialChar, createOfficeList, createElectronicDocumentsClassList, executeSelectSql, CreateColRow, CreateInsertSql, executeTransactSqlList, getBillingDuplicateClass, updateElectronicDocumentsSeikyuDuplicateClass, isSystemEditable, uploadFileRemoveFromS3, downloadFileFromS3 } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs } from '@/graphql/queries';
import { dateConsistency } from '../assets/js/common';

const MODULE_NAME = 'save-documents-list';

export default {
  name: 'SAVE-DOCUMENTS-LIST',
  // コンポーネント
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    STAFFSEARCH,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '保存電子書類一覧',
      loginId: this.$store.getters.user.username,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        checkDocumentsKind: Const.DocumentsKind.electronicDocuments,
        documentsKindList: Const.DocumentsKindList,
        selectSalesOffice: 0,
        salesOffice: [],
        selectElectronicDocumentsClass: '',
        electronicDocumentsClassList: [],
        billingMonthYear: null,
        billingNo: '',
        selectClientClass: '0',
        clientClassList: [],
        clientId: '',
        clientName: '',
        transactionDateStart: '',
        transactionDateEnd: '',
        moneyStart: '',
        moneyEnd: '',
        inputDateStart: '',
        inputDateEnd: '',
        inputStaffId: '',
      },
      // 検索結果表示時に指定された検索項目
      searchResultDispConditions:{
        checkDocumentsKind: Const.DocumentsKind.electronicDocuments,
      },
      // 取引先検索モーダルに渡す値
      modalClientClass: null,
      // 検索結果
      fields: [],
      itemList: [],
      busy: false,
      filter: null,
      // 削除確認ダイアログ用
      confirmMessage: [],
      delData: {},
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 電子書類種類
        DocumentsKind: {
          // 電子書類
          electronicDocuments: Const.DocumentsKind.electronicDocuments,
          // 請求書
          seikyu: Const.DocumentsKind.seikyu,
        },
        // 書類アップロード方法
        DocumentsUploadWay: {
          // 行毎アップロード
          everyRow: Const.DocumentsUploadWay.everyRow,
          // ページ分割アップロード
          pageSplit: Const.DocumentsUploadWay.pageSplit,
        },
      },
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 検索条件（日付）を返却
    getSearchDateName: function() {
      if (this.searchConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        return '取引日付';
      } else {
        // 電子書類種類：請求書
        return '締切日付';
      }
    },
    // 検索条件（金額）を返却
    getSearchMoneyName: function() {
      if (this.searchConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        return '取引金額';
      } else {
        // 電子書類種類：請求書
        return '請求金額';
      }
    },
  },
  // 関数群
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let electronicDocumentsClassListResult = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult, electronicDocumentsClassListResult] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs, condition)),
          createOfficeList(true),
          createElectronicDocumentsClassList(true),
        ]);
        let staffListData = staffListResult.data.list_m_staffs;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice = officeListResult;
        // 営業所データ初期値セット
        this.searchConditions.selectSalesOffice = 0;
        if (staffListData != null && staffListData.length > 0) {
          let loginOffice = this.searchConditions.salesOffice.find(el => el.id == staffListData[0].office_id);
          if (loginOffice != undefined) {
            this.searchConditions.selectSalesOffice = loginOffice.id;
          }
        }
        // 電子書類区分リスト作成
        this.searchConditions.selectElectronicDocumentsClass = 0;
        this.searchConditions.electronicDocumentsClassList = electronicDocumentsClassListResult;
        // 取引先区分リスト作成
        this.searchConditions.selectClientClass = '0';
        this.searchConditions.clientClassList = [];
        this.searchConditions.clientClassList.push({value: '0', text: '全て'});
        for(let i = 0; i < Const.ClientClassList.length; i++){
          this.searchConditions.clientClassList.push(Const.ClientClassList[i]);
        }
        // 起動時の検索フィールド
        this.fields = this.getFields(Const.DocumentsKind.electronicDocuments);
        // 検索条件の初期値設定
        this.searchConditions.inputDateStart = formatCurDate('YYYY-MM-DD');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      try {
        // CRUD処理
        let selectSql = '';
        if (this.searchConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          selectSql = await this.makeSelectSql_electronic();
        } else {
          // 電子書類種類：請求書
          selectSql = await this.makeSelectSql_seikyu();
        }
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        this.saveSearchConditions();
        this.fields = this.getFields(this.searchResultDispConditions.checkDocumentsKind);
        if (resultData != null && resultData.length > 0) {
          await this.setResult(resultData);
          if(resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // 検索条件を保存
    saveSearchConditions: function() {
      this.searchResultDispConditions.checkDocumentsKind = this.searchConditions.checkDocumentsKind;
    },
    // SELECT文字列作成（電子書類）
    async makeSelectSql_electronic() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' t_ed.id';
      selectSql += ',m_ed.electronic_documents_class';
      selectSql += ',m_ed.electronic_documents_name';
      selectSql += ',t_ed.office_id';
      selectSql += ',t_ed.client_class';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.client_name_kanji';
      selectSql += ',t_ed.transaction_date';
      selectSql += ',t_ed.money';
      selectSql += ',t_ed.contents';
      selectSql += ',t_ed.file_extension';
      selectSql += ',DATE_FORMAT(t_ed.created, \'%Y/%m/%d %T\') created';
      selectSql += ',t_ed.input_staff_id';
      selectSql += ',m_s.staff_name_kanji';
      selectSql += ',t_ed.input_date';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_electronic_documents AS t_ed ';
      selectSql += 'INNER JOIN m_electronic_documents AS m_ed ';
      selectSql += 'ON t_ed.electronic_documents_class = m_ed.electronic_documents_class ';
      selectSql += 'LEFT JOIN m_staffs AS m_s ';
      selectSql += 'ON t_ed.input_staff_id = m_s.staff_id ';
      // WHERE句
      let where_clause = [];
      // 電子書類区分
      if (this.searchConditions.selectElectronicDocumentsClass != 0) {
        where_clause.push('t_ed.electronic_documents_class = ' + this.searchConditions.selectElectronicDocumentsClass);
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        where_clause.push('t_ed.office_id = ' + this.searchConditions.selectSalesOffice + ' ');
      }
      // 取引日付
      if (this.searchConditions.transactionDateStart != '' && this.searchConditions.transactionDateEnd == '') {
        where_clause.push('t_ed.transaction_date >= ' + '\'' + this.searchConditions.transactionDateStart + '\'');
      } else if(this.searchConditions.transactionDateStart == '' && this.searchConditions.transactionDateEnd != '') {
        where_clause.push('t_ed.transaction_date <= ' + '\'' + this.searchConditions.transactionDateEnd + '\'');
      } else if(this.searchConditions.transactionDateStart != '' && this.searchConditions.transactionDateEnd != '') {
        where_clause.push('t_ed.transaction_date BETWEEN ' + '\'' + this.searchConditions.transactionDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.transactionDateEnd + '\'');
      }
      // 取引先区分
      if (this.searchConditions.selectClientClass != '0') {
        where_clause.push('t_ed.client_class = ' + this.searchConditions.selectClientClass);
      }
      // 取引先コード
      if (this.searchConditions.clientId != '') {
        where_clause.push('t_ed.client_id = ' + this.searchConditions.clientId);
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        let work =`(REPLACE(REPLACE(CONVERT(t_ed.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        work += `OR REPLACE(REPLACE(CONVERT(t_ed.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', ''))`;
        where_clause.push(work);
      }
      // 取引金額
      if (getNullStr(this.searchConditions.moneyStart) != '' && getNullStr(this.searchConditions.moneyEnd) == '') {
        where_clause.push('t_ed.money >= ' + '\'' + this.searchConditions.moneyStart + '\'');
      } else if(getNullStr(this.searchConditions.moneyStart) == '' && getNullStr(this.searchConditions.moneyEnd) != '') {
        where_clause.push('t_ed.money <= ' + '\'' + this.searchConditions.moneyEnd + '\'');
      } else if(getNullStr(this.searchConditions.moneyStart) != '' && getNullStr(this.searchConditions.moneyEnd) != '') {
        where_clause.push('t_ed.money BETWEEN ' + '\'' + this.searchConditions.moneyStart + '\' ' + 'AND ' + '\'' + this.searchConditions.moneyEnd + '\'');
      }
      // 登録日付
      if (this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd == '') {
        where_clause.push('t_ed.input_date >= ' + '\'' + this.searchConditions.inputDateStart + '\'');
      } else if(this.searchConditions.inputDateStart == '' && this.searchConditions.inputDateEnd != '') {
        where_clause.push('t_ed.input_date <= ' + '\'' + this.searchConditions.inputDateEnd + '\'');
      } else if(this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd != '') {
        where_clause.push('t_ed.input_date BETWEEN ' + '\'' + this.searchConditions.inputDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.inputDateEnd + '\'');
      }
      // 登録担当者コード
      if (this.searchConditions.inputStaffId != '') {
        where_clause.push('t_ed.input_staff_id = ' + this.searchConditions.inputStaffId);
      }
      if (where_clause.length > 0) {
        selectSql += ' WHERE ' + where_clause.join(' AND ');
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' t_ed.transaction_date';
      selectSql += ',t_ed.id';
      // LIMIT
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    // SELECT文字列作成（請求書）
    async makeSelectSql_seikyu() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' t_ed.id';
      selectSql += ',t_ed.billing_month_year';
      selectSql += ',t_ed.office_id';
      selectSql += ',t_ed.closing_ymd';
      selectSql += ',t_ed.normal_separate_class';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.client_name_kanji';
      selectSql += ',t_ed.site_id';
      selectSql += ',t_ed.client_site_name_kanji';
      selectSql += ',t_ed.billing_output_class';
      selectSql += ',t_ed.billing_separate_class';
      selectSql += ',t_ed.separate_invoice_no';
      selectSql += ',t_ed.billing_issue_input_date';
      selectSql += ',t_ed.money_billing_total';
      selectSql += ',t_ed.file_extension';
      selectSql += ',DATE_FORMAT(t_ed.created, \'%Y/%m/%d %T\') created';
      selectSql += ',t_ed.input_staff_id';
      selectSql += ',m_s.staff_name_kanji';
      selectSql += ',t_ed.billing_duplicate_class';
      selectSql += ',t_ed.input_date';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_electronic_documents_seikyu AS t_ed ';
      selectSql += 'LEFT JOIN m_staffs AS m_s ';
      selectSql += 'ON t_ed.input_staff_id = m_s.staff_id ';
      // WHERE句
      let where_clause = [];
      // 請求年月
      if (getNullStr(this.searchConditions.billingMonthYear) != '') {
        where_clause.push('t_ed.billing_month_year = ' + '\'' + this.searchConditions.billingMonthYear.replace(/-/g, '') + '\'');
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        where_clause.push('t_ed.office_id = ' + this.searchConditions.selectSalesOffice + ' ');
      }
      // 締切日付
      if (this.searchConditions.transactionDateStart != '' && this.searchConditions.transactionDateEnd == '') {
        where_clause.push('t_ed.closing_ymd >= ' + '\'' + this.searchConditions.transactionDateStart + '\'');
      } else if(this.searchConditions.transactionDateStart == '' && this.searchConditions.transactionDateEnd != '') {
        where_clause.push('t_ed.closing_ymd <= ' + '\'' + this.searchConditions.transactionDateEnd + '\'');
      } else if(this.searchConditions.transactionDateStart != '' && this.searchConditions.transactionDateEnd != '') {
        where_clause.push('t_ed.closing_ymd BETWEEN ' + '\'' + this.searchConditions.transactionDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.transactionDateEnd + '\'');
      }
      // 取引先コード
      if (this.searchConditions.clientId != '') {
        where_clause.push('t_ed.client_id = ' + this.searchConditions.clientId);
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        let work =`(REPLACE(REPLACE(CONVERT(t_ed.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        work += `OR REPLACE(REPLACE(CONVERT(t_ed.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', ''))`;
        where_clause.push(work);
      }
      // 取引金額
      if (getNullStr(this.searchConditions.moneyStart) != '' && getNullStr(this.searchConditions.moneyEnd) == '') {
        where_clause.push('t_ed.money_billing_total >= ' + '\'' + this.searchConditions.moneyStart + '\'');
      } else if(getNullStr(this.searchConditions.moneyStart) == '' && getNullStr(this.searchConditions.moneyEnd) != '') {
        where_clause.push('t_ed.money_billing_total <= ' + '\'' + this.searchConditions.moneyEnd + '\'');
      } else if(getNullStr(this.searchConditions.moneyStart) != '' && getNullStr(this.searchConditions.moneyEnd) != '') {
        where_clause.push('t_ed.money_billing_total BETWEEN ' + '\'' + this.searchConditions.moneyStart + '\' ' + 'AND ' + '\'' + this.searchConditions.moneyEnd + '\'');
      }
      // 登録日付
      if (this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd == '') {
        where_clause.push('t_ed.input_date >= ' + '\'' + this.searchConditions.inputDateStart + '\'');
      } else if(this.searchConditions.inputDateStart == '' && this.searchConditions.inputDateEnd != '') {
        where_clause.push('t_ed.input_date <= ' + '\'' + this.searchConditions.inputDateEnd + '\'');
      } else if(this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd != '') {
        where_clause.push('t_ed.input_date BETWEEN ' + '\'' + this.searchConditions.inputDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.inputDateEnd + '\'');
      }
      // 登録担当者コード
      if (this.searchConditions.inputStaffId != '') {
        where_clause.push('t_ed.input_staff_id = ' + this.searchConditions.inputStaffId);
      }

      if (where_clause.length > 0) {
        selectSql += ' WHERE ' + where_clause.join(' AND ');
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' t_ed.billing_month_year';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.normal_separate_class';
      selectSql += ',t_ed.site_id';
      selectSql += ',t_ed.created';
      // LIMIT
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    // 取得結果セット
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      let duplicateBillingMonthYearList = [];
      let duplicateClientIdList = [];
      let duplicateSiteIdList = [];
      let duplicateBillingDuplicateClassList = [];
      for (let i = 0; i < result.length; i++) {
        if (this.searchResultDispConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          let searchResult = {
            TransactionDate: formatDate(result[i].transaction_date),
            DocumentsId: result[i].id,
            DocumentsClass: result[i].electronic_documents_class + '：' + result[i].electronic_documents_name,
            OfficeName: getListValue(this.searchConditions.salesOffice, result[i].office_id),
            ClientClass: getListValue(Const.ClientClassList, result[i].client_class, 'value', 'text'),
            Client: result[i].client_id + ' ' + result[i].client_name_kanji,
            ClientId: result[i].client_id,
            Money: result[i].money,
            Contents: result[i].contents,
            FileExtension: result[i].file_extension,
            Created: result[i].created,
            InputStaff: result[i].input_staff_id + (getNullStr(result[i].staff_name_kanji) == '' ? '' : ' ' + result[i].staff_name_kanji),
            InputDate: result[i].input_date,
          };
          this.itemList.push(searchResult);
        } else {
          // 電子書類種類：請求書
          let searchResult = {
            Id: result[i].id,
            BillingMonthYear: result[i].billing_month_year,
            BillingMonthYearText: formatDate(result[i].billing_month_year.toString() + '01', 'YYYY年MM月'),
            OfficeName: getListValue(this.searchConditions.salesOffice, result[i].office_id),
            ClosingYmd: formatDate(result[i].closing_ymd),
            BillingIssueInputDate: formatDate(result[i].billing_issue_input_date),
            NormalSeparateClass: result[i].normal_separate_class,
            NormalSeparateClassText: getListValue(Const.NormalSeparateClassList, result[i].normal_separate_class),
            ClientId: result[i].client_id,
            Client: result[i].client_id + ' ' + result[i].client_name_kanji,
            SiteId: result[i].site_id,
            Site: result[i].site_id + ' ' + result[i].client_site_name_kanji,
            BillingOutputClass: result[i].billing_output_class,
            BillingOutputClassText: getListValue(Const.billingOutputClassList, result[i].billing_output_class),
            BillingSeparateClass: result[i].billing_separate_class,
            SeparateInvoiceNo: result[i].separate_invoice_no,
            SeparateInvoiceNoText: '',
            Money: result[i].money_billing_total,
            FileExtension: result[i].file_extension,
            Created: result[i].created,
            InputStaff: result[i].input_staff_id + (getNullStr(result[i].staff_name_kanji) == '' ? '' : ' ' + result[i].staff_name_kanji),
            BillingDuplicateClass: result[i].billing_duplicate_class,
            BillingDuplicateClassText: result[i].billing_duplicate_class == Const.BillingDuplicateClass.none ? '' : '★',
            InputDate: result[i].input_date,
          };
          if (searchResult.NormalSeparateClass == Const.NormalSeparateClass.separate) {
            // 通常分割区分が「1:分割」の場合のみ請求書分割区分の値を表示
            searchResult.BillingSeparateClassText = getListValue(Const.SeparateBillingClassList, result[i].billing_separate_class, 'value', 'text');
            if (searchResult.BillingSeparateClassText == getListValue(Const.SeparateBillingClassList, Const.SeparateBillingClass.billings, 'value', 'text')) {
              // 請求書分割区分が「0:伝票毎」の場合のみ分割請求書番号の値を表示
              searchResult.SeparateInvoiceNoText = result[i].separate_invoice_no;
            }
          } else {
            // 通常分割区分が「1:分割」でない場合は空白を表示
            searchResult.BillingSeparateClassText = '';
          }
          // 重複チェックのために重複リストを作成
          if (searchResult.BillingDuplicateClass != Const.BillingDuplicateClass.none) {
            // 請求重複区分が「0：重複なし」以外
            // 請求年月
            if (duplicateBillingMonthYearList.findIndex(el => el == searchResult.BillingMonthYear) == -1) {
              duplicateBillingMonthYearList.push(searchResult.BillingMonthYear);
            }
            // 取引先コード
            if (duplicateClientIdList.findIndex(el => el == searchResult.ClientId) == -1) {
              duplicateClientIdList.push(searchResult.ClientId);
            }
            // 現場コード
            if (duplicateSiteIdList.findIndex(el => el == searchResult.SiteId) == -1) {
              duplicateSiteIdList.push(searchResult.SiteId);
            }
            // 請求重複区分
            if (duplicateBillingDuplicateClassList.findIndex(el => el == searchResult.BillingDuplicateClass) == -1) {
              duplicateBillingDuplicateClassList.push(searchResult.BillingDuplicateClass);
            }
          }
          this.itemList.push(searchResult);
        }
      }
      if (this.searchResultDispConditions.checkDocumentsKind == Const.DocumentsKind.seikyu &&
      duplicateBillingMonthYearList.length > 0) {
        // 電子書類種類：請求書
        // 重複データリストが1件以上の場合
        // 最新の重複情報を設定
        await this.setLatestDuplicate(duplicateBillingMonthYearList, duplicateClientIdList, duplicateSiteIdList, duplicateBillingDuplicateClassList);
      }
    },
    // 最新の重複情報を設定
    async setLatestDuplicate(duplicateBillingMonthYearList, duplicateClientIdList, duplicateSiteIdList, duplicateBillingDuplicateClassList) {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' t_ed.billing_month_year';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.site_id';
      selectSql += ',t_ed.billing_duplicate_class';
      selectSql += ',DATE_FORMAT(MAX(t_ed.created), \'%Y/%m/%d %T\') max_created';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_electronic_documents_seikyu AS t_ed ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 't_ed.billing_month_year IN (' + duplicateBillingMonthYearList.join(',') + ') ';
      selectSql += 'AND t_ed.client_id IN (' + duplicateClientIdList.join(',') + ') ';
      selectSql += 'AND t_ed.site_id IN (' + duplicateSiteIdList.join(',') + ') ';
      selectSql += 'AND t_ed.billing_duplicate_class IN (' + duplicateBillingDuplicateClassList.join(',') + ') ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' t_ed.billing_month_year';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.site_id';
      selectSql += ',t_ed.billing_duplicate_class';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' t_ed.billing_month_year';
      selectSql += ',t_ed.client_id';
      selectSql += ',t_ed.site_id';
      selectSql += ',t_ed.billing_duplicate_class';

      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null && resultData.length > 0) {
        for (let i = 0; i < this.itemList.length > 0; i++) {
          let resultDataRow = resultData.find(el => el.billing_month_year == this.itemList[i].BillingMonthYear &&
                                              el.client_id == this.itemList[i].ClientId &&
                                              el.site_id == this.itemList[i].SiteId &&
                                              el.billing_duplicate_class == this.itemList[i].BillingDuplicateClass &&
                                              el.max_created == this.itemList[i].Created);
          if (resultDataRow != undefined) {
            this.itemList[i].IsLatest = true;
          }
        }
      }
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'SAVE-DOCUMENTS-INPUT' });
    },
    // 取引先検索モーダル表示
    showClientSearchModal: function() {
      if (this.searchConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        if (this.searchConditions.selectClientClass == '0') {
          // 取引先区分が0の場合は無効
          return;
        } else {
          this.modalClientClass = this.searchConditions.selectClientClass;
        }
      } else {
        // 電子書類種類：請求書
        this.modalClientClass = Const.ClientClass.customer;
      }
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        this.searchConditions.clientId = clientItems.detail.client_id;
      }
    },
    // 担当者検索
    showStaffSearchModal: function() {
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if (typeof staffItems != 'undefined') {
        this.searchConditions.inputStaffId = getNullStr(staffItems.detail.staff_id);
      }
    },
    // ダウンロードボタン押下時
    async clickDownloadBtn(item) {
      let dateBefore10Years = formatDateCalc(formatCurDate(), -10, 0, 0);
      if (dateConsistency(dateBefore10Years, item.InputDate) == false) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2081'].replace('%arg1%', '10年').replace('%arg2%', 'ダウンロード'));
        scrollTo(0,0);
        return;
      }
      let filePath = '';
      let fileName = '';
      if (this.searchResultDispConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 取引日付を「YYYYMMDD」形式にする
        let dateTransactionDate = formatDate(item.TransactionDate).replace(/\//g, '');
        // 電子書類種類：電子書類
        filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.electronicDocuments).key;
        // 年のディレクトリの下にファイルが入る
        filePath += '/';
        filePath += formatDate(dateTransactionDate, 'YYYY');
        // ファイル名は「取引日付_ID_取引先コード_取引金額.【拡張子】」の形式
        fileName = dateTransactionDate;
        fileName += '_';
        fileName += item.DocumentsId;
        fileName += '_';
        fileName += item.ClientId;
        fileName += '_';
        fileName += item.Money;
        fileName += '.';
        fileName += item.FileExtension;
      } else {
        // 電子書類種類：請求書
        // ファイルパス
        filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.seikyu).key;
        // 年のディレクトリの下にファイルが入る
        filePath += '/';
        filePath += formatDate(getNullStr(item.BillingMonthYear) + '01', 'YYYY');
        // ファイル名は「請求年月_通常分割区分_取引先コード_現場コード_請求書分割区分_分割請求書番号_請求金額_ID.【拡張子】」の形式
        fileName = item.BillingMonthYear;
        fileName += '_';
        fileName += item.NormalSeparateClass;
        fileName += '_';
        fileName += item.ClientId;
        fileName += '_';
        fileName += item.SiteId;
        fileName += '_';
        fileName += item.BillingSeparateClass;
        fileName += '_';
        fileName += item.SeparateInvoiceNo;
        fileName += '_';
        fileName += item.Money;
        fileName += '_';
        fileName += item.Id;
        fileName += '.';
        fileName += item.FileExtension;
      }

      let key = filePath + '/' + fileName;
      //console.log(key);
      if (await downloadFileFromS3(key) == false) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2078']);
        scrollTo(0,0);
      }
    },
    // 削除ボタン押下時
    async clickDeleteBtn(item) {
      const functionName = 'clickDeleteBtn';
      // 保存確認ダイアログを表示
      let confirmMessage = [];
      confirmMessage.push(this.$createElement('div','選択されたデータを削除します。'));
      confirmMessage.push(this.$createElement('div','よろしいですか？'));
      let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '削除確認'});
      if (retConfirm == true) {
        this.$store.commit('setLoading', true);
        //console.log(item);
        // 実行するSQL保持
        let sqlList = [];
        let filePath = '';
        let fileName = '';
        if (this.searchResultDispConditions.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          // 削除履歴へのINSRT文
          let insertSql = this.insertElectronicDocumentsDelHistories(item.DocumentsId);
          //console.log(insertSql);
          sqlList.push(insertSql);
          // DELETE文
          let deleteSql = 'DELETE FROM t_electronic_documents';
          deleteSql += ' WHERE ';
          deleteSql += 'id = ' + item.DocumentsId + ' ';
          //console.log(deleteSql);
          sqlList.push(deleteSql);
          // 取引日付を「YYYYMMDD」形式にする
          let dateTransactionDate = formatDate(item.TransactionDate).replace(/\//g, '');
          // ファイルパス
          filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.electronicDocuments).key;
          // 年のディレクトリの下にファイルが入る
          filePath += '/';
          filePath += formatDate(dateTransactionDate, 'YYYY');
          // ファイル名は「取引日付_ID_取引先コード_取引金額.【拡張子】」の形式
          fileName = dateTransactionDate;
          fileName += '_';
          fileName += item.DocumentsId;
          fileName += '_';
          fileName += item.ClientId;
          fileName += '_';
          fileName += item.Money;
          fileName += '.';
          fileName += item.FileExtension;
        } else {
          // 電子書類種類：請求書
          // 削除履歴へのINSRT文
          let insertSql = this.insertElectronicDocumentsSeikyuDelHistories(item.Id);
          //console.log(insertSql);
          sqlList.push(insertSql);
          // DELETE文
          let deleteSql = 'DELETE FROM t_electronic_documents_seikyu';
          deleteSql += ' WHERE ';
          deleteSql += 'ID = ' + item.Id + ' ';
          //console.log(deleteSql);
          sqlList.push(deleteSql);
          // 重複解消文
          let billingDuplicateClass = getBillingDuplicateClass(item.BillingOutputClass, item.NormalSeparateClass, item.BillingSeparateClass);
          let updateSql = updateElectronicDocumentsSeikyuDuplicateClass(item.BillingMonthYear, item.ClientId, billingDuplicateClass, this.loginId, true);
          //console.log(updateSql);
          sqlList.push(updateSql);
          // ファイルパス
          filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.seikyu).key;
          // 年のディレクトリの下にファイルが入る
          filePath += '/';
          filePath += formatDate(getNullStr(item.BillingMonthYear) + '01', 'YYYY');
          // ファイル名は「請求年月_通常分割区分_取引先コード_現場コード_請求書分割区分_分割請求書番号_請求金額_ID.【拡張子】」の形式
          fileName = item.BillingMonthYear;
          fileName += '_';
          fileName += item.NormalSeparateClass;
          fileName += '_';
          fileName += item.ClientId;
          fileName += '_';
          fileName += item.SiteId;
          fileName += '_';
          fileName += item.BillingSeparateClass;
          fileName += '_';
          fileName += item.SeparateInvoiceNo;
          fileName += '_';
          fileName += item.Money;
          fileName += '_';
          fileName += item.Id;
          fileName += '.';
          fileName += item.FileExtension;
        }
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            this.$store.commit('setLoading', false);
            scrollTo(0,0);
            return;
          }
        } catch (error) {
          console.log(error);
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
          this.$store.commit('setLoading', false);
          scrollTo(0,0);
          return;
        }
        let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
        if (retResult == true) {
          // 削除に成功した場合、S3のファイルも削除
          await uploadFileRemoveFromS3(filePath + '/' + fileName, MODULE_NAME);
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
          await this.searchButton();
        } else {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        }
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    // 電子書類データ＿削除履歴INSERT文
    insertElectronicDocumentsDelHistories: function(id) {
      let colList = [];
      // ID
      colList.push(CreateColRow('id', null, 'NUMBER'));
      // 電子書類区分
      colList.push(CreateColRow('electronic_documents_class', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 取引先区分
      colList.push(CreateColRow('client_class', null, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 取引先名（漢字）
      colList.push(CreateColRow('client_name_kanji', null, 'VARCHAR'));
      // 取引先名（カナ）
      colList.push(CreateColRow('client_name_kana', null, 'VARCHAR'));
      // 取引日
      colList.push(CreateColRow('transaction_date', null, 'DATE'));
      // 取引金額
      colList.push(CreateColRow('money', null, 'NUMBER'));
      // 内容
      colList.push(CreateColRow('contents', null, 'VARCHAR'));
      // ファイル拡張子
      colList.push(CreateColRow('file_extension', null, 'VARCHAR'));
      // 登録日
      colList.push(CreateColRow('input_date', null, 'DATE'));
      // 登録担当者コード
      colList.push(CreateColRow('input_staff_id', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSql = '';
      insertSql += 'INSERT INTO t_electronic_documents_del_histories (' + CreateInsertSql(colList, 'col', 't_electronic_documents_del_histories') + ') ';
      insertSql += 'SELECT ';
      insertSql += 'id';
      insertSql += ',electronic_documents_class';
      insertSql += ',office_id';
      insertSql += ',client_class';
      insertSql += ',client_id';
      insertSql += ',client_name_kanji';
      insertSql += ',client_name_kana';
      insertSql += ',transaction_date';
      insertSql += ',money';
      insertSql += ',contents';
      insertSql += ',file_extension';
      insertSql += ',input_date';
      insertSql += ',input_staff_id';
      insertSql += ',\'' + this.loginId + '\'';
      insertSql += ',\'' + this.loginId + '\'';
      insertSql += ' FROM t_electronic_documents';
      insertSql += ' WHERE ';
      insertSql += 'id=' + id + ' ';

      return insertSql;
    },
    // 電子書類データ＿請求書データ＿削除履歴INSERT文
    insertElectronicDocumentsSeikyuDelHistories: function(id) {
      let colList = [];
      // ID
      colList.push(CreateColRow('id', null, 'NUMBER'));
      // 請求年月
      colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 締切日付
      colList.push(CreateColRow('closing_ymd', null, 'DATE'));
      // 通常分割区分
      colList.push(CreateColRow('normal_separate_class', null, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 取引先名（漢字）
      colList.push(CreateColRow('client_name_kanji', null, 'VARCHAR'));
      // 取引先名（カナ）
      colList.push(CreateColRow('client_name_kana', null, 'VARCHAR'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 現場名（漢字）
      colList.push(CreateColRow('client_site_name_kanji', null, 'VARCHAR'));
      // 請求書発行登録日
      colList.push(CreateColRow('billing_issue_input_date', null, 'DATE'));
      // 請求書分割区分
      colList.push(CreateColRow('billing_separate_class', null, 'NUMBER'));
      // 分割請求書番号
      colList.push(CreateColRow('separate_invoice_no', null, 'NUMBER'));
      // 請求金額
      colList.push(CreateColRow('money_billing_total', null, 'NUMBER'));
      // ファイル拡張子
      colList.push(CreateColRow('file_extension', null, 'VARCHAR'));
      // 登録日
      colList.push(CreateColRow('input_date', null, 'DATE'));
      // 登録担当者コード
      colList.push(CreateColRow('input_staff_id', null, 'NUMBER'));
      // 請求重複区分
      colList.push(CreateColRow('billing_duplicate_class', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSql = '';
      insertSql += 'INSERT INTO t_electronic_documents_seikyu_del_histories (' + CreateInsertSql(colList, 'col', 't_electronic_documents_seikyu_del_histories') + ') ';
      insertSql += 'SELECT ';
      insertSql += 'id';
      insertSql += ',billing_month_year';
      insertSql += ',office_id';
      insertSql += ',closing_ymd';
      insertSql += ',normal_separate_class';
      insertSql += ',client_id';
      insertSql += ',client_name_kanji';
      insertSql += ',client_name_kana';
      insertSql += ',billing_output_class';
      insertSql += ',site_id';
      insertSql += ',client_site_name_kanji';
      insertSql += ',billing_issue_input_date';
      insertSql += ',billing_separate_class';
      insertSql += ',separate_invoice_no';
      insertSql += ',money_billing_total';
      insertSql += ',file_extension';
      insertSql += ',input_date';
      insertSql += ',input_staff_id';
      insertSql += ',billing_duplicate_class';
      insertSql += ',\'' + this.loginId + '\'';
      insertSql += ',\'' + this.loginId + '\'';
      insertSql += ' FROM t_electronic_documents_seikyu';
      insertSql += ' WHERE ';
      insertSql += 'id=' + id + ' ';

      return insertSql;
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // フィールド取得
    getFields: function(checkDocumentsKind) {
      let retFields = [];
      if (checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        retFields.push({key: 'TransactionDate', label: '取引日付', sortable: true});
        retFields.push({key: 'DocumentsId', label: '電子書類キー', sortable: true});
        retFields.push({key: 'DocumentsClass', label: '電子書類区分', sortable: true});
        retFields.push({key: 'OfficeName', label: '営業所', sortable: true});
        retFields.push({key: 'ClientClass', label: '取引先区分', sortable: true});
        retFields.push({key: 'Client', label: '取引先', sortable: true});
        retFields.push({key: 'Money', label: '取引金額', sortable: true, tdClass: 'text-right'});
        retFields.push({key: 'Contents', label: '内容', sortable: true});
      } else {
        // 電子書類種類：請求書
        retFields.push({key: 'BillingMonthYearText', label: '請求年月', sortable: true});
        retFields.push({key: 'BillingDuplicateClassText', label: '重複', sortable: true});
        retFields.push({key: 'ClosingYmd', label: '締切日付', sortable: true});
        retFields.push({key: 'BillingIssueInputDate', label: '請求書発行登録日', sortable: true});
        retFields.push({key: 'NormalSeparateClassText', label: '通常分割区分', sortable: true});
        retFields.push({key: 'OfficeName', label: '営業所', sortable: true});
        retFields.push({key: 'Client', label: '取引先', sortable: true});
        retFields.push({key: 'Site', label: '現場', sortable: true});
        retFields.push({key: 'BillingOutputClassText', label: '出力区分', sortable: true});
        retFields.push({key: 'BillingSeparateClassText', label: '請求書分割区分', sortable: true});
        retFields.push({key: 'SeparateInvoiceNoText', label: '分割請求書番号', sortable: true});
        retFields.push({key: 'Money', label: '請求金額', sortable: true, tdClass: 'text-right'});
      }
      retFields.push({key: 'Created', label: '登録日', sortable: true});
      retFields.push({key: 'InputStaff', label: '登録担当者', sortable: true});
      retFields.push({key: 'Operation', label: '操作'});
      return retFields;
    },
  },
}
</script>