<template>
  <div class="text-center">
    <div v-if="btnFlg == false">
      <div v-for="(page, index) of pages" :key="index">
        <TemplateInventorySheet :id="`page-${index}`"/>
      </div>
    </div>
    <div v-if="btnFlg == true">
      <b-alert show variant="info">
        <ul style="list-style: none;">
          <li>{{message1}}</li>
          <li>{{message2}}</li>
        </ul>
      </b-alert>
      <b-btn-toolbar class="mt-2">
        <b-button v-for="(btnPrint, index) of btnPrintList" :key="index" class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover @click="onPrintButtonClick(officeId, btnPrint.startIndex)">
          <span class="oi oi-document"></span> {{ btnPrint.name }}
        </b-button>
      </b-btn-toolbar>
    </div>
  </div>
</template>
<script>
import TemplateInventorySheet from '@/assets/svg/inventory_Sheet.svg';
import { addOperationLogs, executeSelectSql, executeTransactSqlList, formatCurDate, formatDate, setPaperA4 } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import store from '@/store';

// モジュール名
const MODULE_NAME = 'inventory-print-sheet';
// ページ項目テンプレート
const PAGE_ITEMS_TEMPLATE = {
  office_info: '', // 営業所情報
  place: '', // 在庫置き場所
  inventory_date: '', // 棚卸日（棚卸前処理実施日）
  datalist: [], // 棚卸製品一覧
};
// ページあたりの出力件数
const PER_PAGE = 40;
// アライン
const ITEM_ALIGN_LEFT = 0;
const ITEM_ALIGN_CENTER = 1;
const ITEM_ALIGN_RIGHT = 2;
// 最大ページ数
const MAX_PAGE_CNT = 80;

export default {
  menu_type: 'user',
  name: 'INVENTORY-SHEET',
  components: {
    TemplateInventorySheet
  },
  data() {
    return {
      title: '棚卸表',
      pages: [],
      btnFlg: false,
      btnPrintList: [],
      startIndex: 0,
      officeId: null,
      message1: DISP_MESSAGES.INFO['0001'].replace('%arg1%', MAX_PAGE_CNT),
      message2: DISP_MESSAGES.INFO['0002'],
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  beforeMount() {
    this.$store.commit('setLoading', true);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    // 印刷レイアウトを設定します。
    setPaperA4();
    // 帳票を作成します。
    const query = this.$route.query;
    this.officeId = query.selectSalesOffice;
    this.createForm(query.selectSalesOffice);
  },
  /**
   * 画面閉じる時のイベント
   */
  onunload() {
    // メモリ解放
    this.pages = null;
  },
  methods: {
    /**
     * 帳票を作成します。
     * @param {String} selectSalesOffice - 営業所
     */
    createForm(selectSalesOffice) {
      const functionName = 'createForm';
      this.getInventoriesHistories(selectSalesOffice).then(tInventoriesHistories => {
        if (tInventoriesHistories.length === 0) {
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2050'], {
            title: this.title
          }).then(() => {
            window.close();
          });
          return;
        }
        const inventoryNo = tInventoriesHistories[0].inventory_no;

        // 棚卸製品からデータを取得します。
        this.fetchData(inventoryNo).then(response => {
          if (response === null) {
            this.$store.commit('setLoading', false);
            this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2010'].replace('%arg1%', '棚卸製品'), {
              title: this.title
            }).then(() => {
              window.close();
            });
            return;
          }

          // 棚卸履歴の棚卸表印刷日時を更新します。
          this.updateInventoriesHistories(inventoryNo).then(async updateInventoriesHistoriesResult => {
            if (updateInventoriesHistoriesResult) {
              // 帳票データを作成します。this.pages にデータが代入された時点で、
              // 作成するページ分の帳票テンプレートがレンダリングされます。
              let pages = this.createFormData(response);

              if (this.$route.query.startIndex == -1) {
                if (pages.length > MAX_PAGE_CNT) {
                  // ページ数が最大数を超過していた場合、ページ毎の印刷ボタン画面を表示
                  this.setPagePrintBtnWindow(pages.length);
                  pages = null;
                  this.$store.commit('setLoading', false);
                  return;
                }
              } else {
                this.startIndex = Number(this.$route.query.startIndex);
                // スタートページよりも前の要素を除外
                if (this.startIndex > 0) {
                  // 最初のページから印刷以外
                  pages.splice(0, this.startIndex - 1);
                } 
                // 最大ページ数よりも後ろの要素を除外
                if (pages.length > MAX_PAGE_CNT) {
                  // 最大ページ数よりも後ろの要素がある場合
                  pages.splice(MAX_PAGE_CNT, pages.length);
                }
              }
              this.pages = pages;

              // 出力日時文字列を作成します。
              const outputDateTime = formatCurDate('YY/MM/DD HH:mm');

              // 帳票テンプレートに対して値の置換と表示位置調整を行います。
              this.setPageData(0, outputDateTime);
            } else {
              await addOperationLogs('Error', MODULE_NAME, functionName, `棚卸履歴の棚卸表印刷日時更新でエラーが発生しました：営業所コード=${selectSalesOffice}`);
              this.$store.commit('setLoading', false);
              this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
                title: this.title
              }).then(() => {
                window.close();
              });
              return;
            }
          });
        }).catch(async error => {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2001'], {
            title: this.title
          }).then(() => {
            window.close();
          });
        });
      }).catch(async error => {
        await addOperationLogs('Error', MODULE_NAME, functionName, `棚卸履歴からの棚卸No.の取得でエラーが発生しました：営業所コード=${selectSalesOffice}`, error);
        this.$store.commit('setLoading', false);
        this.$bvModal.msgBoxOk(DISP_MESSAGES.DANGER['3005'], {
          title: this.title
        }).then(() => {
          window.close();
        });
      });
    },
    /**
     * 
     */
    async getInventoriesHistories(officeId) {
      const sql = 'SELECT' +
          ' MAX(inventory_no) AS inventory_no ' +
        'FROM ' +
          't_inventories_histories ' +
        'WHERE ' +
          `office_id = ${officeId} ` +
        'GROUP BY office_id';
      return await executeSelectSql(sql);
    },
    /**
     * 棚卸製品データを取得します。
     * @param {Number} inventoryNo - 棚卸No.
     * @returns {Array} 棚卸製品データ
     */
    async fetchData(inventoryNo) {
      const functionName = 'fetchData';
      const sql = await this.createSQL(inventoryNo);
      if (sql === null) {
        return null;
      }
      const sqls = [ sql ];

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        return null;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        return null;
      }
      return body.data[0];
    },
    /**
     * 棚卸製品取得用SQLを作成します。
     * @param {String} inventoryNo - 棚卸No.
     * @returns {String} 成功した場合はSQL文、失敗した場合はnull
     */
    async createSQL(inventoryNo) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'select ';
      selectSql += '    ti.inventory_no, ';
      selectSql += '    tih.preprocess_datetime, ';
      selectSql += '    ti.office_id, ';
      selectSql += '    IFNULL(mo.office_name_kanji, \'\') AS office_name_kanji, ';
      selectSql += '    ti.product_id, ';
      selectSql += '    IF(mpd.place_1 IS NULL, \'\', TRIM(mpd.place_1)) AS place_1, ';
      selectSql += '    IF(mpd.place_2 IS NULL, \'\', TRIM(mpd.place_2)) AS place_2, ';
      selectSql += '    IF(mpd.place_3 IS NULL, \'\', TRIM(mpd.place_3)) AS place_3, ';
      selectSql += '    IF(mpd.place_4 IS NULL, \'\', TRIM(mpd.place_4)) AS place_4, ';
      selectSql += '    IFNULL(ti.product_name, \'\') AS product_name, ';
      selectSql += '    ti.inventory_count, ';
      selectSql += '    ti.shelves_count, ';
      selectSql += '    ti.inventory_entry_user_id, ';
      selectSql += '    IFNULL(ms.staff_name_kanji, \'\') AS staff_name_kanji ';
      selectSql += 'from ';
      selectSql += '    t_inventories ti ';
      selectSql += '    left outer join ';
      selectSql += '        t_inventories_histories tih ';
      selectSql += '    on  ti.inventory_no = tih.inventory_no ';
      selectSql += '    left outer join ';
      selectSql += '        m_products_details mpd ';
      selectSql += '    on  ti.office_id = mpd.office_id ';
      selectSql += '    and ti.product_id = mpd.product_id ';
      selectSql += '    left outer join ';
      selectSql += '        m_offices mo ';
      selectSql += '    on  ti.office_id = mo.office_id ';
      selectSql += '    left outer join ';
      selectSql += '        m_staffs ms ';
      selectSql += '    on  ti.inventory_entry_user_id = ms.staff_id ';
      selectSql += 'where ';
      selectSql += `    ti.inventory_no = ${inventoryNo} `;
      selectSql += 'order by ';
      selectSql += '    ti.inventory_no, ';
      selectSql += '    ti.office_id, ';
      selectSql += '    mpd.place_1, ';
      selectSql += '    mpd.place_2, ';
      selectSql += '    mpd.place_3, ';
      selectSql += '    mpd.place_4, ';
      selectSql += '    ti.product_id ';

      return selectSql;
    },
    /**
     * 棚卸履歴の棚卸表出力日時を更新します。
     * @param {Number} inventoryNo - 棚卸No.
     * @returns {Boolean} 成功した場合はtrue、失敗した場合はfalse
     */
    async updateInventoriesHistories(inventoryNo) {
      const functionName = 'updateInventoriesHistories';
      return executeTransactSqlList(
        [
          'UPDATE ' +
            't_inventories_histories ' +
          'SET' +
            ' print_inventory_sheet_datetime = CURRENT_TIMESTAMP()' +
            ',updated = CURRENT_TIMESTAMP()' +
            `,updated_user = '${store.getters.user.username}' ` +
          'WHERE ' +
            `inventory_no = ${inventoryNo};`
        ],
        MODULE_NAME, functionName
      );
    },
    /**
     * 棚卸製品データから帳票データを作成します。
     * @param {Array} records - 棚卸製品データ
     * @returns {Array} 作成した帳票データ
     */
    createFormData(records) {
      const pages = [];
      let per_place = ''; //前レコードの置き場所コード
      let current_place = ''; //カレントレコードの置き場所コード
      let page = { ...PAGE_ITEMS_TEMPLATE };

      // データの設定
      if (records.length > 0) {
        for (const record of records) {
          // カレントレコードの置き場所コード
          current_place = [record.place_1.padEnd(2, ' ') , record.place_2.padEnd(2, ' ') , record.place_3.padEnd(2, ' ') , record.place_4.padEnd(2, ' ')].join('-');
          if (pages.length === 0 || page.datalist.length === PER_PAGE || per_place != current_place) {
            // 空行追加
            if(per_place != current_place){
              while (page.datalist.length < PER_PAGE) {
                // 棚卸製品一覧に空製品追加
                page.datalist.push({
                  productId: '',
                  productName: '',
                  inventoryCount: ''
                });
              }
            }
            // 画面初期化
            page = [];
            page = { ...PAGE_ITEMS_TEMPLATE };
            page.datalist = [];
            // 営業所情報
            page.office_info = String(record.office_id).padStart(2, '0') + '　' + record.office_name_kanji;
            // 棚卸日
            page.inventory_date = record.preprocess_datetime;
            // 置き場所コード
            page.place = current_place;
            pages.push(page);
          }
          // 棚卸製品一覧作成
          page.datalist.push({
            productId: record.product_id,
            productName: record.product_name,
            inventoryCount: record.inventory_count
          });
          // 前レコードの置き場所コード更新
          per_place = current_place;
        }
      }

      // 最後のページに空行追加
      if(pages[pages.length - 1].datalist.length < PER_PAGE){
        while (pages[pages.length - 1].datalist.length < PER_PAGE) {
          // 棚卸製品一覧に空製品追加
          pages[pages.length - 1].datalist.push({
            productId: '',
            productName: '',
            inventoryCount: ''
          });
        }
      }

      return pages;
    },
    /**
     * 指定されたページのSVGに帳票データを設定します。
     * @param {Number} pageIndex - ページ番号（0始まり）
     * @param {String} outDateTime - 帳票出力日時置換文字列
     * @returns {Promise} Promiseオブジェクト
     */
    setPageData(pageIndex, outputDateTime) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          try {
            const pageData = this.pages[pageIndex];
            const page = $(`svg#page-${pageIndex}`);
            this.resizePage(page);

            const tspanList = page.find('tspan');
            const productIdElement = tspanList.filter((index, ele) => ele.textContent === '製品コード');
            const productIdElementX = Number(productIdElement.attr('x'));
            const productIdElementWidth = productIdElement[0].getBBox().width;
            const productNameElement = tspanList.filter((index, ele) => ele.textContent === '製品名');
            const productNameElementX = Number(productNameElement.attr('x'));
            const productNameElementWidth = productNameElement[0].getBBox().width;
            const inventoryCountElement = tspanList.filter((index, ele) => ele.textContent === '現在庫数');
            const inventoryCountElementX = Number(inventoryCountElement.attr('x'));
            const inventoryCountElementWidth = inventoryCountElement[0].getBBox().width;
            
            for (let rowIndex = 0; rowIndex < pageData.datalist.length; rowIndex++) {
              const rowData = pageData.datalist[rowIndex];
              const rowNo = String(rowIndex + 1).padStart(2, '0');

              if (rowIndex === 0) {
                // 棚卸表印刷日付
                this.replace(page, '%OUTPUT_DATETIME%', outputDateTime);
                // ページ番号
                this.replace(page, '%PAGE%', String(pageIndex + this.startIndex + 1).padStart(3, ' '));
                // 営業所情報
                this.replace(page, '%OFFICE_NAME%', '営業所 ：　' + pageData.office_info);
                // 棚卸日
                this.replace(page, '%INVENTORY_DATE%', '棚卸日 ：　' + formatDate(pageData.inventory_date));
                // 在庫置き場所コード
                this.replace(page, '%PLACE%', '置場所 ：　' + pageData.place);
              }

              // 製品コード
              this.replaceAlgin(page, `%PRODUCT_ID_${rowNo}%`, rowData.productId, productIdElementX, productIdElementWidth, ITEM_ALIGN_CENTER);
              // 製品名
              this.replaceAlgin(page, `%PRODUCT_NAME_${rowNo}%`, rowData.productName, productNameElementX, productNameElementWidth, ITEM_ALIGN_LEFT);
              // 現在庫数
              this.replaceAlgin(page, `%CURRENT_STOCK_${rowNo}%`, rowData.inventoryCount, inventoryCountElementX, inventoryCountElementWidth, ITEM_ALIGN_RIGHT);

            }

            const nextPageIndex = pageIndex + 1;
            if (nextPageIndex < this.pages.length) {
              this.setPageData(nextPageIndex, outputDateTime);
            } else {
              this.$store.commit('setLoading', false);
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 100);
      });
    },
    /**
     * ページサイズを調整します。
     * @param {Object} page - ページ
     */
    resizePage(page) {
      page.attr('viewBox', `0 0 ${page.innerWidth()} ${page.innerHeight()}`);
      page.attr('width', '315mm');
      page.attr('height', '445mm');
    },
    /**
     * 指定されたページのキーワード置換を行います。
     * @param {Object} page - SVG
     * @param {String} keyword - キーワード
     * @param {String} value - 置換文字列
     */
    replace(page, keyword, value) {
      const element = page.find(`tspan:contains("${keyword}")`);
      element.text(value);
      return element;
    },
    /**
     * 指定されたページのキーワード置換を行います。
     * @param {Object} page - SVG
     * @param {String} keyword - キーワード
     * @param {String} value - 置換文字列
     * @param {Number} titleX - タイトルのX座標
     * @param {Number} titleWidth - タイトルの幅
     * @param {Number} pos - 配置位置
     */
    replaceAlgin(page, keyword, value, titleX, titleWidth, pos) {
      const element = this.replace(page, keyword, value);
      if (pos === ITEM_ALIGN_LEFT) {
        element.attr('x', titleX);
      } else if (pos === ITEM_ALIGN_CENTER) {
        const elementWidth = element[0].getBBox().width;
        if (titleWidth > elementWidth) {
          element.attr('x', Number(titleX) + ((titleWidth - elementWidth) / 2));
        } else {
          element.attr('x', Number(element.attr('x')) + ((elementWidth - titleWidth) / 2));
        }
      } else if (pos === ITEM_ALIGN_RIGHT) {
        element.attr('x', Number(titleX) + titleWidth - element[0].getBBox().width);
      }
    },
    /**
     * ページ毎印刷ボタン画面を設定します。
     * @param {Number} pageCnt - ページ数
     */
    setPagePrintBtnWindow: function(pageCnt) {
      let cntNokori = pageCnt;
      this.btnPrintList = [];
      let btnCnt = 0;
      while (cntNokori > 0) {
        // 全ページの印刷ボタンを用意するまでループ
        cntNokori -= MAX_PAGE_CNT;
        this.btnPrintList.push({name: (btnCnt * MAX_PAGE_CNT + 1).toString() + 'ページ目から印刷', startIndex: btnCnt * MAX_PAGE_CNT });
        btnCnt++;
      }
      this.btnFlg = true;
    },
    /**
     * PDF印刷ボタンクリックイベント処理
     */
    onPrintButtonClick(officeId, startIndex) {
      let query = {
        selectSalesOffice: officeId,
        startIndex: startIndex,
      };
      let route = this.$router.resolve({ name: 'INVENTORY-PRINT-SHEET', query: query});
      window.open(route.href, '_blank');
    },
  }
}
</script>
<style scoped>
</style>