<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container>
                      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-container fluid>
                        <b-row class="mt-2">
                          <!-- 営業所入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                            >
                              <b-form-select
                                v-model="searchConditions.selectSalesOffice"
                                :options="salesOffice"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 注文番号入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="注文番号"
                            >
                              <validation-provider name="selectOrderNumber" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-form-input
                                    v-model="searchConditions.selectOrderNumber"
                                    value-field="id"
                                  >
                                  </b-form-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- 取引先入力欄 -->
                        <b-row class="mt-2">
                          <b-col lg="6">
                            <b-form-group
                              label="取引先"
                            >
                              <validation-provider name="selectClientId" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input v-model="searchConditions.selectClientId" />
                                    <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- 製品コード入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="製品コード"
                            >
                              <validation-provider name="selectProductId" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input v-model="searchConditions.selectProductId"/>
                                    <b-input-group-text @click="showProductSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '2']" @shortkey="showProductSearchModal()" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-2">
                          <!-- 発注日付入力欄 -->
                          <b-col>
                            <validation-provider name="selectOrderDateStart" :rules="{dateConsistency:searchConditions.selectOrderDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="発注日付"
                                label-for="datepicker1"
                                description="パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、注文番号か取引先コードを指定してください。"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker
                                      id="datepicker1"
                                      name="selectOrderDateStart"
                                      class="form-control"
                                      v-model="searchConditions.selectOrderDateStart"
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectOrderDateStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <b-input-group-text>～</b-input-group-text>
                                    <b-form-datepicker
                                      id="datepicker2"
                                      name="selectOrderDateEnd"
                                      class="form-control"
                                      v-model="searchConditions.selectOrderDateEnd"
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectOrderDateEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-container>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
          <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="2"/>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, getListValue, /*executeSelectSql,*/ formatDate, formatCurDate, formatDateCalc } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices } from '@/graphql/queries';
// モジュール名
const MODULE_NAME = 'order-inquiry';
export default {
  name: 'ORDER-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    PRODUCTSEARCH
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注照会',
      // アラート
      alertDanger: [],
      alertWarning: [],
      // コンボボックス
      salesOffice: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: null,
        selectOrderNumber: null,
        selectClientId: null,
        selectProductId: null,
        selectOrderDateStart: null,
        selectOrderDateEnd: null,
      },
      // 全件検索用
      addConditions:{id: 0, name: '全て', text: '全て'},
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0, // not null
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 製品検索情報
      productSearchProp:{
        office_id: null,
        office_name: null,
        client_id: null,
        client_class: null,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コンボボックスの作成
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'orderDate',
          label: '発注日付',
          sortable: true,
        },
        {
          key: 'orderNumber',
          label: '注文番号',
          sortable: true,
        },
        {
          key: 'clientId',
          label: '取引先',
          sortable: true,
        },
        {
          key: 'orderClass',
          label: '発注種別',
          sortable: true,
        },
        {
          key: 'productName',
          label: '製品名',
          sortable: true,
        },
        {
          key: 'remainingQuantity',
          label: '残数',
          sortable: true,
        },
        {
          key: 'directClientId',
          label: '直送取引先',
          sortable: true,
        },
        {
          key: 'directClientSiteNameKanji',
          label: '直送先現場名',
          sortable: true,
        },
        {
          key: 'staffId',
          label: '営業担当コード',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    /* フェッチ */
    async fetchData(){
      // ローディングの開始
      this.$store.commit('setLoading', true);
      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;
      // 営業所データ取得
      await this.getOfficesData();
      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // ログ出力用メソッド名
      const functionName = 'getOfficesData';
      // 担当者データ取得
      let staffListData = null;
      // storeからログイン中ユーザーの情報を取得
      let user = store.getters.user;
      this.loginId = user.username;
      // 検索条件を設定
      let where_clause = `AND login_id = '${this.loginId}'`;
      let condition = {where_clause: where_clause}; 
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(list_m_staffs,condition));
        if (result.data.list_m_staffs) {
          // データを取得できた場合、取得データを返却
          staffListData = result.data.list_m_staffs;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return null;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
      let loginOfficeId = staffListData[0].office_id;
      try {
        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        // 営業所プルダウン作成
        for(const officeData of officeListData){
          this.salesOffice.push({
            id: officeData.office_id,
            name: officeData.office_name_kanji,
            text: officeData.office_id + '：' + officeData.office_name_kanji,
          });
          // default値挿入
          if (loginOfficeId == officeData.office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 検索条件「全て」を追加
        this.salesOffice.unshift(this.addConditions);
        // 発注日付に１か月前～現在日を設定
        this.searchConditions.selectOrderDateEnd = formatCurDate('YYYY-MM-DD');
        this.searchConditions.selectOrderDateStart = formatDateCalc(this.searchConditions.selectOrderDateEnd, 0, -1, 0, false, 'YYYY-MM-DD');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // ログ出力用メソッド名
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          // ローディングの開始
          this.$store.commit('setLoading', true);
          // 検索開始
          await this.search();
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);

      }
      // ローディングの終了
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search(){
      // ログ出力用メソッド名
      const functionName = 'search';
      this.itemList = [];
      // 明細テーブルのローディングを開始
      this.busy = true;
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      let result;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: selectSql }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          result: result
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      // データ取得時
      let resultData;
      const responseBody = JSON.parse(result.data.executeTransactSql.body)
      resultData = responseBody.data[0];
      if(resultData){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.alertWarning.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      // 明細テーブルのローディングを解除
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(const data of result){
        this.itemList.push({
          orderId : data.order_id,
          orderDate : formatDate(data.order_date),
          orderNumber : data.order_number,
          clientSiteNameKanji : data.client_site_name,
          clientId : !data.client_name_kanji ? data.orders_client_id : data.orders_client_id + '：' + data.client_name_kanji,
          orderClass : getListValue(Const.OrderClassList, data.order_class, 'id', 'text'),
          productName : !data.product_name ? data.product_id : data.product_id + '：' + data.product_name,
          remainingQuantity : data.remaining_quantity.toLocaleString(),
          directClientSiteNameKanji : data.client_site_name,
          directClientId : !data.client_name ? data.orders_receives_client_id : data.orders_receives_client_id + '：' + data.client_name,
          staffId : data.staff_id,
          _cellVariants: { productName: getListValue(Const.ProductCheckClass, data.product_check_class, 'value', 'color')}
        });
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      // 検索条件
      let whereClauses = [];

      // 削除済フラグ
      whereClauses.push('orders.is_deleted = 0');
      // 営業所コード
      if (this.searchConditions.selectSalesOffice) {
        whereClauses.push(`orders.office_id = ${this.searchConditions.selectSalesOffice}`);
      }
      // 注文番号
      if (this.searchConditions.selectOrderNumber) {
        whereClauses.push(`orders.order_number = ${this.searchConditions.selectOrderNumber}`);
      }
      // 発注日付開始&発注日付終了
      if (this.searchConditions.selectOrderDateStart && this.searchConditions.selectOrderDateEnd ) {
        // 発注日付開始
        whereClauses.push(`orders.order_date BETWEEN '${this.searchConditions.selectOrderDateStart}'`);
        // 発注日付終了
        whereClauses.push(`'${this.searchConditions.selectOrderDateEnd}'`);
      } else if (this.searchConditions.selectOrderDateStart) {
        whereClauses.push(`orders.order_date >= '${this.searchConditions.selectOrderDateStart}'`);
      } else if (this.searchConditions.selectOrderDateEnd) {
        whereClauses.push(`orders.order_date <= '${this.searchConditions.selectOrderDateEnd}'`);
      }
      // 取引先コードが入力された場合は、結合条件に加える
      if(this.searchConditions.selectClientId){
        whereClauses.push(`orders.client_class = ${Const.ClientClass.supplier}`);
        whereClauses.push(`orders.client_id = ${this.searchConditions.selectClientId}`);
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += '  orders.order_date'; // 発注日付
      selectSql += ', orders.order_number'; // 注文番号
      selectSql += ', orders.client_id AS orders_client_id'; // 仕入先コード
      selectSql += ', orders.order_class'; // 発注種別
      selectSql += ', orders.product_id'; // 製品コード
      selectSql += ', orders.product_name'; // 製品名
      selectSql += ', orders.remaining_quantity'; // 残数
      selectSql += ', orders_receives.client_site_name'; // 直送先現場名
      selectSql += ', orders_receives.client_id AS orders_receives_client_id'; // 直送先コード
      selectSql += ', orders_receives.client_name'; // 直送先名
      selectSql += ', orders_receives.staff_id'; // 営業担当コード
      selectSql += ', clients.client_name_kanji'; // 仕入先名
      selectSql += ', products.product_check_class'; // 製品チェック区分

      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* 取引先マスタ（m_clients）と結合 */
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_id = clients.client_id ';
      selectSql += 'AND clients.client_class = ' + Const.ClientClass.supplier + ' ';
      // 取引先コードが入力された場合は、結合条件に加える
      if(this.searchConditions.selectClientId){
        selectSql += `AND clients.client_id = ${this.searchConditions.selectClientId} `;
      }
      /* 製品マスタ（m_products）と結合 */
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders.product_id = products.product_id '
      // 製品コードが入力された場合は、結合条件に加える
      if(this.searchConditions.selectProductId){
        selectSql += `AND products.product_id = ${this.searchConditions.selectProductId} `;
      }
      /* 受注データ（t_orders_receives）と結合 */
      selectSql += 'LEFT JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON orders.order_id = orders_receives.order_id ';
      selectSql += 'AND orders.order_row = orders_receives.order_row ';
      /* WHERE句 */
      // 検索条件が指定された場合
      if(whereClauses.length !== 0){
        selectSql += `WHERE ${whereClauses.join(' AND ')} ` 
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' products.product_id';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 発注入力照会ボタン押下時 */
    async clickDetailBtn(selectOrderId){
      this.$router.push({ name: 'RECEIVED-ORDER-INQUIRY', params: { orderId: selectOrderId, isDeleted: 0, parentKbn: 1 } });
      //console.log(this.$router);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 「取引先検索」アイコン押下処理
     */
    showClientSearchModal: function() {
      // const functionName = 'showClientSearchModal';
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 「取引先検索」モーダルのクローズ押下処理
     */
    closeClientSearchModal:function(clientItems){
      // const functionName = 'closeClientSearchModal'
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        this.searchConditions.selectClientId = clientItems.detail.client_id;
      }
    },
    /**
     * 「製品検索」アイコン押下処理
     */
    showProductSearchModal:function() {
      this.productSearchProp.office_id = this.searchConditions.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.searchConditions.selectSalesOffice);
      //console.log('製品検索モーダル'+this.productSearchProp);
      this.$bvModal.show('productSearchModal');
    },
    /**
     * 「製品検索」アイコン押下処理
     */
    closeProductSearchModal:function(productItems){
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.searchConditions.selectProductId = productItems.detail.product_id;
      }
    },
  },
}
</script>
<style scoped>
.mainTable {
  align-items:left;
}
.mainTable td{
   padding: 0; 
   border: 0px;
}
.input-daterange{
  min-width: 27vw;
}
</style>