<template>
  <b-modal
  id="stockModal"
  size="xl"
  centered
  @show="searchButton('init')"
  title="在庫照会"
  >
    <b-container>
      <b-row>
        <b-col>
        <!-- ●●●検索条件●●● -->
          <div>
            <b-card>
              <b-card-header v-if="getMessageFlg==true">
                <b-alert show variant="danger" v-if="alertDanger.length">
                  <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                    <li>{{error}}</li>
                  </ul>
                </b-alert>
              </b-card-header>
              <div class="mb-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-1">
                  <b-form @submit.prevent="clearAlert(); searchButton('click');" id="stockInquiryForm" class="form-horizontal">
                    <validation-observer ref="observer">
                      <b-row>
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                          <b-input-group>
                            <template #prepend>
                              <label for="selectSalesOffice">
                                <b-input-group-text  class="px-1">
                                  営業所
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-select
                              id="selectSalesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                              text-field="name"
                            />
                          </b-input-group>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 form-group px-1">
                          <b-input-group>
                            <template #prepend>
                              <label for="productName">
                                <b-input-group-text  class="px-1">
                                  製品名
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="text" id="productName" name="productName" class=" form-control" v-model="searchConditions.productName"></b-form-input>
                          </b-input-group>
                        </div>
                      </b-row>
                      <b-row>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group px-1">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-input-group>
                              <template #prepend>
                                <label for="productCode">
                                  <b-input-group-text  class="px-1">
                                    製品コード
                                  </b-input-group-text>
                                </label>
                              </template>
                              <b-form-tags
                                id="productCode"
                                name="productCode"
                                remove-on-delete
                                class="mb-2"
                                separator=" "
                                v-model="searchConditions.productCode">
                              </b-form-tags>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-input-group>
                          </validation-provider>
                        </div>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="mt-1 justify-content-md-center">
                        <b-col lg="3">
                          <b-button block pill variant="success" type="submit" form="stockInquiryForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                        </b-col>
                      </b-row>
                    </validation-observer>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card>
        <b-alert show variant="warning" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col>
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="mb-1">
              <b-form-group
                label="1ページの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
               <b-col lg="6" class="pl-0">
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
               </b-col>
              </b-form-group>
            </b-col>
            <!-- フィルター -->
            <b-col lg="6" class="mb-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            show-empty
            :small="true"
            :items="stockList"
            :fields="fields"
            :busy="busy"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :sticky-header="true"
            >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
            <template #head(availableQuantity)>
              <div>使用可能</div>
              <div>在庫数</div>
            </template>
            <template #head(totalStockQuantity)>
              <div>合計入荷</div>
              <div>予定数</div>
            </template>
            <template #cell(inquiry)="data">
              <!-- 入荷予定照会ボタン -->
              <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'入荷予定を照会できます。'" @click="stockScheduledInquiry(data.item)" class="mr-1">
                <span class="oi oi-list"></span> 入荷予定
              </b-button>
              <!-- セット品在庫照会ボタン -->
              <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'部材・バラの在庫照会を開きます。'" @click="setStockInquiry(data.item)" v-if="data.item.productSetFlg == 1 || data.item.productCaseFlg == 1">
                <span class="oi oi-list"></span> 部材・バラ
              </b-button>
            </template>
            <template #cell(productName)="data">
              <div style="min-width:160px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.productName}}
                  </span>
                </div>
              </div>
            </template>          
            <template #cell(productNameKana)="data">
              <div style="min-width:160px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>
                    {{data.item.productNameKana}}
                  </span>
                </div>
              </div>
            </template>  
          </b-table>
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="my-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <template #modal-footer>
      <b-button size="sm" @click="$bvModal.hide('stockModal')" class="ml-1 float-start">キャンセル</b-button>
    </template>
  </b-modal>
</template>
<script>
import { init, executeSelectSql, convertSqlLikeSpecialChar, addOperationLogs, getNullStr } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_offices } from '@/graphql/queries';

const MODULE_NAME = 'stock-inquiry';

export default {
  name: 'STOCK-INQUIRY',
  props:['stockInfo'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '在庫照会',
      // 検索条件
      searchConditions:{
        productCode: [],
        productName: '',
        selectSalesOffice: '',
        salesOffice: [],
      },
      // 初期検索は製品コード順
      initFlg: false,
      initDispProductId: [],
      // 検索結果
      resultError: [],
      // アラート
      alertDanger: [],
      stockList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  computed:{
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertDanger.length > 0
    },
    fields: function(){
      return [
        {
          key: 'productId',
          label: '製品コード',
        },
        {
          key: 'parentText',
          label: '',
        },
        {
          key: 'productName',
          label: '製品名',
        },
        {
          key: 'productNameKana',
          label: '製品名カナ',
        },
        {
          key: 'stockQuantity',
          label: '現在庫数',
          tdClass: 'text-right',
        },
        {
          key: 'invReserveQuantity',
          label: '引当済数',
          tdClass: 'text-right',
        },
        {
          key: 'availableQuantity',
          label: '使用可能在庫数',
          tdClass: 'text-right',
        },
        {
          key: 'totalStockQuantity',
          label: '合計入荷予定数',
          tdClass: 'text-right',
        },
        {
          key: 'inquiry',
          label: '',
        },
      ]
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods:{
    /* 検索ボタン押下時 */
    async searchButton(key){
      const functionName = 'searchButton'
      try {
        if(key == 'init'){
          this.initFlg = true;
          // 初期表示
          this.resultError = [];
          this.searchConditions.selectSalesOffice = '';
          this.searchConditions.salesOffice = [];
          this.searchConditions.productCode = [];
          this.searchConditions.productName = '';
          this.initDispProductId = [];
          // 営業所データ取得⇒セット
          let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
          let officeListData = officeListResult.data.list_m_offices;
          //console.log(officeListData);
          for(let i = 0; i < officeListData.length; i++){
            let office = {
              id: officeListData[i].office_id,
              name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
            };
            this.searchConditions.salesOffice.push(office);
          }
          // 営業所データ初期値セット
          for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
            if(this.stockInfo.office_id == this.searchConditions.salesOffice[i].id){
              this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
              break;
            }
          }
          if(this.stockInfo.product_id.length != 0){
            for(let i = 0; i < this.stockInfo.product_id.length; i++){
              if (getNullStr(this.stockInfo.product_id[i]) != '' && isNaN(getNullStr(this.stockInfo.product_id[i])) == false) {
                this.searchConditions.productCode.push(this.stockInfo.product_id[i]);
                this.initDispProductId.push(this.stockInfo.product_id[i]);
              }
            }
          }
          await this.search();
          this.initFlg = false;
        }else {
          const observer = this.$refs.observer;
          const success = await observer.validate();
          if (!success) {
            const el = document.querySelector('#error:first-of-type');
            el.scrollIntoView({ block: 'center', inline: 'nearest' });
          }else{
            await this.search();
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 検索処理 */
    async search(){
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.busy = true;
      this.stockList = [];
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      // 検索条件がある場合、条件取得
      try {
        let resultData = await executeSelectSql(selectSql);
        if(resultData != null){
          //console.log(resultData);
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
          this.busy = false;
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
          this.busy = false;
        }
      } catch (error) {
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.busy = false;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      if (this.initFlg == false) {
        this.totalRows = result.length;
        for(let i = 0; i < result.length; i++){
          let parentText = [];
          if (result[i].set_flg == 1) {
            parentText.push(Const.ProductParentClass.set)
          } 
          if (result[i].case_flg == 1) {
            parentText.push(Const.ProductParentClass.case)
          } 
          if (result[i].loose_flg == 1){
            parentText.push(Const.ProductParentClass.loose)
          } 
          if (result[i].group_flg == 1 || result[i].group_flg_2 == 1){
            parentText.push(Const.ProductParentClass.group)
          }
          let searchResult = {
            productId: result[i].product_id,
            parentText: parentText.join('/'),
            productNameKana: result[i].product_name_kana,
            productName: result[i].product_name_kanji,
            stockQuantity: result[i].inventory_reserve_count + result[i].balance,
            invReserveQuantity: result[i].inventory_reserve_count,
            availableQuantity: result[i].balance,
            totalStockQuantity: result[i].quantity_stock,
            productSetFlg: result[i].set_flg,
            productCaseFlg: result[i].case_flg,
            inquiry: result[i],
          };
          this.stockList.push(searchResult);
        }
      } else {
        this.totalRows = this.initDispProductId.length;
        for (let i = 0; i < this.initDispProductId.length; i++) {
          let data = result.find(el => el.product_id == this.initDispProductId[i]);
          if (data != undefined) {
            let parentText = [];
            if (data.set_flg == 1) {
              parentText.push(Const.ProductParentClass.set)
            } 
            if (data.case_flg == 1) {
              parentText.push(Const.ProductParentClass.case)
            } 
            if (data.loose_flg == 1){
              parentText.push(Const.ProductParentClass.loose)
            } 
            if (data.group_flg == 1 || data.group_flg_2 == 1){
              parentText.push(Const.ProductParentClass.group)
            }
            let searchResult = {
              productId: data.product_id,
              parentText: parentText.join('/'),
              productNameKana: data.product_name_kana,
              productName: data.product_name_kanji,
              stockQuantity: data.inventory_reserve_count + data.balance,
              invReserveQuantity: data.inventory_reserve_count,
              availableQuantity: data.balance,
              totalStockQuantity: data.quantity_stock,
              productSetFlg: data.set_flg,
              productCaseFlg: data.case_flg,
              inquiry: data,
            };
            this.stockList.push(searchResult);
          }
        }
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      /* 請求締更新前の処理月情報あり、前月情報あり */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.product_id';
      selectSql += ',products.product_name_kana';
      selectSql += ',products.product_name_kanji';
      selectSql += ',stocks.inventory_reserve_count';
      selectSql += ',stocks.balance';
      selectSql += ',stocks.quantity_stock';
      selectSql += ',stocks.office_id';
      selectSql += ',CASE WHEN products_compositions.product_id IS NULL THEN 0 ELSE 1 END AS set_flg';
      selectSql += ',CASE WHEN products_prices_groups.product_group_id IS NULL THEN 0 ELSE 1 END AS group_flg';
      selectSql += ',CASE WHEN products_prices_groups_2.product_id IS NULL THEN 0 ELSE 1 END AS group_flg_2';
      selectSql += ',CASE WHEN products_units_conversions_1.case_product_id IS NULL THEN 0 ELSE 1 END AS case_flg';
      selectSql += ',CASE WHEN products_units_conversions_2.loose_product_id IS NULL THEN 0 ELSE 1 END AS loose_flg';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      selectSql += 'INNER JOIN m_control AS control ';
      selectSql += 'ON stocks.month_year = control.process_month_year ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON stocks.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON stocks.product_id = products_details.product_id ';
      selectSql += 'AND stocks.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_products_compositions AS products_compositions ';
      selectSql += 'ON stocks.product_id = products_compositions.product_id ';
      selectSql += 'LEFT JOIN m_products_units_conversions AS products_units_conversions_1 ';
      selectSql += 'ON stocks.product_id = products_units_conversions_1.case_product_id ';
      selectSql += 'LEFT JOIN m_products_units_conversions AS products_units_conversions_2 ';
      selectSql += 'ON stocks.product_id = products_units_conversions_2.loose_product_id ';
      selectSql += 'LEFT JOIN m_products_prices_groups AS products_prices_groups ';
      selectSql += 'ON stocks.product_id = products_prices_groups.product_group_id ';
      selectSql += 'LEFT JOIN m_products_prices_groups AS products_prices_groups_2 ';
      selectSql += 'ON stocks.product_id = products_prices_groups_2.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 製品コード 複数入力された場合,SQLのIN関数にセットする為の文字列準備(カンマ区切り文字列)
      if(this.searchConditions.productCode.length != 0){
        let productIdArray = '';
        for(let i = 0; i < this.searchConditions.productCode.length; i++) {
          if (productIdArray != '') {
            productIdArray += ',';
          }
          productIdArray += this.searchConditions.productCode[i];
        }
        selectSql += 'AND stocks.product_id IN (' + productIdArray + ') ';
      } else {
        selectSql += 'AND stocks.product_id = 0 ';
      }
      // 製品名
      if(this.searchConditions.productName != ''){
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(products.product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(products.product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' stocks.product_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' stocks.product_id';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    // 入荷予定照会画面を開く
    stockScheduledInquiry :function(data){
      let resolvedRoute = this.$router.resolve({ name: 'SCHEDULED-STOCK-INQUIRY', query: { office_id: data.inquiry.office_id, product_id: data.inquiry.product_id } });
      window.open(resolvedRoute.href, '_blank');
    },
    // セット品在庫照会画面を開く
    setStockInquiry :function(data){
      let resolvedRoute = this.$router.resolve({ name: 'SET-STOCK-INQUIRY', query: { office_id: data.inquiry.office_id, product_id: data.inquiry.product_id }});
      window.open(resolvedRoute.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertDanger = [];
      this.resultError = [];
    },
  },
}
</script>