<template>
 <b-modal
  id="confirmModal"
  centered no-close-on-esc no-close-on-backdrop hide-header-close scrollable
  title="確認"
  @ok="selectOk"
 >
  <p v-if="confirmMessage.length">
    <ul v-for="(message,index) in confirmMessage" :key="index" style="list-style: none;">
      <li>{{ message }}</li>
    </ul>
  </p>
 </b-modal>
<!-- ●●●取引先検索モーダル●●● -->
</template>
<script>
import { init } from '@/assets/js/common.js';
export default {
  name: 'CONFIRM',
  props:['confirmMessage'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '確認',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods:{
    selectOk:function(){
      this.$emit('from-child', true);
    },
  },
}
</script>
<style scoped>
</style>