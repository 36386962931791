<template>
  <!-- 製品構成保守新規画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品構成保守新規</strong></h5>
                <router-link to="/products-compositions-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- セット製品コード -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|max:8|min:8" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="productId" class="form-label"><strong>セット製品コード</strong></label>
                        <div class="input-group">
                          <input type="text" id="productId" class="form-control" v-model="producCompositionsObj.product_id" @input="searchProducts(producCompositionsObj.product_id)" maxlength="8">
                          <!-- 製品コード検索ボタン -->
                          <b-input-group-text @click="showProductSearchModalObj" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '1']" @shortkey="showProductSearchModalObj" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品名 -->
                  <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <div v-b-tooltip.hover.noninteractive :title="producCompositionsObj.product_name_kanji">
                          <label for="product_name_kanji" class="form-label"><strong>製品名</strong></label>
                          <input type="text" id="product_name_kanji" class="form-control" v-model="producCompositionsObj.product_name_kanji" readonly>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <!-- 構成テーブル -->
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                    <b-table
                      show-empty
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="producCompositionsObj.compositions"
                      :fields="fields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 部材製品コード -->
                      <template #cell(product_id)="data">
                        <validation-provider rules="required|numeric|max:8|min:8" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <div class="input-group">
                              <input type="text" id="product_id" class="form-control" v-model="data.item.product_id" @input="productIdConfirm(data.item.product_id, data)" maxlength="8">
                              <!-- 製品製品コード検索ボタン -->
                              <b-input-group-text @click="showProductSearchModal(data)">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                </b-button>
                              </b-input-group-text>
                            </div>
                            <small class="form-text text-muted">必須項目です。手入力の場合は8桁</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 部材名 -->
                      <template #cell(product_name_kanji)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <div v-b-tooltip.hover.noninteractive :title="data.item.product_name_kanji">
                              <input type="text" id="product_name_kanji" class="form-control" v-model="data.item.product_name_kanji" readonly>
                            </div>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 数量 -->
                      <template #cell(quantity)="data">
                        <validation-provider rules="required|numeric|between:1,999" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" :id="'quantity' + data.index" class="form-control" v-model="data.item.quantity" maxlength="3">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(operation)="operation">
                        <!-- 削除ボタン -->
                        <b-button size="sm" @click="delComposition(operation)" class="mr-1">
                          <span class="oi oi-delete"></span> 削除
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 form-group">
                    <button type="button" class="btn btn-primary btn-block" @click="addComposition"><span class="oi oi-plus"></span> 部材追加</button>
                  </div>
                </div>
              </validation-observer>
              <!-- 保存ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                   <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import Const from '@/assets/js/const.js';
import { list_m_products, list_m_products_compositions, list_m_products_units_conversions } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, CreateColRow, CreateInsertSql, getUserCol, checkProductId, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products-compositions-input';

export default {
  name: 'PRODUCTS-COMPOSITIONS-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品構成保守新規',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品検索時のindexを一時保存
      productSearchIndex: 0,
      // 製品構成対象
      producCompositionsObj: {},
      // 部材製品コードの置き場
      compositionIds: [],
      // 製品対象が製品名を検索中
      searchTypeObj: false,
      // 構成テーブル
      fields: DataTblDef.product_compositions_table_fields,
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  computed:{
    /* 製品モーダルのパラメータ用 */
    productSearchProp() {
      return {
        office_id: null,
        office_name: null,
        client_class: null,
        client_id: null,
        client_name: null,
      };
    },
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData() {
      this.busy = true;
      // 構成データをセット
      this.producCompositionsObj = {
        product_id: null,
        product_name_kanji: null,
        // 初期データ2件を求めます
        compositions: [
          {
            product_id: null,
            product_name_kanji: null,
            quantity: 1,
          },
          {
            product_id: null,
            product_name_kanji: null,
            quantity: 1,
          },

        ]
      }
      this.busy = false;
    },
    // 製品構成検索
    showProductSearchModal({index}) {
      this.errorMessages = []
      this.searchTypeObj = false
      this.productSearchIndex = index
      this.$bvModal.show('productSearchModal');
    },
    // 製品対象検索
    showProductSearchModalObj() {
      this.errorMessages = []
      this.searchTypeObj = true
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal(productItems){
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        // 「諸口区分」が諸口の場合
        if (productItems.detail.sundries_class == Const.SundriesClass.normal) {
          if (this.searchTypeObj) {
            // 製品対象に検索結果を返す
            this.searchTypeObj = false
            this.producCompositionsObj.product_id = productItems.productId
            this.producCompositionsObj.product_name_kanji = productItems.productNameKanji
            return
          }
          // 製品構成に検索結果を返す
          if (this.compositionIds.indexOf(Number(productItems.productId)) != -1) {
            this.errorMessages.push('同じ部材製品コードがすでに存在します')
            scrollTo(0,0);
            return
          } else {
            this.producCompositionsObj.compositions[this.productSearchIndex].product_id = productItems.productId;
            this.producCompositionsObj.compositions[this.productSearchIndex].product_name_kanji = productItems.productNameKanji;
            // 現在最新部材製品コード
            const tempArr = []
            this.producCompositionsObj.compositions.forEach(element => {
              if (checkProductId(element.product_id)) tempArr.push(Number(element.product_id))
            });
            // 部材製品コードの置き場に入れる
            this.compositionIds = tempArr
            // 製品検索を行った行の数量にフォーカスを移動
            setTimeout(() => {
              this.setFocus('quantity' + this.productSearchIndex);
            }, 500);
          }
        } else {
          // 「諸口区分」が諸口の場合
          this.errorMessages.push(DISP_MESSAGES.WARNING['2044'])
          scrollTo(0,0);
        }
      }
    },
    /**
     * 製品コード確認処理
     * @param {Int} product_id - 製品コード
     * @param {Object} data - データ行内容
      */
    async productIdConfirm(product_id, {index}) {
      const functionName = 'productIdConfirm';
      this.errorMessages = []
      // 数字8桁のみ
      if (checkProductId(product_id)) {
        if (this.compositionIds.indexOf(Number(product_id)) != -1) {
          this.errorMessages.push('同じ部材製品コードがすでに存在します')
          scrollTo(0,0);
          return
        }
        const where_clause = `AND product_id = ${product_id}`
        let result = null;
        try {
          result = await API.graphql(graphqlOperation(list_m_products, { where_clause }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            where_clause
          }, error);
          return null;
        }
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            where_clause,
            result
          });
          return null;
        }
        if (result.data.list_m_products.length === 1) {
          // 「諸口区分」が諸口の場合
          if (result.data.list_m_products[0].sundries_class == Const.SundriesClass.normal) {
            // 部材のコードと製品名をセット
            this.producCompositionsObj.compositions[index].product_id = result.data.list_m_products[0].product_id
            this.producCompositionsObj.compositions[index].product_name_kanji = result.data.list_m_products[0].product_name_kanji
            // 現在最新部材製品コード
            const tempArr = []
            this.producCompositionsObj.compositions.forEach(element => {
              if (checkProductId(element.product_id)) tempArr.push(Number(element.product_id))
            });
            // 部材製品コードの置き場に入れる
            this.compositionIds = tempArr
            // 製品検索を行った行の数量にフォーカスを移動
            setTimeout(() => {
              this.setFocus('quantity' + index);
            }, 500);
          } else {
            // 「諸口区分」が諸口の場合
            this.errorMessages.push(DISP_MESSAGES.WARNING['2044'])
            this.producCompositionsObj.compositions[index].product_id = ''
            this.producCompositionsObj.compositions[index].product_name_kanji = ''
            scrollTo(0,0);
          }
        } else {
          // 部材のコードと製品名をセット
          this.producCompositionsObj.compositions[index].product_name_kanji = ''
          this.errorMessages.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','製品コード'));
          scrollTo(0,0);
        }
      }
    },
    /**
     * 製品マスタ検索
     * @param {Int} product_id - 製品コード
     * @returns {<Object>} 検索結果
     */
    async searchProducts(product_id) {
      const functionName = 'searchProducts';

      // 数字8桁のみ
      if (checkProductId(product_id)) {
        product_id = Number(product_id)
        const whereClause = `and product_id = ${product_id}`
        let result = null;
        try {
          result = await API.graphql(graphqlOperation(list_m_products, {
            where_clause: whereClause
          }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            whereClause: whereClause
          }, error);
          return null;
        }
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            whereClause: whereClause,
            result: result
          });
          return null;
        }
        if (result.data.list_m_products.length === 1) {
          // 「諸口区分」が諸口の場合
          if (result.data.list_m_products[0].sundries_class == Const.SundriesClass.normal) {
            // エラーを消す
            this.errorMessages = []
            // 部材のコードと製品名をセット
            this.producCompositionsObj.product_id = result.data.list_m_products[0].product_id
            this.producCompositionsObj.product_name_kanji = result.data.list_m_products[0].product_name_kanji
          } else {
            // 「諸口区分」が諸口の場合
            this.errorMessages.push(DISP_MESSAGES.WARNING['2044'])
            this.producCompositionsObj.product_id = ''
            this.producCompositionsObj.product_name_kanji = ''
            scrollTo(0,0);
          }
        } else {
          // 製品存在しません
          this.errorMessages.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','製品コード'));
          this.producCompositionsObj.product_name_kanji = ''
          scrollTo(0,0);
        }
      }
    },
    /**
     * 部材を追加
     */
    async addComposition() {
      this.producCompositionsObj.compositions.push({
        product_id:  null,
        product_name_kanji: null,
        quantity: 1,
      })
    },
    /**
     * 部材を削除
     */
    async delComposition({index}) {
      if (checkProductId(this.producCompositionsObj.compositions[index].product_id)) {
        //  部材製品コードを置き場から削除
        this.compositionIds = this.compositionIds.filter((id) => {
          return id !== Number(this.producCompositionsObj.compositions[index].product_id)
        })
      }
      this.producCompositionsObj.compositions.splice(index, 1)
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];
      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execInsert();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-COMPOSITIONS-INQUIRY', 
              query: { product_id: this.producCompositionsObj.product_id },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] }  
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     *  登録処理
     */
    async execInsert() {
      // 部材が0の場合
      if (this.producCompositionsObj.compositions.length === 0) {
        this.errorMessages.push('最低は一つの部材で構成されます')
        return
      }
      // 部材製品コードと製品コード同じ設定場合
      const duplicateProductId = this.producCompositionsObj.compositions.find((composition) => {
        return composition.product_id == this.producCompositionsObj.product_id
      })
      if (duplicateProductId != undefined) {
        this.errorMessages.push('部材製品コードと製品コードを同じに設定できません')
        return
      }
      //  セット製品コードすでに登録されるか判断
      const productIdExistNext = await this.productIdExist(Number(this.producCompositionsObj.product_id))
      if (!productIdExistNext) {
        this.errorMessages.push('この製品コードはすでに登録されています。');
        return
      }
      //  部材製品コードすでにバラ製品として登録されるか判断
      const looseProductIdExist = await this.componentProductIdsExist()
      if (looseProductIdExist.length != 0) {
        this.errorMessages.push(`部材製品コード:[${looseProductIdExist}] はすでにバラ製品として登録されています。`);
        return
      }
      // 構成マスタを登録
      await this.execInsertCompositions()
    },
    /**
     * 登録処理
     */
    async execInsertCompositions() {
      const functionName = 'execInsertCompositions';

      let sqlInsertCol = ''
      let sqlInsertVal = []
      let sqlUpdateCol = []
      let sqlUpdateVal = []
      for (const key in this.producCompositionsObj.compositions) {
        const composition = this.producCompositionsObj.compositions[key]
        const colList = [];
        // 製品コード
        colList.push(CreateColRow('product_id', Number(this.producCompositionsObj.product_id), 'INT'));
        // 部材製品コード
        colList.push(CreateColRow('component_product_id', Number(composition.product_id), 'INT'));
        // 数量
        colList.push(CreateColRow('quantity', Number(composition.quantity), 'INT'));
        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')
        // 更新ユーザー
        const colUpdateUser = await getUserCol(this.username, 'update')

        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_products_compositions').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_products_compositions').split(','));
        // sql update カラム部分
        sqlUpdateCol = CreateInsertSql(colList.concat(colUpdateUser), 'col', 'm_products_compositions').split(',');
      }
      for (const key in sqlUpdateCol) {
        sqlUpdateVal.push(`${sqlUpdateCol[key]} = values(${sqlUpdateCol[key]})`)
      }
      let sql = `INSERT INTO m_products_compositions (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }
    
      sql = `${sql} ${insertArr.join()}`
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    /**
     * すでに登録されたか判断
     * @param {Int} product_id - 製品コード
     * @return {boolean}  続きかどうか
     */
    async productIdExist(product_id) {
      const functionName = 'productIdExist';
      const where_clause = `AND product_id = ${product_id}`
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_products_compositions, { where_clause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_compositions',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_compositions',
          where_clause: where_clause,
          result
        });
        return null;
      }
      if (result.data.list_m_products_compositions.length != 0 ) {
        // 編集画面に遷移
        this.$bvModal.msgBoxConfirm('この製品コードはすでに登録されています。編集画面に遷移するはよろしいですか？').then(async value => {
          if (value) {
            this.$router.push({ 
              name: 'PRODUCTS-COMPOSITIONS-EDIT', 
              query: { product_id: this.producCompositionsObj.product_id },
            });
          }
        });
        return false
      }
      return true
    },
    /**
     * セット製品の部材、且つ、ケースに換算されるバラ製品登録不可
     * @return {Array}  バラ製品になった製品コード
     */
    async componentProductIdsExist() {
      const functionName = 'componentProductIdsExist';

      // 全部の部材製品コード
      let componentProductIds = []
      for (const key in this.producCompositionsObj.compositions) {
        const composition = this.producCompositionsObj.compositions[key]
        // 部材製品コードリストに追加
        componentProductIds.push(Number(composition.product_id))
      }

      const where_clause = `AND loose_product_id in (${componentProductIds.join()})`
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_products_units_conversions, { where_clause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_units_conversions',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_units_conversions',
          where_clause: where_clause,
          result
        });
        return null;
      }
      if (result.data.list_m_products_units_conversions.length != 0 ) {
        // 一時保存のSet
        let tempArray = new Set()
        result.data.list_m_products_units_conversions.forEach(element => {
          tempArray.add(element.loose_product_id)
        });
        return Array.from(tempArray)
      }
      return []
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>