<template>
  <!-- 担当者保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a data-bs-toggle="collapse" class="text-secondary text-body" style="display: block;float:left;" href="#collapse1" role="tablist" aria-expanded="true" aria-controls="collapse1" title="クリックすると検索条件表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <router-link to="/staff-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="担当者マスタの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message,index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{message}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="warningMessages.length">
                      <ul v-for="(message,index) in warningMessages" :key="index" style="list-style: none;">
                        <li>{{message}}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 担当者コード入力欄 -->
                        <div class="col col-6 form-group">
                          <validation-provider name="offieId" rules="numeric" v-slot="{ classes,errors }">
                            <div class=""><label for="offieId" class="form-control-label">担当者コード</label></div>
                            <div :class="classes">
                              <input type="text" id="offieId" name="offieId" class=" form-control" v-model="searchConditions.staffId">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 担当者名入力欄 -->
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label for="offieName" class="control-label">担当者名</label>
                            <input type="text" id="offieName" name="offieName" class=" form-control" v-model="searchConditions.staffName">
                            <small class="form-text text-muted">部分一致検索です。登録済みの担当者名（漢字）または担当者名（カナ）と一致するデータを検索します。</small>
                          </div>
                        </div>
                        <!-- 営業所コードプルダウン -->
                        <div class="col col-6 form-group">
                          <label for="select1" class=" control-label">営業所</label>
                          <select id="select1" class="form-control" v-model="selectOffice">
                            <option v-for="offices in searchConditionOffice" :value="offices" :key="offices.id">
                              {{ offices.disp }}
                            </option>
                          </select>
                        </div>
                        <!-- 削除チェック入力欄 -->
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label for="isDeleted" v-b-tooltip.hover.noninteractive title='チェックONの場合、削除された担当者データのみが表示されます'>削除のみ<span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isDeleted"
                              name="isDeleted"
                              v-model="searchConditions.isDeleted"
                            ></b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <div v-if="staffList.length >= 1000" class="col-sm-12" data-toggle="tooltip" title="" data-original-title="メモ：大量の検索結果時のみ表示する">
            <div class="alert  alert-warning alert-dismissible fade show" role="alert">
                {{messageOver1000Data}}
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table hover
                table-class="datatable"
                show-empty
                :head-variant="headVariant"
                :responsive= true
                :items="staffList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :bordered= true
                :empty-text="emptyText"
                :empty-filtered-text="emptyFilterdText"
              >
                <template #custom-foot="data">
                  {{ totalRows + ' 件中 ' + (currentPage==1?currentPage:(((currentPage*perPage) - perPage)) + 1) + ' から ' + (((((currentPage*perPage) - perPage)) + 1) + data.items.length -1) + ' まで表示'}}
                </template>
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <!-- 操作列 -->
                <template #cell(operation)="operation">
                  <div v-show="!printStatus">
                    <!-- 編集ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left title="担当者マスタの編集を行います。" @click="clickEditBtn(operation.item.staffId)" class="mr-1" v-if="operation.item.isDeleted!=1">
                      <span class="oi oi-pencil"></span> 編集
                    </b-button>
                    <!-- 削除ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left title="担当者マスタの削除を行います。" @click="clickDeleteBtn(operation.item.staffId, operation.item.loginId)" class="mr-1" v-if="operation.item.isDeleted!=1">
                      <span class="oi oi-delete"></span> 削除
                    </b-button>
                    <!-- 復旧ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left title="担当者マスタの削除の復旧を行います。" @click="clickRestoreBtn(operation.item.staffId)" class="mr-1" v-if="operation.item.isDeleted==1">
                      <span class="oi oi-action-undo"></span> 復旧
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, addOperationLogs, convertSqlLikeSpecialChar, isSystemEditable, windowPrint} from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs , list_m_offices } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { deleteCognitoUser, getCurrentAuthenticatedUser } from '@/assets/aws/cognito';

// ログ出力用モジュール名
const MODULE_NAME = 'staff-list';

export default {
  name: 'STAFF-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '担当者保守',
      // 画面メッセージ
      messageOver1000Data: DISP_MESSAGES.WARNING['2002'],
      successMessages: [],
      warningMessages: [],
      // 検索条件
      searchConditions:{
        staffId: '',
        staffName: '',
        isDeleted: false,
      },
      searchConditionOffice: [],
      // 営業所プルダウン
      selectOffice: null,
      // 検索結果
      staffList: [],
      office: [],
      fields:DataTblDef.staff_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: 10,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'STAFF-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData(){
      try {
        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        
        this.searchConditionOffice = [];
        this.searchConditionOffice.push({id:'', name: '', disp: '全て'});
        this.selectOffice = this.searchConditionOffice[0];
        for(let i = 0; i < officeListData.length; i++){
          let temp_office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_name_kanji,
            disp: officeListData[i].office_id + '：' +officeListData[i].office_name_kanji
          };
          this.searchConditionOffice.push(temp_office);
        }
      }catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.log(error);
      }
      /*
      try {
        this.busy = true;
        // 検索処理(初期表示は全件取得)
        let where_clause = 'AND is_deleted <> 1';
        let condition = {where_clause: where_clause};
        let result = await API.graphql(graphqlOperation(list_m_staffs, condition));
        let resultData = result.data.list_m_staffs;
        //console.log('初期表示テーブルデータ');
        //console.log(resultData);
        await this.setResult(resultData);
        this.busy = false;
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.log(error);
      }
      */
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
        console.info('validationエラーあり');
      }else{
        this.$store.commit('setLoading', true);
        this.busy = true;
        await this.search();
        this.busy = false;
        this.$store.commit('setLoading', false);
      }
    },
    /* 検索処理 */
    async search(){
      this.staffList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = await this.makeCondition();
      if (this.searchConditions.isDeleted == true) {
        where_clause += 'AND is_deleted = 1';
      } else {
        where_clause += 'AND is_deleted <> 1';
      }
      let condition = {where_clause: where_clause};
      //console.log(where_clause);
      try {
        // 検索条件作成
        let result = await API.graphql(graphqlOperation(list_m_staffs,condition));
        let resultData = result.data.list_m_staffs;
        if(resultData != null){
          await this.setResult(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 営業所情報を取得
      await this.getOffice();
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          staffId: result[i].staff_id,
          staffNameKanji: result[i].staff_name_kanji,
          staffNameKana: result[i].staff_name_kana,
          officeName: await this.getOfficeName(result[i].office_id),
          loginId: result[i].login_id,
          mailAddress: result[i].mail_address,
          isDeleted: result[i].is_deleted,
          Operation: result[i],
        };
        if (searchResult.isDeleted == 1) {
          searchResult._rowVariant = 'secondary';
        }
        this.staffList.push(searchResult);
      }
    },
    /* 営業所情報取得 */
    async getOffice(){
      let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
      let officeListData = officeListResult.data.list_m_offices;
      for(let i = 0; i < officeListData.length; i++){
        let temp_office = {
          id: officeListData[i].office_id,
          name: officeListData[i].office_name_kanji
        };
        this.office.push(temp_office);
      }
    },
    /* 営業所名取得 */
    async getOfficeName(officeId){
      for(let i = 0; i < this.office.length; i++){
        if(this.office[i].id == officeId) {
          return this.office[i].name;
        }
      }
    },
    /* 検索条件文字列作成 */
    async makeCondition(){
      let where_clause = '';
      //console.log(this.searchConditions);
      // 担当者コード
      if(this.searchConditions.staffId != null && this.searchConditions.staffId != ''){
        where_clause += 'AND staff_id = ' + Number(this.searchConditions.staffId) + ' ';
      }
      // 担当者名(あいまい検索対象)
      if(this.searchConditions.staffName != null && this.searchConditions.staffName != ''){
        const staffName = convertSqlLikeSpecialChar(this.searchConditions.staffName);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(staff_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${staffName}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(staff_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${staffName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 営業所コード
      if(this.selectOffice.id != null && this.selectOffice.id != ''){
        where_clause += 'AND office_id = ' + Number(this.selectOffice.id) + ' ';
      }

      return where_clause;
    },

    /* 詳細ボタン押下時 */
    async clickEditBtn(staffId){
      //console.log('詳細');
      //console.log('担当者コード：' + staffId);
      let route = this.$router.resolve({ name: 'STAFF-EDIT', query: { staffId: staffId, parentKbn: 0 } });
      window.open(route.href, '_blank');
    },

    /* 削除ボタン押下時 */
    async clickDeleteBtn(staffId, loginId){
      const functionName = 'clickDeleteBtn';

      this.successMessages = [];
      this.warningMessages = [];
      //console.log('削除');
      try {
        if(await this.isDeleteingUserOwn(loginId)) {
          this.warningMessages.push(DISP_MESSAGES.WARNING['2028']);
          scrollTo(0,0);
          return;
        }
        // 担当者データを削除
        this.$bvModal.msgBoxConfirm('選択された担当者データを削除します。\nよろしいですか？').then(async value => {
          //console.log({value});
          if (value) {
            this.$store.commit('setLoading', true);
            //console.log('削除対象担当者コード：' + staffId);

            // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
            try {
              const msg = await isSystemEditable();
              if (msg !== null) {
                this.warningMessages.push(msg);
                scrollTo(0,0);
                this.$store.commit('setLoading', false);
                return;
              }
            } catch (error) {
              await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
              this.warningMessages.push(DISP_MESSAGES.DANGER['3002']);
              scrollTo(0,0);
              this.$store.commit('setLoading', false);
              return;
            }

            await this.updateStaff(staffId);
            await deleteCognitoUser(loginId);
            scrollTo(0,0);
            this.$store.commit('setLoading', false);
          }
        });
      } catch (error) {
        this.successMessages = [];
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
        //console.log('error delete user ' + loginId + ':' + error.message);
        // 異常系操作ログの登録
        addOperationLogs('Error', MODULE_NAME, 'clickDeleteBtn', {}, error);
      }
    },
    /* 自分自身を削除するのはNG */
    async isDeleteingUserOwn(loginId) {
      const credential = await getCurrentAuthenticatedUser();
      //console.log({credential});
      //console.log({loginId});
      //console.log(credential.username === loginId);
      return credential.username === loginId;
    },
    /* 担当者データ論理削除 */
    async updateStaff(staffId){
      let date = new Date();
      let now = date.getFullYear() + '-' + ('00' + (date.getMonth()+1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
      now += ' ' + ('00' + (date.getHours())).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
      let username = this.$store.getters.user.username;

      let sqlList = [
        'UPDATE m_staffs SET '
        + 'is_deleted = 1 ,'
        + 'updated = \'' + now +'\','
        + 'updated_user = \'' + username + '\' '
        + 'WHERE staff_id = ' + staffId
      ];
      console.info(sqlList[0]);
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: sqlList };
      //console.info({condition});
      try {
        // executeTransactSqlで実行
        let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        // レスポンスデータを取得できた際の処理
        if(result) {
          // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if(result.data.executeTransactSql.statusCode > 200) {
            this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
            console.info({responseBody});
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({message});
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({errorMessage});
          } else {
            // SQLが正常に終了した際の処理
            this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
            this.login_id_before = this.selectOffice.id;
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              /*
              for (const rows of responseBody.data) {
                console.dir(rows, {depth: null});
              }
              */
            }
            await this.search();
          }
          //console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
        }
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.error(error);
      }
    },
    // 復旧ボタン押下時
    async clickRestoreBtn(staffId){
      let route = this.$router.resolve({ name: 'STAFF-RESTORE', query: { staffId: staffId, parentKbn: 0, restoreFlg: '1' } });
      window.open(route.href, '_blank');
    },
  },
}
</script>
<style scoped>
  table {
    width: 100% !important;
    table-layout: auto;
  }

  table thead tr th{
    background-color: gray;
  }
</style>