<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="kokyaku in listKokyaku" v-bind:key="kokyaku.clientId">
      <div v-for="chouhyou in kokyaku.listChouhyou" v-bind:key="kokyaku.clientId + '_' + chouhyou.page">
        <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
          <TemplateSupplierLedgerStart :id="constData.chouhyouId + kokyaku.clientId + '_' + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
          <TemplateSupplierLedgerEnd :id="constData.chouhyouId + kokyaku.clientId + '_' + chouhyou.page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateSupplierLedgerStart from '@/assets/svg/supplierLedger_Start.svg';
import TemplateSupplierLedgerEnd from '@/assets/svg/supplierLedger_End.svg';
import { setPaperA4Landscape, setChouhyouBodyStyle, addOperationLogs, getNullStr, formatDate, formatCurDate, getListValue, getControlMaster, executeSelectSql, executeSelectSqlNoLimit, getClosingDate, dateConsistency } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_closing_update_range } from '@/graphql/queries';

const MODULE_NAME = 'supplier-ledger';

export default {
  name: 'SUPPLIER-LEDGER',
  /* コンポーネント */
  components: {
    TemplateSupplierLedgerStart,
    TemplateSupplierLedgerEnd,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 40,
        cntEnd: 44,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
        totalRowTitle: '<仕入先合計>',
        totalLineX: 83,
        totalLineWidth: 2360,
        totalLineHeight: 28,
      },
      // ヘッダ
      menu_type: 'user',
      title: '仕入先元帳（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '445mm',
        height: '314mm',
      },
      listKokyaku: [],
      replacementsCommon: [],
      lineY: 0,
      // 支払前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // アラート
      alertWarning: [],
      alertDanger: [],
      // パラメータ
      paymentMonthYear: null,
      clientIdStart: null,
      clientIdEnd: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
        this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.paymentMonthYear = this.$route.query.paymentMonthYear;
    this.clientIdStart = this.$route.query.clientIdStart;
    this.clientIdEnd = this.$route.query.clientIdEnd;
    // 印刷レイアウト設定
    setPaperA4Landscape();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // テーブルからデータを取得して、設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '仕入先元帳_' + this.paymentMonthYear;
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('timerAll');
        // 表示データ設定
        //console.time('timerDisp');
        await this.setDispData();
        //console.timeEnd('timerDisp');
        //console.timeEnd('timerAll');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 表示データ設定 */
    async setDispData() {
      // 請求日（開始日と終了日）の取得
      await this.getBillingDateInfo();
      //console.time('timerSql1');
      // 検索SQL作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.timeEnd('timerSql1');
      //console.time('timerSql2');
      // 検索合計SQL作成
      let selectTotalSql = '';
      selectTotalSql = await this.makeSelectSqlTotal();
      //console.log(selectTotalSql);
      //console.timeEnd('timerSql2');
      //console.time('timerSelect2');
      let dataResultTotal = await executeSelectSql(selectTotalSql);
      //console.timeEnd('timerSelect2');
      //console.log(dataResultTotal);
      if (dataResultTotal != null && dataResultTotal.length > 0) {
        if (dataResultTotal.length > Const.PdfSupplierLedgerClientMaxCnt) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2049'].replace('%arg1%', Const.PdfSupplierLedgerClientMaxCnt));
          return;
        }
        //console.time('timerSelect1');
        let dataResult = await executeSelectSqlNoLimit(selectSql);
        //console.timeEnd('timerSelect1');
        //console.log(dataResult);
        // 取引先毎の一覧を作成
        //console.time('timer1');
        this.initListKokyaku(dataResultTotal);
        //console.timeEnd('timer1');
        // 共通の置換文字列設定
        // 現在日時
        this.replacementsCommon.push({key: '%現在日時%', value: formatCurDate('YYYY/MM/DD HH:mm'), textAnchor: 'start', textLength: 270, chkWidth: false});
        // ページ毎の置換文字列設定
        //console.time('timer2');
        await this.createReplacementsPage(dataResult);
        //console.timeEnd('timer2');
        // ページ部分の置換文字列追加
        //console.time('timer3');
        this.addReplacementsPage();
        //console.timeEnd('timer3');
        // 作成した置換文字データをSVGファイルに設定
        //console.time('timer4');
        this.setChouhyou();
        //console.timeEnd('timer4');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','仕入先元帳'));
        return;
      }
    },
    /* 検索SELECT文字列作成 */
    async makeSelectSql() {
      /* 請求締更新前のデータ */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' payments.client_id';
      selectSql1 += ',cumulative_transaction.transaction_id';
      selectSql1 += ',cumulative_transaction.billing_no';
      selectSql1 += ',cumulative_transaction.billing_row';
      selectSql1 += ',cumulative_transaction.billing_date';
      selectSql1 += ',cumulative_transaction.billing_summary';
      selectSql1 += ',cumulative_transaction.product_id';
      selectSql1 += ',cumulative_transaction.product_name';
      selectSql1 += ',products.product_tax_rate_class_purchase';
      selectSql1 += ',cumulative_transaction.product_quantity';
      selectSql1 += ',cumulative_transaction.product_unit';
      selectSql1 += ',cumulative_transaction.product_purchase_price';
      selectSql1 += ',cumulative_transaction.product_purchase_amount';
      selectSql1 += ',cumulative_transaction.product_tax';
      selectSql1 += ',cumulative_transaction.product_note';
      selectSql1 += ',cumulative_transaction.kind';
      selectSql1 += ',cumulative_transaction.account_support';
      selectSql1 += ',cumulative_transaction.amount';
      selectSql1 += ',cumulative_transaction.summary';
      selectSql1 += ',cumulative_transaction.settlement_scheduled';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_payments AS payments ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql1 += 'AND payments.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql1 += 'AND payments.client_id = clients.client_id ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 請求年月
      selectSql1 += ' CAST(DATE_FORMAT(payments.payment_end_date,\'%Y%m\') AS SIGNED) = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 仕入先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql1 += 'AND payments.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql1 += 'AND payments.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql1 += 'AND payments.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 製品金額、金額、消費税のいずれかが0以外のデータのみを表示
      selectSql1 += 'AND (cumulative_transaction.product_purchase_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      // 支払締更新をしていないデータ
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      // トランザクションID（「SI:仕入」「SK:支払」）
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
      // 消費税行は除外
      selectSql1 += 'AND (cumulative_transaction.transaction_id = \'' + Const.TransactionId.payment + '\' OR cumulative_transaction.product_id <> 0) ';

      /* 支払締更新後のデータ */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' payments_balances.client_id';
      selectSql2 += ',cumulative_transaction.transaction_id';
      selectSql2 += ',cumulative_transaction.billing_no';
      selectSql2 += ',cumulative_transaction.billing_row';
      selectSql2 += ',cumulative_transaction.billing_date';
      selectSql2 += ',cumulative_transaction.billing_summary';
      selectSql2 += ',cumulative_transaction.product_id';
      selectSql2 += ',cumulative_transaction.product_name';
      selectSql2 += ',products.product_tax_rate_class_purchase';
      selectSql2 += ',cumulative_transaction.product_quantity';
      selectSql2 += ',cumulative_transaction.product_unit';
      selectSql2 += ',cumulative_transaction.product_purchase_price';
      selectSql2 += ',cumulative_transaction.product_purchase_amount';
      selectSql2 += ',cumulative_transaction.product_tax';
      selectSql2 += ',cumulative_transaction.product_note';
      selectSql2 += ',cumulative_transaction.kind';
      selectSql2 += ',cumulative_transaction.account_support';
      selectSql2 += ',cumulative_transaction.amount';
      selectSql2 += ',cumulative_transaction.summary';
      selectSql2 += ',cumulative_transaction.settlement_scheduled';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_payments_balances AS payments_balances ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql2 += 'AND payments_balances.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND payments_balances.payment_month_year = cumulative_transaction.closing_month_year ';
      selectSql2 += 'INNER JOIN m_clients AS clients ';
      selectSql2 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql2 += 'AND payments_balances.client_id = clients.client_id ';
      selectSql2 += 'LEFT JOIN m_products AS products ';
      selectSql2 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      // 支払年月
      selectSql2 += ' cumulative_transaction.closing_month_year = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND payments_balances.payment_start_date IS NOT NULL ';
      // 仕入先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql2 += 'AND payments_balances.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql2 += 'AND payments_balances.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql2 += 'AND payments_balances.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 製品金額、金額、消費税のいずれかが0以外のデータのみを表示
      selectSql2 += 'AND (cumulative_transaction.product_purchase_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      // 支払締更新済みのデータ
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 1 ';
      // トランザクションID（「SI:仕入」「SK:支払」）
      selectSql2 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
      // 消費税行は除外
      selectSql2 += 'AND (cumulative_transaction.transaction_id = \'' + Const.TransactionId.payment + '\' OR cumulative_transaction.product_id <> 0) ';

      // 2つのSELECT文をUNION
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.paymentMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が支払前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
        /* ORDER BY句 */
        selectSql += 'ORDER BY client_id,billing_date,transaction_id,billing_no,billing_row';
      } else {
        // 画面から選択した年月が支払前処理の年月と異なる場合
        // 支払残高の登録情報のみを表示
        selectSql = selectSql2;
        /* ORDER BY句 */
        selectSql += 'ORDER BY payments_balances.client_id,cumulative_transaction.billing_date,cumulative_transaction.transaction_id,cumulative_transaction.billing_no,cumulative_transaction.billing_row';
      }

      return selectSql;
    },
    /* 検索SELECT文字列作成（取引先別合計用） */
    async makeSelectSqlTotal() {
      /* 支払締更新前の処理月情報あり、前月情報あり */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' payments.client_id';
      selectSql1 += ',clients.client_name_kanji';
      selectSql1 += ',payments.payment_end_date';
      selectSql1 += ',clients.business_class';
      selectSql1 += ',payments_balances.pre_no_tax_payment_balances';
      selectSql1 += ',payments_balances.pre_tax_payment_balances';
      selectSql1 += ',SUM(cumulative_transaction.product_purchase_amount) AS product_purchase_amount';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.tax + '\',\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.tax + '\',\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql1 += ',clients.sales_tax_class';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_purchase_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_purchase_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_payments AS payments ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql1 += 'AND payments.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_purchase_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql1 += 'AND payments.client_id = clients.client_id ';
      selectSql1 += 'INNER JOIN t_payments_balances AS payments_balances ';
      selectSql1 += 'ON payments.client_id = payments_balances.client_id ';
      selectSql1 += 'AND (payments_balances.pre_no_tax_payment_balances <> 0 OR payments_balances.pre_tax_payment_balances <> 0) ';
      selectSql1 += 'AND payments_balances.payment_month_year = ' + formatDate(this.paymentMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND payments_balances.payment_start_date IS NULL ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 支払年月
      selectSql1 += 'CAST(DATE_FORMAT(payments.payment_end_date,\'%Y%m\') AS SIGNED) = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 仕入先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql1 += 'AND payments.client_id >= ' + this.clientIdStart + ' ';
      } else if (this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql1 += 'AND payments.client_id <= ' + this.clientIdEnd + ' ';
      } else if (this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql1 += 'AND payments.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += 'payments.client_id';
      /* 支払締更新前の処理月情報あり、前月情報なし */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' payments.client_id';
      selectSql2 += ',clients.client_name_kanji';
      selectSql2 += ',payments.payment_end_date';
      selectSql2 += ',clients.business_class';
      selectSql2 += ',0 AS pre_no_tax_payment_balances';
      selectSql2 += ',0 AS pre_tax_payment_balances';
      selectSql2 += ',SUM(cumulative_transaction.product_purchase_amount) AS product_purchase_amount';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.tax + '\',\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.tax + '\',\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql2 += ',clients.sales_tax_class';
      selectSql2 += ',SUM(';
      selectSql2 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql2 += '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_purchase_amount ELSE 0 END) ';
      selectSql2 += '   ELSE 0 END)';
      selectSql2 += ' AS closing_date_normal_tax_subtotal';
      selectSql2 += ',TRUNCATE((SUM(';
      selectSql2 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql2 += '     THEN cumulative_transaction.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql2 += '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql2 += '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql2 += '   ELSE 0 END) / 100),0)';
      selectSql2 += ' AS closing_date_normal_tax';
      selectSql2 += ',SUM(';
      selectSql2 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql2 += '     THEN (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_purchase_amount ELSE 0 END) ';
      selectSql2 += '   ELSE 0 END)';
      selectSql2 += ' AS closing_date_light_tax_subtotal';
      selectSql2 += ',TRUNCATE((SUM(';
      selectSql2 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql2 += '     THEN cumulative_transaction.product_purchase_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql2 += '       (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql2 += '     ELSE (CASE products.product_tax_rate_class_purchase WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql2 += '   ELSE 0 END) / 100),0)';
      selectSql2 += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 'm_payments AS payments ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql2 += 'AND payments.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND (cumulative_transaction.product_purchase_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
      selectSql2 += 'INNER JOIN m_clients AS clients ';
      selectSql2 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql2 += 'AND payments.client_id = clients.client_id ';
      selectSql2 += 'LEFT JOIN t_payments_balances AS payments_balances ';
      selectSql2 += 'ON payments.client_id = payments_balances.client_id ';
      selectSql2 += 'AND (payments_balances.pre_no_tax_payment_balances <> 0 OR payments_balances.pre_tax_payment_balances <> 0) ';
      selectSql2 += 'AND payments_balances.payment_month_year = ' + formatDate(this.paymentMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql2 += 'AND payments_balances.payment_start_date IS NULL ';
      selectSql2 += 'LEFT JOIN m_products AS products ';
      selectSql2 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'payments_balances.client_id IS NULL ';
      // 支払年月
      selectSql2 += 'AND CAST(DATE_FORMAT(payments.payment_end_date,\'%Y%m\') AS SIGNED) = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 仕入先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql2 += 'AND payments.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql2 += 'AND payments.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql2 += 'AND payments.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += 'payments.client_id';
      /* 支払締更新前の処理月情報なし、前月情報あり */
      let selectSql3 = '';
      /* SELECT句 */
      selectSql3 += 'SELECT ';
      selectSql3 += ' payments.client_id';
      selectSql3 += ',clients.client_name_kanji';
      selectSql3 += ',payments.payment_end_date';
      selectSql3 += ',clients.business_class';
      selectSql3 += ',payments_balances.pre_no_tax_payment_balances';
      selectSql3 += ',payments_balances.pre_tax_payment_balances';
      selectSql3 += ',0 AS product_purchase_amount';
      selectSql3 += ',0 AS amount_no_tax';
      selectSql3 += ',0 AS amount_tax';
      selectSql3 += ',clients.sales_tax_class';
      selectSql3 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql3 += ',0 AS closing_date_normal_tax';
      selectSql3 += ',0 AS closing_date_light_tax_subtotal';
      selectSql3 += ',0 AS closing_date_light_tax';
      /* FROM句 */
      selectSql3 += ' FROM ';
      selectSql3 += 'm_payments AS payments ';
      selectSql3 += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql3 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql3 += 'AND payments.client_id = cumulative_transaction.client_id ';
      selectSql3 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql3 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.stocking + '\',\'' + Const.TransactionId.payment + '\') ';
      selectSql3 += 'INNER JOIN m_clients AS clients ';
      selectSql3 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql3 += 'AND payments.client_id = clients.client_id ';
      selectSql3 += 'INNER JOIN t_payments_balances AS payments_balances ';
      selectSql3 += 'ON payments.client_id = payments_balances.client_id ';
      selectSql3 += 'AND (payments_balances.pre_no_tax_payment_balances <> 0 OR payments_balances.pre_tax_payment_balances <> 0) ';
      selectSql3 += 'AND payments_balances.payment_month_year = ' + formatDate(this.paymentMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += 'AND payments_balances.payment_start_date IS NULL ';
      /* WHERE句 */
      selectSql3 += ' WHERE ';
      selectSql3 += 'cumulative_transaction.billing_no IS NULL ';
      // 支払年月
      selectSql3 += 'AND CAST(DATE_FORMAT(payments.payment_end_date,\'%Y%m\') AS SIGNED) = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 仕入先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql3 += 'AND payments.client_id >= ' + this.clientIdStart + ' ';
      } else if (this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql3 += 'AND payments.client_id <= ' + this.clientIdEnd + ' ';
      } else if (this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql3 += 'AND payments.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql3 += ' GROUP BY ';
      selectSql3 += 'payments.client_id';
      /* 支払締更新後 */
      let selectSql4 = '';
      /* SELECT句 */
      selectSql4 += 'SELECT ';
      selectSql4 += ' payments_balances.client_id';
      selectSql4 += ',clients.client_name_kanji';
      selectSql4 += ',payments_balances.payment_end_date';
      selectSql4 += ',clients.business_class';
      selectSql4 += ',payments_balances.pre_no_tax_payment_balances ';
      selectSql4 += ',payments_balances.pre_tax_payment_balances';
      selectSql4 += ',payments_balances.closing_date_payable AS product_purchase_amount';
      selectSql4 += ',payments_balances.closing_date_payments AS amount_no_tax';
      selectSql4 += ',payments_balances.closing_date_tax_payments AS amount_tax';
      selectSql4 += ',clients.sales_tax_class';
      selectSql4 += ',payments_balances.closing_date_normal_tax_subtotal';
      selectSql4 += ',payments_balances.closing_date_normal_tax';
      selectSql4 += ',payments_balances.closing_date_light_tax_subtotal';
      selectSql4 += ',payments_balances.closing_date_light_tax';
      /* FROM句 */
      selectSql4 += ' FROM ';
      selectSql4 += 't_payments_balances AS payments_balances ';
      selectSql4 += 'INNER JOIN m_clients AS clients ';
      selectSql4 += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql4 += 'AND payments_balances.client_id = clients.client_id ';
      /* WHERE句 */
      selectSql4 += ' WHERE ';
      selectSql4 += '(payments_balances.closing_date_payable <> 0 OR payments_balances.closing_date_tax <> 0 OR payments_balances.pre_no_tax_payment_balances <> 0 OR payments_balances.pre_tax_payment_balances <> 0 OR payments_balances.closing_date_payments <> 0 OR payments_balances.closing_date_tax_payments <> 0) ';
      // 支払年月
      selectSql4 += 'AND payments_balances.payment_month_year = ' + this.paymentMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql4 += 'AND payments_balances.payment_start_date IS NOT NULL ';
      // 仕入先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql4 += 'AND payments_balances.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql4 += 'AND payments_balances.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql4 += 'AND payments_balances.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql4 += ' GROUP BY ';
      selectSql4 += 'payments_balances.client_id';

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.paymentMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が支払前処理の年月と同じ場合
        // 4つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql3 + ' UNION ALL ' + selectSql4;
        /* ORDER BY句 */
        selectSql += ' ORDER BY ';
        selectSql += ' client_id ';
      } else {
        // 画面から選択した年月が支払前処理の年月と異なる場合
        // 支払残高の登録情報のみを表示
        selectSql = selectSql4;
        /* ORDER BY句 */
        selectSql += ' ORDER BY ';
        selectSql += ' payments_balances.client_id ';
      }

      return selectSql;
    },
    /* 顧客毎の一覧を作成 */
    initListKokyaku: function(result) {
      //console.log('initListKokyaku');
      let chkWidth = false;
      // DBの結果分ループ
      for (let i = 0; i < result.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // タイトル
        replacements.push({key: '%タイトル%', value: '仕　入　先　元　帳　（　' + formatDate(this.paymentMonthYear + '-01', 'YYYY/MM') + '　）', textAnchor: 'middle', textLength: 900, chkWidth: false});
        // 仕入先コード
        replacements.push({key: '%仕CD%', value: result[i].client_id, textAnchor: 'start', textLength: 140, chkWidth: false});
        // 仕入先名
        if (getNullStr(result[i].client_name_kanji).length > 14) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%仕名%', value: result[i].client_name_kanji, textAnchor: 'start', textLength: 430, chkWidth: chkWidth});
        // 締日
        replacements.push({key: '%締日%', value: formatDate(result[i].payment_end_date), textAnchor: 'middle', textLength: 220, chkWidth: false});
        // 金種
        replacements.push({key: '%金種%', value: result[i].business_class + '（　' + getListValue(Const.businessClassList, result[i].business_class.toString()) + '　）', textAnchor: 'start', textLength: 220, chkWidth: false});
        // 繰越税抜
        if (getNullStr(result[i].pre_no_tax_payment_balances).length > 9) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%繰越額%', value: Number(result[i].pre_no_tax_payment_balances).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // 繰越消費税
        if (getNullStr(result[i].pre_tax_payment_balances).length > 9) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%繰越税%', value: Number(result[i].pre_tax_payment_balances).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        let listChouhyou = [];
        listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: replacements});
        this.listKokyaku.push({ clientId: result[i].client_id, clientNameKanji: result[i].client_name_kanji, totalData: result[i], listChouhyou: listChouhyou, billingEndDate: result[i].payment_end_date, nextForward: Number(result[i].pre_no_tax_payment_balances) + Number(result[i].pre_tax_payment_balances) });
      }
    },
    /* 顧客毎の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let replacements;
      let index = 0;
      let preTranctionId = '';
      let preBillingNo = 0;
      let billingFlg = true;
      let page = 0;
      let totalBalance = 0;
      let totalBalanceTax = 0;
      let totalMoney = 0;
      let totalPayment = 0;
      let totalPaymentTax = 0;
      let totalFlg1 = false;
      let totalFlg2 = false;
      let chkWidth = false;
      let strWork = '';
      for (let i = 0; i < this.listKokyaku.length; i++) {
        // 顧客変更時の初期化
        page = 1;
        preTranctionId = '';
        preBillingNo = 0;
        billingFlg = true;
        totalFlg1 = false;
        totalFlg2 = false;
        // 税無しと税ありは分けない（売上課税区分「0:外税」の場合は最後に合計から税額を計算）
        totalBalance = Number(this.listKokyaku[i].totalData.pre_no_tax_payment_balances);
        totalBalanceTax = Number(this.listKokyaku[i].totalData.pre_tax_payment_balances);
        totalMoney = 0;
        totalPayment = 0;
        totalPaymentTax = 0;
        if (result != null && index < result.length && this.listKokyaku[i].clientId == result[index].client_id) {
          // 帳票毎に設定可能な製品の件数
          while(index < result.length && this.listKokyaku[i].clientId == result[index].client_id) {
            let billingCntByChouhyou = 0;
            // SVGファイルの置換用文字列
            replacements = [];
            if (page == 1) {
              billingCntByChouhyou = this.constData.cntStart;
            } else {
              this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
              billingCntByChouhyou = this.constData.cntEnd;
            }
            // 伝票
            for (let j = 0; j < billingCntByChouhyou; j++) {
              if ((index >= result.length || this.listKokyaku[i].clientId != result[index].client_id) && totalFlg1 == false) {
                /* 合計行1行目 */
                this.createReplacementsTotal(replacements, 1, j, this.listKokyaku[i].totalData, totalMoney, totalPayment, totalPaymentTax, i);
                totalFlg1 = true;
              } else if ((index >= result.length || this.listKokyaku[i].clientId != result[index].client_id) && totalFlg2 == false) {
                /* 合計行2行目 */
                this.createReplacementsTotal(replacements, 2, j, this.listKokyaku[i].totalData, totalMoney, totalPayment, totalPaymentTax, i);
                totalFlg2 = true;
              } else if (result.length <= index ||
                (this.listKokyaku[i].clientId != result[index].client_id) ||
                (preBillingNo != 0 && (preBillingNo != result[index].billing_no || preTranctionId != result[index].transaction_id))) {
                // 先頭以外で伝票番号、または、トランザクションIDが切り替わった場合、または、
                // 同取引先の書き込みが終わった場合、または、
                // テーブルデータの参照が終わった場合
                /* 空行 */
                // 日付
                replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
                // 摘要
                replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 540, chkWidth: false});
                // 備考
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 300, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 支払額
                replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 決済予定
                replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
                // 残高
                replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
                if (index < result.length) {
                  preBillingNo = result[index].billing_no;
                  preTranctionId = result[index].transaction_id;
                }
                billingFlg = true;
              }else{
                if (billingFlg == true) {
                  /* 伝票・現場行 */
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: formatDate(result[index].billing_date), textAnchor: 'start', textLength: 176, chkWidth: false});
                  // コード
                  replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].billing_no == 0 ? '' : result[index].billing_no, textAnchor: 'start', textLength: 170, chkWidth: false});
                  // 摘要
                  if (getNullStr(result[index].billing_summary).length > 17) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%名称' + (j + 1).toString() + '%', value: getNullStr(result[index].billing_summary), textAnchor: 'start', textLength: 540, chkWidth: chkWidth});
                  // 備考
                  replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 300, chkWidth: false});
                  // 数量
                  replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 単価
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 支払額
                  replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 決済予定
                  replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
                  // 残高
                  replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
                  billingFlg = false;
                } else {
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
                  /* 製品行 */
                  if (result[index].product_purchase_amount != 0) {
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: 170, chkWidth: false});
                    // 製品名
                    strWork = result[index].product_name;
                    if (result[index].product_tax_rate_class_purchase == Const.ProductTaxRateClass.lightTax) {
                      strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                    }
                    if (getNullStr(strWork).length > 17) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 540, chkWidth: chkWidth});
                    // 備考
                    if (getNullStr(result[index].product_note).length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[index].product_note, textAnchor: 'start', textLength: 300, chkWidth: chkWidth});
                    // 数量
                    strWork = result[index].product_quantity.toLocaleString() + (getNullStr(result[index].product_unit) == '' ? '' : ' ' + result[index].product_unit);
                    if (getNullStr(strWork).length > 5) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: result[index].product_purchase_price.toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_purchase_amount.toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 支払額
                    replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
                    // 残高
                    totalBalance += Number(result[index].product_purchase_amount);
                    totalMoney += Number(result[index].product_purchase_amount);
                    chkWidth = false;
                    if (totalBalance.length > 10) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalBalance.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                  } else if (result[index].product_tax != 0) {
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
                    // 製品名
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'start', textLength: 540, chkWidth: false});
                    // 備考
                    replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 300, chkWidth: false});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_tax.toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 支払額
                    replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
                    // 残高
                    totalBalanceTax += Number(result[index].product_tax);
                    chkWidth = false;
                    if (totalBalanceTax.length > 10) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalBalanceTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                  } else {
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
                    // 製品名
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '（　' + getNullStr(result[index].kind) + '　' + (getNullStr(getListValue(Const.denominationList, result[index].kind)) + '　　　').substr(0, 3) + '　' + result[index].account_support + '　）', textAnchor: 'end', textLength: 540, chkWidth: false});
                    // 備考
                    if (getNullStr(result[index].summary).length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[index].summary, textAnchor: 'start', textLength: 300, chkWidth: chkWidth});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 支払額
                    replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: result[index].amount.toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: formatDate(result[index].settlement_scheduled), textAnchor: 'start', textLength: 176, chkWidth: false});
                    // 残高
                    if (result[index].kind == Const.denomination.tax ||
                    result[index].kind == Const.denomination.offsetTax ||
                    result[index].kind == Const.denomination.billsTax) {
                      // 消費税の場合は消費税の残高を表示
                      totalBalanceTax -= Number(result[index].amount);
                      strWork = getNullStr(totalBalanceTax);
                    } else {
                      // 消費税以外の場合は税抜の残高を表示
                      totalBalance -= Number(result[index].amount);
                      strWork = getNullStr(totalBalance);
                    }
                    chkWidth = false;
                    if (strWork.length > 10) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: Number(strWork).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                    if (result[index].kind == Const.denomination.tax ||
                    result[index].kind == Const.denomination.offsetTax ||
                    result[index].kind == Const.denomination.billsTax) {
                      // 消費税の場合は消費税を累積
                      totalPaymentTax += Number(result[index].amount);
                    } else {
                      // 消費税以外の場合は税抜を累積
                      totalPayment += Number(result[index].amount);
                    }
                  }
                  preTranctionId = result[index].transaction_id;
                  preBillingNo = result[index].billing_no;
                  index++;
                }
              }
            }
            //console.log('replacements');
            //console.log(replacements);
            Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
            page++;
          }
          // 合計行の記載が残っている場合
          if (totalFlg1 == false || totalFlg2 == false) {
            // SVGファイルの置換用文字列
            replacements = [];
            this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
            // 伝票
            for (let j = 0; j < this.constData.cntEnd; j++) {
              if (totalFlg1 == false) {
                /* 合計行1行目 */
                this.createReplacementsTotal(replacements, 1, j, this.listKokyaku[i].totalData, totalMoney, totalPayment, totalPaymentTax, i);
                totalFlg1 = true;
              } else if (totalFlg2 == false) {
                /* 合計行2行目 */
                this.createReplacementsTotal(replacements, 2, j, this.listKokyaku[i].totalData, totalMoney, totalPayment, totalPaymentTax, i);
                totalFlg2 = true;
              } else {
                /* 空行 */
                // 日付
                replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
                // 摘要
                replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 540, chkWidth: false});
                // 備考
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 300, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 支払額
                replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                // 決済予定
                replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
                // 残高
                replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
              }
            }
            Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
          }
        } else {
          Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, this.createReplacementsBlank());
        }
      }
    },
    /* 顧客の置換配列セット（合計行） */
    createReplacementsTotal: function(replacements, rowNo, j, totalData, totalMoney, totalPayment, totalPaymentTax, kokyakuIndex) {
      //console.log('createReplacementsTotal');
      let billingIssueInputDate = totalData.payment_end_date;
      let chkWidth = false;
      // SVGファイルの置換用文字列
      if (rowNo == 1) {
        /* 合計行1行目 */
        let taxRate = 0;
        if (this.controlMasterData.newTaxStartDate == null ||
          dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
          taxRate = this.controlMasterData.taxRate;
        } else {
          taxRate = this.controlMasterData.newTaxRate;
        }
        let taxRateSubTotalTitle = '消費税' + taxRate + '%課税 小計';
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: this.constData.totalRowTitle, textAnchor: 'start', textLength: 170, chkWidth: true});
        // 摘要
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: taxRateSubTotalTitle + '　' + Number(totalData.closing_date_normal_tax_subtotal).toLocaleString(), textAnchor: 'end', textLength: 540, chkWidth: true});
        // 備考
        replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'end', textLength: 300, chkWidth: false});
        // 数量
        if (getNullStr(totalData.closing_date_normal_tax).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: Number(totalData.closing_date_normal_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '税　抜', textAnchor: 'middle', textLength: 170, chkWidth: false});
        // 金額
        if (getNullStr(totalData.product_purchase_amount).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: Number(totalData.product_purchase_amount).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 支払額
        if (getNullStr(totalData.amount_no_tax).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: Number(totalData.amount_no_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
        // 残高
        let totalNoTax = 0;
        totalNoTax = Number(totalData.pre_no_tax_payment_balances) + totalMoney - totalPayment;
        if (totalNoTax.length > 10) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalNoTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // ページ右下の繰越金額用
        this.listKokyaku[kokyakuIndex].nextForward = totalNoTax;
      } else {
        /* 合計行2行目 */
        let lightTaxRate = 0;
        if (this.controlMasterData.newTaxStartDate == null ||
          dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
          lightTaxRate = this.controlMasterData.lightTaxRate;
        } else {
          lightTaxRate = this.controlMasterData.newLightTaxRate;
        }
        let lightTaxRateSubTotalTitle = '消費税' + lightTaxRate + '%課税（' + this.controlMasterData.lightTaxMark + '） 小計'
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // 摘要
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: lightTaxRateSubTotalTitle + '　' + Number(totalData.closing_date_light_tax_subtotal).toLocaleString(), textAnchor: 'end', textLength: 540, chkWidth: true});
        // 備考
        replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'end', textLength: 300, chkWidth: false});
        // 数量
        if (getNullStr(totalData.closing_date_light_tax).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: Number(totalData.closing_date_light_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'middle', textLength: 170, chkWidth: false});
        // 金額
        if (getNullStr(Number(totalData.closing_date_normal_tax) + Number(totalData.closing_date_light_tax)).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: (Number(totalData.closing_date_normal_tax) + Number(totalData.closing_date_light_tax)).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 支払額
        if (getNullStr(totalData.amount_tax).length > 8) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: Number(totalData.amount_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
        // 残高
        let totalTax = 0;
        totalTax = Number(totalData.pre_tax_payment_balances) - totalPaymentTax;
        totalTax += Number(totalData.closing_date_normal_tax) + Number(totalData.closing_date_light_tax);
        if (totalTax.length > 10) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // ページ右下の繰越金額用
        this.listKokyaku[kokyakuIndex].nextForward += totalTax;
      }
      //console.log(replacements);
      return replacements;
    },
    /* 顧客の置換配列セット（伝票部分は全て空） */
    createReplacementsBlank: function() {
      //console.log('createReplacementsBlank');
      // SVGファイルの置換用文字列
      let replacements = [];
      // 伝票
      for (let j = 0; j < this.constData.cntStart; j++) {
        /* 空行 */
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 176, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // 摘要
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 540, chkWidth: false});
        // 備考
        replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 300, chkWidth: false});
        // 数量
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 金額
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 支払額
        replacements.push({key: '%支払額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 176, chkWidth: false});
        // 残高
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
      }
      return replacements;
    },
    /* ページ追加 */
    addReplacementsPage: function() {
      //console.log('addReplacementsPage');
      let strWork = '';
      let chkWidth = false;
      for (let i = 0; i < this.listKokyaku.length; i++) {
        for (let j = 0; j < this.listKokyaku[i].listChouhyou.length; j++) {
          // ページ番号
          strWork = this.listKokyaku[i].listChouhyou[j].page + ' / ' + this.listKokyaku[i].listChouhyou.length;
          if (getNullStr(strWork).length > 7) {
            chkWidth = true;
          } else {
            chkWidth = false;
          }
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%P%', value: strWork, textAnchor: 'end', textLength: 160, chkWidth: chkWidth});
          // 繰越額2
          if (getNullStr(this.listKokyaku[i].nextForward).length > 9) {
            chkWidth = true;
          } else {
            chkWidth = false;
          }
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%繰越額2%', value: this.listKokyaku[i].nextForward.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
          if (j > 0) {
            // 仕入先情報
            // 仕入先コード
            this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%仕CD%', value: this.listKokyaku[i].clientId, textAnchor: 'start', textLength: 140, chkWidth: false});
            // 仕入先名
            if (this.listKokyaku[i].clientNameKanji.length > 14) {
              chkWidth = true;
            } else {
              chkWidth = false;
            }
            this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%仕名%', value: this.listKokyaku[i].clientNameKanji, textAnchor: 'start', textLength: 430, chkWidth: chkWidth});
          }
        }
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function() {
      //console.log('setChouhyou');
      for (let j = 0; j < this.listKokyaku.length; j++) {
        for (let i = 0; i < this.listKokyaku[j].listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + this.listKokyaku[j].clientId + '_' + this.listKokyaku[j].listChouhyou[i].page);
          this.setReplacements(svgDoc, this.listKokyaku[j].listChouhyou[i].replacements);
          if (this.lineY > 0) {
            svgDoc.append(this.createLine(this.lineY));
            this.lineY = 0;
          }
          this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  this.setTspan(node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 合計行の場合
      if (config.value == this.constData.totalRowTitle) {
        /* 線を追加 */
        this.lineY = parseFloat(tagTspan.getAttribute('y')) - this.constData.totalLineHeight;
      }
    },
    /* 合計行を示す線を作成 */
    createLine: function(y) {
      let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', this.constData.totalLineX);
      line.setAttribute('x2', this.constData.totalLineX + this.constData.totalLineWidth);
      line.setAttribute('y1', y);
      line.setAttribute('y2', y);
      line.setAttribute('stroke', '#000000');
      line.setAttribute('stroke-width', 2);
      line.setAttribute('stroke-dasharray', '10,10');
      return line;
    },
    /* 取得結果セット */
    setSize: function(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBilling = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';
      [preBilling, controlData] = await Promise.all([
        API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause})),
        getControlMaster(),
      ]);
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
      let preBillingResult = preBilling.data.list_t_closing_update_range;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
  },
}
</script>
<style scoped>
  body {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>