<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mb-2">
      <b-col>
        <b-media>
          <b-media-body class="mb-1">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                <strong> 保存電子書類入力</strong>
                <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
              </h5>
              <b-button pill v-b-tooltip.hover.noninteractive.bottom="'保存電子書類一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                <span class="oi oi-circle-x"></span> キャンセル
              </b-button>
            </div>
          </b-media-body>
        </b-media>
        <div class="main-card mb-3 card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <div class="card-body">
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">           
                <b-row>
                  <b-col>
                    <b-input-group>
                      <b-input-group-text class="px-0 mr-2" v-b-tooltip.hover.noninteractive.right="'データ登録中（行が1行でもある場合）は変更不可です。'">
                        電子書類種類&nbsp;<span class="oi oi-flag"/>
                      </b-input-group-text>
                      <b-form-radio-group
                        id="documentsKindList"
                        v-model="checkDocumentsKind"
                        :options="documentsKindList"
                        :disabled="documentList.length > 0"
                        value-field="id"
                        size="sm"
                        @change="setFields"
                      ></b-form-radio-group>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-input-group>
                      <b-input-group-text class="px-0 mr-2" v-b-tooltip.hover.noninteractive.right="'行毎アップロード：登録行毎に電子書類をアップロードします。 ページ分割アップロード：１つの大きな電子書類をアップロードして、それを各登録行にページ分割してアップロードします。'">
                        書類アップロード方法&nbsp;<span class="oi oi-flag"/>
                      </b-input-group-text>
                      <b-form-radio-group
                        v-model="checkDocumentsUploadWay"
                        :options="documentsUploadWayList"
                        :disabled="documentList.length > 0"
                        value-field="id"
                        size="sm"
                        @change="setFields"
                      ></b-form-radio-group>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col sm="6" md="6" lg="6" xl="6">
                    <label
                      class="form-label"
                      for="fileDataCsv"
                      v-b-tooltip.hover.noninteractive.right="'CSVファイルを読み込み行に追加します。'"
                    >
                      CSVファイル読込（.csv）<span class="oi oi-flag"/>
                    </label>
                    <b-form-file
                      size="sm"
                      id="fileDataCsv"
                      v-model="fileDataCsv"
                      placeholder=""
                      drop-placeholder="ここにファイルをドロップ可能です。"
                      accept=".csv"
                    >
                    </b-form-file>
                    <b-button pill variant="success" size="sm" @click="clearAlert(); clickAddCsvRow();" class="mr-2" :disabled="fileDataCsv==null">
                      <span class="oi oi-plus"></span> CSVデータ行追加
                    </b-button>
                  </b-col>
                  <b-col sm="6" md="6" lg="6" xl="6" v-show="checkDocumentsUploadWay==htmlConst.DocumentsUploadWay.pageSplit">
                    <label
                      class="form-label"
                      for="fileDataPdf"
                      v-b-tooltip.hover.noninteractive.right="'保存時にページ分割して各行の電子書類として登録される、複数の取引が記載された電子書類を読み込みます。'"
                    >
                    ページ分割PDF読込（.pdf）<span class="oi oi-flag"/>
                    </label>
                    <b-form-file
                      size="sm"
                      id="fileDataPdf"
                      v-model="fileDataPdf"
                      placeholder=""
                      drop-placeholder="ここにファイルをドロップ可能です。"
                      accept=".pdf"
                    >
                    </b-form-file>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <b-btn-toolbar>
                      <b-button pill variant="success" size="sm" @click="addTableRow()" class="mr-2">
                        <span class="oi oi-plus"></span> 行追加
                      </b-button>
                      <b-button pill variant="success" size="sm" @click="addTableRow(5)" class="mr-2">
                        <span class="oi oi-plus"></span> 5行追加
                      </b-button>
                      <b-button pill variant="success" size="sm" @click="bulkDeleteBtn()" :disabled="checkedIndexs.length == 0">
                        <span class="oi oi-delete"></span> 一括削除
                      </b-button>
                    </b-btn-toolbar>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 書類登録 -->
                  <b-col lg="12">
                    <b-table
                      :items="documentList"
                      :fields="fields"
                      :small="true"
                    >
                      <template #head(Check)>
                        削除<b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="documentList.length == 0" />
                      </template>
                      <template #cell(Check)="data">
                        <b-check v-model="checkedIndexs" :value="data.index" @change="changeCheck"/>
                      </template>
                      <template #cell(SelectDocumentsClass)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 150px;">
                              <b-form-select
                                v-model="data.item.SelectDocumentsClass"
                                :options="electronicDocumentsClassList"
                                value-field="id"
                                size="sm"
                              />
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(SelectOfficeId)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 110px;">
                              <b-form-select
                                v-model="data.item.SelectOfficeId"
                                :options="salesOffice"
                                value-field="id"
                                size="sm"
                              />
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(ClientClass)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 110px;">
                              <b-form-select
                                v-model="data.item.ClientClass"
                                :options="clientClassList"
                                size="sm"
                                @change="clientClear(data.index)"
                              />
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(ClientId)="data">
                        <validation-provider rules="required|numeric|min:6" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 110px;">
                              <b-form-input
                                :readonly="checkDocumentsKind==htmlConst.DocumentsKind.electronicDocuments&&data.item.ClientClass==''"
                                type="text"
                                size="sm"
                                maxlength="6"
                                v-bind:value="data.item.ClientId"
                                v-on:input="data.item.ClientId=$event; if (data.item.ClientId != null && data.item.ClientId.length==6) {searchClient(data.item.ClientClass, data.item.ClientId, data.index);}"
                              />
                              <b-input-group-text @click="showClientSearchModal(data.index)" style="background: none; border: none; padding: 0px;">
                                <b-button size="sm" variant="light" :disabled="checkDocumentsKind==htmlConst.DocumentsKind.electronicDocuments&&data.item.ClientClass==''">
                                  <span class="oi oi-magnifying-glass"></span>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(ClientName)="data">
                        <div style="max-width:250px;">
                          <div style="width:100%; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                            <span v-b-tooltip.hover.noninteractive.right="data.item.ClientName">
                              {{ data.item.ClientName }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <template #cell(DateTransactionDate)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <b-input-group style="width: 140px;">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group class="input-daterange">
                                  <b-form-input
                                    v-model="data.item.DateTransactionDate"
                                    size="sm"
                                    tabindex="-1"
                                    readonly
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button size="sm" @click="openModal(data.item)">
                                      <span class="oi oi-calendar"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes">
                                <b-form-group size="sm" style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                              </b-col>
                            </b-row>
                          </b-input-group>
                        </validation-provider>
                      </template>
                      <template #cell(TextMoney)="data">
                        <validation-provider rules="required|between:-9999999999,9999999999" v-slot="{ classes,errors }">
                          <b-input-group style="width: 120px;">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-form-input :id="'textMoney' + data.index" size="sm" class="px-1" type="number" v-bind:value="data.item.TextMoney" v-on:blur="data.item.TextMoney=$event.target.value" />
                              </b-col>
                              <b-col lg="12" :class="classes">
                                <b-form-group size="sm" style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                              </b-col>
                            </b-row>
                          </b-input-group>
                        </validation-provider>
                      </template>
                      <template #cell(TextContents)="data">
                        <b-input-group style="width: 250px;">
                          <b-form-input style="min-width: 250px;" :maxlength="maxlengthTextContents" size="sm" class="px-1" type="text" v-bind:value="data.item.TextContents" v-on:blur="data.item.TextContents=$event.target.value" v-b-tooltip.hover.noninteractive.left="data.item.TextContents" />
                        </b-input-group>
                      </template>
                      <template #cell(BillingMonthYear)="data">
                        <validation-provider :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                          <b-input-group>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-form-input size="sm" type="month" v-model="data.item.BillingMonthYear" @change="setClosingYmd(data.item);" />
                              </b-col>
                              <b-col lg="12" :class="classes">
                                <b-form-group size="sm" style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                              </b-col>
                            </b-row>
                          </b-input-group>
                        </validation-provider>
                      </template>
                      <template #cell(NormalSeparateClass)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 110px;">
                              <b-form-select
                                v-model="data.item.NormalSeparateClass"
                                :options="normalSeparateClassList"
                                size="sm"
                                value-field="id"
                                text-field="name"
                                @change="changeNormalSeparateClass(data.item)"
                              />
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(BillingSeparateClass)="data">
                        <b-input-group style="width: 110px;">
                          <b-form-select
                            v-model="data.item.BillingSeparateClass"
                            :options="billingSeparateClassList"
                            size="sm"
                            :disabled="data.item.NormalSeparateClass!=htmlConst.NormalSeparateClass.separate||data.item.BillingOutputClass!=htmlConst.BillingOutputClass.clientSite"
                            @change="if (data.item.BillingSeparateClass!=htmlConst.SeparateBillingClass.billings) { data.item.SeparateInvoiceNo = '0'; }"
                          />
                        </b-input-group>
                      </template>
                      <template #cell(SiteId)="data">
                        <validation-provider rules="required|numeric" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-input-group style="width: 110px;">
                              <b-form-input
                                type="text"
                                size="sm"
                                maxlength="4"
                                v-bind:value="data.item.SiteId"
                                :readonly="data.item.BillingOutputClass!=htmlConst.BillingOutputClass.clientSite||data.item.ClientId==''"
                                v-on:input="data.item.SiteId=$event; searchSite(data.item.SiteId, data.index);"
                              />
                              <b-input-group-text @click="showSiteSearchModal(data.index)" v-if="data.item.BillingOutputClass==htmlConst.BillingOutputClass.clientSite&&data.item.ClientId!=''" style="background: none; border: none; padding: 0px;">
                                <b-button size="sm" variant="light">
                                  <span class="oi oi-magnifying-glass"></span>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(SiteName)="data">
                        <div style="max-width:250px;">
                          <div style="width:100%; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                            <span v-b-tooltip.hover.noninteractive.right="data.item.SiteName">
                              {{ data.item.SiteName }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <template #cell(SeparateInvoiceNo)="data">
                        <validation-provider rules="required|numeric|between:0,999999999" v-slot="{ classes,errors }">
                          <b-input-group style="width: 120px;">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-form-input size="sm" class="px-1" type="number" :readonly="data.item.NormalSeparateClass!=htmlConst.NormalSeparateClass.separate||data.item.BillingSeparateClass!=htmlConst.SeparateBillingClass.billings" v-bind:value="data.item.SeparateInvoiceNo" v-on:blur="data.item.SeparateInvoiceNo=$event.target.value" />
                              </b-col>
                              <b-col lg="12" :class="classes">
                                <b-form-group size="sm" style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                              </b-col>
                            </b-row>
                          </b-input-group>
                        </validation-provider>
                      </template>
                      <template #head(Upload)>
                        <label
                          class="form-label"
                          :title="getFileButtonTitle"
                        >
                        アップロード <span class="oi oi-flag"/>
                        </label>
                      </template>
                      <template #cell(Upload)="data">
                        <validation-provider rules="required" v-slot="{ classes,errors }">
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group style="width: 300px;">
                                <b-form-file
                                  style="min-width: 270px;"
                                  v-model="data.item.FileDocument"
                                  placeholder=""
                                  drop-placeholder="ここにファイルをドロップ可能です。"
                                  size="sm"
                                  class="px-1"
                                >
                                </b-form-file>
                                <b-button size="sm" @click="setRowFromFileName(data.item, data.index)" :disabled="data.item.FileDocument==null">
                                  <span class="oi oi-arrow-left"></span>
                                </b-button>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                            </b-col>
                          </b-row>
                        </validation-provider>
                      </template>
                      <template #cell(Page)="data">
                        <validation-provider :rules="{required:true,regex:/^[1-9]$|(^[1-9]([0-9]|,|-)*[0-9]$)/}" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-form-input
                              style="width: 80px;"
                              :maxlength="maxlengthPage"
                              size="sm"
                              class="px-1"
                              type="text"
                              v-bind:value="data.item.Page"
                              v-on:blur="data.item.Page=$event.target.value"
                            />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(Delete)="data">
                        <b-button size="sm" @click="deleteBtn(data.index)">
                          <span class="oi oi-delete"></span> 削除
                        </b-button>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-btn-toolbar>
                      <b-button pill variant="success" size="sm" @click="addTableRow()" class="mr-2">
                        <span class="oi oi-plus"></span> 行追加
                      </b-button>
                      <b-button pill variant="success" size="sm" @click="addTableRow(5)" class="mr-2">
                        <span class="oi oi-plus"></span> 5行追加
                      </b-button>
                      <b-button pill variant="success" size="sm" @click="bulkDeleteBtn()" :disabled="checkedIndexs.length == 0">
                        <span class="oi oi-delete"></span> 一括削除
                      </b-button>
                    </b-btn-toolbar>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button pill block variant="success" type="submit" form="InputForm" :disabled="documentList.length == 0">
                  <span class="oi oi-circle-check"></span> 保存
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="modalClientClass"/>
  <!-- ●●●現場検索モーダル●●● -->
  <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
  <b-modal id='datepickerModal' :val="postItem" size="sm" hide-footer hide-header centered no-close-on-esc>
    <div v-if="postItem">
      <b-calendar
        name="tblSettlementScheduled"
        v-model="postItem.DateTransactionDate"
        @input="$bvModal.hide('datepickerModal')"
        hide-header
      >
        <div class="d-flex" dir="ltr">
          <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="postItem.DateTransactionDate=getToday();"
          >
            {{htmlConst.Calendar.todayButtonName}}
          </b-button>
        </div>
      </b-calendar>
    </div>
  </b-modal>
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { searchClientModal, searchSiteModal } from '@/assets/js/searchModal.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatCurDate, getNullStr, getListValue, addOperationLogs, executeSelectSql, createOfficeList, createElectronicDocumentsClassList, isSystemEditable, getFormCounterMultiple, getFormCounterMultipleReturnNo, escapeQuote, CreateColRow, CreateInsertSql, getBillingDuplicateClass, updateElectronicDocumentsSeikyuDuplicateClass, uploadFileToS3, uploadFileToS3PageSplit, uploadFileRemoveFromS3Multiple, getClosingDate, executeTransactSqlList } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { parse } from 'csv-parse';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs } from '@/graphql/queries';

const MODULE_NAME = 'save-documents-input';

export default {
  name: 'SAVE-DOCUMENTS-INPUT',
  // コンポーネント
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    SITESEARCH,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '保存電子書類入力',
      // ログイン情報
      loginId: this.$store.getters.user.username,
      loginOfficeId: null,
      loginStaffId: null,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 電子書類種類
      checkDocumentsKind: Const.DocumentsKind.electronicDocuments,
      documentsKindList: Const.DocumentsKindList,
      // アップロード方法
      checkDocumentsUploadWay: Const.DocumentsUploadWay.everyRow,
      documentsUploadWayList: Const.DocumentsUploadWayList,
      // 営業所コード
      salesOffice: null,
      // 通常分割区分
      normalSeparateClassList: Const.NormalSeparateClassList,
      // 請求書分割区分
      billingSeparateClassList: Const.SeparateBillingClassList,
      // インポートファイル
      fileDataCsv: null,
      fileDataPdf: null,
      // 電子書類区分プルダウン
      electronicDocumentsClassList: [],
      // 取引先区分プルダウン
      clientClassList: Const.ClientClassList,
      // トランザクションSQLリスト
      transactSqlList: [],
      // テーブル定義
      fields: [],
      documentList: [],
      dataIndex: 0,
      // 各行のチェック状態
      checkedIndexs: [],
      isAllChecked: false,
      // カレンダーモーダルに渡す値
      postItem: '',
      // 取引先検索モーダルに渡す値
      modalClientClass: null,
      // 現場検索モーダルに渡す値
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        Calendar: {
          // 今日を選択するボタン名
          todayButtonName: Const.Calendar.todayButtonName,
          // 日付クリアボタンのボタン名
          clearButtonName: Const.Calendar.clearButtonName,
        },
        // 書類アップロード方法
        DocumentsUploadWay: {
          // 行毎アップロード
          everyRow: Const.DocumentsUploadWay.everyRow,
          // ページ分割アップロード
          pageSplit: Const.DocumentsUploadWay.pageSplit,
        },
        // 電子書類種類
        DocumentsKind: {
          // 電子書類
          electronicDocuments: Const.DocumentsKind.electronicDocuments,
          // 請求書
          seikyu: Const.DocumentsKind.seikyu,
        },
        // 請求書出力単位区分
        BillingOutputClass: {
          // 取引先別
          client: Const.BillingOutputClass.client,
          // 現場別
          clientSite: Const.BillingOutputClass.clientSite,
        },
        // 通常分割区分
        NormalSeparateClass: {
          // 通常
          normal: Const.NormalSeparateClass.normal,
          // 分割
          separate: Const.NormalSeparateClass.separate,
        },
        // 請求書分割区分
        SeparateBillingClass: {
          // 伝票毎
          billings: Const.SeparateBillingClass.billings,
          // 現場毎
          sites: Const.SeparateBillingClass.sites,
        },
      },
      // csvインデックス
      csvColIndex: {
        // 営業所コード
        officeId: -1,
        // 電子書類区分
        documentsClass: -1,
        // 取引先区分
        clientClass: -1,
        // 取引先コード
        clientId: -1,
        // 取引日付
        transactionDate: -1,
        // 取引金額
        money: -1,
        // 内容
        contents: -1,
        // ページ数
        page: -1,
        // ここより下は請求書用
        // 請求年月
        billingMonthYear: -1,
        // 通常分割区分
        normalSeparateClass: -1,
        // 現場コード
        siteId: -1,
        // 請求書分割区分
        billingSeparateClass: -1,
        // 分割請求書番号
        separateInvoiceNo: -1,
      },
      // csvカラム名
      csvColName: {
        // 営業所コード
        officeId: '営業所コード',
        // 電子書類区分
        documentsClass: '電子書類区分',
        // 取引先区分
        clientClass: '取引先区分',
        // 取引先コード
        clientId: '取引先コード',
        // 取引日付
        transactionDate: '取引日付',
        // 取引金額
        money: '取引金額',
        // 内容
        contents: '内容',
        // ページ数
        page: 'ページ',
        // ここより下は請求書用
        // 請求年月
        billingMonthYear: '請求年月',
        // 通常分割区分
        normalSeparateClass: '通常分割区分',
        // 現場コード
        siteId: '現場コード',
        // 請求書分割区分
        billingSeparateClass: '請求書分割区分',
        // 分割請求書番号
        separateInvoiceNo: '分割請求書番号',
      },
      // 上限値
      lineMax: 100,
      maxlengthTextContents: 50,
      maxlengthPage: 20,
      // csv形式エラー
      isReadCsvErr: false,
      // パラメータ
      propDocumentsKind: null,
      propDocumentsUploadWay: null,
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    // ファイルにイベント追加
    document.getElementById('fileDataCsv').addEventListener('click', () => {
      this.fileDataCsv = null;
    });
    document.getElementById('fileDataPdf').addEventListener('click', () => {
      this.fileDataPdf = null;
    });
    // パラメータ取得
    this.propDocumentsKind = this.$route.query.propDocumentsKind;
    this.propDocumentsUploadWay = this.$route.query.propDocumentsUploadWay;
    if (getNullStr(this.propDocumentsKind) != '') {
      this.checkDocumentsKind = this.propDocumentsKind;
    }
    if (getNullStr(this.propDocumentsUploadWay) != '') {
      this.checkDocumentsUploadWay = this.propDocumentsUploadWay;
    }
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // ファイルボタンタイトル取得
    getFileButtonTitle: function() {
      let retTitle = 'ファイル読み込み後に「←」ボタンを押下することでファイル名からデータを読み込みます。（ファイル名の形式';
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        retTitle += '「電子書類区分_営業所コード_取引先区分_取引先コード_取引日付_取引金額.拡張子」）';
      } else {
        // 電子書類種類：請求書
        retTitle += '「請求年月_通常分割区分_営業所コード_取引先コード_現場コード_請求書分割区分_分割請求書番号_請求金額.拡張子」）';
      }
      return retTitle;
    },
  },
  methods: {
    // カレンダーボタン押下時
    async openModal(item) {
      this.postItem = item;
      this.$bvModal.show('datepickerModal');
    },
    // 当日取得
    getToday: function() {
      return formatCurDate('YYYY-MM-DD');
    },
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let electronicDocumentsClassListResult = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult, electronicDocumentsClassListResult] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs, condition)),
          createOfficeList(false),
          createElectronicDocumentsClassList(false),
        ]);
        // 電子書類種類プルダウン作成
        this.electronicDocumentsClassList = electronicDocumentsClassListResult;
        // 営業所プルダウン作成
        this.salesOffice = officeListResult;
        // ログイン営業所コードを取得
        let staffListData = staffListResult.data.list_m_staffs;
        this.loginOfficeId = staffListData[0].office_id;
        this.loginStaffId = staffListData[0].staff_id;
        // 行初期化
        this.setFields();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // CSVデータ行追加ボタン押下時
    async clickAddCsvRow() {
      const functionName = 'clickAddCsvRow';
      try {
        await this.addCsvRow();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // ファイルインポート
    async addCsvRow() {
      this.$store.commit('setLoading', true);
      // 表示中の情報がある場合、初期化
      this.fileDataInit();
      let reader = new FileReader();
      reader.onload = ('load', () => {
        this.readCsvFile(reader.result);
      });
      reader.onerror = ('error', (error) => {
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2062']);
        this.$store.commit('setLoading', false);
      });
      await reader.readAsText(this.fileDataCsv);
    },
    // ファイル情報初期化
    fileDataInit: function() {
      this.isReadCsvErr = false;
      // csvインデックス
      for (let key in this.csvColIndex) {
        this.csvColIndex[key] = -1;
      }
    },
    // CSVファイルの内容を読み込む
    async readCsvFile(fileText) {
      //console.log('readCsvFile');
      //console.log(fileText);
      const functionName = 'readCsvFile';
      try {
        // テキストをCSV形式で読み込み配列を作成
        let csvRecords = this.createCsvArray(fileText);
        //console.log(csvRecords);
        if (this.isReadCsvErr == true) {
          // データ無し
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（CSV形式不正）'));
          this.$store.commit('setLoading', false);
          return;
        }
        // 行数チェック
        if (csvRecords.length < 2) {
          // データ無し
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（データ無し）'));
          this.$store.commit('setLoading', false);
          return;
        } else if (csvRecords.length > this.lineMax + 1) {
          // 上限行を超過
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.lineMax + '行を超過）'));
          this.$store.commit('setLoading', false);
          return;
        }
        // ヘッダーチェック
        if (this.checkHeaderRow(csvRecords[0]) == false) {
          this.$store.commit('setLoading', false);
          return;
        }
        // ヘッダーを除き、不要な列は除外された一覧を作成
        if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          await this.setCsvRecordsElectronic(csvRecords);
        } else {
          // 電子書類種類：請求書
          await this.setCsvRecordsSeikyu(csvRecords);
          //console.log(this.documentList);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // CSV読み込みデータを行設定（電子書類）
    async setCsvRecordsElectronic(csvRecords) {
      let strWork = '';
      let rowIndex = '';
      let startIndex = this.documentList.length;
      let clientClassIdList = [];
      // ・ヘッダー除外のため1始まり
      for (let i = 1; i < csvRecords.length; i++) {
        let clientClass = null;
        let clientId = null;
        this.addTableRow();
        // 電子書類区分
        if (this.csvColIndex.documentsClass >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.documentsClass]).trim();
          rowIndex = this.electronicDocumentsClassList.findIndex(el => getNullStr(el.id) == strWork);
          if (rowIndex != -1) {
            this.documentList[this.documentList.length-1].SelectDocumentsClass = Number(strWork);
          } else {
            // 存在しない電子書類区分の場合は空白とする
            this.documentList[this.documentList.length-1].SelectDocumentsClass = null;
          }
        } else {
          // 存在しない電子書類区分の場合は空白とする
          this.documentList[this.documentList.length-1].SelectDocumentsClass = null;
        }
        // 営業所コード
        if (this.csvColIndex.officeId >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.officeId]).trim();
          rowIndex = this.salesOffice.findIndex(el => getNullStr(el.id) == strWork);
          if (rowIndex != -1) {
            this.documentList[this.documentList.length-1].SelectOfficeId = Number(strWork);
          } else {
            // 存在しない営業所コードの場合は空白とする
            this.documentList[this.documentList.length-1].SelectOfficeId = null;
          }
        } else {
          // 存在しない営業所コードの場合は空白とする
          this.documentList[this.documentList.length-1].SelectOfficeId = null;
        }
        // 取引先区分
        if (this.csvColIndex.clientClass >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.clientClass]).trim();
          if (strWork != getNullStr(Const.ClientClass.customer) && strWork != getNullStr(Const.ClientClass.supplier)) {
            // 取引先区分が「1：得意先」でも「2：仕入先」でもない場合は空白とする
            strWork = '';
          }
          this.documentList[this.documentList.length-1].ClientClass = strWork;
          clientClass = strWork;
        } else {
          strWork = '';
          this.documentList[this.documentList.length-1].ClientClass = strWork;
          clientClass = strWork;
        }
        // 取引先コード
        if (this.csvColIndex.clientId >= 0) {
          if (strWork != '') {
            // 取引先区分が設定された場合
            strWork = getNullStr(csvRecords[i][this.csvColIndex.clientId]).trim();
            if (isNaN(strWork) == false) {
              // 数値の場合のみ設定
              this.documentList[this.documentList.length-1].ClientId = strWork;
              clientId = strWork;
            }
          }
        }
        // 取引日付
        if (this.csvColIndex.transactionDate >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.transactionDate]).trim();
          let date = new Date(strWork);
          // 日付以外の場合は設定しない
          if (isNaN(date) == false) {
            this.documentList[this.documentList.length-1].DateTransactionDate = formatDate(strWork, 'YYYY-MM-DD');
          } else {
            this.documentList[this.documentList.length-1].DateTransactionDate = null;
          }
        } else {
          this.documentList[this.documentList.length-1].DateTransactionDate = null;
        }
        // 取引金額
        if (this.csvColIndex.money >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.money]).trim();
          if (isNaN(strWork) == false) {
            this.documentList[this.documentList.length-1].TextMoney = Number(strWork);
          }
        }
        // 内容
        if (this.csvColIndex.contents >= 0) {
          this.documentList[this.documentList.length-1].TextContents = getNullStr(csvRecords[i][this.csvColIndex.contents]).trim();
          if (this.documentList[this.documentList.length-1].TextContents.length > this.maxlengthTextContents) {
            // 最大桁数を超過している場合は空白を設定
            this.documentList[this.documentList.length-1].TextContents = '';
          }
        }
        // ページ
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          if (this.csvColIndex.page >= 0) {
            // ページ分割アップロードの場合、「ページ」も読み込む
            this.documentList[this.documentList.length-1].Page = getNullStr(csvRecords[i][this.csvColIndex.page]).trim();
            if (this.documentList[this.documentList.length-1].Page.length > this.maxlengthPage) {
              // 最大桁数を超過している場合は空白を設定
              this.documentList[this.documentList.length-1].Page = '';
            }
          }
        }
        clientClassIdList.push({clientClass: clientClass, clientId: clientId});
      }
      // SELECT文作成のために取引先区分、取引先コードをまとめる
      let clientClassList = [];
      for (let i = 0; i < clientClassIdList.length; i++) {
        let clientClassRow = undefined;
        if (getNullStr(clientClassIdList[i].clientClass) != '') {
          clientClassRow = clientClassList.find(el => el.clientClass == clientClassIdList[i].clientClass);
          if (clientClassRow != undefined) {
            if (clientClassIdList[i].clientId != null) {
              let clientIdIndex = clientClassRow.clientIdList.findIndex(el => el == clientClassIdList[i].clientId);
              if (clientIdIndex == -1) {
                clientClassRow.clientIdList.push(clientClassIdList[i].clientId);
              }
            }
          } else {
            let addClientIdRow = {};
            addClientIdRow.clientClass = clientClassIdList[i].clientClass;
            if (clientClassIdList[i].clientId != null) {
              addClientIdRow.clientIdList = [clientClassIdList[i].clientId];
              clientClassList.push(addClientIdRow);
            }
          }
        }
      }
      // 取得した取引先情報をSELECT文で取得
      let promiseArray = [];
      let selectSql = '';
      for (let i = 0; i < clientClassList.length; i++) {
        let csvClientId = '';
        for (let j = 0; j < clientClassList[i].clientIdList.length; j++) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += clientClassList[i].clientIdList[j];
        }
        selectSql = this.makeSelectSqlGetClientsData(clientClassList[i].clientClass, csvClientId, '');
        promiseArray.push(executeSelectSql(selectSql));
      }
      let clientDataResultList = await Promise.all(promiseArray);
      for (let i = startIndex; i < this.documentList.length; i++) {
        if (clientDataResultList != null && clientDataResultList.length > 0) {
          for (let j = 0; j < clientDataResultList.length; j++) {
            if (clientDataResultList[j] != null && clientDataResultList[j].length > 0) {
              let clientDataRow = clientDataResultList[j].find(el => getNullStr(el.client_class) == this.documentList[i].ClientClass && el.client_id == this.documentList[i].ClientId);
              if (clientDataRow != undefined) {
                this.documentList[i].ClientName = clientDataRow.client_name_kanji;
                this.documentList[i].ClientNameKana = clientDataRow.client_name_kana;
                this.documentList[i].BillingOutputClass = clientDataRow.billing_output_class;
                this.documentList[i].BillingOutputClassText = getListValue(Const.BillingOutputClassList, clientDataRow.billing_output_class, 'value', 'text');
                this.documentList[i].IsClientMaster = true;
                break;
              }
            }
            if (j == clientDataResultList.length - 1) {
              this.clientClear(i);
            }
          }
        }
      }
    },
    // CSV読み込みデータを行設定（請求書）
    async setCsvRecordsSeikyu(csvRecords) {
      let strWork = '';
      let rowIndex = '';
      let startIndex = this.documentList.length;
      let clientSiteIdList = [];
      // ・ヘッダー除外のため1始まり
      for (let i = 1; i < csvRecords.length; i++) {
        let clientId = null;
        let siteId = null;
        this.addTableRow();
        // 請求年月
        this.documentList[this.documentList.length-1].BillingMonthYear = null;
        if (this.csvColIndex.billingMonthYear >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.billingMonthYear]).trim();
          if (strWork.length == 6 && isNaN(strWork) == false) {
            strWork = strWork.substring(0, 4) + '-' + strWork.substring(4, 6);
            let date = new Date(strWork + '-01');
            // 日付以外の場合は設定しない
            if (isNaN(date) == false) {
              this.documentList[this.documentList.length-1].BillingMonthYear = strWork;
            }
          }
        }
        // 通常分割区分
        if (this.csvColIndex.normalSeparateClass >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.normalSeparateClass]).trim();
          rowIndex = Const.NormalSeparateClassList.findIndex(el => getNullStr(el.id) == strWork);
          if (rowIndex != -1) {
            this.documentList[this.documentList.length-1].NormalSeparateClass = Number(strWork);
          } else {
            // 存在しない通常分割区分の場合は空白とする
            this.documentList[this.documentList.length-1].NormalSeparateClass = null;
          }
        } else {
          this.documentList[this.documentList.length-1].NormalSeparateClass = null;
        }
        // 営業所コード
        if (this.csvColIndex.officeId >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.officeId]).trim();
          rowIndex = this.salesOffice.findIndex(el => getNullStr(el.id) == strWork);
          if (rowIndex != -1) {
            this.documentList[this.documentList.length-1].SelectOfficeId = Number(strWork);
          } else {
            // 存在しない営業所コードの場合は空白とする
            this.documentList[this.documentList.length-1].SelectOfficeId = null;
          }
        } else {
          this.documentList[this.documentList.length-1].SelectOfficeId = null;
        }
        // 取引先コード
        if (this.csvColIndex.clientId >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.clientId]).trim();
          if (isNaN(strWork) == false) {
            // 数値の場合のみ設定
            this.documentList[this.documentList.length-1].ClientId = strWork;
            clientId = strWork;
            // 現場コード
            if (this.csvColIndex.siteId >= 0) {
              strWork = getNullStr(csvRecords[i][this.csvColIndex.siteId]).trim();
              if (isNaN(strWork) == false) {
                // 取引先コードが数値、且つ、現場コードも数値の場合のみ設定
                this.documentList[this.documentList.length-1].SiteId = strWork;
                siteId = strWork;
              }
            }
          }
        }
        // 締切日付
        if (this.csvColIndex.transactionDate >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.transactionDate]).trim();
          let date = new Date(strWork);
          // 日付以外の場合は設定しない
          if (isNaN(date) == false) {
            this.documentList[this.documentList.length-1].DateTransactionDate = formatDate(strWork, 'YYYY-MM-DD');
          } else {
            this.documentList[this.documentList.length-1].DateTransactionDate = null;
          }
        } else {
          this.documentList[this.documentList.length-1].DateTransactionDate = null;
        }
        // 請求書分割区分
        if (this.csvColIndex.billingSeparateClass >= 0) {
          if (this.documentList[this.documentList.length-1].NormalSeparateClass == Const.NormalSeparateClass.separate) {
            // 通常分割区分が「1：分割」の場合のみ値を設定
            strWork = getNullStr(csvRecords[i][this.csvColIndex.billingSeparateClass]).trim();
            rowIndex = Const.SeparateBillingClassList.findIndex(el => getNullStr(el.value) == strWork);
            if (rowIndex != -1) {
              this.documentList[this.documentList.length-1].BillingSeparateClass = Number(strWork);
            }
          }
        }
        // 分割請求書番号
        if (this.csvColIndex.separateInvoiceNo >= 0) {
          if (this.documentList[this.documentList.length-1].NormalSeparateClass == Const.NormalSeparateClass.separate && this.documentList[this.documentList.length-1].BillingSeparateClass == Const.SeparateBillingClass.billings) {
            strWork = getNullStr(csvRecords[i][this.csvColIndex.separateInvoiceNo]).trim();
            // 通常分割区分が「1：分割」、請求書分割区分が「0：伝票毎」の場合のみ値を設定
            if (isNaN(strWork) == false) {
              // 数値の場合のみ設定
              this.documentList[this.documentList.length-1].SeparateInvoiceNo = strWork;
            }
          }
        }
        // 請求金額
        if (this.csvColIndex.money >= 0) {
          strWork = getNullStr(csvRecords[i][this.csvColIndex.money]).trim();
          if (isNaN(strWork) == false) {
            this.documentList[this.documentList.length-1].TextMoney = Number(strWork);
          }
        }
        // ページ
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          if (this.csvColIndex.page >= 0) {
            // ページ分割アップロードの場合、「ページ」も読み込む
            this.documentList[this.documentList.length-1].Page = getNullStr(csvRecords[i][this.csvColIndex.page]).trim();
            if (this.documentList[this.documentList.length-1].Page.length > this.maxlengthPage) {
              // 最大桁数を超過している場合は空白を設定
              this.documentList[this.documentList.length-1].Page = '';
            }
          }
        }
        clientSiteIdList.push({clientId: clientId, siteId: siteId});
      }
      // SELECT文作成のために取引先コード、現場コードをまとめる
      let clientIdList = [];
      for (let i = 0; i < clientSiteIdList.length; i++) {
        let clientIdRow = undefined;
        if (clientSiteIdList[i].clientId != null) {
          clientIdRow = clientIdList.find(el => el.clientId == clientSiteIdList[i].clientId);
          if (clientIdRow != undefined) {
            if (clientSiteIdList[i].siteId != null) {
              let siteIdIndex = clientIdRow.siteIdList.findIndex(el => el == clientSiteIdList[i].siteId);
              if (siteIdIndex == -1) {
                clientIdRow.siteIdList.push(clientSiteIdList[i].siteId);
              }
            }
          } else {
            let addClientIdRow = {};
            addClientIdRow.clientId = clientSiteIdList[i].clientId;
            if (clientSiteIdList[i].siteId != null) {
              addClientIdRow.siteIdList = [clientSiteIdList[i].siteId];
            } else {
              addClientIdRow.siteIdList = [];
            }
            clientIdList.push(addClientIdRow);
          }
        }
      }
      // 取得した取引先・現場情報をSELECT文で取得
      let promiseArray = [];
      let selectSql = '';
      for (let i = 0; i < clientIdList.length; i++) {
        let csvSiteId = '';
        for (let j = 0; j < clientIdList[i].siteIdList.length; j++) {
          if (csvSiteId != '') {
            csvSiteId += ',';
          }
          csvSiteId += clientIdList[i].siteIdList[j];
        }
        selectSql = this.makeSelectSqlGetClientsData(Const.ClientClass.customer, clientIdList[i].clientId, csvSiteId);
        promiseArray.push(executeSelectSql(selectSql));
      }
      let clientDataResultList = await Promise.all(promiseArray);
      for (let i = startIndex; i < this.documentList.length; i++) {
        if (clientDataResultList != null && clientDataResultList.length > 0) {
          for (let j = 0; j < clientDataResultList.length; j++) {
            if (clientDataResultList[j] != null && clientDataResultList[j].length > 0) {
              let clientDataRow = clientDataResultList[j].find(el => el.client_id == this.documentList[i].ClientId);
              if (clientDataRow != undefined) {
                this.documentList[i].ClientName = clientDataRow.client_name_kanji;
                this.documentList[i].ClientNameKana = clientDataRow.client_name_kana;
                this.documentList[i].BillingOutputClass = clientDataRow.billing_output_class;
                this.documentList[i].BillingOutputClassText = getListValue(Const.BillingOutputClassList, clientDataRow.billing_output_class, 'value', 'text');
                this.documentList[i].IsClientMaster = true;
                this.documentList[i].ClosingDate = clientDataRow.closing_date;
                if (this.documentList[i].DateTransactionDate == null) {
                  // CSVで締切日付が設定されない場合、請求年月と締日から自動設定する
                  this.setClosingYmd(this.documentList[i]);
                }
                if (clientDataRow.billing_output_class == Const.BillingOutputClass.clientSite) {
                  // 現場別の場合
                  let siteDataRow = clientDataResultList[j].find(el => el.client_id == this.documentList[i].ClientId && el.site_id == this.documentList[i].SiteId);
                  if (siteDataRow != undefined) {
                    this.documentList[i].SiteName = siteDataRow.client_site_name_kanji;
                  } else {
                    this.siteClear(i);
                  }
                } else {
                  // 取引先別の場合
                  this.siteClear(i);
                  this.documentList[i].SiteId = '0';
                }
                break;
              }
            }
            if (j == clientDataResultList.length - 1) {
              this.clientClear(i);
            }
          }
        }
      }
    },
    // 取引先情報設定
    async setClientData() {
      let clientIdList = [];
      for (let i = 0; i < this.documentList.length; i++) {
        let clientId = this.documentList[i].ClientId;
        let clientIdIndex = clientIdList.findIndex(el => el == clientId);
        if (clientIdIndex == -1) {
          clientIdList.push(clientId);
        }
      }
    },
    // CSVファイルの配列を作成
    createCsvArray: function(fileText) {
      //console.log('createCsvArray');
      const records = [];
      let parser = parse();
      parser.on('readable', function() {
        let record = parser.read();
        while (record != null) {
          records.push(record);
          record = parser.read();
        }
      });
      parser.on('error', (e) => {
        console.log(e.message);
        this.isReadCsvErr = true;
      });
      parser.write(fileText);
      parser.end();

      return records;
    },
    // ヘッダーチェック
    checkHeaderRow: function(row) {
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        this.csvColName.transactionDate = '取引日付';
        this.csvColName.money = '取引金額';
        return this.checkHeaderRowElectronic(row);
      } else {
        // 電子書類種類：請求書
        this.csvColName.transactionDate = '締切日付';
        this.csvColName.money = '請求金額';
        return this.checkHeaderRowSeikyu(row);
      }
    },
    // ヘッダーチェック（電子書類）
    checkHeaderRowElectronic: function(row) {
      for (let i = 0; i < row.length; i++) {
        switch(row[i].trim()) {
        case this.csvColName.documentsClass:
          if (this.csvColIndex.documentsClass == -1) {
            this.csvColIndex.documentsClass = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.documentsClass + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.officeId:
          if (this.csvColIndex.officeId == -1) {
            this.csvColIndex.officeId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.officeId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.clientId:
          if (this.csvColIndex.clientId == -1) {
            this.csvColIndex.clientId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.clientId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.clientClass:
          if (this.csvColIndex.clientClass == -1) {
            this.csvColIndex.clientClass = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.clientClass + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.transactionDate:
          if (this.csvColIndex.transactionDate == -1) {
            this.csvColIndex.transactionDate = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.transactionDate + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.money:
          if (this.csvColIndex.money == -1) {
            this.csvColIndex.money = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.money + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.contents:
          if (this.csvColIndex.contents == -1) {
            this.csvColIndex.contents = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.contents + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.page:
          if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
            // ページ分割アップロードの場合、「ページ」も読み込む
            if (this.csvColIndex.page == -1) {
              this.csvColIndex.page = i;
            } else {
              // 同じカラムが二つ以上のエラー
              this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.page + 'が2列以上）'));
              return false;
            }
          }
          break;
        default:
          break;
        }
      }
      if (this.csvColIndex.documentsClass != -1 ||
        this.csvColIndex.officeId != -1 ||
        this.csvColIndex.clientClass != -1 ||
        (this.csvColIndex.clientClass != -1 && this.csvColIndex.clientId != -1) ||
        this.csvColIndex.transactionDate != -1 ||
        this.csvColIndex.money != -1 ||
        this.csvColIndex.contents != -1 ||
        (this.csvColIndex.page != -1 && this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit)
      ) {
        // ヘッダー行に1つでもカラムがある場合はtrue
        return true;
      } else {
        let noColName = '';
        noColName += this.csvColName.documentsClass;
        noColName += '、';
        noColName += this.csvColName.officeId;
        noColName += '、';
        noColName += this.csvColName.clientClass;
        if (this.csvColIndex.clientId == -1) {
          noColName += '、';
          noColName += this.csvColName.clientId;
        }
        noColName += '、';
        noColName += this.csvColName.transactionDate;
        noColName += '、';
        noColName += this.csvColName.money;
        noColName += '、';
        noColName += this.csvColName.contents;
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          // ページ分割アップロードの場合、「ページ」も読み込む
          if (this.csvColIndex.page == -1) {
            noColName += '、';
            noColName += this.csvColName.page;
          }
        }
        // ヘッダー行にカラムがない場合はfalse
        this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + noColName + 'の列無し）'));
        return false;
      }
    },
    // ヘッダーチェック（請求書）
    checkHeaderRowSeikyu: function(row) {
      for (let i = 0; i < row.length; i++) {
        switch(row[i].trim()) {
        case this.csvColName.billingMonthYear:
          if (this.csvColIndex.billingMonthYear == -1) {
            this.csvColIndex.billingMonthYear = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.billingMonthYear + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.normalSeparateClass:
          if (this.csvColIndex.normalSeparateClass == -1) {
            this.csvColIndex.normalSeparateClass = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.normalSeparateClass + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.officeId:
          if (this.csvColIndex.officeId == -1) {
            this.csvColIndex.officeId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.officeId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.clientId:
          if (this.csvColIndex.clientId == -1) {
            this.csvColIndex.clientId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.clientId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.transactionDate:
          if (this.csvColIndex.transactionDate == -1) {
            this.csvColIndex.transactionDate = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.transactionDate + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.siteId:
          if (this.csvColIndex.siteId == -1) {
            this.csvColIndex.siteId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.siteId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.billingSeparateClass:
          if (this.csvColIndex.billingSeparateClass == -1) {
            this.csvColIndex.billingSeparateClass = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.billingSeparateClass + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.separateInvoiceNo:
          if (this.csvColIndex.separateInvoiceNo == -1) {
            this.csvColIndex.separateInvoiceNo = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.separateInvoiceNo + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.money:
          if (this.csvColIndex.money == -1) {
            this.csvColIndex.money = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.money + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.page:
          if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
            // ページ分割アップロードの場合、「ページ」も読み込む
            if (this.csvColIndex.page == -1) {
              this.csvColIndex.page = i;
            } else {
              // 同じカラムが二つ以上のエラー
              this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.page + 'が2列以上）'));
              return false;
            }
          }
          break;
        default:
          break;
        }
      }
      if (this.csvColIndex.billingMonthYear != -1 ||
        this.csvColIndex.normalSeparateClass != -1 ||
        this.csvColIndex.officeId != -1 ||
        this.csvColIndex.clientId != -1 ||
        this.csvColIndex.transactionDate != -1 ||
        this.csvColIndex.siteId != -1 ||
        this.csvColIndex.billingSeparateClass != -1 ||
        this.csvColIndex.separateInvoiceNo != -1 ||
        this.csvColIndex.money != -1 ||
        (this.csvColIndex.page != -1 && this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit)
      ) {
        // ヘッダー行に1つでもカラムがある場合はtrue
        return true;
      } else {
        let noColName = '';
        noColName += this.csvColName.billingMonthYear;
        noColName += '、';
        noColName += this.csvColName.normalSeparateClass;
        noColName += '、';
        noColName += this.csvColName.officeId;
        noColName += '、';
        noColName += this.csvColName.clientId;
        noColName += '、';
        noColName += this.csvColName.transactionDate;
        noColName += '、';
        noColName += this.csvColName.siteId;
        noColName += '、';
        noColName += this.csvColName.billingSeparateClass;
        noColName += '、';
        noColName += this.csvColName.separateInvoiceNo;
        noColName += '、';
        noColName += this.csvColName.money;
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          // ページ分割アップロードの場合、「ページ」も読み込む
          if (this.csvColIndex.page == -1) {
            noColName += '、';
            noColName += this.csvColName.page;
          }
        }
        // ヘッダー行にカラムがない場合はfalse
        this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + noColName + 'の列無し）'));
        return false;
      }
    },
    // チェックボックス個別選択（全チェックのチェックボックスを合わせる）
    changeCheck: function() {
      this.isAllChecked = this.documentList.length == this.checkedIndexs.length;
    },
    // 全チェック選択
    clickCheckAll: function() {
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 削除チェックを初期化
        this.checkedIndexs = [];
        // 削除チェックを全てON
        for (let i = 0; i < this.documentList.length; i++) {
          this.checkedIndexs.push(i);
        }
      } else {
        // チェックOFFにした場合
        // 削除チェックを全てOFF
        this.checkedIndexs = [];
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(clientClass, clientId, index) {
      const functionName = 'searchClient';
      let searchClientClass = '';
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        searchClientClass = clientClass;
      } else {
        // 電子書類種類：電子書類以外
        searchClientClass = Const.ClientClass.customer;
      }
      const result = await searchClientModal(clientId, searchClientClass, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (clientId.length == 6) {
          // 取引先クリア
          this.clientClear(index);
        }
      }
    },
    // 取引先検索モーダル表示
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.dataIndex = index;
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        this.modalClientClass = this.documentList[index].ClientClass;
      } else {
        // 電子書類種類：電子書類以外
        this.modalClientClass = Const.ClientClass.customer;
      }
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    clientClear: function(index) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      this.documentList[index].ClientId = '';
      this.documentList[index].ClientName = '';
      this.documentList[index].ClientNameKana = '';
      this.documentList[index].BillingOutputClass = null;
      this.documentList[index].BillingOutputClassText = '';
      this.documentList[index].IsClientMaster = false;
      if (this.checkDocumentsKind == Const.DocumentsKind.seikyu) {
        // 請求書の場合
        this.documentList[index].ClosingDate = 0;
      }
      this.siteClear(index);
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        this.documentList[this.dataIndex].ClientId = clientItems.detail.client_id;
        this.documentList[this.dataIndex].ClientName = clientItems.detail.client_name_kanji;
        this.documentList[this.dataIndex].ClientNameKana = clientItems.detail.client_name_kana;
        this.documentList[this.dataIndex].BillingOutputClass = clientItems.detail.billing_output_class;
        this.documentList[this.dataIndex].BillingOutputClassText = getListValue(Const.BillingOutputClassList, clientItems.detail.billing_output_class, 'value', 'text');
        this.documentList[this.dataIndex].IsClientMaster = true;
        if (this.checkDocumentsKind == Const.DocumentsKind.seikyu) {
          // 請求書の場合
          this.documentList[this.dataIndex].ClosingDate = clientItems.detail.closing_date;
          this.setClosingYmd(this.documentList[this.dataIndex]);
          if (this.documentList[this.dataIndex].BillingOutputClass == Const.BillingOutputClass.clientSite) {
            // 現場別
            // 初期化（入力が必要なため）
            this.siteClear(this.dataIndex);
          } else {
            // 取引先別
            // 固定値を入力（入力が不要なため）
            this.documentList[this.dataIndex].SiteId = '0';
            this.documentList[this.dataIndex].SiteName = '';
            // 請求書分割区分（伝票毎に初期化）
            this.documentList[this.dataIndex].BillingSeparateClass = Const.SeparateBillingClass.billings;
          }
        }
      }
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function(index) {
      //console.log('現場検索モーダル');
      this.dataIndex = index;
      this.siteSearchInfo.client_id = this.documentList[index].ClientId;
      this.siteSearchInfo.client_name = this.documentList[index].ClientName;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(siteId, index) {
      const functionName = 'searchSite';
      const clientId = this.documentList[index].ClientId;
      const result = await searchSiteModal(siteId, clientId, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        // 現場情報セット
        await this.closeSiteSearchModal(result);
      } else {
        // 現場クリア
        this.siteClear(index);
      }
    },
    // 現場検索モーダルを閉じた時
    async closeSiteSearchModal(siteItems) {
      //console.log(siteItems);
      // モーダルから渡された値の有無チェック
      if (typeof siteItems != 'undefined') {
        this.documentList[this.dataIndex].SiteId = siteItems.detail.site_id;
        this.documentList[this.dataIndex].SiteName = siteItems.detail.client_site_name_kanji;
      }
    },
    // 現場クリア
    siteClear: function(index) {
      this.documentList[index].SiteId = '';
      this.documentList[index].SiteName = '';
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // キャンセルボタン押下時
    async clickBack(){
      // 見積・発注一覧画面へ遷移
      this.$router.push({name: 'SAVE-DOCUMENTS-LIST'});
    },
    // 保存ボタンの押下
    async saveData() {
      const functionName = 'saveData';
      try {
        if (this.documentList.length <= this.lineMax) {
          // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
          const observer = this.$refs.observer;
          const success = await observer.validate();

          // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
          if (!success) {
            const el = document.querySelector('#error:first-of-type');
            el.scrollIntoView({block: 'center', inline: 'nearest'});
          } else {
            // 保存確認ダイアログを表示
            let confirmMessage = [];
            confirmMessage.push(this.$createElement('div','入力された電子書類を登録します。'));
            confirmMessage.push(this.$createElement('div','よろしいですか？'));
            let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '保存確認'});
            if (retConfirm == true) {
              this.$store.commit('setLoading', true);
              await this.checkUploadWayBeforeExecSave();
            }
          }
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2080'].replace('%arg1%', this.lineMax));
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 登録処理前にアップロード方法をチェック（ページ分割の場合、まずは、ページ分割ファイルを読み込む）
    async checkUploadWayBeforeExecSave() {
      if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
        // ページ分割アップロードの場合
        if (this.fileDataPdf == null) {
          // ページ分割PDF読込（.pdf）が空白の場合
          // ページ指定のエラーメッセージを表示
          this.alertWarning.push(DISP_MESSAGES.WARNING['2082'].replace('%arg1%', 'ページ分割PDF'));
          return;
        }
        // ファイルリーダーでファイルを読込、読込バッファを引数に入れて呼出し
        let reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          console.log('Unknown error occurred during reading the file');
        };
        reader.onload = () => {
          //console.log('onload');
          this.callExecSave(reader.result);
        };
        reader.readAsArrayBuffer(this.fileDataPdf);
      } else {
        // 行毎アップロードの場合
        // そのまま呼び出す
        await this.callExecSave();
      }
    },
    // 登録処理呼出し処理
    async callExecSave(pdfFileBuffer) {
      const functionName = 'callExecSave';
      try {
        this.$store.commit('setLoading', true);
        if (await this.execSave(pdfFileBuffer) == true) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
          // 成功した場合、メッセージを表示すると共に一覧を初期化する
          this.documentList = [];
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 登録処理
    async execSave(pdfFileBuffer) {
      const functionName = 'execSave';

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      
      this.transactSqlList = [];
      let filePath = '';
      let eDocumentsFirstId = null;
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        // 電子書類データのキー取得
        eDocumentsFirstId = await getFormCounterMultiple(Const.CounterClass.electronicDocumentsId, Const.DocumentsKind.electronicDocuments, 0, this.documentList.length, this.loginId, MODULE_NAME, functionName);
        if (eDocumentsFirstId == null) {
          // IDの取得に失敗した場合、エラーとする。（複数人が同時に保存しようとした場合、発生する可能性あり）
          this.alertWarning.push(DISP_MESSAGES.WARNING['2067'].replace('%arg1%', '電子書類データの登録'));
          return false;
        }
        // 電子書類データの登録
        await this.insertElectronicDocuments(eDocumentsFirstId);
      } else {
        // 電子書類種類：請求書
        // 電子書類データのキー取得
        eDocumentsFirstId = await getFormCounterMultiple(Const.CounterClass.electronicDocumentsId, Const.DocumentsKind.seikyu, 0, this.documentList.length, this.loginId, MODULE_NAME, functionName);
        if (eDocumentsFirstId == null) {
          // IDの取得に失敗した場合、エラーとする。（複数人が同時に保存しようとした場合、発生する可能性あり）
          this.alertWarning.push(DISP_MESSAGES.WARNING['2067'].replace('%arg1%', '電子書類データの登録'));
          return false;
        }
        // 請求書発行登録日設定
        await this.setSeikyuInputIssueDate();
        // 電子書類データ＿請求書データ登録
        await this.insertElectronicDocumentsBillingsIssue(eDocumentsFirstId);
        // 電子書類データ＿請求書データの請求重複区分の設定
        this.updateElectronicDocumentsBillingsIssue();
      }
      //console.log(transactSqlList);
      // 失敗した場合のファイル削除フラグとファイル削除情報の変数を用意
      let isFileRemove = false;
      let removeFileKey = [];
      try {
        // DB登録前にファイルのアップロード実行
        if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.electronicDocuments).key;
        } else {
          // 電子書類種類：請求書
          filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.seikyu).key;
        }
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          // ページ分割アップロードの場合
          let listFileName = [];
          let listPage = [];
          let listFilePath = [];
          for (let i = 0; i < this.documentList.length; i++) {
            listFileName.push(this.documentList[i].FileName);
            listPage.push(this.documentList[i].Page);
            listFilePath.push(filePath + '/' + this.documentList[i].Year);
          }
          let retUpload = await uploadFileToS3PageSplit(pdfFileBuffer, listFilePath, listFileName, listPage, MODULE_NAME);
          if (retUpload.result == true) {
            removeFileKey = retUpload.s3KeyList;
          } else {
            isFileRemove = true;
            if (retUpload.isPageErr == true) {
              // ページ数エラーの場合
              // ページ指定のエラーメッセージを表示
              this.alertWarning.push(DISP_MESSAGES.WARNING['2079']);
            }
          }
        } else {
          // 行毎アップロードの場合
          let promiseArray = [];
          for (let i = 0; i < this.documentList.length; i++) {
            let filePathYear = filePath + '/' + this.documentList[i].Year;
            // ファイルをアップロード
            promiseArray.push(uploadFileToS3(this.documentList[i].FileDocument, filePathYear, this.documentList[i].FileName));
          }
          let retUploadFileToS3List = await Promise.all(promiseArray);
          for (let i = 0; i < retUploadFileToS3List.length; i++) {
            if (retUploadFileToS3List[i] == true) {
              // アップロードに成功した場合
              // 途中で失敗した場合にアップロードファイルを削除するためにアップロードに成功したファイル名を格納
              let filePathYear = filePath + '/' + this.documentList[i].Year;
              removeFileKey.push(filePathYear + '/' + this.documentList[i].FileName);
            } else {
              // アップロードに失敗した場合
              // １つでもアップロードに失敗した場合は失敗フラグを立てる
              isFileRemove = true;
            }
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'ファイルのアップロードに失敗しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        isFileRemove = true;
      }
      if (isFileRemove == false) {
        // ファイルのアップロードが成功した場合
        // 作成した登録用SQLを全実行
        if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          isFileRemove = true;
        }
      }
      if (isFileRemove == true && removeFileKey.length > 0) {
        // 保存に失敗した場合、且つ、アップロードに成功したファイルが存在する場合
        // 成功したファイルを全て削除
        await uploadFileRemoveFromS3Multiple(removeFileKey, MODULE_NAME);
      }
      if (isFileRemove == false) {
        // ファイルの削除が必要ない場合は登録成功
        return true;
      } else {
        // ファイルの削除が必要な場合は登録失敗
        return false;
      }
    },
    // 請求書発行登録日設定
    async setSeikyuInputIssueDate() {
      // 入力された請求書が発行登録済みかどうかを確認
      let promiseArray = [];
      for (let i = 0; i < this.documentList.length; i++){
        let selectSql = '';
        if (this.documentList[i].NormalSeparateClass == Const.NormalSeparateClass.normal) {
          // 通常分割区分が「0：通常」の場合
          // 請求書発行登録テーブルを確認
          // SELECT句
          selectSql += 'SELECT';
          selectSql += ' t_bii.billing_issue_input_date';
          // FROM句
          selectSql += ' FROM ';
          selectSql += 't_billings_issue_input AS t_bii ';
          // WHERE句
          selectSql += ' WHERE ';
          selectSql += 't_bii.billing_month_year = ' + this.documentList[i].BillingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND t_bii.client_id = ' + this.documentList[i].ClientId + ' ';
        } else if (this.documentList[i].SeparateInvoiceNo != '0') {
          // 通常分割区分が「0：通常」以外、且つ、分割請求書番号が0以外の場合
          // 請求書発行登録＿伝票番号毎テーブルを確認
          // SELECT句
          selectSql += 'SELECT';
          selectSql += ' t_biibn.billing_issue_input_date';
          // FROM句
          selectSql += ' FROM ';
          selectSql += '(SELECT ';
          selectSql += ' billing_month_year';
          selectSql += ',client_id';
          selectSql += ',site_id';
          selectSql += ',separate_invoice_no';
          selectSql += ',billing_issue_input_date';
          selectSql += ' FROM ';
          selectSql += 't_billings_issue_input_billing_no ';
          selectSql += ' WHERE ';
          selectSql += 'billing_month_year = ' + this.documentList[i].BillingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND client_id = ' + this.documentList[i].ClientId + ' ';
          selectSql += 'AND separate_invoice_no = ' + this.documentList[i].SeparateInvoiceNo + ' ';
          selectSql += 'GROUP BY separate_invoice_no ';
          selectSql += ') AS t_biibn ';
        } else {
          // 通常分割区分が「0：通常」以外、且つ、分割請求書番号が0の場合
          // 請求書発行登録＿現場毎テーブルを確認
          // SELECT句
          selectSql += 'SELECT';
          selectSql += ' t_biis.billing_issue_input_date';
          // FROM句
          selectSql += ' FROM ';
          selectSql += 't_billings_issue_input_site AS  t_biis ';
          // WHERE句
          selectSql += ' WHERE ';
          selectSql += 't_biis.billing_month_year = ' + this.documentList[i].BillingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND t_biis.client_id = ' + this.documentList[i].ClientId + ' ';
          selectSql += 'AND t_biis.site_id = ' + this.documentList[i].SiteId + ' ';
        }
        promiseArray.push(executeSelectSql(selectSql));
      }
      let issueInputDataResultList = await Promise.all(promiseArray);
      for (let i = 0; i < issueInputDataResultList.length; i++) {
        if (issueInputDataResultList[i] != null && issueInputDataResultList[i].length > 0) {
          // 結果が取得できた場合
          // 発行登録日を設定
          this.documentList[i].BillingIssueInputDate = issueInputDataResultList[i][0].billing_issue_input_date;
        }
      }
    },
    // 電子書類データ登録処理
    async insertElectronicDocuments(eDocumentsFirstId) {
      //console.log('電子書類データ登録処理');
      for (let i = 0; i < this.documentList.length; i++){
        // 取引日付を「YYYYMMDD」形式にする
        let dateTransactionDate = formatDate(this.documentList[i].DateTransactionDate).replace(/\//g, '');
        // ファイル名は「取引日付_ID_取引先コード_取引金額.【拡張子】」の形式
        let fileName = dateTransactionDate;
        fileName += '_';
        fileName += getNullStr(getFormCounterMultipleReturnNo(eDocumentsFirstId, i));
        fileName += '_';
        fileName += this.documentList[i].ClientId;
        fileName += '_';
        fileName += this.documentList[i].TextMoney;
        fileName += '.';
        let extension = '';
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          // ページ分割アップロードの場合
          fileName += 'pdf';
          extension = 'pdf';
        } else {
          // 行毎アップロードの場合
          //ファイル名を取得する
          let uploadFileName = this.documentList[i].FileDocument.name;
          //ファイル名から拡張子を取得する
          let extension_1 = uploadFileName.split('.');
          extension = extension_1[extension_1.length - 1];
          fileName += extension;
        }
        this.documentList[i].FileName = fileName;
        // ファイルパスのために年度を保持
        this.documentList[i].Year = formatDate(dateTransactionDate, 'YYYY');
        let colList = [];
        // ID
        colList.push(CreateColRow('id', getFormCounterMultipleReturnNo(eDocumentsFirstId, i), 'NUMBER'));
        // 電子書類区分
        colList.push(CreateColRow('electronic_documents_class', this.documentList[i].SelectDocumentsClass, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.documentList[i].SelectOfficeId, 'NUMBER'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.documentList[i].ClientClass, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.documentList[i].ClientId, 'NUMBER'));
        // 取引先名（漢字）
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.documentList[i].ClientName), 'VARCHAR'));
        // 取引先名（カナ）
        colList.push(CreateColRow('client_name_kana', await escapeQuote(this.documentList[i].ClientNameKana), 'VARCHAR'));
        // 取引日
        colList.push(CreateColRow('transaction_date', this.documentList[i].DateTransactionDate, 'DATE'));
        // 取引金額
        colList.push(CreateColRow('money', this.documentList[i].TextMoney, 'NUMBER'));
        // 内容
        colList.push(CreateColRow('contents', await escapeQuote(this.documentList[i].TextContents), 'VARCHAR'));
        // ファイル拡張子
        colList.push(CreateColRow('file_extension', await escapeQuote(extension), 'VARCHAR'));
        // 登録日
        colList.push(CreateColRow('input_date', 'CURDATE()', 'DATE'));
        // 登録担当者コード
        colList.push(CreateColRow('input_staff_id', this.loginStaffId, 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        let insertSql = CreateInsertSql(colList, 'full', 't_electronic_documents');
        //console.log(insertSql)
        this.transactSqlList.push(insertSql);
      }
    },
    // 電子書類データ＿請求書データ登録処理
    async insertElectronicDocumentsBillingsIssue(eDocumentsFirstId) {
      //console.log('電子書類データ＿請求書データ登録処理');
      for (let i = 0; i < this.documentList.length; i++) {
        // ファイル名は「請求年月_通常分割区分_取引先コード_現場コード_請求書分割区分_分割請求書番号_請求金額_ID.【拡張子】」の形式
        let billingMonthYear = this.documentList[i].BillingMonthYear.replace(/-/g,'');
        let fileName = billingMonthYear;
        fileName += '_';
        fileName += this.documentList[i].NormalSeparateClass;
        fileName += '_';
        fileName += this.documentList[i].ClientId;
        fileName += '_';
        fileName += this.documentList[i].SiteId;
        fileName += '_';
        fileName += this.documentList[i].BillingSeparateClass;
        fileName += '_';
        fileName += this.documentList[i].SeparateInvoiceNo;
        fileName += '_';
        fileName += this.documentList[i].TextMoney;
        fileName += '_';
        fileName += getNullStr(getFormCounterMultipleReturnNo(eDocumentsFirstId, i));
        fileName += '.';
        let extension = '';
        if (this.checkDocumentsUploadWay == Const.DocumentsUploadWay.pageSplit) {
          // ページ分割アップロードの場合
          fileName += 'pdf';
          extension = 'pdf';
        } else {
          // 行毎アップロードの場合
          //ファイル名を取得する
          let uploadFileName = this.documentList[i].FileDocument.name;
          //ファイル名から拡張子を取得する
          let extension_1 = uploadFileName.split('.');
          extension = extension_1[extension_1.length - 1];
          fileName += extension;
        }
        this.documentList[i].FileName = fileName;
        // ファイルパスのために年度を保持
        this.documentList[i].Year = formatDate(getNullStr(billingMonthYear) + '01', 'YYYY');
        let colList = [];
        // ID
        colList.push(CreateColRow('id', getFormCounterMultipleReturnNo(eDocumentsFirstId, i), 'NUMBER'));
        // 請求年月
        colList.push(CreateColRow('billing_month_year', billingMonthYear, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.documentList[i].SelectOfficeId, 'NUMBER'));
        // 締切日付
        colList.push(CreateColRow('closing_ymd', this.documentList[i].DateTransactionDate, 'DATE'));
        // 通常分割区分
        colList.push(CreateColRow('normal_separate_class', this.documentList[i].NormalSeparateClass, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.documentList[i].ClientId, 'NUMBER'));
        // 取引先名（漢字）
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.documentList[i].ClientName), 'VARCHAR'));
        // 取引先名（カナ）
        colList.push(CreateColRow('client_name_kana', await escapeQuote(this.documentList[i].ClientNameKana), 'VARCHAR'));
        // 請求書出力単位区分
        colList.push(CreateColRow('billing_output_class', this.documentList[i].BillingOutputClass, 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', this.documentList[i].SiteId, 'NUMBER'));
        // 現場名（漢字）
        colList.push(CreateColRow('client_site_name_kanji', await escapeQuote(this.documentList[i].SiteName), 'VARCHAR'));
        // 請求書発行登録日
        colList.push(CreateColRow('billing_issue_input_date', this.documentList[i].BillingIssueInputDate, 'DATE'));
        // 請求書分割区分
        colList.push(CreateColRow('billing_separate_class', this.documentList[i].BillingSeparateClass, 'NUMBER'));
        // 分割請求書番号
        colList.push(CreateColRow('separate_invoice_no', this.documentList[i].SeparateInvoiceNo, 'NUMBER'));
        // 請求金額
        colList.push(CreateColRow('money_billing_total', this.documentList[i].TextMoney, 'NUMBER'));
        // ファイル拡張子
        colList.push(CreateColRow('file_extension', await escapeQuote(extension), 'VARCHAR'));
        // 登録日
        colList.push(CreateColRow('input_date', 'CURDATE()', 'DATE'));
        // 登録担当者コード
        colList.push(CreateColRow('input_staff_id', this.loginStaffId, 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        let insertSql = CreateInsertSql(colList, 'full', 't_electronic_documents_seikyu');
        //console.log(insertSql)
        this.transactSqlList.push(insertSql);
      }
    },
    // 電子書類データ＿請求書データの請求重複区分の設定
    updateElectronicDocumentsBillingsIssue: function() {
      // 請求重複区分毎に登録行をまとめる
      let billingDuplicateClassList = [];
      for (let i = 0; i < this.documentList.length; i++) {
        let billingMonthYear = this.documentList[i].BillingMonthYear.replace(/-/g,'');
        let billingDuplicateClass = getBillingDuplicateClass(this.documentList[i].BillingOutputClass, this.documentList[i].NormalSeparateClass, this.documentList[i].BillingSeparateClass);
        let row = billingDuplicateClassList.find(el => el.billingDuplicateClass == billingDuplicateClass);
        if (row == undefined) {
          // 存在しない請求重複区分の場合
          // 登録内容を作成
          let newRow = {
            billingDuplicateClass: billingDuplicateClass,
            billingMonthYearList: [billingMonthYear],
            clientIdList: [this.documentList[i].ClientId],
          };
          billingDuplicateClassList.push(newRow);
        } else {
          // 存在する請求重複区分の場合
          // 請求年月を確認して、なければ追加
          if (row.billingMonthYearList.findIndex(el => el == billingMonthYear) == -1) {
            row.billingMonthYearList.push(billingMonthYear);
          }
          // 取引先コードを確認して、なければ追加
          if (row.clientIdList.findIndex(el => el == this.documentList[i].ClientId) == -1) {
            row.clientIdList.push(this.documentList[i].ClientId);
          }
        }
      }
      // 請求重複区分毎にUPDATE文を作成し、transactSqlListに追加
      for (let i = 0; i < billingDuplicateClassList.length; i++) {
        let csvBillingMonthYear = billingDuplicateClassList[i].billingMonthYearList.join(',');
        let csvClientid = billingDuplicateClassList[i].clientIdList.join(',');
        let updateSql = updateElectronicDocumentsSeikyuDuplicateClass(csvBillingMonthYear, csvClientid, billingDuplicateClassList[i].billingDuplicateClass, this.loginId);
        this.transactSqlList.push(updateSql);
      }
    },
    // 行追加ボタンイベント
    addTableRow: function(rows = 1) {
      let newRow = this.createNewRow();
      for (let index = 0; index < rows; index++) {
        this.documentList.push({...newRow});
      }
    },
    // 行作成
    createNewRow: function() {
      let newRow = null;
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        newRow = {
          SelectDocumentsClass: null,
          SelectOfficeId: this.loginOfficeId,
          ClientClass: '',
          ClientId: '',
          ClientName: '',
          ClientNameKana: '',
          DateTransactionDate: formatCurDate('YYYY-MM-DD'),
          TextMoney: '',
          TextContents: '',
        };
      } else {
        // 電子書類種類：請求書
        newRow = {
          BillingMonthYear: formatCurDate('YYYY-MM'),
          DateTransactionDate: null,
          NormalSeparateClass: Const.NormalSeparateClass.normal,
          BillingSeparateClass: Const.SeparateBillingClass.billings,
          SelectOfficeId: this.loginOfficeId,
          ClientId: '',
          ClientName: '',
          ClientNameKana: '',
          ClosingDate: 0,
          IsClientMaster: false,
          BillingOutputClass: null,
          BillingOutputClassText: '',
          SiteId: '',
          SiteName: '',
          SeparateInvoiceNo: '0',
          BillingIssueInputDate: null,
          TextMoney: '',
        };
      }
      newRow.FileDocument = null;
      newRow.Page = '';
      return newRow;
    },
    // テーブル行削除イベント（一括）
    async bulkDeleteBtn() {
      let confirmMessage = 'チェックONの行を全て削除します。';
      confirmMessage += 'よろしいですか？';
      let title = '一括削除確認';
      if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
        this.$store.commit('setLoading', true);
        this.checkedIndexs.sort(function(first, second){
          return first - second;
        });
        for (let i = this.checkedIndexs.length - 1; i >= 0; i--) {
          this.documentList.splice(this.checkedIndexs[i], 1);
          this.checkedIndexs.splice(i, 1);
        }
        this.isAllChecked = false;
        this.$store.commit('setLoading', false);
      }
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      let checkedIndex = this.checkedIndexs.findIndex(el => el == rowNum);
      //console.log(this.checkedIndexs);
      //console.log(checkedIndex);
      if (checkedIndex != -1) {
        this.checkedIndexs.splice(checkedIndex, 1);
      }
      this.documentList.splice(rowNum, 1);
      let tempIndex = [];
      for (let i = 0; i < this.checkedIndexs.length; i++) {
        if (this.checkedIndexs[i] > rowNum) {
          tempIndex.push(this.checkedIndexs[i] - 1);
          this.checkedIndexs.splice(checkedIndex, 1);
        }
      }
      for (let i = 0; i < tempIndex.length; i++) {
        this.checkedIndexs.push(tempIndex[i]);
      }
      if (this.documentList.length > 0) {
        this.changeCheck();
      } else {
        this.isAllChecked = false;
      }
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
    // フィールド取得
    setFields: function() {
      let retFields = [];
      retFields.push({key: 'Check'});
      if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
        // 電子書類種類：電子書類
        retFields.push({key: 'SelectDocumentsClass', label: '電子書類区分'});
        retFields.push({key: 'SelectOfficeId', label: '営業所コード'});
        retFields.push({key: 'ClientClass', label: '取引先区分'});
        retFields.push({key: 'ClientId', label: '取引先コード'});
        retFields.push({key: 'ClientName', label: '取引先名'});
        retFields.push({key: 'DateTransactionDate', label: '取引日付'});
        retFields.push({key: 'TextMoney', label: '取引金額'});
        retFields.push({key: 'TextContents', label: '内容'});
      } else {
        // 電子書類種類：請求書
        retFields.push({key: 'BillingMonthYear', label: '請求年月'});
        retFields.push({key: 'NormalSeparateClass', label: '通常分割区分'});
        retFields.push({key: 'SelectOfficeId', label: '営業所コード'});
        retFields.push({key: 'ClientId', label: '取引先コード'});
        retFields.push({key: 'ClientName', label: '取引先名'});
        retFields.push({key: 'DateTransactionDate', label: '締切日付'});
        retFields.push({key: 'BillingOutputClassText', label: '出力区分'});
        retFields.push({key: 'SiteId', label: '現場コード'});
        retFields.push({key: 'SiteName', label: '現場名'});
        retFields.push({key: 'BillingSeparateClass', label: '請求書分割区分'});
        retFields.push({key: 'SeparateInvoiceNo', label: '分割請求書番号'});
        retFields.push({key: 'TextMoney', label: '請求金額'});
      }
      if (this.checkDocumentsUploadWay==Const.DocumentsUploadWay.everyRow) {
        // 書類アップロード方法：行毎アップロード
        retFields.push({key: 'Upload', label: 'アップロード'});
      } else {
        // 書類アップロード方法：ページ分割アップロード
        retFields.push({key: 'Page', label: 'ページ'});
      }
      retFields.push({key: 'Delete', label: ''});
      this.fields = retFields;
    },
    // 締切日付を設定
    setClosingYmd: function(item) {
      // 請求年月が「YYYY-MM」以外の場合は処理を行わない
      if (getNullStr(item.BillingMonthYear).length != 7) {
        return;
      }
      // 請求年月が正しい値かどうかを確認
      let strWork = item.BillingMonthYear + '-01';
      let date = new Date(strWork);
      if (isNaN(date) == true) {
        // 請求年月が不正なため処理をしない
        return;
      }
      // 締日が正しい値かどうかを確認
      if (getNullStr(item.ClosingDate) == '0') {
        // 締日が未設定のため処理をしない
        return;
      }
      // 請求年月と締日から締切日を取得して設定
      item.DateTransactionDate = getClosingDate(item.BillingMonthYear.replace(/-/g,''), item.ClosingDate);
    },
    // ファイル名から行にデータセット
    async setRowFromFileName(item, index) {
      const functionName = 'setRowFromFileName';
      //console.log(item);
      this.$store.commit('setLoading', true);
      try {
        let fileName = item.FileDocument.name;
        let strWork = '';
        let rowIndex = 0;
        let date = null;
        let listPartFileName = fileName.split('_');
        let isClientIdHenko = false;
        let isSiteIdHenko = false;
        for (let i = 0; i < listPartFileName.length; i++) {
          let koumoku = '';
          if (i == listPartFileName.length - 1) {
            // 最終行の場合
            // 拡張子部分を除外
            let listWork = listPartFileName[i].split('.');
            for (let j = 0; j < listWork.length - 1; j++) {
              koumoku += listWork[j];
            }
          } else {
            // 最終行以外の場合
            koumoku = listPartFileName[i];
          }
          strWork = getNullStr(koumoku).trim();
          if (getNullStr(strWork) == '') {
            // 空白の場合は設定しない
            continue;
          }
          if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
            // 電子書類種類：電子書類
            // 「電子書類区分_営業所コード_取引先区分_取引先コード_取引日付_取引金額.拡張子」の形式（アンダーバー区切り）
            switch(i) {
            case 0:
              // 電子書類区分
              rowIndex = this.electronicDocumentsClassList.findIndex(el => getNullStr(el.id) == strWork);
              if (rowIndex != -1) {
                // 存在する場合のみ設定
                item.SelectDocumentsClass = Number(strWork);
              }
              break;
            case 1:
              // 営業所コード
              rowIndex = this.salesOffice.findIndex(el => getNullStr(el.id) == strWork);
              if (rowIndex != -1) {
                // 存在する場合のみ設定
                item.SelectOfficeId = Number(strWork);
              }
              break;
            case 2:
              // 取引先区分
              if (strWork == getNullStr(Const.ClientClass.customer) || strWork == getNullStr(Const.ClientClass.supplier)) {
                // 取引先区分が適正値の場合のみ
                if (getNullStr(item.ClientClass) != strWork) {
                  // 取引先区分が前の値と異なる場合
                  item.ClientClass = Number(strWork);
                  isClientIdHenko = true;
                }
              }
              break;
            case 3:
              // 取引先コード
              if (item.ClientClass == Const.ClientClass.customer || item.ClientClass == Const.ClientClass.supplier) {
                // 取引先区分が適正値の場合
                if (isNaN(strWork) == false && item.ClientId != strWork) {
                  // 数値の場合、且つ、値が変更される場合
                  item.ClientId = strWork;
                  isClientIdHenko = true;
                }
              }
              break;
            case 4:
              // 取引日付
              if (strWork.length == 8 && isNaN(strWork) == false) {
                date = new Date(strWork.substring(0, 4) + '-' + strWork.substring(4, 6) + '-' + strWork.substring(6, 8));
              } else {
                date = new Date(strWork);
              }
              // 日付以外の場合は設定しない
              if (isNaN(date) == false) {
                item.DateTransactionDate = formatDate(strWork, 'YYYY-MM-DD');
              }
              break;
            case 5:
              // 取引金額
              if (isNaN(strWork) == false) {
                // 数値の場合のみ設定
                item.TextMoney = strWork;
              }
              break;
            default:
              break;
            }
          } else {
            // 電子書類種類：請求書
            // 「請求年月_通常分割区分_営業所コード_取引先コード_現場コード_請求書分割区分_分割請求書番号_請求金額.拡張子」の形式（アンダーバー区切り）
            switch(i) {
            case 0:
              // 請求年月
              if (strWork.length == 6 && isNaN(strWork) == false) {
                strWork = strWork.substring(0, 4) + '-' + strWork.substring(4, 6);
                date = new Date(strWork + '-01');
                // 日付以外の場合は設定しない
                if (isNaN(date) == false) {
                  item.BillingMonthYear = strWork;
                }
              }
              break;
            case 1:
              // 通常分割区分
              rowIndex = Const.NormalSeparateClassList.findIndex(el => getNullStr(el.id) == strWork);
              if (rowIndex != -1) {
                // 存在する場合のみ設定
                item.NormalSeparateClass = Number(strWork);
              }
              break;
            case 2:
              // 営業所コード
              rowIndex = this.salesOffice.findIndex(el => getNullStr(el.id) == strWork);
              if (rowIndex != -1) {
                // 存在する場合のみ設定
                item.SelectOfficeId = Number(strWork);
              }
              break;
            case 3:
              // 取引先コード
              if (isNaN(strWork) == false && item.ClientId != strWork) {
                // 数値の場合、且つ、値が変更される場合
                item.ClientId = strWork;
                isClientIdHenko = true;
                isSiteIdHenko = true;
              }
              break;
            case 4:
              // 現場コード
              if (getNullStr(item.ClientId) != '') {
                // 取引先コードの設定あり
                if (isNaN(strWork) == false && item.SiteId != strWork) {
                  // 数値の場合のみ設定
                  item.SiteId = strWork;
                  isSiteIdHenko = true;
                }
              }
              break;
            case 5:
              // 請求書分割区分
              if (item.NormalSeparateClass == Const.NormalSeparateClass.separate) {
                // 通常分割区分が「1：分割」の場合のみ値を設定
                rowIndex = Const.SeparateBillingClassList.findIndex(el => getNullStr(el.value) == strWork);
                if (rowIndex != -1) {
                  // 存在する場合のみ設定
                  item.BillingSeparateClass = strWork;
                }
              }
              break;
            case 6:
              // 分割請求書番号
              if (isNaN(strWork) == false) {
                // 数値の場合のみ設定
                item.SeparateInvoiceNo = strWork;
              }
              break;
            case 7:
              // 請求金額
              if (isNaN(strWork) == false) {
                // 数値の場合のみ設定
                item.TextMoney = strWork;
              }
              break;
            default:
              break;
            }
          }
        }
        if (this.checkDocumentsKind == Const.DocumentsKind.electronicDocuments) {
          // 電子書類種類：電子書類
          if (isClientIdHenko == true) {
            // 取引先コードが変更された場合
            if (getNullStr(item.ClientClass) != '' && getNullStr(item.ClientId) != '' &&
            isNaN(item.ClientClass) == false && isNaN(item.ClientId) == false) {
              // 取引先情報に数値が設定されている場合
              let selectSql = this.makeSelectSqlGetClientsData(item.ClientClass, item.ClientId, '');
              let result = await executeSelectSql(selectSql);
              if (result != null && result.length > 0) {
                item.ClientId = result[0].client_id;
                item.ClientName = result[0].client_name_kanji;
                item.ClientNameKana = result[0].client_name_kana;
                item.BillingOutputClass = result[0].billing_output_class;
                item.BillingOutputClassText = getListValue(Const.BillingOutputClassList, result[0].billing_output_class, 'value', 'text');
                item.IsClientMaster = true;
              } else {
                this.clientClear(index);
              }
            } else {
              this.clientClear(index);
            }
          }
        } else {
          // 電子書類種類：請求書
          if (isClientIdHenko == true || isSiteIdHenko == true) {
            // 取引先コード、または、現場コードが変更された場合
            if (getNullStr(item.ClientId) != '' && isNaN(item.ClientId) == false) {
              // 取引先コードに有効な値が設定されている場合
              let csvSiteId = '';
              if (getNullStr(item.SiteId) != '' && isNaN(item.SiteId) == false) {
                // 現場コードが数値の場合
                csvSiteId = item.SiteId;
              }
              let selectSql = this.makeSelectSqlGetClientsData(Const.ClientClass.customer, item.ClientId, csvSiteId);
              let result = await executeSelectSql(selectSql);
              if (result != null && result.length > 0) {
                item.ClientId = result[0].client_id;
                item.ClientName = result[0].client_name_kanji;
                item.ClientNameKana = result[0].client_name_kana;
                item.BillingOutputClass = result[0].billing_output_class;
                item.BillingOutputClassText = getListValue(Const.BillingOutputClassList, result[0].billing_output_class, 'value', 'text');
                item.IsClientMaster = true;
                item.ClosingDate = result[0].closing_date;
                this.setClosingYmd(item);
                if (item.BillingOutputClass == Const.BillingOutputClass.client) {
                  // 取引先別
                  // 固定値を入力（入力が不要なため）
                  item.SiteId = '0';
                  item.SiteName = '';
                } else {
                  // 現場別
                  if (csvSiteId == '') {
                    // 現場コードが有効な値でない場合
                    this.siteClear(index);
                  } else {
                    if (getNullStr(result[0].site_id) != '') {
                      // 現場コードがマスタに存在
                      item.SiteId = result[0].site_id;
                      item.SiteName = result[0].client_site_name_kanji;
                    } else {
                      // 現場コードがマスタに存在しない
                      this.siteClear(index);
                    }
                  }
                }
              } else {
                this.clientClear(index);
              }
            } else {
              this.clientClear(index);
            }
          }
          this.changeNormalSeparateClass(item);
        }
        // ファイル名の設定値を即確認するためバリデーション実行
        setTimeout(() => {
          this.$refs.observer.validate();
        }, 500);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 取引先情報取得SELECT文作成
    makeSelectSqlGetClientsData: function(clientClass, clientId, csvSiteId) {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' m_c.client_class';
      selectSql += ',m_c.client_id';
      selectSql += ',m_c.client_name_kanji';
      selectSql += ',m_c.client_name_kana';
      selectSql += ',m_c.closing_date';
      selectSql += ',m_c.billing_output_class';
      if (csvSiteId != '') {
        // 現場コードあり
        selectSql += ',m_cs.site_id';
        selectSql += ',m_cs.client_site_name_kanji';
      } else {
        // 現場コード無し
        selectSql += ',NULL AS site_id';
        selectSql += ',\'\' AS client_site_name_kanji';
      }
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_clients AS m_c ';
      if (csvSiteId != '') {
        // 現場コードあり
        selectSql += 'LEFT JOIN m_clients_sites AS m_cs ';
        selectSql += 'ON m_c.client_id = m_cs.client_id ';
        selectSql += 'AND m_cs.site_id IN (' + csvSiteId + ') ';
      }
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'm_c.client_class = ' + clientClass + ' ';
      selectSql += 'AND m_c.client_id IN (' + clientId + ') ';

      return selectSql;
    },
    // 通常分割区分変更時処理
    changeNormalSeparateClass: function(item) {
      if (item.NormalSeparateClass != Const.NormalSeparateClass.separate || 
        item.BillingOutputClass != Const.BillingOutputClass.clientSite) {
        // 分割、且つ、現場別の場合を除き、請求書分割区分は伝票毎固定
        item.BillingSeparateClass = Const.SeparateBillingClass.billings;
      }
      if (item.NormalSeparateClass != Const.NormalSeparateClass.separate ||
        item.BillingSeparateClass != Const.SeparateBillingClass.billings) {
        // 分割、且つ、伝票毎の場合を除き、分割請求書番号は0固定
        item.SeparateInvoiceNo = '0';
      }
    },
  }
}
</script>
<style scoped>
</style>