<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-1" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-1" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-1" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-1">
                        <!-- 営業所入力欄 -->
                        <b-col lg="3">
                          <label for="salesOffice">営業所</label>
                          <b-form-select
                            id="salesOffice"
                            v-model="searchConditions.selectSalesOffice"
                            :options="searchConditions.salesOffice"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </b-col>
                        <!-- 伝票種別入力欄 -->
                        <b-col lg="3">
                          <b-form-group
                            label="伝票種別"
                            label-for="selectTradition"
                          >
                            <b-form-select
                              id="selectTradition"
                              v-model="searchConditions.selectTradition"
                              :options="searchConditions.traditionList"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <label for="billingNo">伝票番号<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="billingNo" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingDateStart" :rules="{dateConsistency:searchConditions.billingDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="伝票日付"
                              label-for="datepicker1"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="billingDateStart" class="form-control" v-model="searchConditions.billingDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="billingDateEnd" class="form-control" v-model="searchConditions.billingDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>

                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                            <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、伝票番号か取引先コードを指定してください。</b-form-text>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <label for="customerCode">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="9">
                          <label for="customerName">取引先名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 担当者コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="personInCharge" rules="numeric" v-slot="{ classes,errors }">
                            <label for="personInCharge">担当者コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="personInCharge" name="personInCharge" v-model="searchConditions.personInCharge"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 現場名入力欄 -->
                        <b-col lg="9">
                          <label for="siteName">現場名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの現場名（漢字）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="siteName" name="siteName" v-model="searchConditions.siteName"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 製品コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <label for="productCode">製品コード<b-form-text class="text-muted d-inline-flex">(前方一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="9">
                          <label for="productName">製品名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-0 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #cell(CustomerName)="data">
                <div style="min-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(SiteName)="data">
                <div style="min-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.SiteName }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <div v-show="!printStatus">
                  <!-- 修正ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の修正を行います。'" @click="clickEditBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear)" class="mr-1">
                    <span class="oi oi-pencil"></span> 修正
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の削除を行います。'" @click="clearAlert(); clickDeleteBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear, operation.item.BillClassId, operation.item.OrderReceiveId, operation.item.CustomerCode, operation.item.BillingDate, operation.item.ClosingDate, operation.item.SiteId)" class="mr-1" :disabled="operation.item.EditFlg==false">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                  <!-- 受注入力照会ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'受注内容の照会と各種操作を行います。'" @click="clearAlert(); clickDetailBtn(operation.item.OrderReceiveId)" class="mr-1" v-if="operation.item.IsOrderReceiveUmu==true">
                    <span class="oi oi-list"></span> 受注入力照会
                  </b-button>
                  <!-- 伝票発行ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された売上入力の伝票発行を行います。'" @click="clickPrintBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear)" class="mr-1">
                    <span class="oi oi-print"></span> 伝票発行
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mb-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, formatDate, formatCurDate, addOperationLogs, convertSqlLikeSpecialChar, getListValue, getControlMaster, dateConsistency, executeSelectSql, CreateColRow, CreateUpdateSql, executeSqlList, isSystemEditable, isAfterMonthlyUpdate, checkInvoiceIssue } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices, list_m_stocks } from '@/graphql/queries';

const MODULE_NAME = 'sales-list';

export default {
  name: 'SALES-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売上一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        billingNo: '',
        billingDateStart: '',
        billingDateEnd: '',
        selectTradition: 0,
        traditionList: [],
        personInCharge: '',
        clientCode: '',
        clientName: '',
        siteName: '',
        productCode: '',
        productName: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 削除確認ダイアログ用
      confirmMessage: [],
      delData: {},
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 営業所テーブル情報
      mOffices: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'BillingDate',
          label: '伝票日付',
          sortable: true,
        },
        {
          key: 'BillingNo',
          label: '伝票番号',
          sortable: true,
        },
        {
          key: 'BillClass',
          label: '伝票種別',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所名',
          sortable: true,
        },
        {
          key: 'StaffId',
          label: '担当者コード',
          sortable: true,
        },
        {
          key: 'PersonInCharge',
          label: '担当者名',
          sortable: true,
        },
        {
          key: 'CustomerCode',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'SiteName',
          label: '現場名',
          sortable: true,
        },
        {
          key: 'OrderReceiveId',
          label: '受注番号',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_offices)),
          getControlMaster(),
        ]);
        let staffListData = staffListResult.data.list_m_staffs;
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);

        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          this.mOffices.push({id: officeListData[i].office_id, name: officeListData[i].office_name_kanji});
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 伝票種別
        this.searchConditions.traditionList.push({id: 0, text: '全て'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「11:通常売上」「12:返品伝票」
          if (Const.TraditionList[i].id == Const.Tradition.salesNormal ||
          Const.TraditionList[i].id == Const.Tradition.salesReturn) {
            this.searchConditions.traditionList.push(Const.TraditionList[i]);
          }
        }
        // 現在処理年月、消費税率取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 伝票日付開始に現在日を設定
        this.searchConditions.billingDateStart = formatCurDate('YYYY-MM-DD');
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // SELECT文字列作成
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',cumulative_transaction.client_site_name';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',cumulative_transaction.order_receive_id';
      selectSql += ',cumulative_transaction.monthly_month_year';
      selectSql += ',clients.closing_date';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN (' + await this.makeSelectQuery() + ') AS cumulative_transaction_QUERY ';
      selectSql += 'ON cumulative_transaction.transaction_id = cumulative_transaction_QUERY.transaction_id ';
      selectSql += 'AND cumulative_transaction.billing_no = cumulative_transaction_QUERY.billing_no ';
      selectSql += 'AND cumulative_transaction.billing_row = cumulative_transaction_QUERY.billing_row ';
      selectSql += 'AND cumulative_transaction.monthly_month_year = cumulative_transaction_QUERY.monthly_month_year ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';

      return selectSql;
    },
    // SELECT文字列作成
    async makeSelectQuery() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' cumulative_transaction.transaction_id';
      selectSql += ',cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_row';
      selectSql += ',cumulative_transaction.monthly_month_year';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += 'INNER JOIN m_clients AS clients ';
        selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
        selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      // 伝票種別
      if (this.searchConditions.selectTradition != 0) {
        // 伝票種別を指定した場合
        selectSql += 'AND cumulative_transaction.bill_class = ' + this.searchConditions.selectTradition + ' ';
      } else {
        // 伝票種別が全て（11:通常伝票、12:返品伝票）
        selectSql += 'AND cumulative_transaction.bill_class IN (' + Const.Tradition.salesNormal + ',' + Const.Tradition.salesReturn + ') ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND cumulative_transaction.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 伝票番号
      if (this.searchConditions.billingNo != '') {
        selectSql += 'AND cumulative_transaction.billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 伝票日付
      if (this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd == '') {
        selectSql += 'AND cumulative_transaction.billing_date >= ' + '\'' + this.searchConditions.billingDateStart + '\' ';
      } else if(this.searchConditions.billingDateStart == '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date <= ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      } else if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN ' + '\'' + this.searchConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
        selectSql += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 担当者コード
      if (!(this.searchConditions.personInCharge === '')) {
        selectSql += 'AND cumulative_transaction.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 現場名(あいまい検索対象)
      if (this.searchConditions.siteName != '') {
        const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.client_site_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 製品コード
      if (this.searchConditions.productCode != '') {
        selectSql += 'AND cumulative_transaction.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if (this.searchConditions.productName != '') {
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.billing_no,cumulative_transaction.monthly_month_year';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.billing_no';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      // コントロールマスタの現在処理年月の初日を作成
      let processDate = ('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01';
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          BillingDate: formatDate(result[i].billing_date),
          BillingNo: result[i].billing_no,
          BillClassId: result[i].bill_class,
          BillClass: getListValue(Const.TraditionList, result[i].bill_class, 'id', 'text'),
          SalesOffice: getListValue(this.mOffices, result[i].office_id),
          StaffId: result[i].staff_id,
          PersonInCharge: result[i].staff_name_kanji,
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          SiteId: result[i].site_id,
          SiteName: result[i].client_site_name,
          OrderReceiveId: result[i].order_receive_id == 0 ? '' : result[i].order_receive_id,
          IsOrderReceiveUmu: result[i].order_receive_id == 0 ? false : true,
          MonthlyMonthYear: result[i].monthly_month_year,
          ClosingDate: result[i].closing_date,
          Operation: result[i],
        };
        // 日付は現在処理年月の初日と同じ、または、未来の場合、編集可
        searchResult.EditFlg = dateConsistency(processDate, searchResult.BillingDate);
        this.itemList.push(searchResult);
      }
    },
    /* 修正ボタン押下時 */
    async clickEditBtn(billingNo, monthlyMonthYear) {
      //console.log('修正');
      //console.log(billingNo);
      //console.log(monthlyMonthYear);
      let route = this.$router.resolve({ name: 'SALES-EDIT', query: { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear } });
      window.open(route.href, '_blank');
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(billingNo, monthlyMonthYear, billClass, receivedOrderId, clientId, billingDate, closingDate, siteId) {
      //console.log('削除');

      // 返品伝票、且つ、在庫計上入力が「0:返品在庫計上未」の場合、削除不可
      if (billClass == Const.Tradition.salesReturn && await this.checkBillReturnCount(receivedOrderId) == false) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2018']);
        scrollTo(0,0);
        return;
      }
      // 請求書発行登録済みの場合、削除不可
      let errMessage = await checkInvoiceIssue(clientId, billingDate, closingDate, siteId, billingNo);
      if (errMessage != '') {
        this.alertWarning.push(errMessage);
        scrollTo(0,0);
        return;
      }
      this.delData = { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear };
      this.confirmMessage = [];
      this.confirmMessage.push('選択されたデータを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    // 受注データの返品在庫計上入力確認
    async checkBillReturnCount(receivedOrderId) {
      const functionName = 'checkOrdersReceives';
      this.$store.commit('setLoading', true);
      let isBillReturnCount = false;
      try {
        let selectSql = '';
        selectSql += 'SELECT ';
        selectSql += 'COUNT(*) AS CNT';
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + receivedOrderId + ' ';
        selectSql += 'AND orders_receives.is_bill_return_count = 1 ';
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null && resultData.length > 0 && resultData[0].CNT > 0) {
          // 返品在庫計上入力のレコードが1件でもある場合
          isBillReturnCount = true;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
      }
      this.$store.commit('setLoading', false);
      return isBillReturnCount;
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      let retResult = false;
      this.transactSqlList = [];
      // 累積トランザクションの数量取得SQL
      let selectSql = '';
      selectSql = this.makeSelectSqlDelete();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 在庫確認
        if (await this.checkStock(dataResult) == true) {
          // 在庫マスタロック
          this.lockStocks(dataResult);
          // 在庫マスタ更新SQL
          this.updateStocksMaster(dataResult);
          // 累積トランザクション削除SQL
          this.deleteCumulativeTransaction();

          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.alertDanger.push(msg);
              return false;
            }
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
            this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
            return false;
          }

          try {
            // 作成した登録用SQLを全実行
            await executeSqlList(this.transactSqlList);

            retResult = true;
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
            this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
            console.log(error);
          }
        } else {
          // 在庫がないため更新不可
          this.alertWarning.push(DISP_MESSAGES.WARNING['2016']);
          return;
        }
      } else {
        // 削除対象が存在しない場合は削除に成功したこととする。
        retResult = true;
      }
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 検索SQL作成（削除対象） */
    makeSelectSqlDelete: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.inventory_control_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      // 伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.delData.billingNo + ' ';
      // 月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.delData.monthlyMonthYear + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },
    /* 在庫チェック */
    async checkStock(result) {
      let retResult = false;
      //console.log('在庫チェック');
      let csvProductId = '';
      for (let i = 0; i < result.length; i++) {
        // 在庫管理区分が「0:する」の場合
        if (result[i].inventory_control_class == Const.InventoryControlClassDef.inventory) {
          if (csvProductId != '') {
            csvProductId += ',';
          }
          csvProductId += result[i].product_id;
        }
      }
      // 在庫から取得する必要のある製品が１つでもある場合、在庫マスタチェック
      if (csvProductId != '') {
        // 在庫マスタから在庫情報取得
        let where_clause = '';
        // 年月
        where_clause += 'AND month_year = ' + this.controlMasterData.processMonthYear + ' ';
        // 営業所コード
        where_clause += 'AND office_id = ' + result[0].office_id + ' ';
        // 製品コード
        where_clause += 'AND product_id IN (' + csvProductId + ') ';
        //console.log(where_clause);
        let stocksResult = await API.graphql(graphqlOperation(list_m_stocks, {where_clause: where_clause}));
        let stocksResultData = stocksResult.data.list_m_stocks;
        //console.log(stocksResultData);
        // 在庫がない場合は完了
        if (stocksResultData == null || stocksResultData.length == 0) {
          return retResult;
        }
        for (let i = 0; i < result.length; i++) {
          // 在庫から取り出す必要のある製品数（削除のため-1を掛ける）
          let needStockCount = result[i].product_quantity * -1;
          if (needStockCount > 0) {
            for (let j = 0; j < stocksResultData.length; j++) {
              // 取得した在庫マスタの対象の製品
              if (result[i].product_id == stocksResultData[j].product_id) {
                if (stocksResultData[j].balance >= needStockCount) {
                  // 必要な在庫数が在庫にある場合、同製品はOKとして、次の製品へ
                  break;
                } else {
                  // 必要な在庫数が在庫にない場合、falseを返却
                  return retResult;
                }
              }
            }
          }
        }
      }

      retResult = true;

      return retResult;
    },
    /* 在庫マスタロックSQL作成 */
    lockStocks: function(result) {
      //console.log('在庫マスタロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.month_year';
      selectSql += ',stocks.office_id';
      selectSql += ',stocks.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + result[0].office_id + ' ';
      let csvProductId = '';
      for (let i = 0; i < result.length; i++) {
        // 在庫管理区分が「0:する」の場合
        if (result[i].inventory_control_class == Const.InventoryControlClassDef.inventory) {
          if (csvProductId != '') {
            csvProductId += ',';
          }
          csvProductId += result[i].product_id;
        }
      }
      selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';
      //console.log(selectSql)
      if (csvProductId != '') {
        this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function(result) {
      //console.log('在庫マスタ更新SQL作成');
      let colList = [];
      for(let i = 0; i < result.length; i++) {
        // 在庫管理区分が「0:する」の場合
        if (result[i].inventory_control_class == Const.InventoryControlClassDef.inventory) {
          colList = [];
          // 残高数
          colList.push(CreateColRow('balance', 'balance + ' + result[i].product_quantity, 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          /* WHERE句 */
          let where_clause = ' WHERE ';
          where_clause += ' month_year = ' + this.controlMasterData.processMonthYear + ' ';
          where_clause += 'AND office_id = ' + result[i].office_id + ' ';
          where_clause += 'AND product_id = ' + result[i].product_id + ' ';
          let updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
          //console.log(updateSql);
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
    },
    /* 累積トランザクション削除SQL作成 */
    deleteCumulativeTransaction: function() {
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.delData.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.delData.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.sales + '\' ';
      deleteSql += 'AND is_update_monthly = 0 ';
      this.transactSqlList.push({sql: deleteSql, forUpdateFlg: 0});
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 受注入力照会ボタン押下時 */
    async clickDetailBtn(receivedOrderId) {
      //console.log('照会');
      //console.log(receivedOrderId);
      var newWin = window.open('', '_blank');
      if (await this.checkOrdersReceives(receivedOrderId) == true) {
        let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: receivedOrderId, parentKbn: 0, reserveErrFlg: 0 } });
        //console.log(this.$router);
        newWin.location = route.href;
      } else {
        newWin.close();
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%', '受注データ'));
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // 受注データの存在確認
    async checkOrdersReceives(receivedOrderId) {
      const functionName = 'checkOrdersReceives';
      this.$store.commit('setLoading', true);
      let isOrdersReceives = false;
      try {
        let selectSql = '';
        selectSql += 'SELECT ';
        selectSql += 'COUNT(*) AS CNT';
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + receivedOrderId + ' ';
        selectSql += 'AND orders_receives.is_deleted = 0 ';
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null && resultData.length > 0 && resultData[0].CNT > 0) {
          // 未削除のレコードが1件でもある場合
          isOrdersReceives = true;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
      }
      this.$store.commit('setLoading', false);
      return isOrdersReceives;
    },
    // 伝票発行ボタン押下時
    clickPrintBtn: function(billingNo, monthlyMonthYear) {
      // 納品書PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        receivedOrderId: 0,
        billingNo: billingNo,
        monthlyMonthYear: monthlyMonthYear,
      };
      let route = this.$router.resolve({ name: 'SALES-DIRECT-NOUHIN', query: query});   
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>