import { render, staticRenderFns } from "./order-edit.vue?vue&type=template&id=0bc3c850&scoped=true&"
import script from "./order-edit.vue?vue&type=script&lang=js&"
export * from "./order-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc3c850",
  null
  
)

export default component.exports