<template>
 <b-modal
  id="clientInquiryModal"
  size="xl"
  centered no-close-on-esc no-close-on-backdrop hide-header-close scrollable
  @show="initData"
  title="取引先照会"
 >
  <b-container>
    <b-row>
      <b-col>
        <b-table-simple
          small
           v-if="resultError.length == 0"
        >
          <b-tbody>
            <b-tr>
              <b-th>取引先コード</b-th>
              <b-td>{{searchResult.clientId}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>取引先名</b-th>
              <b-td>{{searchResult.clientNameKanji}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>郵便番号</b-th>
              <b-td>{{searchResult.zipCode}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>住所１</b-th>
              <b-td>{{searchResult.address1}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>住所２</b-th>
              <b-td>{{searchResult.address2}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>TEL</b-th>
              <b-td>{{searchResult.phoneNumber}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>FAX</b-th>
              <b-td>{{searchResult.faxNumber}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>サービス区分</b-th>
              <b-td>{{searchResult.serviceOrderClass}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>発注条件 数量</b-th>
              <b-td>{{searchResult.orderConditionQuantity}}</b-td>
            </b-tr>
            <b-tr>
              <b-th>発注条件 金額</b-th>
              <b-td>{{searchResult.orderConditionAmount}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
  <template #modal-footer>
    <b-button size="sm" @click="$bvModal.hide('clientInquiryModal')" class="mr-1">キャンセル</b-button>
  </template>
 </b-modal>
<!-- ●●●取引先検索モーダル●●● -->
</template>
<script>
import Const from '@/assets/js/const.js';
import { init, executeSelectSql, addOperationLogs, getListValue } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'client-inquiry';

export default {
  name: 'CLIENT-INQUIRY',
  props:['clientProp'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先照会',
      // 検索結果
      searchResult: {
        clientId: null,
        clientNameKanji: '',
        zipCode: '',
        address1: '',
        address2: '',
        phoneNumber: '',
        faxNumber: '',
        serviceOrderClass: null,
        orderConditionQuantity: null,
        orderConditionAmount: null,
      },
      // エラーメッセージ
      resultError: [],
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods:{
    /* 検索処理 */
    async initData(){
      const functionName = 'initData';
      try {
        this.resultError = [];
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if(resultData != null && resultData.length > 0){
          this.setResult(resultData[0]);
        }else{
          this.resultError.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','取引先'));
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let clientId;
      if (isNaN(Number(this.clientProp.clientId)) == false) {
        // パラメータの取引先コードで検索を行う
        clientId = this.clientProp.clientId;
      } else {
        // パラメータの取引先コードが数値以外の場合は0として検索を行う（数値以外だとエラーとなるため）
        clientId = '0';
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' clients.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',clients.service_order_class';
      selectSql += ',clients.order_condition_quantity';
      selectSql += ',clients.order_condition_amount';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_clients AS clients ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' clients.client_class = ' + this.clientProp.clientClass + ' ';
      selectSql += 'AND clients.client_id = ' + clientId + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    setResult: function(result) {
      // 検索結果反映
      this.searchResult.clientId = result.client_id;
      this.searchResult.clientNameKanji = result.client_name_kanji;
      this.searchResult.zipCode = result.zip_code;
      this.searchResult.address1 = result.address_1;
      this.searchResult.address2 = result.address_2;
      this.searchResult.phoneNumber = result.phone_number;
      this.searchResult.faxNumber = result.fax_number;
      this.searchResult.serviceOrderClass = getListValue(Const.ServiceOrderClassList, result.service_order_class, 'value','text');
      this.searchResult.orderConditionQuantity = result.order_condition_quantity.toLocaleString();
      this.searchResult.orderConditionAmount = result.order_condition_amount.toLocaleString();
    },
  },
}
</script>
<style scoped>
</style>