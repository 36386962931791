<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col cols="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると請求書（仮伝票）出力範囲表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 請求書（仮伝票）出力範囲
                  </a>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <b-container>
                    <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                      <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                      <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 締日プルダウン -->
                          <b-col cols="6">
                            <validation-provider name="closingDate" rules="required" v-slot="{ classes,errors }">
                              <b-form-group
                                label="締日"
                                label-for="closingDate"
                              >
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-select
                                      id="closingDate"
                                      name="closingDate"
                                      v-model="searchConditions.closingDate"
                                      :options="searchConditions.closingDates"
                                      @change="calcBillingDate"
                                    >
                                    </b-form-select>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                                <b-form-text class="text-muted">締日と締切日から開始日と終了日を計算します。</b-form-text>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- 締切年月 -->
                          <b-col cols="6">
                            <validation-provider name="closingYm" :rules="{required: true, regex: /^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="締切年月"
                                label-for="closingYm"
                              >
                                <b-row>
                                  <b-col lg="8" :class="classes">
                                    <b-form-input id="closingYm" name="closingYm" type="month" v-model="searchConditions.closingYm" @change="calcBillingDate" />
                                  </b-col>
                                  <b-col lg="8" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 開始日 -->
                          <b-col lg="6">
                            <b-form-group
                              label="開始日"
                              label-for="billingStartDate"
                            >
                              <b-row>
                                <b-col lg="12">
                                  <b-form-input type="text" id="billingStartDate" v-model="searchConditions.billingStartDate" readonly />
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                          <!-- 締切日 -->
                          <b-col lg="6">
                            <b-form-group
                              label="締切日"
                              label-for="billingEndDate"
                            >
                              <b-row>
                                <b-col lg="12">
                                  <b-form-input type="text" id="billingEndDate" v-model="searchConditions.billingEndDate" readonly />
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 得意先コード -->
                          <b-col lg="12">
                            <b-form-group label="得意先コード">
                              <b-input-group>
                                <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input name="clientIdStart" type="text" v-model.number="searchConditions.clientIdStart" @change="checkClientId" />
                                        <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input name="clientIdEnd" type="text" v-model.number="searchConditions.clientIdEnd" @change="checkClientId" />
                                         <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 現場コード -->
                          <b-col lg="12">
                            <b-form-group label="現場コード">
                              <b-input-group>
                                <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdStart" type="text" v-model.number="searchConditions.siteIdStart" :readonly="searchConditions.billingOutputClass!=1"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdEnd" type="text" v-model.number="searchConditions.siteIdEnd" :readonly="searchConditions.billingOutputClass!=1"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">得意先コードの開始と終了の両方に「現場別」の得意先を入力した場合に有効となります。<br>現場の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-row class="justify-content-md-center pb-4">
            <b-col lg="5">
              <b-btn-toolbar>
                <b-button pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書のPDF出力を行います。（最大200件として下さい。）" @click="clearAlert(); clickOutputBillingPdfTab();" :disabled="checkedClientIds.length==0">
                  <span class="oi oi-document"></span> 請求書PDF出力
                </b-button>
              </b-btn-toolbar>
            </b-col>
            <b-col lg="3">
              <b-input-group>
                <template #prepend>
                  <label for="invoiceInputNo">
                    <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='発行する請求書の事業者登録番号の値を設定して下さい。'>
                      <strong class='text-body'>事業者登録番号 <span class="oi oi-flag"/></strong>
                    </b-input-group-text>
                  </label>
                </template>
                <b-form-input type="text" v-model="invoiceInputNo" maxlength="20"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="4">
              <b-input-group>
                <template #prepend>
                  <label for="orderDate">
                    <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='発行する請求書の請求年月日の値を設定して下さい。'>
                      <strong class='text-body'>請求書発行登録日 <span class="oi oi-flag"/></strong>
                    </b-input-group-text>
                  </label>
                </template>
                <b-form-datepicker
                  v-model="invoiceIssueInputDate"
                  calendar-width="50%"
                  :reset-button="false"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-row>
          <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col class="mt-2">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <template #head(check)>
                  <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="totalCheckCnt==0" />
                </template>
                <template #head(salesUnitAmount)>売上額税抜<br>消費税<br>合計</template>
                <template #head(billingMoney)>今回請求税抜<br>消費税<br>合計</template>
                <template #cell(check)="data">
                  <b-check v-if="data.item.billingOutputClass==searchResultDispConditions.billingOutputClass" v-model="checkedClientIds" :value="data.item.clientId" @change="changeCheck"/>
                </template>
                <template #cell(billingOutputClass)="data">
                  {{ billingOutputClassVal(data.item.billingOutputClass.toString()) }}<br/>
                </template>
                <template #cell(salesUnitAmount)="data">
                  {{ data.item.productSalesUnitAmount.toLocaleString() }}<br/>
                  {{ data.item.productTax.toLocaleString() }}<br/>
                  {{ (data.item.productSalesUnitAmount + data.item.productTax).toLocaleString() }}
                </template>
                <template #cell(billingMoney)="data">
                  {{ data.item.productSalesUnitAmount.toLocaleString() }}<br>
                  {{ data.item.productTax.toLocaleString() }}<br>
                  {{ (data.item.productSalesUnitAmount + data.item.productTax).toLocaleString() }}<br>
                </template>
                <!-- 操作列 -->
                <template #cell(operation)="data">
                  <!-- 取引先保守ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.left="'顧客名の変更を行います。'" @click="clickClientsMasterBtn(data.item.clientId)" class="mr-1">
                    <span class="oi oi-pencil"></span> 取引先保守
                  </b-button>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, formatDate, getListValue, executeSelectSql, addOperationLogs, formatCurDate, getControlMaster } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import { list_v_clients_01 } from '@/graphql/queries';

const MODULE_NAME = 'tentative-invoice-list';

export default {
  name: 'TENTATIVE-INVOICE-LIST',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  /* データ */
  data() {
    return {
      /* ヘッダメニュー種別 */
      menu_type: 'user',
      /* ヘッダタイトル */
      title: '請求一覧表（仮伝票）',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      resultError: [],
      /* 検索項目 */
      searchConditions: {
        // 締日
        closingDate: '',
        // 締日選択肢
        closingDates: Const.closingDateList,
        /* 請求年月 */
        closingYm: '',
        // 開始日
        billingStartDate: '',
        // 締切日
        billingEndDate: '',
        /* 取引先コード（開始） */
        clientIdStart: '',
        /* 取引先コード（終了） */
        clientIdEnd: '',
        // 取引先名
        clientName: '',
        // 請求書出力単位区分（「1:現場別」の場合に現場コードを設定可能）
        billingOutputClass: 0,
        /* 現場コード（開始） */
        siteIdStart: '',
        /* 現場コード（終了） */
        siteIdEnd: ''
      },
      /* 検索結果表示時に指定された検索項目（帳票出力時の引数にはこちらを使用） */
      searchResultDispConditions:{
        // 締日
        closingDate: '',
        /* 請求年月 */
        closingYm: '',
        // 開始日
        billingStartDate: '',
        // 締切日
        billingEndDate: '',
        /* 取引先コード（開始） */
        clientIdStart: '',
        /* 取引先コード（終了） */
        clientIdEnd: '',
        // 請求書出力単位区分
        billingOutputClass: 0,
        /* 現場コード（開始） */
        siteIdStart: '',
        /* 現場コード（終了） */
        siteIdEnd: ''
      },
      // 取引先モーダル用の引数
      propClientClass: Const.ClientClass.customer,
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // 印刷チェックボックスの総件数
      totalCheckCnt: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // フィルタリングデータの総件数
      filterRows: 0,
      /* 全選択チェックボックス */
      isAllChecked:false,
      /* 各行のチェック状態 */
      checkedClientIds: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 請求書区分
        BillingClass: {
          // 出力する
          output: Const.BillingClass.output,
          // 出力しない
          noOutput: Const.BillingClass.noOutput,
        },
      },
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
      },
      // 事業者登録番号
      invoiceInputNo: '',
      // 請求書発行登録日
      invoiceIssueInputDate: formatCurDate('YYYY-MM-DD'),
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コントロールマスタ
    let controlData = await getControlMaster();
    this.controlMasterData.taxRate = controlData.tax_rate;
    this.controlMasterData.newTaxRate = controlData.new_tax_rate;
    this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
    this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
    this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
    this.invoiceInputNo = controlData.invoice_input_no;
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* 現場モーダルのパラメータ用 */
    siteSearchProp: function() {
      return {
        client_id: this.searchConditions.clientIdStart,
        client_name: this.searchConditions.clientName,
      };
    },
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'check',
        },
        {
          key: 'clientId',
          label: '得意先コード',
        },
        {
          key: 'clientNameKanji',
          label: '得意先名',
        },
        {
          key: 'billingOutputClass',
          label: '出力区分',
        },
        {
          key: 'staffId',
          label: '担当者コード',
        },
        {
          key: 'staffNameNameKanji',
          label: '担当者名',
        },
        {
          // 売上額税抜／消費税／合計
          key: 'salesUnitAmount',
          class: 'text-right',
        },
        {
          // 今回請求税抜／消費税／合計
          key: 'billingMoney',
          class: 'text-right',
        },
        {
          key: 'paymentScheduled',
          label: '入金予定',
        },
        {
          key: 'businessClass',
          label: '金種',
        },
        {
          key: 'operation',
          label: '操作',
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertDanger.length > 0
    },
  },
  methods: {
    /* 開始日、締切日を計算 */
    calcBillingDate: function() {
      // 開始日、締切日を計算
      const format = 'YYYY/MM/DD';
      if (this.searchConditions.closingDate == '' || this.searchConditions.closingYm == '') {
        // 計算に必要な値が未入力の場合
        this.searchConditions.billingStartDate = '';
        this.searchConditions.billingEndDate = '';
      } else if (this.searchConditions.closingDate === '99') {
        // 末日の場合
        // 開始日：決算年月の月初日、締切日：決算年月の月末日
        this.searchConditions.billingStartDate = moment(this.searchConditions.closingYm + '-01').format(format);
        this.searchConditions.billingEndDate = moment(this.searchConditions.closingYm + '-01').endOf('month').format(format);
      } else {
        // 末日でない場合
        // 開始日：決算年月の先月の締日の翌日、締切日：決算年月の締日
        this.searchConditions.billingStartDate = moment(this.searchConditions.closingYm + '-' + ('00' + this.searchConditions.closingDate).slice(-2)).add(-1,'months').add(1,'days').format(format);
        this.searchConditions.billingEndDate = moment(this.searchConditions.closingYm + '-' + ('00' + this.searchConditions.closingDate).slice(-2)).format(format);
      }
    },
    // 取引先コードを確認
    async checkClientId() {
      const functionName = 'checkClientId'
      try {
        // 最初に初期化
        this.searchConditions.billingOutputClass = 0;
        this.searchConditions.clientName = '';
        // 得意先コードの開始と終了が同じ場合のみ（且つ、空欄や0以外）
        if (this.searchConditions.clientIdStart == this.searchConditions.clientIdEnd &&
          this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdStart != '0') {
          let where_clause = '';
          where_clause += 'AND client_class = ' + Const.ClientClass.customer + ' ';
          where_clause += 'AND client_id = ' + this.searchConditions.clientIdStart + ' ';
          //console.log(where_clause);
          let condition = {where_clause: where_clause};
          let result = await API.graphql(graphqlOperation(list_v_clients_01,condition));
          let resultData = result.data.list_v_clients_01;
          if (resultData != null && resultData.length > 0) {
            this.searchConditions.clientName = resultData[0].client_name_kanji;
            this.searchConditions.billingOutputClass = resultData[0].billing_output_class;
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    // 取引先検索
    showClientSearchModal:function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal:function(clientItems) {
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined') {
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton'
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索条件を保存 */
    saveSearchConditions: function() {
      this.searchResultDispConditions.closingDate = this.searchConditions.closingDate;
      this.searchResultDispConditions.closingYm = this.searchConditions.closingYm;
      this.searchResultDispConditions.billingStartDate = this.searchConditions.billingStartDate;
      this.searchResultDispConditions.billingEndDate = this.searchConditions.billingEndDate;
      this.searchResultDispConditions.clientIdStart = this.searchConditions.clientIdStart;
      this.searchResultDispConditions.clientIdEnd = this.searchConditions.clientIdEnd;
      this.searchResultDispConditions.billingOutputClass = this.searchConditions.billingOutputClass;
      this.searchResultDispConditions.siteIdStart = this.searchConditions.siteIdStart;
      this.searchResultDispConditions.siteIdEnd = this.searchConditions.siteIdEnd;
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      this.busy = true;
      try {
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null) {
          this.saveSearchConditions();
          await this.setResult(resultData);
          //console.log(resultData);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 印刷チェックを初期化
      this.checkedClientIds = [];
      this.totalCheckCnt = 0;
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          clientId: result[i].client_id,
          clientNameKanji: result[i].client_name_kanji,
          billingOutputClass: result[i].billing_output_class,
          staffId: result[i].staff_id,
          staffNameNameKanji: result[i].staff_name_kanji,
          productSalesUnitAmount: Number(result[i].product_sales_unit_amount),
          productTax: Number(result[i].closing_date_normal_tax) + Number(result[i].closing_date_light_tax),
          paymentScheduled: formatDate(result[i].payment_scheduled),
          businessClass: getListValue(Const.businessClassList, result[i].business_class.toString()),
          billingClass: result[i].billing_class,
          operation: result[i],
        };
        this.itemList.push(searchResult);
        // チェックを保持する配列に取引先コードを入れる（請求書出力区分が検索条件と等しい場合）
        if (result[i].billing_output_class == this.searchResultDispConditions.billingOutputClass) {
          this.checkedClientIds.push(result[i].client_id);
          this.totalCheckCnt++;
        }
      }
      //console.log(this.itemList);
      // 全チェックをON
      this.isAllChecked = true;
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_normal_tax';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_light_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.searchConditions.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.searchConditions.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.searchConditions.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.searchConditions.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',clients.billing_output_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = cumulative_transaction.client_class ';
      selectSql += 'AND clients.client_id = cumulative_transaction.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'cumulative_transaction.billing_date BETWEEN \'' + this.searchConditions.billingStartDate + '\' AND \'' + this.searchConditions.billingEndDate + '\' ';
      selectSql += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.product_tax <> 0) ';
      selectSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql += 'AND clients.closing_date = ' + this.searchConditions.closingDate + ' ';
      // 得意先コード
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        selectSql += 'AND cumulative_transaction.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        selectSql += 'AND cumulative_transaction.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        selectSql += 'AND cumulative_transaction.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += 'cumulative_transaction.client_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.client_id ';

      return selectSql;
    },
    /* 請求書PDF出力ボタンの押下 */
    /*
    async clickOutputBillingPdf(){
      //console.log(this.checkedClientIds);
      // 請求書PDF出力処理画面へ遷移
      let params = {
        propClosingDate: this.searchResultDispConditions.closingDate,
        propBillingMonthYear: this.searchResultDispConditions.closingYm,
        propBillingStartDate: this.searchResultDispConditions.billingStartDate,
        propBillingEndDate: this.searchResultDispConditions.billingEndDate,
        propBillingOutputClass: this.searchResultDispConditions.billingOutputClass,
        propSiteIdStart: this.searchResultDispConditions.siteIdStart == '' ? '0000' : this.searchResultDispConditions.siteIdStart,
        propSiteIdEnd: this.searchResultDispConditions.siteIdEnd == '' ? '9999' : this.searchResultDispConditions.siteIdEnd,
        propCheckedClientIds: this.checkedClientIds,
        propInvoiceInputNo: this.invoiceInputNo,
        propInvoiceIssueInputDate: this.invoiceIssueInputDate,
      };
      this.$router.push({ name: 'TENTATIVE-SEIKYU', params: params });
    },
    */
    /* 請求書PDF出力（別タブ）ボタンの押下 */
    async clickOutputBillingPdfTab() {
      //console.log(this.checkedClientIds);
      if (this.checkedClientIds.length <= Const.PdfTabSeikyuClientMaxCnt) {
        let csvClientId = '';
        for (let i = 0; i < this.checkedClientIds.length; i++) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += this.checkedClientIds[i];
        }
        // 請求書PDF出力処理画面へ遷移
        let query = {
          propClosingDate: this.searchResultDispConditions.closingDate,
          propBillingMonthYear: this.searchResultDispConditions.closingYm,
          propBillingStartDate: this.searchResultDispConditions.billingStartDate,
          propBillingEndDate: this.searchResultDispConditions.billingEndDate,
          propBillingOutputClass: this.searchResultDispConditions.billingOutputClass,
          propSiteIdStart: this.searchResultDispConditions.siteIdStart == '' ? '0000' : this.searchResultDispConditions.siteIdStart,
          propSiteIdEnd: this.searchResultDispConditions.siteIdEnd == '' ? '9999' : this.searchResultDispConditions.siteIdEnd,
          propCheckedClientIds: csvClientId,
          propInvoiceInputNo: this.invoiceInputNo,
          propInvoiceIssueInputDate: this.invoiceIssueInputDate,
        };
        let route = this.$router.resolve({ name: 'TENTATIVE-SEIKYU', query: query });
        window.open(route.href, '_blank');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2049'].replace('%arg1%', Const.PdfTabSeikyuClientMaxCnt));
        scrollTo(0,0);
      }
    },
    /* 全チェック選択 */
    clickCheckAll: function() {
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 印刷チェックを全てON
        for (let i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].billingOutputClass == this.searchResultDispConditions.billingOutputClass) {
            this.checkedClientIds.push(this.itemList[i].clientId);
          }
        }
      } else {
        // チェックOFFにした場合
        // 印刷チェックを全てOFF
        this.checkedClientIds = [];
      }
    },
    /* チェックボックス個別選択（全チェックのチェックボックスを合わせる） */
    changeCheck: function(){
      //console.log('changeCheck');
      this.isAllChecked = this.totalCheckCnt == this.checkedClientIds.length;
    },
    /* 出力区分の区分値返却処理 */
    billingOutputClassVal: function(billingOutputClass) {
      return getListValue(Const.billingOutputClassList, billingOutputClass);
    },
    /* 取引先保守ボタン押下時処理 */
    clickClientsMasterBtn: function(clientId) {
      let route = this.$router.resolve({ name: 'CLIENT-EDIT', query: { clientClass: Const.ClientClass.customer, clientId } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  }
}
</script>
<style scoped>
</style>