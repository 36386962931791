<template>
  <!-- 取引先保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 取引先保守入力</strong></h5>
                <router-link to="/client-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger mb-0" role="alert" v-if="errorMessages.length > 0">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <div class="row mt-2">
                    <!-- 取引先区分 -->
                    <div class="col-md-6 form-group">
                      <label for="clientClass" class="form-label"><strong>取引先区分</strong></label>
                      <validation-provider rules="required|oneOf:1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="clientClass" class="form-control" v-model="clientClass" :options="clientClassList" @change="changeClientClass">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 取引先コード -->
                    <div class="col-md-6 form-group">
                      <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                      <validation-provider rules="required|numeric|min:6|max:6" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientId" class="form-control" v-model="clientId" maxlength="6">
                          </div>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 取引先名（漢字） -->
                    <div class="col-md-6 form-group">
                      <label for="clientNameKanji" class="form-label"><strong>取引先名（漢字）</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientNameKanji" class="form-control" v-model="clientNameKanji" maxlength="34">
                          </div>
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 取引先名（カナ） -->
                    <div class="col-md-6 form-group">
                      <label for="clientNameKana" class="form-label"><strong>取引先名（カナ）</strong></label>
                      <validation-provider rules="required|max:40" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="clientNameKana" class="form-control" v-model="clientNameKana" maxlength="40">
                          </div>
                          <small class="form-text text-muted">必須項目です。40文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 部署１ -->
                    <div class="col-md-6 form-group">
                      <label for="department1" class="form-label"><strong>部署１</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="department1" class="form-control" v-model="department1" maxlength="22">
                          </div>
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 部署２ -->
                    <div class="col-md-6 form-group">
                      <label for="department2" class="form-label"><strong>部署２</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="department2" class="form-control" v-model="department2" maxlength="22">
                          </div>
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 郵便番号 -->
                    <div class="col-md-6 form-group">
                      <label for="zipCode" class="form-label"><strong>郵便番号</strong></label>
                      <validation-provider rules="zip_code" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="zipCode" class="form-control" v-model="zipCode" maxlength="8">
                          <small class="form-text text-muted">任意項目です。「nnn-nnnn」形式で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 住所１ -->
                    <div class="col-md-6 form-group">
                      <label for="address1" class="form-label"><strong>住所１</strong></label>
                      <validation-provider rules="max:36" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="address1" class="form-control" v-model="address1" maxlength="36">
                          <small class="form-text text-muted">任意項目です。36文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- TEL -->
                    <div class="col-md-6 form-group">
                      <label for="phoneNumber" class="form-label"><strong>TEL</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="phoneNumber" class="form-control" v-model="phoneNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 住所２ -->
                    <div class="col-md-6 form-group">
                      <label for="address2" class="form-label"><strong>住所２</strong></label>
                      <validation-provider rules="max:36" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="address2" class="form-control" v-model="address2" maxlength="36">
                          <small class="form-text text-muted">任意項目です。36文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- FAX -->
                    <div class="col-md-6 form-group">
                      <label for="faxNumber" class="form-label"><strong>FAX</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="faxNumber" class="form-control" v-model="faxNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-md-6 form-group" v-if="clientClass === clientClassClient">
                      <label for="staffName" class="form-label"><strong>担当者</strong></label>
                      <div class="input-group">
                        <input type="text" id="staffName" class="form-control" v-model="staffName" readonly>
                        <!-- 担当者検索ボタン -->
                        <b-input-group-text @click="showStaffSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showStaffSearchModal" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </div>
                      <small class="form-text text-muted">必須項目です。</small>
                      <validation-provider rules="required|numeric|max:4|between:0,9999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="staffId" v-model="staffId">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- チェック内容 -->
                    <div class="col-md-6 form-group">
                      <label for="checkContents" class="form-label"><strong>チェック内容</strong></label>
                      <validation-provider rules="max:22" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="checkContents" class="form-control" v-model="checkContents" maxlength="22">
                          <small class="form-text text-muted">任意項目です。22文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 最新現場コード -->
                    <div class="col-md-6 form-group">
                      <label for="latestSiteName" class="form-label"><strong>最新現場コード</strong></label>
                      <input type="text" id="latestSiteName" class="form-control" v-model="latestSiteName" readonly>
                      <small class="form-text text-muted">設定不要です。</small>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 組合コード -->
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="unionId" class="form-label"><strong>組合コード</strong></label>
                      <validation-provider rules="required|numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="unionId" class="form-control" v-model="unionId" maxlength="6">
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 伝票種類 -->
                    <div class="col-md-6 form-group">
                      <label for="billingTypeClass" class="form-label" :title="htmlConst.BillingTypeClassTitle"><strong>伝票種類 <span class="oi oi-flag"/></strong></label>
                      <validation-provider rules="required|oneOf:0,1,2,3,4" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingTypeClass" class="form-control" v-model="billingTypeClass" :options="billingTypeClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 発注書回数 -->
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="orderCountClass" class="form-label"><strong>発注書回数</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="orderCountClass" class="form-control" v-model="orderCountClass" :options="orderCountClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 値引率 -->
                    <div class="col-md-6 form-group">
                      <label for="discountRate" class="form-label"><strong>値引率</strong></label>
                      <validation-provider rules="required|numeric|max:2|between:0,99" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <b-form-input type="number" id="discountRate" v-model.number="discountRate" maxlength="2"/><span class="ml-3 d-flex align-items-center">%</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- サービス区分 -->
                    <div class="col-md-6 form-group">
                      <label for="serviceOrderClass" class="form-label"><strong>サービス区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="serviceOrderClass" class="form-control" v-model="serviceOrderClass" :options="serviceOrderClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- 発注条件 -->
                    <div class="col form-group">
                      <label for="orderConditionQuantity" class="form-label"><strong>発注条件</strong></label>
                      <b-table
                        table-class="datatable"
                        :hover="false"
                        :head-variant="headVariant"
                        :responsive="true"
                        :items="orderCondition"
                        :fields="orderConditionTableFields"
                        :bordered="true"
                      >
                        <!-- 発注条件 数量 -->
                        <template #cell(orderConditionQuantity)="data">
                          <validation-provider rules="required|numeric|max:8|between:0,99999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input :readonly="sundriesClass == 1" type="text" id="orderConditionQuantity" class="form-control" v-model="data.item.orderConditionQuantity" maxlength="8">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </template>
                        <!-- 発注条件 金額 -->
                        <template #cell(orderConditionAmount)="data">
                          <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input :readonly="sundriesClass == 1" type="text" id="orderConditionAmount" class="form-control" v-model="data.item.orderConditionAmount" maxlength="10">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 締日 -->
                    <div class="col-md-6 form-group">
                      <label for="closingDate" class="form-label"><strong>締日</strong></label>
                      <validation-provider rules="required|numeric|oneOf:5,10,15,20,25,99" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="closingDate" class="form-control" v-model="closingDate" :options="closingDateList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 請求書出力単位区分 -->
                    <div class="col-md-6 form-group">
                      <label for="billingOutputClass" class="form-label"><strong>請求書出力単位区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingOutputClass" class="form-control" v-model="billingOutputClass" :options="billingOutputClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 請求書区分 -->
                    <div class="col-md-6 form-group">
                      <label for="billingClass" class="form-label"><strong>請求書区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="billingClass" class="form-control" v-model="billingClass" :options="billingClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 前回請求締切日 -->
                    <div class="col-md-6 form-group">
                      <label for="previousBillingClosingDate" class="form-label"><strong>前回請求締切日</strong></label>
                      <validation-provider rules="regex:[0-9]{4}-[0-1][0-9]-[0-3][0-9]" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-input-group>
                            <b-form-datepicker id="previousBillingClosingDate" class="form-control" v-model="previousBillingClosingDate"
                              locale="ja"
                              :hide-header = true
                              calendar-width="50%"
                              today-button
                              reset-button
                              close-button
                            ></b-form-datepicker>
                            <b-input-group-append>
                              <b-button size="sm" variant="outline-secondary" @click="previousBillingClosingDate=''">
                                <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="form-text text-muted">任意項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 取引区分 -->
                    <div class="col-md-6 form-group">
                      <label for="businessClass" class="form-label"><strong>取引区分</strong></label>
                      <validation-provider rules="required|oneOf:1,2,3,4" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="businessClass" class="form-control" v-model="businessClass" :options="businessClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 相殺相手先 -->
                    <div class="col-md-6 form-group">
                      <label for="offsetClientName" class="form-label"><strong>相殺相手先</strong></label>
                      <div class="input-group">
                        <input type="text" id="offsetClientName" class="form-control" v-model="offsetClientName" readonly>
                        <!-- 取引先検索ボタン -->
                        <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </div>
                      <small class="form-text text-muted">必須項目です。</small>
                      <validation-provider rules="required|numeric|max:6|min_value:0|max_value:999999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="hidden" id="offsetClientId" v-model="offsetClientId">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 入金（支払）区分 -->
                    <div class="col-md-6 form-group">
                      <label for="paymentClass" class="form-label"><strong>入金（支払）区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2,3" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="paymentClass" class="form-control" v-model="paymentClass" :options="paymentClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 入金（支払）予定日 -->
                    <div class="col-md-6 form-group">
                      <label for="paymentScheduled" class="form-label"><strong>入金（支払）予定日</strong></label>
                      <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="paymentScheduled" class="form-control" v-model="paymentScheduled" :options="paymentDateList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 売上課税区分 -->
                    <div class="col-md-6 form-group">
                      <label for="salesTaxClass" class="form-label"><strong>売上課税区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1,2" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="salesTaxClass" class="form-control" v-model="salesTaxClass" :options="salesTaxClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 税額計算区分 -->
                    <div class="col-md-6 form-group">
                      <label for="taxCalculationClass" class="form-label"><strong>税額計算区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select id="taxCalculationClass" class="form-control" v-model="taxCalculationClass" :options="taxCalculationClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="clientClass === clientClassSupplier">
                    <!-- 振り込み手数料 -->
                    <div class="col-md-6 form-group">
                      <label for="transferFee" class="form-label"><strong>振り込み手数料</strong></label>
                      <validation-provider rules="required|numeric|max:10|min_value:0|max_value:2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee" class="form-control" v-model="transferFee" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[0].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 振り込み手数料２ -->
                    <div class="col-md-6 form-group">
                      <label for="transferFee2" class="form-label"><strong>振り込み手数料２</strong></label>
                      <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee2" class="form-control" v-model="transferFee2" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[1].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6 form-group" v-show="clientClass === clientClassSupplier">
                      <label for="transferFee3" class="form-label"><strong>振り込み手数料３</strong></label>
                      <validation-provider rules="required|numeric|max:10|between:0,2147483647" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="transferFee3" class="form-control" v-model="transferFee3" maxlength="10"><span class="ml-3 d-flex align-items-center">円</span>
                          </div>
                          <small class="form-text text-muted">必須項目です。{{transferFeeList[2].text}}</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- WEB発行区分 -->
                    <div class="col-md-6 form-group" v-if="clientClass == clientClassClient">
                      <label for="webIssueClass" class="form-label"><strong>WEB発行区分</strong></label>
                      <b-select
                        id="webIssueClass"
                        class="form-control"
                        v-model="webIssueClass"
                        :options="webIssueClassList"
                        value-field="id"
                      >
                      </b-select>
                    </div>
                  </div>
                  <div v-if="clientClass === clientClassSupplier" class="row mt-2">
                    <!-- 取引先サンワ顧客コード -->
                    <div class="col-12 form-group">
                      <label class="form-label"><strong>取引先サンワ顧客コード</strong></label>
                      <b-row>
                        <!-- 1ページあたりの表示選択 -->
                        <b-col lg="6" class="my-1">
                          <b-form-group
                            label="1ページあたりの表示件数"
                            label-for="per-page-select"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1"
                          >
                            <b-form-select id="per-page-select" v-model="suppliersOfficesTablePerPage" :options="suppliersOfficesTablePageOptions" size="sm"></b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 検索結果検索 -->
                        <b-col lg="6" class="my-1">
                          <b-form-group
                            label="Filter"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-1"
                          >
                            <b-input-group size="sm">
                              <b-form-input id="filter-input" v-model="suppliersOfficesTableFilter" type="search"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <b-table
                            id="suppliersOffices"
                            show-empty
                            :head-variant="headVariant"
                            :responsive="true"
                            :items="suppliersOffices"
                            :fields="suppliersOfficesTableFields"
                            :busy="suppliersOfficesTableBusy"
                            :filter="suppliersOfficesTableFilter"
                            :per-page="suppliersOfficesTablePerPage"
                            :current-page="suppliersOfficesTableCurrentPage"
                            :bordered="true"
                            :empty-text="emptyText"
                            :empty-filtered-text="emptyFilterdText"
                            @filtered="onSuppliersOfficesTableFiltered"
                          >
                            <template #cell(officeId)="data">
                              {{String(data.item.officeId).padStart(2, '0')}}
                            </template>
                            <template #cell(supplierControlOfficeId)="data">
                              <validation-provider rules="numeric|max:10|min_value:0|max_value:2147483647" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input v-model="data.item.supplierControlOfficeId" size="sm" length="10"></b-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group
                            :label="getSuppliersOfficesTablePagingMessage"
                            class="mt-0 mb-0"
                          />
                        </b-col>
                      </b-row>
                      <!-- テーブルページネーション -->
                      <b-row>
                        <b-col class="my-1">
                          <b-pagination
                            v-model="suppliersOfficesTableCurrentPage"
                            :total-rows="suppliersOfficesTableFilter != null ? suppliersOfficesTableFilterRows : suppliersOffices.length"
                            :per-page="suppliersOfficesTablePerPage == -1 ? suppliersOffices.length : suppliersOfficesTablePerPage"
                            align="center"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <b-button class="btn-block" pill variant="success" @click="onSaveButtonClick"><span class="oi oi-circle-check"></span> 保存</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●担当者マスタ検索モーダル●●● -->
    <StaffSearch @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="offsetClientClass"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateInsertSql, isSystemEditable, offsetClientIdRelation, checkOffsetClientIdFaild, getNullStr } from '@/assets/js/common.js';
import StaffSearch from '@/components/modal/staff-search.vue';
import ClientSearch from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_clients, list_m_offices } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'client-input';

export default {
  name: 'CLIENT-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    StaffSearch,
    ClientSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先保守入力',
      clientClassClient: Const.ClientClassList[0].value,
      clientClassSupplier: Const.ClientClassList[1].value,
      // 取引先区分リスト
      clientClassList: Const.ClientClassList,
      // 伝票種類区分リスト
      billingTypeClassList: Const.BillingTypeClassList,
      // 発注書回数区分リスト
      orderCountClassList: Const.OrderCountClassList,
      // サービス発注区分リスト
      serviceOrderClassList: Const.ServiceOrderClassList,
      // 締日リスト
      closingDateList: Const.closingDateList.filter(item => item.value !== ''),
      // 請求書出力単位区分リスト
      billingOutputClassList: Const.BillingOutputClassList,
      // 請求書区分リスト
      billingClassList: Const.BillingClassList,
      // 取引区分リスト
      businessClassList: Const.BusinessClassList,
      // 入金区分リスト
      paymentClassList: Const.PaymentClassList,
      // 入金予定日リスト
      paymentDateList: Const.PaymentDateList,
      // 売上課税区分リスト
      salesTaxClassList: Const.SalesTaxClassList,
      // 税額計算区分リスト
      taxCalculationClassList: Const.TaxCalculationClassList,
      headVariant: DataTblDef.headerVariant,
      orderConditionTableFields: DataTblDef.clients_order_condition_fields,
      suppliersOfficesTableFields: DataTblDef.clients_suppliers_offices_fields,
      suppliersOfficesTableBusy: false,
      suppliersOfficesTableFilter: null,
      // フィルタリングデータの総件数
      suppliersOfficesTableFilterRows: 0,
      // 表示件数のdefault値
      suppliersOfficesTablePerPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      suppliersOfficesTablePageOptions: DataTblDef.pageOptions,
      // ページネーションの初期表示位置
      suppliersOfficesTableCurrentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // 取引先区分
      clientClass: '',
      // 取引先コード
      clientId: '',
      // 取引先名（漢字）
      clientNameKanji: '',
      // 取引先名（カナ）
      clientNameKana: '',
      // 部署１
      department1: '',
      // 部署２
      department2: '',
      // 郵便番号
      zipCode: '',
      // 住所１
      address1: '',
      // 住所２
      address2: '',
      // TEL
      phoneNumber: '',
      // FAX
      faxNumber: '',
      // 担当者コード
      staffId: '',
      // 担当者名
      staffName: '',
      // 最新現場コード
      latestSiteId: '0',
      // 最新現場名
      latestSiteName: '000000：該当なし',
      // チェック内容
      checkContents: '',
      // 諸口区分（新規登録では0固定となり、これまで「1:諸口」の条件にはならなくなる）
      sundriesClass: 0,
      // 締日
      closingDate: '',
      // 請求書区分
      billingClass: '',
      // 前回請求締切日
      previousBillingClosingDate: '',
      // 取引区分
      businessClass: '',
      // 相殺相手先コード
      offsetClientId: '0',
      // 相殺相手先名
      offsetClientName: '000000：該当なし',
      // 請求書出力単位区分
      billingOutputClass: '',
      // 入金（支払）区分
      paymentClass: '',
      // 入金（支払）予定日
      paymentScheduled: '',
      // 売上課税区分
      salesTaxClass: '',
      // 税額計算区分
      taxCalculationClass: '',
      // 税額端数処理区分
      taxFractionationClass: Const.TaxFractionationClass.truncate,
      // 伝票種類区分
      billingTypeClass: '',
      // 組合コード
      unionId: '0',
      // サービス発注区分
      serviceOrderClass: '',
      orderCondition: [
        {
          // 発注条件（数量）
          orderConditionQuantity: '',
          // 発注条件（金額）
          orderConditionAmount: '',
        }
      ],
      // 値引率
      discountRate: 0,
      // 発注書回数区分
      orderCountClass: '',
      // 振り込み手数料
      transferFee: '',
      transferFee2: '',
      transferFee3: '',
      transferFeeList: Const.transferFee,
      // WEB発行区分
      webIssueClass: Const.WebIssueClass.noIssue,
      webIssueClassList: Const.WebIssueClassList,
      // 取引先サンワ顧客コード
      suppliersOffices: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種類吹き出し
        BillingTypeClassTitle: Const.BillingTypeClassTitle,
      },
    }
  },
  computed: {
    /**
     * ページの表示件数
     */
    getSuppliersOfficesTablePagingMessage() {
      const tableLength = (this.suppliersOfficesTableFilter != null) ? this.suppliersOfficesTableFilterRows : this.suppliersOffices.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.suppliersOfficesTablePerPage !== -1) {
        end = this.suppliersOfficesTableCurrentPage * this.suppliersOfficesTablePerPage;
        start = end - this.suppliersOfficesTablePerPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    },
    // 取引先区分に対して相殺相手先区分
    offsetClientClass() {
      // 取引先区分と相殺相手区分は逆
      switch (Number(this.clientClass)) {
      case Const.ClientClass.customer:
        return Const.ClientClass.supplier
      case Const.ClientClass.supplier:
        return Const.ClientClass.customer
      default:
        return ''
      }
    }
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    async setSuppliersOffices() {
      const functionName = 'setSuppliersOffices';
      let resp = null;
      try {
        resp = await API.graphql(graphqlOperation(list_m_offices));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (resp.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          result: resp
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      for (const office of resp.data.list_m_offices) {
        this.suppliersOffices.push({
          officeId: office.office_id,
          officeNameKanji: office.office_name_kanji,
          supplierControlOfficeId: ''
        });
      }
    },
    /**
     * 取引先区分が変更された場合、担当者コード、相殺相手先コード、取引先サンワ顧客コードを
     * クリアします。
     */
    async changeClientClass() {
      this.staffId = '';
      this.staffName = '';
      this.offsetClientId = 0;
      this.offsetClientName = '0：該当なし';
      this.webIssueClass = Const.WebIssueClass.noIssue;
      if (this.clientClass === this.clientClassClient) {
        this.unionId = '0';
        this.serviceOrderClass = '0';
        this.orderCountClass = '0';
        this.transferFee = '0';
        this.transferFee2 = '0';
        this.transferFee3 = '0';
        this.orderCondition[0].orderConditionQuantity = '0';
        this.orderCondition[0].orderConditionAmount = '0';
        this.suppliersOffices = [];
      } else {
        this.unionId = '0';
        this.serviceOrderClass = '';
        this.orderCountClass = '';
        this.transferFee = '';
        this.transferFee2 = '';
        this.transferFee3 = '';
        this.orderCondition[0].orderConditionQuantity = '';
        this.orderCondition[0].orderConditionAmount = '';
        await this.setSuppliersOffices();
      }
    },
    /**
     * 担当者検索モーダルを表示します。 
     */
    showStaffSearchModal() {
      this.$bvModal.show('staffSearchModal');
    },
    /**
     * 担当者検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeStaffSearchModal(choiceData) {
      this.staffId = choiceData.detail.staff_id;
      this.staffName = `${choiceData.detail.staff_id}：${choiceData.detail.staff_name_kanji}`;
    },
    /**
     * 取引先検索モーダルを表示します。
     */
    showClientSearchModal() {
      if (this.clientClass === '') {
        this.$bvModal.msgBoxOk('取引先区分を選択してください。');
      } else {
        this.$bvModal.show('clientSearchModal');
      }
    },
    /**
     * 取引先検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeClientSearchModal(choiceData) {
      this.offsetClientId = choiceData.detail.client_id;
      this.offsetClientName = `${choiceData.detail.client_id}：${choiceData.detail.client_name_kanji}`;
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onSuppliersOfficesTableFiltered(filteredItems) {
      this.suppliersOfficesTableFilterRows= filteredItems.length;
      this.suppliersOfficesTableCurrentPage= DataTblDef.currentPage;
    },
    /**
     * 保存ボタンクリックイベント処理
     */
    async onSaveButtonClick() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        // 入力チェックが完了した場合、保存確認ダイアログを表示
        await this.saveConfirm();
      } else {
        // 入力エラーがあった場合はエラーの項目へスクロール
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length > 0) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      await this.execInsert();
      if (this.errorMessages.length > 0) {
        // INSERT中にエラーが発生した場合は画面の先頭へスクロール
        scrollTo(0,0);
      } else {
        // INSERTに成功した場合は編集画面へ遷移
        this.$router.push({
          name: 'CLIENT-EDIT',
          query: {
            clientClass: Number(this.clientClass),
            clientId: Number(this.clientId),
          },
          params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] }  
        });
      }
      //console.log('保存処理終了');
    },
    /**
     * 登録処理
     */
    async execInsert() {
      const functionName = 'execInsert';

      // SQL文を作成
      const sqls = [];
      sqls.push(await this.createInsertMClientsSQL());
      //console.log(this.suppliersOffices);
      if (this.clientClass === this.clientClassSupplier) {
        for (const supplierOffice of this.suppliersOffices) {
          if (supplierOffice.supplierControlOfficeId !== '') {
            sqls.push(await this.createInsertMSuppliersOffices(supplierOffice));
          }
        }
      }

      // 重複チェック
      const whereClause = `AND client_class = ${this.clientClass} AND client_id = ${this.clientId}`;
      let listMClientsResult = null;
      try {
        listMClientsResult = await API.graphql(graphqlOperation(list_m_clients, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (listMClientsResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause,
          result: listMClientsResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (listMClientsResult.data.list_m_clients.length !== 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2004']);
        return;
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 相殺相手先変更する場合
      if (Number(this.offsetClientId) != 0) {
        // 相殺相手先すでに登録、判断
        if (await checkOffsetClientIdFaild(this.clientClass, this.offsetClientId)) {      
          this.errorMessages.push(DISP_MESSAGES.DANGER['3008']);
          return
        }
        // 新相殺相手先側の相殺相手先を更新する
        const offsetClientIdRelationSql = await offsetClientIdRelation(this.clientClass, this.clientId, this.offsetClientId, this.$store.getters.user.username, 'insert')
        sqls.push(offsetClientIdRelationSql)
      }

      // SQL実行
      let executeTransactSqlResult = null;
      try {
        executeTransactSqlResult = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (executeTransactSqlResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: executeTransactSqlResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      const body = JSON.parse(executeTransactSqlResult.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        'result.data.executeTransactSql': {
          statusCode: executeTransactSqlResult.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    async createInsertMClientsSQL() {
      const colList = [];
      // 取引先区分
      colList.push(CreateColRow('client_class', this.clientClass, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', this.clientId, 'NUMBER'));
      // 取引先分類コード
      colList.push(CreateColRow('client_id_first_digit', this.clientId.length === 6 ? this.clientId.substring(0 ,1) : '0', 'NUMBER'));
      // 取引先名（漢字）
      if (getNullStr(this.clientNameKanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('client_name_kanji', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.clientNameKanji), 'VARCHAR'));
      }
      // 取引先名（カナ）
      colList.push(CreateColRow('client_name_kana', await escapeQuote(this.clientNameKana), 'VARCHAR'));
      // 部署１
      colList.push(CreateColRow('department_1', await escapeQuote(this.department1), 'VARCHAR'));
      // 部署２
      colList.push(CreateColRow('department_2', await escapeQuote(this.department2), 'VARCHAR'));
      // 郵便番号
      colList.push(CreateColRow('zip_code', this.zipCode, 'VARCHAR'));
      // 住所１
      colList.push(CreateColRow('address_1', await escapeQuote(this.address1), 'VARCHAR'));
      // 住所２
      colList.push(CreateColRow('address_2', await escapeQuote(this.address2), 'VARCHAR'));
      // TEL
      colList.push(CreateColRow('phone_number', this.phoneNumber, 'VARCHAR'));
      // FAX
      colList.push(CreateColRow('fax_number', this.faxNumber, 'VARCHAR'));
      // 担当者コード
      const staffId = (this.clientClass === this.clientClassClient) ? this.staffId : 0;
      colList.push(CreateColRow('staff_id', staffId, 'NUMBER'));
      // 最新現場コード
      colList.push(CreateColRow('latest_site_id', 0, 'NUMBER'));
      // チェック内容
      colList.push(CreateColRow('check_contents', await escapeQuote(this.checkContents), 'VARCHAR'));
      // 諸口区分（得意先でも仕入先でも固定値（諸口取引先は過去データのみ））
      colList.push(CreateColRow('sundries_class', '0', 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', this.closingDate, 'NUMBER'));
      // 請求書区分
      colList.push(CreateColRow('billing_class', this.billingClass, 'NUMBER'));
      // 前回請求締切日
      if (this.previousBillingClosingDate !== '') {
        colList.push(CreateColRow('previous_billing_closing_date', this.previousBillingClosingDate, 'DATE'));
      }
      // 取引区分
      colList.push(CreateColRow('business_class', this.businessClass, 'NUMBER'));
      // 相殺相手先コード
      colList.push(CreateColRow('offset_client_id', this.offsetClientId, 'NUMBER'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', this.billingOutputClass, 'NUMBER'));
      // 入金（支払）区分
      colList.push(CreateColRow('payment_class', this.paymentClass, 'NUMBER'));
      // 入金（支払）予定日
      colList.push(CreateColRow('payment_scheduled', this.paymentScheduled, 'NUMBER'));
      // 売上課税区分
      colList.push(CreateColRow('sales_tax_class', this.salesTaxClass, 'NUMBER'));
      // 税額計算区分
      colList.push(CreateColRow('tax_calculation_class', this.taxCalculationClass, 'NUMBER'));
      // 税額端数処理区分
      colList.push(CreateColRow('tax_fractionation_class', this.taxFractionationClass, 'NUMBER'));
      // 伝票種類区分
      colList.push(CreateColRow('billing_type_class', this.billingTypeClass, 'NUMBER'));
      if (this.clientClass === this.clientClassClient) {
        // 得意先の場合は固定値
        // 組合コード
        colList.push(CreateColRow('union_id', '0', 'NUMBER'));
        // サービス発注区分
        colList.push(CreateColRow('service_order_class', '0', 'NUMBER'));
        // 発注条件 数量
        colList.push(CreateColRow('order_condition_quantity', '0', 'NUMBER'));
        // 発注条件 金額
        colList.push(CreateColRow('order_condition_amount', '0', 'NUMBER'));
      } else {
        // 組合コード
        colList.push(CreateColRow('union_id', this.unionId, 'NUMBER'));
        // サービス発注区分
        colList.push(CreateColRow('service_order_class', this.serviceOrderClass, 'NUMBER'));
        // 発注条件 数量
        colList.push(CreateColRow('order_condition_quantity', this.orderCondition[0].orderConditionQuantity, 'NUMBER'));
        // 発注条件 金額
        colList.push(CreateColRow('order_condition_amount', this.orderCondition[0].orderConditionAmount, 'NUMBER'));
      }
      // 値引率
      colList.push(CreateColRow('discount_rate', this.discountRate, 'NUMBER'));
      if (this.clientClass === this.clientClassClient) {
        // 得意先の場合は固定値
        // 発注書回数区分
        colList.push(CreateColRow('order_count_class', '0', 'NUMBER'));
        // 振り込み手数料
        colList.push(CreateColRow('transfer_fee', '0', 'NUMBER'));
        // 振り込み手数料２
        colList.push(CreateColRow('transfer_fee_2', '0', 'NUMBER'));
        // 振り込み手数料３
        colList.push(CreateColRow('transfer_fee_3', '0', 'NUMBER'));
      } else {
        // 発注書回数区分
        colList.push(CreateColRow('order_count_class', this.orderCountClass, 'NUMBER'));
        // 振り込み手数料
        colList.push(CreateColRow('transfer_fee', this.transferFee, 'NUMBER'));
        // 振り込み手数料２
        colList.push(CreateColRow('transfer_fee_2', this.transferFee2, 'NUMBER'));
        // 振り込み手数料３
        colList.push(CreateColRow('transfer_fee_3', this.transferFee3, 'NUMBER'));
      }
      // WEB発行区分
      colList.push(CreateColRow('web_issue_class', this.webIssueClass, 'NUMBER'));
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('created_user', username, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      return CreateInsertSql(colList, 'full', 'm_clients')
    },
    async createInsertMSuppliersOffices(supplierOffice) {
      const colList = [];
      // 仕入先コード
      colList.push(CreateColRow('supplier_id', this.clientId, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', supplierOffice.officeId, 'NUMBER'));
      // 相手先営業所コード
      colList.push(CreateColRow('supplier_control_office_id', supplierOffice.supplierControlOfficeId, 'NUMBER'));
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('created_user', username, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      return CreateInsertSql(colList, 'full', 'm_suppliers_offices')
    },
  }
}
</script>
