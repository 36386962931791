<template>
  <!-- 在庫保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <span class="oi oi-magnifying-glass"></span> 検索条件
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 営業所選択欄 -->
                        <div class="col-md-3 form-group">
                          <label for="officeId" class="form-label">営業所</label>
                          <b-select id="officeId" class="form-control" v-model="searchConditions.officeId" :options="constOfficeList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                        </div>
                        <!-- 処理年月 入力欄 -->
                        <div class="col-md-5 form-group">
                          <validation-provider name="monthYear" :rules="{regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                            <b-form-group label="処理年月">
                              <b-row>
                                <b-col lg="8" :class="classes">
                                  <b-input-group>
                                    <b-form-input name="monthYear" type="month" v-model="searchConditions.monthYear" />
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.monthYear=null">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="8" :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <!-- 製品コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="productId" class="form-label">製品コード</label>
                          <validation-provider name="productId" rules="numeric|max:8|between:0,99999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="productId" name="productId" class="form-control" v-model="searchConditions.productId" maxlength="8">
                              <small class="form-text text-muted">前方一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 製品名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="productName" class="form-label">製品名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="productName" name="productName" class="form-control" v-model.trim="searchConditions.productName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの製品名（漢字）または製品名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="stocksList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- printイベント用のレイアウト -->
                  <template v-if="printStatus" #cell(product_name_kanji)="data">
                    <div style="max-width:800px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.product_name_kanji }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="printStatus" #cell(product_name_kana)="data">
                    <div style="max-width:800px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.product_name_kana }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">      
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <b-button size="sm" @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : stocksList.length"
                :per-page="perPage == -1 ? stocksList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init, setOfficeListOption } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'stocks-maintenance';

export default {
  name: 'STOCKS-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '在庫保守',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        productId: '',
        productName: '',
        officeId: 0,
        monthYear: null,
      },
      // 検索結果
      resultError: [],
      //  在庫リスト
      stocksList: [],
      fields: DataTblDef.stocks_list_fields,
      busy: false,
      filter: null,
      // 営業所選択option
      constOfficeList: [],
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.stocksList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData(){
      // 最新営業所データをリストに更新
      await setOfficeListOption()
      this.constOfficeList = this.$store.getters.officeOption
      // 営業所データ初期値セット
      this.searchConditions.officeId = this.$store.getters.user.office_id
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} listStocksConditions - 検索条件
     */
    async search(listStocksConditions) {
      this.stocksList = [];
      let listStocks = null;
      if (listStocksConditions !== null && listStocksConditions.length > 0) {
        listStocks = await this.searchStocks(listStocksConditions)
        if (listStocks == null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } 
        listStocks.forEach(stock => {
          this.stocksList.push(
            {
              product_id: stock.product_id,
              product_name_kanji: stock.product_name_kanji,
              product_name_kana: stock.product_name_kana,
              office_id: stock.office_id,
              month_year: stock.month_year,
              office_name_kanji: this.searchOfficeName(stock.office_id),
            }
          )
        });
      }
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.stocksList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /**
     * 営業所名検索
     * @param {Int} office_id - 営業所ID
     * @returns {String} 営業所名
     */
    searchOfficeName(office_id) {
      const office = this.constOfficeList.find((office) => {
        return office.value === office_id
      })
      if (office != undefined) {
        return office.text
      }
    },
    /**
     * 在庫マスタ検索
     * @param {String} where_clause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchStocks(where_clause) {
      const functionName = 'searchStocks';

      let result = null;
      // 在庫と製品の結合
      let SQLs = `SELECT  
                  MP.product_name_kanji,
                  MP.product_name_kana,
                  MS.product_id,
                  MS.month_year,
                  MS.office_id
                  FROM m_stocks MS LEFT JOIN m_products MP USING(product_id) 
                  WHERE ${where_clause.join(' AND ')}  ORDER BY MS.updated DESC LIMIT 1000`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0]
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 製品コード
        let listStocksConditions = [];
        if (this.searchConditions.productId !== '') {
          listStocksConditions.push(`product_id LIKE '${this.searchConditions.productId}%' `);
        }
        // 製品名(あいまい検索対象)
        if (this.searchConditions.productName !== '') {
          const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
          let nameConditions = `(REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `
          nameConditions += `OR REPLACE(REPLACE(CONVERT(product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', ''))`
          listStocksConditions.push(nameConditions);
        }
        // 営業所
        if (this.searchConditions.officeId != 0) {
          const officeId = Number(this.searchConditions.officeId);
          listStocksConditions.push(`office_id = ${officeId} `);
        }
        // 処理年月
        if (this.searchConditions.monthYear != null && this.searchConditions.monthYear != '') {
          const monthYear = this.searchConditions.monthYear.replace(/-/, '');
          listStocksConditions.push(`month_year = ${monthYear} `);
        }
        // 検索条件がない場合、初期画面を出す
        if (listStocksConditions.length === 0) {
          listStocksConditions.push('1 = 1')
        }
        await this.search(listStocksConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'STOCKS-INQUIRY', query: 
      { 
        product_id: item.product_id, 
        office_id: item.office_id,
        month_year: item.month_year,
      }});
      window.open(route.href, '_blank');
    },
  }
};
</script>