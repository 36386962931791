<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                <strong> 発注修正
                  <span class="oi oi-flag" v-b-tooltip.hover.noninteractive title="編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。"></span>
                </strong></h5>
              <b-button pill v-b-tooltip.hover.noninteractive.bottom="'見積・発注一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                <span class="oi oi-circle-x"></span> キャンセル
              </b-button>
            </div>
          </b-media-body>
        </b-media>
        <div class="main-card mb-3 card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                  <!-- 発注番号 -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="orderId">
                          <b-input-group-text  class="px-1">
                            <strong class='text-body'>発注番号 </strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="orderId" type="text" v-model="orderId" readonly />
                    </b-input-group>
                  </div>
                  <!-- 営業所コードプルダウン -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="selectSalesOffice">
                          <b-input-group-text  class="px-1">
                            <strong class='text-body'>営業所 </strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                    </b-input-group>
                  </div>
                  <!-- 発注日付 -->
                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                    <validation-provider name="dateOrderDate" :rules="{required: true}" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="dateOrderDate">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>発注日付 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-datepicker
                          id="dateOrderDate"
                          name="dateOrderDate"
                          :class="{'border-danger': errors[0]}"
                          v-model="dateOrderDate"
                          calendar-width="50%"
                          class="mb-2"
                        ></b-form-datepicker>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>    
                      </b-input-group>
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先コード -->
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5 form-group px-1">
                    <validation-provider name="suppliersCode" rules="numeric|min:6|max:6" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='検索ボタンから選択してください。情報ボタンは入力されたコードに紐づく詳細情報を表示します。'>
                              <strong class='text-body'>取引先コード <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input
                          id="suppliersCode"
                          type="text"
                          :class="{'border-danger': errors[0]}"
                          @input="searchClient(changeEstimateBrankId(suppliers.code, false))" 
                          maxlength="6"
                          v-model="suppliers.code"
                        />
                        <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                        <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空白以外の場合、利用可能です。）" :disabled="suppliers.code == ''" @click="clientInfoModal(suppliers.class, suppliers.code)">
                          <span class="oi oi-eye"></span> 情報
                        </b-button>
                        <b-button class="mr-1" size="sm" pill variant="success" title="取引先をクリアします。" @click="clientClear()">
                          <span class="oi oi-circle-x"></span> クリア
                        </b-button>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先名 -->
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-6 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="suppliersName">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='発注登録時点の取引先名です。帳票に出力する取引先は「取引先印刷用宛名」において設定してください'>
                            <strong class='text-body'>取引先名 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="suppliersName" type="text" v-model="suppliers.name" readonly />
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先印刷用宛名 -->
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-6 form-group px-1">
                    <validation-provider name="clientPrintName" rules="max:34" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientPrintName">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「宛先」の部分に出力されます。取引先名の代わりにこちらを変更してください'>
                              <strong class='text-body'>取引先印刷用宛名 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="clientPrintName" name="clientPrintName" :class="{'border-danger': errors[0]}" type="text" v-model="suppliers.printName" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先担当者コード -->
                  <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="clientStaffId">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先設定時に自動設定されます。帳票の「担当」の部分に出力されます'>
                            <strong class='text-body'>取引先担当者 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="clientStaffId" type="text" v-model="clientStaffId" readonly />
                      <b-input-group-text @click="showStaffSearchModal(1)" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                          <span class="oi oi-magnifying-glass"></span>
                          <button type="button" v-shortkey="['alt', '2']" @shortkey="showStaffSearchModal(1)" class="d-none"></button>
                        </b-button>
                      </b-input-group-text>
                      <b-button pill size="sm" variant="success" title="取引先担当者をクリアします。" @click="clientStaffClear()">
                        <span class="oi oi-circle-x"></span> クリア
                      </b-button>
                    </b-input-group>
                  </div>
                  <!-- 作成担当者コード -->
                  <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="createdStaffId">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先担当者と異なる場合、帳票の「担当」の右側に括弧括りで出力されます'>
                            <strong class='text-body'>作成担当者 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="createdStaffId" type="text" v-model="createdStaffId" readonly />
                      <b-input-group-text @click="showStaffSearchModal(2)" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                          <span class="oi oi-magnifying-glass"></span>
                          <button type="button" v-shortkey="['alt', '3']" @shortkey="showStaffSearchModal(2)" class="d-none"></button>
                        </b-button>
                      </b-input-group-text>
                      <b-button pill size="sm" variant="success" title="作成担当者をクリアします。" @click="createdStaffClear()">
                        <span class="oi oi-circle-x"></span> クリア
                      </b-button>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <!-- 内容 -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                    <validation-provider name="textContents" rules="max:50" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textContents">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>内容 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="textContents" name="textContents" :class="{'border-danger': errors[0]}" type="text" v-model="textContents" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                        <b-form-text class="text-muted">作成した発注の内容です<br>帳票には出力されません</b-form-text>
                      </b-input-group>
                    </validation-provider>
                  </div>
                  <!-- 摘要 -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                    <validation-provider name="textSummary" rules="max_row:3,60" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textSummary">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「摘要」の部分に出力されます'>
                              <strong class='text-body'>摘要 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-textarea id="textSummary" :class="{'border-danger': errors[0]}" rows="3" v-model="textSummary" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 製品 -->
                  <b-col lg="12">
                    <b-row>
                      <b-col lg="12">
                        <b-table
                          :items="productList"
                          :fields="fields"
                          :small="true"
                        >
                          <template #table-colgroup>
                            <col style="width:250px;"/>
                            <col style="width:250px;"/>
                            <col style="width:200px;"/>
                            <col style="width:100px;"/>
                            <col style="width:50px;"/>
                            <col style="width:40px;"/>
                          </template>
                          <template #cell(ProductCode)="data">
                            <validation-provider rules="numeric|min:8" v-slot="{ classes,errors }">
                              <div :class="classes">
                                <b-input-group style="width: 250px;">
                                  <b-form-input
                                    type="text"
                                    name="productId"
                                    maxlength="8"
                                    @input="searchProduct(changeEstimateBrankId(data.item.ProductCode, false), data.index)" 
                                    v-model="data.item.ProductCode"
                                    style="width: 110px;"
                                    size="sm"
                                    class="px-1"
                                  />
                                  <!-- 製品クリアボタン -->
                                  <b-input-group-text @click="showProductSearchModal(data.index)">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                    </b-button>
                                  </b-input-group-text>
                                  <b-button size="sm" pill variant="success" title="製品をクリアします。" @click="productClear(data.index)" class="mr-1">
                                    <span class="oi oi-circle-x"></span> クリア
                                  </b-button>
                                </b-input-group>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </div>
                            </validation-provider>
                          </template>
                          <template #cell(ProductName)="data">
                            <validation-provider :rules="{required:data.item.ProductCode=='',max:35}" v-slot="{ classes,errors }">
                              <b-input-group style="width: 250px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group>
                                      <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                      <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(Note)="data">
                            <validation-provider rules="max:30" v-slot="{ classes,errors }">
                              <b-input-group style="width: 200px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-input size="sm" class="px-1" type="text" name="productNote" v-model="data.item.Note" />
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(Quantity)="data">
                            <validation-provider rules="required|between:0,99999" v-slot="{ classes,errors }">
                              <b-input-group style="width: 100px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" />
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(Unit)="data">
                            <validation-provider rules="max:5" v-slot="{ classes,errors }">
                              <b-input-group style="width: 50px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" />
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(delete)="data">
                            <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)" class="mr-1">
                              <span class="oi oi-delete"></span> 削除
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-btn-toolbar class="mt-2">
                          <!-- 行追加ボタン -->
                          <b-button class="mr-2" pill size="sm" variant="success" title="製品行を追加します。" @click="addTableRow">
                            <span class="oi oi-plus"></span> 行追加
                          </b-button>
                        </b-btn-toolbar>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- 保存ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button  class="mr-2" pill block variant="success" title="入力された発注データを保存します。" type="submit" form="InputForm" @click="delListLastBlankRow">
                  <span class="oi oi-circle-check"></span> 保存
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.supplier"/>
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●担当者検索モーダル●●● -->
  <STAFFSEARCH @from-child="closeStaffSearchModal"/>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import { searchProductsModal, searchClientModal } from '@/assets/js/searchModal.js';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, getControlMaster, getListValue, addOperationLogs, escapeQuote, CreateInsertSql, CreateColRow, executeTransactSqlList, isSystemEditable, getNullStr } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_estimate_orders, list_m_offices, list_m_products } from '@/graphql/queries';

const MODULE_NAME = 'estimate-orders-edit';

export default {
  name: 'ESTIMATE-ORDERS-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    STAFFSEARCH,
    PRODUCTSEARCH,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注修正',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 発注日付
      dateOrderDate: '',
      // 取引先系
      suppliers:{
        class: 0,
        code: '',
        name: '',
        printName: '',
      },
      // 取引先担当者
      clientStaff:{
        id: 0,
        name: ' ',
        nameStamp: '',
      },
      // 作成担当者
      createdStaff:{
        id: 0,
        name: ' ',
        nameStamp: '',
      },
      // 内容
      textContents: '',
      // 摘要
      textSummary: '',
      // テーブル定義
      productList:[],
      fields:[
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Note',
          label: '備考',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'delete',
          label: ' ',
        },
      ],
      dataIndex: 0,
      // 作成日
      createdDate: '',
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // パラメータ
      orderId: 0,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.orderId = this.$route.query.orderId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 取引先担当者コード */
    clientStaffId: function() {
      return `${this.clientStaff.id}:${this.clientStaff.name}`
    },
    /* 作成担当者コード */
    createdStaffId: function() {
      return `${this.createdStaff.id}:${this.createdStaff.name}`
    },
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: this.selectSalesOfficeData == null ? null : this.selectSalesOfficeData.id,
        office_name: this.selectSalesOfficeData == null ? null : this.selectSalesOfficeData.name,
        client_class: this.suppliers.class,
        client_id: this.changeEstimateBrankId(this.suppliers.code, false),
        client_name: this.suppliers.name,
        multiSelectFlg: '1',
      };
    },
    /* 選択中の営業所情報取得用 */
    selectSalesOfficeData: function() {
      for (let i = 0; i < this.salesOffice.length; i++) {
        if (this.salesOffice[i].id == this.selectSalesOffice) {
          return this.salesOffice[i];
        }
      }
      return null;
    },
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let officeListResult = null;
        let controlData = null;
        [officeListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_offices)),
          getControlMaster(),
        ]);
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // コントロールマスタ取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 指定された発注データを設定
        await this.setOrder();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 発注データ設定 */
    async setOrder() {
      const functionName = 'setOrder';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let resultOrder = null;
      try {
        resultOrder = await API.graphql(graphqlOperation(list_t_estimate_orders,{where_clause: where_clause}));
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_estimate_orders',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      let dataOrder = resultOrder.data.list_t_estimate_orders;
      //console.log(dataOrder);
      if(dataOrder != null){
        await this.setResult(dataOrder);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake() {
      let where_clause = '';
      // 引数の発注番号で絞り込む
      where_clause += 'AND order_id = ' + this.orderId + ' ';
      // 製品一覧の行番号でソート
      where_clause += 'ORDER BY order_row';

      return where_clause;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        this.selectSalesOffice = result[0].office_id;
        this.selectSalesOfficeText = getListValue(this.salesOffice, result[0].office_id, 'id', 'text');
        // 発注日付
        this.dateOrderDate = result[0].order_date;
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = this.changeEstimateBrankId(result[0].client_id, true);
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_print_name;
        // 取引先担当者
        this.clientStaff = {id:result[0].client_staff_id,name:result[0].client_staff_name_kanji,nameStamp:result[0].client_staff_name_stamp};
        // 作成担当者
        this.createdStaff = {id:result[0].created_staff_id,name:result[0].created_staff_name_kanji,nameStamp:result[0].created_staff_name_stamp};
        // 内容
        this.textContents = result[0].contents;
        // 摘要
        this.textSummary = result[0].summary;
        // 全件参照して製品一覧を作成
        let productIdCsv = '';
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = this.changeEstimateBrankId(result[i].product_id, true);
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].Note = result[i].product_note;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].product_unit;
          if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
            if (productIdCsv != '') {
              productIdCsv += ',';
            }
            // 製品コード
            productIdCsv += this.productList[i].ProductCode;
          }
        }
        // 製品マスターから取得
        if (productIdCsv != '') {
          let where_clause = 'AND product_id IN (' + productIdCsv + ') ';
          let condition = {where_clause: where_clause};
          let productsResult = await API.graphql(graphqlOperation(list_m_products,condition));
          let productsResultData = productsResult.data.list_m_products;
          //console.log(productsResultData);
          if (productsResultData != null) {
            for (let i = 0; i < this.productList.length; i++) {
              let data = productsResultData.find(el => el.product_id == this.productList[i].ProductCode);
              if (data != undefined) {
                this.productList[i].ProductTaxRateClass = data.product_tax_rate_class_purchase;
              }
            }
          }
        }
        // 最終行を空行とする
        this.addTableRow();
        // 作成日
        this.createdDate = result[0].created_date;
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.execSave() == true) {
        // 発注照会画面へ遷移
        this.$router.push({ name: 'ESTIMATE-ORDERS-INQUIRY', query: { orderId: this.orderId, parentKbn: 1 } });
      }
      //console.log('保存処理終了');
    },
    /* 更新処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 発注データの更新
      await this.updateOrders();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      retResult = true;

      return retResult;
    },
    /* 発注データ更新処理 */
    async updateOrders() {
      //console.log('発注データ更新処理');
      // CRUD処理
      // 最初にdelete
      this.transactSqlList.push('DELETE FROM t_estimate_orders WHERE order_id=' + this.orderId);
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      for (let i = 0; i < this.productList.length; i++) {
        let colList = [];
        // 発注番号
        colList.push(CreateColRow('order_id', this.orderId, 'NUMBER'));
        // 発注行番号
        colList.push(CreateColRow('order_row', i+1, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOfficeData.id, 'NUMBER'));
        // 営業所名（漢字）
        colList.push(CreateColRow('office_name_kanji', await escapeQuote(this.selectSalesOfficeData.name), 'VARCHAR'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.changeEstimateBrankId(this.suppliers.code, false), 'NUMBER'));
        // 取引先名（漢字）
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.suppliers.name), 'VARCHAR'));
        // 取引先印刷用宛名
        colList.push(CreateColRow('client_print_name', await escapeQuote(this.suppliers.printName), 'VARCHAR'));
        // 発注日
        colList.push(CreateColRow('order_date', this.dateOrderDate, 'DATE'));
        // 取引先担当者コード
        colList.push(CreateColRow('client_staff_id', this.clientStaff.id, 'NUMBER'));
        // 取引先担当者名（漢字）
        colList.push(CreateColRow('client_staff_name_kanji', await escapeQuote(this.clientStaff.name), 'VARCHAR'));
        // 取引先担当者名（印鑑）
        colList.push(CreateColRow('client_staff_name_stamp', await escapeQuote(this.clientStaff.nameStamp), 'VARCHAR'));
        // 作成担当者コード
        colList.push(CreateColRow('created_staff_id', this.createdStaff.id, 'NUMBER'));
        // 作成担当者名（漢字）
        colList.push(CreateColRow('created_staff_name_kanji', await escapeQuote(this.createdStaff.name), 'VARCHAR'));
        // 作成担当者名（印鑑）
        colList.push(CreateColRow('created_staff_name_stamp', await escapeQuote(this.createdStaff.nameStamp), 'VARCHAR'));
        // 内容
        colList.push(CreateColRow('contents', await escapeQuote(this.textContents), 'VARCHAR'));
        // 摘要
        colList.push(CreateColRow('summary', await escapeQuote(this.textSummary), 'VARCHAR'));
        // 製品コード
        colList.push(CreateColRow('product_id', this.changeEstimateBrankId(this.productList[i].ProductCode, false), 'NUMBER'));
        // 製品名（漢字）
        colList.push(CreateColRow('product_name_kanji', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
        // 製品備考
        colList.push(CreateColRow('product_note', await escapeQuote(this.productList[i].Note), 'VARCHAR'));
        // 製品数量
        colList.push(CreateColRow('product_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 製品単位
        colList.push(CreateColRow('product_unit', await escapeQuote(this.productList[i].Unit), 'VARCHAR'));
        // 作成年月日
        colList.push(CreateColRow('created_date', this.createdDate, 'DATE'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_estimate_orders (' + CreateInsertSql(colList, 'col', 't_estimate_orders') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_estimate_orders') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql)
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      const result = await searchClientModal(client_id, this.htmlConst.ClientClass.supplier, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6 || client_id == '0') {
          // 取引先クリア
          this.clientClear();
        }
      }
    },
    // 取引先検索モーダル表示
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined') {
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.printName = clientItems.detail.client_name_kanji;
        this.clientStaff = {id:clientItems.detail.staff_id, name: clientItems.detail.staff_name_kanji, nameStamp: clientItems.detail.staff_name_stamp};
      }
    },
    // 取引先クリア
    clientClear: function() {
      //console.log('取引先クリア');
      this.suppliers.class = 0;
      this.suppliers.code = '';
      this.suppliers.name = '';
      this.suppliers.printName = '';
      this.clientStaff = {id:0,name:' '};
    },
    // 取引先照会
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 担当者検索モーダル表示
    showStaffSearchModal: function(index) {
      this.dataIndex = index;
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if (typeof staffItems != 'undefined') {
        if (this.dataIndex == 1) {
          this.clientStaff.id = staffItems.detail.staff_id;
          this.clientStaff.name = staffItems.detail.staff_name_kanji;
          this.clientStaff.nameStamp = staffItems.detail.staff_name_stamp;
        } else {
          this.createdStaff.id = staffItems.detail.staff_id;
          this.createdStaff.name = staffItems.detail.staff_name_kanji;
          this.createdStaff.nameStamp = staffItems.detail.staff_name_stamp;
        }
      }
    },
    // 取引先担当者クリア
    clientStaffClear: function() {
      //console.log('取引先担当者クリア');
      this.clientStaff.id = 0;
      this.clientStaff.name = ' ';
      this.clientStaff.nameStamp = '';
    },
    // 作成担当者クリア
    createdStaffClear: function() {
      //console.log('作成担当者クリア');
      this.createdStaff.id = 0;
      this.createdStaff.name = ' ';
      this.createdStaff.nameStamp = '';
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8 || product_id == '0') {
          // 製品クリア
          this.productClear(index);
        }
      }
    },
    // 製品検索モーダル表示
    showProductSearchModal: function(index) {
      this.dataIndex = index;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    setProductSearchModal: function(index, productItemsDetail) {
      this.productList[index].ProductCode = productItemsDetail.product_id;
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].Quantity = 1;
      this.productList[index].Unit = productItemsDetail.unit;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_purchase;
    },
    // 製品クリア
    productClear: function(rowNum) {
      //console.log(rowNum);
      this.productList[rowNum].ProductCode = '';
      this.productList[rowNum].ProductName = '';
      this.productList[rowNum].Quantity = 0;
      this.productList[rowNum].Unit = '';
      this.productList[rowNum].ProductTaxRateClass = Const.ProductTaxRateClass.normalTax;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: '',
        ProductName: '',
        Note:'',
        Quantity: 0,
        Unit: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum){
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum,1);
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack() {
      // 見積・発注一覧画面へ遷移
      this.$router.push({ name: 'ESTIMATE-LIST' });
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (this.productList[index].ProductCode == '' && this.productList[index].ProductName == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      //console.log('空行削除終了');
    },
    // 見積の空白設定コードの変換
    changeEstimateBrankId: function(id, isChangeBrank) {
      if (isChangeBrank == true) {
        // 空白に変更フラグが立っている場合
        // 0の場合、空白に変換して返却
        return getNullStr(id) == '0' ? '' : id;
      } else {
        // 空白に変換フラグが立っていない場合
        return getNullStr(id) == '' ? '0' : id;
      }
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>
<style scoped>
</style>