<template>
  <!-- 請求残高保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 請求残高保守編集</strong></h5>
                <router-link to="/billings-balances-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row col-12 mt-2">
                  <!-- 請求年月 -->
                  <h5>
                    <strong>
                    請求年月: &nbsp;
                      {{billingsBalancesObj.billing_month_year | month_year}}
                    </strong>
                  </h5>
                </div>
                <div class="row col-12">
                  <!-- 得意先コード -->
                  <h5>
                    <strong>
                    得意先コード: &nbsp;
                      {{billingsBalancesObj.client_id}}&nbsp;&nbsp;&nbsp;&nbsp; 
                      {{billingsBalancesObj.client_name_kanji}}
                    </strong>
                  </h5>
                </div>
                <div class="row col-12 mb-2">
                  <!-- 現場コード -->
                  <h5>
                    <strong>
                    現場コード: &nbsp;{{billingsBalancesObj.site_id}}
                    </strong>
                  </h5>
                </div>
                <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                  <b-tbody>
                    <b-tr>
                      <b-th>前回請求税抜残高</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.pre_billing_no_tax_balance" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>2回前請求税抜額</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.two_pre_billing_money_no_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>3回前請求税抜額</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.three_pre_billing_money_no_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>前回請求消費税残高</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.pre_billing_tax_balance" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>2回前請求消費税額</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.two_pre_billing_money_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>3回前請求消費税額</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.three_pre_billing_money_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
                  <b-tbody>
                    <b-tr>
                      <b-th>締日売掛売上</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_receivable_sales" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>請求締切日</b-th>
                      <b-td>
                        <b-form-datepicker size="sm" v-model="billingsBalancesObj.billing_end_date" readonly></b-form-datepicker>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日伝票枚数</b-th>
                      <b-td>
                         <validation-provider rules="required|numeric|max:5" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_sales_billing_cnt" maxlength="5"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>入金予定日</b-th>
                      <b-td>
                        <b-form-datepicker size="sm" v-model="billingsBalancesObj.payment_scheduled" readonly></b-form-datepicker>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日入金額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_payment" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日消費税入金</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_tax_payment" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日一括消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_bulk_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日伝票消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_billing_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日通常消費税課税小計</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_normal_tax_subtotal" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日通常消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_normal_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日軽減消費税課税小計</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_light_tax_subtotal" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日軽減消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_light_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日売掛売上_先行除外</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_receivable_sales_pre_exclude" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日通常消費税課税小計_先行除外</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_normal_tax_subtotal_pre_exclude" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日通常消費税額_先行除外</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_normal_tax_pre_exclude" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日軽減消費税課税小計_先行除外</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_light_tax_subtotal_pre_exclude" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日軽減消費税額_先行除外</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_light_tax_pre_exclude" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6">
                  <b-tbody>
                    <b-tr>
                      <b-th>締日繰越税抜額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_forward_no_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日繰越消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_forward_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日請求税抜額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_billing_money_no_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日請求消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input size="sm" type="text" style="min-width:140px" v-model="billingsBalancesObj.closing_date_billing_money_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </validation-observer>
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="save()"><span class="oi oi-pencil"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, formatDate, CreateColRow, getUserCol, CreateUpdateSql, getControlMaster, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'billings-balances-edit';

export default {
  name: 'BILLINGS-BALANCES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '請求残高保守編集',
      // 請求残高対象
      billingsBalancesObj: {},
      // ユーザ名
      username: this.$store.getters.user.username,
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],

    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 請求年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    try {
      const control = await getControlMaster()
      if (Number(control.process_month_year) > Number(this.$route.query.billing_month_year)) {
        this.$router.push({ 
          name: 'BILLINGS-BALANCES-INQUIRY', 
          query: {
            billing_month_year: this.$route.query.billing_month_year, 
            client_id: this.$route.query.client_id,
            site_id: this.$route.query.site_id,
          },
          params: { successMessages: ['現在処理年月よりも過去のデータは編集不可です。'] }  
        });
      }
    } catch (error) {
      await addOperationLogs('Error', MODULE_NAME, 'getControlMaster', {}, error);
      this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
    }
    await this.fetchData(this.$route.query);

    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} billing_month_year - 請求年月
     * @param {String} client_id - 得意先コード
     * @param {String} site_id - 現場コード
     */
    async fetchData({billing_month_year, client_id, site_id}) {
      this.$store.commit('setLoading', true);
      billing_month_year = Number(billing_month_year)
      client_id = Number(client_id)
      site_id = Number(site_id)
      // 請求残高マスタのデータを請求
      let billingsBalances = await this.searchBillingsBalances(billing_month_year, client_id, site_id)

      // 請求残高データをセット
      this.billingsBalancesObj = {...billingsBalances}
      this.$store.commit('setLoading', false);
    },
    /**
     * 請求残高マスタを検索します。
     * @param {Int} month_year - 処理年月
     * @param {Int} client_id -  得意先コード
     * @param {Int} site_id -  現場コード
     * @returns {Object} 請求残高マスタデータ
     */
    async searchBillingsBalances(month_year, client_id, site_id ) {
      const functionName = 'searchBillingsBalances';

      let result = null;
      let sqls = `SELECT 
                  MC.client_id,
                  MC.client_name_kanji,
                  TBB.billing_month_year,
                  TBB.site_id,
                  TBB.billing_end_date,
                  TBB.pre_billing_no_tax_balance,
                  TBB.pre_billing_tax_balance,
                  TBB.closing_date_receivable_sales,
                  TBB.closing_date_sales_billing_cnt,
                  TBB.closing_date_bulk_tax,
                  TBB.closing_date_billing_tax,
                  TBB.closing_date_tax,
                  TBB.closing_date_payment,
                  TBB.closing_date_tax_payment,
                  TBB.closing_date_forward_no_tax,
                  TBB.closing_date_forward_tax,
                  TBB.closing_date_billing_money_no_tax,
                  TBB.closing_date_billing_money_tax,
                  TBB.two_pre_billing_money_no_tax,
                  TBB.two_pre_billing_money_tax,
                  TBB.three_pre_billing_money_no_tax,
                  TBB.three_pre_billing_money_tax,
                  TBB.payment_scheduled,
                  TBB.closing_date_normal_tax_subtotal,
                  TBB.closing_date_normal_tax,
                  TBB.closing_date_light_tax_subtotal,
                  TBB.closing_date_light_tax,
                  TBB.closing_date_receivable_sales_pre_exclude,
                  TBB.closing_date_normal_tax_subtotal_pre_exclude,
                  TBB.closing_date_normal_tax_pre_exclude,
                  TBB.closing_date_light_tax_subtotal_pre_exclude,
                  TBB.closing_date_light_tax_pre_exclude
                  FROM t_billings_balances TBB LEFT JOIN m_clients MC USING(client_id) 
                  WHERE billing_month_year = ${month_year} AND client_id = ${client_id} AND site_id = ${site_id} AND MC.client_class = 1
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sqls] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },

    /**
     * 保存ボタンの押下
     */
    async save() {
      this.billingsBalancesObj.inventory_count_day = formatDate(this.billingsBalancesObj.inventory_count_day)
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.confirmMessage.push('注意：繰越されたデータは保存されません。');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'BILLINGS-BALANCES-INQUIRY', 
              query: { 
                billing_month_year: this.billingsBalancesObj.billing_month_year, 
                client_id: this.billingsBalancesObj.client_id,
                site_id: this.billingsBalancesObj.site_id,
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      const colList = [];
      // 前回請求税抜残高
      colList.push(CreateColRow('pre_billing_no_tax_balance', Number(this.billingsBalancesObj.pre_billing_no_tax_balance), 'INT'));
      // 前回請求消費税残高
      colList.push(CreateColRow('pre_billing_tax_balance', Number(this.billingsBalancesObj.pre_billing_tax_balance), 'INT'));
      // 2回前請求税抜額
      colList.push(CreateColRow('two_pre_billing_money_no_tax', Number(this.billingsBalancesObj.two_pre_billing_money_no_tax), 'INT'));
      // 2回前請求消費税額
      colList.push(CreateColRow('two_pre_billing_money_tax', Number(this.billingsBalancesObj.two_pre_billing_money_tax), 'INT'));
      // 3回前請求税抜額
      colList.push(CreateColRow('three_pre_billing_money_no_tax', Number(this.billingsBalancesObj.three_pre_billing_money_no_tax), 'INT'));
      // 3回前請求消費税額
      colList.push(CreateColRow('three_pre_billing_money_tax', Number(this.billingsBalancesObj.three_pre_billing_money_tax), 'INT'));
      // 締日売掛売上
      colList.push(CreateColRow('closing_date_receivable_sales', Number(this.billingsBalancesObj.closing_date_receivable_sales), 'INT'));
      // 請求締切日
      //colList.push(CreateColRow('billing_end_date', this.billingsBalancesObj.billing_end_date, 'VARCHAR'));
      // 締日伝票枚数
      colList.push(CreateColRow('closing_date_sales_billing_cnt', Number(this.billingsBalancesObj.closing_date_sales_billing_cnt), 'INT'));
      // 入金予定日
      //colList.push(CreateColRow('payment_scheduled', this.billingsBalancesObj.payment_scheduled, 'VARCHAR'));
      // 締日入金額
      colList.push(CreateColRow('closing_date_payment', Number(this.billingsBalancesObj.closing_date_payment), 'INT'));
      // 締日消費税入金
      colList.push(CreateColRow('closing_date_tax_payment', Number(this.billingsBalancesObj.closing_date_tax_payment), 'INT'));
      // 締日一括消費税額
      colList.push(CreateColRow('closing_date_bulk_tax', Number(this.billingsBalancesObj.closing_date_bulk_tax), 'INT'));
      // 締日伝票消費税額
      colList.push(CreateColRow('closing_date_billing_tax', Number(this.billingsBalancesObj.closing_date_billing_tax), 'INT'));
      // 締日消費税額
      colList.push(CreateColRow('closing_date_tax', Number(this.billingsBalancesObj.closing_date_tax), 'INT'));
      // 締日繰越税抜額
      colList.push(CreateColRow('closing_date_forward_no_tax', Number(this.billingsBalancesObj.closing_date_forward_no_tax), 'INT'));
      // 締日繰越消費税額
      colList.push(CreateColRow('closing_date_forward_tax', Number(this.billingsBalancesObj.closing_date_forward_tax), 'INT'));
      // 締日請求税抜額
      colList.push(CreateColRow('closing_date_billing_money_no_tax', Number(this.billingsBalancesObj.closing_date_billing_money_no_tax), 'INT'));
      // 締日請求消費税額
      colList.push(CreateColRow('closing_date_billing_money_tax', Number(this.billingsBalancesObj.closing_date_billing_money_tax), 'INT'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('closing_date_normal_tax_subtotal', Number(this.billingsBalancesObj.closing_date_normal_tax_subtotal), 'INT'));
      // 締日通常消費税額
      colList.push(CreateColRow('closing_date_normal_tax', Number(this.billingsBalancesObj.closing_date_normal_tax), 'INT'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('closing_date_light_tax_subtotal', Number(this.billingsBalancesObj.closing_date_light_tax_subtotal), 'INT'));
      // 締日軽減消費税額
      colList.push(CreateColRow('closing_date_light_tax', Number(this.billingsBalancesObj.closing_date_light_tax), 'INT'));
      // 締日売掛売上_先行除外
      colList.push(CreateColRow('closing_date_receivable_sales_pre_exclude', Number(this.billingsBalancesObj.closing_date_receivable_sales_pre_exclude), 'INT'));
      // 締日通常消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_subtotal_pre_exclude', Number(this.billingsBalancesObj.closing_date_normal_tax_subtotal_pre_exclude), 'INT'));
      // 締日通常消費税額_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_pre_exclude', Number(this.billingsBalancesObj.closing_date_normal_tax_pre_exclude), 'INT'));
      // 締日軽減消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_light_tax_subtotal_pre_exclude', Number(this.billingsBalancesObj.closing_date_light_tax_subtotal_pre_exclude), 'INT'));
      // 締日軽減消費税額_先行除外
      colList.push(CreateColRow('closing_date_light_tax_pre_exclude', Number(this.billingsBalancesObj.closing_date_light_tax_pre_exclude), 'INT'));

      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 't_billings_balances')} 
                    WHERE 
                    billing_month_year = ${this.billingsBalancesObj.billing_month_year} 
                    AND client_id = ${this.billingsBalancesObj.client_id}
                    AND site_id = ${this.billingsBalancesObj.site_id}
                    AND billing_start_date IS NULL`
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
