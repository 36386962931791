<template>
  <!-- ルート保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> ルート保守照会</strong></h5>
                <router-link to="/route-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 配送コード -->
                <div class="col-12 form-group">
                  <label for="shipping_code" class="form-label">配送コード： {{obj.shipping_code}}</label>
                </div>
              </div>
              <div class="row">
                <!-- 配送名 -->
                <div class="col-12 form-group">
                  <label for="shipping_name" class="form-label">配送名： {{obj.shipping_name}}</label>
                </div>
              </div>
            </div>
            <!-- 削除・編集ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-cancel btn-block" @click="clickDeleteBtn"><span class="oi oi-circle-x"></span> 削除</button>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn"><span class="oi oi-circle-check"></span> 編集</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_routes } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'route-inquiry';

export default {
  name: 'ROUTE-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'ルート保守照会',
      // ユーザ名
      username: this.$store.getters.user.username,
      // ルート対象
      obj: {},
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0 || this.successMessages.length > 0
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData({shipping_code}) {
      const functionName = 'fetchData'
      this.busy = true;
      try {
        // ルートマスタのデータを請求
        const objResult = await this.searchRoutes(shipping_code)
  
        // データセット 
        this.obj = {...objResult}
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']); 
      }
      this.busy = false;
    },
    /**
     * ルートマスタを検索します。
     * @param {String} shipping_code
     * @returns {Object} ルートマスタデータ
     */
    async searchRoutes(shipping_code) {
      const functionName = 'searchRoutes';

      let result = null;
      let where_clause = `AND shipping_code = '${shipping_code}'`
      
      try {
        result = await API.graphql(graphqlOperation(list_m_routes, {where_clause}));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_routes',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_routes',
          where_clause: where_clause,
          result: result
        });
        return null;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      return result.data.list_m_routes[0];
    },
    /**
     * 編集ボタンの押下
     */
    async clickEditBtn() {
      this.$router.push({ 
        name: 'ROUTE-EDIT', 
        query: { 
          shipping_code: this.obj.shipping_code,
        },
      });
    },
    /**
     * 削除ボタン押下時
     */
    clickDeleteBtn() {
      this.$bvModal.msgBoxConfirm('ルートデータを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDel()
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'ROUTE-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1002']] }  
            });
          }
          this.$store.commit('setLoading', false);
        }
      });
    },
    /**
     * 削除処理
     */
    async execDel() {
      const functionName = 'execDel';

      // サービス区分マスタ 
      const SQLs = `DELETE FROM m_routes 
      WHERE shipping_code = '${this.obj.shipping_code}'`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
