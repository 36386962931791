import { render, staticRenderFns } from "./deliverys-designs-classes-edit.vue?vue&type=template&id=41240bb6&"
import script from "./deliverys-designs-classes-edit.vue?vue&type=script&lang=js&"
export * from "./deliverys-designs-classes-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports