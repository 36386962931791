<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-4 min-vh-85">
    <b-row>
      <b-col lg="12">
        <!-- ●●●検索条件●●● -->
        <div class="my-2">
          <b-card>
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <div class="mb-0 p-2" id="heading1">
              <h5 class="mb-0">
                <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                  <span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
            </div>
            <b-collapse id="collapse-1" visible>
              <b-card-body class="p-2">
                <validation-observer ref="observer">
                  <b-form @submit.prevent="clearAlert(); clickSearchButton();" id="scheduledStockInquiryForm" class="form-horizontal">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <b-col lg="6">
                          <b-form-group
                            label="営業所"
                            label-for="selectSalesOffice"
                          >
                            <b-form-select
                              id="selectSalesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col lg="12">
                          <validation-provider name="product" rules="required|numeric|min:8" v-slot="{ classes,errors }">
                            <label for="productId">製品: {{searchConditions.product_name}}</label>
                            <b-container fluid class="pl-0">
                              <b-row>
                                <b-col class="col-6 pr-0">
                                  <b-form-input 
                                    class="pr-0" 
                                    id="productId" 
                                    v-model="searchConditions.productId"
                                    @input="searchProduct(searchConditions.productId)" 
                                    maxlength="8"
                                  ></b-form-input>
                                </b-col>
                                <b-col class="col-1">
                                  <b-input-group-text @click="showclientSearchModal()" class="pl-0" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showclientSearchModal()" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-col>
                              </b-row>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-container>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" type="submit" form="scheduledStockInquiryForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <!-- ●●●検索結果1●●● -->
    <b-card id="resultArea1">
      <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
        <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-col lg="12">
        <b-row>
          <b-table
            show-empty
            :busy = "busy"
            :items="stockInfo"
            :fields="stockFields"
            >
            <template #table-caption>在庫情報</template>
          </b-table>
        </b-row>
      </b-col>
    </b-card>
    <!-- ●●●検索結果2●●● -->
    <b-card id="resultArea2">
      <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
        <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-col lg="12">
        <b-row>
          <b-table
            show-empty
            :busy="busy"
            :items="stockScheduledInfo"
            :fields="stockScheduledFields"
            >
            <template #table-caption>入荷情報一覧</template>
          </b-table>
        </b-row>
      </b-col>
    </b-card>
  </b-container>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
  <Footer />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import Const from '@/assets/js/const.js';
import { searchProductsModal } from '@/assets/js/searchModal.js';
import { DISP_MESSAGES } from '@/assets/js/messages.js';
import { init, formatDate, addOperationLogs, getListValue, executeSelectSql } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_offices, list_m_products } from '@/graphql/queries';

const MODULE_NAME = 'scheduled-stock-inquiry';

export default {
  name: 'SCHEDULED-STOCK-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入荷予定照会',
      // アラート
      alertDanger: [],
      resultError:'',
      // 検索条件
      searchConditions:{
        salesOffice:[],
        selectSalesOffice:'',
        product_name:'',
        productId: '',
      },
      // 営業所
      office: '',
      // 在庫数
      stockInfo: [],
      // 入荷予定数
      stockScheduledInfo:[],
      // 検索結果
      busy:false,
      // 製品検索情報
      productSearchProp:{
        office_id: '',
        office_name: '',
        client_id: null,
        client_class: null,
      },
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertDanger.length
    },
    // 在庫マスタ情報
    stockFields(){
      return [
        {
          key: 'balance',
          label: '現在庫数',
          tdClass: 'text-right',
        },
        {
          key: 'inventoryReserveCount',
          label: '引当済数',
          tdClass: 'text-right',
        },
        {
          key: 'quantityStock',
          label: '入荷予定数',
          tdClass: 'text-right',
        },
      ]
    },
    // 入荷予定情報
    stockScheduledFields(){
      return [
        {
          key: 'stockScheduled',
          label: '入荷予定日',
        },
        {
          key: 'stockScheduleStaff',
          label: '入荷予定処理担当者',
        },
        {
          key: 'stockQuantity',
          label: '入荷予定数',
          tdClass: 'text-right',
        },
        {
          key: 'orderNumber',
          label: '注文番号',
        },
      ]
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /* フェッチ */
    async fetchData({office_id, product_id}) {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // エスケープ
        office_id = Number(office_id)
        product_id = Number(product_id)
        // 製品名取得
        let productListResult = await API.graphql(graphqlOperation(list_m_products, {
          where_clause: `AND product_id = ${product_id}`
        }));
        let product_name = productListResult.data.list_m_products[0].product_name_kanji;
        // 営業所取得
        // 営業所データ取得⇒セット
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_name_kanji,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
        }

        if(office_id != '' && product_id != '' && product_name != ''){
          //console.log('1');
          // 親画面からの引数がある場合(営業所コード、製品コード、製品名)
          // 営業所データ初期値セット
          for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
            if(this.searchConditions.salesOffice[i].id == office_id){
              this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
              break;
            }
          }
          this.searchConditions.product_name = product_name;
          this.searchConditions.productId = product_id;
          // 設定後、初期検索
          await this.search();
        }else{
          //console.log('2');
          // 営業所データ初期値セット
          this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[0].id;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    async clickSearchButton(){
      const functionName = 'searchButton';
      try {
        // 検索ボタン押下時
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.busy = true;
      // 在庫マスタ情報を初期化
      this.stockInfo = [];
      // 入荷予定情報を初期化
      this.stockScheduledInfo = [];
      // 在庫マスタ情報を取得
      let selectSql_1 = '';
      selectSql_1 = await this.makeSelectSql_1();
      //console.log(selectSql_1);
      try {
        let stockResultData = await executeSelectSql(selectSql_1);
        //console.log(stockResultData);
        if(stockResultData != null && stockResultData.length > 0){
          // 取得した在庫マスタ情報をセット(1件のみの想定(最新レコードを取得))
          let stockInfo = {
            balance: stockResultData[0].inventory_reserve_count + stockResultData[0].balance,
            inventoryReserveCount: stockResultData[0].inventory_reserve_count,
            quantityStock: stockResultData[0].quantity_stock,
          };
          this.stockInfo.push(stockInfo);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.busy = false;
        return;
      }
      // 予定入荷情報を取得
      let selectSql_2 = '';
      selectSql_2 = await this.makeSelectSql_2();
      //console.log(selectSql_2);
      try {
        let stockScheduledResultData = await executeSelectSql(selectSql_2);
        //console.log(stockScheduledResultData);
        if(stockScheduledResultData != null){
          // 取得した予定入荷情報をセット
          for(let i = 0; i < stockScheduledResultData.length; i++){
            let stockScheduled = {
              // 入荷予定日
              stockScheduled: formatDate(stockScheduledResultData[i].stock_scheduled) + (stockScheduledResultData[i].stock_scheduled_confirm_class == 1 ? ' *' : ''),
              // 入荷予定処理担当者
              stockScheduleStaff: stockScheduledResultData[i].stock_scheduled_staff_id == null ? '' : stockScheduledResultData[i].stock_scheduled_staff_id + '：' + stockScheduledResultData[i].staff_name_kanji,
              // 入荷予定数
              stockQuantity: stockScheduledResultData[i].remaining_quantity,
              // 注文番号
              orderNumber: stockScheduledResultData[i].order_number,
            }
            this.stockScheduledInfo.push(stockScheduled);
          }
          if(stockScheduledResultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.busy = false;
        return;
      }
      this.busy = false;
    },
    /* SELECT文字列作成（在庫情報取得用） */
    async makeSelectSql_1() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.inventory_reserve_count';
      selectSql += ',stocks.balance';
      selectSql += ',stocks.quantity_stock';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      selectSql += 'INNER JOIN m_control AS control ';
      selectSql += 'ON stocks.month_year = control.process_month_year ';
      selectSql += 'INNER join m_products_details AS products_details USING(product_id,office_id) ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      selectSql += 'AND stocks.product_id = ' + this.searchConditions.productId + ' ';

      return selectSql;
    },
    /* SELECT文字列作成（入荷予定一覧情報取得用） */
    async makeSelectSql_2() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.stock_scheduled';
      selectSql += ',orders.stock_scheduled_staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',orders.remaining_quantity';
      selectSql += ',orders.order_number';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON orders.stock_scheduled_staff_id = staffs.staff_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      selectSql += 'AND orders.product_id = ' + this.searchConditions.productId + ' ';
      selectSql += 'AND orders.order_issue_class > 0 ';
      selectSql += 'AND orders.remaining_quantity > 0 ';
      selectSql += 'AND orders.order_class <> ' + Const.OrderClass.direct + ' ';
      selectSql += 'AND orders.is_deleted = 0 ';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders.stock_scheduled';
      selectSql += ',orders.order_number';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    // 製品検索モーダル開く
    showclientSearchModal:function(){
      this.productSearchProp.office_id = this.searchConditions.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.searchConditions.salesOffice, this.searchConditions.selectSalesOffice);
      this.$bvModal.show('productSearchModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id) {
      this.productSearchProp.office_id = this.searchConditions.selectSalesOffice;
      const result = await searchProductsModal(product_id, this.productSearchProp, 'searchProduct', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result)
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.closeProductSearchModal({detail:{}})
        }
      }
    },
    closeProductSearchModal:function(productItems){
      //console.log(productItems);
      this.searchConditions.product_name = productItems.detail.product_name_kanji;
      this.searchConditions.productId = productItems.detail.product_id;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertDanger = [];
      this.resultError = [];
    },
  }
}
</script>
<style scoped>
</style>