<template>
    <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> 受注・出荷処理メニュー</h5>
                            </div>
                        </div>
                    </div>

                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['1']" @shortkey="executeShortcut('received-order-list')">
                            <router-link to="/received-order-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="1" id="menu1-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">受注 一覧/入力/修正/削除</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>出荷確定機能/仮納品書入力/返品在庫計上入力/受注コピー見積登録/各種モーダルの検索照会含む</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['2']" @shortkey="executeShortcut('ships-scheduled-route')">
                            <router-link to="/ships-scheduled-route"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="2" id="menu1-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">ルート別出荷予定表</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>伝票が未発行の受注データをルート別に検索します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['3']" @shortkey="executeShortcut('sales-slip-print')">
                            <router-link to="/sales-slip-print"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="3" id="menu1-3">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">売上伝票発行</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>出荷確定入力を行った受注製品の納品書を発行します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['4']" @shortkey="executeShortcut('ships-confirm-auto')">
                            <router-link to="/ships-confirm-auto" class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="4" id="menu1-4" style="background-color: yellow; color: red;">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">自動出荷確定</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>すべての範囲を出荷確定する恐れがありますので、<span style="font-weight: bold;">操作には注意！</span></li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <!-- <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;">
                            <router-link to="/forwarding-confirm-list"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2" data-id="alt+2" id="menu2-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">出荷確定 入力・修正・削除</h6>
                                    <span class="fas fa-chevron-right align-middle"></span>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>出荷確定の検索、登録、修正、削除を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div> -->
                        <div id="sort2" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['5']" @shortkey="executeShortcut('ships-confirm-picking')">
                            <router-link to="/ships-confirm-picking"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="5" id="menu1-5">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">出荷確定ピッキング表</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>自動出荷確定された製品のピッキングを行う。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort3" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['6']" @shortkey="executeShortcut('sales-slip-print-auto')">
                            <router-link to="/sales-slip-print-auto"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="6" id="menu1-6" style="background-color: yellow; color: red;">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">自動売上伝票発行</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>自動出荷確定された受注が伝票発行されます。<span style="font-weight: bold;">操作には注意！</span></li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['7']" @shortkey="executeShortcut('received-order-stock-reserve-list')">
                            <router-link to="/received-order-stock-reserve-list"
                            class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                            data-id="7" id="menu1-7">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">7</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">受注在庫引当変更</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>受注した製品の引当情報の表示と別の受注の同製品への引当の移動を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-SALES-ORDERSHIP',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '受注・出荷処理メニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>