<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
          <!-- ●●●コンテンツ●●● -->
          <div class="mt-0">
              <!-- ●●●メインコンテンツ●●● -->
              <div id="content-menu" class="my-2 mx-0" role="main">
                  <!-- ●●●1件目●●● -->
                  <section class="" id="menu-1">
                      <!-- ●●●メニュータイトル●●● -->
                      <div class="user-header alt bg-none">
                          <div class="media">
                              <div class="media-body pb-3">
                                  <h5 class="text-secondary m-0"><span class="oi oi-clipboard"></span>見積管理メニュー</h5>
                              </div>
                          </div>
                      </div>
                      <!-- ●●●メニュー●●● -->
                      <div id="collapse-menu0" class="row collapse show">
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['1']" @shortkey="executeShortcut('estimate-list')">
                              <router-link to="/estimate-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="1" id="menu1-1">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">1</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">見積書・発注書 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>見積データの検索・登録・照会・編集・削除・見積帳票のPDF出力を行います※見積コピー登録を含む</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['2']" @shortkey="executeShortcut('clients-products-master-list')">
                              <router-link to="/clients-products-master-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="2" id="menu1-2">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">2</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">取引先製品マスタ 一覧/入力/修正/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>得意先毎の売値が設定される取引先製品マスタの検索・登録・編集・削除を行います</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['3']" @shortkey="executeShortcut('clients-parent-child-master-list')">
                              <router-link to="/clients-parent-child-master-list"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="3" id="menu1-3">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">3</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">親取引先マスタ 一覧/入力/削除</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>単価登録のための取引先の親子関係の検索・登録・削除を行います</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                          <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                              style="border-radius:0;flex-wrap:wrap;"
                              v-shortkey="['4']" @shortkey="executeShortcut('sales-unit-price-copy')">
                              <router-link to="/sales-unit-price-copy"
                                  class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                  data-id="4" id="menu1-4">
                                  <div class="d-flex w-100 p-0">
                                      <i class="fa stct-pci">4</i>
                                      <h6 class="d-inline-block mr-auto" style="min-width: 110px;">単価自動コピー（個別）</h6>
                                  </div>
                                  <div class="d-flex w-100 p-0">
                                      <ul>
                                          <li>取引先（子）を指定して取引先（親）の単価情報をコピーします</li>
                                      </ul>
                                  </div>
                              </router-link>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
          <Footer />
      </div>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-SALES-ESTIMATE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '見積管理メニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>