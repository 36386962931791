<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container>
                      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 伝票番号入力欄 -->
                        <b-col>
                          <b-form-group label="伝票番号">
                            <validation-provider name="selectBillingNo" :rules="{required: true, numeric: true}" v-slot="{ classes,errors }">
                              <div :class="classes">
                                <b-form-input
                                v-model="searchConditions.selectBillingNo"
                                >
                                </b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </div>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
        <b-alert show variant="warning" class="mt-0" v-if="alertWarning.length">
          <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        </b-card-header>
          <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="mainFields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(customerCode)>取引先<br>&nbsp;部署</template>
              <!-- 操作列 -->
              <template #cell(operation)="operation">
                <!-- 参照ボタン -->
                <b-button size="sm" v-b-tooltip.hover.left="'詳細テーブルを開きます。'" @click="openModal(operation)" class="mr-1">
                  <span  class="oi oi-fullscreen-enter"> 詳細</span>
                </b-button>
                <!-- 売上編集ボタン -->
                <b-button size="sm" v-b-tooltip.hover.left="'売上内容の照会と各種操作を行います。'" @click="clickEditBtn(operation.item)">
                  <span class="oi oi-pencil"></span> 編集
                </b-button>
              </template>
              <!-- 取引先 -->
              <template #cell(customerCode)="customerCode">
                <b-tbody class="mainTable">
                  <b-tr>
                    <b-td>{{customerCode.item.customerCode}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{customerCode.item.department1}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{customerCode.item.department2}}</b-td>
                  </b-tr>
                </b-tbody>
              </template>
              <!-- 住所 -->
              <template #cell(addressCode)="addressCode">
                <b-tbody class="mainTable">
                  <b-tr>
                    <b-td>〒：{{addressCode.item.addressCode}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>&emsp;{{addressCode.item.address1}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>&emsp;{{addressCode.item.address2}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>TEL：{{addressCode.item.tel}}</b-td>
                  </b-tr>
                </b-tbody>
              </template>
              </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <b-modal id="detailsModal" class="details-modal" :val="postItem" size="xl" no-close-on-esc no-close-on-backdrop hide-footer title="売上伝票詳細">
      <div v-if="postItem">
        <b-card class="border-0 py-0">
          <b-card-body class="py-0">
            <b-card-header>
              <div align="right">
                <!-- PDF出力ボタン -->
                <b-button class="mr-2" pill size="sm" variant="success"  v-b-tooltip.hover.left="'照会中の売上伝票のPDF出力を行います。'" @click="outputSalesDirectPdf(postItem)">
                  <span class="oi oi-document"></span> PDF出力
                </b-button>&nbsp;
              </div>
            </b-card-header>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="postItem"
              :fields="subFields"
            >
            </b-table>
            <b-table-simple
              v-if="Object.keys(searchResultDispConditions).length !== 0"
              :bordered='false'
              :borderless='true'
              :outlined='false'
              small
            >
            <b-tbody>
              <b-tr>
                <b-th>納品指定日</b-th>
                <b-td>{{searchResultDispConditions.deliveryDesignDate}}</b-td>
                <b-th></b-th>
                <b-td></b-td>
                <b-th>合計</b-th>
                <b-td>{{(postItem.reduce((sum, i) => sum + new Number(i.originalUnitAmount), 0)).toLocaleString()}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>現場</b-th>
                <b-td style="white-space: normal;">{{searchResultDispConditions.siteId + '  ' + searchResultDispConditions.shippingCode + '  ' + searchResultDispConditions.clientSiteName}}<br>
                    {{searchResultDispConditions.deliveryDesignClass + searchResultDispConditions.deliveryDesign}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>郵便番号</b-th>
                <b-td style="white-space: normal;">{{searchResultDispConditions.clientSiteZipCode}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>住所</b-th>
                <b-td style="white-space: normal;">{{searchResultDispConditions.clientSiteAddress_1}}<br>{{searchResultDispConditions.clientSiteAddress_2}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>納品場所</b-th>
                <b-td style="white-space: normal;">{{searchResultDispConditions.deliveryLocation}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>チェック</b-th>
                <b-td>{{searchResultDispConditions.checkContents}}</b-td>
                <b-th>TEL</b-th>
                <b-td>{{searchResultDispConditions.clientSitePhoneNumber}}</b-td>
                <b-th>処理日</b-th>
                <b-td>{{searchResultDispConditions.processDate}}</b-td>
              </b-tr>
            </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import Const from '@/assets/js/const.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, getListValue, /*executeSelectSql,*/ formatDate, getNullStr } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
// モジュール名
const MODULE_NAME = 'sales-slip-inquiry';
export default {
  name: 'SALES-SLIP-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  props:['propsBillingNo'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売上伝票照会',
      // アラート
      alertDanger: [],
      alertWarning: [],
      // 検索条件
      searchConditions:{
        selectBillingNo: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // フッター項目
      searchResultDispConditions: {},
      // モーダルに渡す値
      postItem: '',
      // PDF出力用変数
      billingNo: '',
      monthlyMonthYear: '',
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // 得意先元帳照会画面からの遷移の場合
    if (this.$route.query.propsBillingNo) {
      this.searchConditions.selectBillingNo = this.$route.query.propsBillingNo;
      await this.setInvoiceData();
    }
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    mainFields: function() {
      return [
        {
          key: 'orderReceiveId',
          label: '受注番号',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'billingDate',
          label: '伝票日付',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'billingNo',
          label: '伝票番号',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'tradition',
          label: '伝票種別',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'closingDate',
          label: '締日',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'customerCode',
          label: '取引先',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'addressCode',
          label: '住所',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'staff',
          label: '担当者',
          sortable: true,
          thClass: 'align-text-top',
        },
        {
          key: 'operation',
          label: '操作',
          thClass: 'align-text-top',
        },
      ];
    },
    subFields: function() {
      return [
        {
          key: 'productName',
          label: '製品名',
          sortable: true,
        },
        {
          key: 'quantity',
          label: '数量',
          sortable: true,
        },
        {
          key: 'unit',
          label: '単位',
          sortable: true,
        },
        {
          key: 'unitPrice',
          label: '単価',
          sortable: true,
        },
        {
          key: 'unitAmount',
          label: '金額',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    /* 検索ボタン押下時 */
    async searchButton(){
      // ログ出力用メソッド名
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          // ローディングの開始
          this.$store.commit('setLoading', true);
          // 検索開始
          await this.search();
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
      }
      // ローディングの終了
      this.$store.commit('setLoading', false);
    },
    /* 得意先元帳照会画面からの遷移時 */
    async setInvoiceData(){
      // ログ出力用メソッド名
      if (this.searchConditions.selectBillingNo) {
        await this.search();
      }
    },
    /* 検索処理 */
    async search(){
      // ログ出力用メソッド名
      const functionName = 'search';
      // 初期化を行う
      this.itemList = [];
      this.searchResultDispConditions = {};
      // 明細テーブルのローディングを開始
      this.busy = true;
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      let result;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: selectSql }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          result: result
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      // データ取得時
      let resultData;
      const responseBody = JSON.parse(result.data.executeTransactSql.body)
      resultData = responseBody.data[0];
      if(resultData){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.alertWarning.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      // 明細テーブルのローディングを解除
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      let subList = [];
      for(const data of result){
        subList = data.billing_row === 1 ? [] : subList;
        let subObj = {};
        // フッターをセット
        this.searchResultDispConditions.deliveryDesignDate = formatDate(data.delivery_design_date);
        this.searchResultDispConditions.siteId = data.site_id;
        this.searchResultDispConditions.shippingCode = getNullStr(data.shipping_code);
        this.searchResultDispConditions.deliveryDesignClass = data.delivery_design_class || '';
        this.searchResultDispConditions.deliveryDesign = data.delivery_design || '';
        this.searchResultDispConditions.clientSiteName = data.client_site_name;
        this.searchResultDispConditions.clientSiteZipCode = data.client_site_zip_code;
        this.searchResultDispConditions.clientSiteAddress_1 = data.client_site_address_1;
        this.searchResultDispConditions.clientSiteAddress_2 = data.client_site_address_2;
        this.searchResultDispConditions.deliveryLocation = data.delivery_location;
        this.searchResultDispConditions.checkContents = data.check_contents;
        this.searchResultDispConditions.clientSitePhoneNumber = data.client_site_phone_number;
        this.searchResultDispConditions.processDate = formatDate(data.process_date);
        subObj = {
          productName : !data.product_name ? data.product_id : data.product_id + '：' + data.product_name,
          quantity : data.product_quantity.toLocaleString(),
          unit : data.product_unit,
          unitPrice : data.product_sales_unit_price.toLocaleString(),
          unitAmount : data.product_sales_unit_amount.toLocaleString(),
          originalUnitAmount : data.product_sales_unit_amount,
          _cellVariants: { productName: getListValue(Const.ProductCheckClass, data.product_check_class, 'value', 'color')}
        }
        subList.push(subObj)
        if(data.billing_row === 1){
        
          this.itemList.push({
            billingRow : data.billing_row,
            billingDate : formatDate(data.billing_date),
            billingNo : data.billing_no,
            tradition : getListValue(Const.TraditionList, data.bill_class, 'id', 'text'),
            orderReceiveId : data.order_receive_id,
            closingDate : data.closing_date,
            customerCode : !data.client_name_kanji ? data.client_id : data.client_id + '：' + data.client_name_kanji,
            address1 : data.address_1,
            address2 : data.address_2,
            addressCode : data.zip_code,
            department1 : data.department_1,
            department2 : data.department_2,
            tel : data.phone_number,
            staff : data.staff_id,
            monthlyMonthYear : data.monthly_month_year,
            sub : subList
          })
        }
      }
      // 総件数をdataTableの総件数にセット(伝票番号が1のデータのみ)
      this.totalRows = this.itemList.length;
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){

      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_date'; // 伝票日付
      selectSql += ',cumulative_transaction.billing_no'; // 伝票No
      selectSql += ',cumulative_transaction.billing_row'; // 伝票行No
      selectSql += ',cumulative_transaction.bill_class'; // 伝票種別
      selectSql += ',clients.closing_date'; // 締日
      selectSql += ',cumulative_transaction.client_id'; // 取引先コード
      selectSql += ',clients.client_name_kanji'; // 取引先名
      selectSql += ',clients.address_1'; // 住所1
      selectSql += ',clients.address_2'; // 住所2
      selectSql += ',clients.zip_code'; // 郵便番号
      selectSql += ',clients.department_1'; // 部署1
      selectSql += ',clients.department_2'; // 部署2
      selectSql += ',clients.phone_number'; // TEL
      selectSql += ',cumulative_transaction.staff_id'; // 担当者
      selectSql += ',cumulative_transaction.product_id'; // 製品コード
      selectSql += ',cumulative_transaction.product_name'; // 製品名
      selectSql += ',cumulative_transaction.product_quantity'; // 数量
      selectSql += ',cumulative_transaction.product_unit'; // 単位
      selectSql += ',cumulative_transaction.product_sales_unit_price'; // 単価
      selectSql += ',cumulative_transaction.product_sales_unit_amount'; // 金額
      selectSql += ',cumulative_transaction.delivery_design_date'; // 納品指定日
      selectSql += ',cumulative_transaction.delivery_design_class'; // 納品指定区分
      selectSql += ',cumulative_transaction.delivery_design'; // 納品指定
      selectSql += ',cumulative_transaction.site_id'; // 現場コード
      selectSql += ',cumulative_transaction.shipping_code'; // 配送コード
      selectSql += ',cumulative_transaction.client_site_name'; // 現場名
      selectSql += ',cumulative_transaction.client_site_zip_code'; // 現場郵便番号
      selectSql += ',cumulative_transaction.client_site_address_1'; // 住所1
      selectSql += ',cumulative_transaction.client_site_address_2'; // 住所2
      selectSql += ',cumulative_transaction.delivery_location'; // 納品場所
      selectSql += ',clients.check_contents'; // チェック
      selectSql += ',cumulative_transaction.client_site_phone_number'; // TEL
      selectSql += ',cumulative_transaction.process_date'; // 処理日
      selectSql += ',cumulative_transaction.order_receive_id'; // 受注番号
      selectSql += ',cumulative_transaction.monthly_month_year'; // 月次年月
      selectSql += ',products.product_check_class'; // 製品チェック区分
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      /* 製品マスタ（m_products）と結合 */
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* 取引先マスタ（m_clients）と結合 */
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'AND cumulative_transaction.client_class = clients.client_class ';
      /* WHERE句 */
      // 検索条件が指定された場合
      selectSql += `WHERE cumulative_transaction.transaction_id = '${'UR'}' `
      selectSql += 'AND cumulative_transaction.bill_class IN (11,12,14) ' 
      selectSql += `AND billing_no = ${this.searchConditions.selectBillingNo} ` 
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.billing_date, cumulative_transaction.billing_row';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 売上編集ボタン押下時 */
    async clickEditBtn(item) {
      // 伝票種別が14:直送以外の場合
      if(item.tradition.indexOf('14') !== 0){
        let route = this.$router.resolve({ 
          name: 'SALES-EDIT', 
          query: { billingNo: item.billingNo, monthlyMonthYear: item.monthlyMonthYear }
        });
        window.open(route.href, '_blank');
      // 伝票種別が14:直送の場合
      } else {
        let route = this.$router.resolve({ 
          name: 'SALES-DIRECT-EDIT-NO-ORDER', 
          query: { billingNo: item.billingNo, monthlyMonthYear: item.monthlyMonthYear, parentKbn: 0 }
        });
        window.open(route.href, '_blank');
      }
    },
    /* PDF出力ボタン押下時 */
    outputSalesDirectPdf: function(postItem) {
      // 売上伝票照会PDF出力処理
      console.log('納品書PDF出力処理' + JSON.stringify(postItem));
      // 受注データ・受注番号の有無により、パラメーターを変更する
      let query = {
        receivedOrderId: 0,
        billingNo: this.billingNo,
        monthlyMonthYear: this.monthlyMonthYear,
      };
      let route = this.$router.resolve({ 
        name: 'SALES-DIRECT-NOUHIN', 
        query: query});
      window.open(route.href, '_blank');
    },
    /* 売上伝票詳細ボタン押下時 */
    async openModal(item){
      this.billingNo = item.item.billingNo;
      this.monthlyMonthYear = item.item.monthlyMonthYear;
      this.postItem = item.item.sub;
      this.$bvModal.show('detailsModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
.mainTable {
  align-items:left;
}
.mainTable td{
   padding: 0; 
   border: 0px;
}
.details-modal{
  max-width: inherit;
  width: 98%;
  margin-left: 15px;
}
.input-daterange{
  min-width: 27vw;
}
</style>