<template>
    <div>
        <!-- ●●●上部メニュー●●● -->
        <Header :type="menu_type" :title="title" />
        <div class="container-fluid px-4 py-4 min-vh-85">
            <!-- ●●●コンテンツ●●● -->
            <div class="mt-0">
                <!-- ●●●メインコンテンツ●●● -->
                <div id="content-menu" class="my-2 mx-0" role="main">
                    <!-- ●●●1件目●●● -->
                    <section class="" id="menu-1">
                        <!-- ●●●メニュータイトル●●● -->
                        <div class="user-header alt bg-none">
                            <div class="media">
                                <div class="media-body pb-3">
                                    <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> CSVファイルインポートメニュー</h5>
                                </div>
                            </div>
                        </div>
                        <!-- ●●●メニュー●●● -->
                        <div id="collapse-menu0" class="row collapse show">
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['1']" @shortkey="executeShortcut('csv-import-sales-purchase-price')">
                                <router-link to="/csv-import-sales-purchase-price"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="1" id="menu1-1">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">1</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">売価・仕入単価一括更新</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>CSVファイルインポートによって製品マスタの売価・仕入単価の一括更新を行います。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['2']" @shortkey="executeShortcut('csv-import-clients-sales-price')">
                                <router-link to="/csv-import-clients-sales-price"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="2" id="menu1-2">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">2</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">取引先製品単価一括更新</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>CSVファイルインポートによって取引先製品マスタの売価の一括更新を行います。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-CSV-IMPORT',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'CSVファイルインポートメニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>