/**
 * sendEmail関数はAmplify Authの関数でないため、別途amplifyが自動生成するauthロールにポリシーをアタッチする必要あり
 * {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": [
                "ses:SendEmail",
                "ses:SendRawEmail"
            ],
            "Resource": "*"
        }
    ]
   }
 */
import AWS from 'aws-sdk';
import { getEssentialCredentials } from '@/assets/aws/cognito';
import setting from '@/assets/setting/setting';
import { addOperationLogs } from '@/assets/js/common.js';
const MODULE_NAME = 'ses';

// メール送信
export async function sendEmail(mailInfo) {
  try {
    const ses = new AWS.SES({
      credentials: await getEssentialCredentials(),
      region: setting.region,
    });

    let params = {
      Destination: {
        ToAddresses: mailInfo.ToAddresses // 配列
      },
      Message: {
        Body: {
          Text: { Data: mailInfo.message },
        },

        Subject: { Data: mailInfo.subject },
      },
      Source: mailInfo.fromAddress,
    };
    return ses.sendEmail(params).promise();
  } catch (error) {
    console.log('sendEmail');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'sendEmail', JSON.stringify(mailInfo), error);
    throw error;
  }
}
