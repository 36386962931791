<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-clipboard"></span>請求締次処理メニュー</h5>
                            </div>
                        </div>
                    </div>

                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['1']" @shortkey="executeShortcut('pre-invoice-process')">
                                <router-link to="/pre-invoice-process"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="1" id="menu1-1">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">1</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求前処理{{ addTitle1 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求書発行前に日付や得意先の範囲を設定する画面。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['2']" @shortkey="executeShortcut('invoice-list')">
                                <router-link to="/invoice-list"
                                    target="_blank"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="2" id="menu1-2">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">2</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求一覧表{{ addTitle1 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求前処理{{ addTitle1 }}で設定された範囲内の請求一覧表や請求書を発行する画面。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['3']" @shortkey="executeShortcut('invoice-search')">
                                <router-link to="/invoice-search"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="3" id="menu1-3">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">3</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">得意先元帳発行</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>得意先毎に発行された売上伝票と入金伝票の参照画面です。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['4']" @shortkey="executeShortcut('invoice-check')">
                                <router-link to="/invoice-check"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="4" id="menu1-4">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">4</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求マスタ状況チェック{{ addTitle1 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求前処理{{ addTitle1 }}で設定された請求マスタの件数の表示と削除を行う画面。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['5']" @shortkey="executeShortcut('invoice-update')">
                                <router-link to="/invoice-update"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="5" id="menu1-5">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">5</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求締更新</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求前処理{{ addTitle1 }}で設定された範囲内の請求書の締更新処理を行う。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['6']" @shortkey="executeShortcut('closing-update-completion-list', htmlConst.BillingPaymentClass.billing)">
                                <router-link :to="{ path: `/closing-update-completion-list`, query: { billingPaymentClass: htmlConst.BillingPaymentClass.billing }}"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="6" id="menu1-6">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">6</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求締更新完了一覧</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求書の締更新処理が完了した範囲の一覧を表示する画面。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['7']" @shortkey="executeShortcut('pre-invoice-process2', htmlConst.BillingPaymentClass.billing2)">
                                <router-link :to="{ path: `/pre-invoice-process2`, query: { billingPaymentClass: htmlConst.BillingPaymentClass.billing2 }}"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="7" id="menu1-7" style="background-color: lightblue;">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">7</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求前処理{{ addTitle2 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求書発行前に日付や得意先の範囲を設定する画面。（請求一覧表{{ addTitle2 }}の範囲を指定）</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['8']" @shortkey="executeShortcut('invoice-list2', htmlConst.BillingPaymentClass.billing2)">
                                <router-link :to="{ path: `/invoice-list2`, query: { billingPaymentClass: htmlConst.BillingPaymentClass.billing2 }}"
                                    target="_blank"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="8" id="menu1-8" style="background-color: lightblue;">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">8</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求一覧表{{ addTitle2 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求前処理{{ addTitle2 }}で設定された範囲内の請求一覧表や請求書を発行する画面。</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                                style="border-radius:0;flex-wrap:wrap;"
                                v-shortkey="['9']" @shortkey="executeShortcut('invoice-check2', htmlConst.BillingPaymentClass.billing2)">
                                <router-link :to="{ path: `/invoice-check2`, query: { billingPaymentClass: htmlConst.BillingPaymentClass.billing2 }}"
                                    class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                    data-id="9" id="menu1-9" style="background-color: lightblue;">
                                    <div class="d-flex w-100 p-0">
                                        <i class="fa stct-pci">9</i>
                                        <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求マスタ状況チェック{{ addTitle2 }}</h6>
                                    </div>
                                    <div class="d-flex w-100 p-0">
                                        <ul>
                                            <li>請求前処理{{ addTitle2 }}で設定された請求マスタの件数の表示と削除を行う画面。</li>
                                            <li><span style="font-weight: bold; color: red;">繰越処理を開始する場合は先に本メニューから請求前処理{{ addTitle2 }}において設定されたデータを削除して下さい。</span></li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                </section>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
import Const from '@/assets/js/const.js';
export default {
  name: 'MENU-SALES-CLAIM',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '請求締次処理メニュー',
      // 定数（htmlで使用）
      htmlConst: {
        // 請求支払区分
        BillingPaymentClass: {
          // 請求
          billing: Const.BillingPaymentClass.billing,
          // 支払
          payment: Const.BillingPaymentClass.payment,
          // 請求2
          billing2: Const.BillingPaymentClass.billing2,
        },
      },
      // タイトル用の固定値
      addTitle1: '（本社）',
      addTitle2: '２（横浜）',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path, billingPaymentClass=null) {
      // パスを受け取って遷移
      console.log('shortcut');
      // 請求支払区分が指定された場合、メニューIDをURLに付与
      if (billingPaymentClass != null) {
        this.$router.push({
          path: `/${path}`,
          query: {
            billingPaymentClass: billingPaymentClass,
          }
        });
      } else {
        this.$router.push(`/${path}`);
      }
    },
  },
}
</script>