<template>
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="p-4 min-vh-85">
      <validation-observer ref="observer">
        <b-row>
          <b-col>
            <b-card no-body>
              <b-card-header v-if="isErrorMessages">
                <b-alert show variant="danger" class="mt-2" v-if="errorMessages.length > 0">
                  <ul v-for="(message,index) in errorMessages" :key="index" style="list-style: none;">
                    <li>{{message}}</li>
                  </ul>
                </b-alert>
              </b-card-header>
              <b-card-body class="p-2">
                <h5><a v-b-toggle.collapse-1 title="クリックすると出力範囲指定を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 検索条件</a></h5>
                <b-collapse id="collapse-1" :visible="true" class="px-3">
                  <b-row>
                    <b-col md="6">
                      <b-form-group description="対象の営業所を選択してください。" label="営業所" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                        <b-form-select v-model="searchCondition.officeId" :options="officeList" size="sm"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group description="ハイフン区切りで入力してください。ブランクにすると全置き場指定となります。" label="置き場所" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                        <validation-provider rules="max:11|place" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input maxlength="11" size="sm" type="search" v-model="searchCondition.place" @blur="toUpperSearchPlace"></b-form-input>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group description="製品名でソートします（追加分除く）。一括済ボタンは使用不可となります。" label="製品名ソート" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                        <b-form-checkbox
                          id="isProductNameSort"
                          name="isProductNameSort"
                          v-model="searchCondition.isProductNameSort"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-sm-center">
                    <b-col class="text-center" sm="5" lg="4" xl="3">
                      <b-button block pill size="sm" variant="success" @click="onSearchButtonClick">
                        <span class="oi oi-magnifying-glass"></span> 検索
                      </b-button>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="border px-4 py-3 mb-2 bg-white">
              <b-row>
                <!-- 1ページあたりの表示選択 -->
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="1ページあたりの表示件数"
                    label-for="per-page-select"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- 検索結果検索 -->
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    :bordered="true"
                    :busy="isBusy"
                    :current-page="currentPage"
                    :fields="inventoryInputTableFields"
                    :filter="filter"
                    :filter-function="tableFilter"
                    :items="items"
                    :per-page="perPage"
                    :responsive="true"
                    show-empty
                    small
                    @filtered="onFiltered"
                  >
                    <!-- テーブル読み込み時表示html -->
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>読み込んでいます...</strong>
                      </div>
                    </template>
                    <!-- 済 -->
                    <template #head(finished)>
                      {{'済'}}&nbsp;<span class="oi oi-flag" v-b-tooltip.hover.noninteractive.right="'棚卸の確認を終えたかどうかです。チェックONは確認済み、チェックOFFは未確認。'" />
                    </template>
                    <!-- 置き場所 -->
                    <template #head(place)>
                      {{'置き場所'}}&nbsp;<span class="oi oi-flag" v-b-tooltip.noninteractive.hover.right="'済チェックと連動していません。変更した場合、その製品の置き場所が更新されます。（製品保守の置き場所も更新されます。）'" />
                    </template>
                    <!-- 実棚数 -->
                    <template #head(shelves_count)>
                      {{'実棚数'}}&nbsp;<span class="oi oi-flag" v-b-tooltip.noninteractive.hover.left="'済チェックと連動しています。数値を変更すると自動でチェック済となります。'" />
                    </template>
                    <!-- 済 -->
                    <template #cell(finished)="data">
                      <b-form-checkbox value="1" unchecked-value="0" v-if="data.item.add_flg === 0" v-model="data.item.finished_flg" @change="onFinishedCheckboxChange(data)"></b-form-checkbox>
                    </template>
                    <!-- 置き場所 -->
                    <template #cell(place)="data">
                      <validation-provider rules="required|max:11|place" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-form-input class="text-center" maxlength="11" size="sm" @change="toUpperPlace(data.item);onChangePlace(data);" v-model="data.item.place_all"></b-form-input>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </template>
                    <!-- 実棚数 -->
                    <template #cell(shelves_count)="data">
                      <validation-provider rules="required|between:-999999,9999999" v-slot="{ classes,errors }">
                        <div :class="classes">
                          <b-form-input type="number" class="text-right" size="sm" @change="onChangeShelvesCount(data)" v-model="data.item.shelves_count"></b-form-input>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </template>
                    <!-- 操作 -->
                    <template #cell(operation)="data">
                      <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'対象の製品が削除されます。元に戻すことは不可能です。製品を追加しなおすか、棚卸前処理で最初からやり直して下さい。'" @click="onDeleteButtonClick(data)">
                        <span class="oi oi-delete"></span> 削除
                      </b-button>
                      <!-- 一括済ボタン -->
                      <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'先頭から選択した行までまとめてチェックONとします。'" @click="onBulkChangeCheckButtonClick(true, data.item.sort_order)" class="ml-1" :disabled="data.item.finished_flg=='1'||data.item.add_flg===1||searchResult.isProductNameSort==true">
                        <span class="oi oi-circle-check"></span> 一括済
                      </b-button>
                      <!-- 一括未済ボタン -->
                      <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'選択した行から最終行までまとめてチェックOFFとします。'" @click="onBulkChangeCheckButtonClick(false, data.item.sort_order)" class="ml-1" :disabled="data.item.finished_flg=='0'||data.item.add_flg===1||searchResult.isProductNameSort==true">
                        <span class="oi oi-circle-x"></span> 一括未済
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <!-- 表示レコードをラベル表示 -->
              <b-row v-if="filterRows > 0 || items.length > 0">
                <b-col cols="6">
                  <small>{{ getPagingMessage }}</small>
                </b-col>
                <b-col class="text-right" cols="6">
                  <b-button class="mx-2" size="sm" variant="primary" @click="onAddProductButtonClick"><span class="oi oi-plus"></span> 製品追加</b-button>
                </b-col>
              </b-row>
              <!-- テーブルページネーション -->
              <b-row>
                <b-col class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="getNullStr(filter) != '' ? filterRows : items.length"
                    :per-page="perPage == -1 ? items.length : perPage"
                    align="center"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <Footer />
    <InventoryProductSearch id="inventoryProductSearch" :officeId="searchResult.officeId" @from-child="onCloseInventoryProductSearchModal"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, escapeQuote, executeSelectSql, executeTransactSqlList, isSystemEditable, init, getNullStr, CreateColRow, CreateUpdateSql } from '@/assets/js/common.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_offices, list_m_staffs, list_t_inventories } from '@/graphql/queries';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { executeTransactSql } from '@/graphql/mutations';
import CONFIRM from '@/components/modal/confirm.vue';
import InventoryProductSearch from '@/components/modal/inventory-product-search.vue';
import Const from '@/assets/js/const.js';
import store from '../store';

// モジュール名
const MODULE_NAME = 'inventory-input';
// トーストの表示位置
const TOASTER = 'b-toaster-bottom-right';
// 行カラー
const ROW_VARIANT_FINISHED = 'warning'; // 済にした製品
const ROW_VARIANT_ADD = 'info'; // 追加した製品

export default {
  name: 'INVENTORY-INPUT',
  components: {
    Header,
    Footer,
    InventoryProductSearch,
    CONFIRM
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '棚卸入力',
      // 上限値
      maxUpdateProductCnt: 5000,
      // 製品リストフィールド
      inventoryInputTableFields: DataTblDef.inventory_input_table_fields,
      // 検索条件
      searchCondition: {
        // 営業所コード
        officeId: 0,
        // 置場所
        place: '',
        // 製品名ソート
        isProductNameSort: false,
      },
      // 営業所プルダウンメニューリスト
      officeList: [],
      // 検索結果
      searchResult: {
        officeId: undefined,
        place: undefined,
        isProductNameSort: undefined,
      },
      // 棚卸製品情報リスト
      items: [],
      // 削除対象棚卸製品データ
      deleteData: {},
      // Dangerアラートメッセージ
      errorMessages: [],
      // テーブルビジーフラグ
      isBusy: false,
      // テーブルフィルターキーワード
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      filteredItems: [],
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 変更前実棚数
      oldShelvesCount: 0,
      // ログイン中ユーザー情報
      loginStaffInfo: null,
      // 確認ダイアログ用
      confirmMessage: [],
      // nullを空欄に変換する関数
      getNullStr: getNullStr,
    };
  },
  computed: {
    /**
     * 表示するアラートメッセージかあるかどうかを確認します。
     * @returns {Boolean} メッセージがある場合はtrue、無い場合はfalse
     */
    isErrorMessages() {
      return this.errorMessages.length > 0;
    },
    /**
     * ページの表示件数
     */
    getPagingMessage() {
      const tableLength = getNullStr(this.filter) != '' ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    this.loginStaffInfo = this.$store.getters.user;
    if (await this.setOfficeList()) {
      await this.setOffficeListDefault();
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
      this.filteredItems = filteredItems;
    },
    /**
     * 営業所リストを更新します。
     */
    async setOfficeList() {
      const functionName = 'setOfficeList';

      let listMOfficesResult = null;
      try {
        listMOfficesResult = await API.graphql(graphqlOperation(list_m_offices));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMOfficesResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          result: listMOfficesResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      for (const mOffice of listMOfficesResult.data.list_m_offices) {
        this.officeList.push({
          value: mOffice.office_id,
          text: `${mOffice.office_id}：${mOffice.office_name_kanji}`
        });
      }
      return true;
    },
    /**
     * 営業所リストの初期値を選択します。
     * @returns {Boolean} 正常に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async setOffficeListDefault() {
      const functionName = 'setOffficeListDefault';

      const whereClause = `AND login_id = '${store.getters.user.username}'`;
      let listMStaffsResult = null;
      try {
        listMStaffsResult = await API.graphql(graphqlOperation(list_m_staffs, { where_clause: whereClause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause
        }, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMStaffsResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause,
          result: listMStaffsResult
        });
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      this.searchCondition.officeId = listMStaffsResult.data.list_m_staffs[0].office_id;
      return true;
    },
    /**
     * 検索ボタンクリックイベント処理
     */
    async onSearchButtonClick() {
      const functionName = 'onSearchButtonClick';

      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
        return;
      }

      //メッセージ初期化
      this.errorMessages = [];

      this.isBusy = true;

      // SQL文の作成
      let sql1OrderBy = 'sort_order ASC';
      if (this.searchCondition.isProductNameSort == true) {
        // 製品名ソートがチェックONの場合
        sql1OrderBy = 'product_name ASC,' + sql1OrderBy;
      }
      const sql1 = this.createSearchSQL(0, sql1OrderBy);
      const sql2 = this.createSearchSQL(1, 'ti.created ASC');

      // SQLの実行
      let items1 = null;
      try {
        items1 = await executeSelectSql(sql1);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '棚卸商品の取得に失敗しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.items = [];
        this.currentPage = DataTblDef.currentPage;
        this.searchResult.officeId = '';
        this.searchResult.place = '';
        this.searchResult.isProductNameSort = false;
        this.isBusy = false;
        return;
      }
      let items2 = null;
      try {
        items2 = await executeSelectSql(sql2);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '棚卸商品（追加分）の取得に失敗しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.items = [];
        this.currentPage = DataTblDef.currentPage;
        this.searchResult.officeId = '';
        this.searchResult.place = '';
        this.searchResult.isProductNameSort = false;
        this.isBusy = false;
        return;
      }

      // 検索結果と検索条件の置き場所を覚えておく。
      // 置き場所は済チェックボックスのON/OFFの際のデータ更新に利用します。
      this.items = items1.concat(items2);
      this.searchResult.officeId = this.searchCondition.officeId;
      this.searchResult.place = this.searchCondition.place;
      this.searchResult.isProductNameSort = this.searchCondition.isProductNameSort;
      this.currentPage = DataTblDef.currentPage;

      this.isBusy = false;
    },
    /**
     * 検索用SQLを作成します。
     * @param {Number} add_flg - 追加フラグ
     * @param {String} orderBy - 並び替え項目をカンマ区切りで指定
     * @return {String} 検索用SQL
     */
    createSearchSQL(add_flg, orderBy) {
      let sql = 'SELECT' +
          ' ti.inventory_no' + // 棚卸No.
          ',ti.sort_order' + // 並び順
          ',ti.office_id' + // 営業所コード
          ',ti.product_id' + // 製品コード
          ',ti.product_name' + // 製品名
          ',ti.inventory_count' + // 総棚卸数
          ',ti.shelves_count' + // 総実棚数
          ',ti.add_flg' + // 行追加フラグ
          ',ti.finished_flg' + // 済フラグ
          ',CONCAT(IF(mpd.place_1 is NULL,\'\',TRIM(mpd.place_1)),\'-\'' +
            ',IF(mpd.place_2 is NULL,\'\',TRIM(mpd.place_2)),\'-\'' +
            ',IF(mpd.place_3 is NULL,\'\',TRIM(mpd.place_3)),\'-\'' +
            ',IF(mpd.place_4 is NULL,\'\',TRIM(mpd.place_4))) AS place_all' +
          `,IF(ti.add_flg = 1, '${ROW_VARIANT_ADD}', IF(ti.finished_flg = 1, '${ROW_VARIANT_FINISHED}', NULL)) AS _rowVariant ` +
        'FROM ' +
          't_inventories ti ' +
          'INNER JOIN m_products_details mpd ' +
            'ON mpd.office_id = ti.office_id AND mpd.product_id = ti.product_id ' +
        'WHERE ' +
          'ti.inventory_no = ' +
            '(' +
              'SELECT' +
                ' MAX(inventory_no) ' +
              'FROM ' +
                't_inventories_histories ' +
              'WHERE ' +
                `office_id = ${this.searchCondition.officeId}` +
            ') ' +
          `AND ti.add_flg = ${add_flg} `;
      if (this.searchCondition.place.length > 0) {
        const words = this.searchCondition.place.split('-');
        sql += (words[0] === '') ? 'AND (mpd.place_1 IS NULL OR TRIM(mpd.place_1) = \'\') ' : `AND mpd.place_1 = '${words[0]}' `;
        sql += (words[1] === '') ? 'AND (mpd.place_2 IS NULL OR TRIM(mpd.place_2) = \'\') ' : `AND mpd.place_2 = '${words[1]}' `;
        sql += (words[2] === '') ? 'AND (mpd.place_3 IS NULL OR TRIM(mpd.place_3) = \'\') ' : `AND mpd.place_3 = '${words[2]}' `;
        sql += (words[3] === '') ? 'AND (mpd.place_4 IS NULL OR TRIM(mpd.place_4) = \'\') ' : `AND mpd.place_4 = '${words[3]}' `;
      }

      return sql + 'ORDER BY ' + orderBy + ';';
    },
    /**
     * 製品追加ボタンクリックイベント処理
     */
    onAddProductButtonClick() {
      this.$bvModal.show('inventoryProductSearch');
    },
    /**
     * 棚卸製品検索選択ボタンクリックイベント処理
     * @param {Object} productInfo - 追加対象製品情報
     */
    async onCloseInventoryProductSearchModal(productInfo) {
      const functionName = 'onCloseInventoryProductSearchModal';
      //メッセージ初期化
      this.errorMessages = [];
      // 重複チェック
      let inventoryNo = this.items[0].inventory_no;
      let productId = productInfo.item.product_id;
      let productName = await escapeQuote(productInfo.item.product_name_kanji);
      const whereClause = `AND inventory_no = ${inventoryNo} AND office_id = ${this.searchResult.officeId} AND product_id = ${productId}`;
      let listTInventoriesResult = null;
      try {
        listTInventoriesResult = await API.graphql(graphqlOperation(list_t_inventories, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_inventories',
          where_clause: whereClause
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (listTInventoriesResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_inventories',
          where_clause: whereClause,
          result: listTInventoriesResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      if (listTInventoriesResult.data.list_t_inventories.length !== 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2004']);
        return;
      }

      //選択した製品を登録する
      
      // INSERT SQL
      let sql = '';
      sql += 'INSERT INTO t_inventories( ';
      sql += '    inventory_no, ';
      sql += '    office_id, ';
      sql += '    product_id, ';
      sql += '    sort_order, ';
      sql += '    product_name, ';
      sql += '    inventory_count, ';
      sql += '    shelves_count, ';
      sql += '    inventory_entry_user_id, ';
      sql += '    input_inventory_datetime, ';
      sql += '    add_flg, ';
      sql += '    finished_flg, ';
      sql += '    created, ';
      sql += '    created_user, ';
      sql += '    updated, ';
      sql += '    updated_user ';
      sql += ') ';
      sql += 'SELECT ';
      sql += `    ${inventoryNo}, `;
      sql += `    ${this.searchResult.officeId}, `;
      sql += `    ${productId}, `;
      sql += '    NULL, ';
      sql += `    '${productName}', `;
      sql += '    tmp.balance, ';
      sql += '    tmp.balance, ';
      sql += `    ${this.loginStaffInfo.staff_id}, `;
      sql += '    CURRENT_TIMESTAMP(), ';
      sql += `    '${Const.InventoryInputAddFlag.added}', `;
      sql += '    NULL, ';
      sql += '    CURRENT_TIMESTAMP(), ';
      sql += `    '${this.loginStaffInfo.login_id}', `;
      sql += '    CURRENT_TIMESTAMP(), ';
      sql += `    '${this.loginStaffInfo.login_id}' `;
      sql += 'FROM ';
      sql += '    ( ';
      sql += '        SELECT ';
      sql += '            tmp.balance ';
      sql += '        FROM ';
      sql += '            ( ';
      sql += '                SELECT ';
      sql += '                    0 as balance ';
      sql += '            ) tmp ';
      sql += '        WHERE ';
      sql += '            NOT EXISTS( ';
      sql += '                SELECT ';
      sql += '                    ms.balance ';
      sql += '                FROM ';
      sql += '                    m_stocks ms ';
      sql += '                    INNER JOIN ';
      sql += '                        m_control mc ';
      sql += '                    ON  mc.process_month_year = ms.month_year ';
      sql += '                WHERE ';
      sql += `                    ms.product_id = ${productId} `;
      sql += `                AND ms.office_id = ${this.searchResult.officeId} `;
      sql += '            ) ';
      sql += '        union all ';
      sql += '        SELECT ';
      sql += '            ms.balance + ms.inventory_reserve_count AS balance ';
      sql += '        FROM ';
      sql += '            m_stocks ms ';
      sql += '            INNER JOIN ';
      sql += '                m_control mc ';
      sql += '            ON  mc.process_month_year = ms.month_year ';
      sql += '        WHERE ';
      sql += `            ms.product_id = ${productId} `;
      sql += `        AND ms.office_id = ${this.searchResult.officeId} `;
      sql += '    ) tmp ';
      sql += 'WHERE ';
      sql += '    NOT EXISTS( ';
      sql += '        SELECT ';
      sql += '            * ';
      sql += '        FROM ';
      sql += '            t_inventories ';
      sql += '        WHERE ';
      sql += `            inventory_no = ${inventoryNo} `;
      sql += `        AND office_id = ${this.searchResult.officeId} `;
      sql += `        AND product_id = ${productId} `;
      sql += '    )';

      const sql2 = this.createTInventoriesHistoriesUpdateSQL(inventoryNo);
      const sqlList = [ sql, sql2 ];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlList }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors || result.data.executeTransactSql.statusCode > 200) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      //再検索実施
      await this.onSearchButtonClick();
      //最後ページに遷移
      if (this.perPage > 0) {
        const tableLength = getNullStr(this.filter) != '' ? this.filterRows : this.items.length;
        this.currentPage = Math.ceil(tableLength / this.perPage);
      } else {
        //1ページあたりの表示件数がALLもしくは0（<=0）の場合、1ページを表示
        this.currentPage = 1;
      }

      this.$bvToast.toast(DISP_MESSAGES.SUCCESS['1001'], {
        title: productInfo.item.product_name_kanji,
        toaster: TOASTER,
        variant: 'success'
      });
    },
    /**
     * 実棚数変更処理
     * @param {Object} data - 変更された製品データ
     */
    async onChangeShelvesCount(data) {
      const functionName = 'onChangeShelvesCount';

      //メッセージ初期化
      this.errorMessages = [];

      //入力チェック
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
        return;
      }

      const item = data.item;
      const sqlList = [];
      // 実棚数更新SQL
      sqlList.push(
        'UPDATE t_inventories ' +
        'SET' +
          ` shelves_count = ${item.shelves_count}` + //実棚数
          `,inventory_entry_user_id = ${this.loginStaffInfo.staff_id}` + // 棚卸入力担当者ID 
          ',input_inventory_datetime = CURRENT_TIMESTAMP()' + //棚卸入力日時
          ',updated = CURRENT_TIMESTAMP()' + // 更新日
          `,updated_user = '${this.loginStaffInfo.login_id}' ` + // 更新ユーザー 
        'WHERE ' +
          `inventory_no = ${item.inventory_no} ` +
          `AND office_id = ${item.office_id} ` +
          `AND product_id = ${item.product_id};`
      );
      // 済フラグを更新
      if (item.add_flg === Const.InventoryInputAddFlag.existing) {
        sqlList.push(this.createUpdateFinishedFlgSQL(Const.InventoryInputFinishedFlag.finished, item.sort_order));
      }
      // 棚卸履歴の棚卸入力日時を更新
      sqlList.push(this.createTInventoriesHistoriesUpdateSQL(item.inventory_no));

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlList }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      if (result.errors || result.data.executeTransactSql.statusCode > 200) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      if (item.add_flg === Const.InventoryInputAddFlag.existing) {
        item.finished_flg = Const.InventoryInputFinishedFlag.finished;
        item._rowVariant = ROW_VARIANT_FINISHED;
      }
      /* 複数のチェックボックスを同時更新する処理は後々必要になる可能性があるためコメントアウト
      if (item.add_flg === Const.InventoryInputAddFlag.existing) {
        const limit = (this.currentPage - 1) * this.perPage + data.index;
        for (let i = 0; i <= limit; i++) {
          const currentItem = this.items[i];
          if (currentItem.finished_flg != Const.InventoryInputFinishedFlag.finished) {
            currentItem.finished_flg = Const.InventoryInputFinishedFlag.finished;
            currentItem._rowVariant = ROW_VARIANT_FINISHED;
          }
        }
      }
      */
    },
    /**
     * 置き場所コード変更処理
     * 
     */
    async onChangePlace(data){
      const functionName = 'onChangePlace';
      //入力チェック
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
        return;
      }

      //メッセージ初期化
      this.errorMessages = [];
  
      const item = data.item;
      //置き場所コード取得
      const placeArry = item.place_all.split('-'); 

      // 置き場所更新SQL
      let sql = 'UPDATE m_products_details SET ';
      sql += (placeArry[0] === '') ? ' place_1 = NULL ' : ` place_1 = '${placeArry[0]}' `; //置き場所１
      sql += (placeArry[1] === '') ? ',place_2 = NULL ' : `,place_2 = '${placeArry[1]}' `; //置き場所２
      sql += (placeArry[2] === '') ? ',place_3 = NULL ' : `,place_3 = '${placeArry[2]}' `; //置き場所３
      sql += (placeArry[3] === '') ? ',place_4 = NULL ' : `,place_4 = '${placeArry[3]}' `; //置き場所４
      sql += ',updated = CURRENT_TIMESTAMP()'; // 更新日
      sql += `,updated_user = '${this.loginStaffInfo.login_id}'`; // 更新ユーザー 
      sql += ' WHERE';
      sql += ` product_id = ${item.product_id}`;
      sql += ` AND office_id = ${item.office_id}`;

      let sqlList =[sql];
      //console.info(sqlList[0]);
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: sqlList };
      //console.info({condition});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      try {
        // executeTransactSqlで実行
        const result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        }
        // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
        //const responseBody = JSON.parse(result.data.executeTransactSql.body);
        // SQL実行でエラーが発生した場合の処理
        if(result.data.executeTransactSql.statusCode > 200) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList,
            result: result
          });
        } else {
          // SQLが正常に終了した際の処理
          this.$bvToast.toast(DISP_MESSAGES.SUCCESS['1003'], {
            title: item.product_name,
            toaster: TOASTER,
            variant: 'success'
          });
          /*
          if (responseBody.data) {
            // SELECT文の結果はresponseBody.dataとして返却される
            // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
            for (const rows of responseBody.data) {
              console.dir(rows, {depth: null});
            }
          }
          */
        }
        //console.info(JSON.parse(result.data.executeTransactSql.body));
      } catch (error) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList
        }, error);
      }
    },
    /**
     * 削除ボタンクリックイベント処理
     * @param {Object} data - 削除する棚卸製品情報
     */
    async onDeleteButtonClick(data) {
      this.deleteData = data;
      this.confirmMessage = [];
      this.confirmMessage.push('選択された棚卸製品データを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /**
     * 棚卸製品削除処理
     * @param {Boolean} pkFlg - 削除確認結果
     */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);

      // モーダルから渡された値の有無チェック
      if (okFlg) {
        //メッセージ初期化
        this.errorMessages = [];

        const item = this.deleteData.item;

        // 実棚数更新SQL
        const sql1 = 'DELETE FROM t_inventories ' +
          'WHERE ' +
            `inventory_no = ${item.inventory_no} ` +
            `AND office_id = ${item.office_id} ` +
            `AND product_id = ${item.product_id}`;
        const sql2 = this.createTInventoriesHistoriesUpdateSQL(item.inventory_no);
        const sqlList = [ sql1, sql2 ];
  
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.errorMessages.push(msg);
            return;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        }
  
        try {
          // executeTransactSqlで実行
          const result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlList }));
          if (result.errors) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqlList
            });
            this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
            return;
          }
          // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
          //const responseBody = JSON.parse(result.data.executeTransactSql.body);
          // SQL実行でエラーが発生した場合の処理
          if(result.data.executeTransactSql.statusCode > 200) {
            this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqlList,
              result: result
            });
          } else {
            // SQLが正常に終了した際の処理
  
            //b-tableから該当製品を削除
            const index = (this.currentPage - 1) * this.perPage + this.deleteData.index;
            this.items.splice(index, 1);
            this.$bvToast.toast(DISP_MESSAGES.SUCCESS['1002'], {
              title: item.product_name,
              toaster: TOASTER,
              variant: 'success'
            });
            /*
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, {depth: null});
              }
            }
            */
          }
          //console.info(JSON.parse(result.data.executeTransactSql.body));
        } catch (error) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList
          }, error);
        }
      }
    },
    /**
     * 済チェックボックス変更処理
     * @param {Object} data - 変更されたチェックボックスの項目データ
     */
    async onFinishedCheckboxChange(data) {
      const functionName = 'onFinishedCheckboxChange';
      this.isBusy = true;

      // 済フラグを更新
      const sql1 = this.createUpdateFinishedFlgSQL(data.item.finished_flg, data.item.sort_order);
      // 棚卸履歴の棚卸入力日時を更新
      const sql2 = this.createTInventoriesHistoriesUpdateSQL(data.item.inventory_no);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
  
      if (!await executeTransactSqlList([ sql1, sql2 ], MODULE_NAME, functionName)) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '済フラグ更新に失敗しました。');
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isBusy = false;
        return;
      }

      if (data.item.finished_flg == Const.InventoryInputFinishedFlag.unfinished) {
        // チェックを外した場合
        delete data.item._rowVariant;
      } else {
        // チェックを付けた場合
        data.item._rowVariant = ROW_VARIANT_FINISHED;
      }
      /* 複数のチェックボックスを同時更新する処理は後々必要になる可能性があるためコメントアウト
      // 画面のチェックボックスを更新
      if (data.item.finished_flg == Const.InventoryInputFinishedFlag.unfinished) {
        // チェックを外した場合
        delete data.item._rowVariant;
        for (let i = index + 1; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.add_flg === Const.InventoryInputAddFlag.added) {
            // 追加データまで到達した場合は終わり
            break;
          }
          if (item.finished_flg == Const.InventoryInputFinishedFlag.finished) {
            item.finished_flg = Const.InventoryInputFinishedFlag.unfinished;
            delete item._rowVariant;
          }
        }
      } else {
        // チェックを付けた場合
        data.item._rowVariant = ROW_VARIANT_FINISHED;
        for (let i = 0; i < index; i++) {
          const item = this.items[i];
          if (item.finished_flg == Const.InventoryInputFinishedFlag.unfinished) {
            item.finished_flg = Const.InventoryInputFinishedFlag.finished;
            item._rowVariant = ROW_VARIANT_FINISHED;
          }
        }
      }
      */
      this.isBusy = false;
    },
    /**
     * 棚卸履歴の棚卸入力日時更新用SQLを作成します。
     * @param {Number} inventoryNo - 棚卸No.
     * @returns {String} SQL文
     */
    createTInventoriesHistoriesUpdateSQL(inventoryNo) {
      return 'UPDATE t_inventories_histories ' +
        'SET' +
          ' input_inventory_datetime = CURRENT_TIMESTAMP()' +
          ',updated = CURRENT_TIMESTAMP()' +
          `,updated_user = '${this.loginStaffInfo.login_id}' ` +
        'WHERE ' +
          `inventory_no = ${inventoryNo};`;
    },
    /**
     * 棚卸商品の済フラグを更新するSQLを作成します。
     * @param {Number or String} finishedFlg - 済フラグ
     * @param {Number} sortOrder - 表示順
     * @return {String} SQL文
     */
    createUpdateFinishedFlgSQL(finishedFlg, sortOrder) {
      let sqlPlacePart = '';
      if (this.searchResult.place.length > 0) {
        const words = this.searchResult.place.split('-'); 
        sqlPlacePart += (words[0] === '') ? 'AND (mpd.place_1 IS NULL OR TRIM(mpd.place_1) = \'\') ' : `AND mpd.place_1 = '${words[0]}' `;
        sqlPlacePart += (words[1] === '') ? 'AND (mpd.place_2 IS NULL OR TRIM(mpd.place_2) = \'\') ' : `AND mpd.place_2 = '${words[1]}' `;
        sqlPlacePart += (words[2] === '') ? 'AND (mpd.place_3 IS NULL OR TRIM(mpd.place_3) = \'\') ' : `AND mpd.place_3 = '${words[2]}' `;
        sqlPlacePart += (words[3] === '') ? 'AND (mpd.place_4 IS NULL OR TRIM(mpd.place_4) = \'\') ' : `AND mpd.place_4 = '${words[3]}' `;
      }
      return (finishedFlg == Const.InventoryInputFinishedFlag.unfinished)
        ? 'UPDATE ' +
            't_inventories ti ' +
            'INNER JOIN m_products_details mpd ' +
              'ON mpd.office_id = ti.office_id AND mpd.product_id = ti.product_id ' +
          'SET' +
            ` ti.inventory_entry_user_id = ${this.loginStaffInfo.staff_id}` + // 棚卸入力担当者ID 
            `,ti.finished_flg = ${Const.InventoryInputFinishedFlag.unfinished} ` + // 済フラグ
            ',ti.updated = CURRENT_TIMESTAMP()' + // 更新日
            `,ti.updated_user = '${this.loginStaffInfo.login_id}' ` + // 更新ユーザー 
          'WHERE ' +
            `ti.inventory_no = ${this.items[0].inventory_no} ` +
            sqlPlacePart +
            `AND ti.add_flg = ${Const.InventoryInputAddFlag.existing} ` +
            `AND ti.finished_flg = ${Const.InventoryInputFinishedFlag.finished} ` +
            `AND ti.sort_order = ${sortOrder}`
        : 'UPDATE ' +
            't_inventories ti ' +
            'INNER JOIN m_products_details mpd ' +
              'ON mpd.office_id = ti.office_id AND mpd.product_id = ti.product_id ' +
          'SET' +
            ` ti.inventory_entry_user_id = ${this.loginStaffInfo.staff_id}` + // 棚卸入力担当者ID 
            `,ti.finished_flg = ${Const.InventoryInputFinishedFlag.finished} ` + // 済フラグ
            ',ti.updated = CURRENT_TIMESTAMP()' + // 更新日
            `,ti.updated_user = '${this.loginStaffInfo.login_id}' ` + // 更新ユーザー 
          'WHERE ' +
            `ti.inventory_no = ${this.items[0].inventory_no} ` +
            sqlPlacePart +
            `AND ti.add_flg = ${Const.InventoryInputAddFlag.existing} ` +
            `AND ti.finished_flg = ${Const.InventoryInputFinishedFlag.unfinished} ` +
            `AND ti.sort_order = ${sortOrder}`;
    },
    /**
     * カスタムフィルター関数
     * @param {{}} row - 行データ
     * @param {String} filterprop - フィルター文字列
     * @return {Boolean} true:行を表示、false:行を非表示
     */
    tableFilter: function(row, filterprop) {
      let convertFilterprop = this.toHalfWidthKatakana(this.hiraToKana(this.toHalfWidthLowercase(filterprop.replace(/ /g, ''))));
      
      let isProductIdFilter = getNullStr(row.product_id).includes(convertFilterprop);
      if (isProductIdFilter == true) {
        return true;
      }
      let convertProductName = this.toHalfWidthKatakana(this.hiraToKana(this.toHalfWidthLowercase(getNullStr(row.product_name.replace(/ /g, '')))));
      let isProductNameFilter = convertProductName.includes(convertFilterprop);
      if (isProductNameFilter == true) {
        return true;
      }

      return false;
    },
    /**
     * 英数文字の半角小文字変換
     * @param {String} str - 変換前文字列
     * @return {String} 変換後文字列
     */
    toHalfWidthLowercase: function(str) {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      }).toLowerCase();
    },
    /**
     * 平仮名を片仮名に変換
     * @param {String} str - 変換前文字列
     * @return {String} 変換後文字列
     */
    hiraToKana: function(str) {
      return str.replace(/[\u3041-\u3096]/g, function(match) {
        var chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
      });
    },
    /**
     * 全角カタカナの半角カタカナ変換
     * @param {String} str - 変換前文字列
     * @return {String} 変換後文字列
     */
    toHalfWidthKatakana: function(str) {
      // 半角カタカナに変換するマップ
      var kanaMap = {
        'ガ': 'ｶﾞ', 'ギ': 'ｷﾞ', 'グ': 'ｸﾞ', 'ゲ': 'ｹﾞ', 'ゴ': 'ｺﾞ',
        'ザ': 'ｻﾞ', 'ジ': 'ｼﾞ', 'ズ': 'ｽﾞ', 'ゼ': 'ｾﾞ', 'ゾ': 'ｿﾞ',
        'ダ': 'ﾀﾞ', 'ヂ': 'ﾁﾞ', 'ヅ': 'ﾂﾞ', 'デ': 'ﾃﾞ', 'ド': 'ﾄﾞ',
        'バ': 'ﾊﾞ', 'ビ': 'ﾋﾞ', 'ブ': 'ﾌﾞ', 'ベ': 'ﾍﾞ', 'ボ': 'ﾎﾞ',
        'パ': 'ﾊﾟ', 'ピ': 'ﾋﾟ', 'プ': 'ﾌﾟ', 'ペ': 'ﾍﾟ', 'ポ': 'ﾎﾟ',
        'ヴ': 'ｳﾞ', 'ヷ': 'ﾜﾞ', 'ヺ': 'ｦﾞ',
        'ア': 'ｱ', 'イ': 'ｲ', 'ウ': 'ｳ', 'エ': 'ｴ', 'オ': 'ｵ',
        'カ': 'ｶ', 'キ': 'ｷ', 'ク': 'ｸ', 'ケ': 'ｹ', 'コ': 'ｺ',
        'サ': 'ｻ', 'シ': 'ｼ', 'ス': 'ｽ', 'セ': 'ｾ', 'ソ': 'ｿ',
        'タ': 'ﾀ', 'チ': 'ﾁ', 'ツ': 'ﾂ', 'テ': 'ﾃ', 'ト': 'ﾄ',
        'ナ': 'ﾅ', 'ニ': 'ﾆ', 'ヌ': 'ﾇ', 'ネ': 'ﾈ', 'ノ': 'ﾉ',
        'ハ': 'ﾊ', 'ヒ': 'ﾋ', 'フ': 'ﾌ', 'ヘ': 'ﾍ', 'ホ': 'ﾎ',
        'マ': 'ﾏ', 'ミ': 'ﾐ', 'ム': 'ﾑ', 'メ': 'ﾒ', 'モ': 'ﾓ',
        'ヤ': 'ﾔ', 'ユ': 'ﾕ', 'ヨ': 'ﾖ',
        'ラ': 'ﾗ', 'リ': 'ﾘ', 'ル': 'ﾙ', 'レ': 'ﾚ', 'ロ': 'ﾛ',
        'ワ': 'ﾜ', 'ヲ': 'ｦ', 'ン': 'ﾝ',
        'ァ': 'ｧ', 'ィ': 'ｨ', 'ゥ': 'ｩ', 'ェ': 'ｪ', 'ォ': 'ｫ',
        'ッ': 'ｯ', 'ャ': 'ｬ', 'ュ': 'ｭ', 'ョ': 'ｮ',
        '。': '｡', '、': '､', 'ー': 'ｰ', '「': '｢', '」': '｣', '・': '･',
        '　': '',
      };
      var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
      return str
        .replace(reg, function (match) {
          return kanaMap[match];
        })
        .replace(/゛/g, 'ﾞ')
        .replace(/゜/g, 'ﾟ');
    },
    /**
     * 置き場所（検索条件）の小文字を大文字に置換
     */
    toUpperSearchPlace: function() {
      if (getNullStr(this.searchCondition.place) != '') {
        this.searchCondition.place = this.searchCondition.place.toUpperCase();
      }
    },
    /**
     * 置き場所（検索結果）の小文字を大文字に置換
     * @param {{}} row - 変更対象の置き場所の行
     */
    toUpperPlace: function(row) {
      if (getNullStr(row.place_all) != '') {
        row.place_all = row.place_all.toUpperCase();
      }
    },
    /**
     * 一括チェックボックス変更ボタン押下処理
     * @param {Boolean} isChangeFinished - 済ボタンの場合true、未済ボタンの場合false
     * @param {Number} sortOrder - 並び順
     */
    async onBulkChangeCheckButtonClick(isChangeFinished, sortOrder) {
      const functionName = 'onBulkChangeCheckButtonClick';
      try {
        //メッセージ初期化
        this.errorMessages = [];
        
        let confirmMessage = '';
        let title = '';
        if (isChangeFinished == true) {
          // 一括済ボタン押下
          confirmMessage = '先頭行から選択行までチェックONに変更します。';
          confirmMessage += 'よろしいですか？';
          title = '一括チェックON確認';
        } else {
          // 一括未済ボタン押下
          confirmMessage = '選択行から最終行までチェックOFFに変更します。';
          confirmMessage += 'よろしいですか？';
          title = '一括チェックOFF確認';
        }
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
          this.$store.commit('setLoading', true);
          this.isBusy = true;
          // 一括チェックボックス変更処理
          await this.execBulkChange(isChangeFinished, sortOrder);
        }
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.isErrorMessages == true) {
        scrollTo(0,0);
      }
      this.isBusy = false;
      this.$store.commit('setLoading', false);
    },
    /**
     * 一括チェックボックス変更処理
     * @param {Boolean} isChangeFinished - 済ボタンの場合true、未済ボタンの場合false
     * @param {Number} sortOrder - 並び順
     */
    async execBulkChange(isChangeFinished, sortOrder) {
      const functionName = 'execBulkChange';

      let itemList = null;
      if (getNullStr(this.filter) != '') {
        itemList = this.filteredItems;
      } else {
        itemList = this.items;
      }
      let index = itemList.findIndex(el => el.sort_order == sortOrder);
      if (index == -1) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      // 済フラグを更新
      let sqlList = this.createBulkUpdateFinishedFlgSQL(isChangeFinished, sortOrder, itemList);
      // 棚卸履歴の棚卸入力日時を更新
      let updateHistoriesSql = this.createTInventoriesHistoriesUpdateSQL(itemList[index].inventory_no);
      sqlList.push(updateHistoriesSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (await executeTransactSqlList(sqlList, MODULE_NAME, functionName) == false) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      //再検索実施（ページは保持）
      let preCurrentPage = this.currentPage;
      await this.onSearchButtonClick();
      const tableLength = getNullStr(this.filter) != '' ? this.filterRows : this.items.length;
      let lastPage = Math.ceil(tableLength / this.perPage);
      if (preCurrentPage <= lastPage) {
        // 最終ページを超過しない場合はページを保持
        this.currentPage = preCurrentPage;
      }
      // SQLが正常に終了した際の処理
      let title = '';
      if (isChangeFinished == true) {
        // 一括済ボタン押下
        title = '一括済';
      } else {
        // 一括未済ボタン押下
        title = '一括未済';
      }
      this.$bvToast.toast(DISP_MESSAGES.SUCCESS['1003'], {
        title: title,
        toaster: TOASTER,
        variant: 'success'
      });
    },
    /**
     * 棚卸商品の済フラグを一括更新するSQLを作成します。
     * @param {Boolean} isChangeFinished - 済ボタンの場合true、未済ボタンの場合false
     * @param {Number} sortOrder - 並び順
     * @param {[]} itemList - 一覧データ
     * @return {[String]} SQL文一覧（更新する製品コードの長さによっては２つ以上の可能性あり）
     */
    createBulkUpdateFinishedFlgSQL(isChangeFinished, sortOrder, itemList) {
      let index = itemList.findIndex(el => el.sort_order == sortOrder);
      let officeId = itemList[index].office_id;
      let inventoryNo = itemList[index].inventory_no;
      let productIdList = [];
      let csvProductIdList = [];
      let sqlList = [];
      if (isChangeFinished == true) {
        // 一括済ボタン押下
        for (let i = 0; i <= index; i++) {
          const item = itemList[i];
          if (item.finished_flg == Const.InventoryInputFinishedFlag.unfinished) {
            productIdList.push(item.product_id);
            if (productIdList.length > this.maxUpdateProductCnt) {
              csvProductIdList.push(productIdList.join(','));
              productIdList = [];
            }
          }
        }
      } else {
        // 一括未済ボタン押下
        for (let i = itemList.length - 1; i >= index; i--) {
          const item = itemList[i];
          if (item.finished_flg == Const.InventoryInputFinishedFlag.finished) {
            productIdList.push(item.product_id);
            if (productIdList.length > this.maxUpdateProductCnt) {
              csvProductIdList.push(productIdList.join(','));
              productIdList = [];
            }
          }
        }
      }
      if (productIdList.length > 0) {
        csvProductIdList.push(productIdList.join(','));
      }
      for(let i = 0; i < csvProductIdList.length; i++) {
        let colList = [];
        // 棚卸入力担当者ID
        colList.push(CreateColRow('inventory_entry_user_id', this.loginStaffInfo.staff_id, 'NUMBER'));
        // 済フラグ
        if (isChangeFinished == true) {
          // 一括済ボタン押下
          colList.push(CreateColRow('finished_flg', Const.InventoryInputFinishedFlag.finished, 'NUMBER'));
        } else {
          // 一括未済ボタン押下
          colList.push(CreateColRow('finished_flg', Const.InventoryInputFinishedFlag.unfinished, 'NUMBER'));
        }
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginStaffInfo.login_id, 'VARCHAR'));
        let updateSql = CreateUpdateSql(colList, 't_inventories');
        updateSql += ' WHERE ';
        updateSql += 'inventory_no = ' + inventoryNo + ' ';
        updateSql += 'AND office_id = ' + officeId + ' ';
        updateSql += 'AND product_id IN (' + csvProductIdList[i] + ') ';
        if (isChangeFinished == true) {
          // 一括済ボタン押下
          updateSql += 'AND finished_flg = ' + Const.InventoryInputFinishedFlag.unfinished + ' ';
        } else {
          // 一括未済ボタン押下
          updateSql += 'AND finished_flg = ' + Const.InventoryInputFinishedFlag.finished + ' ';
        }
        sqlList.push(updateSql);
      }
      return sqlList;
    },
  }
}
</script>