<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="kokyaku in listKokyaku" v-bind:key="kokyaku.clientId + '_' + kokyaku.siteId">
      <div v-for="chouhyou in kokyaku.listChouhyou" v-bind:key="kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page">
        <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
          <TemplateSeikyuStart :id="constData.chouhyouId + kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
          <TemplateSeikyuEnd :id="constData.chouhyouId + kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateSeikyuStart from '@/assets/svg/seikyu_Start.svg';
import TemplateSeikyuEnd from '@/assets/svg/seikyu_End.svg';
import { setPaperA4, setChouhyouBodyStyle, addOperationLogs, formatDate, executeSelectSql, getNullStr, getListValue, getControlMaster, dateConsistency } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { list_m_offices, list_m_service_classes } from '@/graphql/queries';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'tentative-seikyu';

export default {
  name: 'TENTATIVE-SEIKYU',
  /* コンポーネント */
  components: {
    TemplateSeikyuStart,
    TemplateSeikyuEnd,
  },
  /* パラメータ */
  props: ['propClosingDate', 'propBillingMonthYear', 'propBillingStartDate', 'propBillingEndDate', 'propBillingOutputClass', 'propSiteIdStart', 'propSiteIdEnd', 'propCheckedClientIds', 'propInvoiceInputNo','propInvoiceIssueInputDate'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 40,
        cntEnd: 63,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
        addressOfficeId_1: 1,
        addressOfficeId_2: 2,
        textLength_110: 110,
        textLength_150: 150,
        textLength_170: 170,
        textLength_178: 178,
        textLength_180: 180,
        textLength_190: 190,
        textLength_200: 200,
        textLength_210: 210,
        textLength_225: 225,
        textLength_230: 230,
        textLength_280: 280,
        textLength_287: 287,
        textLength_297: 297,
        textLength_300: 300,
        textLength_450: 450,
        textLength_480: 480,
        textLength_510: 510,
        textLength_589: 589,
        textLength_625: 625,
        textLength_636: 636,
        textLength_720: 720,
        textLength_836: 836,
      },
      // ヘッダ
      menu_type: 'user',
      title: '請求書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      listKokyaku: [],
      csvClientId: '',
      // 営業所情報
      officeList: [{
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      },
      {
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      // アラート
      alertWarning: [],
      alertDanger: [],
      // サービス区分
      serviceList: [],
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // パラメータ
      closingDate: null,
      billingMonthYear: null,
      billingStartDate: '',
      billingEndDate: '',
      billingOutputClass: null,
      siteIdStart: null,
      siteIdEnd: null,
      checkedClientIds: '',
      invoiceInputNo: '',
      invoiceIssueInputDate: '',
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  /* マウント */
  async mounted() {
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    document.body.style['padding-top'] = '0px';
    // 初期設定
    await this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '請求書_' + this.billingMonthYear;
  },
  /* 関数群 */
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // パラメータ設定
        this.setParams();
        // パラメータチェック
        if (this.billingMonthYear == null) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
          this.$store.commit('setLoading', false);
          return;
        }
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let serviceListResult = null;
        let controlData = null;
        [serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
          this.getOfficesData(),
        ]);
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 表示データ設定
        await this.setDispData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // パラメータ設定
    setParams: function() {
      // paramsを確認
      if (getNullStr(this.propBillingMonthYear) != '') {
        this.closingDate = this.propClosingDate;
        this.billingMonthYear = this.propBillingMonthYear;
        this.billingStartDate = this.propBillingStartDate
        this.billingEndDate = this.propBillingEndDate;
        this.billingOutputClass = this.propBillingOutputClass;
        this.siteIdStart = this.propSiteIdStart;
        this.siteIdEnd = this.propSiteIdEnd;
        this.checkedClientIds = '';
        for (let i = 0; i < this.propCheckedClientIds.length; i++) {
          if (this.checkedClientIds != '') {
            this.checkedClientIds += ',';
          }
          this.checkedClientIds += this.propCheckedClientIds[i];
        }
        this.invoiceInputNo = this.propInvoiceInputNo;
        this.invoiceIssueInputDate = this.propInvoiceIssueInputDate;
      } else if (getNullStr(this.$route.query.propBillingMonthYear) != '') {
        this.closingDate = this.$route.query.propClosingDate;
        this.billingMonthYear = this.$route.query.propBillingMonthYear;
        this.billingStartDate = this.$route.query.propBillingStartDate
        this.billingEndDate = this.$route.query.propBillingEndDate;
        this.billingOutputClass = this.$route.query.propBillingOutputClass;
        this.siteIdStart = this.$route.query.propSiteIdStart;
        this.siteIdEnd = this.$route.query.propSiteIdEnd;
        this.checkedClientIds = this.$route.query.propCheckedClientIds;
        this.invoiceInputNo = this.$route.query.propInvoiceInputNo;
        this.invoiceIssueInputDate = this.$route.query.propInvoiceIssueInputDate;
      }
    },
    /* 表示データ設定 */
    async setDispData() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      try {
        // 伝票情報取得
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if (dataResult != null && dataResult.length > 0) {
          // 取引先毎、または、現場毎の一覧を作成
          this.initListKokyaku(dataResult);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(dataResult);
        }
        // 顧客（取引先・現場）毎の合計値の置換文字列設定
        await this.createReplacementsTotal();
        if (this.listKokyaku.length > 0) {
          // 作成した置換文字データをSVGファイルに設定
          await this.setChouhyou();
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','請求書'));
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* 検索SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' \'' + this.billingEndDate + '\' AS billing_end_date';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',cumulative_transaction.client_site_name';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.billing_output_class';
      selectSql += ',cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_row';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name';
      selectSql += ',products.product_tax_rate_class_sales';
      selectSql += ',FORMAT(cumulative_transaction.product_quantity,0) AS product_quantity';
      selectSql += ',cumulative_transaction.product_unit';
      selectSql += ',FORMAT(cumulative_transaction.product_sales_unit_price,0) AS product_sales_unit_price';
      selectSql += ',FORMAT(cumulative_transaction.product_sales_unit_amount,0) AS product_sales_unit_amount';
      selectSql += ',FORMAT(cumulative_transaction.product_tax,0) AS product_tax';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_mouth_class AS sundries_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND clients.client_id = cumulative_transaction.client_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'cumulative_transaction.billing_date BETWEEN \'' + this.billingStartDate + '\' AND \'' + this.billingEndDate + '\' ';
      selectSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql += 'AND clients.closing_date = ' + this.closingDate + ' ';
      selectSql += 'AND clients.billing_output_class = ' + this.billingOutputClass + ' ';
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      // 得意先コード
      selectSql += 'AND cumulative_transaction.client_id IN (' + this.checkedClientIds + ') ';
      // 現場コード（現場別の場合のみ）
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        // 現場別の場合、0は除外（0は取引先全体）
        selectSql += 'AND cumulative_transaction.site_id <> 0 ';
        if (this.siteIdStart != '' && this.siteIdEnd == '') {
          selectSql += 'AND cumulative_transaction.site_id >= ' + this.siteIdStart + ' ';
        }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
          selectSql += 'AND cumulative_transaction.site_id <= ' + this.siteIdEnd + ' ';
        }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
          selectSql += 'AND cumulative_transaction.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
        }
      }
      /* ORDER BY句 */
      // ソート（取引先別、現場別によって異なる）
      if (this.billingOutputClass == Const.BillingOutputClass.client) {
        // 取引先別
        selectSql += 'ORDER BY cumulative_transaction.client_id,cumulative_transaction.billing_date,cumulative_transaction.billing_no,cumulative_transaction.billing_row';
      } else {
        // 現場別
        selectSql += 'ORDER BY cumulative_transaction.client_id,cumulative_transaction.site_id,cumulative_transaction.billing_date,cumulative_transaction.billing_no,cumulative_transaction.billing_row';
      }

      return selectSql;
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      const functionName = 'getOfficesData'
      try {
        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          if (this.constData.addressOfficeId_1 == officeListData[i].office_id ||
            this.constData.addressOfficeId_2 == officeListData[i].office_id) {
            if (this.constData.addressOfficeId_1 == officeListData[i].office_id) {
              this.officeList[0].keyNo = '1';
              this.officeList[0].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
              this.officeList[0].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
              this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
              this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            } else {
              this.officeList[1].keyNo = '2';
              this.officeList[1].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
              this.officeList[1].address = getNullStr(officeListData[i].address_1 + officeListData[i].address_2);
              this.officeList[1].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
              this.officeList[1].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            }
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 顧客（取引先、現場）毎の一覧を作成 */
    initListKokyaku: function(result) {
      //console.log('initListKokyaku');
      let preClientId = ''; // 前行の取引先コード
      let preSiteId = ''; // 前行の現場コード
      let preBillingNo = 0; // 前行の伝票番号
      let billingCnt = 0; // 伝票数
      let chouhyouRowCnt = 0; // 帳票の必要行数
      // DBの結果分ループ
      for (let i = 0; i < result.length; i++) {
        // 取引先別で前行も同じ取引先、または、
        // 現場別で前行も同じ取引先－現場
        if ((this.billingOutputClass == Const.BillingOutputClass.client && result[i].client_id == preClientId) ||
            (this.billingOutputClass == Const.BillingOutputClass.clientSite && result[i].client_id == preClientId && result[i].site_id == preSiteId)) {
          // 伝票番号の切り替えを確認
          if (preBillingNo == result[i].billing_no) {
            chouhyouRowCnt++; // 製品行
          } else {
            chouhyouRowCnt += 3; // 製品行、伝票・現場情報行、空行
            billingCnt++;
            preBillingNo = result[i].billing_no;
          }
        } else {
          if (preClientId != '') {
            // 初回以外の場合、別の顧客（取引先、現場）に移ったタイミングでまとめて一覧に追加
            //console.log(billingCnt);
            this.listKokyaku.push(
              { clientId: preClientId, siteId: this.billingOutputClass == Const.BillingOutputClass.client ? 0 : preSiteId, billingCnt: billingCnt, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
          }
          // 帳票情報の初期化と次の顧客を記録
          chouhyouRowCnt = 2; // 製品行、伝票・現場情報行
          billingCnt = 1;
          preBillingNo = result[i].billing_no;
          preClientId = result[i].client_id;
          preSiteId = result[i].site_id;
        }
      }
      // 初回以外の場合、別の顧客（取引先、現場）に移ったタイミングでまとめて一覧に追加
      //console.log(billingCnt);
      this.listKokyaku.push({ clientId: preClientId, siteId: this.billingOutputClass == Const.BillingOutputClass.client ? 0 : preSiteId, billingCnt: billingCnt, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt){
      //console.log('createListChouhyou');
      let listChouhyou = [];
      if (productCnt <= this.constData.cntStart) {
        // 製品が通常テンプレートの件数で収まる場合（通常帳票1ページのみ）
        listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: []});
      } else {
        let productCntNokori = productCnt;
        let page = 1;
        // 開始帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnStart, replacements: []});
        productCntNokori -= this.constData.cntStart;
        page++;
        // 中間帳票
        while (productCntNokori > this.constData.cntEnd) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
          productCntNokori -= this.constData.cntEnd;
          page++;
        }
        // 終了帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
      }
      return listChouhyou;
    },
    /* 顧客（取引先、現場）毎の置換配列セット */
    async createReplacementsPage(result){
      const functionName = 'createReplacementsPage'
      try {
        //console.log('createReplacementsPage');
        let index = 0;
        let preBillingNo = 0;
        let curClientId = 0;
        let curSiteId = 0;
        let billingFlg = true;
        let chkWidth = false;
        let strWork = '';
        for (let k = 0; k < this.listKokyaku.length; k++) {
          // 現在の顧客を保持
          curClientId = this.listKokyaku[k].clientId;
          curSiteId = this.listKokyaku[k].siteId;
          // 顧客（取引先、現場）変更時の初期化
          preBillingNo = 0;
          billingFlg = true;
          for (let i = 0; i < this.listKokyaku[k].listChouhyou.length; i++) {
            // SVGファイルの置換用文字列
            let replacements = [];
            // お客様番号
            replacements.push({key: '%お客様番号%', value: curClientId, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
            // ページ番号
            replacements.push({key: '%P%', value: this.listKokyaku[k].listChouhyou[i].page + ' / ' + this.listKokyaku[k].listChouhyou.length, textAnchor: 'end', textLength: this.constData.textLength_178, chkWidth: false});
  
            // 開始帳票
            if (this.listKokyaku[k].listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
              // タイトル
              replacements.push({key: '%タイトル%', value: '請　求　書', textAnchor: 'middle', textLength: this.constData.textLength_636, chkWidth: false});
              // 郵便番号
              replacements.push({key: '%郵便番号%', value: result[index].zip_code, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
              // 住所１
              if (getNullStr(result[index].address_1).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%住所１%', value: result[index].address_1, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
              // 住所２
              if (getNullStr(result[index].address_2).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%住所２%', value: result[index].address_2, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
              // 取引先名
              if (getNullStr(result[index].client_name_kanji).length > 12) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%取引先名%', value: result[index].client_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_589, chkWidth: chkWidth});
              // 部署１
              if (getNullStr(result[index].department_1).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%部署１%', value: result[index].department_1, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
              // 部署２
              if (getNullStr(result[index].department_2).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%部署２%', value: result[index].department_2, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
              // 営業所情報
              await this.setOfficesData(replacements);
              // 現場
              if (this.billingOutputClass == Const.BillingOutputClass.client) {
                replacements.push({key: '%現場%', value: '', textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: false});
              } else {
                replacements.push({key: '%現場%', value: '現場：' + getNullStr(result[index].client_site_name), textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: true});
              }
              // 締切日
              replacements.push({key: '%締切日%', value: getNullStr(formatDate(result[index].billing_end_date)) + '締切', textAnchor: 'start', textLength: this.constData.textLength_297, chkWidth: false});
              // 入金予定
              replacements.push({key: '%入金予定%', value: formatDate(result[index].payment_scheduled), textAnchor: 'start', textLength: this.constData.textLength_480, chkWidth: false});
              // 軽減税率の説明
              replacements.push({key: '%税率説明%', value: '「' + this.controlMasterData.lightTaxMark + '」は軽減税率対象項目', textAnchor: 'start', textLength: 440, chkWidth: false});
              // 銀行名
              for (let j = 0; j < Const.bankNameList.length; j++) {
                replacements.push({key: Const.bankNameList[j].key, value: Const.bankNameList[j].value, textAnchor: 'start', textLength: Const.bankNameList[j].textLength, chkWidth: true});
              }
            }
            // 帳票毎に設定可能な製品の件数
            let billingCntByChouhyou = 0;
            if (this.listKokyaku[k].listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
              billingCntByChouhyou = this.constData.cntStart;
            } else {
              billingCntByChouhyou = this.constData.cntEnd;
            }
            // 伝票
            for (let j = 0; j < billingCntByChouhyou; j++) {
              // 先頭以外で伝票番号が切り替わった場合、または、
              // 取引先別で前行も同じ取引先、または、
              // 現場別で前行も同じ取引先－現場、または、
              // テーブルデータの参照が終わった場合
              if (result.length <= index ||
                  (preBillingNo != 0 && preBillingNo != result[index].billing_no) ||
                  (this.billingOutputClass == Const.BillingOutputClass.client && result[index].client_id != curClientId) ||
                  (this.billingOutputClass == Const.BillingOutputClass.clientSite && (result[index].client_id != curClientId || result[index].site_id != curSiteId))) {
                /* 空行 */
                // 日付
                replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: false});
                // 名称
                replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                if (index < result.length) {
                  preBillingNo = result[index].billing_no;
                }
                billingFlg = true;
              }else{
                if (billingFlg == true) {
                  /* 伝票・現場行 */
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: formatDate(result[index].billing_date).substr(2), textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                  // コード
                  if (getNullStr(result[index].billing_no).length > 8) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].billing_no, textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  // 名称
                  if (getNullStr(result[index].client_site_name).length > 20) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%名称' + (j + 1).toString() + '%', value: result[index].client_site_name, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
                  // 数量
                  replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                  // 単価
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                  billingFlg = false;
                } else {
                  if (result[index].product_id == '0') {
                    /* 消費税行 */
                    // 日付
                    replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: false});
                    // 名称
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: false});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                    // 金額
                    if (getNullStr(result[index].product_tax).length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_tax, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  } else {
                    /* 製品行 */
                    // 日付
                    replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: true});
                    // コード
                    if (getNullStr(result[index].client_site_name).length > 8) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    // 名称
                    strWork = getNullStr(result[index].product_name);
                    if (getNullStr(result[index].product_tax_rate_class_sales) == Const.ProductTaxRateClass.lightTax) {
                      strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                    }
                    if (getNullStr(strWork).length > 20) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
                    // 数量
                    if (getNullStr(result[index].product_quantity) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                      strWork = '';
                    } else {
                      strWork = result[index].product_quantity + (getNullStr(result[index].product_unit) == '' ? '' : ' ' + result[index].product_unit);
                    }
                    if (getNullStr(strWork).length > 5) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: chkWidth});
                    // 単価
                    if (getNullStr(result[index].service_class) != '') {
                      strWork = getNullStr(getListValue(this.serviceList, result[index].service_class, 'id', 'text'));
                      if (strWork.length > 5) {
                        chkWidth = true;
                      } else {
                        chkWidth = false;
                      }
                      replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    } else {
                      if (getNullStr(result[index].product_quantity) == '0' && getNullStr(result[index].product_sales_unit_price) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                        strWork = '';
                      } else {
                        strWork = getNullStr(result[index].product_sales_unit_price);
                      }
                      if (strWork.length > 9) {
                        chkWidth = true;
                      } else {
                        chkWidth = false;
                      }
                      replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    }
                    // 金額
                    if (getNullStr(result[index].product_quantity) == '0' && getNullStr(result[index].product_sales_unit_price) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                      strWork = '';
                    } else {
                      strWork = getNullStr(result[index].product_sales_unit_amount);
                    }
                    if (strWork.length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  }
                  preBillingNo = result[index].billing_no;
                  index++;
                }
              }
            }
            //console.log('replacements');
            //console.log(replacements);
            this.listKokyaku[k].listChouhyou[i].replacements = replacements;
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for(let i = 0; i < this.officeList.length; i++){
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: this.constData.textLength_150, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: this.constData.textLength_287, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: this.constData.textLength_225, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: this.constData.textLength_225, chkWidth: true});
      }
    },
    /* 顧客（取引先、現場）毎の合計値の置換配列セット */
    async createReplacementsTotal() {
      //console.log('createReplacementsTotal');
      let dataResult = null;
      if (this.billingOutputClass == Const.BillingOutputClass.client) {
        // 取引先別
        let selectSql = '';
        selectSql = await this.makeSelectSqlTotal01();
        //console.log(selectSql);
        dataResult = await executeSelectSql(selectSql);
      } else {
        // 現場別
        let selectSql = '';
        selectSql = await this.makeSelectSqlTotal02();
        //console.log(selectSql);
        dataResult = await executeSelectSql(selectSql);
      }
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        let i = 0;
        // 全顧客参照ループ
        for (i = 0; i < this.listKokyaku.length; i++) {
          if (dataResult.length > i) {
            if (this.billingOutputClass == Const.BillingOutputClass.client) {
              // 取引先別の場合
              if (this.listKokyaku[i].clientId < dataResult[i].client_id) {
                // 合計値になくて、データ一覧の方にある取引先の場合は合計値が0として削除
                this.listKokyaku.splice(i, 1);
                i--;
                continue;
              }
            } else {
              // 現場別の場合
              if ((this.listKokyaku[i].clientId < dataResult[i].client_id) ||
              this.listKokyaku[i].clientId == dataResult[i].client_id && this.listKokyaku[i].siteId < dataResult[i].site_id) {
                // 合計値になくて、データ一覧の方にある取引先の場合は合計値が0として削除
                this.listKokyaku.splice(i, 1);
                i--;
                continue;
              }
            }
            if (this.billingOutputClass == Const.BillingOutputClass.client && (this.listKokyaku[i].clientId != dataResult[i].client_id)) {
              // listKokyakuとdataResultの顧客コードが異なる場合、前月のみのデータのため、合計部分以外が空の顧客を追加
              let blankChouhyou = [{page: 1, tempKbn: this.constData.tempKbnStart, replacements: await this.createReplacementsBlank(dataResult[i])}];
              let blankKokyaku = { clientId: dataResult[i].client_id, siteId: 0, billingCnt: 0, listChouhyou: blankChouhyou }
              this.listKokyaku.splice(i, 0, blankKokyaku);
            } else if (this.billingOutputClass == Const.BillingOutputClass.clientSite && dataResult[i].billings_print_class == 1) {
              // 請求書出力単位区分が「1:現場別」、且つ、現場マスタ.請求書印字区分が「1:請求書用取引先名を印字する」の場合
              for (let j = 0; j < this.listKokyaku[i].listChouhyou[0].replacements.length; j++) {
                if (this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%郵便番号%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%住所１%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%住所２%') {
                  // 郵便番号、住所は削除
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['value'] = '';
                } else if (this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%取引先名%') {
                  // 取引先名には「請求書用取引先名」を設定
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['value'] = dataResult[i].billings_print_client_name;
                }
              }
            }
            await this.pushReplacementsTotal(dataResult, i);
          }
        }
        // 伝票ありの帳票を全部見ても、合計帳票が存在する場合、伝票無し帳票を追加
        while (this.billingOutputClass == Const.BillingOutputClass.client && i < dataResult.length) {
          let blankChouhyou = [{page: 1, tempKbn: this.constData.tempKbnStart, replacements: await this.createReplacementsBlank(dataResult[i])}];
          let blankKokyaku = { clientId: dataResult[i].client_id, siteId: 0, billingCnt: 0, listChouhyou: blankChouhyou }
          this.listKokyaku.push(blankKokyaku);
          await this.pushReplacementsTotal(dataResult, i);
          i++;
        }
      }
    },
    /* 顧客（取引先、現場）毎の合計値の置換配列セット */
    async pushReplacementsTotal(dataResult, i) {
      let preBilling = '';
      let preBillingTax = '';
      let amount = '';
      let amountTax = '';
      let forward = '';
      let forwardTax = '';
      let productAmount = Number(dataResult[i].product_sales_unit_amount).toLocaleString();
      let productAmountTax = (Number(dataResult[i].closing_date_normal_tax) + Number(dataResult[i].closing_date_light_tax)).toLocaleString();
      let billing = productAmount;
      let billingTax = productAmountTax;
      let billingTotal = (Number(dataResult[i].product_sales_unit_amount) + Number(dataResult[i].closing_date_normal_tax) + Number(dataResult[i].closing_date_light_tax)).toLocaleString();
      let normalTaxSubTotal = Number(dataResult[i].closing_date_normal_tax_subtotal).toLocaleString();
      let normalTax = Number(dataResult[i].closing_date_normal_tax).toLocaleString();
      let lightTaxSubTotal = Number(dataResult[i].closing_date_light_tax_subtotal).toLocaleString();
      let lightTax = Number(dataResult[i].closing_date_light_tax).toLocaleString();
      let noTaxSubTotal = (Number(dataResult[i].product_sales_unit_amount) - Number(dataResult[i].closing_date_normal_tax_subtotal) - Number(dataResult[i].closing_date_light_tax_subtotal)).toLocaleString();
      let billingIssueInputDate = formatDate(this.invoiceIssueInputDate);
      let invoiceInputNo = this.invoiceInputNo;
      let taxRate = 0;
      let lightTaxRate = 0;
      if (this.controlMasterData.newTaxStartDate == null ||
        dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
        taxRate = this.controlMasterData.taxRate;
        lightTaxRate = this.controlMasterData.lightTaxRate;
      } else {
        taxRate = this.controlMasterData.newTaxRate;
        lightTaxRate = this.controlMasterData.newLightTaxRate;
      }
      // 前回御請求額
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%前請税抜%', value: preBilling, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%前請消税%', value: preBillingTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      // 御入金額
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%入金税抜%', value: amount, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%入金消税%', value: amountTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      // 差引繰越金額
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%繰越税抜%', value: forward, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%繰越消税%', value: forwardTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      // 売上御買上額
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%売上税抜%', value: productAmount, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%売上消税%', value: productAmountTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      // 今回請求
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請税抜%', value: billing, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請消税%', value: billingTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: true});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請合計%', value: billingTotal, textAnchor: 'end', textLength: this.constData.textLength_280, chkWidth: true});
      // 伝票数
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%伝数%', value: this.listKokyaku[i].billingCnt, textAnchor: 'end', textLength: this.constData.textLength_110, chkWidth: true});
      // 請求年月日
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%請求年月日%', value: billingIssueInputDate, textAnchor: 'start', textLength: 267, chkWidth: false});
      // 事業者登録番号
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%登録番号%', value: invoiceInputNo, textAnchor: 'start', textLength: 397, chkWidth: false});
      // 消費税通常
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通小頭%', value: '消費税' + taxRate + '%課税 小計', textAnchor: 'end', textLength: 440, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通小%', value: normalTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通頭%', value: '消費税' + taxRate + '%', textAnchor: 'end', textLength: 210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通%', value: normalTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      // 消費税軽減
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽小頭%', value: '消費税' + lightTaxRate + '%課税（' + this.controlMasterData.lightTaxMark + '） 小計', textAnchor: 'end', textLength: 440, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽小%', value: lightTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽頭%', value: '消費税' + lightTaxRate + '%', textAnchor: 'end', textLength: 210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽%', value: lightTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      // 消費税非課税
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税非小%', value: noTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
    },
    /* 検索SELECT文字列作成（取引先別合計用） */
    async makeSelectSqlTotal01(){
      /* 請求締更新前の処理月情報あり、前月情報あり */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' \'' + this.billingEndDate + '\' AS billing_end_date';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.billing_class';
      selectSql += ',clients.billing_output_class';
      selectSql += ',SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql += '   ELSE 0 END)';
      selectSql += ' AS closing_date_normal_tax_subtotal';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_normal_tax';
      selectSql += ',SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql += '   ELSE 0 END)';
      selectSql += ' AS closing_date_light_tax_subtotal';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = cumulative_transaction.client_class ';
      selectSql += 'AND clients.client_id = cumulative_transaction.client_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'cumulative_transaction.billing_date BETWEEN \'' + this.billingStartDate + '\' AND \'' + this.billingEndDate + '\' ';
      selectSql += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql += 'AND clients.closing_date = ' + this.closingDate + ' ';
      selectSql += 'AND clients.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql += 'AND cumulative_transaction.client_id IN (' + this.checkedClientIds + ') ';
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.client_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.client_id ';

      return selectSql;
    },
    /* 検索SELECT文字列作成（現場別合計用） */
    async makeSelectSqlTotal02(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',clients.billing_class';
      selectSql += ',clients_sites.billings_print_class';
      selectSql += ',clients_sites.billings_print_client_name';
      selectSql += ',SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql += '   ELSE 0 END)';
      selectSql += ' AS closing_date_normal_tax_subtotal';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_normal_tax';
      selectSql += ',SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql += '   ELSE 0 END)';
      selectSql += ' AS closing_date_light_tax_subtotal';
      selectSql += ',TRUNCATE((SUM(';
      selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql += '   ELSE 0 END) / 100),0)';
      selectSql += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = cumulative_transaction.client_class ';
      selectSql += 'AND clients.client_id = cumulative_transaction.client_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON clients_sites.client_id = cumulative_transaction.client_id ';
      selectSql += 'AND clients_sites.site_id = cumulative_transaction.site_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'cumulative_transaction.billing_date BETWEEN \'' + this.billingStartDate + '\' AND \'' + this.billingEndDate + '\' ';
      selectSql += 'AND cumulative_transaction.site_id <> 0 ';
      selectSql += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql += 'AND clients.closing_date = ' + this.closingDate + ' ';
      selectSql += 'AND clients.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql += 'AND cumulative_transaction.client_id IN (' + this.checkedClientIds + ') ';
      // 現場コード（現場別の場合のみ）
      if (this.siteIdStart != '' && this.siteIdEnd == '') {
        selectSql += 'AND cumulative_transaction.site_id >= ' + this.siteIdStart + ' ';
      }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
        selectSql += 'AND cumulative_transaction.site_id <= ' + this.siteIdEnd + ' ';
      }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
        selectSql += 'AND cumulative_transaction.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.site_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.client_id,cumulative_transaction.site_id ';

      return selectSql;
    },
    /* 顧客（取引先、現場）の置換配列セット（伝票部分は全て空） */
    async createReplacementsBlank(result){
      //console.log('createReplacementsBlank');
      // SVGファイルの置換用文字列
      let replacements = [];
      // お客様番号
      replacements.push({key: '%お客様番号%', value: result.client_id, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
      // ページ番号
      replacements.push({key: '%P%', value: '1 / 1', textAnchor: 'end', textLength: this.constData.textLength_178, chkWidth: false});
      // タイトル
      replacements.push({key: '%タイトル%', value: '請　求　書', textAnchor: 'middle', textLength: this.constData.textLength_636, chkWidth: false});
      // 郵便番号
      replacements.push({key: '%郵便番号%', value: result.zip_code, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
      // 住所１
      replacements.push({key: '%住所１%', value: result.address_1, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: true});
      // 住所２
      replacements.push({key: '%住所２%', value: result.address_2, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: true});
      // 取引先名
      replacements.push({key: '%取引先名%', value: result.client_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_589, chkWidth: true});
      // 営業所情報
      await this.setOfficesData(replacements);
      // 現場
      replacements.push({key: '%現場%', value: '', textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: true});
      // 締切日
      replacements.push({key: '%締切日%', value: getNullStr(formatDate(result.billing_end_date)) + '締切', textAnchor: 'start', textLength: this.constData.textLength_297, chkWidth: false});
      // 入金予定
      replacements.push({key: '%入金予定%', value: formatDate(result.payment_scheduled), textAnchor: 'start', textLength: this.constData.textLength_480, chkWidth: false});
      // 軽減税率の説明
      replacements.push({key: '%税率説明%', value: '「' + this.controlMasterData.lightTaxMark + '」は軽減税率対象項目', textAnchor: 'start', textLength: 440, chkWidth: false});
      // 銀行名
      for (let j = 0; j < Const.bankNameList.length; j++) {
        replacements.push({key: Const.bankNameList[j].key, value: Const.bankNameList[j].value, textAnchor: 'start', textLength: Const.bankNameList[j].textLength, chkWidth: true});
      }
      // 伝票
      for (let j = 0; j < this.constData.cntStart; j++) {
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_200, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_180, chkWidth: false});
        // 名称
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_450, chkWidth: false});
        // 数量
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_190, chkWidth: false});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
        // 金額
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
      }
      return replacements;
    },
    /* 帳票に各種値セット */
    setChouhyou(){
      //console.log('setChouhyou');
      for (let j = 0; j < this.listKokyaku.length; j++) {
        for (let i = 0; i < this.listKokyaku[j].listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + this.listKokyaku[j].clientId + '_' + this.listKokyaku[j].siteId + '_' + this.listKokyaku[j].listChouhyou[i].page);
          this.setReplacements(svgDoc, this.listKokyaku[j].listChouhyou[i].replacements);
          this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    setSize: function(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>