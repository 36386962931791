<template>
  <!-- 現場保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 現場保守入力</strong></h5>
                <router-link to="/site-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <div class="row mt-2">
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                      <validation-provider rules="required|numeric|min:6" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input 
                              type="text"
                              id="clientId" 
                              class="form-control" 
                              v-model="clientId"
                              @input="searchClient(clientId)" 
                              maxlength="6"
                             >
                            <!-- 取引先検索ボタン -->
                            <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                <span class="oi oi-magnifying-glass"></span>
                                <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                              </b-button>
                            </b-input-group-text>
                          </div>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 form-group">
                      <label for="clientName" class="form-label"><strong>取引先名</strong></label>
                      <input type="text" id="clientName" class="form-control" v-model="clientName" readonly>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場コード -->
                    <div class="col form-group">
                      <label for="siteId" class="form-label"><strong>現場コード</strong></label>
                      <validation-provider rules="required|numeric|max:4|between:1,9999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="siteId" name="siteId" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="siteId" maxlength="4">
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 配送コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      <label for="shippingCode" class="form-label"><strong>配送コード</strong></label>
                      <validation-provider rules="max:5" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <div class="input-group">
                            <input type="text" id="shippingCode" class="form-control" v-model="shippingCode" readonly>
                            <!-- 現場保守検索ボタン -->
                            <b-input-group-text @click="showRouteSearchModal()" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                <span class="oi oi-magnifying-glass"></span>
                                <button type="button" v-shortkey="['alt', '2']" @shortkey="showRouteSearchModal()" class="d-none"></button>
                              </b-button>
                            </b-input-group-text>
                            <!-- 配送コードクリアボタン -->
                            <b-button pill variant="success" title="配送コードをクリアします。" @click="clearShippingCode" class="mr-1">
                              <span class="oi oi-circle-x"></span> クリア
                            </b-button>
                          </div>
                          <small class="form-text text-muted">任意項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 配送名 -->
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10">
                      <label for="shippingName" class="form-label"><strong>配送名</strong></label>
                      <input type="text" id="shippingName" class="form-control" v-model="shippingName" readonly>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場名（漢字） -->
                    <div class="col form-group">
                      <label for="clientSiteNameKanji" class="form-label"><strong>現場名（漢字）</strong></label>
                      <validation-provider rules="max:50" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteNameKanji" class="form-control" v-model="clientSiteNameKanji" maxlength="50">
                          <small class="form-text text-muted">任意項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場名（カナ） -->
                    <div class="col form-group">
                      <label for="clientSiteNameKana" class="form-label"><strong>現場名（カナ）</strong></label>
                      <validation-provider rules="required|max:50" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteNameKana" class="form-control" v-model="clientSiteNameKana" maxlength="50">
                          <small class="form-text text-muted">必須項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場郵便番号 -->
                    <div class="col form-group">
                      <label for="clientSiteZipCode" class="form-label"><strong>現場郵便番号</strong></label>
                      <validation-provider rules="zip_code" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteZipCode" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSiteZipCode" maxlength="8">
                          <small class="form-text text-muted">任意項目です。「nnn-nnnn」形式で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場住所１ -->
                    <div class="col form-group">
                      <label for="clientSiteAddress1" class="form-label"><strong>現場住所１</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteAddress1" class="form-control" v-model="clientSiteAddress1" maxlength="34">
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 現場住所２ -->
                    <div class="col form-group">
                      <label for="clientSiteAddress2" class="form-label"><strong>現場住所２</strong></label>
                      <validation-provider rules="max:34" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSiteAddress2" class="form-control" v-model="clientSiteAddress2" maxlength="34">
                          <small class="form-text text-muted">任意項目です。34文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 納品場所 -->
                    <div class="col form-group">
                      <label for="deliveryLocation" class="form-label"><strong>納品場所</strong></label>
                      <validation-provider rules="max:54" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="deliveryLocation" class="form-control" v-model="deliveryLocation" maxlength="54">
                          <small class="form-text text-muted">任意項目です。54文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- TEL -->
                    <div class="col form-group">
                      <label for="clientSitePhoneNumber" class="form-label"><strong>TEL</strong></label>
                      <validation-provider rules="phone|max:21" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientSitePhoneNumber" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSitePhoneNumber" maxlength="21">
                          <small class="form-text text-muted">任意項目です。ハイフン区切りで入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 発注書印字区分 -->
                    <div class="col form-group col-12 col-lg-3">
                      <label for="orderPrintClass" class="form-label"><strong>発注書印字区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select class="form-control" id="orderPrintClass" v-model="orderPrintClass" :options="orderPrintClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 発注書用取引先名 -->
                    <div class="col form-group col-12 col-lg-9">
                      <label for="orderPrintClientName" class="form-label"><strong>発注書用取引先名（※仮伝宛名）</strong></label>
                      <validation-provider :rules="{max: 32}" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="orderPrintClientName" class="form-control" v-model="orderPrintClientName" maxlength="32">
                          <small class="form-text text-muted">任意項目です。32文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 請求書印字区分 -->
                    <div class="col form-group col-12 col-lg-3">
                      <label for="billingPrintClass" class="form-label"><strong>請求書印字区分</strong></label>
                      <validation-provider rules="required|oneOf:0,1" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-select class="form-control" id="billingPrintClass" v-model="billingPrintClass" :options="billingPrintClassList">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                          <small class="form-text text-muted">必須項目です。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 請求書用取引先名 -->
                    <div class="col form-group col-12 col-lg-9">
                      <label for="billingPrintClientName" class="form-label"><strong>請求書用取引先名</strong></label>
                      <validation-provider :rules="{max: 50}" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="billingPrintClientName" class="form-control" v-model="billingPrintClientName" maxlength="50">
                          <small class="form-text text-muted">任意項目です。50文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- アストコード -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="astId" class="form-label"><strong>アストコード</strong></label>
                      <validation-provider rules="alpha_num|max:5" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="astId" class="form-control" v-model="astId" maxlength="5">
                          <small class="form-text text-muted">任意項目です。英数字5文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 相手先現場コード -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="clientControlSiteId" class="form-label"><strong>相手先現場コード</strong></label>
                      <validation-provider rules="alpha_num_spaces|max:10" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="clientControlSiteId" class="form-control" v-model="clientControlSiteId" maxlength="10">
                          <small class="form-text text-muted">任意項目です。英数字、スペース10文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 所管部支店 -->
                    <div class="col form-group col-12 col-md-4">
                      <label for="departmentBranch" class="form-label"><strong>所管部支店</strong></label>
                      <validation-provider rules="max:6" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="departmentBranch" class="form-control" v-model="departmentBranch" maxlength="6">
                          <small class="form-text text-muted">任意項目です。6文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="1"/>
    <!-- ●●●ルートマスタ検索モーダル●●● -->
    <RouteSearch @from-child="closeRouteSearchModal"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { searchClientModal } from '@/assets/js/searchModal.js';
import { API, graphqlOperation } from 'aws-amplify';
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateInsertSql, CreateUpdateSql, isSystemEditable, executeSelectSql, getNullStr } from '@/assets/js/common.js';
import { executeTransactSql } from '@/graphql/mutations';
import Const from '@/assets/js/const.js';
import ClientSearch from '@/components/modal/client-search.vue';
import RouteSearch from '@/components/modal/route-search.vue';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'site-input';

export default {
  name: 'SITE-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    ClientSearch,
    RouteSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場保守入力',
      orderPrintClassList: Const.OrderPrintClassList,
      billingPrintClassList: Const.BillingPrintClassList,
      // 取引先コード
      clientId: '',
      // 取引先名
      clientName: '',
      // 現場コード
      siteId: '',
      // 配送コード
      shippingCode: '',
      // 配送名
      shippingName: '',
      // 現場名（漢字）
      clientSiteNameKanji: '',
      // 現場名（カナ）
      clientSiteNameKana: '',
      // 現場郵便番号
      clientSiteZipCode: '',
      // 現場住所１
      clientSiteAddress1: '',
      // 現場住所２
      clientSiteAddress2: '',
      // 納品場所
      deliveryLocation: '',
      // TEL
      clientSitePhoneNumber: '',
      // 発注書印字区分
      orderPrintClass: '',
      // 発注書用取引先名
      orderPrintClientName: '',
      // 請求書印字区分
      billingPrintClass: '',
      // 請求書用取引先名
      billingPrintClientName: '',
      // アストコード
      astId: '',
      // 相手先現場コード
      clientControlSiteId: '',
      // 所管部支店
      departmentBranch: '',
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 最新現場コード
      latestSiteId: '',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 取引先検索モーダル表示
     */
    showClientSearchModal() {
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const result = await searchClientModal(client_id, 1, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          await this.closeClientSearchModal({detail:{}})
        }
      }
    },
    /**
     * 取引先検索モーダルコールバック
     * @param {Object} choiceData - 選択されたデータ
     */
    async closeClientSearchModal(choiceData) {
      this.clientId = choiceData.clientId;
      this.clientName = choiceData.clientNameKanji;
      if (choiceData.detail.latest_site_id != null) {
        this.siteId = await this.getAutoSiteId(this.clientId, choiceData.detail.latest_site_id);
      } else {
        this.siteId = '';
      }
    },
    /**
     * 取引先内で最新現場コードよりも大きい中で最小の現場コードを取得
     * @param {Int} clientId - 取引先コード
     * @param {Int} latestSiteId - 最新現場コード
     */
    async getAutoSiteId(clientId, latestSiteId) {
      this.errorMessages = [];
      const functionName = 'getAutoSiteId';
      let retSiteId = '';
      // SELECT句
      let selectSql = 'SELECT ';
      selectSql += ' MIN(site_id + 1) AS latest_site_id';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_clients_sites ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += '(site_id + 1) NOT IN (SELECT site_id FROM m_clients_sites WHERE client_id = ' + clientId + ' AND (site_id + 1) > ' + latestSiteId + ') ';
      selectSql += 'AND client_id = ' + clientId + ' ';
      selectSql += 'AND (site_id + 1) > ' + latestSiteId + ' ';
      //console.log(selectSql);

      this.$store.commit('setLoading', true);
      try {
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null && resultData.length > 0) {
          retSiteId = resultData[0].latest_site_id == null ? latestSiteId + 1 : resultData[0].latest_site_id;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      this.$store.commit('setLoading', false);
      return retSiteId;
    },
    /**
     * 配送コード検索モーダル表示
     */
    showRouteSearchModal() {
      this.$bvModal.show('routeSearchModal');
    },
    /**
     * 配送コード検索モーダルコールバック
     * @param {Object} choiceData - 選択されたデータ
     */
    closeRouteSearchModal(choiceData) {
      this.shippingCode = choiceData.shippingCode;
      this.shippingName = choiceData.shippingName;
    },
    /**
     * 配送コードクリアボタン
     */
    clearShippingCode() {
      this.shippingCode = '';
      this.shippingName = '';
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        // 入力エラーがあった場合はエラーの項目へスクロール
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          // 入力エラーが無かった場合はDBへINSERT
          await this.execInsert();
          if (this.errorMessages.length > 0) {
            // INSERT中にエラーが発生した場合は画面の先頭へスクロール
            scrollTo(0,0);
          } else {
            // INSERTに成功した場合は編集画面へ遷移
            this.$router.push({
              name: 'SITE-EDIT',
              query: {
                clientId: this.clientId,
                siteId: this.siteId,
              },
              params: {
                successMessages: [DISP_MESSAGES.SUCCESS['1001']]
              }
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 登録処理
     */
    async execInsert(){
      const functionName = 'execInsert';

      const colList = [];
      // 取引先コード
      colList.push(CreateColRow('client_id', this.clientId, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', this.siteId, 'NUMBER'));
      // 配送コード
      colList.push(CreateColRow('shipping_code', await escapeQuote(this.shippingCode), 'VARCHAR'));
      // 現場名（漢字）
      if (getNullStr(this.clientSiteNameKanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('client_site_name_kanji', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('client_site_name_kanji', await escapeQuote(this.clientSiteNameKanji), 'VARCHAR'));
      }
      // 現場名（カナ）
      colList.push(CreateColRow('client_site_name_kana', await escapeQuote(this.clientSiteNameKana), 'VARCHAR'));
      // 現場郵便番号
      colList.push(CreateColRow('client_site_zip_code', this.clientSiteZipCode, 'VARCHAR'));
      // 現場住所１
      colList.push(CreateColRow('client_site_address_1', await escapeQuote(this.clientSiteAddress1), 'VARCHAR'));
      // 現場住所２
      colList.push(CreateColRow('client_site_address_2', await escapeQuote(this.clientSiteAddress2), 'VARCHAR'));
      // 納品場所
      colList.push(CreateColRow('delivery_location', await escapeQuote(this.deliveryLocation), 'VARCHAR'));
      // TEL
      colList.push(CreateColRow('client_site_phone_number', this.clientSitePhoneNumber, 'VARCHAR'));
      // 発注書印字区分
      colList.push(CreateColRow('order_print_class', this.orderPrintClass, 'NUMBER'));
      // 発注書用取引先名
      colList.push(CreateColRow('order_print_client_name', await escapeQuote(this.orderPrintClientName), 'VARCHAR'));
      // 請求書印字区分
      colList.push(CreateColRow('billings_print_class', this.billingPrintClass, 'NUMBER'));
      // 請求書用取引先名
      colList.push(CreateColRow('billings_print_client_name', await escapeQuote(this.billingPrintClientName), 'VARCHAR'));
      // アストコード
      colList.push(CreateColRow('ast_id', await escapeQuote(this.astId), 'VARCHAR'));
      // 相手先現場コード
      colList.push(CreateColRow('client_control_site_id', await escapeQuote(this.clientControlSiteId), 'VARCHAR'));
      // 所管部支店
      colList.push(CreateColRow('department_branch', await escapeQuote(this.departmentBranch), 'VARCHAR'));
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('created_user', username, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      // SQL実行
      const sql = CreateInsertSql(colList, 'full', 'm_clients_sites');
      let sqls = [];
      sqls.push(sql);
      // 新規の現場コードが取引先内の最新現場コードよりも大きい場合は新規の現場コードを最新現場コードとする
      await this.getLatestSiteId();
      if (this.siteId > this.latestSiteId) {
        sqls.push(await this.updateClients());
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        if (new RegExp(Const.DbMessage.DuplicatePrimaryKey).test(body.error)) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2004']);
        } else {
          logLevel = 'Error';
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        }
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    /**
     * 取引先コード存在チェック
     */
    async getLatestSiteId() {
      // SELECT句
      let selectSql = 'SELECT ';
      selectSql += ' latest_site_id';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_clients ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND client_id = ' + this.clientId + ' ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      this.latestSiteId = resultData[0].latest_site_id;
    },
    /**
     * 取引先マスタ最新現場コード更新SQL作成
     */
    async updateClients() {
      //console.log('取引先マスタ最新現場コード更新SQL作成');
      let colList = [];
      // 最新現場コード
      colList.push(CreateColRow('latest_site_id', this.siteId, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_clients');
      updateSql += ' WHERE ';
      updateSql += 'client_class = ' + Const.ClientClass.customer + ' ';
      updateSql += 'AND client_id = ' + this.clientId + ' ';
      //console.log(updateSql);
      return updateSql;
    },
  }
}
</script>
