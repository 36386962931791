<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="denpyo in listDenpyo" v-bind:key="denpyo.salesNumber">
      <div v-for="billingType in listBillingTypeList" v-bind:key="denpyo.salesNumber + '_' + billingType">
        <div v-if="billingType == constData.billingTypeNohinSei || billingType == constData.billingTypeNohinHikae || billingType == constData.billingTypeSeikyu">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFuku">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinSeiClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFukuClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinSecondClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryo1 || billingType == constData.billingTypeZuryo2">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinReceiptNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryoClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinReceiptClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFukuOrder">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <div v-if="denpyo.clientPrintClass == htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
            <div v-if="denpyo.clientPrintClass != htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondOrderNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryoOrder1 || billingType == constData.billingTypeZuryoOrder2">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <div v-if="denpyo.clientPrintClass == htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinReceiptNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
            <div v-if="denpyo.clientPrintClass != htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinReceiptOrderNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeFusoku">
          <div v-for="chouhyou in denpyo.listFusokuChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeFusokuOrder">
          <div v-for="chouhyou in denpyo.listFusokuChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <div v-if="denpyo.clientPrintClass == htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
            <div v-if="denpyo.clientPrintClass != htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondOrderNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="denpyo.addBlankPage == true" :style="{width: chouhyouSize.width, height: chouhyouSize.height}">&nbsp;</div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import TemplateNouhinNormal from '@/assets/svg/nouhin_Normal.svg';
import TemplateNouhinClientNormal from '@/assets/svg/nouhinClient_Normal.svg';
import TemplateNouhinSecondNormal from '@/assets/svg/nouhinSecond_Normal.svg';
import TemplateNouhinSecondClientNormal from '@/assets/svg/nouhinSecondClient_Normal.svg';
import TemplateNouhinSecondOrderNormal from '@/assets/svg/nouhinSecondOrder_Normal.svg';
import TemplateNouhinReceiptNormal from '@/assets/svg/nouhinReceipt_Normal.svg';
import TemplateNouhinReceiptClientNormal from '@/assets/svg/nouhinReceiptClient_Normal.svg';
import TemplateNouhinReceiptOrderNormal from '@/assets/svg/nouhinReceiptOrder_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, getControlMaster, getFormCounter, formatDate, formatDateCalc, formatCurDate, getListValue, calcTaxNew, getNullStr, executeSelectSql, CreateColRow, CreateInsertSql, CreateUpdateSql, addOperationLogs, executeSqlList, isSystemEditable, setInvoiceIssueList } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { list_m_offices, list_m_staffs, list_m_service_classes } from '@/graphql/queries';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'sales-slip-nouhin-auto';

export default {
  name: 'SALES-SLIP-NOUHIN-AUTO',
  /* コンポーネント */
  components: {
    TemplateNouhinNormal,
    TemplateNouhinClientNormal,
    TemplateNouhinSecondNormal,
    TemplateNouhinSecondClientNormal,
    TemplateNouhinSecondOrderNormal,
    TemplateNouhinReceiptNormal,
    TemplateNouhinReceiptClientNormal,
    TemplateNouhinReceiptOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        productCntByChouhyou: 6,
        chouhyouId: 'idChouhyou',
        addressOfficeId_1: 1,
        addressOfficeId_2: 2,
        billingTypeNohinSei: 1,
        billingTypeNohinFuku: 2,
        billingTypeNohinHikae: 3,
        billingTypeNohinSeiClient: 4,
        billingTypeNohinFukuClient: 5,
        billingTypeSeikyu: 6,
        billingTypeZuryo1: 7,
        billingTypeZuryo2: 8,
        billingTypeZuryoClient: 9,
        billingTypeFusoku: 10,
        billingTypeNohinFukuOrder: 11,
        billingTypeZuryoOrder1: 12,
        billingTypeZuryoOrder2: 13,
        billingTypeFusokuOrder: 14,
        taxId: 0,
        taxName: '消費税',
      },
      // ヘッダ
      menu_type: 'user',
      title: '納品書（帳票）',
      loginId: '',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '315mm',
        height: '222.5mm',
      },
      listDenpyo: [],
      listBillingTypeList: [],
      // 営業所情報
      officeList: [{
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
        Email: '',
      },
      {
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      // 当日
      today: '',
      // 変更後引数
      changeParam: {
        shippingCodeStart: '',
        shippingCodeEnd: '',
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
      },
      // アラート
      alertWarning: [],
      alertDanger: [],
      // トランザクションSQLリスト
      transactSqlList:[],
      // コントロールマスタの情報
      controlMasterData: {
        processMonthYear: 0,
        taxRate: 0,
        newTaxRate: 0,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // パラメータ
      selectSalesOffice: null,
      checkBillingTypeClass: null,
      clientIdStart: null,
      clientIdEnd: null,
      shippingCodeStart: null,
      shippingCodeEnd: null,
      shippingTypeClassStart: null,
      shippingTypeClassEnd: null,
      // 定数（htmlで使用）
      htmlConst: {
        // 発注書印字区分
        OrderPrintClass: {
          customerPrint: Const.OrderPrintClass.customerPrint,
          customerNoPrint: Const.OrderPrintClass.customerNoPrint,
        },
      },
      // 伝票作成情報（「YYYYMMDD(8桁) + 半角スペース + ログイン担当者コード（最大4桁）」）
      createdUserData: '',
      // サービス区分
      serviceList: [],
      // 請求書発行抑止あり
      isCancelInvoiceIssue: false,
      cancelReceivedOrderId: '',
    }
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.selectSalesOffice = this.$route.query.selectSalesOffice;
    this.checkBillingTypeClass = this.$route.query.checkBillingTypeClass;
    this.clientIdStart = this.$route.query.clientIdStart;
    this.clientIdEnd = this.$route.query.clientIdEnd;
    this.shippingCodeStart = this.$route.query.shippingCodeStart;
    this.shippingCodeEnd = this.$route.query.shippingCodeEnd;
    this.shippingTypeClassStart = this.$route.query.shippingTypeClassStart;
    this.shippingTypeClassEnd = this.$route.query.shippingTypeClassEnd;
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '納品書（自動）_' + this.today;
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
        this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 当日をYYYY-MM-DD形式で保持
        this.today = formatCurDate('YYYY-MM-DD');
        // パラメータを修正
        this.changeParamStrRange();
        // 非同期でDBから情報を取得
        let staffListResult = null;
        let condition = {where_clause: 'AND login_id = ' + '\''+ this.loginId + '\''};
        let serviceListResult = null;
        let controlData = null;
        [staffListResult, serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
        ]);
        // コントロールマスタ情報取得
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 伝票作成情報を作成
        let staffListData = staffListResult.data.list_m_staffs;
        this.createdUserData = formatCurDate('YYYYMMDD') + ' ' + staffListData[0].staff_id;
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        let serviceListData = serviceListResult.data.list_m_service_classes;
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 請求書発行抑止ありを初期化
        this.isCancelInvoiceIssue = false;
        this.cancelReceivedOrderId = '';
        // 表示データ設定
        await this.setDispData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listDenpyo = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
      if (this.isCancelInvoiceIssue == true) {
        let message = '一部の伝票を発行しませんでした。請求書発行登録済みの伝票が含まれていました。（受注番号：' + this.cancelReceivedOrderId + '）';
        let options = {
          title: '警告',
          bodyBgVariant: 'warning',
          headerBgVariant: 'warning',
          footerBgVariant: 'danger',
        };
        await this.$bvModal.msgBoxOk(message, options);
      }
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeParamStrRange: function() {
      // ルート（配送コード）
      this.changeParam.shippingCodeStart = this.shippingCodeStart;
      if (this.shippingCodeStart == '*') {
        this.changeParam.shippingCodeStart = '';
      }
      this.changeParam.shippingCodeEnd = this.shippingCodeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeParam.shippingCodeEnd = this.changeParam.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeParam.shippingTypeClassStart = this.shippingTypeClassStart;
      if (this.shippingTypeClassStart == '*') {
        this.changeParam.shippingTypeClassStart = '';
      }
      this.changeParam.shippingTypeClassEnd = this.shippingTypeClassEnd.replace(/9/g, Const.Char.halfMax);
      this.changeParam.shippingTypeClassEnd = this.changeParam.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
    },
    /* 表示データ設定 */
    async setDispData() {
      const functionName = 'setDispData';
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      try {
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if(dataResult != null && dataResult.length > 0) {
          // 伝票種類一覧作成
          this.createBillingTypeList();
          // 営業所情報取得
          await this.getOfficesData();
          // 請求書発行済みの情報設定
          let isInvoiceIssueInput = await setInvoiceIssueList(dataResult);
          if (isInvoiceIssueInput == true) {
            // 請求書発行抑止ありを立てる
            this.isCancelInvoiceIssue = true;
            // 抑止された受注番号を設定
            this.cancelReceivedOrderId = '';
            let cancelReceivedOrderIdList = [];
            for (let i = 0; i < dataResult.length; i++) {
              if (dataResult[i].billings_issue_input_flg == true) {
                let itemIndex = cancelReceivedOrderIdList.findIndex(el => el == dataResult[i].order_receive_id);
                if (itemIndex == -1) {
                  // リストに受注番号が未登録の場合
                  if (cancelReceivedOrderIdList.length >= 5) {
                    this.cancelReceivedOrderId = cancelReceivedOrderIdList.join(',');
                    // 5件以上の場合、5件目以降は「等」でまとめて検索完了
                    this.cancelReceivedOrderId += '等';
                    cancelReceivedOrderIdList = [];
                    break;
                  }
                  cancelReceivedOrderIdList.push(dataResult[i].order_receive_id);
                }
              }
            }
            if (cancelReceivedOrderIdList.length > 0) {
              this.cancelReceivedOrderId = cancelReceivedOrderIdList.join(',');
            }
            // 請求書発行済みの行を削除
            dataResult = dataResult.filter(function (item) {
              return item.billings_issue_input_flg != true;
            });
            if (dataResult.length == 0) {
              this.alertWarning.push(DISP_MESSAGES.WARNING['2069'].replace('%arg1%', this.cancelReceivedOrderId));
              return;
            }
          }
          // 伝票毎の一覧を作成
          await this.initListDenpyo(dataResult);
          if (await this.execSave(dataResult) == false) {
            return;
          }
          // 売上伝票作成チェック
          await this.clearMisakuseiDenpyo(dataResult);
          if (this.listDenpyo.length == 0) {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','納品書'));
            return;
          }
          // 不足データ
          let dataResultFusoku = await this.getFusokuDenpyou();
          // 伝票毎の一覧に不足伝票追加
          this.addListDenpyoFusoku(dataResultFusoku);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(dataResult);
          await this.createReplacementsPageFusoku(dataResultFusoku);
          // 伝票番号毎の空白ページ追加フラグ設定
          this.setAddBlank();
          // 作成した置換文字データをSVGファイルに設定
          this.setChouhyou();
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','納品書'));
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listDenpyo = [];
      }
    },
    /* 検索SELECT文字列作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.zip_code';
      selectSql += ',orders_receives.address_1';
      selectSql += ',orders_receives.address_2';
      selectSql += ',orders_receives.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',orders_receives.department_1';
      selectSql += ',orders_receives.department_2';
      selectSql += ',clients_sites.order_print_class AS client_print_class';
      selectSql += ',clients_sites.order_print_client_name AS client_print_name';
      selectSql += ',orders_receives.client_id';
      selectSql += ',IfNull(ships.sales_issue_date,ships.sales_record_date) AS sales_issue_date';
      selectSql += ',ships.sales_number';
      selectSql += ',CASE WHEN orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.return + ' THEN ' + Const.Tradition.salesReturn + ' ELSE ' + Const.Tradition.salesNormal + ' END AS bill_class';
      selectSql += ',clients.closing_date';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',clients.check_contents';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',ships.shipping_quantity';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_unit_amount';
      selectSql += ',ships.shipping_quantity * orders_receives.order_receive_unit_amount AS order_receive_amount';
      selectSql += ',IfNull(orders_receives.place_1,\'\') AS place_1';
      selectSql += ',IfNull(orders_receives.place_2,\'\') AS place_2';
      selectSql += ',IfNull(orders_receives.place_3,\'\') AS place_3';
      selectSql += ',IfNull(orders_receives.place_4,\'\') AS place_4';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',ships.order_receive_id';
      selectSql += ',ships.order_receive_row';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.tax_fractionation_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.inventory_control_class';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.order_receive_staff_id';
      selectSql += ',orders_receives.case_conversion_class';
      selectSql += ',orders_receives.set_class';
      selectSql += ',orders_receives.service_class';
      selectSql += ',orders_receives.product_manual_input_class AS sundries_class';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      selectSql += 'INNER JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON ships.order_receive_id = orders_receives.order_receive_id ';
      selectSql += 'AND ships.order_receive_row = orders_receives.order_receive_row ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON ships.client_class = clients.client_class ';
      selectSql += 'AND ships.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON orders_receives.client_id = clients_sites.client_id ';
      selectSql += 'AND orders_receives.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 売上伝票発行区分が0のデータ
      selectSql += 'ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      // 配送種別
      selectSql += 'AND ships.shipping_type_class BETWEEN \'' + this.changeParam.shippingTypeClassStart + '\' AND \'' + this.changeParam.shippingTypeClassEnd + '\' ';
      // 営業所コード
      selectSql += 'AND ships.office_id = ' + this.selectSalesOffice + ' ';
      // 伝票種類
      selectSql += 'AND clients.billing_type_class = ' + this.checkBillingTypeClass + ' ';
      // 取引先
      selectSql += 'AND ships.client_class = 1 ';
      selectSql += 'AND ships.client_id BETWEEN ' + this.clientIdStart + ' AND ' + this.clientIdEnd + ' ';
      // ルート（配送コード）
      selectSql += 'AND ships.shipping_code BETWEEN \'' + this.changeParam.shippingCodeStart + '\' AND \'' + this.changeParam.shippingCodeEnd + '\' ';
      // 配送種別（直送は除外）
      selectSql += 'AND ships.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      // 売上計上日が処理年月よりも過去のデータは除外
      selectSql += 'AND ships.sales_record_date >= \'' + formatDate(this.controlMasterData.processMonthYear + '01') + '\' ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY bill_class DESC, ships.shipping_order_number, ships.sales_number, ships.sales_number_row, ships.order_receive_id, ships.order_receive_row ';

      return selectSql;
    },
    /* 伝票番号取得 */
    async getSalesNumber() {
      const functionName = 'getSalesNumber';
      let salesNumber = await getFormCounter(Const.CounterClass.billingNo, this.selectSalesOffice, Const.TransactionId.sales, this.loginId, MODULE_NAME, functionName);
      if (salesNumber != null) {
        return salesNumber;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /* 出力する伝票の種類の一覧を作成 */
    createBillingTypeList: function() {
      if (this.checkBillingTypeClass == Const.BillingTypeClass.normal) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFuku);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo2);
        this.listBillingTypeList.push(this.constData.billingTypeFusoku);
      } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special3) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFukuOrder);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoOrder1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoOrder2);
        this.listBillingTypeList.push(this.constData.billingTypeFusokuOrder);
      } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special1) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinHikae);
        this.listBillingTypeList.push(this.constData.billingTypeSeikyu);
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFuku);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo2);
        this.listBillingTypeList.push(this.constData.billingTypeFusoku);
      } else {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinSeiClient);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFukuClient);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoClient);
        this.listBillingTypeList.push(this.constData.billingTypeFusoku);
      }
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
      let officeListData = officeListResult.data.list_m_offices;
      //console.log(officeListData);
      for(let i = 0; i < officeListData.length; i++){
        if (this.constData.addressOfficeId_1 == officeListData[i].office_id ||
          this.constData.addressOfficeId_2 == officeListData[i].office_id) {
          if (this.constData.addressOfficeId_1 == officeListData[i].office_id) {
            this.officeList[0].keyNo = '1';
            this.officeList[0].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[0].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            this.officeList[0].Email = officeListData[i].e_mail;
          } else {
            this.officeList[1].keyNo = '2';
            this.officeList[1].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[1].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[1].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[1].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
          }
        }
      }
    },
    /* 伝票毎の一覧を作成 */
    async initListDenpyo(result) {
      //console.log('initListDenpyo');
      let salesNumber = await this.getSalesNumber();
      let preOrderReceiveId = 0; // 前行の受注番号
      let preSalesIssueDate = ''; // 前行の伝票日付
      let chouhyouRowCnt = 0; // 帳票の必要行数
      let subTotalNormal = 0; // 伝票毎の小計（通常消費税計算用）
      let subTotalLight = 0; // 伝票毎の小計（軽減消費税計算用）
      let taxCalculationClass = 0; // 税額計算区分（1の場合、消費税行を追加する）
      let clientPrintClass = null;
      let clientPrintName = '';
      // DBの結果分ループ
      for (let i = 0; i < result.length; i++) {
        // 前行も同じ伝票番号
        if (result[i].order_receive_id == preOrderReceiveId) {
          // 消費税の小計を計算
          if (result[i].product_tax_rate_class_sales == Const.ProductTaxRateClass.normalTax) {
            subTotalNormal += result[i].order_receive_amount;
          } else if (result[i].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
            subTotalLight += result[i].order_receive_amount;
          }
          chouhyouRowCnt++; // 製品行
        } else {
          if (preOrderReceiveId != 0) {
            // 税額計算区分が1の場合、消費税行を追加
            if (taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
              result.splice(i, 0, await this.createTaxRow(result[i-1], subTotalNormal, subTotalLight));
              i++;
              chouhyouRowCnt++; // 製品行
            }
            // 初回以外の場合、別の伝票番号に移ったタイミングでまとめて一覧に追加
            //console.log(salesNumber);
            this.listDenpyo.push({ salesNumber: salesNumber, salesIssueDate: formatDate(preSalesIssueDate), orderReceiveId: preOrderReceiveId, listChouhyou: this.createListChouhyou(chouhyouRowCnt), listFusokuChouhyou: null, addBlankPage: false, clientPrintClass: clientPrintClass, clientPrintName: clientPrintName });
            salesNumber = await this.getSalesNumber();
          }
          // 帳票情報の初期化と次の伝票を記録
          chouhyouRowCnt = 1; // 製品行
          preOrderReceiveId = result[i].order_receive_id;
          preSalesIssueDate = result[i].sales_issue_date;
          taxCalculationClass = result[i].tax_calculation_class
          // 消費税の小計を初期化
          if (result[i].product_tax_rate_class_sales == Const.ProductTaxRateClass.normalTax) {
            subTotalNormal = result[i].order_receive_amount;
            subTotalLight = 0;
          } else if (result[i].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
            subTotalNormal = 0;
            subTotalLight = result[i].order_receive_amount;
          } else {
            subTotalNormal = 0;
            subTotalLight = 0;
          }
          clientPrintClass = result[i].client_print_class;
          clientPrintName = result[i].client_print_name;
        }
        result[i].sales_number = salesNumber;
        //console.log(result[i].sales_number);
      }
      // 初回以外の場合、別の伝票番号に移ったタイミングでまとめて一覧に追加
      // 税額計算区分が1の場合、消費税行を追加
      if (taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
        result.push(await this.createTaxRow(result[result.length - 1], subTotalNormal, subTotalLight));
        chouhyouRowCnt++; // 製品行
      }
      //console.log(salesNumber);
      this.listDenpyo.push({ salesNumber: salesNumber, salesIssueDate: formatDate(preSalesIssueDate), orderReceiveId: preOrderReceiveId, listChouhyou: this.createListChouhyou(chouhyouRowCnt), listFusokuChouhyou: null, addBlankPage: false, clientPrintClass: clientPrintClass, clientPrintName: clientPrintName });
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt) {
      //console.log('createListChouhyou');
      let listChouhyou = [];
      let page = 1;
      let productCntNokori = productCnt;
      while (productCntNokori > 0) {
        listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.productCntByChouhyou;
        page++;
      }
      return listChouhyou;
    },
    /* 消費税行作成 */
    async createTaxRow(cloneRow, subTotalNormal, subTotalLight) {
      let taxRow = {};
      // コピー元行の値を全てコピー
      for (let key in cloneRow) {
        taxRow[key] = cloneRow[key];
      }
      // 製品部分を消費税用に変更
      taxRow.product_id = this.constData.taxId;
      taxRow.product_name_kanji = this.constData.taxName;
      taxRow.shipping_quantity = 0;
      taxRow.unit = 0;
      taxRow.order_receive_unit_amount = 0;
      taxRow.place_1 = '';
      taxRow.place_2 = '';
      taxRow.place_3 = '';
      taxRow.place_4 = '';
      // 消費税計算
      taxRow.order_receive_amount = calcTaxNew(taxRow.sales_issue_date, subTotalNormal, subTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);

      return taxRow;
    },
    /* 不足伝票設定 */
    async getFusokuDenpyou() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSqlFusoku(this.getCsvOrderReceiveId());
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      return dataResult;
    },
    /* 受注番号CSV取得 */
    getCsvOrderReceiveId: function() {
      let preOrderReceiveId = '';
      let csvOrderReceiveId = '';
      for (let i = 0; i < this.listDenpyo.length; i++) {
        if (this.listDenpyo[i].orderReceiveId != preOrderReceiveId) {
          if (csvOrderReceiveId != '') {
            csvOrderReceiveId += ',';
          }
          csvOrderReceiveId += this.listDenpyo[i].orderReceiveId;
          preOrderReceiveId = this.listDenpyo[i].orderReceiveId;
        }
      }
      return csvOrderReceiveId;
    },
    /* 検索SELECT文字列作成（不足伝票用） */
    makeSelectSqlFusoku: function(csvOrderReceiveId) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.zip_code';
      selectSql += ',orders_receives.address_1';
      selectSql += ',orders_receives.address_2';
      selectSql += ',orders_receives.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',orders_receives.department_1';
      selectSql += ',orders_receives.department_2';
      selectSql += ',clients_sites.order_print_class AS client_print_class';
      selectSql += ',clients_sites.order_print_client_name AS client_print_name';
      selectSql += ',orders_receives.client_id';
      selectSql += ',CASE WHEN orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.return + ' THEN ' + Const.Tradition.salesReturn + ' ELSE ' + Const.Tradition.salesNormal + ' END AS bill_class';
      selectSql += ',clients.closing_date';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',clients.check_contents';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',orders_receives.remaining_quantity';
      selectSql += ',orders_receives.unit';
      selectSql += ',IfNull(orders_receives.place_1,\'\') AS place_1';
      selectSql += ',IfNull(orders_receives.place_2,\'\') AS place_2';
      selectSql += ',IfNull(orders_receives.place_3,\'\') AS place_3';
      selectSql += ',IfNull(orders_receives.place_4,\'\') AS place_4';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.order_receive_staff_id';
      selectSql += ',orders_receives.service_class';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders_receives.client_class = clients.client_class ';
      selectSql += 'AND orders_receives.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON orders_receives.client_id = clients_sites.client_id ';
      selectSql += 'AND orders_receives.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 受注番号
      selectSql += 'orders_receives.order_receive_id IN (' + csvOrderReceiveId + ') ';
      // 受注残が0以外
      selectSql += 'AND orders_receives.remaining_quantity <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_receives.order_receive_id, orders_receives.order_receive_row ';

      return selectSql;
    },
    /* 伝票毎の一覧に不足一覧を追加 */
    addListDenpyoFusoku: function(result) {
      //console.log('addListDenpyoFusoku');
      let chouhyouRowCnt = 0; // 帳票の必要行数
      let checkOrderReceiveIdList = []; // 不足の確認を終えた受注番号一覧（同じ受注内に複数の伝票がありうるため）
      for (let i = 0; i < this.listDenpyo.length; i++) {
        chouhyouRowCnt = 0;
        // 確認を終えた受注番号の不足伝票は出さない
        if (checkOrderReceiveIdList.includes(this.listDenpyo[i].orderReceiveId) == false) {
          // DBの結果分ループ
          for (let j = 0; j < result.length; j++) {
            if (this.listDenpyo[i].orderReceiveId == result[j].order_receive_id) {
              chouhyouRowCnt++;
            }
          }
          checkOrderReceiveIdList.push(this.listDenpyo[i].orderReceiveId);
        }
        this.listDenpyo[i].listFusokuChouhyou = this.createListChouhyou(chouhyouRowCnt);
      }
    },
    /* 伝票毎の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let index = 0;
      let workIndex = 0;
      let strWork = '';
      let totalMoney = 0;
      let chkWidth = false;
      for (let k = 0; k < this.listDenpyo.length; k++) {
        totalMoney = 0;
        for (let i = 0; i < this.listDenpyo[k].listChouhyou.length; i++) {
          if (index < result.length) {
            workIndex = index;
          } else {
            workIndex = result.length - 1;
          }
          // SVGファイルの置換用文字列
          let replacements = [];
          // ページ番号
          replacements.push({key: '%P%', value: this.listDenpyo[k].listChouhyou[i].page + ' / ' + this.listDenpyo[k].listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
          // タイトル
          replacements.push({key: '%タイトル%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          // 返品表示
          if (result[workIndex].bill_class == Const.Tradition.salesReturn) {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '返品', textAnchor: 'middle', textLength: 585, chkWidth: false});
          } else {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          }
          // 受注日付
          strWork = result[workIndex].order_receive_date == null ? '' : formatDate(result[workIndex].order_receive_date);
          replacements.push({key: '%受注日付%', value: strWork, textAnchor: 'start', textLength: 200, chkWidth: false});
          // 受注番号
          strWork = result[workIndex].order_receive_id == null ? '' : result[workIndex].order_receive_id;
          replacements.push({key: '%受注番号%', value: strWork, textAnchor: 'start', textLength: 250, chkWidth: false});
          // 受注処理担当者
          replacements.push({key: '%受注担当%', value: result[workIndex].order_receive_staff_id, textAnchor: 'start', textLength: 180, chkWidth: false});
          // 郵便番号
          replacements.push({key: '%郵便番号%', value: '〒' + getNullStr(result[workIndex].zip_code), textAnchor: 'start', textLength: 300, chkWidth: false});
          // 住所１
          replacements.push({key: '%取住所1%', value: result[workIndex].address_1, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 住所２
          replacements.push({key: '%取住所2%', value: result[workIndex].address_2, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 取引電話
          replacements.push({key: '%取TEL%', value: result[workIndex].phone_number, textAnchor: 'end', textLength: 720, chkWidth: true});
          // 取引先名
          replacements.push({key: '%取引先名%', value: result[workIndex].client_name_kanji, textAnchor: 'start', textLength: 589, chkWidth: true});
          // 部署１
          replacements.push({key: '%部署1%', value: result[workIndex].department_1, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 部署２
          replacements.push({key: '%部署2%', value: result[workIndex].department_2, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 顧客コード
          replacements.push({key: '%顧客C%', value: result[workIndex].client_id, textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 伝票日付
          replacements.push({key: '%伝日%', value: formatDate(result[workIndex].sales_issue_date), textAnchor: 'start', textLength: 170, chkWidth: true});
          // 伝票番号
          replacements.push({key: '%伝番%', value: this.listDenpyo[k].salesNumber, textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 区分
          replacements.push({key: '%c%', value: result[workIndex].bill_class, textAnchor: 'start', textLength: 60, chkWidth: true});
          // 締日
          if (result[workIndex].closing_date == 99) {
            replacements.push({key: '%締日%', value: formatDateCalc(result[workIndex].sales_issue_date, 0, 0, 0, true, 'DD'), textAnchor: 'start', textLength: 136, chkWidth: false});
          } else {
            replacements.push({key: '%締日%', value: result[workIndex].closing_date, textAnchor: 'start', textLength: 136, chkWidth: false});
          }
          // 担当者
          replacements.push({key: '%担当%', value: result[workIndex].staff_id, textAnchor: 'start', textLength: 120, chkWidth: false});
          // チェック
          replacements.push({key: '%チェック%', value: result[workIndex].check_contents, textAnchor: 'start', textLength: 900, chkWidth: false});
          // 伝票種類が「2：特殊2、4：特殊4」の場合、取引先の追加情報と相手の名称を追加
          if (this.checkBillingTypeClass == Const.BillingTypeClass.special2 || this.checkBillingTypeClass == Const.BillingTypeClass.special4) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'clientName'), textAnchor: 'start', textLength: 467, chkWidth: true});
            // 住所
            replacements.push({key: '%取営住所%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'address'), textAnchor: 'start', textLength: 601, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'tel'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'fax'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先相手名
            if (result[workIndex].client_print_class != Const.OrderPrintClass.customerNoPrint) {
              // 発注書印字区分「0：得意先名を印字する」の場合、固定値
              replacements.push({key: '%取相手名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'partnerName'), textAnchor: 'start', textLength: 589, chkWidth: true});
            } else {
              // 発注書印字区分「1：仮伝名称を印字する」の場合、発注書用取引先名
              replacements.push({key: '%取相手名%', value: result[workIndex].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
            }
          } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special3) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: result[workIndex].client_name_kanji, textAnchor: 'start', textLength: 570, chkWidth: true});
            // 郵便番号
            replacements.push({key: '%取営郵便%', value: '〒' + getNullStr(result[workIndex].zip_code), textAnchor: 'start', textLength: 550, chkWidth: false});
            // 住所１
            replacements.push({key: '%取営住所1%', value: result[workIndex].address_1, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 住所２
            replacements.push({key: '%取営住所2%', value: result[workIndex].address_2, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: result[workIndex].phone_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: result[workIndex].fax_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先相手名
            replacements.push({key: '%取相手名%', value: result[workIndex].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
          }
          // 説明
          replacements.push({key: '%説明%', value: '', textAnchor: 'start', textLength: 585, chkWidth: false});
          // 納品指定
          strWork = result[workIndex].delivery_design_date == null ? '' : formatDate(result[workIndex].delivery_design_date);
          strWork += '    ' + getNullStr(result[workIndex].delivery_design);
          replacements.push({key: '%納品指定%', value: strWork, textAnchor: 'start', textLength: 760, chkWidth: true});
          // 納品指定区分
          replacements.push({key: '%納区%', value: result[workIndex].delivery_design_class, textAnchor: 'end', textLength: 110, chkWidth: true});
          // 現場コード
          replacements.push({key: '%現場C%', value: result[workIndex].site_id, textAnchor: 'start', textLength: 150, chkWidth: false});
          // 配送コード
          replacements.push({key: '%配送C%', value: result[workIndex].shipping_code, textAnchor: 'start', textLength: 204, chkWidth: false});
          // 現場名
          replacements.push({key: '%現場名%', value: result[workIndex].client_site_name_kanji, textAnchor: 'start', textLength: 1351, chkWidth: true});
          // 現場郵便番号
          replacements.push({key: '%現郵便%', value: '〒' + getNullStr(result[workIndex].client_site_zip_code), textAnchor: 'start', textLength: 170, chkWidth: false});
          // 現場住所1
          replacements.push({key: '%現住所1%', value: result[workIndex].client_site_address_1, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 現場住所2
          replacements.push({key: '%現住所2%', value: result[workIndex].client_site_address_2, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 納品場所
          replacements.push({key: '%納品場所%', value: result[workIndex].delivery_location, textAnchor: 'start', textLength: 1000, chkWidth: true});
          // 現場TEL
          replacements.push({key: '%現TEL%', value: result[workIndex].client_site_phone_number, textAnchor: 'end', textLength: 400, chkWidth: true});
          // 帳票毎に設定可能な製品の件数
          let productCntByChouhyou = this.constData.productCntByChouhyou;
          // 製品
          totalMoney = 0;
          for (let j = 0; j < productCntByChouhyou; j++) {
            // テーブルデータの参照が終わった場合
            // 同伝票内の製品の出力が全て終わった場合
            if (result.length <= index ||
            result[index].sales_number != this.listDenpyo[k].salesNumber) {
              /* 空行 */
              // コード
              replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 220, chkWidth: false});
              // 置場所
              replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 製品名
              replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 数量
              replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
              // 単価
              replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
              // 金額
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
            }else{
              if (result[index].product_id == this.constData.taxId && result[index].product_name_kanji == this.constData.taxName) {
                // 消費税行
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 220, chkWidth: false});
                // 置場所
                replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
                // 製品名
                replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: result[index].product_name_kanji, textAnchor: 'start', textLength: 610, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].order_receive_amount.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: false});
              } else {
                // 上記以外（通常の製品行）
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: 220, chkWidth: false});
                // 置場所
                if (result[index].place_1.trim() == '' && result[index].place_2.trim() == '' && result[index].place_3.trim() == '' && result[index].place_4.trim() == '') {
                  strWork = '';
                } else {
                  strWork = '';
                  // TODO 常に非表示、但し、後に表示非表示を出しわけるように修正の可能性があるため、コメントアウト
                  //strWork = ('00' + result[index].place_1.trim()).slice(-2) + '-' + ('00' + result[index].place_2.trim()).slice(-2) + '-' + ('00' + result[index].place_3.trim()).slice(-2) + '-' + ('00' + result[index].place_4.trim()).slice(-2);
                }
                replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: false});
                // 製品名
                strWork = result[index].product_name_kanji;
                if (result[index].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
                  strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                }
                replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: true});
                // 数量
                strWork = result[index].shipping_quantity.toLocaleString();
                if (getNullStr(strWork) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                  strWork = '';
                } else {
                  strWork += getNullStr(result[index].unit) == '' ? '' : ' ' + result[index].unit;
                }
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 210, chkWidth: false});
                // 単価
                if (getNullStr(result[index].service_class) != '') {
                  strWork = getNullStr(getListValue(this.serviceList, result[index].service_class, 'id', 'text'));
                  if (strWork.length <= 7) {
                    chkWidth = false;
                  } else {
                    chkWidth = true;
                  }
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                } else {
                  if (getNullStr(result[index].shipping_quantity) == '0' && getNullStr(result[index].order_receive_unit_amount) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                    strWork = '';
                  } else {
                    strWork = result[index].order_receive_unit_amount.toLocaleString();
                  }
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: false});
                }
                // 金額
                if (getNullStr(result[index].shipping_quantity) == '0' && getNullStr(result[index].order_receive_unit_amount) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                  strWork = '';
                } else {
                  strWork = result[index].order_receive_amount.toLocaleString();
                }
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: false});
              }
              // 合計金額を計算
              totalMoney += result[index].order_receive_amount;
              index++;
            }
          }
          // 合計
          replacements.push({key: '%合計金額%', value: totalMoney.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: false});
          //console.log('replacements');
          //console.log(replacements);
          this.listDenpyo[k].listChouhyou[i].replacements = replacements;
        }
      }
    },
    /* 伝票毎の置換配列セット */
    async createReplacementsPageFusoku(result){
      //console.log('createReplacementsPageFusoku');
      let index = 0;
      let strWork = '';
      for (let k = 0; k < this.listDenpyo.length; k++) {
        index = 0;
        for (let i = 0; i < this.listDenpyo[k].listFusokuChouhyou.length; i++) {
          while (result[index].order_receive_id != this.listDenpyo[k].orderReceiveId) {
            index++;
          }
          // SVGファイルの置換用文字列
          let replacements = [];
          // ページ番号
          replacements.push({key: '%P%', value: this.listDenpyo[k].listFusokuChouhyou[i].page + ' / ' + this.listDenpyo[k].listFusokuChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
          // 受注日付
          strWork = result[index].order_receive_date == null ? '' : formatDate(result[index].order_receive_date);
          replacements.push({key: '%受注日付%', value: strWork, textAnchor: 'start', textLength: 200, chkWidth: false});
          // 受注番号
          strWork = result[index].order_receive_id == null ? '' : result[index].order_receive_id;
          replacements.push({key: '%受注番号%', value: strWork, textAnchor: 'start', textLength: 250, chkWidth: false});
          // 受注処理担当者
          replacements.push({key: '%受注担当%', value: result[index].order_receive_staff_id, textAnchor: 'start', textLength: 180, chkWidth: false});
          // タイトル
          replacements.push({key: '%タイトル%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          // 返品表示
          if (result[index].bill_class == Const.Tradition.salesReturn) {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '返品', textAnchor: 'middle', textLength: 585, chkWidth: false});
          } else {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          }
          // 郵便番号
          replacements.push({key: '%郵便番号%', value: '〒' + getNullStr(result[index].zip_code), textAnchor: 'start', textLength: 300, chkWidth: false});
          // 住所１
          replacements.push({key: '%取住所1%', value: result[index].address_1, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 住所２
          replacements.push({key: '%取住所2%', value: result[index].address_2, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 取引電話
          replacements.push({key: '%取TEL%', value: result[index].phone_number, textAnchor: 'end', textLength: 720, chkWidth: true});
          // 取引先名
          replacements.push({key: '%取引先名%', value: result[index].client_name_kanji, textAnchor: 'start', textLength: 589, chkWidth: true});
          // 部署１
          replacements.push({key: '%部署1%', value: result[index].department_1, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 部署２
          replacements.push({key: '%部署2%', value: result[index].department_2, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 顧客コード
          replacements.push({key: '%顧客C%', value: result[index].client_id, textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 伝票日付（同じ受注内で伝票が複数の可能性があるため、「0000/00/00」とする）
          //replacements.push({key: '%伝日%', value: this.listDenpyo[k].salesIssueDate, textAnchor: 'start', textLength: 170, chkWidth: true});
          replacements.push({key: '%伝日%', value: '0000/00/00', textAnchor: 'start', textLength: 170, chkWidth: true});
          // 伝票番号（同じ受注内で伝票が複数の可能性があるため、「000001」とする）
          //replacements.push({key: '%伝番%', value: this.listDenpyo[k].salesNumber, textAnchor: 'start', textLength: 170, chkWidth: true});
          replacements.push({key: '%伝番%', value: '000001', textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 区分
          replacements.push({key: '%c%', value: result[index].bill_class, textAnchor: 'start', textLength: 60, chkWidth: true});
          // 締日
          if (result[index].closing_date == 99) {
            replacements.push({key: '%締日%', value: formatDateCalc(result[index].sales_issue_date, 0, 0, 0, true, 'DD'), textAnchor: 'start', textLength: 136, chkWidth: false});
          } else {
            replacements.push({key: '%締日%', value: result[index].closing_date, textAnchor: 'start', textLength: 136, chkWidth: false});
          }
          // 担当者
          replacements.push({key: '%担当%', value: result[index].staff_id, textAnchor: 'start', textLength: 120, chkWidth: false});
          // チェック
          replacements.push({key: '%チェック%', value: result[index].check_contents, textAnchor: 'start', textLength: 900, chkWidth: false});
          // 伝票種類が「2：特殊2、4：特殊4」の場合、取引先の追加情報と相手の名称を追加
          if (this.checkBillingTypeClass == Const.BillingTypeClass.special2 || this.checkBillingTypeClass == Const.BillingTypeClass.special4) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'clientName'), textAnchor: 'start', textLength: 467, chkWidth: true});
            // 住所
            replacements.push({key: '%取営住所%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'address'), textAnchor: 'start', textLength: 601, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'tel'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'fax'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先相手名
            if (result[index].client_print_class != Const.OrderPrintClass.customerNoPrint) {
              // 発注書印字区分「0：得意先名を印字する」の場合、固定値
              replacements.push({key: '%取相手名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'partnerName'), textAnchor: 'start', textLength: 589, chkWidth: true});
            } else {
              // 発注書印字区分「1：仮伝名称を印字する」の場合、発注書用取引先名
              replacements.push({key: '%取相手名%', value: result[index].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
            }
          } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special3) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: result[index].client_name_kanji, textAnchor: 'start', textLength: 570, chkWidth: true});
            // 郵便番号
            replacements.push({key: '%取営郵便%', value: '〒' + getNullStr(result[index].zip_code), textAnchor: 'start', textLength: 550, chkWidth: false});
            // 住所１
            replacements.push({key: '%取営住所1%', value: result[index].address_1, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 住所２
            replacements.push({key: '%取営住所2%', value: result[index].address_2, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: result[index].phone_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: result[index].fax_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先相手名
            replacements.push({key: '%取相手名%', value: result[index].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
          }
          // 説明
          replacements.push({key: '%説明%', value: '', textAnchor: 'start', textLength: 585, chkWidth: false});
          // 納品指定
          strWork = result[index].delivery_design_date == null ? '' : formatDate(result[index].delivery_design_date);
          strWork += '    ' + getNullStr(result[index].delivery_design);
          replacements.push({key: '%納品指定%', value: strWork, textAnchor: 'start', textLength: 760, chkWidth: true});
          // 納品指定区分
          replacements.push({key: '%納区%', value: result[index].delivery_design_class, textAnchor: 'end', textLength: 110, chkWidth: true});
          // 現場コード
          replacements.push({key: '%現場C%', value: result[index].site_id, textAnchor: 'start', textLength: 150, chkWidth: false});
          // 配送コード
          replacements.push({key: '%配送C%', value: result[index].shipping_code, textAnchor: 'start', textLength: 204, chkWidth: false});
          // 現場名
          replacements.push({key: '%現場名%', value: result[index].client_site_name_kanji, textAnchor: 'start', textLength: 1351, chkWidth: true});
          // 現場郵便番号
          replacements.push({key: '%現郵便%', value: '〒' + getNullStr(result[index].client_site_zip_code), textAnchor: 'start', textLength: 170, chkWidth: false});
          // 現場住所1
          replacements.push({key: '%現住所1%', value: result[index].client_site_address_1, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 現場住所2
          replacements.push({key: '%現住所2%', value: result[index].client_site_address_2, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 納品場所
          replacements.push({key: '%納品場所%', value: result[index].delivery_location, textAnchor: 'start', textLength: 1000, chkWidth: true});
          // 現場TEL
          replacements.push({key: '%現TEL%', value: result[index].client_site_phone_number, textAnchor: 'end', textLength: 400, chkWidth: true});
          // 帳票毎に設定可能な製品の件数
          let productCntByChouhyou = this.constData.productCntByChouhyou;
          // 製品
          for (let j = 0; j < productCntByChouhyou; j++) {
            // テーブルデータの参照が終わった場合
            // 同伝票内の製品の出力が全て終わった場合
            if (result.length <= index ||
            result[index].order_receive_id != this.listDenpyo[k].orderReceiveId) {
              /* 空行 */
              // コード
              replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 220, chkWidth: false});
              // 置場所
              replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 製品名
              replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 数量
              replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
            }else{
              // コード
              replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: 220, chkWidth: false});
              // 置場所
              if (result[index].place_1.trim() == '' && result[index].place_2.trim() == '' && result[index].place_3.trim() == '' && result[index].place_4.trim() == '') {
                strWork = '';
              } else {
                strWork = '';
                // TODO 常に非表示、但し、後に表示非表示を出しわけるように修正の可能性があるため、コメントアウト
                //strWork = ('00' + result[index].place_1.trim()).slice(-2) + '-' + ('00' + result[index].place_2.trim()).slice(-2) + '-' + ('00' + result[index].place_3.trim()).slice(-2) + '-' + ('00' + result[index].place_4.trim()).slice(-2);
              }
              replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: false});
              // 製品名
              strWork = result[index].product_name_kanji;
              if (result[index].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
                strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
              }
              replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: true});
              // 数量
              strWork = result[index].remaining_quantity.toLocaleString();
              strWork += getNullStr(result[index].unit) == '' ? '' : ' ' + result[index].unit;
              replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 210, chkWidth: false});
              index++;
            }
          }
          //console.log('replacements');
          //console.log(replacements);
          this.listDenpyo[k].listFusokuChouhyou[i].replacements = replacements;
        }
      }
    },
    // 伝票番号毎の空白ページ追加フラグ設定
    setAddBlank: function() {
      for (let i = 0; i < this.listDenpyo.length; i++) {
        let denpyoPageCnt = this.listDenpyo[i].listChouhyou.length * (this.listBillingTypeList.length - 1) + this.listDenpyo[i].listFusokuChouhyou.length;
        if (denpyoPageCnt % 2 == 0) {
          // ページ数が偶数の場合、空白ページの追加不要
          this.listDenpyo[i].addBlankPage = false;
        } else {
          // ページ数が奇数の場合、空白ページの追加必要（伝票番号が変わる場合、ページの上部から開始するため）
          this.listDenpyo[i].addBlankPage = true;
        }
        //console.log(denpyoPageCnt);
        //console.log(this.listDenpyo[i].addBlankPage);
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for (let i = 0; i < this.officeList.length; i++) {
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: 150, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: 287, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
        if (i == 0) {
          // E-mail
          replacements.push({key: '%Mail%', value: this.officeList[i].Email, textAnchor: 'start', textLength: 457, chkWidth: true});
        }
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function() {
      //console.log('setChouhyou');
      for (let j = 0; j < this.listDenpyo.length; j++) {
        for (let k = 0; k < this.listBillingTypeList.length; k++) {
          if (this.listBillingTypeList[k] != this.constData.billingTypeFusoku && this.listBillingTypeList[k] != this.constData.billingTypeFusokuOrder) {
            for (let i = 0; i < this.listDenpyo[j].listChouhyou.length; i++){
              let svgDoc = document.getElementById(this.constData.chouhyouId + this.listDenpyo[j].salesNumber + '_' + this.listBillingTypeList[k] + '_' + this.listDenpyo[j].listChouhyou[i].page);
              this.setReplacements(svgDoc, this.listDenpyo[j].listChouhyou[i].replacements, this.listBillingTypeList[k], this.listDenpyo[j].clientPrintClass, this.listDenpyo[j].clientPrintName);
              this.setSize(svgDoc);
            }
          } else {
            for (let i = 0; i < this.listDenpyo[j].listFusokuChouhyou.length; i++){
              //console.log(this.listDenpyo[j].listFusokuChouhyou[i].replacements);
              let svgDoc = document.getElementById(this.constData.chouhyouId + this.listDenpyo[j].salesNumber + '_' + this.listBillingTypeList[k] + '_' + this.listDenpyo[j].listFusokuChouhyou[i].page);
              this.setReplacements(svgDoc, this.listDenpyo[j].listFusokuChouhyou[i].replacements, this.listBillingTypeList[k], this.listDenpyo[j].clientPrintClass, this.listDenpyo[j].clientPrintName);
              this.setSize(svgDoc);
            }
          }
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements, billingType, clientPrintClass, clientPrintName) {
      //console.log('setReplacements');
      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].tagName == 'text') {
          for (let j = 0; j < node.children[i].children.length; j++) {
            if (node.children[i].children[j].tagName == 'tspan') {
              for (let k = 0; k < replacements.length; k++) {
                if (node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1) {
                  this.setTspan(node.children[i], node.children[i].children[j], replacements[k], billingType, clientPrintClass, clientPrintName);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'g') {
          this.setReplacements(node.children[i], replacements, billingType, clientPrintClass, clientPrintName);
        }
        /*
        else if (node.children[i].tagName == 'path' && node.children[i].getAttribute('fill') == '#fff') {
          this.setBackgroundColor(node.children[i], billingType);
        }
        */
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagText, tagTspan, config, billingType, clientPrintClass, clientPrintName) {
      let configChkWidth = config.chkWidth;
      // 文字を置換
      if (config.key == '%タイトル%') {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'title'));
        tagText.setAttribute('fill', getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'color'));
      } else if (config.key == '%説明%') {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'text'));
      } else if (config.key == '%郵便番号%' || config.key == '%取住所1%' || config.key == '%取住所2%' || config.key == '%取TEL%' || config.key == '%取引先名%' || config.key == '%部署1%' || config.key == '%部署2%') {
        if (billingType == this.constData.billingTypeNohinSei || billingType == this.constData.billingTypeSeikyu || clientPrintClass != Const.OrderPrintClass.customerNoPrint) {
          // 納品書（正）、または、得意先名を印字の場合
          // 基本的な動きと同じ
          tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
        } else {
          // 上記以外（納品書（正）以外、且つ、「仮伝名称を印字する」）の場合
          // 名称以外は空欄、名称は現場マスタの設定値
          if (config.key == '%郵便番号%' || config.key == '%取住所1%' || config.key == '%取住所2%' || config.key == '%取TEL%' || config.key == '%部署1%' || config.key == '%部署2%') {
            tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, '');
            configChkWidth = false;
          } else {
            tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(clientPrintName));
          }
        }
      } else {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (configChkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 背景色を設定 */
    setBackgroundColor(tagPath, billingType) {
      tagPath.setAttribute('fill', getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'backcolor'));
    },
    /* 取得結果セット */
    setSize: function(svgDoc) {
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 保存実行 */
    async execSave(result) {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 受注セット品情報設定
        let componentProductIdList = await this.getOrdersReceivedSet(result);
        // 在庫マスタロック
        this.lockStocks(result, componentProductIdList);
        // 受注データロック処理
        this.lockOrderReceives(result);
        // 受注セット品データロック処理
        this.lockOrdersRreceivedSet(result);
        // 出荷データロック処理
        this.lockShips(result);
        // 累積トランザクションの登録
        this.insertCumulativeTransaction(result);
        // 受注データの更新
        this.updateOrderReceives(result);
        // 受注セット品データの更新
        this.updateOrdersRreceivedSet(result);
        // 在庫マスタの更新
        this.updateStocksMaster(result);
        // 出荷データの更新
        this.updateShips(result);
        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            this.listDenpyo = [];
            return false;
          }
        } catch (error) {
          const msg = DISP_MESSAGES.DANGER['3005'];
          await addOperationLogs('Error', MODULE_NAME, functionName, msg, error);
          this.alertDanger.push(msg);
          this.listDenpyo = [];
          return false;
        }

        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);
        retResult = true;
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        // 伝票発行の保存失敗（帳票をクリアし、エラーメッセージを表示）
        this.listDenpyo = [];
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      return retResult;
    },
    /* 受注セット品情報取得 */
    async getOrdersReceivedSet(result) {
      //console.log('受注セット品情報取得');
      let productIdList = [];
      // 受注セット品製品コード取得SQL作成
      let selectSql = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (result[i].case_conversion_class == Const.CaseConversionClassDef.conversion &&
          result[i].set_class == Const.SetClass.set) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            if (selectSql != '') {
              selectSql += 'UNION ';
            }
            /* SELECT句 */
            selectSql += 'SELECT ';
            selectSql += ' product_id';
            /* FROM句 */
            selectSql += ' FROM ';
            selectSql += 't_orders_received_set ';
            /* WHERE句 */
            selectSql += ' WHERE ';
            selectSql += 'order_receive_id = ' + result[i].order_receive_id + ' ';
            selectSql += 'AND order_receive_row = ' + result[i].order_receive_row + ' ';
          }
        }
      }
      if (selectSql != '') {
        //console.log(selectSql)
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if(dataResult != null && dataResult.length > 0) {
          for (let i = 0; i < dataResult.length; i++) {
            productIdList.push(dataResult[i].product_id);
          }
        }
      }

      return productIdList;
    },
    /* 在庫マスタロックSQL作成 */
    lockStocks: function(result, componentProductIdList) {
      //console.log('在庫マスタロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.month_year';
      selectSql += ',stocks.office_id';
      selectSql += ',stocks.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < result.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += result[i].product_id;
      }
      for (let i = 0; i < componentProductIdList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += componentProductIdList[i];
      }
      selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 受注データロックSQL作成 */
    lockOrderReceives: function(result) {
      //console.log('受注データロックSQL作成');
      let selectSql = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (selectSql != '') {
            selectSql += 'UNION ';
          }
          /* SELECT句 */
          selectSql += 'SELECT ';
          selectSql += ' order_receive_id';
          selectSql += ',order_receive_row';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_orders_receives ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'order_receive_id = ' + result[i].order_receive_id + ' ';
          selectSql += 'AND order_receive_row = ' + result[i].order_receive_row + ' ';
        }
      }
      if (selectSql != '') {
        //console.log(selectSql)
        this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
      }
    },
    /* 受注セット品データロックSQL作成 */
    lockOrdersRreceivedSet: function(result) {
      //console.log('受注セット品データロックSQL作成');
      let selectSql = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (result[i].case_conversion_class == Const.CaseConversionClassDef.conversion &&
          result[i].set_class == Const.SetClass.set) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            if (selectSql != '') {
              selectSql += 'UNION ';
            }
            /* SELECT句 */
            selectSql += 'SELECT ';
            selectSql += ' order_receive_id';
            selectSql += ',order_receive_row';
            /* FROM句 */
            selectSql += ' FROM ';
            selectSql += 't_orders_received_set ';
            /* WHERE句 */
            selectSql += ' WHERE ';
            selectSql += 'order_receive_id = ' + result[i].order_receive_id + ' ';
            selectSql += 'AND order_receive_row = ' + result[i].order_receive_row + ' ';
          }
        }
      }
      if (selectSql != '') {
        //console.log(selectSql)
        this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
      }
    },
    /* 出荷データロックSQL作成 */
    lockShips: function(result) {
      //console.log('出荷データロックSQL作成');
      let selectSql = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (selectSql != '') {
            selectSql += 'UNION ';
          }
          /* SELECT句 */
          selectSql += 'SELECT ';
          selectSql += ' order_receive_id';
          selectSql += ',order_receive_row';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_ships ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'order_receive_id = ' + result[i].order_receive_id + ' ';
          selectSql += 'AND order_receive_row = ' + result[i].order_receive_row + ' ';
        }
      }
      if (selectSql != '') {
        //console.log(selectSql)
        this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
      }
    },
    /* 累積トランザクション登録SQL作成 */
    insertCumulativeTransaction: function(result) {
      //console.log('累積トランザクション登録SQL作成');
      let preSalesNumber = 0;
      let salesNumberRow = 0;
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].sales_number == preSalesNumber) {
          // 前行と同じ伝票番号の場合
          salesNumberRow++;
        } else {
          // 前行と異なる伝票番号の場合
          salesNumberRow = 1;
        }
        preSalesNumber = result[i].sales_number;
        if (preSalesNumber == 0) {
          // 伝票番号が0の場合は設定に不備があったと認識して、エラーとする。
          throw '伝票番号の設定に不備あり（伝票番号0）';
        }
        let colList = [];
        // INSERT文の先頭部分、初回のみ作成
        if (bulkInsertSqlStart == '') {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', null, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', null, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', null, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', null, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', null, 'NUMBER'));
          // 解体組立区分
          colList.push(CreateColRow('dismantling_assembly_class', null, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', null, 'NUMBER'));
          // 移動先コード
          colList.push(CreateColRow('destination_id', null, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', null, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', null, 'NUMBER'));
          // 納品指定日
          colList.push(CreateColRow('delivery_design_date', null, 'DATE'));
          // 納品指定区分
          colList.push(CreateColRow('delivery_design_class', null, 'VARCHAR'));
          // 納品指定
          colList.push(CreateColRow('delivery_design', null, 'VARCHAR'));
          // 現場コード
          colList.push(CreateColRow('site_id', null, 'NUMBER'));
          // 配送コード
          colList.push(CreateColRow('shipping_code', null, 'VARCHAR'));
          // 現場名称
          colList.push(CreateColRow('client_site_name', null, 'VARCHAR'));
          // 現場郵便番号
          colList.push(CreateColRow('client_site_zip_code', null, 'VARCHAR'));
          // 現場住所1
          colList.push(CreateColRow('client_site_address_1', null, 'VARCHAR'));
          // 現場住所2
          colList.push(CreateColRow('client_site_address_2', null, 'VARCHAR'));
          // 納品場所
          colList.push(CreateColRow('delivery_location', null, 'VARCHAR'));
          // 現場TEL
          colList.push(CreateColRow('client_site_phone_number', null, 'VARCHAR'));
          // 伝票摘要
          colList.push(CreateColRow('billing_summary', null, 'VARCHAR'));
          // 処理日付
          colList.push(CreateColRow('process_date', null, 'DATE'));
          // 受注処理担当者コード	
          colList.push(CreateColRow('order_receive_staff_id', null, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', null, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
          // 相殺伝票No
          colList.push(CreateColRow('offset_billing_no', null, 'NUMBER'));
          // 製品コード
          colList.push(CreateColRow('product_id', null, 'NUMBER'));
          // 製品名
          colList.push(CreateColRow('product_name', null, 'VARCHAR'));
          // 製品備考
          colList.push(CreateColRow('product_note', null,'VARCHAR'));
          // サービス区分
          colList.push(CreateColRow('service_class', null, 'VARCHAR'));
          // 製品数量
          colList.push(CreateColRow('product_quantity', null, 'NUMBER'));
          // 製品単位
          colList.push(CreateColRow('product_unit', null, 'VARCHAR'));
          // 製品売上単価
          colList.push(CreateColRow('product_sales_unit_price', null, 'NUMBER'));
          // 製品仕入単価
          colList.push(CreateColRow('product_purchase_price', null, 'NUMBER'));
          // 製品売上金額
          colList.push(CreateColRow('product_sales_unit_amount', null, 'NUMBER'));
          // 製品仕入金額
          colList.push(CreateColRow('product_purchase_amount', null, 'NUMBER'));
          // 製品粗利額
          colList.push(CreateColRow('product_gross_profit', null, 'NUMBER'));
          // 製品消費税
          colList.push(CreateColRow('product_tax', null, 'NUMBER'));
          // 金額
          colList.push(CreateColRow('amount', null, 'NUMBER'));
          // 製品分類コード
          colList.push(CreateColRow('product_class_id', null, 'NUMBER'));
          // 製品諸口区分
          colList.push(CreateColRow('product_mouth_class', null, 'NUMBER'));
          // 在庫管理区分
          colList.push(CreateColRow('inventory_control_class', null, 'NUMBER'));
          // 取引先製品単価区分
          colList.push(CreateColRow('client_amount_class', null, 'VARCHAR'));
          // 売上課税区分
          colList.push(CreateColRow('sales_tax_class', null, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', null, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', null, 'NUMBER'));
          // 月次更新フラグ
          colList.push(CreateColRow('is_update_monthly', null, 'NUMBER'));
          // 月次年月
          colList.push(CreateColRow('monthly_month_year', null, 'NUMBER'));
          // 締日更新フラグ
          colList.push(CreateColRow('is_update_closing_date', null, 'NUMBER'));
          // 締次年月
          colList.push(CreateColRow('closing_month_year', null, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', null, 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', null, 'NUMBER'));
          // 発注番号
          colList.push(CreateColRow('order_id', null, 'NUMBER'));
          // 発注行番号
          colList.push(CreateColRow('order_row', null, 'NUMBER'));
          // 注文番号
          colList.push(CreateColRow('order_number', null, 'NUMBER'));
          // 注文行番号
          colList.push(CreateColRow('order_number_row', null, 'NUMBER'));
          // 組合CD発注取引先
          colList.push(CreateColRow('union_cd_order_client', null, 'NUMBER'));
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          colList.push(CreateColRow('is_check_purchase_price', null, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', null, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
          bulkInsertSqlStart = 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') ';
          //console.log(bulkInsertSqlStart);
        }
        let selectSql = '';
        /* SELECT句 */
        selectSql += 'SELECT ';
        // ﾄﾗﾝｻﾞｸｼｮﾝID
        selectSql += ' \'' + Const.TransactionId.sales + '\'';
        // 伝票No
        selectSql += ',' + result[i].sales_number;
        // 行No
        selectSql += ',' + salesNumberRow;
        // 伝票日付
        selectSql += ',\'' + result[i].sales_issue_date + '\'';
        // 伝票種別
        selectSql += ',' + result[i].bill_class;
        // 解体組立区分
        selectSql += ',0';
        // 営業所コード
        selectSql += ',orders_receives.office_id';
        // 移動先コード
        selectSql += ',0';
        // 取引先区分
        selectSql += ',orders_receives.client_class';
        // 取引先コード
        selectSql += ',orders_receives.client_id';
        // 納品指定日
        selectSql += ',orders_receives.delivery_design_date';
        // 納品指定区分
        selectSql += ',orders_receives.delivery_design_class';
        // 納品指定
        selectSql += ',orders_receives.delivery_design';
        // 現場コード
        selectSql += ',orders_receives.site_id'
        // 配送コード
        selectSql += ',orders_receives.shipping_code';
        // 現場名称
        selectSql += ',orders_receives.client_site_name';
        // 現場郵便番号
        selectSql += ',orders_receives.client_site_zip_code';
        // 現場住所1
        selectSql += ',orders_receives.client_site_address_1';
        // 現場住所2
        selectSql += ',orders_receives.client_site_address_2';
        // 納品場所
        selectSql += ',orders_receives.delivery_location';
        // 現場TEL
        selectSql += ',orders_receives.client_site_phone_number';
        // 伝票摘要
        selectSql += ',\'' + this.createdUserData + '\'';
        // 処理日付
        selectSql += ',orders_receives.order_receive_date';
        // 受注処理担当者コード	
        selectSql += ',orders_receives.order_receive_staff_id';
        // 担当者コード
        selectSql += ',orders_receives.staff_id';
        // 取引先分類コード
        selectSql += ',orders_receives.client_id_first_digit';
        // 相殺伝票No
        selectSql += ',0';
        if (result[i].product_id == this.constData.taxId && result[i].product_name_kanji == this.constData.taxName) {
          // 消費税行
          // 製品コード
          selectSql += ',0';
          // 製品名
          selectSql += ',\'\'';
          // 製品備考
          selectSql += ',\'\'';
          // サービス区分
          selectSql += ',\'\'';
          // 製品数量
          selectSql += ',0';
          // 製品単位
          selectSql += ',\'\'';
          // 製品売上単価
          selectSql += ',0';
          // 製品仕入単価
          selectSql += ',0';
          // 製品売上金額
          selectSql += ',0';
          // 製品仕入金額
          selectSql += ',0';
          // 製品粗利額
          selectSql += ',0';
          // 製品消費税
          selectSql += ',' + result[i].order_receive_amount;
          // 金額
          selectSql += ',0';
          // 製品分類コード
          selectSql += ',0';
          // 製品諸口区分
          selectSql += ',0';
          // 在庫管理区分
          selectSql += ',0';
          // 取引先製品単価区分
          selectSql += ',\'\'';
        } else {
          // 上記以外（通常の製品行）
          // 製品コード
          selectSql += ',orders_receives.product_id';
          // 製品名
          selectSql += ',orders_receives.product_name';
          // 製品備考
          selectSql += ',orders_receives.note';
          // サービス区分
          selectSql += ',orders_receives.service_class';
          // 製品数量
          selectSql += ',' + result[i].shipping_quantity;
          // 製品単位
          selectSql += ',orders_receives.unit';
          // 製品売上単価
          selectSql += ',orders_receives.order_receive_unit_amount';
          // 製品仕入単価
          selectSql += ',orders_receives.order_unit_amount';
          // 製品売上金額
          selectSql += ',orders_receives.order_receive_unit_amount * ' + result[i].shipping_quantity;
          // 製品仕入金額
          selectSql += ',orders_receives.order_unit_amount * ' + result[i].shipping_quantity;
          // 製品粗利額
          selectSql += ',(orders_receives.order_receive_unit_amount - orders_receives.order_unit_amount) * ' + result[i].shipping_quantity;
          // 製品消費税
          selectSql += ',0';
          // 金額
          selectSql += ',0';
          // 製品分類コード
          selectSql += ',orders_receives.product_class_id';
          // 製品諸口区分
          selectSql += ',orders_receives.product_manual_input_class';
          // 在庫管理区分
          selectSql += ',orders_receives.inventory_control_class';
          // 取引先製品単価区分
          selectSql += ',orders_receives.client_amount_class';
        }
        // 売上課税区分
        selectSql += ',' + result[i].sales_tax_class;
        // 税額計算区分
        selectSql += ',' + result[i].tax_calculation_class;
        // 税額端数計算処理区分
        selectSql += ',' + result[i].tax_fractionation_class;
        // 月次更新フラグ
        selectSql += ',0';
        // 月次年月
        selectSql += ',0';
        // 締日更新フラグ
        selectSql += ',0';
        // 締次年月
        selectSql += ',0';
        // 受注番号
        selectSql += ',orders_receives.order_receive_id';
        if (result[i].product_id == this.constData.taxId && result[i].product_name_kanji == this.constData.taxName) {
          // 消費税行
          // 受注行番号
          selectSql += ',0';
          // 発注番号
          selectSql += ',0';
          // 発注行番号
          selectSql += ',0';
        } else {
          // 上記以外（通常の製品行）
          // 受注行番号
          selectSql += ',orders_receives.order_receive_row';
          // 発注番号
          selectSql += ',orders_receives.order_id';
          // 発注行番号
          selectSql += ',orders_receives.order_row';
        }
        // 注文番号
        selectSql += ',0';
        // 注文行番号
        selectSql += ',0';
        // 組合CD発注取引先
        selectSql += ',0';
        // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
        selectSql += ',0';
        // 作成ユーザー
        selectSql += ',\'' + this.loginId + '\'';
        // 更新ユーザー
        selectSql += ',\'' + this.loginId + '\'';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'INNER JOIN t_ships AS ships ';
        selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
        selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
        /* WHERE句 */
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + result[i].order_receive_id + ' ';
        selectSql += 'AND orders_receives.order_receive_row = ' + result[i].order_receive_row + ' ';
        selectSql += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
        //console.log(selectSql);
        if (bulkInsertSqlSelect != '') {
          bulkInsertSqlSelect += 'UNION ALL ';
        }
        bulkInsertSqlSelect += selectSql;
        if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
          this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
          bulkInsertSqlSelect = '';
        }
      }
      if (bulkInsertSqlSelect != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
    },
    /* 受注データ更新SQL作成 */
    updateOrderReceives: function(result) {
      //console.log('受注データ更新SQL作成');
      let colList = [];
      for(let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          colList = [];
          // 受注残数
          colList.push(CreateColRow('orders_receives.remaining_quantity', 'orders_receives.remaining_quantity - ' + result[i].shipping_quantity, 'NUMBER'));
          if (result[i].order_receive_bill_class == Const.OrderReceiveBillClass.special) {
            // 入荷予定引当数
            colList.push(CreateColRow('orders_receives.stock_scheduled_reserve', 'orders_receives.stock_scheduled_reserve - ' + result[i].shipping_quantity, 'NUMBER'));
          } else {
            // 引当済数
            colList.push(CreateColRow('orders_receives.reserve_quantity', 'orders_receives.reserve_quantity - ' + result[i].shipping_quantity, 'NUMBER'));
          }
          // 出荷数
          colList.push(CreateColRow('orders_receives.shipping_quantity', 'orders_receives.shipping_quantity + ' + result[i].shipping_quantity, 'NUMBER'));
          if (result[i].bill_class == Const.Tradition.salesReturn) {
            // 返品伝票発行フラグ
            colList.push(CreateColRow('orders_receives.is_bill_return_print', 1, 'NUMBER'));
          }
          // 更新日
          colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
          let updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives', 't_ships AS ships');
          updateSql += ' WHERE ';
          updateSql += 'orders_receives.order_receive_id = ' + result[i].order_receive_id + ' ';
          updateSql += 'AND orders_receives.order_receive_row = ' + result[i].order_receive_row + ' ';
          updateSql += 'AND ships.order_receive_id = ' + result[i].order_receive_id + ' ';
          updateSql += 'AND ships.order_receive_row = ' + result[i].order_receive_row + ' ';
          updateSql += 'AND ships.order_receive_id = orders_receives.order_receive_id ';
          updateSql += 'AND ships.order_receive_row = orders_receives.order_receive_row ';
          updateSql += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
          //console.log(updateSql);
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
    },
    /* 受注セット品データ更新SQL作成 */
    updateOrdersRreceivedSet: function(result) {
      //console.log('受注セット品データ更新SQL作成');
      let colList = [];
      for(let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (result[i].case_conversion_class == Const.CaseConversionClassDef.conversion &&
          result[i].set_class == Const.SetClass.set) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            // 更新用の副問い合わせテーブル作成
            let updateQuery = '(';
            updateQuery += 'SELECT';
            updateQuery += ' orders_receives.order_receive_id';
            updateQuery += ',orders_receives.order_receive_row';
            updateQuery += ',orders_received_set.order_receive_row_branch';
            updateQuery += ',products_compositions.quantity';
            updateQuery += ' FROM ';
            updateQuery += 't_orders_receives AS orders_receives ';
            updateQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
            updateQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
            updateQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
            updateQuery += 'INNER JOIN t_ships AS ships ';
            updateQuery += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
            updateQuery += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
            updateQuery += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
            updateQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
            updateQuery += 'ON orders_receives.product_id = products_compositions.product_id ';
            updateQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
            updateQuery += ' WHERE ';
            updateQuery += 'orders_receives.order_receive_id = ' + result[i].order_receive_id + ' ';
            updateQuery += 'AND orders_receives.order_receive_row = ' + result[i].order_receive_row + ' ';
            updateQuery += ') AS updateQuery';
            colList = [];
            // 受注残数
            colList.push(CreateColRow('orders_received_set.remaining_quantity', 'orders_received_set.remaining_quantity - updateQuery.quantity * ' + result[i].shipping_quantity, 'NUMBER'));
            if (result[i].order_receive_bill_class == Const.OrderReceiveBillClass.special) {
              // 入荷予定引当数
              colList.push(CreateColRow('orders_received_set.stock_scheduled_reserve', 'orders_received_set.stock_scheduled_reserve - updateQuery.quantity * ' + result[i].shipping_quantity, 'NUMBER'));
            } else {
              // 引当済数
              colList.push(CreateColRow('orders_received_set.reserve_quantity', 'orders_received_set.reserve_quantity - updateQuery.quantity * ' + result[i].shipping_quantity, 'NUMBER'));
            }
            // 出荷数
            colList.push(CreateColRow('orders_received_set.shipping_quantity', 'orders_received_set.shipping_quantity + updateQuery.quantity * ' + result[i].shipping_quantity, 'NUMBER'));
            // 更新日
            colList.push(CreateColRow('orders_received_set.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
            // 更新ユーザー
            colList.push(CreateColRow('orders_received_set.updated_user', this.loginId, 'VARCHAR'));
            let updateSql = CreateUpdateSql(colList, 't_orders_received_set AS orders_received_set', updateQuery);
            updateSql += ' WHERE ';
            updateSql += 'orders_received_set.order_receive_id = updateQuery.order_receive_id ';
            updateSql += 'AND orders_received_set.order_receive_row = updateQuery.order_receive_row ';
            updateSql += 'AND orders_received_set.order_receive_row_branch = updateQuery.order_receive_row_branch ';
            //console.log(updateSql);
            this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
          }
        }
      }
    },
    /* 出荷データ更新SQL作成 */
    updateShips: function(result) {
      //console.log('出荷データ更新SQL作成');
      let preSalesNumber = 0;
      let salesNumberRow = 0;
      let colList = [];
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          colList = [];
          if (result[i].sales_number == preSalesNumber) {
            // 前行と同じ伝票番号の場合
            salesNumberRow++;
          } else {
            // 前行と異なる伝票番号の場合
            salesNumberRow = 1;
          }
          preSalesNumber = result[i].sales_number;
          // 売上伝票発行区分
          colList.push(CreateColRow('sales_issue_class', 1, 'NUMBER'));
          // 売上伝票発行日
          colList.push(CreateColRow('sales_issue_date', result[i].sales_issue_date, 'DATE'));
          // 売上伝票番号
          colList.push(CreateColRow('sales_number', result[i].sales_number, 'NUMBER'));
          // 売上伝票行No
          colList.push(CreateColRow('sales_number_row', salesNumberRow, 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          let updateSql = CreateUpdateSql(colList, 't_ships');
          updateSql += ' WHERE ';
          updateSql += 'order_receive_id = ' + result[i].order_receive_id + ' ';
          updateSql += 'AND order_receive_row = ' + result[i].order_receive_row + ' ';
          updateSql += 'AND sales_issue_class = 0 ';
          //console.log(updateSql);
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function(result) {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      // 上記以外（通常、または、特別受注の在庫管理）
      // 更新用の副問い合わせテーブル作成
      let updateSubQuery = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].product_id != this.constData.taxId || result[i].product_name_kanji != this.constData.taxName) {
          // 消費税以外の行（通常の製品行）
          if (result[i].bill_class != Const.Tradition.salesReturn) {
            // 「返品」以外の行
            if (updateSubQuery != '') {
              updateSubQuery += ' UNION ALL ';
            }
            if (result[i].case_conversion_class == Const.CaseConversionClassDef.conversion &&
            result[i].set_class == Const.SetClass.set) {
              // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
              updateSubQuery += 'SELECT';
              updateSubQuery += ' orders_received_set.product_id';
              // 通常（在庫引当数）
              updateSubQuery += ',' + result[i].shipping_quantity + ' * products_compositions.quantity AS inventory_reserve_count';
              updateSubQuery += ',' + result[i].shipping_quantity + ' * products_compositions.quantity AS monthly_salse_ship';
              updateSubQuery += ' FROM ';
              updateSubQuery += 't_orders_receives AS orders_receives ';
              updateSubQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
              updateSubQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
              updateSubQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
              updateSubQuery += 'INNER JOIN t_ships AS ships ';
              updateSubQuery += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
              updateSubQuery += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
              updateSubQuery += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
              updateSubQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
              updateSubQuery += 'ON products_compositions.product_id = ' + result[i].product_id + ' ';
              updateSubQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
              updateSubQuery += ' WHERE ';
              updateSubQuery += 'orders_receives.order_receive_id = ' + result[i].order_receive_id + ' ';
              updateSubQuery += 'AND orders_receives.order_receive_row = ' + result[i].order_receive_row + ' ';
              updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
            } else {
              // 上記以外
              // ・ケース換算しない
              // ・バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
              updateSubQuery += 'SELECT';
              updateSubQuery += ' orders_receives.product_id';
              updateSubQuery += ',' + result[i].shipping_quantity + ' AS inventory_reserve_count';
              updateSubQuery += ',' + result[i].shipping_quantity + ' AS monthly_salse_ship';
              updateSubQuery += ' FROM ';
              updateSubQuery += 't_orders_receives AS orders_receives ';
              updateSubQuery += 'INNER JOIN t_ships AS ships ';
              updateSubQuery += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
              updateSubQuery += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
              updateSubQuery += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
              updateSubQuery += ' WHERE ';
              updateSubQuery += 'orders_receives.order_receive_id = ' + result[i].order_receive_id + ' ';
              updateSubQuery += 'AND orders_receives.order_receive_row = ' + result[i].order_receive_row + ' ';
              updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
            }
            if (updateSubQuery.length >= Const.SqlMaxLength) {
              // 在庫マスタ更新対象がある場合のみ
              let updateQuery = '(';
              updateQuery += 'SELECT';
              updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
              updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
              updateQuery += ',SUB_QUERY.product_id';
              updateQuery += ',SUM(SUB_QUERY.inventory_reserve_count) AS inventory_reserve_count';
              updateQuery += ',SUM(SUB_QUERY.monthly_salse_ship) AS monthly_salse_ship';
              updateQuery += ' FROM ';
              updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
              updateQuery += 'GROUP BY product_id ';
              updateQuery += ') AS PRODUCT_QUERY';
              // 在庫引当数
              colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - PRODUCT_QUERY.inventory_reserve_count', 'NUMBER'));
              // 月次売上出庫数
              colList.push(CreateColRow('stocks.monthly_salse_ship', 'stocks.monthly_salse_ship + PRODUCT_QUERY.monthly_salse_ship', 'NUMBER'));
              // 更新日
              colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
              // 更新ユーザー
              colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
              updateSql = CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
              updateSql += ' WHERE ';
              updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
              updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
              updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
              //console.log(updateSql);
              this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
              updateSubQuery = '';
            }
          }
        }
      }
      if (updateSubQuery != '') {
        // 在庫マスタ更新対象がある場合のみ
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
        updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
        updateQuery += ',SUB_QUERY.product_id';
        updateQuery += ',SUM(SUB_QUERY.inventory_reserve_count) AS inventory_reserve_count';
        updateQuery += ',SUM(SUB_QUERY.monthly_salse_ship) AS monthly_salse_ship';
        updateQuery += ' FROM ';
        updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
        updateQuery += 'GROUP BY product_id ';
        updateQuery += ') AS PRODUCT_QUERY';
        // 在庫引当数
        colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - PRODUCT_QUERY.inventory_reserve_count', 'NUMBER'));
        // 月次売上出庫数
        colList.push(CreateColRow('stocks.monthly_salse_ship', 'stocks.monthly_salse_ship + PRODUCT_QUERY.monthly_salse_ship', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateSql = CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
        updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
        //console.log(updateSql);
        this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
      }
    },
    // 未作成伝票のクリア
    async clearMisakuseiDenpyo(result) {
      let csvSalesNumber = '';
      for (let i = 0; i < this.listDenpyo.length; i++) {
        if (csvSalesNumber != '') {
          csvSalesNumber += ',';
        }
        csvSalesNumber += this.listDenpyo[i].salesNumber;
      }
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billing_no';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction	 ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql += 'AND billing_no IN (' + csvSalesNumber + ') ';
      selectSql += 'AND billing_row = 1 ';
      selectSql += 'AND monthly_month_year = 0 ';
      // ORDER BY句
      selectSql += 'ORDER BY billing_no ';
      //console.log(selectSql)
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        for (let i = this.listDenpyo.length - 1; i >= 0; i--) {
          let data = dataResult.find(el => el.billing_no == this.listDenpyo[i].salesNumber);
          if (data == undefined) {
            this.listDenpyo.splice(i, 1);
          }
        }
        for (let i = result.length - 1; i >= 0; i--) {
          let data = dataResult.find(el => el.billing_no == result[i].sales_number);
          if (data == undefined) {
            result.splice(i, 1);
          }
        }
      } else {
        this.listDenpyo = [];
      }
    },
  },
}
</script>
<style scoped>
</style>