<template>
  <div>
    <b-modal
      id="stockScheduledEditModal"
      size="xl"
      centered no-close-on-esc no-close-on-backdrop hide-header-close scrollable
      @show="clearAlert(); initData();"
      title="入荷予定情報編集"
    >
      <b-container style="padding: 0px;">
        <b-row>
          <b-col>
            <b-card class="mb-3" style="paddin: 0px;">
              <b-card-header v-if="getMessageFlg" style="paddin: 0px;">
                <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                  <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                    <li>{{error}}</li>
                  </ul>
                </b-alert>
                <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                  <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                    <li>{{error}}</li>
                  </ul>
                </b-alert>
                <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                  <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                    <li>{{error}}</li>
                  </ul>
                </b-alert>
              </b-card-header>
              <b-card-body style="paddin: 0px;">
                <validation-observer ref="observer">
                  <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                    <b-row>
                      <b-col lg="6">
                        <b-input-group>
                          <template #prepend>
                            <label for="clientName">
                              <b-input-group-text  class="px-1">
                                注文番号
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" v-model.trim="propOrderNumber" readonly></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <!-- 製品 -->
                      <b-col>
                        <b-row>
                          <b-col>
                            <b-table
                              :items="itemList"
                              :fields="fields"
                              :small="true"
                            >
                              <template #table-caption>製品テーブル</template>
                              <template #cell(Client)="data">
                                <div style="max-width:200px;">
                                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                                    <span v-b-tooltip.hover.noninteractive :title="data.item.Client">
                                      {{ data.item.Client }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                              <template #cell(ProductCode)="data">
                                <div style="max-width:200px;">
                                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                                    <span v-b-tooltip.hover.noninteractive :title="data.item.ProductCode">
                                      {{ data.item.ProductCode }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                              <template #cell(StockScheduled)="data">
                                <validation-provider :rules="{oldProcessMonthYearChange: {processMonthYear: controlMasterData.processMonthYear, dateInit: data.item.StockScheduledInit}}" v-slot="{ classes,errors }">
                                  <b-input-group style="width: 160px;">
                                    <b-row>
                                      <b-col lg="12" :class="classes">
                                        <b-input-group class="input-daterange">
                                          <b-form-input
                                            id="productStockScheduled"
                                            name="productStockScheduled"
                                            size="sm" 
                                            class="px-1"
                                            v-model="data.item.StockScheduled"
                                            readonly
                                          ></b-form-input>
                                          <b-input-group-append>
                                            <b-button @click="openModal(data)" size="sm">
                                              <span class="oi oi-calendar"></span>
                                            </b-button>
                                          </b-input-group-append>
                                          <b-input-group-append>
                                            <b-button size="sm" variant="outline-secondary" @click="data.item.StockScheduled=''">
                                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                            </b-button>
                                          </b-input-group-append>
                                        </b-input-group>
                                      </b-col>
                                      <b-col lg="12" :class="classes">
                                        <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                      </b-col>
                                    </b-row>
                                  </b-input-group>
                                </validation-provider>
                              </template>
                              <template #cell(StockScheduledStaffText)="data">
                                <b-input-group style="width: 170px;">
                                  <b-form-input size="sm" class="px-1" type="text" v-model="data.item.StockScheduledStaffText" readonly />
                                  <b-input-group-text @click="showStockScheduledStaffSearchModal(data.index)">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </template>
                              <template #cell(StockScheduledConfirmClass)="data">
                                <b-form-checkbox
                                  v-model="data.item.StockScheduledConfirmClass"
                                  value="1"
                                  unchecked-value="0"
                                ></b-form-checkbox>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <!-- 保存ボタン -->
                    <b-row class="mt-2 justify-content-md-center">
                      <b-col lg="2">
                        <b-button pill variant="success" type="submit" form="inputForm"  class="btn btn-block">
                          <span class="oi oi-circle-check"></span> 保存
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button size="sm" @click="$bvModal.hide('stockScheduledEditModal')" class="ml-1 float-start">閉じる</b-button>
      </template>
    </b-modal>
    <b-modal id='datepickerModal' :val="postItem" size="sm" hide-footer hide-header centered no-close-on-esc>
      <div v-if="postItem">
        <b-calendar
          name="tblSettlementScheduled"
          v-model="postItem.item.StockScheduled"
          @input="$bvModal.hide('datepickerModal')"
          hide-header
        >
          <div class="d-flex" dir="ltr">
            <b-button
              size="sm"
              variant="outline-danger"
              v-if="postItem.item.StockScheduled"
              @click="postItem.item.StockScheduled='';"
            >
              {{htmlConst.Calendar.clearButtonName}}
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-auto"
              @click="postItem.item.StockScheduled=getToday();"
            >
              {{htmlConst.Calendar.todayButtonName}}
            </b-button>
          </div>
        </b-calendar>
      </div>
    </b-modal>
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
  </div>
</template>
<script>
import store from '@/store';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import Const from '@/assets/js/const.js';
import { init, getNullStr, formatDate, formatCurDate, getListValue, getControlMaster, executeSelectSql, addOperationLogs, CreateColRow, CreateUpdateSql, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_service_classes } from '@/graphql/queries';

const MODULE_NAME = 'stock-scheduled-edit';

export default {
  name: 'STOCK-SCHEDULED-EDIT',
  props: ['propOrderNumber'],
  /** コンポーネント */
  components: {
    STAFFSEARCH,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入荷予定情報編集',
      loginId: '',
      // テーブル定義
      itemList: [],
      dataIndex: 0,
      // モーダルに渡す値
      postItem: '',
      // サービス区分
      serviceList: [],
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // トランザクションSQLリスト
      transactSqlList: [],
      // 定数（htmlで使用）
      htmlConst: {
        Calendar: {
          // 今日を選択するボタン名
          todayButtonName: Const.Calendar.todayButtonName,
          // 日付クリアボタンのボタン名
          clearButtonName: Const.Calendar.clearButtonName,
        },
      },
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'OrderDate',
          label: '発注日',
        },
        {
          key: 'Client',
          label: '取引先',
        },
        {
          key: 'ProductCode',
          label: '製品',
        },
        {
          key: 'ServiceClassText',
          label: 'サービス区分',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'StockScheduled',
          label: '入荷予定日',
        },
        {
          key: 'StockScheduledStaffText',
          label: '入荷予定処理担当者',
        },
        {
          key: 'StockScheduledConfirmClass',
          label: '入荷予定日確定',
        },
      ];
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  // マウント
  mounted() {
    init(); // common.jsにて初期化処理
  },
  // 関数群
  methods: {
    // 検索処理
    async initData() {
      const functionName = 'initData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // DB情報をまとめて取得
        let serviceListResult = null;
        let controlData = null;
        [serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
        ]);
        // サービス区分を取得
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 検索
        await this.search();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索
    async search() {
      this.itemList = [];
      // 指定した注文番号情報取得
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData != null && resultData.length > 0){
        //console.log(resultData);
        this.setResult(resultData);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // SELECT文字列作成
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += 'orders.order_id';
      selectSql += ',orders.order_row';
      selectSql += ',orders.order_date';
      selectSql += ',orders.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_name';
      selectSql += ',orders.service_class';
      selectSql += ',orders.order_quantity';
      selectSql += ',orders.unit';
      selectSql += ',orders.stock_scheduled';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',orders.stock_scheduled_staff_id';
      selectSql += ',stock_scheduled_staffs.staff_name_kanji AS stock_scheduled_staff_name';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS stock_scheduled_staffs ';
      selectSql += 'ON orders.stock_scheduled_staff_id = stock_scheduled_staffs.staff_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += ' orders.order_number = ' + this.propOrderNumber + ' ';
      selectSql += 'AND orders.is_deleted = 0 ';
      // ORDER BY句
      selectSql += 'ORDER BY orders.order_id,orders.order_row ';

      return selectSql;
    },
    // 取得結果セット
    setResult: function(result) {
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          OrderId: result[i].order_id,
          OrderRow: result[i].order_row,
          OrderDate: formatDate(result[i].order_date),
          Client: result[i].client_id + ' ' + result[i].client_name_kanji,
          ProductCode: result[i].product_id + ' ' + result[i].product_name,
          ServiceClassText: getListValue(this.serviceList, result[i].service_class, 'id', 'text'),
          Quantity: result[i].order_quantity.toLocaleString() + (getNullStr(result[i].unit) == '' ? '' : ' ' + getNullStr(result[i].unit)),
          StockScheduled: result[i].stock_scheduled,
          StockScheduledInit: result[i].stock_scheduled,
          StockScheduledStaffId: result[i].stock_scheduled_staff_id,
          StockScheduledStaffText: result[i].stock_scheduled_staff_id == null ? '' : result[i].stock_scheduled_staff_id + '：' + result[i].stock_scheduled_staff_name,
          StockScheduledConfirmClass: result[i].stock_scheduled_confirm_class,
        };
        this.itemList.push(searchResult);
      }
    },
    // 保存ボタンの押下
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        this.$bvModal.msgBoxConfirm('入力された情報で保存します。\nよろしいですか？').then(async value => {
          if (value) {
            await this.closeConfirmModal();
          }
        });
      }
    },
    // 確認モーダルを閉じた時
    async closeConfirmModal() {
      const functionName = 'closeConfirmModal';
      this.$store.commit('setLoading', true);
      try {
        await this.confirmSave();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 確認後保存処理
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.execSave() == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.search();
      }
      //console.log('保存処理終了');
    },
    // 登録処理
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 発注データの更新
      this.updateOrders();
      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return retResult;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      } else {
        retResult = true;
      }

      return retResult;
    },
    // 発注データ更新SQL作成
    async updateOrders() {
      //console.log('取引先マスタ更新SQL作成');
      let colList = [];
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      // 製品関係の更新部分
      let strWorkField = 'FIELD(CONCAT(order_id,\'_\',order_row)';
      let strWorkStockScheduled = '';
      let strWorkStockScheduledConfirmClass = '';
      let strWorkStockScheduledStaffId = '';
      for (let i = 0; i < this.itemList.length; i++) {
        strWorkField += ',\'' + this.itemList[i].OrderId + '_' + this.itemList[i].OrderRow + '\'';
        // 入荷予定日
        strWorkStockScheduled += ',\'' + (this.itemList[i].StockScheduled == '' ? '9999-12-31' : this.itemList[i].StockScheduled) + '\'';
        // 入荷予定日確定区分
        strWorkStockScheduledConfirmClass += ',' + this.itemList[i].StockScheduledConfirmClass;
        // 入荷予定処理担当者コード
        strWorkStockScheduledStaffId += ',' + this.itemList[i].StockScheduledStaffId;
      }
      strWorkField += ')';
      // 入荷予定日
      colList.push(CreateColRow('stock_scheduled', 'ELT(' + strWorkField + strWorkStockScheduled + ')', 'NUMBER'));
      // 入荷予定日確定区分
      colList.push(CreateColRow('stock_scheduled_confirm_class', 'ELT(' + strWorkField + strWorkStockScheduledConfirmClass + ')', 'NUMBER'));
      // 入荷予定処理担当者コード
      colList.push(CreateColRow('stock_scheduled_staff_id', 'ELT(' + strWorkField + strWorkStockScheduledStaffId + ')', 'NUMBER'));
      // WHERE句
      let where_clause = ' WHERE ';
      where_clause += ' order_number = ' + this.propOrderNumber + ' ';
      where_clause += 'AND is_deleted = 0 ';
      let updateSql = CreateUpdateSql(colList, 't_orders') + where_clause;
      //console.log(updateSql);
      // 更新SQL作成
      this.transactSqlList.push(updateSql);
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 入荷予定処理担当者検索
    showStockScheduledStaffSearchModal: function(index) {
      this.dataIndex = index;
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if(typeof staffItems != 'undefined'){
        this.itemList[this.dataIndex].StockScheduledStaffId = staffItems.detail.staff_id;
        this.itemList[this.dataIndex].StockScheduledStaffText = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
      }
    },
    /* カレンダーボタン押下時 */
    async openModal(item) {
      this.postItem = item;
      this.$bvModal.show('datepickerModal');
    },
    // 当日取得
    getToday: function() {
      return formatCurDate('YYYY-MM-DD');
    },
  },
}
</script>
<style scoped>
</style>