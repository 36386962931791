<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <!-- コンテンツ -->
    <div class="text-center" style="align-items: normal">
      <div class="form-signin" style="max-width: 650px">
        <div class="mt-5">
          <div class="media">
            <div class="media-body login-title pb-3" style="text-align: left">
              <h5 class="text-secondary m-0">
                <span class="oi oi-account-login"></span>
                サンワ販売管理システムログイン
              </h5>
            </div>
          </div>

          <div class="bg-light bg-login px-5 py-4 mb-4">
            <form @submit.prevent="login">
              <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <div v-else-if="warningMessage" class="alert alert-warning" role="alert">
                {{ warningMessage }}
              </div>
              <div class="pb-3">
                <label for="inputID" class="inputID-label"><strong>ユーザーID</strong></label>
                <input type="text" id="inputID" name="" class="form-control" maxlength="128"
                  value="mock-test" autofocus v-model="loginid" :disabled="newPasswordRequired" />
              </div>

              <div class="pb-3" v-if="!newPasswordRequired">
                <label for="inputPassword" class="inputPassword-label"><strong>パスワード</strong></label>
                <input type="password" id="inputPassword" name="" class="form-control"
                  maxlength="128" value="mock-test" v-model="password" />
              </div>

              <div class="pb-3" v-if="newPasswordRequired">
                <label for="inputNewPassword" class="inputPassword-label"><strong>パスワード</strong></label>
                <input type="password" id="inputNewPassword" name="" class="form-control"
                  maxlength="128" value="mock-test" v-model="newPassword" />
              </div>

              <div v-if="newPasswordRequired" class="pb-3">
                <label for="inputConfirmPassword" class="inputPassword-label"><strong>パスワード（確認）</strong></label>
                <input type="password" id="inputConfirmPassword" name="" class="form-control"
                  maxlength="128" value="mock-test" v-model="confirmPassword" />
              </div>

              <div class="row justify-content-md-center pt-2 pb-2">
                <div class="col-lg-4">
                  <button class="btn btn-primary btn-block" type="submit">
                    <span class="oi oi-account-login"></span> ログイン
                  </button>
                </div>
              </div>
            </form>
            <!-- パスワードリセットモーダル -->
            <div v-if="!newPasswordRequired">
              <ResetPassword />
            </div>
          </div>
          <div class="alert alert-success news-box pt-3 pb-0 px-4" role="alert">
            <div class="media">
              <div class="media-body pb-1">
                <h5 class="text-secondary m-0"><span class="oi oi-info"></span> お知らせ</h5>
              </div>
            </div>
            <p>2022/10/14 初回ログイン後、データの操作は行わないでください</p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import ResetPassword from '@/components/modal/reset-password.vue';
import { Auth } from 'aws-amplify';
import { setUserData } from '@/assets/js/common';
export default {
  name: 'LOGIN',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    ResetPassword,
  },
  data() {
    return {
      // ヘッダ
      menu_type: 'login',
      title: 'ログイン',

      loginid: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      warning: false,
      error: false,
      warningMessage: '',
      errorMessage: '',
      newPasswordRequired: false,
    };
  },
  /* マウント */
  mounted() {
    this.$store.commit('setLoading', false);
  },
  methods: {
    async login() {
      this.$store.commit('setLoading', true);
      let result = await this.signIn();
      if (!result) this.$store.commit('setLoading', false);
    },
    async signIn() {
      // エラーメッセージリセット
      this.errorMessage = '';
      this.warningMessage = '';

      if (!this.loginid || !this.password) {
        this.warningMessage = 'ユーザーIDとパスワードを入力してください';
        return false;
      }
      // console.log(`loginid = ${this.loginid}, password = ${this.password}`);
      // ログイン処理
      try {
        let user = await Auth.signIn(this.loginid, this.password);
        // console.log(user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (this.newPasswordRequired) {
            if (!this.newPassword || !this.confirmPassword) {
              this.warningMessage = '新しいパスワードを入力してください';
              return false;
            }
            if (this.newPassword !== this.confirmPassword) {
              this.warningMessage = '同じパスワードを入力してください';
              return false;
            }
            if (this.password === this.newPassword) {
              this.warningMessage = '新しいパスワードは変更前のパスワードと異なるパスワードである必要があります';
              return false;
            }
            try {
              // console.log('completeNewPassword');
              let newAuth = await Auth.completeNewPassword(user, this.newPassword);
              console.log(`user.username = ${newAuth.username}`);
              // ログイン中ユーザーの情報をCacheとVuexに書き込み
              let userJudge = await setUserData(user.username);
              if (userJudge) {
                // メニュー画面に遷移
                this.$router.push('/menu');
                return true;
              }
              return false;
            } catch (error) {
              // console.log('error completeNewPassword', error);
              if (error.code) {
                switch (error.code) {
                case 'InvalidPasswordException':
                  this.errorMessage =
                      'パスワードは数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上含んだ8文字以上の文字列を設定してください。';
                  break;
                default:
                  this.errorMessage = '認証エラーが発生しました。';
                  break;
                }
              }
              return false;
            }
          }
          this.warningMessage = '初期パスワードを変更する必要があります。\n新しいパスワードを入力してください。';
          this.newPasswordRequired = true;
          this.confirmPassword = '';
          return false;
        }
        // ログイン中ユーザーの情報をCacheとVuexに書き込み
        let userJudge = await setUserData(user.username);
        if (userJudge) {
          // メニュー画面に遷移
          this.$router.push('/menu');
          return true;
        }
        // キャッシュへのユーザー情報挿入に失敗時
        // エラーを投げてメッセージを表示
        throw 'setUserData Error';
      } catch (error) {
        // console.log('error signing in', error);
        if (error.code) {
          // console.log('error code', error.code);

          switch (error.code) {
          case 'UserNotFoundException':
          case 'InvalidParameterException':
            this.errorMessage = 'ユーザーIDかパスワードが正しくありません';
            break;
          case 'NotAuthorizedException': {
            if(error.message == 'Password attempts exceeded') {
              this.errorMessage = '所定の回数以上ログインに失敗しました。しばらくしてから再度お試しください';
              break;
            }
            this.errorMessage = 'ユーザーIDかパスワードが正しくありません';
            break;
          }
          case 'setUserData Error':
            this.errorMessage = '認証エラーが発生しました。';
            break;
          default:
            break;
          }
        }
        return false;
      }
    },
  },
};
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
html,
body {
  height: 100%;
}

body {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

/**/
.bg-login {
  border-radius: 4px;
}

/* ログインタイトル */
.login-title {
  font-size: 24px;
}

/*フォームタイトル*/
.inputID-label,
.inputPassword-label {
  width: 100%;
  text-align: left;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.notice {
  font-size: 16px;
}
</style>
