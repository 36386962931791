import { API, graphqlOperation } from 'aws-amplify';
import { list_v_products_02, list_m_clients_products, list_v_clients_01, list_m_clients_sites } from '@/graphql/queries';
import { addOperationLogs, checkProductId, checkClientId, checkSiteId, getNullStr } from '@/assets/js/common.js';

/**
 * 取引先マスタ検索直接入力モーダル
 * @param {Int} client_id - 取引先コード
 * @param {Int} clientClass -  取引先区分
 * @param {String} functionName - 呼ぶ先関数
 * @param {String} MODULE_NAME - 呼ぶ先モーダル
 * @returns {<Object>} 検索結果
 */
export async function searchClientModal(client_id, client_class, functionName, MODULE_NAME) {
  // 数字6桁のみ
  if (checkClientId(client_id)) {
    client_id = Number(client_id);
    let where_clause = '';
    // コード
    where_clause += `AND client_id = ${client_id} `;
    // 取引先区分
    where_clause += `AND client_class = ${client_class} `;

    let result = null
    try {
      result = await API.graphql(graphqlOperation(list_v_clients_01, { where_clause },));
    } catch (error) {
      console.log(error);
      await addOperationLogs(
        'Error',
        MODULE_NAME,
        functionName,
        {
          graphqlOperation: 'list_v_clients_01',
          whereClause: where_clause,
        },
        error
      );
      return undefined;
    }
    if (result.errors) {
      console.log(result.errors);
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'list_v_clients_01',
        whereClause: where_clause,
        result: result,
      });
      return undefined;
    }
    if (result.data.list_v_clients_01.length === 1) {
      const client = result.data.list_v_clients_01[0];
      return {
        detail: {
          ...client
        },
        clientId: client.client_id,
        clientNameKanji: client.client_name_kanji,
        clientNameKana: client.client_name_kana,
        address_1: client.address_1,
        address_2: client.address_2,
        Status: '',
      };
    } else {
      return undefined;
    }
  }
}
/**
 * 現場マスタ検索直接入力モーダル
 * @param {Int} site_id - 現場コード
 * @param {Int} client_id -  取引先コード
 * @param {String} functionName - 呼ぶ先関数
 * @param {String} MODULE_NAME - 呼ぶ先モーダル
 * @returns {<Object>} 検索結果
 */
export async function searchSiteModal(site_id, client_id, functionName, MODULE_NAME) {
  // 現場コード数字のみ  &&  取引先コード数字6桁のみ
  if (checkSiteId(site_id) && checkClientId(client_id)) {
    site_id = Number(site_id);
    client_id = Number(client_id);

    //console.log('site_id', site_id);
    //console.log('client_id', client_id);
    let where_clause = '';
    // 取引先コード
    where_clause += `AND client_id = ${client_id} `;
    // 現場コード
    where_clause += `AND site_id = ${site_id} `;

    let result = null
    try {
      result = await API.graphql(graphqlOperation(list_m_clients_sites, { where_clause },));
    } catch (error) {
      console.log(error);
      await addOperationLogs(
        'Error',
        MODULE_NAME,
        functionName,
        {
          graphqlOperation: 'list_m_clients_sites',
          whereClause: where_clause,
        },
        error
      );
      return undefined;
    }
    if (result.errors) {
      console.log(result.errors);
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'list_m_clients_sites',
        whereClause: where_clause,
        result: result,
      });
      return undefined;
    }
    if (result.data.list_m_clients_sites.length === 1) {
      const site = result.data.list_m_clients_sites[0];
      return {
        detail: {
          ...site
        },
        clientSiteId: site.site_id,
        clientSiteNameKanji: site.client_site_name_kanji,
        clientSiteNameKana: site.client_site_name_kana,
        clientSiteZipCode: site.client_site_zip_code,
        address1: site.client_site_address_1,
        address2: site.client_site_address_2,
        Status: '',
      };
    } else {
      return undefined;
    }
  }
}

/**
 * 製品マスタ検索直接入力モーダル
 * @param {Int} product_id - 製品コード
 * @param {object} productSearchProp -  製品検索情報
 * @param {String} functionName - 呼ぶ先関数
 * @param {String} MODULE_NAME - 呼ぶ先モーダル
 * @returns {<Object>} 検索結果
 */
export async function searchProductsModal(product_id, productSearchProp, functionName, MODULE_NAME) {
  // 数字8桁のみ
  if (checkProductId(product_id)) {
    product_id = Number(product_id);
    var [product, clientsProducts] = await Promise.all([
      searchProducts(product_id, productSearchProp, functionName, MODULE_NAME),
      searchClientsProducts(product_id, productSearchProp, functionName, MODULE_NAME),
    ])
    if (typeof product != 'undefined') {
      return {
        detail: {
          ...product,
          ...clientsProducts,
        }
      }
    } else {
      return undefined;
    }
  }
}

// list_v_products_02 製品検索
async function searchProducts(product_id, productSearchProp, functionName, MODULE_NAME) {
  let where_clause = '';
  // コード
  where_clause += `AND product_id = ${product_id} `;
  // 営業所コード
  if (productSearchProp.office_id != null && productSearchProp.office_id != '0') {
    where_clause += `AND office_id = ${productSearchProp.office_id} `;
  }

  let result = null;
  try {
    result = await API.graphql(
      graphqlOperation(list_v_products_02, {
        where_clause: where_clause,
      })
    );
  } catch (error) {
    console.log(error);
    await addOperationLogs(
      'Error',
      MODULE_NAME,
      functionName,
      {
        graphqlOperation: 'list_v_products_02',
        whereClause: where_clause,
      },
      error
    );
    return undefined;
  }
  if (result.errors) {
    console.log(result.error);
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      graphqlOperation: 'list_v_products_02',
      whereClause: where_clause,
      result: result,
    });
    return undefined;
  }
  if (result.data.list_v_products_02.length === 1) {
    const product = result.data.list_v_products_02[0];
    return {
      ...product
    };
  } else {
    return undefined;
  }
}

// list_m_clients_products 取引先製品検索
async function searchClientsProducts(product_id, productSearchProp, functionName, MODULE_NAME) {
  if (getNullStr(productSearchProp.kijunDate) != '') {
    // 取引先が指定されている場合のみ検索
    let where_clause = '';
    // 取引先区分
    where_clause += `AND client_class = ${productSearchProp.client_class} `;
    // 取引先コード
    where_clause += `AND client_id = ${productSearchProp.client_id} `;
    // 製品コード
    where_clause += `AND product_id = ${product_id} `;
    // 単価適用日（適用中のデータを取得するため未来は除外）
    where_clause += `AND unit_price_effective_date <= '${productSearchProp.kijunDate}' `;
    // ソート（適用日の直近）
    where_clause += 'ORDER BY unit_price_effective_date DESC ';
    where_clause += 'LIMIT 1 ';
    let resultClientsProducts = null;
    try {
      resultClientsProducts = await API.graphql(graphqlOperation(list_m_clients_products, { where_clause }));
    } catch (error) {
      console.log(error);
      let param = {
        graphqlOperation: 'list_m_clients_products',
        where_clause
      };
      await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
      return;
    }
    let dataClientsProducts = resultClientsProducts.data.list_m_clients_products;
    if (dataClientsProducts != null && dataClientsProducts.length > 0) {
      // 取引先製品が存在する場合
      return {
        cp_client_class: dataClientsProducts[0].client_class,
        cp_client_id: dataClientsProducts[0].client_id,
        cp_sales_unit_price: dataClientsProducts[0].sales_unit_price,
      }
    } else {
      return {}
    }
  } else {
    return {}
  }
}
