<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> コントロール保守</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card class="main-card mb-3 card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                  <!-- 現在処理年月 -->
                  <b-col lg="6">
                    <b-form-group
                      label="現在処理年月"
                      label-for="monthYearProcess"
                    >
                      <b-form-input id="monthYearProcess" type="month" readonly v-model="monthProcessMonthYear" />
                      <b-form-text class="text-muted">システム全体の現在処理年月です。</b-form-text>
                    </b-form-group>
                  </b-col>
                  <!-- 期首月 -->
                  <b-col lg="6">
                      <b-form-group
                        label="期首月"
                        label-for="textBeginningMonth"
                      >
                        <b-row>
                            <b-form-input id="textBeginningMonth" name="textBeginningMonth" v-model="textBeginningMonth" readonly />
                        </b-row>
                        <b-form-text class="text-muted">システム全体の期首月です。</b-form-text>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 消費税率 -->
                  <b-col lg="6">
                    <validation-provider name="textTaxRate" rules="required|between:0.00,999.99" v-slot="{ classes,errors }">
                      <b-form-group
                        label="消費税率"
                        label-for="textTaxRate"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-form-input type="number" step="0.01" id="textTaxRate" name="textTaxRate" v-model.number="textTaxRate" />
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">システム全体の消費税率を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 新消費税率 -->
                  <b-col lg="6">
                    <validation-provider name="textNewTaxRate" :rules="{between:{min:0.00,max:999.99},required:dateNewTaxStartDate!=''}" v-slot="{ classes,errors }">
                      <b-form-group
                        label="新消費税率"
                        label-for="textNewTaxRate"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-form-input type="number" step="0.01" id="textNewTaxRate" name="textNewTaxRate" v-model.number="textNewTaxRate" />
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">システム全体の新消費税開始日以降の消費税率を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 新消費税開始日 -->
                  <b-col lg="6">
                    <validation-provider name="dateNewTaxStartDate" :rules="{required:textNewTaxRate!=''||textNewLightTaxRate!=''}" v-slot="{ classes,errors }">
                      <b-form-group
                        label="新消費税開始日"
                        label-for="dateNewTaxStartDate"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-input-group class="input-daterange">
                              <b-form-datepicker
                                id="dateNewTaxStartDate"
                                name="dateNewTaxStartDate"
                                v-model="dateNewTaxStartDate"
                                calendar-width="50%"
                              ></b-form-datepicker>
                              <b-input-group-append>
                                <b-button size="sm" variant="outline-secondary" @click="dateNewTaxStartDate=''">
                                  <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">新消費税率の開始日を設定します<br>新消費税率を設定する場合は必ず設定してください</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 軽減消費税率 -->
                  <b-col lg="6">
                    <validation-provider name="textLightTaxRate" rules="required|between:0.00,999.99" v-slot="{ classes,errors }">
                      <b-form-group
                        label="軽減消費税率"
                        label-for="textLightTaxRate"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-form-input type="number" step="0.01" id="textLightTaxRate" name="textLightTaxRate" v-model.number="textLightTaxRate" />
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">システム全体の軽減消費税率を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 新軽減消費税率 -->
                  <b-col lg="6">
                    <validation-provider name="textNewLightTaxRate" :rules="{between:{min:0.00,max:999.99},required:dateNewTaxStartDate!=''}" v-slot="{ classes,errors }">
                      <b-form-group
                        label="新軽減消費税率"
                        label-for="textNewLightTaxRate"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-form-input type="number" step="0.01" id="textNewLightTaxRate" name="textNewLightTaxRate" v-model.number="textNewLightTaxRate" />
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">システム全体の新消費税開始日以降の軽減消費税率を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 軽減税率対象製品記号 -->
                  <b-col lg="6">
                    <b-form-group
                      label="軽減税率対象製品記号"
                      label-for="lightTaxMark"
                    >
                      <b-row>
                        <b-col lg="6">
                          <b-form-input name="lightTaxMark" type="text" maxlength="1" v-model="lightTaxMark" />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <!-- 適格請求書発行事業者の登録番号 -->
                  <b-col lg="6">
                    <validation-provider name="invoiceInputNo" rules="required" v-slot="{ classes,errors }">
                      <b-form-group
                        label="適格請求書発行事業者の登録番号"
                        label-for="invoiceInputNo"
                      >
                        <b-row>
                          <b-col lg="8" :class="classes">
                            <b-form-input name="invoiceInputNo" type="text" maxlength="20" v-model="invoiceInputNo" />
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- 保存ボタン -->
                <b-row class="justify-content-md-center pb-4">
                  <b-col lg="2">
                    <b-button  class="mr-2" pill block variant="success" title="入力されたデータを保存します。" type="submit" form="InputForm">
                      <span class="oi oi-circle-check"></span> 保存
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, addOperationLogs, formatDate, CreateUpdateSql, CreateColRow, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_control } from '@/graphql/queries';

const MODULE_NAME = 'control-master-update';

export default {
  name: 'CONTROL-MASTER-UPDATE',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'コントロール保守',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 現在処理年月
      monthProcessMonthYear: null,
      // 期首月
      textBeginningMonth: 0,
      // 消費税率
      textTaxRate: 0,
      // 新消費税率
      textNewTaxRate: 0,
      // 新消費税開始日
      dateNewTaxStartDate: '',
      // 軽減消費税率
      textLightTaxRate: 0,
      // 新軽減消費税率
      textNewLightTaxRate: 0,
      // 軽減税率対象製品記号
      lightTaxMark: '',
      // 適格請求書発行事業者の登録番号
      invoiceInputNo: '',
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 担当者実績更新SQLリスト
      // transactSqlListStaffResult: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 表示データを設定
        await this.setDispData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 表示データを設定 */
    async setDispData() {
      const functionName = 'setDispData';
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_control));
      } catch (error) {
        let param = {
          graphqlOperation: 'list_m_control',
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      let dataResult = result.data.list_m_control;
      //console.log(dataResult);
      if(dataResult != null && dataResult.length > 0) {
        await this.setResult(dataResult);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 取得結果セット */
    async setResult(result) {
      // 現在処理年月
      this.monthProcessMonthYear = formatDate(('000000' + result[0].process_month_year).slice(-6) + '01', 'YYYY-MM');
      // 期首月
      this.textBeginningMonth = result[0].beginning_month;
      // 消費税率
      this.textTaxRate = result[0].tax_rate;
      // 新消費税率
      this.textNewTaxRate = result[0].new_tax_rate;
      // 新消費税開始日
      this.dateNewTaxStartDate = result[0].new_tax_start_date;
      // 軽減消費税率
      this.textLightTaxRate = result[0].light_tax_rate;
      // 新軽減消費税率
      this.textNewLightTaxRate = result[0].new_light_tax_rate;
      // 軽減税率対象製品記号
      this.lightTaxMark = result[0].light_tax_mark;
      // 適格請求書発行事業者の登録番号
      this.invoiceInputNo = result[0].invoice_input_no;
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.execSave() == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      }
      //console.log('保存処理終了');
    },
    /* 更新処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // コントロールマスタ更新処理
      await this.updateControlMaster();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return retResult;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }
      retResult = true;

      return retResult;
    },
    /* コントロールマスタ更新SQL作成 */
    async updateControlMaster() {
      //console.log('コントロールマスタ更新SQL作成');
      let colList = [];
      // 消費税率
      colList.push(CreateColRow('tax_rate', this.textTaxRate, 'NUMBER'));
      // 新消費税率
      colList.push(CreateColRow('new_tax_rate', this.textNewTaxRate, 'NUMBER'));
      // 新消費税開始日
      colList.push(CreateColRow('new_tax_start_date', this.dateNewTaxStartDate, 'DATE'));
      // 軽減消費税率
      colList.push(CreateColRow('light_tax_rate', this.textLightTaxRate, 'NUMBER'));
      // 新軽減消費税率
      colList.push(CreateColRow('new_light_tax_rate', this.textNewLightTaxRate, 'NUMBER'));
      // 軽減税率対象製品記号
      colList.push(CreateColRow('light_tax_mark', this.lightTaxMark, 'VARCHAR'));
      // 適格請求書発行事業者の登録番号
      colList.push(CreateColRow('invoice_input_no', this.invoiceInputNo, 'VARCHAR'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      this.transactSqlList.push(CreateUpdateSql(colList, 'm_control'));
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>
<style scoped>
</style>