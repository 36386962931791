<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page">
      <div v-if="chouhyou.tempKbn == constData.tempKbnNormal">
        <TemplateEstimateNormal :id="constData.chouhyouId + chouhyou.page" />
      </div>
      <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
        <TemplateEstimateStart :id="constData.chouhyouId + chouhyou.page" />
      </div>
      <div v-if="chouhyou.tempKbn == constData.tempKbnMiddle">
        <TemplateEstimateMiddle :id="constData.chouhyouId + chouhyou.page" />
      </div>
      <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
        <TemplateEstimateEnd :id="constData.chouhyouId + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateEstimateNormal from '@/assets/svg/estimate_Normal.svg';
import TemplateEstimateStart from '@/assets/svg/estimate_Start.svg';
import TemplateEstimateMiddle from '@/assets/svg/estimate_Middle.svg';
import TemplateEstimateEnd from '@/assets/svg/estimate_End.svg';
import Const from '@/assets/js/const.js';
import { setPaperA4, setChouhyouBodyStyle, formatDate, getControlMaster, executeSelectSql, splitMultiRowString, addOperationLogs, getNullStr, dateConsistency } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { list_m_offices } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'estimate';

export default {
  name: 'ESTIMATE',
  /* コンポーネント */
  components: {
    TemplateEstimateNormal,
    TemplateEstimateStart,
    TemplateEstimateMiddle,
    TemplateEstimateEnd
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 18,
        cntStart: 24,
        cntMiddle: 32,
        cntEnd: 26,
        tempKbnNormal: 1,
        tempKbnStart: 2,
        tempKbnMiddle: 3,
        tempKbnEnd: 4,
        addCntTax: 6,
        addCntNoTax: 1,
        chouhyouId: 'idChouhyou',
        addressOfficeId_1: 1,
        addressOfficeId_2: 2,
      },
      // ヘッダ
      menu_type: 'user',
      title: '見積書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      taxFlg: false,
      estimateKindFlg: false,
      stampFlg: false,
      colStampCX: 0,
      colStampCY: 0,
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],
      // 営業所情報
      officeList: [{
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      },
      {
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // アラート
      alertDanger: [],
      // パラメータ
      estimateId: 0,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.estimateId = this.$route.query.estimateId;
    // 印刷レイアウト設定
    setPaperA4();
    // 中央寄せ
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '見積書_' + this.estimateId;
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // コントロールマスタ
        let controlData = await getControlMaster();
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 指定された見積データを設定
        await this.setEstimate();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listChouhyou = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 見積データ設定 */
    async setEstimate() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      // 検索条件作成
      let dataEstimate = await executeSelectSql(selectSql);
      //console.log(dataEstimate);
      if (dataEstimate != null &&
      dataEstimate.length > 0) {
        if (dataEstimate[0].estimate_kind == Const.EstimateKind.estimate) {
          this.estimateKindFlg = true;
          this.taxFlg = false;
          this.addProductCnt = 0;
        } else {
          this.estimateKindFlg = false;
          // 消費税区分（1:消費税あり、2:消費税なし）
          if (dataEstimate[0].tax_type == Const.TaxType.tax) {
            this.taxFlg = true;
            this.addProductCnt = this.constData.addCntTax;
          } else {
            this.taxFlg = false;
            this.addProductCnt = this.constData.addCntNoTax;
          }
        }
        // 営業所情報取得
        await this.getOfficesData();
        // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
        await this.initListChouhyou(dataEstimate.length + this.addProductCnt);
        // 共通の置換文字列設定
        await this.createReplacementsCommon(dataEstimate);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(dataEstimate);
        // 作成した置換文字データをSVGファイルに設定
        await this.setChouhyou();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    // 検索SELECT文字列作成
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' estimate.estimate_id';
      selectSql += ',estimate.client_print_name';
      selectSql += ',estimate.estimate_date';
      selectSql += ',estimate.client_staff_id';
      selectSql += ',estimate.client_staff_name_kanji';
      selectSql += ',estimate.client_staff_name_stamp';
      selectSql += ',estimate.created_staff_id';
      selectSql += ',estimate.created_staff_name_kanji';
      selectSql += ',estimate.created_staff_name_stamp';
      selectSql += ',estimate.estimate_kind';
      selectSql += ',estimate.tax_type';
      selectSql += ',estimate.delivery_date';
      selectSql += ',estimate.estimate_amount';
      selectSql += ',estimate.tax';
      selectSql += ',estimate.client_site_name_kanji';
      selectSql += ',estimate.payment_way';
      selectSql += ',estimate.estimate_period';
      selectSql += ',estimate.estimate_period_sentence';
      selectSql += ',estimate.summary';
      selectSql += ',estimate.product_id';
      selectSql += ',estimate.product_name_kanji';
      selectSql += ',estimate.product_note';
      selectSql += ',estimate.product_quantity';
      selectSql += ',estimate.product_unit';
      selectSql += ',estimate.product_sales_unit_price';
      selectSql += ',estimate.product_amount';
      selectSql += ',products.sundries_class';
      selectSql += ',IfNull(products.product_tax_rate_class_sales,' + Const.ProductTaxRateClass.normalTax + ') AS product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_estimate AS estimate ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON estimate.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の見積番号で絞り込む
      selectSql += 'estimate.estimate_id = ' + this.estimateId + ' ';
      // 製品一覧の行番号でソート
      selectSql += 'ORDER BY estimate.estimate_row';

      return selectSql;
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
      let officeListData = officeListResult.data.list_m_offices;
      //console.log(officeListData);
      for(let i = 0; i < officeListData.length; i++){
        if (this.constData.addressOfficeId_1 == officeListData[i].office_id ||
          this.constData.addressOfficeId_2 == officeListData[i].office_id) {
          if (this.constData.addressOfficeId_1 == officeListData[i].office_id) {
            this.officeList[0].keyNo = '1';
            this.officeList[0].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[0].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
          } else {
            this.officeList[1].keyNo = '2';
            this.officeList[1].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[1].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[1].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[1].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
          }
        }
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      //console.log('initListChouhyou');
      if (productCnt <= this.constData.cntNormal) {
        // 製品が通常テンプレートの件数で収まる場合（通常帳票1ページのみ）
        this.listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnNormal, replacements: []});
      } else {
        let productCntNokori = productCnt;
        let page = 1;
        // 開始帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnStart, replacements: []});
        productCntNokori -= this.constData.cntStart;
        page++;
        // 中間帳票
        while (productCntNokori > this.constData.cntEnd) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnMiddle, replacements: []});
          productCntNokori -= this.constData.cntMiddle;
          page++;
        }
        // 終了帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
      }
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      //console.log('createReplacementsCommon');
      // 見積日付
      this.replacementsCommon.push({key: '%見積日付%', value: formatDate(result[0].estimate_date), textAnchor: 'end', textLength: 251, chkWidth: false});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      //console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      let strWork = '';
      let subTotalNormalFlg = false;
      let subTotalNormal = 0;
      let subTotalNormalTax = 0;
      let subTotalLightFlg = false;
      let subTotalLight = 0;
      let subTotalLightTax = 0;
      let taxRate = 0;
      let lightTaxRate = 0;
      if (this.controlMasterData.newTaxStartDate == null ||
        dateConsistency(this.controlMasterData.newTaxStartDate, result[0].estimate_date) == false) {
        taxRate = this.controlMasterData.taxRate;
        lightTaxRate = this.controlMasterData.lightTaxRate;
      } else {
        taxRate = this.controlMasterData.newTaxRate;
        lightTaxRate = this.controlMasterData.newLightTaxRate;
      }
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // 見積番号
        replacements.push({key: '%見積番号%', value: result[0].estimate_id, textAnchor: 'end', textLength: 251, chkWidth: false});
        // ページ番号
        replacements.push({key: '%P%', value: this.listChouhyou[i].page + ' / ' + this.listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
        // 通常、または、開始帳票
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal ||
          this.listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
          if (this.taxFlg == true || this.estimateKindFlg == true) {
            // タイトル
            replacements.push({key: '%タイトル%', value: '御 見 積 書', textAnchor: 'middle', textLength: 1785, chkWidth: false});
            if (this.estimateKindFlg == true) {
              // 見積種類が「2:単価見積り」の場合は税抜きに関する説明を表示
              replacements.push({key: '%税込説明%', value: '表示金額は全て税抜きになっておりますので、請求時に別途、消費税金額を申し上げます。', textAnchor: 'start', textLength: 900, chkWidth: true});
            } else {
              replacements.push({key: '%税込説明%', value: '', textAnchor: 'start', textLength: 900, chkWidth: false});
            }
          } else {
            // タイトル
            replacements.push({key: '%タイトル%', value: '御 見 積 書', textAnchor: 'middle', textLength: 1785, chkWidth: false});
            replacements.push({key: '%税込説明%', value: '表示金額は全て税抜きになっておりますので、請求時に別途、消費税金額を申し上げます。', textAnchor: 'start', textLength: 900, chkWidth: true});
          }
          // 軽減税率の説明
          replacements.push({key: '%税率説明%', value: '「' + this.controlMasterData.lightTaxMark + '」は軽減税率対象項目', textAnchor: 'start', textLength: 1600, chkWidth: false});
          // 取引先名
          replacements.push({key: '%取引先名%', value: result[0].client_print_name, textAnchor: 'start', textLength: 832, chkWidth: true});
          // 受渡期日
          replacements.push({key: '%受渡期日%', value: formatDate(result[0].delivery_date), textAnchor: 'start', textLength: 700, chkWidth: false});
          // 受渡場所
          replacements.push({key: '%受渡場所%', value: result[0].client_site_name_kanji, textAnchor: 'start', textLength: 700, chkWidth: true});
          // 有効期限
          let estimatePeriod = formatDate(result[0].estimate_period);
          estimatePeriod += getNullStr(result[0].estimate_period_sentence);
          replacements.push({key: '%有効期限%', value: estimatePeriod, textAnchor: 'start', textLength: 700, chkWidth: true});
          // 支払方法
          replacements.push({key: '%支払方法%', value: result[0].payment_way, textAnchor: 'start', textLength: 700, chkWidth: true});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 担当者名（作成担当者は取引担当者と異なる場合に括弧付きで表示）
          let staffName = getNullStr(result[0].client_staff_name_kanji).trim();
          if (result[0].client_staff_id != result[0].created_staff_id) {
            staffName += getNullStr(result[0].created_staff_name_kanji).trim() == '' ? '' : '（' + result[0].created_staff_name_kanji + '）';
          }
          replacements.push({key: '%担当者名%', value: staffName, textAnchor: 'start', textLength: 416, chkWidth: true});
          // 担当者印
          let staffStamp = getNullStr(result[0].created_staff_name_stamp).trim() == '' ? getNullStr(result[0].client_staff_name_stamp).trim() : getNullStr(result[0].created_staff_name_stamp).trim();
          replacements.push({key: '%社判%', value: staffStamp, textAnchor: 'middle', textLength: 100, chkWidth: true});
          if (staffStamp != '') {
            this.stampFlg = true;
          }
          // 見積金額（見積種類が「2:単価見積り」の場合、固定文言）
          let estimateAmount = '';
          let amountUnit = '';
          if (result[0].estimate_kind == Const.EstimateKind.estimate) {
            estimateAmount = '単価御見積';
            replacements.push({key: '%見積金額%', value: estimateAmount, textAnchor: 'start', textLength: 400, chkWidth: false});
          } else {
            estimateAmount = result[0].estimate_amount.toLocaleString();
            replacements.push({key: '%見積金額%', value: estimateAmount, textAnchor: 'end', textLength: 400, chkWidth: true});
            amountUnit = '円';
            if (this.taxFlg == true) {
              amountUnit += '（消費税込）';
            } else {
              amountUnit += '（税別）';
            }
          }
          // 金額単位
          replacements.push({key: '%金額単位%', value: amountUnit, textAnchor: 'start', textLength: 170, chkWidth: false});
        }
        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = 0;
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal) {
          productCntByChouhyou = this.constData.cntNormal;
        } else if (this.listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
          productCntByChouhyou = this.constData.cntStart;
        } else if (this.listChouhyou[i].tempKbn == this.constData.tempKbnMiddle) {
          productCntByChouhyou = this.constData.cntMiddle;
        } else {
          productCntByChouhyou = this.constData.cntEnd;
        }
        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          if(productsIndex < result.length){
            // 商品名
            strWork = result[productsIndex].product_name_kanji;
            if (result[productsIndex].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
              strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
            }
            replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 単価
            replacements.push({key: '%単価' + (j + 1).toString() + '%', value: this.modUnitPrice(result[productsIndex].product_id, result[productsIndex].sundries_class, result[productsIndex].product_sales_unit_price), textAnchor: 'end', textLength: 220, chkWidth: false});
            // 数量
            let productQuantity = this.modQuantity(result[productsIndex].product_id, result[productsIndex].sundries_class, result[productsIndex].product_quantity);
            if (productQuantity != '') {
              productQuantity += getNullStr(result[productsIndex].product_unit) == '' ? '' : ' ' + result[productsIndex].product_unit;
            }
            replacements.push({key: '%数量' + (j + 1).toString() + '%', value: productQuantity, textAnchor: 'end', textLength: 180, chkWidth: true});
            // 金額
            replacements.push({key: '%金額' + (j + 1).toString() + '%', value: this.modAmount(result[productsIndex].product_id, result[productsIndex].sundries_class, result[productsIndex].product_sales_unit_price, result[productsIndex].product_quantity, result[productsIndex].product_amount), textAnchor: 'end', textLength: 240, chkWidth: false});
            if (result[productsIndex].product_tax_rate_class_sales == Const.ProductTaxRateClass.normalTax) {
              // 通常税の小計
              subTotalNormal += result[productsIndex].product_amount;
            } else if (result[productsIndex].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
              // 軽減税の小計
              subTotalLight += result[productsIndex].product_amount;
            }
            // 品番
            replacements.push({key: '%品番' + (j + 1).toString() + '%', value: result[productsIndex].product_id == 0 ? '' : '(' + result[productsIndex].product_id + ')', textAnchor: 'start', textLength: 360, chkWidth: false});
            // 備考
            replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[productsIndex].product_note, textAnchor: 'start', textLength: 360, chkWidth: true});

            productsIndex++;
          }else{
            // 単価
            replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 220, chkWidth: false});
            // 数量
            replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 180, chkWidth: false});
            // 品番
            replacements.push({key: '%品番' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 360, chkWidth: false});
            // 備考
            replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 360, chkWidth: true});
            if ((this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal ||
              this.listChouhyou[i].tempKbn == this.constData.tempKbnEnd) &&
              j + 1 == productCntByChouhyou && this.estimateKindFlg == false) {
              // 通常、または、終了帳票
              // 製品の最終行（合計）
              // 商品名
              replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '合 計', textAnchor: 'middle', textLength: 550, chkWidth: false});
              // 金額
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[0].estimate_amount.toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});
            } else if ((this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal ||
              this.listChouhyou[i].tempKbn == this.constData.tempKbnEnd) &&
              j + 2 == productCntByChouhyou && this.taxFlg == true) {
              // 通常、または、終了帳票
              // 製品の最終行の2行前、かつ、消費税あり（消費税）
              // 商品名
              replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '消費税' + lightTaxRate + '%', textAnchor: 'middle', textLength: 550, chkWidth: false});
              // 金額
              subTotalLightTax = Math.trunc(subTotalLight * lightTaxRate / 100);
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: subTotalLightTax.toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});
            } else if ((this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal ||
              this.listChouhyou[i].tempKbn == this.constData.tempKbnEnd) &&
              j + 3 == productCntByChouhyou && this.taxFlg == true) {
              // 通常、または、終了帳票
              // 製品の最終行の3行前、かつ、消費税あり（消費税）
              // 商品名
              replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '消費税' + taxRate + '%', textAnchor: 'middle', textLength: 550, chkWidth: false});
              // 金額
              subTotalNormalTax = Math.trunc(subTotalNormal * taxRate / 100);
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: subTotalNormalTax.toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});
            } else if (whiteFlg == true) {
              // 上記以外で以下余白を設定済み
              // 商品名
              replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 550, chkWidth: false});
              // 金額
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 240, chkWidth: false});
            } else {
              // 以下余白を記載していない
              if (productsIndex == result.length &&
              this.taxFlg == true) {
                // 製品の最後を設定した次の行、かつ、消費税あり
                if (subTotalNormalFlg == false) {
                  // 小計（通常消費税）を表示
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '消費税' + taxRate + '%課税 小計', textAnchor: 'start', textLength: 550, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: subTotalNormal.toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});

                  subTotalNormalFlg = true;
                } else if (subTotalLightFlg == false) {
                  // 小計（軽減消費税）を表示
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '消費税' + lightTaxRate + '%課税（' + this.controlMasterData.lightTaxMark + '） 小計', textAnchor: 'start', textLength: 550, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: subTotalLight.toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});

                  subTotalLightFlg = true;
                } else {
                  // 小計を表示
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '小 計', textAnchor: 'start', textLength: 550, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: (result[0].estimate_amount - result[0].tax).toLocaleString(), textAnchor: 'end', textLength: 240, chkWidth: false});

                  productsIndex++;
                }
              } else {
                // 以下余白が入る箇所
                if (i + 1 == this.listChouhyou.length &&
                ((j + 4 == productCntByChouhyou && this.taxFlg == true) ||
                 j + 2 == productCntByChouhyou)) {
                  // 最終帳票、かつ、（最終行の2行前で消費税あり、または、最終帳票の1行前）の場合、余白がないため、「以上」とする。
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以上', textAnchor: 'middle', textLength: 550, chkWidth: false});
                } else {
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以下余白', textAnchor: 'middle', textLength: 550, chkWidth: false});
                }
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 240, chkWidth: false});

                whiteFlg = true;
              }
            }
          }
        }

        // 通常、または、終了帳票
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal ||
          this.listChouhyou[i].tempKbn == this.constData.tempKbnEnd) {
          // 摘要
          this.setSummary(replacements, result[0].summary);
        }
        this.listChouhyou[i].replacements = replacements;
      }
    },
    // 単価改変
    modUnitPrice: function(productId, sundriesClass, unitPrice) {
      if (productId == 0 || sundriesClass == Const.SundriesClass.shokuchi) {
        // 製品コードが0、または、諸口の場合
        if (unitPrice == 0) {
          // 値が0の場合は空白
          return '';
        }
      }
      return unitPrice.toLocaleString();
    },
    // 数量改変
    modQuantity: function(productId, sundriesClass, quantity) {
      if (productId == 0 || sundriesClass == Const.SundriesClass.shokuchi) {
        // 製品コードが0、または、諸口の場合
        if (quantity == 0) {
          // 値が0の場合は空白
          return '';
        }
      }
      return quantity.toLocaleString();
    },
    // 金額改変
    modAmount: function(productId, sundriesClass, unitPrice, quantity, amount) {
      if (productId == 0 || sundriesClass == Const.SundriesClass.shokuchi) {
        // 製品コードが0、または、諸口の場合
        if (unitPrice == 0 || quantity == 0) {
          // 値が0の場合は空白
          return '';
        }
      }
      return amount.toLocaleString();
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for(let i = 0; i < this.officeList.length; i++){
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: 150, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: 287, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, summary) {
      let arySummary = splitMultiRowString(summary, 60);
      for (let i = 0; i < 3; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: arySummary[i], textAnchor: 'start', textLength: 1322, chkWidth: true});
        } else {
          // 備考
          replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 1322, chkWidth: true});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      //console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        await this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
    },
    /* 置換値をSVGファイルに設定 */
    async setReplacements(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  await this.setTspan(node.children[i], node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  if(replacements[k].key == '%社判%'){
                    await this.setStamp(node.children[i], node.children[i].children[j], replacements[k]);
                  }else{
                    await this.setTspan(node.children[i], node.children[i].children[j], replacements[k]);
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'circle') {
          // 社判の赤丸
          if (node.children[i].getAttribute('stroke') == 'red') {
            if (this.stampFlg == true) {
              // 社判のテキストに使用するため中心の座標を取得しておく
              this.colStampCX = node.children[i].getAttribute('cx');
              this.colStampCY = node.children[i].getAttribute('cy');
            } else {
              // 赤丸を削除
              node.removeChild(node.children[i]);
              i--; 
            }
          }
        } else if(node.children[i].tagName == 'g'){
          await this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(config.chkWidth == true &&
        tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    async setStamp(tagText, tagTspan, config){
      if (config.value.length == 0) {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      } else if (config.value.length == 1) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) + 20);
      } else if (config.value.length == 2) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '40');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 5);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 35, config.value.substr(1, 1)));
      } else if (config.value.length == 3) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '30');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 20);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 40, config.value.substr(2, 1)));
      } else if (config.value.length == 4) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '35');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 8);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 18, Number(this.colStampCY) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) + 28, config.value.substr(3, 1)));
      } else {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '28');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 9);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 15, Number(this.colStampCY) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 38, config.value.substr(4, 1)));
      }
    },
    /* 印鑑用のtspan作成 */
    async createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>