<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <label for="billingNo">伝票番号<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="billingNo" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingDateStart" :rules="{dateConsistency:searchConditions.billingDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="伝票日付"
                              label-for="datepicker"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="billingDateStart" class="form-control" v-model="searchConditions.billingDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="billingDateEnd" class="form-control" v-model="searchConditions.billingDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-row class="justify-content-md-center pb-4">
          <b-col lg="12">
            <b-btn-toolbar class="mt-2">
              <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover title="一覧を出した検索条件でPCA会計テーブルのデータのCSV出力を行います。" @click="onOutpurCsvButtonClick" :disabled="itemList.length==0">
                <span class="oi oi-data-transfer-download"></span> CSV出力
              </b-button>
              <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover title="一覧を出した検索条件に紐づくPCA会計テーブルのデータを削除します。" @click="onClearButtonClick" :disabled="itemList.length==0">
                <span class="oi oi-delete"></span> データクリア
              </b-button>
            </b-btn-toolbar>
          </b-col>
        </b-row>
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(DebitAccount)>借方<br>勘定科目</template>
              <template #head(DebitTaxClass)>借方<br>税区分</template>
              <template #head(CreditAccount)>貸方<br>勘定科目</template>
              <template #head(CreditTaxClass)>貸方<br>税区分</template>
              <!-- printイベント用のレイアウト -->
              <template v-if="printStatus" #cell(CustomerName)="data">
                <div style="max-width:160px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span v-b-tooltip.hover :title="data.item.CustomerName">
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(DebitAmount)="data">
                {{data.item.DebitAmount.toLocaleString()}}
              </template>
              <template #cell(CreditAmount)="data">
                {{data.item.CreditAmount.toLocaleString()}}
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mt-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, addOperationLogs, formatDate, formatCurDate, executeSelectSql, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'pca-account-list';

export default {
  name: 'PCA-ACCOUNT-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'PCA会計用データ一覧',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions: {
        billingNo: '',
        billingDateStart: '',
        billingDateEnd: '',
      },
      // 検索条件（検索ボタン押下時）
      searchResultConditions: {
        billingNo: '',
        billingDateStart: '',
        billingDateEnd: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'BillingDate',
          label: '伝票日付',
          sortable: true,
        },
        {
          key: 'BillingNo',
          label: '伝票No',
          sortable: true,
        },
        {
          key: 'DebitAccount',
          label: '借方勘定科目',
          sortable: true,
        },
        {
          key: 'DebitTaxClass',
          label: '借方税区分',
          sortable: true,
        },
        {
          key: 'DebitAmount',
          label: '借方金額',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'CreditAccount',
          label: '貸方勘定科目',
          sortable: true,
        },
        {
          key: 'CreditTaxClass',
          label: '貸方税区分',
          sortable: true,
        },
        {
          key: 'CreditAmount',
          label: '貸方金額',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'Summary',
          label: '摘要',
          sortable: true,
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods: {
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期検索
        //await this.search();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        // 検索条件を保持
        this.saveSearchConditions();
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 2000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%', '2000').replace('%arg2%', '検索結果'));
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // 検索条件を保存
    saveSearchConditions: function() {
      this.searchResultConditions.billingNo = this.searchConditions.billingNo;
      this.searchResultConditions.billingDateStart = this.searchConditions.billingDateStart;
      this.searchResultConditions.billingDateEnd = this.searchConditions.billingDateEnd;
    },
    // 取得結果セット
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          BillingDate: formatDate(result[i].billing_date),
          BillingNo: result[i].billing_no,
          DebitAccount: result[i].debit_account,
          DebitTaxClass: result[i].debit_tax_class,
          DebitAmount: result[i].debit_amount,
          CreditAccount: result[i].credit_account,
          CreditTaxClass: result[i].credit_tax_class,
          CreditAmount: result[i].credit_amount,
          Summary: result[i].summary,
        };
        this.itemList.push(searchResult);
      }
    },
    // SELECT文字列作成
    makeSelectSql: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billing_date';
      selectSql += ',billing_no';
      selectSql += ',debit_account';
      selectSql += ',debit_tax_class';
      selectSql += ',debit_amount';
      selectSql += ',credit_account';
      selectSql += ',credit_tax_class';
      selectSql += ',credit_amount';
      selectSql += ',summary';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_accounting_system_linkage ';
      // WHERE句
      let where_clause = '';
      // 伝票番号
      if (this.searchConditions.billingNo != '') {
        where_clause += 'AND billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 伝票日付
      if (this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd == '') {
        where_clause += 'AND billing_date >= ' + '\'' + this.searchConditions.billingDateStart + '\' ';
      } else if(this.searchConditions.billingDateStart == '' && this.searchConditions.billingDateEnd != '') {
        where_clause += 'AND billing_date <= ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      } else if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd != '') {
        where_clause += 'AND billing_date BETWEEN ' + '\'' + this.searchConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }
      if (where_clause != '') {
        selectSql += ' WHERE ';
        selectSql += where_clause.substr(4);
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += 'transaction_id';
      selectSql += ',billing_date';
      selectSql += ',billing_no';
      selectSql += ',billing_row';
      // LIMIT
      selectSql += ' LIMIT 2000';

      return selectSql;
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // CSV出力ボタン押下
    async onOutpurCsvButtonClick() {
      // 出力用CSV文字列作成
      const csvData = this.createCsv();
      // ファイル名
      const filename = 'PCA_' + formatCurDate('YYYY-MM-DD HH.mm.ss') + '.csv';

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blobPart = csvData.length === 0 ? [csvData] : [bom, csvData];
      const blob = new Blob(blobPart, { type: 'text/csv' });
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    },
    // CSV作成
    createCsv: function() {
      let listCsv = [];
      // ヘッダー行
      listCsv.push(this.getCsvHeader());
      for (let i = 0; i < this.itemList.length; i++) {
        let strCsvData = '';
        // 伝票日付
        strCsvData += formatDate(this.itemList[i].BillingDate,'YYYYMMDD');
        // 伝票ＮＯ
        strCsvData += ',' + this.itemList[i].BillingNo;
        // 計算区分
        strCsvData += ',0';
        // 借方部門コ－ド
        strCsvData += ',0';
        // 借方勘定科目
        strCsvData += ',' + this.itemList[i].DebitAccount;
        // 借方科目名
        strCsvData += ',';
        // 借方補助科目
        strCsvData += ',';
        // 借方補助科目名
        strCsvData += ',';
        // 借方税区分
        strCsvData += ',' + this.itemList[i].DebitTaxClass;
        // 借方金額
        strCsvData += ',' + this.itemList[i].DebitAmount;
        // 借方消費税額
        strCsvData += ',';
        // 貸方部門コ－ド
        strCsvData += ',0';
        // 貸方勘定科目
        strCsvData += ',' + this.itemList[i].CreditAccount;
        // 貸方科目名
        strCsvData += ',';
        // 貸方補助科目
        strCsvData += ',';
        // 貸方補助科目名
        strCsvData += ',';
        // 貸方税区分
        strCsvData += ',' + this.itemList[i].CreditTaxClass;
        // 貸方金額
        strCsvData += ',' + this.itemList[i].CreditAmount;
        // 貸方消費税額
        strCsvData += ',';
        // 数字１
        strCsvData += ',';
        // 数字２
        strCsvData += ',';
        // 摘要（CSVの特殊文字であるカンマとダブルクォーテーションは全角に置換）
        strCsvData += ',' + this.itemList[i].Summary.replace(/,/g, '，').replace(/"/g, '”');
        // ０Ｄ
        strCsvData += ',';
        // ０Ａ
        strCsvData += ',';
        listCsv.push(strCsvData);
      }

      return listCsv.join('\n');
    },
    // CSVヘッダー取得
    getCsvHeader: function() {
      let strCsvHeader = '';
      // 基本情報
      strCsvHeader += '伝票日付';
      strCsvHeader += ',伝票ＮＯ';
      strCsvHeader += ',計算区分';
      // 借方
      strCsvHeader += ',借方部門コ－ド';
      strCsvHeader += ',借方勘定科目';
      strCsvHeader += ',借方科目名';
      strCsvHeader += ',借方補助科目';
      strCsvHeader += ',借方補助科目名';
      strCsvHeader += ',借方税区分';
      strCsvHeader += ',借方金額';
      strCsvHeader += ',借方消費税額';
      // 貸方
      strCsvHeader += ',貸方部門コ－ド';
      strCsvHeader += ',貸方勘定科目';
      strCsvHeader += ',貸方科目名';
      strCsvHeader += ',貸方補助科目';
      strCsvHeader += ',貸方補助科目名';
      strCsvHeader += ',貸方税区分';
      strCsvHeader += ',貸方金額';
      strCsvHeader += ',貸方消費税額';
      // その他
      strCsvHeader += ',数字１';
      strCsvHeader += ',数字２';
      strCsvHeader += ',摘要';
      strCsvHeader += ',０Ｄ';
      strCsvHeader += ',０Ａ';

      return strCsvHeader;
    },
    // データクリアボタン押下
    async onClearButtonClick() {
      const functionName = 'onClearButtonClick';
      if (await this.$bvModal.msgBoxConfirm('指定された検索条件のPCA会計用データを削除します。\nよろしいですか？', {
        title: 'PCA会計用データの削除',
      })) {
        try {
          this.$store.commit('setLoading', true);
          await this.execClear();
        } catch(error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          console.log(error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        // メッセージが１件でもある場合は一番上へスクロール
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // クリア実行
    async execClear() {
      const functionName = 'execClear';
      let sqlList = [];
      // 会計システム連携（支払）の削除SQL
      let deleteSql = 'DELETE FROM t_accounting_system_linkage';
      // WHERE句
      let where_clause = '';
      // 伝票番号
      if (this.searchResultConditions.billingNo != '') {
        where_clause += 'AND billing_no = ' + this.searchResultConditions.billingNo + ' ';
      }
      // 伝票日付
      if (this.searchResultConditions.billingDateStart != '' && this.searchResultConditions.billingDateEnd == '') {
        where_clause += 'AND billing_date >= ' + '\'' + this.searchResultConditions.billingDateStart + '\' ';
      } else if(this.searchResultConditions.billingDateStart == '' && this.searchResultConditions.billingDateEnd != '') {
        where_clause += 'AND billing_date <= ' + '\'' + this.searchResultConditions.billingDateEnd + '\' ';
      } else if(this.searchResultConditions.billingDateStart != '' && this.searchResultConditions.billingDateEnd != '') {
        where_clause += 'AND billing_date BETWEEN ' + '\'' + this.searchResultConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchResultConditions.billingDateEnd + '\' ';
      }
      if (where_clause != '') {
        deleteSql += ' WHERE ';
        deleteSql += where_clause.substr(4);
      }
      //console.log(deleteSql);
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
  },
}
</script>
<style scoped>
</style>