<template>
 <b-modal
  id="clientSearchModal"
  size="xl"
  centered
  @show="searchButton('init')"
  title="取引先検索"
 >
  <b-container class="px-0">
    <b-row>
      <b-col>
        <!-- ●●●検索条件●●● -->
        <div class="main-card card px-3 pt-2">
            <div class="mb-0" id="heading1">
              <h5 class="mb-0">
                <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                  <span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
            </div>
            <b-collapse id="collapse-1" visible>
              <b-card-body class="p-1">
                <b-form @submit.prevent="searchButton('click');" id="clientSearchForm" class="form-horizontal">
                  <validation-observer ref="observer">
                    <b-row>
                      <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 form-group px-1">
                        <b-input-group>
                          <template #prepend>
                            <label for="clientName">
                              <b-input-group-text  class="px-1">
                                取引先名
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="search" id="clientName" name="clientName" class=" form-control" v-model.trim="searchConditions.clientName" autofocus></b-form-input>
                        </b-input-group>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 form-group px-1">
                        <validation-provider name="clientCode" rules="numeric" v-slot="{ classes,errors }">
                          <b-input-group>
                            <template #prepend>
                              <label for="clientCode">
                                <b-input-group-text  class="px-1">
                                  取引先コード
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="search" id="clientCode" name="clientCode" v-model.trim="searchConditions.clientCode"></b-form-input>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>    
                          </b-input-group>
                        </validation-provider>
                      </div>
                    </b-row>
                  </validation-observer>
                  <!-- 検索ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="3">
                      <b-button block pill variant="success" size="sm" type="submit" form="clientSearchForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-collapse>
        </div>
      </b-col>
    </b-row>
    <!-- ●●●検索結果●●● -->
    <b-card id="resultArea">
      <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
        <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-col class="mt-0">
        <b-row>
          <!-- 1ページあたりの表示選択 -->
          <b-col lg="6" class="my-1">
            <b-form-group
              label="1ページの表示件数"
              label-for="per-page-select"
              label-cols-sm="5"
              label-size="sm"
              class="mb-0"
            >
              <b-col lg="5" class="pl-0">
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-col>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          show-empty
          :small="true"
          :items="clientList"
          :fields="fields"
          :busy="busy"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          :sticky-header= true
          @filtered="onFiltered"
          >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>読み込んでいます...</strong>
            </div>
          </template>
          <template #cell(detail)="detail">
            <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'対象のデータで入力します。'" @click="choice(detail.item)" class="mr-1">
              <span class="oi oi-check"></span> 選択
            </b-button>
          </template>
          <template #cell(clientNameKanji)="data">
            <div style="max-width:200px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.clientNameKanji}}
                </span>
              </div>
            </div>
          </template>  
          <template #cell(clientNameKana)="data">
            <div style="max-width:200px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.clientNameKana}}
                </span>
              </div>
            </div>
          </template>  
          <template #cell(address_1)="data">
            <div style="max-width:200px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.address_1}}
                </span>
              </div>
            </div>
          </template>  
          <template #cell(address_2)="data">
            <div style="max-width:200px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.address_2}}
                </span>
              </div>
            </div>
          </template>  
        </b-table>
        <!-- テーブルページネーション -->
        <b-col class="my-0">
          <b-pagination
            size="sm"
            v-model="currentPage"
            :total-rows="filter != null ? filterRows : totalRows"
            :per-page="perPage == -1 ? totalRows : perPage"
            align="center"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-col>
    </b-card>
  </b-container>
  <template #modal-footer>
    <b-button size="sm" @click="$bvModal.hide('clientSearchModal')" class="mr-1">キャンセル</b-button>
  </template>
 </b-modal>
<!-- ●●●取引先検索モーダル●●● -->
</template>
<script>
import { init, convertSqlLikeSpecialChar } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import {API, graphqlOperation} from 'aws-amplify';
import {list_v_clients_01} from '@/graphql/queries';
export default {
  name: 'CLIENT-SEARCH',
  props:['clientClass'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先検索',
      // 検索条件
      searchConditions:{
        clientName: '',
        clientCode: '',
      },
      // 検索結果
      resultError: [],
      clientList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  computed: {
    fields(){
      return [
        {
          key: 'detail',
          label: ''
        },
        {
          key: 'clientId',
          label: 'コード',
          sortable: true
        },
        {
          key: 'clientNameKanji',
          label: '取引先名漢字',
          sortable: true
        },
        {
          key: 'clientNameKana',
          label: '取引先名カナ',
        },
        {
          key: 'address_1',
          label: '住所1',
          sortable: true
        },
        {
          key: 'address_2',
          label: '住所2',
          sortable: true
        },
      ]
    },
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods:{
    choice:function(choiceData){
      this.$emit('from-child', choiceData);
      this.$bvModal.hide('clientSearchModal');
    },
    /* 検索ボタン押下時 */
    async searchButton(key){
      if(key == 'init'){
        // 初期表示時
        this.searchConditions.clientName = '';
        this.searchConditions.clientCode = '';
        this.resultError = [];
        this.clientList = [];
        // ページング機能の初期化
        this.initPaging();
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
        this.busy = false;
      }else if(key == 'click'){
        // 検索ボタン押下時
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      }
    },
    /* 検索処理 */
    async search(){
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.resultError = [];
      this.clientList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      //console.log(this.clientClass);
      let where_clause = '';
      where_clause = await this.conditionMake();
      where_clause += 'AND client_class = ' + this.clientClass;
      where_clause += ' ORDER BY client_id';
      where_clause += ' LIMIT 1000';
      //console.log(where_clause);
      // 取引先検索取得
      try {
        //console.log('取引先検索取得');
        let condition = {where_clause: where_clause};
        let result = await API.graphql(graphqlOperation(list_v_clients_01,condition));
        let resultData = result.data.list_v_clients_01;
        if(resultData != null){
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
          this.busy = false;
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
          this.busy = false;
        }
      } catch (error) {
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.busy = false;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          clientId: result[i].client_id,
          clientNameKanji: result[i].client_name_kanji,
          clientNameKana: result[i].client_name_kana,
          address_1: result[i].address_1,
          address_2: result[i].address_2,
          Status: '',
          detail: result[i],
        };
        this.clientList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake(){
      let where_clause = '';
      const { clientName, clientCode } = this.searchConditions
      // 取引先名
      if(clientName != ''){
        where_clause += `AND (REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(clientName)}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(clientName)}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // コード
      if(clientCode != ''){
        where_clause += 'AND client_id = ' + clientCode + ' ';
      }
      return where_clause;
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
</style>