/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreate_m_9a_products_classes = /* GraphQL */ `
  subscription OnCreate_m_9a_products_classes {
    onCreate_m_9a_products_classes {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_billings = /* GraphQL */ `
  subscription OnCreate_m_billings {
    onCreate_m_billings {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_clients = /* GraphQL */ `
  subscription OnCreate_m_clients {
    onCreate_m_clients {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_clients_sites = /* GraphQL */ `
  subscription OnCreate_m_clients_sites {
    onCreate_m_clients_sites {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_deliverys_designs_classes = /* GraphQL */ `
  subscription OnCreate_m_deliverys_designs_classes {
    onCreate_m_deliverys_designs_classes {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_inquiries_definitions = /* GraphQL */ `
  subscription OnCreate_m_inquiries_definitions {
    onCreate_m_inquiries_definitions {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_offices = /* GraphQL */ `
  subscription OnCreate_m_offices {
    onCreate_m_offices {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_payments = /* GraphQL */ `
  subscription OnCreate_m_payments {
    onCreate_m_payments {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products = /* GraphQL */ `
  subscription OnCreate_m_products {
    onCreate_m_products {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_categories = /* GraphQL */ `
  subscription OnCreate_m_products_categories {
    onCreate_m_products_categories {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_categories_names = /* GraphQL */ `
  subscription OnCreate_m_products_categories_names {
    onCreate_m_products_categories_names {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_compositions = /* GraphQL */ `
  subscription OnCreate_m_products_compositions {
    onCreate_m_products_compositions {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_details = /* GraphQL */ `
  subscription OnCreate_m_products_details {
    onCreate_m_products_details {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_orders = /* GraphQL */ `
  subscription OnCreate_m_products_orders {
    onCreate_m_products_orders {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_prices_groups = /* GraphQL */ `
  subscription OnCreate_m_products_prices_groups {
    onCreate_m_products_prices_groups {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_products_units_conversions = /* GraphQL */ `
  subscription OnCreate_m_products_units_conversions {
    onCreate_m_products_units_conversions {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_routes = /* GraphQL */ `
  subscription OnCreate_m_routes {
    onCreate_m_routes {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_staffs = /* GraphQL */ `
  subscription OnCreate_m_staffs {
    onCreate_m_staffs {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_stocks = /* GraphQL */ `
  subscription OnCreate_m_stocks {
    onCreate_m_stocks {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_suppliers_sites = /* GraphQL */ `
  subscription OnCreate_m_suppliers_sites {
    onCreate_m_suppliers_sites {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_versions = /* GraphQL */ `
  subscription OnCreate_m_versions {
    onCreate_m_versions {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_m_calendar = /* GraphQL */ `
  subscription OnCreate_m_calendar {
    onCreate_m_calendar {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_billings_balances = /* GraphQL */ `
  subscription OnCreate_t_billings_balances {
    onCreate_t_billings_balances {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_billings_remainings = /* GraphQL */ `
  subscription OnCreate_t_billings_remainings {
    onCreate_t_billings_remainings {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_billings_unsettled = /* GraphQL */ `
  subscription OnCreate_t_billings_unsettled {
    onCreate_t_billings_unsettled {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_inventories = /* GraphQL */ `
  subscription OnCreate_t_inventories {
    onCreate_t_inventories {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_inventories_histories = /* GraphQL */ `
  subscription OnCreate_t_inventories_histories {
    onCreate_t_inventories_histories {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_orders = /* GraphQL */ `
  subscription OnCreate_t_orders {
    onCreate_t_orders {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_orders_received_set = /* GraphQL */ `
  subscription OnCreate_t_orders_received_set {
    onCreate_t_orders_received_set {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_orders_receives = /* GraphQL */ `
  subscription OnCreate_t_orders_receives {
    onCreate_t_orders_receives {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_payables_balances_results = /* GraphQL */ `
  subscription OnCreate_t_payables_balances_results {
    onCreate_t_payables_balances_results {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_payments_balances = /* GraphQL */ `
  subscription OnCreate_t_payments_balances {
    onCreate_t_payments_balances {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_products_prices_histories = /* GraphQL */ `
  subscription OnCreate_t_products_prices_histories {
    onCreate_t_products_prices_histories {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_products_deleted_log = /* GraphQL */ `
  subscription OnCreate_t_products_deleted_log {
    onCreate_t_products_deleted_log {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_products_results = /* GraphQL */ `
  subscription OnCreate_t_products_results {
    onCreate_t_products_results {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_receivables_balances_results = /* GraphQL */ `
  subscription OnCreate_t_receivables_balances_results {
    onCreate_t_receivables_balances_results {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_ships = /* GraphQL */ `
  subscription OnCreate_t_ships {
    onCreate_t_ships {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_staffs_results = /* GraphQL */ `
  subscription OnCreate_t_staffs_results {
    onCreate_t_staffs_results {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_t_stocks = /* GraphQL */ `
  subscription OnCreate_t_stocks {
    onCreate_t_stocks {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onCreate_w_clients_sales_file = /* GraphQL */ `
  subscription OnCreate_w_clients_sales_file {
    onCreate_w_clients_sales_file {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const onUpdateQueryStatus = /* GraphQL */ `
  subscription OnUpdateQueryStatus($ProcessID: ID) {
    onUpdateQueryStatus(ProcessID: $ProcessID) {
      ProcessID
      StatusClass
      TypeClass
      S3Keys
      Error
    }
  }
`;
export const onUpdateTransactSqlStatus = /* GraphQL */ `
  subscription OnUpdateTransactSqlStatus($ProcessID: ID) {
    onUpdateTransactSqlStatus(ProcessID: $ProcessID) {
      ProcessID
      StatusClass
      TypeClass
      S3Key
      Error
      createdAt
      updatedAt
    }
  }
`;
