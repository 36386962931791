<template>
  <!-- 支払残高保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <span class="oi oi-magnifying-glass"></span> 検索条件
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 支払年月 入力欄 -->
                        <div class="col-md-5 form-group">
                          <validation-provider name="monthYear" :rules="{regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                            <b-form-group label="支払年月">
                              <b-row>
                                <b-col lg="8" :class="classes">
                                  <b-input-group>
                                    <b-form-input name="monthYear" type="month" v-model="searchConditions.monthYear" />
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.monthYear=null">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="8" :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <!-- 仕入先コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="clientId" class="form-label">仕入先コード</label>
                          <validation-provider name="clientId" rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientId" name="clientId" class="form-control" v-model="searchConditions.clientId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 仕入先名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="clientName" class="form-label">仕入先名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientName" name="clientName" class="form-control" v-model.trim="searchConditions.clientName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの仕入先名（漢字）または仕入先名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="paymentsBalancesList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- printイベント用のレイアウト -->
                  <template v-if="printStatus" #cell(client_name_kanji)="data">
                    <div style="max-width:800px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.client_name_kanji }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="printStatus" #cell(client_name_kana)="data">
                    <div style="max-width:800px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.client_name_kana }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <b-button size="sm" @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <b-button size="sm" @click="clickEditBtn(operation.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>&nbsp;
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : paymentsBalancesList.length"
                :per-page="perPage == -1 ? paymentsBalancesList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, convertSqlLikeSpecialChar, init, windowPrint } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'payments-balances-maintenance';

export default {
  name: 'PAYMENTS-BALANCES-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '支払残高保守',
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        clientId: '',
        clientName: '',
        monthYear: null,
      },
      // 検索結果
      resultError: [],
      //  支払残高リスト
      paymentsBalancesList: [],
      fields: DataTblDef.payments_balances_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.paymentsBalancesList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {Array<String>} searchConditions - 検索条件
     */
    async search(searchConditions) {
      this.paymentsBalancesList = [];
      let listPaymentsBalances = null;
      if (searchConditions !== null && searchConditions.length > 0) {
        listPaymentsBalances = await this.searchPaymentsBalances(searchConditions)
        if (listPaymentsBalances == null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } 
        listPaymentsBalances.forEach(item => {
          this.paymentsBalancesList.push(
            {
              client_id: item.client_id,
              client_name_kanji: item.client_name_kanji,
              client_name_kana: item.client_name_kana,
              month_year: item.payment_month_year,
            }
          )
        });
      }
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.paymentsBalancesList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /**
     * 支払残高マスタ検索
     * @param {Array<String>} where_clause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchPaymentsBalances(where_clause) {
      const functionName = 'searchPaymentsBalances';

      let result = null;
      // 取引先区分が  2:仕入れ先
      where_clause.push('MC.client_class = 2')
      // 支払残高と取引先マスタの結合
      let SQLs = `SELECT 
                  MC.client_name_kanji,
                  MC.client_name_kana,
                  TPB.payment_month_year,
                  TPB.client_id
                  FROM t_payments_balances TPB LEFT JOIN m_clients MC USING(client_id) 
                  WHERE ${where_clause.join(' AND ')} ORDER BY TPB.updated DESC LIMIT 1000`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0]
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 仕入先コード
        let searchConditions = [];
        if (this.searchConditions.clientId !== '') {
          searchConditions.push(`client_id = ${this.searchConditions.clientId} `);
        }
        // 仕入先名(あいまい検索対象)
        if (this.searchConditions.clientName !== '') {
          const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
          let nameConditions = `(REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `
          nameConditions += `OR REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', ''))`
          searchConditions.push(nameConditions);
        }
        // 支払年月
        if (this.searchConditions.monthYear != null && this.searchConditions.monthYear != '') {
          const monthYear = this.searchConditions.monthYear.replace(/-/, '');
          searchConditions.push(`payment_month_year = ${monthYear} `);
        }
        // 検索条件がない場合、初期画面を出す
        if (searchConditions.length === 0) {
          searchConditions.push('1 = 1')
        }
        await this.search(searchConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'PAYMENTS-BALANCES-INQUIRY', query: 
      { 
        payment_month_year: item.month_year, 
        client_id: item.client_id,
      }});
      window.open(route.href, '_blank');
    },
    /**
     * 編集ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickEditBtn(item) {
      let route = this.$router.resolve({ name: 'PAYMENTS-BALANCES-EDIT', query: 
      { 
        payment_month_year: item.month_year, 
        client_id: item.client_id,
      }});
      window.open(route.href, '_blank');
    },

  }
};
</script>
<style scoped>
  @media print {
    .mediaNone {
      display: none;
    }
  }
</style>