<template>
  <!-- 製品コード切替保守画面 -->
  <div>
    <Header :type="menu_type" :title="title"/>
    <!-- 致命的なエラーが発生した場合 -->
    <b-container v-if="criticalMessages.length > 0" fluid class="p-4 min-vh-85">
      <b-alert show variant="danger">
        <ul v-for="(message, index) in criticalMessages" :key="index" style="list-style: none;">
          <li>{{ message }}</li>
        </ul>
      </b-alert>
    </b-container>
    <!-- 致命的なエラーが発生していない場合 -->
    <b-container v-if="criticalMessages.length === 0" fluid class="px-4 pt-4 min-vh-85">
      <b-row class="d-flex justify-content-center my-2">
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{title}}</strong></h5>
                <router-link to="/product-code-switch-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </b-media-body>
          </b-media>
          <!-- 製品コード切替が実行中ではない場合 -->
          <b-card v-if="!isRunning" class="main-card mb-3" no-body>
            <b-card-header>
              <b-alert v-if="errorMessages.length > 0" class="mb-0" show variant="danger">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p class="pb-3">新製品コードを入力して[切替]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="製品名"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="productName"
                      >
                        <b-form-input id="productName" readonly v-model="productName" v-b-tooltip.hover.noninteractive :title="productName"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="旧製品コード"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="productId"
                      >
                        <b-container fluid>
                          <b-row>
                            <b-col class="px-0">
                              <b-form-input id="productId" readonly v-model="productClassId"></b-form-input>
                            </b-col>
                            <b-col sm="1" class="d-flex justify-content-center pt-2">—</b-col>
                            <b-col class="px-0">
                              <b-form-input id="productId" readonly v-model="productIdLow"></b-form-input>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="新製品コード"
                        label-cols-sm="4"
                        label-cols-md="3"
                        label-cols-lg="2"
                        label-for="newProductId"
                        description="使用されていない製品コードを指定してください。"
                      >
                        <b-container fluid>
                          <b-row>
                            <b-col class="px-0">
                              <validation-provider rules="required|numeric|digits:4|between:0,9999" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-form-input id="newProductId" maxlength="4" v-model="newProductClassId"></b-form-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                            <b-col sm="1" class="d-flex justify-content-center pt-2">—</b-col>
                            <b-col class="px-0">
                              <validation-provider rules="required|numeric|digits:4|between:0,9999" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-form-input id="newProductId" maxlength="4" v-model="newProductIdLow"></b-form-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-center mt-2">
                    <b-col sm="5" md="4" lg="3">
                      <b-button block :disabled="isUpdateButtonDisabled" pill variant="primary" @click="onUpdateButtonClick"><span class="oi oi-circle-check"></span> 切替</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- 取引先コード切替が実行中の場合 -->
          <b-card v-if="isRunning" class="main-card mb-3" no-body>
            <b-card-body class="text-center">
              <b-row>
                <b-col>
                  <h5 class="my-3">製品コード切替を実行中です。しばらくお待ちください。</h5>
                  <div class="my-3"><b>本画面は自動的に更新されません。</b></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-3 text-center">
                  <b-button pill variant="success" @click="checkRunningState">状況を確認する</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, init, isSystemEditable, executeSelectSql } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_control, list_m_products, getM_products, getM_9a_products_classes } from '@/graphql/queries';
import { execute_product_code_switch } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const';

// モジュール名
const MODULE_NAME = 'product-code-switch-edit';

export default {
  name: 'PRODUCT-CODE-SWITCH-EDIT',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      menu_type: 'user',
      title: '製品コード切替',
      productCodeSwitchState: 0,
      product: null,
      newProductClassId: '',
      newProductIdLow: '',
      isStartMsgBoxConfirmShow: false,
      errorMessages: [],
      criticalMessages: []
    };
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0, 0);
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /**
     * 製品名を返します。
     */
    productName() {
      return (this.product === null) ? '' : (this.product.product_name_kanji.trim() === '') ? this.product.product_name_kana : this.product.product_name_kanji;
    },
    /**
     * 現在の製品分類コードを返します。
     */
    productClassId() {
      return (this.product === null) ? '' : String(this.product.product_class_id);
    },
    /**
     * 現在の製品コード（下4桁）を返します。
     */
    productIdLow() {
      return (this.product === null) ? '' : String(this.product.product_id).substring(4);
    },
    /**
     * 更新ボタンの有効・無効を返します。
     */
    isUpdateButtonDisabled() {
      return this.isStartMsgBoxConfirmShow;
    },
    /**
     * 取引先コード切替が実行中かどうかを返します。
     */
    isRunning() {
      return this.productCodeSwitchState === Const.ProductCodeSwitchStateClass.RUNNING;
    }
  },
  methods: {
    /**
     * パラメータに指定されたIDに対応する製品情報を取得して画面に表示します。
     */
    async fetchData() {
      const functionName = 'fetchData';

      // 得意先コード切替が実行中かどうかを確認します。
      let listMControlResult = null;
      try {
        listMControlResult = await API.graphql(graphqlOperation(list_m_control));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: listMControlResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.data.list_m_control.length === 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'コントロールマスタが空です。');
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      this.productCodeSwitchState = listMControlResult.data.list_m_control[0].switch_product_id_state;
      if (this.isRunning) {
        return;
      }

      // 切り替えを行う対象の製品情報を取得します。
      let getMProductsResult = null;
      try {
        getMProductsResult = await API.graphql(graphqlOperation(getM_products, { id: this.$route.query.id }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_products',
          id: this.$route.query.id
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (getMProductsResult.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_products',
          id: this.$route.query.id,
          result: getMProductsResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      this.product = getMProductsResult.data.getM_products;
    },
    /**
     * 切替ボタンクリックイベント処理
     */
    async onUpdateButtonClick() {
      const functionName = 'onUpdateButtonClick';

      this.errorMessages = [];
      this.isStartMsgBoxConfirmShow = true;

      // 入力エラーがあった場合はエラーの項目へスクロール
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      // 整合性をチェックします。
      if (!await this.validate()) {
        scrollTo(0,0);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      if (!await this.$bvModal.msgBoxConfirm('製品コード切替を行います。よろしいですか？', {
        title: '製品コード切替の開始',
        okTitle: 'はい',
        cancelTitle: 'いいえ'
      })) {
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      const newProductClassId = Number(this.newProductClassId);
      const newIs9A = (Const.ProductClassIdRange9A.MIN <= newProductClassId && newProductClassId <= Const.ProductClassIdRange9A.MAX) ? 1 : 0;

      // 製品コード切替開始
      const executeProductCodeSwitchInput = {
        id: this.product.id,
        product_class_id: this.product.product_class_id,
        product_id: this.product.product_id,
        is_9A: this.product.is_9A,
        new_product_class_id: this.newProductClassId,
        new_product_id: this.newProductClassId + this.newProductIdLow,
        new_is_9A: newIs9A
      };
      await addOperationLogs('Info', MODULE_NAME, functionName, `製品コード切替API呼び出しを開始します。ID：${executeProductCodeSwitchInput.id}、製品分類コード：${executeProductCodeSwitchInput.product_class_id}、製品コード：${executeProductCodeSwitchInput.product_id}、新製品分類コード：${executeProductCodeSwitchInput.new_product_class_id}、新製品コード：${executeProductCodeSwitchInput.new_product_id}、新9Aフラグ：${executeProductCodeSwitchInput.new_is_9A}`);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          await addOperationLogs('Info', MODULE_NAME, functionName, `月次更新・取引先コード切替・製品コード切替のいずれかが実行中のため、取引先コード切替API呼び出しを中止しました。ID：${executeProductCodeSwitchInput.id}、製品分類コード：${executeProductCodeSwitchInput.product_class_id}、製品コード：${executeProductCodeSwitchInput.product_id}、新製品分類コード：${executeProductCodeSwitchInput.new_product_class_id}、新製品コード：${executeProductCodeSwitchInput.new_product_id}、新9Aフラグ：${executeProductCodeSwitchInput.new_is_9A}`);
          this.errorMessages.push(msg);
          this.isStartMsgBoxConfirmShow = false;
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(execute_product_code_switch, {
          input: executeProductCodeSwitchInput
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_product_code_switch',
          input: executeProductCodeSwitchInput
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }
      if (result.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_product_code_switch',
          input: executeProductCodeSwitchInput,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      await addOperationLogs('Info', MODULE_NAME, functionName, `製品コード切替API呼び出しが成功しました。実行ID：${result.data.execute_product_code_switch.execution_id}`);
      await this.$bvModal.msgBoxOk('製品コード切替を開始しました。', {
        title: '製品コード切替の開始',
        okTitle: 'はい'
      });
      this.isStartMsgBoxConfirmShow = false;
      this.productCodeSwitchState = Const.ProductCodeSwitchStateClass.RUNNING
    },
    /**
     * 入力チェックを行います。
     */
    async validate() {
      const functionName = 'validate';

      // 旧製品コードと新製品コードが異なる値になっているかどうかチェックします。
      if (this.product.product_id === (this.newProductClassId + this.newProductIdLow)) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2040'].replace('%arg1%', '新製品コード').replace('%arg2%', '製品コード'));
        return false;
      }

      // 新しい製品コードが既に存在していないかチェックします。
      const listMProductsWhereClause = `AND product_id = ${this.newProductClassId + this.newProductIdLow}`;
      let listMProductsResult = null;
      try {
        listMProductsResult = await API.graphql(graphqlOperation(list_m_products, { where_clause: listMProductsWhereClause }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products',
          where_clause: listMProductsWhereClause
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMProductsResult.erros) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products',
          where_clause: listMProductsWhereClause,
          result: listMProductsResult
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      console.log(listMProductsResult);
      if (listMProductsResult.data.list_m_products.length > 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2039'].replace('%arg1%', '新製品コード').replace('%arg2%', '製品コード'));
        return false;
      }

      // 製品分類コードが9A製品の範囲の場合、9Aコード管理マスタに存在するかどうかチェックします。
      const newProductClassId = Number(this.newProductClassId);
      if (Const.ProductClassIdRange9A.MIN <= newProductClassId && newProductClassId <= Const.ProductClassIdRange9A.MAX) {
        let getM9AProductsClassesResult = null;
        try {
          getM9AProductsClassesResult = await API.graphql(graphqlOperation(getM_9a_products_classes, { product_class_id: this.newProductClassId }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'getM_9a_products_classes',
            id: this.$route.query.id
          }, error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
        if (getM9AProductsClassesResult.erros) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'getM_9a_products_classes',
            id: this.$route.query.id,
            result: getM9AProductsClassesResult
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
        const m9AProductsClasses = getM9AProductsClassesResult.data.getM_9a_products_classes;
        // 9Aコード管理マスタに登録されていない製品分類コードの場合
        if (m9AProductsClasses === null) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2038']);
          return false;
        }
        const newProductIdLow = Number(this.newProductIdLow);
        // 枝番が有効範囲外の場合
        if (newProductIdLow < m9AProductsClasses.sequence || m9AProductsClasses.max < newProductIdLow) {
          this.errorMessages.push(DISP_MESSAGES.WARNING['2041'].replace('%arg1%', String(m9AProductsClasses.sequence).padStart(4, '0')).replace('%arg2%', String(m9AProductsClasses.max).padStart(4, '0')));
          return false;
        }
      }
      // 製品実績を確認
      // SELECT句
      let selectSql = 'SELECT';
      selectSql += ' (SELECT COUNT(*) FROM t_products_results WHERE product_id = ' + this.newProductClassId.toString() + this.newProductIdLow.toString() + ' LIMIT 1) AS products_results_flg';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'DUAL ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData[0].products_results_flg > 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2039'].replace('%arg1%', '新製品コード').replace('%arg2%', '製品コード'));
        return false;
      }

      return true;
    },
    /**
     * 製品コード切替が実行中かどうかを確認します。
     * 終了している場合は製品コード切替保守画面へ遷移します。
     */
    async checkRunningState() {
      const functionName = 'checkRunningState';

      // 製品コード切替が実行中かどうかを確認します。
      let listMControlResult = null;
      try {
        listMControlResult = await API.graphql(graphqlOperation(list_m_control));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: listMControlResult
        });
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (listMControlResult.data.list_m_control.length === 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'コントロールマスタが空です。');
        this.criticalMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      const m_control = listMControlResult.data.list_m_control[0];
      let message = '';
      switch (m_control.switch_product_id_state) {
      case Const.ProductCodeSwitchStateClass.NOT_RUNNING:
        message = '正常に終了しました。';
        break;
      case Const.ProductCodeSwitchStateClass.RUNNING:
        message = '実行中です。';
        break;
      case Const.ProductCodeSwitchStateClass.ERROR_STOP:
        message = 'エラー終了しました。';
        break;
      }
      await this.$bvModal.msgBoxOk(message, {
        title: '製品コード切替の状況確認',
        okTitle: 'はい'
      });
      if (m_control.switch_product_id_state !== Const.ProductCodeSwitchStateClass.RUNNING) {
        this.$router.push({ name: 'PRODUCT-CODE-SWITCH-MAINTENANCE' });
      }
    }
  }
}
</script>
