<template>
 <b-modal
  id="siteInformationInquiryModal"
  size="xl"
  centered no-close-on-esc no-close-on-backdrop hide-header-close scrollable
  @show="initData"
  title="現場情報照会"
 >
  <b-container>
    <validation-observer ref="observer">
      <b-row>
        <b-col>
          <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
            <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
          <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
            <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
          <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
            <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
          <b-table-simple
            small
          >
            <b-tbody>
              <b-tr>
                <b-th>取引先</b-th>
                <b-td>{{searchResult.clientId + ' ' + searchResult.clientNameKanji}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>現場</b-th>
                <b-td>{{searchResult.siteId + ' ' + searchResult.clientSiteNameKanji}}</b-td>
              </b-tr>
              <b-tr>
                <b-th colspan="2">現場情報</b-th>
              </b-tr>
              <b-tr>
                <b-td colspan="2">
                  <validation-provider name="orderRecieveNote" rules="max_row:26,25" v-slot="{ classes,errors }">
                    <b-row>
                      <b-col lg="12" :class="classes">
                        <b-form-textarea id="orderRecieveNote" rows="10" no-resize  :readonly="editFlg == false" v-model="searchResult.orderRecieveNote" />
                      </b-col>
                      <b-col lg="12" :class="classes">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </b-col>
                    </b-row>
                  </validation-provider>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </validation-observer>
  </b-container>
  <template #modal-footer>
    <b-button size="sm" v-if="editFlg==false" @click="clearAlert(); editFlg=true" class="mr-1">編集</b-button>
    <b-button size="sm" v-if="editFlg==true" @click="clearAlert(); saveData();" class="mr-1">保存</b-button>
    <b-button size="sm" v-if="editFlg==true" @click="clearAlert(); searchResult.orderRecieveNote = searchResult.preOrderRecieveNote; editFlg=false" class="mr-1">キャンセル</b-button>
    <b-button size="sm" v-if="editFlg==false" @click="clearAlert(); clickClose();" class="mr-1">閉じる</b-button>
  </template>
 </b-modal>
<!-- ●●●現場情報照会モーダル●●● -->
</template>
<script>
import store from '@/store';
import Const from '@/assets/js/const.js';
import { init, getNullStr, executeSelectSql, addOperationLogs, CreateColRow, CreateUpdateSql, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'site-information-inquiry';

export default {
  name: 'SITE-INFORMATION-INQUIRY',
  props: ['siteProp'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場情報照会',
      loginId: '',
      // 検索結果
      searchResult: {
        clientId: null,
        clientNameKanji: '',
        siteId: null,
        clientSiteNameKanji: '',
        orderRecieveNote: '',
        preOrderRecieveNote: '',
      },
      editFlg: false,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // トランザクションSQLリスト
      transactSqlList: [],
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods: {
    /* 検索処理 */
    async initData() {
      const functionName = 'initData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        console.log('ログイン情報');
        console.log(this.loginId);
        // 初期化
        this.editFlg = false;
        this.searchResult.clientId = null;
        this.searchResult.clientNameKanji = '';
        this.searchResult.orderRecieveNote = '';
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        if(resultData != null && resultData.length > 0){
          console.log(resultData);
          this.setResult(resultData[0]);
        }else{
          this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      this.$store.commit('setLoading', false);
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' clients.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients_sites.site_id';
      selectSql += ',clients_sites.client_site_name_kanji';
      selectSql += ',clients_sites.order_receive_note';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_clients AS clients ';
      selectSql += 'INNER JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON clients.client_id = clients_sites.client_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND clients.client_id = ' + this.siteProp.clientId + ' ';
      selectSql += 'AND clients_sites.site_id = ' + this.siteProp.siteId + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    setResult: function(result) {
      // 検索結果反映
      this.searchResult.clientId = result.client_id;
      this.searchResult.clientNameKanji = result.client_name_kanji;
      this.searchResult.siteId = result.site_id;
      this.searchResult.clientSiteNameKanji = result.client_site_name_kanji;
      this.searchResult.orderRecieveNote = result.order_receive_note;
      this.searchResult.preOrderRecieveNote = result.order_receive_note;
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        this.$bvModal.msgBoxConfirm('入力された情報で保存します。\nよろしいですか？').then(async value => {
          if (value) {
            await this.closeConfirmModal();
          }
        });
      }
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal() {
      const functionName = 'closeConfirmModal';
      this.$store.commit('setLoading', true);
      try {
        await this.confirmSave();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      console.log('保存処理開始');
      if (await this.execSave() == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.initData();
      }
      console.log('保存処理終了');
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 現場マスタの更新
      this.updateClientsSites();

      console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return retResult;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      } else {
        retResult = true;
      }

      return retResult;
    },
    /* 現場マスタ更新SQL作成 */
    async updateClientsSites() {
      console.log('現場マスタ更新SQL作成');
      let colList = [];
      // 受注メモ
      colList.push(CreateColRow('order_receive_note', this.searchResult.orderRecieveNote, 'VARCHAR'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      /* WHERE句 */
      let where_clause = ' WHERE ';
      where_clause += ' client_id = ' + this.siteProp.clientId + ' ';
      where_clause += 'AND site_id = ' + this.siteProp.siteId + ' ';
      // 更新SQL作成
      this.transactSqlList.push(CreateUpdateSql(colList, 'm_clients_sites') + where_clause);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 閉じるボタン押下
    clickClose: function() {
      let isNoteInput = getNullStr(this.searchResult.orderRecieveNote) == '' ? false : true;
      this.$emit('from-child', {isNoteInput: isNoteInput});
      this.$bvModal.hide('siteInformationInquiryModal');
    },
  },
}
</script>
<style scoped>
</style>