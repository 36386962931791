<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出力範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clickOutputPdf();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 年月 -->
                    <b-col cols="6">
                      <validation-provider name="billingMonthYear" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="年月"
                          label-for="billingMonthYear"
                        >
                          <b-row>
                            <b-col lg="8" :class="classes">
                              <b-form-input id="billingMonthYear" name="billingMonthYear" type="month" v-model="searchConditions.billingMonthYear" />
                            </b-col>
                            <b-col lg="8" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 得意先コード -->
                    <b-col lg="12">
                      <b-form-group
                        label="得意先コード"
                        label-for="clientIdStart"
                      >
                        <b-input-group>
                          <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-form-input type="text" id="clientIdStart" name="clientIdStart" v-model.number="searchConditions.clientIdStart" />
                                  <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                          <b-input-group-text>～</b-input-group-text>
                          <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-input type="text" name="clientIdEnd" v-model.number="searchConditions.clientIdEnd" />
                                  <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                        </b-input-group>
                        <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- PDF出力ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center my-2">
              <b-col lg="4">
                <b-button class="mr-2" pill variant="success" v-b-tooltip.hover title="指定された範囲の得意先元帳のPDF出力を行います。" type="submit" form="InputForm">
                  <span class="oi oi-document"></span> 得意先元帳PDF出力
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import Const from '@/assets/js/const.js';
import { init } from '@/assets/js/common.js';

export default {
  name: 'INVOICE-SEARCH',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  /** データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '得意先元帳発行',
      // 検索項目
      searchConditions: {
        // 請求年月
        billingMonthYear: '',
        // 取引先コード（開始）
        clientIdStart: '',
        // 取引先コード（終了）
        clientIdEnd: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
      let user = store.getters.user;
      this.loginId = user.username;
      console.log('ログイン情報');
      console.log(this.loginId);
    },
    /* 得意先元帳PDF出力ボタンの押下 */
    async clickOutputPdf() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        // PDF出力処理
        await this.clickOutputBillingListPdf();
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    /* 取引先検索 */
    showClientSearchModal: function(index) {
      console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /* 取引先検索モーダルを閉じた時 */
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 得意先元帳PDF出力ボタンの押下 */
    async clickOutputBillingListPdf() {
      // 得意先元帳PDF出力処理
      console.log('得意先元帳PDF出力処理');
      let query = {
        billingMonthYear: this.searchConditions.billingMonthYear,
        clientIdStart: this.searchConditions.clientIdStart == '' ? '000000' : this.searchConditions.clientIdStart,
        clientIdEnd: this.searchConditions.clientIdEnd == '' ? '999999' : this.searchConditions.clientIdEnd,
      };
      let route = this.$router.resolve({ name: 'CUSTOMER-LEDGER', query: query });
      window.open(route.href, '_blank');
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>