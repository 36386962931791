<template>
  <!-- 売掛実績・残高保守 -->
  <div>
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{title}}</strong></h5>
                <router-link to="/accounts-receivable-supply-list" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p class="mb-4">編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <!-- 処理年月 -->
              <b-form-group label="処理年月" label-cols-md="2" label-class="font-weight-bold">
                <b-form-input type="month" v-model="monthYear" style="width: 180px;" readonly></b-form-input>
              </b-form-group>
              <!-- 得意先コード -->
              <b-form-group label="得意先コード" label-cols-md="2" label-class="font-weight-bold">
                <b-form-input v-model="clientName" style="max-width: 500px;" readonly></b-form-input>
              </b-form-group>
              <!-- 担当者コード -->
              <b-form-group label="担当者コード" label-cols-md="2" label-class="font-weight-bold">
                <b-form-input v-model="staffName" style="max-width: 500px;" readonly></b-form-input>
              </b-form-group>
              <!-- 得意先分類コード -->
              <b-form-group label="得意先分類コード" label-cols-md="2" label-class="font-weight-bold" class="mb-5">
                <b-form-input v-model="clientIdFirstDigit" style="max-width: 50px;" readonly class="text-center"></b-form-input>
              </b-form-group>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <!-- 前月末売掛税抜残高 -->
                  <b-form-group label="前月末売掛税抜残高" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="lastMonthReceivableNoTaxBalanceResult" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 前月末売掛消費税残高 -->
                  <b-form-group label="前月末売掛消費税残高" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="lastMonthReceivableTaxBalanceResult" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次売上額 -->
                  <b-form-group label="月次売上額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlySales" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次現金入金額 -->
                  <b-form-group label="月次現金入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次小切手入金額 -->
                  <b-form-group label="月次小切手入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyCheckDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次振込入金額 -->
                  <b-form-group label="月次振込入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTransferDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次手形入金額 -->
                  <b-form-group label="月次手形入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBillDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次相殺入金額 -->
                  <b-form-group label="月次相殺入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyOffsetDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次その他入金額 -->
                  <b-form-group label="月次その他入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyOtherDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次消費税入金額 -->
                  <b-form-group label="月次消費税入金額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTaxDeposit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次一括消費税額 -->
                  <b-form-group label="月次一括消費税額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBulkTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次伝票消費税額 -->
                  <b-form-group label="月次伝票消費税額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBillingTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次消費税額 -->
                  <b-form-group label="月次消費税額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次粗利額 -->
                  <b-form-group label="月次粗利額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyGrossProfit" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 今回請求額 -->
                  <b-form-group label="今回請求額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="billingAmount" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <b-button variant="success" class="rounded-pill btn-block" @click="onSaveButtonClick"><span class="oi oi-circle-check"></span> 保存</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <StaffSearch @from-child="closeStaffSearchModal"/>
  </div>    
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import StaffSearch from '@/components/modal/staff-search.vue'
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateUpdateSql, isSystemEditable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'receivable-balance-result-maintenance';

export default {
  name: 'RECIVABLE-BALANCE-RESULT-MAINTENANCE',
  components: {
    Header,
    Footer,
    StaffSearch
  },
  data() {
    return {
      menu_type: 'user',
      title: '売掛実績・残高保守',
      monthYear: '', // 処理年月
      clientId: '', // 得意先コード
      clientName: '', // 得意先名
      staffId: '', // 担当者コード
      staffName: '', // 担当者名
      clientIdFirstDigit: 0, // 得意先分類コード
      lastMonthReceivableNoTaxBalanceResult: 0, // 前月末売掛税抜残高
      lastMonthReceivableTaxBalanceResult: 0, // 前月末売掛消費税残高
      monthlySales: 0, // 月次売上額
      monthlyDeposit: 0, // 月次現金入金額
      monthlyCheckDeposit: 0, // 月次小切手入金額
      monthlyTransferDeposit: 0, // 月次振込入金額
      monthlyBillDeposit: 0, // 月次手形入金額
      monthlyOffsetDeposit: 0, // 月次相殺入金額
      monthlyOtherDeposit: 0, // 月次その他入金額
      monthlyTaxDeposit: 0, // 月次消費税入金額
      monthlyBulkTax: 0, // 月次一括消費税額
      monthlyBillingTax: 0, // 月次伝票消費税額
      monthlyTax: 0, // 月次消費税額
      monthlyGrossProfit: 0, // 月次粗利額
      billingAmount: 0, // 今回請求額
      errorMessages:[]
    };
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0, 0);
    if (!await this.fetchData(this.$route.query.monthYear, this.$route.query.clientId)) {
      this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * 指定された処理年月と得意先コードに対応する売掛実績・残高データを取得して
     * 画面に反映します。
     * @param {Number|String} monthYear - 処理年月
     * @param {Number|String} clientId - 得意先コード
     * @returns {Boolean} 画面への反映に成功した場合はtrue、そうでない場合はfalse
     */
    async fetchData(monthYear, clientId) {
      const functionName = 'fetchData';

      const sqls = [];
      sqls.push(this.createFetchDataSQL(monthYear, clientId));

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        return false;
      }

      // 売掛実績・残高マスタのデータが存在していた場合はデータを設定
      if (body.data[0].length > 0) {
        const data = body.data[0][0];
        const monthYear = String(data.month_year);
        this.monthYear = `${monthYear.substring(0,4)}-${monthYear.substring(4)}`; // 処理年月
        this.clientId = data.client_id; // 得意先コード
        this.clientName = `${data.client_id}：${data.client_name_kanji}`; // 得意先名
        this.staffId = data.staff_id; // 担当者コード
        this.staffName = this.createStaffName(data.staff_id, data.staff_name_kanji); // 担当者名
        this.clientIdFirstDigit = data.client_id_first_digit; // 得意先分類コード
        this.lastMonthReceivableNoTaxBalanceResult = data.last_month_receivable_no_tax_balance_result; // 前月末売掛税抜残高
        this.lastMonthReceivableTaxBalanceResult = data.last_month_receivable_tax_balance_result; // 前月末売掛消費税残高
        this.monthlySales = data.monthly_sales; // 月次売上額
        this.monthlyDeposit = data.monthly_deposit; // 月次現金入金額
        this.monthlyCheckDeposit = data.monthly_check_deposit; // 月次小切手入金額
        this.monthlyTransferDeposit = data.monthly_transfer_deposit; // 月次振込入金額
        this.monthlyBillDeposit = data.monthly_bill_deposit; // 月次手形入金額
        this.monthlyOffsetDeposit = data.monthly_offset_deposit; // 月次相殺入金額
        this.monthlyOtherDeposit = data.monthly_other_deposit; // 月次その他入金額
        this.monthlyTaxDeposit = data.monthly_tax_deposit; // 月次消費税入金額
        this.monthlyBulkTax = data.monthly_bulk_tax; // 月次一括消費税額
        this.monthlyBillingTax = data.monthly_billing_tax; // 月次伝票消費税額
        this.monthlyTax = data.monthly_tax; // 月次消費税額
        this.monthlyGrossProfit = data.monthly_gross_profit; // 月次粗利額
        this.billingAmount = data.billing_amount; // 今回請求額
      }
      return true;
    },
    /**
     * 指定された処理年月と取引先コードに対応する売掛実績・残高データを取得する
     * SQL文を作成します。
     * @param {Number|String} monthYear - 処理年月
     * @param {Number|String} clientId - 得意先コード
     * @returns {String} SQL文
     */
    createFetchDataSQL(monthYear, clientId) {
      return 'SELECT' +
        ' trbr.month_year' +
        ',trbr.client_id' +
        ',trbr.staff_id' +
        ',trbr.client_id_first_digit' +
        ',trbr.last_month_receivable_no_tax_balance_result' +
        ',trbr.last_month_receivable_tax_balance_result' +
        ',trbr.monthly_sales' +
        ',trbr.monthly_deposit' +
        ',trbr.monthly_check_deposit' +
        ',trbr.monthly_transfer_deposit' +
        ',trbr.monthly_bill_deposit' +
        ',trbr.monthly_offset_deposit' +
        ',trbr.monthly_other_deposit' +
        ',trbr.monthly_tax_deposit' +
        ',trbr.monthly_bulk_tax' +
        ',trbr.monthly_billing_tax' +
        ',trbr.monthly_tax' +
        ',trbr.monthly_gross_profit' +
        ',trbr.billing_amount' +
        ',mc.client_name_kanji' +
        ',ms.staff_name_kanji ' +
        'FROM' +
        ' t_receivables_balances_results AS trbr' +
        ' JOIN m_clients AS mc ON mc.client_class = 1 AND mc.client_id = trbr.client_id' +
        ' JOIN m_staffs AS ms ON ms.staff_id = trbr.staff_id ' +
        'WHERE' +
        ` trbr.month_year = ${monthYear}` +
        ` AND trbr.client_id = ${clientId} ` +
        'ORDER BY trbr.client_id';
    },
    /**
     * 担当者検索モーダルを表示します。 
     */
    showStaffSearchModal() {
      this.$bvModal.show('staffSearchModal');
    },
    /**
     * 担当者検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeStaffSearchModal(choiceData) {
      this.staffId = choiceData.detail.staff_id;
      this.staffName = this.createStaffName(choiceData.detail.staff_id, choiceData.detail.staff_name_kanji);
    },
    /**
     * 担当者コード欄に表示する文字列を作成します。
     * @param {String|Number} staffId - 担当者コード
     * @param {String} staffName - 担当者名
     * @returns {String} 担当者コード欄に表示する文字列
     */
    createStaffName(staffId, staffName) {
      const id = (typeof staffId === 'string') ? Number(staffId) : staffId;
      const name = (id === 0) ? '該当なし' : staffName;
      return `${String(id).padStart(4, '0')}：${name}`;
    },
    /**
     * 保存ボタンクリックイベント処理
     */
    async onSaveButtonClick() {
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('入力された売掛実績・残高データを保存します。よろしいですか？', {
          title: '売掛実績・残高の保存',
        })) {
          this.$store.commit('setLoading', true);
          if (await this.execUpdate()) {
            // UPDATEに成功した場合は得意先別売掛実績表画面へ遷移
            this.$router.push({ name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST', query: {successMessageId: '1001'} });
          } else {
            scrollTo(0, 0);
          }
        }
      } else {
        // 入力エラーがあった場合はエラーの項目へスクロール
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 売掛実績・残高マスタを更新します。
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      // SQL文を作成します。
      const sqls = [];
      sqls.push(await this.createUpdateSQL());

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['3003']);
        return false;
      }

      // 売掛実績・残高データを更新します。
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['3003']);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['3003']);
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['3003']);
        return false;
      }

      return true;
    },
    /**
     * 売掛実績・残高データを更新するSQL文を作成します。
     * @returns {String} SQL文
     */
    async createUpdateSQL() {
      const colList = [];
      // 前月末売掛税抜残高
      colList.push(CreateColRow('last_month_receivable_no_tax_balance_result', this.lastMonthReceivableNoTaxBalanceResult, 'NUMBER'));
      // 前月末売掛消費税残高
      colList.push(CreateColRow('last_month_receivable_tax_balance_result', this.lastMonthReceivableTaxBalanceResult, 'NUMBER'));
      // 月次売上額
      colList.push(CreateColRow('monthly_sales', this.monthlySales, 'NUMBER'));
      // 月次現金入金額
      colList.push(CreateColRow('monthly_deposit', this.monthlyDeposit, 'NUMBER'));
      // 月次小切手入金額
      colList.push(CreateColRow('monthly_check_deposit', this.monthlyCheckDeposit, 'NUMBER'));
      // 月次振込入金額
      colList.push(CreateColRow('monthly_transfer_deposit', this.monthlyTransferDeposit, 'NUMBER'));
      // 月次手形入金額
      colList.push(CreateColRow('monthly_bill_deposit', this.monthlyBillDeposit, 'NUMBER'));
      // 月次相殺入金額
      colList.push(CreateColRow('monthly_offset_deposit', this.monthlyOffsetDeposit, 'NUMBER'));
      // 月次その他入金額
      colList.push(CreateColRow('monthly_other_deposit', this.monthlyOtherDeposit, 'NUMBER'));
      // 月次消費税入金額
      colList.push(CreateColRow('monthly_tax_deposit', this.monthlyTaxDeposit, 'NUMBER'));
      // 月次一括消費税額
      colList.push(CreateColRow('monthly_bulk_tax', this.monthlyBulkTax, 'NUMBER'));
      // 月次伝票消費税額
      colList.push(CreateColRow('monthly_billing_tax', this.monthlyBillingTax, 'NUMBER'));
      // 月次消費税額
      colList.push(CreateColRow('monthly_tax', this.monthlyTax, 'NUMBER'));
      // 月次粗利額
      colList.push(CreateColRow('monthly_gross_profit', this.monthlyGrossProfit, 'NUMBER'));
      // 今回請求額
      colList.push(CreateColRow('billing_amount', this.billingAmount, 'NUMBER'));
      // 更新日時
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      return CreateUpdateSql(colList, 't_receivables_balances_results') + ` WHERE month_year = ${this.monthYear.replace('-', '')} AND client_id = ${this.clientId}`;
    }
  }
}
</script>
