<template>
    <div>
        <!-- ●●●上部メニュー●●● -->
        <Header :type="menu_type" :title="title" />
        <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> マスタ保守</h5>
                            </div>
                        </div>
                    </div>
                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['1']" @shortkey="executeShortcut('staff-list')">
                            <router-link to="/staff-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="1" id="menu1-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">担当者保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>担当者情報を新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['2']" @shortkey="executeShortcut('staffs-results-maintenance')">
                            <router-link to="/staffs-results-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="2" id="menu1-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">担当者実績保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>担当者別売上管理月報を含む</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['3']" @shortkey="executeShortcut('suppliers-sites-maintenance')">
                            <router-link to="/suppliers-sites-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="3" id="menu1-3">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">仕入先現場コード保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>仕入先現場コードを新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['4']" @shortkey="executeShortcut('payments-balances-maintenance')">
                            <router-link to="/payments-balances-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="4" id="menu1-4">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">支払残高保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>支払残高情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['5']" @shortkey="executeShortcut('billings-balances-maintenance')">
                            <router-link to="/billings-balances-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="5" id="menu1-5">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求残高保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>請求残高情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['6']" @shortkey="executeShortcut('client-maintenance')">
                            <router-link to="/client-maintenance"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="6" id="menu1-6">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">取引先保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>取引先情報の保守を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort2" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['7']" @shortkey="executeShortcut('site-maintenance')">
                            <router-link to="/site-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="7" id="menu1-7">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">7</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">現場保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>現場情報の保守を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['8']" @shortkey="executeShortcut('products-maintenance')">
                            <router-link to="/products-maintenance"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2" 
                                data-id="8" id="menu1-8">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">8</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['9']" @shortkey="executeShortcut('route-maintenance')">
                            <router-link to="/route-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="9" id="menu1-9">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">9</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">ルート保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>配送ルートの種別を登録します。（神田方面）</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '1']" @shortkey="executeShortcut('deliverys-designs-classes-maintenance')">
                            <router-link to="/deliverys-designs-classes-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+1" id="menu1-10">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">納品指定区分保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>納品指定区分を新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '2']" @shortkey="executeShortcut('products9A-classes-maintenance')">
                            <router-link to="/products9A-classes-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+2" id="menu1-11">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">9Aコード管理保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>9Aコードを新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '3']" @shortkey="executeShortcut('products9A-maintenance')">
                            <router-link to="/products9A-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+3" id="menu1-12">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">9A製品保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>9A製品情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '4']" @shortkey="executeShortcut('calendar-master-maintenance')">
                            <router-link to="/calendar-master-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+4" id="menu1-13">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">カレンダーマスタ保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>休業日を新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '5']" @shortkey="executeShortcut('control-master-update')">
                            <router-link to="/control-master-update"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+5" id="menu1-14">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">コントロール保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>システム全体の基本情報を設定します</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['alt', '6']" @shortkey="executeShortcut('service-classes-maintenance')">
                            <router-link to="/service-classes-maintenance"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="alt+6" id="menu1-15">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">サービス区分名保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>サービス区分を新規追加・編集・削除します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort2" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['alt', '7']" @shortkey="executeShortcut('office-list')">
                            <router-link to="/office-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+7" id="menu1-16">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+7</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">営業所保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>営業所情報を新規追加・編集します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['alt', '8']" @shortkey="executeShortcut('stocks-maintenance')">
                            <router-link to="/stocks-maintenance"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2" 
                                data-id="alt+8" id="menu1-17">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+8</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">在庫保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>在庫情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '9']" @shortkey="executeShortcut('client-code-switch-maintenance')">
                            <router-link to="/client-code-switch-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+9" id="menu1-18">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+9</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">取引先コード切替</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>取引先コードの変更を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['ctrl', 'alt', '1']" @shortkey="executeShortcut('product-code-switch-maintenance')">
                            <router-link to="/product-code-switch-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="ctrl+alt+1" id="menu1-19">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品コード切替</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品コードの変更を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['ctrl', 'alt', '2']" @shortkey="executeShortcut('products-compositions-maintenance')">
                            <router-link to="/products-compositions-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="ctrl+alt+2" id="menu1-20">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品構成保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品構成情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['ctrl', 'alt', '3']" @shortkey="executeShortcut('products-results-maintenance')">
                            <router-link to="/products-results-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="ctrl+alt+3" id="menu1-21">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品実績保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品別売上管理月報を含む</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['ctrl', 'alt', '4']" @shortkey="executeShortcut('products-units-conversions-maintenance')">
                            <router-link to="/products-units-conversions-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="ctrl+alt+4" id="menu1-22">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品単位変換保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品単位変換情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['ctrl', 'alt', '5']" @shortkey="executeShortcut('products-prices-groups-maintenance')">
                            <router-link to="/products-prices-groups-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="ctrl+alt+5" id="menu1-23">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">製品単価グループ保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>製品単価グループ情報の保守を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['ctrl', 'alt', '6']" @shortkey="executeShortcut('slip-no-maintenance')">
                            <router-link to="/slip-no-maintenance"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="ctrl+alt+6" id="menu1-24">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">伝票No管理保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>営業所別の伝票Noの始まりと終わりの設定をします。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['ctrl', 'alt', '7']" @shortkey="executeShortcut('save-documents-maintenance-list')">
                            <router-link to="/save-documents-maintenance-list"
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="ctrl+alt+7" id="menu1-25">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">Ctrl+ALT+6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">電子書類保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>電子帳簿保存法を扱う保存電子書類画面の電子書類区分の保守を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-MASTER-MAINTENACE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'マスタ保守',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>