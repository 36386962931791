<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85" id="page-top">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="受注データの新規登録" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-1">
                        <!-- 営業所入力欄 -->
                        <b-col lg="3">
                          <b-form-group
                            label="営業所"
                            label-for="selectSalesOffice"
                          >
                            <b-form-select
                              id="selectSalesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                              text-field="name"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 受注番号入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="receivedOrderId" rules="numeric" v-slot="{ classes,errors }">
                            <label for="receivedOrderId">受注番号<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="receivedOrderId" name="receivedOrderId" v-model="searchConditions.receivedOrderId" @blur="clearDateInputCode"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票種別入力欄 -->
                        <b-col lg="3">
                          <b-form-group
                            label="伝票種別"
                            label-for="selectTradition"
                          >
                            <b-form-select
                              id="selectTradition"
                              v-model="searchConditions.selectTradition"
                              :options="searchConditions.traditionList"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 受注日付入力欄 -->
                        <b-col lg="12">
                          <validation-provider name="orderDateStart" :rules="{dateConsistency:searchConditions.orderDateEnd}" v-slot="{ classes,errors }">
                            <label for="datepicker1">受注日付<b-form-text class="text-muted d-inline-flex">(パフォーマンスの関係で過去の全データを表示することは不可能です。日付は基本的に指定してください。または、受注番号か取引先コードを指定してください。)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-input-group class="input-daterange" id="datepicker">
                                <b-form-datepicker id="datepicker1" name="orderDateStart" class="form-control" v-model="searchConditions.orderDateStart"
                                  :hide-header = true
                                ></b-form-datepicker>
                                <b-input-group-append>
                                  <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateStart=''">
                                    <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                  </b-button>
                                </b-input-group-append>
                                <b-input-group-text>～</b-input-group-text>
                                <b-form-datepicker id="datepicker2" name="orderDateEnd" class="form-control" v-model="searchConditions.orderDateEnd"
                                  :hide-header = true
                                ></b-form-datepicker>
                                <b-input-group-append>
                                  <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateEnd=''">
                                    <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1" v-if="searchConditions.selectTradition==htmlConst.Tradition.salesDirect">
                        <!-- 売上伝票未出力チェック入力欄 -->
                        <b-col lg="6">
                          <label for="isRemaining">売上伝票未出力<b-form-text class="text-muted d-inline-flex">(チェックONの場合、売上伝票未発行の受注が表示されます)</b-form-text></label>
                          <b-form-checkbox
                          id="isRemaining"
                          name="isRemaining"
                          v-model="searchConditions.isRemaining"
                          ></b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="4">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <label for="customerCode">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-input-group>
                                <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                    <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="8">
                          <label for="customerName">取引先名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの取引先名（漢字）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 担当者コード入力欄 -->
                        <b-col lg="4">
                          <validation-provider name="personInCharge" rules="numeric" v-slot="{ classes,errors }">
                            <label for="personInCharge">担当者コード<b-form-text class="text-muted d-inline-flex">(完全一致検索です)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="personInCharge" name="personInCharge" v-model="searchConditions.personInCharge"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 現場名入力欄 -->
                        <b-col lg="8">
                          <label for="siteName">現場名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの現場名（漢字）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="siteName" name="siteName" v-model="searchConditions.siteName"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 製品コード入力欄 -->
                        <b-col lg="4">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <label for="productCode">製品コード<b-form-text class="text-muted d-inline-flex">(前方一致検索です)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="8">
                          <label for="productName">製品名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="receivedOrderList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :small="true"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(StaffId_CustomerCode)>
                <div>担当者コード</div>
                <div>取引先コード</div>
              </template>
              <template #cell(Prepend)="operation">
                <div v-show="!printStatus">
                  <!-- 受注入力照会ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'受注照会'" @click="clickDetailBtn(operation.item.ReceivedOrderId)">
                    <span class="oi oi-list"></span>
                  </b-button>
                </div>
              </template> 
              <template #cell(CustomerName)="data">
                <div style="min-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(SiteName)="data">
                <div style="min-width:240px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.SiteName }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(StaffId_CustomerCode)="data">
                <div>{{ data.item.StaffId }}</div>
                <div>{{ data.item.CustomerCode }}</div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <div v-show="!printStatus">
                  <span v-if="operation.item.ShippingTypeClass==htmlConst.ShippingTypeClass.direct">
                    <!-- 直送売上入力ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'直送売上入力画面を表示します。'" @click="clickDirectInput(operation.item.ReceivedOrderId);" class="mr-1" :disabled="operation.item.IsDirectBillPrint">
                      <span class="oi oi-pencil"></span> 直送売上
                    </b-button>
                    &nbsp;
                  </span>
                  <!-- コピー見積新規登録ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして見積を新規登録します。'" @click="clickEstimateCopy(operation.item.ReceivedOrderId)" class="mr-1">
                    <span class="oi oi-tag"></span> コピー見積
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●得意先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class='htmlConst.ClientClass.customer'/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import { init, windowPrint, addOperationLogs, convertSqlLikeSpecialChar, formatDate, getListValue, executeSelectSql } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices } from '@/graphql/queries';

const MODULE_NAME = 'received-order-list';

export default {
  name: 'RECEIVED-ORDER-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '受注一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions: {
        selectSalesOffice: '',
        salesOffice: [],
        selectTradition: 0,
        traditionList: [],
        isRemaining: false,
        receivedOrderId: '',
        personInCharge: '',
        orderDateStart: formatDate(new Date(), 'YYYY-MM-DD'),
        orderDateEnd: formatDate(new Date(), 'YYYY-MM-DD'),
        clientCode: '',
        clientName: '',
        siteName: '',
        productCode: '',
        productName: '',
      },
      // 検索結果
      receivedOrderList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 定数（htmlで使用）
      htmlConst: {
        // 配送種別区分
        ShippingTypeClass: {
          // 通常配送
          normal: Const.ShippingTypeClass.normal,
          // 宅配
          delivery: Const.ShippingTypeClass.delivery,
          // 営業持参
          bring: Const.ShippingTypeClass.bring,
          // 直送
          direct: Const.ShippingTypeClass.direct,
        },
        // 伝票種別
        Tradition: {
          // 直送伝票
          salesDirect: Const.Tradition.salesDirect,
        },
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'Prepend',
          label: '',
        },
        {
          key: 'ReceivedOrderDate',
          label: '受注日付',
          sortable: true,
        },
        {
          key: 'ReceivedOrderId',
          label: '受注番号',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'SiteName',
          label: '現場名',
          sortable: true,
        },
        {
          key: 'Tradition',
          label: '伝票種別',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所名',
        },
        {
          key: 'StaffId_CustomerCode',
          label: '担当者コード&取引先コード',
        },
        {
          key: 'PersonInCharge',
          label: '担当者名',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT', query: { estimateId: 0 } })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        let staffListResult = await API.graphql(graphqlOperation(list_m_staffs,condition));
        let staffListData = staffListResult.data.list_m_staffs;
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);

        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 伝票種別
        this.searchConditions.traditionList.push({id: 0, text: '全て'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「11:通常売上」「12:返品伝票」「14:直送伝票」
          if (Const.TraditionList[i].id == Const.Tradition.salesNormal ||
          Const.TraditionList[i].id == Const.Tradition.salesReturn ||
          Const.TraditionList[i].id == Const.Tradition.salesDirect) {
            this.searchConditions.traditionList.push(Const.TraditionList[i]);
          }
        }
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      this.$store.commit('setLoading', true);
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.receivedOrderList = [];
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      let tradition = '';
      for(let i = 0; i < result.length; i++){
        // 伝票種別
        if (result[i].order_receive_bill_class == Const.OrderReceiveBillClass.return) {
          // 12：返品伝票
          tradition = getListValue(Const.TraditionList, Const.Tradition.salesReturn, 'id', 'text');
        } else if (result[i].shipping_type_class == Const.ShippingTypeClass.direct) {
          // 14：直送伝票
          tradition = getListValue(Const.TraditionList, Const.Tradition.salesDirect, 'id', 'text');
        } else {
          // 11：通常売上
          tradition = getListValue(Const.TraditionList, Const.Tradition.salesNormal, 'id', 'text');
        }
        let searchResult = {
          ReceivedOrderDate: formatDate(result[i].order_receive_date),
          ReceivedOrderId: result[i].order_receive_id,
          ShippingTypeClass: result[i].shipping_type_class,
          Tradition: tradition,
          SalesOffice: result[i].office_name_kanji,
          StaffId: result[i].staff_id,
          PersonInCharge: result[i].staff_name_kanji,
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name,
          SiteName: result[i].client_site_name,
          IsDirectBillPrint: result[i].shipping_type_class == Const.ShippingTypeClass.direct && result[i].is_bill_return_print == 1 ? true : false,
          Operation: result[i],
        };
        this.receivedOrderList.push(searchResult);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name';
      selectSql += ',orders_receives.client_site_name';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.is_bill_return_print';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_offices AS offices USING(office_id)';
      selectSql += 'INNER JOIN m_staffs AS staffs USING(staff_id)';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 削除済フラグ
      selectSql += ' orders_receives.is_deleted = 0 ';
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 受注番号
      if (this.searchConditions.receivedOrderId != '') {
        selectSql += 'AND orders_receives.order_receive_id = ' + this.searchConditions.receivedOrderId + ' ';
      }
      // 受注日付
      if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == '') {
        selectSql += 'AND orders_receives.order_receive_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      } else if (this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      } else if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 伝票種別
      if (this.searchConditions.selectTradition == Const.Tradition.salesNormal) {
        // 11:通常売上
        // 受注伝票種別が「12：返品」以外
        selectSql += 'AND orders_receives.order_receive_bill_class <> ' + Const.OrderReceiveBillClass.return + ' ';
        // 配送種別区分が「4：直送」以外
        selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      } else if (this.searchConditions.selectTradition == Const.Tradition.salesReturn) {
        // 12:返品伝票
        // 受注伝票種別が「12：返品」
        selectSql += 'AND orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.return + ' ';
      } else if (this.searchConditions.selectTradition == Const.Tradition.salesDirect) {
        // 14:直送伝票
        // 配送種別区分が「4：直送」
        selectSql += 'AND orders_receives.shipping_type_class = \'' + Const.ShippingTypeClass.direct + '\' ';
        // 売上伝票未出力
        if (this.searchConditions.isRemaining == true) {
          selectSql += 'AND orders_receives.remaining_quantity > 0 ';
        }
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND orders_receives.client_class = ' + Const.ClientClass.customer + ' ';
        selectSql += 'AND orders_receives.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        // 特殊文字変換
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(orders_receives.client_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 担当者コード
      if (!(this.searchConditions.personInCharge === '')) {
        selectSql += 'AND orders_receives.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 現場名(あいまい検索対象)
      if(this.searchConditions.siteName != ''){
        // 特殊文字変換
        const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(orders_receives.client_site_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 製品コード
      if(this.searchConditions.productCode != ''){
        selectSql += 'AND orders_receives.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if(this.searchConditions.productName != ''){
        // 特殊文字変換
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(orders_receives.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' orders_receives.order_receive_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' order_receive_date DESC';
      selectSql += ',order_receive_id DESC';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT', query: { estimateId: 0 } });
    },
    /* 受注入力照会ボタン押下時 */
    async clickDetailBtn(receivedOrderId) {
      //console.log('照会');
      //console.log(receivedOrderId);
      let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: receivedOrderId, parentKbn: 0, reserveErrFlg: 0 } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    /* コピー見積新規登録ボタン押下時 */
    async clickEstimateCopy(receivedOrderId) {
      //console.log('コピー見積新規登録');
      // 見積入力画面へ遷移
      //console.log(receivedOrderId);
      let route = this.$router.resolve({ name: 'ESTIMATE-INPUT', query: { propEstimateId: '0', propReceivedOrderId: receivedOrderId } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 直送売上入力ボタンの押下
    async clickDirectInput(orderReceiveId) {
      this.clearAlert();
      const functionName = 'clickDirectInput';
      this.$store.commit('setLoading', true);
      var newWin = window.open('', '_blank');
      try {
        let selectSql = '';
        selectSql += 'SELECT ';
        selectSql += 'CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
        selectSql += ',orders_receives.is_bill_return_print AS ships_class';
        selectSql += ',orders_receives.inventory_control_class';
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'LEFT JOIN t_orders AS orders ';
        selectSql += 'ON orders_receives.order_id = orders.order_id ';
        selectSql += 'AND orders_receives.order_row = orders.order_row ';
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + orderReceiveId + ' ';
        selectSql += 'AND orders_receives.is_deleted = 0 ';

        let isAllReserve = true;
        let isShips = false;
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null && resultData.length > 0) {
          for (let i = 0; i < resultData.length; i++) {
            if (resultData[i].ships_class == Const.ShipsClass.sumi) {
              isShips = true;
              break;
            } else if (resultData[i].order_issue_class == 0) {
              // 発注書未発行の帳票あり
              if (resultData[i].inventory_control_class == Const.InventoryControlClassDef.inventory) {
                // 在庫管理ありの帳票が発注書未発行の場合は直送売上入力不可
                isAllReserve = false;
              }
            }
          }
          
          if (isShips == true) {
            newWin.close()
            this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','受注').replace('%arg2%','直送売上入力'));
          } else if (isAllReserve == true) {
            let query = {
              receivedOrderId: orderReceiveId,
              tabFlg: '1',
            };
            let route = this.$router.resolve({name: 'SALES-DIRECT-INPUT', query});
            newWin.location = route.href
          } else {
            newWin.close()
            this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','受注').replace('%arg2%','直送売上入力'));
          }
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','受注').replace('%arg2%','直送売上入力'));
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      } finally {
        if (this.getMessageFlg) {
          // ios safari ための対応
          setTimeout(() => {
            scrollTo(0,0)
          }, 500);
        }
      }
      this.$store.commit('setLoading', false);
    },
    // 番号入力時、日付クリア処理
    clearDateInputCode: function() {
      // 受注番号を入力した場合、日付をクリアする。
      if (this.searchConditions.receivedOrderId != '') {
        this.searchConditions.orderDateStart = '';
        this.searchConditions.orderDateEnd = '';
      }
    },
    //「取引先検索」アイコン押下処理
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    //「取引先検索」モーダルのクローズ押下処理
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        this.searchConditions.clientCode = clientItems.detail.client_id;
      }
    },
  },
}
</script>
<style scoped>
</style>