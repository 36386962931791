<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 直送売上入力</strong></h5>
                <b-button pill v-b-tooltip.hover title="受注入力照会に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>伝票発行の有無</b-th>
                            <b-td>
                              <b-form-checkbox
                              id="isPrint"
                              name="isPrint"
                              v-model="isPrint"
                              ></b-form-checkbox>
                            </b-td>
                            <b-th>営業所</b-th>
                            <b-td colspan="3">{{selectSalesOfficeText}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>受注番号</b-th>
                            <b-td colspan="7">{{receivedOrderId}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>伝票番号</b-th>
                            <b-td></b-td>
                            <b-th>伝票種別</b-th>
                            <b-td>{{selectBillClassText}}</b-td>
                            <b-th>締日</b-th>
                            <b-td>{{suppliers.closingDate}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 日付 -->
                    <b-col lg="6">
                      <validation-provider name="dateSalesRecord" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="日付"
                          label-for="dateSalesRecord"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateSalesRecord"
                                  name="dateSalesRecord"
                                  v-model="dateSalesRecord"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                          <b-form-text class="text-muted">売上伝票の発行日を設定します。</b-form-text>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>取引先</b-th>
                            <b-td colspan="5">{{suppliers.code + ' ' + suppliers.name}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所１</b-th>
                            <b-td colspan="3">{{suppliers.address1}}</b-td>
                            <b-th>部署１</b-th>
                            <b-td>{{suppliers.department1}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所２</b-th>
                            <b-td colspan="3">{{suppliers.address2}}</b-td>
                            <b-th>部署２</b-th>
                            <b-td>{{suppliers.department2}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>郵便番号</b-th>
                            <b-td>{{suppliers.zipCode}}</b-td>
                            <b-th>TEL</b-th>
                            <b-td>{{suppliers.tel}}</b-td>
                            <b-th>担当者</b-th>
                            <b-td>{{suppliers.personInCharge}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>宛名印字区分</b-th>
                            <b-td>{{onSite.clientPrintClass}}</b-td>
                            <b-th>印刷用宛名</b-th>
                            <b-td colspan="3">{{onSite.clientPrintNameText}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-form-text class="text-muted">宛名印字区分が1の場合、取引先名に印刷用宛名が印字され、取引先の住所、部署、郵便番号、TELは印字されない。</b-form-text>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>製品テーブル</template>
                            <template #cell(ProductName)="data">
                              <span v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark + ' ' : '' }}</span>
                              {{ data.item.ProductName }}
                            </template>
                            <template #cell(SellingPrice)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 160px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="d-flex align-items-center">
                                        <b-form-input type="number" name="productSellingPrice" v-model.number="data.item.SellingPrice" @blur="calc(data.index)" />
                                        &nbsp;
                                        <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 130px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input type="number" name="productPurchasePrime" v-model.number="data.item.PurchasePrime" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Money)="data">
                              {{ data.item.Money.toLocaleString() }}
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="Money" v-model.number="data.item.Money" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="TotalPurchasePrime" v-model.number="data.item.TotalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>納品指定日</b-th>
                            <b-td colspan="3">{{orderDate}} {{selectDeliveryDesignation}} {{deliveryNote}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>現場</b-th>
                            <b-td colspan="3">{{onSite.code}} {{onSite.deliveryCode}} {{onSite.name}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>郵便番号</b-th>
                            <b-td colspan="3">{{onSite.zipCode}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所１</b-th>
                            <b-td>{{onSite.address1}}</b-td>
                            <b-th>住所２</b-th>
                            <b-td>{{onSite.address2}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>納品場所</b-th>
                            <b-td colspan="3">{{suppliers.deliveryPlace}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button v-shortkey="['f10']" @shortkey="clearAlert(); saveData();" pill variant="success" type="submit" form="inputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false">
                        <span class="oi oi-circle-check"></span> 保存
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, getControlMaster, formatDate, formatCurDate, formatDateCalc, getListValue, getNullStr, addOperationLogs, executeSelectSql, CreateColRow, CreateInsertSql, CreateUpdateSql, getFormCounter, calcTaxNew, executeSqlList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, checkOldClosingDate, checkInvoiceIssue } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_deliverys_designs_classes, list_m_service_classes, list_m_staffs } from '@/graphql/queries';

const MODULE_NAME = 'sales-direct-input';

export default {
  name: 'SALES-DIRECT-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '直送売上入力',
      loginId: '',
      listUpdatedSelect: [],
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所コード
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      // 受注日付
      dateOrderReceiveDate: '',
      // 伝種プルダウン
      selectBillClass: Const.Tradition.salesDirect,
      selectBillClassText: '',
      // 取引先系
      suppliers: {
        closingDate: '',
        closingDay: 0,
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        taxClass: '',
        taxCalculationClass: '',
        taxFracClass: '',
      },
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: '',
      selectDeliveryDesignationText: '',
      deliveryDesignation: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // テーブル定義
      productList: [],
      // サービス区分
      serviceList: [],
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // トランザクションSQLリスト
      transactSqlList: [],
      // ステータスフラグ
      statusFlg: {
        zanFlg: false,
        hikiateFlg: false,
        shukkaFlg: false,
        denpyoFlg: false,
        editFlg: true,
      },
      // 売上計上日
      dateSalesRecord: '',
      // 伝票発行の有無
      isPrint: true,
      // 合計情報
      totalFee: 0,
      subTotalNormal: 0,
      subTotalLight: 0,
      // 確認ダイアログ用
      confirmMessage: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // パラメータ
      receivedOrderId: null,
      tabFlg: '0',
      // 別タブを開く
      newWin: null,
      // 画面を閉じる
      winClose: false,
      // 伝票作成情報（「YYYYMMDD(8桁) + 半角スペース + ログイン担当者コード（最大4桁）」）
      createdUserData: '',
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'ServiceClassText',
          label: 'サービス区分',
        },
        {
          key: 'Quantity',
          label: '数量',
          tdClass: 'text-right',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'SellingPrice',
          label: '売価',
        },
        {
          key: 'PurchasePrime',
          label: '仕入単価',
        },
        {
          key: 'Money',
          label: '金額',
          tdClass: 'text-right',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    this.tabFlg = this.$route.query.tabFlg;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData'
      this.$store.commit('setLoading', true);
      try {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 非同期でDBから情報を取得
        let staffListResult = null;
        let condition = {where_clause: 'AND login_id = ' + '\''+ this.loginId + '\''};
        let deliveryListResult = null;
        let serviceListResult = null;
        let controlData = null;
        [staffListResult, deliveryListResult, serviceListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_deliverys_designs_classes)),
          API.graphql(graphqlOperation(list_m_service_classes)),
          getControlMaster(),
        ]);
        // 納品指定区分を取得
        let deliveryListData = deliveryListResult.data.list_m_deliverys_designs_classes;
        //console.log(deliveryListData);
        for (let i = 0; i < deliveryListData.length; i++) {
          let deliveryDesignation = {
            id: deliveryListData[i].delivery_design_class,
            name: deliveryListData[i].delivery_design_name,
            text: deliveryListData[i].delivery_design_class + '：' + deliveryListData[i].delivery_design_name,
          };
          this.deliveryDesignation.push(deliveryDesignation);
        }
        // サービス区分を取得
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for (let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 伝票作成情報を作成
        let staffListData = staffListResult.data.list_m_staffs;
        this.createdUserData = formatCurDate('YYYYMMDD') + ' ' + staffListData[0].staff_id;
        // 既存データ設定
        await this.setReceivedOrder();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 受注データ設定 */
    async setReceivedOrder() {
      const functionName = 'setReceivedOrder';
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      try {
        //console.log('条件取得');
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if (dataResult != null) {
          await this.setResult(dataResult);
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_row';
      selectSql += ',orders_receives.office_id';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.client_class';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',orders_receives.zip_code';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.tax_fractionation_class';
      selectSql += ',orders_receives.address_1';
      selectSql += ',orders_receives.address_2';
      selectSql += ',orders_receives.department_1';
      selectSql += ',orders_receives.department_2';
      selectSql += ',orders_receives.phone_number';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
      selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',orders_receives.service_class';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_unit_amount';
      selectSql += ',orders_receives.client_amount_class';
      selectSql += ',orders_receives.order_unit_amount';
      selectSql += ',orders_receives.product_manual_input_class';
      selectSql += ',CASE WHEN cumulative_transaction.transaction_id IS NULL THEN 0 ELSE 1 END AS torokuzumi_flg';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON orders_receives.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders_receives.client_class = clients.client_class ';
      selectSql += 'AND orders_receives.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON orders_receives.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON clients_sites.client_id = orders_receives.client_id ';
      selectSql += 'AND clients_sites.site_id = orders_receives.site_id ';
      selectSql += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'ON orders_receives.order_receive_id = cumulative_transaction.order_receive_id ';
      selectSql += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の受注番号で絞り込む
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      // 削除された製品は表示しない
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_receives.order_receive_id,orders_receives.order_receive_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所
        this.selectSalesOffice = result[0].office_id;
        this.selectSalesOfficeText = result[0].office_id + '：' + result[0].office_name_kanji;
        // 受注日付
        this.dateOrderReceiveDate = formatDate(result[0].order_receive_date);
        // 日付の初期値
        if (result[0].delivery_design_date != null) {
          // 納品指定日に値あり
          this.dateSalesRecord = result[0].delivery_design_date;
        } else {
          // 上記以外の場合は受注日付を初期値とする
          this.dateSalesRecord = formatCurDate('YYYY-MM-DD');
        }
        // 伝票種別
        this.selectBillClassText = getListValue(Const.TraditionList, this.selectBillClass, 'id', 'text');
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        if (result[0].closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        this.suppliers.closingDay = result[0].closing_date;
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number === 'undefined' ? '' : result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code === 'undefined' ? '' : result[0].zip_code;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalculationClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        // 現場系
        this.onSite.deliveryCode = getNullStr(result[0].shipping_code);
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        this.onSite.zipCode = result[0].client_site_zip_code;
        this.onSite.address1 = result[0].client_site_address_1;
        this.onSite.address2 = result[0].client_site_address_2;
        this.onSite.deliveryPlace = result[0].delivery_location;
        this.onSite.tel = result[0].client_site_phone_number;
        this.onSite.clientPrintClass = Number(result[0].order_print_class);
        this.onSite.clientPrintName = result[0].order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : this.onSite.clientPrintName;
        // 納品指定日
        this.orderDate = result[0].delivery_design_date == null ? '' : formatDate(result[0].delivery_design_date);
        // 納品指定区分
        this.selectDeliveryDesignation = result[0].delivery_design_class;
        this.selectDeliveryDesignationText = getListValue(this.deliveryDesignation, this.selectDeliveryDesignation, 'id', 'text');
        // 納品メモ
        this.deliveryNote = result[0].delivery_design;
        // 全件参照して製品一覧を作成
        for (let i = 0; i < result.length; i++) {
          if (i == this.productList.length) {
            this.addTableRow();
          }
          this.productList[i].OrderReceiveRow = result[i].order_receive_row;
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].ServiceClassText = getListValue(this.serviceList, result[i].service_class, 'id', 'text');
          this.productList[i].Quantity = result[i].order_receive_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].ReserveQuantity = result[i].stock_scheduled_reserve_receive;
          this.productList[i].SellingPrice = result[i].order_receive_unit_amount;
          this.productList[i].ClientAmountClass = result[i].client_amount_class;
          this.productList[i].PurchasePrime = result[i].order_unit_amount;
          this.productList[i].SundriesClass = result[i].product_manual_input_class;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          // 直送売上入力済みの場合は
          if (result[i].torokuzumi_flg == 1) {
            this.statusFlg.editFlg = false;
            if (this.alertWarning.length == 0) {
              this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
            }
          }
        }
        this.calcTotal();
      }
    },
    // キャンセルボタン
    cancel:function() {
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 0, reserveErrFlg: 0 }});
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        OrderReceiveRow: null,
        ProductCode: null,
        ProductName: '',
        ServiceClass: '',
        ServiceClassText: '',
        Quantity: 0,
        Unit: '',
        ReserveQuantity: 0,
        SellingPrice: 0,
        ClientAmountClass: 0,
        PurchasePrime: 0,
        Money: 0,
        TotalPurchasePrime: 0,
        SundriesClass: null,
      };
      this.productList.push(newProduct);
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      // 合計金額、合計仕入単価、粗利、消費税額計算
      this.calcTotal();
    },
    // 合計金額計算
    calcTotal: function() {
      this.totalFee = 0;
      let moneySubTotalNormal = 0;
      let moneySubTotalLight = 0;
      for (let i = 0; i < this.productList.length;i++) {
        this.totalFee += this.productList[i].Money;
        if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.normalTax) {
          moneySubTotalNormal += this.productList[i].Money;
        } else if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.lightTax) {
          moneySubTotalLight += this.productList[i].Money;
        }
      }
      this.subTotalNormal = moneySubTotalNormal;
      this.subTotalLight = moneySubTotalLight;
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        // 請求書発行登録済みの場合、保存不可
        let errMessage = await checkInvoiceIssue(this.suppliers.code, this.dateSalesRecord, this.suppliers.closingDay, this.onSite.code);
        if (errMessage != '') {
          this.alertWarning.push(errMessage);
          scrollTo(0,0);
        } else {
          // 入力チェックが完了した場合、保存確認ダイアログを表示
          //await this.saveConfirm();
          if (await this.confirmOldClosingDate() == true) {
            await this.closeConfirmModal();
          }
        }
      }
    },
    /* 保存時の確認ダイアログ表示 */
    /*
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    */
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal() {
      const functionName = 'closeConfirmModal';
      this.winClose = false;
      try {
        this.$store.commit('setLoading', true);
        if (this.isPrint) {
          // 別タブ事前に開く
          this.newWin = window.open('', '_blank');
        }
        await this.confirmSave();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg && this.alertSuccess.length == 0) {
        // タブを閉じる
        if (this.isPrint) {
          this.newWin.close()
        }
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg) {
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      if (this.winClose == true) {
        window.close();
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 受注データの登録(SQLの作成)
      if (await this.execSave() == true) {
        if (this.tabFlg == '1') {
          this.winClose = true;
        } else {
          // 受注入力照会画面へ遷移
          this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 1, reserveErrFlg: 0 } });
        }
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 受注データロック処理
        this.lockOrderReserved();
        // 発注データロック処理
        this.lockOrders();
        // 受注データの更新
        this.updateOrderReserved();
        // 発注データの更新
        this.updateOrders();
        // 累積トランザクション登録
        await this.insertCumulativeTransaction();

        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);
        if (this.isPrint == true) {
          // 納品書PDF出力
          this.outputSalesDirectPdf();
        }
        retResult = true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        console.log(error);
        return false;
      }
      return retResult;
    },
    /* 受注データロックSQL作成 */
    lockOrderReserved: function() {
      //console.log('受注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 発注データロックSQL作成 */
    lockOrders: function() {
      //console.log('発注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 受注データ更新処理 */
    updateOrderReserved: function() {
      //console.log('受注データ更新SQL作成');
      let colList = [];
      // 製品関係の更新部分
      let strWorkField = 'FIELD(orders_receives.order_receive_row';
      let strWorkOrderReceiveUnitAmount = '';
      let strWorkOrderUnitAmount = '';
      for(let i = 0; i < this.productList.length; i++) {
        strWorkField += ',' + this.productList[i].OrderReceiveRow;
        // 受注単価
        strWorkOrderReceiveUnitAmount += ',' + this.productList[i].SellingPrice;
        // 発注単価
        strWorkOrderUnitAmount += ',' + this.productList[i].PurchasePrime;
      }
      strWorkField += ')';
      // 受注単価
      colList.push(CreateColRow('orders_receives.order_receive_unit_amount', 'ELT(' + strWorkField + strWorkOrderReceiveUnitAmount + ')', 'NUMBER'));
      // 発注単価
      colList.push(CreateColRow('orders_receives.order_unit_amount', 'ELT(' + strWorkField + strWorkOrderUnitAmount + ')', 'NUMBER'));
      // 受注金額
      colList.push(CreateColRow('orders_receives.order_receive_amount', 'orders_receives.order_receive_quantity * orders_receives.order_receive_unit_amount', 'NUMBER'));
      // 発注金額
      colList.push(CreateColRow('orders_receives.order_amount', 'orders_receives.order_receive_quantity * orders_receives.order_unit_amount', 'NUMBER'));
      // 粗利額
      colList.push(CreateColRow('orders_receives.gross_profit', 'orders_receives.order_receive_amount - orders_receives.order_amount', 'NUMBER'));
      // 入荷予定引当数受注
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'orders_receives.order_receive_quantity', 'NUMBER'));
      // 受注残数
      colList.push(CreateColRow('orders_receives.remaining_quantity', 'orders_receives.remaining_quantity - orders_receives.stock_scheduled_reserve_receive', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve', 'orders_receives.stock_scheduled_reserve - orders_receives.stock_scheduled_reserve_receive', 'NUMBER'));
      // 出荷数
      colList.push(CreateColRow('orders_receives.shipping_quantity', 'orders_receives.shipping_quantity + orders_receives.stock_scheduled_reserve_receive', 'NUMBER'));
      // 伝票発行フラグ
      colList.push(CreateColRow('is_bill_return_print', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      updateSql += 'AND orders_receives.is_deleted = 0 ';
      updateSql += 'AND orders_receives.shipping_quantity = 0 ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 発注データ更新SQL作成 */
    updateOrders: function() {
      //console.log('発注データ更新SQL作成');
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders_receives.order_receive_id';
      updateQuery += ',orders_receives.order_receive_row';
      updateQuery += ',orders_receives.order_id';
      updateQuery += ',orders_receives.order_row';
      updateQuery += ',orders_receives.order_unit_amount';
      updateQuery += ',orders_receives.order_amount';
      updateQuery += ' FROM ';
      updateQuery += 't_orders_receives AS orders_receives ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      updateQuery += 'AND orders_receives.is_deleted = 0 ';
      updateQuery += ') AS orders_receives_QUERY';
      let colList = [];
      let updateSql = '';
      let where_clause = '';
      colList = [];
      // 発注単価
      colList.push(CreateColRow('orders.order_unit_amount', 'orders_receives_QUERY.order_unit_amount', 'NUMBER'));
      // 発注金額
      colList.push(CreateColRow('orders.order_amount', 'orders_receives_QUERY.order_amount', 'NUMBER'));
      // 発注残数
      colList.push(CreateColRow('orders.remaining_quantity', 0, 'NUMBER'));
      // 入荷数
      colList.push(CreateColRow('orders.stock_quantity', 'orders.order_quantity', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('orders.stock_scheduled_reserve', 0, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders.updated_user', this.loginId, 'VARCHAR'));
      /* WHERE句 */
      where_clause = ' WHERE ';
      where_clause += 'orders.order_id = orders_receives_QUERY.order_id ';
      where_clause += 'AND orders.order_row = orders_receives_QUERY.order_row ';
      where_clause += 'AND orders.order_receive_id = orders_receives_QUERY.order_receive_id ';
      where_clause += 'AND orders.order_receive_row = orders_receives_QUERY.order_receive_row ';
      where_clause += 'AND orders.is_deleted = 0 ';
      // Update句とWHERE句でUPDATE文作成
      updateSql = CreateUpdateSql(colList, 't_orders AS orders', updateQuery) + where_clause;
      //console.log(updateSql)
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 累積トランザクション登録SQL作成 */
    async insertCumulativeTransaction() {
      //console.log('累積トランザクション登録SQL作成');
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      let billingNo = await this.getBillingNo();
      let tax = 0;
      if (this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
        // 税額計算区分が1の場合、消費税を計算
        tax = calcTaxNew(this.dateSalesRecord, this.subTotalNormal, this.subTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);
      }
      for(let i = 0; i < this.productList.length + 1; i++) {
        let colList = [];
        // INSERT文の先頭部分、初回のみ作成
        if (bulkInsertSqlStart == '') {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', null, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', null, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', null, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', null, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', null, 'NUMBER'));
          // 解体組立区分
          colList.push(CreateColRow('dismantling_assembly_class', null, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', null, 'NUMBER'));
          // 移動先コード
          colList.push(CreateColRow('destination_id', null, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', null, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', null, 'NUMBER'));
          // 納品指定日
          colList.push(CreateColRow('delivery_design_date', null, 'DATE'));
          // 納品指定区分
          colList.push(CreateColRow('delivery_design_class', null, 'VARCHAR'));
          // 納品指定
          colList.push(CreateColRow('delivery_design', null, 'VARCHAR'));
          // 現場コード
          colList.push(CreateColRow('site_id', null, 'NUMBER'));
          // 配送コード
          colList.push(CreateColRow('shipping_code', null, 'VARCHAR'));
          // 現場名称
          colList.push(CreateColRow('client_site_name', null, 'VARCHAR'));
          // 現場郵便番号
          colList.push(CreateColRow('client_site_zip_code', null, 'VARCHAR'));
          // 現場住所1
          colList.push(CreateColRow('client_site_address_1', null, 'VARCHAR'));
          // 現場住所2
          colList.push(CreateColRow('client_site_address_2', null, 'VARCHAR'));
          // 納品場所
          colList.push(CreateColRow('delivery_location', null, 'VARCHAR'));
          // 現場TEL
          colList.push(CreateColRow('client_site_phone_number', null, 'VARCHAR'));
          // 伝票摘要
          colList.push(CreateColRow('billing_summary', null, 'VARCHAR'));
          // 処理日付
          colList.push(CreateColRow('process_date', null, 'DATE'));
          // 受注処理担当者コード	
          colList.push(CreateColRow('order_receive_staff_id', null, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', null, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
          // 相殺伝票No
          colList.push(CreateColRow('offset_billing_no', null, 'NUMBER'));
          // 製品コード
          colList.push(CreateColRow('product_id', null, 'NUMBER'));
          // 製品名
          colList.push(CreateColRow('product_name', null, 'VARCHAR'));
          // 製品備考
          colList.push(CreateColRow('product_note', null,'VARCHAR'));
          // サービス区分
          colList.push(CreateColRow('service_class', null, 'VARCHAR'));
          // 製品数量
          colList.push(CreateColRow('product_quantity', null, 'NUMBER'));
          // 製品単位
          colList.push(CreateColRow('product_unit', null, 'VARCHAR'));
          // 製品売上単価
          colList.push(CreateColRow('product_sales_unit_price', null, 'NUMBER'));
          // 製品仕入単価
          colList.push(CreateColRow('product_purchase_price', null, 'NUMBER'));
          // 製品売上金額
          colList.push(CreateColRow('product_sales_unit_amount', null, 'NUMBER'));
          // 製品仕入金額
          colList.push(CreateColRow('product_purchase_amount', null, 'NUMBER'));
          // 製品粗利額
          colList.push(CreateColRow('product_gross_profit', null, 'NUMBER'));
          // 製品消費税
          colList.push(CreateColRow('product_tax', null, 'NUMBER'));
          // 金額
          colList.push(CreateColRow('amount', null, 'NUMBER'));
          // 製品分類コード
          colList.push(CreateColRow('product_class_id', null, 'NUMBER'));
          // 製品諸口区分
          colList.push(CreateColRow('product_mouth_class', null, 'NUMBER'));
          // 在庫管理区分
          colList.push(CreateColRow('inventory_control_class', null, 'NUMBER'));
          // 取引先製品単価区分
          colList.push(CreateColRow('client_amount_class', null, 'VARCHAR'));
          // 売上課税区分
          colList.push(CreateColRow('sales_tax_class', null, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', null, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', null, 'NUMBER'));
          // 月次更新フラグ
          colList.push(CreateColRow('is_update_monthly', null, 'NUMBER'));
          // 月次年月
          colList.push(CreateColRow('monthly_month_year', null, 'NUMBER'));
          // 締日更新フラグ
          colList.push(CreateColRow('is_update_closing_date', null, 'NUMBER'));
          // 締次年月
          colList.push(CreateColRow('closing_month_year', null, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', null, 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', null, 'NUMBER'));
          // 発注番号
          colList.push(CreateColRow('order_id', null, 'NUMBER'));
          // 発注行番号
          colList.push(CreateColRow('order_row', null, 'NUMBER'));
          // 注文番号
          colList.push(CreateColRow('order_number', null, 'NUMBER'));
          // 注文行番号
          colList.push(CreateColRow('order_number_row', null, 'NUMBER'));
          // 組合CD発注取引先
          colList.push(CreateColRow('union_cd_order_client', null, 'NUMBER'));
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          colList.push(CreateColRow('is_check_purchase_price', null, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', null, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
          bulkInsertSqlStart = 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') ';
          //console.log(bulkInsertSqlStart);
        }
        if (i < this.productList.length || this.suppliers.taxCalculationClass == 1) {
          let selectSql = '';
          /* SELECT句 */
          selectSql += 'SELECT ';
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          selectSql += ' \'' + Const.TransactionId.sales + '\'';
          // 伝票No
          selectSql += ',' + billingNo;
          // 行No
          selectSql += ',' + (i + 1).toString();
          // 伝票日付
          selectSql += ',\'' + this.dateSalesRecord + '\'';
          // 伝票種別
          selectSql += ',' + Const.Tradition.salesDirect;
          // 解体組立区分
          selectSql += ',0';
          // 営業所コード
          selectSql += ',orders_receives.office_id';
          // 移動先コード
          selectSql += ',0';
          // 取引先区分
          selectSql += ',orders_receives.client_class';
          // 取引先コード
          selectSql += ',orders_receives.client_id';
          // 納品指定日
          selectSql += ',orders_receives.delivery_design_date';
          // 納品指定区分
          selectSql += ',orders_receives.delivery_design_class';
          // 納品指定
          selectSql += ',orders_receives.delivery_design';
          // 現場コード
          selectSql += ',orders_receives.site_id'
          // 配送コード
          selectSql += ',orders_receives.shipping_code';
          // 現場名称
          selectSql += ',orders_receives.client_site_name';
          // 現場郵便番号
          selectSql += ',orders_receives.client_site_zip_code';
          // 現場住所1
          selectSql += ',orders_receives.client_site_address_1';
          // 現場住所2
          selectSql += ',orders_receives.client_site_address_2';
          // 納品場所
          selectSql += ',orders_receives.delivery_location';
          // 現場TEL
          selectSql += ',orders_receives.client_site_phone_number';
          // 伝票摘要
          selectSql += ',\'' + this.createdUserData + '\'';
          // 処理日付
          selectSql += ',orders_receives.order_receive_date';
          // 受注処理担当者コード	
          selectSql += ',orders_receives.order_receive_staff_id';
          // 担当者コード
          selectSql += ',orders_receives.staff_id';
          // 取引先分類コード
          selectSql += ',orders_receives.client_id_first_digit';
          // 相殺伝票No
          selectSql += ',0';
          if (i == this.productList.length) {
            // 消費税行
            // 製品コード
            selectSql += ',0';
            // 製品名
            selectSql += ',\'\'';
            // 製品備考
            selectSql += ',\'\'';
            // サービス区分
            selectSql += ',\'\'';
            // 製品数量
            selectSql += ',0';
            // 製品単位
            selectSql += ',\'\'';
            // 製品売上単価
            selectSql += ',0';
            // 製品仕入単価
            selectSql += ',0';
            // 製品売上金額
            selectSql += ',0';
            // 製品仕入金額
            selectSql += ',0';
            // 製品粗利額
            selectSql += ',0';
            // 製品消費税
            selectSql += ',' + tax;
            // 金額
            selectSql += ',0';
            // 製品分類コード
            selectSql += ',0';
            // 製品諸口区分
            selectSql += ',0';
            // 在庫管理区分
            selectSql += ',0';
            // 取引先製品単価区分
            selectSql += ',\'\'';
          } else {
            // 上記以外（通常の製品行）
            // 製品コード
            selectSql += ',orders_receives.product_id';
            // 製品名
            selectSql += ',orders_receives.product_name';
            // 製品備考
            selectSql += ',orders_receives.note';
            // サービス区分
            selectSql += ',orders_receives.service_class';
            // 製品数量
            selectSql += ',orders_receives.order_receive_quantity';
            // 製品単位
            selectSql += ',orders_receives.unit';
            // 製品売上単価
            selectSql += ',orders_receives.order_receive_unit_amount';
            // 製品仕入単価
            selectSql += ',orders_receives.order_unit_amount';
            // 製品売上金額
            selectSql += ',orders_receives.order_receive_amount';
            // 製品仕入金額
            selectSql += ',orders_receives.order_amount';
            // 製品粗利額
            selectSql += ',orders_receives.gross_profit';
            // 製品消費税
            selectSql += ',0';
            // 金額
            selectSql += ',0';
            // 製品分類コード
            selectSql += ',orders_receives.product_class_id';
            // 製品諸口区分
            selectSql += ',orders_receives.product_manual_input_class';
            // 在庫管理区分
            selectSql += ',orders_receives.inventory_control_class';
            // 取引先製品単価区分
            selectSql += ',orders_receives.client_amount_class';
          }
          // 売上課税区分
          selectSql += ',' + this.suppliers.taxClass;
          // 税額計算区分
          selectSql += ',' + this.suppliers.taxCalculationClass;
          // 税額端数計算処理区分
          selectSql += ',' + this.suppliers.taxFracClass;
          // 月次更新フラグ
          selectSql += ',0';
          // 月次年月
          selectSql += ',0';
          // 締日更新フラグ
          selectSql += ',0';
          // 締次年月
          selectSql += ',0';
          // 受注番号
          selectSql += ',orders_receives.order_receive_id';
          if (i == this.productList.length) {
            // 消費税行
            // 受注行番号
            selectSql += ',0';
            // 発注番号
            selectSql += ',0';
            // 発注行番号
            selectSql += ',0';
            // 注文番号
            selectSql += ',0';
          } else {
            // 上記以外（通常の製品行）
            // 受注行番号
            selectSql += ',orders_receives.order_receive_row';
            // 発注番号
            selectSql += ',orders_receives.order_id';
            // 発注行番号
            selectSql += ',orders_receives.order_row';
            // 注文番号
            selectSql += ',IfNull(orders.order_number,0)';
          }
          // 注文行番号
          selectSql += ',0';
          // 組合CD発注取引先
          selectSql += ',0';
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          selectSql += ',0';
          // 作成ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          // 更新ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_orders_receives AS orders_receives ';
          selectSql += 'LEFT JOIN t_orders AS orders ';
          selectSql += 'ON orders_receives.order_id = orders.order_id ';
          selectSql += 'AND orders_receives.order_row = orders.order_row ';
          selectSql += 'AND orders.is_deleted = 0 ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
          if (i == this.productList.length) {
            // 消費税行
            selectSql += 'AND orders_receives.order_receive_row = 1 ';
          } else {
            selectSql += 'AND orders_receives.order_receive_row = ' + this.productList[i].OrderReceiveRow + ' ';
          }
          selectSql += 'AND orders_receives.is_deleted = 0 ';
          //console.log(selectSql);
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ';
          }
          bulkInsertSqlSelect += selectSql;
          if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
            this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
            bulkInsertSqlSelect = '';
          }
        }
      }
      if (bulkInsertSqlSelect != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
    },
    /* 伝票番号取得 */
    async getBillingNo() {
      const functionName = 'getBillingNo';
      let billingNo = await getFormCounter(Const.CounterClass.billingNo, this.selectSalesOffice, Const.TransactionId.sales, this.loginId, MODULE_NAME, functionName);
      if (billingNo != null) {
        return billingNo;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /* 納品書PDF出力 */
    outputSalesDirectPdf: function() {
      // 得意先元帳PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        receivedOrderId: this.receivedOrderId,
        billingNo: 0,
        monthlyMonthYear: 0,
      };
      let route = this.$router.resolve({ name: 'SALES-DIRECT-NOUHIN', query: query});
      this.newWin.location = route.href
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    // 締日よりも過去日確認
    async confirmOldClosingDate() {
      // 売上計上日（伝票日付）が締日よりも過去日かチェック
      if (checkOldClosingDate(this.dateSalesRecord, this.suppliers.closingDate) == false) {
        // 売上計上日が締日よりも過去日
        let confirmMessage = '既に締日を過ぎた伝票です。請求済みと思われますが、問題はないですか？';
        let options = {
          title: '警告',
          bodyBgVariant: 'warning',
          headerBgVariant: 'warning',
          footerBgVariant: 'danger',
        };
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, options) == true) {
          return true;
        } else {
          return false;
        }
      } else {
        // 売上計上日が締日と同じ、または、未来
        return true;
      }
    },
  }
}
</script>
<style scoped>
</style>