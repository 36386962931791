<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 入金修正</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button-toolbar>
                  <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'仕入入力（在庫なし）に遷移する'" @click="clickInputBtn" class="m-0">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                  </b-button>&nbsp;&nbsp;
                  <b-button pill v-b-tooltip.hover.noninteractive.bottom="'入金一覧に戻る'" @click="cancel" class="btn-cancel m-0">
                    <span class="oi oi-circle-x"></span> キャンセル
                  </b-button>
                </b-button-toolbar>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- 伝票番号 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票番号"
                        label-for="billingNo"
                      >
                        <b-form-input id="billingNo" type="text" v-model="billingNo" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <validation-provider name="dateBillingDate" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="伝票日付"
                          label-for="dateBillingDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateBillingDate"
                                  name="dateBillingDate"
                                  v-model="dateBillingDate"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票種別 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票種別"
                      >
                        <b-input-group>
                          <b-input-group-text>{{ selectBillClassText }}</b-input-group-text>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先コード -->
                    <b-col lg="7">
                      <validation-provider name="suppliersCode" :rules="{numeric: true, min: 6, max: 6, required: true, client_sundries_class: suppliers.sundriesClass}" v-slot="{ classes,errors }">
                        <label for="suppliersCode">取引先コード<b-form-text class="text-muted d-inline-flex">(変更される場合、関連項目の入金一覧が自動でクリアされます)</b-form-text></label>
                        <b-row :class="classes">
                          <b-col lg="8">
                            <b-input-group>
                              <b-form-input
                                type="tel"
                                id="suppliersCode"
                                name="suppliersCode"
                                @input="searchClient(suppliers.code)"
                                maxlength="6"
                                v-model="suppliers.code"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == null" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group pl-3 pr-2">
                      <label class="form-label"><strong>取引先名: </strong>{{suppliers.name}}</label>
                    </div>
                    <!-- 締日 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>締日: </strong>{{suppliers.closingDate}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 郵便番号 + 取引先住所１ + 取引先住所２ -->
                    <div class="col-12 form-group pl-3 pl-3 pr-2">
                      <label class="form-label"><strong>取引先住所: </strong>{{suppliers.zipCode}} {{suppliers.address1}} {{suppliers.address2}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先部署１ + 取引先部署２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group pl-3 pr-2">
                      <label class="form-label"><strong>部署: </strong>{{suppliers.department1}} {{suppliers.department2}}</label>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>TEL: </strong>{{suppliers.tel}}</label>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>担当者: </strong>{{suppliers.personInCharge}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 入金 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                            :responsive="true"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;入金テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(Denomination)="data">
                              <validation-provider :rules="{required: true, offset_client_id: suppliers.offsetClientId}" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 145px;">
                                   <b-form-select
                                      name="denomination"
                                      size="sm" 
                                      class="px-1"
                                      v-model="data.item.Denomination"
                                      :options="denominationList"
                                      value-field="id"
                                      @change="changeDenomination(data.index); setFocus('tblMoney' + data.index);"
                                    />
                                  <small class="form-text text-muted">必須項目です。</small>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(Money)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 100px;">
                                  <b-form-input size="sm" class="px-1" type="number" :id="'tblMoney' + data.index" name="tblMoney" v-model.number="data.item.Money" @change="calcTotal()" />
                                  <small class="form-text text-muted">必須項目です。</small>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(Summary)="data">
                              <validation-provider rules="max:25" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 200px;">
                                  <b-form-input size="sm" class="px-1" type="text" id="place" name="tblSummary" v-model.lazy="data.item.Summary" />
                                  <small class="form-text text-muted">&nbsp;</small>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(AccountSupport)="data">
                              <validation-provider rules="required|between:100,999" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 100px;">
                                  <b-input-group>
                                    <b-form-input size="sm" class="px-1" type="number" name="tblAccountSupport" v-model.number="data.item.AccountSupport"/>-000
                                  </b-input-group>
                                  <small class="form-text text-muted">必須項目です。</small>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(SettlementScheduled)="data">
                              <validation-provider :rules="{required_if: data.item.Denomination==htmlConst.denomination.bills||data.item.Denomination==htmlConst.denomination.billsTax,oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group style="min-width: 160px;">
                                    <b-form-input
                                      id="input-daterange"
                                      size="sm" 
                                      class="px-1"
                                      v-model="data.item.SettlementScheduled"
                                      readonly
                                    ></b-form-input>
                                    <b-input-group-append>
                                      <b-button
                                        size="sm" 
                                        @click="openModal(data)"
                                        :disabled="data.item.Denomination!=htmlConst.denomination.bills&&data.item.Denomination!=htmlConst.denomination.billsTax">
                                        <span class="oi oi-calendar"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                  <small class="form-text text-muted">&nbsp;</small>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(Delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="inputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <b-modal id='datepickerModal' :val="postItem" size="sm" hide-footer hide-header centered no-close-on-esc>
      <div v-if="postItem">
        <b-calendar
          name="tblSettlementScheduled"
          v-model="postItem.item.SettlementScheduled"
          @input="$bvModal.hide('datepickerModal')"
          hide-header
        ></b-calendar>
      </div>
    </b-modal>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import { searchClientModal } from '@/assets/js/searchModal.js';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, formatDate, getControlMaster, formatDateCalc, getListValue, getNullStr, escapeQuote, executeSelectSql, dateConsistency, CreateInsertSql, CreateColRow, getFormCounter, executeTransactSqlList, isSystemEditable, isAfterMonthlyUpdate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices, list_m_clients } from '@/graphql/queries';

const MODULE_NAME = 'deposit-edit';

export default {
  name: 'DEPOSIT-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入金修正',
      loginId: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 伝票日付
      dateBillingDate: '',
      datePreBillingDate: '',
      // 伝票種別
      selectBillClass: null,
      selectBillClassText: '',
      // 取引先系
      suppliers: {
        closingDate: '',
        class: '',
        code: '',
        name: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        firstDigit: '',
        offsetClientId: 0,
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // 製品ダイアログ用
      dataIndex: 0,
      totalFee: 0,
      // 金種
      denominationList: [],
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 金種
        denomination: {
          // 値引
          discount: Const.denomination.discount,
          // その他
          other: Const.denomination.other,
          // 消費税
          tax: Const.denomination.tax,
          // 相殺消
          offsetTax: Const.denomination.offsetTax,
          // 手形消
          billsTax: Const.denomination.billsTax,
          // 現金
          cash: Const.denomination.cash,
          // 小切手
          check: Const.denomination.check,
          // 当座
          current: Const.denomination.current,
          // 普通
          normal: Const.denomination.normal,
          // 手形
          bills: Const.denomination.bills,
          // 決済
          settlement: Const.denomination.settlement,
          // 相殺
          offset: Const.denomination.offset,
          // 調整
          adjustment: Const.denomination.adjustment,
          // 手数料
          commission: Const.denomination.commission,
        },
      },
      // 伝票番号（INSERT時にカウンタテーブルから取得。最初は0を設定。）
      // 通常用と相殺用
      offsetBillingNo: 0,
      // 取引先系
      offsetSuppliers: {
        class: '',
        code: '',
        personInChargeId: '',
        firstDigit: '',
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // モーダルに渡す値
      postItem: '',
      // パラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
      parentKbn: 0,
    }
  },
  computed:{
    fields: function() {
      return [
        {
          key: 'Denomination',
          label: '金種',
        },
        {
          key: 'Money',
          label: '金額',
        },
        {
          key: 'Summary',
          label: '摘要',
        },
        {
          key: 'AccountSupport',
          label: '勘定－補助',
        },
        {
          key: 'SettlementScheduled',
          label: '決済予定',
        },
        {
          key: 'Delete',
          label: ' ',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 入力画面からの遷移の場合
        if (this.parentKbn == '1') {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        // 初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_offices)),
          getControlMaster(),
        ]);
        let staffListData = staffListResult.data.list_m_staffs;
        //console.log(staffListData);
        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.salesOffice.length; i++){
          //console.log(this.salesOffice[i].id);
          if(this.salesOffice[i].id == staffListData[0].office_id){
            this.selectSalesOffice = this.salesOffice[i].id;
            break;
          }
        }
        // 金種を取得
        this.denominationList.push({id: '', text: '選択してください。'});
        Array.prototype.push.apply(this.denominationList, Const.denominationList);
        // 現在処理年月
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 既存データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 初期化
      this.statusFlg.editFlg = true;
      this.productList = [];
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* カレンダーボタン押下時 */
    async openModal(item){
      this.postItem = item;
      this.$bvModal.show('datepickerModal');
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.client_class';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.sundries_class';
      selectSql += ',cumulative_transaction.client_id_first_digit';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',clients.offset_client_id';
      selectSql += ',cumulative_transaction.offset_billing_no';
      selectSql += ',cumulative_transaction.kind';
      selectSql += ',cumulative_transaction.summary';
      selectSql += ',cumulative_transaction.account_support';
      selectSql += ',cumulative_transaction.settlement_scheduled';
      selectSql += ',cumulative_transaction.amount';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 引数の月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 伝票日付
        this.dateBillingDate = result[0].billing_date;
        this.datePreBillingDate = result[0].billing_date;
        if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.dateBillingDate) == false) {
          // 伝票日付が処理年月よりも過去日付の場合
          this.statusFlg.editFlg = false;
          this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
        }
        // 伝票種別
        this.selectBillClass = result[0].bill_class;
        this.selectBillClassText = getListValue(Const.TraditionList, this.selectBillClass, 'id', 'text');
        // 伝票日付が処理年月よりも過去日付以外
        if (this.statusFlg.editFlg == true) {
          // 支払伝票の場合、編集不可
          if (result[0].bill_class == Const.Tradition.payment) {
            this.statusFlg.editFlg = false;
            this.alertWarning.push(DISP_MESSAGES.WARNING['2019']);
          }
        }
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        if (result[0].closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.firstDigit = result[0].client_id_first_digit;
        this.suppliers.offsetClientId = result[0].offset_client_id;
        // 相殺伝票No
        this.offsetBillingNo = result[0].offset_billing_no;
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].Denomination = result[i].kind;
          this.productList[i].Money = result[i].amount;
          this.productList[i].Summary = result[i].summary;
          this.productList[i].AccountSupport = result[i].account_support.substr(0, 3);
          this.productList[i].SettlementScheduled = result[i].settlement_scheduled;
        }
        this.calcTotal();
      }
    },
    /* 保存ボタンの押下 */
    async saveData(){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        // 入力チェックが完了した場合、保存確認ダイアログを表示
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      // データの登録(SQLの作成)
      if (await this.execSave() == true) {
        // 保存成功メッセージを表示
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.setInitData();
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 最初に現在のデータを全削除
      this.deleteCumulativeTransaction();
      // 金種で「7：相殺」「D：相殺消」のいずれかを選択していた場合
      if (this.checkOffset() == true) {
        // 相殺先取引先情報を設定（相殺取引先がマスタに存在しない場合は失敗）
        if (await this.setClientOffset() == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3006']);
          return false;
        }
        // 相殺伝票番号未作成
        if (this.offsetBillingNo == 0) {
          // 相殺伝票番号をカウンタテーブルから取得
          this.offsetBillingNo = await this.getBillingNo(Const.TransactionId.payment);
        }
      } else {
        this.offsetBillingNo = 0;
      }
      // 累積トランザクションの登録
      await this.insertCumulativeTransaction();
      // 累積トランザクションの登録（相殺分）
      await this.insertCumulativeTransactionOffset();
      // 会計システム連携更新SQL作成
      await this.updateAccountingSystemLinkage();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      retResult = true;

      return retResult;
    },
    /* 伝票番号取得 */
    async getBillingNo(transactionId) {
      const functionName = 'getBillingNo';
      let billingNo = await getFormCounter(Const.CounterClass.billingNo, this.selectSalesOffice, transactionId, this.loginId, MODULE_NAME, functionName);
      if (billingNo != null) {
        return billingNo;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /* 相殺チェック */
    checkOffset: function() {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].Denomination == Const.denomination.offset ||
        this.productList[i].Denomination == Const.denomination.offsetTax) {
          return true
        }
      }
      return false;
    },
    /* 相殺相手先情報設定 */
    async setClientOffset() {
      let where_clause = '';
      where_clause += 'AND client_class = ' + Const.ClientClass.supplier + ' ';
      where_clause += 'AND client_id = ' + this.suppliers.offsetClientId + ' ';
      let result = await API.graphql(graphqlOperation(list_m_clients, {where_clause: where_clause}));
      let resultData = result.data.list_m_clients;
      if (resultData != null && resultData.length > 0) {
        this.offsetSuppliers.class = resultData[0].client_class;
        this.offsetSuppliers.code = resultData[0].client_id;
        this.offsetSuppliers.personInChargeId = resultData[0].staff_id;
        this.offsetSuppliers.firstDigit = resultData[0].client_id_first_digit;
        // 相殺相手先の相殺相手先が異なる場合はエラーとする。
        if (this.suppliers.code == resultData[0].offset_client_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /* 累積トランザクション削除SQL作成 */
    deleteCumulativeTransaction: function() {
      //console.log('累積トランザクション削除SQL作成');
      // 累積トランザクション（入金）の削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      deleteSql += 'AND bill_class = \'' + Const.Tradition.deposit + '\' ';
      deleteSql += 'AND is_update_monthly = 0 ';
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);
      if (this.offsetBillingNo != 0) {
        // 累積トランザクション（支払）の削除SQL（相殺分）
        deleteSql = 'DELETE FROM ';
        deleteSql += 't_cumulative_transaction';
        deleteSql += ' WHERE ';
        deleteSql += 'billing_no = ' + this.offsetBillingNo + ' ';
        deleteSql += 'AND monthly_month_year = ' + this.monthlyMonthYear + ' ';
        deleteSql += 'AND transaction_id = \'' + Const.TransactionId.payment + '\' ';
        deleteSql += 'AND bill_class = \'' + Const.Tradition.deposit + '\' ';
        deleteSql += 'AND is_update_monthly = 0 ';
        //console.log(deleteSql);
        this.transactSqlList.push(deleteSql);
      }
    },
    /* 累積トランザクション登録SQL作成 */
    async insertCumulativeTransaction() {
      //console.log('累積トランザクション登録SQL作成');
      // CRUD処理
      let bulkInsertSql = '';
      for (let i = 0; i < this.productList.length; i++) {
        let colList = [];
        // ﾄﾗﾝｻﾞｸｼｮﾝID
        colList.push(CreateColRow('transaction_id', Const.TransactionId.deposit, 'VARCHAR'));
        // 伝票No
        colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
        // 行No
        colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
        // 伝票日付
        colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
        // 伝票種別
        colList.push(CreateColRow('bill_class', Const.Tradition.deposit, 'NUMBER'));
        // 解体組立区分
        colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
        // 移動先コード
        colList.push(CreateColRow('destination_id', 0, 'NUMBER'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', 0, 'NUMBER'));
        // 担当者コード
        colList.push(CreateColRow('staff_id', this.suppliers.personInChargeId, 'NUMBER'));
        // 取引先分類コード
        colList.push(CreateColRow('client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
        // 相殺伝票No
        colList.push(CreateColRow('offset_billing_no', this.offsetBillingNo, 'NUMBER'));
        // 製品コード
        colList.push(CreateColRow('product_id', 0, 'NUMBER'));
        // 製品数量
        colList.push(CreateColRow('product_quantity', 0, 'NUMBER'));
        // 製品売上単価
        colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
        // 製品仕入単価
        colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
        // 製品売上金額
        colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
        // 製品仕入金額
        colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
        // 製品粗利額
        colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
        // 製品消費税
        colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
        // 金種
        colList.push(CreateColRow('kind', await escapeQuote(this.productList[i].Denomination), 'VARCHAR'));
        // 摘要
        colList.push(CreateColRow('summary', await escapeQuote(this.productList[i].Summary), 'VARCHAR'));
        // 勘定一補助
        colList.push(CreateColRow('account_support', getNullStr(this.productList[i].AccountSupport) + '-000', 'VARCHAR'));
        // 決済予定日
        colList.push(CreateColRow('settlement_scheduled', this.productList[i].SettlementScheduled, 'DATE'));
        // 金額
        colList.push(CreateColRow('amount', this.productList[i].Money, 'NUMBER'));
        // 製品分類コード
        colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
        // 製品諸口区分
        colList.push(CreateColRow('product_mouth_class', 0, 'NUMBER'));
        // 在庫管理区分
        colList.push(CreateColRow('inventory_control_class', 0, 'NUMBER'));
        // 月次更新フラグ
        colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
        // 月次年月
        colList.push(CreateColRow('monthly_month_year', 0, 'NUMBER'));
        // 締日更新フラグ
        colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
        // 締次年月
        colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
        // 受注番号
        colList.push(CreateColRow('order_receive_id', 0, 'NUMBER'));
        // 受注行番号
        colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
        // 発注番号
        colList.push(CreateColRow('order_id', 0, 'NUMBER'));
        // 発注行番号
        colList.push(CreateColRow('order_row', 0, 'NUMBER'));
        // 注文番号
        colList.push(CreateColRow('order_number', 0, 'NUMBER'));
        // 注文行番号
        colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
        // 組合CD発注取引先
        colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
        // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
        colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    /* 累積トランザクション登録SQL作成（相殺用） */
    async insertCumulativeTransactionOffset() {
      //console.log('累積トランザクション登録SQL作成（相殺用）');
      // CRUD処理
      let bulkInsertSql = '';
      let billingRow = 1;
      for (let i = 0; i < this.productList.length; i++) {
        // 金種が「7：相殺」「D：相殺消」
        if (this.productList[i].Denomination == Const.denomination.offset ||
        this.productList[i].Denomination == Const.denomination.offsetTax) {
          let colList = [];
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', Const.TransactionId.payment, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', this.offsetBillingNo, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', billingRow, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', Const.Tradition.deposit, 'NUMBER'));
          // 解体組立区分
          colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
          // 移動先コード
          colList.push(CreateColRow('destination_id', 0, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', this.offsetSuppliers.class, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', this.offsetSuppliers.code, 'NUMBER'));
          // 現場コード
          colList.push(CreateColRow('site_id', 0, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', this.offsetSuppliers.personInChargeId, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', this.offsetSuppliers.firstDigit, 'NUMBER'));
          // 相殺伝票No
          colList.push(CreateColRow('offset_billing_no', this.billingNo, 'NUMBER'));
          // 製品コード
          colList.push(CreateColRow('product_id', 0, 'NUMBER'));
          // 製品数量
          colList.push(CreateColRow('product_quantity', 0, 'NUMBER'));
          // 製品売上単価
          colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
          // 製品仕入単価
          colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
          // 製品売上金額
          colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
          // 製品仕入金額
          colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
          // 製品粗利額
          colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
          // 製品消費税
          colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
          // 金種
          colList.push(CreateColRow('kind', await escapeQuote(this.productList[i].Denomination), 'VARCHAR'));
          // 摘要
          colList.push(CreateColRow('summary', await escapeQuote(this.productList[i].Summary), 'VARCHAR'));
          // 勘定一補助
          colList.push(CreateColRow('account_support', getNullStr(this.productList[i].AccountSupport) + '-000', 'VARCHAR'));
          // 決済予定日
          colList.push(CreateColRow('settlement_scheduled', this.productList[i].SettlementScheduled, 'DATE'));
          // 金額
          colList.push(CreateColRow('amount', this.productList[i].Money, 'NUMBER'));
          // 製品分類コード
          colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
          // 製品諸口区分
          colList.push(CreateColRow('product_mouth_class', 0, 'NUMBER'));
          // 在庫管理区分
          colList.push(CreateColRow('inventory_control_class', 0, 'NUMBER'));
          // 月次更新フラグ
          colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
          // 月次年月
          colList.push(CreateColRow('monthly_month_year', 0, 'NUMBER'));
          // 締日更新フラグ
          colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
          // 締次年月
          colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', 0, 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
          // 発注番号
          colList.push(CreateColRow('order_id', 0, 'NUMBER'));
          // 発注行番号
          colList.push(CreateColRow('order_row', 0, 'NUMBER'));
          // 注文番号
          colList.push(CreateColRow('order_number', 0, 'NUMBER'));
          // 注文行番号
          colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
          // 組合CD発注取引先
          colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            this.transactSqlList.push(bulkInsertSql);
            bulkInsertSql = '';
          }
          billingRow++;
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // 会計システム連携更新SQL作成
    async updateAccountingSystemLinkage() {
      //console.log('会計システム連携更新SQL作成');
      // 最初にdelete
      let where_clause = ' WHERE ';
      where_clause += 'transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      where_clause += 'AND billing_no = ' + this.billingNo + ' ';
      where_clause += 'AND billing_date = \'' + this.datePreBillingDate + '\' ';
      this.transactSqlList.push('DELETE FROM t_accounting_system_linkage' + where_clause);
      // バルクINSERT
      let bulkInsertSql = '';
      for (let i = 0; i < this.productList.length; i++) {
        let colList = [];
        // ﾄﾗﾝｻﾞｸｼｮﾝID
        colList.push(CreateColRow('transaction_id', Const.TransactionId.deposit, 'VARCHAR'));
        // 伝票No
        colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
        // 行No
        colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
        // 伝票日付
        colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
        // 勘定項目取得
        let pcaAccountKindDeposit = null;
        pcaAccountKindDeposit = this.getPcaAccountDeposit(getNullStr(this.productList[i].AccountSupport), this.productList[i].Denomination, getNullStr(this.suppliers.firstDigit));
        // 借方勘定科目
        colList.push(CreateColRow('debit_account', this.productList[i].AccountSupport, 'NUMBER'));
        // 借方税区分
        colList.push(CreateColRow('debit_tax_class', pcaAccountKindDeposit.debitTaxClass, 'VARCHAR'));
        // 借方金額
        colList.push(CreateColRow('debit_amount', this.productList[i].Money, 'NUMBER'));
        // 貸方勘定科目
        colList.push(CreateColRow('credit_account', pcaAccountKindDeposit.creditAccount, 'NUMBER'));
        // 貸方税区分
        colList.push(CreateColRow('credit_tax_class', pcaAccountKindDeposit.creditTaxClass, 'VARCHAR'));
        // 貸方金額
        colList.push(CreateColRow('credit_amount', this.productList[i].Money, 'NUMBER'));
        // 摘要
        colList.push(CreateColRow('summary', await escapeQuote(getNullStr(this.suppliers.name)) + '　' + await escapeQuote(getNullStr(this.suppliers.department1)) + '　' + await escapeQuote(getNullStr(this.productList[i].Summary)), 'VARCHAR'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_accounting_system_linkage (' + CreateInsertSql(colList, 'col', 't_accounting_system_linkage') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_accounting_system_linkage') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // PCA勘定情報取得（入金）
    getPcaAccountDeposit: function(account, kind, clientIdFirstDigit) {
      //console.log('account:' + account);
      //console.log('kind:' + kind);
      //console.log('clientIdFirstDigit:' + clientIdFirstDigit);
      let office = Number(clientIdFirstDigit) >= Number(Const.PcaOfficeClass.yokohama) ? Const.PcaOfficeClass.yokohama: Const.PcaOfficeClass.else;
      let data = Const.PcaAccountKindDepositList.find(el => el.account == account && el.kind == kind && (el.office == office || el.office == '*'));
      if (data != undefined) {
        return data;
      }
      let taxClass = '';
      if (kind == Const.denomination.tax || kind == Const.denomination.offsetTax || kind == Const.denomination.billsTax) {
        // 消費税
        taxClass = Const.PcaKindClass.tax;
      } else {
        // 消費税以外
        taxClass = Const.PcaKindClass.noTax;
      }
      data = Const.PcaAccountKindDepositList.find(el => el.account == account && el.kind == taxClass && el.office == '*');
      if (data != undefined) {
        return data;
      }
      data = Const.PcaAccountKindDepositList.find(el => el.account == '*' && el.kind == taxClass && (el.office == office || el.office == '*'));
      return data;
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'DEPOSIT-LIST'});
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      this.propClientClass = Const.ClientClass.customer;
      const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          this.closeClientSearchModal({detail:{}});
        }
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.propClientClass = Const.ClientClass.customer;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        // 取引先（得意先）から呼出
        if (clientItems.detail.closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + clientItems.detail.closing_date).slice(-2));
        }
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.address1 = clientItems.detail.address_1;
        this.suppliers.address2 = clientItems.detail.address_2;
        this.suppliers.department1 = clientItems.detail.department_1;
        this.suppliers.department2 = clientItems.detail.department_2;
        this.suppliers.tel = clientItems.detail.phone_number;
        this.suppliers.personInChargeId = clientItems.detail.staff_id;
        this.suppliers.personInCharge = clientItems.detail.staff_id + '：' + clientItems.detail.staff_name_kanji;
        this.suppliers.zipCode = clientItems.detail.zip_code;
        this.suppliers.sundriesClass = clientItems.detail.sundries_class;
        this.suppliers.firstDigit = clientItems.detail.client_id_first_digit;
        this.suppliers.offsetClientId = clientItems.detail.offset_client_id;
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 金種変更イベント */
    async changeDenomination(index) {
      //console.log(index);
      // 金種が「5：手形」「E：手形消」以外の場合
      if (this.productList[index].Denomination != Const.denomination.bills &&
      this.productList[index].Denomination != Const.denomination.billsTax) {
        // 決算予定日をクリア
        this.productList[index].SettlementScheduled = '';
      }
    },
    // 合計金額、合計仕入単価、粗利、消費税額計算
    calcTotal: function(){
      this.totalFee = 0;
      for(let i = 0; i < this.productList.length;i++){
        this.totalFee += Number(this.productList[i].Money);
      }
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        Denomination: '',
        Money: 0,
        Summary: '',
        AccountSupport: '',
        SettlementScheduled: '',
      };
      this.productList.push(newProduct);
    },
    // テーブル行削除イベント
    deleteBtn:function(rowNum){
      //console.log(rowNum);
      if(this.productList.length > 1){
        this.productList.splice(rowNum,1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    // テーブル初期化処理
    initProductTable: function() {
      this.productList = [];
      this.addTableRow();
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].Denomination) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'DEPOSIT-INPUT' });
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>
<style scoped>
</style>