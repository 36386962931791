<template>
  <!-- 支払残高保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 支払残高保守編集</strong></h5>
                <router-link to="/payments-balances-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <!-- 支払年月 -->
                <div class="row col-12 form-group">
                  <h5>
                    <strong>
                    支払年月: &nbsp;{{paymentsBalancesObj.payment_month_year | month_year}}
                    </strong>
                  </h5>
                </div>
                <!-- 仕入先コード -->
                <div class="row col-12 form-group">
                  <h5>
                    <strong>
                    仕入先コード: &nbsp;
                      {{paymentsBalancesObj.client_id}}&nbsp;&nbsp;&nbsp;&nbsp; 
                      {{paymentsBalancesObj.client_name_kanji}}
                    </strong>
                  </h5>
                </div>
                <b-table-simple bordered responsive hover small class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                  <b-tbody>
                    <b-tr>
                      <b-th>前回支払税抜残高</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.pre_no_tax_payment_balances" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>前回支払消費税残高</b-th>
                      <b-td>
                        <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.pre_tax_payment_balances" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日買掛仕入</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_payable" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日支払</b-th>
                      <b-td>
                         <validation-provider rules="required|numeric|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_payments" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日消費税支払</b-th>
                      <b-td>
                         <validation-provider rules="required|numeric|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_tax_payments" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日一括消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_bulk_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日伝票消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_bill_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日通常消費税課税小計</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_normal_tax_subtotal" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日通常消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_normal_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日軽減消費税課税小計</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_light_tax_subtotal" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日軽減消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_light_tax" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日繰越税抜額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_forward_no_tax_payments" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                      <b-th>締日繰越消費税額</b-th>
                      <b-td>
                         <validation-provider rules="required|integer|max:12" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" size="sm" style="min-width:140px" v-model="paymentsBalancesObj.closing_date_forward_tax_payments" maxlength="12"/>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </validation-observer>
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="save()"><span class="oi oi-pencil"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, formatDate, CreateColRow, getUserCol, CreateUpdateSql, getControlMaster, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'payments-balances-edit';

export default {
  name: 'PAYMENTS-BALANCES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '支払残高保守編集',
      // 支払残高対象
      paymentsBalancesObj: {},
      // ユーザ名
      username: this.$store.getters.user.username,
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],

    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 支払年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    this.$store.commit('setLoading', true);
    try {
      const control = await getControlMaster()
      if (Number(control.process_month_year) > Number(this.$route.query.payment_month_year)) {
        this.$router.push({ 
          name: 'PAYMENTS-BALANCES-INQUIRY', 
          query: {
            payment_month_year: this.$route.query.payment_month_year, 
            client_id: this.$route.query.client_id,
          },
          params: { successMessages: ['現在処理年月よりも過去のデータは編集不可です。'] }  
        });
      }
    } catch (error) {
      await addOperationLogs('Error', MODULE_NAME, 'getControlMaster', {}, error);
      this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
    }
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} payment_month_year - 支払年月
     * @param {String} client_id - 仕入先コード
     */
    async fetchData({payment_month_year, client_id}) {
      client_id = Number(client_id)
      payment_month_year = Number(payment_month_year)
      // 支払残高マスタのデータを請求
      let paymentsBalances = await this.searchPaymentsBalances(client_id, payment_month_year)

      // 支払残高データをセット
      this.paymentsBalancesObj = {...paymentsBalances}
    },
    /**
     * 支払残高マスタを検索します。
     * @param {Int} client_id -  仕入先コード
     * @param {Int} month_year - 処理年月
     * @returns {Object} 支払残高マスタデータ
     */
    async searchPaymentsBalances(client_id, month_year) {
      const functionName = 'searchPaymentsBalances';

      let result = null;
      let sqls = `SELECT * FROM t_payments_balances TPB LEFT JOIN m_clients MC USING(client_id) 
        WHERE payment_month_year = ${month_year} AND client_id = ${client_id} AND MC.client_class = 2
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sqls] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },

    /**
     * 保存ボタンの押下
     */
    async save() {
      this.paymentsBalancesObj.inventory_count_day = formatDate(this.paymentsBalancesObj.inventory_count_day)
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.confirmMessage.push('注意：繰越されたデータは保存されません。');
      //console.log(this.confirmMessage);
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PAYMENTS-BALANCES-INQUIRY', 
              query: { 
                payment_month_year: this.paymentsBalancesObj.payment_month_year, 
                client_id: this.paymentsBalancesObj.client_id,
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      const colList = [];
      // 前回支払税抜残高
      colList.push(CreateColRow('pre_no_tax_payment_balances', Number(this.paymentsBalancesObj.pre_no_tax_payment_balances), 'INT'));
      // 前回支払消費税残高
      colList.push(CreateColRow('pre_tax_payment_balances', Number(this.paymentsBalancesObj.pre_tax_payment_balances), 'INT'));
      // 締日買掛仕入
      colList.push(CreateColRow('closing_date_payable', Number(this.paymentsBalancesObj.closing_date_payable), 'INT'));
      // 締日支払
      colList.push(CreateColRow('closing_date_payments', Number(this.paymentsBalancesObj.closing_date_payments), 'INT'));
      // 締日消費税支払
      colList.push(CreateColRow('closing_date_tax_payments', Number(this.paymentsBalancesObj.closing_date_tax_payments), 'INT'));
      // 締日一括消費税額
      colList.push(CreateColRow('closing_date_bulk_tax', Number(this.paymentsBalancesObj.closing_date_bulk_tax), 'INT'));
      // 締日伝票消費税額
      colList.push(CreateColRow('closing_date_bill_tax', Number(this.paymentsBalancesObj.closing_date_bill_tax), 'INT'));
      // 締日消費税額
      colList.push(CreateColRow('closing_date_tax', Number(this.paymentsBalancesObj.closing_date_tax), 'INT'));
      // 締日繰越税抜額
      colList.push(CreateColRow('closing_date_forward_no_tax_payments', Number(this.paymentsBalancesObj.closing_date_forward_no_tax_payments), 'INT'));
      // 締日繰越消費税額
      colList.push(CreateColRow('closing_date_forward_tax_payments', Number(this.paymentsBalancesObj.closing_date_forward_tax_payments), 'INT'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('closing_date_normal_tax_subtotal', Number(this.paymentsBalancesObj.closing_date_normal_tax_subtotal), 'INT'));
      // 締日通常消費税額
      colList.push(CreateColRow('closing_date_normal_tax', Number(this.paymentsBalancesObj.closing_date_normal_tax), 'INT'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('closing_date_light_tax_subtotal', Number(this.paymentsBalancesObj.closing_date_light_tax_subtotal), 'INT'));
      // 締日軽減消費税額
      colList.push(CreateColRow('closing_date_light_tax', Number(this.paymentsBalancesObj.closing_date_light_tax), 'INT'));

      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 't_payments_balances')} 
                    WHERE 
                    payment_month_year = ${this.paymentsBalancesObj.payment_month_year} 
                    AND client_id = ${this.paymentsBalancesObj.client_id}
                    AND payment_start_date IS NULL`;
      const SQLs = [sql];
      let result = null;

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
