<template>
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 pt-4 min-vh-85">
      <b-row>
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出力範囲指定</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <b-card>
            <b-card-header v-if="isAlertMessages">
              <b-alert show variant="danger" class="mt-2" v-if="alertDangerMessages.length > 0">
                <ul v-for="(message,index) in alertDangerMessages" :key="index" style="list-style: none;">
                  <li>{{message}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body class="pb-0">
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); clickOutputPdf();" id="InputForm" class="form-horizontal">
                  <b-row>
                    <b-col>
                      <b-form-group description="対象の営業所を選択してください。" label="営業所" label-for="selectSalesOffice" label-cols-sm="2" label-cols-xl="1">
                        <b-form-select id="selectSalesOffice" v-model="searchConditions.selectSalesOffice" :options="searchConditions.salesOffice" value-field="id" size="sm" style="width: 350px;"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
            <!-- PDF出力ボタン -->
            <b-card-footer>
              <b-row class="justify-content-sm-center">
                <b-col lg="4">
                  <b-button class="mr-2" pill variant="success" v-b-tooltip.hover title="指定された営業所の棚卸表のPDF出力を行います。" type="submit" form="InputForm">
                    <span class="oi oi-document"></span> 棚卸表PDF出力
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
     <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, addOperationLogs, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices } from '@/graphql/queries';

// モジュール名
const MODULE_NAME = 'inventory-print';

export default {
  name: 'INVENTORY-PRINT',
  components: {
    Header,
    Footer,
    CONFIRM
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '棚卸表印刷',
      // 営業所コード
      office_id: '',
      // Dangerアラートメッセージ
      alertDangerMessages: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOffice: [],
      },
      // 確認ダイアログ用
      confirmMessage: [],
    };
  },
  computed: {
    /**
     * 表示するアラートメッセージかあるかどうかを確認します。
     * @returns {Boolean} メッセージがある場合はtrue、無い場合はfalse
     */
    isAlertMessages() {
      return this.alertDangerMessages.length > 0;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
      try {
        let user = store.getters.user;
        this.loginId = user.username;
        console.log('ログイン情報');
        console.log(this.loginId);
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        let staffListResult = await API.graphql(graphqlOperation(list_m_staffs,condition));
        let staffListData = staffListResult.data.list_m_staffs;
        console.log(staffListData);
        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        console.log(officeListData);
        // 営業所データ設定
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
          console.log(this.searchConditions.salesOffice[i].id);
          if(this.searchConditions.salesOffice[i].id == staffListData[0].office_id){
            this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
            break;
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* PDF出力ボタンの押下 */
    async clickOutputPdf() {
      console.log(this.searchConditions);
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で実行します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認modal */
    async confirmSave() {
      const functionName = 'confirmSave';

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDangerMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      // PDF出力処理
      await this.clickOutputSalesSlipPdf();
    },
    /* 納品書PDF出力ボタンの押下 */
    async clickOutputSalesSlipPdf() {
      // 得意先元帳PDF出力処理
      console.log('納品書PDF出力処理');
      let query = {
        selectSalesOffice: this.searchConditions.selectSalesOffice,
        startIndex: -1,
      };
      let route = this.$router.resolve({ name: 'INVENTORY-PRINT-SHEET', query: query});
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertDangerMessages = [];
    },
  }
}
</script>