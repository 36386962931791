<template>
    <div>
        <!-- ●●●上部メニュー●●● -->
        <Header :type="menu_type" :title="title" />
        <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> 月次処理メニュー</h5>
                            </div>
                        </div>
                    </div>

                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['1']" @shortkey="executeShortcut('accounts-receivable-supply-list')">
                            <router-link to="/accounts-receivable-supply-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="1" id="menu1-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">得意先別売掛実績表</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>得意先別売掛実績の検索、得意先別売掛実績表の帳票出力を行います。</li>
                                        <li>得意先別売掛実績の検索結果から売掛実績・残高の保守を行うことができます。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['2']" @shortkey="executeShortcut('accounts-payable-supply-list')">
                            <router-link to="/accounts-payable-supply-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="2" id="menu1-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">仕入先別買掛実績表</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>仕入先別買掛実績の検索、仕入先別買掛実績表の帳票出力を行います。</li>
                                        <li>仕入先別買掛実績の検索結果から買掛実績・残高の保守を行うことができます。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['3']" @shortkey="executeShortcut('purchase-unit-price-list')">
                            <router-link to="/purchase-unit-price-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="3" id="menu1-3">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">仕入単価修正</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>伝票の仕入単価が未入力の場合、修正を行います</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['4']" @shortkey="executeShortcut('monthly-update')">
                            <router-link to="/monthly-update"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="4" id="menu1-4">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">月次更新</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>月毎の得意先別売掛実績・残高、仕入先別買掛実績・残高、担当者別実績、在庫実績、製品実績の集計と累積トランザクションの締めを行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-SALES-MONTHLY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '月次処理メニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>