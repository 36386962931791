<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="入金の新規登録" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-1" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-1" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-1" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-1">
                        <!-- 営業所入力欄 -->
                        <b-col lg="3">
                          <b-form-group
                            label="営業所"
                            label-for="salesOffice"
                          >
                            <b-form-select
                              id="salesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                              text-field="name"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <label for="billingNo">伝票番号<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="billingNo" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingDateStart" :rules="{dateConsistency:searchConditions.billingDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="伝票日付"
                              label-for="datepicker"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="billingDateStart" class="form-control" v-model="searchConditions.billingDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="billingDateEnd" class="form-control" v-model="searchConditions.billingDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.billingDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                            <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、伝票番号か取引先コードを指定してください。</b-form-text>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <label for="customerCode">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="9">
                          <label for="customerName">取引先名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します)</b-form-text></label>
                          <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <!-- 担当者コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="personInCharge" rules="numeric" v-slot="{ classes,errors }">
                            <label for="personInCharge">担当者コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                            <b-col lg="12" :class="classes" class="pl-0">
                              <b-form-input type="text" id="personInCharge" name="personInCharge" v-model="searchConditions.personInCharge"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #cell(CustomerName)="data">
                <div style="min-width:220px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <div v-show="!printStatus">
                  <!-- 修正ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された入金入力の修正を行います。'" @click="clickEditBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear)" class="mr-1">
                    <span class="oi oi-pencil"></span> 修正
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'指定された入金入力の削除を行います。'" @click="clearAlert(); clickDeleteBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear, operation.item.OffsetBillingNo, operation.item.BillingDate)" class="mr-1" :disabled="operation.item.EditFlg==false||operation.item.BillClass==htmlConst.Tradition.payment">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-0">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, formatDate, formatCurDate, getListValue, convertSqlLikeSpecialChar, addOperationLogs, getControlMaster, dateConsistency, executeSelectSql, executeTransactSqlList, isSystemEditable, isAfterMonthlyUpdate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices } from '@/graphql/queries';

const MODULE_NAME = 'deposit-list';

export default {
  name: 'DEPOSIT-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入金一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        billingNo: '',
        billingDateStart: '',
        billingDateEnd: '',
        personInCharge: '',
        clientCode: '',
        clientName: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 削除確認ダイアログ用
      confirmMessage: [],
      delData: {},
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種別
        Tradition: {
          // 入金
          deposit: Const.Tradition.deposit,
          // 支払
          payment: Const.Tradition.payment,
        },
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'BillingDate',
          label: '伝票日付',
          sortable: true,
        },
        {
          key: 'BillingNo',
          label: '伝票番号',
          sortable: true,
        },
        {
          key: 'BillClassText',
          label: '伝票種別',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所',
          sortable: true,
        },
        {
          key: 'StaffId',
          label: '担当者コード',
          sortable: true,
        },
        {
          key: 'PersonInCharge',
          label: '担当者名',
          sortable: true,
        },
        {
          key: 'CustomerCode',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'DEPOSIT-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_offices)),
        ]);
        let staffListData = staffListResult.data.list_m_staffs;
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);

        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 伝票日付開始に現在日を設定
        this.searchConditions.billingDateStart = formatCurDate('YYYY-MM-DD');
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      this.itemList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null) {
        await this.setResult(resultData);
        if(resultData.length >= 1000) {
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',cumulative_transaction.monthly_month_year';
      selectSql += ',cumulative_transaction.offset_billing_no';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON cumulative_transaction.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND cumulative_transaction.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 伝票番号
      if (this.searchConditions.billingNo != '') {
        selectSql += 'AND cumulative_transaction.billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 伝票日付
      if (this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd == '') {
        selectSql += 'AND cumulative_transaction.billing_date >= ' + '\'' + this.searchConditions.billingDateStart + '\' ';
      } else if(this.searchConditions.billingDateStart == '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date <= ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      } else if(this.searchConditions.billingDateStart != '' && this.searchConditions.billingDateEnd != '') {
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN ' + '\'' + this.searchConditions.billingDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.billingDateEnd + '\' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
        selectSql += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 担当者コード
      if (!(this.searchConditions.personInCharge === '')) {
        selectSql += 'AND cumulative_transaction.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.billing_no,cumulative_transaction.monthly_month_year';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_row';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      // コントロールマスタの現在処理年月の初日を作成
      let controlData = await getControlMaster();
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      let processDate = ('000000' + controlData.process_month_year).slice(-6) + '01';
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          BillingDate: formatDate(result[i].billing_date),
          BillingNo: result[i].billing_no,
          BillClass: result[i].bill_class,
          BillClassText: getListValue(Const.TraditionList, result[i].bill_class, 'id', 'text'),
          SalesOffice: result[i].office_name_kanji,
          StaffId: result[i].staff_id,
          PersonInCharge: result[i].staff_name_kanji,
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          MonthlyMonthYear: result[i].monthly_month_year,
          OffsetBillingNo: result[i].offset_billing_no,
          Operation: result[i],
        };
        // 日付は現在処理年月の初日と同じ、または、未来の場合、編集可
        searchResult.EditFlg = dateConsistency(processDate, searchResult.BillingDate);
        this.itemList.push(searchResult);
      }
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      this.$router.push({ name: 'DEPOSIT-INPUT' });
    },
    /* 修正ボタン押下時 */
    async clickEditBtn(billingNo, monthlyMonthYear) {
      //console.log('修正');
      //console.log(billingNo);
      //console.log(monthlyMonthYear);
      let route = this.$router.resolve({ name: 'DEPOSIT-EDIT', query: { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear, parentKbn: 0 } });
      window.open(route.href, '_blank');
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(billingNo, monthlyMonthYear, offsetBillingNo, billingDate) {
      //console.log('削除');
      this.delData = { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear, offsetBillingNo: offsetBillingNo, billingDate: billingDate };
      this.confirmMessage = [];
      this.confirmMessage.push('選択されたデータを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      let sqlList = [];
      // 累積トランザクション（入金）の削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.delData.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.delData.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      deleteSql += 'AND bill_class = ' + Const.Tradition.deposit + ' ';
      deleteSql += 'AND is_update_monthly = 0 ';
      //console.log(deleteSql);
      sqlList.push(deleteSql);
      if (this.delData.offsetBillingNo != 0) {
        // 累積トランザクション（支払）の削除SQL（相殺分）
        deleteSql = 'DELETE FROM ';
        deleteSql += 't_cumulative_transaction';
        deleteSql += ' WHERE ';
        deleteSql += 'billing_no = ' + this.delData.offsetBillingNo + ' ';
        deleteSql += 'AND monthly_month_year = ' + this.delData.monthlyMonthYear + ' ';
        deleteSql += 'AND transaction_id = \'' + Const.TransactionId.payment + '\' ';
        deleteSql += 'AND bill_class = ' + Const.Tradition.deposit + ' ';
        deleteSql += 'AND is_update_monthly = 0 ';
        //console.log(deleteSql);
        sqlList.push(deleteSql);
      }
      // 会計システム連携（入金）の削除SQL
      deleteSql = 'DELETE FROM t_accounting_system_linkage';
      deleteSql += ' WHERE ';
      deleteSql += 'transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      deleteSql += 'AND billing_no = ' + this.delData.billingNo + ' ';
      deleteSql += 'AND billing_date = \'' + this.delData.billingDate + '\' ';
      //console.log(deleteSql);
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>