<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page">
      <TemplateOrderNormal :id="constData.chouhyouId + chouhyou.page" />
    </div>
  </div>
</template>
<script>
import TemplateOrderNormal from '@/assets/svg/order_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, formatDate, executeSelectSql, splitMultiRowString, addOperationLogs, getNullStr } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { list_m_offices } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'order';

export default {
  name: 'ORDER',
  /* コンポーネント */
  components: {
    TemplateOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 20,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '発注書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      stampFlg: false,
      colStampCX: 0,
      colStampCY: 0,
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],
      // 営業所情報
      officeList: [{
        keyNo:'',
        officeNameKanji: '',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      officeId: null,
      // 顧客コードに印字
      suppliersKokyakuId: '',
      // アラート
      alertDanger: [],
      // パラメータ
      orderId: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.orderId = this.$route.query.orderId;
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '発注書_' + this.orderId;
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 指定された発注データを設定
        await this.setOrder();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listChouhyou = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 発注データ設定 */
    async setOrder() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataOrder = await executeSelectSql(selectSql);
      //console.log(dataOrder);
      if(dataOrder != null &&
      dataOrder.length > 0){
        // 営業所コード
        this.officeId = dataOrder[0].office_id;
        // 営業所情報取得
        await this.getOfficesData();
        // 仕入先顧客情報を取得
        await this.getSuppliersKokyakuData(dataOrder[0].client_id);
        // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
        await this.initListChouhyou(dataOrder.length + this.addProductCnt);
        // 共通の置換文字列設定
        await this.createReplacementsCommon(dataOrder);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(dataOrder);
        // 作成した置換文字データをSVGファイルに設定
        await this.setChouhyou();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 検索SELECT文字列作成 */
    async makeSelectSql(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' estimate_orders.order_date';
      selectSql += ',estimate_orders.order_id';
      selectSql += ',estimate_orders.office_id';
      selectSql += ',estimate_orders.client_id';
      selectSql += ',estimate_orders.client_print_name';
      selectSql += ',estimate_orders.client_staff_id';
      selectSql += ',estimate_orders.client_staff_name_kanji';
      selectSql += ',estimate_orders.created_staff_name_stamp';
      selectSql += ',estimate_orders.created_staff_id';
      selectSql += ',estimate_orders.created_staff_name_kanji';
      selectSql += ',estimate_orders.client_staff_name_stamp';
      selectSql += ',estimate_orders.product_name_kanji';
      selectSql += ',estimate_orders.product_quantity';
      selectSql += ',estimate_orders.product_unit';
      selectSql += ',estimate_orders.product_note';
      selectSql += ',products_details.supplier_product_id';
      selectSql += ',estimate_orders.summary';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_estimate_orders AS estimate_orders ';
      selectSql += 'LEFT JOIN m_products_details AS products_details ';
      selectSql += 'ON estimate_orders.office_id = products_details.office_id ';
      selectSql += 'AND estimate_orders.product_id = products_details.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の発注番号で絞り込む
      selectSql += 'estimate_orders.order_id = ' + this.orderId + ' ';
      // 製品一覧の行番号でソート
      selectSql += 'ORDER BY estimate_orders.order_row';

      return selectSql;
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      const whereClause = ' AND office_id = ' + this.officeId + ' ';

      let officeListResult = await API.graphql(graphqlOperation(list_m_offices, { where_clause: whereClause }));
      let officeListData = officeListResult.data.list_m_offices;
      //console.log(officeListData);
      this.officeList[0].keyNo = '1';
      this.officeList[0].officeNameKanji = officeListData[0].office_name_kanji;
      this.officeList[0].zipCode = '〒' + getNullStr(officeListData[0].zip_code);
      this.officeList[0].address = getNullStr(officeListData[0].address_1) + getNullStr(officeListData[0].address_2);
      this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[0].phone_number);
      this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[0].fax_number);
    },
    /* 仕入先顧客情報を取得 */
    async getSuppliersKokyakuData(supplierId) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' offices.office_name_kanji';
      selectSql += ',suppliers_offices.supplier_control_office_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_suppliers_offices AS suppliers_offices ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON suppliers_offices.office_id = offices.office_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の仕入先コードで絞り込む
      selectSql += 'suppliers_offices.supplier_id = ' + supplierId + ' ';
      // 営業所コードで絞り込む
      selectSql += 'AND suppliers_offices.office_id = ' + this.officeId + ' ';
      // 営業所コードでソート
      selectSql += 'ORDER BY suppliers_offices.office_id';
      //console.log(selectSql);
      // 仕入先毎の営業所情報取得
      let dataResult = await executeSelectSql(selectSql);
      this.suppliersKokyakuId = '';
      for(let i = 0; i < dataResult.length; i++){
        if (i != 0) {
          this.suppliersKokyakuId += '、';
        }
        this.suppliersKokyakuId += getNullStr(dataResult[i].office_name_kanji);
        this.suppliersKokyakuId += getNullStr(dataResult[i].supplier_control_office_id);
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      //console.log('initListChouhyou');
      let productCntNokori = productCnt;
      let page = 1;
      while (productCntNokori > 0) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
        this.listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.cntNormal;
        page++;
      }
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      //console.log('createReplacementsCommon');
      // 発注日付
      this.replacementsCommon.push({key: '%発注日付%', value: formatDate(result[0].order_date), textAnchor: 'end', textLength: 251, chkWidth: false});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      //console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // 発注番号
        replacements.push({key: '%発注番%', value: result[0].order_id, textAnchor: 'end', textLength: 190, chkWidth: true});
        // 営業
        replacements.push({key: '%営業%', value: this.officeList[0].officeNameKanji, textAnchor: 'start', textLength: 160, chkWidth: true});
        // ページ番号
        replacements.push({key: '%P%', value: this.listChouhyou[i].page + ' / ' + this.listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
        // タイトル
        replacements.push({key: '%タイトル%', value: '御 発 注 書' + '（' + this.officeList[0].officeNameKanji + '）', textAnchor: 'middle', textLength: 925, chkWidth: true});
        // 取引先名
        replacements.push({key: '%取引先名%', value: result[0].client_print_name, textAnchor: 'start', textLength: 769, chkWidth: true});
        // 営業所情報
        await this.setOfficesData(replacements);
        // 担当者名（作成担当者は取引担当者と異なる場合に括弧付きで表示）
        let staffName = getNullStr(result[0].client_staff_name_kanji).trim();
        if (result[0].client_staff_id != result[0].created_staff_id) {
          staffName += getNullStr(result[0].created_staff_name_kanji).trim() == '' ? '' : '（' + result[0].created_staff_name_kanji + '）';
        }
        replacements.push({key: '%担当者名%', value: staffName, textAnchor: 'start', textLength: 416, chkWidth: true});
        // 担当者印
        let staffStamp = getNullStr(result[0].created_staff_name_stamp).trim() == '' ? getNullStr(result[0].client_staff_name_stamp).trim() : getNullStr(result[0].created_staff_name_stamp).trim();
        replacements.push({key: '%社判%', value: staffStamp, textAnchor: 'middle', textLength: 100, chkWidth: false});
        if (staffStamp != '') {
          this.stampFlg = true;
        }
        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = this.constData.cntNormal;
        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          if(productsIndex < result.length){
            // 品番
            replacements.push({key: '%品番' + (j + 1).toString() + '%', value: result[productsIndex].supplier_product_id == null ? '' : result[productsIndex].supplier_product_id, textAnchor: 'start', textLength: 240, chkWidth: true});
            // 商品名
            replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: result[productsIndex].product_name_kanji, textAnchor: 'start', textLength: 710, chkWidth: true});
            // 数量
            let productQuantity = result[productsIndex].product_quantity.toLocaleString();
            productQuantity += getNullStr(result[productsIndex].product_unit) == '' ? '' : ' ' + result[productsIndex].product_unit;
            replacements.push({key: '%数量' + (j + 1).toString() + '%', value: productQuantity, textAnchor: 'end', textLength: 210, chkWidth: true});
            // 備考
            replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[productsIndex].product_note, textAnchor: 'start', textLength: 360, chkWidth: true});

            productsIndex++;
          }else{
            // 品番
            replacements.push({key: '%品番' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 240, chkWidth: false});
            // 数量
            replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
            // 備考
            replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 360, chkWidth: false});
            if (whiteFlg == true) {
              // 以下余白を設定済み
              // 商品名
              replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 710, chkWidth: false});
            } else {
              // 以下余白が入る箇所
              if (i + 1 == this.listChouhyou.length &&
                j + 1 == productCntByChouhyou) {
                // 最終帳票、かつ、最終行の場合、余白がないため、「以上」とする。
                // 商品名
                replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以上', textAnchor: 'middle', textLength: 710, chkWidth: false});
              } else {
                // 商品名
                replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以下余白', textAnchor: 'middle', textLength: 710, chkWidth: false});
              }
              whiteFlg = true;
            }
          }
        }
        // 仕入先顧客コード
        replacements.push({key: '%仕入先顧客コード%', value: this.suppliersKokyakuId, textAnchor: 'start', textLength: 1320, chkWidth: true});
        // 受注情報
        replacements.push({key: '%受注情報%', value: '', textAnchor: 'start', textLength: 1560, chkWidth: false});
        // 備考
        this.setSummary(replacements, result[0].summary);

        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for(let i = 0; i < this.officeList.length; i++){
        // 郵便
        replacements.push({key: '%営業名' + this.officeList[i].keyNo + '%', value: this.officeList[i].officeNameKanji, textAnchor: 'start', textLength: 561, chkWidth: true});
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: 501, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: 501, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, summary) {
      let arySummary = splitMultiRowString(summary, 60);
      for (let i = 0; i < 4; i++) {
        if (i < arySummary.length) {
          // 摘要
          replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: arySummary[i], textAnchor: 'start', textLength: 1322, chkWidth: true});
        } else {
          // 摘要
          replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 1322, chkWidth: true});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      //console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        await this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
    },
    /* 置換値をSVGファイルに設定 */
    async setReplacements(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if (node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  await this.setTspan(node.children[i], node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  if(replacements[k].key == '%社判%'){
                    await this.setStamp(node.children[i], node.children[i].children[j], replacements[k]);
                  }else{
                    await this.setTspan(node.children[i], node.children[i].children[j], replacements[k]);
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'circle') {
          // 社判の赤丸
          if (node.children[i].getAttribute('stroke') == 'red') {
            if (this.stampFlg == true) {
              // 社判のテキストに使用するため中心の座標を取得しておく
              this.colStampCX = node.children[i].getAttribute('cx');
              this.colStampCY = node.children[i].getAttribute('cy');
            } else {
              // 赤丸を削除
              node.removeChild(node.children[i]);
              i--; 
            }
          }
        } else if (node.children[i].tagName == 'g'){
          await this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, getNullStr(config.value));
      // 製品一覧の文字を太字化
      if (getNullStr(config.value).length > 0 &&
      (config.key.indexOf('%商品名') != -1 || config.key.indexOf('%数量') != -1 || config.key.indexOf('%備考') != -1 || config.key.indexOf('%品番') != -1)
      ) {
        tagTspan.setAttribute('font-weight', 'bold');
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(config.chkWidth == true &&
        tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    async setStamp(tagText, tagTspan, config){
      if (config.value.length == 0) {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      } else if (config.value.length == 1) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) + 20);
      } else if (config.value.length == 2) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '40');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 5);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 35, config.value.substr(1, 1)));
      } else if (config.value.length == 3) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '30');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 20);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 40, config.value.substr(2, 1)));
      } else if (config.value.length == 4) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '35');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 8);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 18, Number(this.colStampCY) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) + 28, config.value.substr(3, 1)));
      } else {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '28');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 9);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 15, Number(this.colStampCY) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 38, config.value.substr(4, 1)));
      }
    },
    /* 印鑑用のtspan作成 */
    async createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>