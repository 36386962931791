<template>
  <!--  仕入先現場コード保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong>  仕入先現場コード保守編集</strong></h5>
                <router-link to="/suppliers-sites-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <hr>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 仕入先コード -->
                  <div class="col-12 form-group">
                    <label for="supplier_id" class="form-label">仕入先コード： {{obj.supplier_id}} &nbsp;&nbsp;&nbsp; {{obj.supplierName}}</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 得意先コード -->
                  <div class="col-12 form-group">
                    <label for="client_id" class="form-label">得意先コード： {{obj.client_id}} &nbsp;&nbsp;&nbsp; {{obj.clientName}}</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 現場コード -->
                  <div class="col-12 form-group">
                    <label for="site_id" class="form-label">現場コード： {{obj.site_id}} &nbsp;&nbsp;&nbsp; {{obj.client_site_name_kanji}}</label>
                  </div>
                </div>
                <div class="row">
                  <!-- 相手先現場コード -->
                  <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required|max:10" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="supplier_control_site_id" class="form-label">相手先現場コード</label>
                        <input type="text" id="supplier_control_site_id" class="form-control" v-model="obj.supplier_control_site_id" maxlength="10">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
            </div>
             <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />    
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, CreateColRow, CreateUpdateSql, escapeQuote, getUserCol, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

// ログ出力用モジュール名
const MODULE_NAME = 'suppliers-sites-edit';

export default {
  name: 'SUPPLIERS-SITES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入先現場コード保守編集',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 仕入先現場コード対象
      obj: {},
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],

    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData({supplier_id, client_id, site_id}) {
      this.busy = true;
      // 仕入先現場コードマスタのデータを請求
      const objResult = await this.searchSuppliersSites(supplier_id, client_id, site_id)

      // データセット 
      this.obj = {...objResult}
      this.busy = false;
    },
    /**
     * 仕入先現場コードマスタを検索します。
     * @param {Int} supplier_id
     * @param {Int} client_id
     * @param {Int} site_id
     * @returns {Object} 仕入先現場コードマスタデータ
     */
    async searchSuppliersSites(supplier_id, client_id, site_id) {
      const functionName = 'searchSuppliersSites';

      let result = null;
      // 仕入先現場コードと取引先マスタと現場マスタの結合
      const SQLs = `SELECT  
                    MSS.supplier_id,
                    MSS.client_id,
                    MSS.site_id,
                    MSS.supplier_control_site_id,
                    MC.client_name_kanji AS supplierName,
                    MC2.client_name_kanji AS clientName,
                    MCS.client_site_name_kanji
                    FROM 
                    m_suppliers_sites MSS 
                    LEFT JOIN m_clients MC ON MSS.supplier_id = MC.client_id AND MC.client_class = ${Const.ClientClass.supplier}
                    LEFT JOIN m_clients MC2 ON MSS.client_id = MC2.client_id AND MC2.client_class = ${Const.ClientClass.customer}
                    LEFT JOIN m_clients_sites MCS ON MSS.client_id = MCS.client_id AND MSS.site_id = MCS.site_id 
                    WHERE 
                    MSS.supplier_id = ${supplier_id}  
                    AND MSS.client_id = ${client_id}  
                    AND MSS.site_id = ${site_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      if (body.data[0].length != 0){
        return body.data[0][0]
      } else {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];
      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'SUPPLIERS-SITES-INQUIRY', 
              query: { 
                supplier_id: this.obj.supplier_id,
                client_id: this.obj.client_id,
                site_id: this.obj.site_id,
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      // 仕入先現場コードマスタ更新
      await this.execUpdateSuppliersSites()
    },
    /**
     * 仕入先現場コードマスタ更新
     */
    async execUpdateSuppliersSites(){
      const functionName = 'execUpdateSuppliersSites';

      const colList = [];
      // 相手先現場コード
      colList.push(CreateColRow('supplier_control_site_id', await escapeQuote(this.obj.supplier_control_site_id), 'VARCHAR'));

      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_suppliers_sites')} 
                    WHERE 
                    supplier_id = ${this.obj.supplier_id}
                    AND client_id = ${this.obj.client_id}
                    AND site_id = ${this.obj.site_id}`;
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
