<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" :key="resetKey" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 請求範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 締日プルダウン -->
                    <b-col cols="6">
                      <validation-provider name="closingDate" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="締日"
                          label-for="closingDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-form-select
                                id="closingDate"
                                name="closingDate"
                                v-model="closingDate"
                                :options="closingDates"
                                @change="calcBillingDate"
                              >
                              </b-form-select>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                          <b-form-text class="text-muted">締日と締切日から開始日と終了日を計算します。</b-form-text>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 締切年月 -->
                    <b-col cols="6">
                      <validation-provider name="closingYm" :rules="{required: true, regex: /^[0-9]{4}-(0[1-9]|1[0-2])$/, oldProcessMonthYearYm: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="締切年月"
                          label-for="closingYm"
                        >
                          <b-row>
                            <b-col lg="8" :class="classes">
                              <b-form-input id="closingYm" name="closingYm" type="month" v-model="closingYm" @change="calcBillingDate" />
                            </b-col>
                            <b-col lg="8" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 開始日 -->
                    <b-col lg="6">
                      <b-form-group
                        label="開始日"
                        label-for="billingStartDate"
                      >
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="billingStartDate" v-model="billingStartDate" readonly />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <!-- 締切日 -->
                    <b-col lg="6">
                      <b-form-group
                        label="締切日"
                        label-for="billingEndDate"
                      >
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="billingEndDate" v-model="billingEndDate" readonly />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 得意先コード -->
                    <b-col lg="12">
                      <b-form-group
                        label="得意先コード"
                        label-for="clientIdStart"
                      >
                        <b-input-group>
                          <validation-provider name="clientIdStart" :rules="{numeric: true, consistency: clientIdEnd}" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-form-input type="text" id="clientIdStart" name="clientIdStart" v-model.number="clientIdStart" maxlength="6"/>
                                  <b-input-group-text @click="showClientSearchModalStart" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModalStart" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                          <b-input-group-text>～</b-input-group-text>
                          <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-input type="text" id="clientIdEnd" name="clientIdEnd" v-model.number="clientIdEnd" maxlength="6" />
                                  <b-input-group-text @click="showClientSearchModalEnd" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModalEnd" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                        </b-input-group>
                        <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- 保存ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="3">
                <b-form style="text-align: center;">
                  <b-button class="mr-2" pill variant="success" title="入力された請求マスタデータを保存します。" type="submit" form="InputForm">
                    <span class="oi oi-circle-check"></span> 保存
                  </b-button>
                </b-form>
              </b-col>
              <b-col lg="5" v-if="billingPaymentClass==htmlConst.BillingPaymentClass.billing">
                <b-form style="text-align: center;">
                  <b-button class="mr-2" pill :variant="this.controlMasterData.closingUpdateState == htmlConst.ClosingUpdateStateClass.RUNNING ? 'danger' : 'success'" :title="closingUpdateButtonTitle" @click="clearAlert(); changeClosingUpdateState();">
                    <span class="oi oi-circle-check"></span> {{closingUpdateButtonName}}
                  </b-button>
                  <label style="color: red;">締更新実施開始は繰越処理において使用するボタンです。それ以外の用途では使用しないで下さい。誤って開始させた場合は締更新実施終了を押下して下さい。</label>
                </b-form>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import moment from 'moment';
import { init, formatDate, formatCurDate, getNullStr, addOperationLogs, getControlMaster, executeSelectSql, getFormCounter, CreateInsertSql, CreateUpdateSql, CreateColRow, executeTransactSqlList, isSystemEditable, executeSelectSqlNoLimit } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_closing_update_range, list_m_staffs } from '@/graphql/queries';

const MODULE_NAME = 'pre-invoice-process';

export default {
  name: 'PRE-INVOICE-PROCESS',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CONFIRM,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      baseTitle: '請求前処理',
      title: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 締日
      closingDate: '',
      // 締日選択肢
      closingDates: Const.closingDateList,
      // 締切年月
      closingYm: '',
      // 取引先コード（開始）
      clientIdStart: '',
      // 取引先コード（終了）
      clientIdEnd: '',
      // 開始日
      billingStartDate: '',
      // 締切日
      billingEndDate: '',
      // モーダルからの戻り値の反映先
      openModalIndex: 0,
      // トランザクションSQLリスト
      transactSqlList: [],
      // コントロールマスタの現在処理年月、締更新状態
      controlMasterData: {
        processMonthYear: 0,
        closingUpdateState: null,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 締更新状態状態区分
        ClosingUpdateStateClass: {
          NOT_RUNNING: Const.ClosingUpdateStateClass.NOT_RUNNING, // 停止中
          RUNNING: Const.ClosingUpdateStateClass.RUNNING, // 実行中
        },
        // 請求支払区分
        BillingPaymentClass: {
          // 請求
          billing: Const.BillingPaymentClass.billing,
          // 支払
          payment: Const.BillingPaymentClass.payment,
          // 請求2
          billing2: Const.BillingPaymentClass.billing2,
        },
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // ヘッダー更新キー
      resetKey: 0,
      // パラメータ
      billingPaymentClass: 0,
      // テーブル名
      billingsTableName: '',
      // タイトル用の固定値
      addTitle1: '（本社）',
      addTitle2: '２（横浜）',
      // 伝票作成情報（「YYYYMMDD(8桁) + 半角スペース + ログイン担当者コード（最大4桁）」）
      createdUserData: '',
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    if (this.$route.query.billingPaymentClass == Const.BillingPaymentClass.billing2) {
      this.billingPaymentClass = Const.BillingPaymentClass.billing2;
      this.title = this.baseTitle + this.addTitle2;
      this.billingsTableName = 'm_billings2';
    } else {
      this.billingPaymentClass = Const.BillingPaymentClass.billing;
      this.title = this.baseTitle + this.addTitle1;
      this.billingsTableName = 'm_billings';
    }
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 締更新ボタン名
    closingUpdateButtonName() {
      if (this.controlMasterData.closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '締更新実施終了';
      } else {
        return '締更新実施開始';
      }
    },
    // 締更新ボタン吹き出し
    closingUpdateButtonTitle() {
      if (this.controlMasterData.closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '請求/支払締更新に関係のない画面の登録・更新・削除の制限を解除します。';
      } else {
        return '請求/支払締更新に関係のない画面の登録・更新・削除を制限します。';
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 非同期でDBから情報を取得
        let staffListResult = null;
        let condition = {where_clause: 'AND login_id = ' + '\''+ this.loginId + '\''};
        let controlData = null;
        [staffListResult, controlData] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          getControlMaster(),
        ]);
        // コントロールマスタ取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.closingUpdateState = controlData.closing_update_state;
        // 伝票作成情報を作成
        let staffListData = staffListResult.data.list_m_staffs;
        this.createdUserData = formatCurDate('YYYYMMDD') + ' ' + staffListData[0].staff_id;
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      const functionName = 'setInitData';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause}));
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_closing_update_range',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      let dataResult = result.data.list_t_closing_update_range;
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake() {
      let where_clause = '';
      // 請求のレコードを取得（パラメータで請求、または、請求2）
      where_clause += 'AND billing_payment_class = ' + this.billingPaymentClass + ' ';

      return where_clause;
    },
    /* 取得結果セット（得意先コードは空白） */
    async setResult(result) {
      if (result.length > 0) {
        // 締日
        this.closingDate = result[0].closing_date.toString();
        // 締切年月
        this.closingYm = formatDate(('000000' + result[0].process_month_year).slice(-6) + '01', 'YYYY-MM');
        // 取引先コード（開始）
        this.clientIdStart = result[0].client_id_start.toString();
        // 取引先コード（終了）
        this.clientIdEnd = result[0].client_id_end.toString();
        // 開始日と締切日を計算
        this.calcBillingDate();
      }
    },
    /* 開始日、締切日を計算 */
    calcBillingDate: function() {
      // 開始日、締切日を計算
      const format = 'YYYY/MM/DD';
      if (this.closingDate == '' || this.closingYm == '') {
        // 計算に必要な値が未入力の場合
        this.billingStartDate = '';
        this.billingEndDate = '';
      } else if (this.closingDate === '99') {
        // 末日の場合
        // 開始日：決算年月の月初日、締切日：決算年月の月末日
        this.billingStartDate = moment(this.closingYm + '-01').format(format);
        this.billingEndDate = moment(this.closingYm + '-01').endOf('month').format(format);
      } else {
        // 末日でない場合
        // 開始日：決算年月の先月の締日の翌日、締切日：決算年月の締日
        this.billingStartDate = moment(this.closingYm + '-' + ('00' + this.closingDate).slice(-2)).add(-1,'months').add(1,'days').format(format);
        this.billingEndDate = moment(this.closingYm + '-' + ('00' + this.closingDate).slice(-2)).format(format);
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.checkDuplicateOtherBillings() == true) {
        // 登録することで他方の請求マスタと重複しない場合
        if (await this.execSave() == true) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } else {
        let messageArg1 = '';
        let messageArg2 = '指定した範囲';
        if (this.billingPaymentClass == Const.BillingPaymentClass.billing2) {
          messageArg1 = this.baseTitle + this.addTitle1;
        } else {
          messageArg1 = this.baseTitle + this.addTitle2;
        }
        this.alertWarning.push(DISP_MESSAGES.WARNING['2066'].replace('%arg1%',messageArg1).replace('%arg2%',messageArg2));
      }
      //console.log('保存処理終了');
    },
    // 他方の請求マスタとの重複確認
    async checkDuplicateOtherBillings() {
      let otherBillingPaymentClass;
      if (this.billingPaymentClass == Const.BillingPaymentClass.billing2) {
        otherBillingPaymentClass = Const.BillingPaymentClass.billing;
      } else {
        otherBillingPaymentClass = Const.BillingPaymentClass.billing2;
      }
      // 検索条件作成
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + otherBillingPaymentClass + ' ';
      let result = await API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause}));
      let dataResult = result.data.list_t_closing_update_range;
      //console.log('他方の請求マスタの範囲');
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        // 処理年月チェック
        if (dataResult[0].process_month_year != Number(this.closingYm.replace('-', ''))) {
          // 処理年月が違う場合
          // 重複ではないのでOK
          return true;
        }
        // 締日チェック
        if (dataResult[0].closing_date != this.closingDate) {
          // 処理年月が違う場合
          // 重複ではないのでOK
          return true;
        }
        // 取引先コード重複チェック
        let clientIdStart = getNullStr(this.clientIdStart) == '' ? 0 : Number(this.clientIdStart);
        let clientIdEnd = getNullStr(this.clientIdEnd) == '' ? 999999 : Number(this.clientIdEnd);
        if ((dataResult[0].client_id_start <= clientIdStart && clientIdStart <= dataResult[0].client_id_end) ||
            (dataResult[0].client_id_start <= clientIdEnd && clientIdEnd <= dataResult[0].client_id_end) ||
            (clientIdStart <= dataResult[0].client_id_start && dataResult[0].client_id_start <= clientIdEnd) ||
            (clientIdStart <= dataResult[0].client_id_end && dataResult[0].client_id_end <= clientIdEnd)) {
          // 重複しているためNG
          return false;
        } else {
          // 重複ではないのでOK
          return true;
        }
      } else {
        // 他方の請求マスタが存在しない場合
        // 重複ではないのでOK
        return true;
      }
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 値引取引先（現場）取得SQL
      let selectSql = '';
      selectSql = this.makeSelectSqlDiscount();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      // 累積トランザクション削除（特別値引）のために前の請求前処理で設定した取引先を取得
      selectSql = this.makeSelectSqlPreBillingsClients();
      let dataClientIdResult = await executeSelectSqlNoLimit(selectSql, 10000);
      if (dataClientIdResult != null && dataClientIdResult.length > 0) {
        // 累積トランザクション削除（特別値引）
        this.deleteCumulativeTransaction(dataClientIdResult);
      }
      // 請求マスタ更新SQL作成
      await this.updateBillingsSql();
      if (dataResult != null && dataResult.length > 0) {
        // 累積トランザクション登録（特別値引）
        await this.insertCumulativeTransaction(dataResult);
      }
      // 締更新範囲更新SQL作成
      this.updateClosingUpdateRange();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 伝票番号作成に時間がかかるため、SQL全実行直前にもう一度、片方の請求マスタとの重複チェック
      if (await this.checkDuplicateOtherBillings() == false) {
        let messageArg1 = '';
        let messageArg2 = '指定した範囲';
        if (this.billingPaymentClass == Const.BillingPaymentClass.billing2) {
          messageArg1 = this.baseTitle + this.addTitle1;
        } else {
          messageArg1 = this.baseTitle + this.addTitle2;
        }
        this.alertWarning.push(DISP_MESSAGES.WARNING['2066'].replace('%arg1%',messageArg1).replace('%arg2%',messageArg2));
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      retResult = true;

      return retResult;
    },
    // 検索SQL作成（更新前請求マスタの取引先）
    makeSelectSqlPreBillingsClients: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' billings.client_id';
      selectSql += ',billings.billing_end_date';
      selectSql += ',CASE WHEN billings_issue_input_site.client_id IS NULL THEN 0 ELSE 1 END AS input_site_flg';
      // FROM句
      selectSql += ' FROM ';
      selectSql += this.billingsTableName + ' AS billings ';
      selectSql += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSql += 'ON billings_issue_input_site.billing_month_year = CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED) ';
      selectSql += 'AND billings.client_id = billings_issue_input_site.client_id ';
      // WHERE
      selectSql += ' WHERE ';
      selectSql += 'NOT EXISTS (';
      selectSql += 'SELECT * FROM t_billings_issue_input AS billings_issue_input WHERE ';
      selectSql += 'billings_issue_input.billing_month_year = CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED) ';
      selectSql += 'AND billings_issue_input.client_id = billings.client_id) ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' billings.client_id';
      return selectSql;
    },
    /* 検索SQL作成（値引対象） */
    makeSelectSqlDiscount: function() {
      let selectSql = '';
      // 2つのSQL文をUNION
      // ・0：取引先
      // ・1：現場
      for (let i = 0; i < 2; i++) {
        if (i != 0) {
          selectSql += ' UNION ALL ';
        }
        /* SELECT句 */
        selectSql += 'SELECT';
        selectSql += ' clients.client_id';
        if (i == 0) {
          selectSql += ',0 AS site_id';
        } else {
          selectSql += ',clients_sites.site_id';
        }
        selectSql += ',clients.staff_id';
        selectSql += ',clients.client_id_first_digit';
        selectSql += ',clients.billing_output_class';
        selectSql += ',clients.sales_tax_class';
        selectSql += ',clients.tax_calculation_class';
        selectSql += ',clients.tax_fractionation_class';
        selectSql += ',TRUNCATE(SUM(CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) * clients.discount_rate / 100,0) AS normal_tax_discount';
        selectSql += ',TRUNCATE(SUM(CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) * clients.discount_rate / 100,0) AS light_tax_discount';
        selectSql += ',TRUNCATE(SUM(CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.noTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) * clients.discount_rate / 100,0) AS no_tax_discount';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_cumulative_transaction AS cumulative_transaction ';
        selectSql += 'INNER JOIN (';
        selectSql += 'SELECT * FROM m_clients AS clients ';
        selectSql += ' WHERE ';
        selectSql += 'clients.client_class = ' + Const.ClientClass.customer + ' ';
        // 取引先コード
        if(this.clientIdStart != '' && this.clientIdEnd == ''){
          selectSql += 'AND clients.client_id >= ' + this.clientIdStart + ' ';
        }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
          selectSql += 'AND clients.client_id <= ' + this.clientIdEnd + ' ';
        }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
          selectSql += 'AND clients.client_id BETWEEN ' + this.clientIdStart + ' AND ' + this.clientIdEnd + ' ';
        }
        // 締日
        selectSql += 'AND clients.closing_date=' + this.closingDate + ' ';
        // 値引率
        selectSql += 'AND clients.discount_rate > 0 ';
        // 税額計算区分「0:請求」のみ
        selectSql += 'AND clients.tax_calculation_class = ' + Const.TaxCalculationClass.invoice + ' ';
        // 請求書出力区分
        if (i == 0) {
          // 「0:取引先別」のみ（現場別であっても、現場コード0の行は必要）
          selectSql += 'AND clients.billing_output_class = ' + Const.billingOutputClass.clients + ' ';
        } else {
          // 「1:現場別」のみ（現場別であっても、現場コード0の行は必要）
          selectSql += 'AND clients.billing_output_class = ' + Const.billingOutputClass.clientsSites + ' ';
        }
        selectSql += 'AND NOT EXISTS (';
        selectSql += 'SELECT * FROM t_billings_issue_input AS billings_issue_input WHERE ';
        selectSql += 'billings_issue_input.billing_month_year = ' + this.closingYm.replace('-', '') + ' ';
        selectSql += 'AND billings_issue_input.client_id = clients.client_id) ';
        selectSql += ') AS clients ';
        selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
        selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
        if (i == 1) {
          selectSql += 'INNER JOIN m_clients_sites AS clients_sites ';
          selectSql += 'ON cumulative_transaction.client_id = clients_sites.client_id ';
          selectSql += 'AND cumulative_transaction.site_id = clients_sites.site_id ';
          selectSql += 'AND NOT EXISTS (';
          selectSql += 'SELECT * FROM t_billings_issue_input_site AS billings_issue_input_site WHERE ';
          selectSql += 'billings_issue_input_site.billing_month_year = ' + this.closingYm.replace('-', '') + ' ';
          selectSql += 'AND billings_issue_input_site.client_id = clients_sites.client_id ';
          selectSql += 'AND billings_issue_input_site.site_id = clients_sites.site_id) ';
        }
        selectSql += 'LEFT JOIN m_products AS products ';
        selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
        /* WHERE句 */
        selectSql += ' WHERE ';
        selectSql += 'cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.billingStartDate + '\' AND \'' + this.billingEndDate + '\' ';
        selectSql += 'AND cumulative_transaction.is_update_closing_date = 0 ';
        selectSql += 'AND cumulative_transaction.product_id NOT IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
        /* GROUP BY句 */
        if (i == 0) {
          selectSql += ' GROUP BY ';
          selectSql += ' cumulative_transaction.client_id';
        } else {
          selectSql += ' GROUP BY ';
          selectSql += ' cumulative_transaction.client_id';
          selectSql += ',cumulative_transaction.site_id';
        }
      }
      /* ORDER BY */
      selectSql += ' ORDER BY ';
      selectSql += ' client_id';
      selectSql += ',site_id';

      return selectSql;
    },
    /* 請求マスタ更新SQL作成処理 */
    async updateBillingsSql() {
      //console.log('請求マスタ更新SQL作成処理');
      // CRUD処理
      // 最初にdelete
      this.transactSqlList.push('DELETE FROM ' + this.billingsTableName);
      // 登録SQLを作成（INSERT文の前部分のため設定値不要）
      let insertSql = '';
      let colList = [];
      // 得意先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', null, 'NUMBER'));
      // 請求開始日
      colList.push(CreateColRow('billing_start_date', null, 'DATE'));
      // 請求締切日
      colList.push(CreateColRow('billing_end_date', null, 'DATE'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', null, 'NUMBER'));
      // 得意先分類コード
      colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      insertSql += 'INSERT INTO ' + this.billingsTableName + ' (' + CreateInsertSql(colList, 'col', this.billingsTableName) + ') ';
      // 現場と結合
      let selectSql1 = '';
      selectSql1 += 'SELECT';
      selectSql1 += ' clients.client_id';
      selectSql1 += ',clients_sites.site_id';
      selectSql1 += ',0';
      selectSql1 += ',' + this.closingDate + ' ';
      selectSql1 += ',\'' + this.billingStartDate + '\'';
      selectSql1 += ',\'' + this.billingEndDate + '\'';
      selectSql1 += ',clients.billing_output_class';
      selectSql1 += ',clients.client_id_first_digit';
      selectSql1 += ',\'' + this.loginId + '\'';
      selectSql1 += ',\'' + this.loginId + '\'';
      selectSql1 += ' FROM m_clients AS clients ';
      selectSql1 += ' INNER JOIN m_clients_sites AS clients_sites ';
      selectSql1 += ' ON clients.client_id = clients_sites.client_id ';
      selectSql1 += ' WHERE ';
      selectSql1 += 'clients.client_class = ' + Const.ClientClass.customer + ' ';
      // 取引先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql1 += 'AND clients.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql1 += 'AND clients.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql1 += 'AND clients.client_id BETWEEN ' + this.clientIdStart + ' AND ' + this.clientIdEnd + ' ';
      }
      selectSql1 += 'AND clients.closing_date=' + this.closingDate + ' ';
      // 取引先のみ（現場コードは0固定）
      let selectSql2 = '';
      selectSql2 += 'SELECT';
      selectSql2 += ' clients.client_id';
      selectSql2 += ',0';
      selectSql2 += ',0';
      selectSql2 += ',' + this.closingDate + ' ';
      selectSql2 += ',\'' + this.billingStartDate + '\'';
      selectSql2 += ',\'' + this.billingEndDate + '\'';
      selectSql2 += ',clients.billing_output_class';
      selectSql2 += ',clients.client_id_first_digit';
      selectSql2 += ',\'' + this.loginId + '\'';
      selectSql2 += ',\'' + this.loginId + '\'';
      selectSql2 += ' FROM m_clients AS clients ';
      selectSql2 += ' WHERE ';
      selectSql2 += 'clients.client_class = ' + Const.ClientClass.customer + ' ';
      // 取引先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql2 += 'AND client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql2 += 'AND client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql2 += 'AND client_id BETWEEN ' + this.clientIdStart + ' AND ' + this.clientIdEnd + ' ';
      }
      selectSql2 += 'AND clients.closing_date=' + this.closingDate + ' ';

      insertSql += selectSql1 + ' UNION ALL ' + selectSql2;

      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /* 累積トランザクション削除SQL作成 */
    deleteCumulativeTransaction: function(dataClientIdResult) {
      //console.log('累積トランザクション削除SQL作成');
      let preBillingEndDate = dataClientIdResult[0].billing_end_date;
      let clientIdList = [];
      let clientIdInputSiteList = [];
      for (let i = 0; i < dataClientIdResult.length; i++) {
        if (dataClientIdResult[i].input_site_flg == 1) {
          clientIdInputSiteList.push(dataClientIdResult[i].client_id);
        } else {
          clientIdList.push(dataClientIdResult[i].client_id);
          if (clientIdList.length >= 7000) {
            let deleteSql = this.createDeleteCumulativeTransaction(preBillingEndDate, clientIdList);
            this.transactSqlList.push(deleteSql);
            clientIdList = [];
          }
        }
      }
      if (clientIdList.length > 0) {
        let deleteSql = this.createDeleteCumulativeTransaction(preBillingEndDate, clientIdList);
        this.transactSqlList.push(deleteSql);
      }
      if (clientIdInputSiteList.length > 0) {
        let deleteSql = this.createDeleteCumulativeTransactionInputSite(preBillingEndDate, clientIdInputSiteList);
        this.transactSqlList.push(deleteSql);
      }
    },
    // 累積トランザクションの削除SQL作成
    createDeleteCumulativeTransaction: function(preBillingEndDate, clientIdList) {
      let csvClientId = clientIdList.join(',');
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'product_id IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      deleteSql += 'AND billing_date = \'' + preBillingEndDate + '\' ';
      deleteSql += 'AND client_id IN (' + csvClientId + ') ';
      deleteSql += 'AND closing_month_year = 0 ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.sales + '\' ';
      //console.log(deleteSql);

      return deleteSql;
    },
    // 累積トランザクションの削除SQL作成（請求書発行登録済みの現場は削除しない）
    createDeleteCumulativeTransactionInputSite: function(preBillingEndDate, clientIdList) {
      let csvClientId = clientIdList.join(',');
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE cumulative_transaction FROM ';
      deleteSql += 't_cumulative_transaction AS cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'cumulative_transaction.product_id IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      deleteSql += 'AND cumulative_transaction.billing_date = \'' + preBillingEndDate + '\' ';
      deleteSql += 'AND cumulative_transaction.client_id IN (' + csvClientId + ') ';
      deleteSql += 'AND cumulative_transaction.closing_month_year = 0 ';
      deleteSql += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      deleteSql += 'AND NOT EXISTS (';
      deleteSql += 'SELECT * FROM t_billings_issue_input_site AS billings_issue_input_site WHERE ';
      deleteSql += 'billings_issue_input_site.billing_month_year = ' + formatDate(preBillingEndDate,'YYYYMM') + ' ';
      deleteSql += 'AND billings_issue_input_site.client_id = cumulative_transaction.client_id ';
      deleteSql += 'AND billings_issue_input_site.site_id = cumulative_transaction.site_id) ';
      //console.log(deleteSql);

      return deleteSql;
    },
    /* 累積トランザクション登録SQL作成 */
    async insertCumulativeTransaction(result) {
      //console.log('累積トランザクション登録SQL作成');
      let bulkInsertSql = '';
      for (let i = 0; i < result.length; i++) {
        // 通常値引、軽減税率値引、非課税値引が0以外の場合
        if (result[i].normal_tax_discount != 0 || result[i].light_tax_discount != 0 || result[i].no_tax_discount != 0) {
          let officeId = Const.specialSalesDiscount.honshaOfficeId;
          if (Const.specialSalesDiscount.honshaClientIdStart <= result[i].client_id && result[i].client_id <= Const.specialSalesDiscount.honshaClientIdEnd) {
            officeId = Const.specialSalesDiscount.honshaOfficeId;
          } else if (Const.specialSalesDiscount.yokohamaClientIdStart <= result[i].client_id && result[i].client_id <= Const.specialSalesDiscount.yokohamaClientIdEnd) {
            officeId = Const.specialSalesDiscount.yokohamaOfficeId;
          }
          let salesNumber = await this.getBillingNo(officeId);
          let billingRow = 1;
          for (let j = 0; j < Const.ProductTaxRateClassList.length; j++) {
            if (j == Const.ProductTaxRateClass.normalTax && result[i].normal_tax_discount == 0 ||
            j == Const.ProductTaxRateClass.lightTax && result[i].light_tax_discount == 0 ||
            j == Const.ProductTaxRateClass.noTax && result[i].no_tax_discount == 0) {
              // 通常値引、軽減税率値引、非課税値引が0の場合は処理をスキップ
              continue;
            }
            let colList = [];
            // ﾄﾗﾝｻﾞｸｼｮﾝID
            colList.push(CreateColRow('transaction_id', Const.TransactionId.sales, 'VARCHAR'));
            // 伝票No
            colList.push(CreateColRow('billing_no', salesNumber, 'NUMBER'));
            // 行No
            colList.push(CreateColRow('billing_row', billingRow, 'NUMBER'));
            // 伝票日付
            colList.push(CreateColRow('billing_date', this.billingEndDate, 'DATE'));
            // 伝票種別
            colList.push(CreateColRow('bill_class', Const.Tradition.salesNormal, 'NUMBER'));
            // 解体組立区分
            colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
            // 営業所コード
            colList.push(CreateColRow('office_id', officeId, 'NUMBER'));
            // 移動先コード
            colList.push(CreateColRow('destination_id', 0, 'NUMBER'));
            // 取引先区分
            colList.push(CreateColRow('client_class', Const.ClientClass.customer, 'NUMBER'));
            // 取引先コード
            colList.push(CreateColRow('client_id', result[i].client_id, 'NUMBER'));
            // 現場コード
            colList.push(CreateColRow('site_id', result[i].site_id, 'NUMBER'));
            // 伝票摘要
            colList.push(CreateColRow('billing_summary', this.createdUserData, 'VARCHAR'));
            // 担当者コード
            colList.push(CreateColRow('staff_id', result[i].staff_id, 'NUMBER'));
            // 取引先分類コード
            colList.push(CreateColRow('client_id_first_digit', result[i].client_id_first_digit, 'NUMBER'));
            // 相殺伝票No
            colList.push(CreateColRow('offset_billing_no', 0, 'NUMBER'));
            // 製品コード
            if (j == Const.ProductTaxRateClass.normalTax) {
              colList.push(CreateColRow('product_id', Const.specialSalesDiscount.productId, 'NUMBER'));
            } else if (j == Const.ProductTaxRateClass.lightTax) {
              colList.push(CreateColRow('product_id', Const.specialSalesDiscount.productIdLightTax, 'NUMBER'));
            } else if (j == Const.ProductTaxRateClass.noTax) {
              colList.push(CreateColRow('product_id', Const.specialSalesDiscount.productIdNoTax, 'NUMBER'));
            }
            // 製品名
            if (j == Const.ProductTaxRateClass.normalTax) {
              colList.push(CreateColRow('product_name', Const.specialSalesDiscount.productName, 'VARCHAR'));
            } else if (j == Const.ProductTaxRateClass.lightTax) {
              colList.push(CreateColRow('product_name', Const.specialSalesDiscount.productNameLightTax, 'VARCHAR'));
            } else if (j == Const.ProductTaxRateClass.noTax) {
              colList.push(CreateColRow('product_name', Const.specialSalesDiscount.productNameNoTax, 'VARCHAR'));
            }
            // 製品数量
            colList.push(CreateColRow('product_quantity', -1, 'NUMBER'));
            // 製品売上単価
            if (j == Const.ProductTaxRateClass.normalTax) {
              colList.push(CreateColRow('product_sales_unit_price', result[i].normal_tax_discount, 'NUMBER'));
            } else if (j == Const.ProductTaxRateClass.lightTax) {
              colList.push(CreateColRow('product_sales_unit_price', result[i].light_tax_discount, 'NUMBER'));
            } else if (j == Const.ProductTaxRateClass.noTax) {
              colList.push(CreateColRow('product_sales_unit_price', result[i].no_tax_discount, 'NUMBER'));
            }
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', 'product_quantity * product_sales_unit_price', 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', 'product_quantity * product_sales_unit_price', 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
            // 金額
            colList.push(CreateColRow('amount', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', Const.SundriesClass.shokuchi, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', Const.InventoryControlClassDef.noInventory, 'NUMBER'));
            // 売上課税区分
            colList.push(CreateColRow('sales_tax_class', result[i].sales_tax_class, 'NUMBER'));
            // 税額計算区分
            colList.push(CreateColRow('tax_calculation_class', result[i].tax_calculation_class, 'NUMBER'));
            // 税額端数計算処理区分
            colList.push(CreateColRow('tax_fractionation_class', result[i].tax_fractionation_class, 'NUMBER'));
            // 月次更新フラグ
            colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
            // 月次年月
            colList.push(CreateColRow('monthly_month_year', 0, 'NUMBER'));
            // 締日更新フラグ
            colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
            // 締次年月
            colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
            // 受注番号
            colList.push(CreateColRow('order_receive_id', 0, 'NUMBER'));
            // 受注行番号
            colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
            // 発注番号
            colList.push(CreateColRow('order_id', 0, 'NUMBER'));
            // 発注行番号
            colList.push(CreateColRow('order_row', 0, 'NUMBER'));
            // 注文番号
            colList.push(CreateColRow('order_number', 0, 'NUMBER'));
            // 注文行番号
            colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
            // 組合CD発注取引先
            colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
            // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
            colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
            billingRow++;
            if (bulkInsertSql == '') {
              bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
            } else {
              bulkInsertSql += ',';
            }
            bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
            if (bulkInsertSql.length >= Const.SqlMaxLength) {
              this.transactSqlList.push(bulkInsertSql);
              bulkInsertSql = '';
            }
          }
        }
      }
      if (bulkInsertSql != '') {
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    /* 伝票番号取得 */
    async getBillingNo(officeId) {
      const functionName = 'getBillingNo';
      let billingNo = await getFormCounter(Const.CounterClass.billingNo, officeId, Const.TransactionId.sales, this.loginId, MODULE_NAME, functionName);
      if (billingNo != null) {
        return billingNo;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    // 締更新範囲更新SQL作成
    async updateClosingUpdateRange() {
      //console.log('締更新範囲更新SQL作成');
      // CRUD処理
      // 最初にdelete
      this.transactSqlList.push('DELETE FROM t_closing_update_range WHERE billing_payment_class = ' + this.billingPaymentClass);
      // 登録SQLを作成
      let colList = [];
      // 請求支払区分
      colList.push(CreateColRow('billing_payment_class', this.billingPaymentClass, 'NUMBER'));
      // 処理年月
      colList.push(CreateColRow('process_month_year', this.closingYm.replace('-', ''), 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', this.closingDate, 'NUMBER'));
      // 取引先コード開始
      colList.push(CreateColRow('client_id_start', getNullStr(this.clientIdStart) == '' ? 0 : this.clientIdStart, 'NUMBER'));
      // 取引先コード終了
      colList.push(CreateColRow('client_id_end', getNullStr(this.clientIdEnd) == '' ? 999999 : this.clientIdEnd, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      let insertSql = CreateInsertSql(colList, 'full', 't_closing_update_range');
      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /** 得意先コード（開始）の取引先検索モーダル表示 */
    showClientSearchModalStart:function() {
      this.openModalIndex = 1;
      this.$bvModal.show('clientSearchModal');
    },
    /** 得意先コード（終了）の取引先検索モーダル表示 */
    showClientSearchModalEnd:function() {
      this.openModalIndex = 2;
      this.$bvModal.show('clientSearchModal');
    },
    /** 得意先コードの取引先検索モーダルを閉じた時 */
    closeClientSearchModal:function(clientItems){
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.clientIdStart = clientItems.detail.client_id;
        } else {
          this.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 締更新状態の変更
    async changeClosingUpdateState() {
      const functionName = 'changeClosingUpdateState';
      try {
        // 現在処理年月取得
        let controlData = await getControlMaster();
        let closingUpdateState = controlData.closing_update_state;
        if (this.controlMasterData.closingUpdateState == closingUpdateState) {
          let confirmMessage = '';
          let title = '';
          let changeClosingUpdateState = null;
          // コントロールマスタの締更新状態を変更
          if (closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
            confirmMessage = '請求/支払締更新に関係のない画面の登録・更新・削除の制限を解除します。';
            confirmMessage += 'よろしいですか？';
            title = '締更新終了';
            changeClosingUpdateState = Const.ClosingUpdateStateClass.NOT_RUNNING;
          } else {
            confirmMessage = '請求/支払締更新に関係のない画面の登録・更新・削除を制限します。';
            confirmMessage += 'よろしいですか？';
            title = '締更新開始';
            changeClosingUpdateState = Const.ClosingUpdateStateClass.RUNNING;
          }
          if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
            this.$store.commit('setLoading', true);
            await this.execClosingUpdateStateChange(changeClosingUpdateState);
          }
        } else {
          this.controlMasterData.closingUpdateState = closingUpdateState;
        }
        // ヘッダー部分を再描画
        if (this.resetKey != 0) {
          this.resetKey = 0;
        } else {
          this.resetKey++;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 締更新状態変更処理実行
    async execClosingUpdateStateChange(closingUpdateState) {
      const functionName = 'execClosingUpdateStateChange';
      this.transactSqlList = [];
      // コントロールマスタ更新SQL作成
      await this.updateControlMaster(closingUpdateState);
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 作成した登録用SQLを全実行
      let retResult = await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.controlMasterData.closingUpdateState = closingUpdateState;
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    // コントロールマスタ更新SQL作成
    async updateControlMaster(closingUpdateState) {
      //console.log('コントロールマスタ更新SQL作成');
      let colList = [];
      // 締更新状態
      colList.push(CreateColRow('closing_update_state', closingUpdateState, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      this.transactSqlList.push(CreateUpdateSql(colList, 'm_control'));
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>