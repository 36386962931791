<template>
  <!-- 買掛実績・残高保守 -->
  <div>
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{title}}</strong></h5>
                <router-link to="/accounts-payable-supply-list" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p class="mb-4">編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <!-- 処理年月 -->
              <b-form-group label="処理年月" label-cols-md="2" label-class="font-weight-bold">
                <b-form-input type="month" v-model="monthYear" style="width: 180px;" readonly></b-form-input>
              </b-form-group>
              <!-- 仕入先コード -->
              <b-form-group label="仕入先コード" label-cols-md="2" label-class="font-weight-bold" class="mb-5">
                <b-form-input v-model="supplierName" class="d-inline-block mb-2" style="max-width: 500px;" readonly></b-form-input>
              </b-form-group>
              <validation-observer ref="observer">
                <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                  <!-- 前月末買掛税抜残高 -->
                  <b-form-group label="前月末買掛税抜残高" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="lastMonthPayableNoTaxBalanceResult" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 前月末買掛消費税残高 -->
                  <b-form-group label="前月末買掛消費税残高" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="lastMonthPayableTaxBalanceResult" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次仕入額 -->
                  <b-form-group label="月次仕入額" label-cols-md="2" label-class="font-weight-bold" class="mt-5">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyPayable" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次現金支払額 -->
                  <b-form-group label="月次現支払金額" label-cols-md="2" label-class="font-weight-bold" class="mt-5">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次小切手支払額 -->
                  <b-form-group label="月次小切手支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyCheckPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次振込支払額 -->
                  <b-form-group label="月次振込支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTransferPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次手形支払額 -->
                  <b-form-group label="月次手形支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBillPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次相殺支払額 -->
                  <b-form-group label="月次相殺支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyOffsetPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次その他支払額 -->
                  <b-form-group label="月次その他支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyOtherPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次消費税支払額 -->
                  <b-form-group label="月次消費税支払額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTaxPayment" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次一括消費税額 -->
                  <b-form-group label="月次一括消費税額" label-cols-md="2" label-class="font-weight-bold" class="mt-5">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBulkTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次伝票消費税額 -->
                  <b-form-group label="月次伝票消費税額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyBillingTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                  <!-- 月次消費税額 -->
                  <b-form-group label="月次消費税額" label-cols-md="2" label-class="font-weight-bold">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <b-form-input v-model="monthlyTax" style="max-width: 500px;"></b-form-input>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <b-button variant="success" class="rounded-pill btn-block" @click="onSaveButtonClick"><span class="oi oi-circle-check"></span> 保存</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>    
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateUpdateSql, isSystemEditable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'payable-balance-result-maintenance';

export default {
  name: 'PAYABLE-BALANCE-RESULT-MAINTENANCE',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      menu_type: 'user',
      title: '買掛実績・残高保守',
      monthYear: '', // 処理年月
      supplierId: '', // 仕入先コード
      supplierName: '', // 仕入先名
      lastMonthPayableNoTaxBalanceResult: 0, // 前月末買掛税抜残高
      lastMonthPayableTaxBalanceResult: 0, // 前月末買掛消費税残高
      monthlyPayable: 0, // 月次仕入額
      monthlyPayment: 0, // 月次現金支払額
      monthlyCheckPayment: 0, // 月次小切手支払額
      monthlyTransferPayment: 0, // 月次振込支払額
      monthlyBillPayment: 0, // 月次手形支払額
      monthlyOffsetPayment: 0, // 月次相殺支払額
      monthlyOtherPayment: 0, // 月次その他支払額
      monthlyTaxPayment: 0, // 月次消費税支払額
      monthlyBulkTax: 0, // 月次一括消費税額
      monthlyBillingTax: 0, // 月次伝票消費税額
      monthlyTax: 0, // 月次消費税額
      errorMessages:[]
    };
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0, 0);
    if (!await this.fetchData(this.$route.query.monthYear, this.$route.query.supplierId)) {
      this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * 指定された処理年月と仕入先コードに対応する買掛実績・残高データを取得して
     * 画面に反映します。
     * @param {Number|String} monthYear - 処理年月
     * @param {Number|String} supplierId - 仕入先コード
     * @returns {Boolean} 画面への反映に成功した場合はtrue、そうでない場合はfalse
     */
    async fetchData(monthYear, supplierId) {
      const functionName = 'fetchData';

      const sqls = [];
      sqls.push(this.createFetchDataSQL(monthYear, supplierId));

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        return false;
      }

      // 買掛実績・残高マスタのデータが存在していた場合はデータを設定
      if (body.data[0].length > 0) {
        const data = body.data[0][0];
        const monthYear = String(data.month_year);
        this.monthYear = `${monthYear.substring(0,4)}-${monthYear.substring(4)}`; // 処理年月
        this.supplierId = data.supplier_id; // 仕入先コード
        this.supplierName = `${data.supplier_id}：${data.client_name_kanji}`; // 仕入先名
        this.lastMonthPayableNoTaxBalanceResult = data.last_month_payable_no_tax_balance_result; // 前月末売掛税抜残高
        this.lastMonthPayableTaxBalanceResult = data.last_month_payable_tax_balance_result; // 前月末売掛消費税残高
        this.monthlyPayable = data.monthly_payable; // 月次仕入額
        this.monthlyPayment = data.monthly_payment; // 月次現金支払額
        this.monthlyCheckPayment = data.monthly_check_payment; // 月次小切手支払額
        this.monthlyTransferPayment = data.monthly_transfer_payment; // 月次振込支払額
        this.monthlyBillPayment = data.monthly_bill_payment; // 月次手形支払額
        this.monthlyOffsetPayment = data.monthly_offset_payment; // 月次相殺支払額
        this.monthlyOtherPayment = data.monthly_other_payment; // 月次その他支払額
        this.monthlyTaxPayment = data.monthly_tax_payment; // 月次消費税支払額
        this.monthlyBulkTax = data.monthly_bulk_tax; // 月次一括消費税額
        this.monthlyBillingTax = data.monthly_billing_tax; // 月次伝票消費税額
        this.monthlyTax = data.monthly_tax; // 月次消費税額
      }
      return true;
    },
    /**
     * 指定された処理年月と支払先コードに対応する買掛実績・残高データを取得する
     * SQL文を作成します。
     * @param {Number|String} monthYear - 処理年月
     * @param {Number|String} supplierId - 仕入先コード
     * @returns {String} SQL文
     */
    createFetchDataSQL(monthYear, supplierId) {
      return 'SELECT' +
        ' tpbr.month_year' +
        ',tpbr.supplier_id' +
        ',tpbr.last_month_payable_no_tax_balance_result' +
        ',tpbr.last_month_payable_tax_balance_result' +
        ',tpbr.monthly_payable' +
        ',tpbr.monthly_payment' +
        ',tpbr.monthly_check_payment' +
        ',tpbr.monthly_transfer_payment' +
        ',tpbr.monthly_bill_payment' +
        ',tpbr.monthly_offset_payment' +
        ',tpbr.monthly_other_payment' +
        ',tpbr.monthly_tax_payment' +
        ',tpbr.monthly_bulk_tax' +
        ',tpbr.monthly_billing_tax' +
        ',tpbr.monthly_tax' +
        ',mc.client_name_kanji ' +
        'FROM' +
        ' t_payables_balances_results AS tpbr' +
        ' JOIN m_clients AS mc ON mc.client_class = 2 AND mc.client_id = tpbr.supplier_id ' +
        'WHERE' +
        ` tpbr.month_year = ${monthYear}` +
        ` AND tpbr.supplier_id = ${supplierId}`;
    },
    /**
     * 保存ボタンクリックイベント処理
     */
    async onSaveButtonClick() {
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('入力された買掛実績・残高データを保存します。よろしいですか？', {
          title: '買掛実績・残高の保存',
        })) {
          this.$store.commit('setLoading', true);
          if (await this.execUpdate()) {
            // UPDATEに成功した場合は仕入先別買掛実績表画面へ遷移
            this.$router.push({ name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST', query: {successMessageId: '1001'} });
          } else {
            scrollTo(0, 0);
          }
        }
      } else {
        // 入力エラーがあった場合はエラーの項目へスクロール
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 買掛実績・残高マスタを更新します。
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      // SQL文を作成します。
      const sqls = [];
      sqls.push(await this.createUpdateSQL());

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      // 買掛実績・残高データを更新します。
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      return true;
    },
    /**
     * 買掛実績・残高データを更新するSQL文を作成します。
     * @returns {String} SQL文
     */
    async createUpdateSQL() {
      const colList = [];
      // 前月末買掛税抜残高
      colList.push(CreateColRow('last_month_payable_no_tax_balance_result', this.lastMonthPayableNoTaxBalanceResult, 'NUMBER'));
      // 前月末買掛消費税残高
      colList.push(CreateColRow('last_month_payable_tax_balance_result', this.lastMonthPayableTaxBalanceResult, 'NUMBER'));
      // 月次仕入額
      colList.push(CreateColRow('monthly_payable', this.monthlyPayable, 'NUMBER'));
      // 月次現金支払額
      colList.push(CreateColRow('monthly_payment', this.monthlyPayment, 'NUMBER'));
      // 月次小切手支払額
      colList.push(CreateColRow('monthly_check_payment', this.monthlyCheckPayment, 'NUMBER'));
      // 月次振込支払額
      colList.push(CreateColRow('monthly_transfer_payment', this.monthlyTransferPayment, 'NUMBER'));
      // 月次手形支払額
      colList.push(CreateColRow('monthly_bill_payment', this.monthlyBillPayment, 'NUMBER'));
      // 月次相殺支払額
      colList.push(CreateColRow('monthly_offset_payment', this.monthlyOffsetPayment, 'NUMBER'));
      // 月次その他支払額
      colList.push(CreateColRow('monthly_other_payment', this.monthlyOtherPayment, 'NUMBER'));
      // 月次消費税支払額
      colList.push(CreateColRow('monthly_tax_payment', this.monthlyTaxPayment, 'NUMBER'));
      // 月次一括消費税額
      colList.push(CreateColRow('monthly_bulk_tax', this.monthlyBulkTax, 'NUMBER'));
      // 月次伝票消費税額
      colList.push(CreateColRow('monthly_billing_tax', this.monthlyBillingTax, 'NUMBER'));
      // 月次消費税額
      colList.push(CreateColRow('monthly_tax', this.monthlyTax, 'NUMBER'));
      // 更新日時
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      return CreateUpdateSql(colList, 't_payables_balances_results') + ` WHERE month_year = ${this.monthYear.replace('-', '')} AND supplier_id = ${this.supplierId}`;
    }
  }
}
</script>
