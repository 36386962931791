<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 仕入単価修正</strong></h5>
                <b-button pill v-b-tooltip.hover.noninteractive title="仕入単価修正一覧に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <hr>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveOrder();" id="receivedOrderInputForm" class="form-horizontal">
                  <div class="row">
                    <!-- 営業所 -->
                    <div class="col-12 form-group">
                      営業所: {{this.obj.office_name_kanji}}
                    </div>
                  </div>
                  <div class="row">
                    <!-- 伝票番号 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      伝票番号: {{billingNo}}
                    </div>
                    <!-- 日付 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      日付: {{this.obj.billing_date}}
                    </div>
                    <!-- 伝票種別 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      伝票種別: {{this.obj.bill_class}}
                    </div>
                    <!-- 締日 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      締日: {{this.obj.closing_date}}
                    </div>
                  </div>
                  <div class="row">
                    <!-- 取引先コード -->
                    <div class="col-12 form-group">
                      取引先コード: {{this.obj.clientName}}
                      <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。" @click="clientInfoModal()">
                        <span class="oi oi-eye"></span> 情報
                      </b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 form-group">
                      <!-- 住所 -->
                      住所: {{this.obj.address_1}} &nbsp;{{this.obj.address_2}}
                    </div>
                    <!-- 部署 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 form-group">
                      部署: {{this.obj.department_1}} &nbsp;{{this.obj.department_2}}
                    </div>
                  </div>
                  <div class="row">
                    <!-- 郵便番号 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      郵便番号: {{this.obj.zip_code}}
                    </div>
                    <!-- 電話 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      TEL: {{this.obj.phone_number}}
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      担当者: {{this.obj.staffId}}
                    </div>
                  </div>
                  <!-- テーブル -->
                  <div class="row">
                    <div class="col-12 form-group">
                      <b-table
                        :responsive="true"
                        :items="productList"
                        :fields="fields"
                        small
                      >
                        <!-- テーブルタイトル -->
                        <template #table-caption>製品テーブル</template>
                        <template #cell(productName)="data">
                          <span v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.productTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark + ' ' : '' }}</span>
                          {{ data.item.productName }}
                        </template>
                        <!-- 仕入単価 -->
                        <template #cell(productPurchasePrice)="data">
                          <div v-if="data.item.productPurchasePriceEditable">
                            <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes, errors }">
                              <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.purchase_price">
                                <b-form-input type="number" id="productPurchasePrice" v-model.number="data.item.productPurchasePrice" maxlength="9" @blur="calc(data.index)" />
                                <small class="form-text text-muted">必須項目です。最大8桁</small>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </div>
                            </validation-provider>
                          </div>
                          <div v-else>{{data.item.productPurchasePrice}}</div>
                        </template>
                        <template #cell(salesMoney)="data">
                          {{ data.item.salesMoney }}
                          <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                            <b-input-group style="width: 100px;">
                              <b-row>
                                <b-col lg="12" :class="classes">
                                  <input type="hidden" name="totalPurchasePrime" v-model.number="data.item.totalPurchasePrime" />
                                  <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                </b-col>
                              </b-row>
                            </b-input-group>
                          </validation-provider>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <!-- テーブル footer -->
                  <div class="row">
                    <b-col>
                      <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                    </b-col>
                  </div>
                  <!-- 現場部分 -->
                  <div class="row">
                    <!-- 納品指定日 -->
                    <div class="col-12 form-group">
                      納品指定日: {{this.obj.delivery_design_date}}                   
                    </div>
                  </div>
                  <div class="row">
                    <!-- 現場コード -->
                    <div class="col-12 form-group">
                      現場コード: {{this.obj.clientId}}                   
                    </div>
                  </div>
                  <div class="row">
                    <!-- 住所 -->
                    <div class="col-12 form-group">
                      郵便番号: {{this.obj.client_site_zip_code}}                   
                    </div>
                  </div>
                  <div class="row">
                    <!-- 住所 -->
                    <div class="col-12 form-group">
                      住所: {{this.obj.address}}                   
                    </div>
                  </div>
                  <div class="row">
                    <!-- 納品場所 -->
                    <div class="col-12 form-group">
                      納品場所: {{this.obj.delivery_location}}                   
                    </div>
                  </div>
                  <div class="row">
                    <!-- チェック -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      チェック: {{this.obj.check_contents}}                   
                    </div>
                    <!-- TEL -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      TEL: {{this.obj.client_site_phone_number}}                   
                    </div>
                    <!-- 処理日 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      処理日: {{this.obj.process_date}}                   
                    </div>
                    <!-- 受注処理担当者コード -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-2 form-group">
                      担当者: {{this.obj.orderReceiveStaffId}}                   
                    </div>
                  </div>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="receivedOrderInputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, CreateUpdateSql, getUserCol, CreateColRow, dateConsistency, getControlMaster, formatDateCalc, formatDate, executeSelectSql, executeTransactSqlList, isSystemEditable, isAfterMonthlyUpdate } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
const MODULE_NAME = 'purchase-unit-price-edit';
export default {
  name: 'PURCHASE-UNIT-PRICE-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入単価修正',
      username: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 画面対象
      obj: {},
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: '',
        lightTaxMark: '',
      },
      // テーブル定義
      productList:[],
      // トランザクションSQLリスト
      transactSqlList:[],
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // パラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
    }
  },
  computed:{
    fields: function() {
      return [
        {
          key: 'roductCode',
          label: '製品コード',
          thStyle: 'width: 10%;',
        },
        {
          key: 'productName',
          label: '製品名',
          thStyle: 'width: 30%;',
        },
        {
          key: 'serviceClass',
          label: 'サービス区分',
          thStyle: 'width: 10%;',
        },
        {
          key: 'quantity',
          label: '数量',
          thStyle: 'width: 10%;',
        },
        {
          key: 'unit',
          label: '単位',
          thStyle: 'width: 10%;',
        },
        {
          key: 'productSalesUnitPrice',
          label: '売上単価',
          thStyle: 'width: 10%;',
        },
        {
          key: 'productPurchasePrice',
          label: '仕入単価',
          thStyle: 'width: 10%;',
        },
        {
          key: 'salesMoney',
          label: '売上金額',
          thStyle: 'width: 10%;',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      // 合計仕入単価
      let totalPurchasePrice = 0
      // 合計金額
      let totalSalesPrice = 0
      // 粗利
      let grossProfit = 0
      for (let index = 0; index < this.productList.length; index++) {
        const product = this.productList[index];
        totalPurchasePrice += Number(String(product.productPurchasePrice).replaceAll(',', '')) * Number(product.quantity)
        totalSalesPrice += Number(String(product.salesMoney).replaceAll(',', ''))
      }
      grossProfit = Math.round((totalSalesPrice - totalPurchasePrice) * 1000 / totalSalesPrice) / 10;

      let retTotalMoneyText = '粗利：' + grossProfit + ' %　';
      retTotalMoneyText += '合計仕入単価：' + totalPurchasePrice.toLocaleString() + '　円　';
      retTotalMoneyText += '合計金額：' + totalSalesPrice.toLocaleString() + '　円　';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報を取得
        let user = store.getters.user;
        this.username = user.username;
       
        // コントロールマスタ
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;

        // 既存データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 初期化
      this.statusFlg.editFlg = true;
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      let dataResult = await executeSelectSql(selectSql);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += 'offices.office_name_kanji';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.client_class';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',clients.check_contents';
      selectSql += ',cumulative_transaction.order_receive_staff_id';
      selectSql += ',order_receive_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
      selectSql += ',cumulative_transaction.process_date';
      selectSql += ',cumulative_transaction.delivery_design_date';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',cumulative_transaction.client_site_name AS client_site_name_kanji';
      selectSql += ',cumulative_transaction.client_site_zip_code';
      selectSql += ',cumulative_transaction.client_site_address_1';
      selectSql += ',cumulative_transaction.client_site_address_2';
      selectSql += ',cumulative_transaction.delivery_location';
      selectSql += ',cumulative_transaction.client_site_phone_number';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name AS product_name_kanji';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.product_unit AS unit';
      selectSql += ',cumulative_transaction.product_sales_unit_price';
      selectSql += ',cumulative_transaction.product_purchase_price';
      selectSql += ',cumulative_transaction.billing_row';
      selectSql += ',cumulative_transaction.monthly_month_year';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON cumulative_transaction.client_id = clients_sites.client_id ';
      selectSql += 'AND cumulative_transaction.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN m_staffs AS order_receive_staffs ';
      selectSql += 'ON cumulative_transaction.order_receive_staff_id = order_receive_staffs.staff_id ';
      selectSql += 'LEFT JOIN m_offices AS offices ';
      selectSql += 'ON cumulative_transaction.office_id = offices.office_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID = 'UR'
      selectSql += `cumulative_transaction.transaction_id = '${Const.TransactionId.sales}' `;
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 引数の月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },

    async setResult(result) {
      if (result.length > 0) {
        for (let index = 0; index < result.length; index++) {
          const item = result[index];
          if (index == 0) {
            // 取引先エリア
            // 伝票日付
            item.billing_date = formatDate(item.billing_date);
            if (dateConsistency(this.controlMasterData.processMonthYear + '01', item.billing_date) == false) {
              // 伝票日付が処理年月よりも過去日付の場合
              this.statusFlg.editFlg = false;
              this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
            }
            // 伝票種別
            const tradition = Const.TraditionList.find((tradition) => {
              return tradition.id == item.bill_class
            })
            item.bill_class = tradition.text
            // 締日
            // todo テスト必要
            item.closing_date = item.closing_date == 99 ? 
              formatDateCalc(String(this.controlMasterData.processMonthYear) + '01', 0, 0, 0, true) : formatDate(String(this.controlMasterData.processMonthYear) + item.closing_date);
            // 取引先コード
            item.clientName = `${item.client_id}  ${item.client_name_kanji}`;
            // 担当者
            item.staffId = `${item.staff_id}:${item.staff_name_kanji}`;

            // 現場エリア
            // 納品指定日
            item.delivery_design_date = formatDate(item.delivery_design_date) || '';
            // 現場コード
            item.clientId = `${item.site_id}:${item.client_site_name_kanji || ''}`;
            // 住所
            item.address = `${item.client_site_address_1 || ''} ${item.client_site_address_2 || ''}`;
            // 処理日
            item.process_date = formatDate(item.process_date) || '';
            // 受注処理担当者コード
            item.orderReceiveStaffId = `${item.order_receive_staff_id || ''}:${item.order_receive_staff_name_kanji || ''}`;

            this.obj = {...item}
            // 製品リスト 一個目
            this.productList.push({
              roductCode: item.product_id,
              productName: item.product_name_kanji,
              productTaxRateClass: item.product_tax_rate_class_sales,
              serviceClass: item.service_class,
              quantity: item.product_quantity,
              unit: item.unit,
              productSalesUnitPrice: Number(item.product_sales_unit_price).toLocaleString(),
              productPurchasePrice: Number(item.product_purchase_price).toLocaleString(),
              productPurchasePriceEditable: item.product_purchase_price == 0,
              salesMoney: (Number(item.product_quantity) * Number(item.product_sales_unit_price)).toLocaleString(),
              billingRow: item.billing_row,
              monthlyMonthYear: item.monthly_month_year,
            })
          } else {
            // 製品リスト
            this.productList.push({
              roductCode: item.product_id,
              productName: item.product_name_kanji,
              productTaxRateClass: item.product_tax_rate_class_sales,
              serviceClass: item.service_class,
              quantity: item.product_quantity,
              unit: item.unit,
              productSalesUnitPrice: Number(item.product_sales_unit_price).toLocaleString(),
              productPurchasePrice: Number(item.product_purchase_price).toLocaleString(),
              productPurchasePriceEditable: item.product_purchase_price == 0,
              salesMoney: (Number(item.product_quantity) * Number(item.product_sales_unit_price)).toLocaleString(),
              billingRow: item.billing_row,
              monthlyMonthYear: item.monthly_month_year,
            })
          }
          this.calc(index);
        }
      }
    },
    /* 保存ボタンの押下 */
    async saveOrder(){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      // 仕入データの保存(SQLの作成)
      if (await this.execSave() == true) {
        // 保存成功メッセージを表示
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      }
      //console.log('保存処理終了');
    },
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 仕入データの更新
        await this.updateCumulativeTransaction();

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 作成した登録用SQLを全実行
        if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
          return false;
        }
        retResult = true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
      }

      return retResult;
    },
    /* 累積トランザクション更新SQL作成 */
    async updateCumulativeTransaction() {
      //console.log('累積トランザクション更新SQL作成');
      // 累積トランザクションの更新SQL
      let updateSql = []
      for(let i = 0; i < this.productList.length; i++) {
        // 仕入単価入力部分更新
        if (!this.productList[i].productPurchasePriceEditable) continue
        // 仕入単価
        const productPurchasePrice = Number(String(this.productList[i].productPurchasePrice).replaceAll(',', ''))
        // 数量
        const quantity = Number(String(this.productList[i].quantity).replaceAll(',', ''))
        // 仕入金額
        const productPurchaseAmount = productPurchasePrice * quantity
        // 粗利額
        const grossProfit = Number(String(this.productList[i].productSalesUnitPrice).replaceAll(',', '')) * quantity - productPurchaseAmount

        let colList = [];
        // 製品仕入単価
        colList.push(CreateColRow('product_purchase_price', productPurchasePrice, 'NUMBER'));
        // 製品仕入金額
        colList.push(CreateColRow('product_purchase_amount', productPurchaseAmount, 'NUMBER'));
        // 製品粗利額
        colList.push(CreateColRow('product_gross_profit', grossProfit, 'NUMBER'));

        // 更新ユーザー
        const colUpdateUser = await getUserCol(this.username, 'update')

        const updateItem = `${CreateUpdateSql(colList.concat(colUpdateUser), 't_cumulative_transaction')} 
              WHERE transaction_id = '${Const.TransactionId.sales}' 
              AND billing_no = ${this.billingNo} 
              AND billing_row = '${this.productList[i].billingRow}' 
              AND monthly_month_year = '${this.productList[i].monthlyMonthYear}'`
        updateSql.push(updateItem)

      }

      if (updateSql.length != 0) {
        updateSql.forEach(element => {
          this.transactSqlList.push(element);
        });
      }
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].totalPurchasePrime = Number(String(this.productList[index].quantity).replaceAll(',', '')) * Number(String(this.productList[index].productPurchasePrice).replaceAll(',', ''));
    },
    // キャンセルボタン
    cancel:function(){
      this.$router.push({ name: 'PURCHASE-UNIT-PRICE-LIST'});
    },
    // 取引先照会
    clientInfoModal() {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = this.obj.client_class;
      this.propClientInquiry.clientId = this.obj.client_id;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>