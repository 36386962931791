<template>
  <!-- カレンダーマスタ保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <!-- ●●●ボディ●●● -->
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> カレンダーマスタ保守入力</strong></h5>
                <router-link to="/menu-master-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <b-card>
            <b-card-header>
              <div v-if="errorMessages.length" class="alert alert-danger" role="alert">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </b-card-header>
            <div class="mb-3">カレンダーの日付を選択してください。営業日と休業日を切り替えることが出来ます。<br>営業日は白、休業日は赤で表示されます。</div>
            <b-calendar
              block
              class="pb-4"
              :date-info-fn="dateInfo"
              :hide-header="true"
              label-current-month="今月を表示します。"
              label-help="日付をクリックすると、カーソルキーを使用してのカレンダーの日付移動、Enterキーでの日付選択を行うことができるようになります。"
              label-next-month="翌月を表示します。"
              label-next-year="翌年を表示します。"
              label-prev-month="前月を表示します。"
              label-prev-year="前年を表示します。"
              locale="ja-JP"
              :no-highlight-today="true"
              @context="onContext"
              @selected="selected"
            ></b-calendar>
          </b-card>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, escapeQuote, formatDate, CreateColRow, CreateInsertSql, isSystemEditable, isDayHoliday } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_calendar } from '@/graphql/queries';
import { delete_m_calendar, executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'calendar-master-maintenance';

export default {
  name: 'CALENDAR-MASTER-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'カレンダーマスタ保守',
      dates: [],
      activeMonth: undefined,
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * イベントデータに存在する日付の場合は日付の背景色を変更します。
     * @param ymd {String} - 文字列形式の日付
     * @returns {String} クラス名
     */
    dateInfo(ymd) {
      if (isDayHoliday(ymd) == true) {
        return 'table-danger';
      } else {
        return this.dates.find(item => item === ymd) ? 'table-danger' : '';
      }
    },
    /**
     * selected イベントを処理します。
     * イベントデータに存在しない場合は追加、存在する場合は追加します。
     * @param ymd {String} - 選択された日付
     */
    async selected(ymd) {
      if (isDayHoliday(ymd) == true) {
        // 休日の場合は何もしない
        return;
      }
      this.$store.commit('setLoading', true);
      const functionName = 'selected';
      this.errorMessages = [];

      const newDates = this.dates.filter(item => item !== ymd);
      if (this.dates.length > newDates.length) {
        // イベント削除
        const deleteVariables = {
          delete_m_calendarInput: {
            date: ymd,
            event_class: Const.CalendarEventClass.Holiday
          }
        };

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.errorMessages.push(msg);
            this.$store.commit('setLoading', false);
            return;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
          this.$store.commit('setLoading', false);
          return;
        }

        let deleteResult = null;
        try {
          deleteResult = await API.graphql(graphqlOperation(delete_m_calendar, deleteVariables));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'delete_m_calendar',
            variables: deleteVariables
          }, error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
          this.$store.commit('setLoading', false);
          return;
        }
        if (deleteResult.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'delete_m_calendar',
            variables: deleteVariables,
            result: deleteResult
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
          this.$store.commit('setLoading', false);
          return;
        }
        this.dates = newDates;
      } else {
        // イベント追加
        const colList = [];
        colList.push(CreateColRow('date', ymd, 'VARCHAR'));
        colList.push(CreateColRow('event_class', Const.CalendarEventClass.Holiday, 'NUMBER'));
        const username = await escapeQuote(this.$store.getters.user.username);
        colList.push(CreateColRow('created_user', username, 'VARCHAR'));
        colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
        const sql = CreateInsertSql(colList, 'full', 'm_calendar');
        const sqls = [sql];

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.errorMessages.push(msg);
            this.$store.commit('setLoading', false);
            return;
          }
        } catch (error) {
          const msg = DISP_MESSAGES.DANGER['3005'];
          await addOperationLogs('Error', MODULE_NAME, functionName, msg, error);
          this.errorMessages.push(msg);
          this.$store.commit('setLoading', false);
          return;
        }

        let executeTransactSqlResult = null;
        try {
          executeTransactSqlResult = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqls
          }, error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
          this.$store.commit('setLoading', false);
          return;
        }
        if (executeTransactSqlResult.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqls,
            result: executeTransactSqlResult
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
          this.$store.commit('setLoading', false);
          return;
        }

        this.dates.push(ymd);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * contextイベントを処理します。
     * 月が変更されるとカレンダーマスタからイベントデータを取得し直します。
     * @param ctx {Object} - コンテキスト
     */
    async onContext(ctx) {
      const functionName = 'onContext';

      // 月が替わったかどうかをチェックします。
      const activeDate = new Date(ctx.activeDate.getTime());
      activeDate.setDate(1);
      if (this.activeMonth && this.activeMonth.getTime() === activeDate.getTime()) {
        return;
      } else {
        this.activeMonth = activeDate;
      }

      // 取得期間を設定します。
      const start = new Date(activeDate.getTime());
      start.setDate(start.getDate() - 6);
      const end = new Date(activeDate.getTime());
      end.setMonth(end.getMonth() + 1);
      end.setDate(end.getDate() + 5);
      const whereClause = `AND date BETWEEN '${formatDate(start, 'YYYY-MM-DD')}' AND '${formatDate(end, 'YYYY-MM-DD')} AND event_class = ${Const.CalendarEventClass.Holiday}'`;

      // カレンダーマスタからイベントを取得します。
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_calendar, {
          where_clause: whereClause
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_calendar',
          where_clause: whereClause
        }, error);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_calendar',
          where_clause: whereClause,
          result: result
        });
        return;
      }

      // 表示用データに詰め替えます。
      const newDates = [];
      for (const item of result.data.list_m_calendar) {
        newDates.push(item.date);
      }
      this.dates = newDates;
    }
  }
}
</script>
