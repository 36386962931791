<template>
  <b-modal id="routeSearchModal" size="xl" centered title="ルート検索" @show="showModal()">
    <b-container class="px-0">
      <b-row>
        <b-col>
          <!-- ●●●検索条件●●● -->
          <div class="main-card card px-3 pt-2">
            <div class="mb-0" id="heading1">
              <h5 class="mb-0">
                <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
            </div>
            <b-collapse id="collapse-1" visible>
              <b-card-body class="p-1">
                <b-form @submit.prevent="searchButton();" id="routeSearchForm" class="form-horizontal">
                  <validation-observer ref="observer">
                    <b-row>
                      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 form-group px-1">
                        <validation-provider name="shippingCode" rules="max:5" v-slot="{ classes, errors }">
                          <b-input-group>
                            <template #prepend>
                              <label for="shippingCode">
                                <b-input-group-text  class="px-1">
                                  配送コード
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="search" v-model="searchConditions.shippingCode" maxlength="5"></b-form-input>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-input-group>
                        </validation-provider>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 form-group px-1">
                        <validation-provider name="shippingName" rules="max:12" v-slot="{ classes, errors }">
                          <b-input-group>
                            <template #prepend>
                              <label for="shippingName">
                                <b-input-group-text  class="px-1">
                                  配送名
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="search" class="form-control" v-model="searchConditions.shippingName" maxlength="12"></b-form-input>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-input-group>
                        </validation-provider>
                      </div>
                    </b-row>
                  </validation-observer>
                  <!-- 検索ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="3">
                      <b-button block pill variant="success" size="sm" type="submit" form="routeSearchForm"><span class="oi oi-magnifying-glass"></span> 検 索</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error, index) in resultError" :key="index" style="list-style: none">
            <li>{{ error }}</li>
          </ul>
        </b-alert>
        <b-col class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="7" class="my-1">
              <b-form-group label="1ページの表示件数" label-for="per-page-select" label-cols-sm="4" label-size="sm" class="mb-0">
                <b-col lg="4" class="pl-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <b-col lg="5" class="my-1">
              <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table show-empty :small="true" :items="routeList" :fields="fields" :busy="busy" :filter="filter" :per-page="perPage" :current-page="currentPage" :sticky-header="true">
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
            <template #cell(detail)="detail">
              <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'対象のデータで入力します。'" @click="choice(detail.item)" class="mr-1"><span class="oi oi-check"></span> 選択</b-button>
            </template>
          </b-table>
          <!-- テーブルページネーション -->
          <b-col class="my-0">
            <b-pagination v-model="currentPage" :total-rows="routeList.length" :per-page="perPage"  size="sm" align="center" class="my-0"></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <template #modal-footer>
      <b-button size="sm" @click="$bvModal.hide('routeSearchModal')" class="mr-1">キャンセル</b-button>
    </template>
  </b-modal>
  <!-- ●●●ルート検索モーダル●●● -->
</template>
<script>
import { init, convertSqlLikeSpecialChar } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_routes } from '@/graphql/queries';

// 検索結果件数リミット
const LIMIT = 1000;

export default {
  name: 'ROUTE-SEARCH',
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'ルート検索',
      // 検索条件
      searchConditions: {
        shippingCode: '',
        shippingName: '',
      },
      // 検索結果
      resultError: [],
      routeList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'detail',
          label: ''
        },
        {
          key: 'shippingCode',
          label: '配送コード',
          sortable: true,
        },
        {
          key: 'shippingName',
          label: '配送名',
          sortable: true,
        },
      ];
    },
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods: {
    /* ルート検索 */
    async search(conditions) {
      this.routeList = [];
      this.resultError = [];

      let whereClause = '';
      if (conditions !== null && conditions.length > 0) {
        whereClause += conditions;
      }
      whereClause += 'ORDER BY shipping_code ASC LIMIT ' + LIMIT;

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(list_m_routes, { where_clause: whereClause }));
      } catch (error) {
        console.error(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      if (result.errors) {
        console.error(result.errors);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      for (const mRoute of result.data.list_m_routes) {
        this.routeList.push({
          shippingCode: mRoute.shipping_code,
          shippingName: mRoute.shipping_name
        });
      }

      if (this.routeList.length >= LIMIT) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /* 画面表示時 */
    async showModal() {
      //this.busy = true;
      this.searchConditions.shippingCode = '';
      this.searchConditions.shippingName = '';
      //await this.search(null);
      //this.busy = false;
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.busy = true;
      if (this.$refs.observer.validate()) {
        let conditions = '';
        if (this.searchConditions.shippingCode.length > 0) {
          conditions += `AND shipping_code = '${this.searchConditions.shippingCode}' `
        }
        if (this.searchConditions.shippingName.length > 0) {
          conditions += `AND REPLACE(REPLACE(CONVERT(shipping_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(this.searchConditions.shippingName)}%', 'ﾞ', ''), 'ﾟ', '') `
        }
        await this.search(conditions);
      } else {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
      this.busy = false;
    },
    /* 選択ボタン押下時 */
    choice(choiceData) {
      this.$emit('from-child', choiceData);
      this.$bvModal.hide('routeSearchModal');
    }
  }
};
</script>
<style scoped></style>
