<template>
  <div class="text-center">
    <div v-for="(page, index) of pages" :key="index">
      <TemplateMonthlyAccountPayableSupplyList :id="`page-${index}`"/>
    </div>
  </div>
</template>
<script>
import TemplateMonthlyAccountPayableSupplyList from '@/assets/svg/monthly_AccountPayableSupplyList.svg';
import { addOperationLogs, formatCurDate, setPaperA4Landscape } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'accounts-payable-supply-list-print';
// テーブル項目テンプレート
const TBALE_ITEMS_TEMPLATE = {
  supplier_id: '', // 仕入先コード
  client_name_kanji: '', // 取引先名（漢字）
  last_month_payable_no_tax_balance_result: 0, // 前月税抜残高
  last_month_payable_tax_balance_result: 0, // 前月消費税残高
  monthly_payable: 0, // 仕入額
  monthly_payment: 0, // 現金支払額
  monthly_check_payment: 0, // 小切手支払額
  monthly_transfer_payment: 0, // 振込支払額
  monthly_bill_payment: 0, // 手形支払額
  monthly_offset_payment: 0, // 相殺支払額
  monthly_other_payment: 0, // その他支払額
  monthly_tax_payment: 0, // 消費税支払額
  monthly_bulk_tax: 0, // 一括消費税額
  monthly_billing_tax: 0, // 伝票消費税額
  monthly_tax: 0 // 消費税額
};
// ページあたりの出力件数
const PER_PAGE = 10;
// 合計用の仕入先コード
const SUPPLIER_ID_TOTAL = 'total';
// 各種金額列の左右パディング
const PADDING = 8;

export default {
  menu_type: 'user',
  name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST-PRINT',
  components: {
    TemplateMonthlyAccountPayableSupplyList
  },
  data() {
    return {
      title: '仕入先別買掛実績表',
      pages: []
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  beforeMount() {
    this.$store.commit('setLoading', true);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    // 印刷レイアウトを設定します。
    setPaperA4Landscape();
    // 帳票を作成します。
    const query = this.$route.query;
    this.createForm(query.monthYear, query.supplierIdFrom, query.supplierIdTo);
  },
  methods: {
    /**
     * 帳票を作成します。
     * @param {String} monthYear - 処理年月
     * @param {String} supplierIdFrom - 開始仕入先コード
     * @param {String} supplierIdTo - 終了仕入先コード
     */
    createForm(monthYear, supplierIdFrom, supplierIdTo) {
      const functionName = 'createForm';

      // 買掛実績・残高マスタからデータを取得します。
      this.fetchData(monthYear, supplierIdFrom, supplierIdTo).then(response => {
        if (response === null) {
          this.$store.commit('setLoading', false);
          this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2001'], {
            title: this.title
          }).then(() => {
            window.close();
          });
          return;
        }

        // 帳票タイトル文字列を作成します。
        const year = Math.floor(monthYear / 100 % 100);
        const month = String(monthYear % 100).padStart(2, '0');
        const title = '仕　入　先　別　買　掛　実　績　表　' + `${year}/${month}`;

        // 帳票データを作成します。this.pages にデータが代入された時点で、
        // 作成するページ分の帳票テンプレートがレンダリングされます。
        this.pages = this.createFormData(response);

        // 出力日時文字列を作成します。
        const outputDateTime = formatCurDate('YY/MM/DD HH:mm');

        // 帳票テンプレートに対して値の置換と表示位置調整を行います。
        this.setPageData(0, title, outputDateTime);
      }).catch(async error => {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.$store.commit('setLoading', false);
        this.$bvModal.msgBoxOk(DISP_MESSAGES.WARNING['2001'], {
          title: this.title
        }).then(() => {
          window.close();
        });
      });
    },
    /**
     * DBから買掛実績・残高マスタデータを取得します。
     * @param {String} monthYear - 処理年月
     * @param {String} supplierIdFrom - 開始仕入先コード
     * @param {String} supplierIdTo - 終了仕入先コード
     * @returns {Array} 買掛実績・残高マスタデータ
     */
    async fetchData(monthYear, supplierIdFrom, supplierIdTo) {
      const functionName = 'fetchData';

      const sql = await this.createSQL(monthYear, supplierIdFrom, supplierIdTo);
      if (sql === null) {
        return null;
      }
      const sqls = [ sql ];

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        return null;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        return null;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        return null;
      }
      return body.data[0];
    },
    /**
     * 買掛実績・残高マスタ取得用SQLを作成します。
     * @param {String} monthYear - 処理年月
     * @param {String} supplierIdFrom - 開始仕入先コード
     * @param {String} supplierIdTo - 終了仕入先コード
     * @returns {String} 成功した場合はSQL文、失敗した場合はnull
     */
    async createSQL(monthYear, supplierIdFrom, supplierIdTo) {
      const whereClauses = [];
      whereClauses.push(`tpbr.month_year = ${monthYear}`);
      if (supplierIdFrom !== '' && supplierIdTo !== '') {
        whereClauses.push(`tpbr.supplier_id BETWEEN ${supplierIdFrom} AND ${supplierIdTo}`);
      } else if (supplierIdFrom !== '') {
        whereClauses.push(`tpbr.supplier_id >= ${supplierIdFrom}`);
      } else if (supplierIdTo !== '') {
        whereClauses.push(`tpbr.supplier_id <= ${supplierIdTo}`);
      }

      return 'SELECT' +
        ' tpbr.month_year' +
        ',tpbr.supplier_id' +
        ',tpbr.last_month_payable_no_tax_balance_result' +
        ',tpbr.last_month_payable_tax_balance_result' +
        ',tpbr.monthly_payable' +
        ',tpbr.monthly_payment' +
        ',tpbr.monthly_check_payment' +
        ',tpbr.monthly_transfer_payment' +
        ',tpbr.monthly_bill_payment' +
        ',tpbr.monthly_offset_payment' +
        ',tpbr.monthly_other_payment' +
        ',tpbr.monthly_tax_payment' +
        ',tpbr.monthly_bulk_tax' +
        ',tpbr.monthly_billing_tax' +
        ',tpbr.monthly_tax' +
        ',mc.client_name_kanji ' +
        'FROM' +
        ' t_payables_balances_results tpbr' +
        ' JOIN m_clients mc ON mc.client_class = 2 AND tpbr.supplier_id = mc.client_id ' +
        'WHERE' +
        ` ${whereClauses.join(' AND ')} ` +
        'ORDER BY tpbr.supplier_id ASC';
    },
    /**
     * 買掛実績・残高マスタデータを集計して帳票データを作成します。
     * @param {Array} records - 買掛実績・残高マスタデータ
     * @returns {Array} 作成した帳票データ
     */
    createFormData(records) {
      const pages = [];
      let page = null;
      const total = { ...TBALE_ITEMS_TEMPLATE };
      total.supplier_id = SUPPLIER_ID_TOTAL;

      // データの設定
      if (records.length === 0) {
        page = [ total ];
        pages.push(page);
      } else {
        for (const record of records) {
          if (pages.length === 0 || page.length === PER_PAGE) {
            page = [];
            pages.push(page);
          }

          // 仕入先コード
          record.supplier_id = String(record.supplier_id).padStart(6, '0');
          // 買掛税抜残高
          total.last_month_payable_no_tax_balance_result += record.last_month_payable_no_tax_balance_result;
          // 買掛消費税残高
          total.last_month_payable_tax_balance_result += record.last_month_payable_tax_balance_result;
          // 仕入額
          total.monthly_payable += record.monthly_payable;
          // 現金支払額
          total.monthly_payment += record.monthly_payment;
          // 小切手支払額
          total.monthly_check_payment += record.monthly_check_payment;
          // 振込支払額
          total.monthly_transfer_payment += record.monthly_transfer_payment;
          // 手形支払額
          total.monthly_bill_payment += record.monthly_bill_payment;
          // 相殺支払額
          total.monthly_offset_payment += record.monthly_offset_payment;
          // その他支払額
          total.monthly_other_payment += record.monthly_other_payment;
          // 消費税支払額
          total.monthly_tax_payment += record.monthly_tax_payment;
          // 一括消費税額
          total.monthly_bulk_tax += record.monthly_bulk_tax;
          // 伝票消費税額
          total.monthly_billing_tax += record.monthly_billing_tax;
          // 消費税額
          total.monthly_tax += record.monthly_tax;

          page.push(record);
        }

        if (page.length === PER_PAGE) {
          page = [];
          pages.push(page);
        }
        page.push(total);
      }

      for (let i = page.length; i < PER_PAGE; i++) {
        page.push({ supplier_id: null });
      }

      return pages;
    },
    /**
     * 指定されたページのSVGに帳票データを設定します。
     * @param {Number} pageIndex - ページ番号（0始まり）
     * @param {String} title - 帳票タイトル置換文字列
     * @param {String} outDateTime - 帳票出力日時置換文字列
     * @returns {Promise} Promiseオブジェクト
     */
    setPageData(pageIndex, title, outputDateTime) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          try {
            const pageData = this.pages[pageIndex];
            const page = $(`svg#page-${pageIndex}`);
            this.resizePage(page);

            // ヘッダー幅
            let lastMonthPayableNoTaxBalanceResultWidth = 0; // 買掛税抜残高・買掛消費税残高・支払額の列の幅
            let monthlyPaymentWidth = 0; // 現金支払額・小切手支払額・振込支払額の列の幅
            let monthlyBillPaymentWidth = 0; // 手形支払額・相殺支払額・その他支払額の列の幅
            let monthlyTaxPaymentWidth = 0; // 消費税支払額・一括消費税額・伝票消費税額
            let monthlyTaxWidth = 0; // 消費税額

            for (let rowIndex = 0; rowIndex < pageData.length; rowIndex++) {
              const rowData = pageData[rowIndex];
              const rowNo = rowIndex + 1;

              let element = null;
              if (rowIndex === 0) {
                element = page.find('tspan:contains("%TITLE%")');
                element.text(element.text().replace('%TITLE%', title));
                element.attr('x', (element.parent().parent()[0].getBBox().width - element[0].getBBox().width) / 2);

                // 日付／ページ番号
                element = page.find('tspan:contains("%DATE_TIME%")');
                element.text(element.text()
                  .replace('%DATE_TIME%', outputDateTime)
                  .replace('%PAGE%', String(pageIndex + 1).padStart(3, ' '))
                );

                // 買掛税抜残高
                lastMonthPayableNoTaxBalanceResultWidth = this.replaceHeaderValue(page, '%0_2_1%', '買掛税抜残高');
                // 買掛消費税残高
                this.replaceValueAlignRight(page, '%0_2_2%', '買掛消費税残高', lastMonthPayableNoTaxBalanceResultWidth);
                // 仕入額
                this.replaceValueAlignRight(page, '%0_2_3%', '仕入額', lastMonthPayableNoTaxBalanceResultWidth);
                // 現金支払額
                monthlyPaymentWidth = this.replaceHeaderValue(page, '%0_3_1%', '現金支払額');
                // 小切手支払額
                this.replaceValueAlignRight(page, '%0_3_2%', '小切手支払額', monthlyPaymentWidth);
                // 振込支払額
                this.replaceValueAlignRight(page, '%0_3_3%', '振込支払額', monthlyPaymentWidth);
                // 手形支払額
                monthlyBillPaymentWidth = this.replaceHeaderValue(page, '%0_4_1%', '手形支払額');
                // 相殺支払額
                this.replaceValueAlignRight(page, '%0_4_2%', '相殺支払額', monthlyBillPaymentWidth);
                // その他支払額
                this.replaceValueAlignRight(page, '%0_4_3%', 'その他支払額', monthlyBillPaymentWidth);
                // 消費税支払額
                monthlyTaxPaymentWidth = this.replaceHeaderValue(page, '%0_5_1%', '消費税支払額');
                // 一括消費税額
                this.replaceValueAlignRight(page, '%0_5_2%', '一括消費税額', monthlyTaxPaymentWidth);
                // 伝票消費税額
                this.replaceValueAlignRight(page, '%0_5_3%', '伝票消費税額', monthlyTaxPaymentWidth);
                // 消費税額
                monthlyTaxWidth = this.replaceHeaderValue(page, '%0_6_1%', '消費税額');
              }

              let supplier_id = ''; // 仕入先コード
              let client_name_kanji = ''; // 取引先名（漢字）
              let last_month_payable_no_tax_balance_result = ''; // 前月税抜残高
              let last_month_payable_tax_balance_result = ''; // 前月消費税残高
              let monthly_payable = ''; // 仕入額
              let monthly_payment = ''; // 現金支払額
              let monthly_check_payment = ''; // 小切手支払額
              let monthly_transfer_payment = ''; // 振込支払額
              let monthly_bill_payment = ''; // 手形支払額
              let monthly_offset_payment = ''; // 相殺支払額
              let monthly_other_payment = ''; // その他支払額
              let monthly_tax_payment = ''; // 消費税支払額
              let monthly_bulk_tax = ''; // 一括消費税額
              let monthly_billing_tax = ''; // 伝票消費税額
              let monthly_tax = ''; // 消費税額

              if (rowData.supplier_id !== null) {
                if (rowData.supplier_id === SUPPLIER_ID_TOTAL) {
                  // ＜  合  計  ＞
                  client_name_kanji = '＜ 合  計 ＞'; 
                } else {
                  // 仕入先コード
                  supplier_id = String(rowData.supplier_id).padStart(6, '0');
                  // 仕入先名
                  client_name_kanji = rowData.client_name_kanji;
                }

                // 前月税抜残高
                last_month_payable_no_tax_balance_result = rowData.last_month_payable_no_tax_balance_result.toLocaleString();
                // 前月消費税残高
                last_month_payable_tax_balance_result = rowData.last_month_payable_tax_balance_result.toLocaleString();
                // 月次仕入額
                monthly_payable = rowData.monthly_payable.toLocaleString();

                // 現金支払額
                monthly_payment = rowData.monthly_payment.toLocaleString();
                // 小切手支払額
                monthly_check_payment = rowData.monthly_check_payment.toLocaleString();
                // 振込支払額
                monthly_transfer_payment = rowData.monthly_transfer_payment.toLocaleString();

                // 手形支払額
                monthly_bill_payment = rowData.monthly_bill_payment.toLocaleString();
                // 相殺支払額
                monthly_offset_payment = rowData.monthly_offset_payment.toLocaleString();
                // その他支払額
                monthly_other_payment = rowData.monthly_other_payment.toLocaleString();

                // 消費税支払額
                monthly_tax_payment = rowData.monthly_tax_payment.toLocaleString();
                // 一括消費税額
                monthly_bulk_tax = rowData.monthly_bulk_tax.toLocaleString();
                // 伝票消費税額
                monthly_billing_tax = rowData.monthly_billing_tax.toLocaleString();

                // 消費税額
                monthly_tax = rowData.monthly_tax.toLocaleString();
              }

              // 取引先コード
              this.replaceValue(page, `%${rowNo}_1_1%`, supplier_id);
              // 取引先名
              this.replaceValue(page, `%${rowNo}_1_2%`, client_name_kanji);

              // 買掛税抜残高
              this.replaceValueAlignRight(page, `%${rowNo}_2_1%`, last_month_payable_no_tax_balance_result, lastMonthPayableNoTaxBalanceResultWidth);
              // 買掛消費税残高
              this.replaceValueAlignRight(page, `%${rowNo}_2_2%`, last_month_payable_tax_balance_result, lastMonthPayableNoTaxBalanceResultWidth);
              // 仕入額
              this.replaceValueAlignRight(page, `%${rowNo}_2_3%`, monthly_payable, lastMonthPayableNoTaxBalanceResultWidth);

              // 現金支払額
              this.replaceValueAlignRight(page, `%${rowNo}_3_1%`, monthly_payment, monthlyPaymentWidth);
              // 小切手支払額
              this.replaceValueAlignRight(page, `%${rowNo}_3_2%`, monthly_check_payment, monthlyPaymentWidth);
              // 振込支払額
              this.replaceValueAlignRight(page, `%${rowNo}_3_3%`, monthly_transfer_payment, monthlyPaymentWidth);

              // 手形支払額
              this.replaceValueAlignRight(page, `%${rowNo}_4_1%`, monthly_bill_payment, monthlyBillPaymentWidth);
              // 相殺支払額
              this.replaceValueAlignRight(page, `%${rowNo}_4_2%`, monthly_offset_payment, monthlyBillPaymentWidth);
              // その他支払額
              this.replaceValueAlignRight(page, `%${rowNo}_4_3%`, monthly_other_payment, monthlyBillPaymentWidth);

              // 消費税支払額
              this.replaceValueAlignRight(page, `%${rowNo}_5_1%`, monthly_tax_payment, monthlyTaxPaymentWidth);
              // 一括消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_5_2%`, monthly_bulk_tax, monthlyTaxPaymentWidth);
              // 伝票消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_5_3%`, monthly_billing_tax, monthlyTaxPaymentWidth);

              // 消費税額
              this.replaceValueAlignRight(page, `%${rowNo}_6_1%`, monthly_tax, monthlyTaxWidth);
            }

            const nextPageIndex = pageIndex + 1;
            if (nextPageIndex < this.pages.length) {
              this.setPageData(nextPageIndex, title, outputDateTime);
            } else {
              this.$store.commit('setLoading', false);
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 0);
      });
    },
    /**
     * ページサイズを調整します。
     * @param {Object} page - ページ
     */
    resizePage(page) {
      page.attr('viewBox', `0 0 ${page.innerWidth()} ${page.innerHeight()}`);
      page.attr('width', '297mm');
      page.attr('height', '209mm');
    },
    /**
     * 指定されたページのキーワードを指定された値に置換します。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @returns {Object} 文字列置換を行った要素
     */
    replaceValue(page, keyword, value) {
      const element = page.find(`tspan:contains("${keyword}")`);
      element.text(element.text().trimEnd().replace(keyword, value));
      return element;
    },
    /**
     * 指定されたページからキーワードを含む要素を探し出して文字列を置換し、右寄せにします。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @returns {Number} 見つけた要素の親フレームの幅
     */
    replaceHeaderValue(page, keyword, value) {
      const element = this.replaceValue(page, keyword, value);
      const fieldWidth = element.parent().prev()[0].getBBox().width;
      this.setAlignRight(element, fieldWidth);
      return fieldWidth;
    },
    /**
     * 指定されたページからキーワードを含む要素を探し出して文字列を置換し、右寄せします。
     * @param {Object} page - 置換を行うページ
     * @param {String} keyword - 置換対象文字列
     * @param {Number|String} value - 置換文字列
     * @param {Number} fieldWidth - フィールド幅
     */
    replaceValueAlignRight(page, keyword, value, fieldWidth) {
      const element = this.replaceValue(page, keyword, value);
      this.setAlignRight(element, fieldWidth);
    },
    /**
     * 指定されたJQuery要素を親要素の親要素の幅に合わせて右寄せします。
     * @param {Object} element - 位置を調整するtspan要素
     * @param {Number} fieldWidth - フィールド幅
     */
    setAlignRight(element, fieldWidth) {
      const x = Number(element.attr('x'));
      const width = element[0].getBBox().width;
      element.attr('x', x - PADDING + fieldWidth - width);
    }
  }
}
</script>
