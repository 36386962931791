/**
 * adminCreateUser/adminDeleteUser/adminUpdateUserAttributes
 * 上記3関数はAmplify Authの関数でないため、別途amplifyが自動生成するauthロールにポリシーをアタッチする必要あり
 * {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": [
                "cognito-idp:AdminDeleteUser",
                "cognito-idp:AdminCreateUser",
                "cognito-idp:AdminUpdateUserAttributes"
            ],
            "Resource": "arn:aws:cognito-idp:ap-northeast-1:{AWS AccountId}:userpool/{UserPoolId}"
        }
    ]
   }
 */
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { addOperationLogs } from '@/assets/js/common.js';
import setting from '@/assets/setting/setting';
const MODULE_NAME = 'cognito.js'
// ログイン中ユーザーのログイン情報を返却
export async function getCurrentAuthenticatedUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log('error on getCurrentAuthenticatedUser');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'getCurrentAuthenticatedUser', {}, error);
    throw error;
  }
}
// ログイン中ユーザーの認証情報を返却
export async function getCredential() {
  try {
    return await Auth.currentCredentials();
  } catch (error) {
    console.log('error on getCredential');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'getCredential', {}, error);
    throw error;
  }
}

// Cognitoのサービスインターフェースオブジェクトを返却
export async function getIdentityServiceProvider() {
  try {
    return new AWS.CognitoIdentityServiceProvider({
      credentials: await getEssentialCredentials(),
      region: setting.region,
    });
  } catch (error) {
    console.log('error on getIdentityServiceProvider');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'getIdentityServiceProvider', {}, error);
    throw error;
  }
}

// Cognitoのコンパクト認証情報を返却
export async function getEssentialCredentials() {
  try {
    return Auth.essentialCredentials(await getCredential());
  } catch (error) {
    console.log('error on getEssentialCredentials');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'getEssentialCredentials', {}, error);
    throw error;
  }
}

// ログインユーザーのユーザープールIDを返却
export async function getUserPoolId() {
  try {
    const currentUserPoolUser = await Auth.currentUserPoolUser();
    return currentUserPoolUser.pool.userPoolId;
  } catch (error) {
    console.log('error on getUserPoolId');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'getUserPoolId', {}, error);
    throw error;
  }
}

// Cognitoユーザーを作成
export async function createCognitoUser(userId, mailAddress, password) {
  try {
    const userPoolId = await getUserPoolId();
    const cognitoIdentity = await getIdentityServiceProvider()
    console.log({userPoolId});
    console.log({cognitoIdentity});

    await cognitoIdentity.adminCreateUser({
      UserPoolId: userPoolId,
      MessageAction: 'SUPPRESS',
      TemporaryPassword: password,
      UserAttributes: [
        {
          Name: 'email',
          Value: mailAddress,
        },
      ],
      Username: userId,
    }).promise();

    // メールアドレスを認証済みに変更
    await cognitoIdentity.adminUpdateUserAttributes({
      UserAttributes: [
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
      UserPoolId: userPoolId,
      Username: userId,
    }).promise();
  } catch (error) {
    console.log('error on createCognitoUser');
    console.log(error);
    addOperationLogs('ERROR', MODULE_NAME, 'createCognitoUser', {userId: userId}, error);
    throw error;
  }
}

// Cognitoユーザーを削除
export async function deleteCognitoUser(userId) {
  try {
    const deleteParams = {
      Username: userId, // userNameに削除対象のユーザーIDをセットしておく
      UserPoolId: await getUserPoolId(),
    };

    const cognitoIdentity = await getIdentityServiceProvider();

    await cognitoIdentity.adminDeleteUser(deleteParams).promise();
  } catch (error) {
    console.log('cognito error deleting user ' + userId + ':' + error.message);
    addOperationLogs('Error', MODULE_NAME, 'deleteCognitoUser', {userId: userId}, error);
  }
}