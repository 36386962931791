<template>
  <div>
    <a class="btn btn-link btn-lg btn-block text-primary" style="font-size: 12px" data-toggle="modal" data-target="#editTextModal">
      <u>パスワードのリセット</u>
    </a>
    <!-- ●●●テキスト編集モーダル●●● -->
    <div
      class="modal fade"
      id="editTextModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editTextModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-secondary" id="editTextModalLabel">
              <span class="text-primary oi oi-loop-circular"></span>
              パスワードのリセット
            </h5>
          </div>
          <div class="modal-body">
            <div class="container col-md-12">
              <div class="row">
                <div v-show="!complete && alertMessage" class="mx-auto alert alert-warning" role="alert" v-html="alertMessage"></div>
                <div v-show="errorMessage" class="mx-auto alert alert-danger" role="alert" v-html="errorMessage"></div>
                <div v-show="complete" class="mx-auto alert alert-success" role="alert">パスワードの更新が完了しました</div>
                <div class="col-md-12">
                  <div class="row form-group">
                    <div class="col col-md-12">
                      <label class="form-control-label">ユーザーID</label>
                    </div>
                    <div class="col-12 col-md-12">
                      <input type="text" class="form-control valid" v-model="loginID" :disabled="onReset || complete" />
                    </div>
                  </div>
                </div>
                <div v-if="onReset" class="col-md-12">
                  <div class="row form-group">
                    <div class="col col-md-12">
                      <label class="form-control-label">リセットコード</label>
                    </div>
                    <div class="col-12 col-md-12">
                      <input type="text" class="form-control valid" v-model.trim="resetCode" />
                    </div>
                    <div class="col col-md-12">
                      <label class="form-control-label">新パスワード</label>
                    </div>
                    <div class="col-12 col-md-12">
                      <input type="password" class="form-control valid" v-model="newPassword" maxlength="128" />
                    </div>
                    <div class="col col-md-12">
                      <label class="form-control-label">新パスワード（確認）</label>
                    </div>
                    <div class="col-12 col-md-12">
                      <input type="password" class="form-control valid" v-model="confirmPassword" maxlength="128" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="!onReset && !complete" class="btn-inner">
              <button type="button" class="btn btn-primary btn-block" @click="sendPasswordReset" tabindex="-1">
                <span class="oi oi-circle-check"></span>
                送信
              </button>
              <br />
            </div>
            <div v-if="onReset" class="btn-inner">
              <button type="button" class="btn btn-primary btn-block" @click="confirmPasswordReset" tabindex="-1">
                <span class="oi oi-circle-check"></span>
                変更
              </button>
              <br />
            </div>
            <div class="btn-inner">
              <button type="button" class="btn btn-cancel btn-block" data-dismiss="modal" tabindex="-1">
                <span class="oi oi-action-undo"></span>
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●テキスト編集モーダル●●● -->
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import $ from 'jquery';
export default {
  name: 'PasswordResetModal',
  data() {
    return {
      loginID: '',
      resetCode: '',
      newPassword: '',
      confirmPassword: '',
      onReset: false,
      alertMessage: '',
      errorMessage: '',
      warning: true,
      complete: false,
    };
  },
  mounted() {
    this.alertMessage = '登録しているユーザーIDを入力してください<br>リセットコードが通知されます';

    let self = this;
    // モーダルクローズ時処理
    $('#editTextModal').on('hidden.bs.modal', function () {
      self.loginID = '';
      self.resetCode = '';
      self.newPassword = '';
      self.confirmPassword = '';
      self.alertMessage = '登録しているユーザーIDを入力してください<br>リセットコードが通知されます';
      self.errorMessage = '';
      self.onReset = false;
      self.complete = false;
    });
  },
  methods: {
    // パスワードリセットメール送信処理
    async sendPasswordReset() {
      this.alertMessage = '';
      this.errorMessage = '';

      if(!this.loginID) {
        this.alertMessage = 'ユーザーIDを入力してください';
        return;
      }

      try {
        await Auth.forgotPassword(this.loginID)
        this.alertMessage = 'リセットコードを送信しました<br>リセットコードと新しいパスワードを入力してください';
        this.onReset = true;
      } catch (error) {
        console.log({error});
        if (error.code) {
          switch (error.code) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
            this.errorMessage = '存在しないユーザーIDです';
            break;
          default:
            break;
          }
        }
      }
    },
    // パスワードリセット処理
    async confirmPasswordReset() {
      this.alertMessage = '';
      this.errorMessage = '';

      if(!this.newPassword || !this.confirmPassword) {
        this.alertMessage = '新しいパスワードを入力してください';
        return;
      }
      if(this.newPassword !== this.confirmPassword) {
        this.alertMessage = '同じパスワードを入力してください';
        return;
      }

      try {
        await Auth.forgotPasswordSubmit(this.loginID, this.resetCode, this.newPassword);
        this.onReset = false;
        this.complete = true;
      } catch (error) {
        if (error.code) {
          switch (error.code) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
            this.errorMessage = '存在しないユーザーIDです';
            break;
          case 'InvalidPasswordException':
            this.errorMessage =
                'パスワードは数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上含んだ8文字以上の文字列を設定してください。';
            break;
          case 'InvalidParameterException':
            this.errorMessage =
                'パスワードは数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上含んだ8文字以上の文字列を設定してください。';
            break;
          case 'CodeMismatchException':
            this.errorMessage = 'リセットコードが異なります';
            break;
          default:
            this.errorMessage =
                'パスワードは数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上含んだ8文字以上の文字列を設定してください。';
            break;
          }
        }
      }
    },
  },
};
</script>
