<template>
  <!-- 仕入先現場コード保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a v-b-toggle.collapse-1 title="クリックすると検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <router-link to="/suppliers-sites-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="仕入先現場コードデータの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <b-collapse id="collapse-1" :visible="true">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!--仕入先コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="supplierId" class="form-label">仕入先コード</label>
                          <validation-provider name="supplierId" rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="supplierId" name="supplierId" class="form-control" v-model.trim="searchConditions.supplierId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 仕入先名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="supplierName" class="form-label">仕入先名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="supplierName" name="supplierName" class="form-control" v-model.trim="searchConditions.supplierName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの仕入先名一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!--得意先コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="clientId" class="form-label">得意先コード</label>
                          <validation-provider name="clientId" rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientId" name="clientId" class="form-control" v-model.trim="searchConditions.clientId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 得意先名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="clientName" class="form-label">得意先名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientName" name="clientName" class="form-control" v-model.trim="searchConditions.clientName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの得意先名一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 現場コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="siteId" class="form-label">現場コード</label>
                          <validation-provider name="siteId" rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="siteId" name="siteId" class="form-control" v-model.trim="searchConditions.siteId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!--  現場名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="siteName" class="form-label">現場名</label>
                          <validation-provider rules="max:50" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="siteName" name="siteName" class="form-control" v-model.trim="searchConditions.siteName" maxlength="50">
                              <small class="form-text text-muted">部分一致検索です。登録済みの現場名一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 相手先現場コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="supplierControlSiteId" class="form-label">相手先現場コード</label>
                          <validation-provider name="supplierControlSiteId" rules="max:10" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="supplierControlSiteId" name="supplierControlSiteId" class="form-control" v-model.trim="searchConditions.supplierControlSiteId" maxlength="10">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="resultList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- printイベント用のレイアウト -->
                  <template v-if="printStatus" #cell(supplier_name)="data">
                    <div style="max-width:240px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.supplier_name }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="printStatus" #cell(client_name)="data">
                    <div style="max-width:240px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.client_name }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="printStatus" #cell(client_site_name_kanji)="data">
                    <div style="max-width:240px;">
                      <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                        <span>{{ data.item.client_site_name_kanji }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickEditBtn(operation.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>&nbsp;
                      <!-- 削除ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDeleteBtn(operation.item)" class="mr-1">
                        <span class="oi oi-delete"></span> 削除
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : resultList.length"
                :per-page="perPage == -1 ? resultList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init, isSystemEditable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'suppliers-sites-maintenance';

export default {
  name: 'SUPPLIERS-SITES-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入先現場コード保守',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        supplierId: '',
        supplierName: '',
        clientId: '',
        clientName: '',
        siteId: '',
        siteName: '',
        supplierControlSiteId: '',
      },
      // 検索結果
      resultError: [],
      //  結果リスト
      resultList: [],
      fields: DataTblDef.suppliers_sites_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.resultList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    scrollTo(0,0);
    //await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'SUPPLIERS-SITES-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData(){
      // 最新製品データ1000件を取得
      await this.search(['1 = 1']);
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} listConditions - 検索条件
     */
    async search(listConditions) {
      this.busy = true;
      this.resultList = [];

      // リストの検索を行います。
      let resultList = null;
      if (listConditions !== null && listConditions.length > 0) {
        resultList = await this.searchSuppliersSites(listConditions);
        if (resultList == null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } 
        resultList.forEach(item => {
          this.resultList.push(
            {
              supplier_id: item.supplier_id,
              client_id: item.client_id,
              site_id: item.site_id,
              supplier_name: item.supplierName,
              client_name: item.clientName,
              client_site_name_kanji: item.client_site_name_kanji,
              supplier_control_site_id: item.supplier_control_site_id,
            }
          )
        });
      }
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.resultList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
      this.busy = false;
    },
    /**
     * 仕入先現場コードマスタ検索
     * @param {Array<String>} whereClause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchSuppliersSites(whereClause) {
      const functionName = 'searchSuppliersSites';

      let result = null;
      // 仕入先現場コードと取引先マスタと現場マスタの結合
      let SQLs = `SELECT  
                  MSS.supplier_id,
                  MSS.client_id,
                  MSS.site_id,
                  MSS.supplier_control_site_id,
                  MC.client_name_kanji AS supplierName,
                  MC2.client_name_kanji AS clientName,
                  MCS.client_site_name_kanji
                  FROM 
                  m_suppliers_sites MSS 
                  LEFT JOIN m_clients MC ON MSS.supplier_id = MC.client_id AND MC.client_class = ${Const.ClientClass.supplier}
                  LEFT JOIN m_clients MC2 ON MSS.client_id = MC2.client_id AND MC2.client_class = ${Const.ClientClass.customer}
                  LEFT JOIN m_clients_sites MCS ON MSS.client_id = MCS.client_id AND MSS.site_id = MCS.site_id 
                  WHERE ${whereClause.join(' AND ')}  
                  ORDER BY MSS.updated DESC LIMIT 1000`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0]
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 仕入先コード
        let listConditions = [];
        if (this.searchConditions.supplierId !== '') {
          listConditions.push(`MSS.supplier_id = '${this.searchConditions.supplierId}' `);
        }
        // 仕入先名(あいまい検索対象)
        if (this.searchConditions.supplierName !== '') {
          const supplierName = convertSqlLikeSpecialChar(this.searchConditions.supplierName);
          let nameConditions = `(REPLACE(REPLACE(CONVERT(MC.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${supplierName}%', 'ﾞ', ''), 'ﾟ', '') `
          nameConditions += `OR REPLACE(REPLACE(CONVERT(MC.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${supplierName}%', 'ﾞ', ''), 'ﾟ', ''))`
          listConditions.push(nameConditions);
        }
        if (this.searchConditions.clientId !== '') {
          listConditions.push(`MSS.client_id = '${this.searchConditions.clientId}' `);
        }
        // 得意先名(あいまい検索対象)
        if (this.searchConditions.clientName !== '') {
          const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
          let nameConditions = `(REPLACE(REPLACE(CONVERT(MC2.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `
          nameConditions += `OR REPLACE(REPLACE(CONVERT(MC2.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', ''))`
          listConditions.push(nameConditions);
        }
        if (this.searchConditions.siteId !== '') {
          listConditions.push(`MSS.site_id = '${this.searchConditions.siteId}' `);
        }
        // 現場名(あいまい検索対象)
        if (this.searchConditions.siteName !== '') {
          const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName);
          let nameConditions = `(REPLACE(REPLACE(CONVERT(MCS.client_site_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `
          nameConditions += `OR REPLACE(REPLACE(CONVERT(MCS.client_site_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', ''))`
          listConditions.push(nameConditions);
        }
        if (this.searchConditions.supplierControlSiteId !== '') {
          listConditions.push(`MSS.supplier_control_site_id = '${this.searchConditions.supplierControlSiteId}' `);
        }
        // 検索条件がない場合、初期画面を出す
        if (listConditions.length === 0) {
          listConditions.push('1 = 1')
        }
        await this.search(listConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ 
        name: 'SUPPLIERS-SITES-INQUIRY', 
        query: { 
          supplier_id: item.supplier_id ,
          client_id: item.client_id ,
          site_id: item.site_id ,
        }
      });
      window.open(route.href, '_blank');
    },
    /**
     * 編集ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickEditBtn(item) {
      let route = this.$router.resolve({ 
        name: 'SUPPLIERS-SITES-EDIT', 
        query: { 
          supplier_id: item.supplier_id ,
          client_id: item.client_id ,
          site_id: item.site_id ,
        }
      });
      window.open(route.href, '_blank');
    },
    /**
     * 削除ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDeleteBtn(item) {
      this.$bvModal.msgBoxConfirm('選択された仕入先現場コードデータを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDelItem(item)
          await this.searchButton();
          this.$store.commit('setLoading', false);
          scrollTo(0,0);
        }
      });
    },
    /**
     * 削除処理
     * @param {Int} supplier_id - 選択された仕入先コード
     * @param {Int} client_id - 選択された得意先コード
     * @param {Int} site_id - 選択された現場コード
     */
    async execDelItem({supplier_id, client_id, site_id}) {
      const functionName = 'execDelItem';
      // 仕入先現場コードマスタ
      const SQLs = `DELETE FROM m_suppliers_sites 
                    WHERE 
                    supplier_id = ${supplier_id}
                    AND client_id = ${client_id}
                    AND site_id = ${site_id}`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push('削除でエラーが発生しました。');
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push('削除でエラーが発生しました。');
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push('削除でエラーが発生しました。');
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });

      if (this.errorMessages.length == 0) {
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
      }
    },
  }
};
</script>