<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page">
      <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
        <TemplateShipsConfirmPickingStart :id="constData.chouhyouId + '_' + chouhyou.page" />
      </div>
      <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
        <TemplateShipsConfirmPickingEnd :id="constData.chouhyouId + '_' + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateShipsConfirmPickingStart from '@/assets/svg/shipsConfirmPicking_Start.svg';
import TemplateShipsConfirmPickingEnd from '@/assets/svg/shipsConfirmPicking_End.svg';
import { setPaperA4Landscape, setChouhyouBodyStyle, addOperationLogs, formatDate, formatCurDate, getNullStr, getListValue, executeSelectSql } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { API, graphqlOperation } from 'aws-amplify';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { list_m_control, list_m_offices } from '@/graphql/queries';

const MODULE_NAME = 'ships-confirm-picking-print';

export default {
  name: 'SHIPS-CONFIRM-PICKING-PRINT',
  /* コンポーネント */
  components: {
    TemplateShipsConfirmPickingStart,
    TemplateShipsConfirmPickingEnd,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 31,
        cntEnd: 36,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: 'ルート別ピッキング表（帳票）',
      // 表示帳票のサイズ（A4）ルート別出荷予定表
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '445mm',
        height: '314mm',
      },
      // 検索項目
      searchResultDispConditions: {
        // 営業所プルダウン
        salesOfficeText: null,
        // 配送種別（開始／終了）
        shippingTypeClass: '',
        // ルート（配送コード）（開始／終了）
        shippingCode: '',
        // 伝票種類（開始／終了）
        place: '',
        // 確定入力日
        dateConfirmDate: '',
        // 取引先コード（開始／終了）
        clientId: '',
        // 現場コード（開始／終了）
        siteId: '',
      },
      // 変更後引数
      changeConditions: {
        // 配送種別（開始／終了）
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始／終了）
        shippingCodeStart: '',
        shippingCodeEnd: '',
        // 場所（開始／終了）
        placeStart: '',
        placeEnd: '',
      },
      itemList: [],
      listChouhyou: [],
      replacementsCommon: [],
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // アラート
      alertWarning: [],
      alertDanger: [],
      // パラメータ
      selectSalesOffice: null,
      shippingCodeStart: null,
      shippingCodeEnd: null,
      shippingTypeClassStart: null,
      shippingTypeClassEnd: null,
      placeStart: null,
      placeEnd: null,
      dateConfirmDate: null,
      clientIdStart: null,
      clientIdEnd: null,
      siteIdStart: null,
      siteIdEnd: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.selectSalesOffice = this.$route.query.selectSalesOffice;
    this.shippingCodeStart = this.$route.query.shippingCodeStart;
    this.shippingCodeEnd = this.$route.query.shippingCodeEnd;
    this.shippingTypeClassStart = this.$route.query.shippingTypeClassStart;
    this.shippingTypeClassEnd = this.$route.query.shippingTypeClassEnd;
    this.placeStart = this.$route.query.placeStart;
    this.placeEnd = this.$route.query.placeEnd;
    this.dateConfirmDate = this.$route.query.dateConfirmDate;
    this.clientIdStart = this.$route.query.clientIdStart;
    this.clientIdEnd = this.$route.query.clientIdEnd;
    this.siteIdStart = this.$route.query.siteIdStart;
    this.siteIdEnd = this.$route.query.siteIdEnd;
    // 印刷レイアウト設定
    setPaperA4Landscape();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // テーブルからデータを取得して、設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = 'ルート別ピッキング表_' + formatCurDate('YYYY/MM/DD');
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('timerAll');
        // 検索条件を設定
        await this.setSearchConditions();
        // 検索値を変更
        this.changeSearchConditionsStrRange();
        // 現在処理年月取得
        let controlResult = await API.graphql(graphqlOperation(list_m_control));
        if (controlResult.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_control',
            result: controlResult
          });
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        } else {
          let controlData = controlResult.data.list_m_control;
          if(controlData.length > 0) {
            this.controlMasterData.processMonthYear = controlData[0].process_month_year;
          }
          // 表示データ設定
          //console.time('timerDisp');
          await this.setDispData();
          //console.timeEnd('timerDisp');
          //console.timeEnd('timerAll');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        this.listChouhyou = [];
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索条件を設定 */
    async setSearchConditions() {
      let strWork = '';
      // 営業所データ取得
      let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
      let officeListData = officeListResult.data.list_m_offices;
      //console.log(officeListData);
      this.searchResultDispConditions.salesOfficeText = this.selectSalesOffice;
      this.searchResultDispConditions.salesOfficeText += '：';
      this.searchResultDispConditions.salesOfficeText += getListValue(officeListData, this.selectSalesOffice, 'office_id', 'office_name_kanji');
      // 配送種別
      if (this.shippingTypeClassStart == this.shippingTypeClassEnd) {
        if (this.shippingTypeClassStart == '*') {
          strWork = '';
        } else {
          strWork = getListValue(Const.DeliveryList, this.shippingTypeClassStart, 'id', 'text');
          if (strWork == null) {
            strWork = this.shippingTypeClassStart;
          }
        }
        this.searchResultDispConditions.shippingTypeClass = strWork;
      } else {
        if (this.shippingTypeClassStart == '*') {
          strWork = '';
        } else {
          strWork = getListValue(Const.DeliveryList, this.shippingTypeClassStart, 'id', 'text');
          if (strWork == null) {
            strWork = this.shippingTypeClassStart;
          }
        }
        this.searchResultDispConditions.shippingTypeClass = strWork;
        this.searchResultDispConditions.shippingTypeClass += ' ～ ';
        strWork = getListValue(Const.DeliveryList, this.shippingTypeClassEnd, 'id', 'text');
        if (strWork == null) {
          strWork = this.shippingTypeClassEnd;
        }
        this.searchResultDispConditions.shippingTypeClass += strWork;
      }
      // ルート
      if (this.shippingCodeStart == this.shippingCodeEnd) {
        if (this.shippingCodeStart == '*') {
          strWork = '';
        } else {
          strWork = this.shippingCodeStart;
        }
        this.searchResultDispConditions.shippingCode = strWork;
      } else {
        if (this.shippingCodeStart == '*') {
          strWork = '';
        } else {
          strWork = this.shippingCodeStart;
        }
        this.searchResultDispConditions.shippingCode = strWork;
        this.searchResultDispConditions.shippingCode += ' ～ ';
        this.searchResultDispConditions.shippingCode += this.shippingCodeEnd;
      }
      // 置き場所
      if (this.placeStart == this.placeEnd) {
        if (this.placeStart == '*') {
          strWork = '';
        } else {
          strWork = this.placeStart;
        }
        this.searchResultDispConditions.place = strWork;
      } else {
        if (this.placeStart == '*') {
          strWork = '';
        } else {
          strWork = this.placeStart;
        }
        this.searchResultDispConditions.place = strWork;
        this.searchResultDispConditions.place += ' ～ ';
        this.searchResultDispConditions.place += this.placeEnd;
      }
      // 得意先
      if (this.clientIdStart == this.clientIdEnd) {
        this.searchResultDispConditions.clientId = this.clientIdStart;
      } else {
        this.searchResultDispConditions.clientId = this.clientIdStart;
        this.searchResultDispConditions.clientId += ' ～ ';
        this.searchResultDispConditions.clientId += this.clientIdEnd;
      }
      // 現場
      if (this.siteIdStart == this.siteIdEnd) {
        this.searchResultDispConditions.siteId = this.siteIdStart;
      } else {
        this.searchResultDispConditions.siteId = this.siteIdStart;
        this.searchResultDispConditions.siteId += ' ～ ';
        this.searchResultDispConditions.siteId += this.siteIdEnd;
      }
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeSearchConditionsStrRange: function() {
      // ルート（配送コード）
      this.changeConditions.shippingCodeStart = this.shippingCodeStart;
      if (this.changeConditions.shippingCodeStart == '*') {
        this.changeConditions.shippingCodeStart = '';
      }
      this.changeConditions.shippingCodeEnd = this.shippingCodeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeConditions.shippingCodeEnd = this.changeConditions.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeConditions.shippingTypeClassStart = this.shippingTypeClassStart;
      if (this.changeConditions.shippingTypeClassStart == '*') {
        this.changeConditions.shippingTypeClassStart = '';
      }
      this.changeConditions.shippingTypeClassEnd = this.shippingTypeClassEnd.replace(/9/g, Const.Char.halfMax);
      this.changeConditions.shippingTypeClassEnd = this.changeConditions.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
      // 置き場所
      this.changeConditions.placeStart = this.placeStart;
      if (this.changeConditions.placeStart == '*') {
        this.changeConditions.placeStart = '';
      }
      this.changeConditions.placeEnd = this.placeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeConditions.placeEnd = this.changeConditions.placeEnd.padEnd(2, Const.Char.halfMax);
    },
    /* 表示データ設定 */
    async setDispData() {
      const functionName = 'setDispData';

      //console.time('timerSql1');
      // 検索SQL作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.timeEnd('timerSql1');
      //console.time('timerSelect1');
      let dataResult = null;
      try {
        dataResult = await executeSelectSql(selectSql);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'executeSelectSqlでエラーが発生しました。', error);
      }
      //console.timeEnd('timerSelect1');
      //console.log(dataResult);
      if(dataResult != null && dataResult.length > 0) {
        // 取引先毎、または、現場毎の一覧を作成
        //console.time('timer1');
        this.setItemList(dataResult);
        //console.timeEnd('timer1');
        // 帳票一覧作成
        this.createListChouhyou(this.itemList.length);
        // 共通の置換文字列設定
        // 現在日時
        this.replacementsCommon.push({key: '%現在日時%', value: formatCurDate('YYYY/MM/DD HH:mm'), textAnchor: 'start', textLength: 270, chkWidth: false});
        // ページ毎の置換文字列設定
        //console.time('timer2');
        await this.createReplacementsPage();
        //console.timeEnd('timer2');
        // 作成した置換文字データをSVGファイルに設定
        //console.time('timer4');
        await this.setChouhyou();
        //console.timeEnd('timer4');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','出荷予定'));
        return;
      }
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      // 発注データのサブクエリを作成
      let subQuery = 'SELECT';
      subQuery += ' product_id';
      subQuery += ',SUM(IfNull(remaining_quantity,0)) AS remaining_quantity';
      subQuery += ',IfNull(MIN(stock_scheduled),\'0000/00/00\') AS stock_scheduled';
      subQuery += ' FROM ';
      subQuery += ' t_orders ';
      subQuery += ' WHERE ';
      subQuery += ' office_id = ' + this.selectSalesOffice + ' ';
      subQuery += 'AND remaining_quantity > 0 ';
      subQuery += 'AND order_issue_class > 0 ';
      subQuery += 'AND order_class <> ' + Const.OrderClass.direct + ' ';
      subQuery += 'AND is_deleted = 0 ';
      subQuery += 'GROUP BY product_id ';

      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.product_id';
      selectSql += ',products.product_name_kanji';
      selectSql += ',products.unit';
      selectSql += ',SUM(ships.shipping_quantity) AS shipping_quantity';
      selectSql += ',IfNull(stocks.balance,0) AS balance';
      selectSql += ',IfNull(stocks.inventory_reserve_count,0) AS inventory_reserve_count';	
      selectSql += ',IfNull(orders.remaining_quantity,0) AS quantity_stock';
      selectSql += ',IfNull(orders.stock_scheduled,\'0000/00/00\') AS stock_scheduled';
      selectSql += ',IfNull(products_details.place_1,\'\') AS place_1';
      selectSql += ',IfNull(products_details.place_2,\'\') AS place_2';
      selectSql += ',IfNull(products_details.place_3,\'\') AS place_3';
      selectSql += ',IfNull(products_details.place_4,\'\') AS place_4';
      selectSql += ',IfNull(clients.client_name_kanji,\'\') AS client_name_kanji';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON orders_receives.product_id = products_details.product_id ';
      selectSql += 'AND orders_receives.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN (' + subQuery + ') AS orders ';
      selectSql += 'ON orders_receives.product_id = orders.product_id ';
      selectSql += 'LEFT JOIN m_stocks AS stocks ';
      selectSql += 'ON orders_receives.office_id = stocks.office_id ';
      selectSql += 'AND orders_receives.product_id = stocks.product_id ';
      selectSql += 'AND stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'LEFT JOIN m_products_orders AS products_orders ';
      selectSql += 'ON products_orders.product_id = products_details.product_id ';
      selectSql += 'AND products_orders.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql += 'AND clients.client_id = products_orders.client_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'orders_receives.office_id = ' + this.selectSalesOffice + ' ';
      // 配送種別
      selectSql += 'AND orders_receives.shipping_type_class BETWEEN \'' + this.changeConditions.shippingTypeClassStart + '\' AND \'' + this.changeConditions.shippingTypeClassEnd + '\' ';
      // ルート（配送コード）
      selectSql += 'AND orders_receives.shipping_code BETWEEN \'' + this.changeConditions.shippingCodeStart + '\' AND \'' + this.changeConditions.shippingCodeEnd + '\' ';
      // 置き場所
      selectSql += 'AND IfNull(orders_receives.place_1,\'\') BETWEEN \'' + this.changeConditions.placeStart + '\' AND \'' + this.changeConditions.placeEnd + '\' ';
      // 確定入力日
      if (getNullStr(this.dateConfirmDate) != '') {
        selectSql += 'AND ships.shipping_confirm_date = \'' + this.dateConfirmDate + '\' ';
      }
      // 得意先
      selectSql += 'AND orders_receives.client_class = 1 ';
      selectSql += 'AND orders_receives.client_id BETWEEN ' + this.clientIdStart + ' AND ' + this.clientIdEnd + ' ';
      // 現場
      selectSql += 'AND orders_receives.site_id BETWEEN ' + this.siteIdStart + ' AND ' + this.siteIdEnd + ' ';
      // 削除されていない製品
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      // ピッキング表印刷区分
      selectSql += 'AND products_details.picking_print_class = ' + Const.PickingPrintClassDef.pick + ' ';
      // 伝票未発行の出荷データ
      selectSql += 'AND ships.sales_issue_class = 0 ';
      // 出荷数がマイナスでない出荷データ
      selectSql += 'AND ships.shipping_quantity >= 0 ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders_receives.product_id ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY products_details.place_1, products_details.place_2, products_details.place_3, products_details.place_4, orders_receives.product_id ';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    /* 取得結果セット */
    async setItemList(result) {
      let strWork = '';
      let prePlace1 = '';
      for(let i = 0; i < result.length; i++){
        if (i > 0 && prePlace1 != result[i].place_1.trim()) {
          // 前の行の置き場所1と異なる場合
          // 空行追加
          this.itemList.push(this.createBrankRow());
        }
        // 置場所
        strWork = result[i].place_1.trim() + '-' + result[i].place_2.trim() + '-' + result[i].place_3.trim() + '-' + result[i].place_4.trim();
        let searchResult = {
          ClientNameKanji: result[i].client_name_kanji,
          ProductCode: result[i].product_id,
          ProductName: result[i].product_name_kanji,
          Unit: result[i].unit,
          ShippingQuantity: result[i].shipping_quantity.toLocaleString(),
          Balance: (result[i].balance + result[i].inventory_reserve_count).toLocaleString(),
          QuantityStock: result[i].quantity_stock.toLocaleString(),
          StockScheduled: result[i].stock_scheduled == '0000/00/00' ? result[i].stock_scheduled : formatDate(result[i].stock_scheduled),
          Place: strWork,
        };
        this.itemList.push(searchResult);
        prePlace1 = result[i].place_1.trim();
      }
    },
    // 空行作成
    createBrankRow: function() {
      let brankRow = {
        ClientNameKanji: '',
        ProductCode: '',
        ProductName: '',
        Unit: '',
        ShippingQuantity: '',
        Balance: '',
        QuantityStock: '',
        StockScheduled: '',
        Place: '',
      };
      return brankRow;
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt) {
      //console.log('createListChouhyou');
      if (productCnt <= this.constData.cntStart) {
        // 製品が通常テンプレートの件数で収まる場合（開始帳票1ページのみ）
        this.listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: []});
      } else {
        let productCntNokori = productCnt;
        let page = 1;
        // 開始帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnStart, replacements: []});
        productCntNokori -= this.constData.cntStart;
        page++;
        // 中間帳票（終了帳票）
        while (productCntNokori > this.constData.cntEnd) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
          productCntNokori -= this.constData.cntEnd;
          page++;
        }
        // 終了帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
      }
    },
    /* 置換配列セット */
    async createReplacementsPage() {
      //console.log('createReplacementsPage');
      let replacements;
      let index = 0;
      let isChkWidth = false;
      let textAnchor = '';
      let strWork = '';
      for (let i = 0; i < this.listChouhyou.length; i++) {
        let cntByChouhyou = 0;
        // SVGファイルの置換用文字列
        replacements = [];
        // ページ番号
        replacements.push({key: '%P%', value: this.listChouhyou[i].page + ' / ' + this.listChouhyou.length, textAnchor: 'end', textLength: 160, chkWidth: false});
        // 開始帳票
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
          // タイトル
          replacements.push({key: '%タイトル%', value: 'ルート別ピッキング表', textAnchor: 'middle', textLength: 900, chkWidth: false});
          // 営業所
          replacements.push({key: '%営業%', value: this.searchResultDispConditions.salesOfficeText, textAnchor: 'start', textLength: 500, chkWidth: false});
          // 配送種別
          replacements.push({key: '%配送%', value: this.searchResultDispConditions.shippingTypeClass, textAnchor: 'start', textLength: 500, chkWidth: false});
          // ルート
          replacements.push({key: '%ルート%', value: this.searchResultDispConditions.shippingCode, textAnchor: 'start', textLength: 500, chkWidth: false});
          // 置き場所
          replacements.push({key: '%場所%', value: this.searchResultDispConditions.place, textAnchor: 'start', textLength: 500, chkWidth: false});
          // 得意先
          replacements.push({key: '%得意先%', value: this.searchResultDispConditions.clientId, textAnchor: 'start', textLength: 500, chkWidth: false});
          // 現場
          replacements.push({key: '%現場%', value: this.searchResultDispConditions.siteId, textAnchor: 'start', textLength: 500, chkWidth: false});
        }
        if (i == 0) {
          cntByChouhyou = this.constData.cntStart;
        } else {
          cntByChouhyou = this.constData.cntEnd;
        }
        // 一覧
        for (let j = 0; j < cntByChouhyou; j++) {
          if (index < this.itemList.length) {
            // 列０
            strWork = this.itemList[index].ClientNameKanji.replace(/ /g,'').replace(/\u3000/g,'');
            if (strWork != null && strWork.length > 8) {
              strWork = strWork.substring(0, 8) + '…';
            }
            if (strWork == null || strWork.length <= 5) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%0_' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 200, chkWidth: isChkWidth});
            // 列１
            if (this.itemList[index].ProductCode == null || this.itemList[index].ProductCode.length <= 10) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C1_' + (j + 1).toString() + '%', value: this.itemList[index].ProductCode, textAnchor: 'start', textLength: 190, chkWidth: isChkWidth});
            // 列２
            if (this.itemList[index].ProductName == null || this.itemList[index].ProductName.length <= 20) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C2_' + (j + 1).toString() + '%', value: this.itemList[index].ProductName, textAnchor: 'start', textLength: 760, chkWidth: isChkWidth});
            // 列３
            if (this.itemList[index].Unit == null || this.itemList[index].Unit.length <= 4) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C3_' + (j + 1).toString() + '%', value: this.itemList[index].Unit, textAnchor: 'start', textLength: 160, chkWidth: isChkWidth});
            // 列４
            if (this.itemList[index].ShippingQuantity == null || this.itemList[index].ShippingQuantity.length <= 7) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C4_' + (j + 1).toString() + '%', value: this.itemList[index].ShippingQuantity, textAnchor: 'end', textLength: 160, chkWidth: isChkWidth});
            // 列５
            if (this.itemList[index].Balance == null || this.itemList[index].Balance.length <= 7) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C5_' + (j + 1).toString() + '%', value: this.itemList[index].Balance, textAnchor: 'end', textLength: 160, chkWidth: isChkWidth});
            // 列６
            if (this.itemList[index].QuantityStock == null || this.itemList[index].QuantityStock.length <= 7) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C6_' + (j + 1).toString() + '%', value: this.itemList[index].QuantityStock, textAnchor: 'end', textLength: 160, chkWidth: isChkWidth});
            // 列７
            if (this.itemList[index].StockScheduled == null || this.itemList[index].StockScheduled.length <= 10) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C7_' + (j + 1).toString() + '%', value: this.itemList[index].StockScheduled, textAnchor: 'start', textLength: 240, chkWidth: isChkWidth});
            // 列８
            if (this.itemList[index].Place == null || this.itemList[index].Place.length <= 9) {
              isChkWidth = false;
            } else {
              isChkWidth = true;
            }
            replacements.push({key: '%C8_' + (j + 1).toString() + '%', value: this.itemList[index].Place, textAnchor: textAnchor, textLength: 240, chkWidth: isChkWidth});
            index++;
          } else {
            // 列０
            replacements.push({key: '%0_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 200, chkWidth: false});
            // 列１
            replacements.push({key: '%C1_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 190, chkWidth: false});
            // 列２
            replacements.push({key: '%C2_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 760, chkWidth: false});
            // 列３
            replacements.push({key: '%C3_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 160, chkWidth: false});
            // 列４
            replacements.push({key: '%C4_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 160, chkWidth: false});
            // 列５
            replacements.push({key: '%C5_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 160, chkWidth: false});
            // 列６
            replacements.push({key: '%C6_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 160, chkWidth: false});
            // 列７
            replacements.push({key: '%C7_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 240, chkWidth: false});
            // 列８
            replacements.push({key: '%C8_' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 240, chkWidth: false});
          }
        }
        //console.log('replacements');
        //console.log(replacements);
        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function(){
      //console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        let svgDoc = document.getElementById(this.constData.chouhyouId + '_' + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements, svgDoc);
        this.setSize(svgDoc);
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements, topNode){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  this.setTspan(node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements, topNode);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    setSize: function(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>