<template>
  <!-- 支払残高保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 支払残高保守照会</strong></h5>
                <router-link to="/payments-balances-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
                <!-- 支払年月 -->
                <div class="row col-12 form-group">
                  <h5>
                    <strong>
                    支払年月: &nbsp;{{paymentsBalancesObj.payment_month_year | month_year}}
                    </strong>
                  </h5>
                </div>
                <!-- 仕入先コード -->
                <div class="row col-12 form-group">
                  <h5>
                    <strong>
                    仕入先コード: &nbsp;
                      {{paymentsBalancesObj.client_id}}&nbsp;&nbsp;&nbsp;&nbsp; 
                      {{paymentsBalancesObj.client_name_kanji}}
                    </strong>
                  </h5>
                </div>
                <b-table-simple bordered responsive hover small class="col-10">
                  <b-tbody>
                    <b-tr>
                      <b-th style="width:20%">前回支払税抜残高</b-th>
                      <b-td>
                        {{paymentsBalancesObj.pre_no_tax_payment_balances}}
                      </b-td>
                      <b-th style="width:20%">前回支払消費税残高</b-th>
                      <b-td>
                        {{paymentsBalancesObj.pre_tax_payment_balances}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日買掛仕入</b-th>
                      <b-td>
                         {{paymentsBalancesObj.closing_date_payable}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日支払</b-th>
                      <b-td>
                         {{paymentsBalancesObj.closing_date_payments}}
                      </b-td>
                      <b-th style="width:20%">締日消費税支払</b-th>
                      <b-td>
                         {{paymentsBalancesObj.closing_date_tax_payments}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日一括消費税額</b-th>
                      <b-td>
                         {{paymentsBalancesObj.closing_date_bulk_tax}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日伝票消費税額</b-th>
                      <b-td>
                         {{paymentsBalancesObj.closing_date_bill_tax}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日消費税額</b-th>
                      <b-td>
                        {{paymentsBalancesObj.closing_date_tax}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日通常消費税課税小計</b-th>
                      <b-td>
                          {{paymentsBalancesObj.closing_date_normal_tax_subtotal}}
                      </b-td>
                      <b-th>締日通常消費税額</b-th>
                      <b-td>
                          {{paymentsBalancesObj.closing_date_normal_tax}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>締日軽減消費税課税小計</b-th>
                      <b-td>
                          {{paymentsBalancesObj.closing_date_light_tax_subtotal}}
                      </b-td>
                      <b-th>締日軽減消費税額</b-th>
                      <b-td>
                          {{paymentsBalancesObj.closing_date_light_tax}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:20%">締日繰越税抜額</b-th>
                      <b-td>
                        {{paymentsBalancesObj.closing_date_forward_no_tax_payments}}
                      </b-td>
                      <b-th style="width:20%">締日繰越消費税額</b-th>
                      <b-td>
                        {{paymentsBalancesObj.closing_date_forward_tax_payments}}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn(paymentsBalancesObj)"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'payments-balances-edit';

export default {
  name: 'PAYMENTS-BALANCES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '支払残高保守照会',
      // 支払残高対象
      paymentsBalancesObj: {},
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** フィルター */
  filters: {
    month_year: function (month_year) {
      // 支払年月の表示方法
      return String(month_year).slice(0,4)+'/'+ String(month_year).slice(4)
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} payment_month_year - 支払年月
     * @param {String} client_id - 仕入先コード
     */
    async fetchData({payment_month_year, client_id}) {
      client_id = Number(client_id)
      payment_month_year = Number(payment_month_year)
      // 支払残高マスタのデータを請求
      let paymentsBalances = await this.searchPaymentsBalances(client_id, payment_month_year)
      // 値を「,」で分ける
      Object.keys(paymentsBalances).forEach(key => {
        if (key != 'client_id' && key != 'client_name_kanji' && key != 'payment_month_year') {
          paymentsBalances[key] = paymentsBalances[key].toLocaleString();
        }
      });

      // 支払残高データをセット
      this.paymentsBalancesObj = {...paymentsBalances}
    },
    /**
     * 支払残高マスタを検索します。
     * @param {Int} product_id -  仕入先コード
     * @param {Int} month_year - 処理年月
     * @returns {Object} 支払残高マスタデータ
     */
    async searchPaymentsBalances(client_id, month_year) {
      const functionName = 'searchPaymentsBalances';

      let result = null;
      let sqls = `SELECT 
                  MC.client_id,
                  MC.client_name_kanji,
                  TPB.payment_month_year,
                  TPB.pre_no_tax_payment_balances,
                  TPB.pre_tax_payment_balances,
                  TPB.closing_date_payable,
                  TPB.closing_date_bulk_tax,
                  TPB.closing_date_bill_tax,
                  TPB.closing_date_tax,
                  TPB.closing_date_payments,
                  TPB.closing_date_tax_payments,
                  TPB.closing_date_forward_no_tax_payments,
                  TPB.closing_date_forward_tax_payments,
                  TPB.closing_date_normal_tax_subtotal,
                  TPB.closing_date_normal_tax,
                  TPB.closing_date_light_tax_subtotal,
                  TPB.closing_date_light_tax
                  FROM t_payments_balances TPB LEFT JOIN m_clients MC USING(client_id) 
                  WHERE payment_month_year = ${month_year} AND client_id = ${client_id} AND MC.client_class = 2
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sqls] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },
    /**
     * 編集ボタン押下時
     * @param {Int} payment_month_year - 支払年月
     * @param {Int} client_id - 仕入先コード
     */
    clickEditBtn({payment_month_year, client_id}) {
      this.$router.push({ name: 'PAYMENTS-BALANCES-EDIT',
        query: { 
          payment_month_year: payment_month_year, 
          client_id: client_id,
        }});
    },
  }
}
</script>
