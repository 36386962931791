<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container>
                      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-container fluid>
                        <b-row class="mt-2">
                          <!-- 営業所入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                            >
                              <b-form-select
                                v-model="searchConditions.selectSalesOffice"
                                :options="salesOffice"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 配送種別入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="配送種別"
                            >
                              <b-form-select
                                v-model="searchConditions.selectShippingTypeClass"
                                :options="shippingTypeClass"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <!-- 受注番号入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="受注番号"
                            >
                              <validation-provider name="selectOrderReceiveId" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-form-input
                                    v-model="searchConditions.selectOrderReceiveId"
                                    value-field="id"
                                  >
                                  </b-form-input>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- ルート入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="ルート"
                            >
                              <b-form-select
                                v-model="searchConditions.selectShippingCode"
                                :options="shippingCode"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-2">
                          <!-- 出荷確定日入力欄 -->
                          <b-col>
                            <b-container fluid>
                              <b-row>
                                <b-col class="px-0">
                                  <b-form-group
                                    label="出荷確定日"
                                    label-for="datepicker"
                                    description="パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、受注番号を指定してください。"
                                  >
                                    <b-input-group>
                                      <validation-provider name="selectShippingConfirmDateStart" :rules="{dateConsistency:searchConditions.selectShippingConfirmDateEnd}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <b-input-group class="input-daterange" id="datepicker">
                                            <b-form-datepicker
                                              id="datepicker1"
                                              name="selectShippingConfirmDateStart"
                                              class="form-control"
                                              v-model="searchConditions.selectShippingConfirmDateStart"
                                            ></b-form-datepicker>
                                            <b-input-group-append>
                                              <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectShippingConfirmDateStart=''">
                                                <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                              </b-button>
                                            </b-input-group-append>
                                          </b-input-group>
                                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </div>
                                      </validation-provider>
                                      <b-input-group-text>～</b-input-group-text>
                                      <div>
                                        <b-input-group class="input-daterange" id="datepicker">
                                          <b-form-datepicker
                                            id="datepicker2"
                                            name="selectShippingConfirmDateEnd"
                                            class="form-control"
                                            v-model="searchConditions.selectShippingConfirmDateEnd"
                                          ></b-form-datepicker>
                                          <b-input-group-append>
                                            <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectShippingConfirmDateEnd=''">
                                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                            </b-button>
                                          </b-input-group-append>
                                        </b-input-group>
                                      </div>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                      </b-container>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
          <ul v-for="(message,index) in alertWarning" :key="index" style="list-style: none;">
            <li>{{message}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(operation)="operation">
                <!-- 受注照会ボタン -->
                <b-button size="sm" v-b-tooltip.hover.left="'受注内容の照会と各種操作を行います。'" @click="clickDetailBtn(operation.item.receivedOrderId)" class="mr-1">
                  <span class="oi oi-list"></span> 照会
                </b-button>&nbsp;
              </template>
              <template #cell(shippingOrderDate)="data">
                <!-- 配送日アイコン -->
                <b-button size="sm" v-b-tooltip.hover.right="'出荷確定修正画面に遷移し、項目の編集を行います。（伝票発行済みの場合は編集不可です。）'" @click="clickShipsBtn(data.item.receivedOrderId)" :disabled="data.item.salesIssueClass==htmlConst.SalesIssueClass.sumi" class="mr-2">
                  <span class="oi oi-pencil"></span>
                </b-button>
                <span>{{data.item.shippingOrderDate}}</span>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, getListValue, /*executeSelectSql,*/ formatDate, formatCurDate, formatDateCalc } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_staffs, list_m_offices, list_m_routes } from '@/graphql/queries';
// モジュール名
const MODULE_NAME = 'ships-confirm-inquiry';
export default {
  name: 'SHIPS-CONFIRM-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '出荷確定照会',
      // アラート
      alertSuccess: [],
      alertWarning:[],
      alertDanger : [],
      // コンボボックス
      salesOffice: [],
      shippingCode: [],
      // 配送種別区分取得
      shippingTypeClass: Const.DeliveryList.map( list => ({...list})),
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        selectShippingTypeClass: '',
        selectOrderReceiveId: '',
        selectShippingCode: '',
        selectShippingConfirmDateStart: '',
        selectShippingConfirmDateEnd: '',
        receivedOrderId: '',
      },
      // 全件検索用
      addConditions:{id: 0, name: '全て', text: '全て'},
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票発行区分
        SalesIssueClass: {
          // 未
          mi: 0,
          // 済
          sumi: 1,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コンボボックスの作成
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'salesIssueClassText',
          label: '伝票発行済',
          sortable: true,
        },
        {
          key: 'shippingOrderNumber',
          label: '配送順',
          sortable: true,
        },
        {
          key: 'shippingOrderDate',
          label: '配送日',
          sortable: true,
        },
        {
          key: 'receivedOrderId',
          label: '受注番号',
          sortable: true,
        },
        {
          key: 'receivedOrderDate',
          label: '受注日',
          sortable: true,
        },
        {
          key: 'customerCode',
          label: '取引先',
          sortable: true,
        },
        {
          key: 'clientSiteId',
          label: '現場',
          sortable: true,
        },
        {
          key: 'shippingType',
          label: '配送種別',
          sortable: true,
        },
        {
          key: 'shipCode',
          label: 'ルート',
          sortable: true,
        },
        {
          key: 'shipConfirmDate',
          label: '出荷確定日',
          sortable: true,
        },
        {
          key: 'operation',
          label: '操作',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    /* フェッチ */
    async fetchData(){
      // ローディングの開始
      this.$store.commit('setLoading', true);
      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;

      // 営業所データ取得
      if(!await this.getOfficesData()){
        // ローディングの解除
        this.$store.commit('setLoading', false);
        return;
      }
      // ルート取得
      if(!await this.getShippingData()){
        // ローディングの解除
        this.$store.commit('setLoading', false);
        return;
      }
      // 配送種別取得
      // idに0がなければ、検索条件「全て」を追加
      if(!this.shippingTypeClass.some(({ id }) => id === 0)){
        this.shippingTypeClass.unshift(this.addConditions)
      }
      // default値挿入
      this.searchConditions.selectShippingTypeClass = this.addConditions.id;
      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // ログ出力用メソッド名
      const functionName = 'getOfficesData';
      // 担当者データ取得
      let staffListData = null;
      // storeからログイン中ユーザーの情報を取得
      let user = store.getters.user;
      this.loginId = user.username;
      // 検索条件を設定
      let where_clause = `AND login_id = '${this.loginId}'`;
      let condition = {where_clause: where_clause}; 
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(list_m_staffs,condition));
        if (result.data.list_m_staffs) {
          // データを取得できた場合、取得データを返却
          staffListData = result.data.list_m_staffs;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      let loginOfficeId = staffListData[0].office_id;
      try {
        // 営業所データ取得
        let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
        let officeListData = officeListResult.data.list_m_offices;
        console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        for(const officeData of officeListData){
          this.salesOffice.push({
            id: officeData.office_id,
            name: officeData.office_name_kanji,
            text: officeData.office_id + '：' + officeData.office_name_kanji,
          });
          // default値挿入
          if (loginOfficeId == officeData.office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 検索条件「全て」を追加
        this.salesOffice.unshift(this.addConditions);
        // 出荷確定日に１か月前～現在日を設定
        this.searchConditions.selectShippingConfirmDateEnd = formatCurDate('YYYY-MM-DD');
        this.searchConditions.selectShippingConfirmDateStart = formatDateCalc(this.searchConditions.selectShippingConfirmDateEnd, 0, -1, 0, false, 'YYYY-MM-DD');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      return true;
    },
    /* ルート情報を取得 */
    async getShippingData() {
      // ログ出力用メソッド名
      const functionName = 'getShippingData';
      try {
        // ルートデータ取得
        let shippingListResult = await API.graphql(graphqlOperation(list_m_routes));
        let shippingListData = shippingListResult.data.list_m_routes;
        // ルートプルダウン作成
        for(const shippingData of shippingListData){
          this.shippingCode.push({
            id: shippingData.shipping_code,
            name: shippingData.shipping_name,
            text: shippingData.shipping_code + '：' + shippingData.shipping_name,
          });
        }
        // 検索条件「全て」を追加
        this.shippingCode.unshift(this.addConditions);
        // default値挿入
        this.searchConditions.selectShippingCode = this.addConditions.id;
      } catch (error) {
        // データを取得できなかった場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_routes'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      return true;
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // ログ出力用メソッド名
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          // ローディングの開始
          this.$store.commit('setLoading', true);
          // 検索開始
          await this.search();
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
      }
      // ローディングの終了
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search(){
      // ログ出力用メソッド名
      const functionName = 'search';
      this.itemList = [];
      // 明細テーブルのローディングを開始
      this.busy = true;
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      let result;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: selectSql }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          result: result
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      // データ取得時
      let resultData;
      const responseBody = JSON.parse(result.data.executeTransactSql.body)
      resultData = responseBody.data[0];      
      if(resultData){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.alertWarning.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      // 明細テーブルのローディングを解除
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(const data of result){
        this.itemList.push({
          selectSalesOffice : this.searchConditions.selectSalesOffice,
          shippingOrderNumber : data.shipping_order_number,
          shippingOrderDate : !data.shipping_date ? null :formatDate(data.shipping_date),
          receivedOrderId : data.order_receive_id,
          receivedOrderDate : formatDate(data.order_receive_date),
          customerCode : !data.client_name_kanji ? data.client_id : data.client_id + '：' + data.client_name_kanji,
          clientSiteId : (!data.client_site_name_kanji || data.site_id == 0 ) ? null : data.site_id + '：' + data.client_site_name_kanji,
          salesIssueClass : data.sales_issue_class,
          salesIssueClassText : getListValue(Const.SalesIssueClassList, data.sales_issue_class),
          shipConfirmDate : formatDate(data.shipping_confirm_date),
          shipCode : data.shipping_code == 0 ? null : data.shipping_code + '：' + getListValue(this.shippingCode, data.shipping_code),
          shippingType : getListValue(Const.DeliveryList, data.shipping_type_class),
          // 配送日が入力済みの行は赤色表示
          _rowVariant : !data.shipping_date ? null : 'danger'
        });
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      // 検索条件
      let whereClauses = [];
      // 営業所コード
      if (this.searchConditions.selectSalesOffice) {
        whereClauses.push(`ships.office_id = ${this.searchConditions.selectSalesOffice}`);
      }
      // 配送種類区分
      if (this.searchConditions.selectShippingTypeClass) {
        whereClauses.push(`ships.shipping_type_class = ${this.searchConditions.selectShippingTypeClass}`);
      }
      // 受注番号
      if (this.searchConditions.selectOrderReceiveId) {
        whereClauses.push(`ships.order_receive_id = ${this.searchConditions.selectOrderReceiveId}`);
      }
      // ルート
      if (this.searchConditions.selectShippingCode) {
        whereClauses.push(`ships.shipping_code = '${this.searchConditions.selectShippingCode}'`);
      }
      // 出荷確定日開始&出荷確定日終了
      if (this.searchConditions.selectShippingConfirmDateStart && this.searchConditions.selectShippingConfirmDateEnd ) {
        // 出荷確定日開始
        whereClauses.push(`ships.shipping_confirm_date BETWEEN '${this.searchConditions.selectShippingConfirmDateStart}'`);
        // 出荷確定日終了
        whereClauses.push(`'${this.searchConditions.selectShippingConfirmDateEnd}'`);
      } else if (this.searchConditions.selectShippingConfirmDateStart) {
        whereClauses.push(`ships.shipping_confirm_date >= '${this.searchConditions.selectShippingConfirmDateStart}'`);
      } else if (this.searchConditions.selectShippingConfirmDateEnd) {
        whereClauses.push(`ships.shipping_confirm_date <= '${this.searchConditions.selectShippingConfirmDateEnd}'`);
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' ships.shipping_order_number'; // 配送順
      selectSql += ',ships.shipping_date'; // 配送日
      selectSql += ',ships.shipping_code'; // ルート
      selectSql += ',orders_receives.order_receive_date'; // 受注日
      selectSql += ',ships.client_id'; // 取引先コード
      selectSql += ',clients.client_name_kanji'; // 取引先名
      selectSql += ',ships.site_id'; // 現場コード
      selectSql += ',clients_sites.client_site_name_kanji'; // 現場名
      selectSql += ',ships.sales_issue_class'; // 伝票発行済
      selectSql += ',ships.order_receive_id'; //受注番号
      selectSql += ',ships.order_receive_row'; //受注行番号
      selectSql += ',ships.shipping_type_class'; //配送種類
      selectSql += ',ships.shipping_confirm_date'; //出荷確定日
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';

      /* 受注データ（t_orders_receives）と結合 */
      selectSql += 'INNER JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON ships.order_receive_id = orders_receives.order_receive_id ';
      selectSql += 'AND ships.order_receive_row = orders_receives.order_receive_row ';

      /* 取引先マスタ（m_clients）と結合 */
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON ships.client_id = clients.client_id ';
      selectSql += 'AND ships.client_class = clients.client_class ';

      /* 現場マスタ（m_clients_sites）と結合 */
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON ships.client_id = clients_sites.client_id ';
      selectSql += 'AND ships.site_id = clients_sites.site_id ';

      /* WHERE句 */
      // 検索条件が指定された場合
      if(whereClauses.length !== 0){
        selectSql += `WHERE ${whereClauses.join(' AND ')} ` 
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders_receives.order_receive_id';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 照会ボタン押下時 */
    async clickDetailBtn(receivedOrderId){
      let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: receivedOrderId, parentKbn: 0, reserveErrFlg: 0 } });
      window.open(route.href, '_blank');
    },
    /* 鉛筆アイコン押下時 */
    async clickShipsBtn(receivedOrderId){
      let route = this.$router.resolve({ name: 'SHIPS-CONFIRM-EDIT', query: { receivedOrderId: receivedOrderId } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
.input-daterange{
  min-width: 27vw;
}
</style>