<template>
  <!-- 照会レポート定義入力画面 -->
  <div>
    <!-- 上部メニュー -->
    <Header :type="menu_type" :title="isEdit ? '照会レポート定義編集' : '照会レポート定義入力'" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{ isEdit ? '照会レポート定義編集' : '照会レポート定義入力' }}</strong></h5>
                <router-link to="/inquiry-report-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="errorMessages.length">
                <ul v-for="(message, index) of errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <form>
                  <div class="row mt-2 mb-4">
                    <div class="col-md-12">
                      <label for="inquiry-name" class="form-label"><strong>照会レポート定義名前</strong></label>
                      <validation-provider rules="required|max:30" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <input type="text" id="inquiry-name" v-model="inquiryName" maxlength="30" class="form-control">
                          <small class="form-text text-muted">必須項目です。30文字以内で入力してください。</small>
                          <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label v-b-toggle.description-collapse for="description" class="form-label"><strong>説明</strong></label>
                      <b-collapse :visible="collapseVisible" id="description-collapse" class="mb-4">
                        <validation-provider rules="max:100" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <textarea id="description" v-model="description" rows="2" class="form-control" maxlength="256"></textarea>
                            <small class="form-text text-muted">任意項目です。100文字以内で入力してください。改行も1文字に含まれます。</small>
                            <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                          </div>
                        </validation-provider>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- 参照テーブル -->
                  <div class="row">
                    <div class="col-md-12">
                      <label v-b-toggle.from-collapse for="from-table" class="form-label"><strong>参照テーブル</strong></label>
                      <b-collapse :visible="collapseVisible" id="from-collapse" class="mb-4">
                        <b-table-simple id="from-table" :responsive="true" :hover="true" class="mb-2">
                          <b-thead class="thead-light text-center">
                            <b-tr>
                              <b-th style="width: 55px;"><div>No.</div></b-th>
                              <b-th colspan="2"><div>名前</div></b-th>
                              <b-th style="width: 126px;"><div>結合方法</div></b-th>
                              <b-th><div>結合条件</div></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-if="froms.length === 0">
                              <b-td colspan="5"><div><div class="text-center my-2">データが登録されていません。</div></div></b-td>
                            </b-tr>
                            <b-tr
                              v-for="(from, fromIndex) of froms"
                              :key="fromIndex"
                              draggable
                              @dragstart="onFromDragstart($event, fromIndex)"
                              @drop="onFromDrop($event, fromIndex)"
                              @dragover.prevent
                              @dragenter.prevent
                            >
                              <b-td class="align-middle text-center" style="width: 55px;">{{ fromIndex + 1 }}</b-td>
                              <b-td class="pr-2 align-top" style="width: 75px; border-right: 0px;">
                                <b-button size="sm" style="width: 54px;" @click="onRemoveFromButtonClick($event, fromIndex)">
                                  <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                </b-button>
                                <div class="mt-2" v-if="from.name != undefined">
                                  <b-button size="sm" @click="onAddAllOutputItemButtonClick(from)" v-b-tooltip.hover.right="'出力項目にテーブル項目を全て追加します。'">
                                    <small><span class="oi oi-plus"></span><span class="ml-1">全項目出力</span></small>
                                  </b-button>
                                </div>
                              </b-td>
                              <b-td class="pl-2 align-top" style="width: 231px; border-left: 0px;">
                                <!-- 参照テーブル：テーブル名 -->
                                <validation-provider rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select :options="rdsTblDef" v-model="from.name" size="sm" style="width: 210px;" @input="onFromSelectInput($event, from, fromIndex)">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td class="align-top" style="width: 126px;">
                                <!-- 参照テーブル：結合方法 -->
                                <validation-provider v-if="fromIndex !== 0" rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select :options="fromJoinOptions" v-model="from.join" size="sm" style="width: 100px;">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td class="px-0 py-2">
                                <!-- 参照テーブル：結合条件 -->
                                <b-table-simple v-if="from.name && fromIndex !== 0" :hover="true" :bordered="false" :borderless="true" class="m-0">
                                  <b-thead>
                                    <b-tr>
                                      <b-th class="p-0"></b-th>
                                      <b-th class="p-0"></b-th>
                                      <b-th class="p-0"></b-th>
                                      <b-th class="p-0"></b-th>
                                      <b-th class="p-0"></b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr
                                      v-for="(fromCondition, fromConditionIndex) of from.conditions"
                                      :key="fromConditionIndex"
                                      draggable
                                      @dragstart="onFromConditionDragstart($event, fromConditionIndex)"
                                      @drop="onFromConditionDrop($event, from, fromConditionIndex)"
                                      @dragover.prevent
                                      @dragenter.prevent
                                    >
                                      <b-td class="pr-2 py-1" style="width: 74px;">
                                        <b-button size="sm" style="width: 54px;" @click="onRemoveFromConditionButtonClick($event, from, fromConditionIndex)">
                                          <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                        </b-button>
                                      </b-td>
                                      <b-td class="px-2 py-1" style="width: 100px;">
                                        <!-- 参照テーブル：結合条件：論理演算子 -->
                                        <div v-if="fromConditionIndex === 0" style="width: 84px;"></div>
                                        <validation-provider v-if="fromConditionIndex !== 0" rules="required" v-slot="{ classes, errors }">
                                          <div :class="classes">
                                            <b-form-select v-model="fromCondition.op" size="sm" :options="opOptions" style="width: 84px;">
                                              <template #first>
                                                <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                          </div>
                                        </validation-provider>
                                      </b-td>
                                      <b-td class="px-2 py-1" style="width: 300px;">
                                        <!-- 参照テーブル：結合条件：左辺 -->
                                        <validation-provider rules="required" v-slot="{ classes, errors }">
                                          <div :class="classes">
                                            <b-form-select
                                              v-model="fromCondition.left.name"
                                              size="sm"
                                              :options="createFromConditionLeftOptions(from, fromIndex)"
                                              style="width: 284px;"
                                              @change="onFromConditionLeftSelectChange($event, fromCondition)"
                                            >
                                              <template #first>
                                                <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                          </div>
                                        </validation-provider>
                                      </b-td>
                                      <b-td class="px-2 py-1" style="width: 130px;">
                                        <!-- 参照テーブル：結合条件：式 -->
                                        <validation-provider v-if="fromCondition.left.name" rules="required" v-slot="{ classes, errors }">
                                          <div :class="classes">
                                            <b-form-select
                                              v-model="fromCondition.expr"
                                              size="sm"
                                              :options="createFromConditionExprOptions(fromCondition)"
                                              style="width: 114px;"
                                              @change="onFromConditionExprSelectChange($event, fromCondition)"
                                            >
                                              <template #first>
                                                <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                          </div>
                                        </validation-provider>
                                      </b-td>
                                      <b-td class="pl-2 py-1">
                                        <!-- 参照テーブル：結合条件：右辺 -->
                                        <div class="d-flex flex-nowrap">
                                          <validation-provider v-if="typeof fromCondition.expr !== 'undefined'" rules="required" v-slot="{ classes, errors }">
                                            <div :class="classes">
                                              <b-form-select
                                                v-model="fromCondition.right1.name"
                                                size="sm"
                                                :options="createFromConditionRightOptions(from, fromCondition)"
                                                style="width: 284px;"
                                              >
                                                <template #first>
                                                  <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                                </template>
                                                <template>
                                                  <b-form-select-option size="sm" :value="anyValue">任意の値</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                              <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.left.column && fromCondition.right1.name === anyValue && (fromCondition.left.column.type === columnClass.VARCHAR || fromCondition.left.column.type === columnClass.INT)"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-2">
                                              <b-form-input v-model="fromCondition.right1.value1" size="sm" style="width: 200px;"></b-form-input>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.left.column && fromCondition.right1.name === anyValue && (fromCondition.left.column.type === columnClass.DATE || fromCondition.left.column.type === columnClass.DATETIME)"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-3">
                                              <b-form-datepicker
                                                v-model="fromCondition.right1.value1"
                                                size="sm"
                                                boundary="window"
                                                style="width: 210px;"
                                              ></b-form-datepicker>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.left.column && fromCondition.right1.name === anyValue && fromCondition.left.column.type === columnClass.DATETIME"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-2">
                                              <b-form-timepicker
                                                v-model="fromCondition.right1.value2"
                                                size="sm"
                                                boundary="window"
                                                locale="ja-JP" show-seconds :hour12="false" style="width: 210px;"></b-form-timepicker>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <span
                                            v-if="fromCondition.left.column && ((fromCondition.left.column.type === columnClass.INT && fromCondition.expr === exprInt.BT) || (fromCondition.left.column.type === columnClass.DATE && fromCondition.expr === exprDate.BT) || (fromCondition.left.column.type === columnClass.DATETIME && fromCondition.expr === exprDateTime.BT))"
                                            class="px-2 pt-1"
                                          >～</span>
                                          <validation-provider
                                            v-if="fromCondition.left.column && ((fromCondition.left.column.type === columnClass.INT && fromCondition.expr === exprInt.BT) || (fromCondition.left.column.type === columnClass.DATE && fromCondition.expr === exprDate.BT) || (fromCondition.left.column.type === columnClass.DATETIME && fromCondition.expr === exprDateTime.BT))"
                                            rules="required"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes">
                                              <b-form-select
                                                v-model="fromCondition.right2.name"
                                                size="sm"
                                                :options="createFromConditionRightOptions(from, fromCondition)"
                                                style="width: 284px;"
                                              >
                                                <template #first>
                                                  <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                                </template>
                                                <template>
                                                  <b-form-select-option :value="anyValue">任意の値</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                              <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.right2.name === anyValue && fromCondition.left.column.type === columnClass.INT && fromCondition.expr === exprInt.BT"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-2">
                                              <b-form-input v-model="fromCondition.right2.value1" size="sm" style="width: 200px;" class="d-inline"></b-form-input>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.right2.name === anyValue && ((fromCondition.left.column.type === columnClass.DATE && fromCondition.expr === exprDate.BT) || (fromCondition.left.column.type === columnClass.DATETIME && fromCondition.expr === exprDateTime.BT))"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-3">
                                              <b-form-datepicker
                                                v-model="fromCondition.right2.value1"
                                                size="sm"
                                                boundary="window"
                                                style="width: 210px;"
                                              ></b-form-datepicker>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                          <validation-provider
                                            v-if="fromCondition.right2.name === anyValue && fromCondition.left.column.type === columnClass.DATETIME && fromCondition.expr === exprDate.BT"
                                            :rules="fromCondition.left.column.rules"
                                            v-slot="{ classes, errors }"
                                          >
                                            <div :class="classes" class="ml-2">
                                              <b-form-timepicker
                                                v-model="fromCondition.right2.value2"
                                                size="sm"
                                                boundary="window"
                                                locale="ja-JP"
                                                show-seconds
                                                :hour12="false"
                                                style="width: 210px;"
                                              ></b-form-timepicker>
                                              <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                            </div>
                                          </validation-provider>
                                        </div>
                                      </b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                                <div v-if="fromIndex !== 0 && from.name" class="pl-3 pt-1">
                                  <b-button size="sm" @click="onAddFromConditionButtonClick($event, from)" variant="primary" class="px-3">
                                    <small><span class="oi oi-plus"></span><span class="ml-1">結合条件追加</span></small>
                                  </b-button>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                        <b-button size="sm" @click="onAddFromButtonClick" variant="primary" class="px-3">
                          <small><span class="oi oi-plus"></span><span class="ml-1">参照テーブル追加</span></small>
                        </b-button>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- 検索条件 -->
                  <div class="row">
                    <div class="col-md-12">
                      <label v-b-toggle.where-collapse for="where-table" class="form-label"><strong>検索条件</strong></label>
                      <b-collapse :visible="collapseVisible" id="where-collapse" class="mb-4">
                        <b-table-simple id="where-table" :responsive="true" :hover="true" class="mb-2">
                          <b-thead class="thead-light text-center">
                            <b-tr>
                              <b-th>No.</b-th>
                              <b-th colspan="3"><div>項目</div></b-th>
                              <b-th><div>式</div></b-th>
                              <b-th><div>値</div></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-if="wheres.length === 0">
                              <b-td colspan="6"><div><div class="text-center my-2">データが登録されていません。</div></div></b-td>
                            </b-tr>
                            <b-tr
                              v-for="(where, whereIndex) of wheres"
                              :key="whereIndex"
                              draggable
                              @dragstart="onWhereDragstart($event, whereIndex)"
                              @drop="onWhereDrop($event, whereIndex)"
                              @dragover.prevent
                              @dragenter.prevent
                            >
                              <b-td class="align-middle text-center" style="width: 55px;"><div>{{ whereIndex + 1 }}</div></b-td>
                              <b-td class="pr-2" style="width: 75px; border-right: 0px;">
                                <b-button size="sm" @click="onRemoveWhereButtonClick($event, whereIndex)" style="width: 54px;">
                                  <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                </b-button>
                              </b-td>
                              <b-td class="px-2" style="width: 100px; border-left: 0px; border-right: 0px;">
                                <!-- 検索条件：論理演算子 -->
                                <div v-if="whereIndex === 0" style="width: 84px;"></div>
                                <validation-provider v-if="whereIndex !== 0" rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select v-model="where.op" size="sm" :options="opOptions" style="width: 84px;">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td class="pl-2" style="width: 305px; border-left: 0px;">
                                <!-- 検索条件：左辺 -->
                                <validation-provider rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select
                                      v-model="where.left.name"
                                      size="sm"
                                      :options="createWhereItemListOptions()"
                                      style="width: 284px;"
                                      @change="onWhereItemChange(where)"
                                    >
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td style="width: 140px;">
                                <!-- 検索条件：式 -->
                                <validation-provider v-if="where.left.name" rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select v-model="where.expr" size="sm" :options="getWhereConditionExprOptions(where)" @change="onWhereExprChange(where)" style="width: 114px;">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td>
                                <div class="d-flex flex-nowrap">
                                  <!-- 検索条件：右辺 -->
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && (where.left.column.type === columnClass.VARCHAR || where.left.column.type === columnClass.INT)"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-input v-model="where.right1.value1" size="sm" @change="onWhereRight1Change(where)"></b-form-input>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && (where.left.column.type === columnClass.DATE || where.left.column.type === columnClass.DATETIME)"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-datepicker
                                        v-model="where.right1.value1"
                                        size="sm"
                                        boundary="window"
                                        style="width: 210px;"
                                      ></b-form-datepicker>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && where.left.column.type === columnClass.DATETIME"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-timepicker
                                        v-model="where.right1.value2"
                                        size="sm"
                                        boundary="window"
                                        locale="ja-JP"
                                        show-seconds
                                        :hour12="false"
                                        style="width: 210px;"
                                        class="ml-2"
                                      ></b-form-timepicker>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                  <span
                                    v-if="typeof where.expr !== 'undefined' && ((where.left.column.type === columnClass.INT && where.expr === exprInt.BT) || (where.left.column.type === columnClass.DATE && where.expr === exprDate.BT) || (where.left.column.type === columnClass.DATETIME && where.expr === exprDateTime.BT))"
                                    class="px-2 pt-1"
                                  >～</span>
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && where.left.column.type === columnClass.INT && where.expr === exprInt.BT" v-model="where.right2.value1"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-input
                                        v-model="where.right2.value1"
                                        size="sm"
                                        @change="onWhereRight2Change(where)"
                                      ></b-form-input>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && ((where.left.column.type === columnClass.DATE && where.expr === exprDate.BT) || (where.left.column.type === columnClass.DATETIME && where.expr === exprDateTime.BT))"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-datepicker
                                        v-model="where.right2.value1"
                                        size="sm"
                                        boundary="window"
                                        style="width: 210px;"
                                      ></b-form-datepicker>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                  <validation-provider
                                    v-if="typeof where.expr !== 'undefined' && where.left.column.type === columnClass.DATETIME && where.expr === exprDateTime.BT"
                                    :rules="where.left.column.rules"
                                    v-slot="{ classes, errors }"
                                  >
                                    <div :class="classes">
                                      <b-form-timepicker
                                        v-model="where.right2.value2"
                                        size="sm"
                                        boundary="window"
                                        show-seconds
                                        locale="ja-JP"
                                        :hour12="false"
                                        style="width: 210px;"
                                        class="ml-2"
                                      ></b-form-timepicker>
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                        <b-button size="sm" @click="onAddWhereButtonClick" variant="primary" class="px-3">
                          <small><span class="oi oi-plus"></span><span class="ml-1">検索条件追加</span></small>
                        </b-button>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- 読み込み情報 -->
                  <div class="row">
                    <div class="col-12">
                      <label v-b-toggle.load-collapse for="from-table" class="form-label"><strong>読み込み情報</strong></label>
                      <b-collapse :visible="collapseVisible" id="load-collapse" class="mb-2">
                        <b-container fluid class="px-0">
                          <b-row>
                            <b-col md="3" lg="2">
                              <b-form-group label="読み込み方法" label-size="sm">
                                <validation-provider rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select id="loadMethod" :options="loadMethodOptions" v-model="loadMethod" size="sm">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" lg="2">
                              <b-form-group label="読み込み方向" label-size="sm">
                                <validation-provider rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select id="loadDirection" :options="loadDirectionOptions" v-model="loadDirection" size="sm">
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- 出力項目 -->
                  <div class="row">
                    <div class="col-md-12">
                      <label v-b-toggle.output-item-collapse for="output-item-table" class="form-label"><strong>出力項目</strong></label>
                      <b-collapse :visible="collapseVisible" id="output-item-collapse" class="mb-4">
                        <b-table-simple id="output-item-table" :responsive="true" :hover="true" class="mb-2 output-item-table">
                          <b-thead class="thead-light text-center">
                            <b-tr>
                              <b-th><div>{{ loadDirection === loadDirectionClass.VERTICAL ? '列' : '行' }}</div></b-th>
                              <b-th colspan="2"><div>項目</div></b-th>
                              <b-th><div>表示名</div></b-th>
                              <b-th style="width: 115px;"><div>昇降順序</div></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-if="outputItems.length === 0">
                              <b-td colspan="5"><div><div class="text-center my-2">データが登録されていません。</div></div></b-td>
                            </b-tr>
                            <b-tr
                              v-for="(outputItem, outputItemIndex) of outputItems"
                              :key="outputItemIndex"
                              draggable
                              @dragstart="onOutputItemDragstart($event, outputItemIndex)"
                              @drop="onOutputItemDrop($event, outputItemIndex)"
                              @dragover.prevent
                              @dragenter.prevent
                            >
                              <b-td class="align-middle text-center" style="width: 60px;">
                                {{ loadDirection === loadDirectionClass.VERTICAL ? getExcelRowIndex(outputItemIndex + 1) : outputItemIndex + 1 }}
                              </b-td>
                              <b-td class="pr-2" style="width: 75px; border-right: 0px;">
                                <b-button size="sm" @click="removeOutputItem(outputItemIndex)" style="width: 54px;">
                                  <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                </b-button>
                              </b-td>
                              <b-td class="pl-2" style="width: 431px; border-left: 0px;">
                                <validation-provider rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select
                                      :options="createOutputItemSelectOptions()"
                                      v-model="outputItem.name"
                                      size="sm"
                                      style="width: 410px;"
                                      @change="onOutputItemSelectChange($event, outputItem)"
                                    >
                                      <template #first>
                                        <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                      </template>
                                      <template>
                                        <b-form-select-option :value="blank">空白</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td>
                                <!-- 表示名 -->
                                <validation-provider v-if="outputItem.name !== blank" rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes" class="input-group-sm">
                                    <input type="text" v-model="outputItem.displayName" class="form-control">
                                    <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                              <b-td style="width: 116px;">
                                <!-- 昇降順序 -->
                                <validation-provider v-if="outputItem.name !== blank" rules="required" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-form-select :options="sortOptions" v-model="outputItem.sort" size="sm" style="width: 90px;">
                                    </b-form-select>
                                    <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                  </div>
                                </validation-provider>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                        <b-button id="addOutputItemButton" size="sm" @click="onAddOutputItemButtonClick" variant="primary" class="px-3">
                          <small><span class="oi oi-plus"></span><span class="ml-1">出力項目追加</span></small>
                        </b-button>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- クロスキー項目 -->
                  <div class="row" v-if="loadMethod === loadMethodClass.CROSS">
                    <div class="col">
                      <label v-b-toggle.cross-key-items for="cross-key-items" class="form-label"><strong>クロスキー項目</strong></label>
                      <b-collapse :visible="collapseVisible" id="cross-key-items" class="mb-4">
                        <b-form-group label="左側キー項目" label-for="cross-left-key-items-table" label-size="sm">
                          <b-table-simple id="cross-left-key-items-table" :hover="true" class="mb-2">
                            <b-thead class="thead-light text-center">
                              <b-tr>
                                <b-th><div>No.</div></b-th>
                                <b-th colspan="2"><div>項目</div></b-th>
                                <b-th>表示名</b-th>
                                <b-th><div>昇降順序</div></b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-if="crossLeftKeyItems.length === 0">
                                <b-td colspan="5"><div><div class="text-center my-2">データが登録されていません。</div></div></b-td>
                              </b-tr>
                              <b-tr
                                v-for="(crossLeftKeyItem, crossLeftKeyItemIndex) of crossLeftKeyItems"
                                :key="crossLeftKeyItemIndex"
                                draggable
                                @dragstart="onCrossLeftKeyDragstart($event, crossLeftKeyItemIndex)"
                                @drop="onCrossLeftKeyDrop($event, crossLeftKeyItemIndex)"
                                @dragover.prevent
                                @dragenter.prevent
                              >
                                <b-td class="align-middle text-center" style="width: 55px;"><div>{{ crossLeftKeyItemIndex + 1 }}</div></b-td>
                                <b-td class="pr-2" style="width: 74px; border-right: 0px;">
                                  <b-button size="sm" style="width: 54px;" @click="onRemoveCrossLeftKeyButtonClick(crossLeftKeyItemIndex)">
                                    <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                  </b-button>
                                </b-td>
                                <b-td class="pl-2" style="width: 431px; border-left: 0px;">
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes">
                                      <b-form-select
                                        :options="createCrossLeftKeyItemList()"
                                        v-model="crossLeftKeyItem.name"
                                        size="sm"
                                        style="width: 410px;"
                                        @change="onCrossLeftKeyItemSelectChange($event, crossLeftKeyItem)"
                                      >
                                        <template #first>
                                          <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                        </template>
                                        <template>
                                          <b-form-select-option :value="blank">空白</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                                <b-td>
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes" class="input-group-sm">
                                      <input type="text" v-model="crossLeftKeyItem.displayName" class="form-control">
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                                <b-td style="width: 116px;">
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes">
                                      <b-form-select :options="sortOptions" v-model="crossLeftKeyItem.sort" size="sm" style="width: 90px;">
                                      </b-form-select>
                                      <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                          <b-button size="sm" @click="onAddCrossLeftKeyButtonClick" variant="primary" class="px-3">
                            <small><span class="oi oi-plus"></span><span class="ml-1">左側クロスキー項目追加</span></small>
                          </b-button>
                        </b-form-group>
                        <b-form-group label="上側キー項目" label-for="cross-top-key-items-table" label-size="sm">
                          <b-table-simple id="cross-top-key-items-table" :hover="true" class="mb-2">
                            <b-thead class="thead-light text-center">
                              <b-tr>
                                <b-th><div>No.</div></b-th>
                                <b-th colspan="2"><div>項目</div></b-th>
                                <b-th>表示名</b-th>
                                <b-th><div>昇降順序</div></b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-if="crossTopKeyItems.length === 0">
                                <b-td colspan="5"><div><div class="text-center my-2">データが登録されていません。</div></div></b-td>
                              </b-tr>
                              <b-tr
                                v-for="(crossTopKeyItem, crossTopKeyItemIndex) of crossTopKeyItems"
                                :key="crossTopKeyItemIndex"
                                draggable
                                @dragstart="onCrossTopKeyDragstart($event, crossTopKeyItemIndex)"
                                @drop="onCrossTopKeyDrop($event, crossTopKeyItemIndex)"
                                @dragover.prevent
                                @dragenter.prevent
                              >
                                <b-td class="align-middle text-center" style="width: 55px;"><div>{{ crossTopKeyItemIndex + 1 }}</div></b-td>
                                <b-td class="pr-2" style="width: 74px; border-right: 0px;">
                                  <b-button size="sm" style="width: 54px;" @click="onRemoveCrossTopKeyButtonClick(crossTopKeyItemIndex)">
                                    <small><span class="oi oi-delete"></span><span class="ml-1">削除</span></small>
                                  </b-button>
                                </b-td>
                                <b-td class="pl-2" style="width: 431px; border-left: 0px;">
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes">
                                      <b-form-select
                                        :options="createCrossTopKeyItemListOptions()"
                                        v-model="crossTopKeyItem.name"
                                        size="sm"
                                        style="width: 410px;"
                                        @change="onCrossTopKeyItemSelectChange($event, crossTopKeyItem)"
                                      >
                                        <template #first>
                                          <b-form-select-option :value="undefined" disabled>選択してください</b-form-select-option>
                                        </template>
                                        <template>
                                          <b-form-select-option :value="blank">空白</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                                <b-td>
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes" class="input-group-sm">
                                      <input type="text" v-model="crossTopKeyItem.displayName" class="form-control">
                                      <span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                                <b-td style="width: 116px;">
                                  <validation-provider rules="required" v-slot="{ classes, errors }">
                                    <div :class="classes">
                                      <b-form-select :options="sortOptions" v-model="crossTopKeyItem.sort" size="sm" style="width: 90px;">
                                      </b-form-select>
                                      <br><span id="error" v-if="errors[0]"><small>{{ errors[0] }}</small></span>
                                    </div>
                                  </validation-provider>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                          <b-button size="sm" @click="onAddCrossTopKeyButtonClick" variant="primary" class="px-3">
                            <small><span class="oi oi-plus"></span><span class="ml-1">上側クロスキー項目追加</span></small>
                          </b-button>
                        </b-form-group>
                      </b-collapse>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-3">
                <div class="col-lg-2 pb-1">
                  <b-button variant="primary" class="btn-block" @click="onSearchButtonClick"><span class="oi oi-magnifying-glass"></span><span class="pl-2">検索</span></b-button>
                </div>
                <div class="col-lg-2 pb-1">
                  <b-button variant="primary" class="btn-block" @click="onSaveButtonClick"><span class="oi oi-circle-check"></span><span class="pl-2">保存</span></b-button>
                </div>
                <div class="col-lg-2 pb-1">
                  <b-button variant="primary" class="btn-block" @click="onClickOutputCsvButtonClick"><span class="oi oi-data-transfer-download"></span><span class="pl-2">CSV出力</span></b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- フッター -->
    <Footer />
    <!-- 検索結果モーダル -->
    <b-modal id="inquiry-result-table-modal" title="検索結果" size="xl" hide-footer>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="alert alert-danger" role="alert" v-if="modalErrorMessages.length">
              <ul v-for="(message, index) in modalErrorMessages" :key="index" style="list-style: none;">
                <li>{{ message }}</li>
              </ul>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group label="1ページあたりの表示件数" label-for="per-page-select" label-cols-sm="4" label-align-sm="right" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <!-- 標準表形式 -->
            <b-table
              id="select-table"
              show-empty
              :head-variant="headVariant"
              :responsive="true"
              :items="inquiryResultTableItems"
              :fields="inquiryResultTableFields"
              :busy="isInquiryResultTableBusy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :caption-top="false"
              :bordered="true"
              :empty-text="emptyText"
              :empty-filtered-text="emptyFilterdText"
              @filtered="onFiltered"
            >
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner><span class="pl-3"><strong>読み込んでいます...</strong></span>
                </div>
              </template>
              <template #thead-top>
                <b-tr v-for="(inquiryResultTableHeadTopFields, inquiryResultTableHeadTopFieldsIndex) of inquiryResultTableHeadTopFieldsList" :key="inquiryResultTableHeadTopFieldsIndex">
                  <b-th><div>{{ (inquiryResultTableHeadTopFieldsIndex === 0) ? '' : inquiryResultTableHeadTopFieldsIndex }}</div></b-th>
                  <b-th v-for="(column, columnIndex) of inquiryResultTableHeadTopFields" :key="columnIndex" :colspan="column.colspan"><div>{{ column.label }}</div></b-th>
                </b-tr>
              </template>
              <template #cell(rowNo)="data">
                <div>{{ (perPage * currentPage) - perPage + inquiryResultTableHeadTopFieldsList.length + 1 + data.index }}</div>
              </template>
              <template #cell(headerColumn)="data">
                <div>{{ data.item.headerColumn }}</div>
              </template>
            </b-table>
            <div>{{ getPagingMessage }}</div>
          </b-col>
        </b-row>
        <b-row v-if="inquiryResultTableItems.length > 0">
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :small="true"
              :total-rows="filter != null ? filterRows : inquiryResultTableItems.length"
              :per-page="perPage == -1 ? inquiryResultTableItems.length : perPage"
              aria-controls="select-table"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, escapeQuote, init, CreateColRow, CreateInsertSql, CreateUpdateSql, isSystemEditable, executeSelectSql, formatCurDate } from '@/assets/js/common.js';
import { QUERY_REQUEST_TYPE } from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { ColumnClass, RdsTblDef } from '@/assets/js/rdsTableDef.js';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import { getM_inquiries_definitions } from '@/graphql/queries';
import { createSelectDataOnS3, executeTransactSql } from '@/graphql/mutations';
import { onUpdateQueryStatus } from '@/graphql/subscriptions';
import { requestQueryAsync, onQueryStatusUpdate } from '@/assets/js/executeSqlAsync.js'

const MODULE_NAME = 'inquiry-report-input-edit';

// 読み込み方法
const LOAD_METHOD_CLASS = {
  STANDARD: 0,
  CROSS: 1
};
const LOAD_METHOD_SELECT_ITEMS = [
  { text: '標準読み込み', value: LOAD_METHOD_CLASS.STANDARD },
  { text: 'クロス集計', value: LOAD_METHOD_CLASS.CROSS }
];

// 読み込み方向
const LOAD_DIRECTION_CLASS = {
  VERTICAL: 0,
  HORIZONTAL: 1
};
const LOAD_DIRECTION_SELECT_ITEMS = [
  { text: '縦にレコード', value: LOAD_DIRECTION_CLASS.VERTICAL },
  { text: '横にレコード', value: LOAD_DIRECTION_CLASS.HORIZONTAL }
];

// 参照テーブル結合方法
const FROM_JOIN_VALUE = { 0: 'INNER JOIN', 1: 'LEFT JOIN', 2: 'RIGHT JOIN', 3: 'CROSS JOIN' };
const FROM_JOIN_CLASS = { INNER_JOIN: 0, LEFT_JOIN: 1, RIGHT_JOIN: 2, CROSS_JOIN: 3 };
const FROM_JOIN_SELECT_ITEMS = [
  { text: '内部結合', value: FROM_JOIN_CLASS.INNER_JOIN },
  { text: '左外部結合', value: FROM_JOIN_CLASS.LEFT_JOIN },
  { text: '右外部結合', value: FROM_JOIN_CLASS.RIGHT_JOIN },
  { text: '交差結合', value: FROM_JOIN_CLASS.CROSS_JOIN }
];

const OP_VALUE = { 0: 'AND', 1: 'OR' };
const OP_CLASS = { AND: 0, OR: 1 };
const OP_SELECT_ITEMS = [
  { text: 'かつ', value: OP_CLASS.AND },
  { text: 'または', value: OP_CLASS.OR }
];

const EXPR_INT_VALUE = { 0: '=', 1: '!=', 2: '<', 3: '<=', 4: '>', 5: '>=', 6: 'BETWEEN' };
const EXPR_INT_CLASS = { EQ: 0, NE: 1, LT: 2, LE: 3, GT: 4, GE: 5, BT: 6 };
const EXPR_INT_SELECT_ITEMS = [
  { text: '=', value: EXPR_INT_CLASS.EQ },
  { text: '≠', value: EXPR_INT_CLASS.NE },
  { text: '<', value: EXPR_INT_CLASS.LT },
  { text: '≦', value: EXPR_INT_CLASS.LE },
  { text: '>', value: EXPR_INT_CLASS.GT },
  { text: '≧', value: EXPR_INT_CLASS.GE },
  { text: '範囲', value: EXPR_INT_CLASS.BT }
];

const EXPR_VARCHAR_VALUE = { 0: '=', 1: '!=', 2: 'LIKE', 3: 'LIKE', 4: 'LIKE' };
const EXPR_VARCHAR_CLASS = { EQ: 0, NE: 1, FE: 2, RE: 3, IN: 4 };
const EXPR_VARCHAR_SELECT_ITEMS = [
  { text: '完全一致', value: EXPR_VARCHAR_CLASS.EQ },
  { text: '一致しない', value: EXPR_VARCHAR_CLASS.NE },
  { text: '前方一致', value: EXPR_VARCHAR_CLASS.FE },
  { text: '後方一致', value: EXPR_VARCHAR_CLASS.RE },
  { text: '文字含む', value: EXPR_VARCHAR_CLASS.IN }
];

const EXPR_DATE_VALUE = { 0: '=', 1: '!=', 2: '<', 3: '<=', 4: '>', 5: '>=', 6: 'BETWEEN' };
const EXPR_DATE_CLASS = { EQ: 0, NE: 1, LT: 2, LE: 3, GT: 4, GE: 5, BT: 6 };
const EXPR_DATE_SELECT_ITEMS = [
  { text: '=', value: EXPR_DATE_CLASS.EQ },
  { text: '≠', value: EXPR_DATE_CLASS.NE },
  { text: '<', value: EXPR_DATE_CLASS.LT },
  { text: '≦', value: EXPR_DATE_CLASS.LE },
  { text: '>', value: EXPR_DATE_CLASS.GT },
  { text: '≧', value: EXPR_DATE_CLASS.GE },
  { text: '範囲', value: EXPR_DATE_CLASS.BT }
];

const EXPR_DATETIME_VALUE = { 0: '=', 1: '!=', 2: '<', 3: '<=', 4: '>', 5: '>=', 6: 'BETWEEN' };
const EXPR_DATETIME_CLASS = { EQ: 0, NE: 1, LT: 2, LE: 3, GT: 4, GE: 5, BT: 6 };
const EXPR_DATETIME_SELECT_ITEMS = [
  { text: '=', value: EXPR_DATETIME_CLASS.EQ },
  { text: '≠', value: EXPR_DATETIME_CLASS.NE },
  { text: '<', value: EXPR_DATETIME_CLASS.LT },
  { text: '≦', value: EXPR_DATETIME_CLASS.LE },
  { text: '>', value: EXPR_DATETIME_CLASS.GT },
  { text: '≧', value: EXPR_DATETIME_CLASS.GE },
  { text: '範囲', value: EXPR_DATETIME_CLASS.BT }
];
const EXPR_UNDEFINED_SELECT_ITEMS = [];

const SORT_VALUE = { 0: '', 1: 'ASC', 2: 'DESC' };
const SORT_CLASS = { NONE: 0, ASC: 1, DESC: 2 };
const SORT_SELECT_ITEMS = [
  { text: 'なし', value: SORT_CLASS.NONE },
  { text: '昇順', value: SORT_CLASS.ASC },
  { text: '降順', value: SORT_CLASS.DESC }
];

// 参照テーブル結合条件の任意の値
const ANY_VALUE = '!any_value!';
// 出力項目選択オプションメニューの空白
const BLANK = '!blank!';
// CSV出力時にサイズ超過が発生した場合の分割数
const RETRY_SPLIT_CNT = [10, 20, 50, 100];
const MAX_SPLIT_RETRY = 4;

export default {
  name: 'INQUIRY-REPORT-INPUT-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  computed: {
    /**
     * ページの表示件数
     */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.inquiryResultTableItems.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    },
    getWhereConditionExprOptions() {
      return (where) => {
        // console.log('getWhereConditionExprOptions');
        if (where.left.column) {
          switch (where.left.column.type) {
          case ColumnClass.INT:
            // console.log('int');
            return EXPR_INT_SELECT_ITEMS;
          case ColumnClass.VARCHAR:
            // console.log('varchar');
            return EXPR_VARCHAR_SELECT_ITEMS;
          case ColumnClass.DATE:
            // console.log('date');
            return EXPR_DATE_SELECT_ITEMS;
          case ColumnClass.DATETIME:
            // console.log('datetime');
            return EXPR_DATETIME_SELECT_ITEMS;
          default:
            // console.log('undefined');
            return EXPR_UNDEFINED_SELECT_ITEMS;
          }
        }
        return EXPR_UNDEFINED_SELECT_ITEMS;
      }
    }
  },
  watch: {
    /**
     * 入力画面から編集画面へ遷移した時に入力画面で登録したデータを読み込みます。
     * @param {Object} to - 遷移先情報
     * @param {Object} from - 遷移元情報
     */
    async $route(to, from) {
      if (to.name === 'INQUIRY-REPORT-EDIT' && from.name === 'INQUIRY-REPORT-INPUT') {
        this.$store.commit('setLoading', true);
        this.isEdit = true;
        await this.fetchData(await this.$route.query.id);
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        scrollTo(0, 0);
        this.$store.commit('setLoading', false);
      }
      // ブラウザ戻るボタン押す処理、入力モードに戻る、情報をクリア => リロードする
      if (to.name === 'INQUIRY-REPORT-INPUT' && from.name === 'INQUIRY-REPORT-EDIT') {
        window.location.reload()
      }
    }
  },
  /**
   * コンポーネントインスタンスのデータオブジェクトを返します。
   * @returns {Object} コンポーネントインスタンスのデータオブジェクト
   */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      isEdit: false,
      // メッセージ
      successMessages: [],
      errorMessages: [],
      columnClass: ColumnClass,
      rdsTblDef: RdsTblDef,
      loadMethodClass: LOAD_METHOD_CLASS,
      loadMethodOptions: LOAD_METHOD_SELECT_ITEMS,
      loadDirectionClass: LOAD_DIRECTION_CLASS,
      loadDirectionOptions: LOAD_DIRECTION_SELECT_ITEMS,
      exprInt: EXPR_INT_CLASS,
      exprDate: EXPR_DATE_CLASS,
      exprDateTime: EXPR_DATETIME_CLASS,
      opOptions: OP_SELECT_ITEMS,
      fromJoinOptions: FROM_JOIN_SELECT_ITEMS,
      whereFields: DataTblDef.inquiry_report_where_fields,
      sortOptions: SORT_SELECT_ITEMS,
      anyValue: ANY_VALUE,
      blank: BLANK,
      dispMessages: DISP_MESSAGES,
      collapseVisible: true,
      id: 0,
      inquiryName: '',
      description: '',
      froms: [],
      wheres: [],
      exprOptions: undefined,
      loadMethod: LOAD_METHOD_CLASS.STANDARD,
      loadDirection: LOAD_DIRECTION_CLASS.VERTICAL,
      outputItems: [],
      crossLeftKeyItems: [],
      crossTopKeyItems: [],
      // ----- ここから検索結果モーダル情報 -----
      modalErrorMessages: [],
      isInquiryResultTableBusy: false,
      inquiryResultTableHeadTopFieldsList: [],
      inquiryResultTableFields: [],
      inquiryResultTableItems: [],
      filter: undefined,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant: DataTblDef.headerVariant,
      // subscription
      subscription: null,
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0, 0);
    let id = this.$route.query.id
    if (id != undefined) {
      this.isEdit = true;
      await this.fetchData(Number(id));
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * 指定された名前の定義を読み込みます。
     * @param {Number} id - 定義ID
     */
    async fetchData(id) {
      const functionName = 'fetchData';

      // データの読み込み
      let resp = null;
      try {
        resp = await API.graphql(graphqlOperation(getM_inquiries_definitions, {
          id: id
        }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_inquiries_definitions',
          id: id
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (resp.errors || resp.data.getM_inquiries_definitions === null) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_inquiries_definitions',
          id: id,
          result: resp
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }

      // 画面に反映
      const data = resp.data.getM_inquiries_definitions;
      this.id = id;
      this.inquiryName = data.inquiry_name;
      this.description = data.description;
      this.froms = JSON.parse(data.froms);
      for (const from of this.froms) {
        from.table = RdsTblDef.find(item => item.value === from.name);
        for (const condition of from.conditions) {
          const columnName = condition.left.name.split('.')[1];
          condition.left.column = from.table.columns.find(item => item.value === columnName);
        }
      }
      this.wheres = JSON.parse(data.wheres);
      for (const where of this.wheres) {
        const [ alias, columnName ] = where.left.name.split('.');
        const table = this.getTableDefByAlias(alias);
        where.left.column = table.columns.find(item => item.value === columnName);
      }
      this.loadMethod = data.load_method;
      this.loadDirection = data.load_direction;
      this.outputItems = JSON.parse(data.output_items);
      if (data.cross_left_keys) {
        this.crossLeftKeyItems = JSON.parse(data.cross_left_keys);
        this.crossTopKeyItems = JSON.parse(data.cross_top_keys);
      }
    },
    /**
     * 参照テーブル
     */
    createFromConditionLeftOptions(from, index) {
      const columns = [];
      if (from && from.name) {
        const no = index + 1;
        const table = this.rdsTblDef.find(item => item.value === from.name);
        if (table.columns) {
          for (const column of table.columns) {
            columns.push({
              text: `${no}. ${table.text}.${column.text}`,
              value: `${from.alias}.${column.value}`
            });
          }
        }
      }
      return columns;
    },
    createFromConditionExprOptions(fromCondition) {
      if (fromCondition.left.column) {
        switch (fromCondition.left.column.type) {
        case ColumnClass.INT:
          return EXPR_INT_SELECT_ITEMS;
        case ColumnClass.VARCHAR:
          return EXPR_VARCHAR_SELECT_ITEMS;
        case ColumnClass.DATE:
          return EXPR_DATE_SELECT_ITEMS;
        case ColumnClass.DATETIME:
          return EXPR_DATETIME_SELECT_ITEMS;
        default:
          return EXPR_UNDEFINED_SELECT_ITEMS;
        }
      }
      return EXPR_UNDEFINED_SELECT_ITEMS;
    },
    createFromConditionRightOptions(from, fromCondition) {
      const columns = [];
      if (from.name) {
        const endIndex = this.froms.length - 1;
        for (let i = 0; i < endIndex; i++) {
          const from1 = this.froms[i];
          for (const column of this.froms[i].table.columns) {
            if (column.type === fromCondition.left.column.type) {
              columns.push({
                text: `${i + 1}. ${from1.table.text}.${column.text}`,
                value: `${from1.alias}.${column.value}`
              });
            }
          }
        }
      }
      return columns;
    },
    /**
     * テーブルのエイリアス名から対応するテーブル定義データを取得します。
     * @param {String} alias - テーブルのエイリアス名
     * @return {Object} テーブル定義データ
     */
    getTableDefByAlias(alias) {
      const table = this.froms.find(item => item.alias === alias);
      return this.rdsTblDef.find(item => item.value === table.name)
    },
    /**
     * テーブルのエイリアス名とカラム名からカラム定義データを取得します。
     * @param {String} tableAlias - テーブルのエイリアス名
     * @param {String} columnName - カラム名
     * @returns {Object} カラム定義データ
     */
    getColumnDefByTableAlias(tableAlias, columnName) {
      return this.getTableDefByAlias(tableAlias).columns.find(item => item.value === columnName);
    },
    /**
     * カラムのエイリアス名からカラム定義データを取得します。カラムのエイリアス名は
     * 「テーブルエイリアス名.カラム名」である必要があります。
     * @param {String} aliasColumnName - カラムのエイリアス名
     * @returns {Object} カラム定義データ
     */
    getColumnDefByTableAliasColumnName(aliasColumnName) {
      const [ alias, columnName ] = aliasColumnName.split('.');
      return this.getColumnDefByTableAlias(alias, columnName);
    },
    /**
     * 参照テーブルドロップダウンメニュー入力イベント処理
     * @param {String} event - 選択値（from.nameと同じ）
     * @param {Object} from - 参照テーブル情報
     * @param {Number} fromIndex - 参照テーブル情報のインデックス
     */
    onFromSelectInput(event, from, fromIndex) {
      if (!from.table || from.name !== from.table.value) {
        // from.table が無い場合は「選択してください」からの項目選択
        // from.name と from.table.name が一致しない場合は「選択してください」以外からの項目選択
        from.table = this.rdsTblDef.find(item => item.value === from.name);
        from.conditions = [];
      } else {
        // ドラッグ＆ドロップによる移動
        if (fromIndex === 0) {
          // 先頭に移動された場合は結合条件を全て削除
          from.conditions = [];
        } else {
          const targetFroms = this.froms.slice(0, fromIndex);
          for (let conditionIndex = from.conditions.length - 1; conditionIndex >= 0; conditionIndex--) {
            let isDeleted = false;
            const right1Name = from.conditions[conditionIndex].right1.name;
            const right1Alias = (right1Name && right1Name !== ANY_VALUE) ? right1Name.split('.')[0] : undefined;
            if (right1Alias && !targetFroms.find(item => item.alias === right1Alias)) {
              isDeleted = true;
            }
            if (!isDeleted) {
              const right2Name = from.conditions[conditionIndex].right2.name;
              const right2Alias = (right2Name && right2Name !== ANY_VALUE) ? right2Name.split('.')[0] : undefined;
              if (right2Alias && !targetFroms.find(item => item.alias === right2Alias)) {
                isDeleted = true;
              }
            }
            if (isDeleted) {
              from.conditions.splice(conditionIndex, 1);
            }
          }
        }
      }
    },
    /**
     * 参照テーブルの結合条件式の左辺ドロップダウンメニュー変更イベント処理
     * @param {String} event - 選択値（fromCondition.left.nameと同じ）
     * @param {Object} fromCondition - 参照テーブル結合条件
     */
    onFromConditionLeftSelectChange(event, fromCondition) {
      fromCondition.left.column = this.getColumnDefByTableAliasColumnName(fromCondition.left.name);
      fromCondition.expr = undefined;
      fromCondition.right1 = {};
      fromCondition.right2 = {};
    },
    /**
     * 参照テーブルの式ドロップダウンメニュー変更イベント処理
     * @param {String} event - 選択値（fromCondition.exprと同じ）
     * @param {Object} fromCondition - 参照テーブル結合条件
     */
    onFromConditionExprSelectChange(event, fromCondition) {
      if ((fromCondition.left.column.type === ColumnClass.INT && fromCondition.expr !== EXPR_INT_CLASS.BT) ||
          (fromCondition.left.column.type === ColumnClass.DATE && fromCondition.expr !== EXPR_DATE_CLASS.BT) ||
          (fromCondition.left.column.type === ColumnClass.DATETIME && fromCondition.expr !== EXPR_DATETIME_CLASS.BT)) {
        fromCondition.right2 = {};
      }
    },
    /**
     * 参照テーブルを追加します。
     */
    onAddFromButtonClick() {
      this.froms.push({
        name: undefined,
        alias: 't' + String(Date.now()),
        conditions: [
          {
            op: undefined,
            left: {},
            expr: undefined,
            right1: {},
            right2: {}
          }
        ]
      });
    },
    /**
     * 参照テーブル削除ボタンクリックイベント処理
     * @param {Object} event - イベントオブジェクト
     * @param {Number} fromIndex - 削除するテーブルの行番号
     */
    onRemoveFromButtonClick(event, fromIndex) {
      console.log(this.froms);
      // 1番目が削除される場合は2番目が1番目に繰り上がるので結合条件を削除
      if (fromIndex === 0 && this.froms.length > 1) {
        this.froms[1].conditions = [];
      }
      console.log(this.froms);
      // 削除する行の次以降の行の全結合条件をチェックし、削除するテーブルが
      // 使われている場合はその結合条件を削除
      const alias = this.froms[fromIndex].alias + '.';
      for (let i1 = fromIndex + 1; i1 < this.froms.length; i1++) {
        const from = this.froms[i1];
        for (let i2 = from.conditions.length - 1; i2 >= 0; i2--) {
          const right1 = from.conditions[i2].right1;
          const right2 = from.conditions[i2].right2;
          if ((right1.name !== ANY_VALUE && right1.name.startsWith(alias)) ||
              (right2.name && right2.name !== ANY_VALUE && right2.name.startsWith(alias))) {
            from.conditions.splice(i2, 1);
          }
        }
      }
      // 対象の参照テーブル行を削除
      this.froms.splice(fromIndex, 1);
    },
    /**
     * 参照テーブル結合条件追加ボタンクリックイベント処理
     * @param {Object} event - イベントオブジェクト
     * @param {Object} from - 結合条件を追加する参照テーブル情報
     */
    onAddFromConditionButtonClick(event, from) {
      from.conditions.push({
        op: undefined,
        left: {},
        expr: undefined,
        right1: {},
        right2: {}
      });
    },
    /**
     * 参照テーブル結合条件削除ボタンクリックイベント処理
     * @param {Object} event - イベントオブジェクト
     * @param {Object} from - 結合条件を削除する参照テーブル情報
     * @param {Number} fromConditionIndex - 結合条件を削除する参照テーブル情報のインデックス
     */
    onRemoveFromConditionButtonClick(event, from, fromConditionIndex) {
      if (fromConditionIndex === 0 && from.conditions.length > 1) {
        from.conditions[1].op = undefined;
      }
      from.conditions.splice(fromConditionIndex, 1);
    },
    /**
     * 検索条件削除ボタンクリックイベント処理
     * @param {Object} event - イベントオブジェクト
     * @param {Number} whereIndex - 削除対象の検索条件行番号
     */
    onRemoveWhereButtonClick(event, whereIndex) {
      this.wheres.splice(whereIndex, 1);
    },
    createWhereItemListOptions() {
      const list = [];
      for (let i = 0; i < this.froms.length; i++) {
        const from = this.froms[i];
        if (from.name) {
          const no = i + 1;
          const table = this.rdsTblDef.find(item => item.value === from.name);
          if (table.columns) {
            for (const column of table.columns) {
              list.push({
                text: `${no}. ${table.text}.${column.text}`,
                value: `${from.alias}.${column.value}`
              });
            }
          }
        }
      }
      return list;
    },
    onAddWhereButtonClick() {
      this.wheres.push({
        op: undefined,
        left: {},
        expr: undefined,
        right1: {},
        right2: {}
      });
    },
    onWhereItemChange(where) {
      where.left.column = this.getColumnDefByTableAliasColumnName(where.left.name);
      where.expr = {};
      where.expr = undefined;
      where.right1 = {};
      where.right2 = {};
    },
    onWhereExprChange(where) {
      where.right2 = {};
    },
    onWhereRight1Change(where) {
      if (where.right1.name !== ANY_VALUE) {
        where.right1.value2 = undefined;
      }
    },
    onWhereRight2Change(where) {
      if (where.right2.name !== ANY_VALUE) {
        where.right2.value2 = undefined;
      }
    },
    createOutputItemSelectOptions() {
      const list = [];
      for (let i = 0; i < this.froms.length; i++) {
        const from = this.froms[i];
        if (from.name) {
          const no = i + 1;
          const table = this.rdsTblDef.find(item => item.value === from.name);
          for (const column of table.columns) {
            const value = `${from.alias}.${column.value}`;
            if (!this.crossLeftKeyItems.find(item => item.name === value) && !this.crossTopKeyItems.find(item => item.name === value)) {
              list.push({
                text: `${no}. ${table.text}.${column.text}`,
                value: value
              });
            }
          }
        }
      }
      return list;
    },
    /**
     * 指定した数値に対応する Microsoft Excel で使われている列番号を取得します。
     * @param {Number} number - 数値
     * @returns {String} 列番号
     */
    getExcelRowIndex(number) {
      const al = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
      let value = number;
      let ret = '';
      while (value > 0) {
        ret = al[(value - 1) % 26] + ret;
        value = Math.floor((value - 1) /26);
      }
      return ret;
    },
    onAddOutputItemButtonClick() {
      this.outputItems.push({
        name: undefined,
        displayName: undefined,
        sort: SORT_CLASS.NONE
      });
    },
    onAddAllOutputItemButtonClick(from) {
      for (let i = 0; i < from.table.columns.length; i++) {
        let aliasColumnName = from.alias + '.' + from.table.columns[i].value;
        let column = this.getColumnDefByTableAliasColumnName(aliasColumnName);
        this.outputItems.push({
          name: aliasColumnName,
          displayName: column.text,
          sort: SORT_CLASS.NONE
        });
      }
      // ios safari ための対応
      setTimeout(() => {
        let btnAddOutputItem = document.getElementById('addOutputItemButton');
        btnAddOutputItem.scrollIntoView(true);
      }, 500);
    },
    removeOutputItem(outputItemIndex) {
      this.outputItems.splice(outputItemIndex, 1);
    },
    onOutputItemSelectChange(event, outputItem) {
      if (outputItem.name === this.blank) {
        outputItem.displayName = '';
        outputItem.sort = SORT_CLASS.NONE;
      } else {
        const column = this.getColumnDefByTableAliasColumnName(event);
        outputItem.displayName = column.text;
      }
    },
    /**
     * 「参照テーブル」テーブルのドラッグ開始イベント
     * @param {Object} event - イベント情報
     * @param {Number} fromIndex - ドラッグ対象行インデックス
     */
    onFromDragstart(event, fromIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('from-drag-index', fromIndex);
    },
    /**
     * 「参照テーブル」テーブルのドロップイベント
     * @param {Object} event - イベント情報
     * @param {Number} moveToIndex - ドロップ先行インデックス
     */
    onFromDrop(event, moveToIndex) {
      const moveFromIndex = Number(event.dataTransfer.getData('from-drag-index'));
      const moveRowData = this.froms.splice(moveFromIndex, 1)[0];
      this.froms.splice(moveToIndex, 0, moveRowData);
      if (moveFromIndex === 0) {
        this.froms[0].join = undefined;
      }
    },
    /**
     * 「結合条件」テーブルのドラッグ開始イベント
     * @param {Object} event - イベント情報
     * @param {Number} fromConditionIndex - ドラッグ対象行インデックス
     */
    onFromConditionDragstart(event, fromConditionIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('from-condition-drag-index', fromConditionIndex);
    },
    /**
     * 「結合条件」テーブルのドロップイベント
     * @param {Object} event - イベント情報
     * @param {Object} from - ドロップ対象の結合条件
     * @param {Number} moveToIndex - ドロップ先インデックス
     */
    onFromConditionDrop(event, from, moveToIndex) {
      const moveFromIndex = Number(event.dataTransfer.getData('from-condition-drag-index'));
      const moveRowData = from.conditions.splice(moveFromIndex, 1)[0];
      from.conditions.splice(moveToIndex, 0, moveRowData);
      if (moveFromIndex === 0) {
        from.conditions[0].op = undefined;
      }
    },
    /**
     * 「検索条件」テーブルのドラッグ開始イベント
     * @param {Object} event - イベント情報
     * @param {Number} whereIndex - ドラッグ対象行インデックス
     */
    onWhereDragstart(event, whereIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('where-drag-index', whereIndex);
    },
    /**
     * 「検索条件」テーブルのドロップイベント
     * @param {Object} event - イベント情報
     * @param {Number} moveToIndex - ドロップ先インデックス
     */
    onWhereDrop(event, moveToIndex) {
      const moveFromIndex = Number(event.dataTransfer.getData('where-drag-index'));
      const moveRowData = this.wheres.splice(moveFromIndex, 1)[0];
      this.wheres.splice(moveToIndex, 0, moveRowData);
      if (moveFromIndex === 0) {
        delete this.wheres[0].op;
      }
    },
    /**
     * 「出力項目」テーブルのドラッグ開始イベント
     * @param {Object} event - イベント情報
     * @param {Number} outputItemIndex - ドラッグ対象行インデックス
     */
    onOutputItemDragstart(event, outputItemIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('output-item-drag-index', outputItemIndex);
    },
    /**
     * 「出力項目」テーブルのドロップイベント
     * @param {Object} event - イベント情報
     * @param {Number} outputItemIndex - ドロップ先インデックス
     */
    onOutputItemDrop(event, outputItemIndex) {
      const moveRowData = this.outputItems.splice(event.dataTransfer.getData('output-item-drag-index'), 1)[0];
      this.outputItems.splice(outputItemIndex, 0, moveRowData);
    },
    createCrossLeftKeyItemList() {
      const list = [];
      for (let i = 0; i < this.froms.length; i++) {
        const from = this.froms[i];
        if (from.name) {
          const no = i + 1;
          const table = this.rdsTblDef.find(item => item.value === from.name);
          for (const column of table.columns) {
            const value = `${from.alias}.${column.value}`;
            if (!this.outputItems.find(item => item.name === value) && !this.crossTopKeyItems.find(item => item.name === value)) {
              list.push({
                text: `${no}. ${table.text}.${column.text}`,
                value: value
              });
            }
          }
        }
      }
      return list;
    },
    onRemoveCrossLeftKeyButtonClick(crossLeftKeyIndex) {
      this.crossLeftKeyItems.splice(crossLeftKeyIndex, 1);
    },
    onCrossLeftKeyItemSelectChange(event, crossLeftKey) {
      const column = this.getColumnDefByTableAliasColumnName(event);
      crossLeftKey.displayName = column.text;
    },
    onAddCrossLeftKeyButtonClick() {
      this.crossLeftKeyItems.push({
        name: undefined,
        displayName: undefined,
        sort: SORT_CLASS.NONE
      });
    },
    onCrossLeftKeyDragstart(event, crossLeftKeyIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('cross-left-key-drag-index', crossLeftKeyIndex);
    },
    onCrossLeftKeyDrop(event, crossLeftKeyIndex) {
      const moveRowData = this.crossLeftKeyItems.splice(event.dataTransfer.getData('cross-left-key-drag-index'), 1)[0];
      this.crossLeftKeyItems.splice(crossLeftKeyIndex, 0, moveRowData);
    },
    createCrossTopKeyItemListOptions() {
      const list = [];
      for (let i = 0; i < this.froms.length; i++) {
        const from = this.froms[i];
        if (from.name) {
          const table = this.rdsTblDef.find(item => item.value === from.name);
          for (const column of table.columns) {
            const value = `${from.alias}.${column.value}`;
            if (!this.outputItems.find(item => item.name === value) && !this.crossLeftKeyItems.find(item => item.name === value)) {
              list.push({
                text: `${i + 1}. ${table.text}.${column.text}`,
                value: value
              });
            }
          }
        }
      }
      return list;
    },
    onRemoveCrossTopKeyButtonClick(crossTopKeyIndex) {
      this.crossTopKeyItems.splice(crossTopKeyIndex, 1);
    },
    onCrossTopKeyItemSelectChange(event, crossTopKey) {
      const column = this.getColumnDefByTableAliasColumnName(event);
      crossTopKey.displayName = column.text;
    },
    onAddCrossTopKeyButtonClick() {
      this.crossTopKeyItems.push({
        name: undefined,
        displayName: undefined,
        sort: SORT_CLASS.NONE
      });
    },
    onCrossTopKeyDragstart(event, crossTopKeyIndex) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('cross-top-key-drag-index', crossTopKeyIndex);
    },
    onCrossTopKeyDrop(event, crossTopKeyIndex) {
      const moveRowData = this.crossTopKeyItems.splice(event.dataTransfer.getData('cross-top-key-drag-index'), 1)[0];
      this.crossTopKeyItems.splice(crossTopKeyIndex, 0, moveRowData);
    },
    getExprValue(columnClass, exprClass) {
      switch (columnClass) {
      case ColumnClass.INT:
        return EXPR_INT_VALUE[exprClass];
      case ColumnClass.VARCHAR:
        return EXPR_VARCHAR_VALUE[exprClass];
      case ColumnClass.DATE:
        return EXPR_DATE_VALUE[exprClass];
      case ColumnClass.DATETIME:
        return EXPR_DATETIME_VALUE[exprClass];
      }
    },
    createStandardLoadSql() {
      let sql = 'SELECT ';
      for(let i = 0; i < this.outputItems.length; i++) {
        const item = this.outputItems[i];
        if (i !== 0) {
          sql += ', ';
        }
        sql += (item.name === this.blank) ? `'' AS '${i}'` : `${item.name} AS '${i}'`;
      }
      sql += this.createFromClause();
      sql += this.createWhereClause();
      let orderBy = '';
      for (const outputItem of this.outputItems) {
        if (outputItem.sort !== SORT_CLASS.NONE) {
          orderBy += (orderBy === '') ? ' ORDER BY ' : ', ';
          orderBy += `${outputItem.name} ${SORT_VALUE[outputItem.sort]}`;
        }
      }
      sql += orderBy;
      return sql;
    },
    executeCrossLoadSql() {
      let sql = 'SELECT ';
      for (let i = 0; i < this.outputItems.length; i++) {
        const item = this.outputItems[i];
        if (i !== 0) {
          sql += ', ';
        }
        sql += `${item.name} AS '${i}'`;
      }
      for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
        const item = this.crossLeftKeyItems[i];
        sql += `, ${item.name} AS l${i}`;
      }
      for (let i = 0; i < this.crossTopKeyItems.length; i++) {
        const item = this.crossTopKeyItems[i];
        sql += `, ${item.name} AS t${i}`;
      }
      sql += this.createFromClause();
      sql += this.createWhereClause();
      let groupBy = '';
      for (const item of this.crossLeftKeyItems) {
        groupBy += (groupBy === '') ? ' GROUP BY ' : ', ';
        groupBy += item.name;
      }
      for (const item of this.crossTopKeyItems) {
        groupBy += (groupBy === '') ? ' GROUP BY ' : ', ';
        groupBy += item.name;
      }
      sql += groupBy;
      let orderBy = '';
      for (const item of this.crossLeftKeyItems) {
        if (item.sort !== SORT_CLASS.NONE) {
          orderBy += (orderBy === '') ? ' ORDER BY ' : ', ';
          orderBy += `${item.name} ${SORT_VALUE[item.sort]}`;
        }
      }
      for (const item of this.crossTopKeyItems) {
        if (item.sort !== SORT_CLASS.NONE) {
          orderBy += (orderBy === '') ? ' ORDER BY ' : ', ';
          orderBy += `${item.name} ${SORT_VALUE[item.sort]}`;
        }
      }
      for (const item of this.outputItems) {
        if (item.sort !== SORT_CLASS.NONE) {
          orderBy += (orderBy === '') ? ' ORDER BY ' : ', ';
          orderBy += `${item.name} ${SORT_VALUE[item.sort]}`;
        }
      }
      sql += orderBy;
      return sql;
    },
    createFromClause() {
      let sql = ' FROM ';
      for (let i = 0; i < this.froms.length; i++) {
        const from = this.froms[i];
        if (i === 0) {
          sql += `${from.name} AS ${from.alias}`;
        } else {
          sql += ` ${FROM_JOIN_VALUE[from.join]} ${from.name} AS ${from.alias} ON`;
          for (const fromCondition of from.conditions) {
            sql += this.createStandardLoadFromClauseCondition(fromCondition);
          }
        }
      }
      return sql;
    },
    createStandardLoadFromClauseCondition(fromCondition) {
      let sql = ' ';
      if (typeof fromCondition.op !== 'undefined') {
        sql += OP_VALUE[fromCondition.op] + ' ';
      }

      sql += `${fromCondition.left.name} ${this.getExprValue(fromCondition.left.column.type, fromCondition.expr)} `;

      if (fromCondition.right1.name === ANY_VALUE) {
        switch (fromCondition.left.column.type) {
        case ColumnClass.INT:
          sql += fromCondition.right1.value1;
          break;
        case ColumnClass.VARCHAR:
          switch (fromCondition.expr) {
          case EXPR_VARCHAR_CLASS.FE:
            sql += `'${fromCondition.right1.value1}%'`;
            break;
          case EXPR_VARCHAR_CLASS.RE:
            sql += `'%${fromCondition.right1.value1}'`;
            break;
          case EXPR_VARCHAR_CLASS.IN:
            sql += `'%${fromCondition.right1.value1}%'`;
            break;
          default:
            sql += `'${fromCondition.right1.value1}'`;
            break;
          }
          break;
        case ColumnClass.DATE:
          sql += `'${fromCondition.right1.value1}'`;
          break;
        case ColumnClass.DATETIME:
          sql += `'${fromCondition.right1.value1} ${fromCondition.right1.value2}'`;
          break;
        }
      } else {
        sql += fromCondition.right1.name;
      }

      if ((fromCondition.left.column.type === ColumnClass.INT && fromCondition.expr === EXPR_INT_CLASS.BT) ||
          (fromCondition.left.column.type === ColumnClass.DATE && fromCondition.expr === EXPR_DATE_CLASS.BT) ||
          (fromCondition.left.column.type === ColumnClass.DATETIME && fromCondition.expr === EXPR_DATETIME_CLASS.BT)) {
        sql += ' AND ';
        if (fromCondition.right2.name === ANY_VALUE) {
          switch (fromCondition.left.column.type) {
          case ColumnClass.INT:
            sql += fromCondition.right2.value1;
            break;
          case ColumnClass.VARCHAR: // passthru
          case ColumnClass.DATE:
            sql += `'${fromCondition.right2.value1}'`;
            break;
          case ColumnClass.DATETIME:
            sql += `'${fromCondition.right2.value1} ${fromCondition.right2.value2}'`;
            break;
          }
        } else {
          sql += fromCondition.right2.name;
        }
      }

      return sql;
    },
    createWhereClause() {
      if (this.wheres.length === 0) {
        return '';
      }

      let sql = ' WHERE ';
      for (let i = 0; i < this.wheres.length; i++) {
        const where = this.wheres[i];
        if (typeof where.op !== 'undefined' && i > 0) {
          sql += ` ${OP_VALUE[where.op]} `;
        }
        sql += `${where.left.name} ${this.getExprValue(where.left.column.type, where.expr)} `;

        switch (where.left.column.type) {
        case ColumnClass.INT:
          sql += where.right1.value1;
          break;
        case ColumnClass.VARCHAR:
          switch (where.expr) {
          case EXPR_VARCHAR_CLASS.FE:
            sql += `'${where.right1.value1}%'`;
            break;
          case EXPR_VARCHAR_CLASS.RE:
            sql += `'%${where.right1.value1}'`;
            break;
          case EXPR_VARCHAR_CLASS.IN:
            sql += `'%${where.right1.value1}%'`;
            break;
          default:
            sql += `'${where.right1.value1}'`;
            break;
          }
          break;
        case ColumnClass.DATE:
          sql += `'${where.right1.value1}'`;
          break;
        case ColumnClass.DATETIME:
          sql += `'${where.right1.value1} ${where.right1.value2}'`;
          break;
        default:
          break;
        }

        if ((where.left.column.type === ColumnClass.INT && where.expr === EXPR_INT_CLASS.BT) ||
            (where.left.column.type === ColumnClass.DATE && where.expr === EXPR_DATE_CLASS.BT) ||
            (where.left.column.type === ColumnClass.DATETIME && where.expr === EXPR_DATETIME_CLASS.BT)) {
          sql += ' AND ';
          switch (where.left.column.type) {
          case ColumnClass.INT:
            sql += where.right2.value1;
            break;
          case ColumnClass.VARCHAR: // passthru
          case ColumnClass.DATE:
            sql += `'${where.right2.value1}'`;
            break;
          case ColumnClass.DATETIME:
            sql += `'${where.right2.value1} ${where.right2.value2}'`;
            break;
          }
        }
      }

      return sql;
    },
    /**
     * 標準読み込みテーブル更新処理
     * @param {Object} inquiryResult - 検索結果
     */
    refreshStandardLoadInquiryResultTable(inquiryResult) {
      const headTopFieldsList = [];
      const tableFields = [];
      const tableItems = [];

      if (this.loadDirection === LOAD_DIRECTION_CLASS.VERTICAL) {
        // 縦にレコード
        const headTopFields = [];
        tableFields.push({
          key: 'rowNo',
          label: '1',
          isRowHeader: true,
          tdClass: 'align-middle text-center title-column'
        });
        for (let i = 0; i < this.outputItems.length; i++) {
          headTopFields.push({
            label: this.getExcelRowIndex(i + 1)
          });
          const outputItem = this.outputItems[i];
          tableFields.push({
            key: String(i),
            label: outputItem.displayName,
            sortable: true
          });
        }
        headTopFieldsList.push(headTopFields);
        for (const inquiryResultRow of inquiryResult) {
          tableItems.push({ ...inquiryResultRow });
        }
      } else {
        // 横にレコード
        tableFields.push({
          key: 'rowNo',
          label: '',
          isRowHeader: true,
          tdClass: 'align-middle text-center title-column'
        });
        for (let i = 0; i < inquiryResult.length + 1; i++) {
          tableFields.push({
            key: (i === 0) ? 'headerColumn' : String(i - 1),
            label: this.getExcelRowIndex(i + 1),
            isRowHeader: (i === 0),
            tdClass: (i === 0) ? 'title-column' : ''
          });
        }
        for (const outputItem of this.outputItems) {
          tableItems.push({'headerColumn': outputItem.displayName });
        }
        for (let i1 = 0; i1 < inquiryResult.length; i1++) {
          const inquiryResultRow = inquiryResult[i1];
          const inquiryResultRowIndexString = String(i1);
          for (let i2 = 0; i2 < tableItems.length; i2++) {
            const tableItem = tableItems[i2];
            tableItem[inquiryResultRowIndexString] = inquiryResultRow[String(i2)];
          }
        }
      }

      this.inquiryResultTableHeadTopFieldsList = headTopFieldsList;
      this.inquiryResultTableFields = tableFields;
      this.inquiryResultTableItems = tableItems;
    },
    /**
     * 縦にレコードクロス集計テーブル更新処理
     * @param {Object} inquiryResult - 検索結果
     */
    refreshCrossVerticalLoadInquiryResultTable(inquiryResult) {
      if (inquiryResult.length === 0) {
        return;
      }

      // ピボットデータの作成
      const headerRecords = [];
      const dataRecords = [];
      const dataRecordsMap = {};
      for (const record of inquiryResult) {
        let currentDataMap = dataRecordsMap;
        const headerColumns = [];
        for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
          const value = record[`l${i}`];
          if (!(value in currentDataMap)) {
            currentDataMap[value] = {};
          }
          currentDataMap = currentDataMap[value];
          headerColumns.push(value);
        }
        let dataRecordMap = null;
        if (!('dataNo' in currentDataMap)) {
          currentDataMap['dataNo'] = dataRecords.length;
          dataRecordMap = {
            headerColumns: headerColumns,
            dataColumns: {}
          };
          dataRecords.push(dataRecordMap);
        } else {
          dataRecordMap = dataRecords[currentDataMap['dataNo']];
        }
        let keySuffix = '';
        for (let i = 0; i < this.crossTopKeyItems.length; i++) {
          const value = record[`t${i}`];
          keySuffix += (i === 0) ? value : `.${value}`;
          if (headerRecords.length === i) {
            headerRecords.push([value]);
          } else {
            const headerRecord = headerRecords[i];
            if (!headerRecord.includes(value)) {
              headerRecord.push(value);
            }
          }
        }
        for (let i = 0; i < this.outputItems.length; i++) {
          dataRecordMap.dataColumns[`${i}.${keySuffix}`] = record[`${i}`];
        }
      }

      for (let i = 0; i < headerRecords.length; i++) {
        const item = this.crossTopKeyItems[i];
        switch(item.sort) {
        case SORT_CLASS.ASC:
          headerRecords[i] = headerRecords[i].sort((a, b) => (a < b) ? -1 : 1);
          break;
        case SORT_CLASS.DESC:
          headerRecords[i] = headerRecords[i].sort((a, b) => (a > b) ? -1 : 1);
          break;
        default:
          // 何もしません。
          break;
        }
      }

      /*
       * ピボットデータからb-table用データを作成
       */
      const newInquiryResultTableHeadTopFieldsList = [];
      const newInquiryResultTableFields = [];
      const newInquiryResultTableItems = [];

      const keyArray = [];
      for (let i = 0; i < this.outputItems.length; i++) {
        this.createCrossVerticalCSVHelper(headerRecords, 0, i, keyArray);
      }

      headerRecords.unshift(this.outputItems.map(item => item.displayName));

      // thead-top部データの作成
      newInquiryResultTableHeadTopFieldsList.push([]);
      let columnCount = 1;
      for (const item of headerRecords) {
        columnCount *= item.length;
      }
      columnCount += (this.crossLeftKeyItems.length);
      for (let i = 0; i < columnCount; i++) {
        newInquiryResultTableHeadTopFieldsList[0].push({
          label: this.getExcelRowIndex(i + 1),
          colspan: 1
        });
      }
      for (let i1 = 0; i1 < headerRecords.length - 1; i1++) {
        const headerRecord = headerRecords[i1];
        let colspan = 1;
        for (let i2 = i1 + 1; i2 < headerRecords.length; i2++) {
          colspan *= headerRecords[i2].length;
        }

        let repeatCount = 1;
        for (let i2 = 0; i2 < i1; i2++) {
          repeatCount *= headerRecords[i2].length;
        }

        let fields = [];
        for (let i2 = 0; i2 < this.crossLeftKeyItems.length; i2++) {
          fields.push({ label: '', colspan: 1 });
        }
        for (let i2 = 0; i2 < repeatCount; i2++) {
          for (const column of headerRecord) {
            fields.push({ label: column, colspan: colspan });
          }
        }
        newInquiryResultTableHeadTopFieldsList.push(fields);
      }
      // fields部データの作成
      newInquiryResultTableFields.push({
        key: 'rowNo',
        label: String(this.crossTopKeyItems.length + 1),
        isRowHeader: true,
        tdClass: 'text-center title-column'
      });
      for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
        newInquiryResultTableFields.push({
          key: `l${i}`,
          label: '',
          isRowHeader: true,
          tdClass: 'title-column'
        });
      }
      let repeatCount = 1;
      for (let i = 0; i < headerRecords.length - 1; i++) {
        repeatCount *= headerRecords[i].length;
      }
      repeatCount += (this.crossLeftKeyItems.length + 1);
      const lastHeaderRecord = headerRecords[headerRecords.length - 1];
      for (let i = 0, keyArrayIndex = 0; i < repeatCount; i++) {
        for (const label of lastHeaderRecord) {
          newInquiryResultTableFields.push({
            key: keyArray[keyArrayIndex],
            label: `${label}`
          });
          keyArrayIndex++;
        }
      }
      // items部データの作成
      for (const record of dataRecords) {
        let newInquiryResultTableItem = {};
        for (let columnIndex = 0; columnIndex < record.headerColumns.length; columnIndex++) {
          const column = record.headerColumns[columnIndex];
          newInquiryResultTableItem[`l${columnIndex}`] = column;
        }
        newInquiryResultTableItems.push({ ...newInquiryResultTableItem, ...record.dataColumns });
      }

      this.inquiryResultTableHeadTopFieldsList = newInquiryResultTableHeadTopFieldsList;
      this.inquiryResultTableFields = newInquiryResultTableFields;
      this.inquiryResultTableItems = newInquiryResultTableItems;
    },
    /**
     * 横にレコードクロス集計テーブル更新処理
     * @param {Object} inquiryResult - 検索結果
     */
    refreshCrossHolizontalLoadInquiryResultTable(inquiryResult) {
      if (inquiryResult.length === 0) {
        return;
      }

      const headerColumnsList = [];
      const dataColumns = [];
      const dataColumnsMap = {};
      for (const record of inquiryResult) {
        let currentDataMap = dataColumnsMap;
        const headerRecords = [];
        for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
          const value = record[`l${i}`];
          if (!(value in currentDataMap)) {
            currentDataMap[value] = {};
          }
          currentDataMap = currentDataMap[value];
          headerRecords.push(value);
        }
        let dataColumnMap = null;
        if (!('dataNo' in currentDataMap)) {
          currentDataMap['dataNo'] = dataColumns.length;
          dataColumnMap = {
            headerRecords: headerRecords,
            dataRecords: {}
          };
          dataColumns.push(dataColumnMap);
        } else {
          dataColumnMap = dataColumns[currentDataMap['dataNo']];
        }
        let keySuffix = '';
        for (let i = 0; i < this.crossTopKeyItems.length; i++) {
          const value = record[`t${i}`];
          keySuffix += (i === 0) ? value : `.${value}`;
          if (headerColumnsList.length === i) {
            headerColumnsList.push([value]);
          } else {
            const headerColumn = headerColumnsList[i];
            if (!headerColumn.includes(value)) {
              headerColumn.push(value);
            }
          }
        }
        for (let i = 0; i < this.outputItems.length; i++) {
          dataColumnMap.dataRecords[`${this.outputItems[i].displayName}.${keySuffix}`] = record[`${i}`];
        }
      }

      for (let i = 0; i < this.crossTopKeyItems.length; i++) {
        const item = this.crossTopKeyItems[i];
        switch(item.sort) {
        case SORT_CLASS.ASC:
          headerColumnsList[i] = headerColumnsList[i].sort((a, b) => (a < b) ? -1 : 1);
          break;
        case SORT_CLASS.DESC:
          headerColumnsList[i] = headerColumnsList[i].sort((a, b) => (a > b) ? -1 : 1);
          break;
        default:
          // 何もしません。
          break;
        }
      }

      /*
       * ピボットデータからb-table用データを作成
       */
      const newInquiryResultTableHeadTopFieldsList = [];
      const newInquiryResultTableFields = [];
      const newInquiryResultTableItems = [];

      // thead-top部データの作成
      newInquiryResultTableHeadTopFieldsList.push([]);
      for (let i = 0; i < 1 + this.crossTopKeyItems.length + dataColumns.length; i++) {
        newInquiryResultTableHeadTopFieldsList[0].push({
          label: this.getExcelRowIndex(i + 1), colspan: 1
        });
      }
      for (let i1 = 0; i1 < this.crossLeftKeyItems.length - 1; i1++) {
        const headTopFields = [];
        newInquiryResultTableHeadTopFieldsList.push(headTopFields);
        headTopFields.push({ label: '', colspan: 1 }); // 出力項目名列
        for (let i2 = 0; i2 < this.crossTopKeyItems.length; i2++) {
          headTopFields.push({ label: '', colspan: 1 });
        }
        for (const dataColumn of dataColumns) {
          headTopFields.push({ label: dataColumn.headerRecords[`${i1}`], colspan: 1 });
        }
      }
      // fields部データの作成
      headerColumnsList.unshift(
        this.outputItems.map(item => this.csvStringValue(item.displayName))
      );
      newInquiryResultTableFields.push({
        key: 'rowNo',
        label: String(this.crossLeftKeyItems.length),
        isRowHeader: true,
        tdClass: 'text-center title-column'
      });
      for (let i = 0; i < headerColumnsList.length; i++) {
        newInquiryResultTableFields.push({
          key: `t${i}`,
          label: '',
          isRowHeader: true,
          tdClass: 'title-column'
        });
      }
      for (let i = 0; i < dataColumns.length; i++) {
        const dataColumn = dataColumns[i];
        let value = dataColumn.headerRecords[dataColumn.headerRecords.length - 1];
        if (typeof value !== 'string') {
          value = String(value);
        }
        newInquiryResultTableFields.push({
          key: String(i),
          label: value,
          tdClass: 'align-middle'
        });
      }
      // items部データの作成
      const dataRecords = [];
      for (let i1 = 0; i1 < headerColumnsList.length; i1++) {
        let repeatMax = 1;
        for (let i2 = 0; i2 < i1; i2++) {
          repeatMax *= headerColumnsList[i2].length;
        }
        let span = 1;
        for (let i2 = i1 + 1; i2 < headerColumnsList.length; i2++) {
          span *= headerColumnsList[i2].length;
        }
        const headerColumns = headerColumnsList[i1];
        for (let i2 = 0; i2 < repeatMax; i2++) {
          for (let i3 = 0; i3 < headerColumns.length; i3++) {
            let index = headerColumns.length * i2 + span * i3;
            if (dataRecords.length === index) {
              dataRecords.push({ headerColumns: [], key: '' });
            }
            let dataRecord = dataRecords[index];
            const headerColumn = headerColumns[i3];
            if (i1 === 0) {
              dataRecord.headerColumns.push(headerColumn);
              dataRecord.key += headerColumn;
            } else {
              dataRecord.headerColumns.push(headerColumn);
              dataRecord.key += `.${headerColumn}`;
            }
            for (let i4 = 1; i4 < span; i4++) {
              index++;
              if (dataRecords.length === index) {
                dataRecords.push({ headerColumns: [], key: '' });
              }
              dataRecord = dataRecords[index];
              if (i1 === 0) {
                dataRecord.headerColumns.push('');
                dataRecord.key = headerColumn;
              } else if (i1 === headerColumnsList.length - 1) {
                dataRecord.headerColumns.push(headerColumn);
                dataRecord.key = `.${headerColumn}`;
              } else {
                dataRecord.headerColumns.push('');
                dataRecord.key = `.${headerColumn}`;
              }
            }
          }
        }
      }
      for (const dataRecord of dataRecords) {
        const record = {};
        for (let i = 0; i < dataRecord.headerColumns.length; i++) {
          record[`t${i}`] = dataRecord.headerColumns[i];
        }
        for (let i = 0; i < dataColumns.length; i++) {
          const dataColumn = dataColumns[i];
          record[String(i)] = dataColumn.dataRecords[dataRecord.key];
        }
        newInquiryResultTableItems.push(record);
      }

      this.inquiryResultTableHeadTopFieldsList = newInquiryResultTableHeadTopFieldsList;
      this.inquiryResultTableFields = newInquiryResultTableFields;
      this.inquiryResultTableItems = newInquiryResultTableItems;
    },
    /**
     * 検索ボタンクリックイベント処理
     */
    async onSearchButtonClick() {
      this.$store.commit('setLoading', true);
      this.successMessages = [];
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (this.checkConsistency()) {
          this.modalErrorMessages = [];
          this.inquiryResultTableHeadTopFieldsList = [];
          this.inquiryResultTableFields = [];
          this.inquiryResultTableItems = [];

          this.$store.commit('setLoading', false);
          this.$bvModal.show('inquiry-result-table-modal');
          this.isInquiryResultTableBusy = true;
          if (this.loadMethod === LOAD_METHOD_CLASS.STANDARD) {
            const sql = this.createStandardLoadSql() + ' LIMIT 1000';
            const inquiryResult = await this.executeSelectSql(sql);
            if (inquiryResult !== null) {
              if (inquiryResult.length === 1000) {
                this.modalErrorMessages.push(DISP_MESSAGES.WARNING['2002']);
              }
              this.refreshStandardLoadInquiryResultTable(inquiryResult);
            }
          } else {
            const sql = this.executeCrossLoadSql() + ' LIMIT 1000';
            const inquiryResult = await this.executeSelectSql(sql);
            if (inquiryResult !== null) {
              if (inquiryResult.length === 1000) {
                this.modalErrorMessages.push(DISP_MESSAGES.WARNING['2002']);
              }
              if (this.loadDirection === this.loadDirectionClass.VERTICAL) {
                this.refreshCrossVerticalLoadInquiryResultTable(inquiryResult);
              } else {
                this.refreshCrossHolizontalLoadInquiryResultTable(inquiryResult);
              }
            }
          }
          this.isInquiryResultTableBusy = false;
        } else {
          scrollTo(0, 0);
          this.$store.commit('setLoading', false);
        }
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
        this.$store.commit('setLoading', false);
      }
    },
    /**
     * SQL文を実行します。SELECT文を実行することを想定しています。
     * @param {String} sql - SELECTのSQL文
     * @returns {Object} SQL文の結果
     */
    async executeSelectSql(sql) {
      const functionName = 'executeSelectSql';

      const sqls = [sql];
      let resp = null;
      try {
        resp = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.modalErrorMessages.push(DISP_MESSAGES.WARNING['2001']);
        return null;
      }
      if (resp.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: resp
        });
        this.modalErrorMessages.push(DISP_MESSAGES.WARNING['2001']);
        return null;
      }
      const body = JSON.parse(resp.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          body: body
        });
        this.modalErrorMessages.push(DISP_MESSAGES.WARNING['2001']);
        return null;
      }
      return body.data[0];
    },
    /**
     * 画面上の整合性をチェックします。
     * @returns {Boolean} 整合性に問題が無い場合はtrue、問題がある場合はfalseを返します。
     */
    checkConsistency() {
      let isSuccess = true;
      if (this.froms.length === 0) {
        isSuccess = false;
        this.errorMessages.push(DISP_MESSAGES.WARNING['2011'].replace('%arg1%', '参照テーブル'));
      }
      if (this.outputItems.length === 0) {
        isSuccess = false;
        this.errorMessages.push(DISP_MESSAGES.WARNING['2011'].replace('%arg1%', '出力項目'));
      }
      if (this.loadMethod === this.loadMethodClass.CROSS) {
        if (this.crossLeftKeyItems.length === 0) {
          isSuccess = false;
          this.errorMessages.push(DISP_MESSAGES.WARNING['2011'].replace('%arg1%', '左側キー項目'));
        }
        if (this.crossTopKeyItems.length === 0) {
          isSuccess = false;
          this.errorMessages.push(DISP_MESSAGES.WARNING['2011'].replace('%arg1%', '上側キー項目'));
        }
      }
      return isSuccess;
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * CSV出力ボタンクリックイベント処理
     */
    async onClickOutputCsvButtonClick() {
      const functionName = 'onClickOutputCsvButtonClick';
      this.$store.commit('setLoading', true);

      this.successMessages = [];
      this.errorMessages = [];

      // バリデーション
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
        return;
      }

      // 画面上の整合性をチェック
      if (!this.checkConsistency()) {
        scrollTo(0, 0);
        return;
      }

      // CSVにヘッダを出力するか
      let isHeader = null;
      if (this.loadMethod === this.loadMethodClass.STANDARD) {
        this.$store.commit('setLoading', false);
        isHeader = await this.$bvModal.msgBoxConfirm('項目名を出力しますか？', {
          title: 'CSV出力',
          cancelTitle: 'いいえ',
          okTitle: 'はい'
        });
        this.$store.commit('setLoading', true);
      }
      // console.log({isHeader});

      // SQLを設定
      const sql = (this.loadMethod === this.loadMethodClass.STANDARD)
        ? this.createStandardLoadSql()
        : this.executeCrossLoadSql();

      // 非同期SELECTリクエスト
      let processId;
      try {
        processId = await requestQueryAsync({
          type: QUERY_REQUEST_TYPE.QUERY_FOR_CREATE_CSV,
          sqls: [sql],
          csvOutputConfig: {
            loadMethod: this.loadMethod,
            loadDirection: this.loadDirection,
            outputItemsLength: this.outputItems.length,
            crossLeftKeyItemsLength: this.crossLeftKeyItems.length
          }
        });
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          message: DISP_MESSAGES.WARNING['2063']
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // ローディングの終了
        this.$store.commit('setLoading', false);
        return;
      }

      // DataCreationStatusテーブルを監視する。
      let subscription;
      try {
        subscription = API.graphql(
          graphqlOperation(onUpdateQueryStatus, { ProcessID: processId })
        ).subscribe({
          next: async ({ value }) => {
            // ステータスの変更を検知した際の処理
            const result = await onQueryStatusUpdate(value?.data?.onUpdateQueryStatus ?? null, subscription);

            if(result === undefined) return;
            if(!result) {
              this.alertDanger.push(DISP_MESSAGES.WARNING['2001']);
              // 総件数をdataTableの総件数にセット
              this.totalRows = 0;
              // ローディングの終了
              this.$store.commit('setLoading', false);
              return;
            }
            if(result[0].length > 0) {
              // 結果データからCSVデータを作成
              const csvData = this.createCsvData(result[0], isHeader);
              // CSVファイルをダウンロード
              await this.downloadCsvFile(csvData);
            } else {
              // データが存在しない旨メッセージ表示
              this.errorMessages.push(DISP_MESSAGES.WARNING['2065']);
              scrollTo(0, 0);
            }
            // ローディングの終了
            this.$store.commit('setLoading', false);
          }
        });      
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'onQueryStatusUpdate',
          message: DISP_MESSAGES.WARNING['2064'],
          SQLs: sql,
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // ローディングの終了
        this.$store.commit('setLoading', false);
        return;
      }
    },
    createCsvData(jsonData, isHeader) {
      if (this.loadMethod === this.loadMethodClass.STANDARD) {
        if (this.loadDirection === this.loadDirectionClass.VERTICAL) {
          // console.log('createStandardVerticalCSV');
          return this.createStandardVerticalCSV(jsonData, isHeader);
        } else {
          // console.log('createStandardHorizontalCSV');
          return this.createStandardHorizontalCSV(jsonData, isHeader);
        }
      }
      if (this.loadDirection === this.loadDirectionClass.VERTICAL) {
        // console.log('createCrossVerticalCSV');
        return this.createCrossVerticalCSV(jsonData);
      } else {
        // console.log('createCrossHorizontalCSV');
        return this.createCrossHorizontalCSV(jsonData);
      }
    },
    downloadCsvFile(csvData) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blobPart = csvData.length === 0 ? [csvData] : [bom, csvData];
      const blob = new Blob(blobPart, { type: 'text/csv' });
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.download = `照会レポート_${this.inquiryName}_${formatCurDate('YYYYMMDDHHmm')}.csv`;
      download.href = url;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    },
    /**
     * SELECTデータを取得してS3にSELECTデータファイルを作成し、ファイルのデータを
     * 取得します。データ取得後、S3のSELECTデータファイルを削除します。
     * @param {String} selectSql - SELECT文
     * @returns {ExecuteStatementCommandOutput} ExecuteStatementCommandの戻り値
     */
    async createSelectData(selectSql) {
      const functionName = 'createSelectData';

      // 認証情報を取得します。
      let credentials = null;
      try {
        credentials = await Auth.currentCredentials();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          message: '認証情報の取得に失敗しました。'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2001']);
        return null;
      }

      let keys = [];
      let sqls = [ selectSql ];
      let splitRetry = 0;
      for (let i = 0; i < sqls.length; i++) {
        let sql = sqls[i];
        // S3にSELECTデータを作成します。
        const createSelectDataOnS3Input = {
          SQLs: [ sql ],
          level: 'private',
          identityId: credentials.identityId
        };
        let result1 = null;
        try {
          result1 = await API.graphql(graphqlOperation(createSelectDataOnS3, {
            createSelectDataOnS3Input: createSelectDataOnS3Input
          }));
          if (result1.data.createSelectDataOnS3.statusCode > 200) {
            const responseBody = JSON.parse(result1.data.createSelectDataOnS3.body);

            if (splitRetry < MAX_SPLIT_RETRY &&
            responseBody.message.toUpperCase() == 'Database returned more than the allowed response size limit'.toUpperCase()) {
              // 分割前にサイズオーバーとなった場合、分割してやり直し
              sqls = await this.getSplitSql(selectSql, RETRY_SPLIT_CNT[splitRetry]);
              //console.log(sqls);
              i = -1;
              splitRetry++;
              //console.log(splitRetry);
              if (keys.length > 0) {
                // S3にファイルが残っている場合、削除します。
                await this.removeS3(keys);
              }
              continue;
            }
            throw responseBody.message;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'createSelectDataOnS3',
            variables: { createSelectDataOnS3Input: createSelectDataOnS3Input }
          }, error);
          // S3のCSVファイルを削除します。
          await this.removeS3(keys);
          if (typeof(error) == 'string' &&
            error.toUpperCase() == 'Database returned more than the allowed response size limit'.toUpperCase()) {
            this.errorMessages.push(DISP_MESSAGES.WARNING['2054']);
          } else {
            this.errorMessages.push(DISP_MESSAGES.WARNING['2001']);
          }
          return null;
        }
        let body = JSON.parse(result1.data.createSelectDataOnS3.body);
        keys.push(body.keys[0]);
      }

      let jsonList = [];
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        // S3からSELECTデータファイルを取得します。
        let result2 = null;
        try {
          result2 = await Storage.get(key, {
            download: true,
            level: 'private'
          });
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            message: 'S3からSELECTデータファイルの取得に失敗しました。',
            key: key
          }, error);
          // S3のCSVファイルを削除します。
          await this.removeS3(keys);
          this.errorMessages.push(DISP_MESSAGES.WARNING['2001']);
          return null;
        }

        // SELECTデータを取得します。
        try {
          jsonList.push(await result2.Body.text());
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            message: 'S3からCSVデータの取得に失敗しました。',
            key: key
          }, error);
          // S3のCSVファイルを削除します。
          await this.removeS3(keys);
          this.errorMessages.push(DISP_MESSAGES.WARNING['2001']);
          return null;
        }
      }
      // S3に残ったファイルを削除します。
      await this.removeS3(keys);

      let executeStatementCommandOutput = JSON.parse(jsonList[0]);

      for (let i = 1; i < jsonList.length; i++) {
        let addRecords = JSON.parse(jsonList[i]).records;
        executeStatementCommandOutput.records = executeStatementCommandOutput.records.concat(addRecords);
      }

      return executeStatementCommandOutput;
    },
    /**
     * 引数keys内のcsvファイルをS3から全て削除します。
     * @param {String} sql - 元となるSQL
     * @param {String} splitCnt - 分割数
     * @returns {[String]} - 分割されたSQL配列
     */
    async getSplitSql(sql, splitCnt) {
      let sqls = [];
      let selectSqlCount = 'SELECT COUNT(*) AS CNT FROM (' + sql + ') AS COUNT_QUERY';
      let dataResult = await executeSelectSql(selectSqlCount);
      let selectCnt = dataResult[0].CNT;
      //console.log(selectCnt);

      if (selectCnt >= splitCnt) {
        let limitCnt = Math.ceil(selectCnt / splitCnt);
        for (let i = 0; i < splitCnt; i++) {
          sqls.push(sql + ' LIMIT ' + limitCnt + ' OFFSET ' + (i * limitCnt).toString());
        }
      } else {
        let limitCnt = 1;
        for (let i = 0; i < selectCnt; i++) {
          sqls.push(sql + ' LIMIT ' + limitCnt + ' OFFSET ' + (i * limitCnt).toString());
        }
      }
      //console.log(sqls);

      return sqls;
    },
    /**
     * 引数keys内のcsvファイルをS3から全て削除します。
     * @param {[String]} keys - 削除対象のs3ファイル名
     */
    async removeS3(keys) {
      const functionName = 'removeS3';
      // S3のCSVファイルを削除します。
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        try {
          await Storage.remove(key, { level: 'private' });
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            message: 'S3からSELECTデータファイルの削除に失敗しました。',
            key: key
          }, error);
        }
      }
      keys = [];
    },
    /**
     * 標準読み込み／縦にレコードのCSVデータ作成処理
     * @param {ExecuteStatementCommandOutput} executeStatementCommandOutput - ExecuteStatementCommandの戻り値
     * @param {Boolean} isHeader - 項目名出力フラグ
     * @returns {String} - CSVデータ
     */
    createStandardVerticalCSV(parsedJsonData, isHeader) {
      let csvString = '';
      if (isHeader) {
        for (let outputItemIndex = 0; outputItemIndex < this.outputItems.length; outputItemIndex++) {
          const outputItem = this.outputItems[outputItemIndex];
          if (outputItemIndex !== 0) {
            csvString += ',';
          }
          csvString += outputItem.displayName;
        }
        csvString += '\n';
      }

      let csvDataList = [];
      for (const record of parsedJsonData) {
        let csvDataRecord = '';
        for (let columnIndex = 0; columnIndex < Object.keys(record).length; columnIndex++) {
          const column = record[columnIndex];
          if (columnIndex !== 0) {
            csvDataRecord += ',';
          }
          const value = this.getStandardCsvColumnValue(column);
          csvDataRecord += value;
        }
        csvDataRecord += '\n';

        csvDataList.push(csvDataRecord);
      }
      for (let i = 0; i < csvDataList.length; i++) {
        csvString += csvDataList[i];
      }
      return csvString;
    },
    /**
     * 標準読み込み／横にレコードのCSVデータ作成処理
     * @param {ExecuteStatementCommandOutput} executeStatementCommandOutput - ExecuteStatementCommandの戻り値
     * @param {Boolean} isHeader - 項目名出力フラグ
     * @returns {String} - CSVデータ
     */
    createStandardHorizontalCSV(parsedJsonData, isHeader) {
      const csvData = [];
      for (const outputItem of this.outputItems) {
        const record = [];
        if (isHeader) {
          record.push(outputItem.displayName);
        }
        csvData.push(record);
      }

      for (const record of parsedJsonData) {
        for (let columnIndex = 0; columnIndex < Object.keys(record).length; columnIndex++) {
          const column = record[columnIndex];
          const value = this.getStandardCsvColumnValue(column);
          csvData[columnIndex].push(value);
        }
      }

      let csvString = '';
      let csvDataList = [];
      for (const record of csvData) {
        let csvDataRecord = '';
        for (let columnIndex = 0; columnIndex < record.length; columnIndex++) {
          const column = record[columnIndex];
          if (columnIndex !== 0) {
            csvDataRecord += ',';
          }
          csvDataRecord += column;
        }
        csvDataRecord += '\n';

        csvDataList.push(csvDataRecord);
      }
      for (let i = 0; i < csvDataList.length; i++) {
        csvString += csvDataList[i];
      }
      return csvString;
    },
    /**
     * クロス集計／縦にレコードのCSVデータ作成処理
     * @param {parsedJsonData} parsedJsonData - ExecuteStatementCommandの戻り値
     * @returns {String} - CSVデータ
     */
    createCrossVerticalCSV(parsedJsonData) {
      if (parsedJsonData.length === 0) {
        return '';
      }

      // カラム名とカラムの順番を紐づけ
      const columnNoMap = {};
      const objectKeys = Object.keys(parsedJsonData[0]);
      for (let i = 0; i < objectKeys.length; i++) {
        columnNoMap[objectKeys[i]] = i;
      }

      const headerRecords = [];
      const dataRecords = [];
      const dataRecordsMap = {};
      for (const record of parsedJsonData) {
        let currentDataMap = dataRecordsMap;
        const headerColumns = [];
        for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
          // const column = [record[columnNoMap[`l${i}`]]];
          // const value = Object.values(column)[0];
          const value = record[`l${i}`];
          if (!(value in currentDataMap)) {
            currentDataMap[value] = {};
          }
          currentDataMap = currentDataMap[value];
          headerColumns.push(value);
        }
        let dataRecordMap = null;
        if (!('dataNo' in currentDataMap)) {
          currentDataMap['dataNo'] = dataRecords.length;
          dataRecordMap = {
            headerColumns: headerColumns,
            dataColumns: {}
          };
          dataRecords.push(dataRecordMap);
        } else {
          dataRecordMap = dataRecords[currentDataMap['dataNo']];
        }
        let keySuffix = '';
        for (let i = 0; i < this.crossTopKeyItems.length; i++) {
          const columnValue = record[`t${i}`];
          keySuffix += (i === 0) ? columnValue : `.${columnValue}`;
          if (headerRecords.length === i) {
            headerRecords.push([columnValue]);
          } else {
            const headerRecord = headerRecords[i];
            if (!headerRecord.includes(columnValue)) {
              headerRecord.push(columnValue);
            }
          }
        }
        for (let i = 0; i < this.outputItems.length; i++) {
          dataRecordMap.dataColumns[`${i}.${keySuffix}`] = record[`${i}`];
        }
      }

      for (let i = 0; i < this.crossTopKeyItems.length; i++) {
        const item = this.crossTopKeyItems[i];
        switch(item.sort) {
        case SORT_CLASS.ASC:
          headerRecords[i] = headerRecords[i].sort((a, b) => (a < b) ? -1 : 1);
          break;
        case SORT_CLASS.DESC:
          headerRecords[i] = headerRecords[i].sort((a, b) => (a > b) ? -1 : 1);
          break;
        default:
          // 何もしません。
          break;
        }
      }

      /**
       * CSVデータの作成
       */
      let csvData = '';
      // ヘッダー行
      headerRecords.unshift(
        this.outputItems.map(item => this.csvStringValue(item.displayName))
      );
      let headerIndentColumns = '';
      for (let i = 0; i < this.crossLeftKeyItems.length - 1; i++) {
        headerIndentColumns += ',';
      }
      for (let i1 = 0; i1 < headerRecords.length; i1++) {
        let commaMax = 1;
        for (let i2 = i1 + 1; i2 < headerRecords.length; i2++) {
          commaMax *= headerRecords[i2].length;
        }
        commaMax--;

        let repeatCount = 1;
        for (let i2 = 0; i2 < i1; i2++) {
          repeatCount *= headerRecords[i2].length;
        }

        let s = '';
        for (let i2 = 0; i2 < repeatCount; i2++) {
          for (const item of headerRecords[i1]) {
            s += (typeof item === 'string') ? `,${this.csvStringValue(item)}` : `,${item}`;
            for (let i3 = 0; i3 < commaMax; i3++) {
              s += ',';
            }
          }
        }
        csvData += `${headerIndentColumns}${s}\n`;
      }

      // データ行
      let csvDataList = [];
      for (const record of dataRecords) {
        let csvDataRecord = '';
        // ヘッダーカラム
        for (const column of record.headerColumns) {
          if (typeof column === 'string') {
            csvDataRecord += `${this.csvStringValue(column)},`;
          } else if (String(column).toUpperCase() === 'null'.toUpperCase() && String(column).toUpperCase() === 'true'.toUpperCase()) {
            csvDataRecord += ',';
          } else {
            csvDataRecord += `${column},`;
          }
        }
        // データカラム
        const keyArray = [];
        for (let i = 0; i < this.outputItems.length; i++) {
          this.createCrossVerticalCSVHelper(headerRecords, 1, i, keyArray);
        }
        for(let i = 0; i < keyArray.length; i++) {
          const column = record.dataColumns[keyArray[i]];
          if (column) {
            if (typeof column === 'string') {
              csvDataRecord += this.csvStringValue(column);
            } else if (String(column).toUpperCase() === 'null'.toUpperCase() && String(column).toUpperCase() === 'true'.toUpperCase()) {
              csvDataRecord += '';
            } else {
              csvDataRecord += String(column);
            }
          }
          if (i < keyArray.length - 1) {
            csvDataRecord += ',';
          }
        }
        csvDataRecord += '\n';

        csvDataList.push(csvDataRecord);
      }
      for (let i = 0; i < csvDataList.length; i++) {
        csvData += csvDataList[i];
      }
      return csvData;
    },
    /**
     * クロス集計／横にレコードのCSVデータ作成処理
     * @param {parsedJsonData} parsedJsonData - ExecuteStatementCommandの戻り値
     * @returns {String} - CSVデータ
     */
    createCrossHorizontalCSV(parsedJsonData) {
      try {
        if (parsedJsonData.length === 0) {
          return '';
        }

        // カラム名とカラムの順番を紐づけ
        const columnNoMap = {};
        const objectKeys = Object.keys(parsedJsonData[0]);
        for (let i = 0; i < objectKeys.length; i++) {
          columnNoMap[objectKeys[i]] = i;
        }

        const headerColumnsList = [];
        const dataColumns = [];
        const dataColumnsMap = {};
        for (const record of parsedJsonData) {
          let currentDataMap = dataColumnsMap;
          const headerRecords = [];
          for (let i = 0; i < this.crossLeftKeyItems.length; i++) {
            // const column = record[columnNoMap[`l${i}`]];
            // const value = Object.values(column)[0];
            const value = record[`l${i}`];
            if (!(value in currentDataMap)) {
              currentDataMap[value] = {};
            }
            currentDataMap = currentDataMap[value];
            headerRecords.push(value);
          }
          let dataColumnMap = null;
          if (!('dataNo' in currentDataMap)) {
            currentDataMap['dataNo'] = dataColumns.length;
            dataColumnMap = {
              headerRecords: headerRecords,
              dataRecords: {}
            };
            dataColumns.push(dataColumnMap);
          } else {
            dataColumnMap = dataColumns[currentDataMap['dataNo']];
          }
          let keySuffix = '';
          for (let i = 0; i < this.crossTopKeyItems.length; i++) {
            const columnValue = record[`t${i}`];
            keySuffix += (i === 0) ? columnValue : `.${columnValue}`;
            if (headerColumnsList.length === i) {
              headerColumnsList.push([columnValue]);
            } else {
              const headerColumn = headerColumnsList[i];
              if (!headerColumn.includes(columnValue)) {
                headerColumn.push(columnValue);
              }
            }
          }
          for (let i = 0; i < this.outputItems.length; i++) {
            dataColumnMap.dataRecords[`${this.outputItems[i].displayName}.${keySuffix}`] = record[`${i}`];
          }
        }

        for (let i = 0; i < this.crossTopKeyItems.length; i++) {
          const item = this.crossTopKeyItems[i];
          switch(item.sort) {
          case SORT_CLASS.ASC:
            headerColumnsList[i] = headerColumnsList[i].sort((a, b) => (a < b) ? -1 : 1);
            break;
          case SORT_CLASS.DESC:
            headerColumnsList[i] = headerColumnsList[i].sort((a, b) => (a > b) ? -1 : 1);
            break;
          default:
            // 何もしません。
            break;
          }
        }

        /**
         * CSVデータの作成
         */
        let csvData = '';
        // ヘッダー行
        console.log({dataColumns});
        for (let i1 = 0; i1 < this.crossLeftKeyItems.length; i1++) {
          for (let i2 = 0; i2 < headerColumnsList.length; i2++) {
            csvData += ',';
          }
          for (const dataColumn of dataColumns) {
            csvData += `,${dataColumn.headerRecords[`${i1}`]}`;
          }
          csvData += '\n';
        }
        // データ行
        headerColumnsList.unshift(
          this.outputItems.map(item => this.csvStringValue(item.displayName))
        );
        const dataRecords = [];
        for (let i1 = 0; i1 < headerColumnsList.length; i1++) {
          let repeatMax = 1;
          for (let i2 = 0; i2 < i1; i2++) {
            repeatMax *= headerColumnsList[i2].length;
          }
          let span = 1;
          for (let i2 = i1 + 1; i2 < headerColumnsList.length; i2++) {
            span *= headerColumnsList[i2].length;
          }
          const headerColumns = headerColumnsList[i1];
          for (let i2 = 0; i2 < repeatMax; i2++) {
            for (let i3 = 0; i3 < headerColumns.length; i3++) {
              let index = headerColumns.length * i2 + span * i3;
              if (dataRecords.length === index) {
                dataRecords.push({ record: '', key: '' });
              }
              let dataRecord = dataRecords[index];
              const headerColumn = headerColumns[i3];
              if (i1 === 0) {
                dataRecord.record += headerColumn;
                dataRecord.key += headerColumn;
              } else {
                dataRecord.record += `,${headerColumn}`;
                dataRecord.key += `.${headerColumn}`;
              }
              for (let i4 = 1; i4 < span; i4++) {
                index++;
                if (dataRecords.length === index) {
                  dataRecords.push({ record: '', key: '' });
                }
                dataRecord = dataRecords[index];
                if (i1 === 0) {
                  dataRecord.record = '';
                  dataRecord.key = headerColumn;
                } else if (i1 === headerColumnsList.length - 1) {
                  dataRecord.record = `,${headerColumn}`;
                  dataRecord.key = `.${headerColumn}`;
                } else {
                  dataRecord.record = ',';
                  dataRecord.key = `.${headerColumn}`;
                }
              }
            }
          }
        }
        let csvDataList = [];
        console.log({dataColumns});
        for (const dataRecord of dataRecords) {
          let csvDataRecord = '';
          csvDataRecord += dataRecord.record;
          for (const dataColumn of dataColumns) {
            let value = dataColumn.dataRecords[dataRecord.key];
            if (value) {
              if (typeof value === 'string') {
                value = this.csvStringValue(value);
              } else if (String(value).toUpperCase() === 'null'.toUpperCase() && String(value).toUpperCase() === 'true'.toUpperCase()) {
                value = ',';
              }
              csvDataRecord += `,${value}`;
            } else {
              csvDataRecord += ',';
            }
          }
          csvDataRecord += '\n';

          csvDataList.push(csvDataRecord);
        }
        for (let i = 0; i < csvDataList.length; i++) {
          csvData += csvDataList[i];
        }

        return csvData;
      } catch (error) {
        console.log(error);
        this.$store.commit('setLoading', false);
      }
    },
    // CSVデータのフォーマットを行います
    getStandardCsvColumnValue(columnValue) {
      const stringValue = String(columnValue);
      if(stringValue.toUpperCase() === 'null'.toUpperCase() || stringValue.toUpperCase() === 'true'.toUpperCase()) return '';
      if(stringValue.indexOf('T00:00:00.000Z') !== -1) return stringValue.replace('T00:00:00.000Z', '')
      if(stringValue.includes('"') || stringValue.includes(',')) return this.csvStringValue(columnValue);
      return columnValue;
    },
    createCrossVerticalCSVHelper(a, i, s, r) {
      if (i < a.length - 1) {
        for (const item of a[i]) {
          this.createCrossVerticalCSVHelper(a, i + 1, `${s}.${item}`, r);
        }
      } else {
        for (const item of a[i]) {
          r.push(`${s}.${item}`);
        }
      }
    },
    /**
     * 文字列データに'"'が含まれている場合はエスケープを行い、'"'と','が含まれている場合は
     * '"'で囲います。
     * @param {String} value - 文字列データ
     * @returns {String} 処理後の文字列データ
     */
    csvStringValue(value) {
      let newValue = value;
      const isDoubleQuote = value.includes('"');
      if (isDoubleQuote) {
        // node.jsでString.prototype.replaceAll()がサポートされるのはv15.0.0から
        newValue = newValue.replace(/"/g, '""');
      }
      if (isDoubleQuote || value.includes(',')) {
        newValue = `"${newValue}"`;
      }
      return newValue;
    },
    /**
     * 保存ボタンクリックイベント処理
     */
    async onSaveButtonClick() {
      this.successMessages = [];
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('照会レポート定義を保存します。よろしいですか？', {
          title: '照会レポート定義の保存',
        })) {
          this.$store.commit('setLoading', true);
          if (this.isEdit) {
            await this.execUpdate();
            scrollTo(0, 0);
          } else {
            const id = await this.execInsert();
            if (this.errorMessages.length === 0) {
              // INSERTに成功した場合は編集画面へ遷移
              this.id = id;
              this.$router.push({ name: 'INQUIRY-REPORT-EDIT', query: { id: id }});
            } else {
              scrollTo(0, 0);
            }
          }
        }
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 画面に入力された定義を登録します。
     * @returns {Number} 登録された定義のID
     */
    async execInsert() {
      const functionName = 'execInsert';

      // 登録された定義のID
      let id = -1;

      // INSERT文を作成
      const sql = await this.createInsertStatement();
      const sqls = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return id;
        }
      } catch (error) {
        const msg = DISP_MESSAGES.DANGER['3001'];
        await addOperationLogs('Error', MODULE_NAME, functionName, msg, error);
        this.errorMessages.push(msg);
        return id;
      }

      // 登録
      let executeTransactSqlResult = null;
      try {
        executeTransactSqlResult = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return id;
      }

      if (executeTransactSqlResult.errors) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      } else {
        const body = JSON.parse(executeTransactSqlResult.data.executeTransactSql.body);
        if (body.error) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        } else {
          id = body.data[0][0]['LAST_INSERT_ID()'];
        }
      }
      await addOperationLogs(id === -1 ? 'Error' : 'Info', MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        result: executeTransactSqlResult
      });
      return id;
    },
    /**
     * 画面上の定義からINSERT文を作成します。
     * @returns {String} INSERT文
     */
    async createInsertStatement() {
      const colList = [];
      // 照会定義名
      colList.push(CreateColRow('inquiry_name', this.inquiryName, 'VARCHAR'));
      // コメント
      colList.push(CreateColRow('description', this.description, 'VARCHAR'));
      // 参照テーブル
      const insertFroms = [];
      for (const from of this.froms) {
        const insertFrom = Object.assign({}, from);
        insertFroms.push(insertFrom);
        delete insertFrom.table;
        insertFrom.conditions = [];
        for (let conditionIndex = 0; conditionIndex < from.conditions.length; conditionIndex++) {
          const condition = from.conditions[conditionIndex];
          const insertCondition = Object.assign({}, condition);
          insertFrom.conditions.push(insertCondition);
          if (conditionIndex > 0) {
            insertCondition.left = Object.assign({}, condition.left);
            delete insertCondition.left.column;
          }
        }
      }
      colList.push(CreateColRow('froms', JSON.stringify(insertFroms), 'VARCHAR'));
      // 検索条件
      const insertWheres = [];
      for (const where of this.wheres) {
        const insertWhere = Object.assign({}, where);
        insertWhere.left = Object.assign({}, where.left);
        delete insertWhere.left.column;
        insertWheres.push(insertWhere);
      }
      colList.push(CreateColRow('wheres', JSON.stringify(insertWheres), 'VARCHAR'));
      // 読み込み方法
      colList.push(CreateColRow('load_method', this.loadMethod, 'NUMBER'));
      // 読み込み方向
      colList.push(CreateColRow('load_direction', this.loadDirection, 'NUMBER'));
      // 出力項目
      colList.push(CreateColRow('output_items', JSON.stringify(this.outputItems), 'VARCHAR'));
      if (this.loadMethod === this.loadMethodClass.CROSS) {
        // クロス左側キー項目
        colList.push(CreateColRow('cross_left_keys', JSON.stringify(this.crossLeftKeyItems), 'VARCHAR'));
        // クロス上側キー項目
        colList.push(CreateColRow('cross_top_keys', JSON.stringify(this.crossTopKeyItems), 'VARCHAR'));
      }
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('created_user', username, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
      return CreateInsertSql(colList, 'full', 'm_inquiries_definitions');
    },
    /**
     * 画面に入力された照会定義を更新します。
     */
    async execUpdate() {
      const functionName = 'execUpdate';

      // UPDATE文を作成
      const sql = await this.createUpdateStatement() + ` WHERE id = '${this.id}'`;
      const sqls = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      // 更新
      let executeTransactSqlResult = null;
      try {
        executeTransactSqlResult = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let isSuccess = true;
      if (executeTransactSqlResult.errors) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        isSuccess = false;
      } else {
        const body = JSON.parse(executeTransactSqlResult.data.executeTransactSql.body);
        if (body.error) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          isSuccess = false;
        } else {
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1003']);
        }
      }
      await addOperationLogs(isSuccess ? 'Info' : 'Error', MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqls,
        result: executeTransactSqlResult
      });
    },
    /**
     * 画面上に入力された定義のUPDATE文を作成します。
     * @returns {String} UPDATE文
     */
    async createUpdateStatement() {
      const colList = [];
      // 照会定義名
      colList.push(CreateColRow('inquiry_name', this.inquiryName, 'VARCHAR'));
      // コメント
      colList.push(CreateColRow('description', this.description, 'VARCHAR'));
      // 参照テーブル
      const updateFroms = [];
      for (const from of this.froms) {
        const updateFrom = Object.assign({}, from);
        updateFroms.push(updateFrom);
        delete updateFrom.table;
        updateFrom.conditions = [];
        for (let conditionIndex = 0; conditionIndex < from.conditions.length; conditionIndex++) {
          const condition = from.conditions[conditionIndex];
          const updateCondition = Object.assign({}, condition);
          updateFrom.conditions.push(updateCondition);
          if (conditionIndex > 0) {
            updateCondition.left = Object.assign({}, condition.left);
            delete updateCondition.left.column;
          }
        }
      }
      colList.push(CreateColRow('froms', JSON.stringify(updateFroms), 'VARCHAR'));
      // 検索条件
      const updateWheres = [];
      for (const where of this.wheres) {
        const updateWhere = Object.assign({}, where);
        updateWhere.left = Object.assign({}, where.left);
        delete updateWhere.left.column;
        updateWheres.push(updateWhere);
      }
      colList.push(CreateColRow('wheres', JSON.stringify(updateWheres), 'VARCHAR'));
      // 読み込み方法
      colList.push(CreateColRow('load_method', this.loadMethod, 'NUMBER'));
      // 読み込み方向
      colList.push(CreateColRow('load_direction', this.loadDirection, 'NUMBER'));
      // 出力項目
      colList.push(CreateColRow('output_items', JSON.stringify(this.outputItems), 'VARCHAR'));
      if (this.loadMethod === this.loadMethodClass.CROSS) {
        // クロス左側キー項目
        colList.push(CreateColRow('cross_left_keys', JSON.stringify(this.crossLeftKeyItems), 'VARCHAR'));
        // クロス上側キー項目
        colList.push(CreateColRow('cross_top_keys', JSON.stringify(this.crossTopKeyItems), 'VARCHAR'));
      }
      // 更新ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));
      return CreateUpdateSql(colList, 'm_inquiries_definitions');
    }
  }
}
</script>
<style scoped>
/* #select-table>>>.title-column { */
.table-responsive >>> .title-column {
  color: #495057 !important;
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}
</style>