<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-1">
                        <!-- 年月 -->
                        <b-col cols="6">
                          <validation-provider name="monthYear" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                            <b-form-group label="年月">
                              <b-row>
                                <b-col lg="8" :class="classes">
                                  <b-form-input name="monthYear" type="month" v-model="searchConditions.monthYear" />
                                </b-col>
                                <b-col lg="8" :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mt-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, formatDate, getNullStr, getControlMaster } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_closing_update_completion } from '@/graphql/queries';

const MODULE_NAME = 'closing-update-completion-list';

export default {
  name: 'CLOSING-UPDATE-COMPLETION-LIST',
  // コンポーネント
  components: {
    Header,
    Footer,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '締更新完了一覧',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        // 請求支払区分
        billingPaymentClass: Const.BillingPaymentClass.billing,
        // 年月
        monthYear: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // フィールド
    fields: function() {
      return [
        {
          key: 'MonthYear',
          label: '年月',
          sortable: true,
        },
        {
          key: 'ClosingDate',
          label: '締日',
          sortable: true,
        },
        {
          key: 'ClientIdStart',
          label: '取引先コード開始',
          sortable: true,
        },
        {
          key: 'ClientIdEnd',
          label: '取引先コード終了',
          sortable: true,
        },
      ];
    },
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  // 関数群
  methods: {
    windowPrint: function() {
      this.printStatus = true;
      this.$nextTick(() => {
        windowPrint(document, window, this);
      })
    },
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 請求支払区分
        this.searchConditions.billingPaymentClass = this.$route.query.billingPaymentClass;
        if (this.searchConditions.billingPaymentClass == Const.BillingPaymentClass.billing) {
          // タイトル
          this.title = '請求' + this.title;
        } else {
          // タイトル
          this.title = '支払' + this.title;
        }
        // 年月
        let controlData = await getControlMaster();
        this.searchConditions.monthYear = formatDate(controlData.process_month_year + '01', 'YYYY-MM');
        // 初期検索
        await this.search();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      this.itemList = [];
      let param = {};
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let where_clause = '';
        // 請求支払区分
        where_clause += 'AND billing_payment_class = ' + this.searchConditions.billingPaymentClass + ' ';
        // 年月
        if(getNullStr(this.searchConditions.monthYear) != ''){
          where_clause += 'AND process_month_year = ' + this.searchConditions.monthYear.replace('-', '') + ' ';
        }
        where_clause += 'ORDER BY billing_payment_class,process_month_year,closing_date,client_id_start ';
        where_clause += 'LIMIT 1000';
        let condition = {where_clause: where_clause};
        //console.log(where_clause);
        let result;
        let resultData;
        param = {
          graphqlOperation: 'list_t_closing_update_completion',
          where_clause: where_clause
        };
        result = await API.graphql(graphqlOperation(list_t_closing_update_completion, condition));
        resultData = result.data.list_t_closing_update_completion;
        param = {};
        console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // 取得結果セット
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          MonthYear: ('000000' + result[i].process_month_year).slice(-6),
          ClosingDate: ('00' + result[i].closing_date).slice(-2),
          ClientIdStart: ('000000' + result[i].client_id_start).slice(-6),
          ClientIdEnd: ('000000' + result[i].client_id_end).slice(-6),
        };
        this.itemList.push(searchResult);
      }
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
</style>