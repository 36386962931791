/**
 * 照会レポート機能用テーブル定義ファイル
 */

/**
 * カラムの型
 */
export const ColumnClass = {
  INT: 0,
  VARCHAR: 1,
  DATE: 2,
  DATETIME: 3
};

/**
 * テーブル定義
 */
export const RdsTblDef = [
  {
    text: '9Aコード管理マスタ',
    value: 'm_9a_products_classes',
    columns: [
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類名', value: 'product_class_name', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: '最大', value: 'max', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '最小', value: 'min', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'シーケンス', value: 'sequence', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '売掛実績・残高マスタ',
    value: 't_receivables_balances_results',
    columns: [
      { text: '処理年月', value: 'month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前月末売掛税抜残高', value: 'last_month_receivable_no_tax_balance_result', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前月末売掛消費税残高', value: 'last_month_receivable_tax_balance_result', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次売上額', value: 'monthly_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次現金入金額', value: 'monthly_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次小切手入金額', value: 'monthly_check_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次振込入金額', value: 'monthly_transfer_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次手形入金額', value: 'monthly_bill_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次相殺入金額', value: 'monthly_offset_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次その他入金額', value: 'monthly_other_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次消費税入金額', value: 'monthly_tax_deposit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次一括消費税額', value: 'monthly_bulk_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次伝票消費税額', value: 'monthly_billing_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次消費税額', value: 'monthly_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次粗利額', value: 'monthly_gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '今回請求額', value: 'billing_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '営業所マスタ',
    value: 'm_offices',
    columns: [
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所名（漢字）', value: 'office_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '営業所名（カナ）', value: 'office_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '郵便番号', value: 'zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '住所１', value: 'address_1', type: ColumnClass.VARCHAR, rules: 'required|max:80' },
      { text: '住所２', value: 'address_2', type: ColumnClass.VARCHAR, rules: 'required|max:80' },
      { text: 'TEL', value: 'phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: 'FAX', value: 'fax_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: 'E-MAIL', value: 'e_mail', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '買掛実績・残高マスタ',
    value: 't_payables_balances_results',
    columns: [
      { text: '処理年月', value: 'month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入先コード', value: 'supplier_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前月末買掛税抜残高', value: 'last_month_payable_no_tax_balance_result', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前月末買掛消費税残高', value: 'last_month_payable_tax_balance_result', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次仕入額', value: 'monthly_payable', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次現金支払い額', value: 'monthly_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次小切手支払額', value: 'monthly_check_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次振込支払額', value: 'monthly_transfer_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次手形支払額', value: 'monthly_bill_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次相殺支払額', value: 'monthly_offset_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次その他支払額', value: 'monthly_other_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次消費税支払額', value: 'monthly_tax_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次一括消費税額', value: 'monthly_bulk_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次伝票消費税額', value: 'monthly_billing_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次消費税額', value: 'monthly_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '現場マスタ',
    value: 'm_clients_sites',
    columns: [
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '配送コード', value: 'shipping_code', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '現場名（漢字）', value: 'client_site_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '現場名（カナ）', value: 'client_site_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '現場郵便番号', value: 'client_site_zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '現場住所１', value: 'client_site_address_1', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '現場住所２', value: 'client_site_address_2', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '納品場所', value: 'delivery_location', type: ColumnClass.VARCHAR, rules: 'required|max:54' },
      { text: 'TEL', value: 'client_site_phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: '発注書印字区分', value: 'order_print_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注書用取引先名', value: 'order_print_client_name', type: ColumnClass.VARCHAR, rules: 'required|max:32' },
      { text: 'アストコード', value: 'ast_id', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '相手先現場コード', value: 'client_control_site_id', type: ColumnClass.VARCHAR, rules: 'required|max:10' },
      { text: '所管部支店', value: 'department_branch', type: ColumnClass.VARCHAR, rules: 'required|max:6' },
      { text: '請求書印字区分', value: 'billings_print_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書用取引先名', value: 'billings_print_client_name', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '受注メモ', value: 'order_receive_note', type: ColumnClass.VARCHAR, rules: 'required|max:700' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: 'サービス区分マスタ',
    value: 'm_service_classes',
    columns: [
      { text: 'サービス区分', value: 'service_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: 'サービス区分名', value: 'service_class_name', type: ColumnClass.VARCHAR, rules: 'required|max:10' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '在庫マスタ',
    value: 'm_stocks',
    columns: [
      { text: '年月', value: 'month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '残高数', value: 'balance', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次仕入入庫数', value: 'monthly_purchase_stock', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次調整入庫数', value: 'monthly_tuning_stock', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次売上出庫数', value: 'monthly_salse_ship', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次調整出庫数', value: 'monthly_tuning_ship', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫引当数', value: 'inventory_reserve_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定数', value: 'quantity_stock', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数', value: 'quantity_stock_reserve', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '仕入先営業所コードマスタ',
    value: 'm_suppliers_offices',
    columns: [
      { text: '仕入先コード', value: 'supplier_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '相手先営業所コード', value: 'supplier_control_office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '仕入先現場コードマスタ',
    value: 'm_suppliers_sites',
    columns: [
      { text: '仕入先コード', value: 'supplier_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '相手先現場コード', value: 'supplier_control_site_id', type: ColumnClass.VARCHAR, rules: 'required|max:10' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '支払残高',
    value: 't_payments_balances',
    columns: [
      { text: '支払年月', value: 'payment_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '支払開始日', value: 'payment_start_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '支払締切日', value: 'payment_end_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '前回支払税抜残高', value: 'pre_no_tax_payment_balances', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前回支払消費税残高', value: 'pre_tax_payment_balances', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日買掛仕入', value: 'closing_date_payable', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日一括消費税額', value: 'closing_date_bulk_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日伝票消費税額', value: 'closing_date_bill_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日消費税額', value: 'closing_date_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日支払', value: 'closing_date_payments', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日消費税支払', value: 'closing_date_tax_payments', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日繰越税抜額', value: 'closing_date_forward_no_tax_payments', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日繰越消費税額', value: 'closing_date_forward_tax_payments', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税課税小計', value: 'closing_date_normal_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税額', value: 'closing_date_normal_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税課税小計', value: 'closing_date_light_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税額', value: 'closing_date_light_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '支払マスタ',
    value: 'm_payments',
    columns: [
      { text: '仕入先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日', value: 'closing_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '支払開始日', value: 'payment_start_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '支払締切日', value: 'payment_end_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '出荷データ',
    value: 't_ships',
    columns: [
      { text: '受注番号', value: 'order_receive_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行番号', value: 'order_receive_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '出荷確定日', value: 'shipping_confirm_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '売上計上日', value: 'sales_record_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required' },
      { text: '配送種別区分', value: 'shipping_type_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '配送コード', value: 'shipping_code', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '配送順', value: 'shipping_order_number', type: ColumnClass.INT, rules: 'required' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required' },
      { text: '出荷数', value: 'shipping_quantity', type: ColumnClass.INT, rules: 'required' },
      { text: '受注数', value: 'order_receive_quantity', type: ColumnClass.INT, rules: 'required' },
      { text: '引当数', value: 'reserve_quantity', type: ColumnClass.INT, rules: 'required' },
      { text: '売上伝票発行区分', value: 'sales_issue_class', type: ColumnClass.INT, rules: 'required' },
      { text: '売上伝票発行日', value: 'sales_issue_date', type: ColumnClass.INT, rules: 'required' },
      { text: '売上伝票番号', value: 'sales_number', type: ColumnClass.INT, rules: 'required' },
      { text: '売上伝票行No', value: 'sales_number_row', type: ColumnClass.INT, rules: 'required' },
      { text: '在庫置き場所１', value: 'place_1', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '在庫置き場所２', value: 'place_2', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '在庫置き場所３', value: 'place_3', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '在庫置き場所４', value: 'place_4', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '配送日', value: 'shipping_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '明細単位区分', value: 'details_unit_class', type: ColumnClass.INT, rules: 'required' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '受注セット品データ',
    value: 't_orders_received_set',
    columns: [
      { text: '受注番号', value: 'order_receive_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行番号', value: 'order_receive_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行枝番', value: 'order_receive_row_branch', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名', value: 'product_name', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '備考', value: 'note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: 'サービス区分', value: 'service_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '受注数', value: 'order_receive_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単位', value: 'unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品手入力区分', value: 'product_manual_input_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫管理区分', value: 'inventory_control_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先製品単価区分', value: 'client_amount_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: 'ケース換算区分', value: 'case_conversion_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注残数', value: 'remaining_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '未引当数', value: 'reserve_quantity_incomplete', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '引当済数', value: 'reserve_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数', value: 'stock_scheduled_reserve', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数受注', value: 'stock_scheduled_reserve_receive', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '出荷数', value: 'shipping_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注製品コード', value: 'order_product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '換算入数', value: 'converted_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫置き場所１', value: 'place_1', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所２', value: 'place_2', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所３', value: 'place_3', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所４', value: 'place_4', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: 'セット品区分', value: 'set_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '受注データ',
    value: 't_orders_receives',
    columns: [
      { text: '受注番号', value: 'order_receive_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行番号', value: 'order_receive_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注日', value: 'order_receive_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '受注処理担当者コード', value: 'order_receive_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '配送種別区分', value: 'shipping_type_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名称', value: 'client_name', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先宛名印字区分', value: 'client_print_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先印刷用宛名', value: 'client_print_name', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先部署１', value: 'department_1', type: ColumnClass.VARCHAR, rules: 'required|max:22' },
      { text: '取引先部署２', value: 'department_2', type: ColumnClass.VARCHAR, rules: 'required|max:22' },
      { text: '取引先住所１', value: 'address_1', type: ColumnClass.VARCHAR, rules: 'required|max:36' },
      { text: '取引先住所２', value: 'address_2', type: ColumnClass.VARCHAR, rules: 'required|max:36' },
      { text: '取引先郵便番号', value: 'zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '取引先TEL', value: 'phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: '取引先諸口区分', value: 'sundries_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '納品指定日', value: 'delivery_design_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '納品指定区分', value: 'delivery_design_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '納品指定', value: 'delivery_design', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '配送コード', value: 'shipping_code', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '現場名称', value: 'client_site_name', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '現場郵便番号', value: 'client_site_zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '現場住所１', value: 'client_site_address_1', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '現場住所２', value: 'client_site_address_2', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '納品場所', value: 'delivery_location', type: ColumnClass.VARCHAR, rules: 'required|max:54' },
      { text: '現場TEL', value: 'client_site_phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名', value: 'product_name', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '備考', value: 'note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: 'サービス区分', value: 'service_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '受注数', value: 'order_receive_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単位', value: 'unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '受注単価', value: 'order_receive_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注単価', value: 'order_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注金額', value: 'order_receive_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注金額', value: 'order_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '粗利額', value: 'gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '消費税', value: 'tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品手入力区分', value: 'product_manual_input_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫管理区分', value: 'inventory_control_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先製品単価区分', value: 'client_amount_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: 'ケース換算区分', value: 'case_conversion_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注残数', value: 'remaining_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '未引当数', value: 'reserve_quantity_incomplete', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '引当済数', value: 'reserve_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数', value: 'stock_scheduled_reserve', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数受注', value: 'stock_scheduled_reserve_receive', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '出荷数', value: 'shipping_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上課税区分', value: 'sales_tax_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額計算区分', value: 'tax_calculation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額端数処理区分', value: 'tax_fractionation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注製品コード', value: 'order_product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '換算入数', value: 'converted_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫置き場所１', value: 'place_1', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所２', value: 'place_2', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所３', value: 'place_3', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '在庫置き場所４', value: 'place_4', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: 'セット品区分', value: 'set_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注伝票種別', value: 'order_receive_bill_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '返品伝票発行フラグ', value: 'is_bill_return_print', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '返品在庫計上フラグ', value: 'is_bill_return_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単価登録区分', value: 'unit_price_register_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単価適用日', value: 'unit_price_effective_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '単価備考', value: 'unit_price_note', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '削除済フラグ', value: 'is_deleted', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入力日', value: 'input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '入力担当者コード', value: 'input_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'グループ登録区分', value: 'group_register_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求残高マスタ',
    value: 't_billings_balances',
    columns: [
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求開始日', value: 'billing_start_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求締切日', value: 'billing_end_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '前回請求税抜残高', value: 'pre_billing_no_tax_balance', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前回請求消費税残高', value: 'pre_billing_tax_balance', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日売掛売上', value: 'closing_date_receivable_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日売上伝票枚数', value: 'closing_date_sales_billing_cnt', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日一括消費税額', value: 'closing_date_bulk_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日伝票消費税額', value: 'closing_date_billing_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日消費税額', value: 'closing_date_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日入金額', value: 'closing_date_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日消費税入金', value: 'closing_date_tax_payment', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日繰越税抜額', value: 'closing_date_forward_no_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日繰越消費税額', value: 'closing_date_forward_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日請求税抜額', value: 'closing_date_billing_money_no_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日請求消費税額', value: 'closing_date_billing_money_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '2回前請求税抜額', value: 'two_pre_billing_money_no_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '2回前請求消費税額', value: 'two_pre_billing_money_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '3回前請求税抜額', value: 'three_pre_billing_money_no_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '3回前請求消費税額', value: 'three_pre_billing_money_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入金予定日', value: 'payment_scheduled', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税課税小計', value: 'closing_date_normal_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税額', value: 'closing_date_normal_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税課税小計', value: 'closing_date_light_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税額', value: 'closing_date_light_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日売掛売上_先行除外', value: 'closing_date_receivable_sales_pre_exclude', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税課税小計_先行除外', value: 'closing_date_normal_tax_subtotal_pre_exclude', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日通常消費税額_先行除外', value: 'closing_date_normal_tax_pre_exclude', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税課税小計_先行除外', value: 'closing_date_light_tax_subtotal_pre_exclude', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日軽減消費税額_先行除外', value: 'closing_date_light_tax_pre_exclude', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求書発行登録',
    value: 't_billings_issue_input',
    columns: [
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書発行登録日', value: 'billing_issue_input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '事業者登録番号', value: 'invoice_input_no', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求書発行登録＿伝票番号毎',
    value: 't_billings_issue_input_billing_no',
    columns: [
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '分割請求書番号', value: 'separate_invoice_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '伝票No', value: 'billing_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書発行登録日', value: 'billing_issue_input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '事業者登録番号', value: 'invoice_input_no', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '売上金額', value: 'sales_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税課税小計', value: 'normal_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税額', value: 'normal_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税課税小計', value: 'light_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税額', value: 'light_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上金額_現場毎', value: 'sales_unit_amount_site', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税課税小計_現場毎', value: 'normal_tax_subtotal_site', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税額_現場毎', value: 'normal_tax_site', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税課税小計_現場毎', value: 'light_tax_subtotal_site', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税額_現場毎', value: 'light_tax_site', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求書発行登録＿現場毎',
    value: 't_billings_issue_input_site',
    columns: [
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書発行登録日', value: 'billing_issue_input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '事業者登録番号', value: 'invoice_input_no', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '売上金額', value: 'sales_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税課税小計', value: 'normal_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '通常消費税額', value: 'normal_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税課税小計', value: 'light_tax_subtotal', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '軽減消費税額', value: 'light_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求マスタ',
    value: 'm_billings',
    columns: [
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日', value: 'closing_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求開始日', value: 'billing_start_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求締切日', value: 'billing_end_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日売上伝票枚数', value: 'closing_date_sales_billing_cnt', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '請求マスタ２',
    value: 'm_billings2',
    columns: [
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日', value: 'closing_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求開始日', value: 'billing_start_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求締切日', value: 'billing_end_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '得意先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日売上伝票枚数', value: 'closing_date_sales_billing_cnt', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品カテゴリーマスタ',
    value: 'm_products_categories',
    columns: [
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '大カテゴリーコード', value: 'category_1', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '中カテゴリーコード', value: 'category_2', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '小カテゴリーコード', value: 'category_3', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '細カテゴリーコード', value: 'category_4', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '細細カテゴリーコード', value: 'category_5', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品カテゴリー名称マスタ',
    value: 'm_products_categories_names',
    columns: [
      { text: 'カテゴリー区分', value: 'category_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '大カテゴリーコード', value: 'category_1', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '中カテゴリーコード', value: 'category_2', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '小カテゴリーコード', value: 'category_3', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '細カテゴリーコード', value: 'category_4', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '細細カテゴリーコード', value: 'category_5', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'カテゴリー名称', value: 'category_name', type: ColumnClass.VARCHAR, rules: 'required|max:256' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品構成マスタ',
    value: 'm_products_compositions',
    columns: [
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '部材製品コード', value: 'component_product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '数量', value: 'quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品実績',
    value: 't_products_results',
    columns: [
      { text: '処理年月', value: 'month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所分類コード', value: 'office_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入数量', value: 'purchase_stock_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入返品数量', value: 'purchase_stock_return_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '総仕入数量', value: 'purchase_stock_total_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入額', value: 'purchase_stock_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入返品額', value: 'purchase_stock_return_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '総仕入額', value: 'purchase_stock_total_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上数量', value: 'sales_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上返品数量', value: 'sales_return_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '総売上数量', value: 'sales_total_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上額', value: 'sales_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上返品額', value: 'sales_return_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '総売上額', value: 'sales_total_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '粗利額', value: 'gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品詳細マスタ',
    value: 'm_products_details',
    columns: [
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入単価', value: 'purchase_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '原価単価', value: 'cost_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '置き場所１', value: 'place_1', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '置き場所２', value: 'place_2', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '置き場所３', value: 'place_3', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '置き場所４', value: 'place_4', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: 'ピッキング表印刷区分', value: 'picking_print_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '適正在庫数', value: 'appropriate_stock', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'ケース換算区分', value: 'case_conversion_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入先製品コード', value: 'supplier_product_id', type: ColumnClass.VARCHAR, rules: 'required|max:25' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品単位変換マスタ',
    value: 'm_products_units_conversions',
    columns: [
      { text: 'ケース製品コード', value: 'case_product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'バラ製品コード', value: 'loose_product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入数', value: 'quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品単価一括更新履歴',
    value: 't_products_prices_bulk_histories',
    columns: [
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品単価修正日時', value: 'price_update_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '製品名（漢字）', value: 'product_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品名（漢字）（更新前）', value: 'product_name_kanji_old', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品名（カナ）', value: 'product_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '製品名（カナ）（更新前）', value: 'product_name_kana_old', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '定価', value: 'regular_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '定価（更新前）', value: 'regular_price_old', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上単価', value: 'sales_unit_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上単価（更新前）', value: 'sales_unit_price_old', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入単価', value: 'purchase_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入単価（更新前）', value: 'purchase_price_old', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '原価単価', value: 'cost_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '原価単価（更新前）', value: 'cost_price_old', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入先製品コード', value: 'supplier_product_id', type: ColumnClass.VARCHAR, rules: 'required|max:25' },
      { text: '仕入先製品コード（更新前）', value: 'supplier_product_id_old', type: ColumnClass.VARCHAR, rules: 'required|max:25' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品単価グループマスタ',
    value: 'm_products_prices_groups',
    columns: [
      { text: '代表製品コード', value: 'product_group_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品発注マスタ',
    value: 'm_products_orders',
    columns: [
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '最低発注数', value: 'minimum_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注時サービス数', value: 'service_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注ロット数', value: 'lots_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入庫リードタイム', value: 'lead_time', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'サービス有無区分', value: 'service_whether_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '製品マスタ',
    value: 'm_products',
    columns: [
      { text: 'ID', value: 'id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '9Aフラグ', value: 'is_9A', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名（漢字）', value: 'product_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品名（カナ）', value: 'product_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '単位', value: 'unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '定価', value: 'regular_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上単価', value: 'sales_unit_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品チェック区分', value: 'product_check_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '諸口区分', value: 'sundries_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫管理区分', value: 'inventory_control_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上消費税率区分', value: 'product_tax_rate_class_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入消費税率区分', value: 'product_tax_rate_class_purchase', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '棚卸一括更新履歴',
    value: 't_inventories_bulk_update_histories',
    columns: [
      { text: '棚卸No.', value: 'inventory_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '実棚数（更新時）', value: 'shelves_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫引当数（更新時）', value: 'inventory_reserve_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '残高数（更新後）', value: 'balance_new', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '残高数（更新前）', value: 'balance_old', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '登録日', value: 'input_date', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '棚卸商品',
    value: 't_inventories',
    columns: [
      { text: '棚卸No.', value: 'inventory_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '並び順', value: 'sort_order', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名', value: 'product_name', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '総棚卸数', value: 'inventory_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '総実棚数', value: 'shelves_count', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '棚卸入力者', value: 'inventory_entry_user_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '棚卸入力日', value: 'input_inventory_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '行追加フラグ', value: 'add_flg', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '済フラグ', value: 'finished_flg', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '棚卸履歴',
    value: 't_inventories_histories',
    columns: [
      { text: '棚卸No.', value: 'inventory_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '置き場所', value: 'place', type: ColumnClass.VARCHAR, rules: 'required|max:11' },
      { text: '前処理実施日時', value: 'preprocess_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '棚卸表出力日時', value: 'print_inventory_sheet_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '棚卸入力日時', value: 'input_inventory_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '棚卸差異表出力日時', value: 'print_stock_variance_datetime', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '担当者実績',
    value: 't_staffs_results',
    columns: [
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上額', value: 'sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上返品額', value: 'returned', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上値引額', value: 'discount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上額', value: 'net_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '粗利額', value: 'gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '翌月売上額', value: 'next_month_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '翌月売上返品額', value: 'next_month_returned', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '翌月値引額', value: 'next_month_discount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '翌月純売上額', value: 'next_month_net_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '翌月粗利額', value: 'next_month_gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '当期売上額', value: 'current_term_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '当期売上返品額', value: 'current_term_returned', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '当期値引額', value: 'current_term_discount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '当期純売上額', value: 'current_term_net_sales', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '当期粗利額', value: 'current_term_gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高１', value: 'net_sales_1', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高２', value: 'net_sales_2', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高３', value: 'net_sales_3', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高４', value: 'net_sales_4', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高５', value: 'net_sales_5', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高６', value: 'net_sales_6', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高７', value: 'net_sales_7', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高８', value: 'net_sales_8', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高９', value: 'net_sales_9', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高１０', value: 'net_sales_10', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高１１', value: 'net_sales_11', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '純売上高１２', value: 'net_sales_12', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '担当者マスタ',
    value: 'm_staffs',
    columns: [
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '担当者名（漢字）', value: 'staff_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '担当者名（カナ）', value: 'staff_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '担当者名（印鑑）', value: 'staff_name_stamp', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: 'ログインID', value: 'login_id', type: ColumnClass.VARCHAR, rules: 'required|max:128' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '削除済フラグ', value: 'is_deleted', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '電子書類データ',
    value: 't_electronic_documents',
    columns: [
      { text: 'ID', value: 'id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '電子書類区分', value: 'electronic_documents_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先名（カナ）', value: 'client_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '取引日', value: 'transaction_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '取引金額', value: 'money', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '内容', value: 'contents', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: 'ファイル拡張子', value: 'file_extension', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '登録日', value: 'input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '登録担当者コード', value: 'input_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '電子書類データ＿削除履歴',
    value: 't_electronic_documents_del_histories',
    columns: [
      { text: 'ID', value: 'id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '電子書類区分', value: 'electronic_documents_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先名（カナ）', value: 'client_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '取引日', value: 'transaction_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '取引金額', value: 'money', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '内容', value: 'contents', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: 'ファイル拡張子', value: 'file_extension', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '登録日', value: 'input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '登録担当者コード', value: 'input_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '電子書類データ＿請求書データ',
    value: 't_electronic_documents_seikyu',
    columns: [
      { text: 'ID', value: 'id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締切日付', value: 'closing_ymd', type: ColumnClass.DATE, rules: 'required' },
      { text: '通常分割区分', value: 'normal_separate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先名（カナ）', value: 'client_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場名（漢字）', value: 'client_site_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '請求書発行登録日', value: 'billing_issue_input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求書分割区分', value: 'billing_separate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '分割請求書番号', value: 'separate_invoice_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求金額', value: 'money_billing_total', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'ファイル拡張子', value: 'file_extension', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '登録日', value: 'input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '登録担当者コード', value: 'input_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求重複区分', value: 'billing_duplicate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '電子書類データ＿請求書データ＿削除履歴',
    value: 't_electronic_documents_seikyu_del_histories',
    columns: [
      { text: 'ID', value: 'id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求年月', value: 'billing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締切日付', value: 'closing_ymd', type: ColumnClass.DATE, rules: 'required' },
      { text: '通常分割区分', value: 'normal_separate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先名（カナ）', value: 'client_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場名（漢字）', value: 'client_site_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '請求書発行登録日', value: 'billing_issue_input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求書分割区分', value: 'billing_separate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '分割請求書番号', value: 'separate_invoice_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求金額', value: 'money_billing_total', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'ファイル拡張子', value: 'file_extension', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '登録日', value: 'input_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '登録担当者コード', value: 'input_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求重複区分', value: 'billing_duplicate_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '電子書類マスタ',
    value: 'm_electronic_documents',
    columns: [
      { text: '電子書類区分', value: 'electronic_documents_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '電子書類名', value: 'electronic_documents_name', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '表示順', value: 'sort', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '削除フラグ', value: 'is_deleted', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '得意先請求残',
    value: 't_billings_remainings',
    columns: [
      { text: '得意先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求日', value: 'billing_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '請求金額', value: 'billing_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求消費税', value: 'billing_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '得意先別売上額ファイル',
    value: 'w_clients_sales_file',
    columns: [
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '制御の切れ目レベル', value: 'control_break_level', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'オーバーフロー・フラグ', value: 'is_overflow', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'RTDTDN　／100', value: 'one_hundredth_rtdtdn', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'RTKNUR　　　合計', value: 'rtknur_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'RTKNAR　　　合計', value: 'rtknar_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '取引先親子マスタ',
    value: 'm_clients_parent_child',
    columns: [
      { text: '取引先区分（親）', value: 'client_class_parent', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード（親）', value: 'client_id_parent', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先区分（子）', value: 'client_class_branch', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード（子）', value: 'client_id_branch', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '取引先製品マスタ',
    value: 'm_clients_products',
    columns: [
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単価適用日', value: 'unit_price_effective_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '売上単価', value: 'sales_unit_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '理由', value: 'reason', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '取引先マスタ',
    value: 'm_clients',
    columns: [
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先名（カナ）', value: 'client_name_kana', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '部署１', value: 'department_1', type: ColumnClass.VARCHAR, rules: 'required|max:22' },
      { text: '部署２', value: 'department_2', type: ColumnClass.VARCHAR, rules: 'required|max:22' },
      { text: '郵便番号', value: 'zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '住所１', value: 'address_1', type: ColumnClass.VARCHAR, rules: 'required|max:36' },
      { text: '住所２', value: 'address_2', type: ColumnClass.VARCHAR, rules: 'required|max36' },
      { text: 'TEL', value: 'phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: 'FAX', value: 'fax_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '最新現場コード', value: 'latest_site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'チェック内容', value: 'check_contents', type: ColumnClass.VARCHAR, rules: 'required|max:22' },
      { text: '諸口区分', value: 'sundries_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日', value: 'closing_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書区分', value: 'billing_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '前回請求締切日', value: 'previous_billing_closing_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '取引区分', value: 'business_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '相殺相手先コード', value: 'offset_client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '請求書出力単位区分', value: 'billing_output_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入金（支払）区分', value: 'payment_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入金（支払）予定日', value: 'payment_scheduled', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '売上課税区分', value: 'sales_tax_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額計算区分', value: 'tax_calculation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額端数処理区分', value: 'tax_fractionation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '伝票種類区分', value: 'billing_type_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '組合コード', value: 'union_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'サービス発注区分', value: 'service_order_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注条件：数量', value: 'order_condition_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注条件：金額', value: 'order_condition_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '値引率', value: 'discount_rate', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注書回数区分', value: 'order_count_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '振り込み手数料', value: 'transfer_fee', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '振り込み手数料2', value: 'transfer_fee_2', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '振り込み手数料3', value: 'transfer_fee_3', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注メモ', value: 'order_receive_note', type: ColumnClass.VARCHAR, rules: 'required|max:700' },
      { text: 'WEB発行区分', value: 'web_issue_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '入荷データ',
    value: 't_stocks',
    columns: [
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷日', value: 'stock_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷数', value: 'stock_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定日', value: 'stock_scheduled', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定日確定区分', value: 'stock_scheduled_confirm_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定処理担当者コード', value: 'stock_scheduled_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '注文番号', value: 'order_number', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷確定区分', value: 'stock_confirm_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷確定日', value: 'stock_confirm_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '納品指定区分マスタ',
    value: 'm_deliverys_designs_classes',
    columns: [
      { text: '納品指定区分', value: 'delivery_design_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '納品指定区分名', value: 'delivery_design_name', type: ColumnClass.VARCHAR, rules: 'required|max:15' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '発注データ',
    value: 't_orders',
    columns: [
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注日', value: 'order_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '発注種別', value: 'order_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名', value: 'product_name', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '備考', value: 'note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: 'サービス区分', value: 'service_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '発注数', value: 'order_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単位', value: 'unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '発注単価', value: 'order_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注金額', value: 'order_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '消費税', value: 'tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注残数', value: 'remaining_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷数', value: 'stock_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定日', value: 'stock_scheduled', type: ColumnClass.DATE, rules: 'required' },
      { text: '入荷予定日確定区分', value: 'stock_scheduled_confirm_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定処理担当者コード', value: 'stock_scheduled_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '入荷予定引当数', value: 'stock_scheduled_reserve', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '組合コード', value: 'union_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'セット品区分', value: 'set_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品手入力区分', value: 'product_manual_input_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫管理区分', value: 'inventory_control_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先製品単価区分', value: 'client_amount_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: 'ケース換算区分', value: 'case_conversion_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入課税区分', value: 'client_tax_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額計算区分', value: 'tax_calculation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額端数処理区分', value: 'tax_fractionation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注書発行区分', value: 'order_issue_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注書発行日', value: 'order_issue_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '注文番号', value: 'order_number', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注番号', value: 'order_receive_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行番号', value: 'order_receive_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '削除済フラグ', value: 'is_deleted', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '見積データ',
    value: 't_estimate',
    columns: [
      { text: '見積番号', value: 'estimate_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '見積行番号', value: 'estimate_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所名（漢字）', value: 'office_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先印刷用宛名', value: 'client_print_name', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '見積日', value: 'estimate_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '取引先担当者コード', value: 'client_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先担当者名（漢字）', value: 'client_staff_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '取引先担当者名（印鑑）', value: 'client_staff_name_stamp', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '作成担当者コード', value: 'created_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成担当者名（漢字）', value: 'created_staff_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '作成担当者名（印鑑）', value: 'created_staff_name_stamp', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '見積種類', value: 'estimate_kind', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '消費税区分', value: 'tax_type', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単価登録区分', value: 'unit_price_register_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '単価適用日', value: 'unit_price_effective_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '単価備考', value: 'unit_price_note', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '内容', value: 'contents', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '納期', value: 'delivery_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '見積金額', value: 'estimate_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '消費税', value: 'tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '現場名（漢字）', value: 'client_site_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '支払方法', value: 'payment_way', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: '見積期限', value: 'estimate_period', type: ColumnClass.DATE, rules: 'required' },
      { text: '見積期限（文章）', value: 'estimate_period_sentence', type: ColumnClass.VARCHAR, rules: 'required|max:15' },
      { text: '摘要', value: 'summary', type: ColumnClass.VARCHAR, rules: 'required|max:160' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名（漢字）', value: 'product_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品備考', value: 'product_note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: '製品数量', value: 'product_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品単位', value: 'product_unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '製品売上単価', value: 'product_sales_unit_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品仕入単価', value: 'product_purchase_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品金額', value: 'product_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: 'グループ登録区分', value: 'group_register_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '見積発注データ',
    value: 't_estimate_orders',
    columns: [
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所名（漢字）', value: 'office_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先名（漢字）', value: 'client_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '取引先印刷用宛名', value: 'client_print_name', type: ColumnClass.VARCHAR, rules: 'required|max:34' },
      { text: '発注日', value: 'order_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '取引先担当者コード', value: 'client_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先担当者名（漢字）', value: 'client_staff_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '取引先担当者名（印鑑）', value: 'client_staff_name_stamp', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '作成担当者コード', value: 'created_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成担当者名（漢字）', value: 'created_staff_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:20' },
      { text: '作成担当者名（印鑑）', value: 'created_staff_name_stamp', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '内容', value: 'contents', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '摘要', value: 'summary', type: ColumnClass.VARCHAR, rules: 'required|max:160' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名（漢字）', value: 'product_name_kanji', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品備考', value: 'product_note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: '製品数量', value: 'product_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品単位', value: 'product_unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '作成年月日', value: 'created_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: '累積トランザクション',
    value: 't_cumulative_transaction',
    columns: [
      { text: 'トランザクションID', value: 'transaction_id', type: ColumnClass.VARCHAR, rules: 'required|max:2' },
      { text: '伝票No', value: 'billing_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '行No', value: 'billing_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '伝票日付', value: 'billing_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '伝票種別', value: 'bill_class', type: ColumnClass.VARCHAR, rules: 'required|numeric' },
      { text: '解体組立区分', value: 'dismantling_assembly_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '営業所コード', value: 'office_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '移動先コード', value: 'destination_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先区分', value: 'client_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先コード', value: 'client_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '納品指定日', value: 'delivery_design_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '納品指定区分', value: 'delivery_design_class', type: ColumnClass.VARCHAR, rules: 'required' },
      { text: '納品指定', value: 'delivery_design', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '現場コード', value: 'site_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '配送コード', value: 'shipping_code', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '現場名称', value: 'client_site_name', type: ColumnClass.VARCHAR, rules: 'required|max:50' },
      { text: '現場郵便番号', value: 'client_site_zip_code', type: ColumnClass.VARCHAR, rules: 'required|max:8' },
      { text: '現場住所１', value: 'client_site_address_1', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '現場住所２', value: 'client_site_address_2', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '納品場所', value: 'delivery_location', type: ColumnClass.VARCHAR, rules: 'required|max:60' },
      { text: '現場TEL', value: 'client_site_phone_number', type: ColumnClass.VARCHAR, rules: 'required|max:21' },
      { text: '伝票摘要', value: 'billing_summary', type: ColumnClass.VARCHAR, rules: 'required|max:40' },
      { text: '処理日付', value: 'process_date', type: ColumnClass.DATE, rules: 'required' },
      { text: '受注処理担当者コード', value: 'order_receive_staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '担当者コード', value: 'staff_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先分類コード', value: 'client_id_first_digit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '相殺伝票No', value: 'offset_billing_no', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品コード', value: 'product_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品名', value: 'product_name', type: ColumnClass.VARCHAR, rules: 'required|max:35' },
      { text: '製品備考', value: 'product_note', type: ColumnClass.VARCHAR, rules: 'required|max:30' },
      { text: 'サービス区分', value: 'service_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '製品数量', value: 'product_quantity', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品単位', value: 'product_unit', type: ColumnClass.VARCHAR, rules: 'required|max:5' },
      { text: '製品売上単価', value: 'product_sales_unit_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品仕入単価', value: 'product_purchase_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品売上金額', value: 'product_sales_unit_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品仕入金額', value: 'product_purchase_amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品粗利額', value: 'product_gross_profit', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品消費税', value: 'product_tax', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '金種', value: 'kind', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '摘要', value: 'summary', type: ColumnClass.VARCHAR, rules: 'required|max:25' },
      { text: '勘定一補助', value: 'account_support', type: ColumnClass.VARCHAR, rules: 'required|max:7' },
      { text: '決済予定日', value: 'settlement_scheduled', type: ColumnClass.DATE, rules: 'required' },
      { text: '金額', value: 'amount', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品分類コード', value: 'product_class_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '製品諸口区分', value: 'product_mouth_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '在庫管理区分', value: 'inventory_control_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '取引先製品単価区分', value: 'client_amount_class', type: ColumnClass.VARCHAR, rules: 'required|max:1' },
      { text: '売上課税区分', value: 'sales_tax_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額計算区分', value: 'tax_calculation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '税額端数処理区分', value: 'tax_fractionation_class', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次更新フラグ', value: 'is_update_monthly', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '月次年月', value: 'monthly_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締日更新フラグ', value: 'is_update_closing_date', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '締次年月', value: 'closing_month_year', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注番号', value: 'order_receive_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '受注行番号', value: 'order_receive_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注番号', value: 'order_id', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '発注行番号', value: 'order_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '注文番号', value: 'order_number', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '注文行番号', value: 'order_number_row', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '組合CD発注取引先', value: 'union_cd_order_client', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '仕入単価チェックフラグ', value: 'is_check_purchase_price', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
  {
    text: 'ルートマスタ',
    value: 'm_routes',
    columns: [
      { text: '配送コード', value: 'shipping_code', type: ColumnClass.INT, rules: 'required|numeric' },
      { text: '配送名', value: 'shipping_name', type: ColumnClass.VARCHAR, rules: 'required|max:12' },
      { text: '作成日', value: 'created', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '作成ユーザー', value: 'created_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
      { text: '更新日', value: 'updated', type: ColumnClass.DATETIME, rules: 'required' },
      { text: '更新ユーザー', value: 'updated_user', type: ColumnClass.VARCHAR, rules: 'required|max:191' },
    ]
  },
];
