<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="danger" class="mt-2" style="white-space:pre-wrap; word-wrap:break-word;" v-if="alertDanger.length">
        <ol v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ol>
      </b-alert>
    </div>
    <div v-for="set in listSet" v-bind:key="set.clientId">
      <div v-for="chouhyou in set.listChouhyou" v-bind:key="set.clientId + '_' + chouhyou.page">
        <TemplateOrderNormal :id="constData.chouhyouId + set.clientId + '_' + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateOrderNormal from '@/assets/svg/order_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, formatDate, executeSelectSql, getFormCounter, addOperationLogs, isSystemEditable, getNullStr, escapeQuote, CreateColRow, CreateUpdateSql } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import { list_m_staffs, list_m_offices, list_m_control } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'purchase-order-normal';

export default {
  name: 'PURCHASE-ORDER-NORMAL',
  /* コンポーネント */
  components: {
    TemplateOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 20,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '発注書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      stampFlg: false,
      colStampCX: 0,
      colStampCY: 0,
      addProductCnt: 0,
      listSet: [],
      // 営業所情報
      officeList: [],
      // コントロールマスタの情報
      controlMasterData: null,
      // アラート
      alertDanger: [],

      // URLパラメータ
      urlParams: null,
      // 再発行フラグ
      isReissue: false,
      // ログイン中ユーザー情報
      loginStaffInfo: null,
      loginStaffNameKanji: '',
      loginStaffNameStamp: '',
      // エラーリスト対象
      errorList: [],
      // 現在日付
      currentDate: new Date(),
      // 営業所コード
      salesOffice: '',
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertDanger.length > 0;
    },
  },
  /* マウント */
  mounted() {
    this.urlParams = this.$route.query;
    this.isReissue = this.urlParams.orderSlipClass == 2;
    this.loginStaffInfo = this.$store.getters.user;
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名 $発注書（入力分）_yyyyMMddHHmmss
    let MM = ('0' + (this.currentDate.getMonth() + 1)).slice(-2);
    let DD = ('0' + this.currentDate.getDate()).slice(-2);
    let hh = ('0' + this.currentDate.getHours()).slice(-2);
    let mm = ('0' + this.currentDate.getMinutes()).slice(-2);
    let ss = ('0' + this.currentDate.getSeconds()).slice(-2);
    document.title = `発注書_${this.currentDate.getFullYear()}${MM}${DD}${hh}${mm}${ss}`;
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // コントロールマスタ
        await this.getControlMasterData();
        // ログイン情報取得
        await this.getLoginStaffInfo();
        // 発注が必要な製品一覧を取得
        const resultDatas = await this.executeSqls(await this.makeSelectSqlNeedsOrder(), functionName);
        //console.log(resultDatas);
        // 出力用にデータを集約
        const outputOrderDatas = await this.checkOutputsDatas(resultDatas[0]);
        //console.log(outputOrderDatas);

        // エラーリストの対象を表示
        if (this.errorList.length > 0) {
          this.alertDanger.push('以下の発注先は、発注条件を満たさなかったため、出力されません。');
          let messageId = 0;
          let message = '数量・金額ともに不足';
          for (const errorClient of this.errorList) {
            messageId = 0;
            if ((errorClient.totalQuantity - errorClient.order_condition_quantity) < 0) {
              messageId += 1;
            }
            if ((errorClient.totalAmount - errorClient.order_condition_amount) < 0) {
              messageId += 2;
            }
            switch (messageId) {
            case 1:
              message = '数量不足';
              break;
            case 2:
              message = '金額不足';
              break;
            case 3:
              message = '数量・金額ともに不足';
              break;
            }
            this.alertDanger.push(`発注先「${errorClient.client_id}：${errorClient.client_name}」\nエラー理由：${message}\n発注条件(数量/金額)：${errorClient.order_condition_quantity} / ${errorClient.order_condition_amount}\n今回発注(数量/金額)：${errorClient.totalQuantity} / ${errorClient.totalAmount}`);
          }
        }

        // 出力用データの存在チェック
        if (!(outputOrderDatas != null && outputOrderDatas.length > 0)) {
          this.alertDanger.push('出力対象のデータがありません。');
          scrollTo(0,0);
          this.$store.commit('setLoading', false);
          return;
        }
        /* 前処理 */
        // 営業所情報取得
        await this.getOfficesData();

        /* 帳票作成 */
        // 発注毎の一覧を作成
        await this.initListSet(outputOrderDatas);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(outputOrderDatas);

        /* 後処理 */
        const sqls = await this.makeUpdateSql(outputOrderDatas);
        // 後処理失敗用に3回までリトライ
        let retryCnt = 0;
        const retryMax = 3;
        do {
          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          let msg = null;
          try {
            msg = await isSystemEditable();
          } catch (error) {
            if (retryCnt < retryMax) {
              retryCnt++;                            
              continue;
            } else {
              await addOperationLogs('Error', MODULE_NAME, functionName, DISP_MESSAGES.DANGER['3005'], error);
              throw 'Failing retry';
            }
          }

          if (msg === null) {
            try {
              await this.executeSqls(sqls, functionName);
              break;
            } catch (error) {
              console.log(error);
              if (retryCnt < retryMax) {
                console.log('retry');
              } else {
                throw 'Failing retry';
              }
            }
          } else {
            if (retryCnt >= retryMax) {
              this.alertDanger.push(msg);
              this.listSet = [];
              break;
            }
          }
          retryCnt++;
        } while (retryCnt <= retryMax);
        // 作成した置換文字データをSVGファイルに設定
        await this.setChouhyou();
      } catch(error) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listSet = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索SELECT文字列作成 */
    async makeSelectSqlNeedsOrder(){
      //console.log('makeSelectSql ' + JSON.stringify(this.$route.query));
      const query = this.$route.query;
      const whereClausesQuery = [];
      const whereClauses = [];
      const sqlList = [];

      // 営業所
      if (query.salesOffice && query.salesOffice !== '') {
        whereClausesQuery.push(`T_RECEIVES.office_id = ${query.salesOffice}`);
        this.salesOffice = query.salesOffice;
      }
      // 発注書回数
      if (query.orderSlipOutoutNum && query.orderSlipOutoutNum !== '') {
        switch (query.orderSlipOutoutNum) {
        case '1':
          whereClauses.push('M_CLIENTS_SUPPLIER.order_count_class IN (0, 1)');
          break;
        case '2':
          whereClauses.push('M_CLIENTS_SUPPLIER.order_count_class IN (0, 2)');
          break;
        }
      }
      // 発注先コード（開始）
      if (query.supplierCodeStart && query.supplierCodeStart !== '') {
        whereClauses.push(`M_CLIENTS_SUPPLIER.client_id >= ${query.supplierCodeStart}`);
      }
      // 発注先コード（終了）
      if (query.supplierCodeEnd && query.supplierCodeEnd !== '') {
        whereClauses.push(`M_CLIENTS_SUPPLIER.client_id <= ${query.supplierCodeEnd}`);
      }

      // SQL共通WHERE句
      let commonWhereOptions = ' WHERE';
      commonWhereOptions += ' T_RECEIVES.reserve_quantity_incomplete > 0'; // 未引当数あり
      commonWhereOptions += ' AND T_RECEIVES.is_deleted = 0'; // 削除フラグ「0:未削除」
      commonWhereOptions += ' AND T_RECEIVES.shipping_type_class <> 4'; // 配送種別区分「4:直送」以外
      commonWhereOptions += ' AND T_RECEIVES.order_receive_bill_class = 0'; // 受注伝票種別「0:通常」
      commonWhereOptions += ' AND T_RECEIVES.inventory_control_class = 0'; // 在庫管理区分「0:通常」

      let selectSql = 'SELECT';
      selectSql += ' M_CLIENTS_SUPPLIER.client_id AS client_id'; // 取引先ID
      selectSql += ', M_CLIENTS_SUPPLIER.client_name_kanji AS client_name'; // 取引先名
      selectSql += ', M_PRODUCTS.product_id AS product_id'; // 製品ID
      selectSql += ', M_PRODUCTS.product_name_kanji AS product_name'; // 製品名
      selectSql += ', MAX(TOTAL_JUCHU.order_quantity_1) + MAX(TOTAL_JUCHU.order_quantity_2) + MAX(TOTAL_JUCHU.order_quantity_3) + M_PRODUCTS_DETAILS.appropriate_stock - (M_STOCKS.balance + IFNULL(T_ORDERS.remaining_quantity, 0)) AS order_quantity'; // 数量
      selectSql += ', M_PRODUCTS.unit AS unit'; // 単位
      selectSql += ', TOTAL_JUCHU.note AS note'; // 備考
      selectSql += ', TOTAL_JUCHU.office_id AS office_id'; // 営業所コード
      selectSql += ', M_PRODUCTS_DETAILS.supplier_product_id AS supplier_product_id'; // 仕入先顧客コード
      selectSql += ', M_CLIENTS_SUPPLIER.order_condition_quantity AS order_condition_quantity'; // 発注条件 数量
      selectSql += ', M_CLIENTS_SUPPLIER.order_condition_amount AS order_condition_amount'; // 発注条件 金額
      selectSql += ', M_PRODUCTS_ORDERS.minimum_quantity AS minimum_quantity'; // 最低発注数
      selectSql += ', M_PRODUCTS_ORDERS.service_quantity AS service_quantity'; // 発注時ｻｰﾋﾞｽ数
      selectSql += ', M_PRODUCTS_ORDERS.lots_quantity AS lots_quantity'; // 発注ロット数
      selectSql += ', M_PRODUCTS_ORDERS.lead_time AS lead_time'; // 入庫ﾘｰﾄﾞﾀｲﾑ
      selectSql += ', M_PRODUCTS_DETAILS.purchase_price AS purchase_price'; // 仕入金額
      selectSql += ', M_CLIENTS_SUPPLIER.union_id AS union_id'; // 組合コード
      selectSql += ', M_PRODUCTS.product_class_id AS product_class_id'; // 製品分類コード
      selectSql += ', TOTAL_JUCHU.product_manual_input_class AS product_manual_input_class'; // 製品手入力区分
      selectSql += ', M_PRODUCTS.inventory_control_class AS inventory_control_class'; // 在庫管理区分
      selectSql += ', M_CLIENTS_SUPPLIER.sales_tax_class AS client_tax_class'; // 仕入課税区分
      selectSql += ', M_CLIENTS_SUPPLIER.tax_calculation_class AS tax_calculation_class'; // 税額計算区分
      selectSql += ', M_CLIENTS_SUPPLIER.tax_fractionation_class AS tax_fractionation_class'; // 税額端数処理区分
      selectSql += ', M_PRODUCTS_ORDERS.service_whether_class AS service_whether_class'; // サービス有無区分
      selectSql += ', M_CLIENTS_SUPPLIER.service_order_class'; // サービス発注区分
      selectSql += ' FROM (';
      /* サブクエリ1 製品パターンに応じてデータ取得：ケース換算なし */
      selectSql += 'SELECT';
      selectSql += ' T_RECEIVES.product_id';
      selectSql += ', SUM(T_RECEIVES.reserve_quantity_incomplete) AS order_quantity_1'; // サブクエリ１の通常製品の入荷予定数を合計
      selectSql += ', 0 AS order_quantity_2'; // サブクエリ２の入荷予定数
      selectSql += ', 0 AS order_quantity_3'; // サブクエリ３の入荷予定数
      selectSql += ', T_RECEIVES.note';
      selectSql += ', T_RECEIVES.office_id';
      selectSql += ', T_RECEIVES.product_manual_input_class'; // 製品手入力区分
      selectSql += ' FROM';
      selectSql += ' t_orders_receives AS T_RECEIVES';
      /* サブクエリ1 WHERE句 */
      selectSql += commonWhereOptions;
      selectSql += ` AND ${whereClausesQuery.join(' AND ')}`;
      selectSql += ' AND T_RECEIVES.case_conversion_class = 1'; // ケース換算「1:しない」
      /* サブクエリ1 GROUP BY句 */
      selectSql += ' GROUP BY';
      selectSql += ' T_RECEIVES.product_id';
      selectSql += ' UNION ALL';
      /* サブクエリ2 製品パターンに応じてデータ取得：バラ製品⇒ケース製品に変換 */
      selectSql += ' SELECT';
      selectSql += ' CASE_QUERY.product_id';
      selectSql += ', 0 AS order_quantity_1'; // サブクエリ１の入荷予定数
      selectSql += ', SUM(CASE_QUERY.order_quantity_2) AS order_quantity_2';
      selectSql += ', 0 AS order_quantity_3'; // サブクエリ３の入荷予定数
      selectSql += ', CASE_QUERY.note';
      selectSql += ', CASE_QUERY.office_id';
      selectSql += ', CASE_QUERY.product_manual_input_class';
      selectSql += ' FROM';
      selectSql += ' (SELECT';
      selectSql += ' M_PRODUCTS_CONVERT.case_product_id AS product_id';
      selectSql += ', CEILING(SUM(T_RECEIVES.reserve_quantity_incomplete) / M_PRODUCTS_CONVERT.quantity) AS order_quantity_2'; // バラ製品の入荷予定数の合計をケース製品換算
      selectSql += ', T_RECEIVES.note';
      selectSql += ', T_RECEIVES.office_id';
      selectSql += ', T_RECEIVES.product_manual_input_class'; // 製品手入力区分
      selectSql += ' FROM';
      selectSql += ' t_orders_receives AS T_RECEIVES';
      selectSql += ' INNER JOIN'; // 製品単位変換マスタ 主キー（loose_product_id）で結合
      selectSql += ' m_products_units_conversions AS M_PRODUCTS_CONVERT';
      selectSql += ' ON  T_RECEIVES.product_id = M_PRODUCTS_CONVERT.loose_product_id';
      /* サブクエリ2 WHERE句 */
      selectSql += commonWhereOptions;
      selectSql += ` AND ${whereClausesQuery.join(' AND ')}`;
      selectSql += ' AND T_RECEIVES.case_conversion_class = 0'; // ケース換算「0:する」
      selectSql += ' AND T_RECEIVES.set_class = 0'; // セット品区分「0:セット品でない」
      /* サブクエリ2 GROUP BY句 */
      selectSql += ' GROUP BY';
      selectSql += ' M_PRODUCTS_CONVERT.case_product_id';
      selectSql += ' , M_PRODUCTS_CONVERT.loose_product_id';
      selectSql += ' ) AS CASE_QUERY';
      selectSql += ' GROUP BY CASE_QUERY.product_id';
      selectSql += ' UNION ALL';
      /* サブクエリ3 製品パターンに応じてデータ取得：セット製品⇒部材製品に変換 */
      selectSql += ' SELECT';
      selectSql += ' T_RECEIVES_SET.product_id';
      selectSql += ', 0 AS order_quantity_1'; // サブクエリ１の入荷予定数
      selectSql += ', 0 AS order_quantity_2'; // サブクエリ２の入荷予定数
      selectSql += ', SUM(T_RECEIVES_SET.reserve_quantity_incomplete) AS order_quantity'; // 部材製品の入荷予定数を合計
      selectSql += ', T_RECEIVES_SET.note';
      selectSql += ', T_RECEIVES.office_id';
      selectSql += ', T_RECEIVES.product_manual_input_class'; // 製品手入力区分
      selectSql += ' FROM';
      selectSql += ' t_orders_receives AS T_RECEIVES';
      selectSql += ' INNER JOIN'; // 受注セット品データ（部材製品の一覧） 主キー（order_receive_id,order_receive_row）で結合
      selectSql += ' t_orders_received_set AS T_RECEIVES_SET';
      selectSql += ' ON  T_RECEIVES_SET.order_receive_id = T_RECEIVES.order_receive_id';
      selectSql += ' AND T_RECEIVES_SET.order_receive_row = T_RECEIVES.order_receive_row';
      /* サブクエリ3 WHERE句 */
      selectSql += commonWhereOptions;
      selectSql += ` AND ${whereClausesQuery.join(' AND ')}`;
      selectSql += ' AND T_RECEIVES.case_conversion_class = 0'; // ケース換算「0:する」
      selectSql += ' AND T_RECEIVES.set_class = 1'; // セット品区分「1:セット品」
      selectSql += ' GROUP BY';
      selectSql += ' T_RECEIVES_SET.product_id';
      selectSql += ' UNION ALL';
      /* サブクエリ4 製品パターンに応じてデータ取得：未引当ではないが、適正在庫数が1よりも大きい製品 */
      selectSql += ' SELECT';
      selectSql += ' products_details.product_id';
      selectSql += ', 0 AS order_quantity_1'; // サブクエリ１の入荷予定数
      selectSql += ', 0 AS order_quantity_2'; // サブクエリ２の入荷予定数
      selectSql += ', 0 AS order_quantity_3'; // 部材製品の入荷予定数を合計
      selectSql += ', \'\'  AS note';
      selectSql += ', products_details.office_id';
      selectSql += ', products.sundries_class AS product_manual_input_class'; // 製品手入力区分
      selectSql += ' FROM';
      selectSql += ' m_products_details AS products_details';
      selectSql += ' INNER JOIN'; // 製品マスタ 主キー（product_id）で結合
      selectSql += ' m_products AS products';
      selectSql += ' ON products_details.product_id = products.product_id';
      /* サブクエリ4 WHERE句 */
      selectSql += ' WHERE';
      selectSql += ' products_details.office_id = ' + this.urlParams.salesOffice ; // 営業所
      selectSql += ' AND products_details.appropriate_stock > 0'; // 適正在庫数
      selectSql += ' AND products.inventory_control_class = ' + Const.InventoryControlClassDef.inventory; // 在庫管理区分「0:する」
      selectSql += ' AND products_details.case_conversion_class = ' + Const.CaseConversionClassDef.noConversion; // ケース換算「1:しない」
      selectSql += ') AS TOTAL_JUCHU';
      /* メインクエリFROM句 */
      selectSql += ' LEFT JOIN'; // 発注データ product_id, office_id, client_id, client_class, order_classで結合
      selectSql += ' (SELECT';
      selectSql += ' office_id';
      selectSql += ', product_id';
      selectSql += ', SUM(remaining_quantity) AS remaining_quantity';
      selectSql += ' FROM';
      selectSql += ' t_orders';
      selectSql += ' WHERE';
      selectSql += ' is_deleted = 0'; // 削除済フラグ
      selectSql += ' AND office_id = ' + this.urlParams.salesOffice ; // 営業所
      selectSql += ' AND client_class = ' + Const.ClientClass.supplier ; // 仕入先
      selectSql += ' AND order_class IN (' + Const.OrderClass.noStock + ',' + Const.OrderClass.input + ')'; // 在庫切れ、発注入力
      selectSql += ' AND order_issue_class > 0'; // 発注書発行区分（新規発行、または、強制発行）
      selectSql += ' AND remaining_quantity > 0';
      selectSql += ' GROUP BY';
      selectSql += ' office_id';
      selectSql += ', product_id';
      selectSql += ' ) AS T_ORDERS';
      selectSql += ' ON  TOTAL_JUCHU.product_id = T_ORDERS.product_id';
      selectSql += ' AND TOTAL_JUCHU.office_id = T_ORDERS.office_id';
      selectSql += ' INNER JOIN'; // 製品マスタ 主キー（product_id）で結合
      selectSql += ' m_products AS M_PRODUCTS';
      selectSql += ' ON  TOTAL_JUCHU.product_id = M_PRODUCTS.product_id';
      selectSql += ' INNER JOIN'; // 製品詳細マスタ 主キー（product_id）で結合
      selectSql += ' m_products_details AS M_PRODUCTS_DETAILS';
      selectSql += ' ON  TOTAL_JUCHU.product_id = M_PRODUCTS_DETAILS.product_id';
      selectSql += ' AND TOTAL_JUCHU.office_id = M_PRODUCTS_DETAILS.office_id';
      selectSql += ' INNER JOIN'; // 製品発注マスタ 主キー（product_id, office_id）で結合
      selectSql += ' m_products_orders AS M_PRODUCTS_ORDERS';
      selectSql += ' ON  TOTAL_JUCHU.product_id = M_PRODUCTS_ORDERS.product_id';
      selectSql += ' AND TOTAL_JUCHU.office_id = M_PRODUCTS_ORDERS.office_id';
      selectSql += ' INNER JOIN'; // 取引先マスタ（発注先） 主キー（client_id,client_class）で結合
      selectSql += ' m_clients AS M_CLIENTS_SUPPLIER';
      selectSql += ' ON  M_PRODUCTS_ORDERS.client_id = M_CLIENTS_SUPPLIER.client_id';
      selectSql += ' AND M_CLIENTS_SUPPLIER.client_class = ' + Const.ClientClass.supplier;
      selectSql += ' INNER JOIN'; // 在庫マスタ 主キー（month_year, product_id, office_id）で結合
      selectSql += ' m_stocks AS M_STOCKS';
      selectSql += ` ON  M_STOCKS.month_year = ${this.controlMasterData.processMonthYear}`
      selectSql += ' AND TOTAL_JUCHU.office_id = M_STOCKS.office_id';
      selectSql += ' AND TOTAL_JUCHU.product_id = M_STOCKS.product_id';
      /* メインクエリ1 WHERE句 */
      selectSql += ' WHERE';
      selectSql += ` ${whereClauses.join(' AND ')}`;
      /* メインクエリ GROUP BY句 */
      selectSql += ' GROUP BY';
      selectSql += ' client_id';
      selectSql += ', product_id';
      /* メインクエリ HAVING句 */
      selectSql += ' HAVING';
      selectSql += ' order_quantity > 0'; // 必要数と在庫の差分が1以上ある製品のみ取得
      /* メインクエリ ORDER BY句 */
      selectSql += ' ORDER BY';
      selectSql += ' client_id ASC';
      selectSql += ', product_id ASC';
      //console.log(selectSql);
      sqlList.push(selectSql);
      return sqlList;
    },
    // コントロールマスタ情報取得
    async getControlMasterData() {
      // 現在処理年月取得
      let controlResult = await API.graphql(graphqlOperation(list_m_control));
      let controlData = controlResult.data.list_m_control;
      if (controlData.length > 0) {
        this.controlMasterData = {
          processMonthYear: controlData[0].process_month_year,
          taxRate: controlData[0].tax_rate,
          newTaxRate: controlData[0].new_tax_rate,
          newTaxStartDate: controlData[0].new_tax_start_date,
        };
      }
    },
    /* ログイン社員情報を取得 */
    async getLoginStaffInfo() {
      // ログイン社員データ取得
      let where_clause = 'AND staff_id = ' + ''+ this.loginStaffInfo.staff_id + '';
      let condition = {where_clause: where_clause};
      let staffListResult = await API.graphql(graphqlOperation(list_m_staffs,condition));
      let staffListData = staffListResult.data.list_m_staffs;
      //console.log(staffListData);
      this.loginStaffNameKanji = staffListData[0].staff_name_kanji;
      this.loginStaffNameStamp = staffListData[0].staff_name_stamp;
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      let where_clause = ' AND office_id = ' + this.urlParams.salesOffice + ' ';
      let condition = {where_clause: where_clause};
      let officeListResult = await API.graphql(graphqlOperation(list_m_offices, condition));
      let officeListData = officeListResult.data.list_m_offices;
      for(const officeData of officeListData) {
        this.officeList.push({
          keyNo : 1,
          officeNameKanji : officeData.office_name_kanji,
          zipCode : '〒' + getNullStr(officeData.zip_code),
          address : getNullStr(officeData.address_1) + getNullStr(officeData.address_2),
          phoneNumber : 'TEL ' + getNullStr(officeData.phone_number),
          faxNumber : 'FAX ' + getNullStr(officeData.fax_number),
        });
      }
    },
    /* 仕入先顧客情報を取得 */
    async getSuppliersKokyakuData(supplierId) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' offices.office_name_kanji';
      selectSql += ',suppliers_offices.supplier_control_office_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_suppliers_offices AS suppliers_offices ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON suppliers_offices.office_id = offices.office_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の仕入先コードで絞り込む
      selectSql += 'suppliers_offices.supplier_id = ' + supplierId + ' ';
      // 営業所コードで絞り込む
      selectSql += 'AND suppliers_offices.office_id = ' + this.salesOffice + ' ';
      // 営業所コードでソート
      selectSql += 'ORDER BY suppliers_offices.office_id';
      // console.log(selectSql);
      // 仕入先毎の営業所情報取得
      let dataResult = await executeSelectSql(selectSql);
      let suppliersKokyakuId = '';
      for (let i = 0; i < dataResult.length; i++) {
        if (i != 0) {
          suppliersKokyakuId += '、';
        }
        suppliersKokyakuId += getNullStr(dataResult[i].office_name_kanji);
        suppliersKokyakuId += getNullStr(dataResult[i].supplier_control_office_id);
      }

      return suppliersKokyakuId;
    },
    /* 発注データ毎の一覧を作成 */
    initListSet: function(result) {
      //console.log('initListSet');
      let preClientId = 0; // 前行の仕入先ID
      let chouhyouRowCnt = 0; // 帳票の必要行数
      // DBの結果分ループ
      for (const selectData of result) {
        // 前行も同じ発注番号
        if (selectData.client_id == preClientId) {
          chouhyouRowCnt++; // 製品行
        } else {
          if (preClientId !== 0) {
            // 初回以外の場合、別の発注番号に移ったタイミングでまとめて一覧に追加
            this.listSet.push({ clientId: preClientId, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
          }
          // 帳票情報の初期化と次の顧客を記録
          chouhyouRowCnt = 1; // 製品行
          preClientId = selectData.client_id;
        }
      }
      // 初回以外の場合、別の発注番号に移ったタイミングでまとめて一覧に追加
      //console.log(`chouhyouRowCnt = ${chouhyouRowCnt}`);
      this.listSet.push({ clientId: preClientId, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
    },
    /* 帳票リスト初期化 */
    createListChouhyou: function(productCnt){
      let listChouhyou = [];
      let productCntNokori = productCnt;
      let page = 1;
      while (productCntNokori > 0) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
        listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.cntNormal;
        page++;
      }
      //console.log('createListChouhyou');
      return listChouhyou;
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      let curClientId = 0;
      let workIndex = 0;
      // 発注日付用に現在年月日を取得
      let MM = ('0' + (this.currentDate.getMonth() + 1)).slice(-2);
      let DD = ('0' + this.currentDate.getDate()).slice(-2);
      try {
        for (const printSet of this.listSet) {
          curClientId = printSet.clientId;
          whiteFlg = false;
          for (let i = 0; i < printSet.listChouhyou.length; i++) {
            if (productsIndex < result.length && result[productsIndex].client_id == curClientId) {
              workIndex = productsIndex;
            } else {
              // 製品行のないページ（途中、または、最終ページ）
              workIndex = productsIndex - 1;
            }
            // SVGファイルの置換用文字列
            let replacements = [];
            // 注文番号
            replacements.push({key: '%発注番%', value: result[workIndex].order_number, textAnchor: 'end', textLength: 190, chkWidth: true});
            // 営業
            replacements.push({key: '%営業%', value: this.officeList[0].officeNameKanji, textAnchor: 'start', textLength: 160, chkWidth: true});
            // 発注日付
            result[workIndex].order_date = `${this.currentDate.getFullYear()}-${MM}-${DD}`;
            replacements.push({key: '%発注日付%', value: formatDate(result[workIndex].order_date), textAnchor: 'end', textLength: 251, chkWidth: false});
            // ページ番号
            replacements.push({key: '%P%', value: printSet.listChouhyou[i].page + ' / ' + printSet.listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
            // タイトル
            replacements.push({key: '%タイトル%', value: '御 発 注 書' + '（' + this.officeList[0].officeNameKanji + '）', textAnchor: 'middle', textLength: 925, chkWidth: true});
            // 取引先名
            let clientName = result[workIndex].client_name;
            replacements.push({key: '%取引先名%', value: clientName, textAnchor: 'start', textLength: 769, chkWidth: true});
            // 営業所情報
            await this.setOfficesData(replacements);
            // 担当者名
            let staffName = getNullStr(this.loginStaffNameKanji).trim();
            replacements.push({key: '%担当者名%', value: staffName, textAnchor: 'start', textLength: 416, chkWidth: true});
            // 担当者印
            let staffStamp = getNullStr(this.loginStaffNameStamp).trim();
            replacements.push({key: '%社判%', value: staffStamp, textAnchor: 'middle', textLength: 100, chkWidth: false});
            if (staffStamp != '') {
              this.stampFlg = true;
            }
            // 帳票毎に設定可能な製品の件数
            let productCntByChouhyou = this.constData.cntNormal;
            // 仕入先顧客コード
            replacements.push({key: '%仕入先顧客コード%', value: await this.getSuppliersKokyakuData(result[workIndex].client_id), textAnchor: 'start', textLength: 1320, chkWidth: true});
            // 受注情報
            replacements.push({key: '%受注情報%', value: '', textAnchor: 'start', textLength: 1560, chkWidth: false});
            // 摘要（直送以外は表示しない）
            this.setSummary(replacements);
            // 製品
            for (let j = 0; j < productCntByChouhyou; j++){
              if (productsIndex < result.length && result[productsIndex].client_id == curClientId) {
                // 品番
                replacements.push({key: '%品番' + (j + 1).toString() + '%', value: result[productsIndex].supplier_product_id == null ? '' : result[productsIndex].supplier_product_id, textAnchor: 'start', textLength: 240, chkWidth: true});
                // 商品名
                replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: result[productsIndex].product_name, textAnchor: 'start', textLength: 710, chkWidth: true});
                // 数量
                let productQuantity = result[productsIndex].order_quantity.toLocaleString();
                productQuantity += getNullStr(result[productsIndex].unit) == '' ? '' : ' ' + result[productsIndex].unit;
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: productQuantity, textAnchor: 'end', textLength: 210, chkWidth: true});
                // 備考（サービス品の場合は備考に記載）
                result[productsIndex].note = !result[productsIndex].isService ?
                  '' : (!result[productsIndex].isDividedServiceProduct ? 'サービス品含む' : 'サービス品');
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[productsIndex].note, textAnchor: 'start', textLength: 360, chkWidth: true});

                productsIndex++;
              }else{
                // 品番
                replacements.push({key: '%品番' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 240, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                // 備考
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 360, chkWidth: false});
                if (whiteFlg == true) {
                  // 以下余白を設定済み
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 710, chkWidth: false});
                } else {
                  // 以下余白が入る箇所
                  if (i + 1 == printSet.listChouhyou.length &&
                    j + 1 == productCntByChouhyou) {
                    // 最終帳票、かつ、最終行の場合、余白がないため、「以上」とする。
                    // 商品名
                    replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以上', textAnchor: 'middle', textLength: 710, chkWidth: false});
                  } else {
                    // 商品名
                    replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以下余白', textAnchor: 'middle', textLength: 710, chkWidth: false});
                  }
                  whiteFlg = true;
                }
              }
            }
            printSet.listChouhyou[i].replacements = replacements;
          }
        }
      } catch(error) {
        console.log(error);
        throw error;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for (const office of this.officeList){
        // 営業名
        replacements.push({ key: '%営業名' + office.keyNo + '%', value: office.officeNameKanji, textAnchor: 'start', textLength: 561, chkWidth: true });
        // 郵便
        replacements.push({key: '%郵便' + office.keyNo + '%', value: office.zipCode, textAnchor: 'start', textLength: 501, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + office.keyNo + '%', value: office.address, textAnchor: 'start', textLength: 501, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + office.keyNo + '%', value: office.phoneNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + office.keyNo + '%', value: office.faxNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements) {
      for (let i = 0; i < 4; i++) {
        // 摘要
        replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 1322, chkWidth: false});
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      //console.log('setChouhyou');
      for (const listItem of this.listSet) {
        for (let i = 0; i < listItem.listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + listItem.clientId + '_' + listItem.listChouhyou[i].page);
          // console.log(svgDoc);
          await this.setReplacements(svgDoc, listItem.listChouhyou[i].replacements);
          await this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    async setReplacements(node, replacements){
      // console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if (node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  if(replacements[k].key == '%社判%'){
                    await this.setStamp(node.children[i], node.children[i].children[j], replacements[k]);
                  }else{
                    await this.setTspan(node.children[i], node.children[i].children[j], replacements[k]);
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'circle') {
          // 社判の赤丸
          if (node.children[i].getAttribute('stroke') == 'red') {
            if (this.stampFlg == true) {
              // 社判のテキストに使用するため中心の座標を取得しておく
              this.colStampCX = node.children[i].getAttribute('cx');
              this.colStampCY = node.children[i].getAttribute('cy');
            } else {
              // 赤丸を削除
              node.removeChild(node.children[i]);
              i--; 
            }
          }
        } else if (node.children[i].tagName == 'g'){
          await this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, getNullStr(config.value));
      // 製品一覧の文字を太字化
      if (getNullStr(config.value).length > 0 &&
      (config.key.indexOf('%商品名') != -1 || config.key.indexOf('%数量') != -1 || config.key.indexOf('%備考') != -1 || config.key.indexOf('%品番') != -1)
      ) {
        tagTspan.setAttribute('font-weight', 'bold');
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(config.chkWidth == true &&
        tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    async setStamp(tagText, tagTspan, config) {
      switch (config.value.length) {
      case 0:
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        break;
      case 1:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) + 20);
        break;
      case 2:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '40');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 5);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 35, config.value.substr(1, 1)));
        break;
      case 3:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '30');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 20);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 40, config.value.substr(2, 1)));
        break;
      case 4:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '35');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 8);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 18, Number(this.colStampCY) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) + 28, config.value.substr(3, 1)));
        break;
      default:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '28');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 9);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 15, Number(this.colStampCY) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 38, config.value.substr(4, 1)));
        break;
      }
    },
    /* 印鑑用のtspan作成 */
    async createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 伝票番号取得 */
    async getOrderNumber() {
      const functionName = 'getOrderNumber';
      let orderNumber = await getFormCounter(Const.CounterClass.orderNumber, 0, 0, this.loginStaffInfo.login_id, MODULE_NAME, functionName);
      if (orderNumber != null) {
        return orderNumber;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /*
     * 帳票出力用データチェック処理
     * ・数量チェック（最低発注数、発注ロット数）
     * ・発注条件チェック（発注条件 数量、発注条件 金額）
     * ・サービス品チェック（発注時サービス数）
     * ・注文番号の付与
     */
    async checkOutputsDatas(checkDatas) {
      // レコードが0件の場合は処理を行わない
      if (checkDatas.length == 0) {
        return null;
      }

      /*** 数量チェック ***/
      let beforeQuantity = 0;
      let quotient = 0;
      let remainder = 0;
      for (const product of checkDatas) {
        /* 最低発注数チェック */
        if (product.minimum_quantity > 0) {
          beforeQuantity = product.order_quantity;
          // 最低発注数の指定あり、かつ最低発注数を下回る発注数の場合、最低発注数に上書き
          product.order_quantity = (beforeQuantity < product.minimum_quantity) ?
            product.minimum_quantity : beforeQuantity;
        }
        // console.log(`check minimum : product_id = ${product.product_id}, quantity = ${product.order_quantity}, original_quantity = ${beforeQuantity}, minimum_quantity = ${product.minimum_quantity}`);
        /* 発注ロット数チェック */
        if (product.lots_quantity > 0) {
          quotient = product.order_quantity / product.lots_quantity;
          remainder = product.order_quantity % product.lots_quantity;
          beforeQuantity = product.order_quantity;
          // 発注ロット数の指定がある場合、かつ発注数が発注ロット数の倍数になっていない場合（余りがある場合）、余り分も含めた倍数となるよう発注数を補正
          // 発注ロット数の指定がある場合、かつ発注数が発注ロット数の倍数になっている場合（余りがない場合）、そのまま発注数
          product.order_quantity = (remainder > 0) ?
            Math.ceil(quotient) * product.lots_quantity : product.order_quantity;
        }
        // console.log(`check lots    : product_id = ${product.product_id}, quantity = ${product.order_quantity}, original_quantity = ${beforeQuantity}, lots_quantity = ${product.lots_quantity}, quotient = ${quotient}, remainder = ${remainder}`);
      }

      /*** 発注条件チェック ***/
      // 新規発行の時、または、強制発行の時チェック
      if (this.urlParams.orderSlipClass == 1 || this.urlParams.orderSlipClass == 3) {
        // チェック用に仕入先毎に数量・金額をサマリ
        const groupByClient = checkDatas.reduce((result, current) => {
          // 同一仕入先を検索
          const element = result.find(value => value.client_id === current.client_id);
          if (element) {
            // 既に仕入先の集約データがある場合、数量・発注金額を合算
            element.totalQuantity += Number(current.order_quantity);
            element.totalAmount += Number(current.order_quantity * current.purchase_price);
          } else {
            result.push({
              client_id: current.client_id,
              client_name: current.client_name,
              totalQuantity: Number(current.order_quantity),
              totalAmount: Number(current.order_quantity * current.purchase_price),
              order_condition_quantity: current.order_condition_quantity,
              order_condition_amount: current.order_condition_amount,
            })
          }
          return result;
        }, []);
        //console.log('発注条件')
        //console.log(groupByClient)
        for (const client of groupByClient) {
          // 開発用（特定の取引先コードを明示的に除外）
          // if (client.client_id == 100103) {
          //   client.order_condition_quantity = 10000000
          //   client.order_condition_amount = 10000000
          // }
          if (this.urlParams.orderSlipClass == 1) {
            // 新規発行の時
            // 発注条件の数量・金額に満たない仕入先への発注は、エラーリストに記載
            if (client.totalQuantity < client.order_condition_quantity
              || client.totalAmount < client.order_condition_amount) {
              // 画面表示用のリストに追加
              this.errorList.push({
                client_id: client.client_id,
                client_name: client.client_name,
                totalQuantity: client.totalQuantity,
                totalAmount: client.totalAmount,
                order_condition_quantity: client.order_condition_quantity,
                order_condition_amount: client.order_condition_amount,
              });
              // 出力対象から削除
              checkDatas = checkDatas.filter(item => item.client_id !== client.client_id);
            }
          } else {
            // 強制発行の時
            // 発注条件の数量・金額の両方を満たした仕入先への発注は出力対象から削除
            if (client.totalQuantity >= client.order_condition_quantity
              && client.totalAmount >= client.order_condition_amount) {
              // 出力対象から削除
              checkDatas = checkDatas.filter(item => item.client_id !== client.client_id);
            }
          }
        }
      }

      /*** サービス品チェック ***/
      let serviceProductList = [];
      for (let product of checkDatas) {
        if (this.urlParams.orderSlipClass == 1) {
          // 新規発行の時
          // サービス有無区分が「0:有」の場合、サービス分増加orレコードを追加
          if (product.service_whether_class == 0) {
            let copyProduct = null;
            // サービス発注区分によって、表示方法を変更
            switch (product.service_order_class) {
            case Const.ServiceOrderClass.include: // 0:サービス数を含めて発注
              product.order_quantity = Number(product.order_quantity) + Number(product.service_quantity); // 製品数に加算
              // サービス品の表示なし
              product.isDividedServiceProduct = false;
              product.isService = true;
              break;
            case Const.ServiceOrderClass.split: // 1:分けて発注
              //console.log(`product_id = ${product.product_id} ${product.product_name} サービス品のみ`)
              // コピー元はサービス品でないよう表示
              product.isDividedServiceProduct = true;
              product.isService = false;
              copyProduct = Object.assign({}, product);
              copyProduct.order_quantity = Number(product.service_quantity); // サービス用製品数を発注サービス数で作成
              // サービス品として別レコードで表示
              copyProduct.isDividedServiceProduct = true;
              copyProduct.isService = true;
              serviceProductList.push(copyProduct);
              break;
            default:
              // サービス品の表示なし
              product.isDividedServiceProduct = false;
              product.isService = false;
              break;
            }
          } else {
            // サービス品の表示なし
            product.isDividedServiceProduct = false;
            product.isService = false;
          }
        } else {
          // 強制発行の時
          // サービス品の表示なし
          product.isDividedServiceProduct = false;
          product.isService = false;
        }
      }
      // サービス品の配列を連結
      Array.prototype.push.apply(checkDatas,serviceProductList); 
      // サービス品チェック後に再度、製品をソート
      checkDatas.sort(function(a,b){
        if (a.client_id > b.client_id) return 1;
        if (a.client_id < b.client_id) return -1;
        if (a.product_id > b.product_id) return 1;
        if (a.product_id < b.product_id) return -1;
        if (!a.isDividedServiceProduct && b.isDividedServiceProduct) return 1;
        if (a.isDividedServiceProduct && !b.isDividedServiceProduct) return -1;
        return 0;
      });

      /*** 注文番号の採番 ***/
      let orderNumber = null;
      let beforeClientId = null;
      for (const data of checkDatas) {
        // 同一仕入先が異なる場合、帳票カウンタから取得
        if (data.client_id !== beforeClientId) {
          orderNumber = await this.getOrderNumber();
        }
        // 注文番号をセット、直前の仕入先IDを更新
        data.order_number = orderNumber;
        beforeClientId = data.client_id;
      }
      return checkDatas;
    },
    /*
     * executeTransactSql実行用の共通関数
     */
    async executeSqls(sqls, timing) {
      const functionName = `executeSqls (${timing})`;
      //console.log(functionName);
      //console.log(sqls);
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        throw error;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        throw result.errors;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        throw body.error;
      }
      return body.data;
    },
    /*
     * 帳票出力後の更新処理用SQL生成
     */
    async makeUpdateSql(outputOrderDatas){
      let sqls = [];
      let insertSql = '';
      let leadTime = 0;
      let stockDate = null;
      let cntRow = 0;
      let beforeProductId = null;
      let orderId = await this.getAutoIncrementOrders() - 1; // ループ内で最初に+1されるため、-1する
      let quantityStockList = [];
      for (const data of outputOrderDatas) {
        // 同一製品の場合はカウンタを更新、別製品の場合は初期化
        if (beforeProductId == data.product_id) {
          // 行番号用カウンタをカウントアップ、発注番号用カウンタはそのまま
          cntRow++;
        } else {
          // 行番号用カウンタを初期化、発注番号用カウンタをカウントアップ
          cntRow = 1;
        }
        if (data.isDividedServiceProduct == false || data.isService == false) {
          orderId++;
        }
        // 入荷予定日算出
        if (data.lead_time == 99) {
          // 99未定のため、一旦9999-12-31
          stockDate = '9999-12-31'
        } else {
          leadTime = data.lead_time;
          stockDate = new Date();
          stockDate.setDate(stockDate.getDate() + leadTime);
          stockDate = `${stockDate.getFullYear()}-${stockDate.getMonth() + 1}-${stockDate.getDate()}`; 
        }

        /* 発注データの作成 */
        if (insertSql == '') {
          insertSql = 'INSERT INTO t_orders';
          insertSql += ' VALUES('
        } else {
          insertSql += ' ,('
        }
        let colVal = '';
        colVal += `${orderId}`; // 発注番号
        colVal += `, ${data.isDividedServiceProduct && data.isService ? cntRow : 1}`; // 発注行番号（同一製品でサービス品を分けて表示した場合のみ、同一発注番号かつ行番号を連番で作成）
        colVal += ', CURDATE()'; // 発注日
        colVal += ', 2'; // 発注種別「2:在庫切れ」
        colVal += `, ${this.salesOffice}`; // 営業所コード
        colVal += ', 2'; // 取引先区分「2:仕入先」
        colVal += `, ${data.client_id}`; // 取引先コード
        colVal += `, ${this.loginStaffInfo.staff_id}`; // 担当者コード
        colVal += `, ${data.product_id}`; // 製品コード
        colVal += `, '${await escapeQuote(data.product_name)}'`; // 製品名
        colVal += `, '${await escapeQuote(data.note)}'`; // 備考
        colVal += `, ${data.isService ? '\'A\'' : '\'\''}`; // サービス区分
        colVal += `, ${data.order_quantity}`; // 発注数
        colVal += `, '${await escapeQuote(data.unit)}'`; // 単位
        colVal += `, ${data.purchase_price}`; // 発注単価
        colVal += `, ${(data.order_quantity * data.purchase_price)}`; // 発注金額
        colVal += ', 0'; // 消費税
        colVal += `, ${data.order_quantity}`; // 発注残数
        colVal += ', 0'; // 入荷数
        colVal += `, '${stockDate}'`; // 入荷予定日
        colVal += ', 0'; // 入荷予定日確定区分
        colVal += `, ${null}`; // 入荷予定処理担当者コード
        colVal += ', 0'; // 入荷予定引当数
        colVal += `, ${data.union_id}`; // 組合コード
        colVal += ', 0'; // セット品区分
        colVal += ', 0'; // 現場コード
        colVal += `, ${data.product_class_id}`; // 製品分類コード
        colVal += `, ${data.product_manual_input_class}`; // 製品手入力区分
        colVal += `, ${data.inventory_control_class}`; // 在庫管理区分
        colVal += `, ${null}`; // 取引先製品単価区分
        colVal += ', 1'; // ケース換算区分
        colVal += `, ${data.client_tax_class}`; // 仕入課税区分
        colVal += `, ${data.tax_calculation_class}`; // 税額計算区分
        colVal += `, ${data.tax_fractionation_class}`; // 税額端数処理区分
        colVal += `, ${this.urlParams.orderSlipClass == 1 ? 1 : 2}`; // 発注書発行区分
        colVal += ', CURDATE()'; // 発注書発行日
        colVal += `, ${data.order_number}`; // 注文番号
        colVal += ', 0'; // 受注番号
        colVal += ', 0'; // 受注行番号
        colVal += ', 0'; // 削除済フラグ
        colVal += ', CURRENT_TIMESTAMP()'; // 作成日
        colVal += `, '${this.loginStaffInfo.login_id}'`; // 作成ユーザー
        colVal += ', CURRENT_TIMESTAMP()'; // 更新日
        colVal += `, '${this.loginStaffInfo.login_id}'`; // 更新ユーザー
        insertSql = insertSql + colVal + ')';
        if (insertSql.length >= Const.SqlMaxLength) {
          //console.log(insertSql);
          sqls.push(insertSql);
          insertSql = '';
        }
        // 在庫更新情報を作成
        this.addStockProductIdList(quantityStockList, data.product_id, Number(data.order_quantity));
        // 製品コードの更新
        beforeProductId = data.product_id;
      }
      if (insertSql != '') {
        //console.log(insertSql);
        sqls.push(insertSql);
      }
      /* 在庫マスタの更新 */
      sqls.push(this.createUpdateSqlStocks(quantityStockList));

      //console.log(sqls);
      return sqls;
    },
    /* SELECT文字列作成 */
    async getAutoIncrementOrders() {
      let selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' AUTO_INCREMENT';
      selectSql += ' FROM ';
      selectSql += 'information_schema.tables ';
      selectSql += ' WHERE ';
      selectSql += 'TABLE_NAME = \'t_orders\' ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      return Number(resultData[0].AUTO_INCREMENT);
    },
    // 入荷予定製品リスト追加
    addStockProductIdList: function(quantityStockList, productId, orderQuantity) {
      if (quantityStockList.length == 0) {
        quantityStockList.push({productId: productId, orderQuantity: orderQuantity});
      } else {
        // 重複しないように製品コードをリストに追加
        for (let i = 0; i < quantityStockList.length; i++) {
          if (quantityStockList[i].productId == productId) {
            quantityStockList[i].orderQuantity += orderQuantity;
            break;
          }
          if (i == quantityStockList.length - 1) {
            // 在庫マスタの増加があった製品はリストに保持（後で未引当の受注への引当に使用）
            quantityStockList.push({productId: productId, orderQuantity: orderQuantity});
            break;
          }
        }
      }
    },
    // 在庫マスタ更新SQL作成
    createUpdateSqlStocks: function(quantityStockList) {
      let colList = [];
      // 製品関係の更新部分
      let strWorkField = 'FIELD(product_id';
      let strWorkQuantityStock = '';
      let csvProductId = '';
      for(let i = 0; i < quantityStockList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += quantityStockList[i].productId;
        // 入荷予定数
        strWorkQuantityStock += ',quantity_stock + ' + quantityStockList[i].orderQuantity.toString();
      }
      strWorkField += ',' + csvProductId + ')';
      // 入荷予定数
      colList.push(CreateColRow('quantity_stock', 'ELT(' + strWorkField + strWorkQuantityStock + ')', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginStaffInfo.login_id, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_stocks');
      updateSql += ' WHERE ';
      updateSql += 'month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND office_id = ' + this.salesOffice + ' ';
      updateSql += 'AND product_id IN (' + csvProductId + ') ';
      //console.log(updateSql);

      return updateSql;
    },
  },
}
</script>
<style scoped>
</style>