<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" :key="resetKey" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 売価・仕入単価一括更新</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <!-- ●●●検索条件●●● -->
          <b-card>
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body class="p-2">
              <validation-observer ref="observer">
                <b-container fluid>
                  <!-- ●●●検索条件●●● -->
                  <b-row class="mt-2">
                    <!-- インポートファイル設定 -->
                    <b-col lg="6">
                      <validation-provider name="fileSalesPurchasePrice" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="読み込みファイル(.csv)"
                          label-for="fileSalesPurchasePrice"
                        >
                          <b-form-file
                            id="fileSalesPurchasePrice"
                            v-model="fileSalesPurchasePrice"
                            :class="{'border-danger': errors[0]}"
                          >
                          </b-form-file>
                          <b-col :class="classes" lg="12">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- ファイルインポートボタン -->
                  <b-row class="my-2">
                    <b-col lg="6">
                      <b-form style="text-align: left;">
                        <b-button pill variant="success" @click="clearAlert(); clickFileImport();">
                          <span class="oi oi-data-transfer-upload"></span> ファイルインポート
                        </b-button>
                      </b-form>
                    </b-col>
                    <b-col lg="6">
                      <b-form style="text-align: center;">
                        <b-button class="mr-2" pill :variant="this.controlMasterData.productsBulkUpdateState == htmlConst.ClosingUpdateStateClass.RUNNING ? 'danger' : 'success'" v-b-tooltip.hover.noninteractive :title="bulkUpdateButtonTitle" @click="clearAlert(); changeProductsBulkUpdateState();">
                          <span class="oi oi-circle-check"></span> {{bulkUpdateButtonName}}
                        </b-button>
                        <label style="color: red;">一括更新実施開始は一括更新時において使用するボタンです。それ以外の用途では使用しないで下さい。誤って開始させた場合は一括更新実施終了を押下して下さい。</label>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea" v-if="fields!=null">
        <b-col lg="12">
          <b-row>
            <b-col lg="12" class="my-1 px-0">
              <!-- 更新ボタン -->
              <b-btn-toolbar>
                <b-button class="mr-2"
                  pill
                  variant="success"
                  v-b-tooltip.hover.noninteractive
                  title="インポートした情報で製品マスタを一括更新します。"
                  @click="clearAlert(); clickBulkUpdate()"
                  :disabled="itemList.length == 0 || checkOkNg == 2"
                >
                  <span class="oi oi-pencil"></span> 一括更新
                </b-button>&nbsp;
              </b-btn-toolbar>
            </b-col>
          </b-row>
          <b-row>
            <!-- 表示データ選択 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-radio-group
                id="checkOkNg"
                v-model="checkOkNg"
                :options="dispOkNgList"
                @change="setOkNgList"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :small="true"
            >
              <template #head(OfficeId)>
                <div>営業所</div>
                <div>コード</div>
              </template>
              <template #head(ProductNameMaster)>
                <div>製品名（漢字）</div>
                <div>（マスタ）</div>
              </template>
              <template #head(ProductNameKanaMaster)>
                <div>製品名（カナ）</div>
                <div>（マスタ）</div>
              </template>
              <template #head(RegularPriceMaster)>
                <div>定価</div>
                <div>（マスタ）</div>
              </template>
              <template #head(SalesUnitPriceMaster)>
                <div>売上単価</div>
                <div>（マスタ）</div>
              </template>
              <template #head(PurchasePriceMaster)>
                <div>仕入単価</div>
                <div>（マスタ）</div>
              </template>
              <template #head(CostPriceMaster)>
                <div>原価単価</div>
                <div>（マスタ）</div>
              </template>
              <template #head(SupplierProductIdMaster)>
                <div>仕入先製品コード</div>
                <div>（マスタ）</div>
              </template>
              <template #cell(RegularPriceMaster)="data">
                {{ checkOkNgDisp == 1 ? data.item.RegularPriceMaster.toLocaleString() : data.item.RegularPriceMaster }}
              </template>
              <template #cell(RegularPrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.RegularPrice.toLocaleString() : data.item.RegularPrice }}
              </template>
              <template #cell(SalesUnitPriceMaster)="data">
                {{ checkOkNgDisp == 1 ? data.item.SalesUnitPriceMaster.toLocaleString() : data.item.SalesUnitPriceMaster }}
              </template>
              <template #cell(SalesUnitPrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.SalesUnitPrice.toLocaleString() : data.item.SalesUnitPrice }}
              </template>
              <template #cell(PurchasePriceMaster)="data">
                {{ checkOkNgDisp == 1 ? data.item.PurchasePriceMaster.toLocaleString() : data.item.PurchasePriceMaster }}
              </template>
              <template #cell(PurchasePrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.PurchasePrice.toLocaleString() : data.item.PurchasePrice }}
              </template>
              <template #cell(CostPriceMaster)="data">
                {{ checkOkNgDisp == 1 ? data.item.CostPriceMaster.toLocaleString() : data.item.CostPriceMaster }}
              </template>
              <template #cell(CostPrice)="data">
                {{ checkOkNgDisp == 1 ? data.item.CostPrice.toLocaleString() : data.item.CostPrice }}
              </template>
              <template #cell(ErrNaiyo)="data">
                <div style="max-width:100px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;" v-b-tooltip.hover.noninteractive.right="data.item.ErrNaiyo">
                    {{ data.item.ErrNaiyo }}
                  </div>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const, { TRANSACT_SQLS_REQUEST_TYPE } from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, getControlMaster, formatCurDate, getNullStr, addOperationLogs, executeSelectSql, CreateInsertSql, CreateUpdateSql, CreateColRow, escapeQuote, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { requestTransactSqlsAsync, onTransactSqlStatusUpdate } from '@/assets/js/executeSqlAsync.js'
import { onUpdateTransactSqlStatus } from '@/graphql/subscriptions';
import { parse } from 'csv-parse';

const MODULE_NAME = 'csv-import-sales-purchase-price';

export default {
  name: 'CSV-IMPORT-SALES-PURCHASE-PRICE',
  // コンポーネント
  components: {
    Header,
    Footer,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売価・仕入単価一括更新',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // インポートファイル
      fileSalesPurchasePrice: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群（本項目は件数が大きくなりやすいため、共通値は使用しない）
      pageOptions: [
        {value: 100, text: '100'},
        {value: 500, text: '500'},
        {value: 1000, text: '1000'},
      ],
      // 表示データの総件数
      totalRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // csvインデックス
      csvColIndex: {
        // 営業所コード
        officeId: -1,
        // 製品コード
        productId: -1,
        // 製品名（漢字）
        productNameKanji: -1,
        // 製品名（カナ）
        productNameKana: -1,
        // 定価
        regularPrice: -1,
        // 売上単価
        salesUnitPrice: -1,
        // 仕入単価
        purchasePrice: -1,
        // 原価単価
        costPrice: -1,
        // 仕入先製品コード
        supplierProductId: -1,
      },
      // csvカラム名
      csvColName: {
        // 営業所コード
        officeId: '営業所コード',
        // 製品コード
        productId: '製品コード',
        // 製品名（漢字）
        productNameKanji: '製品名（漢字）',
        // 製品名（カナ）
        productNameKana: '製品名（カナ）',
        // 定価
        regularPrice: '定価',
        // 売上単価
        salesUnitPrice: '売上単価',
        // 仕入単価
        purchasePrice: '仕入単価',
        // 原価単価
        costPrice: '原価単価',
        // 仕入先製品コード
        supplierProductId: '仕入先製品コード',
      },
      // 上限値
      limitCnt: 400,
      lineMax: 10000,
      // テーブル構成
      fields: null,
      fieldsOk: [],
      fieldsNg: [],
      // インポート結果
      itemList: [],
      checkOkList: [],
      checkNgList: [],
      checkOkNg: 1,
      checkOkNgDisp: 1,
      dispOkNgList: [
        {value: 1, text: '更新データ一覧表示'},
        {value: 2, text: 'NGデータ一覧表示'}
      ],
      isWarning: false,
      // トランザクションSQLリスト
      transactSqlList: [],
      // コントロールマスタの製品一括更新状態
      controlMasterData: {
        productsBulkUpdateState: null,	
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 締更新状態状態区分
        ClosingUpdateStateClass: {
          NOT_RUNNING: Const.ClosingUpdateStateClass.NOT_RUNNING, // 停止中
          RUNNING: Const.ClosingUpdateStateClass.RUNNING, // 実行中
        },
      },
      // ヘッダー更新キー
      resetKey: 0,
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    // ファイルにイベント追加
    document.getElementById('fileSalesPurchasePrice').addEventListener('click', () => {
      this.fileSalesPurchasePrice = null;
    });
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = this.totalRows;
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 一括更新ボタン名
    bulkUpdateButtonName() {
      if (this.controlMasterData.productsBulkUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '売価・仕入単価一括更新実施終了';
      } else {
        return '売価・仕入単価一括更新実施開始';
      }
    },
    // 締更新ボタン吹き出し
    bulkUpdateButtonTitle() {
      if (this.controlMasterData.productsBulkUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '売価・仕入単価一括更新に関係のない画面の登録・更新・削除の制限を解除します。';
      } else {
        return '売価・仕入単価一括更新に関係のない画面の登録・更新・削除を制限します。';
      }
    },
  },
  // 関数群
  methods: {
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)を取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // コントロールマスタ取得
        let controlData = await getControlMaster();
        this.controlMasterData.productsBulkUpdateState = controlData.products_bulk_update_state;
        // フィールド作成
        this.createFields();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // フィールド作成
    createFields: function() {
      // 更新対象のフィールド
      this.fieldsOk.push({key: 'No', label: 'No'});
      this.fieldsOk.push({key: 'OfficeId', label: '営業所コード'});
      this.fieldsOk.push({key: 'ProductId', label: '製品コード'});
      this.fieldsOk.push({key: 'ProductNameMaster', label: '製品名（漢字）（マスタ）'});
      this.fieldsOk.push({key: 'ProductName', label: '製品名（漢字）'});
      this.fieldsOk.push({key: 'ProductNameKanaMaster', label: '製品名（カナ）（マスタ）'});
      this.fieldsOk.push({key: 'ProductNameKana', label: '製品名（カナ）'});
      this.fieldsOk.push({key: 'RegularPriceMaster', label: '定価（マスタ）', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'RegularPrice', label: '定価', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'SalesUnitPriceMaster', label: '売上単価（マスタ）', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'SalesUnitPrice', label: '売上単価', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'PurchasePriceMaster', label: '仕入単価（マスタ）', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'PurchasePrice', label: '仕入単価', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'CostPriceMaster', label: '原価単価（マスタ）', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'CostPrice', label: '原価単価', tdClass: 'text-right'});
      this.fieldsOk.push({key: 'SupplierProductIdMaster', label: '仕入先製品コード（マスタ）'});
      this.fieldsOk.push({key: 'SupplierProductId', label: '仕入先製品コード'});
      // エラー行のフィールド
      this.fieldsNg.push({key: 'No', label: 'No'});
      this.fieldsNg.push({key: 'ErrNaiyo', label: 'エラー内容', sortable: true});
      this.fieldsNg.push({key: 'OfficeId', label: '営業所コード'});
      this.fieldsNg.push({key: 'ProductId', label: '製品コード'});
      this.fieldsNg.push({key: 'ProductNameMaster', label: '製品名（漢字）（マスタ）'});
      this.fieldsNg.push({key: 'ProductName', label: '製品名（漢字）'});
      this.fieldsNg.push({key: 'ProductNameKanaMaster', label: '製品名（カナ）（マスタ）'});
      this.fieldsNg.push({key: 'ProductNameKana', label: '製品名（カナ）'});
      this.fieldsNg.push({key: 'RegularPriceMaster', label: '定価（マスタ）', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'RegularPrice', label: '定価', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'SalesUnitPriceMaster', label: '売上単価（マスタ）', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'SalesUnitPrice', label: '売上単価', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'PurchasePriceMaster', label: '仕入単価（マスタ）', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'PurchasePrice', label: '仕入単価', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'CostPriceMaster', label: '原価単価（マスタ）', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'CostPrice', label: '原価単価', tdClass: 'text-right'});
      this.fieldsNg.push({key: 'SupplierProductIdMaster', label: '仕入先製品コード（マスタ）'});
      this.fieldsNg.push({key: 'SupplierProductId', label: '仕入先製品コード'});
    },
    // ファイルインポートボタン押下時
    async clickFileImport() {
      const functionName = 'clickFileImport';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          let confirmMessage = '指定したファイルを取り込みます。よろしいですか？';
          if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: 'ファイルインポート'}) == true) {
            await this.fileImport();
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // ファイルインポート
    async fileImport() {
      this.$store.commit('setLoading', true);
      // 表示中の情報がある場合、初期化
      this.fileDataInit();
      let reader = new FileReader();
      reader.onload = ('load', () => {
        this.readFile(reader.result);
      });
      reader.onerror = ('error', (error) => {
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2062']);
        this.$store.commit('setLoading', false);
      });
      await reader.readAsText(this.fileSalesPurchasePrice);
    },
    // ファイルの内容を読み込む
    async readFile(fileText) {
      const functionName = 'readFile';
      try {
        // テキストをCSV形式で読み込み配列を作成
        let csvRecords = this.createCsvArray(fileText);
        //console.log(csvRecords);
        // 行数チェック
        if (csvRecords.length < 2) {
          // データ無し
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（データ無し）'));
          this.$store.commit('setLoading', false);
          return;
        } else if (csvRecords.length > this.lineMax + 1) {
          // 上限行を超過
          this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.lineMax + '行を超過）'));
          this.$store.commit('setLoading', false);
          return;
        }
        // ヘッダーチェック
        if (this.checkHeaderRow(csvRecords[0]) == false) {
          this.$store.commit('setLoading', false);
          return;
        }
        // 表示用の一覧を作成
        let csvDataRecords = [];
        // ヘッダーを除き、不要な列は除外された一覧を作成
        // ・ヘッダー除外のため1始まり
        for (let i = 1; i < csvRecords.length; i++) {
          let newRow = {
            No: i,
            _cellVariants: {No: 'info'},
            OfficeId: getNullStr(csvRecords[i][this.csvColIndex.officeId]).trim(),
            ProductId: getNullStr(csvRecords[i][this.csvColIndex.productId]).trim(),
            ProductName: getNullStr(csvRecords[i][this.csvColIndex.productNameKanji]),
            ProductNameMaster: null,
            ProductNameKana: getNullStr(csvRecords[i][this.csvColIndex.productNameKana]),
            ProductNameKanaMaster: null,
            RegularPrice: getNullStr(csvRecords[i][this.csvColIndex.regularPrice]).trim(),
            RegularPriceMaster: null,
            SalesUnitPrice: getNullStr(csvRecords[i][this.csvColIndex.salesUnitPrice]).trim(),
            SalesUnitPriceMaster: null,
            PurchasePrice: getNullStr(csvRecords[i][this.csvColIndex.purchasePrice]).trim(),
            PurchasePriceMaster: null,
            CostPrice: getNullStr(csvRecords[i][this.csvColIndex.costPrice]).trim(),
            CostPriceMaster: null,
            SupplierProductId: csvRecords[i][this.csvColIndex.supplierProductId],
            SupplierProductIdMaster: null,
          };
          csvDataRecords.push(newRow);
        }
        // CSVファイルのデータをチェックし、更新可能リストと更新NGリストに分ける
        await this.createDispList(csvDataRecords);
        // 初期状態では更新リストを表示
        this.setOkNgList();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // ファイル情報初期化
    fileDataInit: function() {
      // ページングの初期化
      this.initPaging();
      // フィールド情報
      this.fields = null;
      // 表示情報
      this.itemList = [];
      this.checkOkList = [];
      this.checkNgList = [];
      this.checkOkNg = 1;
      this.checkOkNgDisp = 1;
      this.isWarning = false;
      // csvインデックス
      for (let key in this.csvColIndex) {
        this.csvColIndex[key] = -1;
      }
    },
    // CSVファイルの配列を作成
    createCsvArray: function(fileText) {
      const records = [];
      let parser = parse();
      parser.on('readable', function() {
        let record;
        while ((record = parser.read()) !== null) {
          records.push(record);
        }
      });
      parser.write(fileText);
      parser.end();

      return records;
    },
    // ヘッダーチェック
    checkHeaderRow: function(row) {
      for (let i = 0; i < row.length; i++) {
        switch(row[i].trim()) {
        case this.csvColName.officeId:
          if (this.csvColIndex.officeId == -1) {
            this.csvColIndex.officeId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.officeId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.productId:
          if (this.csvColIndex.productId == -1) {
            this.csvColIndex.productId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.productId + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.productNameKanji:
          if (this.csvColIndex.productNameKanji == -1) {
            this.csvColIndex.productNameKanji = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.productNameKanji + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.productNameKana:
          if (this.csvColIndex.productNameKana == -1) {
            this.csvColIndex.productNameKana = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.productNameKana + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.regularPrice:
          if (this.csvColIndex.regularPrice == -1) {
            this.csvColIndex.regularPrice = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.regularPrice + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.salesUnitPrice:
          if (this.csvColIndex.salesUnitPrice == -1) {
            this.csvColIndex.salesUnitPrice = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.salesUnitPrice + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.purchasePrice:
          if (this.csvColIndex.purchasePrice == -1) {
            this.csvColIndex.purchasePrice = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.purchasePrice + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.costPrice:
          if (this.csvColIndex.costPrice == -1) {
            this.csvColIndex.costPrice = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.costPrice + 'が2列以上）'));
            return false;
          }
          break;
        case this.csvColName.supplierProductId:
          if (this.csvColIndex.supplierProductId == -1) {
            this.csvColIndex.supplierProductId = i;
          } else {
            // 同じカラムが二つ以上のエラー
            this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + this.csvColName.supplierProductId + 'が2列以上）'));
            return false;
          }
          break;
        default:
          break;
        }
      }
      if (this.csvColIndex.officeId != -1 &&
      this.csvColIndex.productId != -1 &&
      this.csvColIndex.productNameKanji != -1 &&
      this.csvColIndex.productNameKana != -1 &&
      this.csvColIndex.regularPrice != -1 &&
      this.csvColIndex.salesUnitPrice != -1 &&
      this.csvColIndex.purchasePrice != -1 &&
      this.csvColIndex.costPrice != -1 &&
      this.csvColIndex.supplierProductId != -1
      ) {
        // ヘッダー行に全てのカラムがある場合はtrue
        return true;
      } else {
        let noColName = '';
        if (this.csvColIndex.officeId == -1) {
          noColName += this.csvColName.officeId;
        }
        if (this.csvColIndex.productId == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.productId;
        }
        if (this.csvColIndex.productNameKanji == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.productNameKanji;
        }
        if (this.csvColIndex.productNameKana == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.productNameKana;
        }
        if (this.csvColIndex.regularPrice == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.regularPrice;
        }
        if (this.csvColIndex.salesUnitPrice == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.salesUnitPrice;
        }
        if (this.csvColIndex.purchasePrice == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.purchasePrice;
        }
        if (this.csvColIndex.costPrice == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.costPrice;
        }
        if (this.csvColIndex.supplierProductId == -1) {
          if (noColName != '') {
            noColName += '、';
          }
          noColName += this.csvColName.supplierProductId;
        }
        // ヘッダー行にカラムがない場合はfalse
        this.alertWarning.push(DISP_MESSAGES.WARNING['2059'].replace('%arg1%','（' + noColName + 'の列無し）'));
        return false;
      }
    },
    // 表示用の一覧作成
    async createDispList(csvDataRecords) {
      let splitCnt = Math.trunc((csvDataRecords.length - 1) / this.limitCnt) + 1;
      //console.log('splitCnt:' + splitCnt);
      for (let i = 0; i < splitCnt ; i++) {
        await this.createDispListSplit(csvDataRecords, i * this.limitCnt, (i + 1) * this.limitCnt);
      }
    },
    // 表示用の一覧を分割作成
    async createDispListSplit(csvDataRecords, startIndex, endIndex) {
      let officeIdList = [];
      let productIdList = [];
      // 製品マスタ、製品詳細マスタから取得するための営業所コードと製品コードを取得
      for (let i = startIndex; i < endIndex && i < csvDataRecords.length; i++) {
        let officeId = officeIdList.find(el => el == csvDataRecords[i].OfficeId);
        if (officeId == undefined) {
          if (isNaN(Number(csvDataRecords[i].OfficeId)) == false) {
            // 数値以外の場合は追加しない
            officeIdList.push(Number(csvDataRecords[i].OfficeId));
          }
        }
        let productId = productIdList.find(el => el == csvDataRecords[i].ProductId);
        if (productId == undefined) {
          if (isNaN(Number(csvDataRecords[i].ProductId)) == false) {
            // 数値以外の場合は追加しない
            productIdList.push(Number(csvDataRecords[i].ProductId));
          }
        }
      }
      //console.log(officeIdList);
      //console.log(productIdList);
      let isNoMaster = false;
      if (officeIdList.length > 0 && productIdList.length > 0) {
        let csvOfficeId = officeIdList.join(',');
        let csvProductId = productIdList.join(',');
        let selectSql = this.makeSelectSqlProduct(csvOfficeId, csvProductId);
        //console.log(selectSql);
        let productResultData = await executeSelectSql(selectSql);
        //console.log(productResultData);
        if (productResultData != null && productResultData.length > 0) {
          this.checkDispListSplit(csvDataRecords, startIndex, endIndex, productResultData);
        } else {
          isNoMaster = true;
        }
      } else {
        isNoMaster = true;
      }
      if (isNoMaster == true) {
        // 製品情報を取得できなかった場合、全データをマスタエラーとする
        for (let i = startIndex; i < endIndex && i < csvDataRecords.length; i++) {
          csvDataRecords[i].ErrNaiyo = 'マスタエラー';
          csvDataRecords[i]._cellVariants.OfficeId = 'danger';
          csvDataRecords[i]._cellVariants.ProductId = 'danger';
          this.checkNgList.push(csvDataRecords[i]);
        }
      }
    },
    // SELECT文字列作成
    makeSelectSqlProduct: function(csvOfficeId, csvProductId) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' products_details.office_id';
      selectSql += ',products_details.product_id';
      selectSql += ',products.product_name_kanji';
      selectSql += ',products.product_name_kana';
      selectSql += ',products.regular_price';
      selectSql += ',products.sales_unit_price';
      selectSql += ',products_details.purchase_price';
      selectSql += ',products_details.cost_price';
      selectSql += ',products_details.supplier_product_id';
      selectSql += ',products.is_9A';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_products_details AS products_details ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON products.product_id = products_details.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'products_details.office_id IN (' + csvOfficeId + ') ';
      // 製品コード
      selectSql += 'AND products_details.product_id IN (' + csvProductId + ') ';

      return selectSql;
    },
    // 表示用の一覧を分割判定
    checkDispListSplit: function(csvDataRecords, startIndex, endIndex, productResultData) {
      for (let i = startIndex; i < endIndex && i < csvDataRecords.length; i++) {
        let data = productResultData.find(el => el.office_id == csvDataRecords[i].OfficeId && el.product_id == csvDataRecords[i].ProductId);
        if (data != undefined) {
          let isErr = false;
          let isNoChange = true;
          // 製品名（漢字）
          csvDataRecords[i].ProductNameMaster = data.product_name_kanji;
          if (getNullStr(csvDataRecords[i].ProductName).trim() == '') {
            // 空白の場合は全角スペースを入れる
            csvDataRecords[i].ProductName = '　';
            if (csvDataRecords[i].ProductName != csvDataRecords[i].ProductNameMaster) {
              isNoChange = false;
            }
          } else if (getNullStr(csvDataRecords[i].ProductName).length <= 35) {
            csvDataRecords[i].ProductName = getNullStr(csvDataRecords[i].ProductName);
            if (csvDataRecords[i].ProductName != csvDataRecords[i].ProductNameMaster) {
              isNoChange = false;
            }
          } else {
            csvDataRecords[i].ProductName = getNullStr(csvDataRecords[i].ProductName).substring(0, 36);
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.productNameKanji + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.productNameKanji + 'が不正';
            csvDataRecords[i]._cellVariants.ProductName = 'danger';
            isErr = true;
          }
          // 製品名（カナ）
          csvDataRecords[i].ProductNameKanaMaster = data.product_name_kana;
          if (getNullStr(csvDataRecords[i].ProductNameKana).trim() == '') {
            // 空白の場合は全角スペースを入れる
            csvDataRecords[i].ProductNameKana = '　';
            if (csvDataRecords[i].ProductNameKana != csvDataRecords[i].ProductNameKanaMaster) {
              isNoChange = false;
            }
          } else if (getNullStr(csvDataRecords[i].ProductNameKana).length <= 40) {
            csvDataRecords[i].ProductNameKana = getNullStr(csvDataRecords[i].ProductNameKana);
            if (csvDataRecords[i].ProductNameKana != csvDataRecords[i].ProductNameKanaMaster) {
              isNoChange = false;
            }
          } else {
            csvDataRecords[i].ProductNameKana = getNullStr(csvDataRecords[i].ProductNameKana).substring(0, 41);
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.productNameKana + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.productNameKana + 'が不正';
            csvDataRecords[i]._cellVariants.ProductNameKana = 'danger';
            isErr = true;
          }
          // 定価
          csvDataRecords[i].RegularPriceMaster = data.regular_price;
          if (getNullStr(csvDataRecords[i].RegularPrice) != '' && isNaN(Number(csvDataRecords[i].RegularPrice)) == false &&
          0 <= Number(csvDataRecords[i].RegularPrice) && Number(csvDataRecords[i].RegularPrice) <= 99999999 && Number.isInteger(Number(csvDataRecords[i].RegularPrice)) == true) {
            csvDataRecords[i].RegularPrice = Number(csvDataRecords[i].RegularPrice);
            if (data.is_9A == 1 && csvDataRecords[i].RegularPrice != 0) {
              // 9A製品で原価単価に0以外を設定しようとする場合はエラーとする
              csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.regularPrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.regularPrice + 'が不正';
              csvDataRecords[i]._cellVariants.RegularPrice = 'danger';
              isErr = true;
            } else {
              if (csvDataRecords[i].RegularPrice != csvDataRecords[i].RegularPriceMaster) {
                isNoChange = false;
              }
            }
          } else {
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.regularPrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.regularPrice + 'が不正';
            csvDataRecords[i]._cellVariants.RegularPrice = 'danger';
            isErr = true;
          }
          // 売上単価
          csvDataRecords[i].SalesUnitPriceMaster = data.sales_unit_price;
          if (getNullStr(csvDataRecords[i].SalesUnitPrice) != '' && isNaN(Number(csvDataRecords[i].SalesUnitPrice)) == false &&
            0 <= Number(csvDataRecords[i].SalesUnitPrice) && Number(csvDataRecords[i].SalesUnitPrice) <= 99999999 && Number.isInteger(Number(csvDataRecords[i].SalesUnitPrice)) == true) {
            csvDataRecords[i].SalesUnitPrice = Number(csvDataRecords[i].SalesUnitPrice);
            if (csvDataRecords[i].SalesUnitPrice != csvDataRecords[i].SalesUnitPriceMaster) {
              isNoChange = false;
            }
          } else {
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.salesUnitPrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.salesUnitPrice + 'が不正';
            csvDataRecords[i]._cellVariants.SalesUnitPrice = 'danger';
            isErr = true;
          }
          // 仕入単価
          csvDataRecords[i].PurchasePriceMaster = data.purchase_price;
          if (getNullStr(csvDataRecords[i].PurchasePrice) != '' && isNaN(Number(csvDataRecords[i].PurchasePrice)) == false &&
            0 <= Number(csvDataRecords[i].PurchasePrice) && Number(csvDataRecords[i].PurchasePrice) <= 99999999 && Number.isInteger(Number(csvDataRecords[i].PurchasePrice)) == true) {
            csvDataRecords[i].PurchasePrice = Number(csvDataRecords[i].PurchasePrice);
            if (csvDataRecords[i].PurchasePrice != csvDataRecords[i].PurchasePriceMaster) {
              isNoChange = false;
            }
          } else {
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.purchasePrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.purchasePrice + 'が不正';
            csvDataRecords[i]._cellVariants.PurchasePrice = 'danger';
            isErr = true;
          }
          // 原価単価
          csvDataRecords[i].CostPriceMaster = data.cost_price;
          if (getNullStr(csvDataRecords[i].CostPrice) != '' && isNaN(Number(csvDataRecords[i].CostPrice)) == false &&
            0 <= Number(csvDataRecords[i].CostPrice) && Number(csvDataRecords[i].CostPrice) <= 99999999 && Number.isInteger(Number(csvDataRecords[i].CostPrice)) == true) {
            csvDataRecords[i].CostPrice = Number(csvDataRecords[i].CostPrice);
            if (data.is_9A == 1 && csvDataRecords[i].CostPrice != 0) {
              // 9A製品で原価単価に0以外を設定しようとする場合はエラーとする
              csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.costPrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.costPrice + 'が不正';
              csvDataRecords[i]._cellVariants.CostPrice = 'danger';
              isErr = true;
            } else {
              if (csvDataRecords[i].CostPrice != csvDataRecords[i].CostPriceMaster) {
                isNoChange = false;
              }
            }
          } else {
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.costPrice + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.costPrice + 'が不正';
            csvDataRecords[i]._cellVariants.CostPrice = 'danger';
            isErr = true;
          }
          // 仕入先製品コード
          csvDataRecords[i].SupplierProductIdMaster = data.supplier_product_id;
          if (getNullStr(csvDataRecords[i].SupplierProductId).length <= 25) {
            if (getNullStr(csvDataRecords[i].SupplierProductId).trim() == '' &&
            getNullStr(csvDataRecords[i].SupplierProductIdMaster).trim() == '') {
              csvDataRecords[i].SupplierProductId = csvDataRecords[i].SupplierProductIdMaster;
            } else {
              if (csvDataRecords[i].SupplierProductId != csvDataRecords[i].SupplierProductIdMaster) {
                isNoChange = false;
              }
            }
          } else {
            csvDataRecords[i].SupplierProductId = getNullStr(csvDataRecords[i].SupplierProductId).substring(0, 26);
            csvDataRecords[i].ErrNaiyo = getNullStr(csvDataRecords[i].ErrNaiyo) == '' ? this.csvColName.supplierProductId + 'が不正' : csvDataRecords[i].ErrNaiyo += '、' + this.csvColName.supplierProductId + 'が不正';
            csvDataRecords[i]._cellVariants.SupplierProductId = 'danger';
            isErr = true;
          }
          if (isErr == false && isNoChange == true) {
            // 金額の変更を行っていない場合はエラーとする
            csvDataRecords[i].ErrNaiyo = '変更なし';
            isErr = true;
          }
          if (isErr == true) {
            // エラーがある場合はエラー一覧に追加
            this.checkNgList.push(csvDataRecords[i]);
          } else {
            // エラーがない場合
            if (data.is_9A == 0) {
              // 9A製品でない場合
              if (csvDataRecords[i].SalesUnitPrice < csvDataRecords[i].CostPrice) {
                // 売上単価＜原価単価の場合、警告
                csvDataRecords[i]._cellVariants = {
                  No: 'info',
                  OfficeId: 'warning',
                  ProductId: 'warning',
                  ProductName: 'warning',
                  ProductNameMaster: 'warning',
                  ProductNameKana: 'warning',
                  ProductNameKanaMaster: 'warning',
                  RegularPrice: 'warning',
                  RegularPriceMaster: 'warning',
                  SalesUnitPrice: 'warning',
                  SalesUnitPriceMaster: 'warning',
                  PurchasePrice: 'warning',
                  PurchasePriceMaster: 'warning',
                  CostPrice: 'warning',
                  CostPriceMaster: 'warning',
                  SupplierProductId: 'warning',
                  SupplierProductIdMaster: 'warning',
                };
                this.isWarning = true;
              }
            } else {
              // 9A製品の場合
              if (csvDataRecords[i].SalesUnitPrice < csvDataRecords[i].PurchasePrice) {
                // 売上単価＜仕入単価の場合、警告
                csvDataRecords[i]._cellVariants = {
                  No: 'info',
                  OfficeId: 'warning',
                  ProductId: 'warning',
                  ProductName: 'warning',
                  ProductNameMaster: 'warning',
                  ProductNameKana: 'warning',
                  ProductNameKanaMaster: 'warning',
                  RegularPrice: 'warning',
                  RegularPriceMaster: 'warning',
                  SalesUnitPrice: 'warning',
                  SalesUnitPriceMaster: 'warning',
                  PurchasePrice: 'warning',
                  PurchasePriceMaster: 'warning',
                  CostPrice: 'warning',
                  CostPriceMaster: 'warning',
                  SupplierProductId: 'warning',
                  SupplierProductIdMaster: 'warning',
                };
                this.isWarning = true;
              }
            }
            // 重複チェック
            let deplicateRow = this.checkOkList.find(el => el.OfficeId == csvDataRecords[i].OfficeId && el.ProductId == csvDataRecords[i].ProductId);
            if (deplicateRow == undefined) {
              // エラーがなく、重複していない場合は更新一覧に追加
              this.checkOkList.push(csvDataRecords[i]);
            } else {
              csvDataRecords[i].ErrNaiyo = '重複エラー';
              csvDataRecords[i]._cellVariants.OfficeId = 'danger';
              csvDataRecords[i]._cellVariants.ProductId = 'danger';
              this.checkNgList.push(csvDataRecords[i]);
            }
          }
        } else {
          csvDataRecords[i].ErrNaiyo = 'マスタエラー';
          csvDataRecords[i]._cellVariants.OfficeId = 'danger';
          csvDataRecords[i]._cellVariants.ProductId = 'danger';
          this.checkNgList.push(csvDataRecords[i]);
        }
      }
    },
    // OKNGリスト切り替え
    setOkNgList: function() {
      this.$store.commit('setLoading', true);
      this.initPaging();
      if (this.checkOkNg == 1) {
        this.fields = this.fieldsOk;
        // OKリスト選択
        this.itemList = this.checkOkList;
      } else {
        this.fields = this.fieldsNg;
        // NGリスト選択
        this.itemList = this.checkNgList;
      }
      this.checkOkNgDisp = this.checkOkNg;
      this.totalRows = this.itemList.length;
      this.$store.commit('setLoading', false);
    },
    // 一括更新ボタン押下時
    async clickBulkUpdate() {
      const functionName = 'clickBulkUpdate';
      try {
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','表示されているデータで製品情報を一括更新します。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        if (this.isWarning == true) {
          confirmMessage.push(this.$createElement('div','※売上単価が原価単価（仕入単価）よりも小さい行があります。'));
        }
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '一括更新'}) == true) {
          this.$store.commit('setLoading', true);
          await this.execSave();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // 保存実行
    async execSave() {
      const functionName = 'execSave';
      this.transactSqlList = [];
      try {
        let splitCnt = Math.trunc((this.checkOkList.length - 1) / this.limitCnt) + 1;
        //console.log('splitCnt:' + splitCnt);
        for (let i = 0; i < splitCnt ; i++) {
          // 製品マスタの更新
          await this.updateProducts(i * this.limitCnt, (i + 1) * this.limitCnt);
          // 製品詳細マスタの更新
          await this.updateProductsDetails(i * this.limitCnt, (i + 1) * this.limitCnt);
        }
        // 製品単価履歴の登録
        this.insertProductsPricesHistories();
        // 製品単価一括更新履歴の登録
        await this.insertProductsPricesBulkHistories();
        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable(0, 1);
          if (msg !== null) {
            this.alertDanger.push(msg);
            this.$store.commit('setLoading', false);
            return;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
          this.$store.commit('setLoading', false);
          return;
        }
        // 作成した登録用SQLを全実行
        await this.executeTransactSqlStatusUpdate(this.transactSqlList);
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        console.log(error);
        this.$store.commit('setLoading', false);
        return;
      }
    },
    // 製品マスタの更新SQL作成
    async updateProducts(startIndex, endIndex) {
      // 重複更新させないために最初に全製品コード配列作成（重複した場合は後優先で更新するため）
      let productIdList = [];
      let productIdDeplicatedList = [];
      for (let i = startIndex; i < endIndex && i < this.checkOkList.length; i++) {
        let productId = productIdList.find(el => el == this.checkOkList[i].ProductId);
        if (productId == undefined) {
          if (isNaN(Number(this.checkOkList[i].ProductId)) == false) {
            // 数値以外の場合は追加しない
            productIdList.push(Number(this.checkOkList[i].ProductId));
          }
        } else {
          // 既に製品一覧に入っている製品コードの場合、重複製品一覧に追加
          if (isNaN(Number(this.checkOkList[i].ProductId)) == false) {
            // 数値以外の場合は追加しない
            productIdDeplicatedList.push(Number(this.checkOkList[i].ProductId));
          }
        }
      }
      let colList = [];
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      // 製品関係の更新部分
      let strWorkField = 'FIELD(product_id';
      let strWorkProductName = '';
      let strWorkProductNameKana = '';
      let strWorkRegularPrice = '';
      let strWorkSalesUnitPrice = '';
      for (let i = startIndex; i < endIndex && i < this.checkOkList.length; i++) {
        let delProductIndex = productIdDeplicatedList.findIndex(el => el == this.checkOkList[i].ProductId);
        if (delProductIndex == -1) {
          // 製品コードリストにない場合、更新対象
          strWorkField += ',' + this.checkOkList[i].ProductId;
          // 製品名（漢字）
          strWorkProductName += ',\'' + await escapeQuote(this.checkOkList[i].ProductName) + '\'';
          // 製品名（カナ）
          strWorkProductNameKana += ',\'' + await escapeQuote(this.checkOkList[i].ProductNameKana) + '\'';
          // 定価
          strWorkRegularPrice += ',' + this.checkOkList[i].RegularPrice;
          // 売上単価
          strWorkSalesUnitPrice += ',' + this.checkOkList[i].SalesUnitPrice;
        } else {
          // 製品コードリストにある場合、更新対象とせず、製品コードリストから除外
          productIdDeplicatedList.splice(delProductIndex, 1);
        }
      }
      strWorkField += ')';
      // 製品名（漢字）
      colList.push(CreateColRow('product_name_kanji', 'ELT(' + strWorkField + strWorkProductName + ')', 'NUMBER'));
      // 製品名（カナ）
      colList.push(CreateColRow('product_name_kana', 'ELT(' + strWorkField + strWorkProductNameKana + ')', 'NUMBER'));
      // 定価
      colList.push(CreateColRow('regular_price', 'ELT(' + strWorkField + strWorkRegularPrice + ')', 'NUMBER'));
      // 売上単価
      colList.push(CreateColRow('sales_unit_price', 'ELT(' + strWorkField + strWorkSalesUnitPrice + ')', 'NUMBER'));
      // WHERE句
      let csvProductId = productIdList.join(',');
      let where_clause = ' WHERE ';
      where_clause += ' product_id IN (' + csvProductId + ') ';
      let updateSql = CreateUpdateSql(colList, 'm_products') + where_clause;
      //console.log(updateSql);
      // 更新SQL作成
      this.transactSqlList.push(updateSql);
    },
    // 製品詳細マスタの更新SQL作成
    async updateProductsDetails(startIndex, endIndex) {
      let officeIdList = [];
      for (let i = startIndex; i < endIndex && i < this.checkOkList.length; i++) {
        let officeId = officeIdList.find(el => el == this.checkOkList[i].OfficeId);
        if (officeId == undefined) {
          if (isNaN(Number(this.checkOkList[i].OfficeId)) == false) {
            // 数値以外の場合は追加しない
            officeIdList.push(Number(this.checkOkList[i].OfficeId));
          }
        }
      }
      // 営業所毎に全製品をまとめて更新
      for (let j = 0; j < officeIdList.length; j++) {
        let colList = [];
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        // 製品関係の更新部分
        let strWorkField = 'FIELD(product_id';
        let strWorkPurchasePrice = '';
        let strWorkCostPrice = '';
        let strWorkSupplierProductId = '';
        let productIdList = [];
        for (let i = startIndex; i < endIndex && i < this.checkOkList.length; i++) {
          if (officeIdList[j] == this.checkOkList[i].OfficeId) {
            let productId = productIdList.find(el => el == this.checkOkList[i].ProductId);
            if (productId == undefined) {
              if (isNaN(Number(this.checkOkList[i].ProductId)) == false) {
                // 数値以外の場合は追加しない
                productIdList.push(Number(this.checkOkList[i].ProductId));
              }
            }
            strWorkField += ',' + this.checkOkList[i].ProductId;
            // 仕入単価
            strWorkPurchasePrice += ',' + this.checkOkList[i].PurchasePrice;
            // 原価単価
            strWorkCostPrice += ',' + this.checkOkList[i].CostPrice;
            // 仕入先製品コード
            if (this.checkOkList[i].SupplierProductId == null) {
              strWorkSupplierProductId += ',NULL';
            } else {
              strWorkSupplierProductId += ',\'' + await escapeQuote(this.checkOkList[i].SupplierProductId) + '\'';
            }
          }
        }
        strWorkField += ')';
        // 仕入単価
        colList.push(CreateColRow('purchase_price', 'ELT(' + strWorkField + strWorkPurchasePrice + ')', 'NUMBER'));
        // 原価単価
        colList.push(CreateColRow('cost_price', 'ELT(' + strWorkField + strWorkCostPrice + ')', 'NUMBER'));
        // 仕入先製品コード
        colList.push(CreateColRow('supplier_product_id', 'ELT(' + strWorkField + strWorkSupplierProductId + ')', 'NUMBER'));
        // WHERE句
        let csvProductId = productIdList.join(',');
        let where_clause = ' WHERE ';
        where_clause += ' product_id IN (' + csvProductId + ') ';
        where_clause += 'AND office_id = ' + officeIdList[j] + ' ';
        let updateSql = CreateUpdateSql(colList, 'm_products_details') + where_clause;
        //console.log(updateSql);
        // 更新SQL作成
        this.transactSqlList.push(updateSql);
      }
    },
    // 製品単価履歴の登録
    insertProductsPricesHistories: function() {
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      // 現在日付を「yyyy/mm/dd」の形式で作成
      let strToday = formatCurDate();
      for (let i = 0; i < this.checkOkList.length; i++) {
        if (this.checkOkList[i].PurchasePrice != this.checkOkList[i].PurchasePriceMaster) {
          // 仕入単価を変更した行のみを登録
          let colList = [];
          // 製品コード
          colList.push(CreateColRow('product_id', this.checkOkList[i].ProductId, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', this.checkOkList[i].OfficeId, 'NUMBER'));
          // 製品単価修正日時
          colList.push(CreateColRow('price_update_datetime', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 仕入単価
          colList.push(CreateColRow('purchase_price', this.checkOkList[i].PurchasePrice, 'NUMBER'));
          // 摘要
          colList.push(CreateColRow('summary', strToday, 'VARCHAR'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_products_prices_histories (' + CreateInsertSql(colList, 'col', 't_products_prices_histories') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_products_prices_histories') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            this.transactSqlList.push(bulkInsertSql);
            bulkInsertSql = '';
          }
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql)
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // 製品単価一括更新履歴の登録
    async insertProductsPricesBulkHistories() {
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      for (let i = 0; i < this.checkOkList.length; i++) {
        let colList = [];
        // 製品コード
        colList.push(CreateColRow('product_id', this.checkOkList[i].ProductId, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.checkOkList[i].OfficeId, 'NUMBER'));
        // 製品単価修正日時
        colList.push(CreateColRow('price_update_datetime', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 製品名（漢字）
        colList.push(CreateColRow('product_name_kanji', await escapeQuote(this.checkOkList[i].ProductName), 'VARCHAR'));
        // 製品名（漢字）（更新前）
        colList.push(CreateColRow('product_name_kanji_old', await escapeQuote(this.checkOkList[i].ProductNameMaster), 'VARCHAR'));
        // 製品名（カナ）
        colList.push(CreateColRow('product_name_kana', await escapeQuote(this.checkOkList[i].ProductNameKana), 'VARCHAR'));
        // 製品名（カナ）（更新前）
        colList.push(CreateColRow('product_name_kana_old', await escapeQuote(this.checkOkList[i].ProductNameKanaMaster), 'VARCHAR'));
        // 定価
        colList.push(CreateColRow('regular_price', this.checkOkList[i].RegularPrice, 'NUMBER'));
        // 定価（更新前）
        colList.push(CreateColRow('regular_price_old', this.checkOkList[i].RegularPriceMaster, 'NUMBER'));
        // 売上単価
        colList.push(CreateColRow('sales_unit_price', this.checkOkList[i].SalesUnitPrice, 'NUMBER'));
        // 売上単価（更新前）
        colList.push(CreateColRow('sales_unit_price_old', this.checkOkList[i].SalesUnitPriceMaster, 'NUMBER'));
        // 仕入単価
        colList.push(CreateColRow('purchase_price', this.checkOkList[i].PurchasePrice, 'NUMBER'));
        // 仕入単価（更新前）
        colList.push(CreateColRow('purchase_price_old', this.checkOkList[i].PurchasePriceMaster, 'NUMBER'));
        // 原価単価
        colList.push(CreateColRow('cost_price', this.checkOkList[i].CostPrice, 'NUMBER'));
        // 原価単価（更新前）
        colList.push(CreateColRow('cost_price_old', this.checkOkList[i].CostPriceMaster, 'NUMBER'));
        // 仕入先製品コード
        colList.push(CreateColRow('supplier_product_id', await escapeQuote(this.checkOkList[i].SupplierProductId), 'VARCHAR'));
        // 仕入先製品コード（更新前）
        colList.push(CreateColRow('supplier_product_id_old', await escapeQuote(this.checkOkList[i].SupplierProductIdMaster), 'VARCHAR'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_products_prices_bulk_histories (' + CreateInsertSql(colList, 'col', 't_products_prices_bulk_histories') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_products_prices_bulk_histories') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql)
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // SQL一覧を更新
    async executeTransactSqlStatusUpdate(sqls) {
      const functionName = 'executeTransactSqlStatusUpdate';

      let subscription = null;
      try {
        // SQL実行をリクエスト
        const processId = await requestTransactSqlsAsync(TRANSACT_SQLS_REQUEST_TYPE.SAVE_SQLS_ON_S3, sqls);

        // TransactSqlStatusテーブルを監視する。
        subscription = API.graphql(
          graphqlOperation(onUpdateTransactSqlStatus, { ProcessID: processId })
        ).subscribe({
          next: async ({ value }) => {
            const result = await onTransactSqlStatusUpdate(value?.data?.onUpdateTransactSqlStatus ?? null, subscription);

            if(result === undefined) return;
            if(result) {
              // 表示中の情報がある場合、初期化
              this.fileDataInit();
              this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1003']);
            } else {
              // エラーメッセージを表示
              this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
            }
            scrollTo(0,0);
            this.$store.commit('setLoading', false);
          }
        });
      } catch (error) {
        // subscription中の予期せぬエラー対策
        subscription?.unsubscribe();

        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'onUpdateTransactSqlStatus',
          message: '売価・仕入単価一括更新中にエラーが発生しました。',
          SQLs: sqls,
        }, error);
        // エラーメッセージを表示
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    // 製品一括更新状態の変更
    async changeProductsBulkUpdateState() {
      const functionName = 'changeProductsBulkUpdateState';
      try {
        // 現在処理年月取得
        let controlData = await getControlMaster();
        let productsBulkUpdateState = controlData.products_bulk_update_state;
        if (this.controlMasterData.productsBulkUpdateState == productsBulkUpdateState) {
          let confirmMessage = '';
          let title = '';
          let changeBulkUpdateState = null;
          // コントロールマスタの製品一括更新状態を変更
          if (productsBulkUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
            confirmMessage = '売価・仕入単価一括更新に関係のない画面の登録・更新・削除の制限を解除します。';
            confirmMessage += 'よろしいですか？';
            title = '売価・仕入単価一括更新終了';
            changeBulkUpdateState = Const.ClosingUpdateStateClass.NOT_RUNNING;
          } else {
            confirmMessage = '売価・仕入単価一括更新に関係のない画面の登録・更新・削除を制限します。';
            confirmMessage += 'よろしいですか？';
            title = '売価・仕入単価一括更新開始';
            changeBulkUpdateState = Const.ClosingUpdateStateClass.RUNNING;
          }
          if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
            this.$store.commit('setLoading', true);
            await this.execProductsBulkUpdateStateChange(changeBulkUpdateState);
          }
        } else {
          this.controlMasterData.productsBulkUpdateState = productsBulkUpdateState;
        }
        // ヘッダー部分を再描画
        if (this.resetKey != 0) {
          this.resetKey = 0;
        } else {
          this.resetKey++;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 締更新状態変更処理実行
    async execProductsBulkUpdateStateChange(productsBulkUpdateState) {
      const functionName = 'execProductsBulkUpdateStateChange';
      this.transactSqlList = [];
      // コントロールマスタ更新SQL作成
      await this.updateControlMaster(productsBulkUpdateState);
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(0, 1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 作成した登録用SQLを全実行
      let retResult = await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.controlMasterData.productsBulkUpdateState = productsBulkUpdateState;
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    // コントロールマスタ更新SQL作成
    async updateControlMaster(productsBulkUpdateState) {
      //console.log('コントロールマスタ更新SQL作成');
      let colList = [];
      // 製品一括更新状態
      colList.push(CreateColRow('products_bulk_update_state', productsBulkUpdateState, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      this.transactSqlList.push(CreateUpdateSql(colList, 'm_control'));
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>