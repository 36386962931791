<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> 販売管理</h5>
                            </div>
                        </div>
                    </div>

                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;" 
                            v-shortkey="['1']" @shortkey="executeShortcut('menu-sales-daily')">
                            <router-link to="/menu-sales-daily"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="1" id="menu1-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">日次処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>日毎の仕入、入金、支払、入出庫、売上を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['2']" @shortkey="executeShortcut('menu-sales-claim')">
                            <router-link to="/menu-sales-claim"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="2" id="menu1-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">請求締次処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>請求書の発行や請求締更新を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort2" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['3']" @shortkey="executeShortcut('menu-sales-pay')">
                            <router-link to="/menu-sales-pay"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="3" id="menu1-3">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">支払締次処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>仕入先元帳の参照や支払締更新を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['4']" @shortkey="executeShortcut('menu-sales-monthly')">
                            <router-link to="/menu-sales-monthly"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="4" id="menu1-4">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">月次処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>月毎の集計と締めの作業、集計結果の参照を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['5']" @shortkey="executeShortcut('menu-sales-ordership')">
                            <router-link to="/menu-sales-ordership"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="5"  id="menu1-5">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">受注・出荷処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>受注データの登録、受注データの出荷確定、伝票発行を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['6']" @shortkey="executeShortcut('menu-sales-orderstock')">
                            <router-link to="/menu-sales-orderstock"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="6" id="menu1-6">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">発注・入荷処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>発注データの登録、発注書発行、入荷データの登録、入荷確定を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['7']" @shortkey="executeShortcut('menu-sales-query')">
                            <router-link to="/menu-sales-query"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="7" id="menu1-7">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">7</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">QUERYメニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>得意先別、担当者別、製品別、9A製品別の売上額/受注額の参照を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['8']" @shortkey="executeShortcut('menu-sales-inquiry')">
                            <router-link to="/menu-sales-inquiry"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="8" id="menu1-8">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">8</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">問い合せ処理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>受発注、入出荷、在庫、売上伝票、担当者実績、得意先元帳の照会を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['9']" @shortkey="executeShortcut('menu-master-maintenance')">
                            <router-link to="/menu-master-maintenance"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="9" id="menu1-9">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">9</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">マスタ保守</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>各種マスタのメンテナンスを行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-4">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●2件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-calculator"></span> 見積管理</h5>
                            </div>
                        </div>
                    </div>
                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0 " class="row">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['alt', '1']" @shortkey="executeShortcut('menu-sales-estimate')">
                            <router-link to="/menu-sales-estimate"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="alt+1" id="menu2-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">ALT+1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">見積管理メニュー</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>見積書や見積形式の発注書の発行を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <!-- ●●●コンテンツ●●● -->
        <div class="mt-5">
          <!-- ●●●メインコンテンツ●●● -->
          <div id="content-menu" class="my-2 mx-0" role="main">
            <!-- ●●●3件目●●● -->
            <section class="" id="menu-1">
              <!-- ●●●メニュータイトル●●● -->
              <div class="user-header alt">
                  <div class="media">
                      <div class="media-body pb-3">
                          <h5 class="text-secondary m-0"><span class="oi oi-task"></span> その他</h5>
                      </div>
                  </div>
              </div>
              <!-- ●●●メニュー●●● -->
              <div id="collapse-menu0 " class="row">
                  <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                      style="border-radius:0;"
                      v-shortkey="['ctrl', 'alt', '1']" @shortkey="executeShortcut('menu-inventory')">
                      <router-link to="/menu-inventory"
                        class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                          data-id="ctrl+alt+1" id="menu3-1">
                          <div class="d-flex w-100 p-0">
                              <i class="fa stct-pci">Ctrl+ALT+1</i>
                              <h6 class="d-inline-block mr-auto" style="min-width: 110px;">棚卸メニュー</h6>
                          </div>
                          <div class="d-flex w-100 p-0">
                              <ul>
                                  <li>棚卸業務を行います。</li>
                              </ul>
                          </div>
                      </router-link>
                  </div>
                  <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                      style="border-radius:0;"
                      v-shortkey="['ctrl', 'alt', '2']" @shortkey="executeShortcut('inquiry-report-maintenance')">
                      <router-link to="/inquiry-report-maintenance"
                        class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                          data-id="ctrl+alt+2" id="menu3-2">
                          <div class="d-flex w-100 p-0">
                              <i class="fa stct-pci">Ctrl+ALT+2</i>
                              <h6 class="d-inline-block mr-auto" style="min-width: 110px;">照会レポート</h6>
                          </div>
                          <div class="d-flex w-100 p-0">
                              <ul>
                                  <li>照会定義の作成、照会レポートの参照／CSVファイル出力を行います。</li>
                              </ul>
                          </div>
                      </router-link>
                  </div>
                  <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                      style="border-radius:0;"
                      v-shortkey="['ctrl', 'alt', '3']" @shortkey="executeShortcut('menu-csv-import')">
                      <router-link to="/menu-csv-import"
                        class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                          data-id="ctrl+alt+3" id="menu3-2">
                          <div class="d-flex w-100 p-0">
                              <i class="fa stct-pci">Ctrl+ALT+3</i>
                              <h6 class="d-inline-block mr-auto" style="min-width: 110px;">CSVファイルインポートメニュー</h6>
                          </div>
                          <div class="d-flex w-100 p-0">
                              <ul>
                                  <li>CSVインポートを行います。</li>
                              </ul>
                          </div>
                      </router-link>
                  </div>
              </div>
            </section>
          </div>
        </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: 'メインメニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path) {
      // パスを受け取って遷移
      console.log('shortcut');
      this.$router.push(`/${path}`);
    },
  },
}
</script>
<style scoped>
  ul {
    padding-left: 1.5rem;
  }

  /* カードリスト */
  /*リストの高さ調整  */
  .menu-display {
    display: flex;
  }

  /*リスト*/
  .list-group-item {
    border-radius: 0.5rem !important;
    position: relative;
  }

  .list-group-item .menu-arw {
    position: absolute;
    right: 16px;
    top: 45%;
  }

  /* リストの先頭 */
  .list-group-item:first-child {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  /* リストの最後 */
  .list-group-item:last-child {
    margin-right: -1px !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .list-group-item div {
    align-self: center;
  }

  .list-group-item div span {
    color: var(--primary);
  }

  .list-group-item div h6 {
    font-size: 20px;
  }
</style>