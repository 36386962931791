<template>
    <div>
        <!-- ●●●上部メニュー●●● -->
        <Header :type="menu_type" :title="title" />
        <div class="container-fluid px-4 py-4 min-vh-85">
        <!-- ●●●コンテンツ●●● -->
        <div class="mt-0">
            <!-- ●●●メインコンテンツ●●● -->
            <div id="content-menu" class="my-2 mx-0" role="main">
                <!-- ●●●1件目●●● -->
                <section class="" id="menu-1">
                    <!-- ●●●メニュータイトル●●● -->
                    <div class="user-header alt bg-none">
                        <div class="media">
                            <div class="media-body pb-3">
                                <h5 class="text-secondary m-0"><span class="oi oi-cart"></span> 発注・入荷処理メニュー</h5>
                            </div>
                        </div>
                    </div>
                    <!-- ●●●メニュー●●● -->
                    <div id="collapse-menu0" class="row collapse show">
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['1']" @shortkey="executeShortcut('order-slip-print', 1)">
                            <router-link :to="{ path: `/order-slip-print`, query: { menuId: 1 }}"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="1" id="menu1-1">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">1</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">発注書発行</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>受注入力されたデータで、発注書を発行します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['2']" @shortkey="executeShortcut('order-slip-print', 2)">
                            <router-link :to="{ path: `/order-slip-print`, query: { menuId: 2 }}"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="2" id="menu1-2">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">2</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">発注書発行（入力分）</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>発注入力されたデータで、発注書を発行します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['3']" @shortkey="executeShortcut('order-slip-print', 3)">
                            <router-link :to="{ path: `/order-slip-print`, query: { menuId: 3 }}"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="3" id="menu1-3">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">3</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">発注書発行（特別売上分）</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>特別発注されたデータで、発注書を発行します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort1" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['4']" @shortkey="executeShortcut('order-stock-list')">
                            <router-link to="/order-stock-list" 
                                class="list-group-item list-group-item-action align-items-start p-3 mb-2"
                                data-id="4" id="menu1-4">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">4</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">入荷 一覧/入力/修正</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>発行した発注書に対して入荷された製品の入力、または、入力に対する修正を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort0" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;flex-wrap:wrap;"
                            v-shortkey="['5']" @shortkey="executeShortcut('order-list')">
                            <router-link to="/order-list"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="5" id="menu1-5">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">5</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">発注 一覧/入力/修正/削除</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>発注書の手入力分の入力、または、発注書発行によって発行された発注書の修正・削除を行います。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                        <div id="sort3" class="col-md-6 justify-content-center align-content-stretch"
                            style="border-radius:0;"
                            v-shortkey="['6']" @shortkey="executeShortcut('order-stock-confirm-input')">
                            <router-link to="/order-stock-confirm-input"
                                class="list-group-item list-group-item-action flex-fill flex-column align-items-start p-3 mb-2"
                                data-id="6" id="menu1-6">
                                <div class="d-flex w-100 p-0">
                                    <i class="fa stct-pci">6</i>
                                    <h6 class="d-inline-block mr-auto" style="min-width: 110px;">入荷確定入力</h6>
                                </div>
                                <div class="d-flex w-100 p-0">
                                    <ul>
                                        <li>入荷入力されたデータを確定します。</li>
                                    </ul>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import {init} from '@/assets/js/common.js'
export default {
  name: 'MENU-SALES-ORDERSTOCK',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注・入荷処理メニュー',
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods: {
    // ショートカットキー押下時処理
    executeShortcut(path, menuId=null) {
      // パスを受け取って遷移
      console.log('shortcut');
      // 発注書発行の場合、メニューIDをURLに付与
      if (menuId) {
        this.$router.push({
          name: 'ORDER-SLIP-PRINT',
          query: {
            menuId: menuId,
          }
        });
      } else {
        this.$router.push(`/${path}`);
      }
    },
  },
}
</script>