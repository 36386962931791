<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="kokyaku in listKokyaku" v-bind:key="kokyaku.clientId">
      <div v-for="chouhyou in kokyaku.listChouhyou" v-bind:key="kokyaku.clientId + '_' + chouhyou.page">
        <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
          <TemplateCustomerLedgerStart :id="constData.chouhyouId + kokyaku.clientId + '_' + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
          <TemplateCustomerLedgerEnd :id="constData.chouhyouId + kokyaku.clientId + '_' + chouhyou.page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateCustomerLedgerStart from '@/assets/svg/customerLedger_Start.svg';
import TemplateCustomerLedgerEnd from '@/assets/svg/customerLedger_End.svg';
import { setPaperA4Landscape, setChouhyouBodyStyle, addOperationLogs, getNullStr, formatDate, formatCurDate, getListValue, getControlMaster, executeSelectSql, executeSelectSqlNoLimit, getClosingDate, dateConsistency } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_t_closing_update_range } from '@/graphql/queries';

const MODULE_NAME = 'customer-ledger';

export default {
  name: 'CUSTOMER-LEDGER',
  /* コンポーネント */
  components: {
    TemplateCustomerLedgerStart,
    TemplateCustomerLedgerEnd,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 40,
        cntEnd: 44,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
        totalRowTitle: '<得意先合計>',
        forwardBalanceRowTitle: '<　請求残高　>',
        totalLineX: 83,
        totalLineWidth: 2360,
        totalLineHeight: 28,
        forwardBalanceRowCnt: 3,
      },
      // ヘッダ
      menu_type: 'user',
      title: '得意先元帳（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '445mm',
        height: '314mm',
      },
      listKokyaku: [],
      replacementsCommon: [],
      lineY: 0,
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタ
      controlMasterData: {
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // アラート
      alertWarning: [],
      alertDanger: [],
      // パラメータ
      billingMonthYear: null,
      clientIdStart: null,
      clientIdEnd: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
        this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.billingMonthYear = this.$route.query.billingMonthYear;
    this.clientIdStart = this.$route.query.clientIdStart;
    this.clientIdEnd = this.$route.query.clientIdEnd;
    // 印刷レイアウト設定
    setPaperA4Landscape();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // テーブルからデータを取得して、設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '得意先元帳_' + this.billingMonthYear;
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('timerAll');
        // 表示データ設定
        //console.time('timerDisp');
        await this.setDispData();
        //console.timeEnd('timerDisp');
        //console.timeEnd('timerAll');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 表示データ設定 */
    async setDispData() {
      // 請求日（開始日と終了日）の取得
      await this.getBillingDateInfo();
      //console.time('timerSql1');
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.timeEnd('timerSql1');
      //console.time('timerSql2');
      // 検索合計SQL作成
      let selectTotalSql = '';
      selectTotalSql = this.makeSelectSqlTotal();
      //console.log(selectTotalSql);
      //console.timeEnd('timerSql2');
      //console.time('timerSelect2');
      let dataResultTotal = await executeSelectSql(selectTotalSql);
      //console.timeEnd('timerSelect2');
      //console.log(dataResultTotal);
      if (dataResultTotal == null) {
        dataResultTotal = [];
      }
      await this.setTotalData(dataResultTotal);
      if (dataResultTotal != null && dataResultTotal.length > 0) {
        if (dataResultTotal.length > Const.PdfCustomerLedgerClientMaxCnt) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2049'].replace('%arg1%', Const.PdfCustomerLedgerClientMaxCnt));
          return;
        }
        //console.time('timerSelect1');
        let dataResult = await executeSelectSqlNoLimit(selectSql);
        //console.timeEnd('timerSelect1');
        //console.log(dataResult);
        // 取引先毎の一覧を作成
        //console.time('timer1');
        this.initListKokyaku(dataResultTotal);
        //console.timeEnd('timer1');
        // 請求残高設定
        await this.setForwardBalances();
        // 共通の置換文字列設定
        // 現在日時
        this.replacementsCommon.push({key: '%現在日時%', value: formatCurDate('YYYY/MM/DD HH:mm'), textAnchor: 'start', textLength: 270, chkWidth: false});
        // ページ毎の置換文字列設定
        //console.time('timer2');
        await this.createReplacementsPage(dataResult);
        //console.timeEnd('timer2');
        // ページ部分の置換文字列追加
        //console.time('timer3');
        this.addReplacementsPage();
        //console.timeEnd('timer3');
        // 作成した置換文字データをSVGファイルに設定
        //console.time('timer4');
        this.setChouhyou();
        //console.timeEnd('timer4');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','得意先元帳'));
        return;
      }
    },
    /* 検索SELECT文字列作成 */
    makeSelectSql: function(){
      /* 請求締更新前のデータ */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',cumulative_transaction.transaction_id';
      selectSql1 += ',cumulative_transaction.billing_no';
      selectSql1 += ',cumulative_transaction.billing_row';
      selectSql1 += ',cumulative_transaction.billing_date';
      selectSql1 += ',billings.site_id';
      selectSql1 += ',cumulative_transaction.shipping_code';
      selectSql1 += ',cumulative_transaction.client_site_name';
      selectSql1 += ',cumulative_transaction.product_id';
      selectSql1 += ',cumulative_transaction.product_name';
      selectSql1 += ',products.product_tax_rate_class_sales';
      selectSql1 += ',cumulative_transaction.product_quantity';
      selectSql1 += ',cumulative_transaction.product_unit';
      selectSql1 += ',cumulative_transaction.product_sales_unit_price';
      selectSql1 += ',cumulative_transaction.product_sales_unit_amount';
      selectSql1 += ',cumulative_transaction.product_tax';
      selectSql1 += ',cumulative_transaction.kind';
      selectSql1 += ',cumulative_transaction.account_support';
      selectSql1 += ',cumulative_transaction.amount';
      selectSql1 += ',cumulative_transaction.settlement_scheduled';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = clients.client_id ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 請求年月
      selectSql1 += ' CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED) = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql1 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql1 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 製品金額、金額、消費税のいずれかが0以外のデータのみを表示
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      // 請求締更新をしていないデータ
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      // トランザクションID（「UR:売上」「NK:入金」）
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      // 消費税行は除外
      selectSql1 += 'AND (cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' OR cumulative_transaction.product_id <> 0) ';

      /* 請求締更新後のデータ */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' billings_balances.client_id';
      selectSql2 += ',cumulative_transaction.transaction_id';
      selectSql2 += ',cumulative_transaction.billing_no';
      selectSql2 += ',cumulative_transaction.billing_row';
      selectSql2 += ',cumulative_transaction.billing_date';
      selectSql2 += ',cumulative_transaction.site_id';
      selectSql2 += ',cumulative_transaction.shipping_code';
      selectSql2 += ',cumulative_transaction.client_site_name';
      selectSql2 += ',cumulative_transaction.product_id';
      selectSql2 += ',cumulative_transaction.product_name';
      selectSql2 += ',products.product_tax_rate_class_sales';
      selectSql2 += ',cumulative_transaction.product_quantity';
      selectSql2 += ',cumulative_transaction.product_unit';
      selectSql2 += ',cumulative_transaction.product_sales_unit_price';
      selectSql2 += ',cumulative_transaction.product_sales_unit_amount';
      selectSql2 += ',cumulative_transaction.product_tax';
      selectSql2 += ',cumulative_transaction.kind';
      selectSql2 += ',cumulative_transaction.account_support';
      selectSql2 += ',cumulative_transaction.amount';
      selectSql2 += ',cumulative_transaction.settlement_scheduled';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings_balances.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings_balances.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND billings_balances.billing_month_year = cumulative_transaction.closing_month_year ';
      selectSql2 += 'INNER JOIN m_clients AS clients ';
      selectSql2 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings_balances.client_id = clients.client_id ';
      selectSql2 += 'LEFT JOIN m_products AS products ';
      selectSql2 += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      // 請求年月
      selectSql2 += ' cumulative_transaction.closing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql2 += 'AND billings_balances.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql2 += 'AND billings_balances.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql2 += 'AND billings_balances.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 製品金額、金額、消費税のいずれかが0以外のデータのみを表示
      selectSql2 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      // 請求締更新済みのデータ
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 1 ';
      // トランザクションID（「UR:売上」「NK:入金」）
      selectSql2 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      // 消費税行は除外
      selectSql2 += 'AND (cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' OR cumulative_transaction.product_id <> 0) ';

      // 2つのSELECT文をUNION
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
        /* ORDER BY句 */
        selectSql += 'ORDER BY client_id,billing_date,transaction_id DESC,billing_no,billing_row';
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
        /* ORDER BY句 */
        selectSql += 'ORDER BY billings_balances.client_id,cumulative_transaction.billing_date,cumulative_transaction.transaction_id DESC,cumulative_transaction.billing_no,cumulative_transaction.billing_row';
      }

      return selectSql;
    },
    /* 検索SELECT文字列作成（取引先別合計用） */
    makeSelectSqlTotal: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.billing_end_date';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',billings_QUERY.pre_billing_no_tax_balance';
      selectSql += ',billings_QUERY.pre_billing_tax_balance';
      selectSql += ',billings_QUERY.amount_no_tax';
      selectSql += ',billings_QUERY.amount_tax';
      selectSql += ',billings_QUERY.closing_date_flg';
      selectSql += ',IfNull(billings_QUERY.payment_scheduled,';
      selectSql += '   CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '     THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '     ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   END';
      selectSql += ' ) AS payment_scheduled';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.business_class';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainTotalQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings_QUERY.client_id ';

      return selectSql;
    },
    // 合計メインクエリ作成
    makeMainTotalQuery: function() {
      /* 請求締更新前の処理月情報あり、または、なし、当月情報あり（取引先別） */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.billing_end_date';
      selectSql1 += ',0 AS pre_billing_no_tax_balance';
      selectSql1 += ',0 AS pre_billing_tax_balance';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql1 += ',NULL AS payment_scheduled';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql1 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql1 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外（入金は除外しない）
      selectSql1 += 'AND (billings_issue_input_billing_no.billing_no IS NULL OR cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\') ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      /* 当月情報あり（現場別） */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings_site_QUERY.client_id';
      selectSql2 += ',\'' + this.preBilling.billingEndDate + '\' AS billing_end_date';
      selectSql2 += ',0 AS pre_billing_no_tax_balance';
      selectSql2 += ',0 AS pre_billing_tax_balance';
      selectSql2 += ',SUM(billings_site_QUERY.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql2 += ',SUM(billings_site_QUERY.amount_no_tax) AS amount_no_tax';
      selectSql2 += ',SUM(billings_site_QUERY.amount_tax) AS amount_tax';
      selectSql2 += ',NULL AS payment_scheduled';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax) AS closing_date_light_tax';
      selectSql2 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += '(' + this.makeSelectSqlSiteQuery() + ') AS billings_site_QUERY ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_site_QUERY.client_id';
      /* 請求締更新後 */
      let selectSql4 = '';
      /* SELECT句 */
      selectSql4 += 'SELECT ';
      selectSql4 += ' billings_balances.client_id';
      selectSql4 += ',billings_balances.billing_end_date';
      selectSql4 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql4 += ',billings_balances.pre_billing_tax_balance';
      selectSql4 += ',billings_balances.closing_date_receivable_sales AS product_sales_unit_amount';
      selectSql4 += ',billings_balances.closing_date_payment AS amount_no_tax';
      selectSql4 += ',billings_balances.closing_date_tax_payment AS amount_tax';
      selectSql4 += ',billings_balances.payment_scheduled';
      selectSql4 += ',billings_balances.closing_date_normal_tax_subtotal';
      selectSql4 += ',billings_balances.closing_date_normal_tax';
      selectSql4 += ',billings_balances.closing_date_light_tax_subtotal';
      selectSql4 += ',billings_balances.closing_date_light_tax';
      selectSql4 += ',0 AS closing_date_flg ';
      /* FROM句 */
      selectSql4 += ' FROM ';
      selectSql4 += 't_billings_balances AS billings_balances ';
      /* WHERE句 */
      selectSql4 += ' WHERE ';
      selectSql4 += '(billings_balances.closing_date_receivable_sales <> 0 OR billings_balances.closing_date_tax <> 0 OR billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.closing_date_payment <> 0 OR billings_balances.closing_date_tax_payment <> 0) ';
      // 請求年月
      selectSql4 += 'AND billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql4 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql4 += 'AND billings_balances.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql4 += ' GROUP BY ';
      selectSql4 += ' billings_balances.client_id';

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 3つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql4;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql4;
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（現場別クエリ）
    makeSelectSqlSiteQuery: function() {
      // 現場別取引先の売上情報
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',0 AS amount_no_tax';
      selectSql1 += ',0 AS amount_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql1 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if (this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if (this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
      selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      // 現場別取引先の入金情報
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings.client_id';
      selectSql2 += ',0 AS product_sales_unit_amount';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql2 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql2 += ',0 AS closing_date_normal_tax';
      selectSql2 += ',0 AS closing_date_light_tax_subtotal';
      selectSql2 += ',0 AS closing_date_light_tax';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 'm_billings AS billings ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND (cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql2 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql2 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql2 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql2 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 現場コード
      selectSql2 += 'AND billings.site_id = 0 ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings.client_id';
      let selectSql = '';
      // 2つのSELECT文をUNION
      selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 顧客毎の一覧を作成 */
    initListKokyaku: function(result) {
      //console.log('initListKokyaku');
      let chkWidth = false;
      // DBの結果分ループ
      for (let i = 0; i < result.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // タイトル
        replacements.push({key: '%タイトル%', value: '得　意　先　元　帳　（　' + formatDate(this.billingMonthYear + '-01', 'YYYY/MM') + '　）', textAnchor: 'middle', textLength: 900, chkWidth: false});
        // 得意先コード
        replacements.push({key: '%得CD%', value: result[i].client_id, textAnchor: 'start', textLength: 140, chkWidth: false});
        // 得意先名
        if (getNullStr(result[i].client_name_kanji).length > 14) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%得名%', value: result[i].client_name_kanji, textAnchor: 'start', textLength: 430, chkWidth: chkWidth});
        // 締日
        replacements.push({key: '%締日%', value: formatDate(result[i].billing_end_date), textAnchor: 'middle', textLength: 220, chkWidth: false});
        // 入金予定
        replacements.push({key: '%入金予定%', value: formatDate(result[i].payment_scheduled), textAnchor: 'middle', textLength: 220, chkWidth: false});
        // 金種
        replacements.push({key: '%金種%', value: getNullStr(result[i].business_class) + '（　' + getNullStr(getListValue(Const.businessClassList, result[i].business_class.toString())) + '　）', textAnchor: 'start', textLength: 220, chkWidth: false});
        // 担当者コード
        replacements.push({key: '%担CD%', value: result[i].staff_id, textAnchor: 'start', textLength: 140, chkWidth: false});
        // 担当者名
        if (getNullStr(result[i].staff_name_kanji).length > 14) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%担当名%', value: result[i].staff_name_kanji, textAnchor: 'start', textLength: 400, chkWidth: chkWidth});
        // 繰越税抜
        if (getNullStr(result[i].pre_billing_no_tax_balance).length > 9) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%繰越額%', value: Number(result[i].pre_billing_no_tax_balance).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // 繰越消費税
        if (getNullStr(result[i].pre_billing_tax_balance).length > 9) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%繰越税%', value: Number(result[i].pre_billing_tax_balance).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        let listChouhyou = [];
        listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: replacements});
        this.listKokyaku.push({ clientId: result[i].client_id, clientNameKanji: result[i].client_name_kanji, totalData: result[i], listChouhyou: listChouhyou, billingEndDate: result[i].billing_end_date, nextForward: Number(result[i].pre_billing_no_tax_balance) + Number(result[i].pre_billing_tax_balance), forwardBalancesList: [], remainingsAmount: Number(result[i].amount_no_tax), remainingsAmountTax: Number(result[i].amount_tax) });
      }
    },
    // 請求残高取得
    async setForwardBalances() {
      //console.log('setForwardBalances');
      // 請求残高を表示する取引先コードCSVを作成
      let csvClientId = '';
      for (let i = 0; i < this.listKokyaku.length; i++) {
        if (this.listKokyaku[i].totalData.closing_date_flg == 1) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += this.listKokyaku[i].clientId;
        }
      }
      //console.log(csvClientId);
      if (csvClientId != '') {
        // 得意先請求残データ取得
        let selectSql = '';
        /* SELECT句 */
        selectSql += 'SELECT';
        selectSql += ' client_id';
        selectSql += ',billing_date';
        selectSql += ',billing_amount';
        selectSql += ',billing_tax';
        selectSql += ' FROM ';
        selectSql += 't_billings_remainings ';
        selectSql += ' WHERE ';
        selectSql += 'client_id IN (' + csvClientId + ') ';
        selectSql += 'AND (billing_amount <> 0 OR billing_tax <> 0)';
        selectSql += 'ORDER BY client_id,billing_date ';
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if (dataResult != null && dataResult.length > 0) {
          let index = 0;
          for (let i = 0; i < this.listKokyaku.length; i++) {
            for (; index < dataResult.length; index++) {
              //console.log(index);
              //console.log(this.listKokyaku[i].clientId);
              //console.log(dataResult[index].client_id);
              if (this.listKokyaku[i].clientId == dataResult[index].client_id) {
                this.listKokyaku[i].forwardBalancesList.push(dataResult[index]);
                //console.log(this.listKokyaku[i].forwardBalancesList);
              } else {
                break;
              }
            }
          }
          //console.log(index);
        }
        //console.log(this.listKokyaku);
      }
    },
    /* 顧客毎の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let replacements;
      let index = 0;
      let preTranctionId = '';
      let preBillingNo = 0;
      let billingFlg = true;
      let page = 0;
      let totalBalance = 0;
      let totalBalanceTax = 0;
      let totalMoney = 0;
      let totalDeposit = 0;
      let totalDepositTax = 0;
      let totalFlg1 = false;
      let totalFlg2 = false;
      let forwardBalanceRow = 0;
      let chkWidth = false;
      let strWork = '';
      for (let i = 0; i < this.listKokyaku.length; i++) {
        // 顧客変更時の初期化
        page = 1;
        preTranctionId = '';
        preBillingNo = 0;
        billingFlg = true;
        totalFlg1 = false;
        totalFlg2 = false;
        forwardBalanceRow = 0;
        // 税無しと税ありは分けない（売上課税区分「0:外税」の場合は最後に合計から税額を計算）
        totalBalance = Number(this.listKokyaku[i].totalData.pre_billing_no_tax_balance);
        totalBalanceTax = Number(this.listKokyaku[i].totalData.pre_billing_tax_balance);
        totalMoney = 0;
        totalDeposit = 0;
        totalDepositTax = 0;
        if (result != null && index < result.length && this.listKokyaku[i].clientId == result[index].client_id) {
          // 帳票毎に設定可能な製品の件数
          while(index < result.length && this.listKokyaku[i].clientId == result[index].client_id) {
            let billingCntByChouhyou = 0;
            // SVGファイルの置換用文字列
            replacements = [];
            if (page == 1) {
              billingCntByChouhyou = this.constData.cntStart;
            } else {
              this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
              billingCntByChouhyou = this.constData.cntEnd;
            }
            // 伝票
            for (let j = 0; j < billingCntByChouhyou; j++) {
              if ((index >= result.length || this.listKokyaku[i].clientId != result[index].client_id) && totalFlg1 == false) {
                /* 合計行1行目 */
                this.createReplacementsTotal(replacements, 1, j, this.listKokyaku[i].totalData, totalMoney, totalDeposit, totalDepositTax, i);
                totalFlg1 = true;
              } else if ((index >= result.length || this.listKokyaku[i].clientId != result[index].client_id) && totalFlg2 == false) {
                /* 合計行2行目 */
                this.createReplacementsTotal(replacements, 2, j, this.listKokyaku[i].totalData, totalMoney, totalDeposit, totalDepositTax, i);
                totalFlg2 = true;
              } else if (totalFlg1 == true && totalFlg2 == true && forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
                this.createReplacementsForwardBalance(replacements, j, forwardBalanceRow, this.listKokyaku[i]);
                forwardBalanceRow++;
              } else if (result.length <= index ||
                (this.listKokyaku[i].clientId != result[index].client_id) ||
                (preBillingNo != 0 && (preBillingNo != result[index].billing_no || preTranctionId != result[index].transaction_id))) {
                // 先頭以外で伝票番号が切り替わった場合、または、
                // 同取引先の書き込みが終わった場合、または、
                // テーブルデータの参照が終わった場合
                /* 空行 */
                this.addReplacementsBlankRow(replacements, j);
                if (index < result.length) {
                  preTranctionId = result[index].transaction_id;
                  preBillingNo = result[index].billing_no;
                }
                billingFlg = true;
              } else {
                if (billingFlg == true) {
                  /* 伝票・現場行 */
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: formatDate(result[index].billing_date), textAnchor: 'start', textLength: 170, chkWidth: false});
                  // コード
                  replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].billing_no == 0 ? '' : result[index].billing_no, textAnchor: 'start', textLength: 180, chkWidth: false});
                  // 現場コード
                  replacements.push({key: '%C' + (j + 1).toString() + '%', value: result[index].site_id == 0 ? '' : result[index].site_id, textAnchor: 'start', textLength: 100, chkWidth: false});
                  // 配送コード
                  replacements.push({key: '%H' + (j + 1).toString() + '%', value: getNullStr(result[index].shipping_code), textAnchor: 'start', textLength: 100, chkWidth: false});
                  // 現場名
                  if (getNullStr(result[index].client_site_name).length > 12) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%名称' + (j + 1).toString() + '%', value: getNullStr(result[index].client_site_name), textAnchor: 'start', textLength: 410, chkWidth: chkWidth});
                  // 数量
                  replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 単価
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                  // 入金額
                  replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                  // 決済予定
                  replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                  // 残高
                  replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
                  billingFlg = false;
                } else {
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
                  /* 製品行 */
                  if (result[index].product_sales_unit_amount != 0) {
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: 180, chkWidth: false});
                    // 製品名
                    strWork = result[index].product_name;
                    if (result[index].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
                      strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                    }
                    if (getNullStr(strWork).length > 20) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%C' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 630, chkWidth: chkWidth});
                    // （配送コード）
                    replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
                    // （現場名）
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
                    // 数量
                    strWork = result[index].product_quantity.toLocaleString() + (getNullStr(result[index].product_unit) == '' ? '' : ' ' + result[index].product_unit);
                    if (getNullStr(strWork).length > 5) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: result[index].product_sales_unit_price.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_sales_unit_amount.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 入金額
                    replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 残高
                    totalBalance += Number(result[index].product_sales_unit_amount);
                    totalMoney += Number(result[index].product_sales_unit_amount);
                    chkWidth = false;
                    if (totalBalance.length >= 13) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalBalance.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                  } else if (result[index].product_tax != 0) {
                    // SELECT文の関係でここには入らない想定
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
                    // 製品名
                    replacements.push({key: '%C' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'start', textLength: 630, chkWidth: false});
                    // （配送コード）
                    replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
                    // （現場名）
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_tax.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 入金額
                    replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 残高
                    totalBalanceTax += Number(result[index].product_tax);
                    chkWidth = false;
                    if (totalBalanceTax.length >= 13) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalBalanceTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                  } else {
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
                    // 製品名
                    replacements.push({key: '%C' + (j + 1).toString() + '%', value: '（　' + getNullStr(result[index].kind) + '　' + (getNullStr(getListValue(Const.denominationList, result[index].kind)) + '　　　').substr(0, 3) + '　' + result[index].account_support + '　）', textAnchor: 'end', textLength: 630, chkWidth: false});
                    // （配送コード）
                    replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
                    // （現場名）
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 金額
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 入金額
                    replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: result[index].amount.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
                    // 決済予定
                    replacements.push({key: '%決済' + (j + 1).toString() + '%', value: formatDate(result[index].settlement_scheduled), textAnchor: 'start', textLength: 170, chkWidth: false});
                    // 残高
                    if ((result[index].kind == Const.denomination.tax && result[index].account_support != Const.TaxCommission) ||
                    result[index].kind == Const.denomination.offsetTax ||
                    result[index].kind == Const.denomination.billsTax) {
                      // 消費税の場合は消費税の残高を表示
                      totalBalanceTax -= result[index].amount;
                      strWork = getNullStr(totalBalanceTax);
                    } else {
                      // 消費税以外の場合は税抜の残高を表示
                      totalBalance -= result[index].amount;
                      strWork = getNullStr(totalBalance);
                    }
                    chkWidth = false;
                    if (strWork.length >= 13) {
                      chkWidth = true;
                    }
                    replacements.push({key: '%残高' + (j + 1).toString() + '%', value: Number(strWork).toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                    if ((result[index].kind == Const.denomination.tax && result[index].account_support != Const.TaxCommission) ||
                    result[index].kind == Const.denomination.offsetTax ||
                    result[index].kind == Const.denomination.billsTax) {
                      // 消費税の場合は消費税を累積
                      totalDepositTax += Number(result[index].amount);
                    } else {
                      // 消費税以外の場合は税抜を累積
                      totalDeposit += Number(result[index].amount);
                    }
                  }
                  preTranctionId = result[index].transaction_id;
                  preBillingNo = result[index].billing_no;
                  index++;
                }
              }
            }
            //console.log('replacements');
            //console.log(replacements);
            Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
            page++;
          }
          // 合計行の記載が残っている場合
          if (totalFlg1 == false || totalFlg2 == false) {
            // SVGファイルの置換用文字列
            replacements = [];
            this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
            // 伝票
            for (let j = 0; j < this.constData.cntEnd; j++) {
              if (totalFlg1 == false) {
                /* 合計行1行目 */
                this.createReplacementsTotal(replacements, 1, j, this.listKokyaku[i].totalData, totalMoney, totalDeposit, totalDepositTax, i);
                totalFlg1 = true;
              } else if (totalFlg2 == false) {
                /* 合計行2行目 */
                this.createReplacementsTotal(replacements, 2, j, this.listKokyaku[i].totalData, totalMoney, totalDeposit, totalDepositTax, i);
                totalFlg2 = true;
              } else if (totalFlg1 == true && totalFlg2 == true && forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
                this.createReplacementsForwardBalance(replacements, j, forwardBalanceRow, this.listKokyaku[i]);
                forwardBalanceRow++;
              } else {
                /* 空行 */
                this.addReplacementsBlankRow(replacements, j);
              }
            }
            Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
            page++;
          }
          if (forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
            while (forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
              // SVGファイルの置換用文字列
              replacements = [];
              this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
              // 伝票
              for (let j = 0; j < this.constData.cntEnd; j++) {
                if (totalFlg1 == true && totalFlg2 == true && forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
                  this.createReplacementsForwardBalance(replacements, j, forwardBalanceRow, this.listKokyaku[i]);
                  forwardBalanceRow++;
                } else {
                  /* 空行 */
                  this.addReplacementsBlankRow(replacements, j);
                }
              }
              Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
              page++;
            }
          }
        } else {
          if (forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
            while (forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
              let billingCntByChouhyou = 0;
              // SVGファイルの置換用文字列
              replacements = [];
              if (page == 1) {
                billingCntByChouhyou = this.constData.cntStart;
              } else {
                this.listKokyaku[i].listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
                billingCntByChouhyou = this.constData.cntEnd;
              }
              // 伝票
              for (let j = 0; j < billingCntByChouhyou; j++) {
                if (forwardBalanceRow < this.listKokyaku[i].forwardBalancesList.length * this.constData.forwardBalanceRowCnt) {
                  this.createReplacementsForwardBalance(replacements, j, forwardBalanceRow, this.listKokyaku[i]);
                  forwardBalanceRow++;
                } else {
                  /* 空行 */
                  this.addReplacementsBlankRow(replacements, j);
                }
              }
              Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, replacements);
              page++;
            }
          } else {
            Array.prototype.push.apply(this.listKokyaku[i].listChouhyou[page - 1].replacements, this.createReplacementsBlank());
          }
        }
      }
    },
    /* 顧客の置換配列セット（合計行） */
    createReplacementsTotal: function(replacements, rowNo, j, totalData, totalMoney, totalDeposit, totalDepositTax, kokyakuIndex) {
      //console.log('createReplacementsTotal');
      // SVGファイルの置換用文字列
      let billingIssueInputDate = totalData.billing_end_date;
      let chkWidth = false;
      if (rowNo == 1) {
        /* 合計行1行目 */
        let taxRate = 0;
        if (this.controlMasterData.newTaxStartDate == null ||
          dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
          taxRate = this.controlMasterData.taxRate;
        } else {
          taxRate = this.controlMasterData.newTaxRate;
        }
        let taxRateSubTotalTitle = '消費税' + taxRate + '%課税 小計';
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: this.constData.totalRowTitle, textAnchor: 'start', textLength: 180, chkWidth: true});
        // 現場コード
        replacements.push({key: '%C' + (j + 1).toString() + '%', value: taxRateSubTotalTitle + '　' + Number(totalData.closing_date_normal_tax_subtotal).toLocaleString(), textAnchor: 'end', textLength: 510, chkWidth: true});
        // 配送コード
        replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
        // 現場名
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'end', textLength: 410, chkWidth: false});
        // 数量
        if (Number(totalData.closing_date_normal_tax).toLocaleString().length >= 10) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: Number(totalData.closing_date_normal_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '税　抜', textAnchor: 'middle', textLength: 210, chkWidth: false});
        // 金額
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: Number(totalData.product_sales_unit_amount).toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 入金額
        replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: Number(totalData.amount_no_tax).toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 残高
        let totalNoTax = 0;
        totalNoTax = Number(totalData.pre_billing_no_tax_balance) + totalMoney - totalDeposit;
        if (totalNoTax.toLocaleString().length >= 13) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalNoTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // ページ右下の繰越金額用
        this.listKokyaku[kokyakuIndex].nextForward = totalNoTax;
      } else {
        /* 合計行2行目 */
        let lightTaxRate = 0;
        if (this.controlMasterData.newTaxStartDate == null ||
          dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
          lightTaxRate = this.controlMasterData.lightTaxRate;
        } else {
          lightTaxRate = this.controlMasterData.newLightTaxRate;
        }
        let lightTaxRateSubTotalTitle = '消費税' + lightTaxRate + '%課税（' + this.controlMasterData.lightTaxMark + '） 小計'
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
        // 現場コード
        replacements.push({key: '%C' + (j + 1).toString() + '%', value: lightTaxRateSubTotalTitle + '　' + Number(totalData.closing_date_light_tax_subtotal).toLocaleString(), textAnchor: 'end', textLength: 510, chkWidth: true});
        // 配送コード
        replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
        // 現場名
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'end', textLength: 410, chkWidth: false});
        // 数量
        if (Number(totalData.closing_date_light_tax).toLocaleString().length >= 10) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: Number(totalData.closing_date_light_tax).toLocaleString(), textAnchor: 'end', textLength: 170, chkWidth: chkWidth});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'middle', textLength: 210, chkWidth: false});
        // 金額
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: Number(totalData.product_tax).toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 入金額
        replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: Number(totalData.amount_tax).toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 残高
        let totalTax = 0;
        totalTax = Number(totalData.pre_billing_tax_balance) - totalDepositTax;
        totalTax += Number(totalData.closing_date_normal_tax) + Number(totalData.closing_date_light_tax);
        if (totalTax.toLocaleString().length >= 13) {
          chkWidth = true;
        } else {
          chkWidth = false;
        }
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: totalTax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
        // ページ右下の繰越金額用
        this.listKokyaku[kokyakuIndex].nextForward += totalTax;
      }
      //console.log(replacements);
      return replacements;
    },
    // 顧客の置換配列セット（請求残高）
    async createReplacementsForwardBalance(replacements, j, forwardBalanceRow, kokyaku) {
      //console.log('createReplacementsForwardBalance');
      let kokyakuIndex = Math.floor(forwardBalanceRow / this.constData.forwardBalanceRowCnt);
      let rowCnt = forwardBalanceRow % this.constData.forwardBalanceRowCnt;
      //console.log(kokyakuIndex);
      //console.log(rowCnt);
      let work = 0;
      if (rowCnt == 0) {
        // 空行
        this.addReplacementsBlankRow(replacements, j);
      } else if (rowCnt == 1) {
        // 税抜行
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
        // 現場コード
        replacements.push({key: '%C' + (j + 1).toString() + '%', value: this.constData.forwardBalanceRowTitle, textAnchor: 'middle', textLength: 630, chkWidth: false});
        // 配送コード
        replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
        // 現場名
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
        // 数量
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '税　抜', textAnchor: 'middle', textLength: 210, chkWidth: false});
        // 金額
        if (kokyakuIndex >= kokyaku.forwardBalancesList.length - 1) {
          // 最終行
          work = Number(kokyaku.forwardBalancesList[kokyakuIndex].billing_amount) - kokyaku.remainingsAmount;
          kokyaku.remainingsAmount = 0;
        } else {
          // 最終行以外
          work = Number(kokyaku.forwardBalancesList[kokyakuIndex].billing_amount);
          if (work <= 0) {
            kokyaku.remainingsAmount = kokyaku.remainingsAmount - work;
            work = 0;
          } else {
            if (work >= kokyaku.remainingsAmount) {
              work = work - kokyaku.remainingsAmount;
              kokyaku.remainingsAmount = 0;
            } else {
              kokyaku.remainingsAmount = kokyaku.remainingsAmount - work;
              work = 0;
            }
          }
        }
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: work.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 入金額
        replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: '(' + formatDate(kokyaku.forwardBalancesList[kokyakuIndex].billing_date) + ')', textAnchor: 'start', textLength: 210, chkWidth: false});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 残高
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
      } else {
        // 消費税行
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
        // 現場コード
        replacements.push({key: '%C' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
        // 配送コード
        replacements.push({key: '%H' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
        // 現場名
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
        // 数量
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'middle', textLength: 210, chkWidth: false});
        // 金額
        if (kokyakuIndex >= kokyaku.forwardBalancesList.length - 1) {
          // 最終行
          work = Number(kokyaku.forwardBalancesList[kokyakuIndex].billing_tax) - kokyaku.remainingsAmountTax;
          kokyaku.remainingsAmountTax = 0;
        } else {
          // 最終行以外
          work = Number(kokyaku.forwardBalancesList[kokyakuIndex].billing_tax);
          if (work <= 0) {
            kokyaku.remainingsAmountTax = kokyaku.remainingsAmountTax - work;
            work = 0;
          } else {
            if (work >= kokyaku.remainingsAmountTax) {
              work = work - kokyaku.remainingsAmountTax;
              kokyaku.remainingsAmountTax = 0;
            } else {
              kokyaku.remainingsAmountTax = kokyaku.remainingsAmountTax - work;
              work = 0;
            }
          }
        }
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: work.toLocaleString(), textAnchor: 'end', textLength: 210, chkWidth: false});
        // 入金額
        replacements.push({key: '%入金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
        // 決済予定
        replacements.push({key: '%決済' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
        // 残高
        replacements.push({key: '%残高' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
      }
    },
    /* 顧客の置換配列セット（伝票部分は全て空） */
    createReplacementsBlank: function() {
      //console.log('createReplacementsBlank');
      // SVGファイルの置換用文字列
      let replacements = [];
      // 伝票
      for (let j = 0; j < this.constData.cntStart; j++) {
        /* 空行 */
        this.addReplacementsBlankRow(replacements, j);
      }
      return replacements;
    },
    // 空行追加
    addReplacementsBlankRow: function(replacements, index) {
      /* 空行 */
      // 日付
      replacements.push({key: '%日付' + (index + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 170, chkWidth: false});
      // コード
      replacements.push({key: '%コード' + (index + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 180, chkWidth: false});
      // 現場コード
      replacements.push({key: '%C' + (index + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
      // 配送コード
      replacements.push({key: '%H' + (index + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
      // 現場名
      replacements.push({key: '%名称' + (index + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 410, chkWidth: false});
      // 数量
      replacements.push({key: '%数量' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
      // 単価
      replacements.push({key: '%単価' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
      // 金額
      replacements.push({key: '%金額' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
      // 入金額
      replacements.push({key: '%入金額' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
      // 決済予定
      replacements.push({key: '%決済' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 170, chkWidth: false});
      // 残高
      replacements.push({key: '%残高' + (index + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
    },
    /* ページ追加 */
    addReplacementsPage: function() {
      //console.log('addReplacementsPage');
      let strWork = '';
      let chkWidth = false;
      for (let i = 0; i < this.listKokyaku.length; i++) {
        for (let j = 0; j < this.listKokyaku[i].listChouhyou.length; j++) {
          // ページ番号
          strWork = this.listKokyaku[i].listChouhyou[j].page + ' / ' + this.listKokyaku[i].listChouhyou.length;
          if (getNullStr(strWork).length > 7) {
            chkWidth = true;
          } else {
            chkWidth = false;
          }
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%P%', value: this.listKokyaku[i].listChouhyou[j].page + ' / ' + this.listKokyaku[i].listChouhyou.length, textAnchor: 'end', textLength: 160, chkWidth: chkWidth});
          // 繰越額2
          if (getNullStr(this.listKokyaku[i].nextForward).length > 9) {
            chkWidth = true;
          } else {
            chkWidth = false;
          }
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%繰越額2%', value: this.listKokyaku[i].nextForward.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
          // 得意先コード
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%得CD%', value: this.listKokyaku[i].clientId, textAnchor: 'start', textLength: 140, chkWidth: false});
          if (getNullStr(this.listKokyaku[i].clientNameKanji).length > 14) {
            chkWidth = true;
          } else {
            chkWidth = false;
          }
          this.listKokyaku[i].listChouhyou[j].replacements.push({key: '%得名%', value: this.listKokyaku[i].clientNameKanji, textAnchor: 'start', textLength: 430, chkWidth: chkWidth});
        }
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function(){
      //console.log('setChouhyou');
      for (let j = 0; j < this.listKokyaku.length; j++) {
        for (let i = 0; i < this.listKokyaku[j].listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + this.listKokyaku[j].clientId + '_' + this.listKokyaku[j].listChouhyou[i].page);
          this.setReplacements(svgDoc, this.listKokyaku[j].listChouhyou[i].replacements);
          if (this.lineY > 0) {
            svgDoc.append(this.createLine(this.lineY));
            this.lineY = 0;
          }
          this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  this.setTspan(node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 合計行の場合
      if (config.value == this.constData.totalRowTitle) {
        /* 線を追加 */
        this.lineY = parseFloat(tagTspan.getAttribute('y')) - this.constData.totalLineHeight;
      }
    },
    /* 合計行を示す線を作成 */
    createLine: function(y) {
      let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', this.constData.totalLineX);
      line.setAttribute('x2', this.constData.totalLineX + this.constData.totalLineWidth);
      line.setAttribute('y1', y);
      line.setAttribute('y2', y);
      line.setAttribute('stroke', '#000000');
      line.setAttribute('stroke-width', 2);
      line.setAttribute('stroke-dasharray', '10,10');
      return line;
    },
    /* 取得結果セット */
    setSize: function(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBilling = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';
      [preBilling, controlData] = await Promise.all([
        API.graphql(graphqlOperation(list_t_closing_update_range, {where_clause: where_clause})),
        getControlMaster(),
      ]);
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
      let preBillingResult = preBilling.data.list_t_closing_update_range;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
    // 検索SELECT文字列作成（前月情報）
    makeSelectSqlPreBillingBalance: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' billings.billing_end_date';
      selectSql += ',billings.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',0 AS product_sales_unit_amount';
      selectSql += ',0 AS product_tax';
      selectSql += ',billings_balances.pre_billing_no_tax_balance';
      selectSql += ',billings_balances.pre_billing_tax_balance';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.business_class';
      selectSql += ',0 AS closing_date_normal_tax_subtotal';
      selectSql += ',0 AS closing_date_normal_tax';
      selectSql += ',0 AS closing_date_light_tax_subtotal';
      selectSql += ',0 AS closing_date_light_tax';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_billings AS billings ';
      selectSql += 'INNER JOIN t_billings_balances AS billings_balances ';
      selectSql += 'ON billings.client_id = billings_balances.client_id ';
      selectSql += 'AND billings_balances.site_id = 0 ';
      selectSql += 'AND billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_balances.billing_start_date IS NULL ';
      selectSql += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0) ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'billings.site_id = 0 ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings.client_id';

      return selectSql;
    },
    // 検索SELECT文字列作成（先行請求書発行情報）
    makeSelectSqlSeparate: function() {
      let selectSqlQuery = '';
      // SELECT句
      selectSqlQuery += 'SELECT';
      selectSqlQuery += ' billings.billing_end_date';
      selectSqlQuery += ',billings.client_id';
      selectSqlQuery += ',MAX(billings_issue_input_billing_no.sales_unit_amount) AS sales_unit_amount';
      selectSqlQuery += ',MAX(billings_issue_input_billing_no.normal_tax_subtotal) AS normal_tax_subtotal';
      selectSqlQuery += ',MAX(billings_issue_input_billing_no.normal_tax) AS normal_tax';
      selectSqlQuery += ',MAX(billings_issue_input_billing_no.light_tax_subtotal) AS light_tax_subtotal';
      selectSqlQuery += ',MAX(billings_issue_input_billing_no.light_tax) AS light_tax';
      // FROM句
      selectSqlQuery += ' FROM ';
      selectSqlQuery += 'm_billings AS billings ';
      selectSqlQuery += 'INNER JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSqlQuery += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSqlQuery += 'AND billings.client_id = billings_issue_input_billing_no.client_id ';
      selectSqlQuery += 'AND billings.site_id = billings_issue_input_billing_no.site_id ';
      // WHERE句
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSqlQuery += 'WHERE billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSqlQuery += 'WHERE billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSqlQuery += 'WHERE billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // GROUP BY句
      selectSqlQuery += ' GROUP BY ';
      selectSqlQuery += ' billings.client_id';
      selectSqlQuery += ',billings_issue_input_billing_no.separate_invoice_no';

      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' subQuery.billing_end_date';
      selectSql += ',subQuery.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',SUM(subQuery.sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',SUM(subQuery.normal_tax) + SUM(subQuery.light_tax) AS product_tax';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.business_class';
      selectSql += ',SUM(subQuery.normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql += ',SUM(subQuery.normal_tax) AS closing_date_normal_tax';
      selectSql += ',SUM(subQuery.light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql += ',SUM(subQuery.light_tax) AS closing_date_light_tax';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + selectSqlQuery + ') AS subQuery ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND subQuery.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' subQuery.client_id';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' subQuery.client_id';

      return selectSql;
    },
    // 合計データに設定（前月分データ、先行発行登録伝票データ）
    async setTotalData(dataResultTotal) {
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 指定した年月が請求マスタの範囲と同じ場合
        // 請求残高から前月分の情報を取得、請求書発行登録済みの伝票番号情報を取得
        let selectSqlPreBillingBalance = this.makeSelectSqlPreBillingBalance();
        let selectSqlSeparate = this.makeSelectSqlSeparate();
        //console.log(selectSqlPreBillingBalance);
        //console.log(selectSqlSeparate);
        let dataResultPreBillingBalance = null;
        let dataResultSeparate = null;
        [dataResultPreBillingBalance, dataResultSeparate] = await Promise.all([
          executeSelectSql(selectSqlPreBillingBalance),
          executeSelectSql(selectSqlSeparate),
        ]);
        //console.log(dataResultPreBillingBalance);
        //console.log(dataResultSeparate);
        if (dataResultPreBillingBalance != null && dataResultPreBillingBalance.length > 0) {
          // 前月分の情報があった場合
          let totalIndex = 0;
          for (let i = 0; i < dataResultPreBillingBalance.length; i++) {
            if (dataResultTotal != null && dataResultTotal.length > 0) {
              if (totalIndex < dataResultTotal.length) {
                if (dataResultPreBillingBalance[i].client_id == dataResultTotal[totalIndex].client_id) {
                  if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                    dataResultTotal[totalIndex].pre_billing_no_tax_balance = dataResultPreBillingBalance[i].pre_billing_no_tax_balance;
                    dataResultTotal[totalIndex].pre_billing_tax_balance = dataResultPreBillingBalance[i].pre_billing_tax_balance;
                  }
                } else if (dataResultPreBillingBalance[i].client_id < dataResultTotal[totalIndex].client_id) {
                  dataResultTotal.splice(totalIndex, 0, dataResultPreBillingBalance[i]);
                } else {
                  totalIndex++;
                  i--;
                }
              } else {
                dataResultTotal.push(dataResultPreBillingBalance[i]);
              }
            } else {
              dataResultTotal.push(dataResultPreBillingBalance[i]);
            }
          }
        }
        if (dataResultSeparate != null && dataResultSeparate.length > 0) {
          // 前月分の情報があった場合
          let totalIndex = 0;
          for (let i = 0; i < dataResultSeparate.length; i++) {
            if (dataResultTotal != null && dataResultTotal.length > 0) {
              if (totalIndex < dataResultTotal.length) {
                if (dataResultSeparate[i].client_id == dataResultTotal[totalIndex].client_id) {
                  if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                    dataResultTotal[totalIndex].product_sales_unit_amount = Number(dataResultTotal[totalIndex].product_sales_unit_amount) + Number(dataResultSeparate[i].product_sales_unit_amount);
                    dataResultTotal[totalIndex].product_tax = Number(dataResultTotal[totalIndex].product_tax) + Number(dataResultSeparate[i].product_tax);
                    dataResultTotal[totalIndex].closing_date_normal_tax_subtotal = Number(dataResultTotal[totalIndex].closing_date_normal_tax_subtotal) + Number(dataResultSeparate[i].closing_date_normal_tax_subtotal);
                    dataResultTotal[totalIndex].closing_date_normal_tax = Number(dataResultTotal[totalIndex].closing_date_normal_tax) + Number(dataResultSeparate[i].closing_date_normal_tax);
                    dataResultTotal[totalIndex].closing_date_light_tax_subtotal = Number(dataResultTotal[totalIndex].closing_date_light_tax_subtotal) + Number(dataResultSeparate[i].closing_date_light_tax_subtotal);
                    dataResultTotal[totalIndex].closing_date_light_tax = Number(dataResultTotal[totalIndex].closing_date_light_tax) + Number(dataResultSeparate[i].closing_date_light_tax);
                  }
                } else if (dataResultSeparate[i].client_id < dataResultTotal[totalIndex].client_id) {
                  dataResultTotal.splice(totalIndex, 0, dataResultSeparate[i]);
                } else {
                  totalIndex++;
                  i--;
                }
              } else {
                dataResultTotal.push(dataResultSeparate[i]);
              }
            } else {
              dataResultTotal.push(dataResultSeparate[i]);
            }
          }
        }
      }
    },
  },
}
</script>
<style scoped>
  body {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>