<template>
  <!-- 製品単位変換保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品単位変換保守照会</strong></h5>
                <router-link to="/products-units-conversions-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- ケース製品コード -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                  <label for="case_product_id" class="form-label"><strong>ケース製品コード(単位:{{productUnitsConversionsObj.case_unit}})</strong></label>
                  <input type="text" id="case_product_id" class="form-control" v-model="productUnitsConversionsObj.case_product_id" readonly>
                </div>
                <!-- ケース製品名 -->
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                  <label for="case_product_name_kanji" class="form-label"><strong>ケース製品名(単位:{{productUnitsConversionsObj.case_unit}})</strong></label>
                  <input type="text" id="case_product_name_kanji" class="form-control" v-model="productUnitsConversionsObj.case_product_name_kanji" readonly v-b-tooltip.hover.noninteractive :title="productUnitsConversionsObj.case_product_name_kanji">
                </div>
              </div>
              <div class="row mt-2">
                <!-- バラ製品コード -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                      <label for="loose_product_id" class="form-label"><strong>バラ製品コード</strong></label>
                      <input type="text" id="loose_product_id" class="form-control" v-model="productUnitsConversionsObj.loose_product_id" readonly>
                </div>
                <!-- バラ製品名 -->
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                  <label for="case_product_name_kanji" class="form-label"><strong>バラ製品名</strong></label>
                  <input type="text" id="case_product_name_kanji" class="form-control" v-model="productUnitsConversionsObj.loose_product_name_kanji" readonly v-b-tooltip.hover.noninteractive :title="productUnitsConversionsObj.loose_product_name_kanji">
                </div>
              </div> 
              <div class="row mt-2">
                <!-- ケース入数 -->
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-1 form-group">
                  <label for="case_product_id" class="form-label"><strong>ケース入数(単位:{{productUnitsConversionsObj.loose_unit}})</strong></label>
                  <input type="text" id="quantity" class="form-control" v-model="productUnitsConversionsObj.quantity" readonly>
                </div>
              </div> 
              <!-- 削除・編集ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                    <button type="button" class="btn btn-cancel btn-block" @click="clickDeleteBtn(productUnitsConversionsObj)"><span class="oi oi-circle-x"></span> 削除</button>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                   <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn(productUnitsConversionsObj.product_id)"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products_units_conversions-inquiry';

export default {
  name: 'PRODUCTS_UNITS_CONVERSIONS-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品単位変換保守照会',
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品単位変換対象
      productUnitsConversionsObj: {},
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} case_product_id - ケース製品コード
     * @param {String} loose_product_id - バラ製品コード
     */
    async fetchData({case_product_id, loose_product_id}) {
      // ケース製品コード
      case_product_id = Number(case_product_id)
      loose_product_id = Number(loose_product_id)
      // 製品のデータを請求
      // 製品単位変換マスタのデータを請求
      const productUnitsConversions = await this.searchUnitsConversions(case_product_id, loose_product_id)
      if (productUnitsConversions === undefined) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      // 製品単位変換データをセット
      this.productUnitsConversionsObj = {...productUnitsConversions}
    },
   
    /**
     *  製品単位変換マスタを検索します。
     * @param {Int} case_product_id - 製品コード
     * @param {Int} loose_product_id - 製品コード
     * @returns {Array<Object>} 製品単位変換マスタと製品マスタ結合データ
     */
    async searchUnitsConversions(case_product_id, loose_product_id) {
      const functionName = 'searchUnitsConversions';

      let result = null;
      // 製品単位変換マスタと製品マスタ結合
      const SQLs = `SELECT  
                    MPUC.case_product_id case_product_id,
                    MP.product_name_kanji case_product_name_kanji,
                    MP.product_name_kana case_product_name_kana,
                    MP.unit case_unit,
                    MPUC.quantity,
                    MPUC.loose_product_id loose_product_id,
                    MP2.product_name_kanji loose_product_name_kanji,
                    MP2.product_name_kana loose_product_name_kana,
                    MP2.unit loose_unit
                    FROM 
                    m_products_units_conversions MPUC 
                    LEFT JOIN m_products MP ON MPUC.case_product_id = MP.product_id 
                    LEFT JOIN m_products MP2 ON  MPUC.loose_product_id = MP2.product_id
                    WHERE MPUC.case_product_id=${case_product_id} AND MPUC.loose_product_id=${loose_product_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0][0]
    },
    /**
     * 編集ボタンの押下
     */
    async clickEditBtn() {
      this.$router.push({ 
        name: 'PRODUCTS-UNITS-CONVERSIONS-EDIT', 
        query: { 
          case_product_id: this.productUnitsConversionsObj.case_product_id,
          loose_product_id: this.productUnitsConversionsObj.loose_product_id
        },
      });
      
    },
    /**
     * 削除ボタン押下時
     * @param {Object} obj - 製品単位変換対象
     */
    clickDeleteBtn(obj) {
      this.$bvModal.msgBoxConfirm('製品単位変換データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDelUnitsConversions(obj)
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-UNITS-CONVERSIONS-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1002']] }  
            });
          }
          this.$store.commit('setLoading', false);
        }
      });
    },
    /**
     * 削除処理
     * @param {Int} case_product_id - 選択されたケース製品コード
     * @param {Int} loose_product_id - 選択されたバラ製品コード
     */
    async execDelUnitsConversions({case_product_id, loose_product_id}) {
      const functionName = 'execDelUnitsConversions';
      // 製品単位変換マスタ
      const SQLs = `DELETE FROM m_products_units_conversions WHERE case_product_id = ${case_product_id} AND loose_product_id = ${loose_product_id}`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>