<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <!-- TODO 現在は印刷時にヘッダーは除外しているが、1ページ目のみに表示されるようにする（タイトルを表示するため） -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 営業所コードプルダウン -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                              label-for="selectSalesOffice"
                            >
                              <b-form-select
                                id="selectSalesOffice"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- 伝票日付入力欄 -->
                          <b-col lg="6">
                            <validation-provider name="orderDateStart" :rules="{dateConsistency:searchConditions.orderDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="受注日付"
                                label-for="datepicker1"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker id="datepicker1" name="orderDateStart" class="form-control" v-model="searchConditions.orderDateStart"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <b-input-group-text>～</b-input-group-text>
                                    <b-form-datepicker id="datepicker2" name="orderDateEnd" class="form-control" v-model="searchConditions.orderDateEnd"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                              <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、伝票番号か取引先コードを指定してください。</b-form-text>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 配送種別 -->
                          <b-col lg="12">
                            <b-form-group
                              label="配送種別"
                              label-for="shippingTypeClassStart"
                            >
                              <b-input-group>
                                <validation-provider name="shippingTypeClassStart" :rules="{required: (searchConditions.shippingCodeStart==''||searchConditions.shippingCodeEnd==''), consistency:searchConditions.shippingTypeClassEnd}" v-slot="{ classes,errors }">
                                  <b-input-group :class="classes">
                                    <b-form-input type="text" id="shippingTypeClassStart" name="shippingTypeClassStart" v-model="searchConditions.shippingTypeClassStart" @blur="searchConditions.shippingTypeClassEnd = searchConditions.shippingTypeClassStart" />
                                  </b-input-group>
                                  <b-input-group :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-input-group>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="shippingTypeClassEnd" :rules="{required: (searchConditions.shippingCodeStart==''||searchConditions.shippingCodeEnd=='')}" v-slot="{ classes,errors }">
                                  <b-input-group :class="classes">
                                    <b-form-input type="text" name="shippingTypeClassEnd" v-model="searchConditions.shippingTypeClassEnd" />
                                  </b-input-group>
                                  <b-input-group :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-input-group>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">配送種別の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- ルート -->
                          <b-col lg="12">
                            <b-form-group
                              label="ルート"
                              label-for="shippingCodeStart"
                            >
                              <validation-provider name="shippingCodeStart" :rules="{consistency:searchConditions.shippingCodeEnd}" v-slot="{ classes,errors }">
                                <b-input-group :class="classes">
                                  <b-form-input type="text" id="shippingCodeStart" name="shippingCodeStart" v-model="searchConditions.shippingCodeStart" @blur="searchConditions.shippingCodeEnd = searchConditions.shippingCodeStart" />
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-input type="text" name="shippingCodeEnd" v-model="searchConditions.shippingCodeEnd" />
                                </b-input-group>
                                <b-input-group :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-input-group>
                              </validation-provider>
                              <b-form-text class="text-muted">配送コードの範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 伝票種類 -->
                          <b-col lg="12">
                            <b-form-group>
                              <label for="billingTypeClassStart" class="form-label" :title="htmlConst.BillingTypeClassTitle">伝票種類 <span class="oi oi-flag"/></label>
                              <b-input-group>
                                <validation-provider name="billingTypeClassStart" :rules="{numeric:true,consistency:searchConditions.billingTypeClassEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input type="text" id="billingTypeClassStart" name="billingTypeClassStart" v-model="searchConditions.billingTypeClassStart" />
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                {{ '～' }}
                                <validation-provider name="billingTypeClassEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-input type="text" name="billingTypeClassEnd" v-model="searchConditions.billingTypeClassEnd" />
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">伝票種類の範囲を入力してください。空白の場合は全範囲が設定されます。<br>（{{this.getBillingTypeClassList}}）</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                      <b-col lg="2"></b-col>
                      <b-col lg="5">
                        <b-form style="text-align: center;">
                          <b-button class="mr-2" pill variant="success" title="在庫が余っていて未引当の受注製品を探して引当てます。" @click="clearAlert(); clickReserveOrdersReceives();">
                            <span class="oi oi-circle-check"></span> 受注製品引当
                          </b-button>
                          <label style="color: red; background-color: yellow; font-weight: bold;">注意：同営業所内で受注データ作成中、または、入荷確定入力中でないことを確認した上での使用をお願いします。</label>
                        </b-form>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-row class="justify-content-md-center pb-4">
            <b-col lg="12">
              <b-btn-toolbar class="mt-2">
                <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のPDF出力を行います。" @click="clickOutputPdf" :disabled="totalRows==0">
                  <span class="oi oi-document"></span> ルート別出荷予定表PDF出力
                </b-button>&nbsp;
              </b-btn-toolbar>
            </b-col>
          </b-row>
          <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col class="mt-2">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <template #head(free_col_1)>受注番号<br><br>入力日<br>受注処理担当者<br>&nbsp;</template>
                <template #head(free_col_2)>取引先名<br>取引先コード<br><br><br>&nbsp;</template>
                <template #head(free_col_3)>現場<br>納品先住所<br>納品場所<br>納品指定日<br>納品先TEL</template>
                <template #head(free_col_4)><br><br><br><br>製品</template>
                <template #head(free_col_5)>担当者<br><br><br><br>受注数</template>
                <template #head(free_col_6)><br><br><br><br>受注残</template>
                <template #head(free_col_7)>伝票種類<br><br><br><br>引当数</template>
                <template #head(free_col_8)>伝票種別<br><br><br><br>現在庫数</template>
                <template #head(free_col_9)><br><br><br>チェック<br>入荷予定日</template>
                <template #cell(operation)="data">
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'出荷確定入力、または、出荷確定修正画面を表示します。'" @click="clearAlert(); clickShipsConfirm(data.item.orderReceiveId);" class="mr-1" v-if="data.item.shippingTypeClass&&data.item.shippingTypeClass!=shippingTypeClass.direct" :disabled="data.item.editFlg==false">
                    <span class="oi oi-pencil"></span> 出荷確定入力
                  </b-button>
                </template>
                <template #cell(free_col_1)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.orderReceiveId }}<br/>
                    <br/>
                    {{ data.item.inputDate }}<br/>
                    {{ data.item.orderReceiveStaffId }}
                  </div>
                </template>
                <template #cell(free_col_2)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.clientNameKanji }}<br/>
                    {{ data.item.clientId }}<br/>
                  </div>
                </template>
                <template #cell(free_col_3)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.siteId + ' ' + data.item.shippingCode + ' ' + data.item.clientSiteName }}<br/>
                    {{ data.item.clientSiteAddress1 + ' ' + data.item.clientSiteAddress2 }}<br/>
                    {{ data.item.deliveryLocation }}<br/>
                    <span style="font-weight: bold;">{{ data.item.deliveryDesignDate + ' ' + data.item.deliveryDesignClass + ' ' + data.item.deliveryDesign }}</span><br/>
                    {{ data.item.clientSitePhoneNumber }}
                  </div>
                </template>
                <template #cell(free_col_4)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul v-for="(productName,index) in data.item.productName" :key="index" style="list-style: none;">
                      <li>{{productName}}</li>
                    </ul>
                  </div>
                  <div v-if="data.item.orderReceiveId==null">
                    {{ data.item.productName }}<br/>
                  </div>
                </template>
                <template #cell(free_col_5)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.staffId }}<br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul v-for="(productQuantity,index) in data.item.productQuantity" :key="index" style="list-style: none;">
                      <li>{{productQuantity}}</li>
                    </ul>
                  </div>
                  <div v-if="data.item.orderReceiveId==null">
                    {{data.item.productQuantity}}
                  </div>
                </template>
                <template #cell(free_col_6)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul v-for="(productRemainingQuantity,index) in data.item.productRemainingQuantity" :key="index" style="list-style: none;">
                      <li>{{productRemainingQuantity}}</li>
                    </ul>
                  </div>
                </template>
                <template #cell(free_col_7)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.billing_type_class }}<br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul v-for="(productReserveQuantity,index) in data.item.productReserveQuantity" :key="index" style="list-style: none;">
                      <li>{{productReserveQuantity}}</li>
                    </ul>
                  </div>
                  <div v-if="data.item.orderReceiveId==null">
                    {{data.item.productReserveQuantity}}
                  </div>
                </template>
                <template #cell(free_col_8)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    {{ data.item.orderReceiveBillClass }}<br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul v-for="(productBalance,index) in data.item.productBalance" :key="index" style="list-style: none;">
                      <li>{{productBalance}}</li>
                    </ul>
                  </div>
                  <div v-if="data.item.orderReceiveId==null">
                    {{data.item.productBalance}}
                  </div>
                </template>
                <template #cell(free_col_9)="data">
                  <div v-if="data.item.orderReceiveId!=null">
                    <br/>
                    <br/>
                    <br/>
                    {{ data.item.checkContents }}<br/>
                    <ul v-for="(productStockScheduled,index) in data.item.productStockScheduled" :key="index" style="list-style: none;">
                      <li>{{productStockScheduled}}</li>
                    </ul>
                  </div>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, executeSelectSql, getControlMaster, formatDate, formatCurDate, formatDateCalc, dateConsistency, getListValue, getNullStr, isDayHoliday, getNextBusinessDate, addOperationLogs, reserveStockProductIdList, modStockProductIdListCase } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { list_m_service_classes, list_m_staffs, list_m_offices } from '@/graphql/queries';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'ships-scheduled-route';

export default {
  name: 'SHIPS-SCHEDULED-ROUTE',
  /* コンポーネント */
  components: {
    Header,
    Footer,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: 'ルート別出荷予定表',
      // アラート
      resultError: [],
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOffice: [],
        // 配送種別（開始）
        shippingTypeClassStart: '',
        // 配送種別（終了）
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始）
        shippingCodeStart: '',
        // ルート（配送コード）（終了）
        shippingCodeEnd: '',
        // 伝票種類（開始）
        billingTypeClassStart: '',
        // 伝票種類（終了）
        billingTypeClassEnd: '',
        // 受注日付（開始）
        orderDateStart: '',
        // 受注終了（終了）
        orderDateEnd: '',
      },
      // 検索項目
      searchResultDispConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOfficeText: null,
        // 配送種別（開始／終了）
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始／終了）
        shippingCodeStart: '',
        shippingCodeEnd: '',
        // 伝票種類（開始／終了）
        billingTypeClassStart: '',
        billingTypeClassEnd: '',
        // 受注日付（開始／終了）
        orderDateStart: '',
        orderDateEnd: '',
      },
      // 変更後引数
      changeSearchConditions: {
        billingTypeClassStart: '',
        billingTypeClassEnd: '',
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        shippingCodeStart: '',
        shippingCodeEnd: '',
      },
      // 検索結果に表示する検索条件
      bordered: false,
      borderless: true,
      outlined: false,
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // サービス区分
      serviceList: [],
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // 印刷チェックボックスの総件数
      totalCheckCnt: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 配送種別区分取得
      shippingTypeClass: Const.ShippingTypeClass,
      // 伝種（受注伝票種別区分）取得
      orderReceiveBillClass: Const.OrderReceiveBillClass,
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種類吹き出し
        BillingTypeClassTitle: Const.BillingTypeClassTitle,
      },
      // 翌日営業日
      nextBusinessDay: '',
      // 5営業日前
      fiveBusinessDayBefore: '',
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 伝票種類の区分値（区分：区分値） */
    getBillingTypeClassList: function() {
      let strRet = '';
      for (let i = 0; i < Const.BillingTypeClassList.length; i++) {
        if (strRet != '') {
          strRet += '、';
        }
        strRet += Const.BillingTypeClassList[i].text;
      }
      return strRet;
    },
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'operation',
          label: '',
        },
        {
          key: 'free_col_1',
        },
        {
          key: 'free_col_2',
        },
        {
          key: 'free_col_3',
        },
        {
          key: 'free_col_4',
        },
        {
          key: 'free_col_5',
        },
        {
          key: 'free_col_6',
        },
        {
          key: 'free_col_7',
        },
        {
          key: 'free_col_8',
        },
        {
          key: 'free_col_9',
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
      try {
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListResult = null;
        let officeListResult = null;
        let serviceListResult = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        let condition = {where_clause: where_clause};
        [staffListResult, officeListResult, serviceListResult] = await Promise.all([
          API.graphql(graphqlOperation(list_m_staffs,condition)),
          API.graphql(graphqlOperation(list_m_offices)),
          API.graphql(graphqlOperation(list_m_service_classes)),
        ]);
        let staffListData = staffListResult.data.list_m_staffs;
        //console.log(staffListData);
        // 営業所データ取得
        let officeListData = officeListResult.data.list_m_offices;
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
          //console.log(this.searchConditions.salesOffice[i].id);
          if(this.searchConditions.salesOffice[i].id == staffListData[0].office_id){
            this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
            break;
          }
        }
        // サービス区分を取得
        let serviceListData = serviceListResult.data.list_m_service_classes;
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      // 範囲指定の文字列引数の変換（9を「ﾟ」に変換）
      this.changeSearchConditionsStrRange();
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.busy = true;
      try {
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let controlData = null;
        let kijunDate = formatCurDate('YYYY-MM-DD');
        [controlData, this.nextBusinessDay, this.fiveBusinessDayBefore] = await Promise.all([
          getControlMaster(),
          getNextBusinessDate(kijunDate),
          this.getFiveBusinessDayBefore(kijunDate),
        ]);
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null) {
          this.saveSearchConditions();
          await this.setResult(resultData);
          if (resultData.length >= 2500) {
            this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%','2500').replace('%arg2%','製品'));
          }
          //console.log(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeSearchConditionsStrRange: function() {
      // ルート（配送コード）
      this.changeSearchConditions.shippingCodeStart = this.searchConditions.shippingCodeStart;
      if (this.changeSearchConditions.shippingCodeStart == '*') {
        this.changeSearchConditions.shippingCodeStart = '';
      }
      this.changeSearchConditions.shippingCodeEnd = this.searchConditions.shippingCodeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingCodeEnd = this.changeSearchConditions.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeSearchConditions.shippingTypeClassStart = this.searchConditions.shippingTypeClassStart;
      if (this.changeSearchConditions.shippingTypeClassStart == '*') {
        this.changeSearchConditions.shippingTypeClassStart = '';
      }
      this.changeSearchConditions.shippingTypeClassEnd = this.searchConditions.shippingTypeClassEnd.replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingTypeClassEnd = this.changeSearchConditions.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
      // 伝票種類
      if (this.searchConditions.billingTypeClassStart == '') {
        this.changeSearchConditions.billingTypeClassStart = '0';
      } else {
        this.changeSearchConditions.billingTypeClassStart = this.searchConditions.billingTypeClassStart;
      }
      if (this.searchConditions.billingTypeClassEnd == '') {
        this.changeSearchConditions.billingTypeClassEnd = '9';
      } else {
        this.changeSearchConditions.billingTypeClassEnd = this.searchConditions.billingTypeClassEnd;
      }
    },
    /* 検索条件を保存 */
    saveSearchConditions: function() {
      this.searchResultDispConditions.selectSalesOffice = this.searchConditions.selectSalesOffice;
      this.searchResultDispConditions.shippingTypeClassStart = this.searchConditions.shippingTypeClassStart;
      this.searchResultDispConditions.shippingTypeClassEnd = this.searchConditions.shippingTypeClassEnd;
      this.searchResultDispConditions.shippingCodeStart = this.searchConditions.shippingCodeStart;
      this.searchResultDispConditions.shippingCodeEnd = this.searchConditions.shippingCodeEnd;
      this.searchResultDispConditions.billingTypeClassStart = this.searchConditions.billingTypeClassStart;
      this.searchResultDispConditions.billingTypeClassEnd = this.searchConditions.billingTypeClassEnd;
      this.searchResultDispConditions.orderDateStart = this.searchConditions.orderDateStart;
      this.searchResultDispConditions.orderDateEnd = this.searchConditions.orderDateEnd;
    },
    // 5営業日前の日付を取得
    async getFiveBusinessDayBefore(kijunDate) {
      // カレンダーマスタについて、対象日から100日前まで確認
      // ※仮に100営業日前まで見ても5営業日前の日がない場合は100日前を返却
      const maxDay = 100;
      let dateMaxDayBefore = formatDateCalc(kijunDate, 0, 0, -maxDay, false, 'YYYY-MM-DD');
      // 検索条件作成
      let selectSql = '';
      selectSql += 'SELECT ';
      selectSql += ' date';
      selectSql += ' FROM ';
      selectSql += 'm_calendar ';
      selectSql += ' WHERE ';
      selectSql += 'date BETWEEN \'' + dateMaxDayBefore + '\' AND \'' + formatDateCalc(kijunDate, 0, 0, -1, false, 'YYYY-MM-DD') + '\' ';
      selectSql += 'AND event_class = ' + Const.CalendarEventClass.Holiday + ' ';
      selectSql += 'ORDER BY date DESC ';

      let beforeBusinessDayCnt = 0;
      let dataResult = await executeSelectSql(selectSql);
      //console.log(selectSql);
      //console.log(dataResult);
      for (let i = 1; i < maxDay; i++) {
        // 基準日の前日から調査するためi=1からスタート
        let date = formatDateCalc(kijunDate, 0, 0, -i, false, 'YYYY-MM-DD');
        //console.log(date);
        if (isDayHoliday(date) == false) {
          // 曜日の休日以外
          if (dataResult != null && dataResult.length > 0 && dataResult.findIndex(el => el.date == date) >= 0) {
            // カレンダーマスタに存在する休日
            // 休日として営業日数をカウントしない
            continue;
          } else {
            // 営業日の場合
            beforeBusinessDayCnt++;
            if (beforeBusinessDayCnt >= 5) {
              // 営業日数が5日になった場合、その日を5営業日前として返却
              return date;
            }
          }
        }
      }

      // 最大日数の間に5営業日前がない場合は最大日数前を返却
      return dateMaxDayBefore;
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.order_receive_staff_id';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',clients.billing_type_class';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.input_date';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',clients.check_contents';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity';
      selectSql += ',orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity AS stock_scheduled_reserve_receive';
      selectSql += ',IfNull(stocks.balance,0) AS balance';
      selectSql += ',IfNull(stocks.inventory_reserve_count,0) AS inventory_reserve_count';
      selectSql += ',orders_QUERY.stock_scheduled';
      selectSql += ',orders_QUERY.stock_scheduled_staff_id';
      selectSql += ',CASE WHEN orders_QUERY.stock_scheduled_confirm_class = 1 THEN \'*\' ELSE \'\' END AS stock_scheduled_confirm_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'LEFT JOIN (' + this.createSubOrdersQuerySql() + ') AS orders_QUERY ';
      selectSql += 'ON (orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      selectSql += 'AND orders_receives.order_id = orders_QUERY.order_id ';
      selectSql += 'AND orders_receives.order_row = orders_QUERY.order_row) ';
      selectSql += 'OR (orders_receives.office_id = orders_QUERY.office_id ';
      selectSql += 'AND orders_receives.product_id = orders_QUERY.product_id ';
      selectSql += 'AND orders_receives.order_id = 0) ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders_receives.client_class = clients.client_class ';
      selectSql += 'AND orders_receives.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_stocks AS stocks ';
      selectSql += 'ON orders_receives.office_id = stocks.office_id ';
      selectSql += 'AND orders_receives.product_id = stocks.product_id ';
      selectSql += 'AND stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 伝票種別
      selectSql += 'clients.billing_type_class BETWEEN ' + this.changeSearchConditions.billingTypeClassStart + ' AND ' + this.changeSearchConditions.billingTypeClassEnd + ' ';
      // 営業所コード
      selectSql += 'AND orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 配送種別
      selectSql += 'AND orders_receives.shipping_type_class BETWEEN \'' + this.changeSearchConditions.shippingTypeClassStart + '\' AND \'' + this.changeSearchConditions.shippingTypeClassEnd + '\' ';
      // ルート（配送コード）
      selectSql += 'AND orders_receives.shipping_code BETWEEN \'' + this.changeSearchConditions.shippingCodeStart + '\' AND \'' + this.changeSearchConditions.shippingCodeEnd + '\' ';
      // 受注日付
      if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == '') {
        selectSql += 'AND orders_receives.order_receive_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      } else if (this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      } else if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 削除されていない製品
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      // 受注残数が0以外（伝票未発行の受注）
      selectSql += 'AND orders_receives.remaining_quantity <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_receives.shipping_code, orders_receives.order_receive_id,orders_receives.order_receive_row ';
      /* LIMIT */
      selectSql += ' LIMIT 2500';
      return selectSql;
    },
    // 対象データSubOrdersQuerySQL作成処理
    createSubOrdersQuerySql: function() {
      // 受注番号に紐づかない発注データ（入荷予定のみ）
      let selectSql1 = 'SELECT';
      selectSql1 += ' 0 AS order_receive_id';
      selectSql1 += ',0 AS order_receive_row';
      selectSql1 += ',0 AS order_id';
      selectSql1 += ',0 AS order_row';
      selectSql1 += ',office_id';
      selectSql1 += ',product_id';
      selectSql1 += ',MIN(stock_scheduled) AS stock_scheduled';
      selectSql1 += ',stock_scheduled_staff_id';
      selectSql1 += ',stock_scheduled_confirm_class';
      selectSql1 += ' FROM ';
      selectSql1 += 't_orders';
      selectSql1 += ' WHERE ';
      selectSql1 += 'order_receive_id = 0 ';
      selectSql1 += 'AND is_deleted = 0 ';
      selectSql1 += 'AND remaining_quantity > 0 ';
      selectSql1 += 'AND order_issue_class > 0 ';
      selectSql1 += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      selectSql1 += 'GROUP BY office_id,product_id ';
      // 受注番号と紐づく発注データ（入荷予定のみ）
      let selectSql2 = 'SELECT';
      selectSql2 += ' order_receive_id';
      selectSql2 += ',order_receive_row';
      selectSql2 += ',order_id';
      selectSql2 += ',order_row';
      selectSql2 += ',0 AS office_id';
      selectSql2 += ',0 AS product_id';
      selectSql2 += ',stock_scheduled';
      selectSql2 += ',stock_scheduled_staff_id';
      selectSql2 += ',stock_scheduled_confirm_class';
      selectSql2 += ' FROM ';
      selectSql2 += 't_orders';
      selectSql2 += ' WHERE ';
      selectSql2 += 'order_receive_id > 0 ';
      selectSql2 += 'AND is_deleted = 0 ';
      selectSql2 += 'AND remaining_quantity > 0 ';
      selectSql2 += 'AND order_issue_class > 0 ';
      selectSql2 += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 2つのSELECT文をUNION
      let selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      let searchResult = null;
      let orderReceiveReserveQuantity = 0;
      for (let i = 0; i < result.length; i++) {
        // 受注番号毎に１件として検索結果に表示するリストを作成
        if (searchResult == null || searchResult.orderReceiveId != result[i].order_receive_id) {
          if (searchResult != null) {
            if (orderReceiveReserveQuantity == 0) {
              // 同受注内の引当数が0の場合、出荷確定ボタン非活性
              searchResult.editFlg = false;
            }
            orderReceiveReserveQuantity = 0;
            this.itemList.push(searchResult);
          }
          let deliveryLocation = getNullStr(result[i].client_site_zip_code);
          if (deliveryLocation == '') {
            deliveryLocation += getNullStr(result[i].delivery_location);
          } else {
            deliveryLocation = '〒' + deliveryLocation + ' ' + getNullStr(result[i].delivery_location);
          }
          searchResult = {
            orderReceiveId: result[i].order_receive_id,
            orderReceiveStaffId: getNullStr(result[i].order_receive_staff_id),
            clientId: result[i].client_id,
            clientNameKanji: getNullStr(result[i].client_name_kanji),
            staffId: result[i].staff_id,
            shippingTypeClass: result[i].shipping_type_class,
            billing_type_class: getListValue(Const.BillingTypeClassList, result[i].billing_type_class, 'value', 'text'),
            orderReceiveBillClass: getListValue(Const.OrderReceiveBillClassList, result[i].order_receive_bill_class, 'id', 'text'),
            inputDate: formatDate(result[i].input_date),
            deliveryDesignDate: result[i].delivery_design_date == null ? '0000/00/00' : formatDate(result[i].delivery_design_date),
            deliveryDesignClass: getNullStr(result[i].delivery_design_class),
            deliveryDesign: getNullStr(result[i].delivery_design),
            siteId: result[i].site_id,
            shippingCode: getNullStr(result[i].shipping_code),
            clientSiteName: getNullStr(result[i].client_site_name_kanji),
            clientSiteAddress1: getNullStr(result[i].client_site_address_1),
            clientSiteAddress2: getNullStr(result[i].client_site_address_2),
            deliveryLocation: getNullStr(deliveryLocation),
            clientSitePhoneNumber: getNullStr(result[i].client_site_phone_number),
            checkContents: getNullStr(result[i].check_contents),
            productId: [],
            productName: [],
            productQuantity: [],
            productRemainingQuantity: [],
            productReserveQuantity: [],
            productBalance: [],
            productStockScheduled: [],
            editFlg: true,
          };
          //this.nextBusinessDay, this.fiveBusinessDayBefore
          if ((searchResult.deliveryDesignClass == 'M' || searchResult.deliveryDesignClass == 'S') &&
          dateConsistency(searchResult.deliveryDesignDate, this.nextBusinessDay) == true) {
            // 納品指定区分が「M:迄」「S:指定」、且つ、納品指定日が翌日営業日以前
            searchResult._rowVariant = 'danger';
          } else if (dateConsistency(searchResult.inputDate, this.fiveBusinessDayBefore) == true) {
            // 入力日が5営業日前以前の場合
            searchResult._rowVariant = 'warning';
          }
        }
        searchResult['productId'].push(result[i].product_id);
        searchResult['productName'].push(result[i].product_id + ' ' + result[i].product_name_kanji);
        searchResult['productQuantity'].push(result[i].order_receive_quantity.toLocaleString() + (result[i].unit == '' ? '' : ' ' + result[i].unit ));
        searchResult['productRemainingQuantity'].push(result[i].remaining_quantity.toLocaleString());
        if (result[i].shipping_type_class == this.shippingTypeClass.direct || result[i].order_receive_bill_class == this.orderReceiveBillClass.special) {
          searchResult['productReserveQuantity'].push(result[i].stock_scheduled_reserve_receive.toLocaleString());
          orderReceiveReserveQuantity += result[i].stock_scheduled_reserve_receive;
        } else {
          searchResult['productReserveQuantity'].push(result[i].reserve_quantity.toLocaleString());
          orderReceiveReserveQuantity += result[i].reserve_quantity;
        }
        searchResult['productBalance'].push((result[i].balance + result[i].inventory_reserve_count).toLocaleString());
        let productStockScheduled = result[i].stock_scheduled == null ? '0000/00/00' : formatDate(result[i].stock_scheduled);
        if (result[i].stock_scheduled_staff_id != null && result[i].stock_scheduled_staff_id != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_staff_id;
        }
        if (result[i].stock_scheduled_confirm_class != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_confirm_class;
        }
        searchResult['productStockScheduled'].push(productStockScheduled);
      }
      if (searchResult != null) {
        if (orderReceiveReserveQuantity == 0) {
          // 同受注内の引当数が0の場合、出荷確定ボタン非活性
          searchResult.editFlg = false;
        }
        this.itemList.push(searchResult);
      }
      // 伝票の総件数をdataTableの総件数にセット
      this.totalRows = this.itemList.length;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.resultError = [];
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /* PDF出力ボタンの押下 */
    async clickOutputPdf(){
      // 得意先元帳PDF出力処理
      //console.log('PDF出力処理');
      let query = {
        selectSalesOffice: this.searchResultDispConditions.selectSalesOffice,
        shippingCodeStart: this.searchResultDispConditions.shippingCodeStart == '' ? '*' : this.searchResultDispConditions.shippingCodeStart,
        shippingCodeEnd: this.searchResultDispConditions.shippingCodeEnd == '' ? '99999' : this.searchResultDispConditions.shippingCodeEnd,
        shippingTypeClassStart: this.searchResultDispConditions.shippingTypeClassStart == '' ? '*' : this.searchResultDispConditions.shippingTypeClassStart,
        shippingTypeClassEnd: this.searchResultDispConditions.shippingTypeClassEnd == '' ? '9' : this.searchResultDispConditions.shippingTypeClassEnd,
        billingTypeClassStart: this.searchResultDispConditions.billingTypeClassStart == '' ? '0' : this.searchResultDispConditions.billingTypeClassStart,
        billingTypeClassEnd: this.searchResultDispConditions.billingTypeClassEnd == '' ? '9' : this.searchResultDispConditions.billingTypeClassEnd,
        orderDateStart: this.searchResultDispConditions.orderDateStart,
        orderDateEnd: this.searchResultDispConditions.orderDateEnd,
      };
      let route = this.$router.resolve({ name: 'SHIPS-SCHEDULED-ROUTE-PRINT', query: query});
      window.open(route.href, '_blank');
    },
    // 出荷確定ボタンの押下
    async clickShipsConfirm(orderReceiveId) {
      const functionName = 'clickShipsConfirm';
      this.$store.commit('setLoading', true);
      var newWin = window.open('', '_blank');
      try {
        let selectSql = '';
        selectSql += 'SELECT ';
        selectSql += 'orders_receives.reserve_quantity';
        selectSql += ',orders_receives.stock_scheduled_reserve_receive';
        selectSql += ',CASE WHEN ships.order_receive_id IS NULL THEN 0 ELSE 1 END AS ships_class';
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'LEFT JOIN t_ships AS ships ';
        selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
        selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + orderReceiveId + ' ';
        selectSql += 'AND orders_receives.is_deleted = 0 ';

        let isReserve = false;
        let isShips = false;
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null) {
          for (let i = 0; i < resultData.length; i++) {
            if (resultData[i].ships_class == Const.ShipsClass.sumi) {
              isShips = true;
              break;
            } else if (resultData[i].reserve_quantity != 0 || resultData[i].stock_scheduled_reserve_receive != 0) {
              isReserve = true;
            }
          }
          if (isShips == true) {
            let query = {
              name: 'SHIPS-CONFIRM-EDIT',
              query: { receivedOrderId: orderReceiveId, tabFlg: '1', }
            };
            let route = this.$router.resolve(query);
            // タブurl変更      
            newWin.location = route.href
          } else if (isReserve == true) {
            let query = {
              name: 'SHIPS-CONFIRM-INPUT',
              query: { receivedOrderId: orderReceiveId, tabFlg: '1', }
            };
            let route = this.$router.resolve(query);
            // タブurl変更      
            newWin.location = route.href
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','受注').replace('%arg2%','出荷確定入力'));
          }
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','受注').replace('%arg2%','出荷確定入力'));
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg) {
        newWin.close()
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      this.$store.commit('setLoading', false);
    },
    // 受注製品引当ボタン押下
    async clickReserveOrdersReceives() {
      const functionName = 'changeClosingUpdateState';
      let confirmMessage = '選択された営業所の在庫が余っている製品を未引当の受注に引き当てます。';
      confirmMessage += 'よろしいですか？';
      let title = '在庫引当確認';
      if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
        this.$store.commit('setLoading', true);
        try {
          let errCsvProductId = '';
          // ケース換算製品追加
          let addStockProductIdList = [];
          // 現在処理年月取得
          let controlData = await getControlMaster();
          let processMonthYear = controlData.process_month_year;
          let selectSql = this.makeSelectSqlReserveIncomplete(processMonthYear);
          //console.log(selectSql);
          let resultData = await executeSelectSql(selectSql);
          //console.log(resultData);
          if (resultData != null && resultData.length > 0) {
            for (let i = 0; i < resultData.length; i++) {
              addStockProductIdList.push(resultData[i].product_id);
            }
          }
          //console.log(addStockProductIdList);
          let csvStockProductIdList = await modStockProductIdListCase(addStockProductIdList);
          if (csvStockProductIdList.length > 0) {
            errCsvProductId = await reserveStockProductIdList(this.searchConditions.selectSalesOffice, csvStockProductIdList, processMonthYear, this.loginId, MODULE_NAME);
          }
          if (errCsvProductId == '') {
            this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2021'].replace('%arg1%', errCsvProductId));
          }
        } catch(error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          console.log(error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // 未引当製品取得用SQL
    makeSelectSqlReserveIncomplete: function(processMonthYear) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_stocks AS stocks ';
      selectSql += 'ON stocks.month_year = ' + processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = orders_receives.office_id ';
      selectSql += 'AND stocks.product_id = orders_receives.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 受注伝票種別（返品も特別受注も引当数は0以下のため未指定でも問題なし）
      selectSql += 'AND order_receive_bill_class = ' + Const.OrderReceiveBillClass.normal + ' ';
      // 配送種別区分（直送の引当数は0のため未指定でも問題なし）
      selectSql += 'AND shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      // 未引当数
      selectSql += 'AND reserve_quantity_incomplete > 0 ';
      // 削除済みフラグ
      selectSql += 'AND is_deleted = 0 ';
      // ケース換算する製品、または、ケース換算しないで在庫がある製品
      selectSql += 'AND (orders_receives.case_conversion_class = 0 ';
      selectSql += 'OR  (orders_receives.case_conversion_class = 1 AND stocks.balance > 0)) ';
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' orders_receives.product_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders_receives.product_id';

      return selectSql;
    }, 
  }
}
</script>