<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 電子書類保守修正</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'電子書類保守一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card mb-3 card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <div class="card-body">
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                  <b-row>
                    <b-col sm="4" class="px-1">
                      <!-- 電子書類区分 -->
                      <div>
                        <label for="electronicDocumentsClass" class="form-label">電子書類区分</label>
                        <input type="text" id="electronicDocumentsClass" class="form-control" v-model="electronicDocumentsClass" maxlength="4" readonly>
                      </div>
                    </b-col>
                    <b-col sm="2" class="px-1">
                      <!-- 表示順 -->
                      <validation-provider name="sort" rules="required|numeric|between:1,9999" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <label for="sort" class="form-label" v-b-tooltip.hover.noninteractive title="必須項目です。数値を入力してください。">表示順 <span class="px-1 oi oi-flag"/></label>
                          <input type="text" id="sort" class="form-control" v-model="sort" maxlength="4">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col lg="12" class="px-1">
                      <!-- 電子書類名 -->
                      <validation-provider name="electronicDocumentsName" rules="required" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <label for="electronicDocumentsName" class="form-label" v-b-tooltip.hover.noninteractive title="必須項目です。">電子書類名 <span class="px-1 oi oi-flag"/></label>
                          <input type="text" id="electronicDocumentsName" class="form-control" v-model="electronicDocumentsName" maxlength="40">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <!-- 保存ボタン -->
            <b-card-footer>
              <b-row class="justify-content-md-center pb-4">
                <b-col lg="2">
                  <b-button  class="mr-2" pill block variant="success" type="submit" form="InputForm" :disabled="statusFlg.editFlg==false">
                    <span class="oi oi-circle-check"></span> 保存
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { init, addOperationLogs, executeSelectSql, CreateColRow, escapeQuote, CreateUpdateSql, executeTransactSqlList, isSystemEditable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'save-documents-maintenance-edit';

export default {
  name: 'SAVE-DOCUMENTS-MAINTENANCE-EDIT',
  // コンポーネント
  components: {
    Header,
    Footer,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '電子書類保守修正',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 入力情報
      electronicDocumentsName: '',
      sort: '',
      // ログイン情報
      loginId: this.$store.getters.user.username,
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // パラメータ
      electronicDocumentsClass: '',
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.electronicDocumentsClass = this.$route.query.electronicDocumentsClass;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 初期データ設定
    async setInitData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
        this.statusFlg.editFlg = false;
      }
    },
    // 検索SQL作成（初期値）
    makeSelectSql: function() {
      // SELECT句
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' electronic_documents_class';
      selectSql += ',electronic_documents_name';
      selectSql += ',sort';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_electronic_documents ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'electronic_documents_class = ' + this.electronicDocumentsClass + ' ';

      return selectSql;
    },
    // 取得結果セット
    async setResult(result) {
      // 入力情報
      this.electronicDocumentsName = result[0].electronic_documents_name;
      this.sort = result[0].sort;
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // キャンセルボタン押下時
    async clickBack(){
      // 電子書類保守一覧画面へ遷移
      this.$router.push({name: 'SAVE-DOCUMENTS-MAINTENANCE-LIST'});
    },
    // 保存ボタンの押下
    async saveData() {
      const functionName = 'saveData';
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        // 保存確認ダイアログを表示
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','入力された情報で保存します。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '保存確認'});
        if (retConfirm == true) {
          this.$store.commit('setLoading', true);
          try {
            // 重複チェック
            let errMessage = await this.checkDuplicate();
            if (errMessage == '') {
              // データの登録(SQLの作成)
              if (await this.execSave() == true) {
                // 検索画面に戻る
                this.$router.push({name: 'SAVE-DOCUMENTS-MAINTENANCE-LIST', query: { parentKbn: 1 }});
              }
            } else {
              this.alertWarning.push(errMessage);
            }
          } catch(error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
            console.log(error);
            this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          }
          // メッセージが１件でもある場合は一番上へスクロール
          if (this.getMessageFlg == true) {
            scrollTo(0,0);
          }
          this.$store.commit('setLoading', false);
        }
      }
    },
    // 保存実行
    async execSave() {
      const functionName = 'execSave';
      let transactSqlList = [];
      // 電子書類マスタの登録
      transactSqlList.push(await this.updateElectronicDocumentsMaste());

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      return true;
    },
    // 電子書類マスタ更新SQL作成
    async updateElectronicDocumentsMaste() {
      //console.log('電子書類マスタ更新SQL作成');
      let colList = [];
      // 電子書類名
      colList.push(CreateColRow('electronic_documents_name', await escapeQuote(this.electronicDocumentsName), 'VARCHAR'));
      // 表示順
      colList.push(CreateColRow('sort', this.sort,'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_electronic_documents');
      updateSql += ' WHERE ';
      updateSql += 'electronic_documents_class = ' + this.electronicDocumentsClass + ' ';
      updateSql += 'AND is_deleted = 0 ';
      return updateSql;
    },
    // 重複チェックを行う（返却値はエラーメッセージ（空白の場合はエラー無し））
    async checkDuplicate() {
      let selectSql = '';
      selectSql += 'SELECT ';
      selectSql += 'm_ed.electronic_documents_class';
      selectSql += ',m_ed.electronic_documents_name';
      selectSql += ' FROM ';
      selectSql += 'm_electronic_documents AS m_ed ';
      selectSql += ' WHERE ';
      selectSql += 'm_ed.electronic_documents_class <> ' + this.electronicDocumentsClass + ' ';
      selectSql += 'AND m_ed.electronic_documents_name = \'' + await escapeQuote(this.electronicDocumentsName) + '\' ';
      let resultData = await executeSelectSql(selectSql);
      if (resultData != null && resultData.length > 0) {
        // 電子書類名が重複
        return DISP_MESSAGES.WARNING['2076'].replace('%arg1%', '電子書類名');
      } else {
        // 重複データがないためOK
        return '';
      }
    },
  }
}
</script>
<style scoped>
</style>