<template>
  <!--  仕入先現場コード保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong>  仕入先現場コード保守入力</strong></h5>
                <router-link to="/suppliers-sites-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <hr>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 仕入先コード -->
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required|numeric|min:6" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="supplier_id" class="form-label">仕入先コード: {{ obj.supplierName }}</label>
                        <div class="input-group">
                          <input 
                            type="text" 
                            id="supplier_id" 
                            class="form-control" 
                            v-model="obj.supplier_id"
                            @change="searchClientSupplier(obj.supplier_id)" 
                            maxlength="6"
                          >
                          <!-- 仕入先コード検索ボタン -->
                          <b-input-group-text @click="showClientSearchModal(Const.ClientClass.supplier)" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(Const.ClientClass.supplier)" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 得意先コード -->
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required|numeric|min:6" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="client_id" class="form-label">得意先コード: {{ obj.clientName }}</label>
                        <div class="input-group">
                          <input 
                            type="text" 
                            id="client_id" 
                            class="form-control" 
                            v-model="obj.client_id"
                            @change="searchClientCustomer(obj.client_id)"
                            maxlength="6"
                          >
                          <!-- 得意先コード検索ボタン -->
                          <b-input-group-text @click="showClientSearchModal(Const.ClientClass.customer)" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(Const.ClientClass.customer)" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 現場コード -->
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="site_id" class="form-label">現場コード: {{ obj.siteName }}</label>
                        <div class="input-group">
                          <input 
                            type="text" 
                            id="site_id" 
                            class="form-control" 
                            v-model="obj.site_id"
                            @change="searchSite(obj.site_id)" 
                            :readonly="!checkClientId(obj.client_id)" 
                            maxlength="4"
                          >
                          <!-- 現場コード検索ボタン -->
                          <b-input-group-text @click="showSiteSearchModal" v-b-tooltip.hover title="「ALT+3」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '3']" @shortkey="showSiteSearchModal" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                        </div>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <!-- 相手先現場コード -->
                <div class="row">
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 form-group">
                    <validation-provider rules="required|max:10" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="supplier_control_site_id" class="form-label">相手先現場コード</label>
                        <input type="text" id="supplier_control_site_id" class="form-control" v-model="obj.supplier_control_site_id" maxlength="10">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
            </div>
             <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="propClientClass"/>
     <!-- ●●●現場マスタ検索モーダル●●● -->
    <SiteSearch @from-child="closeSiteSearchModal" :clientInfo="{ client_id: obj.client_id, client_name: obj.clientNameKanji}"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, CreateColRow, CreateInsertSql, escapeQuote, getUserCol, isSystemEditable, checkClientId } from '@/assets/js/common.js';
import { searchClientModal, searchSiteModal } from '@/assets/js/searchModal.js';
import ClientSearch from '@/components/modal/client-search.vue';
import SiteSearch from '@/components/modal/site-search.vue';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

// ログ出力用モジュール名
const MODULE_NAME = 'suppliers-sites-input';

export default {
  name: 'SUPPLIERS-SITES-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    ClientSearch,
    SiteSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入先現場コード保守入力',
      propClientClass: 0,
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 仕入先現場コード対象
      obj: {},
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
      Const: {...Const},
    }
  },
  watch: {
    newObj: {
      handler(newObj, oldObj) {
        if (newObj.client_id && oldObj.client_id) {
          // 得意先コード変更ある場合
          if (newObj.client_id != oldObj.client_id) {
            // 現場コードをリセット
            this.siteClear()
          }
        }
      },
      immediate: false,
    },
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0
    },
    newObj(){
      return JSON.parse(JSON.stringify(this.obj));
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    await this.fetchData();
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 発注先検索モーダル表示
     */
    showClientSearchModal(propClientClass) {
      this.propClientClass = propClientClass;
      this.$bvModal.show('clientSearchModal');
    },
    // 仕入先コード直接入力 - 取引先検索
    async searchClientSupplier(client_id) {
      this.propClientClass = Const.ClientClass.supplier;
      const result = await searchClientModal(client_id, this.propClientClass, 'searchClientSupplier', MODULE_NAME)
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 仕入先クリア
          await this.closeClientSearchModal({})
        }
      }
    },
    // 得意先コード直接入力 - 取引先検索
    async searchClientCustomer(client_id) {
      this.propClientClass = Const.ClientClass.customer;
      const result = await searchClientModal(client_id, this.propClientClass, 'searchClientCustomer', MODULE_NAME)
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          //  得意先クリア
          await this.closeClientSearchModal({})
        }
      }
    },
    /**
     * 発注先検索モーダルコールバック
     * @param {Object} choiceData - 選択されたデータ
     */
    closeClientSearchModal(choiceData) {
      if (this.propClientClass == Const.ClientClass.supplier) {
        this.obj.supplier_id = choiceData.clientId
        this.obj.supplierName = choiceData.clientNameKanji
      }
      if (this.propClientClass == Const.ClientClass.customer) {
        this.obj.client_id = choiceData.clientId
        this.obj.clientName = choiceData.clientNameKanji
        this.obj.clientNameKanji = choiceData.clientNameKanji
      }
    },
    /**
     * 現場検索モーダルを表示します。 
     */
    showSiteSearchModal() {
      if (this.obj.client_id) {
        this.$bvModal.show('siteSearchModal');
      } else {
        this.$bvModal.msgBoxOk('得意先コードが選択された場合のみ選択できます。');
      }
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(site_id) {
      const client_id = this.obj.client_id
      const result = await searchSiteModal(site_id, client_id, 'searchSite', MODULE_NAME)
      if (typeof result != 'undefined') {
        // 現場情報セット
        await this.closeSiteSearchModal(result)
      } else {
        // 現場クリア
        this.siteClear()
      }
    },
    /**
     * 現場検索モーダルクローズコールバック処理
     * @param {Object} choiceData - 選択データ
     */
    closeSiteSearchModal(choiceData) {
      this.obj.site_id = choiceData.clientSiteId
      this.obj.siteName = choiceData.clientSiteNameKanji
    },
    siteClear() {
      this.obj.site_id = ''
      this.obj.siteName = ''
    },
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData() {
      this.busy = true;
      // 仕入先現場コードマスタのデータを請求
      const objResult = {
        supplier_id: '',
        supplierName: '',
        client_id: '',
        clientName: '',
        site_id: '',
        siteName: '',
        supplier_control_site_id: '',
      }

      // データセット 
      this.obj = {...objResult}
      this.busy = false;
    },
    /**
     * 仕入先現場コードマスタを検索します。
     * @param {Int} supplier_id
     * @param {Int} client_id
     * @param {Int} site_id
     * @returns {Object} 仕入先現場コードマスタデータ
     */
    async searchSuppliersSites(supplier_id, client_id, site_id) {
      const functionName = 'searchSuppliersSites';

      let result = null;
      // 仕入先現場コードと取引先マスタと現場マスタの結合
      const SQLs = `SELECT  
                    supplier_id,
                    client_id,
                    site_id,
                    supplier_control_site_id
                    FROM 
                    m_suppliers_sites
                    WHERE 
                    supplier_id = ${supplier_id}  
                    AND client_id = ${client_id}  
                    AND site_id = ${site_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0]
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];
      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execInsert();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'SUPPLIERS-SITES-INQUIRY', 
              query: { 
                supplier_id: this.obj.supplier_id,
                client_id: this.obj.client_id,
                site_id: this.obj.site_id,
              },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] }  
            });
          }
          this.$store.commit('setLoading', false);
          console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 登録処理
     */
    async execInsert() {
      //仕入先現場コードマスタ登録チェック
      const suppliersSites = await this.searchSuppliersSites(this.obj.supplier_id, this.obj.client_id, this.obj.site_id)
      if (suppliersSites.length != 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2004']);
        return 
      }

      // 仕入先現場コードマスタ登録
      await this.execInsertSuppliersSites()
    },
    /**
     * 仕入先現場コードマスタ登録
     */
    async execInsertSuppliersSites(){
      const functionName = 'execInsertSuppliersSites';

      const colList = [];
      // 仕入先コード
      colList.push(CreateColRow('supplier_id', Number(this.obj.supplier_id), 'INT'));
      // 得意先コード
      colList.push(CreateColRow('client_id', Number(this.obj.client_id), 'INT'));
      // 現場コード
      colList.push(CreateColRow('site_id', Number(this.obj.site_id), 'INT'));
      // 相手先現場コード
      colList.push(CreateColRow('supplier_control_site_id', await escapeQuote(this.obj.supplier_control_site_id), 'VARCHAR'));

      // 更新と登録ユーザー
      const colUser = await getUserCol(this.username, 'both')

      const sql = `${CreateInsertSql(colList.concat(colUser), 'full', 'm_suppliers_sites')}`
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
